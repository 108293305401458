import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { infoImg } from '../../../utilities/images'
import ProductDetails from './ProductDetails'
import ProductImageAdd from './productImageAdd'
import AddProductHeader from './EditProductHeader'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { imageUploadAsync } from '../message/messageSlice';
import { toast } from 'react-toastify';
import { formValidator } from './formValidator'
import { editProductApiAsync } from '../dashboard/dashboardSlice'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { editProductValidator } from './editProductValidator'
import Multiselect from 'multiselect-react-dropdown'
import EditVarientsModal from '../myProducts/EditVarientsModal'

const EditProduct = (props) => {
    const history = useHistory();
    const location = useLocation();
    const selectedProductDtails = location?.state;
    // const [unitType, setUnitType] = useState(selectedProductDtails?.supplies ? selectedProductDtails?.supplies[0].quantity_unit : "per_unit");
    const backRoute = localStorage.getItem("backButtonRoute")
    const [productId, setProductId] = useState(selectedProductDtails?.id)
    const [productDetails, setProductDetails] = useState({
        "productName": selectedProductDtails?.name,
        "service_id": selectedProductDtails?.service_id,
        "category_id": selectedProductDtails?.category_id,
        "sub_category_id": selectedProductDtails?.sub_category_id,
        "brand_id": selectedProductDtails?.brand_id
    })
    // const [costPrice, setcostPrice] = useState(selectedProductDtails?.price ? selectedProductDtails?.price : "")
    const [productDescription, setProductDescription] = useState(selectedProductDtails?.description)
    const [imageDetails, setImageDetails] = useState({
        image: selectedProductDtails?.image,
        images: selectedProductDtails?.product_images?.map(v => v?.url)
    })
    const allImages = selectedProductDtails?.product_images ?? []
    const [weightUnit, setWeightUnit] = useState(selectedProductDtails?.weight_unit ? selectedProductDtails?.weight_unit : "kg");
    const [dimensions, setDimensions] = useState({
        weight: selectedProductDtails?.weight,
        length: selectedProductDtails?.length,
        breadth: selectedProductDtails?.breadth,
        height: selectedProductDtails?.height
    }); 
    const [barCode, setBarCode] = useState(selectedProductDtails?.barcode ? selectedProductDtails?.barcode : "");
    const [sku, setSku] = useState(selectedProductDtails?.sku ? selectedProductDtails?.sku : "");
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [successLoader, setSuccessLoader] = useState(false)
    const [allAddress, setAllAddress] = useState([]);
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const handleWeightUnit = (e) => {
        setWeightUnit(e.target.value)
    }

    const handleProductDetails = (e, flag) => {
        if (flag == "description") {
            setProductDescription(e)
        } else {
            setProductDetails(e)
        }
    }
    const handleImageDetails = (e) => {
        setImageDetails(e)
    }

    const handleBarcodeChange = (event) => {
        // Check if the input length is within the allowed limit (12 characters)
        if (event.target.value.length <= 14) {
            setBarCode(event.target.value);
        }
      };
      const handleSkuChange = (event) => {
        // Check if the input length is within the allowed limit (12 characters)
        if (event.target.value.length <= 14) {
            setSku(event.target.value);
        }
      };

    // const handleUnitChange = (e) => {
    //     setUnitType(e.target.value)
    // }

    const handledimensions = (e) => {
        const { name, value } = e.target;
        let data = { ...dimensions, [name]: value }
        setDimensions(data);
    }

    const handleSave = () => {
        var imagesToDelete = allImages.filter((item) => !imageDetails.images.includes(item.url));
        imagesToDelete = imagesToDelete.map((item) => item.id);
        let product_attribute = {
            "id": productId,
            "name": productDetails?.productName,
            "category_id": (productDetails?.category_id)?.toString(),
            "sub_category_id": (productDetails?.sub_category_id)?.toString(),
            "brand_id": (productDetails?.brand_id).toString(),
            "service_id": productDetails?.service_id,
            "description": productDescription,
            "type": "physical",
            "barcode": barCode,
            "image": imageDetails?.image,
            "images": imageDetails?.images,
            // "price": costPrice,
            // "weight_unit": "lb",
            // "quantity_unit": unitType,
            // "dimensions": dimensions,
            // "weight": dimensions?.weight,
            // "length": dimensions?.length,
            // "height": dimensions?.height,
            // "breadth": dimensions?.breadth,
        }
        if (imagesToDelete.length > 0) {
       
            product_attribute.deleted_image_ids = imagesToDelete
        }
        if (sku) {
            product_attribute = { ...product_attribute, "sku": sku }
        }


        let result = editProductValidator(product_attribute, dimensions)

        if (result) {
    
            setSuccessLoader(true)
            let params = {
                "postData": product_attribute,
                "tokenData": auth?.payload?.token
            }
            dispatch(editProductApiAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setSuccessLoader(false)
                    toast.success(obj?.msg)
                    history.push(`/productList`)
                }
                )
                .catch((obj) => {
                    setSuccessLoader(false)
                });
        }
    }

    return (
        <div className='addProductManually'>
            {/* Start FixedTopHeader */}
            <AddProductHeader flag={backRoute} save={(e) => handleSave(e)} successLoader={successLoader} />
            {/* End FixedTopHeader */}
            <div className="productRightWrapper">
                <div className='container-fluid pt-3 pb-3'>
                    <div className='row justify-content-center'>
                        <div className='col-md-10 '>
                            {/* Start Uploaded ProductImg Area */}

                            {/* <ProductImageAdd imageData={(e) => handleImageDetails(e)} /> */}

                            <ProductImageAdd imageData={(e) => handleImageDetails(e)} updateImageDetails={setImageDetails} oldImages={imageDetails} />
                            {/* End Uploaded ProductImg Area */}

                            {/* Start ProductDetails Area */}
                            <div className='mt-4'>
                                <div className='row'>
                                    {/* Start ProductDetails Area */}
                                    <div className='col-md-7'>
                                        <ProductDetails productDetails={(e, flag) => handleProductDetails(e, flag)} />
                                        {/* Start CostPrice Area */}
                                        {/* <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                            <form className='row costPrice'>
                                                <div className='form-group  col-md-6 mb-4'>
                                                    <label className="subheaderHeading mb-2">Cost Price
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>
                                                    <div className='inputGroup'>
                                                        <input
                                                            min="1"
                                                            onKeyDown={blockInvalidChar}
                                                            className="customform-control"
                                                            placeholder="Cost Price"
                                                            name="text"
                                                            type="number"
                                                            value={costPrice}
                                                            onChange={(e) => setcostPrice(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='form-group col-md-6 mb-4'>
                                                    <label className='subheaderHeading mb-2' htmlFor='amount'>Unit</label>
                                                    <select autoComplete="new-password" className='customform-control select' defaultValue={unitType} onChange={(e) => handleUnitChange(e)}>
                                                        <option value="per_unit">per unit</option>
                                                        <option value="per_box">per box</option>
                                                        <option value="per_bag">per bag</option>
                                                        <option value="per_pound">per pound</option>
                                                        <option value="per_feet">per feet</option>
                                                        <option value="per_gallon">per gallon</option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div> */}
                                        {/* End CostPrice Area */}

                                    </div>
                                    {/* End ProductDetails Area */}
                                    <div className='col-md-5'>
                                        {/* Start ShippingPickup Area */}
                                        {/* <div className='outerArea'>
                                            <form className='editFormControl'>
                                                <div className='form-group mb-3 '>
                                                    <label className="deatilsHead mb-2 fw-normal">Product weight
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>

                                                    <div className='productWeight_'>
                                                        <div className='col-lg-12'>
                                                            <div className='row align-items-center '>
                                                                <div className='col-xl-12 col-lg-12 col-md-12 pe-0'>
                                                                    <div className='inputGroup'>
                                                                        <input className="customform-control weightPlace" placeholder="lb" name="weight" type="number" value={dimensions?.weight} onChange={(e) => { handledimensions(e) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="deatilsHead mb-2 fw-normal">Product Dimensions
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>
                                                    <ul className="dimensionsGroup mb-3">
                                                        <li className='dimensionsGroupItems'>
                                                            <label className='textinner_ mb-1'><i>Length</i></label>
                                                            <input type="number" className="form-control" placeholder="length" name="length" aria-describedby="button-addon1" value={dimensions?.length} onChange={(e) => { handledimensions(e) }} />
                                                        </li>
                                                        <li className='dimensionsGroupItems'>
                                                            <label className='textinner_ mb-1'><i>Breadth</i></label>
                                                            <input type="number" className="form-control" placeholder="breadth" name="breadth" aria-describedby="button-addon1" value={dimensions?.breadth} onChange={(e) => { handledimensions(e) }} />
                                                        </li>
                                                        <li className='dimensionsGroupItems'>
                                                            <label className='textinner_ mb-1'><i>Height</i></label>
                                                            <input type="number" className="form-control" placeholder="height" name="height" aria-describedby="button-addon1" value={dimensions?.height} onChange={(e) => { handledimensions(e) }} />
                                                        </li>
                                                        <li className='dimensionsGroupItems'>
                                                            <span className='linkText'>in</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </form>
                                        </div> */}
                                        {/* End ShippingPickup Area */}

                                        <div className='outerArea mt-3'>
                                            <form className='editFormControl'>
                                                <div className='form-group mb-3 '>
                                                    <label className="deatilsHead mb-2 fw-normal">Barcode (ISBN, UPC, GTIN, etc.)
                                                    </label>
                                                    <div className='productWeight_'>
                                                        <div className='col-lg-12'>
                                                            <div className='inputGroup'>
                                                                <input className="customform-control " maxLength={14} onKeyDown={blockInvalidChar} placeholder="barcode" name="barcode" type="number" value={barCode} onChange={handleBarcodeChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='outerArea mt-3'>
                                            <form className='editFormControl'>
                                                <div className='form-group mb-3 '>
                                                    <label className="deatilsHead mb-2 fw-normal">SKU (Stock Keeping Unit)
                                                    </label>
                                                    <div className='productWeight_'>
                                                        <div className='col-lg-12'>
                                                            <div className='inputGroup'>
                                                                <input className="customform-control " placeholder="sku" name="upc" maxLength={14} type="text" value={sku} onChange={handleSkuChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <EditVarientsModal type="product" selectedProduct={selectedProductDtails} serviceId={productDetails?.service_id} />
                                </div>
                            </div>
                            {/* End ProductDetails Area */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProduct