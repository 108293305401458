import React from "react";
import * as Images from '../../../../utilities/images';
// import Image from "next/image";

const NoInvoiceSelected = () => {
  return (
    <div className="col-lg-6">
      <div className="commanOuter d-flex align-items-center justify-content-center">
        <div className="w-100 text-center">
          <img className="invoicebill" src={Images.Receiptbill} />
          <h2 className="noInvoiceText">No Invoices selected</h2>
        </div>
      </div>
    </div>
  );
};

export default NoInvoiceSelected;
