import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  defaultUser,
  galleryImg,
  galleryUpload,
  infoImg,
  manage,
  user,
} from "../../../utilities/images";
// import ProductDetails from './serviceDetails'
import EditServiseImageAdd from "./serviceImageAdd";
import EditServiceHeader from "./EditServiceHeader";
import ProductPrice from "./productPrice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getPosUsersAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageUploadAsync } from "../message/messageSlice";
import { toast } from "react-toastify";
import { editProductApiAsync } from "../dashboard/dashboardSlice";
import Multiselect from "multiselect-react-dropdown";
import CustomModal from "../../components/shared/CustomModal";
import AddStaffModal from "./AddStaffModal";
import ServiceDetails from "./serviceDetails";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ProductImageAdd from "../editProducts/productImageAdd";
import EditVarientsModal from "../myProducts/EditVarientsModal";
import EditServiceVarient from "./editvarientModal";

const EditService = (props) => {
  const history = useHistory();
  const location = useLocation();
  const selectedServiceDetails = location?.state;
  const [serviceId, setServiceId] = useState(selectedServiceDetails?.id);
  const [serviceDetails, setServiceDetails] = useState({
    serviceName: selectedServiceDetails?.name,
    masterCategoryId: selectedServiceDetails?.service_id,
    category_id: selectedServiceDetails?.category_id,
    sub_category_id: selectedServiceDetails?.sub_category_id,
  });

  const [posList, setPosList] = useState([]);
  // const [costPrice, setcostPrice] = useState(selectedServiceDetails?.price ? selectedServiceDetails?.price : null)
  const [serviceDescription, setServiceDescription] = useState(
    selectedServiceDetails?.description
  );
  const [imageDetails, setImageDetails] = useState({
    image: selectedServiceDetails?.image,
    images: selectedServiceDetails?.product_images?.map((v) => v?.url),
  });

  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [selectedPosId, setSelectedPosId] = useState([]);
  const [oldSelectedPosId, setOldSelectedPosId] = useState([]);

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const getIdsHandle = (data) => {
    // console.log(oldSelectedPosId, data, "poss iddddsssssssss")
    setSelectedPosId(data);
    handleOnCloseModal();
  };

  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setServiceDescription(e);
    } else {
      setServiceDetails(e);
    }
  };
  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  const handleSave = () => {
    if (!serviceDetails?.serviceName) {
      toast.error("Please enter service name");
      return;
    } else if (!serviceDetails?.masterCategoryId) {
      toast.error("Please select master category");
      return;
    } else if (
      serviceDetails?.masterCategoryId == "2" &&
      !serviceDetails?.category_id
    ) {
      toast.error("Please select category");
      return;
    } else if (
      serviceDetails?.masterCategoryId == "2" &&
      !serviceDetails?.sub_category_id
    ) {
      toast.error("Please select sub category");
      return;
    } else if (!serviceDescription) {
      toast.error("Please enter service description");
      return;
    }
    // else if (!costPrice) {
    //     toast.error("Please enter cost price")
    //     return
    // }
    else if (!selectedPosId) {
      toast.error("Please select POS User");
      return;
    }
    let product_attribute = {
      id: serviceId,
      name: serviceDetails?.serviceName,
      category_id: serviceDetails?.category_id
        ? serviceDetails?.category_id?.toString()
        : "",
      sub_category_id: serviceDetails?.sub_category_id
        ? serviceDetails?.sub_category_id?.toString()
        : "",
      service_id: serviceDetails?.masterCategoryId,
      description: serviceDescription,
      image: imageDetails?.image,
      images: imageDetails?.images,
      // "price": costPrice,
      pos_staff_ids: selectedPosId?.map((val) =>
        val?.pos_staff_details ? val?.pos_staff_details?.id : val?.id
      ),
    };
    if (
      oldSelectedPosId.filter(
        (itemA) => !selectedPosId.some((itemB) => itemB.id === itemA.id)
      )?.length > 0
    ) {
      product_attribute = {
        ...product_attribute,
        deleted_pos_staff_ids: oldSelectedPosId
          .filter(
            (itemA) => !selectedPosId.some((itemB) => itemB.id === itemA.id)
          )
          ?.map((val) =>
            val?.pos_staff_details ? val?.pos_staff_details?.id : val?.id
          ),
      };
    }

    setSuccessLoader(true);
    let params = {
      postData: product_attribute,
      tokenData: auth?.payload?.token,
    };
    dispatch(editProductApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setSuccessLoader(false);
        toast.success(obj?.msg);
        history.push("/service-list");
      })
      .catch((obj) => {
        setSuccessLoader(false);
      });
  };

  useEffect(() => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        need_staff_member: true,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        // const modifiedData = obj.payload.pos_staff.map((item) => ({
        //     value: `${item.user.user_profiles.firstname} ${item.user.user_profiles.lastname}`,
        //     id: item.id
        // }));
        let staffIds = selectedServiceDetails?.pos_staff?.map(
          (item) => item?.pos_staff_details_id
        );
        setSelectedPosId(
          obj?.payload?.pos_staff?.filter((value) =>
            staffIds?.includes(value?.id)
          )
        );
        setOldSelectedPosId(
          obj?.payload?.pos_staff?.filter((value) =>
            staffIds?.includes(value?.id)
          )
        );
        setPosList(obj?.payload?.pos_staff);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="addProductManually addserviceManually">
      {/* Start FixedTopHeader */}
      <EditServiceHeader
        save={(e) => handleSave(e)}
        successLoader={successLoader}
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper">
        <div className="container-fluid pt-3 pb-3">
          <div className="row justify-content-center">
            <div className="col-md-10 ">
              {/* Start Uploaded ProductImg Area */}
              {/* <EditServiseImageAdd imageData={(e) => handleImageDetails(e)} /> */}
              <ProductImageAdd
                imageData={(e) => handleImageDetails(e)}
                oldImages={imageDetails}
              />

              {/* End Uploaded ProductImg Area */}

              {/* Start ProductDetails Area */}
              <div className="mt-4">
                <div className="row">
                  {/* Start ProductDetails Area */}
                  <div className="col-md-8">
                    <ServiceDetails
                      data={selectedServiceDetails}
                      servicesDetails={(e, flag) =>
                        handleProductDetails(e, flag)
                      }
                    />
                    {/* Start CostPrice Area */}
                    {/* <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                            <form className='costPrice'>
                                                <div className='form-group'>
                                                    <label className="subheaderHeading mb-2">Cost Price
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>
                                                    <div className='inputGroup'>
                                                        <input
                                                            min="1"
                                                            onKeyDown={blockInvalidChar}
                                                            className="customform-control"
                                                            placeholder="Cost Price"
                                                            name="text"
                                                            type="number"
                                                            value={costPrice}
                                                            onChange={(e) => setcostPrice(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div> */}
                  </div>
                </div>
              </div>
              {/* End ProductDetails Area */}
              <div className="row mt-3 mb-3">
                <div className="col-md-8">
                  <div className="outerArea ">
                    <label className="labelTxt mb-3" htmlFor="amount">
                      <b>Pos User</b>
                    </label>
                    {selectedPosId?.length > 0 ? (
                      <>
                        {selectedPosId?.map((val) => {
                          let logo;
                          if (val?.user) {
                            logo = val?.user?.user_profiles?.profile_photo
                              ? val?.user?.user_profiles?.profile_photo
                              : defaultUser;
                          } else if (val?.pos_staff_details) {
                            logo = val.pos_staff_details?.user?.user_profiles
                              ?.profile_photo
                              ? val.pos_staff_details?.user?.user_profiles
                                  ?.profile_photo
                              : defaultUser;
                          } else {
                            logo = defaultUser;
                          }

                          return (
                            <img
                              className="me-2"
                              src={logo}
                              alt="pos_img"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50px",
                              }}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                    <span class="editIcon_">
                      <i
                        class="fa-solid fa-plus"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModalDetail({ show: true, flag: "addStaff" });
                          setKey(Math.random());
                        }}
                      ></i>
                    </span>
                  </div>
                </div>
                <EditServiceVarient
                  type="service"
                   selectedServices={selectedServiceDetails}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "ActivePos" ? "customContent" : ""}
        ids={
          modalDetail.flag === "ActivePos"
            ? "activePos"
            : modalDetail.flag === "addStaff"
            ? "addStaff"
            : ""
        }
        size={modalDetail.flag === "addStaff" ? "lg" : "md"}
        child={
          modalDetail.flag === "addStaff" ? (
            <AddStaffModal
              posIds={selectedPosId}
              close={() => handleOnCloseModal()}
              getIdsHandle={(e) => getIdsHandle(e)}
            />
          ) : (
            <></>
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              <h2 className="modalHeading">Staffs</h2>
              <button className="closeButton">
                <i
                  className="las la-times"
                  onClick={() => handleOnCloseModal()}
                ></i>
              </button>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default EditService;
