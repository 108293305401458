import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { selectLoginAuth } from '../auth/authSlice';
import { calendar_light, scn, search_light } from '../../../utilities/images';
import { getProductServiceListApiAsync } from './productSlice';
import { createProductOfferApiAsync, getProductOfferListApiAsync } from '../dashboard/dashboardSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';

const ProductOfferModal = (props) => {
    const history = useHistory();
    const [startDate, setStartDate] = useState('');
    const [showProduct, setShowProduct] = useState(false)
    const [offerFlag, setOfferFlag] = useState("per_pack")
    const [quantity, setQuantity] = useState(1)
    const [offerOneCarton, setOfferOneCarton] = useState('');
    const [productList, setProductList] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [selectedproduct, setSelectedproduct] = useState('');
    console.log(selectedproduct?.supplies, 'selectedproduct');
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch()
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const handleProductChange = (e) => {
        setSelectedproduct(e)
        setShowProduct(false)
    }

    const getOfferList = (page) => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                'seller_id': auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                'page': 1,
                'limit': 10,
                'type': props.type === 'digital' ? "service" : "product"
            }
        }

        dispatch(getProductOfferListApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                props?.setOfferList(obj?.payload?.data);
                props?.setTotalRows(obj?.payload?.total);
            })
            .catch((obj) => {
            })
    }

    let offerFunCall = () => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                product_id: String(selectedproduct?.id),
                actual_price: String(selectedproduct?.supplies[0]?.cost_price),
                offer_price: offerOneCarton,
                offer_validity: moment(startDate).format('YYYY-MM-DD'),
                type: props.type === 'digital' ? "service" : "product",
                offer_flag: offerFlag,
                quantity: offerFlag == "per_box" ? Number(quantity) : 1
            }
        }

        if (selectedproduct?.category_id) {
            params = {
                tokenData: auth?.payload?.token,
                postData: {
                    ...params.postData, category_id: String(selectedproduct?.category_id)
                }
            }
        }
        if (selectedproduct?.sub_category_id) {
            params = {
                tokenData: auth?.payload?.token,
                postData: {
                    ...params.postData, sub_category_id: String(selectedproduct?.sub_category_id)
                }
            }
        }
        if (selectedproduct?.brand_id) {
            params = {
                tokenData: auth?.payload?.token,
                postData: {
                    ...params.postData, brand_id: String(selectedproduct?.brand_id)
                }
            }
        }

            setButtonLoading(true)
            dispatch(createProductOfferApiAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setButtonLoading(false)
                    toast.success(obj?.msg)
                    getOfferList()
                    props.close();
                    // history.push(`/productOffer`)
                }
                )
                .catch((obj) => {
                    setButtonLoading(false)
                })
    }

    const sendOffer = (e) => {
        e.preventDefault();
        if (!offerOneCarton) {
            toast.error("please enter offering price")
            return false;
        }
        if (offerOneCarton <= selectedproduct?.supplies?.reduce((max, current) => Math.max(max, current.cost_price), selectedproduct?.supplies[0].cost_price)) {
            // toast.error("Offer Price should not be less than cost price")
            // return false;
            Swal.fire({
                title: 'Do you want to Proceed with offer price less then cost price?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                   offerFunCall() 
                }
            })
            return false;
        }

        if (!startDate) {
            toast.error("please select offer validity date")
            return false;
        }
        if (offerFlag == "per_box" && !quantity) {
            toast.error("please enter box quantity")
            return false;
        }

        offerFunCall()
    }
    console.log(selectedproduct?.supplies?.reduce((min, current) => Math.min(min, current.cost_price), selectedproduct?.supplies[0].cost_price), 'selectedproductmmmmmmmmmmmmmmmmmmm');
    const getProductList = () => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                type: props.type,
                app_name: "merchant",
                search: searchData,
                filter_deleted: true,
                lat: 32.5534,
                long: 76.1258
            }
        }
        setShowProduct(true)
        setLoading(true)
        dispatch(getProductServiceListApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setProductList(obj?.payload?.data);
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    console.log(selectedproduct, "selected product");

    useEffect(() => {
        if (searchData.length >= 3 || searchData == '') {
            getProductList()
        }
    }, [searchData])

    return (
        <div className='createodderBody_'>
            <div className="input-group offersearch_group mb-4">
                <img src={search_light} alt="" className="img-fluid searchIcon " />
                <input value={searchData} onChange={(e) => setSearchData(e.target.value)} type="text" className="customform-control" placeholder={props.type === 'digital' ? "Search service here" : "Search product here"}
                    aria-label="Recipient's username" aria-describedby="basic-addon2" />
            </div>
            {
                loading === true ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    showProduct && <ul className='createListoffer offerListMain'>
                        {(productList?.length > 0) ?
                            productList?.map((val, index) => {
                                return (
                                    <li style={{ cursor: 'pointer' }} onClick={() => handleProductChange(val)} key={index} className='offerListData'>
                                        {<img src={val?.image ? val?.image : "https://www.technol.si/wp-content/uploads/2018/11/default-image1.jpg"} alt="product_img" style={{ width: "50px", height: "50px", borderRadius: "50px" }} />}
                                        {" "} <span className='offerHeadMain'>{val.name}</span>
                                    </li>
                                )
                            }
                            )
                            :
                            <li>
                                <div className="search-typinner">
                                    <h4>No Result Found</h4>
                                </div>
                            </li>
                        }
                    </ul>

            }
            {
                selectedproduct ?
                    <form className='makeofferDetail horizontalForms' onSubmit={(e) => { sendOffer(e) }}>
                        <div className='offerSubData'>
                            <div className='selectedProduct_ mb-4'>
                                <h5 className='textInnerDark mb-3'>Selected {props.type === 'digital' ? "Service" : "Product"}:</h5>
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <div className='productImage_'>
                                            <img className='productimg_' src={selectedproduct?.image} alt='' />
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='productContent'>
                                            <div className='productList'>
                                                <h5 className='textInner mb-1'>{props.type === 'digital' ? "Service" : "Product"} name:</h5>
                                                <h6 className='textinner_'>{selectedproduct?.name}</h6>
                                            </div>
                                            {
                                                props.type === 'digital' ? <></> :
                                                    <div className='productList'>
                                                        <h5 className='textInner mb-1'>Brand:</h5>
                                                        <h6 className='textinner_'>{selectedproduct?.brand?.name}</h6>
                                                    </div>
                                            }
                                            <div className='productList'>
                                                <h5 className='textInner mb-1'>Sub-category:</h5>
                                                <h6 className='textinner_'>{selectedproduct?.sub_category?.name}</h6>
                                            </div>
                                            <div className='productList'>
                                                <h5 className='textInner mb-1'>Category:</h5>
                                                <h6 className='textinner_'>{selectedproduct?.category?.name}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='offerpriceBox_ mb-4'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='currentPrice_'>
                                            <h5 className='textInner mb-3'>{props.type === 'digital' ? "Service Price" : "Cost Price"}</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-9'>
                                        <div className='currentPrice_'>
                                            <h5 className='textInner mb-3'>Offering Price</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        {
                                            props.type === 'digital' ?
                                                <div className='currentPrice_'>
                                                    <div className='piceDetail mb-3'>
                                                        <h5 className='priceHead_'>$ {(selectedproduct?.supplies[0]?.cost_price)?.toFixed(2)} </h5>
                                                        <p className='textinner_'>per service</p>
                                                    </div>
                                                </div>
                                                :
                                                <div className='currentPrice_'>
                                                    <div className='piceDetail mb-3'>
                                                        <h5 className='priceHead_'>$ {(selectedproduct?.supplies[0]?.cost_price)?.toFixed(2)} </h5>
                                                        {/* <p className='textinner_'>per {props.type === 'digital' ? "service" : 'pack'}</p> */}
                                                    </div>
                                                </div>
                                        }

                                        {/* {
                                        selectedproduct?.supplies[0]?.supply_prices?.map((val, index) => {
                                            return(
                                                <>
                                                
                                                </>
                                            )
                                        })
                                        
                                        } */}

                                    </div>
                                    <div className='col-md-9'>
                                        <div className='currentPrice_'>
                                            <div className={props.type === 'digital' ? "inputPrice mb-4" : "inputPrice inputValue_ mb-4"}>
                                                <span className='dollarSign'>$</span>
                                                <input
                                                    type="number"
                                                    className='priceInput'
                                                    onKeyDown={blockInvalidChar}
                                                    step="any"
                                                    min="1"
                                                    value={offerOneCarton}
                                                    onChange={(e) => { setOfferOneCarton(e.target.value) }}
                                                />
                                                {
                                                    props.type === 'digital' ?
                                                        <span className='pricegrey'>per service</span>

                                                        : <select autoComplete="new-password" className='customform-control pricegrey' value={offerFlag} onChange={e => setOfferFlag(e.target.value)}>
                                                            <option value="per_pack" >Per Pack</option>
                                                            <option value="per_box">Per Box</option>
                                                        </select>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    offerFlag == "per_box" ?
                                        <>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className='currentPrice_'>
                                                        <h5 className='textInner mb-3'>Quantity</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                </div>
                                                <div className='col-md-9'>
                                                    <div className='currentPrice_'>
                                                        <div className="inputPrice inputValue_ mb-4 ">
                                                            <input
                                                                type="number"
                                                                className='priceInput'
                                                                onKeyDown={blockInvalidChar}
                                                                min="1"
                                                                value={quantity}
                                                                onChange={(e) => { setQuantity(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                        <></>
                                }




                            </div>




                            <div className='offerValidity'>
                                <h5 className='validText'>Offer Validity </h5>
                                <hr></hr>
                                <div className='selectDate '>
                                    <label className='subtextDark_ mb-3'>Select Date</label>
                                    <img className='calanderImgg' src={calendar_light} />
                                    <DatePicker
                                        className="customform-control"
                                        selected={startDate}
                                        minDate={moment().toDate()}
                                        onChange={(date) => setStartDate(date)}
                                        placeholderText="Offer Validity Date"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mt-4'>
                            <div className='offerbutton'>
                                <button className='sendOfferBtn w-100' onClick={() => props.close()}>Back</button>
                                <button className='staticBtn w-100' type='submit' disabled={buttonLoading}>
                                    {buttonLoading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Create Offer</span>
                                </button>
                            </div>
                        </div>

                    </form> : <></>}
        </div>
    )
}

export default ProductOfferModal