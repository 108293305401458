import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  facebook,
  instaIcon,
  twitterNew,
  userImg_,
} from "../../../utilities/images";
import {
  getServicesApiAsync,
  getUserByIdApiAsync,
  selectLoginAuth,
  updateMerchantApiAsync,
} from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { imageUploadAsync } from "../message/messageSlice";
import { Spinner } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { CKEditor } from "ckeditor4-react";
import DashboardNavbar from "../dashboard/DashboardNavbar";

const EditProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const profileData = auth?.payload?.user?.user_profiles;
  console.log(profileData, "profileData");
  const [productDescription, setProductDescription] = useState(
    profileData?.business_details ? profileData?.business_details : ""
  );
  const [firstname, setFirstName] = useState(profileData?.firstname);
  const [lastname, setLastName] = useState(profileData?.lastname);
  const [organizationName, serOrganizationName] = useState(
    profileData?.organization_name
  );
  const [serviceList, setServiceList] = useState([]);
  console.log(serviceList, "serviceList");
  const [filteredList, setFilteredList] = useState([]);
  const [instaParams, setInstaParams] = useState(
    profileData?.social_links?.find((item) => item?.slug === "instagram")
      ? profileData?.social_links?.find((item) => item?.slug === "instagram")
      : ""
  );
  const [twitterParams, setTwitterParams] = useState(
    profileData?.social_links?.find((item) => item?.slug === "twitter")
      ? profileData?.social_links?.find((item) => item?.slug === "twitter")
      : ""
  );
  const [facbookParams, setFacebookParams] = useState(
    profileData?.social_links?.find((item) => item?.slug === "facebook")
      ? profileData?.social_links?.find((item) => item?.slug === "facebook")
      : ""
  );
  const [imageUrl, setImageUrl] = useState(profileData?.profile_photo);
  const [businessLogo, setBusinessLogo] = useState(profileData?.business_logo);
  const [operatingIndustry, setOperatingIndustry] = useState([]);
  const [oldOptionsOne, setOldOptionsOne] = useState([]);
  const [selectedOldValues, setSelectedOldValues] = useState([]);
  console.log(selectedOldValues, "selectedOldValues");
  const [selectedOldSellerType, setSelectedOldSellerType] = useState(
    profileData?.business_type?.map((item, index) => ({
      id: index + 1,
      value: item,
      label: `Option ${index + 1}`,
    })) || []
  );
  const [sellerType, setSellerType] = useState(profileData?.seller_type);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // const handleBusinessChange = (e) => {
  //     setBusinesType(e.target.value)
  //     let filteredData = serviceList?.filter((val) => val.type === e.target.value)
  //     setFilteredList(filteredData)
  // }

  // const selectedOldSellerType = profileData?.business_type?.map((data) => { return data })

  // console.log(selectedOldSellerType,"selectedOldSellerType");
  console.log("selectedOldSellerType", selectedOldSellerType);
  const socialLinksData = [
    { slug: "twitter", title: "Twitter", logo: twitterNew },
    { slug: "instagram", title: "Instagram", logo: instaIcon },
    { slug: "facebook", title: "Facebook", logo: facebook },
  ];

  const optionsOne =
    sellerType === "manufacturer"
      ? [{ id: 1, value: "product", label: "Option 1" }]
      : sellerType === "whole_seller"
      ? [{ id: 1, value: "product", label: "Option 1" }]
      : sellerType === "retailer" || sellerType === null
      ? [
          { id: 1, value: "product", label: "Option 1" },
          { id: 2, value: "service", label: "Option 2" },
        ]
      : "";

  console.log(sellerType, optionsOne, "option listttttttttttttttt");

  const handleOperatingIndustryChange = (e) => {
    setSelectedOldValues(e);
    let operatingIndustryIds = e?.map((v) => v?.id);
    setOperatingIndustry(operatingIndustryIds);
  };
  const handleProductDescription = (e) => {
    setProductDescription(e);
  };
  const handleSellerTypeChange = (e) => {
    setSellerType(e.target.value);
    setSelectedOldValues([]);
    setSelectedOldSellerType([]);
  };
  const handleSelect = (selectedList) => {
    setSelectedOldSellerType(selectedList);
    let businessType = selectedList?.map((v) => v?.value);
    setSelectedOptions(businessType);
    if (businessType?.length > 0) {
      let data = {
        type: businessType?.toString(),
      };
      dispatch(getServicesApiAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setServiceList(obj?.payload?.data);
        })
        .catch((obj) => {});
    }
  };
  let imageFile;
  const onSelectFiles = (e, flag) => {
    imageFile = e.target.files[0];
    if (!imageFile) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select Image");
      }
      return;
    }
    let formData = new FormData();
    formData.append("file", imageFile);
    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    flag === "business" ? setLoading1(true) : setLoading2(true);
    dispatch(imageUploadAsync(params))
      .then(unwrapResult)
      .then(async (obj) => {
        flag === "business"
          ? setBusinessLogo(obj?.payload[0]?.filePath)
          : setImageUrl(obj?.payload[0]?.filePath);
        setLoading1(false);
        setLoading2(false);
      })
      .catch((obj) => {
        setLoading1(false);
        setLoading2(false);
      });
  };
  const handleSubmit = () => {
    if (!firstname) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your first name");
      }
      return;
    }
    if (!lastname) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your last name");
      }
      return;
    }
    if (selectedOldSellerType?.length < 1) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your Business Type");
      }
      return;
    }
    if (selectedOldValues?.length < 1) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select Operating Industry");
      }
      return;
    }
    if (!organizationName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your organization name");
      }
      return;
    }
    // if (!operatingIndustry) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please your operating industry");
    //   }
    //   return;
    // }
    if (!sellerType) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select seller type");
      }
      return;
    }
    if (selectedOptions?.length === 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select business type");
      }
      return;
    }

    if (instaParams?.url?.length > 0) {
      const pattern =
        /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
      var result = pattern.test(instaParams?.url);
    }
    if (twitterParams?.url?.length > 0) {
      const pattern =
        /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
      var result = pattern.test(twitterParams?.url);
    }
    if (facbookParams?.url?.length > 0) {
      const pattern =
        /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
      var result = pattern.test(facbookParams?.url);
    }

    if (result === false) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter valid url");
      }
      return;
    }
    let data = {
      postData: {
        firstname: firstname,
        lastname: lastname,
        organization_name: organizationName,
        seller_type: sellerType,
        business_type: selectedOptions,
        service_ids: operatingIndustry?.toString(),
        id: auth?.payload?.user?.user_profiles?.user_id
          ? auth?.payload?.user?.user_profiles?.user_id
          : auth?.payload?.user_profiles?.user_id,
      },
      tokenData: auth?.payload?.token,
    };
    if (imageUrl) {
      data = {
        postData: {
          ...data?.postData,
          profile_photo: imageUrl,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (businessLogo) {
      data = {
        postData: {
          ...data?.postData,
          business_logo: businessLogo,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (productDescription) {
      data = {
        postData: {
          ...data?.postData,
          business_details: productDescription,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (twitterParams?.url?.length > 0) {
      data = {
        postData: {
          ...data?.postData,
          social_links: [twitterParams],
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (instaParams?.url?.length > 0) {
      data = {
        postData: {
          ...data?.postData,
          social_links: [instaParams],
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (facbookParams?.url?.length > 0) {
      data = {
        postData: {
          ...data?.postData,
          social_links: [facbookParams],
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (instaParams?.url?.length > 0 && twitterParams?.url?.length > 0) {
      data = {
        postData: {
          ...data?.postData,
          social_links: [instaParams, twitterParams],
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (facbookParams?.url?.length > 0 && twitterParams?.url?.length > 0) {
      data = {
        postData: {
          ...data?.postData,
          social_links: [facbookParams, twitterParams],
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (facbookParams?.url?.length > 0 && instaParams?.url?.length > 0) {
      data = {
        postData: {
          ...data?.postData,
          social_links: [facbookParams, instaParams],
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (
      facbookParams?.url?.length > 0 &&
      twitterParams?.url?.length > 0 &&
      instaParams?.url?.length > 0
    ) {
      data = {
        postData: {
          ...data?.postData,
          social_links: [facbookParams, twitterParams, instaParams],
        },
        tokenData: auth?.payload?.token,
      };
    }
    setLoading(true);
    dispatch(updateMerchantApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            id: auth?.payload?.user?.user_profiles?.user_id
              ? auth?.payload?.user?.user_profiles?.user_id
              : auth?.payload?.user_profiles?.user_id,
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getUserByIdApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setLoading(false);
            history.push(`/myProfile`);
          })
          .catch((obj) => {
            setLoading(false);
          });
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let data = {
      postData: {
        id: auth?.payload?.user?.user_profiles?.user_id
          ? auth?.payload?.user?.user_profiles?.user_id
          : auth?.payload?.user_profiles?.user_id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading3(true);
    dispatch(getUserByIdApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        let businessType = optionsOne?.map((v) => v?.value);
        let data = {
          type: businessType?.toString(),
        };
        dispatch(getServicesApiAsync(data))
          .then(unwrapResult)
          .then((obj1) => {
            let serviceIds =
              obj?.payload?.user_profiles?.service_ids?.split(",");
            let businessTypeData = obj?.payload?.user_profiles?.business_type;
            setSelectedOptions(businessTypeData);
            if (businessTypeData?.includes("service")) {
              setOldOptionsOne([
                { id: 2, value: "service", label: "Option 2" },
              ]);
            }
            if (businessTypeData?.includes("product")) {
              setOldOptionsOne([
                { id: 1, value: "product", label: "Option 1" },
              ]);
            }
            if (
              businessTypeData?.includes("product") &&
              businessTypeData?.includes("service")
            ) {
              setOldOptionsOne([
                { id: 1, value: "product", label: "Option 1" },
                { id: 2, value: "service", label: "Option 2" },
              ]);
            }
            setOperatingIndustry(serviceIds);
            let filterData = obj1?.payload?.data?.filter((item) =>
              serviceIds?.includes(item.id.toString())
            );
            setSelectedOldValues(filterData);
            setServiceList(filterData);
            setLoading3(false);
          })
          .catch((obj) => {
            setLoading3(false);
          });
      })
      .catch((obj) => {
        setLoading3(false);
      });
  }, []);

  return (
    <>
      <DashboardNavbar title="Edit Profile" show={true} />
      {/* <div className='editProfile_'>
                <div className='topHeader_'>
                    <div className="topStickyHeader_ mb-3">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <span onClick={() => history.push('/myProfile')}>
                                        <i className="las la-angle-left me-3 directionArrow"></i>
                                    </span>
                                    <span>Edit Profile</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="editProfile chartsOuter">
        <div className="space">
          <div className="cart_max_inner mb-3 mt-4">
            <h5 className="deatilsHead mb-0">User Details</h5>
            <div className="cart_max_child ">
              <h6 className=" mb-3 mt-2">Profile Photo</h6>
              <div className="edit_profile mb-4">
                <img
                  id="profileImagePreview"
                  src={imageUrl ? imageUrl : userImg_}
                  className="img-fluid profile_modal"
                />
                {loading2 === true ? (
                  <Spinner variant="primary" animation="border" />
                ) : (
                  <>
                    <label htmlFor="file-upload" className="custom-file-upload">
                      <i className="fas fa-camera"></i>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      name="profile_image"
                      className="d-none"
                      onChange={(e) => {
                        onSelectFiles(e, "profile");
                      }}
                    />
                  </>
                )}
              </div>
              <div className="row  mt-4">
                <div className="col-md-12">
                  <form action="" method="" className="profileForm">
                    <div className="row ">
                      <div className="mb-5 form-group col-md-6">
                        <label className="deatilsHead mb-3">First Name</label>
                        <input
                          className="customform-control"
                          type="text"
                          placeholder="First Name"
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="mb-5 form-group col-md-6">
                        <label className="deatilsHead mb-3">Last Name</label>
                        <input
                          className="customform-control"
                          type="text"
                          placeholder="Last Name"
                          name=""
                          value={lastname}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                      <div className="mb-3 form-group col-md-6">
                        <label className="deatilsHead mb-3">Email</label>
                        <input
                          type="email"
                          className="customform-control"
                          name=""
                          placeholder="Email"
                          value={
                            auth?.payload?.email
                              ? auth?.payload?.email
                              : auth?.payload?.user?.email
                          }
                          disabled
                        />
                        {/* <img className="inputIcon" src={Images.mail} alt=""/> */}
                      </div>
                      <div className="mb-3 form-group col-md-6">
                        <label className="deatilsHead mb-3">Phone Number</label>
                        <input
                          // type="number"
                          className="customform-control"
                          name=""
                          placeholder="Phone Number"
                          disabled
                          value={profileData?.full_phone_number}
                        />
                        {/* <img className="inputIcon" src={Images.mail} alt=""/> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <h5 className="deatilsHead mt-3 mb-0">Business Details</h5>
            <div className="cart_max_child">
              <h6 className="mb-3">Business Logo</h6>
              <div className="edit_profile mb-2">
                <img
                  id="profileImagePreview"
                  src={businessLogo ? businessLogo : userImg_}
                  className="img-fluid profile_modal"
                />
                {loading1 === true ? (
                  <Spinner variant="primary" animation="border" />
                ) : (
                  <>
                    <label
                      htmlFor="file-upload1"
                      className="custom-file-upload"
                    >
                      <i className="fas fa-camera"></i>
                    </label>
                    <input
                      id="file-upload1"
                      type="file"
                      name="profile_image"
                      className="d-none"
                      onChange={(e) => {
                        onSelectFiles(e, "business");
                      }}
                    />
                  </>
                )}
              </div>
              <div className="row  mt-0">
                <div className="col-md-12">
                  <form action="" method="" className="profileForm">
                    <div className="row ">
                      <div className="mb-5 mt-5 form-group col-md-6">
                        <label className="deatilsHead mb-3">
                          Business Type
                        </label>
                        {/* <select autoComplete="new-password" className='customform-control select' defaultValue={profileData?.service_ids == 2 || profileData?.service_ids == 3 ? "service" : profileData?.service_ids == 4 || profileData?.service_ids == 5 || profileData?.service_ids == 6 || profileData?.service_ids == 7 || profileData?.service_ids == 8 ? 'product' : "Business Type"} onChange={(e) => handleBusinessChange(e)}>
                                                    <option value="Business Type" disabled>Business Type</option>
                                                    <option value="product">Product</option>
                                                    <option value="service">Services</option>
                                                </select> */}
                        <Multiselect
                          className="brandFilter customform-control"
                          displayValue="value"
                          options={optionsOne}
                          hidePlaceholder={false}
                          placeholder="Select Business Type"
                          onSelect={handleSelect}
                          onRemove={handleSelect}
                          selectedValues={selectedOldSellerType}
                          showCheckbox
                        />
                      </div>
                      <div className="mb-5 mt-5 form-group col-md-6">
                        <label className="deatilsHead mb-3">
                          Operating Industry
                        </label>
                        {/* <select autoComplete="new-password" className='customform-control select' disabled={businessType === ""} value={operatingIndustry ? operatingIndustry : "Operating Industry"} onChange={(e) => handleOperatingIndustryChange(e)}>
                                                    <option value="Operating Industry" disabled>Operating Industry</option>
                                                    {
                                                        filteredList?.length > 0 ?
                                                            <>
                                                                {filteredList?.map((value, index) => {
                                                                    return (
                                                                        <option value={value?.id} key={index}>{value?.name}</option>
                                                                    )
                                                                })}
                                                            </> : <></>
                                                    }
                                                </select> */}
                        <Multiselect
                          className="brandFilter customform-control"
                          displayValue="name"
                          options={serviceList}
                          hidePlaceholder={true}
                          placeholder="Select operating industry"
                          onSelect={handleOperatingIndustryChange}
                          onRemove={handleOperatingIndustryChange}
                          showCheckbox
                          selectedValues={selectedOldValues}
                        />
                      </div>
                      <div className="mb-4 form-group col-md-6">
                        <label className="deatilsHead mb-1">
                          Organization Name
                        </label>
                        <input
                          type="text"
                          className="customform-control"
                          name=""
                          placeholder="Organization Name"
                          value={organizationName}
                          onChange={(e) => serOrganizationName(e.target.value)}
                        />
                        {/* <img className="inputIcon" src={Images.mail} alt=""/> */}
                      </div>
                      <div className="mb-4 form-group col-md-6">
                        <label className="deatilsHead mb-2">Seller Type</label>
                        <select
                          autoComplete="new-password"
                          className="customform-control select"
                          value={sellerType}
                          onChange={(e) => handleSellerTypeChange(e)}
                        >
                          <option disabled>Seller Type</option>
                          <option value="manufacturer">Manufacturer</option>
                          <option value="whole_seller">Distributor</option>
                          <option value="retailer">Retailer</option>
                        </select>
                        {/* <img className="inputIcon" src={Images.mail} alt=""/> */}
                      </div>
                      <div className="mb-4 form-group col-md-12">
                        <label className="deatilsHead mb-1">About Us</label>
                        <div className="productDetailsSpc">
                          <CKEditor
                            initData={productDescription}
                            config={{
                              versionCheck: false,
                              removePlugins: [
                                "EasyImage",
                                "ImageUpload",
                                "MediaEmbed",
                                "Table",
                                "image",
                              ],
                            }}
                            name="editor"
                            readOnly={false}
                            onChange={(event, editor) => {
                              const data = event.editor.getData();
                              handleProductDescription(data);
                            }}
                            type="classic"
                          />
                        </div>
                        {/* <img className="inputIcon" src={Images.mail} alt=""/> */}
                      </div>
                      <div className="form-group col-md-12">
                        <label className="deatilsHead mb-1">Social Links</label>
                        <div className="productDetailsSpc">
                          <div className="my-2 mx-2">
                            <img
                              className="me-2 "
                              style={{ width: "40px" }}
                              src={socialLinksData[0]?.logo}
                              alt=""
                            />
                            <input
                              style={{ width: "825px" }}
                              value={twitterParams?.url}
                              onChange={(e) =>
                                setTwitterParams({
                                  url: e.target.value,
                                  slug: socialLinksData[0]?.slug,
                                  title: socialLinksData[0]?.title,
                                })
                              }
                              placeholder="Type url here"
                              type="text"
                              className="editInputSocial"
                            />
                          </div>
                        </div>
                        <div className="productDetailsSpc">
                          <div className="my-2 mx-2">
                            <img
                              className="me-2"
                              style={{ width: "40px" }}
                              src={socialLinksData[1]?.logo}
                              alt=""
                            />
                            <input
                              style={{ width: "825px" }}
                              value={instaParams?.url}
                              onChange={(e) =>
                                setInstaParams({
                                  url: e.target.value,
                                  slug: socialLinksData[1]?.slug,
                                  title: socialLinksData[1]?.title,
                                })
                              }
                              placeholder="Type url here"
                              type="text"
                              className="editInputSocial"
                            />
                          </div>
                        </div>
                        <div className="productDetailsSpc">
                          <div className="my-2 mx-2">
                            <img
                              className="me-2"
                              style={{ width: "40px" }}
                              src={socialLinksData[2]?.logo}
                              alt=""
                            />
                            <input
                              style={{ width: "825px" }}
                              value={facbookParams?.url}
                              onChange={(e) =>
                                setFacebookParams({
                                  url: e.target.value,
                                  slug: socialLinksData[2]?.slug,
                                  title: socialLinksData[2]?.title,
                                })
                              }
                              placeholder="Type url here"
                              type="text"
                              className="editInputSocial"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-grid col-lg-2 col-md-2">
          <button
            className="greyButton_ m-0 pt-3 pb-3"
            type="submit"
            onClick={() => history.push(`/myProfile`)}
          >
            <span>Cancel</span>
          </button>
        </div>
        <div className="d-grid col-lg-2 col-md-2">
          <button
            onClick={() => handleSubmit()}
            className="primaryBtn m-0 pt-3 pb-3"
            type="submit"
            disabled={loading}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <span>Update</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
