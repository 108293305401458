import React from 'react'

const AddnewInventoryModal = () => {
    return (
        <div className='addingNewiventory_'>
            <h2 className='mainBoldHeading'>20<span className='blacksemiHead_'>Box</span></h2>
            <p className='subheadingBlack_'>You are going to add Inventory</p>
            <div className='inventButton mt-5'>
                <button className='declineBtn me-3'>Cancel</button>
                <button className='acceptBtn_'>Add now</button>
            </div>
        </div>
    )
}

export default AddnewInventoryModal