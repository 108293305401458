import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { voiceIcon_ } from '../../../../utilities/images';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import ReactAudioPlayer from 'react-audio-player';
import { FileUploader } from "react-drag-drop-files";
import { toast } from 'react-toastify';

const fileTypes = ["webm"];


const VoicerecordModal = (props) => {
    const [audioUrl, setAudioUrl] = useState("");
    const recorderControls = useAudioRecorder();

    const addAudioElement = (blob) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement("audio");
        audio.src = url;
        //audio.controls = true;
        //document.body.appendChild(audio);
        setAudioUrl(url);
    };

    const handleChange = (file) => {
        let objUrl = URL.createObjectURL(file[0])
        setAudioUrl(objUrl);
    };


    const sendAudio =(e,audio) => {
        e.preventDefault();
        if(!audio) {
            toast.error("please select audio");
            return false;
        }
        else {
            props.audio(audio, "audio");
            props.close();

        }
        
        
    }
   


    return (
        <div className='uploadphotoModal commonBody'>
            <form className='emplyoeeDetails horizontalForms' onSubmit={(e)=>{sendAudio(e,audioUrl)}}>
                <div className='row'>
                    <div className='form-group col-md-12 mb-4 text-start'>
                        <div>
                            <div>

                                <AudioRecorder
                                    onRecordingComplete={(blob) => addAudioElement(blob)}
                                    recorderControls={recorderControls}
                                />
                                <input type="button" onClick={recorderControls.stopRecording} value="stop" />
                                {/* <input type="button" onClick={recorderControls.startRecording} value="start" />  */}
                                <input type="button" onClick={recorderControls.togglePauseResume} value="pause" />


                            </div>
                            {/* <div id='dropzone'>
                                <input type="file" id="file" accept="image/*" className="form-control d-none" />
                                <label for="file">
                                    <span className="file-button">
                                        <img src={voiceIcon_} className="img-fluid editProfileImg" alt="user img" />
                                        <div className='dropText ms-3'>
                                            <span className='textinner_'>Drop your files here or</span>
                                            <Link to="#" className='linkTxt ms-1'>Browse</Link>
                                        </div>
                                    </span>
                                </label>


                            </div> */}
                            <FileUploader
                                multiple={true}
                                handleChange={handleChange}
                                name="audio"
                                types={fileTypes}
                            />
                            <div>
                                <div>
                                    <ReactAudioPlayer
                                        src={audioUrl}
                                        autoPlay={false}
                                        controls={true}
                                    />
                                </div>
                            </div>
                            <div className='sendBtn_ mt-5'>
                                <button type = "submit"className='submitBtn' >Send</button>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}

export default VoicerecordModal