import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { brandLogo, shops, stepHalf, } from '../../../../utilities/images'
import OTPInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { resendOtpApiAsync, verifyEmailOtpAsync } from '../authSlice';
import OtpTimer from "otp-timer";

const VerifyemailOtp = () => {
    const history = useHistory()
    const [otp, setOtp] = useState("");
    const emailData = localStorage.getItem("email_details") ? JSON.parse(localStorage.getItem("email_details")) : ""
    const phoneData = localStorage.getItem("phone_details") ? JSON.parse(localStorage.getItem("phone_details")) : ""
    const toastId = React.useRef(null)
    const dispatch = useDispatch()
    const [otpLoading, setOtpLoading] = useState(false)
    const [buttonActive, setButtonActive] = useState(false)
    const [key] = useState(Math.random());
    const generateRandomName = () => {
        return Math.random().toString(36).substr(2, 10);
      };
    const onComplete = (code) => {
        setOtp(code);
        if(code.length == 5){
            setButtonActive(true)
        }
        else{
            setButtonActive(false)
        }
    }
    const handleVerifyOtp = (e) => {
        // e.preventDefault()
        if (!otp || otp.length < 5) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your otp");
            }
            return false
        }
        setOtpLoading(true)

        let params
        if (phoneData?.user_id) {
            params = {
                "type": "email",
                "verification_id": `${emailData?.verification_id}`,
                "otp": otp,
                "user_id": `${phoneData?.user_id}`
            }
        }
        else {
            params = {
                "type": "email",
                "verification_id": `${emailData?.verification_id}`,
                "otp": otp
            }
        }
        dispatch(verifyEmailOtpAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/onboarding')
                setOtpLoading(false)
            }
            )
            .catch((obj) => {
                setOtpLoading(false)
            })
    }

    const resendOtp = () => {
        let params = {
            "type": "email",
            "verification_id": `${emailData?.verification_id}`
        }
        dispatch(resendOtpApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
            })
    }
    useEffect(()=>{
        if(otp && otp?.length == 5){
            handleVerifyOtp()
        }
    },[otp])
    return (
        <div className='chooseRoles'>
            <div className="commonLogin">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-lg-6 p-0 text-center">
                            <div className='leftSect'>
                                <div className="login_left">
                                    <img src={shops} className="d-none d-md-block img-fluid mx-auto mb-3" alt="img" />
                                    <span className='heading_'><b>Create Your <br />Organization</b></span>
                                </div>
                                <div className='aboutBusiness'>
                                    {/* <img src={stepHalf} className='img-fluid mb-4' /> */}
                                    <h4 className='mb-2 innerSubtext'>Tell us about your Business</h4>
                                    <span className=''>Please let us know what you need help with and We will  <br />do our best to assist you.</span>
                                    <p className="mt-4 gobacklink justify-content-center d-flex align-items-center"><i className="fa-solid fa-arrow-left-long"></i> Go back to <Link to="#" onClick={() => { history.push("/") }}> Login </Link> </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className='loginhorrizontal-form verifyFrom'>
                                <form autoComplete="nope" className="form_horizontal row" onSubmit={(e) => handleVerifyOtp(e)}>
                                    <div className="logo-part">
                                        <Link to="#">
                                            <img className='mb-4' src={brandLogo} alt="" />
                                        </Link>
                                        <h2>Verify your <strong>Email</strong></h2>
                                        <p>Enter OTP code here</p>
                                    </div>

                                    <div className="verify-part">
                                        <div className="verify-box text-center">
                                            <div className="pin-box d-flex justify-content-center" >
                                                <OTPInput numInputs={5}
                                                    className='input_digits_'
                                                    value={otp}
                                                    isInputSecure={true}
                                                    data-cy="pin-field"
                                                    isInputNum={true}
                                                    onChange={onComplete}
                                                    autoComplete="new-password"
                                                // onComplete={(code) => onComplete(code)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-5 text-center">
                                    <p className='innerSubtext mt-5 mb-5'>
                                            <OtpTimer
                                                //minutes={1}
                                                seconds={60}
                                                key={key}
                                                //seconds={otpTimerPeriod}
                                                //text="Resend OTP in:"
                                                ButtonText="Resend"
                                                resend={resendOtp}
                                            />
                                        </p>
                                        {/* <p>Didn’t receive Code? <Link to="#" onClick={() => resendOtp()}>Resend</Link></p> */}
                                        {/* <button className={`loginBtnSign w-100 ${buttonActive ? 'buttonActive': ''}`} type="submit" disabled={otpLoading || !buttonActive}>
                                            {otpLoading === true && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Verified</span>
                                        </button> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyemailOtp