import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
// import { exportImg, employeProfile } from '../../../../utilities/images'
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import {createTaxAsync} from "../../systemConfig/systemConfigSlice";

const TaxPayer = (props) => {
    const history = useHistory()
    const [loading, setLoading] = useState([]);
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth);
    const [createTax, setCreateTax] = useState()
    const [name, setName] = useState(auth?.payload?.user?.user_profiles?.firstname +" "+ auth?.payload?.user?.user_profiles?.lastname)
    const [ssn, setSsn] = useState(auth?.payload?.user?.user_profiles?.ssn_number)
    const [address, setAddress] = useState(auth?.payload?.user?.user_profiles?.current_address)
    const [suite, setSuite] = useState(auth?.payload?.user?.user_profiles?.current_address)
    const [country, setCountry] = useState(auth?.payload?.user?.user_profiles?.current_address)
    const [state, setState] = useState(auth?.payload?.user?.user_profiles?.current_address)
    const [city, setCity] = useState(auth?.payload?.user?.user_profiles?.current_address)
    const [zipcode, setZipcode] = useState(auth?.payload?.user?.user_profiles?.zipcode)
    // console.log(name,ssn,address,suite,country,state,city,zipcode,"hhhhhhhhhhh");

    const createTaxdata = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                seller_id: auth?.payload?.uniqe_id,
                name: name,
                ssn: ssn,
                country: country,
                state: state,
                city: city,
                zip_code: zipcode,
                street_address: address,
                apartment: suite
            }
        }
        setLoading(true)
        dispatch(createTaxAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setCreateTax(obj?.payload ? obj?.payload : [])
                
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
        }
    const handleClick = () =>{
        createTaxdata();
        history.push('/taxInformation')
    }
  return (
    <div className='addEmployeeModal'>
    <form className='emplyoeeDetails horizontalForms'>
        <p className='noteValidation'>We are currently unable to contact the IRS to validate the name and TIN you provided. Please try again at a later time.</p>
       <div className='row'>
            <div className='form-group col-md-12  mb-4'>
                <label className='labelTxt mb-3' htmlFor='amount'>Name </label>
                <input type='text' className='customform-control' placeholder="Enter the Name" 
                value={name || ''} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='form-group col-md-12  mb-4'>
                <label className='labelTxt mb-3' htmlFor='amount'>SSN or EIN</label>
                <input type='text' className='customform-control' placeholder="Enter SSN" value={ssn || ''} onChange={(e)=>setSsn(e.target.value)} />
            </div>
            <div className='form-group col-md-12  mb-4'>
                <label className='labelTxt mb-3' htmlFor='amount'>Street Address</label>
                <input type='text' className='customform-control' placeholder="Select Address" value={address || ''} onChange={(e)=>setAddress(e.target.value)}/>
            </div>
            <div className='form-group col-md-12  mb-4'>
                <label className='labelTxt mb-3' htmlFor='amount'>Apartment/Suite</label>
                <input type='text' className='customform-control' placeholder="Select Apartment"  value={suite || ''} onChange={(e)=>setSuite(e.target.value)}/>
            </div>
          <div className='form-group col-md-6 mb-4'>
              <label className='labelTxt mb-3' htmlFor='amount'>Country</label>
              <select className='customform-control select fw-normal' placeholder="Select country" value={country || ''} onChange={(e)=>setCountry(e.target.value)}>
                  <option>{props?.data.country}</option>
                  <option>Department2</option>
                  <option>Department3</option>
              </select>
          </div>
          <div className='form-group col-md-6 mb-4'>
              <label className='labelTxt mb-3' htmlFor='amount'>State</label>
              <select className='customform-control select fw-normal' placeholder='Select State' value={state || ''} onChange={(e)=>setState(e.target.value)}>
                  <option>{props?.stateData?.state}</option>
                  <option>Department2</option>
                  <option>Department3</option>
              </select>
          </div>
          <div className='form-group col-md-6 mb-4'>
              <label className='labelTxt mb-3' htmlFor='amount'>City</label>
              <select className='customform-control select fw-normal' placeholder='Select City' value={city || ''} onChange={(e)=>setCity(e.target.value)}>
                  <option>"city"</option>
                  <option>Department2</option>
                  <option>Department3</option>
              </select>
          </div>
          <div className='form-group col-md-6  mb-4'>
                <label className='labelTxt mb-3' htmlFor='amount'>Zip Code</label>
                <input type='text' className='customform-control' placeholder="Enter Zipcode"  value={zipcode} onChange={(e)=>setZipcode(e.target.value)}/>
            </div>
            <div className='footerSec mt-4'>
                <button to='' className='closebtnModal me-4'>Cancel</button>
                <button to='' className='exchangeBtn lh-sm' onClick={()=>handleClick()}>Verify</button>
            </div>
       </div>
    </form>
    </div>
  )
}

export default TaxPayer