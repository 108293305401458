import moment from "moment-timezone";

// MMMM Do YYYY, h:mm:ss a
export const getFormatedDate = (date, format = 'MM-DD-YYYY, h:mm:ss A', timeZone = '') => {
    if (date && moment(date).isValid()) {
        if (timeZone) {
            return moment(date).format(format).tz(timeZone);
        }
        return moment(date).format(format)
    }
    return false;
}

export const options = [
    { id: 1, label: 'Monday' },
    { id: 2, label: 'Tuesday' },
    { id: 3, label: 'Wednesday' },
    { id: 4, label: 'Thursday' },
    { id: 5, label: 'Friday' },
    { id: 6, label: 'Saturday' },
    { id: 7, label: 'Sunday' }
];

export const dayList = [
    { id: 1, value: 'Monday', day: 'monday' },
    { id: 2, value: 'Tuesday', day: 'tuesday' },
    { id: 3, value: 'Wednesday', day: 'wednesday' },
    { id: 4, value: 'Thursday', day: 'thursday' },
    { id: 5, value: 'Friday', day: 'friday' },
    { id: 6, value: 'Saturday', day: 'saturday' },
    { id: 7, value: 'Sunday', day: 'sunday' }
]

export const languageList = [
    {
        name: "United States of America", image: "https://flagcdn.com/w320/us.png", status: "true",
    },
    {
        name: "Russia", image: "https://flagcdn.com/w320/ru.png", status: "true"
    },
    {
        name: "Portugal", image: "https://flagcdn.com/w320/pt.png", status: "true"
    },
    {
        name: "Spanish", image: "https://flagcdn.com/w320/es.png", status: "true"
    },
    {
        name: "Italian", image: "https://flagcdn.com/w320/it.png", status: "true"
    }
]