import React from 'react'
import { profileImg1, user } from '../../../../utilities/images';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';

const PendingInvoiceModal = (props) => {
  const auth = useSelector(selectLoginAuth)
  return (
    <div className='pendingInvoice commonPadding_'>
      <div className='invoiceDetail'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='invoiceHead'>
              <h3 className='invoiceHeading'>Invoice</h3>
            </div>
          </div>
          <div className="col-md-4">
            <div className="CustomerOnlinestatus_">
              <h5 className='buyHeading'>Invoice details</h5>
              <p className='invoiceHead'>
                Invoice Id : <span>{props?.singleOrderData?.invoices?.invoice_number}</span>
              </p>
              <p className='invoiceHead'>
                Created Date : <span>{moment(props?.singleOrderData?.invoices?.created_date).format("DD MMM YYYY")}</span>
              </p>
              <p className='invoiceHead'>
                Due Date : <span>{moment(props?.singleOrderData?.invoices?.due_date).format("DD MMM YYYY")}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='invoiceForm'>
        <h5 className='semiboldHeading_'>Invoice from:</h5>
        <p className='smalllightHead_'>{props?.singleOrderData?.seller_details?.firstname + " " + props?.singleOrderData?.seller_details?.lastname}</p>
        <p className='smalllightHead_'>{props?.singleOrderData?.seller_details?.phone_number}</p>
        <p className='smalllightHead_'>{props?.singleOrderData?.seller_details?.current_address?.street_address}</p>
      </div>
      <div className="customerInvoicetable mt-4">
        <div className="table-responsive">
          <table className="table  listItemsContainer">
            <thead className="tHead_">
              <th className="text-start">#</th>
              <th className="text-start">Descriptions</th>
              <th className="text-start">No. of Items</th>
              <th className="text-start">Rate</th>
              <th>Amount</th>
            </thead>
            <tbody>
              {props?.singleOrderData?.order_details?.length > 0 ?
                props?.singleOrderData?.order_details?.map((val, ind) => {
                  return (
                    <tr key={ind} role="row">
                      <td className="text-start">{ind + 1}</td>
                      <td className="text-start">
                        <p className="contentheadtext_">{val?.product_name}</p>
                      </td>
                      <td className="text-start">
                        <p className="contentheadtext_">{val?.qty}</p>
                      </td>
                      <td className="text-start">
                        <p className="contentheadtext_">${val?.offer_price && val?.offer_price != 0 ? val?.offer_price / val?.qty : val?.actual_price}</p>
                      </td>
                      <td className="text-start">
                        <p className="itemsTxt">${val?.offer_price && val?.offer_price != 0 ? val?.offer_price : val?.qty * val?.actual_price}</p>
                      </td>
                    </tr>
                  )
                })
                : "No Orders"}
            </tbody>
          </table>
        </div>
        <div className="totalArea">
          <div className='row'>
            <div className="col-md-6 text-start">
              <div className="notes">
                <textarea
                  className=""
                  placeholder="Notes"
                  id="floatingTextarea"
                  rows="9"
                  value={props?.singleOrderData?.notes}
                  disabled
                ></textarea>
              </div>
            </div>
            <div className="col-md-6 text-start">
              <div className="Customertotal">
                <div className="totalDetails">
                  <h5 className="personalinfoHead">Sub Total</h5>
                  <h5 className="personalinfoHead">${props?.singleOrderData?.actual_amount}</h5>
                </div>
                <div className="totalDetails">
                  <h5 className="personalinfoHead">Discount {props?.singleOrderData?.coupon_code ? (props?.singleOrderData?.coupon_code) : ""}</h5>
                  <h5 className="personalinfoHead">${props?.singleOrderData?.discount}</h5>
                </div>
                <div className="totalDetails">
                  <h5 className="personalinfoHead">{props?.singleOrderData?.is_shipment_created === true ? 'Shipping' : 'Delivery'} Charge</h5>
                  <h5 className="personalinfoHead">${props?.singleOrderData?.is_shipment_created === true ? props?.singleOrderData?.shipping_charge : props?.singleOrderData?.delivery_charge}</h5>
                </div>
                <div className="totalDetails">
                  <h5 className="personalinfoHead">Tax</h5>
                  <h5 className="personalinfoHead">${props?.singleOrderData?.tax ? props?.singleOrderData?.tax : "0"}</h5>
                </div>
                <div className="totalDetails">
                  <h5 className="personalinfoHead">Tips</h5>
                  <h5 className="personalinfoHead">${props?.singleOrderData?.tips ? props?.singleOrderData?.tips : "0"}</h5>
                </div>
                <div className="totalDetails">
                  <h5 className="itemsText ">Total</h5>
                  <h5 className="itemsText">${props?.singleOrderData?.payable_amount}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(props?.singleOrderData?.status === 0) ?
          <div className='invoiceBottombtn mt-5'>
            <button onClick={() => props?.statusHandle(props?.singleOrderData?.id, 8)} className='declineBtn me-3' type="submit" disabled={props?.cancelLoading}>
              {props?.cancelLoading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              &nbsp;&nbsp;
              <span>Decline Order</span>
            </button>
            <button onClick={() => props?.statusHandle(props?.singleOrderData?.id, 1)} className='acceptBtn_' type="submit" disabled={props?.acceptLoaDing}>
              {props?.acceptLoaDing && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              &nbsp;&nbsp;
              <span>Accept Order</span>
            </button>
          </div> : <></>}
        {props?.singleOrderData?.driver_details ?
          <div className="carrierbox">
            <h5>JBR Delivery</h5>
            <div className="shippingContact carriercontact">
              <div className="shipcontactLeft">
                <img style={{ width: "40px" }} className='me-2' src={props?.singleOrderData?.driver_details?.profile_photo ? props?.singleOrderData?.driver_details?.profile_photo : profileImg1} />
                <div className="contctInfo">
                  <h4>{props?.singleOrderData?.driver_details?.firstname + " " + props?.singleOrderData?.driver_details?.lastname}</h4>
                </div>
              </div>
              <button className="contactBtn">
                <i className="las la-phone"></i>
                <h3>Contact<br />{props?.singleOrderData?.driver_details?.phone_number}</h3>
              </button>
            </div>
          </div> :
          <></>
        }
      </div>
    </div>
  )
}

export default PendingInvoiceModal