import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { deleteProductSupplyApiAsync } from "../dashboard/dashboardSlice";
import { toast } from "react-toastify";

const EditVarientsModal = (props) => {
  const history = useHistory();
  const [supplyinfo, setSupplyinfo] = useState(
    props?.selectedProduct?.supplies
  );
  const [address, setAddress] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


console.log(props?.selectedProduct,"dssssssssssssssssssssssssss");

  const deleteProductSupply = (supplyId) => {
    Swal.fire({
      title: "Do you want to delete the product Supply?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          postData: {
            product_Id: props?.selectedProduct?.id,
            supply_Id: supplyId,
          },
          tokenData: auth?.payload?.token,
        };
        // setDeleteLoading(true)
        dispatch(deleteProductSupplyApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setSupplyinfo((prev) => prev.filter((val) => val.id !== supplyId));
            // getProductList()
            // setDeleteLoading(false)
            props?.getProductList();
            toast.success(obj?.msg);
          })
          .catch((obj) => {
            // setDeleteLoading(false)
          });
      }
    });
  };
  useEffect(() => {
    const paramsData = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(paramsData))
      .then(unwrapResult)
      .then((obj1) => {
        setLoading(false);
        setAddress(obj1?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="col-lg-12 mt-4">
        <div className="productSelling">
          {supplyinfo?.map((v, i) => {
            return (
              <React.Fragment key={i}>
                <div className="d-flex align-items-center justify-content-space-between">
                  <h1 className="textInnerHead fw-500">
                    Address:{" "}
                    {
                      address?.find((item) => item?.id == v?.seller_address_id)
                        ?.format_address
                    }
                  </h1>
                  <button
                    className="backgroundFillbtn ms-auto"
                    onClick={() =>
                      history.push({
                        pathname: `/${
                          props?.type == "product"
                            ? "editProductSupply"
                            : "editServiceSupply"
                        }`,
                        state: { ...v, product_id: props?.selectedProduct?.id, serviceId: props?.serviceId },
                      })
                    }
                  >
                    Edit Supply
                  </button>

                  {supplyinfo.length > 1 ? (
                    <button
                      className="backgroundFillbtn ms-2"
                      style={{ background: "#fe0000eb" }}
                      onClick={() => deleteProductSupply(v?.id)}
                    >
                      Delete Supply
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="table-responsive mt-3">
                  <table className="table  listItemsContainer">
                    <thead className="productHead_">
                      <th className="dateapoint">#</th>
                      {props?.selectedProduct?.type === "digital" ? (
                        <></>
                      ) : (
                        <th className="dateapoint text-center">Cost Price</th>
                      )}
                      {props?.selectedProduct?.type === "digital" ? (
                        <></>
                      ) : (
                        <th className="dateapoint text-center">
                          Sold quantity
                        </th>
                      )}
                      {props?.selectedProduct?.type === "digital" ? (
                        <></>
                      ) : (
                        <th className="dateapoint text-center">
                          Rest Quantity
                        </th>
                      )}
                      {props?.selectedProduct?.type === "digital" ? (
                        <></>
                      ) : (
                        <th className="dateapoint text-center">
                          Total Quantity
                        </th>
                      )}
                      <th className="dateapoint text-center">Status</th>
                      <th className="dateapoint text-center">Supply Prices</th>
                    </thead>
                    <tbody>
                      <tr role="row">
                        <td className="text-start">{i + 1}</td>
                        {props?.selectedProduct?.type === "digital" ? (
                          <></>
                        ) : (
                          <td className="text-center">
                            <div className="name_content">
                              <h6 className="contentheadtext_">
                                {" "}
                               ${addThousandSeparator((v?.cost_price).toFixed(2))}
                              </h6>
                              {/* <span className="contentHeadtextlight_">Box of 25</span> */}
                            </div>
                          </td>
                        )}
                        {props?.selectedProduct?.type === "digital" ? (
                          <></>
                        ) : (
                          <td className="text-center">
                            <div className="name_content">
                              <h6 className="contentheadtext_">
                                {" "}
                                {v?.sold_quantity}
                              </h6>
                              {/* <span className="contentHeadtextlight_">Box of 25</span> */}
                            </div>
                          </td>
                        )}
                        {props?.selectedProduct?.type === "digital" ? (
                          <></>
                        ) : (
                          <td className="text-center">
                            <p className="contentheadtext_">
                              {v?.rest_quantity}
                            </p>
                          </td>
                        )}
                        {props?.selectedProduct?.type === "digital" ? (
                          <></>
                        ) : (
                          <td className="text-center">
                            <p className="contentheadtext_">
                              {v?.total_quantity}
                            </p>
                          </td>
                        )}
                        <td className="text-center">
                          <p className="itemsTxt">
                            {v?.status === true ? "Active" : "Inactive"}
                          </p>
                        </td>
                        <td className="text-center">
                          <p className="itemsTxt">
                            <span className="textParagh productindetail_">
                              {v?.supply_prices?.map((val, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <p className="textInnerHead px-3">
                                      App Name:{" "}
                                      <span className="mainlightHead">
                                        {val?.app_name}{" "}
                                      </span>
                                    </p>
                                    <p className="textInnerHead px-3">
                                      Selling Prices:{" "}
                                      <span className="mainlightHead">
                                        $
                                        {val?.selling_price
                                          ? addThousandSeparator((val?.selling_price).toFixed(2))
                                          : 0}{" "}
                                      </span>
                                    </p>
                                  </React.Fragment>
                                );
                              })}
                            </span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {v?.supply_variants?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table  listItemsContainer">
                      <thead className="productHead">
                        <th className="dateapoint text-startr">#</th>
                        <th className="dateapoint text-center">Image/Name</th>
                        <th className="dateapoint text-center">Price</th>
                        <th className="dateapoint text-center">Stock</th>
                        <th className="dateapoint text-center">UPC</th>
                        <th className="dateapoint text-center">Weight</th>
                      </thead>
                      <tbody>
                        {v?.supply_variants?.map((val, ind) => {
                          return (
                            <tr key={ind} role="row">
                              <td className="text-start">{ind + 1}</td>
                              <td className="text-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <div className="imgFileBox me-2">
                                    <label className="" htmlFor="file-input">
                                      <span className="galleryCircle me-2">
                                        <img
                                          src={val?.image}
                                          alt=""
                                          className=""
                                        />
                                      </span>
                                    </label>
                                  </div>
                                  <div className="variantItemsContent">
                                    <p className="subtextDark_">
                                      {JSON.parse(
                                        val?.attribute_variant?.variants
                                      )[0]?.attribute_name +
                                        "/" +
                                        JSON.parse(
                                          val?.attribute_variant?.variants
                                        )[0]?.attribute_value_name}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <p className="contentheadtext_">
                                  ${val?.price}
                                </p>
                              </td>
                              <td className="text-center">
                                <p className="contentheadtext_">{val?.stock}</p>
                              </td>
                              <td className="text-center">
                                <p className="contentheadtext_">{val?.upc}</p>
                              </td>
                              <td className="text-center">
                                <p className="contentheadtext_">
                                  {val?.weight}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EditVarientsModal;
