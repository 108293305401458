import React from 'react'

const RefundoverviewModal = (props) => {
    return (
        <div className='refundoverviewModal_ '>
            {props?.data}
            {/* <p className='parasmallHead_ mb-2'>Refund Timeline</p>
            <p className='parasmallHead_ '>Once a refund is initiated, your customer should see a pending transaction on their bank account for the refunded amount within 1-3 business days. This timeline to post the transaction ultimately depends on the card-issuing bank.</p>
            <p className='parasmallHead_ mb-4'>Note: Pending transaction notifications currently apply to payments made with Visa, American Express, or Mastercard. Pending transaction notifications will not apply for payments made with other card networks.
            </p>
            <p className='parasmallHead_'>The Square full refund time frame typically takes 2-7 business days. Once the refund is processed and sent to your customer's card issuing bank, it can take another 2-7 business days (depending on the bank's processing speeds) for the refund to post to the customer's account.
                Payments processed on Square Online are only eligible to refund within one year.
            </p>
            <p className='parasmallHead_'>Payments processed on Square Online are only eligible to refund within one year. </p>
            <p className='parasmallHead_'>Note: Refund requests that aren’t covered by your Square balance may not be approved, even if requested within the appropriate time frame.</p>
            <p className='parasmallHead_ mb-4'>Declined Refund</p>
            <p className='parasmallHead_'>If a refund to a payment card is declined, the buyer will automatically be notified about the decline. In this case, you will be instructed to issue an alternative form of payment to the buyer to complete the refund, such as cash, check, or gift card credit.</p>
            <p className='parasmallHead_ mb-3'>Refund Policy</p>
            <p className='parasmallHead_'>Customize your refund and return policy from the Receipt settings in your online Square Dashboard. Your policy appears on email, text, and printed receipts. If a return policy is absent, it’s assumed there’s no policy. Be sure to clearly state if you don’t offer refunds or warranties.</p> */}
        </div>
    )
}

export default RefundoverviewModal
