import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { selectLoginAuth } from "../auth/authSlice";
import { calendar_light } from "../../../utilities/images";
import {
  getProductOfferListApiAsync,
  updateProductOfferApiAsync,
} from "../dashboard/dashboardSlice";

const OfferEditModal = (props) => {
    console.log(props.selectedProduct,"props");
  const [offerFlag, setOfferFlag] = useState("per_pack");
  const [quantity, setQuantity] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [costPrice, setCostPrice] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [valDate, setValDate] = useState("");

  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();


const dateString = props?.selectedProduct?.offer_validity;
const newDateString = dateString.split('T')[0] + 'T00:00:00';
const correctDate=moment(`${newDateString}`)?.toDate()

  useEffect(() => {
    setCostPrice(props?.selectedProduct?.actual_price);
    setOfferPrice(props?.selectedProduct?.offer_price);
    setValDate(correctDate);
    setQuantity(props?.selectedProduct?.quantity);
    setOfferFlag(props?.selectedProduct?.offer_flag);
  }, [props]);

  const getOfferList = (page) => {
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        page: 1,
        limit: 10,
        type: props.type === "digital" ? "service" : "product",
      },
    };

    dispatch(getProductOfferListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        props?.setOfferList(obj?.payload?.data);
        props?.setTotalRows(obj?.payload?.total);
      })
      .catch((obj) => {});
  };

  const handleOfferPriceChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setOfferPrice(limitedValue);
  };
  const handleQuntChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setQuantity(limitedValue);
  };
  const updateOffer = (e) => {
    if (offerPrice <= props?.selectedProduct?.actual_price) {
      toast.error("Offer Price should not be less than cost price")
      return false;
  }
    e.preventDefault();
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        product_id: String(props?.selectedProduct?.id),
        offer_price: offerPrice,
        offer_validity: moment(valDate).format("YYYY-MM-DD"),
        offer_flag: offerFlag,
        quantity: quantity ? quantity : 1,
      },
    };
    setButtonLoading(true);
    dispatch(updateProductOfferApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setButtonLoading(false);
        console.log(obj, "offer response");
        toast.success(obj?.msg);
        getOfferList();
        props.close();
        // history.push(`/productOffer`)
      })
      .catch((obj) => {
        setButtonLoading(false);
      });
  };

  return (
    <>
      <div className="offerSubData">
        <div className="selectedProduct_ mb-4">
          <h5 className="textInnerDark mb-3">
            Selected {props.type === "digital" ? "Service" : "Product"}:
          </h5>
          <div className="row">
            <div className="col-md-5">
              <div className="productImage_">
                <img
                  className="productimg_ editOfferImg"
                  src={props?.selectedProduct?.product?.image}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="productContent">
                <div className="productList">
                  <h5 className="textInner mb-1">
                    {props.type === "digital" ? "Service" : "Product"} name:
                  </h5>
                  <h6 className="textinner_">
                    {props?.selectedProduct?.product?.name}
                  </h6>
                </div>
                {props.type === "digital" ? (
                  <></>
                ) : (
                  <div className="productList">
                    <h5 className="textInner mb-1">Brand:</h5>
                    <h6 className="textinner_">
                      {props?.selectedProduct?.brands?.name}
                    </h6>
                  </div>
                )}
                <div className="productList">
                  <h5 className="textInner mb-1">Sub-category:</h5>
                  <h6 className="textinner_">
                    {props?.selectedProduct?.subcategories?.name}
                  </h6>
                </div>
                <div className="productList">
                  <h5 className="textInner mb-1">Category:</h5>
                  <h6 className="textinner_">
                    {props?.selectedProduct?.categories?.name}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offerpriceBox_ mb-4">
          <div className="row">
            <div className="col-md-3">
              <div className="currentPrice_">
                <h5 className="textInner mb-3">
                  {props.type === "digital" ? "Service Price" : "Cost Price"}
                </h5>
              </div>
            </div>
            <div className="col-md-9">
              <div className="currentPrice_">
                <h5 className="textInner mb-3">Offering Price</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              {props.type === "digital" ? (
                <div className="currentPrice_">
                  <div className="piceDetail mb-3">
                    <h5 className="priceHead_">
                      ${props?.selectedProduct?.actual_price}
                    </h5>
                    <p className="textinner_">per service</p>
                  </div>
                </div>
              ) : (
                <div className="currentPrice_">
                  <div className="piceDetail mb-3">
                    <h5 className="priceHead_">{costPrice}</h5>
                    {/* <p className='textinner_'>per {props.type === 'digital' ? "service" : 'pack'}</p> */}
                  </div>
                </div>
              )}

              {/* {
    selectedproduct?.supplies[0]?.supply_prices?.map((val, index) => {
        return(
            <>
            
            </>
        )
    })
    
    } */}
            </div>
            <div className="col-md-9">
              <div className="currentPrice_">
                <div
                  className={
                    props.type === "digital"
                      ? "inputPrice mb-4"
                      : "inputPrice inputValue_ mb-4"
                  }
                >
                  {/* <span className="dollarSign">${offerPrice}</span> */}
                  {/* <input
                    type="number"
                    className="priceInput"
                    onKeyDown={blockInvalidChar}
                    step="any"
                    min="1"
              
                    value={offerPrice}
                    onChange={(e) => {
                      setOfferPrice(e.target.value);
                    }}
                  /> */}
                  <input
                    type="number"
                    className="priceInput"
                    onKeyDown={(e) => {
                    
                      if (!/[\d\b]/.test(e.key) && e.key.length === 1) {
                        e.preventDefault();
                      }
                    }}
                    step="any"
                    min="1"
                    value={offerPrice}
                    onChange={handleOfferPriceChange}
                  />
                  {props.type === "digital" ? (
                    <span className="pricegrey">per service</span>
                  ) : (
                    <select
                      autoComplete="new-password"
                      className="customform-control pricegrey"
                      value={offerFlag}
                      onChange={(e) => setOfferFlag(e.target.value)}
                    >
                      <option value="per_pack">Per Pack</option>
                      <option value="per_box">Per Box</option>
                    </select>
                  )}
                </div>
              </div>
            </div>
          </div>

          {offerFlag == "per_box" ? (
            <>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  <div className="currentPrice_">
                    <h5 className="textInner mb-3">Quantity</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  <div className="currentPrice_">
                    <div className="inputPrice inputValue_ mb-4 ">
                      <input
                        type="number"
                        className="priceInput"
                        onKeyDown={blockInvalidChar}
                        min="1"
                        value={quantity}
                        
                        onChange={handleQuntChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="offerValidity">
          <h5 className="validText">Offer Validity </h5>
          <hr></hr>
          <div className="selectDate ">
            <label className="subtextDark_ mb-3 ">Select Date</label>
            <img className="calanderImgg" alt="" src={calendar_light} />
            <DatePicker
              className="customform-control"
              selected={valDate}
              minDate={moment().toDate()}
              onChange={(date) => setValDate(date)}
              placeholderText="Offer Validity Date"
            />
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-4">
        <div className="offerbutton">
          <button className="sendOfferBtn w-100" onClick={() => props.close()}>
            Back
          </button>
          <button
            className="staticBtn w-100"
            type="button"
            disabled={buttonLoading}
            onClick={(e) => updateOffer(e)}
          >
            {buttonLoading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <span>Update Offer</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default OfferEditModal;
