import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import { brandLogo } from '../../../utilities/images';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesAsync, getBrandOfCategoryAsync } from '../../containers/dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { selectLoginAuth } from '../../containers/auth/authSlice';

const ProductSidebar = (props) => {
    const [options_, setOptions] = useState([]);
    const [categoryLoading, setCategoryLoading] = useState(false)
    const [subCategoryLoading, setSubCategoryLoading] = useState(false)
    const [brandLoading, setBrandLoading] = useState(false)
    const [subCategoryIds, setSubCategoryIds] = useState([])
    const [category, setCategory] = useState([])
    const [categoryId, setCategoryId] = useState([])
    const [subCategoryId, setSubCategoryId] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [brand, setBrand] = useState([])
    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState("")
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const history = useHistory()
    
    const closeSide = () => {
        props.closeNav();
        document.body.setAttribute("style", "overflow:auto");
    }
    const closeActions = () => {
        document.body.setAttribute("style", "overflow:auto");
    }

    const handleSearch = (value) => {
        setLoading(true);
        const results = value ? brand.filter(w => w.value.toLowerCase().includes(value)) : []
        setTimeout(r => { setOptions(results); setLoading(false) }, 400, results)
    }

    const handleSelect = (e) => {
        let allIds = e.map((v) => {
            return v.id
        })
        props.brandId(allIds)
    }

    const handleOptionValue = (e) => {
        return (
            <>
                {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCHkgJPx9vKxK-UV2Oqs_FaBstYEz0JqBb4OXGemg&s" alt="" height="50px" width="50px"/> */}
                {e}
            </>
        )
    }
    const getAllCategory = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                main_category: true,
                check_product_existance: false,
                service_type:"product"
            }
        }
        setCategoryLoading(true)
        dispatch(getCategoriesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setCategoryLoading(false);
                setCategory(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setCategoryLoading(false)
            })
    }

    const handleCategoryChange = (categoryId) => {
        setCategoryId(categoryId)
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                category_id: categoryId,
                need_subcategory: true,
                check_product_existance: false
            }
        }
        setSubCategoryLoading(true)
        dispatch(getCategoriesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setSubCategoryLoading(false);
                setSubCategory(obj?.payload?.data);
            }
            )
            .catch((obj) => {
                setSubCategoryLoading(false)
            })
    }

    const handleSubcategoryChange = (subCategoryId) => {
        setSubCategoryId(subCategoryId)
        let filterData = [...subCategoryIds]
        if (filterData?.includes(subCategoryId)) {
            filterData = filterData?.filter(val => val != subCategoryId)
            setSubCategoryIds(filterData)
        }
        else {
            filterData = [...subCategoryIds, subCategoryId]
            setSubCategoryIds(filterData)
        }
        props.subCategoryId(filterData)
    }

    const allBrandData = () => {
        let data = {
            tokenData: auth?.payload?.token,
            check_product_existance: false
        }
        setBrandLoading(true)
        dispatch(getBrandOfCategoryAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setBrandLoading(false);
                const modifiedData = obj.payload.data.map((item) => ({
                    value: item.name,
                    id: item.id
                    // Add more key modifications as needed
                }));
                setBrand(modifiedData);
                // setBrand(obj?.payload?.data);
            }
            )
            .catch((obj) => {
                setBrandLoading(false)
            })
    }

    // const handleBrandChange = () => {
    //     let filterData = [...brandIds]
    //     if (filterData?.includes(brandId)) {
    //         filterData = filterData?.filter(val => val != brandId)
    //         console.log(brandId, filterData?.filter(val => val != brandId), "filter value")
    //         setBrandIds(filterData)
    //     }
    //     else {
    //         filterData = [...brandId, brandId]
    //         setBrandIds(filterData)
    //     }
    //     props.brandId(filterData)
    // }
    useEffect(() => {
        getAllCategory()
        allBrandData()
    }, [])

    return (
        <div className='productSidebar'>
            <aside className={`vertical-tab ${props?.nav?.open_nav ? 'slideOpen' : ''}`} id="myNav">
                {/* <Link className="closebtn2" to="#" onClick={() => {
                    closeSide(); closeActions()
                }} ><i className="las la-times"></i></Link> */}
                <Link to="/wallet" className="brandLogo">
                    <img src={brandLogo} className="img-fluid" alt="" />
                </Link>
                <div className='sidebarMenus navbar_overlay_content_'>
                    <Accordion defaultActiveKey="0">
                        {
                            category?.length > 0 ?
                                <>
                                    {
                                        category?.map((val, index) => {
                                            return (
                                                <Accordion.Item eventKey={val?.id} key={index} >
                                                    <Accordion.Header className={activeData == val?.name ? "active" : ""} onClick={() => { setActiveData(val?.name); handleCategoryChange(val?.id) }}>
                                                        <span>
                                                            {/* <i className="las la-angle-left me-1 directionArrow"></i> */}
                                                            {/* <i class="fa-solid fa-plus"></i> */}
                                                        </span>
                                                        {val?.name}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        {
                                                            subCategoryLoading ?
                                                                <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList"></span>
                                                                :
                                                                <>
                                                                    {
                                                                        subCategory?.length > 0 ?
                                                                            <>
                                                                                <ul className='accordionInnerMenus'>
                                                                                    {
                                                                                        subCategory?.map((val, index) => {
                                                                                            return (
                                                                                                <li className='accordionInnerItems' key={index}>
                                                                                                    <Link to='#' className={`accordionInnerLinks ${subCategoryIds?.includes(val?.id) ? "active" : ""}`} onClick={() => { setActiveData(val?.name); handleSubcategoryChange(val?.id) }}>
                                                                                                        <span className='beforeDot'></span>
                                                                                                        {val?.name}
                                                                                                    </Link>
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </> :
                                                                            <></>
                                                                    }
                                                                </>
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </>
                                :
                                <></>
                        }
                        {/* <Accordion.Item eventKey='2' >
                            <Accordion.Header>
                                Brand
                            </Accordion.Header>
                            <Accordion.Body className='p-0'>
                                <div className='multiSelectContainerArea'>
                                    <Multiselect
                                        className='brandFilter'
                                        displayValue="value"
                                        options={brand}
                                        onSearch={handleSearch}
                                        loading={loading}
                                        isObject={true}
                                        placeholder='Search here'
                                        hidePlaceholder={false}
                                        // selectionLimit={1}
                                        onSelect={handleSelect}
                                        onRemove={handleSelect}
                                        optionValueDecorator={(e) => handleOptionValue(e)}
                                        selectedValueDecorator={(e) => handleOptionValue(e)}
                                        avoidHighlightFirstOption={true}
                                        showCheckbox
                                    />
                                    <img src={search_light} alt='searchIcon' className='searchBarIcon' />
                                </div>
                            </Accordion.Body>
                        </Accordion.Item> */}
                        {/* <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                Price
                            </Accordion.Header>
                            <Accordion.Body>
                                No Price Filter available.
                            </Accordion.Body>
                        </Accordion.Item> */}

                    </Accordion>
                </div>
            </aside>
        </div>
    )
}

export default ProductSidebar