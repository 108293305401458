import React, { useState } from 'react'
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import { calendar } from '../../../../../utilities/images'

const NewinventoryModal = () => {
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    return (
        <div className='newinventoryModal commonPadding_'>
            <div className='quantityTop_'>
                <div className='selectLeft'>
                    <h5 className='lightgreyHeading_ mb-2'>Selected Product:</h5>
                    <h5 className='modalHeading'>Aromas de San Andrés</h5>
                </div>
                <div className='selectRight'>
                    <h5 className='subheadBlue'>Quantity Available</h5>
                    <h4 className='modalHeadingGrey'>15036</h4>
                </div>
            </div>
            <div className='form-Inventory_ mt-3'>
                <form className="form_horizontal">
                    <div className='form-group mb-3'>
                        <div className='row'>
                            <div className='leftLabel col-md-4'>
                                <label className="subtextDark_ ">Mode of adjustment</label>
                            </div>
                            <div className='rightLabel col-md-8'>
                                <select className="customform-control" required>
                                    <option value="" selected>
                                        Input box label
                                    </option>
                                    <option>Category1</option>
                                    <option>Category2</option>
                                </select>

                            </div>
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <div className='row'>
                            <div className='leftLabel col-md-4'>
                                <label className="subtextDark_ ">Reference Number</label>
                            </div>
                            <div className='rightLabel col-md-8'>
                                <input
                                    type="text"
                                    className="customform-control"
                                    name="text"
                                    placeholder="#"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <div className='row'>
                            <div className='leftLabel col-md-4'>
                                <label className="subtextDark_ ">Date</label>
                            </div>
                            <div className='rightLabel col-md-8'>
                                <div className="calenderIcon">
                                    <img src={calendar} alt="calender" />
                                    {/* <DatePicker className='customform-control' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="MM/dd/yyyy" /> */}
                                    <DatePicker
                                            className='cateDate'
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            placeholderText="Date"
                                        />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <div className='row'>
                            <div className='leftLabel col-md-4'>
                                <label className="subtextDark_ ">Reason</label>
                            </div>
                            <div className='rightLabel col-md-8'>
                                <select className="customform-control" required>
                                    <option value="" selected>
                                        Select one
                                    </option>
                                    <option>Category1</option>
                                    <option>Category2</option>
                                </select>

                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <div className='quantity_add'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='quantityBox_'>
                            <h5 className='buyHeading'>Quantity Adjusted</h5>
                            <input
                                type="text"
                                className="customform-control inventoryInput"
                                name="text"
                                placeholder=""
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='quantityBox_'>
                            <h5 className='blacksemiHead_'>New Quantity On habd</h5>
                            <h4 className='blueheadbold_'>0</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='inventButton mt-5'>
                <button className='declineBtn me-3'>Cancel</button>
                <button className='acceptBtn_'>Adjust now</button>
            </div>
        </div>
    )
}

export default NewinventoryModal