import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import moment from 'moment-timezone'
import { Link  } from 'react-router-dom'
import { calendar, user } from '../../../../utilities/images'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import products from "../../../db/category.json"
import CustomModal from '../../../components/shared/CustomModal';
import AddEmployeeModal from './addEmployeeModal.js';

const SystemConfig = () => {
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [key, setKey] = useState(Math.random());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const [modalDetail, setModalDetail] = useState({
      show: false,
      title: "",
      flag: "",
    });
    const handleOnCloseModal = () => {
      setModalDetail({
        show: false,
        title: "",
        flag: "",
      });
      setKey(Math.random());
    };
      const history = useHistory()
      const columns1 = [
        {
          name: '#',
          selector: row => row.id,
          width: "50px" 
        },
        {
          name: "Name",
          selector: row => {
          return (
                <>
                  <div className='d-flex align-items-center'>
                    <img className='userInnerImg' src={user} />
                    <div className='ms-2'>
                      <span className='textParagh '>Teri J Henderson</span>
                    </div>
                  </div>
                </>
              );
          },
          width: "200px", 
        },
        { name: 'Role',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Sales Manager</span>
                </>
              );
          },
        },
       
        {
          name: 'Date',
          selector: row => {
            return (
                  <>
                    <span className='textParagh '>17 Jun, 2020</span>
                  </>
                );
            },
        },
        
        {
          selector: row => {
            return (
                  <>
                    <span className='textParagh '>38</span>
                  </>
                );
            },
          name: 'Age'
        },
        {
          selector: row => {
            return (
                  <>
                    <span className='textParagh '>Female</span>
                  </>
                );
            },
          name: 'Gender',
        },
        {
          name: 'Status',
          selector: row => {
            return (
                  <>
                    <div className='ms-2 tableBtn__'>
                        <Link to="#" className='greenBtn_'>Active</Link>
                        {/* <i className="fal fa-chevron-right ms-3" ></i>  */}
                    </div>
                  </>
                );
            },
        },
        {
          name: 'Action',
          selector: row => {
            return (
                  <>
                  <div className='d-flex align-items-center'>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                    </div>
                  </div>
                  </>
                );
            },
            width: "120px",
        },
        {
          name: '',
          selector: row => {
            return (
                  <>
                  <div className='logsign_' onClick={() => history.push('/systemEmployeHistory')}>
                    <i className="fal fa-chevron-right cursor" ></i> 
                  </div>

                  </>
                );
            },
            width: "50px",
        },
      ];
    
      const handleRowClick = (e) => {
          // console.log("rowclicked", e)
          // history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
      }

  return (
    <div className='systemConfig'>
        <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>System Config / <span className="innerSubtext">Employee</span> </span>
                            </h2>
                        </div>
                        <div className="col-md-6 text-end">
                            <button className='backgroundFillbtn' onClick={() => {
                    setModalDetail({ show: true, flag: "AddNewEmplyoee" });
                    setKey(Math.random());
                  }}> <span className='plusIcon_'><i className="fa-solid fa-plus"></i> </span> Add Employee</button>
                        </div>
                    </div>
                </div>
        </div>
        <div className='tableFilters mt-4 mb-3'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="calenderIcon">
                        <img src={calendar} alt="calender" />
                        {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                            dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                        <DatePicker
                                className='cateDate'
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText="Date"
                            />
                    </div>
                </div>
                {/* <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                            Country
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                            State
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                            Id type
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div> */}
            </div>
        </div>
        <DataTable
          pagination
          columns={columns1}
          data={products}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "AddNewEmplyoee" ? "commonWidth customContent" : ""}
          ids={modalDetail.flag === "AddNewEmplyoee" ? "addNewEmplyoee" : ""}
          // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
          child={
            modalDetail.flag === "AddNewEmplyoee" ? (
              <AddEmployeeModal close={() => handleOnCloseModal()} />
            ) : (
              ""
            )
          }
          header={
            <div className='modalHeader_'>
              <div className="common_">
                  <h2 className="modalHeading">
                      Add New Employee Details
                  </h2>
                  <button className="closeButton">
                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                  </button>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
    </div>
  )
}

export default SystemConfig