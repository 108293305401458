import React from 'react'
import { Link } from 'react-router-dom';
import {  cashout, shield, Phoneimg, locationimg, } from '../../../../utilities/images'

const WalletpaymentModal = () => {

    return (
        <div className='walletPayment'>
            <div className="commonModal">
                <div className='commonBody p-0'>
                    <div className='subHeader'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="subheaderInnerwallet">
                                    <h5 className='subhaedHeading'>Sender</h5>
                                    <h6 className='subheaderHeading'>Abdullah Shafique</h6>
                                    <p className='contentSubtextbold'>
                                        <img src={shield} />
                                        <span> 4703-515-1193</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={Phoneimg} />
                                        <span>  vidal.webe2@gmail.com</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={locationimg} />
                                        <span>2061 Grand Avenue Orlando, FL 32801</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="subheaderInnerwallet">
                                    <h5 className='subhaedHeading'>Receiver</h5>
                                    <h6 className='subheaderHeading'>Abdullah Shafique</h6>
                                    <p className='contentSubtextbold'>
                                        <img src={shield} /> <span>4703-515-1193</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={Phoneimg} /> <span>vidal.webe2@gmail.com</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={locationimg} />
                                        <span>2061 Grand Avenue Orlando, FL 32801</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modalContent">
                        <div className='amountDeatils_'>
                            <h3 className='mainbluekHead_'>JBR $100.00</h3>
                            <h5 className='mainlightHead'>Token Amount</h5>
                            <img className='walletadd' src={cashout} />

                        </div>
                        <div className='bankInformation'>
                            <p className='infoHeading'><span className='bankheadInnr'>Transaction ID</span> <span className='haedingsemiBold_'>288224966</span></p>
                            <p className='infoHeading'><span className='bankheadInnr'>Date & Time</span> <span className='haedingsemiBold_'>Sep 13, 2022 7:50 PM</span></p>
                        </div>
                        <div className='transactionStatus'>
                            <p className='mainlightHead'>Transaction Status</p>
                            <Link to="#" className='innerBtn_'>Completed</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default WalletpaymentModal
