import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { delivery, dropOff, locationPin, pickup, shield, shipping, businesslocation, store, setLocation, dotsinbox } from '../../../../utilities/images'
import CustomModal from '../../../components/shared/CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import CustomLoader from '../../../components/shared/CustomLoader'
import { toast } from 'react-toastify'
import { updateSellerInfoApiAsync } from '../../dashboard/dashboardSlice'
import { deleteAddressApiAsync, getAddressApiAsync, selectAddressList, selectLoginAuth } from '../../auth/authSlice'
import Swal from 'sweetalert2';
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import DeliveryAddressModal from '../../dashboard/wallet/deliveryAddressModal'
import ShippingModal from '../../dashboard/wallet/shippingModal'
import StoreAddressModal from '../../dashboard/wallet/storeAddressModal'
import SetUpAddressModal from '../../dashboard/wallet/setUpAddressModal'
import AddAddressModal from '../../dashboard/wallet/addAddressModal'
import AddaddressModal from "../../systemConfig/locations/addaddressModal"
import EditaddressModal from './editaddressModal'
import DefaultaddressModal from './defaultaddressModal'
import DeleteaddressModal from './deleteaddressModal'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'


const Locations = () => {
    const location = useLocation();
    const pathname = window.location.pathname;
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
        defaultAddress: false
    });
    const addressList = useSelector(selectAddressList)
    const [addressId, setAddressId] = useState("")
    const [addressIdModal, setAddressIdModal] = useState("")
    const [addressDataModal, setAddressDataModal] = useState("")
    const [addressData, setAddressData] = useState("")
    const [loading, setLoading] = useState(false)
    const [addressType, setAddressType] = useState("")
    const [addressTypeModal, setAddressTypeModal] = useState("")
    const auth = useSelector(selectLoginAuth)
    const toastId = React.useRef(null)
    const dispatch = useDispatch()

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
            , defaultAddress: false
        });
        setKey(Math.random());
    }
    const history = useHistory('')

    const handleChange = (id, flag, status) => {
        let params = {
            "postData": {
                "id": id,
                [flag]: status == true ? "0" : "1",
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(updateSellerInfoApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let params = {
                    token: auth?.payload?.token,
                    sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                }
                dispatch(getAddressApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getAddress = () => {
        let params = {
            token: auth?.payload?.token,
            sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
        }
        dispatch(getAddressApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }
    const handleDeleteAddress = (id, flag) => {
        if (flag === true) {
            setModalDetail({ show: true, flag: "DeleteaddressModal" })
            setKey(Math.random())
            return
        }
        Swal.fire({
            title: 'Do you want to delete the address?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    "postData": {
                        "id": id,
                    },
                    "tokenData": auth?.payload?.token
                }
                setLoading(true)
                dispatch(deleteAddressApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false)
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.success(obj?.msg)
                        }
                        getAddress();
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Business Locations";
    }, []);

    const handleEditAddress = (id, flag, data) => {
        setAddressId(id)
        setModalDetail({ show: true, flag: "AddAddress", defaultAddress: false });
        setKey(Math.random());
        setAddressType(flag)
        setAddressData(data)
    }

    const getAddressMerchant = () => {
        setLoading(true)
        let params = {
            token: auth?.payload?.token,
            sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
        }
        dispatch(getAddressApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getAddressMerchant()
    }, [])
    return (
        <>

            <DashboardNavbar title={location?.state === "plans" ? "Setup Address" : "Business Locations"} backShow={location?.state === "plans" ? `/plans` : false} show={true} />


            <button className="webPosBtn m-2" type="button" onClick={() => {
                setModalDetail({ show: true, flag: "add" });
                setKey(Math.random());
            }}>
                Add Address
            </button>

            <div className='setupAddress setAdressbussiness businessLOcation'>
                {
                    addressList?.payload?.map((val, index) => {
                        console.log(val.is_default, "Default Address")
                        return (
                            <React.Fragment key={index}>
                                <div className='paymentContainer'>
                                    <div className='paymentArea'>
                                        <div className='row mb-4'>
                                            <div className='col-md-12'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='setLocationImg me-3 '><img src={businesslocation} className='img-fluid' /></div>
                                                    <div className='w-100'>
                                                        <h3 className='textInnerDark'>Business Locations </h3>
                                                        <p className='subtext_ mt-2'>An extra layer to boost your team members account security. A verification code will be required in addition to password each time you sign in.</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='paymentArea bussinessPayment ms-5'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='d-flex align-items-start'>
                                                        <div className='setLocationImg me-3 '><img src={setLocation} className='img-fluid' /></div>
                                                        <div className='w-100'>
                                                            <h3 className='textInnerDark'>{val?.address_type == "ware_house" ? "ware house" : "store"}</h3>
                                                            <p className='subtext_ mt-2'>{val?.format_address}</p>
                                                        </div>
                                                        <div className='dropdown typeBtn_'>
                                                            <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                                                <img className='' src={dotsinbox} />
                                                            </Link>
                                                            <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton2" >
                                                                <li ><Link to="#" className="dropdown-item chatdropItem " onClick={() => { setModalDetail({ show: true, flag: val.is_default ? "DefaultaddressModal" : "EditaddressModal", defaultAddress: val.is_default }); setKey(Math.random()); setAddressTypeModal("JOBR Delivery"); setAddressIdModal(val?.id); setAddressDataModal(val) }}>
                                                                    <span className='subtextSmall_'>Edit</span></Link>
                                                                </li>
                                                                <li ><Link to="#" className="dropdown-item chatdropItem">
                                                                    <span className='subtextSmall_' onClick={() => { handleDeleteAddress(val.id, val.is_default) }}>Delete</span></Link>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='fourPaymentArea'>
                                                    {/* Local Pickup */}
                                                    <div className='paymentArea'>
                                                        <div className='row'>
                                                            <div onClick={() => { setModalDetail({ show: true, flag: "setUpAddress" }); setKey(Math.random()); setAddressTypeModal("Location: Local Pickup"); setAddressIdModal(val?.id); setAddressDataModal(val) }} className='col-md-10'>
                                                                <div className='d-flex align-items-start'>
                                                                    <img src={pickup} className='paymentTypeIcon me-3' />
                                                                    <div className='w-100'>
                                                                        <div style={{ cursor: "pointer" }} key={index}>
                                                                            <div className='row'>
                                                                                <div className='col-md-10' >
                                                                                    <h3 className='textInnerDark'>Local Pickup</h3>
                                                                                    <p className='subtext_ mt-2'>This location(s) will allow Customer Pickups, Driver Pickups, Shipping Pickups</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2 text-end'>
                                                                <div className="form-check form-switch">
                                                                    <input type="checkbox"
                                                                        checked={val?.allow_local_pickup}
                                                                        onChange={(e) => { handleChange(val?.id, "allow_local_pickup", val?.allow_local_pickup) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* JOBR Delivery */}
                                                    <div className='paymentArea'>
                                                        <div className='row'>
                                                            <div className='col-md-10'>
                                                                <div className='d-flex align-items-start'>
                                                                    <img src={delivery} className='paymentTypeIcon me-3' />
                                                                    <div className='w-100'>
                                                                        <div style={{ cursor: "pointer" }} key={index}>
                                                                            <div className='row'>
                                                                                <div className='col-md-10'
                                                                                    onClick={() => { setModalDetail({ show: true, flag: "deliveryModal", defaultAddress: false }); setKey(Math.random()); setAddressTypeModal("Location: JOBR Delivery"); setAddressIdModal(val?.id); setAddressDataModal(val) }}>
                                                                                    <h3 className='textInnerDark'>JOBR Delivery</h3>
                                                                                    <p className='subtext_ mt-2'>This location(s) will allow Customer Pickups, Driver Pickups, Shipping Pickups</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-md-2 text-end'>
                                                                <div className="form-check form-switch">
                                                                    <input type="checkbox"
                                                                        checked={val?.allow_jobr_delivery}
                                                                        onChange={(e) => { handleChange(val?.id, "allow_jobr_delivery", val?.allow_jobr_delivery) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*  Local Drop off */}
                                                    <div className='paymentArea'>
                                                        <div className='row'>
                                                            <div className='col-md-10'>
                                                                <div className='d-flex align-items-start'>
                                                                    <img src={dropOff} className='paymentTypeIcon me-3' />
                                                                    <div className='w-100'>
                                                                        <div style={{ cursor: "pointer" }} key={index}>
                                                                            <div className='row'>
                                                                                <div className='col-md-9' onClick={() => { setModalDetail({ show: true, flag: "storeModal", defaultAddress: false }); setKey(Math.random()); setAddressTypeModal("Local Drop-off"); setAddressIdModal(val?.id); setAddressDataModal(val) }}>
                                                                                    <h3 className='textInnerDark'>Local Drop-off</h3>
                                                                                    <p className='subtext_ mt-2'>This location(s) will allow Customer Pickups, Driver Pickups, Shipping Pickups</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2 text-end'>
                                                                <div className="form-check form-switch">
                                                                    <input type="checkbox"
                                                                        checked={val?.allow_local_drop_off}
                                                                        onChange={(e) => { handleChange(val?.id, "allow_local_drop_off", val?.allow_local_drop_off) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Shipping */}
                                                    <div className='paymentArea'>
                                                        <div className='row'>
                                                            <div className='col-md-10'>
                                                                <div className='d-flex align-items-start'>
                                                                    <img src={shipping} className='paymentTypeIcon me-3' />
                                                                    <div className='w-100'>
                                                                        <div style={{ cursor: "pointer" }} key={index}>
                                                                            <div className='row'>
                                                                                <div className='col-md-9' onClick={() => { setModalDetail({ show: true, flag: "shippingModal", defaultAddress: false }); setKey(Math.random()); setAddressTypeModal("Shipping"); setAddressIdModal(val?.id); setAddressDataModal(val) }}>
                                                                                    <h3 className='textInnerDark'>Shipping</h3>
                                                                                    <p className='subtext_ mt-2'>This location(s) will allow Customer Pickups, Driver Pickups, Shipping Pickups</p>
                                                                                    <p className='subtext_ mt-2'> {val?.isShippingConnected ? <>
                                                                                        Shipping Connected
                                                                                    </> : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2 text-end'>
                                                                <div className="form-check form-switch">
                                                                    <input type="checkbox"
                                                                        checked={val?.allow_shipping}
                                                                        onChange={(e) => { handleChange(val?.id, "allow_shipping", val?.allow_shipping) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })
                }
                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    // backdrop="static"
                    showCloseBtn={false}
                    isRightSideModal={false}
                    mediumWidth={false}
                    ids={modalDetail.flag === "AddAddress" ? "addAddress" : modalDetail.flag === 'setUpAddress' ? "SetUpAddress" : modalDetail.flag === 'storeModal' ? "StoreModal" : modalDetail.flag === 'shippingModal' ? "ShippingModal" : modalDetail.flag === 'deliveryModal' ? "DeliveryModal"
                        : modalDetail.flag === "EditaddrressModal" ? "editaddressModal" : modalDetail.flag === "add"
                        ? "addaddressModal" : ""}
                    size={modalDetail.flag === "setUpAddress" || modalDetail.flag === 'storeModal' || modalDetail.flag === 'shippingModal' || modalDetail.flag === 'deliveryModal' ? "xl" : modalDetail?.flag == "add" ? "md": ""}
                    child={
                        modalDetail?.flag === "add" ? <AddaddressModal close={() => handleOnCloseModal()} flag={pathname}/>:
                        modalDetail.flag === 'AddAddress' ? <AddAddressModal close={() => handleOnCloseModal()} addressId={addressId} addressType={addressType} addressData={addressData} />
                        : modalDetail.flag === 'setUpAddress' ? <SetUpAddressModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} /> :
                            modalDetail.flag === 'storeModal' ? <StoreAddressModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} /> :
                                modalDetail.flag === 'shippingModal' ? <ShippingModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} /> :
                                    modalDetail.flag === 'deliveryModal' ? <DeliveryAddressModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} /> :
                                        modalDetail.flag === "DeleteaddressModal" ? <DeleteaddressModal close={() => handleOnCloseModal()} /> :
                                            modalDetail.flag === 'DefaultaddressModal' && modalDetail.defaultAddress === true ? <DefaultaddressModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} /> : <EditaddressModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} />
                    }
                    header={modalDetail.flag === 'AddAddress'
                        ?
                        <div className="modalHeader_">
                            <div className="common_">
                                <h3 className='modalHeading'>Edit Address</h3>

                                <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                    <i className="las la-times" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div> :
                        modalDetail.flag === 'DeleteaddressModal'
                            ?
                            <div className="modalHeader_">
                                <div className="common_">

                                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                        <i className="las la-times" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div> :
                            modalDetail.flag === "EditaddressModal" || modalDetail.flag === "DefaultaddressModal" ? false :

                            modalDetail?.flag == "add" ? <></>:
                                <>
                                    <div className="modalHeader_">
                                        <div className="common_">
                                            <h3 className='modalHeading_'>{addressTypeModal}</h3>

                                            <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                                <i className="las la-times" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </>
                    }

                    onCloseModal={() => handleOnCloseModal()}
                />

            </div>
        </>
    )
}
export default Locations