import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { selectLoginAuth } from '../../auth/authSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { dotsinbox, filestore, imageImg1, importIcon, pdfImg1, removeIcon, renameIcon, viewIcon, wordIcon } from '../../../../utilities/images'
import moment from 'moment-timezone'
import DataTable from 'react-data-table-component'
import { deleteFileInRecievedCatalogApiAsync, getSingleRecievedCatalogApiAsync } from '../../dashboard/dashboardSlice'
import ViewdocumentModal from './viewdocumentModal'
import CustomModal from '../../../components/shared/CustomModal'
import DashboardNavbar from '../../dashboard/DashboardNavbar'

const ReceievedFolderOpen = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [pdfCaption, setPdfCaption] = useState("")
    const [pdfUrl, setPdfUrl] = useState("")
    const { id } = useParams();
    const auth = useSelector(selectLoginAuth)
    const [loading, setLoading] = useState(false)
    const [folderData, setFolderData] = useState('');
    const [folderName, setFolderName] = useState('');
    const [mimeType, setMimeType] = useState('');

    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const handleViewClick = (e) => {
        setPdfCaption(e?.originalname)
        setPdfUrl(e?.filePath)
        setMimeType(e.mimeType?.split('/')[0])
        if (pdfCaption && pdfUrl) {
            setModalDetail({ show: true, flag: "ViewdocumentModal" });
            setKey(Math.random());
        }
    }
    const columns1 = [

        {
            name: "File Name",
            width: "350px",
            selector: row => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.media_url?.mimeType?.split('/')[0] === "image" ? imageImg1 : row?.media_url?.mimeType?.split('/')[1] === "pdf" ? pdfImg1 : wordIcon} />
                            <div className='ms-2'>
                                <span className='textParagh '>{row?.media_url?.originalname?.length > 15 ? row?.media_url?.originalname?.substring(0, 15) + "..." : row?.media_url?.originalname}</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            name: 'Folder',
            width: '250px',
            selector: row => {
                return (
                    <>
                        <div className='text-start'>
                            <span className='textParagh '> <img className='doctabfile' src={filestore} />{folderData?.folder_name?.length > 15 ? folderData?.folder_name?.substring(0, 15) + "..." : folderData?.folder_name}</span>
                        </div>
                    </>
                );
            },
        },

        {
            name: 'Date',
            width: '250px',
            selector: row => {
                return (
                    <>
                        <div className='text-start'>
                            <span className='textParagh '>{moment(row?.date).format("DD MMM, YYYY | H : mm")}</span>
                        </div>
                    </>
                );
            },
        },


        {
            name: 'File Size',
            selector: row => ((row?.media_url?.size) / (1024 * 1024)).toFixed(2) + "MB",
            width: '150px',

        },

        {
            name: '',
            selector: row => {
                return (
                    <>
                        <div className='dropdown typeBtn_ text-end'>
                            <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                <img className='dotIconn_' src={dotsinbox} alt='' />
                            </Link>
                            <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton2" >
                                <li onClick={() => {

                                    handleViewClick(row?.media_url)

                                }}><Link to="#" className="dropdown-item chatdropItem">
                                        <img className='dropIcon' src={viewIcon} alt='' />
                                        <span className='subtextSmall_'>View</span></Link>
                                </li>
                                {/* <li onClick={() => { setModalDetail({ show: true, flag: "CreatefolderModal" }); setKey(Math.random()); setFlag("renameCatalogInFolder"); setRenameCatalogId(row?.id); setOldName(row?.caption) }}><Link to="#" className="dropdown-item chatdropItem">
                                    <img className='dropIcon' src={renameIcon} />
                                    <span className='subtextSmall_'>Rename</span></Link>
                                </li> */}
                                <li onClick={() => handleRemoveCatalog(row?.media_url?._id)}><Link to="#" className="dropdown-item chatdropItem" >
                                    <img className='dropIcon' src={removeIcon} />
                                    <span className='subtextSmall_'>Remove</span></Link>
                                </li>
                                <li>
                                    <a target='_blank' className="dropdown-item chatdropItem" href={row?.media_url?.filePath} download>
                                        <img className='dropIcon' src={importIcon} />
                                        <span className='subtextSmall_'>Download</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </>
                );
            },
        },
    ];

    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    }

    const handleRemoveCatalog = (fileId) => {
        let params = {
            "postData": {
                sender_id: id,
                file_id: fileId
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(deleteFileInRecievedCatalogApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let data = {
                    'id': id,
                    "tokenData": auth?.payload?.token
                }
                dispatch(getSingleRecievedCatalogApiAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        setFolderData(obj?.payload[0])
                        setLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const singleFolderData = () => {
        let params = {
            'id': id,
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getSingleRecievedCatalogApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setFolderData(obj?.payload[0])
                setFolderName(obj?.payload ? obj?.payload[0]?.folder_name : "")
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        singleFolderData()
    }, [])

    return (
        <>
            <div className='folderOpen_'>
                <DashboardNavbar title={'Folder' + " / " + folderName} backShow={`/catalogs`} />
                <section className='table-responsive catalogTable commonTableheight tableSectionBackground'>
                    <DataTable
                        pagination
                        columns={columns1}
                        data={folderData?.files}
                        defaultSortFieldId={1}
                        onRowClicked={handleRowClick}
                        progressPending={loading}
                    // paginationServer
                    // paginationTotalRows={totalRows}
                    // paginationPerPage={perPage}
                    // onChangePage={handlePageChange}
                    // onChangeRowsPerPage={handleRowsPerPageChange}
                    /></section>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "CreatefolderModal" ? "commonWidth customContent" : modalDetail.flag === "FileUpload" ? "commonWidth customContent" : modalDetail.flag === "ViewdocumentModal" ? "viewdocumentModal" : ""}
                ids={modalDetail.flag === "ViewdocumentModal" ? "viewdocumentModal" : ""
                }

                child={
                    // modalDetail.flag === "CreatefolderModal" ? (
                    //     <CreatefolderModal oldName={oldName} loadingState={loading1} flag={flag} renameCatalogInFolder={(e) => handleRenameCatalogInFolder(e)} renameFolderInFolder={(e) => handleRenameFolderInFolder(e)} close={() => handleOnCloseModal()} />
                    // ) :
                    //     modalDetail.flag === "FileUpload" ? (
                    //         <FileuploadModal uploadHandleInCatelog={(e) => uploadFileHandle(e)} flag={"catalog"} close={() => handleOnCloseModal()} />
                    //     )
                    //         :
                    modalDetail.flag === "ViewdocumentModal" ? (
                        <ViewdocumentModal close={() => handleOnCloseModal()} mimeType={mimeType} pdfCaption={pdfCaption} pdfUrl={pdfUrl} />
                    )
                        :
                        (
                            ""
                        )
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {
                                // modalDetail.flag === "CreatefolderModal" ?
                                //     <div className='anotherHeader'>
                                //         <h2 className="innerSubheadNormal">{flag === 'renameFolderInFolder' ? 'Rename Folder' : flag === 'renameCatalogInFolder' ? 'Rename Catalog' : ''}</h2>
                                //     </div>
                                //     :
                                //     modalDetail.flag === "FileUpload" ?
                                //         <h2 className="modalHeading">
                                //             File Upload
                                //         </h2>
                                //         :
                                modalDetail.flag === "ViewdocumentModal" ?
                                    <h2 className="modalHeading">
                                        {pdfCaption}
                                    </h2>
                                    :
                                    ""
                            }
                            <button className="closeButton">
                                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                            </button>
                        </div>
                    </div>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default ReceievedFolderOpen
