import React, { useEffect, useState } from "react";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import {
  barcode,
  brandLogo,
  map,
  pickuporder,
  OrderDown,
  fillradio_,
  unselectedOrder,
  verifyorder,
  cancleorder,
  returnlogo,
} from "../../../../utilities/images";
import { getOrderDetailByIdAsync } from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment-timezone";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { getAddressFromCoordinates } from "../../auth/formCordinates";

const OrderTrack = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const auth = useSelector(selectLoginAuth);
  const [driverLoc, setDriverLoc] = useState("");
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [driverDistance, setDriverDistance] = useState("");
  const [driverDuration, setDriverDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState("");
  const locationData = orderData;
  const [mapStatus, setMapStatus] = useState(false);

  const pickupLatitude = parseFloat(
    orderData?.order_delivery?.order_pickup_latitude ?? 0.0
  );
  const pickupLongitude = parseFloat(
    orderData?.order_delivery?.order_pickup_longitude ?? 0.0
  );
  const deliverLatitude = parseFloat(
    orderData?.order_delivery?.order_delivery_latitude ?? 0.0
  );
  const deliverLongitude = parseFloat(
    orderData?.order_delivery?.order_delivery_longitude ?? 0.0
  );
  const center = { lat: pickupLatitude, lng: pickupLongitude };
  const origin = { lat: pickupLatitude, lng: pickupLongitude };
  const Destination = { lat: deliverLatitude, lng: deliverLongitude };

  const directionsOptions = {
    destination: Destination,
    origin: origin,
    travelMode: "DRIVING",
  };
  const directionsCallback = (response) => {
    if (response !== null && map !== null) {
      if (response.status === "OK" && mapStatus == false) {
        setMapStatus(true);
        setDirectionsResponse(response);
        console.log("called");
      } else {
        console.error("Directions request failed:", response);
        setMapStatus(true);
      }
    }
  };

  const geocodeHandler = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      getAddressFromCoordinates(
        position.coords.latitude,
        position.coords.longitude
      )
        .then((data) => {
          setDriverLoc(data?.formatted_address);
        })
        .catch((err) => console.log("error getting address", err));
    });
  };
  async function calculateDriverRoute() {
    if (driverLoc === "" || locationData?.address_2 === "") {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: driverLoc,
      destination: locationData?.address_2,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDriverDistance(results.routes[0].legs[0].distance.text);
    setDriverDuration(results.routes[0].legs[0].duration.text);
  }
  const getOrderDataById = () => {
    let data = {
      id: id,
      token: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getOrderDetailByIdAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        console.log("objjjjjjjjjjjj", obj?.payload);
        setOrderData(obj?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getOrderDataById();
    geocodeHandler();
    calculateDriverRoute();
  }, []);
  return (
    <div className="orderTrack">
      <DashboardNavbar
        title={`Orders#${
          loading
            ? ""
            : orderData?.invoices?.invoice_number
            ? orderData?.invoices?.invoice_number
            : orderData?.return_detail?.invoices?.invoice_number
        }`}
        backShow={`/allOrder`}
        show={true}
      />
      {loading ? (
        <span className="spinner-border inner-spin spinner-border-sm"></span>
      ) : (
        <div className="consumerOrder">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="walletPurchaseSection orderDetails_">
                  {orderData?.user_details ? (
                    <div className="walletAddress">
                      <h4 className="expectedHeading">
                        {orderData?.user_details?.firstname +
                          " " +
                          orderData?.user_details?.lastname}
                      </h4>
                      <p className="walletHeading mt-3">
                        {
                          orderData?.user_details?.current_address
                            ?.street_address
                        }
                      </p>
                      <h4 className="walletHeading mt-2">
                        {orderData?.user_details?.phone_number}
                      </h4>
                    </div>
                  ) : (
                    <div>Not Available</div>
                  )}
                  <div className="walletPurchaseTable">
                    {orderData?.order_details?.length > 0
                      ? orderData?.order_details?.map((val, i) => {
                          return (
                            <div key={i} className="purchaseTableSub">
                              <div className="walletFlex">
                                <h4 className="profileboldHead">{val?.qty}</h4>
                                <div className="orderDimension">
                                  <h4 className="orderDimensionText mb-1">
                                    {val?.product_name}
                                  </h4>
                                  {/* <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4> */}
                                </div>
                              </div>
                              <h4 className="profileboldHead">
                                $
                                {Number(
                                  val?.offer_price && val?.offer_price != 0
                                    ? val?.offer_price
                                    : val?.qty * val?.actual_price
                                )?.toFixed(2)}
                              </h4>
                            </div>
                          );
                        })
                      : orderData?.return_detail?.return_details?.map(
                          (val, i) => {
                            return (
                              <div key={i} className="purchaseTableSub">
                                <div className="walletFlex">
                                  <h4 className="profileboldHead">
                                    {val?.order_details?.qty}
                                  </h4>
                                  <div className="orderDimension">
                                    <h4 className="orderDimensionText mb-1">
                                      {val?.order_details?.product_name}
                                    </h4>
                                    {/* <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4> */}
                                  </div>
                                </div>
                                <h4 className="profileboldHead">
                                  $
                                  {Number(val?.order_details?.price)?.toFixed(
                                    2
                                  )}
                                </h4>
                              </div>
                            );
                          }
                        )}
                  </div>
                  <div className="totalPurchaseOrder">
                    <div className="totalOrderSub">
                      <h4 className="totalOrderText mb-0">Sub-Total</h4>
                      <h4 className="walletHeading mb-0">
                        ${Number(orderData?.actual_amount)?.toFixed(2)}
                      </h4>
                    </div>
                    <div className="totalOrderSub">
                      <h4 className="totalOrderText mb-0">
                        Discount{" "}
                        {orderData?.coupon_code ? orderData?.coupon_code : ""}
                      </h4>
                      <h4 className="walletHeading mb-0">
                        $
                        {orderData?.discount
                          ? Number(orderData?.discount)?.toFixed(2)
                          : "0.00"}
                      </h4>
                    </div>
                    {orderData?.delivery_option == 1 ||
                    orderData?.delivery_option == 4 ? (
                      <div className="totalOrderSub">
                        <h4 className="totalOrderText mb-0">
                          {orderData?.delivery_option == 4
                            ? "Shipping"
                            : "Delivery"}{" "}
                          Charge
                        </h4>
                        <h4 className="walletHeading mb-0">
                          $
                          {orderData?.delivery_option == 4
                            ? Number(orderData?.shipping_charge)?.toFixed(2)
                            : Number(orderData?.delivery_charge)?.toFixed(2)}
                        </h4>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="totalOrderSub">
                      <h4 className="totalOrderText mb-0">Tips</h4>
                      <h4 className="walletHeading mb-0">
                        $
                        {orderData?.tips
                          ? Number(orderData?.tips)?.toFixed(2)
                          : "0.00"}
                      </h4>
                    </div>
                    <div className="totalOrderSub">
                      <h4 className="totalOrderText mb-0">Tax</h4>
                      <h4 className="walletHeading mb-0">
                        $
                        {orderData?.tax
                          ? Number(orderData?.tax)?.toFixed(2)
                          : "0.00"}
                      </h4>
                    </div>
                    <div className="totalOrderSub totalOrderMain ">
                      <h4 className="totalOrderText mb-0">Total</h4>
                      <h4 className="walletHeading mb-0">
                        ${Number(orderData?.payable_amount)?.toFixed(2)}
                      </h4>
                    </div>
                  </div>
                  <div className="paymentoptionSection">
                    <h4 className="profileboldHead">
                      Payment option:
                      <span className="headingjobr ms-2">
                        {orderData?.mode_of_payment === "card"
                          ? "Card"
                          : orderData?.mode_of_payment === "cash"
                          ? "Cash"
                          : orderData?.mode_of_payment === "jbr"
                          ? "JBR Wallet"
                          : "---"}
                      </span>
                    </h4>
                    <h4 className="subtextSmallblack_ ">
                      {moment(orderData?.created_at).format(
                        "dddd DD MMM, YYYY"
                      )}
                      <span className="subtextSmallblack_  ms-2">
                        {moment(orderData?.created_at).format("hh:mm a")}
                      </span>
                    </h4>
                    {orderData?.delivery_option == 2 ||
                    orderData?.delivery_option == 3 ? (
                      <h4 className="subtextSmallblack_ ">Walk-In</h4>
                    ) : (
                      <></>
                    )}
                    <h4 className="subtextSmallblack_ ">
                      Invoice No.. #
                      {orderData?.invoices?.invoice_number
                        ? orderData?.invoices?.invoice_number
                        : orderData?.return_detail?.invoices?.invoice_number}
                    </h4>
                    <h4 className="subtextSmallblack_ ">
                      POS No. #Front-{orderData?.pos_user_id?.slice(0, 4)}
                    </h4>
                    <h4 className="subtextSmallblack_ ">
                      User ID : ****
                      {orderData?.user_id?.slice(
                        orderData?.user_id?.length - 3,
                        orderData?.user_id?.length
                      )}
                    </h4>
                  </div>
                  <div className="walletfooterModal">
                    <h2 className="modalFooterText">Thank You</h2>
                    <img
                      src={
                        orderData?.invoices?.barcode
                          ? orderData?.invoices?.barcode
                          : orderData?.return_detail?.invoices?.barcode
                          ? orderData?.return_detail?.invoices?.barcode
                          : barcode
                      }
                      className="img-fluid"
                      alt="barcodeImage"
                    />
                    <img
                      src={brandLogo}
                      className="img-fluid brandImg_"
                      alt="barcodeImage"
                    />
                  </div>
                </div>
              </div>
              {orderData?.delivery_option == 1 ||
              orderData?.delivery_option == 4 ? (
                <div className="col-lg-8 col-md-6 pe-0">
                  <div className="consumerMap">
                    <div className="detailTrackingright_">
                      <div className="MapOrders">
                        <div className="MapOrders_inner">
                          <GoogleMap
                            center={center}
                            zoom={15}
                            mapContainerStyle={{
                              width: "100%",
                              height: "calc(100vh - 131px)",
                            }}
                            options={{
                              zoomControl: false,
                              streetViewControl: false,
                              mapTypeControl: false,
                              fullscreenControl: false,
                            }}
                            onLoad={(map) => setMap(map)}
                          >
                            <DirectionsService
                              options={directionsOptions}
                              callback={directionsCallback}
                            />
                            {directionsResponse && (
                              <DirectionsRenderer
                                directions={directionsResponse}
                              />
                            )}
                          </GoogleMap>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="deliveryStatus">
                    <div className="statusHead stausDown">
                      <div className="statusLeft">
                        <h3>Order status</h3>
                        {/* <h6>Delivered</h6> */}
                      </div>
                      <figure>
                        <img src={OrderDown} alt="downSelect" />
                      </figure>
                    </div>
                    <div className="orderStatus ">
                      {/* <div className="readyPickupstatus disableTrack">
                                                <div className='pickupBtnDetails'>
                                                    <figure>
                                                        <img src={fillradio_} alt="pickup" />
                                                    </figure>
                                                    <article>
                                                        <h3>Return CODE</h3>
                                                    </article>
                                                </div>
                                                <div className='pickupBtn'>
                                                    <button className='countbtn'>659X</button>
                                                </div>
                                            </div> */}
                      {(orderData?.status <= 5 &&
                        orderData?.delivery_option == 1) ||
                      (orderData?.status <= 5 &&
                        orderData?.delivery_option == 3) ? (
                        <div className="readyPickupstatus">
                          <figure>
                            <img
                              src={
                                orderData?.status == 5
                                  ? pickuporder
                                  : unselectedOrder
                              }
                              alt="pickup"
                            />
                          </figure>
                          <article>
                            <h3>Delivered</h3>
                            {orderData?.status_desc?.status_5_updated_at ? (
                              <h6>
                                {moment(
                                  orderData?.status_desc?.status_5_updated_at
                                ).format("DD MMM, YYYY | hh:mm a")}
                              </h6>
                            ) : (
                              <></>
                            )}
                          </article>
                        </div>
                      ) : (
                        <></>
                      )}
                      {orderData?.status <= 4 &&
                      orderData?.delivery_option == 4 ? (
                        <div className="readyPickupstatus">
                          <figure>
                            <img
                              src={
                                orderData?.status == 4
                                  ? pickuporder
                                  : unselectedOrder
                              }
                              alt="pickup"
                            />
                          </figure>
                          <article>
                            <h3>Shipped</h3>
                            {orderData?.status_desc?.status_4_updated_at ? (
                              <h6>
                                {moment(
                                  orderData?.status_desc?.status_4_updated_at
                                ).format("DD MMM, YYYY | hh:mm a")}
                              </h6>
                            ) : (
                              <></>
                            )}
                          </article>
                        </div>
                      ) : (
                        <></>
                      )}
                      {orderData?.status === 8 ? (
                        <div className="readyPickupstatus">
                          <figure>
                            <img
                              src={
                                orderData?.status == 8
                                  ? cancleorder
                                  : unselectedOrder
                              }
                              alt="pickup"
                            />
                          </figure>
                          <article>
                            <h3>Rejected</h3>
                            <h6>
                              {moment(
                                orderData?.status_desc?.status_8_updated_at
                              ).format("DD MMM, YYYY | hh:mm a")}
                            </h6>
                          </article>
                        </div>
                      ) : (
                        <></>
                      )}
                      {orderData?.status === 7 || orderData?.status === 9 ? (
                        <>
                          <div className="readyPickupstatus">
                            <figure>
                              <img
                                src={
                                  orderData?.status === 9
                                    ? pickuporder
                                    : unselectedOrder
                                }
                                alt="pickup"
                              />
                            </figure>
                            <article>
                              <h3>Return to Shop</h3>
                              <div className="reviewuserinfo_">
                                <img
                                  style={{ width: "50px" }}
                                  className="invoveuser me-3"
                                  src={
                                    orderData?.seller_details?.banner_image
                                      ? orderData?.seller_details?.banner_image
                                      : returnlogo
                                  }
                                />
                                <article className="ivoiceuserDetail_">
                                  <h4 className="monshead14">
                                    {
                                      orderData?.seller_details
                                        ?.organization_name
                                    }
                                  </h4>
                                  <p className="monshead12">
                                    {
                                      orderData?.seller_details?.current_address
                                        ?.street_address
                                    }
                                  </p>
                                </article>
                              </div>
                              {/* <div className='returnShop_'>
                                        <img className='returnlogo_' src={returnlogo}/>
                                    </div> */}
                            </article>
                          </div>
                          <div className="readyPickupstatus">
                            <figure>
                              <img
                                src={
                                  orderData?.status === 9
                                    ? cancleorder
                                    : unselectedOrder
                                }
                                alt="pickup"
                              />
                            </figure>
                            <article>
                              <h3>Canceled</h3>
                              <h6>
                                {moment(
                                  orderData?.status_desc?.status_8_updated_at
                                ).format("DD MMM, YYYY | hh:mm a")}
                              </h6>
                            </article>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {orderData?.status >= 4 && orderData?.status != 8 ? (
                        <div className="readyPickupstatus pickupBtnDetails">
                          <div className="pickupBtnDetails">
                            <figure>
                              <img
                                src={
                                  orderData?.status >= 4
                                    ? verifyorder
                                    : unselectedOrder
                                }
                                alt="verifyorder"
                              />
                            </figure>
                            <article>
                              <h3>Product Pickup</h3>
                              {orderData?.status_desc?.status_4_updated_at ? (
                                <h6>
                                  {moment(
                                    orderData?.status_desc?.status_4_updated_at
                                  ).format("DD MMM, YYYY | hh:mm a")}
                                </h6>
                              ) : (
                                <></>
                              )}
                            </article>
                          </div>
                          {orderData?.order_delivery?.seller_otp ? (
                            <div className="pickupBtn">
                              <button className="countbtn">
                                {orderData?.order_delivery?.seller_otp}
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {orderData?.delivery_option == 1 ? (
                        <div className="readyPickupstatus">
                          <figure>
                            <img
                              src={
                                orderData?.driver_details
                                  ? pickuporder
                                  : unselectedOrder
                              }
                              alt="pickup"
                            />
                          </figure>
                          <article>
                            <h3>Assign Driver</h3>
                            {/* <h6>{moment(orderData?.status_desc?.status_1_updated_at).format("DD MMM, YYYY | hh:mm a")}</h6> */}
                          </article>
                        </div>
                      ) : (
                        <></>
                      )}
                      {orderData?.status != 8 ? (
                        <div className="readyPickupstatus">
                          <figure>
                            <img
                              src={
                                orderData?.status >= 3
                                  ? pickuporder
                                  : unselectedOrder
                              }
                              alt="pickup"
                            />
                          </figure>
                          <article>
                            <h3>Ready to pickup</h3>
                            {orderData?.status_desc?.status_3_updated_at ? (
                              <h6>
                                {moment(
                                  orderData?.status_desc?.status_3_updated_at
                                ).format("DD MMM, YYYY | hh:mm a")}
                              </h6>
                            ) : (
                              <></>
                            )}
                          </article>
                        </div>
                      ) : (
                        <></>
                      )}
                      {orderData?.status != 8 ? (
                        <div className="readyPickupstatus">
                          <figure>
                            <img
                              src={
                                orderData?.status >= 1
                                  ? pickuporder
                                  : unselectedOrder
                              }
                              alt="pickup"
                            />
                          </figure>
                          <article>
                            <h3>Order accepted</h3>
                            {orderData?.status_desc?.status_1_updated_at ? (
                              <h6>
                                {moment(
                                  orderData?.status_desc?.status_1_updated_at
                                ).format("DD MMM, YYYY | hh:mm a")}
                              </h6>
                            ) : (
                              <></>
                            )}
                          </article>
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* <div className="carrierbox">
                          <h5>Carrier:</h5>
                          <div className="shippingContact carriercontact">
                            <div className="shipcontactLeft">
                              <img className='me-2' src={singleOrderData?.driverDetails?.profile_photo ? singleOrderData?.driverDetails?.profile_photo : profileImg1} />
                              <div className="contctInfo">
                                <h4>{singleOrderData?.driverDetails?.user_profiles?.firstname + " " + singleOrderData?.driverDetails?.user_profiles?.lastname}</h4>
                              </div>
                            </div>
                            <button className="contactBtn">
                              <i className="las la-phone"></i>
                              <h3>Contact<br />{singleOrderData?.driverDetails?.user_profiles?.full_phone_number}</h3>
                            </button>
                          </div>
                        </div> : */}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderTrack;
