import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteRoleApiAsync, getRoleApiAsync, selectLoginAuth } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const DeleteModal = (props) => {
    return (
        <div>
            <div className='addingNewiventory_'>
                <h2 className='mainBoldHeading'><span className='blacksemiHead_'>You are going to delete this {props?.flag === "deletePosUser" ? "Pos User" : props?.flag === "deleteSupport" ? "Support" : 'role'}!</span></h2>
                <p className='subheadingBlack_'>Are You Sure?</p>
                <div className='inventButton mt-5'>
                    <button onClick={() => props.close()} className='declineBtn me-3'>No</button>
                    <button onClick={props?.flag === "deletePosUser" ? () => props?.deletePosUserHandle(props?.id) : props?.flag === "deleteSupport" ? () => props?.deleteSupport(props?.selectedProduct?.id) : () => props?.deleteHandle(props?.selectedProduct?.id)} className='acceptBtn_' disabled={props?.loadingState}>
                        {props?.loadingState && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Yes</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal
