import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import products from "../../../db/category.json"
import CustomModal from '../../../components/shared/CustomModal';
import CallexchangeModal from './callexchangeModal';
import { calendar, shield, user, blackphone } from '../../../../utilities/images';
const DroppedCall = () => {

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const history = useHistory()
  const [enterprisesDate, setEnterprisesDate] = useState("")
  const columns1 = [
    {
      name: '#',
      selector: row => row.id,
      width: "50px"
    },
    {
      name: "Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <img className='userInnerImg' src={user} />
              <div className='ms-2'>
                <span className='contentSubtext_ '>{row.name}</span>
                <p className='mb-0'>
                  <img src={shield} className='img-fluid me-2' />
                  <span className='textInner'>260 101 480 0083 </span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "300px",
    },
    {
      name: 'Date',
      selector: row => {
        return (
          <>
            <div className='ms-2'>
              <span className='contentSubtext_'>{row.date}</span>
              <p className='textInner mt-2'>9:00:23a</p>
            </div>
          </>
        );
      },
      width: "220px",
    },
    {
      selector: row => row.value,
      name: 'Ticket id'
    },
    {
      selector: row => row.usertype,
      name: 'User Type'
    },
    {
      selector: row => row.ringtime,
      name: 'Ringing Time'
    },
    {
      name: '',
      selector: row => {
        return (
          <>
            <div className='ms-2 mt-2 tableBtn__ text-center'>
              <Link to='#' className='yellowBigbtn_'><img src={blackphone} alt="call icon" />Call Back</Link>

            </div>
          </>
        );
      },
      width: "250px"
    },

  ];

  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
  }
  return (
    <div className='suportTicket'>
      <div className='table-responsive'>
        <DataTable
          pagination
          columns={columns1}
          data={products}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "CallexchangeModal" ? "callexchangeModal" : ""}
        ids={modalDetail.flag === "CallexchangeModal" ? "callexchangeModal" : ""}
        // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
        child={
          modalDetail.flag === "CallexchangeModal" ? (
            <CallexchangeModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          <div className='modalHeader_'>
            <div className="common_">
              <h2 className="mainHeading">
                Call Exchange
              </h2>
              <button className="closeButton">
                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
              </button>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  )
}

export default DroppedCall