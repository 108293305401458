import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shops, threeStep } from "../../../../utilities/images";
import {
  checkConnectAccount,
  getconnectAccountApiAsync,
  selectLoginAuth,
  selectSignUpAuth,
} from "../authSlice";
import AddBankDetails from "./AddBankDetails";
import UpdateBankDetails from "./UpdateBankDetails";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ConnectBankAccount = () => {
  const [loading, setLoading] = useState(true);
  const signupAuth = useSelector(selectSignUpAuth);
  const [userConnectDetails, setUserConnectDetails] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const history = useHistory()
  useEffect(() => {
    let params = {
      tokenData: signupAuth?.payload?.token
        ? signupAuth?.payload?.token
        : auth?.payload?.user?.token,
    };
    dispatch(getconnectAccountApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
          setLoading(false);
          if (res?.payload?.data && res?.payload?.data?.length > 0) {
            setUserConnectDetails(res?.payload?.data);
          }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let params = {
      tokenData: signupAuth?.payload?.token
        ? signupAuth?.payload?.token
        : auth?.payload?.user?.token,
    };
    dispatch(checkConnectAccount(params))
      .then(unwrapResult)
      .then((res) => {
        if (res?.payload?.status == 2) {
          toast.error(res?.msg);
          history.push("/chooseProtection");
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="protectionSection">
        <div className="commonLogin">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-lg-6 p-0 text-center">
                <div className="leftSect">
                  <div className="login_left">
                    <img
                      src={shops}
                      className="d-none d-md-block img-fluid mx-auto mb-3"
                      alt="img"
                    />
                    <span className="heading_">
                      Create Your <br />
                      Organization
                    </span>
                  </div>
                  <div className="aboutBusiness">
                    <img src={threeStep} className="img-fluid mb-4" />
                    <h4 className="mb-2 innerSubtext">Connect Your Bank</h4>
                    <span className="">
                      Please let us know what you need help with and We will{" "}
                      <br />
                      do our best to assist you.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {loading ? (
                  <div className="full-loader">
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                  </div>
                ) : (
                  <>
                    {/* {userConnectDetails?.length > 0 ? (
                      <UpdateBankDetails
                        userConnectDetails={userConnectDetails}
                      />
                    ) : ( */}
                      <AddBankDetails />
                    {/* )}   */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectBankAccount;
