import React, { useEffect } from 'react'
import { reportImg } from '../../../../utilities/images'

const ReportModal = (props) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      props.details()
    }, 2000);
    return () => clearTimeout(timer);
  }, [])

  return (
    <div> <div className='addingNewiventory_'>
      <img src={reportImg} className='reportImage mb-4 ' />
      <h2 className='reportHeading mb-2'>Reported</h2>
      <p className='repoetPara'>You have reported issue(s), Company will reply you soon!</p>
    </div></div>
  )
}

export default ReportModal