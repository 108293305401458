import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { firmImage, locate, starimg, timeimg, verifyImg, calendarsh } from '../../../../utilities/images'
import CustomModal from '../../../components/shared/CustomModal';
import VideoscheduleModal from './videoscheduleModal';
const VideocallModal = () => {
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
      show: false,
      title: "",
      flag: "",
    });
    const handleOnCloseModal = () => {
      setModalDetail({
        show: false,
        title: "",
        flag: "",
      });
      setKey(Math.random());
    };
    return (
        <>
        <div className='videocallModal commonBody'>
            <form className='videocallsDetail horizontalForms'>
                <div className='row'>
                    <div className='form-group col-md-12 mb-4 text-start'>
                        <div className='scheduleCallfirm'>
                            <img className='firmImage' src={firmImage} />
                            <div className='firmContent'>
                                <h6>Yiwu Leqi E-Commerce Firm</h6>
                                <div className='firmInfo'>
                                    <img className='firminfoimg me-2' src={verifyImg} />
                                    <p className='me-2'><img className='firminfoimg me-1' src={locate} /><span>Maimi, USA</span></p>
                                    <p className='me-2'><img className='firminfoimg me-1' src={starimg} /><span>4.5</span></p>
                                    <p className='me-2'><img className='firminfoimg me-1' src={timeimg} /><span>Since 2022</span></p>
                                </div>

                            </div>

                        </div>
                        <div className='schduleBottom mt-5'>
                            <p className='scheduleInfo_ '>
                                We are not online for instant video calls. Please make a schedule for a video call. Our representative will join you at the right time
                            </p>
                            <button className='secondaryOuterbtn_ mt-5' data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                        setModalDetail({ show: true, flag: "ScheduleCall" });
                        setKey(Math.random());
                      }}>
                                <img className='calendershdule me-2' src={calendarsh} /> <span>Schedule</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
         <CustomModal
         key={key}
         show={modalDetail.show}
         backdrop="static"
         showCloseBtn={false}
         isRightSideModal={false}
         mediumWidth={false}
         className={modalDetail.flag === "ScheduleCall" ? "commonWidth customContent" :""}
         ids={modalDetail.flag === "ScheduleCall" ? "scheduleCall" :""}
         // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
         child={
         
             modalDetail.flag === "ScheduleCall" ? (
               <VideoscheduleModal close={() => handleOnCloseModal()} />
             ) :
               (
                 ""
               )
         }
         header={
           <div className='modalHeader_'>
             <div className="common_">
               {modalDetail.flag === "ScheduleCall" ?
                 <h2 className="modalHeading">
                   Schedule
                 </h2>
                   : ""
               }
               <button className="closeButton">
                 <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
               </button>
             </div>
           </div>
         }
 
         onCloseModal={() => handleOnCloseModal()}
       />
       </>
    )
}

export default VideocallModal