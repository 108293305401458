import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { returnToInventoryInOrder, searchByInvoiceIdApi, searchBySkuApi } from "../../dashboard/dashboardApi";

const initialState = {
    status: "idle",
    invoiceByInvoiceId: null,
    searchBySKU: null,
    returnToInventory: "",
    invoiceData: "",
};

export const searchByInvoiceIdApiAsync = createAsyncThunk("auth/searchByInvoiceIdApi", async (data) => {
    const response = await searchByInvoiceIdApi(data.postData, data.tokenData);
    return response.data;
});

export const searchBySkuApiAsync = createAsyncThunk("auth/searchBySkuApi", async (data) => {
    const response = await searchBySkuApi(data.postData, data.tokenData);
    return response.data;
});

export const returnSlice = createSlice({
    name: "return",
    initialState,
    reducers: {
        returnEmpty: (state) => {
            state.invoiceByInvoiceId = null;
            state.searchBySKU = null;
            state.returnToInventory = null;
            state.invoiceData = null;
        },
        setInvoiceData: (state, action) => {
            state.invoiceData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchByInvoiceIdApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(searchByInvoiceIdApiAsync.fulfilled, (state, { payload }) => {
                console.log(payload, "statestatestate");
                state.invoiceByInvoiceId = payload
                state.status = "idle";
            })
            .addCase(searchByInvoiceIdApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.invoiceByInvoiceId = null
            })
            .addCase(searchBySkuApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(searchBySkuApiAsync.fulfilled, (state, { payload }) => {
                state.searchBySkuStatus = payload
                state.status = "idle";
            })
            .addCase(searchBySkuApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.searchBySkuStatus = null
            })

    }
});
export const { returnEmpty, setInvoiceData } = returnSlice.actions;
export const selectReturnData = (state) => state.return;
export default returnSlice.reducer;