import React, { useState } from "react";
import {
  check,
  checkSuccess,
  shops,
  stepFull,
  twoStep,
} from "../../../../utilities/images";
import CustomModal from "../../../components/shared/CustomModal";
import VerifyModal from "../verifyModal";
import { useDispatch } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { getAddressFromCoordinates } from "../formCordinates";
import PhoneInput from "react-phone-input-2";
import LoginProfileModal from "../loginProfileModal";
import SuccessfulModal from "../successfulModal";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { last } from "lodash";
import { sigupApiAsync } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Signup = () => {
  const toastId = React.useRef(null);
  const [hide, setHide] = useState(false);
  const [displayValue, setDisplayValue] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const phoneData = localStorage.getItem("phone_details")
    ? JSON.parse(localStorage.getItem("phone_details"))
    : "";
  const existing_user = localStorage.getItem("existing_user")
    ? JSON.parse(localStorage.getItem("existing_user"))
    : "";
  const emailData = localStorage.getItem("email_details")
    ? JSON.parse(localStorage.getItem("email_details"))
    : "";
  const emailInfo = localStorage.getItem("email_info")
    ? localStorage.getItem("email_info")
    : "";
  const phoneInfo = localStorage.getItem("phone_info")
    ? JSON.parse(localStorage.getItem("phone_info"))
    : "";
  const [firstName, setFirstName] = useState(
    phoneData?.user_profiles?.firstname
      ? phoneData?.user_profiles?.firstname
      : ""
  );
  const [lastName, setLastName] = useState(
    phoneData?.user_profiles?.lastname ? phoneData?.user_profiles?.lastname : ""
  );
  const [phoneNo, setPhoneNo] = useState(
    existing_user?.user_profiles?.phone_no
      ? existing_user?.user_profiles?.phone_no
      : phoneInfo?.phone_no
  );
  const [phoneCode, SetPhoneCode] = useState(
    existing_user?.user_profiles?.phone_code
      ? existing_user?.user_profiles?.phone_code
      : phoneInfo?.phone_code
  );
  const [email, setEmail] = useState(
    phoneData?.user_email ? phoneData?.user_email : emailInfo
  );
  const [password, setPassword] = useState("");
  const [securityPin, setSecurityPin] = useState(
    existing_user?.user_profiles?.security_pin.length == 4
      ? existing_user?.user_profiles?.security_pin
      : ""
  );
  const [confirmSecurityPin, setConfirmSecurityPin] = useState(
    existing_user?.user_profiles?.security_pin.length == 4
      ? existing_user?.user_profiles?.security_pin
      : ""
  );
  const [address, setAddress] = useState("");
  console.log(address, "addressaddress");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [countryValue, setCountryValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  console.log(stateCode, "stateCode");
  const [stateValue, setStateValue] = useState("");
  const [aptValue, setAptValue] = useState("");
  console.log(aptValue, "aptValue");
  const [zipCode, setZipCode] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(Math.random());
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };

  const extractStreetAddress = (address) => {
    // Extract the street address from the address
    const parts = address.split(",");
    if (parts.length >= 3) {
      return `${parts[0].trim()}, ${parts[1].trim()}`;
    }
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  let location = useLocation();

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleModalDetails = (data) => {
    if (data === "Successful" || data === "LoginProfile") {
      setModalDetail({ show: true, flag: data });
      setKey(Math.random());
    }
  };

  const onChangePhoneNumber = (value, data, event, formattedValue) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  };

  const autoCompleteHandleChange = (address) => {
    console.log(address, "address");
    // setAddress(address);
    setFormattedAddress(address);
    // geocodeByAddress(address)
    //   .then((results) => {
    //     console.log(results,"resultsokkk");
    //     // console.log(results[0]?.formatted_address,"results[0]?.formatted_address");
    //     // fillAddressDetails(results);
    //     setDisplayValue(results[0]?.formatted_address?.split(','))
    //     setHide(true)
    //     setLatitude(results[0].geometry.location.lat());
    //     setLongitude(results[0].geometry.location.lng());
    //   })
    //   .catch((error) => { });
    setZipCode("");
    setCityValue("");
    setStateValue("");
    setCountryValue("");
    setAptValue("");
    setAddress("");
  };

  // const autoCompleteHandleChange = (address) => {
  //   setAddress(address);
  //   setFormattedAddress(address);
  //   setHide(false);
  //   setZipCode('');
  //   setCityValue('');
  //   setStateValue('');
  //   setCountryValue('');
  //   setAptValue('');
  // };

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        setDisplayValue(results[0]?.formatted_address?.split(",")[0]);
        fillAddressDetails(results);
        setLatitude(results[0].geometry.location.lat());
        setLongitude(results[0].geometry.location.lng());
        setHide(true);
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  // const fillAddressDetails = (results) => {
  //   setFormattedAddress(results[0]?.formatted_address || '');

  //   results[0]?.address_components.forEach((component) => {
  //     const { types, long_name, short_name } = component;
  //     console.log(types, long_name, short_name,"types, long_name, short_name");
  //     if (types.includes('postal_code')) {
  //       setZipCode(short_name);
  //     } else if (types.includes('locality')) {
  //       setCityValue(long_name);
  //     } else if (types.includes('administrative_area_level_1') ||
  //       types.includes('administrative_area_level_3')) {
  //       setStateValue(long_name);
  //     } else if (types.includes('country')) {
  //       setCountryValue(long_name);
  //     } else if (types.includes('street_number')) {
  //       setAptValue(long_name);
  //     } else if (types.includes('route')) {
  //       setAddress(long_name);
  //     }
  //   });
  // };

  const fillAddressDetails = (results) => {
    // setAddress(results[0].formatted_address);
    setFormattedAddress(results[0].formatted_address);

    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCode(results[0].address_components[j].short_name);
      } else if (results[0].address_components[j].types[0] == "locality") {
        setCityValue(results[0].address_components[j].long_name);
      } else if (
        results[0].address_components[j].types[0] ==
          "administrative_area_level_1" ||
        results[0].address_components[j].types[0] ===
          "administrative_area_level_3" ||
        results[0].address_components[j].types[0] === "locality"
      ) {
        setStateValue(results[0].address_components[j].long_name);
        setStateCode(results[0].address_components[j]?.short_name);
      } else if (results[0].address_components[j].types[0] == "country") {
        setCountryValue(results[0].address_components[j].long_name);
        setCountryCode(results[0].address_components[j]?.short_name);
      } else if (results[0].address_components[j].types[0] == "street_number") {
        setAptValue(results[0].address_components[j].long_name);
      } else if (results[0].address_components[j].types[0] == "route") {
        setAddress(results[0].address_components[j].long_name);
      }
    }
  };
  const handleGoToWebPos = () => {
    window.open("https://webpos.jobr.com/", "_blank");
  };

  const handleSubmit = (e) => {
    const fullAddress = `${aptValue} ${address}`.trim();
    console.log(fullAddress, "fullAddress");
    e.preventDefault();
    let data = {
      seller_type: location?.state?.seller_type,
      organization_name: location?.state?.organization_name,
      is_business_registered: location?.state?.is_business_registered,
      business_type: location?.state?.business_type,
      operations: location?.state?.operation,
      service_ids: location?.state?.service_ids,
      firstname: firstName,
      lastname: lastName,
      phone_code: phoneCode,
      phone_no: phoneNo,
      email: phoneData?.user_email ? phoneData?.user_email : emailInfo,
      current_address: {
        street_address: fullAddress
          ? fullAddress
          : extractStreetAddress(formattedAddress),
        country: countryValue,
        state: stateValue,
        city: cityValue,
        zipcode: zipCode,
        address_type: "current",
        longitude: longitude,
        latitude: latitude,
        country_code: countryCode,
        state_code: stateCode,
        format_address: formattedAddress,
      },
      is_default_address: defaultAddress,
    };
    console.log(data, "parammm");

    if (!firstName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your firstname");
      }
      return;
    }
    if (!lastName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your lastname");
      }
      return;
    }
    if (!phoneCode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your phonecode");
      }
      return;
    }
    if (!phoneNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your phone number");
      }
      return;
    }
    if (phoneNo.length < 10) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Phone no must be of 10 digits");
      }
      return;
    }
    if (!email) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your email");
      }
      return;
    }
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    var resultemail = pattern.test(email);
    if (resultemail === false) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter valid email");
      }
      return;
    }
    if (businessWebsite) {
      var urlPattern = new RegExp(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
      );
      var resultUrl = urlPattern.test(
        businessWebsite?.split(":")[0] === "https"
          ? businessWebsite
          : `https://${businessWebsite}`
      );
    }
    if (resultUrl === false) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter valid webiste url");
      }
      return;
    }
    if (!formattedAddress) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your address");
      }
      return;
    }
    if (!countryValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your country");
      }
      return;
    }
    if (!stateValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your state");
      }
      return;
    }
    if (!cityValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your city");
      }
      return;
    }
    if (!zipCode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your zipcode");
      }
      return;
    }
    if (!latitude && !longitude) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Incorrect Address");
      }
      return;
    }
    if (aptValue) {
      data = {
        ...data,
        current_address: {
          ...data.current_address,
          apt: aptValue,
        },
      };
    }
    if (businessWebsite && businessWebsite != "") {
      data = {
        ...data,
        business_website:
          businessWebsite?.split(":")[0] === "https"
            ? businessWebsite
            : `https://${businessWebsite}`,
      };
    }
    if (phoneData?.user_id) {
      data = {
        ...data,
        user_id: phoneData?.user_id,
      };
    }
    setLoading(true);
    dispatch(sigupApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        history.push("/connect-bank-account");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  let phoneDetails = {
    phone_code: `+${phoneCode}`,
    phone_no: phoneNo,
  };

  return (
    <div className="chooseRoles">
      <div className="commonLogin">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-6 p-0 text-center">
              <div className="leftSect">
                <div className="login_left">
                  <img
                    src={shops}
                    className="d-none d-md-block img-fluid mx-auto mb-3"
                    alt="img"
                  />
                  <span className="heading_">
                    <b>
                      Create Your <br />
                      Organization
                    </b>
                  </span>
                </div>
                <div className="aboutBusiness">
                  <img src={twoStep} className="img-fluid mb-4" alt="" />
                  <h4 className="mb-2 innerSubtext">
                    Add your business details
                  </h4>
                  <span className="">
                    Please let us know what you need help with and We will{" "}
                    <br />
                    do our best to assist you.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="loginhorrizontal-form">
                <form
                  autoComplete="nope"
                  className="form_horizontal row"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <div className="mb-4 form-group col-md-6">
                    <label className="deatilsHead mb-3">First name</label>
                    <input
                      type="text"
                      className="customform-control"
                      name={generateRandomName}
                      autoComplete="new-password"
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 form-group col-md-6">
                    <label className="deatilsHead mb-3">Last Name</label>
                    <input
                      type="text"
                      className="customform-control"
                      name={generateRandomName()}
                      autoComplete="new-password"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-3">Phone Number</label>
                    <div style={{ display: "flex" }}>
                      <PhoneInput
                        className=""
                        enableSearch={true}
                        name={generateRandomName()}
                        autoComplete="new-password"
                        country="us"
                        value={phoneCode + phoneNo}
                        disabled
                        placeholder="Phone no."
                        // onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                      />
                      {phoneInfo?.phone_no ? (
                        <img
                          className="checkarrow_"
                          style={{ width: "20px" }}
                          src={check}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-3">Email Address</label>
                    <div style={{ display: "flex" }}>
                      <input
                        type="email"
                        className="customform-control"
                        name={generateRandomName()}
                        autoComplete="new-password"
                        placeholder="Email"
                        value={email}
                        disabled
                        // onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailInfo || phoneData?.user_email ? (
                        <img
                          className="checkarrow_"
                          style={{ width: "20px" }}
                          src={check}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}
                      {/* {phoneData?.is_email_verified === true ? <img style={{ width: "20px" }} src={check} alt="" /> : <></>} */}
                    </div>
                  </div>

                  <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-3">Address Line 1</label>
                    <div className="inputGroup">
                      {hide ? (
                        <span
                          className="customform-control countrytags_ w-100"
                          onClick={() => setHide(!hide)}
                        >
                          {displayValue}
                        </span>
                      ) : (
                        <PlacesAutocomplete
                          className=""
                          autoComplete="off"
                          value={formattedAddress}
                          onChange={autoCompleteHandleChange}
                          onSelect={autoCompleteHandleSelect}
                          searchOptions={{
                            componentRestrictions: {
                              country: ["us", "ind"],
                            },
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: "Street Address",
                                  className:
                                    "location-search-input customform-control countrytags_",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#41b6e6",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={index}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      )}
                    </div>
                  </div>
                  <div className="mb-4 form-group col-md-6">
                    <label className="deatilsHead mb-3">Apt.</label>
                    <input
                      className="customform-control id_password"
                      placeholder="Apt."
                      name={generateRandomName}
                      autoComplete="new-password"
                      type="text"
                      value={aptValue}
                      onChange={(e) => setAptValue(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 form-group col-md-6">
                    <label className="deatilsHead mb-3">Country</label>
                    <input
                      className="customform-control id_password"
                      placeholder="Country"
                      name={generateRandomName}
                      autoComplete="new-password"
                      type="text"
                      value={countryValue}
                      onChange={(e) => setCountryValue(e.target.value)}
                    />
                    {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                  </div>
                  <div className="mb-4 form-group col-md-6">
                    <label className="deatilsHead mb-3">State</label>
                    <input
                      className="customform-control id_password"
                      placeholder="State"
                      name={generateRandomName}
                      autoComplete="new-password"
                      type="text"
                      value={stateValue}
                      onChange={(e) => setStateValue(e.target.value)}
                    />
                    {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                  </div>
                  <div className="mb-4 form-group col-md-6">
                    <label className="deatilsHead mb-3">City</label>
                    <input
                      className="customform-control id_password"
                      placeholder="City"
                      name={generateRandomName}
                      autoComplete="new-password"
                      type="text"
                      value={cityValue}
                      onChange={(e) => setCityValue(e.target.value)}
                    />

                    {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                  </div>
                  <div className="mb-4 form-group col-md-6">
                    <label className="deatilsHead mb-3">Zip code</label>
                    <input
                      type="number"
                      className="customform-control"
                      name={generateRandomName}
                      autoComplete="new-password"
                      placeholder="Zip code"
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-3">
                      Business website{" "}
                      <span className="optionData_">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="customform-control"
                      placeholder="e.g. jobr.com"
                      value={businessWebsite}
                      name={generateRandomName}
                      autoComplete="new-password"
                      onChange={(e) => setBusinessWebsite(e.target.value)}
                    />
                  </div>
                  {/* <div className="mb-4 form-group col-md-12">
                    <p>
                      click here to redirect to{" "}
                      <span className="webpos-text" onClick={handleGoToWebPos}>
                        Pos Website
                      </span>
                    </p>
                  </div> */}
                  <div className="mb-4 form-group col-md-12">
                    <input
                      type="checkbox"
                      id="register"
                      autoComplete="new-password"
                      name="defaultAddress"
                      checked={defaultAddress}
                      onChange={(e) => setDefaultAddress(!defaultAddress)}
                    />
                    <label
                      htmlFor="register"
                      className="ms-2 mainlightPhragraph"
                    >
                      Set as default address
                    </label>
                    <br />
                  </div>
                  <div className="col-md-12 mt-5">
                    <button
                      className={`loginBtnSign w-100 ${
                        firstName &&
                        lastName &&
                        phoneCode &&
                        phoneNo.length == 10 &&
                        email &&
                        formattedAddress &&
                        countryValue &&
                        stateValue &&
                        cityValue &&
                        zipCode
                          ? "buttonActive"
                          : ""
                      }`}
                      type="submit"
                      disabled={
                        loading ||
                        !(
                          firstName &&
                          lastName &&
                          phoneCode &&
                          phoneNo.length == 10 &&
                          email &&
                          formattedAddress &&
                          countryValue &&
                          stateValue &&
                          cityValue &&
                          zipCode
                        )
                      }
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Next</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "VerifyModal"
            ? "verifyModal"
            : modalDetail.flag === "Successful"
            ? "successful"
            : modalDetail.flag === "LoginProfile"
            ? "loginProfileModal"
            : ""
        }
        size={
          modalDetail.flag === "VerifyModal"
            ? "md"
            : modalDetail.flag === "Successful"
            ? "md"
            : ""
        }
        child={
          modalDetail.flag === "VerifyModal" ? (
            <VerifyModal
              close={() => handleOnCloseModal()}
              phoneDetails={phoneDetails}
              details={(e) => handleModalDetails(e)}
            />
          ) : modalDetail.flag === "Successful" ? (
            <SuccessfulModal
              close={() => handleOnCloseModal()}
              details={(e) => handleModalDetails(e)}
            />
          ) : modalDetail.flag === "LoginProfile" ? (
            <LoginProfileModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />{" "}
    </div>
  );
};

export default Signup;
