import React, { useEffect, useState } from "react";
import {
  rewardBlue,
  mapBlue,
  emailBlue,
  rewardImg,
  phoneBlue,
  profileImg1,
} from "../../../../utilities/images";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import CustomModal from "../../../components/shared/CustomModal";
import EditConsumerModal from "./editConsumerModal";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getAddressApiAsync,
  getSingleInvitationPosUserAsync,
  getSinglePosUserAsync,
  selectLoginAuth,
} from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAllOrdersApiAsync,
  getMarketStatusApiAsync,
  getServicesAsync,
  updateMarketStatusApiAsync,
} from "../../dashboard/dashboardSlice";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";

const ConsumerProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const auth = useSelector(selectLoginAuth);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const userDatas = location.state;
  console.log(userDatas,"userDatas");
  const [userData, setUserData] = useState("");
  const [userInvitationData, setInvitationData] = useState("");
  console.log(userData,"userData");
  const [key, setKey] = useState(Math.random());
  const [marketStatus, setMarketStatus] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [services, setServices] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [servicesId, setServicesId] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
 console.log(auth?.payload,"iddataaaa"); 
  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };
  const history = useHistory();
  const handleRowClick = (row) => {
    // Change Order Id
    history.push(`/orderTrack/${row.id}`);
  };
  const columns = [
    {
      name: "#",
      width: "50px",
      selector: (row, index) => index + 1,
    },
    {
      name: "Date",
      selector: (row) => moment(row.order_date).format("MM/DD/YYYY"),
    },
    {
      selector: (row) => row?.invoices?.invoice_number ?? "-",
      name: "Invoices",
    },
    {
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="d-flex align-items-center"
            >
              <img
                className="userInnerImg"
                src={
                  row?.seller_details?.profile_photo
                    ? row?.seller_details?.profile_photo
                    : profileImg1
                }
              />
              <div className="ms-2 text-start">
                <span className="contentSubtext_ ">
                  {row?.seller_details?.firstname +
                    " " +
                    row?.seller_details?.lastname}
                </span>
                <p className="mb-0">
                  <span className="textInner">POS Staff</span>
                </p>
              </div>
            </div>
          </>
        );
      },
      name: "Staff",
      width: "200px",
    },
    {
      selector: (row) =>
        `${row?.seller_details?.current_address?.city}, ${row?.seller_details?.current_address?.state}`,
      name: "Store Location",
      width: "200px",
    },
    {
      selector: (row) => {
        return (
          <>
            {row?.delivery_details && row?.delivery_option == 1 ? (
              <div
                onClick={() => handleRowClick(row)}
                className="d-flex align-items-center"
              >
                <img
                  className="userInnerImg"
                  src={
                    row?.driver_details?.profile_photo
                      ? row?.driver_details?.profile_photo
                      : profileImg1
                  }
                />
                <div className="ms-2 text-start">
                  <span className="contentSubtext_ ">
                    {row?.delivery_details?.title}
                  </span>
                  <p className="mb-0">
                    {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                    <span className="textInner">${row?.delivery_charge}</span>
                  </p>
                </div>
              </div>
            ) : row?.shipping_details && row?.delivery_option == 4 ? (
              <div
                onClick={() => handleRowClick(row)}
                className="d-flex align-items-center"
              >
                <img
                  className="userInnerImg"
                  src={
                    row?.shipping_details?.image
                      ? row?.shipping_details?.image
                      : profileImg1
                  }
                />
                <div className="ms-2 text-start">
                  <span className="contentSubtext_ ">
                    {row?.shipping_details?.title}
                  </span>
                  <p className="mb-0">
                    {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                    <span className="textInner">${row?.shipping_charge}</span>
                  </p>
                </div>
              </div>
            ) : (
              <>No Delivery Info</>
            )}
          </>
        );
      },
      name: "Delivery Type",
      width: "200px",
    },
    {
      selector: (row) => row?.total_items,
      name: "No. of items",
      width: "200px",
    },
    {
      selector: (row) => {
        return (
          <>
            <div className={`${row?.payable_amount < 0 ? "text-danger" : ""}`}>
              ${row?.payable_amount}
            </div>
          </>
        );
      },
      name: "Amount",
    },
    {
      selector: (row) => {
        return (
          <div className="ms-2 tableBtn__">
            <div
              to="#"
              className={`status_button_all_purchase`}
            // style={{
            //   background:
            //     row.status == 0
            //       ? "#3C444D"
            //       : row?.status === 1 || row?.status === 2
            //         ? "#1FB3FF"
            //         : row?.status === 3 || row?.status === 4
            //           ? "#005DB2"
            //           : row?.status === 5 || row?.status === 6
            //             ? "#275AFF"
            //             : row?.status === 9
            //               ? "#FB466C"
            //               : "#D33300",
            // }}
            >
              {row?.status == 0 ? (
                <Link to="#" className="commonbtndesign_ viewDetailBtn_ ">
                  {row?.order_type === "product"
                    ? "Order to Review"
                    : "Service to Review"}
                </Link>
              ) : row?.status == 1 ? (
                <Link to="#" className="commonbtndesign_ acceptBtn_ ">
                  Accepted
                </Link>
              ) : row?.status == 2 ? (
                <Link to="#" className="commonbtndesign_ viewDetailBtn_ ">
                  {row?.order_type === "product"
                    ? "Order Preparing"
                    : "In progress"}
                </Link>
              ) : row?.status == 3 && row?.delivery_option != 4 ? (
                <Link to="#" className="commonbtndesign_ greenBtn_ ">
                  {row?.order_type === "product"
                    ? "Ready for Pickup"
                    : "In progress"}
                </Link>
              ) : row?.status == 3 && row?.delivery_option == 4 ? (
                <Link to="#" className="commonbtndesign_ greenBtn_ ">
                  Printing Label
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 1 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  Assign to Driver
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 2 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  In progress
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 3 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  Picked-up
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 4 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  Shipped
                </Link>
              ) : row?.status == 5 ? (
                <Link to="#" className="commonbtndesign_ viewDetailBtn_">
                  {row?.order_type === "product" ? "Delivered" : "Completed"}
                </Link>
              ) : row?.status == 6 && row?.delivery_option == 3 ? (
                <Link to="#" className="commonbtndesign_ deliverBtn_ mb-0">
                  Pickup by Customer
                </Link>
              ) : row?.status == 7 && row?.is_returned_order == true ? (
                <Link to="#" className="commonbtndesign_ cancelbtn_">
                  Refunded
                </Link>
              ) : row?.status == 7 && row?.is_returned_order == false ? (
                <Link to="#" className="commonbtndesign_ cancelbtn_">
                  Cancelled
                </Link>
              ) : row?.status == 8 ? (
                <Link to="#" className="commonbtndesign_ cancelbtn_">
                  Rejected
                </Link>
              ) : row?.status == 9 ? (
                <Link to="#" className="commonbtndesign_ returnBtn_">
                  Returned
                </Link>
              ) : (
                ""
              )}
            </div>
            <i className="fal fa-chevron-right ms-3"></i>
          </div>
        );
      },
      name: "Status",
      width: "150px",
    },
  ];

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const getAllOrdersHandle = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        page: currentPage,
        limit: perPage,
        need_returned: false,
      },
      tokenData: auth?.payload?.token,
    };

    if (servicesId) {
      params = {
        postData: {
          ...params.postData,
          service_id: servicesId,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (addressId) {
      params = {
        postData: {
          ...params.postData,
          seller_address_id: addressId,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (userDatas?.user_id) {
      params = {
        postData: {
          ...params.postData,
          user_uid: userDatas?.user_id ? userDatas?.user_id : "null",
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (userDatas?.invitation_id) {
      params = {
        postData: {
          ...params.postData,
          invitation_id: userDatas?.invitation_id,
        },
        tokenData: auth?.payload?.token,
      };
    }

    // if (startDate != null && endDate != null) {
    //     let newData = {
    //         ...params.postData,
    //         "start_date": moment(startDate).format('YYYY-MM-DD'),
    //         "end_date": moment(endDate).format('YYYY-MM-DD')
    //     }
    //     params = {
    //         tokenData: auth?.payload?.token,
    //         postData: newData
    //     }
    // }
    setLoading(true);
    dispatch(getAllOrdersApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setOrderData(obj?.payload);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        // type: "product",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAllAddress(obj?.payload);
      })
      .catch((obj) => { });
  };

  const handleMarketStatus = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        user_id: userDatas?.user_details?.id?.toString(),
        accept: !marketStatus
          ? true
          : marketStatus && marketStatus?.accept === true
            ? false
            : marketStatus && marketStatus?.accept === false
              ? true
              : "",
      },
    };
    dispatch(updateMarketStatusApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        getMarketingStatus();
      })
      .catch((obj) => { });
  };

  const getSingleUserApiAsyncHandler = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        id: userDatas?.user_details?.id,
      },
      tokenData: auth?.payload?.token,
    };

    dispatch(getSinglePosUserAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setUserData(obj?.payload)
      })
      .catch((obj) => { });
  };

  const getSingleInvitationUserApiAsyncHandler = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        id: userDatas?.invitation_details?.id,
      },
      tokenData: auth?.payload?.token,
    };

    dispatch(getSingleInvitationPosUserAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setInvitationData(obj?.payload)
      })
      .catch((obj) => { });
  };


  console.log(userInvitationData,"user invite data");
  const getMarketingStatus = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        user_id: userDatas?.user_details?.id,
      },
    };
    dispatch(getMarketStatusApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setMarketStatus(obj?.payload);
      })
      .catch((obj) => { });
  };

  useEffect(() => {
    getAllOrdersHandle();
    if(!userDatas?.invitation_details){
    getSingleUserApiAsyncHandler();
    }
    getSingleInvitationUserApiAsyncHandler()
  }, [perPage, servicesId, addressId]);

  useEffect(() => {
    if (userDatas?.user_details?.id) {
      getMarketingStatus()
    }
    getServices();
    getAllAddress();
  }, []);

  console.log(userDatas, "nappydata");
  return (
    <>
      <DashboardNavbar
        title="UserProfile"
        flag={"customer"}
        backShow={"/customer"}
      />
      <div className="mainCatalog mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="profileDetails d-flex">
                {userData?.user_profiles ? (
                  <div className="profileUser_">
                    <img
                      src={userData?.user_profiles?.profile_photo ?? profileImg1}
                      className="img-fluid consumerUser"
                    />
                    <div class="userEdit mt-4">
                      <button
                        onClick={() => {
                          setModalDetail({
                            show: true,
                            flag: "editConsumer",
                            userData: userData,
                          });
                          setKey(Math.random());
                        }}
                        class="editbtn"
                      >
                        {" "}
                        Edit{" "}
                      </button>
                    </div>
                  </div>
                ) : userDatas?.invitation_details?.full_phone_number ? (
                  <div className="profileUser_">
                    <img
                      src={
                        userDatas?.invitation_details?.profile_photo ??
                        profileImg1
                      }
                      className="img-fluid consumerUser"
                    />
                    <div class="userEdit mt-4">
                      <button
                        onClick={() => {
                          setModalDetail({
                            show: true,
                            flag: "editConsumer",
                            userData: userData,
                          });
                          setKey(Math.random());
                        }}
                        class="editbtn"
                      >
                        {" "}
                        Edit{" "}
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {userData?.user_profiles || userDatas?.invitation_details ? (
                  <div className="profile Heading">
                    <h4 className="headingText_ ">
                      {userData?.user_profiles?.firstname
                        ? userData?.user_profiles?.firstname
                        :userInvitationData?.firstname?
                        userInvitationData?.firstname
                        : userDatas?.invitation_details?.firstname
                          ? userDatas?.invitation_details?.firstname
                          : ""}{" "}
                      {userData?.user_profiles?.middlename
                        ? userData?.user_profiles?.middlename
                        :userInvitationData?.middlename?
                        userInvitationData?.middlename
                        : userDatas?.invitation_details?.middlename
                          ? userDatas?.invitation_details?.middlename
                          : ""}{" "}
                      {userData?.user_profiles?.lastname
                        ? userData?.user_profiles?.lastname
                        :userInvitationData?.lastname?
                        userInvitationData?.lastname
                        : userDatas?.invitation_details?.lastname
                          ? userDatas?.invitation_details?.lastname
                          : ""}
                    </h4>

                    <ul className="contentextLightMenus mt-3">
                      <li className="contentextLightItems">
                        <img
                          src={phoneBlue}
                          className="img-fluid consumerUser"
                        />
                        <span className="ms-2">
                          {userData?.user_profiles?.full_phone_number
                            ? userData?.user_profiles?.full_phone_number
                            :userInvitationData?.full_phone_number?
                            userInvitationData?.full_phone_number
                            : userDatas?.invitation_details?.full_phone_number
                              ? userDatas?.invitation_details?.full_phone_number
                              : "-"}
                        </span>
                      </li>
                      {
                        userData?.email
                          || userDatas?.invitation_details?.email ?
                          <li className="contentextLightItems">
                            <img
                              src={emailBlue}
                              className="img-fluid consumerUser"
                            />
                            <span className="ms-2">
                              {userData?.email
                                ? userData?.email
                                :userInvitationData?.email?
                                userInvitationData?.email
                                : userDatas?.invitation_details?.email
                                  ? userDatas?.invitation_details?.email
                                  : "-"}
                            </span>
                          </li> : <></>}
                      {userData?.user_profiles?.current_address?.city ? (
                        <li className="contentextLightItems">
                          <img
                            src={mapBlue}
                            className="img-fluid consumerUser"
                          />
                          <span className="ms-2">
                            {userData?.user_profiles?.current_address?.city ??
                              "-"}
                            , (
                            {userData?.user_profiles?.current_address?.state ??
                              "-"}
                            ),
                            {userData?.user_profiles?.current_address?.country ??
                              "-"}
                          </span>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                ) : (
                  <div>Not Available</div>
                )}
              </div>
            </div>

            {/* <div className="col-lg-6">
              <div className="profilePoints">
                <button className="pointBtn pointBtnBlue">
                  <img src={rewardBlue} className="img-fluid pointsIcons" />{" "}
                  <span className="smallHeading">Points: 2</span>
                </button>
              </div>
              {
                userDatas?.user_details?.id ?
                  <div className="profilePoints mt-3">
                    <button className="pointBtn">
                      {" "}
                      <span className="form-check form-switch  pointsIcons">
                        <input
                          type="checkbox"
                          checked={marketStatus?.accept}
                          onChange={() => handleMarketStatus()}
                        />
                      </span>
                      <span className="smallHeading">Accept Marketing </span>
                    </button>
                  </div> : <></>
              }
            </div> */}
          </div>
        </div>
      </div>

      <div className="tableSectionBackground">
        <div className="tableFihandleBrandDataChangelters tableFilters mt-4 mb-3">
          <div className="col-md-12">
            <div className="row">

              <div className="col-lg-6 col-md-12">
                <div className="productMainCategory">
                  {/* <div className="categroyBox">
                    <select
                      className="cateSelectdFilter"
                      value={servicesId}
                      onChange={(e) => setServicesId(e.target.value)}
                    >
                      <option value="" selected>
                        All Category
                      </option>
                      {services?.map((val, index) => {
                        return (
                          <option value={val?.value} key={index}>
                            {val?.label}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  <div className="categroyBox">
                    <select
                      autoComplete="new-password"
                      className="customform-control  selectControl form-select"
                      value={addressId}
                      onChange={(e) => setAddressId(e.target.value)}
                    >
                      <option value="" selected>
                        All location
                      </option>
                      {allAddress?.map((val, index) => {
                        return (
                          <option value={val?.id} key={index}>
                            {val?.format_address}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="plansAreasContainer profileUser_ table-responsive">
            <DataTable
              data={orderData?.data}
              columns={columns}
              pagination
              defaultSortFieldId={1}
              onRowClicked={handleRowClick}
              className="transection_datatable"
              paginationServer
              progressPending={loading}
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "editConsumer" ? "editConsumer" : ""}
        size={modalDetail.flag === "editConsumer" ? "md" : "lg"}
        child={
          modalDetail.flag === "editConsumer" ? (
            <EditConsumerModal
              getSingleUserApiAsyncHandler={() => getSingleUserApiAsyncHandler()}
              getSingleInvitationUserApiAsyncHandler={() => getSingleInvitationUserApiAsyncHandler()}
              userData={userData}
              userInvitationData={userInvitationData}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        // header={
        //   <>
        //     {modalDetail.flag === "editConsumer" ? (
        //       <>
        //         <div className="editConsumerModal">
        //           <div className="editCrossDetails">
        //             <span
        //               className="crossIcon"
        //               onClick={() => handleOnCloseModal()}
        //             >
        //               <i className="las la-times" aria-hidden="true"></i>
        //             </span>
        //           </div>
        //           <div className="consumerBtns_">
        //             <button className="discardBtn" onClick={handleOnCloseModal}>
        //               Discard
        //             </button>
        //             <button className="saveBtn"> Save</button>
        //           </div>
        //         </div>
        //       </>
        //     ) : (
        //       <></>
        //     )}
        //   </>
        // }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ConsumerProfile;
