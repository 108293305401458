import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, profileImg1, shield, user } from '../../../../utilities/images';
import CustomModal from '../../../components/shared/CustomModal';
import SuportticketModal from './supportticketModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { assignSupportAgentApiAsync, getSupportTicketDataApiAsync } from '../../dashboard/dashboardSlice';

const NewTicket = (props) => {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch()
  const [selectedProduct, setSelectedProduct] = useState('')
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [supportData, setSupportData] = useState([]);
  const [supportID, setSupportID] = useState('');
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const history = useHistory()
  const [enterprisesDate, setEnterprisesDate] = useState("")
  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };
  const columns1 = [
    {
      name: '#',
      selector: row => row?.id,
      width: "70px"
    },
    {
      name: "Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <img className='userInnerImg' src={row?.user_detail?.user_profiles?.profile_photo ? row?.user_detail?.user_profiles?.profile_photo : profileImg1} />
              <div className='ms-2'>
                <span className='contentSubtext_ '>{row?.user_detail?.user_profiles?.firstname}</span>
                <p className='mb-0'>
                  <img src={shield} className='img-fluid me-2' />
                  <span className='textInner'>260 101 480 0083 </span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "300px",
    },
    {
      name: 'Date',
      selector: row => {
        return (
          <>
            <div className='ms-2'>
              <span className='contentSubtext_'>{moment(row?.created_at).format("MMM DD, YYYY")}</span>
              <p className='textInner mt-2'>{moment(row?.created_at).format('hh:mm a')}</p>
            </div>
          </>
        );
      },
      width: "220px",
    },
    {
      selector: row => `#${row?.id}`,
      name: 'Ticket id',
      width: "120px",
    },
    {
      selector: row => row?.user_detail?.user_profiles?.seller_type,
      name: 'User Type',
      width: "120px",
    },
    {
      selector: row => `${row?.subject_id == 1 ? "Payment" : row?.subject_id == 2 ? "Delivery" : row?.subject_id == 3 ? "Transfer" : row?.subject_id == 4 ? "Order" : "Others"} Issue`,
      name: 'Subject',
      width: "120px",
    },

    {
      name: 'Status',
      selector: row => {
        return (
          <>
            <div className='ms-2 mt-2 tableBtn__ text-center'>
              <Link to='#' className='assignBtn_' onClick={() => {
                setModalDetail({ show: true, flag: "SuportticketModal" });
                setKey(Math.random());
                setSelectedProduct(row);
                setSupportID(row?.id)
              }}>Assign Now</Link>
              {/* <h5 className='tableHeadsmall'>00:01:29</h5> */}
            </div>
          </>
        );
      },
      width: "170px"
    }
  ];

  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
  }

  const getSupportData = () => {
    let params = {
      "postData": {
        page: currentPage,
        limit: perPage,
        filter: 'new_tickets'
      },
      "tokenData": auth?.payload?.token
    }
    if (props?.startDate && props?.endDate) {
      let newData = {
          ...params.postData,
          "start_date": moment(props?.startDate).format('YYYY-MM-DD'),
          "end_date": moment(props?.endDate).format('YYYY-MM-DD')
      }
      params = {
          tokenData: auth?.payload?.token,
          postData: newData
      }
  }

    setLoading(true)
    dispatch(getSupportTicketDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setSupportData(obj?.payload?.data)
        setTotalRows(obj?.payload?.total)
        props.setNewTicketCount(obj?.payload?.data?.length)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  const handleAssignAgent = (supportAgentIid) => {
    let paramData = {
      postData: {
        "support_id": supportID,
        "support_agent_id": supportAgentIid
      },
      tokenData: auth?.payload?.token
    }
    setButtonLoading(true)
    dispatch(assignSupportAgentApiAsync(paramData))
    .then(unwrapResult)
      .then((obj) => {
        getSupportData()
        setButtonLoading(false)
        handleOnCloseModal()
      }
      )
      .catch((obj) => {
        setButtonLoading(false)
      })
  }

  useEffect(() => {
    getSupportData()
  }, [currentPage, perPage, props?.endDate])
  return (
    <div className='suportTicket'>
      <section className='table-responsive'>
        <DataTable
          pagination
          columns={columns1}
          data={supportData}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
          progressPending={loading}
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "SuportticketModal" ? "suportticketModal" : ""}
        ids={modalDetail.flag === "SuportticketModal" ? "suportticketModal" : ""}
        // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
        child={
          modalDetail.flag === "SuportticketModal" ? (
            <SuportticketModal selectedProduct={selectedProduct} loading={buttonLoading} handleAssignAgent={(supportAgentIid)=>handleAssignAgent(supportAgentIid)} close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          <div className='modalHeader_'>
            <div className="common_">
              <h2 className="mainHeading">
                Assign Agent
              </h2>
              <button className="closeButton">
                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
              </button>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  )
}

export default NewTicket