import React, { useState } from 'react'
import { stepImgLg, rightArrowLg } from '../../../../utilities/images'
import { toast } from 'react-toastify';

const TwoStepModal = (props) => {
    const [activeData, setActiveData] = useState("");
    const [authenticatorChecked, setAuthnticatorChecked] = useState(false)
    const toastId = React.useRef(null)

    const handleNext = () => {

        if (authenticatorChecked) {
            props?.close()
        }
        else if (!activeData) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select Google Authenticator")
            }
        }
        else {
            props.details("QrCodeModal")
        }

    }

    console.log(authenticatorChecked, "authenticator checked")
    return (
        <>
            <div className='twoStepModal'>
                <p className='innerSubtext fw-normal'>First, download Google Authenticator from the <span to='' className="linkTxt">Google Play Store</span> or the <span to='' className="linkTxt">iOS App Store</span>.</p>
                <div className={`verificationDetails ${activeData === 'verificationDetails' ? "active" : ""}`}
                    onClick={() => setActiveData('verificationDetails')}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex align-items-center">
                                <span className="me-3"> <img src={stepImgLg} /></span>
                                <div className="">
                                    <h4 className="itemsText"></h4>
                                    <div className="">
                                        <p className="subheading_ fw-500">Google authenticator </p>
                                        <p className="textinner_">Instead of waiting for text messages, get verification codes from an authenticator app. It works even if your phone is offline.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='twoStepModalFoot'>
                    <div className='rolecheckbox_ mb-3'>
                        <input className="me-2" type="checkbox" id="view1" value={authenticatorChecked} onChange={() => setAuthnticatorChecked(!authenticatorChecked)} />
                        <label className='contentheadtext_' for="view1"> I will do it later</label>
                    </div>
                    <div className=''>
                        <button className='backBtn_ fw-normal' onClick={() => handleNext()}>Next <img src={rightArrowLg} alt="" /></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TwoStepModal