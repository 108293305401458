import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { fedeximg, userImage } from '../../../../utilities/images'
import moment from 'moment-timezone';

const NewpurchaseInvoice = (props) => {
    let data = props?.singleOrderData;
    const history = useHistory()
    const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });


    return (

        <div className='newpurchaseInvoice'>
            {/* Start FixedTopHeader */}
            <div className='modalHeader_'>
                <div className="common_">
                    <div className='col-lg-12'>
                        <div className='modal-header'>
                            <div className='leftInvoice'>
                            <i className="las la-angle-left" onClick={() => props.close()}></i>
                            <h2 className="innerSubheadNormal me-4">Invoice#{data?.invoice?.invoice_id}</h2>
                            <span className="pendingStatus">
                                {data?.status === 0 ? "REVIEWING" :
                                    data?.status === 1 ? "ACCEPTED BY SELLER" :
                                        data?.status === 2 ? "PREPAIRING" :
                                            data?.status === 3 ? "Ready for Pickup" :
                                                data?.status === 4 ? "PICKUP" :
                                                    data?.status === 5 ? "DELIVERED" :
                                                        data?.status === 6 ? "PICKUP BY CUSTOMER" :
                                                            data?.status === 7 ? "CANCELED" :
                                                                data?.status === 8 ? "REJECTED BY SELLER" :
                                                                    ""}
                            </span>
                            </div>
                            <button className="closeButton">
                                <i className="las la-times" onClick={() => props.close()}></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* End FixedTopHeader */}
            <div className="refundorderWrapper">
                <div className='container-fluid pt-3 pb-3'>
                    <div className='row justify-content-center'>
                        <div className='col-md-12'>
                            <div className="toporderCommon">
                                <div className="row">
                                    <div className='CustomerOnlinestatus_ '>
                                        <div className='invoiceHead mb-3'>
                                            <h3 className='invoiceHeading'>Invoice</h3>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='invoiceForm'>
                                                    <h5 className='semiboldHeading_'>Invoice from:</h5>
                                                    <p className='smalllightHead_'>{data?.sellerDetails?.user_profiles?.organization_name}</p>
                                                    <p className='smalllightHead_'>{data?.sellerDetails?.user_profiles?.full_phone_number}</p>
                                                    <p className='smalllightHead_'>{data?.sellerDetails?.user_profiles?.current_address?.street_address}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="invoiceForm">
                                                    <h5 className='semiboldHeading_'>Invoice details</h5>
                                                    <p className='invoiceHead'>
                                                        Invoice Id : <span>{data?.invoice?.invoice_id}</span>
                                                    </p>
                                                    <p className='invoiceHead'>
                                                        Created Date : <span>{data?.invoice?.created_date}</span>
                                                    </p>
                                                    <p className='invoiceHead'>
                                                        Canceled Date : <span>{data?.invoice?.delivery_date}</span>
                                                    </p>
                                                    <p className='invoiceHead fw-500'>
                                                        <span>{data?.invoice?.due_date}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customerInvoicetable mt-4">
                                    <div className="table-responsive">
                                        <table className="table  listItemsContainer">
                                            <thead className="tHead_">
                                                <th className="text-start">#</th>
                                                <th className="text-start">Descriptions</th>
                                                <th className="text-start">No. of Items</th>
                                                <th className="text-start">Rate</th>
                                                <th>Amount</th>
                                            </thead>
                                            <tbody>
                                                {data?.order_details?.length > 0 ?
                                                    data?.order_details?.map((val, ind) => {
                                                        return (
                                                            <tr key={ind} role="row">
                                                                <td className="text-start">1</td>
                                                                <td className="text-start productonlineDetail">
                                                                    <div className="name_content">
                                                                        <h6 className="contentheadtext_"> {val?.product_name}</h6>
                                                                        <span className="contentHeadtextlight_">Box of 25</span>
                                                                    </div>
                                                                </td>
                                                                <td className="text-start">
                                                                    <p className="contentheadtext_">{val?.qty}</p>
                                                                </td>
                                                                <td className="text-start">
                                                                    <p className="contentheadtext_">${val?.price}</p>
                                                                </td>
                                                                <td className="text-start">
                                                                    <p className="itemsTxt">${(val?.qty) * (val?.price)}</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : "No Purchase"}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="totalArea">
                                        <div className='row'>
                                            <div className="col-md-6 text-start">
                                                <div className="notes">
                                                    <textarea
                                                        className="h-auto"
                                                        placeholder="Notes"
                                                        id="floatingTextarea"
                                                        rows="10"
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-6 text-start">
                                                <div className="Customertotal">
                                                    <div className="totalDetails">
                                                        <h5 className="personalinfoHead">Sub Total</h5>
                                                        <h5 className="itemsTxt">${data?.actual_amount}</h5>
                                                    </div>
                                                    <div className="totalDetails">
                                                        <h5 className="personalinfoHead">Discount ({data?.coupon_code ? data?.coupon_code : "No Coupon Applied"})</h5>
                                                        <h5 className="itemsTxt Orangetext">${data?.discount}</h5>
                                                    </div>
                                                    <div className="totalDetails">
                                                        <h5 className="personalinfoHead">Shipping Charge</h5>
                                                        <h5 className="itemsTxt">${data?.delivery_option == 1 ? data?.delivery_charge : data?.shipping_charge}</h5>
                                                    </div>
                                                    <div className="totalDetails">
                                                        <h5 className="itemsText ">Total</h5>
                                                        <h5 className="itemsTxt">${data?.payable_amount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    data?.status == 0 ?
                                        <div className='invoiceBottombtn mt-5'>
                                            <button onClick={() => props.statusHandle(data?.id, 7)} className='disableBtn_ me-3' disabled={props.loadingState}>
                                                {props.loadingState && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                &nbsp;&nbsp;
                                                <span>Cancel Purchase Order</span>
                                            </button>
                                        </div>
                                        : data?.status == 1 || data?.status == 2 || data?.status == 3 || data?.status == 4 || data?.status == 5 ?
                                            <div className="carrierbox">
                                                <h5>Carriar:</h5>
                                                <div className="shippingContact carriercontact">
                                                    <div className="shipcontactLeft">
                                                        <img className='me-2' src={fedeximg} />
                                                        <div className="contctInfo">
                                                            <h4>FedEx</h4>

                                                        </div>
                                                    </div>
                                                    <button className="contactBtn">
                                                        <i className="las la-phone"></i>
                                                        <h3>Contact</h3>
                                                    </button>
                                                </div>
                                            </div> : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewpurchaseInvoice