import React from 'react'

const CreateNewModal = () => {
  return (
    <>
    <div className='pendingInvoice commonPadding_'>
    <div className='invoiceDetail'>
        <div className='row'>
            <div className='col-md-8'>
                <div className='logiHere'>
                    Your<br/>Logo
                </div>
                <div className='invoiceHead'>
                    <h3 className='invoiceHeading'>Invoice</h3>
                </div>
            </div>
            <div className="col-md-4">
                <div className="CustomerOnlinestatus_">
                    <h5 className='buyHeading fst-italic'>Invoice details</h5>
                    <p className='invoiceHead'>
                        Invoice Id : <span>025659165659165</span>
                    </p>
                    <p className='invoiceHead'>
                        Created Date : <span>05-04-2022</span>
                    </p>
                    <p className='invoiceHead'>
                        Due Date : <span>05-04-2022</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className='invoiceForm'>
        <h5 className='semiboldHeading_ text-uppercase'>Billed to:</h5>
        <p className='smalllightHead_'>Imani Olowe</p>
        <p className='smalllightHead_'>+123-456-7890</p>
        <p className='smalllightHead_'>63 Ivy Road, Hawkville, GA, USA 31036</p>
    </div>
    <div className="customerInvoicetable mt-4">
        <div className="table-responsive">
            <table className="table  listItemsContainer">
                <thead className="tHead_">
                    <th className="text-start">#</th>
                    <th className="text-start">Descriptions</th>
                    <th className="text-start">No. of Items</th>
                    <th className="text-start">Rate</th>
                    <th>Amount</th>
                </thead>
                <tbody>
                    <tr role="row">
                        <td className="text-start">1</td>
                        <td className="text-start productonlineDetail">
                            <div className="name_content">
                                <h6 className="contentheadtext_"> Ashton Classic</h6>
                                <span className="contentHeadtextlight_">Box of 25</span>
                            </div>
                        </td>
                        <td className="text-start">
                            <p className="contentheadtext_">16 Box</p>
                        </td>
                        <td className="text-start">
                            <p className="contentheadtext_">$250.00</p>
                        </td>
                        <td className="text-start">
                            <p className="itemsTxt">$4,063.20</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td className="text-start">1</td>
                        <td className="text-start productonlineDetail">
                            <div className="name_content">
                                <h6 className="contentheadtext_"> Ashton Classic</h6>
                                <span className="contentHeadtextlight_">Box of 25</span>
                            </div>
                        </td>
                        <td className="text-start">
                            <p className="contentheadtext_">16 Box</p>
                        </td>
                        <td className="text-start">
                            <p className="contentheadtext_">$250.00</p>
                        </td>
                        <td className="text-start">
                            <p className="itemsTxt">$4,063.20</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td className="text-start">1</td>
                        <td className="text-start productonlineDetail">
                            <div className="name_content">
                                <h6 className="contentheadtext_"> Ashton Classic</h6>
                                <span className="contentHeadtextlight_">Box of 25</span>
                            </div>
                        </td>
                        <td className="text-start">
                            <p className="contentheadtext_">16 Box</p>
                        </td>
                        <td className="text-start">
                            <p className="contentheadtext_">$250.00</p>
                        </td>
                        <td className="text-start">
                            <p className="itemsTxt">$4,063.20</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="totalArea">
            <div className='row'>
                <div className="col-md-6 text-start">
                    <div className="notes">
                        <textarea
                            className=""
                            placeholder="Notes"
                            id="floatingTextarea"
                            rows="9"
                        ></textarea>
                    </div>
                </div>
                <div className="col-md-6 text-start">
                    <div className="Customertotal">
                        <div className="totalDetails">
                            <h5 className="personalinfoHead">Sub Total</h5>
                            <h5 className="personalinfoHead">$7,363.20</h5>
                        </div>
                        <div className="totalDetails">
                            <h5 className="personalinfoHead">Discount ( MIDApril100)</h5>
                            <h5 className="personalinfoHead">$100.00</h5>
                        </div>
                        <div className="totalDetails">
                            <h5 className="personalinfoHead">Shipping Charrg</h5>
                            <h5 className="personalinfoHead">$12.00</h5>
                        </div>
                        <div className="totalDetails">
                            <h5 className="itemsText ">Total</h5>
                            <h5 className="itemsText">$7001.20</h5>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className='modalHeadingGrey mt-4'>Thank You</h1>
        </div>
        
    </div>

    </div>
    </>
  )
}

export default CreateNewModal