import React, { useEffect, useState } from 'react'
import { cardImg,  wallet, walletBig ,payWalletImg } from '../../../../utilities/images'
import Form from 'react-bootstrap/Form';
import { Link, useHistory } from 'react-router-dom';
import { systemConfigAsync, updateUserSettingAsync } from '../../systemConfig/systemConfigSlice';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import DashboardNavbar from '../DashboardNavbar';



const Payments = () => {
    const history = useHistory()
    const pathname = window.location.pathname;
    const [loading, setLoading] = useState(false)
    const [systemConfiguration, setSystemConfiguration] = useState("")
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const toastId = React.useRef(null)

    const handleCheckboxChange = (status, flag) => {
        // languagesData[index]["status"] = status == true ? false : true;
        let params

        if(flag == "accept_cash_payment"){
            params = {
                tokenData: auth?.payload?.token,
                postData: {
                    "app_name": "pos",
                    "accept_cash_payment": status ==  true? false : true
                },
            };
        }

        if(flag == "accept_card_payment"){
            params = {
                tokenData: auth?.payload?.token,
                postData: {
                    "app_name": "pos",
                    "accept_card_payment": status ==  true? false : true
                },
            };
        }
        dispatch(updateUserSettingAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg);
                }
                getSystemData();
            })
            .catch((obj) => {
                //
            })
    }

    const getSystemData = () => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                "app_name": "pos"
            },
        };
        setLoading(true)
        dispatch(systemConfigAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                setSystemConfiguration(obj?.payload)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
                                
    useEffect(() => {
        getSystemData()
    }, [])                  

    return (
        <>
        <DashboardNavbar title="Wallet Configuration" backShow={false} />



        <div className='payments'>
            {/* Start FixedTopHeader */}
            {/* <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            {
                                pathname === "/payments" ?
                                    <h2 className="mainHeading">
                                        <span onClick={() => history.push('/plans')}>
                                            <i className="las la-angle-left me-3 directionArrow"></i>
                                        </span>
                                        <span>Payments</span>
                                    </h2> :
                                    <h2 className="mainHeading">
                                        <span>System Config / <span className="innerSubtext">Wallet Config</span> </span>
                                    </h2>
                            }
                        </div>
                    </div>
                </div>
            </div> */}                 
            {/* End FixedTopHeader */}
            <div className='paymentContainer'>
                <div className='paymentArea'>
                    <div className='row'>
                        <div className='col-md-10'>
                            <div className='d-flex align-items-start'>
                                <img src={walletBig} className='paymentTypeIcon me-3' />
                                <div className=''>
                                    <h3 className='textInnerDark'>Pay by JBR Coin</h3>
                                    <h4 className='subtextDark_ mt-2 mb-1'>System: Online Shop, POS</h4>
                                    <p className='subtextSmall_'>Default payment </p>
                                    <p className='subtext_ mt-3 mb-3'>You need to provide some additional information about your business to start receiving payouts from Shopify Payments.</p>
                                    {/* <button className='primary_btn w-auto'>Complete Account Setup</button> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-2 text-end'>
                        <div className="form-check form-switch">
                            <input type="checkbox"
                            defaultChecked={true}
                            disabled/>
                        </div>
                    </div>  */}
                    </div>
                </div>
                <div className='paymentArea'>
                    <div className='row'>
                        <div className='col-md-10'>
                            <div className='d-flex align-items-start'>
                                <img src={payWalletImg} className='paymentTypeIcon me-3' />
                                <div className=''>
                                    <h3 className='textInnerDark'>Pay by Cash</h3>
                                    <h4 className='subtextDark_ mt-2 mb-1'>System: POS</h4>
                                    {/* <p className='subtextSmall_'>Default payment </p> */}
                                    <p className='subtext_ mt-3 mb-3'>You need to provide some additional information about your business to start receiving payouts from Shopify Payments.</p>
                                    {/* <button className='primary_btn w-auto'>Setup POS</button> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 text-end'>
                            <div className="form-check form-switch">
                                <input type="checkbox" checked={systemConfiguration?.accept_cash_payment} onChange={() => handleCheckboxChange(systemConfiguration?.accept_cash_payment, "accept_cash_payment")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='paymentArea'>               
                    <div className='row'>
                        <div className='col-md-10'>
                            <div className='d-flex align-items-start'>
                                <img src={cardImg} className='paymentTypeIcon me-3' />
                                <div className=''>
                                    <h3 className='textInnerDark'>Pay by card</h3>
                                    <h4 className='subtextDark_ mt-2 mb-1'>System: POS</h4>
                                    <p className='subtext_ mt-3 mb-3'>You need to provide some additional information about your business to start receiving payouts from Shopify Payments.</p>

                                    {/* <h4 className='subtextDark_ mt-2 mb-1'>System: Pay by card</h4>
                                <p className='subtextSmall_'>Cards, Bank transfer and all</p>
                                <p className='subtext_ mt-3 mb-3'>Sila is a powerful payment processor that provides a seamless checkout experience, various payments options, and fraud protection.</p>
                                <ul className='paymentPoints'>
                                    <li>Pay 2.9% + $0.30 per online transaction. Custom rates are available for qualified businesses.</li>
                                    <li>Sell subscriptions: collect your customers’ card details and charge automatically at a later time, with no additional fees for subscription charges.</li>
                                    <li>Always feel secure with advanced fraud protection that automatically blocks high-risk payments.</li>
                                </ul>
                                <p className='subtext_'>
                                    By enabling payments through Sila, you will be accepting Stripe Services Agreement. Please refer to the list of Prohibited Businesses before registering for and opening a Stripe account.
                                </p> */}
                                    {/* <ul className='paymentTypeMenus'>
                                        <li className='paymentTypeItems'>
                                            <Link to="#" className=''>
                                                <img src={visa} alt='Visa' />
                                            </Link>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 text-end'>
                            <div className="form-check form-switch">
                                <input type="checkbox" checked={systemConfiguration?.accept_card_payment} onChange={() => handleCheckboxChange(systemConfiguration?.accept_card_payment, "accept_card_payment")}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button className='primary_btn w-auto' type="button" onClick={() => history.push('/plans')}>Save</button> */}

            </div>

        </div>
        </>
    )
}

export default Payments