import React, { useState } from "react";
import CustomModal from "../../components/shared/CustomModal";
import NewPromotionModal from "./newPromotionModal";
import {
  addWhite,
  b2b,
  b2c,
  facebook,
  instaIcon,
  linkedin,
} from "../../../utilities/images";
import ConnectAccountModal from "./connectAccountModal";

const ConnectedAccount = () => {
  const [key, setKey] = useState(Math.random());
  const data = Array.from(Array(5).keys());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  return (
    <div className="connectedAccount">
      {/* Start TopFixedHeader */}
      <div className="topHeader_">
        <div className="topStickyHeader_ mb-3">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="mainHeading">
                Campaigns /{" "}
                <span className="innerSubtext">Promotional Activities</span>
              </h2>
            </div>
            <div className="col-md-6 text-end">
              <button
                className="inActiveBtn_ fw-normal pt-2 pb-2"
                onClick={() => {
                  setModalDetail({ show: true, flag: "ConnectAccount" });
                  setKey(Math.random());
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-2"
                >
                  <path
                    d="M3.5 11C3.5 9.10025 3.50106 7.72573 3.64199 6.67754C3.78098 5.64373 4.04772 5.00253 4.52513 4.52513C5.00253 4.04772 5.64373 3.78098 6.67754 3.64199C7.72573 3.50106 9.10025 3.5 11 3.5H13C14.8998 3.5 16.2743 3.50106 17.3225 3.64199C18.3563 3.78098 18.9975 4.04772 19.4749 4.52513C19.9523 5.00253 20.219 5.64373 20.358 6.67754C20.4989 7.72573 20.5 9.10025 20.5 11V13C20.5 14.8998 20.4989 16.2743 20.358 17.3225C20.219 18.3563 19.9523 18.9975 19.4749 19.4749C18.9975 19.9523 18.3563 20.219 17.3225 20.358C16.2743 20.4989 14.8998 20.5 13 20.5H11C9.10025 20.5 7.72573 20.4989 6.67754 20.358C5.64373 20.219 5.00253 19.9523 4.52513 19.4749C4.04772 18.9975 3.78098 18.3563 3.64199 17.3225C3.50106 16.2743 3.5 14.8998 3.5 13V11Z"
                    stroke="white"
                  />
                  <path d="M12 8L12 16" stroke="white" strokeLinejoin="round" />
                  <path d="M16 12L8 12" stroke="white" strokeLinejoin="round" />
                </svg>
                Add New Connection
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* End TopFixedHeader */}
      {/* Start ConnectedData */}
      <section className="connectedData">
        {data.length > 0 ? (
          <>
            {data.map((val, index) => {
              return (
                <div className="connectedDataArea mb-2" key={index}>
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <img
                        src={
                          index === 0
                            ? [instaIcon]
                            : index === 1
                            ? [facebook]
                            : index === 2
                            ? [linkedin]
                            : index === 3
                            ? [b2b]
                            : [b2c]
                        }
                        alt=""
                        className="me-2"
                      />
                      <span className="mainlightHead">
                        {index === 0
                          ? "Instagram/JOBR_Offical"
                          : index === 1
                          ? "Facebook/JOBR_Offical"
                          : index === 2
                          ? "Linkedin/JOBR_Offical"
                          : index === 3
                          ? "B2B/JOBR_Offical"
                          : "B2C/JOBR_Offical"}
                      </span>
                    </div>
                    <div className="col-md-2">
                      <p className="commonsmallgeryHead_">Since Nov, 22</p>
                    </div>
                    <div className="col-md-2 text-end">
                      <div className="form-check form-switch me-2 mb-0">
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}
      </section>
      {/* End ConnectedData */}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={true}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "ConnectAccount" ? "connectAccountModal" : ""}
        size={modalDetail.flag === "ConnectAccount" ? "xl" : "xl"}
        child={
          modalDetail.flag === "ConnectAccount" ? (
            <ConnectAccountModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default ConnectedAccount;
