import React, { useState, useEffect } from 'react'
import { pinlocate, addloaction, building_, cardemail_, cardphone_ } from '../../../../utilities/images'
import CustomModal from '../../../components/shared/CustomModal';
import AddnewaddressModal from './addnewaddressModal';
import { selectLoginAuth, getAddressApiAsync } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';

const ShippingaddressModal = (props) => {
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState();
  const [activeTab, setActiveTab] = useState("")
  const dispatch = useDispatch()
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };


  const sendSeppingAddress = (address) => {
    let sendAddress = {
      firstName: auth?.payload?.user?.user_profiles?.firstname,
      lastName: auth?.payload?.user?.user_profiles?.lastname,
      company_name: auth?.payload?.user?.user_profiles?.organization_name,
      email: auth?.payload?.user?.email ? auth?.payload?.user?.email : auth?.payload?.email,
      mobile: auth?.payload?.user?.user_profiles?.full_phone_number,
      full_address: address?.street_address
    }
    props.shippingAddress(sendAddress, "shipping_address");
    props.close();

  }

  const getShippingAddressList = () => {
    setLoading(true)
    let params = {
      token: auth?.payload?.token,
      sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
    }
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setShippingAddress(obj?.payload?.shipping)
        setLoading(false);
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getShippingAddressList();
  }, [])

  return (
    <>
      {
        loading ?
          <div className="pageLoader d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          :
          <div className='businesscard_ commonBody'>
            <form className='shippingAddress horizontalForms'>
              {/* {addressLength > 0 ?  */}
              <>
                {shippingAddress?.length > 0 ?
                  <>
                    {shippingAddress?.map((item, index) => {
                      return (
                        <div className={`userProfileinfo_ mb-3 ${activeTab === `shapping${item?.id}` ? 'active' : ''}`} key={index}
                          onClick={() => { setSelectedShippingAddress(item); setActiveTab(`shapping${item?.id}`) }}>
                          <div className="userProfileinforight_">
                            <h3 className='userhaeding_'>{auth?.payload?.user?.user_profiles?.firstname + " " + auth?.payload?.user?.user_profiles?.lastname}</h3>
                            <ul className='contentextLightMenus mt-2'>
                              <li className='contentextLightItems'>
                                <img className='cradInfo_' src={building_} />
                                <span className='ms-2'>{auth?.payload?.user?.user_profiles?.organization_name}</span>
                              </li>
                              <li className='contentextLightItems'>
                                <img className='cradInfo_' src={pinlocate} />
                                <span className='ms-2'>{item?.street_address}</span>
                              </li>
                              <li className='contentextLightItems'>
                                <img className='cradInfo_' src={cardemail_} />

                                <span className='ms-2'>{auth?.payload?.user?.email}</span>
                              </li>
                              <li className='contentextLightItems'>
                                <img className='cradInfo_' src={cardphone_} />

                                <span className='ms-2'>{auth?.payload?.user?.user_profiles?.full_phone_number}</span>
                              </li>
                            </ul>
                          </div>

                        </div>
                      )
                    })}
                  </>
                  : <></>}
              </>
              {/* : ""} */}
              <div className='addAddress_ mt-3' >

                <img className='addloacte_ mb-2' src={addloaction} onClick={(shippingAddress?.length < 5) ? () => {
                  setModalDetail({ show: true, flag: "AddnewAddress" });
                  setKey(Math.random());
                } : void (0)} />
                <p className='addinfo_'>Add Address</p>
                <p className='addinfo_' >({shippingAddress?.length}/5)</p>
              </div>

            </form>
            <div className='sendBtn_ mt-5'>
              <button className='submitBtn' disabled={!activeTab} onClick={() => sendSeppingAddress(selectedShippingAddress)}>Send</button>
            </div>
          </div>}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "AddnewAddress" ? "commonWidth customContent" : ""}
        ids={modalDetail.flag === "AddnewAddress" ? "addnewAddress" : ""}

        child={
          modalDetail.flag === "AddnewAddress" ? (
            <AddnewaddressModal close={() => handleOnCloseModal()} shippingAddress={() => getShippingAddressList()}/>
          ) :
            (
              ""
            )
        }
        header={
          <div className='modalHeader_'>
            <div className="common_">
              {modalDetail.flag === "AddnewAddress" ?
                <h2 className="modalHeading">
                  New Shipping Address
                </h2>
                : ""
              }
              <button className="closeButton">
                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
              </button>
            </div>
          </div>
        }

        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default ShippingaddressModal