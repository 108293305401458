import React from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const EditServiceHeader = (props) => {
  const history = useHistory();
  const location = useLocation();
 

  const handleSave = () => {
    props.save();
  };
  return (
    <div className="topHeader_">
      <div className="productTopStickyHeader_ mb-3">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h2 className="innerSubheadNormal fw-semibold">
              <span onClick={() => history.goBack()}>
                <i className="las la-angle-left me-3 directionArrow"></i>
              </span>

              <span>
                {" "}
                {location?.pathname == "/editServiceSupply"
                  ? "Edit Service Supply"
                  : "Edit Service"}
              </span>
            </h2>
          </div>
          <div className="col-md-6 text-end">
            <button
              className="discardBtn me-3"
              type="button"
              onClick={() => history.goBack()}
            >
              Discard
            </button>
            <button
              className="saveBtn "
              type="button"
              onClick={() => handleSave()}
              disabled={props?.successLoader}
            >
              {props?.successLoader && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditServiceHeader;
