import React, { useState } from 'react'
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import { calendar, productList1, productList2, productList3, productList4 } from '../../../utilities/images'
import CategoryListtable from './categoryListtable';
import CategoryTableview from './categoryTableview';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { logDOM } from '@testing-library/react';

const CategoryListview = () => {
    const location = useLocation();
    const pathName = location.pathname;
    const { flag, serviceType } = useParams();
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [activeTab, setActiveTab] = useState("MonthlyBilling");
    const [monthlyPlan, setMonthlyPlan] = useState([])
    const [yearlyPlan, setYearlyPlan] = useState([])

    return (
        <div className='categoryListview  brandNewlist_'>
            <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                {serviceType === "service" ? "Service /" : "Product /"}
                                <span className="innerSubtext">
                                    {/* {pathName === "/service-subcategory" ? "Sub-Categories" :
                                        pathName === "/categoryListview/sub_category" ? "Sub-Categories" :
                                            pathName === "/categoryListview/brand" ? "Brands List"
                                                : "Categories"} */}
                                    {flag === "sub_category" ? " Sub-Categories" :
                                            flag === "brand" ? " Brands List"
                                                : " Categories"}
                                </span> </h2>
                        </div>

                    </div>
                </div>
            </div>
          { /*  <div className='tableFilters'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="calenderIcon">
                            <img src={calendar} alt="calender" />
                            <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" defaultValue="Country">
                            <option value="Country">
                                Country
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" defaultValue="State">
                            <option value="State">
                                State
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" defaultValue="Id type">
                            <option value="Id type">
                                Id type
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                </div>
            </div> */}
            <div className='productListTab'>
                <button className={`plansBtns ${activeTab === 'MonthlyBilling' ? 'active' : ""}`}
                    onClick={() => setActiveTab('MonthlyBilling')}
                >
                    <img src={productList1} alt="image" className='showImage' />
                    <img src={productList2} alt="image" className='hideImage' />
                </button>
                <button className={`plansBtns ${activeTab === 'AnnuallyBilling' ? 'active' : ""}`}
                    onClick={() => setActiveTab('AnnuallyBilling')}>
                    <img src={productList3} alt="image" className='hideImage' />
                    <img src={productList4} alt="image" className='showImage' />

                </button>
            </div>
            <div className='plansAreasContainer'>
                {activeTab == 'MonthlyBilling'
                    ?
                    <CategoryListtable data={monthlyPlan} />
                    : activeTab == "AnnuallyBilling" ? 
                        <CategoryTableview data={yearlyPlan} />
                        : ""
                }
            </div>
        </div>
    )
}

export default CategoryListview