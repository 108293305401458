import React, { useState } from "react";
import { Form } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { updateSellerInfoApiAsync } from "../../dashboard/dashboardSlice";
import { toast } from "react-toastify";
const EditaddressModal = (props) => {
  const pathname = window.location.pathname;
  const [hide, setHide] = useState(false)
  const [displayValue, setDisplayValue] = useState()
  const { addressDataModal, addressId, close, getAddressMerchant } = {
    ...props,
  };
  const toastId = React.useRef(null)
  const [addressType, setAddressType] = useState(
    addressDataModal?.address_type
  );
  const [address, setAddress] = useState("");
  const [formattedAddress, setFormattedAddress] = useState(
    addressDataModal?.format_address
  );
  const [latitude, setLatitude] = useState(addressDataModal?.latitude);
  const [longitude, setLongitude] = useState(addressDataModal?.longitude);
  const [countryValue, setCountryValue] = useState(addressDataModal.country);
  const [cityValue, setCityValue] = useState(addressDataModal?.city);
  const [countryCode, setCountryCode] = useState(
    addressDataModal?.country_code
  );
  const [stateCode, setStateCode] = useState(addressDataModal?.state_code);
  const [stateValue, setStateValue] = useState(addressDataModal?.state);
  const [aptValue, setAptValue] = useState(addressDataModal?.apt);
  const [zipCode, setZipCode] = useState(addressDataModal?.zipcode);

  //
  const extractStreetAddress = (address) => {
    // Extract the street address from the address
    const parts = address.split(",");
    if (parts.length >= 3) {
      return `${parts[0].trim()}, ${parts[1].trim()}`;
    }
  };
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const address2 = addressDataModal;
  const [editAdress, setEditAddress] = useState({
    allow_local_pickup: address2?.allow_local_pickup,
    allow_jobr_delivery: address2?.allow_jobr_delivery,
    allow_local_drop_off: address2?.allow_local_drop_off,
    allow_shipping: address2?.allow_shipping,
  });
  const handleChange = (e) => {
    console.log(e.target.checked, e.target.name);
    setEditAddress((prevEditAddress) => ({
      ...prevEditAddress,
      [e.target.name]: !prevEditAddress[e.target.name],
    }));
  };
  const handleSave = () => {
    if (!formattedAddress) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your address")
      }
      return
    }
    if (!countryValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your country")
      }
      return
    }
    if (!stateValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your state")
      }
      return
    }
    if (!cityValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your city")
      }
      return
    }
    if (!zipCode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your zipcode")
      }
      return
    }
    if (!latitude && !longitude) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Incorrect Address")
      }
      return
    }
    const checkBox = { ...editAdress };

    for (let i in checkBox) {
      if (checkBox[i] === true) {
        checkBox[i] = "1";
      } else {
        checkBox[i] = "0";
      }
    }
    let params = {
      postData: {
        id: address2.id,
        ...checkBox,
        address_type: addressType,
        street_address: address
          ? address
          : extractStreetAddress(formattedAddress),
        country: countryValue,
        state: stateValue,
        city: cityValue,
        zipcode: zipCode,
        longitude: longitude,
        latitude: latitude,
        country_code: countryCode,
        state_code: stateCode,
        format_address: formattedAddress,
        "apt": aptValue
      },
      tokenData: auth?.payload?.token,
    };
    if (aptValue) {
      params = {
        postData: {
          ...params.postData,
          "apt": aptValue
        },
        tokenData: auth?.payload?.token
      }
    }
    console.log(params.postData)

    setLoading(true);
    dispatch(updateSellerInfoApiAsync(params))
      .then(unwrapResult)
      .then(() => {
        setLoading(false);
        getAddressMerchant();
        close();
      }).catch(() => {
        setLoading(false)
      });
  };
  //

  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const autoCompleteHandleChange = (address) => {
    // setAddress(address);
    setFormattedAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        // fillAddressDetails(results);
        setLatitude(results[0].geometry.location.lat());
        setLongitude(results[0].geometry.location.lng());
      })
      .catch((error) => {
      });
    setZipCode("");
    setCityValue("");
    setStateValue("");
    setCountryValue("");
    setAptValue("");
    setAddress("");
  };

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        fillAddressDetails(results);
        setLatitude(results[0].geometry.location.lat());
        setLongitude(results[0].geometry.location.lng());
        setDisplayValue(results[0]?.formatted_address?.split(',')[0])
        setHide(true)
      })
      .catch((error) => { });
  };

  const fillAddressDetails = (results) => {
    // setAddress(results[0].formatted_address);
    setFormattedAddress(results[0].formatted_address);

    console.log(results, "formatted address");
    for (let j = 0; j < results[0].address_components.length; j++) {
      console.log(results[0].address_components[j])
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCode(results[0].address_components[j].short_name);
      } else if (results[0].address_components[j].types[0] == "locality") {
        setCityValue(results[0].address_components[j].long_name);
      } else if (
        results[0].address_components[j].types[0] ==
        "administrative_area_level_1" ||
        results[0].address_components[j].types[0] ===
        "administrative_area_level_3" ||
        results[0].address_components[j].types[0] === "locality"
      ) {
        setStateValue(results[0].address_components[j].long_name);
        setStateCode(results[0].address_components[j]?.short_name);
      } else if (results[0].address_components[j].types[0] == "country") {
        setCountryValue(results[0].address_components[j].long_name);
        setCountryCode(results[0].address_components[j]?.short_name);
      } else if (results[0].address_components[j].types[0] == "street_number") {
        setAptValue(results[0].address_components[j].long_name);
      } else if (results[0].address_components[j].types[0] == "route") {
        setAddress(results[0].address_components[j].long_name);
      }
    }
  };
  return (
    <div className="editaddressModal">
      <>
        <div className="commonmodal-header mb-4">
          <div className="addheadLeft_">
            <h4 className="modalHeading_">Edit Address </h4>

            {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
          </div>
          <div className="addheadRight">
            <button
              className="addressdiscardBtn me-2"
              type="submit"
              onClick={() => {
                close();
              }}
            >
              Discard
            </button>

            <button
              className="addresssaveBtn bluebgcolor_"
              onClick={handleSave}
              disabled={loading}
              type="submit"
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Save</span>
            </button>
          </div>
        </div>
        <div className="addaddressModal_">
          <Form className="addressForm_">
            <div class="mb-4 form-group">
              <label class="contentheadtext_ mb-3 fw-semibold">
                Identify As
              </label>
              <select
                class="customform-control select"
                value={addressType}
                onChange={(e) => setAddressType(e.target.value)}
              >
                <option value="store">Store</option>
                <option value="ware_house">Wear House</option>
              </select>
            </div>

            <div className="mb-4 form-group">
              <label className="deatilsHead mb-3">Street Address</label>
              <div className="inputGroup">
                {
                  hide ?
                    <span
                      className="customform-control countrytags_ w-100"
                      onClick={() => setHide(!hide)}
                    >{displayValue}</span>
                    :
                    <PlacesAutocomplete
                      className=""
                      autoComplete="off"
                      value={formattedAddress}
                      onChange={autoCompleteHandleChange}
                      onSelect={autoCompleteHandleSelect}
                      searchOptions={{
                        componentRestrictions: {
                          country: ["us", "ind"],
                        },
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Street Address",
                              className: "location-search-input customform-control countrytags_",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                  backgroundColor: "#41b6e6",
                                  cursor: "pointer",
                                }
                                : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={index}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                }
              </div>
            </div>
            <div className="mb-4 form-group">
              <label className="deatilsHead mb-3">Apt.</label>
              <input
                className="customform-control id_password"
                placeholder="Apt."
                name={generateRandomName}
                autoComplete="new-password"
                type="text"
                value={aptValue}
                onChange={(e) => setAptValue(e.target.value)}
              />
            </div>
            <div className="mb-4 form-group">
              <label className="deatilsHead mb-3">Country</label>
              <input
                className="customform-control id_password"
                placeholder="Country"
                name={generateRandomName}
                autoComplete="new-password"
                type="text"
                value={countryValue}
                onChange={(e) => setCountryValue(e.target.value)}
              />
              {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
            </div>
            <div className="mb-4 form-group">
              <label className="deatilsHead mb-3">State</label>
              <input
                className="customform-control id_password"
                placeholder="State"
                name={generateRandomName}
                autoComplete="new-password"
                type="text"
                value={stateValue}
                onChange={(e) => setStateValue(e.target.value)}
              />
              {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
            </div>
            <div className="mb-4 form-group">
              <label className="deatilsHead mb-3">City</label>
              <input
                className="customform-control id_password"
                placeholder="City"
                name={generateRandomName}
                autoComplete="new-password"
                type="text"
                value={cityValue}
                onChange={(e) => setCityValue(e.target.value)}
              />

              {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
            </div>
            <div className="mb-4 form-group">
              <label className="deatilsHead mb-3">Zip code</label>
              <input
                type="number"
                className="customform-control"
                name={generateRandomName}
                autoComplete="new-password"
                placeholder="Zip code"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>

            <div class="mb-4 mt-4 form-group">
              <div className="shippingavail_">
                <h5 className="testshiphead">
                  Shipping & Pickup Availability{" "}
                  <span className="optionalhead_">Optional</span>
                </h5>
                <div className="shippCheck_ mt-3">
                  <div className="inputCheck_ mb-2">
                    <input
                      type="checkbox"
                      id="custom-checkbox-selectAll2"
                      className="checkBox "
                      name="allow_local_pickup"
                      checked={editAdress?.allow_local_pickup}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="custom-checkbox-selectAll2"
                      className="checkBoxTxt "
                    >
                      Local Pickup
                    </label>
                  </div>
                  <div className="inputCheck_ mb-2">
                    <input
                      type="checkbox"
                      id="custom-checkbox-selectAll3"
                      className="checkBox "
                      name="allow_jobr_delivery"
                      checked={editAdress?.allow_jobr_delivery}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="custom-checkbox-selectAll3"
                      className="checkBoxTxt "
                    >
                      JOBR Delivery
                    </label>
                  </div>
                  <div className="inputCheck_ mb-2">
                    <input
                      type="checkbox"
                      id="custom-checkbox-selectAll4"
                      className="checkBox "
                      name="allow_local_drop_off"
                      checked={editAdress?.allow_local_drop_off}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="custom-checkbox-selectAll4"
                      className="checkBoxTxt "
                    >
                      Local Drop-off
                    </label>
                  </div>
                  <div className="inputCheck_ mb-2">
                    <input
                      type="checkbox"
                      id="custom-checkbox-selectAll5"
                      checked={editAdress?.allow_shipping}
                      className="checkBox "
                      name="allow_shipping"
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="custom-checkbox-selectAll5"
                      className="checkBoxTxt "
                    >
                      Shipping
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </>
    </div>
  );
};

export default EditaddressModal;
