import React, { useEffect, useState } from 'react'
import { infoImg, pos } from '../../../utilities/images';
import FixedPrice from './fixedPrice'
import CustomPrice from './customPrice';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

var changingSellingPrice = false;
var changingMargin = false;
const ProductPrice = (props) => {
    const costPrices = props?.costPrice ? props?.costPrice : "";
    const location = useLocation();
    const selectedServiceDetails = location?.state;
    const [activeData, setActiveData] = useState("fixedPrice");
    const [prices, setPrices] = useState([]);


    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const [priceJobrPos, setPriceJobrPos] = useState({
        "app_name": "",
        "price_type": "",
        "selling_price": "",
        "margin_percentage": ""
    });

    const [priceJobrBtoc, setPriceJobrBtoc] = useState({
        "app_name": "",
        "price_type": "",
        "selling_price": "",
        "margin_percentage": ""
    });
    const [prisejobrBtobFixed, setPriceJobrBtobFixed] = useState({});
    const [priceJobrBtobCustom, setPriceJobrBtobCustom] = useState({});
    // console.log("priceJobrPos", priceJobrPos, priceJobrBtoc,prisejobrBtobFixed, priceJobrBtobCustom);

    // useEffect(() => {
    //    if(props.activeBtob == true){
    //     setActiveData("fixedPrice")
    //    }else {
    //     setActiveData("");
    //    }
    // },[])

    useEffect(() => {
        removePriceJobrPos("pos");
    }, [props.activePos == false])

    useEffect(() => {
        removePriceJobrPos("b2c");
    }, [props.activeBtoc == false]);

    useEffect(() => {
        removePriceJobrPos("b2b");
    }, [props.activeBtob == false]);

    useEffect(() => {
        removePriceJobrPos("onlinestore");
    }, [props.activeOnlineStore == false]);

    const removePriceJobrPos = (name) => {
        let removeobj = [];
        if (name == "onlinestore") {
            removeobj = prices.filter(item => item.app_name == "pos");
            setPrices(removeobj);
        }
        else {
            if (prices.length > 0) {
                removeobj = prices.filter(item => item.app_name != name);
                setPrices(removeobj);
            }
        }
        props.prices(removeobj);

    }

    const handleChangePriceJobrPos = (e) => {
        const { name, value } = e.target;
        let data = { ...priceJobrPos, [name]: value, app_name: "pos", price_type: "fixed" }
        if(name == 'selling_price'){
            changingSellingPrice = true;
        }

        if(name == 'margin_percentage'){
            changingMargin = true;
        }
        setPriceJobrPos(data);
        // props.prices(data);
        checkAndAdd(data);
    }

    const handleChangePriceBtoc = (e) => {
        const { name, value } = e.target;
        let data = { ...priceJobrBtoc, [name]: value, app_name: "b2c", price_type: "fixed" }
        if(name == 'selling_price'){
            changingSellingPrice = true;
        }

        if(name == 'margin_percentage'){
            changingMargin = true;
        }
        setPriceJobrBtoc(data);
        // props.prices(data);
        checkAndAdd(data);
    }

    const handleFixedPriceBtob = (e) => {
        setPriceJobrBtobFixed(e);
        checkAndAdd(e);
    }

    const handleCustomPriceBtob = (e) => {
        const custompriceBtob = {
            "app_name": "b2b",
            "price_type": "quantity_base",
            "bundle_price": e,
        }
        setPriceJobrBtobCustom(custompriceBtob);
        checkAndAdd(custompriceBtob);
    }

    useEffect(() => {
        // setPrices(props?.updatedPrice)
        setPriceJobrPos(selectedServiceDetails?.supply_prices?.find(item => item?.app_name === "pos"))
        setPriceJobrBtoc(selectedServiceDetails?.supply_prices?.find(item => item?.app_name === "b2c"))
        if (selectedServiceDetails?.supply_prices?.find(item => item?.app_name === "b2b" && item?.price_type === "quantity_base")) {
            setActiveData("quantity_base")
        }
    }, [])

    useEffect(() => {
        if (costPrices !== undefined && costPrices !== 0 && priceJobrPos?.selling_price && changingSellingPrice) {
            changingSellingPrice = false;
            const posMargin = ((priceJobrPos?.selling_price - costPrices) / costPrices) * 100;
            const sellingPrice = costPrices + (costPrices * (priceJobrPos?.margin_percentage / 100));
            const newPricePos = {
                ...priceJobrPos,
                // sellingPrice: 0,
                margin_percentage: priceJobrPos?.selling_price ? posMargin.toFixed(2) : ""
            };
            setPriceJobrPos(newPricePos);
            props.prices(newPricePos);
            checkAndAdd(newPricePos);
         
        }

    }, [costPrices, priceJobrPos?.selling_price]);

    useEffect(() => {
        if (costPrices !== undefined && costPrices !== 0 && priceJobrPos?.margin_percentage && changingMargin) {
            changingMargin = false;
            const sellingPrice = parseFloat(costPrices) + (parseFloat(costPrices) * (parseFloat(priceJobrPos?.margin_percentage) / 100));
            const newPricePos = {
                ...priceJobrPos,
                selling_price: sellingPrice ? sellingPrice.toFixed(2) : "",
            };
            setPriceJobrPos(newPricePos);
            props.prices(newPricePos);
            checkAndAdd(newPricePos);
        }

    }, [costPrices, priceJobrPos?.margin_percentage]);





    useEffect(() => {
        // Recalculate margin_percentage when costPrices changes
        if (costPrices !== undefined && costPrices !== 0 && priceJobrBtoc?.selling_price && changingSellingPrice) {
           changingSellingPrice = false
            const btocMargin = ((priceJobrBtoc?.selling_price - costPrices) / costPrices) * 100;
            const newPriceBtoc = {
                ...priceJobrBtoc,
                margin_percentage: priceJobrBtoc?.selling_price ? btocMargin.toFixed(2) : ""
            };
            // console.log(newPriceBtoc,"newPriceBtoc");
            props.prices(newPriceBtoc);
            setPriceJobrBtoc(newPriceBtoc)
            checkAndAdd(newPriceBtoc);
        }
    }, [costPrices, priceJobrBtoc?.selling_price]);

    useEffect(() => {
        if (costPrices !== undefined && costPrices !== 0 && priceJobrBtoc?.margin_percentage && changingMargin) {
            changingMargin = false;
            const sellingPrice = parseFloat(costPrices) + (parseFloat(costPrices) * (parseFloat(priceJobrBtoc?.margin_percentage) / 100));
            const newPriceBtoc = {
                ...priceJobrBtoc,
                selling_price: sellingPrice ? sellingPrice.toFixed(2) : "",
            };
            setPriceJobrBtoc(newPriceBtoc);
            props.prices(newPriceBtoc);
            checkAndAdd(newPriceBtoc);
        }

    }, [costPrices, priceJobrBtoc?.margin_percentage]);

    function checkAndAdd(obj) {
        let found;
        for (let i = 0; i < prices.length; i++) {
            if (prices[i]['app_name'] === obj.app_name) {
                prices[i] = obj;
                found = true;
                break;
            }
        }
        if (!found) {
            prices.push(obj)
        }

   
        props.prices(prices);
    }

    return (
        <>
            {/* Start JobrPos Area */}
            {props.activePos == true ?
                <div className='outerArea mt-3'>
                    <h2 className='headingBlue mb-4'>
                        JOBR POS <span className='linkTxt'><i>(Physical store)</i></span>
                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-2' />
                    </h2>
                    <form className='inventory row'>
                        <div className='form-group mb-3 col-md-6'>
                            <label className="deatilsHead mb-2 fw-normal">Selling Price  for Customer
                            </label>
                            <div className='inputGroup'>
                                <input
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="125"
                                    name="selling_price"
                                    type="number"
                                    value={priceJobrPos?.selling_price}
                                    onChange={(e) => handleChangePriceJobrPos(e)}
                                />
                                <span className='dollrsign_'>$</span>
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label className="deatilsHead mb-2 fw-normal">Profit Margin
                            </label>
                            <div className='inputGroup'>
                                <input
                                // disabled
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="10"
                                    name="margin_percentage"
                                    type="number"
                                    value={(priceJobrPos?.margin_percentage)?.toString()?.replace(/\.00$/, "")}
                                    onChange={(e) => handleChangePriceJobrPos(e)}
                                />
                                <span className='percentagesign_'>%</span>
                            </div>
                        </div>
                    </form>
                </div>
                : ""}
            {/* End JobrPos Area */}
            {/* Start JobrB2C Area */}
            {props.activeBtoc == true ?
                <div className='outerArea mt-3'>
                    <h2 className='headingBlue mb-4'>
                        JOBR B2C <span className='linkTxt'><i>(Online Store)</i></span>
                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-2' />
                    </h2>
                    <form className='inventory row'>
                        <div className='form-group mb-3 col-md-6'>
                            <label className="deatilsHead mb-2 fw-normal">Selling Price  for Customer
                            </label>
                            <div className='inputGroup'>
                                <input
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="125"
                                    name="selling_price"
                                    type="number"
                                    value={priceJobrBtoc?.selling_price}
                                    onChange={(e) => handleChangePriceBtoc(e)}
                                //onChange={(e, name = "b2c", type = "fixed")=> {setPriceJobrBtoc({...priceJobrBtoc, app_name: name ,price_type: type,selling_price : e.target.value})}}
                                />
                                <span className='dollrsign_'>$</span>
                            </div>
                        </div>
                        <div className='form-group col-md-6'>
                            <label className="deatilsHead mb-2 fw-normal">Profit Margin
                            </label>
                            <div className='inputGroup'>
                                <input
                                // disabled
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="10"
                                    name="margin_percentage"
                                    type="number"
                                    value={(priceJobrBtoc?.margin_percentage)?.toString()?.replace(/\.00$/, "")}
                                    onChange={(e) => handleChangePriceBtoc(e)}
                                //onChange={(e)=> {setPriceJobrBtoc({...priceJobrBtoc, margin_percentage : e.target.value});}}
                                />
                                <span className='percentagesign_'>%</span>
                            </div>
                        </div>
                    </form>
                </div>
                : ""}
            {/* End JobrB2C Area */}
            {/* Start JobrB2B Area */}
            {props.activeBtob ?
                <div className='outerArea mt-3'>
                    <h2 className='headingBlue mb-4'>
                        JOBR B2B <span className='linkTxt'><i>(Online Store)</i></span>
                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-2' />
                    </h2>
                    <div className='priceContainer'>
                        <div className='row mb-4'>
                            <div className='col-md-6'>
                                <div className={`priceContainerArea ${activeData === "fixedPrice" ? "active" : ""}`} onClick={() => setActiveData("fixedPrice")}>
                                    <h4 className='subheadText fw-bold mb-1'>Fixed Price</h4>
                                    <p className='subtext_'>Choose specific areas that you deliver to.</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className={`priceContainerArea ${activeData === "quantity_base" ? "active" : ""}`} onClick={() => setActiveData("quantity_base")}>
                                    <h4 className='subheadText fw-bold mb-1'>Price based on Quantity</h4>
                                    <p className='subtext_'>Set a distance around your location</p>
                                </div>
                            </div>
                        </div>
                        {/* Start PriceContainerData */}
                        <div className='priceContainerData'>
                            {activeData == 'fixedPrice'
                                ?
                                <FixedPrice activeBtob={props.activeBtob} fixedPriceBtob={(e) => handleFixedPriceBtob(e)} costPrice = {props?.costPrice}/>
                                : activeData == 'quantity_base' ?
                                    <CustomPrice customPriceBtob={(e) => handleCustomPriceBtob(e)} costPrice = {props?.costPrice}/>
                                    : ""
                            }
                        </div>
                        {/* End PriceContainerData */}
                    </div>
                </div>
                : ""}
            {/* End JobrB2B Area */}
        </>
    )
}

export default ProductPrice