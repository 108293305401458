import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, shield, user } from '../../../../utilities/images';

const NewEmails = () => {
  const history = useHistory()
  const [enterprisesDate, setEnterprisesDate] = useState("")
  const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
  const columns1 = [
    {
      name: '#',
      selector: row => row.id,
      width: "50px"
    },
    {
      name: "Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <img className='userInnerImg' src={user} />
              <div className='ms-2'>
                <span className='contentSubtext_ '>{row.name}</span>
                <p className='mb-0'>
                  <img src={shield} className='img-fluid me-2' />
                  <span className='textInner'>260 101 480 0083 </span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "300px",
    },
    {
      name: 'Date',
      selector: row => {
        return (
          <>
            <div className='ms-2'>
              <span className='contentSubtext_'>{row.date}</span>
              <p className='textInner mt-2'>9:00:23a</p>
            </div>
          </>
        );
      },
      width: "220px",
    },
    {
      selector: row => row.value,
      name: 'Ticket id'
    },
    {
      selector: row => row.amount,
      name: 'User Type'
    },
    {
      selector: row => row.subject,
      name: 'Subject'
    },

    {
      name: 'Status',
      selector: row => {
        return (
          <>
            <div className='ms-2 mt-2 tableBtn__ text-center'>
              <Link to='#' className='assignBtn_'>Assign Now</Link>
              <h5 className='tableHeadsmall'>00:01:29</h5>
            </div>
          </>
        );
      },
      width: "170px"
    }
  ];

  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
  }
  return (
    <div className='suportTicket'>
      <div className='topHeader_'>
        <div className="topStickyHeader_ mb-3">
          <div className="row  align-items-center">
            <div className="col-md-12">
              <h2 className="mainHeading">Support /<span className='innerSubtext'> Email</span></h2>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='row'>
          <div className='col-md-12'>
            <button className='valuesTimeswallet active' onClick={() => { history.push("/supportEmail") }}>New Email (05)</button>
            <button className='valuesTimeswallet' onClick={() => { history.push("/pendingEmail") }}>Pending Email (13)</button>
            <button className='valuesTimeswallet' onClick={() => { history.push("/assignedEmail") }}>Assigned Email (13)</button>
            <button className='valuesTimeswallet' onClick={() => { history.push("/resolveEmail") }}>Resolved Email</button>
          </div>
        </div>
      </div>
      <div className='commonTables mt-3 mb-3'>
        <div className='tableFilters'>
          <div className="calenderIcon">
            <img src={calendar} alt="calender" />
            {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
              dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
            <DatePicker
                  className='cateDate'
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Date"
              />
          </div>
          {/* <select className="cateSelectdFilter" required>
            <option value="" selected>
              Country
            </option>
            <option>Category1</option>
            <option>Category2</option>
          </select>
          <select className="cateSelectdFilter" required>
            <option value="" selected>
              State
            </option>
            <option>Category1</option>
            <option>Category2</option>
          </select>
          <select className="cateSelectdFilter" required>
            <option value="" selected>
              Id type
            </option>
            <option>Category1</option>
            <option>Category2</option>
          </select> */}
        </div>
      </div>
      <DataTable
        pagination 
        columns={columns1}
        data={products}
        defaultSortFieldId={1}
        onRowClicked={handleRowClick}
      />

    </div>
  )
}

export default NewEmails