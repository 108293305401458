import React from 'react'
import { locationBlue, profileImg1, clockimg_} from '../../../utilities/images';
import moment from 'moment-timezone';

const MultiplebookingModal = (props) => {
    let data = props?.singleAppointment;

    const statusChange = (id, status) => {
        props.changeStatusOfAppointment(id, status)
        if (!props.loading2) {
            props.close()
        }
    }
    return (
        <div className='multiplebookingModal_'>
            <div className='leftbookingusers_'>
                <div className='topbarcalender_'>
                    <ul className='midcionUsers_'>
                        {
                            props?.posList?.length > 0 ?
                                props?.posList?.map((v, i) => {
                                    return (
                                        <li className='appointuser_'>
                                            <img className='apouser_' src={v?.user?.user_profiles?.profile_photo ? v?.user?.user_profiles?.profile_photo : profileImg1} alt="userImg" />
                                            <span className='appointNotification_'>0</span>
                                        </li>
                                    )
                                }) : ""
                        }
                    </ul>
                </div>
            </div>

            <div className='rightbookingInfo_'>
                <div className="checkModalDetails requestbgColor">
                    <div className="Innerbooking mb-2">
                        <i style={{ cursor: "pointer" }} class="las la-times closexmark" onClick={() => props.close()}></i>

                        <div className="customerBookProfile mb-4">
                            <h5 className='smallHeading'>Customer:</h5>
                            {
                                data?.user_details ?
                                    <div className=' customerMain_ d-flex align-items-center'>
                                        <img className='userInnerImg' src={data?.user_details?.profile_photo ? data?.user_details?.profile_photo : profileImg1} />
                                        <div className='ms-2'>
                                            <span className='haedingsemiBold_ '>{data?.user_details?.firstname + " " + data?.user_details?.lastname}</span>
                                            {data?.user_details?.current_address &&
                                                <p className='mb-0'>
                                                    <img src={locationBlue} className='img-fluid me-2 custImg' />
                                                    <span className='contentSmallTxt'>{data?.user_details?.current_address?.city},{data?.user_details?.current_address?.country}</span>
                                                </p>}
                                        </div>
                                    </div> : <>Not Available</>}
                        </div>
                    </div>
                    <div className='innerservice_'>
                        <div className="InnercheckIn mb-4">
                            <div className="contentappont">
                                <h5 className='smallHeading mb-2'>Assigned:</h5>
                                <div className='d-flex align-items-center'>
                                    <img className='userInnerImg' src={data?.pos_user_details?.user?.user_profiles?.profile_photo ? data?.pos_user_details?.user?.user_profiles?.profile_photo : profileImg1} />
                                    <div className='ms-2'>
                                        <span className='haedingsemiBold_'>{data?.pos_user_details?.user?.user_profiles?.firstname + " " + data?.pos_user_details?.user?.user_profiles?.lastname}</span>
                                        <p className='mb-0'>
                                            <span className='contentSmallTxt'>{data?.pos_user_details?.is_staff_member === true ? "Staff" : "Non-staff"}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="appointInner mb-4">
                            <div className="contentappont">
                                <h5 className='smallHeading mb-2'>Service Requested:</h5>
                                <span>{data?.product_name}</span>
                            </div>
                        </div>
                        <div className="appointInner upcommingAnother mb-4">
                            <div className="contentappont">
                                <h5 className='smallHeading mb-2'>Service Time:</h5>
                                <div className='serviceTime_'>
                                    <span><img className='img-fluid clockimg_ me-2' alt="clock" src={clockimg_} />{moment.utc(data?.date).format("dddd ")}  </span> | <span> {moment.utc(data?.date).format("MMMM DD, YYYY")} </span> | <span>{data?.start_time + "-" + data?.end_time}</span></div>
                            </div>
                        </div>
                        <div className="appointInner mb-4">
                            <div className="contentappont">
                                <span className='smallHeading'>Duration : </span>
                                <strong className='timeText'>{data?.approx_service_time + " min"}</strong>
                            </div>
                        </div>

                    </div>
                    <div className="servicecharge_ mb-4">
                        <div className="contentappont">
                            <h5 className='smallHeading mb-2'>Service Charge:</h5>
                            <div className='totalvalue_ mb-3'>
                                <span className='subtext_'>Sub Total</span>
                                <span className='subtext_'>${data?.actual_price}</span>
                            </div>
                            {/* <div className='totalvalue_ mb-3'>
                                <span className='contentheadtext_'>Discount{data?.coupon_code ? (data?.coupon_code) : ""}</span>
                                <span className='contentheadtext_'>${data?.discount ? data?.discount : "0"}</span>
                            </div>
                            <div className='totalvalue_ mb-3'>
                                <span className='contentheadtext_'>Taxes</span>
                                <span className='contentheadtext_'>${data?.tax ? data?.tax : "0"}</span>
                            </div>
                            <div className='totalvalue_ mb-3'>
                                <span className='contentheadtext_'>Tips</span>
                                <span className='contentheadtext_'>${data?.tips ? data?.tips : "0"}</span>
                            </div> */}
                            <div className='totalvalue_'>
                                <strong className='timeText'>Total</strong>
                                <strong className='timeText'> <span className='paidbtn_ me-3' >Paid</span> ${data?.price}</strong>
                            </div>
                            <h5 className='subtext_ mt-4 fw-400'>Invoice # {data?.invoices?.invoice_number}</h5>

                        </div>
                    </div>
                    <div className='modalbottonFooter_'>
                        <button className='modalcloseBtn_' type="submit" onClick={() => props.detailsUpdate("rescheduleModal", data)}>Edit</button>
                        <button onClick={() => props.changeStatusOfAppointment(data?.id, 2)} className='modalsaveBtn_' type="submit" disabled={props.loading2}>
                            {props.loading2 && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Check-in</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiplebookingModal