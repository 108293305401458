import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { unwrapResult } from '@reduxjs/toolkit'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import TimePicker from 'react-time-picker';
import moment from 'moment-timezone'
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { selectAddressList, selectLoginAuth } from '../../auth/authSlice'
import { updateSellerInfoApiAsync } from '../dashboardSlice'
import { dayList, options } from '../../../../utilities/helpers'

const StoreAddressModal = (props) => {
    const location = useLocation();
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [defaultBusinessHours, setDefaultBusinessHours] = useState(false)
    const [dropInstructions, setDropInstructions] = useState("")
    const [dropOffInstruction, setDropoffInstruction] = useState("")
    const addresslist = useSelector(selectAddressList)
    const [businessHours, setBusinessHours] = useState([])
    const [startMondayTime, setStartMondayTime] = useState("");
    const [endMondayTime, setEndMondayTime] = useState('');
    const [startTuesddayTime, setStartTuesdayTime] = useState("");
    const [endTuesdayTime, setEndTuesdayTime] = useState('');
    const [startWednesdayTime, setStartWednesdayTime] = useState("");
    const [endWednesdayTime, setEndWednesdayTime] = useState('');
    const [startThursdayTime, setStartThursdayTime] = useState("");
    const [endThursdayTime, setEndThursdayTime] = useState('');
    const [startFridayTime, setStartFridayTime] = useState("");
    const [endFridayTime, setEndFridayTime] = useState('');
    const [startSaturdayTime, setStartSaturdayTime] = useState("");
    const [endSaturdayTime, setEndSaturdayTime] = useState('');
    const [startSundayTime, setStartSundayTime] = useState("");
    const [endSundayTime, setEndSundayTime] = useState('');
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const toastId = React.useRef(null)



    const [selectedOptions, setSelectedOptions] = useState([]);

    let selectedOptionNew = []
    const handleCheckboxChange1 = (option, flag) => {
        if (selectedOptions.find(val => val?.id == option?.id)) {
            selectedOptionNew = selectedOptions.filter((item) => item?.id !== option?.id)
            setSelectedOptions(selectedOptionNew);
        } else {
            selectedOptionNew = [...selectedOptions, option]
            setSelectedOptions([...selectedOptions, option]);
        }

        let startTime = (flag == 1) ? startMondayTime :
            (flag == 2) ? startTuesddayTime :
                (flag == 3) ? startWednesdayTime :
                    (flag == 4) ? startThursdayTime :
                        (flag == 5) ? startFridayTime :
                            (flag == 6) ? startSaturdayTime :
                                startSundayTime

        let endTime = (flag == 1) ? endMondayTime :
            (flag == 2) ? endTuesdayTime :
                (flag == 3) ? endWednesdayTime :
                    (flag == 4) ? endThursdayTime :
                        (flag == 5) ? endFridayTime :
                            (flag == 6) ? endSaturdayTime :
                                endSundayTime

        if (selectedOptionNew.find((day) => day.id == 1)) {
            setStartMondayTime(startTime);
            setEndMondayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 2)) {
            setStartTuesdayTime(startTime);
            setEndTuesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 3)) {
            setStartWednesdayTime(startTime);
            setEndWednesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 4)) {
            setStartThursdayTime(startTime);
            setEndThursdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 5)) {
            setStartFridayTime(startTime);
            setEndFridayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 6)) {
            setStartSaturdayTime(startTime);
            setEndSaturdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 7)) {
            setStartSundayTime(startTime);
            setEndSundayTime(endTime)
        }
    };

    function convertTo24HourFormat(time12Hour) {
        // Split the input time string into hours and minutes
        const [time, period] = time12Hour.split(' ');
        const [hours, minutes] = time.split(':');

        // Convert hours to an integer
        let hours24Hour = parseInt(hours, 10);

        // Adjust hours based on AM or PM
        if (period === 'PM' && hours24Hour !== 12) {
            hours24Hour += 12;
        } else if (period === 'AM' && hours24Hour === 12) {
            hours24Hour = 0;
        }

        // Format hours and minutes with leading zeros
        const formattedHours = hours24Hour.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');

        // Combine the hours and minutes into the 24-hour format
        const time24Hour = `${formattedHours}:${formattedMinutes}`;
        return time24Hour;
    }
    const filterData = () => {
        const businessDays = [
            // props?.addressDataModal?.drop_off_availability?.availability[0]?.closing_time != "" && 'monday',
            // props?.addressDataModal?.drop_off_availability?.availability[1]?.closing_time != "" && 'tuesday',
            // props?.addressDataModal?.drop_off_availability?.availability[2]?.closing_time != "" && 'wednesday',
            // props?.addressDataModal?.drop_off_availability?.availability[3]?.closing_time != "" && 'thursday',
            // props?.addressDataModal?.drop_off_availability?.availability[4]?.closing_time != "" && 'friday',
            // props?.addressDataModal?.drop_off_availability?.availability[5]?.closing_time != "" && 'saturday',
            // props?.addressDataModal?.drop_off_availability?.availability[6]?.closing_time != "" && 'sunday'
        ];
        // setStartMondayTime(props?.addressDataModal?.drop_off_availability?.availability[0]?.opening_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[0]?.opening_time) : props?.addressDataModal?.drop_off_availability?.availability[0]?.opening_time)
        // setEndMondayTime(props?.addressDataModal?.drop_off_availability?.availability[0]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[0]?.closing_time) : props?.addressDataModal?.drop_off_availability?.availability[0]?.closing_time)
        // setStartTuesdayTime(props?.addressDataModal?.drop_off_availability?.availability[1]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[1]?.opening_time) : props?.addressDataModal?.drop_off_availability?.availability[1]?.closing_time)
        // setEndTuesdayTime(props?.addressDataModal?.drop_off_availability?.availability[1]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[1]?.closing_time) : props?.addressDataModal?.drop_off_availability?.availability[1]?.closing_time)
        // setStartWednesdayTime(props?.addressDataModal?.drop_off_availability?.availability[2]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[2]?.opening_time) : props?.addressDataModal?.drop_off_availability?.availability[2]?.closing_time)
        // setEndWednesdayTime(props?.addressDataModal?.drop_off_availability?.availability[2]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[2]?.closing_time) : props?.addressDataModal?.drop_off_availability?.availability[2]?.closing_time)
        // setStartThursdayTime(props?.addressDataModal?.drop_off_availability?.availability[3]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[3]?.opening_time) : props?.addressDataModal?.drop_off_availability?.availability[3]?.closing_time)
        // setEndThursdayTime(props?.addressDataModal?.drop_off_availability?.availability[4]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[3]?.closing_time) : props?.addressDataModal?.drop_off_availability?.availability[3]?.closing_time)
        // setStartFridayTime(props?.addressDataModal?.drop_off_availability?.availability[4]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[4]?.opening_time) : props?.addressDataModal?.drop_off_availability?.availability[4]?.closing_time)
        // setEndFridayTime(props?.addressDataModal?.drop_off_availability?.availability[4]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[4]?.closing_time) : props?.addressDataModal?.drop_off_availability?.availability[4]?.closing_time)
        // setStartSaturdayTime(props?.addressDataModal?.drop_off_availability?.availability[5]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[5]?.opening_time) : props?.addressDataModal?.drop_off_availability?.availability[5]?.closing_time)
        // setEndSaturdayTime(props?.addressDataModal?.drop_off_availability?.availability[5]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[5]?.closing_time) : props?.addressDataModal?.drop_off_availability?.availability[5]?.closing_time)
        // setStartSundayTime(props?.addressDataModal?.drop_off_availability?.availability[6]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[6]?.opening_time) : props?.addressDataModal?.drop_off_availability?.availability[6]?.closing_time)
        // setEndSundayTime(props?.addressDataModal?.drop_off_availability?.availability[6]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.drop_off_availability?.availability[6]?.closing_time) : props?.addressDataModal?.drop_off_availability?.availability[6]?.closing_time)
        // setBusinessHours(businessDays)
        setDefaultBusinessHours(props?.addressDataModal?.product_availability?.default_business_hours == "false" ? false : true)
        setDropoffInstruction(props?.addressDataModal?.product_availability?.drop_off_instructions)
    }

    const handleStartTimeChange = (newStartTime, flag) => {
        if (flag == "monday") {
            setStartMondayTime(newStartTime)
        }
        if (flag == "tuesday") {
            setStartTuesdayTime(newStartTime)
        }
        if (flag == "wednesday") {
            setStartWednesdayTime(newStartTime)
        }
        if (flag == "thursday") {
            setStartThursdayTime(newStartTime)
        }
        if (flag == "friday") {
            setStartFridayTime(newStartTime)
        }
        if (flag == "saturday") {
            setStartSaturdayTime(newStartTime)
        }
        if (flag == "sunday") {
            setStartSundayTime(newStartTime)
        }
    };

    const handleEndTimeChange = (newEndTime, flag) => {
        if (flag == "monday") {
            setEndMondayTime(newEndTime)
        }
        if (flag == "tuesday") {
            setEndTuesdayTime(newEndTime)
        }
        if (flag == "wednesday") {
            setEndWednesdayTime(newEndTime)
        }
        if (flag == "thursday") {
            setEndThursdayTime(newEndTime)
        }
        if (flag == "friday") {
            setEndFridayTime(newEndTime)
        }
        if (flag == "saturday") {
            setEndSaturdayTime(newEndTime)
        }
        if (flag == "sunday") {
            setEndSundayTime(newEndTime)
        }
    };

    const handleCopy = (flag) => {
        setSelectedOptions([])
    }

    const handleCheckboxChange = (event) => {
        var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
        if (event.target.checked) {
            updatedList = businessHours?.length > 0 ? [...businessHours, event.target.value] : [event.target.value];
        } else {
            updatedList.splice(businessHours?.indexOf(event.target.value), 1);
        }
        setBusinessHours(updatedList);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (dropOffInstruction === "") {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter Drop-off instruction");
            }
            return false
        }
        let addressData = {
            postData: {
                "id": props.addressId,
                "product_availability": {
                    // "default_business_hours": defaultBusinessHours.toString(),
                    "drop_off_instructions": dropOffInstruction
                }

            },
            tokenData: auth?.payload?.token
        }

        setLoading(true)
        dispatch(updateSellerInfoApiAsync(addressData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                props?.getAddressMerchant()
                props?.close()
            })
            .catch((obj) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        filterData()
    }, [])
    return (
        <div className=' storeAddressSection'>
            <form className="form_horizontal locationForm_" onSubmit={(e) => { handleSubmit(e) }}>
                <div className='setupModalBg'>
                    {/* <div className="mb-4 orderPickUp">
                        <label className="deatilsHead mb-3"> Business Hours</label>
                        <div className='checkboxGroup businessChekbox d-block'>
                            {dayList.map((item, index) => {
                                return (
                                    <div className='checkboxInputs' key={index}>
                                        <input type="checkbox" id={item.day} className='checkBox' name={item.day} value={item.day}
                                            onChange={(e) => handleCheckboxChange(e)} checked={businessHours?.includes(item.day)} />
                                        <label htmlFor={item.day} className='checkBoxTxt'>{item.value}</label>
                                        {
                                            businessHours?.includes(item.day) &&
                                            <>
                                                <div style={{ display: "flex" }} className='timerBox_'>
                                                    <div style={{ display: "flex" }} className='innertimedate_'>
                                                        <p className='timehead_ me-2'>Start Time : </p>
                                                        <TimePicker
                                                            onChange={(e) => handleStartTimeChange(e, item.day)}
                                                            value={item.id == 1 ? startMondayTime : item.id == 2 ? startTuesddayTime : item.id == 3 ? startWednesdayTime : item.id == 4 ? startThursdayTime : item.id == 5 ? startFridayTime : item.id == 6 ? startSaturdayTime : startSundayTime}
                                                            format="h:mm a"
                                                            showLeadingZeros={false}
                                                            amPmAriaLabel="Select AM/PM"
                                                            className="custom-time-picker customPicker"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }} className='timerBox_'>
                                                        <p className='timehead_ me-2'>End Time : </p>
                                                        <TimePicker
                                                            onChange={(e) => handleEndTimeChange(e, item.day)}
                                                            value={item.id == 1 ? endMondayTime : item.id == 2 ? endTuesdayTime : item.id == 3 ? endWednesdayTime : item.id == 4 ? endThursdayTime : item.id == 5 ? endFridayTime : item.id == 6 ? endSaturdayTime : endSundayTime} // Use the 12-hour formatted time string directly
                                                            format="h:mm a" // Specify the format as 12-hour with AM/PM                                              
                                                            className="custom-time-picker customPicker"
                                                        />
                                                    </div>
                                                </div>
                                                <Dropdown onClick={() => { handleCopy(item.id) }}>
                                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                        <label className="iconcopy_ mb-0"> <i className="fa-solid fa-copy"></i> </label>
                                                    </Dropdown.Toggle>
                                                    <div className='timedatecoprBox_'>
                                                        <Dropdown.Menu>
                                                            <Form>
                                                                {options.map((option) => (
                                                                    <Form.Check
                                                                        key={option.id}
                                                                        type="checkbox"
                                                                        label={option.label}
                                                                        id={`option-${option.id}`}
                                                                        checked={selectedOptions.find(val => val?.id == option?.id)}
                                                                        onChange={() => handleCheckboxChange1(option, item.id)}
                                                                    />
                                                                ))}
                                                            </Form>
                                                        </Dropdown.Menu>
                                                    </div>
                                                </Dropdown>
                                            </>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}

                    {/* <div className='mb-4 form-group col-md-12'>
                        <label className="monsitallic mb-3"> can be add or modify from Settings</label>
                        <div className="form-check checkdefault_">
                            <input type="checkbox"
                                checked={
                                    defaultBusinessHours
                                }
                                onChange={(e) => setDefaultBusinessHours(e.target.checked)} />

                            <span className='ms-2 fw-semibold'>Save as Default Business Hours </span>
                        </div>
                    </div> */}

                    <div className='mb-4 form-group col-md-12 orderPickUp'>
                        <label className="deatilsHead mb-3"> Drop-off instructions</label>
                        <textarea className="customform-control" id="" rows="4" value={dropOffInstruction} onChange={e => setDropoffInstruction(e.target.value)} placeholder='Write your instructions here' />
                    </div>
                </div>
                {/* <div className='col-md-3'>
                </div> */}

                <div className=' modalfooterBtn'>
                    <button className='me-4 w-100 btnDiscard' onClick={() => props.close()}>Cancel</button>
                    <button className='w-100 btnSave' type="submit" disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Save</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default StoreAddressModal;