import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { fedeximg, userImage } from '../../../../utilities/images'

const CancelpurchaseInvoice = () => {
    const history = useHistory()

    return (

        <div className='newpurchaseInvoice'>
            {/* Start FixedTopHeader */}
            <div className='topHeader_'>
                <div className="productTopStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className='anotherHeader'>
                                <i className="las la-angle-left" onClick={() => history.push('/plans')}></i>
                                <h2 className="innerSubheadNormal">Invoice#2589</h2>
                                <span className="deniedBtn">Canceled</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* End FixedTopHeader */}
            <div className="refundorderWrapper">
                <div className='container-fluid pt-3 pb-3'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8'>
                            <div className="toporderCommon">
                                <div className="row">
                                    <div className='CustomerOnlinestatus_ '>
                                        <div className='invoiceHead mb-3'>
                                            <h3 className='invoiceHeading'>Invoice</h3>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='invoiceForm'>
                                                    <h5 className='semiboldHeading_'>Invoice from:</h5>
                                                    <p className='smalllightHead_'>Imani Olowe</p>
                                                    <p className='smalllightHead_'>+123-456-7890</p>
                                                    <p className='smalllightHead_'>63 Ivy Road, Hawkville, GA, USA 31036</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="invoiceForm">
                                                    <h5 className='semiboldHeading_'>Invoice details</h5>
                                                    <p className='invoiceHead'>
                                                        Invoice Id : <span>025659165659165</span>
                                                    </p>
                                                    <p className='invoiceHead'>
                                                        Created Date : <span>05-04-2022</span>
                                                    </p>
                                                    <p className='invoiceHead'>
                                                        Canceled Date : <span>05-04-2022</span>
                                                    </p>
                                                    <p className='invoiceHead fw-500'>
                                                        <span>05-04-2022</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="customerInvoicetable mt-4">
                                    <div className="table-responsive">
                                        <table className="table  listItemsContainer">
                                            <thead className="tHead_">
                                                <th className="text-start">#</th>
                                                <th className="text-start">Descriptions</th>
                                                <th className="text-start">No. of Items</th>
                                                <th className="text-start">Rate</th>
                                                <th>Amount</th>
                                            </thead>
                                            <tbody>
                                                <tr role="row">
                                                    <td className="text-start">1</td>
                                                    <td className="text-start productonlineDetail">
                                                        <img src={userImage} alt="profileimg" className="tableProductimg" />
                                                        <div className="name_content">
                                                            <h6 className="contentheadtext_"> Ashton Classic</h6>
                                                            <span className="contentHeadtextlight_">Box of 25</span>
                                                        </div>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="contentheadtext_">16 Box</p>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="contentheadtext_">$250.00</p>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="itemsTxt">$4,063.20</p>
                                                    </td>
                                                </tr>
                                                <tr role="row">
                                                    <td className="text-start">1</td>
                                                    <td className="text-start productonlineDetail">
                                                        <img src={userImage} alt="profileimg" className="tableProductimg" />
                                                        <div className="name_content">
                                                            <h6 className="contentheadtext_"> Ashton Classic</h6>
                                                            <span className="contentHeadtextlight_">Box of 25</span>
                                                        </div>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="contentheadtext_">16 Box</p>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="contentheadtext_">$250.00</p>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="itemsTxt">$4,063.20</p>
                                                    </td>
                                                </tr>
                                                <tr role="row">
                                                    <td className="text-start">1</td>
                                                    <td className="text-start productonlineDetail">
                                                        <img src={userImage} alt="profileimg" className="tableProductimg" />
                                                        <div className="name_content">
                                                            <h6 className="contentheadtext_"> Ashton Classic</h6>
                                                            <span className="contentHeadtextlight_">Box of 25</span>
                                                        </div>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="contentheadtext_">16 Box</p>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="contentheadtext_">$250.00</p>
                                                    </td>
                                                    <td className="text-start">
                                                        <p className="itemsTxt">$4,063.20</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="totalArea">
                                        <div className='row'>
                                            <div className="col-md-6 text-start">
                                                <div className="notes">
                                                    <textarea
                                                        className="h-auto"
                                                        placeholder="Notes"
                                                        id="floatingTextarea"
                                                        rows="10"
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-6 text-start">
                                                <div className="Customertotal">
                                                    <div className="totalDetails">
                                                        <h5 className="personalinfoHead">Sub Total</h5>
                                                        <h5 className="itemsTxt">$7,363.20</h5>
                                                    </div>
                                                    <div className="totalDetails">
                                                        <h5 className="personalinfoHead">Discount ( MIDApril100)</h5>
                                                        <h5 className="itemsTxt Orangetext">$100.00</h5>
                                                    </div>
                                                    <div className="totalDetails">
                                                        <h5 className="personalinfoHead">Shipping Charrg</h5>
                                                        <h5 className="itemsTxt">$12.00</h5>
                                                    </div>
                                                    <div className="totalDetails">
                                                        <h5 className="itemsText ">Total</h5>
                                                        <h5 className="itemsTxt">$7001.20</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelpurchaseInvoice