import React from 'react'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import { incoiceuser_, profileImg1, serviceuser_ } from '../../../../utilities/images';

const OrdersView = () => {
  return (
    <div className='orderReview_ oderViews'>
                    <DashboardNavbar title="Orders#890000" show={true} />
                    <div className='row'>
                <div className='col-md-6'>
                    <div className='chartsOuter bglightgrey_ pt-0'>
                        <div className="scheduleModal">
                            <div className="scheduleModalContent mt-0">
                                <div className='invoiceUser_'>
                                    <div className='reviewuserinfo_'>
                                        <img className='invoveuser me-3' src={incoiceuser_} />
                                        <article className='ivoiceuserDetail_'>
                                            <h4 className='monshead14'>Rebecca R. Russell</h4>
                                            <p className='monshead12'>1480 Bassel Street, New Orleans, LA 70113</p>
                                        </article>
                                    </div>
                                    <div className='reviewuserinfo_'>
                                        <img className='invoveuser me-3' src={incoiceuser_} />
                                        <article className='ivoiceuserDetail_'>
                                            <h4 className='monshead14 blueText_'>Express Delivery</h4>
                                            <p className='monshead12'>Immediately</p>
                                        </article>
                                    </div>
                                </div>
                                <div className='serviceDeatils_'>
                                    <div className='serviceinfo_'>
                                        <img className='img-fluid me-3' src={serviceuser_} />
                                        <article className='duration_'>
                                            <h3 className='lightgreyHeading_'>Full Body Massage</h3>
                                            <p className='designation_'>White / s</p>

                                        </article>
                                    </div>
                                    <div className='servicechanges_'>
                                        <span className='serciceprice_'>$80.99</span>
                                        <span className='serciceprice_'>1</span>
                                        <span className='serciceprice_'>$80.99</span>
                                    </div>
                                </div>
                            </div>
                            <div className='grandTotal_ row'>
                                <div className="col-md-5 text-start">
                                    <div className='orderinvoive'>
                                        <h5 className='lightgreyHeading_'>Total Item</h5>
                                        <h4 className='monshead22'>1</h4>
                                        <h5 className='lightgreyHeading_'>Order Date</h5>
                                        <h4 className='monshead22'>12/20/2023</h4>
                                        <h5 className='lightgreyHeading_'>Invoice No.</h5>
                                        <h4 className='monshead22'>AST0000876</h4>

                                    </div>
                                </div>

                                <div className="col-md-7 text-start">
                                    <div className='orderBill_'>
                                        <div className="Customertotal">
                                            <div className="totalDetails">
                                                <h5 className="lightgreyHeading_ fw-semibold">Sub Total</h5>
                                                <h5 className="lightgreyHeading_ fw-semibold">$4.00</h5>
                                            </div>
                                            <div className="totalDetails">
                                                <h5 className="textInnerHead2">Discount </h5>
                                                <h5 className="personalinfoHead">-$2.00</h5>
                                            </div>
                                            <div className="totalDetails">
                                                <h5 className="textInnerHead2"> Other Fee</h5>
                                                <h5 className="personalinfoHead">$4.00</h5>
                                            </div>
                                            <div className="totalDetails">
                                                <h5 className="textInnerHead2">Tax</h5>
                                                <h5 className="personalinfoHead">$4.00</h5>
                                            </div>
                                            <hr className='dashedrow'></hr>
                                            <div className="totalDetails">
                                                <h5 className="itemsText ">Total</h5>
                                                <h5 className="itemsText">$254.60</h5>
                                            </div>
                                            <div className='reviewbotBtn_ mt-2'>
                                                <button class="acceptBtn_ me-2" type="submit" disabled=""><span>Ready to Pickup</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='chartsOuter h-100'>
                        No Data
                    </div>
                </div>

            </div>

    </div>

  )
}

export default OrdersView