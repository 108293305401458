import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectLoginAuth } from "../auth/authSlice";
import {
  cardDeleteApiAsync,
  getAllSaveCardAsync,
  getMerchantStepStatusAsync,
  subscriptionBuyAsync,
} from "../dashboard/dashboardSlice";
import Swal from "sweetalert2";

const AddCardModal = (props) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [getAllSaveCards, setGetAllSaveCards] = useState("");
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  // open Add Card modal through callback and pass selectedPlanId
  const handleOpenAddCardModal = () => {
    props?.openModalflag({ flag: "AddCard", id: props?.selectedPlanId });
  };

  const getAllSaveCard = () => {
    setLoading(true);
    dispatch(getAllSaveCardAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then(async (obj) => {
        setLoading(false);
        setGetAllSaveCards(obj?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const selectedSubscription = (token) => {
    setSelectedCardId(token);
  };

  const handleSubscriptionBuy = () => {
    if (!selectedCardId) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select payment card");
      }
      return;
    }
    let params = {
      postData: {
        price_id: props?.selectedPlanId,
        paymentMethod: selectedCardId,
      },
      tokenData: auth?.payload?.token,
    };
    setButtonLoading(true);
    dispatch(subscriptionBuyAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setButtonLoading(false);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
          .then(unwrapResult)
          .then((obj) => {
            props?.close();
          })
          .catch((obj) => {});
        props?.openModalflag("PickPlan");
      })
      .catch((obj) => {
        setButtonLoading(false);
      });
  };

  const handleDelete = (cardId) => {
    Swal.fire({
      title: "Do you want to delete this Card?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          postData: {
            id: cardId,
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(cardDeleteApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            getAllSaveCard();
            toast.success(obj?.msg);
          })
          .catch((obj) => {});
      }
    });
  };

  useEffect(() => {
    getAllSaveCard();
  }, []);
  return (
    <>
      <div class="col-md-12 text-center">
        <button
          className="submitBtn mb-5"
          type="button"
          onClick={() => handleOpenAddCardModal()}
        >
          Add card
        </button>
      </div>

      {loading ? (
        <div className="cardPageLoader d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            {getAllSaveCards?.length > 0 ? (
              getAllSaveCards?.map((val) => {
                return (
                  <>
                    <div className="col-lg-6 col-md-12">
                      <div className="d-flex me-3 justify-content-center align-items-center position-relative">
                        <div
                          className={`cardp  ${
                            selectedCardId === val?.id ? "active" : ""
                          }`}
                          onClick={() => selectedSubscription(val?.id)}
                        >
                          <div className="title">
                            <span className="tit">Payment Details</span>
                          </div>
                          <div className="visa">
                            <h1></h1>
                          </div>
                          <div className="cash">
                            <h5>{val?.card?.display_brand}</h5>
                            <span className="one">
                              ************{val?.card?.last4}
                            </span>
                          </div>
                          <div className="ccv">
                            <div className="tit">CVC</div>
                            <span className="cv">***</span>
                          </div>

                          <div className="exp">
                            <h4>Expiration</h4>
                            <span className="month">
                              {val?.card?.exp_month}/
                            </span>
                            <span className="num">{val?.card?.exp_year}</span>
                          </div>
                          <i
                            className="fa-solid fa-trash card_DeleteBtn"
                            onClick={() => handleDelete(val?.id)}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="mainCatalog plans_ mb-3">
                <h4 className="text-center">No Save Card</h4>
              </div>
            )}
          </div>
          {getAllSaveCards?.length > 0 && (
            <div class="col-md-12 text-center">
              <button
                className="submitBtn mt-5 "
                type="button"
                onClick={() => handleSubscriptionBuy()}
              >
                Buy Subscription
                {buttonLoading && (
                  <span className="spinner-border spinner-border-sm ms-2"></span>
                )}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default AddCardModal;
