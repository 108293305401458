import React, { useRef, useState } from "react";
import { consumerProfile, profileImg1 } from "../../../../utilities/images";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import { useEffect } from "react";
import {
    getSinglePosUserAsync,
    getSingleInvitationPosUserAsync,
    updateCustomerApiAsync,
    updateMerchantApiAsync,
    updateInvitedCustomerApiAsync,
    //   updateMerchantApiAsync2,
} from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../auth/authSlice";
import reactSelect from "react-select";
import { toast } from "react-toastify";

const EditConsumerModal = (props) => {
    const toastId = useRef(null);
    const { userInvitationData } = { ...props };
    const { userData } = { ...props };
    const [hide, setHide] = useState(false)
    const [displayValue, setDisplayValue] = useState()
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [firstname, setFirstname] = useState(
        userData?.user_profiles?.firstname ? userData?.user_profiles?.firstname : userInvitationData?.firstname
    );
    const [lastname, setLastname] = useState(
        userData?.user_profiles?.lastname ? userData?.user_profiles?.lastname : userInvitationData?.lastname
    );
    const [phoneCode, setPhoneCode] = useState(
        userData?.user_profiles?.phone_code ? userData?.user_profiles?.phone_code :userInvitationData?.phone_code
    );
    console.log(phoneCode,"phoneCode");

    const [phoneNumber, setPhoneNumber] = useState(
        userData?.user_profiles? userData?.user_profiles?.phone_no :userInvitationData?.phone_no
    )

    const [email, setEmail] = useState(
        userData?.email?userData?.email:userInvitationData?.email
    )
    console.log(phoneNumber,"phoneNumber");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    console.log(
        userData?.user_profiles?.current_address?.phone_no,
        "usdsdhewhiuqwh"
    );
    const [zipCode, setZipCode] = useState(
        userData?.user_profiles?.current_address?.zipcode ?? ""
    );
    const [cityValue, setCityValue] = useState(
        userData?.user_profiles?.current_address?.city
    );
    const [latitude, setLatitude] = useState(
        userData?.user_profiles?.current_address?.latitude
    );
    const [longitude, setLongitude] = useState(
        userData?.user_profiles?.current_address?.longitude
    );
    const [formattedAddress, setFormattedAddress] = useState();
    const [stateValue, setStateValue] = useState(
        userData?.user_profiles?.current_address?.state
    );
    const [countryValue, setCountryValue] = useState(
        userData?.user_profiles?.current_address?.country
    );
    const [aptValue, setAptValue] = useState();
    const [address, setAddress] = useState();
    const [stateCode, setStateCode] = useState(
        userData?.user_profiles?.current_address?.state_code
    );
    const [countryCode, setCountryCode] = useState(
        userData?.user_profiles?.current_address?.country_code
    );
    const autoCompleteHandleChange = (address) => {
        // setAddress(address);
        setFormattedAddress(address);
        geocodeByAddress(address)
            .then((results) => {
                // fillAddressDetails(results);
                setLatitude(results[0].geometry.location.lat());
                setLongitude(results[0].geometry.location.lng());
            })
            .catch((error) => { });
        setZipCode("");
        setCityValue("");
        setStateValue("");
        setCountryValue("");
        setAptValue("");
        setAddress("");
    };
    const autoCompleteHandleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                fillAddressDetails(results);
                setLatitude(results[0].geometry.location.lat());
                setLongitude(results[0].geometry.location.lng());
                setDisplayValue(results[0]?.formatted_address?.split(',')[0])
                setHide(true)
            })
            .catch((error) => { });
    };
    const fillAddressDetails = (results) => {
        console.log(results);
        // setAddress(results[0].formatted_address);
        setFormattedAddress(results[0].formatted_address);
        for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] == "postal_code") {
                setZipCode(results[0].address_components[j].short_name);
            } else if (results[0].address_components[j].types[0] == "locality") {
                setCityValue(results[0].address_components[j].long_name);
            } else if (
                results[0].address_components[j].types[0] ==
                "administrative_area_level_1" ||
                results[0].address_components[j].types[0] ===
                "administrative_area_level_3" ||
                results[0].address_components[j].types[0] === "locality"
            ) {
                setStateValue(results[0].address_components[j].long_name);
                setStateCode(results[0].address_components[j]?.short_name);
            } else if (results[0].address_components[j].types[0] == "country") {
                setCountryValue(results[0].address_components[j].long_name);
                setCountryCode(results[0].address_components[j]?.short_name);
            } else if (results[0].address_components[j].types[0] == "street_number") {
                setAptValue(results[0].address_components[j].long_name);
            } else if (results[0].address_components[j].types[0] == "route") {
                setAddress(results[0].address_components[j].long_name);
            } else if (results[0].address_components[j].types[0] == "street_number") {
                setAptValue(results[0].address_components[j].long_name);
            }
        }
    };

    const onChangePhoneNumber = (value, data) => {
        let phoneCode = data.dialCode;
        let phoneNumber = value.slice(data.dialCode.length);
        setPhoneNumber(phoneNumber);
        setPhoneCode(phoneCode);
      }

    const getSingleUserApiAsyncHandler = () => {
        let params = {
            postData: {
                seller_id: auth?.payload?.uniqe_id
                    ? auth?.payload?.uniqe_id
                    : auth?.payload?.user?.unique_uuid,
                id: userData?.id ,
            },
            tokenData: auth?.payload?.token,
        };

        dispatch(getSinglePosUserAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                console.log(obj?.payload, "data in obj");
                setData(obj?.payload);
                setFormattedAddress(obj?.payload?.current_location?.formatted_address);
                setLoading(false);
                setAptValue(obj?.payload?.current_location?.apt);
            })
            .catch((obj) => { });
    };

    const handleSave = () => {
        if (userData && !formattedAddress) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your address");
            }
            return;
        }
        if (userData && !countryValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your country");
            }
            return;
        }
        if (userData && !stateValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your state");
            }
            return;
        }
        if (userData && !cityValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your city");
            }
            return;
        }
        if (userData && !zipCode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your zipcode");
            }
            return;
        }
        if (userData && !latitude && !longitude) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Incorrect Address");
            }
            return;
        }

        let params = {
            postData: {
                id: userData?.id ? userData?.id :userInvitationData?.id,
                address_id: userData?.user_profiles?.current_address?.address_id,
                email: email,
                firstname: firstname,
                lastname: lastname,
                // phone_code: phoneCode,
                // phone_no: phoneNumber,
            },
            tokenData: auth?.payload?.token,
        };

        if (userData?.user_profiles?.is_phone_verified == false || userInvitationData?.is_phone_verified == false) {
            params = {
                postData: {
                    ...params.postData,
                    "phone_code": phoneCode,
                    "phone_no": phoneNumber,
                    "firstname": firstname,
                    "lastname": lastname,
                    "email": email
                    },
                tokenData: auth?.payload?.token,
            };
        }

        if (formattedAddress) {
            params = {
                postData: {
                    ...params.postData,
                    current_address: {
                            country: countryValue,
                            state: stateValue,
                            city: cityValue,
                            zipcode: zipCode,
                            address_type: "current",
                            longitude: longitude,
                            latitude: latitude,
                            country_code: countryCode,
                            state_code: stateCode,
                            //   format_address: formattedAddress,
                    },
                },
                tokenData: auth?.payload?.token,
            };
        }

        if (aptValue) {
            params = {
                postData: {
                    ...params.postData,
                    current_address: {
                        ...params.postData.current_address,
                        apt: aptValue,
                    },
                },
                tokenData: auth?.payload?.token,
            };
        }
            
            if(userData){
                dispatch(updateCustomerApiAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    // if(userData){
                        props.getSingleUserApiAsyncHandler();
                    // }
                    // else{
                    //     props.getSingleInvitationUserApiAsyncHandler()
                    // }
                    // props?.setUserData((prev)=>({
                    //   user_details: {
                    //     ...prev.user_details,
                    //     current_address:obj?.payload?.current_address,
                    //   }
                    // }))
                    props?.close();
                });
            }
            else{
                dispatch(updateInvitedCustomerApiAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    // if(userData){
                    //     props.getSingleUserApiAsyncHandler();
                    // }
                    // else{
                        props.getSingleInvitationUserApiAsyncHandler()
                    // }
                    // props?.setUserData((prev)=>({
                    //   user_details: {
                    //     ...prev.user_details,
                    //     current_address:obj?.payload?.current_address,
                    //   }
                    // }))
                    props?.close();
                });
            }
    };
    useEffect(() => {
        if(userData){
            getSingleUserApiAsyncHandler();
        }
        
    }, []);

    return (
        <>
            {/* {loading ? (
        <div className="pageLoader d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      ) : (
        <></>
      )} */}
            <div className="editConsumerModal p-3 position-relative top">
                <div className="editCrossDetails">
                    <span className="crossIcon" onClick={() => props?.close()}>
                        <i className="las la-times" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="consumerBtns_">
                    <button
                        className="discardBtn"
                        onClick={() => {
                            props?.close();
                        }}
                    >
                        Discard
                    </button>
                    <button
                        className="saveBtn"
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        {" "}
                        Save
                    </button>
                </div>
            </div>
            <div className="editModaluser_">
                <div className="row">
                    <div className="col-md-12">
                        <div className="consumerProfileEdit">
                            <img
                                src={userData?.user_profiles?.profile_photo ?? profileImg1}
                                alt="images"
                                className="editImg_ m-0"
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "50px",
                                }}
                            />
                        </div>
                        <form className="form_horizontal row">
                            <div className="userInfoScroll">
                                <div className="mb-4 form-group col-md-12">
                                    <label className="form-label labelText mb-2">First Name</label>
                                    <input
                                        className="customform-control"
                                        value={firstname}
                                        onChange={(e) => setFirstname(e.target.value)}
                                        placeholder="Angela K. Loving"
                                    ></input>
                                </div>
                                <div className="mb-4 form-group col-md-12">
                                    <label className="form-label labelText mb-2">Last Name</label>
                                    <input
                                        className="customform-control"
                                        value={lastname}
                                        onChange={(e) => setLastname(e.target.value)}
                                        placeholder="Angela K. Loving"
                                    ></input>
                                </div>
                                <div className="phone-numbpart">
                                    <div className="country-plugin">
                                        <label className="form-label labelText mb-2"> Phone</label>
                                        <div id="result" className="userInput">
                                            <PhoneInput
                                                country="us"
                                                disabled={userData?.user_profiles?.is_phone_verified || userInvitationData?.is_phone_verified}
                                                value={
                                                    phoneCode +
                                                    phoneNumber
                                                }
                                                placeholder="Phone no."
                                                onChange={(value, data, event, formattedValue) => onChangePhoneNumber(value, data, event, formattedValue)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4 form-group col-md-12">
                                    <label className="form-label labelText mb-2">
                                        Email Address
                                    </label>
                                    <input
                                        className="customform-control"
                                        disabled={userData?.user_profiles?.is_email_verified || userInvitationData?.is_email_verified}
                                        value={email
                                        }
                                        onChange={(e) => setEmail(e.target.value)}
                                    //   mailto:placeholder="vidal.webe2@gmail.com"
                                    ></input>
                                </div>
                                {!userInvitationData?
                                <div className="mb-4 form-group">
                                    <label className="labelText mb-2">Street Address</label>
                                    <div className="inputGroup">
                                    {
                                        hide ?
                                            <span
                                                className="customform-control countrytags_ w-100"
                                                 onClick={() => setHide(!hide)}
                                            >{displayValue}</span>
                                            :
                                        <PlacesAutocomplete
                                            className=""
                                            autoComplete="off"
                                            value={formattedAddress}
                                            onChange={autoCompleteHandleChange}
                                            onSelect={autoCompleteHandleSelect}
                                            searchOptions={{
                                                componentRestrictions: {
                                                    country: ["us", "ind"],
                                                },
                                            }}
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: "Street Address",
                                                            className:
                                                                "location-search-input customform-control countrytags_",
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion, index) => {
                                                            const className = suggestion.active
                                                                ? "suggestion-item--active"
                                                                : "suggestion-item";
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? {
                                                                    backgroundColor: "#41b6e6",
                                                                    cursor: "pointer",
                                                                }
                                                                : {
                                                                    backgroundColor: "#ffffff",
                                                                    cursor: "pointer",
                                                                };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                    key={index}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
}
                                    </div>
                                </div>:""}

                                {!userInvitationData?
                                <div className="mb-4 form-group col-md-12">
                                    <label className="form-label labelText mb-2">
                                        Apartment/Suite
                                    </label>
                                    <input
                                        className="customform-control"
                                        value={aptValue}
                                        onChange={(e) => {
                                            setAptValue(e.target.value);
                                        }}
                                        placeholder="Apartment/Suite"
                                    ></input>
                                </div>:""}
                                {!userInvitationData?
                                <div className="row">
                                    <div className="mb-4 form-group col-md-6">
                                        <label className="form-label labelText mb-2">Country</label>
                                        <input
                                            className="customform-control "
                                            placeholder="Country"
                                            value={countryValue}
                                            onChange={(e) => {
                                                setCountryValue(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-4 form-group col-md-6">
                                        <label className="form-label labelText  mb-2">City</label>
                                        <input
                                            className="customform-control"
                                            placeholder="City"
                                            value={cityValue}
                                            onChange={(e) => {
                                                setCityValue(e.target.value);
                                            }}
                                        />

                                      
                                    </div>
                                </div>:""}
                                {!userInvitationData?
                                <div className="row">
                                    <div className="mb-4 form-group col-md-6">
                                        <label className="form-label labelText  mb-2">Zip code</label>
                                        <input
                                            type="number"
                                            className="customform-control"
                                            value={zipCode}
                                            placeholder="Zip code"
                                            onChange={(e) => {
                                                setZipCode(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-4 form-group col-md-6">
                                        <label className="form-label labelText mb-2">State</label>
                                        <input
                                            className="customform-control "
                                            placeholder="State"
                                            type="text"
                                            value={stateValue}
                                            onChange={(e) => {
                                                setStateValue(e.target.value);
                                            }}
                                        />
                                       
                                    </div>
                                </div>:""}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditConsumerModal;
