import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { calenderappoint, appointtoday, locationBlue, usersappoint, usersettings, modalCancel, calendar, OrderDown } from '../../../utilities/images'
import { Link } from 'react-router-dom';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { Calendar, Views, globalizeLocalizer, momentLocalizer, DateLocalizer } from 'react-big-calendar'
import CustomModal from '../../components/shared/CustomModal';
import AppointSchedule from '../myAppointments/todayAppointments/appointSchedule';
import { getPosUsersAsync, selectLoginAuth } from '../auth/authSlice';
import { changeAppointmentStatusApiAsync, getAppointemntApiAsync } from '../dashboard/dashboardSlice';
import { productList1, productList2, productList3, productList4, profileImg1 } from '../../../utilities/images';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import BookingsettingModal from './bookingsettingModal';
import BookingSchedule from './bookingSchedule';
import CheckinModal from './checkinModal';
import ConfirmCompleteModal from './ConfirmCompleteModal';
import { getUserSettingsApiAsync } from '../systemConfig/systemConfigSlice';
import BookingrequestModal from './bookingrequestModal';
import MultiplebookingModal from './multiplebookingModal';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

function UpcomingBooking() {
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const toastId = React.useRef(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState("")
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loadingId, setLoadingId] = useState("")
    const [userSettingData, setUserSettingData] = useState("");
    const [completedBooking, setCompletedBooking] = useState("");
    const [approveLoading, setApproveLoading] = useState(false)
    const [filterValue, setFilterValue] = useState(userSettingData?.calender_view);
    console.log(filterValue, 'filter value');
    const [isFilterValue, setIsFilterValue] = useState(false);
    const [singleAppointment, setSingleAppointment] = useState("");
    console.log(userSettingData, 'user setting data');
    const [singleOrderData, setSingleOrderData] = useState('');
    const [appointmentData, setAppointmentData] = useState([]);
    const [newBookingData, setNewBookingData] = useState([]);
    const [posList, setPosList] = useState([]);
    const [calenderData, setCalenderData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [event, setEvent] = useState([]);
    console.log(event, 'eventssssssssss');
    const [activeTab, setActiveTab] = useState("tableView");
    const defaultDate = useMemo(() => new Date(2015, 3, 1), [])
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const views = {
        day: true,   // Enable day view
        week: true,  // Enable week view
        month: true, // You can also enable month view
    };
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentWeek, setCurrentWeek] = useState(moment());
    const [currDate, setCurrDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const handlePrevMonth = () => {
        setIsFilterValue(false)
        const prevMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1);
        console.log(prevMonth, 'prev month');
        const year = prevMonth.getFullYear();
        const month = prevMonth.getMonth();
        const lastDay = new Date(year, month + 1, 0);
        const newDate = moment(currentDate).subtract(1, 'months').toDate();
        setCurrentDate(newDate);
        setCurrentMonth(prevMonth);
        setStartDate(prevMonth)
        setEndDate(lastDay)
    };

    const handleNextMonth = () => {
        setIsFilterValue(false)
        const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
        setCurrentMonth(nextMonth);
        const year = nextMonth.getFullYear();
        const month = nextMonth.getMonth();
        const lastMonthDay = new Date(year, month + 1, 0);
        const newDate = moment.utc(currentDate).add(1, 'months').toDate();
        setCurrentDate(newDate);
        setStartDate(nextMonth)
        setEndDate(lastMonthDay)
    };
    const handleCurrDateChange = (increment) => {
        setIsFilterValue(false)
        const newDate = new Date(currDate);
        newDate.setDate(newDate.getDate() + increment);
        setCurrDate(newDate);
        setCurrentDate(newDate);
        setStartDate(newDate)
        setEndDate(newDate)
    };

    const handleWeekChange = (increment) => {
        setIsFilterValue(false)
        setCurrentWeek(currentWeek.clone().add(increment, 'weeks'));
        setStartDate(currentWeek.clone().add(increment, 'weeks').startOf('isoWeek')?._d)
        setEndDate(currentWeek.clone().add(increment, 'weeks').endOf('isoWeek')?._d)
    };

    const handleDateChange = (dates) => {
        setIsFilterValue(false)
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    function addThousandSeparator(number) {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const detailsUpdate = (newFlag, data) => {
        setSingleAppointment(data)
        setModalDetail({
            show: true,
            flag: newFlag,
        });
        setKey(Math.random());
    };
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };
    const CustomToolbar = () => {
        return <div />; // Return an empty div to hide the toolbar
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Bookings";
    }, []);
    const columns1 = [
        {
            name: 'Date/Time',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='text-center'>
                            <span className='textParagh'>{moment.utc(row?.date).format("MM/DD/YYYY | ")}<span className="">{row?.start_time + "-" + row?.end_time}</span></span>
                        </div>
                    </>
                );
            },
            width: "270px",
        },
        {
            name: "Customer",
            selector: row => {
                return (
                    <>{
                        row?.user_details ?
                            <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                                <img className='userInnerImg' src={row?.user_details?.profile_photo ? row?.user_details?.profile_photo : profileImg1} alt='' />
                                <div className='ms-2'>
                                    <span className='contentSubtext_ '>{row?.user_details?.firstname ? row?.user_details?.firstname : "" + " " + row?.user_details?.lastname ? row?.user_details?.lastname : ""}</span>
                                    {
                                        row?.user_details?.current_address &&
                                        <p className='mb-0'>
                                            <img src={locationBlue} className='img-fluid me-2 custImg' alt='' />
                                            <span className='contentSmallTxt'>{row?.user_details?.current_address?.city},{row?.user_details?.current_address?.country}</span>
                                        </p>
                                    }

                                </div>
                            </div> : <div className='d-flex align-items-center'>Not Available</div>}
                    </>
                );
            },
            width: "300px",
        },
        {
            name: "Assigned to",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.pos_user_details?.user?.user_profiles?.profile_photo ? row?.pos_user_details?.user?.user_profiles?.profile_photo : profileImg1} alt='' />
                            <div className='ms-2'>
                                <span className='contentSubtext_ '>{row?.pos_user_details?.user?.user_profiles?.firstname + " " + row?.pos_user_details?.user?.user_profiles?.lastname}</span>
                                <p className='mb-0'>
                                    {/* <img src={locationBlue} className='img-fluid me-2 custImg' /> */}
                                    <span className='contentSmallTxt'>{row?.pos_user_details?.is_staff_member === true ? "Staff" : "Non-staff"}</span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "200px",
        },
        {
            selector: row => row?.product_name,
            name: 'Service',
            width: "150px",
        },
        {
            selector: row => row?.approx_service_time + " min",
            name: 'Duration',
            width: "150px",
        },
        {
            selector: row => '$' + addThousandSeparator(row?.price),
            name: 'Price',
            width: "150px",
        },
        {
            name: 'Action',
            selector: row => {
                return (
                    <>
                        <div className='ms-2 tableBtn__'>
                            <div className='col-lg-12 '>
                                {
                                    row?.status === 0 ?
                                        <div className='stoclRight'>
                                            <button onClick={() => { changeStatusOfAppointmentHandle(row?.id, 4); setLoadingId(row?.id) }} className='me-3 checkBtnColor' type="submit" disabled={loading1}>
                                                {loading1 && loadingId === row?.id && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                &nbsp;&nbsp;
                                                <span>Decline</span>
                                            </button>
                                            <button onClick={() => { changeStatusOfAppointmentHandle(row?.id, 1); setLoadingId(row?.id) }} className='acceptBtnColor me-2' type="submit" disabled={loading2}>
                                                {loading2 && loadingId == row?.id && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                &nbsp;&nbsp;
                                                <span>Approve</span>
                                            </button>
                                        </div> :
                                        row?.status === 1 ?
                                            <div className='stoclRight'>
                                                <button onClick={() => { setSingleAppointment(row); setModalDetail({ show: true, flag: "checkInModal" }); setKey(Math.random()); }} className='me-3 checkInBtnColor' type="submit">
                                                    <span>Check-in</span>
                                                </button>
                                                <button onClick={() => { setSingleAppointment(row); setModalDetail({ show: true, flag: "rescheduleModal" }); setKey(Math.random()); }} className='acceptBtnColor me-2' type="submit">
                                                    <span>Update</span>
                                                </button>
                                            </div> :
                                            row?.status === 2 ?
                                                <div className='stoclRight'>
                                                    <button onClick={() => { setSingleAppointment(row); setModalDetail({ show: true, flag: "confirmCompleteModal" }); setKey(Math.random()); }} className=' me-2 w-100 checkInBtnColor' type="submit" disabled={loading2}>
                                                        <span>Mark Complete</span>
                                                    </button>
                                                </div> :
                                                row?.status === 3 ?
                                                    <div className='stoclRight'>
                                                        <button className='acceptBtnColor me-2 w-100' type="submit" disabled>
                                                            <span>Completed</span>
                                                        </button>
                                                    </div>
                                                    : <></>
                                }
                            </div>

                        </div>
                    </>
                );
            },
            width: "250px"
        }
    ];

    const CustomRow = ({ data }) => {
        return (
            <div>
                <p>This is a custom row element.</p>
                <p>ID: {data.id}</p>
                <p>Date: {data.date}</p>
            </div>
        );
    };

    const customRowStyles = [
        {
            when: (row) => (row.id == 2 || row.id == 5), // Condition to apply the custom row style
            style: {
                backgroundColor: '#e5f0ff4d',
            },
            classNames: 'custom-row-className',
            component: <CustomRow />, // Custom row component to render
        },
    ];
    const localizer = momentLocalizer(moment)
    console.log(localizer, 'llllllllllllll');

    const changeStatusOfAppointmentHandle = (id, status, modalIs) => {
        let orderStatus = {
            "postData": {
                id: id,
                status: status
            },
            "tokenData": auth?.payload?.token
        }
        status === 4 ? setLoading1(true) : status === 3 ? setApproveLoading(true): setLoading2(true)
        dispatch(changeAppointmentStatusApiAsync(orderStatus))
            .then(unwrapResult)
            .then((obj) => {
                console.log(obj, 'objjjjjjjjj');
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let params = {
                    "postData": {
                        page: currentPage,
                        limit: perPage,
                        status: "0,1,2,3",
                        // need_upcoming: true,
                        filter_by: activeTab === 'tableView' ? filterValue : 'month',
                        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                    },
                    "tokenData": auth?.payload?.token
                }
                if (isFilterValue) {
                    delete params?.postData?.start_date
                    delete params?.postData?.end_date
                    let newData = {
                        ...params.postData, 'filter_by': filterValue
                    }
                    params = {
                        tokenData: auth?.payload?.token,
                        postData: newData
                    }
                }
                dispatch(getAppointemntApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setAppointmentData(obj?.payload?.data)
                        setCompletedBooking(obj?.payload?.data?.filter(item => item?.status == 3))
                        let newState = obj?.payload?.data?.filter(item => item?.status != '0')?.map(obj => {
                            return ({ ...obj, start: moment.utc(obj?.start_date_time).format('YYYY-MM-DD HH:mm:ss'), end: moment.utc(obj?.end_date_time).tz(obj?.timezone).format('YYYY-MM-DD HH:mm:ss'), title: obj?.product_name })
                        });
                        setEvent(newState);
                        setTotalRows(obj?.payload?.total)
                        setCalenderData(obj?.payload?.data)
                        setLoading1(false)
                        setLoading2(false)
                        setApproveLoading(false)
                        handleOnCloseModal()
                        // setModalDetail({ show: false, flag: "" });
                        // setKey(Math.random());
                    }
                    )
                    .catch((obj) => {
                        setLoading1(false)
                        setLoading2(false)
                        setApproveLoading(false)
                    })

            }
            )
            .catch((obj) => {
                setLoading1(false)
                setLoading2(false)
            })
    }
    const handleRowClick = (e) => {
        // setSingleAppointment(e)
        // setModalDetail({
        //     show: true,
        //     flag: "AppointSchedule"
        // });
        // setKey(Math.random());
    }
    // const allViews = Object.keys(Calendar.Views).map(k => Calendar.Views[k]);

    const handleSelectedEvent = (event) => {
        if (event?.status === 1) {
            setSingleAppointment(event)
            setModalDetail({
                show: true,
                flag: "multiplebookingModal"
            });
            setKey(Math.random());
        }
        if (event?.status === 2) {
            setSingleAppointment(event)
            setModalDetail({
                show: true,
                flag: "confirmCompleteModal"
            });
            setKey(Math.random());
        }
        if (event?.status === 3) {
            setSingleAppointment(event)
            setModalDetail({
                show: true,
                flag: "PendingInvoiceModal"
            });
            setKey(Math.random());
        }
    };
    const handleAppointmentData = () => {
        let params = {
            "postData": {
                page: currentPage,
                limit: perPage,
                status: "0,1,2,3",
                search: keyword || undefined,
                // filter_by: activeTab === 'tableView' ? filterValue : 'month',
                // filter_by: filterValue,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            delete params?.postData?.filter_by
            let newData = {
                ...params.postData,
                "start_date": moment(startDate)?.format('YYYY-MM-DD'),
                "end_date": moment(endDate)?.format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        if (isFilterValue) {
            delete params?.postData?.start_date
            delete params?.postData?.end_date
            let newData = {
                ...params.postData, 'filter_by': filterValue
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAppointemntApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAppointmentData(obj?.payload?.data)
                setCompletedBooking(obj?.payload?.data?.filter(item => item?.status == 3))
                let newState = obj?.payload?.data?.filter(item => item?.status != '0')?.map(obj => {
                    return ({ ...obj, start: new Date(moment.utc(obj?.start_date_time).format('YYYY-MM-DD HH:mm:ss')), end: new Date(moment.utc(obj?.end_date_time).format('YYYY-MM-DD HH:mm:ss')), title: obj?.product_name })
                });
                console.log(newState, 'newstate');
                setEvent(newState);
                setTotalRows(obj?.payload?.total)
                setCalenderData(obj?.payload?.data)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const changeStatusForNewRequest = (id, status) => {
        let orderStatus = {
            "postData": {
                id: id,
                status: status
            },
            "tokenData": auth?.payload?.token
        }
        status === 4 ? setLoading1(true) : setLoading2(true)
        dispatch(changeAppointmentStatusApiAsync(orderStatus))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                handleNewBookingRequest()
                setLoading1(false)
                setLoading2(false)
            }
            )
            .catch((obj) => {
                setLoading1(false)
                setLoading2(false)
            })
    }

    const handleNewBookingRequest = () => {
        let params = {
            "postData": {
                status: '0',
                // filter_by: filterValue,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getAppointemntApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setNewBookingData(obj?.payload?.data)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getPosUser = () => {
        let params = {
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                need_staff_member: true
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getPosUsersAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setPosList(obj?.payload?.pos_staff)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getUserSettingHandle = () => {
        let data = {
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                app_name: 'pos'
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getUserSettingsApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setUserSettingData(obj?.payload)
                setFilterValue(obj?.payload?.calender_view === "day" ? "today" : obj?.payload?.calender_view)
                let params = {
                    "postData": {
                        page: currentPage,
                        limit: perPage,
                        status: "0,1,2,3",
                        filter_by: obj?.payload?.calender_view === 'day' ? "today" : obj?.payload?.calender_view,
                        // filter_by: activeTab === 'tableView' ? obj?.payload?.calender_view : 'month',
                        // filter_by: filterValue,
                        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                    },
                    "tokenData": auth?.payload?.token
                }
                if (startDate && endDate) {
                    delete params?.postData?.filter_by
                    let newData = {
                        ...params.postData,
                        "start_date": moment(startDate)?.format('YYYY-MM-DD'),
                        "end_date": moment(endDate)?.format('YYYY-MM-DD')
                    }
                    params = {
                        tokenData: auth?.payload?.token,
                        postData: newData
                    }
                }
                if (filterValue) {
                    delete params?.postData?.start_date
                    delete params?.postData?.end_date
                    let newData = {
                        ...params.postData, 'filter_by': filterValue
                    }
                    params = {
                        tokenData: auth?.payload?.token,
                        postData: newData
                    }
                }
                dispatch(getAppointemntApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        console.log(obj, 'objjjjjjjjjjjjjjj');
                        setAppointmentData(obj?.payload?.data)
                        setCompletedBooking(obj?.payload?.data?.filter(item => item?.status == 3))
                        let newState = obj?.payload?.data?.filter(item => item?.status != '0')?.map(obj => {
                            return ({ ...obj, start: new Date(moment.utc(obj?.start_date_time).format('YYYY-MM-DD HH:mm:ss')), end: new Date(moment.utc(obj?.end_date_time).format('YYYY-MM-DD HH:mm:ss')), title: obj?.product_name })
                        });
                        console.log(newState, 'newstate');
                        setEvent(newState);
                        setTotalRows(obj?.payload?.total)
                        setCalenderData(obj?.payload?.data)
                        setLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getUserSettingHandle()
        getPosUser()
        handleNewBookingRequest()
    }, [activeTab, perPage, currentPage])

    useEffect(() => {
        if (filterValue) {
            handleAppointmentData()
        }
    }, [filterValue])

    useEffect(() => {
        handleAppointmentData()
        // getUserSettingHandle()
    }, [endDate])

    useEffect(() => {
        if(keyword == "" || keyword?.length > 2){
            handleAppointmentData()
        }
        // getUserSettingHandle()
    }, [keyword])

    return (
        <>
            <div className='calenderMain_'>
                <div className='leftcalender_'>
                    <div className='upcomingDetail_'>
                        <DashboardNavbar title="Calender" backShow={`/bookings`} setKeyword={setKeyword} />
                        <div className="apoointmidHead_ mb-4">
                            <div className='appointDatetime_ appointGap'>
                                <div className='apointUpcomdate'>
                                    {
                                        filterValue === "today" ?
                                            <p className='dateapoint m-0'><i onClick={() => handleCurrDateChange(-1)} style={{ cursor: "pointer" }} className="fas fa-chevron-left"></i> <span className='me-2 ms-2'>{filterValue === "today" && isFilterValue ? moment.utc(new Date()).format("ddd MMM DD YYYY") : currDate?.toDateString()}</span> <i onClick={() => handleCurrDateChange(1)} style={{ cursor: "pointer" }} className="fas fa-chevron-right"></i></p>
                                            : filterValue === "week" ?
                                                <p className='dateapoint m-0'><i onClick={() => handleWeekChange(-1)} style={{ cursor: "pointer" }} className="fas fa-chevron-left"></i> <span className='me-2 ms-2'>{currentWeek.startOf('isoWeek').format('MM-DD-YYYY')} - {currentWeek.endOf('isoWeek').format('MM-DD-YYYY')}</span> <i onClick={() => handleWeekChange(1)} style={{ cursor: "pointer" }} className="fas fa-chevron-right"></i></p>
                                                : filterValue === "month" ?
                                                    <p className='dateapoint m-0'><i onClick={handlePrevMonth} style={{ cursor: "pointer" }} className="fas fa-chevron-left"></i> <span className='me-2 ms-2'>{monthNames[currentMonth.getMonth()]} {currentMonth.getFullYear()}</span> <i onClick={handleNextMonth} style={{ cursor: "pointer" }} className="fas fa-chevron-right"></i></p>
                                                    : ""
                                    }
                                </div>
                                <div className='categroyBox'>
                                    <div className="calenderIcon campCalendar">
                                        <img src={calendar} alt="calender" className='campcalendarImg' />
                                        <DatePicker
                                            className='cateDate'
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            placeholderText="Date"
                                        />
                                        <img src={OrderDown} alt="calender" className='calenderDownIcon' />
                                    </div>
                                </div>
                                <div className='weekcount'>
                                    <button onClick={() => { setFilterValue('today'); setIsFilterValue(true) }} className={`daysDetail_ ${filterValue === 'today' ? 'active' : ""}`}>Day</button>
                                    <button onClick={() => { setFilterValue('week'); setIsFilterValue(true) }} className={`daysDetail_ ${filterValue === 'week' ? 'active' : ""}`}>Week</button>
                                    <button onClick={() => { setFilterValue('month'); setIsFilterValue(true) }} className={`daysDetail_ ${filterValue === 'month' ? 'active' : ""}`}>Month</button>
                                </div>
                            </div>
                            <div className='productListTab upcomingTab_ pt-0'>
                                <button className={`plansBtns ${activeTab === 'tableView' ? 'active' : ""}`}
                                    onClick={() => setActiveTab('tableView')}
                                >
                                    <img src={productList1} alt="image" className='showImage' />
                                    <img src={productList2} alt="image" className='hideImage' />
                                </button>
                                <button className={`plansBtns ${activeTab === 'cartView' ? 'active' : ""}`}
                                    onClick={() => setActiveTab('cartView')}>
                                    <img src={productList3} alt="image" className='hideImage' />
                                    <img src={productList4} alt="image" className='showImage' />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* {
                loading === true ?
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                    : */}
                    <>
                        {
                            activeTab === 'tableView' ?
                                <section className='recentTransections mt-5 table-responsive tableSectionBackground bookDataTable'>
                                    <DataTable
                                        pagination
                                        columns={columns1}
                                        customStyles={customStyles}
                                        data={appointmentData}
                                        defaultSortFieldId={1}
                                        className="refundOrders"
                                        conditionalRowStyles={customRowStyles}
                                        onRowClicked={(e) => handleRowClick(e)}
                                        progressPending={loading}
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                        paginationPerPage={perPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                </section> :
                                <Calendar
                                    localizer={localizer}
                                    events={event}
                                    startAccessor="start"
                                    titleAccessor="title"
                                    endAccessor="end"
                                    views={["day", "week", "month"]}
                                    // views={allViews}
                                    components={{
                                        toolbar: CustomToolbar,
                                    }}
                                    style={{ height: 600 }}
                                    popup
                                    date={currentDate}
                                    onSelectEvent={(events) => handleSelectedEvent(events)}
                                />
                        }</>
                </div>
                <div className='righttab_calender'>
                    <div className='topbarcalender_'>
                        <div style={{ cursor: "pointer" }} className='appointuser_'>
                            <img onClick={() => { setModalDetail({ show: true, flag: "bookingrequestModal" }); setKey(Math.random()); }} className='img-fluid posuseimg_' alt="calender" src={calenderappoint} />
                            <span className='appointNotification_ bluenotification_'>{newBookingData ? newBookingData?.length : 0}</span>
                        </div>
                        <div className='appointuser_'>
                            <img className='apouser_' src={appointtoday} alt="userImg" />
                            <span className='appointNotification_ bluenotification_'>{completedBooking ? completedBooking?.length : 0}</span>
                        </div>

                        <ul className='midcionUsers_'>
                            {
                                posList?.length > 0 ?
                                    posList?.map((v, i) => {
                                        return (
                                            <li key={i} className='appointuser_'>
                                                <img className='apouser_' src={v?.user?.user_profiles?.profile_photo ? v?.user?.user_profiles?.profile_photo : profileImg1} alt="userImg" />
                                                <span className='appointNotification_'>0</span>
                                            </li>
                                        )
                                    }) : ""
                            }
                        </ul>
                    </div>
                    <div className='footericoncalender_'>
                        <ul className='midcionUsers_'>
                            <li className='appointuser_ '>
                                <img className='apouser_' src={usersappoint} alt="userImg" />
                                <span className='appointNotification_ bluenotification_'>{posList?.length}</span>
                            </li>
                            <li style={{ cursor: "pointer" }} className='appointuser_' onClick={() => { setModalDetail({ show: true, flag: "BookingsettingModal" }); setKey(Math.random()); }}>
                                <img className='apouser_' src={usersettings} alt="userImg" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* } */}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="false"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={modalDetail.flag === "PendingInvoiceModal" ? "pendingModal" : modalDetail.flag === "rescheduleModal" ? "RescheduleModal" : modalDetail.flag === "confirmCompleteModal" ? "ConfirmCompleteModal" : modalDetail.flag === "checkInModal" ? "CheckInModal" : modalDetail.flag === "BookingsettingModal" ? "BookingsettingModal"
                    : modalDetail.flag === "bookingrequestModal" ? "BookingrequestModal" : modalDetail.flag === "multiplebookingModal" ? "MultiplebookingModal" : ""}
                className={modalDetail.flag === "rescheduleModal" ? "commonWidth customContent" : modalDetail.flag === "PendingInvoiceModal" ? "commonWidth" : ""}
                child={
                    modalDetail.flag === "rescheduleModal" ? (
                        <BookingSchedule handleAllAppointmentData={() => getUserSettingHandle()} changeStatusOfAppointment={(id, status) => changeStatusOfAppointmentHandle(id, status)} data={singleAppointment} loading1={loading1} loading2={loading2} close={() => handleOnCloseModal()} />
                    ) :
                        modalDetail.flag === "checkInModal" ? (
                            <CheckinModal changeStatusOfAppointment={(id, status) => changeStatusOfAppointmentHandle(id, status)} data={singleAppointment} loading2={loading2} close={() => handleOnCloseModal()} />
                        ) :
                            modalDetail.flag === "confirmCompleteModal" ? (
                                <ConfirmCompleteModal changeStatusOfAppointment={(id, status) => changeStatusOfAppointmentHandle(id, status)} data={singleAppointment} loading2={approveLoading} close={() => handleOnCloseModal()} />
                            ) :
                                modalDetail.flag === "BookingsettingModal" ? (
                                    <BookingsettingModal userSettingData={userSettingData} handleAllAppointmentData={() => getUserSettingHandle()} close={() => handleOnCloseModal()} />
                                ) :
                                    modalDetail.flag === "bookingrequestModal" ? (
                                        <BookingrequestModal loading1={loading1} loading2={loading2} changeStatus={(id, status) => changeStatusForNewRequest(id, status)} newBookingData={newBookingData} close={() => handleOnCloseModal()} />
                                    ) :
                                        modalDetail.flag === "multiplebookingModal" ? (
                                            <MultiplebookingModal detailsUpdate={(newFlag, data) => detailsUpdate(newFlag, data)} changeStatusOfAppointment={(id, status) => changeStatusOfAppointmentHandle(id, status)} loading2={loading2} posList={posList} singleAppointment={singleAppointment} close={() => handleOnCloseModal()} />
                                        ) :
                                            modalDetail.flag === "PendingInvoiceModal" ? (
                                                <AppointSchedule data={singleAppointment} close={() => handleOnCloseModal()} />
                                            ) : ""

                }
                header=
                {modalDetail.flag === "rescheduleModal" ?
                    <>
                        <p onClick={handleOnCloseModal} className='modal_cancel'>
                            <img style={{ cursor: "pointer" }} src={modalCancel} className='ModalCancel' />
                        </p>
                        <h4 className='modalHeading_'>Booking  #<span>{singleAppointment?.id}</span></h4>
                    </> :
                    modalDetail.flag === 'PendingInvoiceModal'
                        ?
                        <div className="modalHeader_">
                            <div className="common_">
                                <h3 className='modalHeading_'>Invoice#{singleAppointment?.invoices?.invoice_number}</h3>

                                <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                    <i className="las la-times" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                        :
                        modalDetail.flag === "checkInModal" || modalDetail.flag === "confirmCompleteModal" ?
                            <>
                                <p onClick={handleOnCloseModal} className='modal_cancel'>
                                    <img style={{ cursor: "pointer" }} src={modalCancel} className='ModalCancel' />
                                </p>
                            </>
                            :
                            ''
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default UpcomingBooking;               