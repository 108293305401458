import React from "react";
import { profileImg1, user } from "../../../utilities/images";
import moment from "moment-timezone";

const SaleinvoiceModal = (props) => {

  console.log(props?.data?.seller_details?.current_address?.street_address, "sasasasasa");
  return (
    <div className="saleinvoiceModal">
      <div className="pendingInvoice commonPadding_">
        <div className="invoiceDetail">
          <div className="row">
            <div className="col-md-8">
              <div className="invoiceHead">
                <h3 className="invoiceHeading">Invoice</h3>
              </div>
            </div>
            <div className="col-md-4">
              <div className="CustomerOnlinestatus_">
                <h5 className="buyHeading">Invoice details</h5>
                <p className="invoiceHead">
                  Invoice Id :{" "}
                  <span>
                    {props?.data?.invoices?.invoice_number
                      ? props?.data?.invoices?.invoice_number
                      : props?.data?.return_detail?.invoices?.invoice_number}
                  </span>
                </p>
                <p className="invoiceHead">
                  Created Date :{" "}
                  <span>
                    {moment(
                      props?.data?.invoices?.created_date
                        ? props?.data?.invoices?.created_date
                        : props?.data?.return_detail?.invoices?.created_date
                    ).format("DD MMM YYYY")}
                  </span>
                </p>
                {props?.data?.invoices?.due_date ||
                props?.data?.return_detail?.invoices?.due_date ? (
                  <p className="invoiceHead">
                    Due Date :{" "}
                    <span>
                      {moment(
                        props?.data?.invoices?.due_date
                          ? props?.data?.invoices?.due_date
                          : props?.data?.return_detail?.invoices?.due_date
                      ).format("DD MMM YYYY")}
                    </span>
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="invoiceForm">
          <h5 className="semiboldHeading_">Invoice from:</h5>
          <p className="smalllightHead_">
            {props?.data?.seller_details?.firstname +
              " " +
              props?.data?.seller_details?.lastname}
          </p>
          <p className="smalllightHead_">
            {props?.data?.seller_details?.phone_number}
          </p>
          <p className="smalllightHead_">
            {props?.data?.seller_details?.current_address?.street_address +
              " " +
              props?.data?.seller_details?.current_address?.city +
              " " +
              props?.data?.seller_details?.current_address?.country +
              " " +
              props?.data?.seller_details?.current_address?.zipcode}
          </p>
        </div>
        <div className="customerInvoicetable mt-4">
          <div className="table-responsive">
            <table className="table  listItemsContainer">
              <thead className="tHead_">
                <th className="text-start">#</th>
                <th className="text-start">Descriptions</th>
                <th className="text-start">No. of Items</th>
                <th className="text-start">Rate</th>
                <th>Amount</th>
              </thead>
              <tbody>
                {props?.data?.order_details?.map((val, index) => {
                  return (
                    <tr key={index} role="row">
                      <td className="text-start">{index + 1}</td>
                      <td className="text-start">
                        <p className="contentheadtext_">{val?.product_name}</p>
                      </td>
                      <td className="text-start">
                        <p className="contentheadtext_">{val?.qty}</p>
                      </td>
                      <td className="text-start">
                        <p className="contentheadtext_">
                          ${Number(val?.price)?.toFixed(2)}
                        </p>
                      </td>
                      <td className="text-start">
                        <p className="itemsTxt">
                          ${Number(val?.price * val?.qty)?.toFixed(2)}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="totalArea">
            <div className="row">
              <div className="col-md-6 text-start">
                <div className="notes">
                  <textarea
                    className=""
                    placeholder="Notes"
                    id="floatingTextarea"
                    rows="9"
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6 text-start">
                <div className="Customertotal">
                  <div className="totalDetails">
                    <h5 className="personalinfoHead">Sub Total</h5>
                    <h5 className="personalinfoHead">
                      ${Number(props?.data?.total_sale_price)?.toFixed(2)}
                    </h5>
                  </div>
                  <div className="totalDetails">
                    <h5 className="personalinfoHead">
                      Discount{" "}
                      {props?.data?.coupon_code ? props?.data?.coupon_code : ""}
                    </h5>
                    <h5 className="personalinfoHead">
                      $
                      {props?.data?.discount
                        ? Number(props?.data?.discount)?.toFixed(2)
                        : "0.00"}
                    </h5>
                  </div>
                  {props?.data?.delivery_option == 1 ||
                  props?.data?.delivery_option == 4 ? (
                    <>
                      <div className="totalDetails">
                        <h5 className="personalinfoHead">
                          {" "}
                          {props?.data?.delivery_option == 4
                            ? "Shipping"
                            : "Delivery"}{" "}
                          Charge
                        </h5>
                        <h5 className="personalinfoHead">
                          $
                          {props?.data?.delivery_option == 4
                            ? Number(props?.data?.shipping_charge)?.toFixed(2)
                            : Number(props?.data?.delivery_charge)?.toFixed(2)}
                        </h5>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="totalDetails">
                    <h5 className="personalinfoHead">Tax</h5>
                    <h5 className="personalinfoHead">
                      $
                      {props?.data?.tax
                        ? Number(props?.data?.tax)?.toFixed(2)
                        : "0.00"}
                    </h5>
                  </div>
                  <div className="totalDetails">
                    <h5 className="personalinfoHead">Tips</h5>
                    <h5 className="personalinfoHead">
                      $
                      {props?.data?.tips
                        ? Number(props?.data?.tips)?.toFixed(2)
                        : "0.00"}
                    </h5>
                  </div>
                  <div className="totalDetails">
                    <h5 className="itemsText ">Total</h5>
                    <h5 className="itemsText">
                      ${Number(props?.data?.payable_amount)?.toFixed(2)}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props?.data?.driver_details ? (
            <div className="carrierbox">
              <h5>JBR Delivery</h5>
              <div className="shippingContact carriercontact">
                <div className="shipcontactLeft">
                  <img
                    style={{ width: "40px" }}
                    className="me-2"
                    src={
                      props?.data?.driver_details?.profile_photo
                        ? props?.data?.driver_details?.profile_photo
                        : profileImg1
                    }
                  />
                  <div className="contctInfo">
                    <h4>
                      {props?.data?.driver_details?.firstname +
                        " " +
                        props?.data?.driver_details?.lastname}
                    </h4>
                  </div>
                </div>
                <button className="contactBtn">
                  <i className="las la-phone"></i>
                  <h3>
                    Contact
                    <br />
                    {props?.data?.driver_details?.phone_number}
                  </h3>
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default SaleinvoiceModal;
