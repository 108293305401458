import React, { useEffect, useState } from 'react'
import { shareMap, orderproduct, parachute, downlight } from '../../../utilities/images'
import ChartArea from '../dashboard/wallet/ChartArea';
import { selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { getInventoryCountApiAsync, getInventoryDetailsApiAsync, getInventorySalePurchaseStatisticsApiAsync } from '../dashboard/dashboardSlice';
import { updateInventoryApiAsync } from '../myProducts/productSlice';

const Inventory = () => {
    const dispatch = useDispatch();
    const [filterValue, setFilterValue] = useState("This week");
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const [countData, setCountData] = useState([]);
    const [detailsData, setDetailsData] = useState('');
    const [activeData, setActiveData] = useState('');
    const [inHandData, setInHandData] = useState('');
    const [salePurchaseData, setSalePurchaseData] = useState('');

    const handleFilterValue = (value) => {
        setFilterValue(value);
    }

    const options1 = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }
    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    }

    const data = {
        labels: salePurchaseData?.labels,
        datasets: [
            {
                label: 'Purchases',
                data: salePurchaseData?.datasets ? salePurchaseData?.datasets[1]?.purchase :"",
                borderColor: '#FB466C',
            },
            {
                label: 'Sales',
                data: salePurchaseData?.datasets ? salePurchaseData?.datasets[0]?.sale : '',
                borderColor: '#275AFF',
            },
        ],
    };

    const doughnutData = {
        labels: ['Active', 'In-active'],
        datasets: [
            {
                label: 'Percentage',
                data: [activeData?.activeInventoryPercentage, 100-activeData?.activeInventoryPercentage],
                backgroundColor: [
                    '#275AFF',
                    '#FB466C'
                ],
                borderColor: [
                    '#275AFF',
                    '#FB466C'
                ],
                borderWidth: 1,
            },
        ],
    };

    const inventoryCountHandle = () => {
        let params = {
            "postData": {
                filter: filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getInventoryCountApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setCountData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const inventoryDetailsHandle = () => {
        let params = {
            "postData": {
                filter: 'details',
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getInventoryDetailsApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setDetailsData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    const inventoryActiveDataHandle = () => {
        let params = {
            "postData": {
                filter: 'active',
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getInventoryDetailsApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setActiveData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    const inventoryQuantityInHandHandle = () => {
        let params = {
            "postData": {
                filter: 'inHand',
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getInventoryDetailsApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setInHandData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const salePurchaseStatisticsHandle = () => {
        let params = {
            "postData": {
                filter: filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getInventorySalePurchaseStatisticsApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setSalePurchaseData(obj?.payload)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        inventoryCountHandle()
        inventoryDetailsHandle()
        inventoryActiveDataHandle()
        inventoryQuantityInHandHandle()
        salePurchaseStatisticsHandle()
    }, [filterValue])

    return (
        <div className='myOrdersmain'>
            <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>Inventory</span>
                            </h2>
                        </div>

                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-12 text-end'>
                    <div className="dropdown weekDropdown">
                        <button className="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {filterValue} <img src={downlight} alt="img" />
                        </button>
                        <ul className="dropdown-menu weekDropdownMenus">
                            <li><a className="dropdown-item" onClick={() => handleFilterValue("Today")} >Today</a></li>
                            <li><a className="dropdown-item" onClick={() => handleFilterValue("This week")} >This week</a></li>
                            <li><a className="dropdown-item" onClick={() => handleFilterValue("Month")}> Month</a></li>
                            <li><a className="dropdown-item" onClick={() => handleFilterValue("Year")}> Year</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {
                loading === true ?
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                    :
                    <>
                        <div className='verificationTotal'>
                            <div className='row'>
                                {
                                    countData?.length > 0 ?
                                        countData?.map((v, i) => {
                                            return (
                                                <div key={i} className='col-sm-6 col-md-3 col-lg-3 customSpce'>

                                                    <div className='percentageContainer justify-content-between align-items-center'>
                                                        <div className='percentageValues'>
                                                            <h3 className='mainHeadingsub_ '>{v?.value}</h3>
                                                            <p className='textinner_'>{v?.name}</p>
                                                        </div>
                                                        <div className='percentageRating mapbg'>
                                                            <img src={shareMap} className="" alt='ShareMap' />
                                                            <p className='percentageRate'>{v?.percentageChange.toFixed(2)}%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : <></>
                                }
                            </div>
                        </div>
                        <div className='inventoryDetails_ mt-4 mb-3'>
                            <div className='row'>
                                <div className='col-md-7 outerBorder'>
                                    <div className='chartsOuter'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <h3 className='textInnerHead mb-2'>Inventory Details</h3>
                                                <ul className='productDetailsNumber'>
                                                    <li className='inventoryDetailsItems added_'>
                                                        <span className='productDetailsValues'>Low Stock items</span>
                                                        <span className='productDetailsValues fw-bold'>{detailsData?.lowStockInventory}</span>
                                                    </li>
                                                    <li className='inventoryDetailsItems discontinued_'>
                                                        <span className='productDetailsValues'>Items to be adjusted</span>
                                                        <span className='productDetailsValues fw-bold'>{detailsData?.inventoryToAdjust}</span>
                                                    </li>
                                                    <li className='inventoryDetailsItems totalActive_'>
                                                        <span className='productDetailsValues'>Items to be reached</span>
                                                        <span className='productDetailsValues fw-bold'>{detailsData?.inventoryToReceive}</span>
                                                    </li>
                                                    <li className='inventoryDetailsItems'>
                                                        <span className='productDetailsValues'>Items to be shipped</span>
                                                        <span className='productDetailsValues fw-bold'>{detailsData?.inventoryToShip}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='inventoryGraph'>
                                                    <h3 className='textInnerHead mb-1'>Active Items</h3>
                                                    {/* <img className='inventgraph' src={inventgraph}/> */}
                                                    <ChartArea classes="col-md-7  chartProduct" options={options2} data={doughnutData} chartType="Doughnut" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className='chartsOuter'>
                                        <div className='quantityHand_'>
                                            <div className='quantityContent_'>
                                                <img src={orderproduct} alt="orderProduct"/>
                                                <h5 className='subtext_ mt-2 mb-2'>Quantity in Hand</h5>
                                                <h3 className='mainbluekHead_'>{inHandData?.inventoryInHand}</h3>
                                            </div>
                                            <div className='quantityContent_'>
                                                <img src={parachute} />
                                                <h5 className='subtext_ mt-2 mb-2'>Will be Received</h5>
                                                <h3 className='mainbluekHead_'>{inHandData?.inventoryToReceive}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='tradesValue inventoryvalue mt-4 mb-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='chartsOuter'>
                                        <h3 className="textInnerHead mt-3">Sales and Purchases Statistics</h3>

                                        <div className='row mt-4 mb-4 align-items-end'>
                                            <div className='col-md-3'>
                                                <div className='tradesContainer'>
                                                    <div className='tradesMain'>
                                                        <span className='innerHeadingSky'>${salePurchaseData?.sales?.toFixed(2)}</span>
                                                        <span className='textinner_'>Sales </span>
                                                    </div>
                                                    <div className='tradesMain'>
                                                        <span className='inactiveHeading_'>${salePurchaseData?.purchases?.toFixed(2)}</span>
                                                        <span className='textinner_'>Purchases</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-9'>
                                                <ChartArea classes="productSales" header="" options={options1} data={data} chartType="Line" />
                                                {/* <img src={salesChart} alt="Graph Image" className='img-fluid' /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
        </div>
    )
}

export default Inventory