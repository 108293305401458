import React, { useEffect } from 'react'
import { category, category2, secrviceIcon } from '../../../utilities/images'
import { useState } from 'react'
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import ChartArea from '../dashboard/wallet/ChartArea'
import { getProductSalesGraphApiAsync, getProductTrendingGraphAsync, getServiceCountDataApiAsync, getServiceSalesBarGraphApiAsync, getTopSellingApiAsync } from '../myProducts/productSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import CustomLoader from '../../components/shared/CustomLoader';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { addThousandSeparator } from '../../../utilities/thousandCommaSeparated';


const MyService = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [salesGraphData, setSalesGraphData] = useState("")
    const [pieChartData, setPieChartData] = useState("")
    const [salesTrendingData, setSalesTrendingData] = useState("")
    const [barGraphData, setBarGraphData] = useState("")
    const [countData, setCountData] = useState("")
    const [topSellingProduct, setTopSellingProduct] = useState([])
    const [topSellingBrand, setTopSellingBrand] = useState([])
    const [topSellingCategory, setTopSellingCategory] = useState([])
    const [filterValue, setFilterValue] = useState("This week");
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const handleFilterValue = (value) => {
        setFilterValue(value);
        let params = {
            filterValue: value === "This week" ? "week" : value?.toLowerCase()
        }
        getSalesGraphData(params)
        getWeeklySalesBarGraphData(params)
    }

    const options1 = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }

    let otherOption = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
    }
    const lineData = {
        labels: salesGraphData ? salesGraphData?.labels : [],
        datasets: [
            {
                label: 'sales',
                data: salesGraphData ? salesGraphData?.datasets[0]?.data : [],
                borderColor: '#1FB3FF',
                backgroundColor: '#1FB3FF',
            }
        ],
    };
    const doughnutDataProduct = {
        labels: ['Total Services', "Low Sell Services"],
        datasets: [
            {
                label: '',
                data: [pieChartData?.totalInventory, pieChartData?.lowSold],
                backgroundColor: [
                    '#3C444D',
                    '#FB466C',
                    "#D33300"
                ],
                borderColor: [
                    '#3C444D',
                    '#FB466C',
                    "#D33300"
                ],
                borderWidth: 1,
            },
        ],
    };



    const serviceCountDataHandle = () => {
        let countParams = {
            postData: {
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                // "filter": "year",
                "service_type": "service"
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getServiceCountDataApiAsync(countParams))
            .then(unwrapResult)
            .then((obj) => {
                setCountData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getSalesTrendData = () => {
        let params = {
            postData: {
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                "type": "service"
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getProductTrendingGraphAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setSalesTrendingData(obj?.payload)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getTopSellingProduct = () => {
        let params = {
            postData: {
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                "type": "service",
                // limit: "10"
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getTopSellingApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setTopSellingProduct(obj?.payload?.filter(item=>item?.product_details?.name))
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getTopSellingCategory = () => {
        let params = {
            postData: {
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                "service_type":'service',
                "type": "category"
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getTopSellingApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setTopSellingCategory(obj?.payload?.length>10 ?  obj?.payload?.slice(0,10) : obj?.payload)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getSalesGraphData = (data) => {
        let params = {
            postData: {
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                "filter": data?.filterValue ? data?.filterValue : "week",
                "type": "service"
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getProductSalesGraphApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setSalesGraphData(obj?.payload)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    const getPieChartData = (data) => {
        let params = {
            postData: {
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                "filter": data?.filterValue ? data?.filterValue : "week",
                "type": "service",
                "service_pie_chart": true
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getProductSalesGraphApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setPieChartData(obj?.payload)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    const getWeeklySalesBarGraphData = (data) => {
        let paramsData = {
            postData: {
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                "filter": data?.filterValue ? data?.filterValue : "week",
                "type": "service"
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getServiceSalesBarGraphApiAsync(paramsData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setBarGraphData(obj?.payload)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }


    useEffect(() => {
        getSalesTrendData()
        getPieChartData()
        getSalesGraphData(null)
        serviceCountDataHandle()
        getTopSellingProduct()
        getTopSellingCategory()
        getWeeklySalesBarGraphData(null)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Services";
      }, []);
    return (
        <>
            <DashboardNavbar title="Service Overview" />
            <div className='myProductmain seviceMain_'>
                {/* <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">Services </h2>
                        </div>
                        <div className="col-md-6 text-end">
                            <button className='backgroundFillbtn' onClick={() => history.push('/add-service')}> <span className='plusIcon_'><i className="fa-solid fa-plus"></i> </span> Add Service </button>
                        </div>
                    </div>
                </div>
            </div> */}

                {/* <div className='row mb-3'>
                <div className='col-md-12 text-end'>
                    <div className="dropdown weekDropdown">
                        <button className="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {filterValue} <img src={downlight} alt="img" />
                        </button>
                        <ul className="dropdown-menu weekDropdownMenus">
                            <li><a className="dropdown-item" onClick={() => handleFilterValue("Today")} >Today</a></li>
                            <li><a className="dropdown-item" onClick={() => handleFilterValue("This week")} >This week</a></li>
                            <li><a className="dropdown-item" onClick={() => handleFilterValue("Month")}> Month</a></li>
                            <li><a className="dropdown-item" onClick={() => handleFilterValue("Year")}> Year</a></li>
                        </ul>
                    </div>
                </div>
            </div> */}
                {
                    loading ?
                        // <CustomLoader /> 
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                        :
                        <>
                            <div class="grossProfitData productProfitData">
                                <div className='row'>
                                    {
                                        countData?.result?.length > 0 ?
                                            countData?.result?.filter(v => v?.title !== "Brand")?.map((v, i) => {
                                                return (
                                                    <div key={i} className='col-sm-6 col-md-4 col-lg-4 customSpce'
                                                        onClick={() => history.push('/service-list')}>
                                                        <div className='grossTotalorder'>
                                                            <img src={v?.title === "Category" ? category :
                                                                v?.title === "Sub Category" ? category2 :
                                                                    v?.title === 'Product' ? secrviceIcon : category
                                                            } className="mb-4 serviceSubImg" alt='ShareMap' />

                                                            <div className='percentageValues'>
                                                                <h3 className='heading_ '>{v?.count}</h3>
                                                                <p className='textInner'>{v?.title === "Product" ? "Services" : v?.title}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            }) : <></>
                                    }
                                </div>
                            </div>
                            {/* <div className='productSales mt-2 mb-2'>
                <div className='col-md-12'>
                    <div className='chartsOuter mt-4 mb-3'>
                        <h3 className='textInnerHead'>Services Sales </h3>
                        <img className='graphImage mt-4' src={graphImg} />
                    </div>
                </div>
            </div> */}
                            <div className='chartsInfo mt-4 mb-3'>
                                <div className="row">
                                    <div className='col-md-4 '>
                                        <div className='chartInfoSub '>
                                            <h6 className='chartActive'>Active Services</h6>
                                            <div className='row justify-content-center'>
                                                <ChartArea classes="col-md-12 col-xl-10 col-xxl-10 col-lg-10 chartProduct" push={() => history.push('/analytics')} header="Product Sales Trends" options={otherOption} data={doughnutDataProduct} chartType="Doughnut" />
                                            </div>
                                            <div className="row">
                                                <div className='col-lg-12 outerBorder'>
                                                    <div className='productDetailInfo'>
                                                        <div className='dFlex'>
                                                            <h6 className='totalcharts_'>Total Services: </h6>
                                                            <h6 className='chartActive'>{pieChartData?.totalInventory}</h6>
                                                        </div>
                                                        <div className='dFlex stockItem_'>
                                                            <h6 className='totalcharts_'>Low Sell Services:</h6>
                                                            <h6 className='chartActive'> {pieChartData?.lowSold}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='chartInfoSub '>
                                            <h6 className='chartActive text-start'>Service Sales </h6>
                                            <ChartArea classes="productSales mt-2 mb-2" header="Services Sales" push={() => history.push('/analytics')} options={options1} data={lineData} chartType="Line" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='topProducts_'>
                                <div className='row'>
                                    <div className='col-xl-6 col-lg-12'>
                                        <div className='chartsOuter'>
                                            <h5 className='dollarSign mb-3'>Top Service</h5>
                                            <div className='topItemsection dFlex'>
                                                <div className='itemSale_'>
                                                    <h4 className='itemsText'>Services</h4>
                                                </div>
                                                <div className='unitSale'>
                                                    {/* <h4 className='itemsText '>Unit Sold</h4> */}
                                                    <h4 className='itemsText '>Booking</h4>
                                                    <h4 className='itemsText '>Gross Sale</h4>
                                                </div>
                                            </div>
                                            <div className='countriesDetails mt-3'>
                                                {
                                                    topSellingProduct?.length > 0 ?
                                                        <>
                                                            {
                                                                topSellingProduct?.map((val, index) => {
                                                                    return (
                                                                        <div key={index} className='countryInnerderail topItemsection dFlex'>
                                                                            <div className='itemSale_'>
                                                                                <span className='serialNo'>{index + 1}</span>
                                                                                <div className="countryCount dFlex">
                                                                                    <img className='brandImg' style={{ width: "50px", height: "40px" }} src={val?.product_details?.image} />
                                                                                </div>
                                                                                <div className='countryContent ms-2'>
                                                                                    <h4 className='mainlightHead'>{val?.product_details?.name?.length > 15 ? (val?.product_details?.name)?.slice(0, 15) + "..." : val?.product_details?.name}</h4>
                                                                                    {/* <h5 className='tableHeadsmall'>Belicoso 6 × 54</h5> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='unitSale'>
                                                                                <h5 className='itemsText ms-3'>{val?.qty_sum}</h5>
                                                                                <h5 className='itemsText'>${addThousandSeparator((val?.price_sum).toFixed(2))}</h5>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        "No Services Found"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' col-xl-6 col-lg-12 '>
                                        <div className='chartsOuter'>
                                            <h5 className='dollarSign mb-3'>Top Service Category</h5>
                                            <div className='topItemsection dFlex'>
                                                <div className='itemSale_'>
                                                    <h4 className='itemsText'>Service Category</h4>
                                                </div>
                                                <div className='unitSale'>
                                                    {/* <h4 className='itemsText '>Service Unit Sold</h4> */}
                                                    <h4 className='itemsText '>Booking</h4>
                                                    <h4 className='itemsText '>Gross Sale</h4>
                                                </div>
                                            </div>
                                            <div className='countriesDetails mt-3'>
                                                {
                                                    topSellingCategory?.length > 0 ?
                                                        <>
                                                            {
                                                                topSellingCategory?.map((val, index) => {
                                                                    return (
                                                                        <div key={index} className='countryInnerderail topItemsection dFlex'>
                                                                            <div className='itemSale_'>
                                                                                <span className='serialNo'>{index + 1}</span>
                                                                                <div className="countryCount dFlex">
                                                                                    <img className='brandImg' style={{ width: "50px", height: "40px" }} src={val?.product_details ?.image} />
                                                                                </div>
                                                                                <div className='countryContent ms-2'>
                                                                                    <h4 className='mainlightHead'>{`${(val?.product_details?.name)?.slice(0, 35) ? (val?.product_details?.name)?.slice(0, 35): ""}...`}</h4>
                                                                                    {/* <h5 className='tableHeadsmall'>Belicoso 6 × 54</h5> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='unitSale'>
                                                                                <h5 className='itemsText ms-3'>{val?.qty_sum}</h5>
                                                                                <h5 className='itemsText'>${addThousandSeparator((val?.price_sum).toFixed(2))}</h5>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        "No Categories Found"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                }

            </div>
        </>
    )
}

export default MyService