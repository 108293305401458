import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import DataTable from 'react-data-table-component';
import CustomModal from '../../components/shared/CustomModal'
import MyProductDetials from '../myProducts/myProductDetails';
import { changeServiceAvailabilityStatusApiAsync, getProductServiceListApiAsync, selectProductData } from '../myProducts/productSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { deleteProductApiAsync } from '../dashboard/dashboardSlice';
import { addThousandSeparator } from '../../../utilities/thousandCommaSeparated';


const ServiceListTable = forwardRef((props, ref) => {
    const history = useHistory()
    const [selectedProduct, setSelectedProduct] = useState({});
    const [key, setKey] = useState(Math.random());
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("ServiceTable");
    const [serviceData, setServiceData] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const productData = useSelector(selectProductData)
    let ProductsData = productData?.payload?.data;
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
   
    const availabilityStatusHandle = (id, status) => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                type: 'digital',
                id: id,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                // "seller_id": "deca13cf-3f00-4776-9bfc-30a29c40af2f",
            },
            statusData: {
                availability_status: status === false ? 1 : 0
            }
        }
        // setLoading(true)
        dispatch(changeServiceAvailabilityStatusApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let data = {
                    tokenData: auth?.payload?.token,
                    postData: {
                        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                        // "seller_id": "deca13cf-3f00-4776-9bfc-30a29c40af2f",

                        type: "digital",
                        app_name: "merchant",
                        category_ids: props?.categoryId ? props?.categoryId : [],
                        sub_category_ids: props?.subCategoryId ? (props?.subCategoryId).toString() : [],
                        brand_id: props?.brandId ? props?.brandId : [],
                        lat: 32.5534,
                        long: 76.1258,
                        filter_deleted: true,
                        page: currentPage,
                        limit: perPage,

                    }
                }
                // setLoading(true)
          
                dispatch(getProductServiceListApiAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        setServiceData(obj?.payload?.data)
                        setLoading(false)
                     
                    })
                    .catch((err) => {
                        setLoading(false)
                    })
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    let columns1 = [
        // {
        //     name: '#',
        //     // selector: row => row.id,
        //     selector: (row, i) => (i + 1 * (perPage * (currentPage - 1)) + 1),
        //     width: "80px"
        // },
        {
            name: "Service Name",
            selector: row => {
                return (
                    <>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img className='userInnerImg' src={row?.image} alt='' />
                            <div className='ms-2'>
                                <span className='textParagh '>{row?.name}</span>
                            </div>
                        </div>

                    </>
                );
            },
            width: "300px",
        },
        {
            name: 'Availability',
            selector: row => {
                console.log(row, "row?.supplies");
                return (
                    <>
                        <div className='d-flex align-items-center productToggleItems' >
                            <div className="form-check form-switch me-2 mb-0">
                                <input
                                    type="checkbox"
                                    id="availabilityStatus"
                                    name="availabilityStatus"
                                    value="availabilityStatus"
                                    checked={row?.supplies[0]?.status === true}
                                    onChange={() => availabilityStatusHandle(row?.id, row?.supplies[0]?.status)}
                                />
                            </div>
                        </div>
                    </>
                );
            },

        },
        {
            name: "",
            selector: row => {
                return (
                    <>
                        <div className='d-flex justify-space-between align-items-center pe-3 ps-3'>
                            <div className='dropdown tableDropdown ms-3 '>
                                <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                                    <i className="fal fa-ellipsis-v icon_"></i>
                                </span>
                                <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                                    <li className='dropdown-item' onClick={() => {
                                        setModalDetail({ show: true, flag: "MyProductDetials" });
                                        setKey(Math.random());
                                        setSelectedProduct(row);
                                    }}
                                    >View</li>
                                    {/* <li className='dropdown-item' >Edit Variants</li> */}
                                    <li className='dropdown-item' onClick={() => history.push({ pathname: `/editService`, state: row })}>Edit</li>
                                    {/* <li className='dropdown-item' onClick={() => history.push({ pathname: `editServiceSupply`, state: row })}>Edit Supply</li> */}
                                    <li className='dropdown-item' onClick={() => { row = { ...row, from: "duplicateService" }; history.push({ pathname: `/add-service`, state: row }) }} >Duplicate Service</li>
                                    <li className='dropdown-item' onClick={() => deleteHandle(row)}>Delete</li>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
            width: "70px",
        },
    ];

    if (props?.columnStatus?.category) {
        columns1 = [...columns1?.slice(0, columns1?.length - 1),
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.category?.name?.length > 15 ? row?.category?.name?.substring(0, 15) + "..." : row?.category?.name}</span>
                    </>
                );
            },
            width: "160px",
            name: 'Category'
        },
        columns1[columns1?.length - 1],
        ]
    }
    if (props?.columnStatus?.locations) {
        columns1 = [...columns1.slice(0, columns1.length - 1),
        {
            name: 'Location',
            selector: row => {
                console.log(row, "Locationrow");
                return (
                    <>
                        <span className='textParagh listhead_'>{row?.locations}</span>
                    </>
                );
            },
            // width: "200px",
        },
        columns1[columns1.length - 1],
        ]
    }
    if (props?.columnStatus?.sub_category) {
        columns1 = [...columns1.slice(0, columns1.length - 1),
        {
            name: 'Sub-Category',
            selector: row => {
                console.log(row, "rowdataa");
                return (
                    <>
                        <span className='textParagh '>{row?.sub_category?.name}</span>
                    </>
                );
            },
            width: "160px",
        },
        columns1[columns1.length - 1],
        ]
    }
    if (props?.columnStatus?.staff) {
        columns1 = [...columns1.slice(0, columns1.length - 1),
        {
            name: 'staff',
            selector: row => {
                console.log(row, "counttttttttt");
                return (
                    <>
                        <span className='textParagh ' >{row?.staff_count}</span>
                    </>
                );
            },
            width: "160px",
        },
        columns1[columns1.length - 1],
        ]
    }
    if (props?.columnStatus?.price) {
        columns1 = [...columns1.slice(0, columns1.length - 1),
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>${addThousandSeparator(Number(row?.price)?.toFixed(2))}</span>
                    </>
                );
            },
            name: 'Price',
            // width: "250px"
        },
        columns1[columns1.length - 1],
        ]
    }

    const deleteHandle = (row) => {
        Swal.fire({
            title: 'Do you want to delete the service?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    postData: {
                        product_ids: [row?.id]
                    },
                    tokenData: auth?.payload?.token
                }
                setDeleteLoading(true)
                dispatch(deleteProductApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        serviceListDataHandle()
                        setDeleteLoading(false)
                        toast.success("Service deleted successfully")
                    })
                    .catch((obj) => {
                        setDeleteLoading(false)
                    })
            }
        })
    }

    const handleRowSelected = (state) => {
        console.log(state, 'stateeeeee');
        props?.setSelectedRows(state?.selectedRows)
    }

    const handleRowClick = (e) => {
        // setSelectedProduct(e);
        // setModalDetail({ show: true, flag: "MyProductDetials" });
        // setKey(Math.random());
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
        // props.pageData(page,perPage)
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const serviceListDataHandle = () => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                // "seller_id": "deca13cf-3f00-4776-9bfc-30a29c40af2f",

                type: "digital",
                app_name: "merchant",
                lat: 32.5534,
                long: 76.1258,
                search: props?.keyword || undefined,
                // category_ids: props?.categoryId ? props?.categoryId : [],
                // sub_category_ids: props?.subCategoryId ? (props?.subCategoryId).toString() : [],
                // brand_id: props?.brandId ? (props?.brandId).toString() : [],
                page: currentPage,
                limit: perPage,
                need_pos_users: true,
                filter_deleted: true

            }

        }
        if (props?.startDate != null && props?.endDate != null) {
            let newData = {
                ...params.postData,
                "start_date": moment(props?.startDate).format('YYYY-MM-DD'),
                "end_date": moment(props?.endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }

        if (props?.subCategoryId?.length > 0) {
            let newParams = {
                ...params.postData,
                sub_category_ids: props?.subCategoryId ? (props?.subCategoryId).toString() : [],
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newParams
            }
        }
        setLoading(true)
        dispatch(getProductServiceListApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setServiceData(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }


    useImperativeHandle(ref, () => ({
        getProductListParent() {
            setLoading(true)
            let params = {
                tokenData: auth?.payload?.token,
                postData: {
                    seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                    // "seller_id": "deca13cf-3f00-4776-9bfc-30a29c40af2f",
    
                    type: "digital",
                    app_name: "merchant",
                    lat: 32.5534,
                    long: 76.1258,
                    search: props?.keyword || undefined,
                    // category_ids: props?.categoryId ? props?.categoryId : [],
                    // sub_category_ids: props?.subCategoryId ? (props?.subCategoryId).toString() : [],
                    // brand_id: props?.brandId ? (props?.brandId).toString() : [],
                    page: currentPage,
                    limit: perPage,
                    need_pos_users: true,
                    filter_deleted: true
    
                }
    
            }
            if (props?.startDate != null && props?.endDate != null) {
                let newData = {
                    ...params.postData,
                    "start_date": moment(props?.startDate).format('YYYY-MM-DD'),
                    "end_date": moment(props?.endDate).format('YYYY-MM-DD')
                }
                params = {
                    tokenData: auth?.payload?.token,
                    postData: newData
                }
            }
    
            if (props?.subCategoryId?.length > 0) {
                let newParams = {
                    ...params.postData,
                    sub_category_ids: props?.subCategoryId ? (props?.subCategoryId).toString() : [],
                }
                params = {
                    tokenData: auth?.payload?.token,
                    postData: newParams
                }
            }
            setLoading(true)
            dispatch(getProductServiceListApiAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setServiceData(obj?.payload?.data)
                    setTotalRows(obj?.payload?.total)
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                })
        },
      }));

    useEffect(() => {
        if (props?.selectedValue) {
            serviceListDataHandle()
        }
    }, [perPage, currentPage, props?.subCategoryId, props?.brandId, props.endDate])

    useEffect(() => {
        if(props?.keyword == "" || props?.keyword?.length > 2){
            serviceListDataHandle();
        }
      }, [props?.keyword])
      

    // const handleRowClick = (e) => {
    //     history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    // }
    return (
        <>
            <section className='recentTransections table-responsive  serviceListTable commonTableheight'>
                <DataTable
                    columns={columns1}
                    data={ProductsData}
                    defaultSortFieldId={1}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    selectableRows
                    onSelectedRowsChange={handleRowSelected}
                // onRowClicked={(e)=>handleRowClick(e)}
                />
            </section>
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={modalDetail.flag === "MyProductDetials" ? "myProductDetails" : ""}
                size={modalDetail.flag === "MyProductDetials" ? "xl" : "md"}
                child={modalDetail.flag === 'MyProductDetials' ? <MyProductDetials close={() => handleOnCloseModal()} selectedProductId={selectedProduct?.id} serviceType={true} />
                    : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
});
export default ServiceListTable