import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import DataTable from 'react-data-table-component'
import { selectLoginAuth } from '../../auth/authSlice'
import CreateAgentModal from './createAgentModal'
import CustomModal from '../../../components/shared/CustomModal'
import { deleteSupportAgentApiAsync, getAllSupportAgentsApiAsync, getDepartmentDataInSupportApiAsync } from '../../dashboard/dashboardSlice'
import Swal from 'sweetalert2';
import AgentDeatailsModal from './agentDeatailsModal'
import UpdateAgentModal from './updateAgentModal'

const SupportAgents = () => {
    const [loading, setLoading] = useState(false)
    const [agentList, setAgentList] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [departmentData, setDepartmentData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRow, setSelectedRow] = useState('');
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const [key, setKey] = useState(Math.random());

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const columns1 = [
        {
            name: '#',
            selector: row => row?.id,
            width: "70px"
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.user_profiles?.firstname + " " + row?.user_profiles?.lastname}</span>
                    </>
                );
            },
            name: 'Name',
            width: "200px",
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.email?.length > 20 ? row?.email?.substring(0, 20) + "..." : row?.email}</span>
                    </>
                );
            },
            name: 'Email',
            width: "200px",
        },
        {
            name: "Departments",
            width: "400px",
            selector: row => {
                let selectedDepartmentIds = row?.user_departments?.map(v => v?.department_id)
                let departmentSelected = departmentData?.filter(value => selectedDepartmentIds?.includes(value?.id))?.map((v, i) => {
                    return v?.name
                })
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <span className='textParagh '>{departmentSelected?.toString()?.length > 50 ? departmentSelected?.toString()?.substring(0, 50) + "..." : departmentSelected?.toString()}</span>
                        </div>
                    </>
                );
            },
        },
        {
            selector: row => {
                return (
                    <>
                        <div className='mobposUser'>
                            <span className='textParagh '>{row?.user_profiles?.full_phone_number}</span>

                        </div>
                    </>
                );
            },
            name: 'Mobile No.',
        },
        {
            selector: row => {
                return (
                    <>
                        <div className='d-flex justify-space-between align-items-center pe-3 ps-3'>
                            <div className='dropdown tableDropdown ms-3 '>
                                <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                                    <i className="fal fa-ellipsis-v icon_"></i>
                                </span>
                                <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                                    <li className='dropdown-item' onClick={() => {
                                        setSelectedRow(row)
                                        setModalDetail({ show: true, flag: "viewAgentDetails" });
                                        setKey(Math.random());
                                    }}>View Details</li>
                                    <li className='dropdown-item' onClick={() => {
                                        setSelectedRow(row)
                                        setModalDetail({ show: true, flag: "updateAgentDetails" });
                                        setKey(Math.random());
                                    }}>Edit</li>
                                    <li className='dropdown-item' onClick={() => handleDeletSupportAgent(row?.id)}>Delete</li>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
            name: '',
            width: '80px'
        },
    ]

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const handleDeletSupportAgent = (id) => {
        Swal.fire({
            title: 'Do you want to delete this agent?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    "postData": {
                        "id": id
                    },
                    "tokenData": auth?.payload?.token
                }
                setLoading(true)
                dispatch(deleteSupportAgentApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        getAllSupportAgents()
                        setLoading(false)
                    })
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
        })
    }

    const getAllSupportAgents = () => {
        let params = {
            postData: {
                page: currentPage,
                limit: perPage
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getAllSupportAgentsApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAgentList(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getAllDepartment = () => {
        dispatch(getDepartmentDataInSupportApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setDepartmentData(obj?.payload)
            }
            )
            .catch((obj) => {
            })
    }

    useEffect(() => {
        getAllDepartment()
        getAllSupportAgents()
    }, [perPage, currentPage])
    return (
        <>
            <div className='posUser_'>
                <div className='topHeader_'>
                    <div className="topStickyHeader_ mb-3">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <span>Support Agents</span>
                                </h2>
                            </div>
                            <div className="col-md-6 text-end">
                                <button className='btn btn-primary' onClick={() => {
                                    setModalDetail({ show: true, flag: "createSupportAgent" });
                                    setKey(Math.random());
                                }}>Create New Agent</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <section className='recentTransections postransaction table-responsive commonTableheight mt-1'> */}
                <section className='recentTransections table-responsive commonTableheight mt-1'>
                    <DataTable
                        columns={columns1}
                        data={agentList}
                        //   onRowClicked={handleRowClick}
                        //   customStyles={customStyles}        
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                </section>

            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "createSupportAgent" || modalDetail.flag === "updateAgentDetails"  ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "createSupportAgent" ? "CreateSupportAgent" : modalDetail.flag === "viewAgentDetails" ? "ViewAgentDetails" : ""}
                child={
                    modalDetail.flag === "createSupportAgent" ? (
                        <CreateAgentModal handleAllAgents={() => getAllSupportAgents()} close={() => handleOnCloseModal()} />
                    ) :
                        modalDetail.flag === "viewAgentDetails" ? (
                            <AgentDeatailsModal selectedRow={selectedRow} close={() => handleOnCloseModal()} />
                        ) :
                        modalDetail.flag === "updateAgentDetails" ? (
                            <UpdateAgentModal handleAllAgents={() => getAllSupportAgents()} selectedRow={selectedRow} close={() => handleOnCloseModal()} />
                        ) :
                            (
                                ""
                            )
                }
                header={
                    <>
                        <div className='modalHeader_'>
                            <div className="common_">
                                {
                                    modalDetail.flag === "createSupportAgent" ?
                                        <div className='anotherHeader'>
                                            <h2 className="modalHeading">Create New Agent</h2>
                                        </div>:
                                        modalDetail.flag === "updateAgentDetails" ?
                                        <div className='anotherHeader'>
                                            <h2 className="modalHeading">Update Agent</h2>
                                        </div>
                                        :
                                        <div className='anotherHeader'>
                                            <h2 className="modalHeading">Agent Details</h2>
                                        </div>
                                }
                                <button className="closeButton">
                                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                </button>

                            </div>
                        </div>
                    </>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </>

    )
}

export default SupportAgents