import React from 'react'

const InventoryaddnewModal = (props) => {
  return (
    <div>
      <div className='addingNewiventory_'>
        <h2 className='mainBoldHeading'>{props?.quantity}<span className='blacksemiHead_'>Box</span></h2>
        <p className='subheadingBlack_'>{props?.reason ? 'You are going to adjust Inventory':'You are going to add Inventory'}</p>
        <div className='inventButton mt-5'>
          <button onClick={() => props?.close()} className='declineBtn me-3'>Cancel</button>
          <button onClick={props?.reason ? () => props?.adjustInventory(props?.id, props?.quantity, props?.options, props?.reason, props?.date, props?.reference) : () => props?.updateInventory(props?.id, props?.quantity, props?.option)} className='acceptBtn_' disabled={props?.loading}>
            {props?.loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
            <span>{props?.reason ? 'Adjust now' :'Add now'}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default InventoryaddnewModal