import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../dashboard/DashboardNavbar'
import { useDispatch } from 'react-redux'
import { getPluginsListAsync } from '../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'

const PluginIntegrations = () => {
    const [loading, setLoading] = useState(false)
    const [pluginstData, setPluginsData] = useState()
    const dispatch = useDispatch()

    const handleDownload = (flag, url) => {
        if(flag == "Wordpress"){
            const fileUrl = url;
    
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = 'jobr-wordpress.zip'; // Specify the downloaded file name
        
            document.body.appendChild(link);
            link.click();
        
            document.body.removeChild(link);
        }
      };

    useEffect(() => {
        setLoading(true)
        dispatch(getPluginsListAsync())
            .then(unwrapResult)
            .then((obj) => {
                setPluginsData(obj?.payload)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }, [])
 
    return (
        <>
            {/* <div className='productPluginSection rightWrapper headerWrap'> */}
                <DashboardNavbar title="Plugins " flag="goBack" backShow={true} />
                <div className='fileUploadSection'>
                    <div className='pluginSub'>
                        <h2 className='pluginHeading'>Discover the Best Plugins for your business to add products easily!</h2>
                        <p className='offerpara_'>JOBR Developed apps for your every needs</p>

                        {
                            loading ?
                                <span className="spinner-border inner-spin spinner-border-sm"></span>
                                :
                                <>
                                {
                                    pluginstData?.length > 0 ?
                                    <div className='row'>
                                    {
                                        pluginstData?.map((val, index) =>{
                                            return(
                                                <div className='col-xxl-3 col-xl-6 col-lg-6 col-md-6' style={{cursor: 'pointer'}} key={index} onClick={() => handleDownload(val?.title, val?.url)}>
                                                    <div className='plugSubSection'>
                                                        <img src={val?.logo} alt='wordpress image' className='img-fluid pluginImg' />
                                                        <div className='plugHeadSection'>
                                                            <h4 className='walletHeading text-start'>{val?.title}</h4>
                                                            <p className='offerpara_'>{val?.discription}</p>
                                                            <h4 className='freeText mt-3'>{val?.subscription}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                    :
                                    <div>No Plugins Found</div>
                                }
                                </>

                        }
                    </div>
                </div>
            {/* </div> */}
        </>
    )
}

export default PluginIntegrations