import React from "react";
import Sidebar from "./Sidebar";

const DashboardLayout = (props) => {
  return (
    <main>
      <div className="wrapper">
        <Sidebar />
        <div className="rightdashboard_">
          <div className="rightWrapper">
            <div className="container-fluid pt-3 pb-3">
              <div
                className={
                  window.location.pathname === "/directMessage" ||
                  window.location.pathname === "/inquaries" ||
                  window.location.pathname === "/rfqMessage"
                    ? "content_areas message"
                    : "content_areas"
                }
              >
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashboardLayout;
