import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../dashboard/DashboardNavbar'
import { shareMap, profileImg1, locationBlue, modalCancel, lossArrow } from '../../../utilities/images'
import { selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import ChartArea from '../dashboard/wallet/ChartArea'
import { changeAppointmentStatusApiAsync, getAppointemntApiAsync, getAppointmentCountApiAsync, getAppointmentGraphApiAsync } from '../dashboard/dashboardSlice';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DataTable from 'react-data-table-component';
import moment from 'moment-timezone';
import CustomModal from '../../components/shared/CustomModal';
import BookingSchedule from './bookingSchedule';
import CheckinModal from './checkinModal';
import ConfirmCompleteModal from './ConfirmCompleteModal';

const Bookings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [appointmentCountData, setAppointmentCountData] = useState([]);
  const [appointmentGraphData, setAppointmentGraphData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [singleOrderData, setSingleOrderData] = useState('');
  const auth = useSelector(selectLoginAuth);
  const [filterValue, setFilterValue] = useState("week");
  const [checked, setChecked] = useState({
    appointment: true,
    completed: true
  })
  let [dataSets, setDataSets] = useState([])
  const [key, setKey] = useState(Math.random());
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        align: 'start',
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          pointStyle: 'circle',
          usePointStyle: true,
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }

  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  const handleRowClick = (e) => {
    // setSingleOrderData(e)
    // setModalDetail({ show: true, flag: "PendingInvoiceModal" });
    // setKey(Math.random());
  }

  const columns1 = [
    {
      name: 'Date/Time',
      selector: row => {
        return (
          <>
            <div onClick={() => handleRowClick(row)} className=' text-left'>
              <span className='textParagh'>{moment.utc(row?.date).format("MM/DD/YYYY | ")}<span className="">{row?.start_time + "-" + row?.end_time}</span></span>
            </div>
          </>
        );
      },
      width: "270px",
    },
    {
      name: "Customer",
      selector: row => {
        return (
          <>
            {
              row?.user_details ?
                <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                  <img className='userInnerImg' src={row?.user_details?.profile_photo ? row?.user_details?.profile_photo : profileImg1} />
                  <div className='ms-2'>
                    <span className='contentSubtext_ fw-semibold'>{row?.user_details?.firstname + " " + row?.user_details?.lastname}</span>
                    {
                      row?.user_details?.current_address ?
                    <p className='mb-0'>
                      <img src={locationBlue} className='img-fluid me-2 custImg' />
                      <span className='contentSmallTxt'>{row?.user_details?.current_address?.city},{row?.user_details?.current_address?.country}</span>
                    </p>:<></>}
                  </div>
                </div> : <div className='d-flex align-items-center'>Not Available</div>}
          </>
        );
      },
      width: "300px",
    },
    {
      name: "Assigned to",
      selector: row => {
        return (
          <>
            <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
              <img className='userInnerImg' src={row?.pos_user_details?.user?.user_profiles?.profile_photo ? row?.pos_user_details?.user?.user_profiles?.profile_photo : profileImg1} />
              <div className='ms-2'>
                <span className='contentSubtext_ fw-semibold'>{row?.pos_user_details?.user?.user_profiles?.firstname + " " + row?.pos_user_details?.user?.user_profiles?.lastname}</span>
                <p className='mb-0 text-start'>
                  {/* <img src={locationBlue} className='img-fluid me-2 custImg' /> */}
                  <span className='contentSmallTxt'>{row?.pos_user_details?.is_staff_member === true ? "Staff" : "Non-staff"}</span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "250px",
    },
    {
      selector: row => row?.product_name,
      name: 'Service',
      width: "200px",
    },
    {
      selector: row => row?.approx_service_time + " min",
      name: 'Duration',
      width: "150px",
    },
    {
      selector: row => '$' + addThousandSeparator(row?.price),
      name: 'Price',
      width: "150px",
    },
    {
      name: 'Action',
      selector: row => {
        return (
          <>
            <div className='ms-2 tableBtn__'>
              <div className='col-lg-12 '>
                {
                  row?.status === 0 ?
                    <div className='stoclRight'>
                      <button onClick={() => changeStatusOfAppointmentHandle(row?.id, 4)} className=' me-3 checkBtnColor' type="submit" disabled={loading1}>
                        {loading1 && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Decline</span>
                      </button>
                      <button onClick={() => changeStatusOfAppointmentHandle(row?.id, 1)} className='acceptBtnColor me-2' type="submit" disabled={loading2}>
                        {loading2 && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Approve</span>
                      </button>
                    </div> :
                    row?.status === 1 ?
                      <div className='stoclRight'>
                        <button onClick={() => { setSingleOrderData(row); setModalDetail({ show: true, flag: "checkInModal" }); setKey(Math.random()); }} className='me-3 checkInBtnColor' type="submit">
                          <span>Check-in</span>
                        </button>
                        <button onClick={() => { setSingleOrderData(row); setModalDetail({ show: true, flag: "rescheduleModal" }); setKey(Math.random()); }} className='acceptBtnColor me-2' type="submit">
                          <span>Update</span>
                        </button>
                      </div> :
                      row?.status === 2 ?
                        <div className='stoclRight'>
                          <button onClick={() => { setSingleOrderData(row); setModalDetail({ show: true, flag: "confirmCompleteModal" }); setKey(Math.random()); }} className=' me-2 w-100 checkInBtnColor' type="submit" disabled={loading2}>
                            <span>Mark Complete</span>
                          </button>
                        </div> :
                        row?.status === 3 ?
                          <div className='stoclRight '>
                            <button className='acceptBtnColor me-2 w-100' type="submit" disabled>
                              <span>Completed</span>
                            </button>
                          </div>
                          : <></>
                }
              </div>
            </div>
          </>
        );
      },
      width: "220px"
    }
  ];

  const lineData = {
    labels: appointmentGraphData?.labels,
    datasets: dataSets,
  };
  const handelDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: appointmentGraphData?.datasets
            ? appointmentGraphData?.datasets[num]?.data
            : "",
          borderColor: color,
          backgroundColor: color,
        },
      ]);
    } else {
      setDataSets(
        dataSets.filter((item) => item.label !== value))
    }
  }
  const changeStatusOfAppointmentHandle = (id, status) => {
    let orderStatus = {
      "postData": {
        id: id,
        status: status
      },
      "tokenData": auth?.payload?.token
    }
    status === 4 ? setLoading1(true) : setLoading2(true)
    dispatch(changeAppointmentStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          "postData": {
            // page: 1,
            // limit: 10,
            // need_upcoming: true,
            status: "0,1,2",
            filter_by: filterValue,
            seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
          },
          "tokenData": auth?.payload?.token
        }
        dispatch(getAppointemntApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setAppointmentData(obj?.payload?.data)
            setLoading1(false)
            setLoading2(false)
            handleOnCloseModal()
          }
          )
          .catch((obj) => {
            setLoading1(false)
            setLoading2(false)
          })
      }
      )
      .catch((obj) => {
        setLoading1(false)
        setLoading2(false)
      })
  }
  const appointmentCountHandle = () => {
    let params = {
      "postData": {
        filter_by: filterValue,
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      },
      "tokenData": auth?.payload?.token
    }
    setLoading(true)
    dispatch(getAppointmentCountApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentCountData(obj?.payload)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  const appointmentGraphHandle = () => {
    let params = {
      "postData": {
        filter_by: filterValue,
        cancelled_nd_completed: true,
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      },
      "tokenData": auth?.payload?.token,

    }
    setLoading(true)
    dispatch(getAppointmentGraphApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentGraphData(obj?.payload)
        setDataSets(
          [
            {
              fill: true,
              label: 'Appointments',
              data: obj?.payload?.datasets ? obj?.payload?.datasets[0]?.data : "",
              borderColor: '#275AFF',
              backgroundColor: '#275AFF',
              cubicInterpolationMode: 'monotone',
            },
            {
              fill: true,
              label: 'Completed',
              data: obj?.payload?.datasets ? obj?.payload?.datasets[1]?.data : "",
              borderColor: '#D33300',
              backgroundColor: '#D33300',
              cubicInterpolationMode: 'monotone',
            },
          ]
        )
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  const handleAppointmentData = () => {
    let params = {
      "postData": {
        // need_upcoming: 
        status: "0,1,2",
        filter_by: filterValue,
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      },
      "tokenData": auth?.payload?.token
    }
    setLoading(true)
    dispatch(getAppointemntApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentData(obj?.payload?.data)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    appointmentCountHandle()
    appointmentGraphHandle()
    handleAppointmentData()
  }, [filterValue])


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Bookings";
  }, []);
  return (
    <>
      <DashboardNavbar title="Bookings" show={false} />
      <div className='bookingsOverview_'>
        <div className='verificationTotal'>
          <div className='row mt-4'>
            <div className='col-lg-12 d-flex justify-content-end'>
              <ul className='weekNav'>
                <li onClick={() => setFilterValue("today")}><Link className={`weekMenu ${filterValue == "today" ? "active" : ""}`} to="#">Today</Link></li>
                <li onClick={() => setFilterValue("week")}><Link className={`weekMenu ${filterValue == "week" ? "active" : ""}`} to="#">Weekly</Link></li>
                <li onClick={() => setFilterValue("month")}><Link className={`weekMenu ${filterValue == "month" ? "active" : ""}`} to="#">Monthly</Link></li>
                <li onClick={() => setFilterValue("year")}><Link className={`weekMenu ${filterValue == "year" ? "active" : ""}`} to="#">Yearly</Link></li>
              </ul>
            </div>
          </div>
        </div>
        {
          loading ? <span className="spinner-border inner-spin spinner-border-sm"></span> :
            <>
              <div className='row mt-4'>
                {
                  appointmentCountData?.length > 0 ?
                    appointmentCountData?.map((v, i) => {
                      return (
                        <div onClick={v?.name === "Fulfilled" || v?.name === "Cancelled" ? () => history.push({ pathname: "/completed-bookings", state: v?.name === "Fulfilled" ? "3" : "4,5" }) : () => history.push(`/upcoming-bookings`)} key={i} className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                          <div className='percentageContainer justify-content-between align-items-center'>
                            <div className='percentageValues'>
                              <h3 className='mainHeadingsub_ '>{v?.value}{v?.name === 'Fulfilled' ? "%" : ""}</h3>
                              <p className='textinner_'>{v?.name}</p>
                            </div>
                            <div className={`percentageRating ${v?.percentageChange >= 0 ? '' : 'percentageBg'}`}>
                              <img style={{ width: "30px" }} src={v?.percentageChange >= 0 ? shareMap : lossArrow} className="" alt='ShareMap' />
                              <p className={v?.percentageChange >= 0 ? 'percentageRate' : 'minusPercentageRate'}>{v?.percentageChange?.toFixed(2)}%</p>
                            </div>
                          </div>
                        </div>
                      )
                    }) : <></>
                }
              </div>

              <div className='tradesValue mt-4 mb-4'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='chartsOuter'>
                      <h3 className="textInnerHead mt-3">Booking</h3>
                      {/* <div className='processStatus mt-3'>
                        <p className='statusbox_'><span className='booking_'></span> Bookings</p>
                        <p className='statusbox_'> <span className='Cancelled_'></span> Cancelled </p>
                      </div> */}
                      <div className='row mt-4 mb-4 align-items-center'>
                        <div className="graphChecked_ mt-3 mb-2 ">
                          <div className="graphBox_ me-3">
                            <input
                              type="checkbox"
                              id="custom-checkbox-selectAll2"
                              className="checkBox "
                              name="custom-checkbox-selectAll"
                              value="custom-checkbox-selectAll"
                              onChange={(e) => {
                                handelDataSetChange(e, "Appointments", 0, "#275AFF");
                                setChecked({ ...checked, appointment: checked.appointment === true ? false : true })
                              }}
                              checked={checked.appointment}
                            />
                            <label
                              htmlFor="custom-checkbox-selectAll2"
                              className="checkBoxTxt masterChecked"
                            >
                              Appointments
                            </label>
                          </div>
                          <div className="checkRed me-3">
                            <input
                              type="checkbox"
                              id="custom-checkbox-selectAll4"
                              className="checkBox "
                              name="custom-checkbox-selectAll"
                              value="custom-checkbox-selectAll"
                              checked={checked.completed}
                              onChange={(e) => {
                                handelDataSetChange(e, "Completed", 1, "#FB466C");
                                setChecked({ ...checked, completed: checked.completed === true ? false : true })
                              }}
                            />
                            <label
                              htmlFor="custom-checkbox-selectAll4"
                              className="checkBoxTxt masterChecked"
                            >
                              Completed
                            </label>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <ChartArea className='img-fluid' header="" options={options1} data={lineData} chartType="Line" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className='recentTransections mt-3 table-responsive chartsOuter'>
                <h3 className='textInnerHead mb-4'>Upcoming Bookings</h3>
                <DataTable
                  pagination
                  columns={columns1}
                  data={appointmentData}
                  defaultSortFieldId={1}
                  customStyles={customStyles}
                  // expandableRows
                  onRowClicked={handleRowClick}
                  // expandableRowsComponent={ExpandedComponent("")}
                  className="newOrderTable"
                />
              </section>
            </>}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "rescheduleModal" ? "RescheduleModal" : modalDetail.flag === "confirmCompleteModal" ? "ConfirmCompleteModal" : modalDetail.flag === "checkInModal" ? "CheckInModal" : ""}
        className={modalDetail.flag === "rescheduleModal" ? "commonWidth customContent" : ""}
        child={
          modalDetail.flag === "rescheduleModal" ? (
            <BookingSchedule handleAllAppointmentData={() => handleAppointmentData()} changeStatusOfAppointment={(id, status) => changeStatusOfAppointmentHandle(id, status)} data={singleOrderData} loading1={loading1} loading2={loading2} close={() => handleOnCloseModal()} />
          ) :
            modalDetail.flag === "checkInModal" ? (
              <CheckinModal changeStatusOfAppointment={(id, status) => changeStatusOfAppointmentHandle(id, status)} data={singleOrderData} loading2={loading2} close={() => handleOnCloseModal()} />
            ) :
              modalDetail.flag === "confirmCompleteModal" ? (
                <ConfirmCompleteModal changeStatusOfAppointment={(id, status) => changeStatusOfAppointmentHandle(id, status)} data={singleOrderData} loading2={loading2} close={() => handleOnCloseModal()} />
              ) :
                (
                  ""
                )
        }
        header=
        {modalDetail.flag === "rescheduleModal" ?
          <>
            <p onClick={handleOnCloseModal} className='modal_cancel'>
              <img style={{ cursor: "pointer" }} src={modalCancel} className='ModalCancel' />
            </p>
            <h4 className='modalHeading_'>Booking  #<span>{singleOrderData?.id}</span></h4>
          </>
          :
          modalDetail.flag === "checkInModal" || modalDetail.flag === "confirmCompleteModal" ?
            <>
              <p onClick={handleOnCloseModal} className='modal_cancel'>
                <img style={{ cursor: "pointer" }} src={modalCancel} className='ModalCancel' />
              </p>
            </>
            :
            ''
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default Bookings