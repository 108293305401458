import React, { useEffect, useState } from 'react'
import { viewEye_, calendar, totalOrder, analyseInventory, analysis, grossProfit } from '../../../utilities/images';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import products from '../../db/category.json'
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { totalAnalyticsInventoryApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';

const TotalInventory = () => {
    const dispatch = useDispatch();
    const today = new Date();
    const auth = useSelector(selectLoginAuth);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [totalOrderAnalyticsData, setTotalOrderAnalyticsData] = useState("")
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    // const handleRowClick = (e) => {
    //     // console.log("rowclicked", e)
    //     // history.push({ pathname: `/salesReport/${e?.flag}`, state: JSON.stringify(e) })
    // }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    function addThousandSeparator(number) {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const columns1 = [
        {
            name: 'Product Name',
            selector: row => row?.name ? `${row?.name?.length > 20 ? `${row?.name?.slice(0, 20)}...` : row?.name}` : ""
        },
        {
            selector: row => row.category?.name?.length > 20 ? row.category?.name?.slice(0, 20) + "..." : row.category?.name,
            name: 'Category',
        },
        {
            selector: row => row.upc,
            name: 'UPC',
        },
        {
            selector: row => `$${row?.supplies[0]?.rest_quantity ? addThousandSeparator((row?.supplies[0]?.rest_quantity * row?.supplies[0]?.cost_price)?.toFixed(2)) : "0"}`,
            name: 'Price',
        },
        {
            selector: row => row?.supplies[0]?.rest_quantity,
            name: 'In Stock',
        },
        {
            selector: row => moment(row.last_sold_date).format('MM/DD/YYYY'),
            name: 'Last Sold Date',
        }
    ];

    const totalInventoryAnalyticsHandle = () => {
        let params = {
            "postData": {
                filter: filterValue,
                channel: channelFilter,
                page: currentPage,
                limit: perPage,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(totalAnalyticsInventoryApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setTotalOrderAnalyticsData(obj?.payload)
                setTotalRows(obj?.payload?.inventory_list?.total)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        totalInventoryAnalyticsHandle()
    }, [filterValue, channelFilter, endDate, currentPage])
    return (
        <>
            <DashboardNavbar title="Total Inventory" backShow="/analytics" />
            <div className='analyticInventory'>
                <div className='row mt-4'>
                    <div className='col-lg-5'>
                        <div className='dateChannel'>
                            <div className="calenderIcon">
                                <img src={calendar} alt="calender" />
                                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                <DatePicker
                                    className='cateDate'
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={today}
                                    selectsRange
                                    placeholderText="Date"
                                />
                            </div>
                            <div className=" form-group ">
                                <div className=" form-group ">
                                    <select autoComplete="new-password" className='customform-control  selectControl form-select' value={channelFilter} onChange={(e) => setChannelFilter(e.target.value)}>
                                        <option value="all" >All Channels</option>
                                        <option value="b2c">B2C</option>
                                        <option value="b2b">B2B</option>
                                        <option value="pos">Pos</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 d-flex justify-content-end'>
                        <ul className='weekNav'>
                            <li onClick={() => setFilterValue("today")}><Link className={`weekMenu ${filterValue == "today" ? "active" : ""}`} to="#">Today</Link></li>
                            <li onClick={() => setFilterValue("week")}><Link className={`weekMenu ${filterValue == "week" ? "active" : ""}`} to="#">Weekly</Link></li>
                            <li onClick={() => setFilterValue("month")}><Link className={`weekMenu ${filterValue == "month" ? "active" : ""}`} to="#">Monthly</Link></li>
                            <li onClick={() => setFilterValue("year")}><Link className={`weekMenu ${filterValue == "year" ? "active" : ""}`} to="#">Yearly</Link></li>
                        </ul>
                    </div>
                </div>
                {loading ?
                    <span className="spinner-border inner-spin spinner-border-sm"></span> :
                    <div className='grossProfitData'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6  pe-2'>
                                <div className='grossTotalorder'>
                                    <img src={totalOrder} className='img-fluid grossImages' alt='total order' />
                                    <p className='groSub mt-4 mb-0'>Total Inventory</p>
                                    <h4 className='groMain'>{totalOrderAnalyticsData?.inventory_overview?.total_inventory}</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                                <div className='grossTotalorder'>
                                    <img src={analyseInventory} className='img-fluid grossImages' alt='anlyseinventory' />
                                    <p className='groSub mt-4 mb-0'>Total Inventory Value</p>
                                    <h4 className='groMain'>{totalOrderAnalyticsData?.inventory_overview?.total_inventory_cost ? `$${addThousandSeparator((totalOrderAnalyticsData?.inventory_overview?.total_inventory_cost)?.toFixed(2))}` : "$0.00"}</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                                <div className='grossTotalorder'>
                                    <img src={analysis} className='img-fluid grossImages' alt='analysis' />
                                    <p className='groSub mt-4 mb-0'>Average Order Value</p>
                                    <h4 className='groMain'>{totalOrderAnalyticsData?.inventory_overview?.average_value ? `$${addThousandSeparator((totalOrderAnalyticsData?.inventory_overview?.average_value)?.toFixed(2))}` : "$0.00"}</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 ps-2 '>
                                <div className='grossTotalorder'>
                                    <img src={grossProfit} className='img-fluid grossImages' alt='grossProfit' />
                                    <p className='groSub mt-4 mb-0'>Gross profit</p>
                                    <h4 className='groMain'>{totalOrderAnalyticsData?.inventory_overview?.total_profit ? `$${addThousandSeparator((totalOrderAnalyticsData?.inventory_overview?.total_profit)?.toFixed(2))}` : "$0.00"}</h4>
                                </div>
                            </div>
                        </div>
                    </div>}
                <DataTable
                    columns={columns1}
                    data={totalOrderAnalyticsData?.inventory_list?.data}
                    defaultSortFieldId={1}
                    className='analytics_'
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                />
            </div>
        </>
    )
}

export default TotalInventory