import React, { useState } from 'react'
import moment from 'moment-timezone'
import { calendar, suplier, manage, fedeximg } from '../../../../utilities/images'
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import products from "../../../db/category.json";

const DriverDeliveries = (props) => {
  const [enterprisesDate, setEnterprisesDate] = useState("")
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const columns1 = [
    {
      name: '#',
      selector: row => row?.id,
      width: "70px",
    },
    {
      name: 'Date',
      selector: row => {
        return (
          <>
            <div className='ms-2'>
              <span className='contentSubtext_'>{moment(row?.delivered_at).format("DD MMM,YYYY")}</span>
              <p className='tableHeadsmall'>{moment(row?.delivered_at).format("h:mm a")}</p>
            </div>
          </>
        );
      },
      width: "120px",
    },
    {
      selector: row => row?.order?.invoice?.invoice_id,
      name: 'Invoice Id',
    },
    {
      name: "Location",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <div className='ms-2'>
                <span className='contentSubtext_ '>{row?.order?.city + "," + row?.order?.state}
                </span>
                <p className='mb-0 '>
                  <span className='tableHeadsmall'>{row?.order?.country}</span>
                </p>
              </div>
            </div>
          </>
        );
      },
    },

    {
      name: 'Tracker',
      selector: row => {
        return (
          <>
            {
              row.track ?
                <div className='ms-2'>
                  <span className='contentSubtext_'>{row.track}</span>
                  <img className='tarckIconn_' src={manage} />
                </div> : ""
            }
          </>
        );
      },
    },


    {
      name: 'Status',
      selector: row => {
        return (
          <>
            <div className='ms-2 tableBtn__'>
              <Link to='#' className='completeStatus'>Delivered</Link>
            </div>
          </>
        );
      },
      width: "150px"

    },
    {
      name: '',
      selector: row => {
        return (
          <>
            <div className='ms-2 tableBtn__'>

              {/* <i className="fal fa-chevron-right ms-3"></i> */}
            </div>
          </>
        );
      },

      width: "50px"
    },
  ];

  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
  }
  return (
    <div className='driverDeliveries mt-4'>
      <div className='tableFilters mb-4'>
        <div className='row'>
          <div className='col-md-12'>
            <div className="calenderIcon">
              <img src={calendar} alt="calender" />
              {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
              <DatePicker
                className='cateDate'
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Date"
              />
            </div>
          </div>
          {/* <div className='col-md-3'>
            <select className="cateSelectdFilter" required>
              <option value="" selected>
                Country
              </option>
              <option>Category1</option>
              <option>Category2</option>
            </select>
          </div>
          <div className='col-md-3'>
            <select className="cateSelectdFilter" required>
              <option value="" selected>
                State
              </option>
              <option>Category1</option>
              <option>Category2</option>
            </select>
          </div>
          <div className='col-md-3'>
            <select className="cateSelectdFilter" required>
              <option value="" selected>
                Id type
              </option>
              <option>Category1</option>
              <option>Category2</option>
            </select>
          </div> */}
        </div>
      </div>
      {
        props?.loading ?
          <span className="spinner-border inner-spin spinner-border-sm"></span>
          :
          <DataTable
            pagination
            columns={columns1}
            data={props?.data}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
          />}
    </div>
  )
}

export default DriverDeliveries