import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../auth/authSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import DataTable from 'react-data-table-component'
import { profileImg1 } from '../../../utilities/images'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { staffPayApiAsync, staffPaymentApiAsync } from '../dashboard/dashboardSlice'
import DashboardNavbar from '../dashboard/DashboardNavbar'




const StaffPayment = () => {
    const [loading, setLoading] = useState(false)
    const history = useHistory();
    const toastId = React.useRef(null);
    const [payLoading, setPayLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [staffPaymentData, setStaffPaymentData] = useState("")
    const [selectedRows, setSelectedRows] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)

    const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
    }

    // let dummyData = [
    //     {
    //         id: 1,
    //         full_name: "Rakesh Kakkar",
    //         date: "May 29, 2023 - Jun 4, 2023",
    //         duration: "44 h 20 m",
    //         amount: "70500"
    //     },
    //     {
    //         id: 2,
    //         full_name: "Anil Kumar",
    //         date: "May 1, 2023 - Jun 7, 2023",
    //         duration: "44 h 20 m",
    //         amount: "70500"
    //     }
    // ]

    function addThousandSeparator(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const columns1 = [
        {
            name: '#',
            selector: row => row.user_id,
            width: "70px"
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{`${row?.user?.user_profiles?.firstname} ${row?.user?.user_profiles?.lastname}`}</span>
                    </>
                );
            },
            name: 'Fullname',
            // width: "200px",
        },
        {
            name: "Date",
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{`${row?.startDate} - ${row?.endDate}`}</span>
                    </>
                );
            },
            name: 'Date',
            // width: "220px",
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.duration}</span>
                    </>
                );
            },
            name: 'Duration',
            // width: "200px",
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{`JBR ${addThousandSeparator(row?.amount)}`}</span>
                    </>
                );
            },
            name: 'Amount',
        }
    ]

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const handlePaymentUser = () => {
        if (selectedRows?.length > 0) {
            const productIds = selectedRows.map(item => item.id);
            Swal.fire({
                title: 'Do you want to proceed to pay?',
                showCancelButton: true,
                confirmButtonText: 'Pay',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const posIds = selectedRows.map((item) => {
                        return ({
                            "start_date": item?.startDate,
                            "end_date": item?.endDate,
                            "amount": (item?.amount).toString(),
                            "user_id": (item?.user_id).toString()
                        })
                    });
                    let params = {
                        postData: {
                            payment_details: posIds
                        },
                        tokenData: auth?.payload?.token
                    }
                    setPayLoading(true)
                    dispatch(staffPayApiAsync(params))
                        .then(unwrapResult)
                        .then((obj) => {
                            let params = {
                                postData: {
                                    page: 1,
                                    limit: perPage
                                },
                                tokenData: auth?.payload?.token
                            }
                            dispatch(staffPaymentApiAsync(params))
                                .then(unwrapResult)
                                .then((obj) => {
                                    setStaffPaymentData(obj?.payload?.data)
                                    setTotalRows(obj?.payload?.total)
                                    setPayLoading(false)
                                })
                                .catch((obj) => {
                                    setPayLoading(false)
                                })
                            toast.success(obj?.msg)
                        })
                        .catch((obj) => {
                            setPayLoading(false)
                        })
                }
            })
        }
        else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select user to pay")
            }
        }
    }

    useEffect(() => {
        let params = {
            postData: {
                page: currentPage,
                limit: perPage
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(staffPaymentApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                console.log(obj, "staff pay list")
                setStaffPaymentData(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }, [perPage, currentPage])
    return (
        <>
            <DashboardNavbar title="Staff Payments" />
            <div className='posUser_'>
                {/* <div className='topHeader_'>
                    <div className="topStickyHeader_ mb-3">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <span>Staff Payment</span>
                                </h2>
                            </div>
                       
                        </div>
                    </div>
                </div> */}

                <div className="col-md-12 text-end">
                    <button className='btn btn-primary' type="button" disabled={payLoading} onClick={() => handlePaymentUser()}>
                        {payLoading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Pay</span>
                    </button>
                </div>

                {/* <section className='recentTransections postransaction table-responsive commonTableheight mt-1'> */}
                <section className='recentTransections table-responsive staffpayment_ commonTableheight mt-1'>
                    <DataTable
                        columns={columns1}
                        data={staffPaymentData}
                        //   onRowClicked={handleRowClick}
                        //   customStyles={customStyles}        
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        paginationPerPage={perPage}
                        onChangePage={handlePageChange}
                        selectableRows
                        onSelectedRowsChange={handleRowSelected} // Callback for row selection
                        onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                </section>
            </div>
        </>
    )
}

export default StaffPayment