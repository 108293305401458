import { useSelector } from "react-redux";
import io from "socket.io-client";
import { selectLoginAuth } from "../../containers/auth/authSlice";
let socket;

export const initializeSocket = (id) => {
  if (!socket) {
    socket = io(`https://apichat.jobr.com:8007?userId=${id}`, {
      path: "/api/v1/connect",
    });
    // Optionally handle socket events or authentication here
  }
  return socket;
};

export const getSocket = () => {
  if (!socket) {
    const socket = initializeSocket();
    return socket;
  }
  return socket;
};

export const closeSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};