import React, { useState } from 'react'
import moment from 'moment-timezone';

import { locationBlue, profileImg1, clockimg_ } from '../../../utilities/images';

const BookingrequestModal = (props) => {
    const [loadingId, setLoadingId] = useState("")
    let newBookingData = props?.newBookingData;

    return (
        <div className='bookingrequestModal_'>
            <h2 className='modalHeading_'>Request ({newBookingData?.length ? newBookingData?.length : 0})</h2>
            {
                newBookingData?.length > 0 ?
                    newBookingData?.map((data, i) => {
                        return (
                            <div key={i} className="checkModalDetails requestbgColor">
                                <div className="Innerbooking mb-2">

                                    <div className="customerBookProfile mb-4">
                                        <h5 className='smallHeading'>Customer:</h5>
                                        {
                                            data?.user_details ?
                                                <div className=' customerMain_ d-flex align-items-center'>
                                                    <img className='userInnerImg' src={data?.user_details?.profile_photo ? data?.user_details?.profile_photo : profileImg1} />
                                                    <div className='ms-2'>
                                                        <span className='haedingsemiBold_ '>{data?.user_details?.firstname + " " + data?.user_details?.lastname}</span>
                                                        {data?.user_details?.current_address &&
                                                            <p className='mb-0'>
                                                                <img src={locationBlue} className='img-fluid me-2 custImg' />
                                                                <span className='contentSmallTxt'>{data?.user_details?.current_address?.city},{data?.user_details?.current_address?.country}</span>
                                                            </p>}
                                                    </div>
                                                </div> : <>Not Available</>}
                                    </div>
                                </div>
                                <div className='innerservice_'>
                                    <div className="appointInner mb-4">
                                        <div className="contentappont">
                                            <h5 className='smallHeading mb-2'>Service Requested:</h5>
                                            <span>{data?.product_name}</span>
                                        </div>
                                    </div>
                                    <div className="appointInner upcommingAnother mb-4">
                                        <div className="contentappont">
                                            <h5 className='smallHeading mb-2'>Service Time:</h5>
                                            <div className='serviceTime_'>
                                                <span><img className='img-fluid clockimg_ me-2' alt="clock" src={clockimg_} />{moment.utc(data?.date).format("dddd ")}  </span> | <span> {moment.utc(data?.date).format("MMMM DD, YYYY")} </span> | <span>{data?.start_time + "-" + data?.end_time}</span></div>
                                        </div>
                                    </div>
                                    <div className="appointInner mb-4">
                                        <div className="contentappont">
                                            <span className='smallHeading'>Duration : </span>
                                            <strong className='timeText'>{data?.approx_service_time + " min"}</strong>
                                        </div>
                                    </div>
                                    <div className="appointInner mb-4">
                                        <div className="contentappont">
                                            <h5 className='smallHeading mb-2'>Service Charge:</h5>
                                            <div className='totalvalue_'>
                                                <strong className='timeText'>Total</strong>
                                                <strong className='timeText'>${data?.price}</strong>
                                            </div>
                                            <button className='paidbtn_' type='submit'>Paid</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='modalbottonFooter_'>
                                    <button onClick={() => { props?.changeStatus(data?.id, 4); setLoadingId(data?.id) }} className='modalcloseBtn_' type="submit" disabled={props?.loading1}>
                                        {props?.loading1 && loadingId === data?.id && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        <span>Decline</span>
                                    </button>
                                    <button onClick={() => { props?.changeStatus(data?.id, 1); setLoadingId(data?.id) }} className='modalsaveBtn_' type="submit" disabled={props?.loading2}>
                                        {props?.loading2 && loadingId === data?.id && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        <span>Approve</span>
                                    </button>
                                </div>
                            </div>
                        )
                    }) : <div>No New Booking Found</div>
            }
        </div>
    )
}

export default BookingrequestModal