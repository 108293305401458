import React, { useState } from 'react'
import Templates from './templates'
import { templateImage, imgGallery, templateProfile, templateLogo, pinLocate, modalCancel } from '../../../utilities/images'
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import { getUserByIdApiAsync, selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom'
import { FileUploader } from "react-drag-drop-files";
import CustomModal from '../../components/shared/CustomModal';
import PaymentTemplateModal from './paymentTemplateModal';
const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];


const NewTemplatesModal = () => {
    const auth = useSelector(selectLoginAuth)
    const date = moment.utc(auth?.payload?.user?.user_profiles?.dob);
    const newDate = date.toDate()
    const [dob, setDob] = useState(auth?.payload?.user?.user_profiles?.dob ? newDate : "")
    const generateRandomName = () => {
        return Math.random().toString(36).substr(2, 10);
    };
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    //closeModal
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleUserProfile = (flag) => {

        setModalDetail({
            show: true,
            flag: flag,
            type: flag,
        });
        setKey(Math.random());
    };
    return (
        <>
            <div className='newTemplatesModal'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='templateLeft'>
                            <form className='tempForm row'>
                                <div className='templateSubForm'>
                                    <div className="mb-3 form-group col-md-12">
                                        <label className="deatilsHead mb-2">Template Name</label>
                                        <input
                                            type="text"
                                            className="customform-control"
                                            name="text"
                                            autoComplete="new-password"
                                            placeholder="Name of your Organization"
                                            value="New Templates 1"
                                        />
                                        <hr className='templateDivide' />
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="mb-4 form-group col-md-6">
                                            <label className="deatilsHead mb-2">Campaign Type</label>
                                            <select autoComplete="new-password" className='customform-control select' >
                                                <option value="operations" disabled>Feature Shop</option>
                                                <option value="0">Physical</option>
                                                <option value="1">Online</option>
                                                <option value="2">Both</option>
                                            </select>
                                        </div>
                                        <div className="mb-4 form-group col-md-6">
                                            <label className="deatilsHead mb-2">Placement</label>
                                            <select autoComplete="new-password" className='customform-control select' >
                                                <option value="operations" disabled>Main Home Page</option>
                                                <option value="0">Physical</option>
                                                <option value="1">Online</option>
                                                <option value="2">Both</option>
                                            </select>
                                        </div>
                                        <hr className='templateDivide' />
                                    </div>
                                    <div className='row mt-3'>
                                        <div className="mb-4 form-group col-md-6">
                                            <label className="deatilsHead mb-2">Campaign Type</label>
                                            <select autoComplete="new-password" className='customform-control select' >
                                                <option value="operations" disabled>Feature Shop</option>
                                                <option value="0">Physical</option>
                                                <option value="1">Online</option>
                                                <option value="2">Both</option>
                                            </select>
                                        </div>
                                        <div className="mb-4 form-group col-md-6">
                                            <label className="deatilsHead mb-2">Placement</label>
                                            <select autoComplete="new-password" className='customform-control select' >
                                                <option value="operations" disabled>Main Home Page</option>
                                                <option value="0">Physical</option>
                                                <option value="1">Online</option>
                                                <option value="2">Both</option>
                                            </select>
                                        </div>
                                        <hr className='templateDivide' />
                                    </div>
                                    <div className='row'>
                                        <div className="mb-4 form-group col-md-6">
                                            <label className="deatilsHead mb-2">
                                                Publish Scheduling </label>
                                            <DatePicker
                                                selected={dob}
                                                name={generateRandomName}
                                                className="birth customform-control mb-0"
                                                autoComplete='new-password'
                                                onChange={(date) => setDob(date)}
                                                dateFormat="MM/dd/yyyy"
                                                maxDate={moment().subtract(18, 'years')._d}
                                                placeholderText="MM/DD/YYYY"
                                            />
                                        </div>
                                        <div className="mb-4 form-group col-md-6">
                                            <label className="deatilsHead mb-2">Placement</label>
                                            <select autoComplete="new-password" className='customform-control select' >
                                                <option value="operations" disabled>Main Home Page</option>
                                                <option value="0">Physical</option>
                                                <option value="1">Online</option>
                                                <option value="2">Both</option>
                                            </select>
                                        </div>
                                        <hr className='templateDivide' />
                                        <div className='col-md-12 form-group '>
                                            <h4 className='placementText '>Upload Image</h4>
                                            <div className='mainCatalog ps-0 pe-0'>
                                                <div id='dropzone'>
                                                    <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" />
                                                    <label htmlFor="file">
                                                        <span className="file-button uploadImages_">
                                                            {/* <img src={imgGallery1} className="img-fluid editProfileImg" alt="user img" /> */}
                                                            <div className='dropText ms-3'>
                                                                <span className='textinner_'>Drop your files here or</span>
                                                                <Link to='#' className='linkTxt ms-1'>Browse</Link>
                                                            </div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=' col-md-12'>
                                            <img src={templateImage} alt="template Image" className='img-fluid templateImage' />
                                        </div>
                                        <div className="mb-4 form-group col-md-12 mt-3">
                                            <label className="deatilsHead mb-2">Select Button</label>
                                            <select autoComplete="new-password" className='customform-control select' >
                                                <option value="operations" disabled>Store</option>
                                                <option value="0">Physical</option>
                                                <option value="1">Online</option>
                                                <option value="2">Both</option>
                                            </select>
                                        </div>
                                        <hr className='templateDivide' />
                                    </div>
                                </div>
                                <div className=' modalfooterBtn'>
                                    <button className='me-4 w-100 drafBtn' >Save as Draft</button>
                                    <button className='w-100 btnSave' type="submit" >
                                        Next
                                    </button>
                                </div>
                                {/* <div className='form-group col-md-4'>
                                    <label className='deatilsHead mb-2 fw-normal'>Template Name</label>
                                    <select className="customform-control select">
                                        <option value="0" selected>Category ...</option>
                                        <option value="1">Tobacco</option>
                                        <option value="2">Dab</option>
                                        <option value="10">Vegetables and fruit</option>
                                        <option value="11">Juice and plant drinks</option>
                                    </select>
                                </div>
                                <div className='form-group col-md-4'>
                                    <label className='deatilsHead mb-2 fw-normal'>Category</label>
                                    <select className="customform-control select">
                                        <option value="0" selected>Category ...</option>
                                        <option value="1">Tobacco</option>
                                        <option value="2">Dab</option>
                                        <option value="10">Vegetables and fruit</option>
                                        <option value="11">Juice and plant drinks</option>
                                    </select>
                                </div>
                                <div className='form-group col-md-4'>
                                    <label className='deatilsHead mb-2 fw-normal'>Language</label>
                                    <select className="customform-control select">
                                        <option value="0" selected>Language ..</option>
                                        <option value="1">Tobacco</option>
                                        <option value="2">Dab</option>
                                        <option value="10">Vegetables and fruit</option>
                                        <option value="11">Juice and plant drinks</option>
                                    </select>
                                </div> */}
                            </form>
                            {/* <div className='headerTypes'>
                                <h3 className="labelTxt"><b>Header</b> <i>(Optional)</i> </h3>
                                <p className="textinner_ mt-3 mb-2 fw-500">Add a title or choose which type of media you'll use for this header.</p>
                                <p className="textinner_ mb-4 fw-500">Your title can't include more than one variable.</p>
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <select className="customform-control select">
                                            <option value="0" selected>Text</option>
                                            <option value="1">Tobacco</option>
                                            <option value="2">Dab</option>
                                            <option value="10">Vegetables and fruit</option>
                                            <option value="11">Juice and plant drinks</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 mt-5 d-none'>
                                        <textarea className="customform-control" id="" rows="2" value='' placeholder='Write here' />
                                    </div>
                                    <div className='mt-4'>
                                        <div className='col-md-12'>
                                            <textarea className="customform-control" id="" rows="1" value='' placeholder='Write here' />
                                        </div>
                                        <h3 className='labelTxt mt-4 mb-3'><b>Or</b></h3>
                                        <div className='col-md-8'>
                                            <div className="inputfile-box active">
                                                <input type="file" id="file" className="form-control inputfile d-none" name="images[]" data-id="1" accept="image/png, image/jpeg" multiple />
                                                <label htmlFor="file">
                                                    <span id="file-name" className="file-box d-none">Choose File</span>
                                                    <div className="file-button d-flex ">
                                                        <img src={imgGallery} width="25" id="profileImagePreview1" className="beforeUpload" />
                                                        <p className='subtextSmall_'>Drop your files here <span className='linkTxt'>Brwose</span></p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className='bodies'>
                                <h3 className="labelTxt"><b>Body</b></h3>
                                <p className="textinner_ mt-3 mb-2 fw-500">Add a title or choose which type of media you'll use for this header.</p>
                                <p className="linkTxt">Example: {`{{shop_name}}`}</p>
                                <textarea className="customform-control mt-5" id="" style={{ fontStyle: "italic" }} rows="5" value="Hi {`{{name}}`}
                              Welcome to Demo! Click below button to download app and get JBR 15 coin as bonus" placeholder='' />
                            </div>
                            <hr />
                            <div className='footers'>
                                <h3 className="labelTxt"><b>Footer</b> <i>(Optional)</i> </h3>
                                <p className="textinner_ mt-3 mb-2 fw-500">Add a short line of text to the bottom of your message template.</p>
                                <div className='row'>
                                    <div className='col-md-12 mt-4 '>
                                        <textarea className="customform-control" id="" rows="2" value='' placeholder='Write here' />
                                    </div>
                                </div>
                            </div>
                            <div className='footers'>
                                <h3 className="labelTxt"><b>Footer</b> <i>(Optional)</i> </h3>
                                <p className="textinner_ mt-3 mb-2 fw-500">Add a short line of text to the bottom of your message template.</p>
                                <div className='row'>
                                    <div className='col-md-12 mt-4 '>
                                        <textarea className="customform-control" id="" rows="2" value='' placeholder='Write here' />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='templateRight'>
                            <h5 className='placementText'>Preview</h5>
                            <div className='templatePreview'>
                                <img src={templateProfile} alt="template Profile Image" className='img-fluid templateProfileImg' />
                                <div className='templateInfo'>
                                    <img src={templateLogo} alt="template Logo Image" className='img-fluid templateLogo' />
                                    <div className='templateProfile'>
                                        <h4 className='profileText'>ZARA</h4>
                                        <div className='dFlex'>
                                            <div className='tempLocate'>
                                                <img src={pinLocate} alt="location image" className='img-fluid' />
                                                <h6 className='locateSub m-0'>1.3 mile</h6>
                                            </div>
                                            <div className='tempLocate ms-3'>
                                                <span className='dotCircle'></span>
                                                <h6 className='locateSub m-0'>Miami, Florida</h6>
                                            </div>
                                        </div>
                                        <div className='tempLable'>Ad</div>
                                    </div>
                                    <button className='storeBtn' onClick={() => {
                                        handleUserProfile("paymentTemplate")
                                    }}>Store</button>
                                </div>
                            </div>

                            {/* <div className='msgArea mt-2'>
                                Hi Friend 👋🏻 <br />
                                Welcome to WATI Demo!
                                Click below button to send your first message package
                            </div>
                            <button className='plainBtn mt-2'>Button Text</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={modalDetail.flag === "paymentTemplate" ? "paymenttTemplateModal" : ""}
                size={modalDetail.flag === "paymentTemplate" ? "xl" : "xl"}
                child={
                    modalDetail.flag === "paymentTemplate" ? (
                        <PaymentTemplateModal
                            close={() => handleOnCloseModal()}
                        />
                    ) :
                        ""
                }
                header=

                {modalDetail.flag === "paymentTemplate" ?
                    <>
                        <h4 className='modalHeading_'>New Template</h4>
                        <p onClick={handleOnCloseModal} className='modal_cancel'>
                            <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                        </p>
                    </>
                    :
                    ''
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default NewTemplatesModal