import React, { useEffect, useState } from 'react'

var changingSellingPrice = false;
var changingMargin = false;
const FixedPrice = (props) => {
    const costPrices = props?.costPrice ? props?.costPrice : "";
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const [priceJobrBtobFixed, setPriseJobrBtobFixed] = useState({
        "app_name": "",
        "price_type": "",
        "selling_price": "",
        "margin_percentage": ""
    });

    const handleInputChangefixedBtob = (e) => {
        const { name, value } = e.target;
        let data = { ...priceJobrBtobFixed, [name]: value, app_name: "b2b", price_type: "fixed" }
        // if(costPrices !== undefined && costPrices !== 0 && costPrices ){
        //     const margin = ((data?.selling_price - costPrices) / costPrices) * 100;
        //     if (data?.selling_price) {
        //         data = { ...data, margin_percentage: margin };
        //     }
        //     else{
        //         data = { ...data, margin_percentage: "" };
        //     }
        // }
        if(name == 'selling_price'){
            changingSellingPrice = true;
        }

        if(name == 'margin_percentage'){
            changingMargin = true;
        }
        setPriseJobrBtobFixed(data);
        props.fixedPriceBtob(data);
    };

    useEffect(() => {
        // Recalculate margin_percentage when costPrices changes
        if (costPrices !== undefined && costPrices !== 0 && priceJobrBtobFixed?.selling_price && changingSellingPrice) {
            changingSellingPrice = false;
            const btobFixedMargin = ((priceJobrBtobFixed?.selling_price - costPrices) / costPrices) * 100;
            const newPriceBtob = {
                ...priceJobrBtobFixed,
                margin_percentage: priceJobrBtobFixed?.selling_price ? btobFixedMargin.toFixed(2) : ""
            };
            props.fixedPriceBtob(newPriceBtob);
            setPriseJobrBtobFixed(newPriceBtob)
            // checkAndAdd(newPriceBtoc);
        }
    }, [costPrices, priceJobrBtobFixed?.selling_price]);

    useEffect(() => {
        if (costPrices !== undefined && costPrices !== 0 && priceJobrBtobFixed?.margin_percentage && changingMargin) {
            changingMargin = false
            const sellingPrice = parseFloat(costPrices) + (parseFloat(costPrices) * (parseFloat(priceJobrBtobFixed?.margin_percentage) / 100));
            const newPriceBtob = {
                ...priceJobrBtobFixed,
                selling_price: sellingPrice ? sellingPrice.toFixed(2) : "",
            };
            setPriseJobrBtobFixed(newPriceBtob);
            props.fixedPriceBtob(newPriceBtob);
            // checkAndAdd(newPriceBtob);
        }

    }, [costPrices, priceJobrBtobFixed?.margin_percentage]);

    return (
        <>
            {props.activeBtob ?
                <div className='fixedPrice outerArea'>
                    <form className='inventory row '>
                        <div className='form-group mb-3 col-md-6'>
                            <label className="deatilsHead mb-2 fw-normal">Selling Price  for Customer
                            </label>
                            <div className='inputGroup'>
                                <input
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="125"
                                    name="selling_price"
                                    type="number"
                                    value={priceJobrBtobFixed?.selling_price}
                                    onChange={(e) => handleInputChangefixedBtob(e)}
                                />
                                <span className='dollrsign_'>$</span>
                            </div>
                        </div>
                        <div className='form-group mb-3 col-md-6'>
                            <label className="deatilsHead mb-2 fw-normal">Profit Margin
                            </label>
                            <div className='inputGroup'>
                                <input
                                // disabled
                                    min="1"
                                    onKeyDown={blockInvalidChar}
                                    className="customform-control"
                                    placeholder="10"
                                    name="margin_percentage"
                                    type="number"
                                    value={(priceJobrBtobFixed?.margin_percentage)?.toString()?.replace(/\.00$/, "")}
                                    onChange={(e) => handleInputChangefixedBtob(e)}
                                />
                                <span className='percentagesign_'>%</span>
                            </div>
                        </div>
                    </form>
                </div>
                : ""}
        </>
    )
}

export default FixedPrice