import React from 'react'

const BankdetailviewModal = () => {
    return (
        <div className='bankdetailviewModal_'>
            <div className='previewStatement_ mt-4'>
                <div className='row'>
                    <div className='col-md-5 mb-2'>
                        <div className='valuewithdrawleft_'>
                            <h5 className='labelTxt'>Bank Name:</h5 >
                        </div>
                    </div>
                    <div className='col-md-7 mb-2'>
                        <div className='valuewithdraw_'>
                            <h5 className='labelTxt fw-bold'>FIRST NATIONAL BANK OF OMAHA</h5 >
                        </div>
                    </div>
                    <div className='col-md-5 mb-2'>
                        <div className='valuewithdrawleft_'>
                            <h5 className='labelTxt'>Account Number:</h5 >
                        </div>
                    </div>
                    <div className='col-md-7 mb-2'>
                        <div className='valuewithdraw_'>
                            <h5 className='labelTxt fw-bold'>47692351869664</h5 >
                        </div>
                    </div>
                    <div className='col-md-5 mb-2'>
                        <div className='valuewithdrawleft_'>
                            <h5 className='labelTxt'>Account Name:</h5 >
                        </div>
                    </div>
                    <div className='col-md-7 mb-2'>
                        <div className='valuewithdraw_'>
                            <h5 className='labelTxt fw-bold'>Store name</h5 >
                        </div>
                    </div>
                    <div className='col-md-5 mb-2'>
                        <div className='valuewithdrawleft_'>
                            <h5 className='labelTxt'>Routing Number:</h5 >
                        </div>
                    </div>
                    <div className='col-md-7 mb-2'>
                        <div className='valuewithdraw_'>
                            <h5 className='labelTxt fw-bold'>11701987</h5 >
                        </div>
                    </div>
                    <div className='col-md-5 mb-2'>
                        <div className='valuewithdrawleft_'>
                            <h5 className='labelTxt'>Bank City/State:</h5 >
                        </div>
                    </div>
                    <div className='col-md-7 mb-2'>
                        <div className='valuewithdraw_'>
                            <h5 className='labelTxt fw-bold'>Miami</h5 >
                        </div>
                    </div>
                    <div className='col-md-5 mb-2'>
                        <div className='valuewithdrawleft_'>
                            <h5 className='labelTxt'>Address line :</h5 >
                        </div>
                    </div>
                    <div className='col-md-7 mb-2'>
                        <div className='valuewithdraw_'>
                            <h5 className='labelTxt fw-bold'>350 S Ave Miami Fl-2990088</h5 >
                        </div>
                    </div>
                    <div className='col-md-12 mb-2'>
                        <div className='overTimeMain mt-4 mb-2 w-100'>
                            <div className="form-check form-switch me-2 mb-0 ">
                                <input type="checkbox" id="skipPOS" />
                            </div>
                            <h4 className='identityText m-0'>Set Default for Payroll Payment</h4>
                        </div>
                    </div>
                    <div className='col-md-12 mb-2'>
                        <div className='overTimeMain w-100'>
                            <div className="form-check form-switch me-2 mb-0 ">
                                <input type="checkbox" id="skipPOS" />
                            </div>
                            <h4 className='identityText m-0'>Set Default for Withdrawal</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankdetailviewModal