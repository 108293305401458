import React, { useEffect, useState } from "react";
import {
  defaultUser,
  profileImg1,
  search_light,
  user,
  userregular,
} from "../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { getPosUsersAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AddStaffModal = (props) => {
  const [searchData, setSearchData] = useState("");
  const [posList, setPosList] = useState([]);
  console.log(posList, "posList");
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();

  const toggleItem = (data) => {
    if (
      selectedId.find(
        (val) => val?.pos_staff_details_id === data?.id || val?.id === data?.id
      )
    ) {
      // Remove item if it exists in the array
      const updatedItems = selectedId.filter(
        (item) => item.id !== data.id || item?.pos_staff_details_id === data?.id
      );

      setSelectedId(updatedItems);
    } else {
      // Add item if it doesn't exist in the array
      setSelectedId([...selectedId, data]);
    }
  };

  const searchPosUser = () => {
    let params = {
      postData: {
        // seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        need_staff_member: true,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "b169ed4d-be27-44eb-9a08-74f997bc6a2j"
      },
      tokenData: auth?.payload?.token,
    };
    if (searchData) {
      params = {
        postData: {
          ...params.postData,
          search: searchData,
        },
        tokenData: auth?.payload?.token,
      };
    }
    setLoading(true);
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setPosList(obj?.payload?.pos_staff);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (searchData == "" || searchData.length >= 3) {
      searchPosUser();
    }
  }, [searchData]);

  useEffect(() => {
    setSelectedId(props?.posIds ? [...props?.posIds] : []);
  }, []);
  return (
    <>
      <div className="input-group offersearch_group mb-4 mt-4">
        <img src={search_light} alt="" className="img-fluid searchIcon " />
        <input
          value={searchData}
          onChange={(e) => setSearchData(e.target.value)}
          type="text"
          className="customform-control"
          placeholder="Search here"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
      </div>

      {loading === true ? (
        <div className="pageLoader d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <ul className="createListoffer">
            {posList?.length > 0 ? (
              posList?.map((val, index) => {
                return (
                  <li
                    style={{ cursor: "pointer" }}
                    className={
                      selectedId?.find(
                        (id) =>
                          id.pos_staff_details_id == val?.id || id.id == val.id
                      )
                        ? "active"
                        : ""
                    }
                    onClick={() => toggleItem(val)}
                    key={index}
                  >
                    {
                      <>
                        <img
                          className="me-2"
                          src={
                            val?.user?.user_profiles?.profile_photo
                              ? val?.user?.user_profiles?.profile_photo
                              : profileImg1
                          }
                          alt="product_img"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50px",
                          }}
                        />
                      </>
                    }
                    <div className="listinercontent_">
                      <h4 className="monsHeading_">
                        {" "}
                        {`${val?.user?.user_profiles?.firstname ? val?.user?.user_profiles?.firstname : "" } ${val?.user?.user_profiles?.lastname ? val?.user?.user_profiles?.lastname : ""}`}
                      </h4>
                      <span className="designation_">
                        {val?.user?.user_roles[0]?.role?.name}
                      </span>
                    </div>
                  </li>
                );
              })
            ) : (
              <li>
                <div className="search-typinner">
                  <h4>No Result Found</h4>
                </div>
              </li>
            )}
          </ul>
        </>
      )}
      <p className="staffText_ mb-3">
        Sales Taxes configured to apply to all items or categories are
        automatically applied to new items. Edit Sales Tax application rules in{" "}
        <Link to="#" className="linkback_">
          Settings <i class="fa-solid fa-chevron-right"></i>
        </Link>{" "}
        <Link to="#" className="linkback_">
          Staff{" "}
        </Link>{" "}
        .{" "}
      </p>

      {selectedId?.length > 0 && (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button
            onClick={() => props?.close()}
            className="cancelBookBtn me-3"
            type="button"
          >
            <span>Cancel</span>
          </button>
          <button
            onClick={() => {
              props?.getIdsHandle(selectedId);
              props?.handleSelect(selectedId);
            }}
            className="saveModifyBtn me-2"
            type="button"
          >
            <span>Add</span>
          </button>
        </div>
      )}
    </>
  );
};

export default AddStaffModal;
