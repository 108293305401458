
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {editIcon, user} from '../../../../utilities/images'
const ViewrolePermission = () => {
    const history = useHistory()
    return (
        <div className='viewrolePermission'>
            <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row  align-items-center">
                        <div className="col-md-6">
                            <div className='anotherHeader'>
                            <i className="las la-angle-left" onClick={() => history.push ('/systemConfig/userviewList')}></i>
                            <h2 className="innerSubheadNormal">Role: Wallet Admin</h2>
                            {/* <span className="pendingStatus">Pending</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='viewcommonbox_'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='chartsOuter rolemaincontainer_'>
                            <div className='topheadpermit'>
                                <div className='row align-items-center'>
                                    <div className='col-md-8'>
                                        <div className='rolepermitTop'>
                                            <h5 className='subheadText'>Role Permission (Admin manager)</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-4 text-end'>
                                        <button className="valuesTimeswallet active" onClick={() => history.push ('/rolePermissionEdit')}>
                                            <img className='editIconn' src={editIcon} />
                                            <span className='ms-2'>Edit</span>
                                        </button>
                                    </div>
                                </div>
                             </div>
                             <div className='middlebodycontain_'>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Orders Managment</h4>
                                    <h5 className='contentheadtext_ mb-3'>View</h5>
                                    <h5 className='contentheadtext_ mb-3'>Edit/Update</h5>  
                                    <h5 className='contentheadtext_ deletefade_ mb-3'>Delete</h5> 
                                </div>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Invetory Managment</h4>
                                    <h5 className='contentheadtext_ mb-3'>View</h5>
                                    <h5 className='contentheadtext_ mb-3'>Edit/Update</h5>  
                                    <h5 className='contentheadtext_ mb-3'>Add Inventory</h5> 
                                    <h5 className='contentheadtext_ mb-3'>Adjust Inventory</h5>
                                    <h5 className='contentheadtext_ deletefade_ mb-3'>Delete</h5>
                                </div>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Sales Management</h4>
                                    <h5 className='contentheadtext_ mb-3'>View</h5>
                                    <h5 className='contentheadtext_ mb-3'>Edit/Update</h5>  
                                    <h5 className='contentheadtext_ deletefade_ mb-3'>Delete</h5>
                                </div>
                                <div className='manageOrder mb-4'>
                                    <h4 className='innerSubtext mb-3'>Wallet Management</h4>
                                    <h5 className='contentheadtext_ mb-3'>View</h5>
                                    <h5 className='contentheadtext_ mb-3'>Edit/Update</h5>  
                                    <h5 className='contentheadtext_ mb-3'>Payment Verifications</h5>
                                    <h5 className='contentheadtext_ deletefade_ mb-3'>Delete</h5>
                                </div>
                             </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='chartsOuter rolemaincontainer_'>
                                <div className='topheadpermit'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-7'>
                                                <div className='rolepermitTop'>
                                                    <h5 className='subheadText'>Assigned Users</h5>
                                                </div>
                                            </div>
                                            <div className='col-md-5 text-end'>
                                                <button className="valuesTimeswallet active">
                                                    <span>Add user</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='middlebodycontain_'>
                                         <div className='customNewSwitch'>
                                             <div className='d-flex align-items-center'>
                                              <img className='userInnerImg' src={user} />
                                                <div className='ms-2'>
                                                  <span className='textParagh '>Teri J Henderson</span>
                                                 </div>
                                             </div>
                                             <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                <label className="form-check-label" for="flexSwitchCheckChecked"></label>
                                            </div>
                                        </div>
                                        <div className='customNewSwitch'>
                                             <div className='d-flex align-items-center'>
                                              <img className='userInnerImg' src={user} />
                                                <div className='ms-2'>
                                                  <span className='textParagh '>Teri J Henderson</span>
                                                 </div>
                                             </div>
                                             <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                                                <label className="form-check-label" for="flexSwitchCheckChecked"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default ViewrolePermission