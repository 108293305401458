import React from 'react'
import moment from 'moment-timezone';

const GiftDetails = (props) => {


    return (
        <>
            <div className="scheduleModal">
                <div className="scheduleModalContent">
                    <div className="userProfileinfo_">
                        <img style={{ width: "125px" }} className='scheduleimg me-3' src={props?.selectedGift?.image} />
                        <div className="userProfileinforight_">
                            <h3 className='subHeading'>{props?.selectedGift?.title}</h3>
                        </div>
                    </div>
                    <div className="appointmentDetails_">
                        <h3>Gift Details</h3>
                        <div className="appointInner mb-4">
                            <div className="contentappont">
                                <p className='mainlightPhragraph mb-1'>
                                    <b>Gift Price: </b>${(props?.selectedGift?.gift_amount)?.toLocaleString()}
                                </p>
                                <p className='mainlightPhragraph mb-1'>
                                    <b>Offer Validity: </b>till {moment?.utc(props?.selectedGift?.end_date).format("MMM DD, YYYY")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GiftDetails