import React from 'react'
import { shield, manage, chatIcon, pdfImage, profileImg1 } from '../../../../utilities/images'
import moment from 'moment-timezone';

const ManufactureProfile = (props) => {
  let data = props?.data;
  console.log(data)

  return (
    <div className='merchantactive'>
      <div className="row">
        <div className="col-md-4 col-lg-4 col-xl-5 col-xxl-4">
          <div className='chartsOuter mt-4'>
            <div className='driverprofile_box'>
              <img className='driveinfo_img mb-3' src={data?.user_profiles?.profile_photo ? data?.user_profiles?.profile_photo : profileImg1} />
              <h4 className='boldsubSubhead'>{data?.user_profiles?.firstname + " " + data?.user_profiles?.lastname}</h4>
              <p className='contentSubtextbold mb-2'>
                <img src={shield} /> <span>4703-515-1193</span>
              </p>
              <p className='bluesubHead__ mb-3'>{data?.user_profiles?.current_address?.state + "," + data?.user_profiles?.current_address?.country}</p>
            </div>
            <hr></hr>
            <div className='drivecontentInfo_'>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Registered</strong>
                <p className='mt-2 mb-0'>{moment(data?.created_at).format("DD MMM YYYY")}</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Total Order Complete</strong>
                <p className='mt-2 mb-0'>{data?.ordersCompleted}</p>
              </h4>
              <h4 className='deviceHead mb-4'>
                <strong className='subtextDark_'>Success Rate</strong>
                <p className='mt-2 mb-0'>{data?.success_rate?.toFixed(2)}%</p>
              </h4>
              <h4 className='deviceHead mb-4'>
                <strong className='subtextDark_'>Total Annual Revenue</strong>
                <p className='mt-2 mb-0'>{data?.user_profiles?.overview ? data?.user_profiles?.overview[0]?.total_annual_revenue :""} JBR 5,550</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Status</strong>
               <p className='completeStatus ms-0 mt-2 mb-0'>{data?.is_active === true ? 'Active' : "Inactive"}</p>
              </h4>
              <div className='bottombtn__ mt-4'>
                <button className='chatbtnn_' type="button"><img className='chaticonn__' src={chatIcon} /> Send Message</button>
                <button className='trackApp_' type="button"><img className='trachimg_ me-2' src={manage} /> Website</button>

              </div>
            </div>

          </div>
        </div>
        <div className="col-md-8 col-lg-8 col-xl-7 col-xxl-8">
          <div className="chartsOuter mt-4">
            <div className='persnlInfo_'>
              <h3 className='modalHeading mb-3'>Business Details</h3>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Legal business name</strong>
                <p className=' mt-2 mb-0'>{data?.user_profiles?.organization_name}</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Business Type </strong>
                <p className='mt-2 mb-0'>{data?.user_profiles?.seller_type}</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Business phone number</strong>
                <p className='mt-2 mb-0'>{data?.user_profiles?.phone_code + data?.user_profiles?.phone_no}</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Business email address</strong>
                <p className='mt-2 mb-0'>{data?.email}</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Business address</strong>
                <p className='mt-2 mb-0'>{data?.user_profiles?.current_address?.street_address}</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Business website</strong>
                <p className='mt-2 mb-0'>{data?.user_profiles?.business_website}</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Year established</strong>
                <p className='mt-2 mb-0'>{data?.user_profiles?.overview ? data?.user_profiles?.overview[0]?.year_established :""}</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Main Products</strong>
                <p className='mt-2 mb-0'>{data?.user_profiles?.overview ? data?.user_profiles?.overview[0]?.main_products :""}</p>
              </h4>
              <h4 className='deviceHead mb-2'>
                <strong className='subtextDark_'>Product Certifications({data?.user_profiles?.certification?.length})</strong>
                <p className='mt-2 mb-0'>{data?.user_profiles?.overview ? data?.user_profiles?.overview[0]?.patents :""}</p>
              </h4>
            </div>
          </div>
          <div className='chartsOuter mt-3'>
            <div className='productionequipment_'>
              <h3 className='modalHeading mb-3'>Production Equipment</h3>
              <div className='row'>
                {
                  data?.user_profiles?.manufacturer_images?.length > 0 ?
                    data?.user_profiles?.manufacturer_images?.map((v, i) => {
                      return (
                        <div key={i} className='col-lg-3 col-md-3 mb-3 mt-2'>
                          <div className='equipmentImages_'>
                            <img className='equipmentimage_' src={v} />
                          </div>
                        </div>
                      )
                    }) : <>No Images</>
                }
              </div>
            </div>
          </div>
          <div className='chartsOuter mt-3'>
            <div className='productionequipment_'>
              <h3 className='modalHeading mb-3'>Production Certificates</h3>
              <div className='equipMain'>
                {
                  data?.user_profiles?.certification?.length > 0 ?
                    data?.user_profiles?.certification?.map((v, i) => {
                      return (
                        <div key={i} className='equipmentImages_ me-3'>
                          <img style={{ width: "100px" }} className='equipmentimage_' src={v} />
                        </div>
                      )
                    }) : <>No Certificates</>
                }
              </div>
            </div>
          </div>
          <div className='chartsOuter mt-3'>
            <div className='productionequipment_'>
              <h3 className='modalHeading mb-3'>Factory Inspection Reports</h3>
              <div className='equipMain'>
                {
                  data?.user_profiles?.business_inspection_report?.length > 0 ?
                    data?.user_profiles?.business_inspection_report?.map((v, i) => {
                      return (
                        <div key={i} className='equipmentImages_ me-3'>
                          <a href={v} target='_blank' rel='noopener noreferrer'>
                            <img className='equipmentimage_' src={pdfImage} />
                          </a>
                        </div>
                      )
                    }) : <>No Inspection Reports</>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManufactureProfile