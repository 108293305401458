import React, { useState } from 'react'
import { toast } from 'react-toastify';

const LargeOrderQuantity = (props) => {
    const [loading, setLoading] = useState(false);
    const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
    const submitHandle = (e) => {
        e.preventDefault()
        if(props?.qty){
            props?.handleCheckBox(true, "largeOrder" , "toggle")
            props?.close()
        }
        else{
            toast.error("Please enter qty")
        }
    }
    return (
        <div className="addEmployeeuser createuserprof_">
            <form         autoComplete="nope"
        onSubmit={(e) => submitHandle(e)}>
            <div className="form-group col-md-6  mb-4">
                <label className="labelTxt mb-3" htmlFor="amount">
                    Quantity
                </label>
                <input
                    type="number"
                    name="text"
                    className="customform-control"
                    placeholder="Please enter title"
                    autoComplete="off"
                    onKeyDown={blockInvalidChar}
                    value={props?.qty}
                    onChange={(e) => props?.setQty(e.target.value)}
                />
            </div>
            <div className="footerSec mt-4">
                <button className="submitBtn" type="submit" disabled={loading}>
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Save</span>
                </button>
            </div>
            </form>
        </div>
    )
}

export default LargeOrderQuantity