import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../dashboard/DashboardNavbar'
import { deleteGiftCardApiAsync, getAllGiftCardAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectLoginAuth } from '../auth/authSlice';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import CustomModal from '../../components/shared/CustomModal';
import DataTable from 'react-data-table-component';
import { calendar } from '../../../utilities/images';
import DatePicker from "react-datepicker";
import GiftDetails from './GiftDetails';
import CreateGift from './CreateGift';
import { toast } from 'react-toastify';

const GiftCards = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [giftList, setGiftList] = useState([]);
  const [selectedGift, setSelectedGift] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(Math.random());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getGiftCardList = (page) => {
    setLoading(true);
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        page: page ? page : currentPage,
        limit: perPage,
      },
    };
    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    dispatch(getAllGiftCardAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        console.log(obj?.payload, "offers");
        setGiftList(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const deleteHandle = (id) => {
    Swal.fire({
      title: "Do you want to delete this gift card?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          postData: {
            id: id?.id,
          },
          tokenData: auth?.payload?.token,
        }
        dispatch(deleteGiftCardApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            getGiftCardList();
            toast.success(obj?.msg);
          })
          .catch((obj) => { });
      }
    });
  };

  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  console.log(selectedGift, "ssssssssssssssssssssssss");

  let columns1 = [
    {
      name: "#",
      // selector: row => row?.id,
      selector: (row, i) => i + 1 * (perPage * (currentPage - 1)) + 1,
      width: "40px",
    },
    {
      name: `Name`,
      selector: (row) => {
        return (
          <>
            <div
              className="d-flex align-items-center"
              onClick={() => handleRowClick(row)}
            >
              <img className="userInnerImg" src={row?.image} />
              <div className="ms-2">
                <span className="textParagh ">
      
                  {row?.title?.length > 15
                    ? row?.title?.substring(0, 15) + "..."
                    : row?.title}
                </span>
              </div>
            </div>
          </>
        );
      },
      width: "200px",
    },
    {
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {addThousandSeparator(row?.gift_amount) ? "$" : ""}
              {addThousandSeparator(row?.gift_amount)}
            </span>
          </>
        );
      },
      name: "Gift Amount",
    },
    {
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {moment?.utc(row?.end_date)?.format("MMM DD, YYYY")}
            </span>
          </>
        );
      },
      name: "Gift validity date",
      width: "170px",
    },
    {
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {moment?.utc(row?.updated_at)?.format("MMM DD, YYYY")}
            </span>
          </>
        );
      },
      name: "Updated At",
      width: "170px",
    },
    {
      name: "",
      selector: (row) => {
        return (
          <>
            <div className="d-flex justify-space-between align-items-center pe-3 ps-3">
              <div className="dropdown tableDropdown ms-3 ">
                <span
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="barMenus"
                >
                  <i className="fal fa-ellipsis-v icon_"></i>
                </span>
                <div
                  className="dropdown-menu tableDropdownMenus categoryDrop"
                  aria-labelledby="barMenus"
                >
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedGift(row);
                      setModalDetail({ show: true, flag: "viewGift" });
                      setKey(Math.random());
                    }}
                  >
                    View
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedGift(row);
                      setModalDetail({ show: true, flag: "editGift" });
                      setKey(Math.random());
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => deleteHandle(row)}
                  >
                    Delete
                  </li>
                </div>
              </div>
            </div>
          </>
        );
      },
      width: "100px",
    },
  ];
  const handleRowClick = (e) => {
    setSelectedGift(e);
    setModalDetail({ show: true, flag: "viewGift" });
    setKey(Math.random());
  };



  useEffect(() => {
    getGiftCardList();
  }, [currentPage, perPage, endDate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Gift Cards";
  }, []);

  return (
    <>
      <DashboardNavbar title="Gift Cards" backShow={false} />
      <div className="tableSectionBackground productOfferSection">
        <div className="filteOffer">
          <div className="row">
            <div className="col-lg-6">
              <div className="calenderIcon">
                <img src={calendar} alt="calender" />
                <DatePicker
                  className="cateDate"
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Date"
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <button
                className="backgroundFillbtn"
                type="button"
                onClick={() => {
                  setModalDetail({ show: true, flag: "MakeGift" });
                  setKey(Math.random());
                }}
              >
                Create Gift Card
              </button>
            </div>
          </div>
        </div>
        <div className='plansAreasContainer'>
          <section className='recentTransections mt-1 commonTableheight table-responsive'>
            <DataTable
              columns={columns1}
              data={giftList}
              progressPending={loading}
              defaultSortFieldId={1}
              customStyles={customStyles}
              onRowClicked={(e) => handleRowClick(e)}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationPerPage={perPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </section>
        </div>

        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop={true}
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          ids={
            modalDetail.flag === "viewGift"
              ? "ViewGift"
              : modalDetail.flag === "MakeGift"
                ? "createOffer"
                : modalDetail.flag === "editGift"
                  ? "editGift"
                  : ""
          }
          size={
            modalDetail.flag === "viewGift" || modalDetail.flag === "MakeGift"
              ? "xl"
              : "md" ||
                modalDetail.flag === "editGift" ||
                modalDetail.flag === "MakeGift"
                ? "xl"
                : "md"
          }
          child={
            modalDetail.flag === "MakeGift" ? (
              <CreateGift
                close={() => handleOnCloseModal()}
                getGiftCardList={() => getGiftCardList()}
              />
            ) : modalDetail.flag === "viewGift" ? (
              <GiftDetails
                selectedGift={selectedGift}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "editGift" ? (
              <CreateGift
                close={() => handleOnCloseModal()}
                getGiftCardList={() => getGiftCardList()}
                selectedGift={selectedGift}
                editMode={true}
              />
            ) : (
              ""
            )
          }
          header={
            <div className="modalHeader_">
              <div className="common_">
                <h2 className="modalHeading_">
                  {modalDetail.flag === "MakeGift"
                    ? "Create Gift Card"
                    : "" || modalDetail.flag === "editGift"
                      ? "Edit"
                      : "" || modalDetail.flag === "viewGift"
                        ? "Gift Details"
                        : ""}{" "}
                </h2>

                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  )
}

export default GiftCards