import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { delivery, dropOff, locationPin, pickup, shield, shipping, starsetup, store, setLocation, dotsinbox } from '../../../../utilities/images'
import CustomModal from '../../../components/shared/CustomModal'
import AddAddressModal from './addAddressModal'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import CustomLoader from '../../../components/shared/CustomLoader'
import { toast } from 'react-toastify'
import { updateSellerInfoApiAsync } from '../../dashboard/dashboardSlice'
import { deleteAddressApiAsync, getAddressApiAsync, selectAddressList, selectLoginAuth } from '../../auth/authSlice'
import Swal from 'sweetalert2';
import DashboardNavbar from '../DashboardNavbar';
import SetUpAddressModal from './setUpAddressModal'
import StoreAddressModal from './storeAddressModal'
import ShippingModal from './shippingModal'
import DeliveryAddressModal from './deliveryAddressModal'

const ConfigShippingPickup = () => {
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const addressList = useSelector(selectAddressList)
    const [addressId, setAddressId] = useState("")
    const [addressIdModal, setAddressIdModal] = useState("")
    const [addressDataModal, setAddressDataModal] = useState("")
    const [addressData, setAddressData] = useState("")
    const [loading, setLoading] = useState(false)
    const [addressType, setAddressType] = useState("")
    const [addressTypeModal, setAddressTypeModal] = useState("")
    const auth = useSelector(selectLoginAuth)
    const toastId = React.useRef(null)
    const dispatch = useDispatch()
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const history = useHistory('')

    const handleChange = (id, flag, status) => {
        let params = {
            "postData": {
                "id": id,
                [flag]: status == true ? "0" : "1",
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(updateSellerInfoApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let params = {
                    token: auth?.payload?.token,
                    sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                }
                dispatch(getAddressApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getAddress = () => {
        let params = {
            token: auth?.payload?.token,
            sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
        }
        dispatch(getAddressApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }
    const handleDeleteAddress = (id) => {
        Swal.fire({
            title: 'Do you want to delete the address?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    "postData": {
                        "id": id,
                    },
                    "tokenData": auth?.payload?.token
                }
                setLoading(true)
                dispatch(deleteAddressApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false)
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.success(obj?.msg)
                        }
                        getAddress();
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
        })
    }


    const handleEditAddress = (id, flag, data) => {
        setAddressId(id)
        setModalDetail({ show: true, flag: "AddAddress" });
        setKey(Math.random());
        setAddressType(flag)
        setAddressData(data)
    }

    const getAddressMerchant = () => {
        setLoading(true)
        let params = {
            token: auth?.payload?.token,
            sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
        }
        dispatch(getAddressApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getAddressMerchant()
    }, [])
    return (
        <>

            <DashboardNavbar title="Setup Address" backShow={true} />
            <div className='setupAddress setAdressbussiness'>
                {/* Start FixedTopHeader */}
                {/* <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span onClick={() => history.push('/plans')}>
                                    <i className="las la-angle-left me-3 directionArrow"></i>
                                </span>
                                <span>Setup Addresses</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div> */}
                {/* End FixedTopHeader */}

                {
                    addressList?.payload?.map((val, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className='paymentContainer'>
                                    <div className='paymentArea bussinessPayment'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='d-flex align-items-start'>
                                                    <div className='setLocationImg me-3 '><img src={setLocation} className='img-fluid' /></div>
                                                    <div className='w-100'>
                                                        <h3 className='textInnerDark'>{auth?.payload?.user?.user_profiles?.organization_name}</h3>
                                                        <p className='subtext_ mt-2'>{val?.format_address}</p>
                                                    </div>
                                                    <div className='dropdown typeBtn_'>
                                                        <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                                            <img className='' src={dotsinbox} />
                                                        </Link>
                                                        <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton2" >
                                                            <li ><Link to="#" className="dropdown-item chatdropItem " >
                                                                <span className='subtextSmall_'>Edit</span></Link>
                                                            </li>
                                                            <li ><Link to="#" className="dropdown-item chatdropItem">
                                                                <span className='subtextSmall_' >Delete</span></Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='fourPaymentArea'>
                                                {/* Local Pickup */}
                                                <div className='paymentArea'>
                                                    <div className='row'>
                                                        <div onClick={() => { setModalDetail({ show: true, flag: "setUpAddress" }); setKey(Math.random()); setAddressTypeModal("Local Pickup"); setAddressIdModal(val?.id); setAddressDataModal(val) }} className='col-md-10'>
                                                            <div className='d-flex align-items-start'>
                                                                <img src={pickup} className='paymentTypeIcon me-3' />
                                                                <div className='w-100'>
                                                                    <div style={{ cursor: "pointer" }} key={index}>
                                                                        <div className='row'>
                                                                            <div className='col-md-10' >
                                                                                <h3 className='textInnerDark'>Local Pickup</h3>
                                                                                <p className='subtext_ mt-2'>This location(s) will allow Customer Pickups, Driver Pickups, Shipping Pickups</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2 text-end'>
                                                            <div className="form-check form-switch">
                                                                <input type="checkbox"
                                                                    checked={val?.allow_local_pickup}
                                                                    onChange={(e) => { handleChange(val?.id, "allow_local_pickup", val?.allow_local_pickup) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* JOBR Delivery */}
                                                <div className='paymentArea'>
                                                    <div className='row'>
                                                        <div className='col-md-10'>
                                                            <div className='d-flex align-items-start'>
                                                                <img src={delivery} className='paymentTypeIcon me-3' />
                                                                <div className='w-100'>
                                                                    <div style={{ cursor: "pointer" }} key={index}>
                                                                        <div className='row'>
                                                                            <div className='col-md-10'
                                                                                onClick={() => { setModalDetail({ show: true, flag: "deliveryModal" }); setKey(Math.random()); setAddressTypeModal("JOBR Delivery"); setAddressIdModal(val?.id); setAddressDataModal(val) }}>
                                                                                <h3 className='textInnerDark'>JOBR Delivery</h3>
                                                                                <p className='subtext_ mt-2'>This location(s) will allow Customer Pickups, Driver Pickups, Shipping Pickups</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-2 text-end'>
                                                            <div className="form-check form-switch">
                                                                <input type="checkbox"
                                                                    checked={val?.allow_jobr_delivery}
                                                                    onChange={(e) => { handleChange(val?.id, "allow_jobr_delivery", val?.allow_jobr_delivery) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*  Local Drop off */}
                                                <div className='paymentArea'>
                                                    <div className='row'>
                                                        <div className='col-md-10'>
                                                            <div className='d-flex align-items-start'>
                                                                <img src={dropOff} className='paymentTypeIcon me-3' />
                                                                <div className='w-100'>
                                                                    <div style={{ cursor: "pointer" }} key={index}>
                                                                        <div className='row'>
                                                                            <div className='col-md-9' onClick={() => { setModalDetail({ show: true, flag: "storeModal" }); setKey(Math.random()); setAddressTypeModal("Local Drop-off"); setAddressIdModal(val?.id); setAddressDataModal(val) }}>
                                                                                <h3 className='textInnerDark'>Local Drop-off</h3>
                                                                                <p className='subtext_ mt-2'>This location(s) will allow Customer Pickups, Driver Pickups, Shipping Pickups</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2 text-end'>
                                                            <div className="form-check form-switch">
                                                                <input type="checkbox"
                                                                    checked={val?.allow_local_drop_off}
                                                                    onChange={(e) => { handleChange(val?.id, "allow_local_drop_off", val?.allow_local_drop_off) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Shipping */}
                                                <div className='paymentArea'>
                                                    <div className='row'>
                                                        <div className='col-md-10'>
                                                            <div className='d-flex align-items-start'>
                                                                <img src={shipping} className='paymentTypeIcon me-3' />
                                                                <div className='w-100'>
                                                                    <div style={{ cursor: "pointer" }} key={index}>
                                                                        <div className='row'>
                                                                            <div className='col-md-9' onClick={() => { setModalDetail({ show: true, flag: "shippingModal" }); setKey(Math.random()); setAddressTypeModal("Shipping"); setAddressIdModal(val?.id); setAddressDataModal(val) }}>
                                                                                <h3 className='textInnerDark'>Shipping</h3>
                                                                                <p className='subtext_ mt-2'>This location(s) will allow Customer Pickups, Driver Pickups, Shipping Pickups</p>
                                                                                <p className='subtext_ mt-2'> {val?.isShippingConnected ? <>
                                                                                    Shipping Connected
                                                                                </> : ""}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2 text-end'>
                                                            <div className="form-check form-switch">
                                                                <input type="checkbox"
                                                                    checked={val?.allow_shipping}
                                                                    onChange={(e) => { handleChange(val?.id, "allow_shipping", val?.allow_shipping) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })
                }

                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    // backdrop="static"
                    showCloseBtn={false}
                    isRightSideModal={false}
                    mediumWidth={false}
                    ids={modalDetail.flag === "AddAddress" ? "addAddress" : modalDetail.flag === 'setUpAddress' ? "SetUpAddress" : modalDetail.flag === 'storeModal' ? "StoreModal" : modalDetail.flag === 'shippingModal' ? "ShippingModal" : modalDetail.flag === 'deliveryModal' ? "DeliveryModal" : ""}
                    size={modalDetail.flag === "setUpAddress" || modalDetail.flag === 'storeModal' || modalDetail.flag === 'shippingModal' || modalDetail.flag === 'deliveryModal' ? "lg" : ""}
                    child={modalDetail.flag === 'AddAddress' ? <AddAddressModal close={() => handleOnCloseModal()} addressId={addressId} addressType={addressType} addressData={addressData} />
                        : modalDetail.flag === 'setUpAddress' ? <SetUpAddressModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} /> :
                            modalDetail.flag === 'storeModal' ? <StoreAddressModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} /> :
                                modalDetail.flag === 'shippingModal' ? <ShippingModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} /> :
                                    modalDetail.flag === 'deliveryModal' ? <DeliveryAddressModal addressDataModal={addressDataModal} getAddressMerchant={() => getAddressMerchant()} addressId={addressIdModal} close={() => handleOnCloseModal()} /> : ""
                    }
                    header={modalDetail.flag === 'AddAddress'
                        ?
                        <div className="modalHeader_">
                            <div className="common_">
                                <h3 className='modalHeading'>Edit Address</h3>

                                <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                    <i className="las la-times" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div> :
                        <>
                            <div className="modalHeader_">
                                <div className="common_">
                                    <h3 className='modalHeading_'>{addressTypeModal}</h3>

                                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                        <i className="las la-times" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </>
                    }

                    onCloseModal={() => handleOnCloseModal()}
                />

            </div>
        </>
    )
}

export default ConfigShippingPickup