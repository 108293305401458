import React, { useEffect, useState } from "react";
import * as Images from '../../../../utilities/images';
// import Image from "next/image";
// import { searchBySKU } from "../../redux/slices/productReturn";
import { selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from '@reduxjs/toolkit';
import { searchBySkuApiAsync } from "../../myOrders/returnOrder/returnOrderSlice";


const Manualinvoice = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const authData = useSelector(selectLoginAuth);
  const sellerId = authData?.payload?.uniqe_id;
  const productDetails = props?.productDetails;

  const [searchKeyword, setSearchKeyword] = useState();
  const [productsSearchBySku, setProductsSearchBySku] = useState("");

  const isProductIdMatched = productDetails?.some(
    (product) => product?.product_id === productsSearchBySku?.id
  );

  const handleSearchSku = (skuNumber) => {
    let params = {
      "postData": {
        app_name:"pos",
        search: skuNumber,
        seller_id: sellerId,
      },
      "tokenData": authData?.payload?.token
  }
    setLoading(true);
    dispatch(searchBySkuApiAsync(params))
    .then(unwrapResult)
    .then((obj) => {
        setLoading(false)
        setProductsSearchBySku(obj?.payload?.product_detail);
    }
    )
    .catch((obj) => {
        setLoading(false)
    })
  };

  useEffect(() => {
    if (searchKeyword && typeof searchKeyword != "undefined") {
      const search = setTimeout(() => {
        var keyword = searchKeyword.toLowerCase();
        handleSearchSku(keyword);
      }, 2000);
      return () => clearTimeout(search);
    } else {
    }
  }, [searchKeyword]);

  const handleManulEntry = () => {
    if (!productsSearchBySku) {
      toast.error("Please add Product by search SKU!");
      return;
    } else {
      props.closeManulModal();
    }
  };
  const handleCheckProduct = () => {
    if (isProductIdMatched === true) {
      toast.success("Product Checked!");
      props.setCheckedData(productsSearchBySku);
      return;
    } else {
      toast.error("Product Not found in order!");
    }
  };

  return (
    <>
      <div className="manualInvoice">
        <div className="commanscrollBar manualOrderedProduct mt-0 mb-0">
          <div className="ManualsearchBar">
            <input
              type="text"
              className="form-control searchControl"
              placeholder="search sku here..."
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
            />
            <img
              src={Images.SearchIcon}
              alt="SearchImageIcon"
              className="img-fluid searchImg"
            />
            {/* <button className="closeButton">
              <img
                src={Images.modalCross}
                alt="img"
                onClick={() => props.closeManulModal()}
              />
            </button> */}
          </div>
          {loading === false ? (
            <div className="manualSelectedProduct">
              {productsSearchBySku ? (
                <div
                  className={
                    isProductIdMatched === true
                      ? "afterCheckedProduct"
                      : "selectedProductDetails"
                  }
                  onClick={handleCheckProduct}
                >
                  <div className="d-flex align-items-center">
                    <figure className="m-0">
                      <img
                        src={productsSearchBySku?.image}
                        alt="tableImg"
                        className="costumerImg"
                        height={100}
                        width={100}
                      />
                    </figure>
                    <div className="ps-1">
                      <p className="aboutProduct">
                        {productsSearchBySku?.name}
                      </p>
                      <div className="d-flex">
                        {/* <article className="productColor">
                        <span className="Yellow"></span>
                        <span className="Red"></span>
                        <span className="Pink"></span>
                        <span className="Blue"></span>
                        <span className="Black"></span>
                        <span className="White"></span>
                      </article> */}

                        <span className="sku">
                          {productsSearchBySku?.sku
                            ? productsSearchBySku?.sku
                            : ""}
                        </span>
                        {/* <span className="productSize">Colors / Size</span> */}
                      </div>
                    </div>
                  </div>
                  <p className="productPriceinvoice">
                    ${productsSearchBySku?.price}
                  </p>
                </div>
              ) : (
               <p className="notfound_ mt-2"> No Product Found</p>
              )}
            </div>
          ) : (
            <div className="loaderOuter">
              <div className="spinner-grow loaderSpinner text-center my-5"></div>
            </div>
          )}
          <div className="flexBox buttonBox mb-0">
            <button
              type="button"
              className="modalcloseBtn_"
              onClick={() => props.closeManulModal()}
            >
              Cancel
            </button>
            <button
              type="button"
              className=" modalsaveBtn_"
              onClick={(e) => handleManulEntry(e)}
            >
              Next
              <img
                src={Images.ArrowRight}
                alt="ArrowRight"
                className="img-fluid ArrowRight"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manualinvoice;
