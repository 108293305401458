export const getCurrentTimeZone = () => {
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Replace "Asia/Calcutta" with "Asia/Kolkata"
    if (timeZone === 'Asia/Calcutta') {
        timeZone = 'Asia/Kolkata';
    }

    return timeZone;
};
