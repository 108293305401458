import React, { useState } from 'react'
import moment from 'moment-timezone';
import { locationBlue, profileImg1, clockimg_ } from '../../../utilities/images';

const CheckinModal = (props) => {
    let data = props?.data;

    const statusChange = (id, status) => {
        props.changeStatusOfAppointment(id, status)
        if (!props.loading2) {
            props.close()
        }
    }

    return (
        <div className="checkInModalSection scheduleModal">
            {/* <div className=''>
                <button className="closeAction text-black"><i className="las la-times" onClick={props.close}></i></button>
            </div> */}
            <div className="checkModalDetails">
                <div className="Innerbooking mb-4">
                    <div className="customerBookProfile mb-4">
                        <h5 className='smallHeading mb-2'>Customer:</h5>
                        {
                            data?.user_details ?
                                <div className='d-flex align-items-center'>
                                    <img className='userInnerImg' src={data?.user_details?.profile_photo ? data?.user_details?.profile_photo : profileImg1} />
                                    <div className='ms-2'>
                                        <span className='haedingsemiBold_ '>{data?.user_details?.firstname + " " + data?.user_details?.lastname}</span>
                                        {data?.user_details?.current_address &&
                                            <p className='mb-0'>
                                                <img src={locationBlue} className='img-fluid me-2 custImg' />
                                                <span className='contentSmallTxt'>{data?.user_details?.current_address?.city},{data?.user_details?.current_address?.country}</span>
                                            </p>}
                                    </div>
                                </div> : <>Not Available</>}
                    </div>
                </div>
                <div className="InnercheckIn mb-4">
                    <div className="contentappont">
                        <h5 className='smallHeading mb-2'>Assigned:</h5>
                        <div className='d-flex align-items-center'>
                            <img className='userInnerImg' src={data?.pos_user_details?.user?.user_profiles?.profile_photo ? data?.pos_user_details?.user?.user_profiles?.profile_photo : profileImg1} />
                            <div className='ms-2'>
                                <span className='haedingsemiBold_'>{data?.pos_user_details?.user?.user_profiles?.firstname + " " + data?.pos_user_details?.user?.user_profiles?.lastname}</span>
                                <p className='mb-0'>
                                    <span className='contentSmallTxt'>{data?.pos_user_details?.is_staff_member === true ? "Staff" : "Non-staff"}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="appointInner mb-4">
                    <div className="contentappont">
                        <h5 className='smallHeading mb-2'>Service Requested:</h5>
                        <span>{data?.product_name}</span>
                    </div>
                </div>
                <div className="appointInner upcommingAnother mb-4">
                    <div className="contentappont">
                        <h5 className='smallHeading mb-2'>Service Time:</h5>
                        <div className='serviceTime_'>
                            <span><img className='img-fluid clockimg_ me-2' alt="clock" src={clockimg_} />{moment.utc(data?.date).format("dddd ")}  </span> | <span> {moment.utc(data?.date).format("MMMM DD, YYYY")} </span> | <span>{data?.start_time + "-" + data?.end_time}</span></div>
                    </div>
                </div>
                <div className="appointInner mb-2">
                    <div className="contentappont">
                        <span className='smallHeading'>Duration : </span>
                        <span className='timeText'>{data?.approx_service_time + " min"}</span>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <button onClick={() => props.changeStatusOfAppointment(data?.id, 2)} className='saveModifyBtn mt-3' type="submit" disabled={props.loading2}>
                    {props.loading2 && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Confirm Check-in</span>
                </button>
            </div>

        </div>
    )
}

export default CheckinModal;