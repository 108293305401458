import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { searchUser } from '../../../../utilities/images'
import Multiselect from 'multiselect-react-dropdown';
const BroadcastMessage = () => {
    const [options_, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const data = [{ cat: 1, key: "cerulean" },
    { cat: 2, key: "fuchsia rose" },
    { cat: 3, key: "true red" },
    { cat: 4, key: "aqua sky" },
    { cat: 5, key: "tigerlily" },
    { cat: 6, key: "blue turquoise" }];
    const handleSearch = (value) => {
        setLoading(true);
        const results = value ? data.filter(w => w.key.toLowerCase().includes(value)) : []
        setTimeout(r => { setOptions(results); setLoading(false) }, 400, results)
    }
    const handleSelect = (e) => {
    }
    const handleOptionValue = (e) => {
        return (
            <>
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCHkgJPx9vKxK-UV2Oqs_FaBstYEz0JqBb4OXGemg&s" alt="" height="50px" width="50px"/>
            {e}
            </>
        )
    }
    return (
        <>
                <div className='rightMessagebox_'>
                    <div className='messageTophead p-0'>
                        <div className='topcahtSearch'>
                            <h5 className='boldsubHead_'>To:</h5>
                            {/* <input type="search" className="form-control" id="gsearch" name="gsearch"></input> */}
                            <Multiselect
                                displayValue="key"
                                options={options_}
                                onSearch={handleSearch}
                                loading={loading}
                                isObject={true}
                                placeholder='Search here'
                                hidePlaceholder={false}
                                onSelect={handleSelect}
                                onRemove={handleSelect}
                                optionValueDecorator={(e)=>handleOptionValue(e)}
                                selectedValueDecorator={(e)=>handleOptionValue(e)}
                                avoidHighlightFirstOption={true}
                                showCheckbox
                            // style={{
                            //     chips: {
                            //       background: 'red'
                            //     },
                            //     multiselectContainer: {
                            //       color: 'red'
                            //     },
                            //     searchBox: {
                            //       border: 'none',
                            //       'border-bottom': '1px solid blue',
                            //       'border-radius': '0px'
                            //     }
                            //   }}
                            />
                            <img className='searchUser_' src={searchUser} />
                        </div>

                    </div>
                    <div className='broadCastmsg'>
                        <input type="text" className="form-control" placeholder="Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    </div>
                    <div className='message_body'>
                        <div className='chatDatebox_'>
                            <h5 className='chatdeateHeading'>Today | April 20, 2022</h5>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default BroadcastMessage