import React, { useState } from 'react'
import { toast } from "react-toastify";

const CreatefolderModal = (props) => {
    const toastId = React.useRef(null);
    const [folderName, setFolderName] = useState(props?.oldName)

    const handleClick = () => {
        if (!folderName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your folder name")
            }
            return
        }
        if (props?.flag === 'rename') {
            props?.renameFolder(folderName)
        } else if (props?.flag === "renameCatalog") {
            props?.renameCatalog(folderName)
        } else if (props?.flag === "renameFolderInFolder") {
            props?.renameFolderInFolder(folderName)
        } else if (props?.flag === "renameCatalogInFolder") {
            props?.renameCatalogInFolder(folderName)
        } else {
            props?.folderCreate(folderName)
        }
    }
    return (
        <>
            <div className='createFolder commonPadding_'>
                <div className='mainCatalog mb-4'>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>{props?.flag === "renameCatalog" || props?.flag === "renameCatalogInFolder" ? "Catalog Name" : "Folder Name"}</label>
                    <input type='text' className='customform-control' placeholder='Folder Name' value={folderName}
                        onChange={(e) => setFolderName(e.target.value)} />
                </div>
                </div>
                <div className='col-md-12'>
                <div className='footerSec mt-3'>
                    <button className='closebtnModal me-4' onClick={() => props.close()}>Discard</button>
                    <button className='appointBtn' onClick={() => handleClick()} disabled={props.loadingState === true}>{props.loadingState === true && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                        &nbsp;&nbsp;
                        {props?.flag === "renameCatalog" || props?.flag === "renameCatalogInFolder" ? 'Rename Catalog' : props?.flag === 'rename' || props?.flag === "renameFolderInFolder" ? 'Rename Folder' : 'Create folder'}</button>

                </div>
                </div>
            </div>
        </>
    )
}

export default CreatefolderModal