import React, { useEffect, useState } from 'react'
import { smallProduct, tobaco } from '../../../utilities/images'
import DataTable from 'react-data-table-component'
import products from "../../db/catalog.json"
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleProductStatisticDataByIdAsync } from './productSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { selectLoginAuth } from '../auth/authSlice'
import CustomLoader from '../../components/shared/CustomLoader'

const CategoryDetail = () => {
  const history = useHistory();
  const { flag, id } = useParams();
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({})
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [activeTab, setActiveTab] = useState('product1');
  const [activeImage, setActiveImage] = useState("");
  const columns1 = [
    {
      name: '#',
      selector: row => row?.sub_category_id,
      width: "50px"
    },
    {
      name: "Sub Category Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <img className='userInnerImg' src={row?.sub_category_image} />
              <div className='ms-2'>
                <span className='textParagh '>{row?.sub_category_name?.length > 35 ? row?.sub_category_name?.substring(0, 35) + "..." : row?.sub_category_name}</span>
              </div>
            </div>
          </>
        );
      },
      width: "600px",
    },



    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.product_listed}</span>
          </>
        );
      },
      name: 'Product'
    },

    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.brand_listed}</span>
          </>
        );
      },
      name: 'Brand'
    },

  ];
  const columns2 = [
    {
      name: '#',
      selector: row => row?.brand_id,
      width: "50px"
    },
    {
      name: "Brand Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <img className='userInnerImg' src={row?.brand_image} />
              <div className='ms-2'>
                <span className='textParagh '>{row?.brand_name?.length > 30 ? row?.brand_name?.substring(0, 30) + "..." : row?.brand_name}</span>
              </div>
            </div>
          </>
        );
      },
      width: "200px",
    },



    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.category_name?.length > 20 ? row?.category_name?.substring(0, 20) + "..." : row?.category_name}</span>
          </>
        );
      },
      name: 'Category'
    },

    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.product_listed}</span>
          </>
        );
      },
      name: 'Product Listed'
    },

  ];
  const columns3 = [
    {
      name: '#',
      selector: row => row?.id,
      width: "50px"
    },
    {
      name: "Product Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <img className='userInnerImg' src={row?.image} />
              <div className='ms-2'>
                <span className='textParagh '>{row?.name?.length > 25 ? row?.name?.substring(0, 25) + "..." : row?.name}</span>
              </div>
            </div>
          </>
        );
      },
      width: "300px",
    },

    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.sku}</span>
          </>
        );
      },
      name: 'SKU'
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.category?.name?.length > 12 ? row?.category?.name?.substring(0, 12) + "..." : row?.category?.name}</span>
          </>
        );
      },
      name: 'Category'
    },

    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.sub_category?.name?.length > 12 ? row?.sub_category?.name?.substring(0, 12) + "..." : row?.sub_category?.name}</span>
          </>
        );
      },
      name: 'Sub-Category'
    },

    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.brand?.name?.length > 12 ? row?.brand?.name?.substring(0, 12) + "..." : row?.brand?.name}</span>
          </>
        );
      },
      name: 'Brand'
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.supplies ? row?.supplies[0]?.quantity_unit : ""}</span>
          </>
        );
      },
      name: 'Unit'
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.weight}{row?.weight_unit}</span>
          </>
        );
      },
      name: 'Weight'
    },

  ];
  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
  }

  const categoryDataHandle = () => {
    let params = {}
    if (flag === "category") {
      params = {
        "tokenData": auth?.payload?.token,
        "postData": {
          category_id: id
        }
      }
    }
    else if (flag === "sub_category") {
      params = {
        "tokenData": auth?.payload?.token,
        "postData": {
          sub_category_id: id
        }
      }
    }
    else if (flag === "brand") {
      params = {
        "tokenData": auth?.payload?.token,
        "postData": {
          brand_id: id
        }
      }
    }
    setLoading(true)
    dispatch(getSingleProductStatisticDataByIdAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setCategoryData(obj?.payload)
        setActiveImage(obj?.payload?.result?.image[0])
        setLoading(false)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    categoryDataHandle()
  }, [])

  return (
    <>
      {loading === true ?
        // <CustomLoader /> 
        <span className="spinner-border inner-spin spinner-border-sm"></span>
        :
        <div className='categoryDetail spaBeauty_'>
          <div className='topHeader_'>
            <div className="topStickyHeader_">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h2 className="lightsubHead"><i style={{ cursor: "pointer" }} onClick={() => history.goBack()} className="fa-solid fa-chevron-left"></i> {categoryData?.result?.name} </h2>
                </div>

              </div>
            </div>
          </div>
          {
            categoryData ?
              <div className='categoryDetailbox_'>
                <div className='row'>
                  <div className='col-xl-3 col-lg-4'>
                    <div className='productImages'>
                      <img style={{ width: "300px" }} className='prodictView_' src={activeImage} />
                      <ul className='previewList_'>
                        {categoryData?.result?.image?.length > 0 ?
                          categoryData?.result?.image?.map((v, i) => {
                            return (
                              <li key={i} className={`${activeTab == 'product1' ? 'active' : ''} listImage_`} onClick={() => { setActiveTab('product1'); setActiveImage(v) }}><img style={{ width: "80px" }} className='smallproduct' src={v} /> </li>
                            )
                          }) : <></>
                        }
                        {/* <li className={`${activeTab == 'product1' ? 'active' : ''} listImage_`} onClick={() => { setActiveTab('product1') }}><img className='smallproduct' src={smallProduct} /> </li>
                    <li className={`${activeTab == 'product2' ? 'active' : ''} listImage_`} onClick={() => { setActiveTab('product2') }}><img className='smallproduct' src={smallProduct} /> </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-8'>
                    <div className='productImagesdetail'>
                      {flag === "brand" ?
                        <div>
                          <h6 className='textInnerHead'>Category:</h6>
                          <p className='contentheadtext_ mt-2 mb-4'>{categoryData?.products?.data[0]?.category?.name}</p>
                          <h6 className='textInnerHead'>Sub-category:</h6>
                          <p className='contentheadtext_ mt-2 mb-4'>{categoryData?.products?.data[0]?.sub_category?.name}</p>
                        </div> : <></>
                      }
                      <h6 className='textInnerHead'>Details</h6>
                      <p className='contentheadtext_ mt-2 mb-4'>{categoryData?.result?.details}</p>
                      <h6 className='textInnerHead mb-3'>Tags</h6>
                      {categoryData?.result?.tags?.length > 0 ?
                        categoryData?.result?.tags?.map((v, i) => {
                          return (
                            <span key={i} className='highlightSmall'>{v}</span>
                          )
                        }) : "No tags"
                      }
                      {
                        flag === "category" ? <h6 className='textInnerHead mt-4'>Listed Sub-category: {categoryData?.data?.length}</h6> :
                          flag === "sub_category" ? <h6 className='textInnerHead mt-4'>Listed Brands: {categoryData?.data?.length}</h6> :
                            flag === "brand" ? <h6 className='textInnerHead mt-4'>Listed Products: {categoryData?.products?.data?.length}</h6> : ""}
                    </div>
                  </div>
                </div>
              </div> : <></>}
          <div className='categoryTable_ mt-4 table-responsive'>
            <DataTable
              pagination
              columns={flag === "category" ? columns1 : flag === "sub_category" ? columns2 : columns3}
              progressPending={loading}
              data={flag === 'brand' ? categoryData?.products?.data : categoryData?.data}
              defaultSortFieldId={1}
              onRowClicked={handleRowClick}
            />
          </div>
        </div>
      }
    </>
  )
}

export default CategoryDetail