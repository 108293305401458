import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom'
import { calendar } from '../../../../utilities/images';
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component'
import { adjustInventoryApiAsync, getLowStockInventoryAsync, getProductServiceListApiAsync, updateInventoryApiAsync } from '../../myProducts/productSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import CustomModal from '../../../components/shared/CustomModal';
import UpdateinventoryModal from './updateinventoryModal';
import InventoryadjustModal from './inventoryadjustModal';
import InventoryaddnewModal from './inventoryaddnewModal';

const LowstockInventory = () => {
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("");
    const [myValue, setMyValue] = useState('');
    const [key, setKey] = useState(Math.random());
    const [stockData, setStockData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingButton, setLoadingButton] = useState(false);
    const [id, setId] = useState("");
    const [option, setOption] = useState("");
    const [quantity, setQuantity] = useState("");
    const [reference, setReference] = useState("");
    const [date, setDate] = useState("");
    const [reason, setReason] = useState("");

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const handleDetails = (flag) => {
        if (flag == "InventoryaddnewModal") {
            setModalDetail({ show: true, flag: "InventoryaddnewModal" });
            setKey(Math.random());
        }
    }
    
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };
    
      const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
      };
    const columns1 = [
        {
            name: '#',
            selector: row => row.id,
            width: "50px"
        },
        {
            name: "Product Name",
            selector: row => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row.image} />
                            <div className='ms-2'>
                                <span className='textParagh listhead_'>{row?.name}</span>
                            </div>
                        </div>
                    </>
                );
            },
            width: "400px",
        },
        {
            name: 'Stock',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.supplies ? row?.supplies[row?.supplies?.length - 1]?.rest_quantity : ""}</span>
                    </>
                );
            },
            width: "100px",
        },
        {
            name: 'Re-Order level',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.supplies ? row?.supplies[row?.supplies?.length - 1]?.quantity_reorder_point : ""}</span>
                    </>
                );
            },
            width: "170px",
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.category?.name?.length > 20 ? row?.category?.name?.substring(0, 20) + "..." : row?.category?.name}</span>
                    </>
                );
            },
            width: "150px",
            name: 'Category'
        },
        {
            name: 'Sub-Category',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.sub_category?.name?.length > 20 ? row?.sub_category?.name?.substring(0, 20) + "..." : row?.sub_category?.name}</span>
                    </>
                );
            },
            width: "150px",
        },

        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.brand?.name?.length > 20 ? row?.brand?.name?.substring(0, 20) + "..." : row?.brand?.name}</span>
                    </>
                );
            },
            name: 'Brand',
            width: "200px",
        },
        {
            selector: row => {
                return (
                    <>
                        <div className='d-flex justify-space-between align-items-center'>
                            <span className='textParagh '>${row?.price}</span>
                            <div className='dropdown tableDropdown ms-3 '>
                                <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                                    <i className="fal fa-ellipsis-v icon_"></i>
                                    {/* <img src={dots} alt=""/> */}
                                </span>
                                <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                                    <li className='dropdown-item' onClick={() => history.push(`/inventoryviewDetail/${row?.id}/lowstockInventory`)}>View Details</li>
                                    {/* <li className='dropdown-item'>History</li> */}
                                    <li className='dropdown-item' onClick={() => {
                                        setSelectedProduct(row)
                                        setModalDetail({ show: true, flag: "UpdateinventoryModal" });
                                        setKey(Math.random());
                                    }}>Update Inventory</li>
                                    <li className='dropdown-item' onClick={() => {
                                        setSelectedProduct(row)
                                        setModalDetail({ show: true, flag: "InventoryadjustModal" });
                                        setKey(Math.random());
                                    }}>Adjust Inventory</li>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
            name: 'Price',
            width: "100px"
        },
    ];

    const updateInventoryData = (id, quantity, option) => {
        let params = {
            "postData": {
                id: id,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "bodyData": {
                total_quantity: quantity,
                quantity_unit: 'packet',
            },
            "tokenData": auth?.payload?.token
        }
        setLoadingButton(true)
        dispatch(updateInventoryApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    tokenData: auth?.payload?.token,
                    postData: {
                        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                        type: "physical",
                        app_name: "merchant",
                        filter_deleted: true,
                        need_low_stock: true
                    }
                }
                dispatch(getProductServiceListApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoadingButton(false)
                        setStockData(obj?.payload?.data)
                        handleOnCloseModal()
                    })
                    .catch((obj) => {
                        setLoadingButton(false)
                    })
            }
            )
            .catch((obj) => {
                setLoadingButton(false)
            })
    }

    const adjustInventoryHandle = (id, quantity, option, reason, date, reference) => {
        let params = {
            "postData": {
                'id': id,
                'adjusted_date': moment(date).format('YYYY-MM-DD'),
                'adjusted_reason': reason,
                'adjusted_quantity': quantity,
                'references_number': reference,
                'adjustment_mode': option
            },
            "tokenData": auth?.payload?.token
        }
        setLoadingButton(true)
        dispatch(adjustInventoryApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    tokenData: auth?.payload?.token,
                    postData: {
                        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                        type: "physical",
                        app_name: "merchant",
                        filter_deleted: true,
                        need_low_stock: true
                    }
                }
                dispatch(getProductServiceListApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoadingButton(false)
                        setStockData(obj?.payload?.data)
                        handleOnCloseModal()
                    })
                    .catch((obj) => {
                        setLoadingButton(false)
                    })
            }
            )
            .catch((obj) => {
                setLoadingButton(false)
            })
    }

    const inventoryUpdateData = (a, b, c, flag) => {
        setId(a)
        setQuantity(b)
        setOption(c)
        setModalDetail({ show: true, flag: flag });
        setKey(Math.random());
    }

    const inventoryAdjustedData = (a, b, c, d, e, f, flag) => {
        setId(a)
        setQuantity(b)
        setOption(c)
        setReason(d)
        setDate(e)
        setReference(f)
        setModalDetail({ show: true, flag: flag });
        setKey(Math.random());
    }

    const getLowStockData = () => {
        let params = {
            "postData": {
                type: "physical",
                app_name: "merchant",
                need_low_stock: true,
                lat: 32.5534,
                long: 76.1258,
                page: currentPage,
                limit: perPage,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        else {
            params = {
                "postData": {
                    seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                    type: "physical",
                    app_name: "merchant",
                    lat: 32.5534,
                    long: 76.1258,
                    need_low_stock: true,
                    page: currentPage,
                    limit: perPage
                },
                "tokenData": auth?.payload?.token
            }
        }
        setLoading(true)
        dispatch(getLowStockInventoryAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setStockData(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getLowStockData()
    }, [endDate, perPage, currentPage])
    return (
        <div className='lowstockInventory_'>
            <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">Inventory / <span className="innerSubtext">Low Inventories </span> </h2>
                        </div>

                    </div>
                </div>
            </div>
            <div className='tableFilters'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="calenderIcon">
                            <img src={calendar} alt="calender" />
                            {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                            <DatePicker
                                className='cateDate'
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText="Date"
                            />
                        </div>
                    </div>
                    {/* <div className='col-md-3'>
                        <select className="cateSelectdFilter" value={myValue} onChange={(e) => setMyValue(e.target.value)} required>
                            <option  >Country </option>
                            <option  >Category1</option>
                            <option  >Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" value={myValue} onChange={(e) => setMyValue(e.target.value)} required>
                            <option  >State </option>
                            <option  >Category1</option>
                            <option  >Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" value={myValue} onChange={(e) => setMyValue(e.target.value)} required>
                            <option  >Id type </option>
                            <option  >Category1</option>
                            <option  >Category2</option>
                        </select>
                    </div> */}
                </div>
            </div>
            {/* {

                loading === true ?
                    <span className="spinner-border inner-spin spinner-border-sm serviceList"></span>
                    : */}
                    <section className='recentTransections commonTableheight mt-1 table-responsive'>
                        {/* <h3 className='textInnerHead mb-4'>Recent Orders</h3> */}
                        <DataTable
                            pagination
                            columns={columns1}
                            data={stockData}
                            defaultSortFieldId={1}
                            progressPending={loading}
                            paginationServer
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            paginationTotalRows={totalRows}
                            paginationPerPage={perPage}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                        />
                    </section>
{/* } */}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "UpdateinventoryModal" ? "commonWidth customContent" : "InventoryadjustModal" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "UpdateinventoryModal" ? "updateinventoryModal" : "InventoryadjustModal" ? "inventoryadjustModal" : ""}

                child={

                    modalDetail.flag === "UpdateinventoryModal" ? (
                        <UpdateinventoryModal inventoryUpdateData={(a, b, c, flag) => inventoryUpdateData(a, b, c, flag)} data={selectedProduct} close={() => handleOnCloseModal()} details={e => handleDetails(e)} />
                    ) :
                        modalDetail.flag === "InventoryadjustModal" ? (
                            <InventoryadjustModal inventoryAdjustedData={(a, b, c, d, e, f, flag) => inventoryAdjustedData(a, b, c, d, e, f, flag)} data={selectedProduct} close={() => handleOnCloseModal()} details={e => handleDetails(e)} />
                        ) :
                            modalDetail.flag === "InventoryaddnewModal" ?
                                <InventoryaddnewModal loading={loadingButton} updateInventory={(a, b, c) => updateInventoryData(a, b, c)} adjustInventory={(a, b, c, d, e, f) => adjustInventoryHandle(a, b, c, d, e, f)} id={id} options={option} quantity={quantity} date={date} reason={reason} reference={reference} close={() => handleOnCloseModal()} /> :
                                <></>
                }
                header={
                    <>
                        <div className='modalHeader_'>
                            <div className="common_">
                                {modalDetail.flag === "UpdateinventoryModal" ?
                                    <h2 className="subheading2_">Add New Inventory</h2>
                                    :
                                    modalDetail.flag === "InventoryadjustModal" ?
                                        <h2 className="subheading2_">Add New Inventory</h2>
                                        :
                                        modalDetail.flag === "InventoryaddnewModal" ?
                                            <h2 className="subheading2_">Adding New Inventory</h2>
                                            :

                                           ""
                                }
                                <button className="closeButton">
                                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                </button>
                            </div>
                        </div>
                    </>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default LowstockInventory