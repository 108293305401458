import React, { useEffect, useMemo, useState } from 'react'
import { productList1, productList2, productList3, productList4, profileImg1 } from '../../../../utilities/images';
import DataTable from 'react-data-table-component'
import { calendar, user, locationBlue, moneyIcon } from '../../../../utilities/images'
import { Link } from 'react-router-dom';
import { changeAppointmentStatusApiAsync, getAppointemntApiAsync } from '../../dashboard/dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { Calendar, Views, globalizeLocalizer, momentLocalizer, DateLocalizer } from 'react-big-calendar'
import CustomModal from '../../../components/shared/CustomModal';
import AppointSchedule from '../todayAppointments/appointSchedule';

function UpcomingDetails() {
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [filterValue, setFilterValue] = useState("month");
    const [singleAppointment, setSingleAppointment] = useState("");
    const [appointmentData, setAppointmentData] = useState([]);
    const [calenderData, setCalenderData] = useState([]);
    const [event, setEvent] = useState([]);
    const [activeTab, setActiveTab] = useState("tableView");
    const startOfweek = new Date();
    const endOfWeek = new Date(new Date().setDate(new Date().getDate() + 7))
    const endOfMonth = new Date(new Date().setDate(new Date().getDate() + 30))
    const defaultDate = useMemo(() => new Date(2015, 3, 1), [])
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };
    const columns1 = [
        {
            name: '#',
            width: '100px',
            selector: row => row?.id

        },
        {
            name: "Service Name",
            selector: row => row?.product_name
        },
        {
            name: "User",
            width: "400px",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.user_details?.profile_photo ? row?.user_details?.profile_photo : profileImg1} />
                            <div className='ms-2 text-start'>
                                <span className='contentSubtext_ '>{row?.user_details?.firstname + " " + row?.user_details?.lastname}</span>
                                <p className='mb-0'>
                                    <img src={locationBlue} className='img-fluid me-2' />
                                    <span className='textInner'>{row?.user_details?.current_address?.city},{row?.user_details?.current_address?.country}</span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
        },

        {
            name: 'Date',
            width: '300px',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <p className='textInner mt-2'>
                                <span className='contentSubtext_ me-3 ms-3'>{moment(row?.date).format("dddd MMM DD, YYYY")}</span>
                                {row?.start_time + "-" + row?.end_time}</p>
                        </div>
                    </>
                );
            },
        },


        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div className='ms-2 tableBtn__'>
                            <Link to='#' className={row?.date == "28 Sep, 2022" ? "greyButton_ upcoming_active" : "greyButton_"}> {row?.date == "28 Sep, 2022" ? "Done" : "Next"} </Link>

                        </div>
                    </>
                );
            },

        },

        // {
        //     name: '',
        //     selector: row => {
        //         return (
        //             <>
        //                 <div className='ms-2'>
        //                     <i className="fal fa-chevron-right ms-3" aria-hidden="true"></i>
        //                 </div>
        //             </>
        //         );
        //     },
        //     width: "50px",
        // },
    ];

    const CustomRow = ({ data }) => {
        return (
            <div>
                <p>This is a custom row element.</p>
                <p>ID: {data.id}</p>
                <p>Date: {data.date}</p>
            </div>
        );
    };

    const customRowStyles = [
        {
            when: (row) => (row.id == 2 || row.id == 5), // Condition to apply the custom row style
            style: {
                backgroundColor: '#e5f0ff4d',
            },
            classNames: 'custom-row-className',
            component: <CustomRow />, // Custom row component to render
        },
    ];
    const localizer = momentLocalizer(moment)

    const changeStatusOfAppointmentHandle = (id, status, modalIs) => {
        let orderStatus = {
            "postData": {
                id: id,
                status: status
            },
            "tokenData": auth?.payload?.token
        }
        status === 3 ? setLoading1(true) : setLoading2(true)
        dispatch(changeAppointmentStatusApiAsync(orderStatus))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    "postData": {
                        status: 1,
                        need_upcoming: true,
                        filter_by: activeTab === 'tableView' ? filterValue : 'month',
                        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getAppointemntApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setAppointmentData(obj?.payload?.data)
                        let newState = obj?.payload?.data?.map(obj => {
                            return ({ ...obj, start: moment(obj?.start_date_time).format('YYYY-MM-DD HH:mm:ss'), end: moment(obj?.end_date_time).format('YYYY-MM-DD HH:mm:ss'), title: obj?.product_name })
                        });
                        setEvent(newState);
                        setCalenderData(obj?.payload?.data)
                        setLoading1(false)
                        setLoading2(false)
                        setModalDetail({ show: false, flag: "" });
                        setKey(Math.random());
                    }
                    )
                    .catch((obj) => {
                        setLoading1(false)
                        setLoading2(false)
                    })

            }
            )
            .catch((obj) => {
                setLoading1(false)
                setLoading2(false)
            })
    }
    const handleRowClick = (e) => {
        setSingleAppointment(e)
        setModalDetail({
            show: true,
            flag: "AppointSchedule"
        });
        setKey(Math.random());
    }
    // const allViews = Object.keys(Calendar.Views).map(k => Calendar.Views[k]);

    const handleSelectedEvent = (event) => {
        setSingleAppointment(event)
        setModalDetail({
            show: true,
            flag: "AppointSchedule"
        });
        setKey(Math.random());
    };
    const handleAppointmentData = () => {
        let params = {
            "postData": {
                status: 1,
                need_upcoming: true,
                filter_by: activeTab === 'tableView' ? filterValue : 'month',
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getAppointemntApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAppointmentData(obj?.payload?.data)
                let newState = obj?.payload?.data?.map(obj => {
                    return ({ ...obj, start: new Date(moment(obj?.start_date_time).format('YYYY-MM-DD HH:mm:ss')), end: new Date(moment(obj?.end_date_time).format('YYYY-MM-DD HH:mm:ss')), title: obj?.product_name })
                });
                setEvent(newState);
                setCalenderData(obj?.payload?.data)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        handleAppointmentData()
    }, [filterValue, activeTab])

    return (
        <>
            <div className='upcomingDetail_'>
                <div className='topHeader_'>
                    <div className="topStickyHeader_">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">Appointments / <span className="innerSubtext">Upcoming Appointments</span> </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="apoointmidHead_ mb-4">
                    <div className='apointUpcomdate'>
                        <p className='dateapoint m-0'><i className="fas fa-chevron-left"></i> <span className='me-2 ms-2'>{filterValue === 'week' ?
                            moment(startOfweek).format("MMM DD") + " - " + moment(endOfWeek).format("MMM DD, YYYY") : filterValue === 'month' ?
                                moment(startOfweek).format("MMM DD") + " - " + moment(endOfMonth).format("MMM DD, YYYY")
                                : moment(startOfweek).format("MMMM DD, YYYY")}</span> <i className="fas fa-chevron-right"></i></p>
                    </div>
                    {
                        activeTab === 'tableView' ?
                            <div className='weekcount'>
                                <button onClick={() => setFilterValue('today')} className={`daysDetail_ ${filterValue === 'today' ? 'active' : ""}`}>Day</button>
                                <button onClick={() => setFilterValue('week')} className={`daysDetail_ ${filterValue === 'week' ? 'active' : ""}`}>Week</button>
                                <button onClick={() => setFilterValue('month')} className={`daysDetail_ ${filterValue === 'month' ? 'active' : ""}`}>Month</button>
                            </div> : <></>
                    }
                    <div className='productListTab upcomingTab_ pt-0'>
                        <button className={`plansBtns ${activeTab === 'tableView' ? 'active' : ""}`}
                            onClick={() => setActiveTab('tableView')}
                        >
                            <img src={productList1} alt="image" className='showImage' />
                            <img src={productList2} alt="image" className='hideImage' />
                        </button>
                        <button className={`plansBtns ${activeTab === 'cartView' ? 'active' : ""}`}
                            onClick={() => setActiveTab('cartView')}>
                            <img src={productList3} alt="image" className='hideImage' />
                            <img src={productList4} alt="image" className='showImage' />
                        </button>
                    </div>
                </div>
            </div>
            {
                loading === true ?
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                    :
                    <>
                        {
                            activeTab === 'tableView' ?
                                <section className='recentTransections mt-5 table-responsive'>
                                    <DataTable
                                        pagination
                                        columns={columns1}
                                        customStyles={customStyles}
                                        data={appointmentData}
                                        defaultSortFieldId={1}
                                        className="refundOrders"
                                        conditionalRowStyles={customRowStyles}
                                        onRowClicked={(e) => handleRowClick(e)}
                                    />
                                </section> :
                                <Calendar
                                    localizer={localizer}
                                    events={event}
                                    startAccessor="start"
                                    titleAccessor="title"
                                    endAccessor="end"
                                    views={["day", "week", "month"]}
                                    // views={allViews}
                                    style={{ height: 600 }}
                                    popup
                                    defaultDate={startOfweek}
                                    onSelectEvent={(events) => handleSelectedEvent(events)}
                                />
                        }</>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "AppointSchedule" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "AppointSchedule" ? "AppointSchedule" : ""}
                child={modalDetail.flag === "AppointSchedule" ? (
                    <AppointSchedule stateLoading1={loading1} statusChange={(a, b, c) => changeStatusOfAppointmentHandle(a, b, c)} data={singleAppointment} close={() => handleOnCloseModal()} />
                ) : ""}
            />
        </>
    )
}

export default UpcomingDetails                  