import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  suplier,
  locationBlue,
  downlight,
  profileImg1,
} from "../../../utilities/images";
import products from "../../db/category.json";
import ChartArea from "../dashboard/wallet/ChartArea";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import {
  changeStatusApiAsync,
  getInventoryCountApiAsync,
  getPurchaseGraphApiAsync,
  getPurchaseListApiAsync,
} from "../dashboard/dashboardSlice";
import moment from "moment-timezone";
import CustomModal from "../../components/shared/CustomModal";
// import PendingInvoiceModal from "../myOrders/newOrders/pendingInvoiceModal";
import NewpurchaseInvoice from "./newPurchase/newpurchaseInvoice";
import { getLowStockInventoryAsync } from "../myProducts/productSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { addThousandSeparator } from "../../../utilities/thousandCommaSeparated";

const MyPurchases = () => {
  const history = useHistory();
  const [filterrowue, setFilterrowue] = useState("This week");
  const [stockData, setStockData] = useState([]);
  const [purchaseData, setPurchaseData] = useState("");
  const [singleOrderData, setSingleOrderData] = useState({});
  const [purchaseList, setPurchaseList] = useState([]);
  const [countData, setCountData] = useState([]);
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [topPurchaseData, setTopPurchaseData] = useState([]);
  const [checked, setChecked] = useState({
    Booking: true,
    Cancelled: true,
  });

  let [dataSets, setDataSets] = useState([
    {
      fill: true,
      label: "Booking",
      data: purchaseData?.graphData?.datasets
        ? purchaseData?.graphData?.datasets[0]
        : "",
      borderColor: "#275AFF",
    },
    {
      fill: true,
      label: "Cancelled",
      data: purchaseData?.graphData?.datasets
        ? purchaseData?.graphData?.datasets[1]
        : "",
      borderColor: "#FB466C",
    },
  ]);
  const handelDataSetChange = (e, value, num, color) => {
    console.log(value, e.target.checked);
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: purchaseData?.graphData?.datasets
            ? purchaseData?.graphData?.datasets[num]
            : "",
          borderColor: color,
        },
      ]);
    } else {
      setDataSets(dataSets.filter((item) => item.label !== value));
    }
    console.log(dataSets);
  };

  // remove this
  // auth.payload.uniqe_id = "016b1b3a-d7d3-4fc3-a76b-995b23c43852"
  const columns1 = [
    {
      name: "item",
      selector: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <img className="userInnerImg" src={row.image} />
              <div className="ms-2">
                <span className="textParagh listhead_">{row?.name}</span>
              </div>
            </div>
          </>
        );
      },
      width: "auto",
    },
    {
      selector: (row) => {
        return (
          <>
            <div className="d-flex justify-space-between align-items-center">
              <span className="textParagh ">{row?.sku ? row?.sku : ""}</span>
            </div>
          </>
        );
      },
      name: "UPC",
      width: "auto",
    },
    {
      name: "Stock",
      selector: (row) => {
        return (
          <>
            <span className="textParagh textRed">
              {row?.supplies
                ? row?.supplies[row?.supplies?.length - 1]?.rest_quantity
                : ""}
            </span>
          </>
        );
      },
      width: "auto",
    },
    {
      name: "Stock Reoder",
      selector: (row) => {
        return (
          <>
            <span className="textParagh ">
              {row?.supplies
                ? row?.supplies[row?.supplies?.length - 1]
                    ?.quantity_reorder_point
                : ""}
            </span>
          </>
        );
      },
      width: "auto",
    },
    {
      selector: (row) => {
        return (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <span className="textParagh ">
                {" "}
                ${addThousandSeparator((row?.price).toFixed(2))}
              </span>
            </div>
          </>
        );
      },
      name: "Cost Price/Unit",
      width: "auto",
    },
  ];

  const getLowStockData = () => {
    let params = {
      postData: {
        type: "physical",
        app_name: "merchant",
        need_low_stock: true,
        lat: 32.5534,
        long: 76.1258,
        page: "1",
        limit: "5",
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "8e29389f-7987-453b-9389-a1ad6bea25db",
      },
      tokenData: auth?.payload?.token,
    };

    params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "8e29389f-7987-453b-9389-a1ad6bea25db",
        type: "physical",
        app_name: "merchant",
        lat: 32.5534,
        long: 76.1258,
        need_low_stock: true,
        page: currentPage,
        limit: perPage,
      },
      tokenData: auth?.payload?.token,
    };

    setLoading(true);
    dispatch(getLowStockInventoryAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setStockData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleFilterrowue = (rowue) => {
    setFilterrowue(rowue);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top", // Positioning the legend at the top
        align: "start", // Aligning the legend to start (left)
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // const options1 = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //     },
  //   },
  // };

  const serviceSalesData = {
    labels: purchaseData?.graphData?.labels,
    datasets: dataSets,
  };

  const lowStockData = {
    labels: ["Low Stock", "Others"],
    datasets: [
      {
        label: "low Stock",
        data: countData ? [countData[0]?.value, countData[0]?.percentage] : "",
        backgroundColor: ["#D33300", "#EFEFEF"],
        borderColor: ["#D33300", "#EFEFEF"],
        borderColor: ["#D33300", "#EFEFEF"],
        borderWidth: 1,
      },
    ],
  };
  const averageStockData = {
    labels: ["Average Stock", "Others"],
    datasets: [
      {
        label: "Average",
        data: countData ? [countData[1]?.value, countData[1]?.percentage] : "",
        backgroundColor: ["#6C63F0", "#EFEFEF"],
        borderColor: ["#6C63F0", "#EFEFEF"],
        borderWidth: 1,
      },
    ],
  };
  const damageData = {
    labels: ["Damage", "Others"],
    datasets: [
      {
        label: "Damage",
        data: countData ? [countData[2]?.value, countData[2]?.percentage] : "",
        backgroundColor: ["#FCBA30", "#EFEFEF"],
        borderColor: ["#FCBA30", "#EFEFEF"],
        borderWidth: 1,
      },
    ],
  };
  // const avgCreditFacility = {
  //   labels: ["Avg Credit Facility", "Others"],
  //   datasets: [
  //     {
  //       label: "Avg Credit Facility",
  //       data: countData ? [countData[3]?.value, countData[3]?.percentage] : "",
  //       backgroundColor: ["#50BB87", "#EFEFEF"],
  //       borderColor: ["#50BB87", "#EFEFEF"],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  const supplyCycle = {
    labels: ["Supply Cycle", "Others"],
    datasets: [
      {
        label: "Supply Cycle",
        data: countData ? [countData[4]?.value, countData[4]?.percentage] : "",
        backgroundColor: ["#FB466C", "#EFEFEF"],
        borderColor: ["#FB466C", "#EFEFEF"],
        borderWidth: 1,
      },
    ],
  };
  const refundData = {
    labels: ["Refund", "Others"],
    datasets: [
      {
        label: "Refund",
        data: countData ? [countData[5]?.value, countData[5]?.percentage] : "",
        backgroundColor: ["#A084CF", "#EFEFEF"],
        borderColor: ["#A084CF", "#EFEFEF"],
        borderWidth: 1,
      },
    ],
  };
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const handleRowClick = (e) => {
    setSingleOrderData(e);
    setModalDetail({ show: true, flag: "PendingInvoiceModal" });
    setKey(Math.random());
  };

  const changeStatusOfPurchaseHandle = (id, status) => {
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(changeStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            seller_id: auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid,
            // seller_id: "8e29389f-7987-453b-9389-a1ad6bea25db",

            page: currentPage,
            limit: perPage,
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getPurchaseListApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setPurchaseList(obj?.payload?.data);
            setTotalRows(obj?.payload?.total);
            setLoading1(false);
            handleOnCloseModal();
          })
          .catch((obj) => {
            setLoading1(false);
          });
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  const getPurchaseListApiAsyncHandler = () => {
    let data = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,

        need_top_purchases: true,
        limit: "5",
        filter:
          filterrowue === "This week" ? "week" : filterrowue?.toLowerCase(),
        // seller_id: "8e29389f-7987-453b-9389-a1ad6bea25db",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPurchaseListApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setTopPurchaseData(obj?.payload?.data);
        console.log(obj.payload.data);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const inventoryCountHandle = () => {
    let params = {
      postData: {
        filter:
          filterrowue === "This week" ? "week" : filterrowue?.toLowerCase(),
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "8e29389f-7987-453b-9389-a1ad6bea25db",
        need_purchases: true,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getInventoryCountApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        console.log("countData", countData, filterrowue);
        setCountData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const purchaseDataHandle = () => {
    let params = {
      postData: {
        filter:
          filterrowue === "This week" ? "week" : filterrowue?.toLowerCase(),
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "8e29389f-7987-453b-9389-a1ad6bea25db",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPurchaseGraphApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setPurchaseData(obj?.payload);
        setDataSets([
          {
            fill: true,
            label: "Booking",
            data: obj?.payload?.graphData?.datasets
              ? obj?.payload?.graphData?.datasets[0]
              : "",
            borderColor: "#275AFF",
          },
          {
            fill: true,
            label: "Cancelled",
            data: obj?.payload?.graphData?.datasets
              ? obj?.payload?.graphData?.datasets[1]
              : "",
            borderColor: "#FB466C",
          },
        ]);
        setLoading(false);
        setChecked({
          Booking: true,
          Cancelled: true,
        });
      })

      .catch((obj) => {
        setLoading(false);
      });
  };

  const purchaseListHandle = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "8e29389f-7987-453b-9389-a1ad6bea25db",
        page: currentPage,
        limit: perPage,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPurchaseListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setPurchaseList(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    purchaseDataHandle();
    purchaseListHandle();
    inventoryCountHandle();
    getPurchaseListApiAsyncHandler();
    getLowStockData();
    console.log("stockData", stockData);
    console.log("topPurchaseData", topPurchaseData);
    console.log(auth.payload.token, "sellerId", auth?.payload?.uniqe_id);
  }, [filterrowue, currentPage, perPage]);

  return (
    <div className="myOrdersmain">
      <DashboardNavbar title="Store Purchases Overview" show={true} />
      <div className="row mb-3">
        <div className="col-md-12 text-end">
          <div className="dropdown weekDropdown">
            <button
              className="dropdown-toggle cateSelectd"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {filterrowue} <img src={downlight} alt="img" />
            </button>
            <ul className="dropdown-menu weekDropdownMenus">
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => handleFilterrowue("Today")}
                >
                  Today
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => handleFilterrowue("This week")}
                >
                  This week
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => handleFilterrowue("Month")}
                >
                  {" "}
                  Month
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => handleFilterrowue("Year")}
                >
                  {" "}
                  Year
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="purchaseStats_">
        <div className="chartsOuter">
          <div className="row row-cols-5">
            <div className="col">
              <div className="stockPurchase">
                <h6 className="textinner_ mb-2">
                  {countData ? countData[0]?.name : ""}
                </h6>
                <ChartArea
                  classes=""
                  options={options1}
                  data={lowStockData}
                  chartType="Doughnut"
                />
                {/* <img className='imgStock' src={imageStock} /> */}
              </div>
            </div>
            <div className="col">
              <div className="stockPurchase">
                <h6 className="textinner_ mb-2">
                  {countData ? countData[1]?.name : ""}
                </h6>
                <ChartArea
                  classes=""
                  options={options1}
                  data={averageStockData}
                  chartType="Doughnut"
                />
                {/* <img className='imgStock' src={imageStock2} /> */}
              </div>
            </div>
            <div className="col">
              <div className="stockPurchase">
                <h6 className="textinner_ mb-2">
                  {countData ? countData[2]?.name : ""}
                </h6>
                <ChartArea
                  classes=""
                  options={options1}
                  data={damageData}
                  chartType="Doughnut"
                />
                {/* <img className='imgStock' src={imageStock3} /> */}
              </div>
            </div>
            {/* <div className="col-md-2">
              <div className="stockPurchase">
                <h6 className="textinner_ mb-2">
                  {countData ? countData[3]?.name : ""}
                </h6>
                <ChartArea
                  classes=""
                  options={options1}
                  data={avgCreditFacility}
                  chartType="Doughnut"
                />
              </div>
            </div> */}
            <div className="col">
              <div className="stockPurchase">
                <h6 className="textinner_ mb-2">
                  {countData ? countData[4]?.name : ""}
                </h6>
                <ChartArea
                  classes=""
                  options={options1}
                  data={supplyCycle}
                  chartType="Doughnut"
                />
                {/* <img className='imgStock' src={imageStock5} /> */}
              </div>
            </div>
            <div className="col">
              <div className="stockPurchase">
                <h6 className="textinner_ mb-2">
                  {countData ? countData[5]?.name : ""}
                </h6>
                <ChartArea
                  classes=""
                  options={options1}
                  data={refundData}
                  chartType="Doughnut"
                />
                {/* <img className='imgStock' src={imageStock} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tradesrowue mt-4 mb-4">
        <div className="row">
          <div className="col-md-12">
            <div className="chartsOuter">
              <h3 className="textInnerHead mt-3">Cost of Purchases</h3>
              <div className="graphChecked_ mt-3 mb-2 ">
                <div className="graphBox_ me-3">
                  <input
                    type="checkbox"
                    id="custom-checkbox-selectAll2"
                    className="checkBox "
                    name="custom-checkbox-selectAll"
                    value="custom-checkbox-selectAll"
                    onChange={(e) => {
                      handelDataSetChange(e, "Booking", 0, "#275AFF");
                      setChecked({
                        ...checked,
                        Booking: checked.Booking === true ? false : true,
                      });
                    }}
                    checked={checked.Booking}
                  />
                  <label
                    htmlFor="custom-checkbox-selectAll2"
                    className="checkBoxTxt masterChecked"
                  >
                    Booking
                  </label>
                </div>
                <div className="graphCheck_ me-3">
                  <input
                    type="checkbox"
                    id="custom-checkbox-selectAll3"
                    className="checkBox "
                    name="custom-checkbox-selectAll"
                    value="custom-checkbox-selectAll"
                    checked={checked.Cancelled}
                    onChange={(e) => {
                      handelDataSetChange(e, "Cancelled", 1, "#FB466C");
                      setChecked({
                        ...checked,
                        Cancelled: checked.Cancelled === true ? false : true,
                      });
                    }}
                  />
                  <label
                    htmlFor="custom-checkbox-selectAll3"
                    className="checkBoxTxt masterChecked"
                  >
                    Cancelled
                  </label>
                </div>
              </div>
              <div className="row mt-4 mb-4 align-items-center">
                <div className="col-md-12">
                  <ChartArea
                    classes="productSales mt-2 mb-2"
                    header="Services Sales"
                    options={options}
                    data={serviceSalesData}
                    chartType="Line"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="topProducts_ mb-4">
        <div className="row">
          <div className="col-xl-6 col-lg-12">
            <div className="chartsOuter">
              <h5 className="dollarSign mb-3">Top Purchasing Items</h5>
              <div className="topItemsection dFlex">
                <div className="itemSale_">
                  <h4 className="itemsText">Items</h4>
                </div>
                <div className="unitSale justify-content-around">
                  <h4 className="itemsText ">Unit Purchased</h4>
                  <h4 className="itemsText ">Total Cost</h4>
                </div>
              </div>
              <div className="countriesDetails mt-3">
                {topPurchaseData?.length > 0 ? (
                  <>
                    {topPurchaseData?.map((row, index) => {
                      return (
                        <div className="countryInnerderail topItemsection dFlex">
                          <div className="itemSale_">
                            <span className="serialNo">{index + 1}</span>
                            <div className="countryCount dFlex">
                              <img
                                className="brandImg"
                                style={{ width: "50px", height: "40px" }}
                                src={
                                  row?.order_details[0]?.product_image
                                    ? row?.order_details[0]?.product_image
                                    : profileImg1
                                }
                              />
                              <div className="countryContent ms-2">
                                <h4 className="mainlightHead">
                                  {row?.order_details[0]?.product_name}
                                </h4>
                                <h5 className="tableHeadsmall">
                                  {row?.sellerDetails?.user_profiles
                                    ?.current_address?.city +
                                    "," +
                                    row?.sellerDetails?.user_profiles
                                      ?.current_address?.country}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="unitSale justify-content-around">
                            <h5 className="itemsText ms-3">
                              {row?.order_details[0]?.qty
                                ? row?.order_details[0]?.qty
                                : 1}
                            </h5>
                            <h5 className="itemsText">
                              $
                              {row?.order_details[0]?.price
                                ? addThousandSeparator(
                                    (row?.order_details[0]?.price).toFixed(2)
                                  )
                                : ""}
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  "No Purchasing Items Found"
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12">
            <div className="chartsOuter">
              <h5 className="dollarSign mb-3">
                Top Suppliers/Wholesalers/Manufacfurers
              </h5>
              <div className="topItemsection dFlex">
                <div className="itemSale_">
                  <h4 className="itemsText">Category</h4>
                </div>
                <div className="unitSale justify-content-around">
                  <h4 className="itemsText ">Unit Purchased</h4>
                  <h4 className="itemsText ">Total Cost</h4>
                </div>
              </div>
              <div className="countriesDetails mt-3">
                {topPurchaseData?.length > 0 ? (
                  <>
                    {topPurchaseData?.map((row, index) => {
                      return (
                        <div className="countryInnerderail topItemsection dFlex">
                          <div className="itemSale_">
                            <span className="serialNo">{index + 1}</span>
                            <div className="countryCount  dFlex">
                              <img
                                className="brandImg"
                                style={{ width: "50px", height: "40px" }}
                                src={
                                  row?.sellerDetails?.user_profiles
                                    ?.profile_photo
                                    ? row?.sellerDetails?.user_profiles
                                        ?.profile_photo
                                    : profileImg1
                                }
                              />
                              <div className="countryContent ms-2">
                                <h4 className="mainlightHead">{`${
                                  row?.sellerDetails?.user_profiles?.firstname
                                    ? row?.sellerDetails?.user_profiles
                                        ?.firstname
                                    : ""
                                } ${
                                  row?.sellerDetails?.user_profiles?.lastname
                                    ? row?.sellerDetails?.user_profiles
                                        ?.lastname
                                    : "  "
                                }`}</h4>
                              </div>
                            </div>
                          </div>
                          <div className="unitSale justify-content-around">
                            <h5 className="itemsText ms-3">
                              {row?.order_details[0]?.qty
                                ? row?.order_details[0]?.qty
                                : 1}
                            </h5>
                            <h5 className="itemsText">
                              $
                              {row?.order_details[0]?.price
                                ? addThousandSeparator(
                                    (row?.order_details[0]?.price).toFixed(2)
                                  )
                                : ""}
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  "No Data Found"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "PendingInvoiceModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "PendingInvoiceModal"
            ? "pendinginvoiceModal"
            : ""
        }
        child={
          modalDetail.flag === "PendingInvoiceModal" ? (
            <NewpurchaseInvoice
              loadingState={loading1}
              statusHandle={(a, b) => changeStatusOfPurchaseHandle(a, b)}
              singleOrderData={singleOrderData}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          <>
            <div className="modalHeader_">
              <div className="common_">
                {modalDetail.flag === "PendingInvoiceModal" ? (
                  <div className="anotherHeader">
                    <i
                      onClick={() => handleOnCloseModal()}
                      className="las la-angle-left"
                    ></i>
                    <h2 className="innerSubheadNormal">Invoice#</h2>
                    <span className="bundleBtn">On the way</span>
                  </div>
                ) : (
                  ""
                )}
                {/* <button className="closeButton">
                                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                </button> */}
              </div>
            </div>
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
      <section className="recentTransections commonTableheight mt-1 table-responsive">
        {/* <h3 className='textInnerHead mb-4'>Recent Orders</h3> */}
        <DataTable
          columns={columns1}
          data={stockData}
          defaultSortFieldId={1}
          progressPending={loading}
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </section>
    </div>
  );
};

export default MyPurchases;
