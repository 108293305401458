import React from 'react'

const CallexchangeModal = () => {
  return (
    <div className='addEmployeeModal '>
        <form className='commonbody_ horizontalForms'>
           <div className='row'>
           
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Reason</label>
                    <select className='w-100 select customform-control'>
                        <option selected>Technical Issue</option>
                        <option>Department2</option>
                        <option>Department3</option>
                    </select>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Select Agent</label>
                    <select className='w-100 select customform-control'>
                        <option selected>Select Agent</option>
                        <option>Department2</option>
                        <option>Department3</option>
                    </select>
                </div>
               
                <div className='footerSec mt-4'>
                    <button className='closebtnModal me-4'>Cancel</button>
                    <button className='exchangeBtn'>Exchange Now</button>
                </div>
           </div>
        </form>
    </div>
  )
}

export default CallexchangeModal