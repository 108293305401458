import React from "react";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import { user } from "../../../../utilities/images";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment-timezone";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
const PurchaseReport = () => {
  const location = useLocation();
  const data = location.state;
  const dispatch = useDispatch();
  const handleReportItem = (row) => {};
  console.log(data);
  const columns1 = [
    {
      name: "#",
      selector: (row, index) => {
        return <>{index + 1}</>;
      },
      // width: "200px",
    },
    {
      name: "Descriptions",
      selector: (row, index) => {
        return (
          <>
            <div className="text-start productonlineDetail">
              <img
                src={row?.product_image ?? user}
                alt="profileimg"
                className="tableProductimg"
              />
              <div className="name_content">
                <h6 className="contentheadtext_">
                  {row?.product_name.slice(0, 20)}
                </h6>
                <span className="contentHeadtextlight_">
                  {row?.product_name.slice(20)}
                </span>
              </div>
            </div>
          </>
        );
      },
      // width: "200px",
    },
    {
      name: "No. of items",
      selector: (row, index) => {
        return <>{row?.returned_qty}</>;
      },
      // width: "200px",
    },
    {
      name: "Rate",
      selector: (row, index) => {
        return <>{row?.price}</>;
      },
      // width: "200px",
    },
    {
      name: "Amount",
      selector: (row, index) => {
        return <>{row?.price * row?.returned_qty}</>;
      },
      // width: "200px",
    },
    {
      name: "Issue",
      selector: (row, index) => {
        return (
          <>
            <select className="cateSelectdFilter">
              <option value="">Select One</option>
              <option value="Damaged">Damaged</option>
              <option value="Used">Used</option>
            </select>
          </>
        );
      },
      // width: "200px",
    },
    {
      name: "Action",
      selector: (row, index) => {
        return (
          <>
            <button
              to="#"
              className="acceptBtn_ "
              onClick={(row) => {
                handleReportItem(row);
              }}
            >
              Report this Item
            </button>
          </>
        );
      },
      // width: "200px",
    },
  ];

  return (
    <div className="PurchaseReport headerWrap rightWrapper">
      <DashboardNavbar title="Store Purchases Overview" backShow={`/newPurchase`} />
      <div className="refundorderWrapper">
        <div className="container-fluid pt-3 pb-3">
          <div className="row justify-content-center">
            <div className="col-md-10 ">
              <div className="toporderCommon">
                <div className="row">
                  <div className="CustomerOnlinestatus_ ">
                    <div className="invoiceHead mb-3">
                      <h3 className="invoiceHeading">Invoice</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="invoiceForm">
                          <h5 className="semiboldHeading_">Invoice from:</h5>
                          <p className="smalllightHead_">Imani Olowe</p>
                          <p className="smalllightHead_">+123-456-7890</p>
                          <p className="smalllightHead_">
                            63 Ivy Road, Hawkville, GA, USA 31036
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="invoiceForm">
                          <h5 className="semiboldHeading_">Return/Refund:</h5>
                          <p className="smalllightHead_ fw-500">
                            Driver#{" "}
                            <Link to="#" className="textBlue">
                              #36259
                            </Link>
                          </p>
                          <p className="smalllightHead_ fw-500">POS #36259</p>
                          <p className="smalllightHead_ fw-500">
                            Customer Unreachable{" "}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="invoiceForm">
                          <h5 className="semiboldHeading_">Invoice details</h5>
                          <p className="invoiceHead">
                            Invoice Id : <span>{data.invoices.id}</span>
                          </p>
                          <p className="invoiceHead">
                            Created Date :{" "}
                            <span>
                              {moment(data.invoices.created_at).format(
                                "DD MMMM YYYY"
                              )}
                            </span>
                          </p>
                          <p className="invoiceHead">
                            Recieved Date :{" "}
                            <span>
                              {moment(data.invoices.delivery_date).format(
                                "DD MMMM YYYY"
                              )}
                            </span>
                          </p>
                          <p className="invoiceHead fw-500">
                            <span>8/03/2023</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <section className="recentTransections table-responsive mt-1">
                  {/* <h3 className='textInnerHead mb-4'>Recent Orders</h3> */}
                  <DataTable
                    columns={columns1}
                    // //  data={props.ProductList}
                    data={data.order_details}
                    // progressPending={loading}
                    // onRowClicked={handleRowClick}
                    // customStyles={customStyles}
                    // // customRowComponent={(row) => <CustomRow {...row} onClick={handleRowClick} />}
                    // pagination
                    // paginationServer
                    // paginationTotalRows={productData?.payload?.total}
                    // paginationPerPage={perPage}
                    // onChangePage={handlePageChange}
                    // onChangeRowsPerPage={handleRowsPerPageChange}
                    selectableRows
                    // onSelectedRowsChange={handleRowSelected} // Callback for row selection
                  />
                </section>
                {/*  */}

                <div className="customerInvoicetable mt-4">
                  <div className="table-responsive">
                    <table className="table  listItemsContainer">
                      <thead className="tHead_">
                        <th className="text-start">#</th>
                        <th className="text-start"></th>
                        <th className="text-start">Descriptions</th>
                        <th className="text-start">No. of Items</th>
                        <th className="text-start">Price</th>
                        <th>Amount</th>
                        <th className="text-start">Issue</th>
                        <th className="text-start">Action</th>
                      </thead>
                      <tbody>
                        <tr role="row">
                          <td className="text-start">1</td>
                          <td className="text-start">
                            <div className="rolecheckbox_">
                              <input
                                className="me-2"
                                type="checkbox"
                                id="view1"
                                value=""
                              />
                              <label
                                className="contentheadtext_ mb-3"
                                htmlFor="view1"
                              >
                                {" "}
                              </label>
                            </div>
                          </td>
                          <td className="text-start productonlineDetail">
                            <img
                              src={user}
                              alt="profileimg"
                              className="tableProductimg"
                            />
                            <div className="name_content">
                              <h6 className="contentheadtext_">
                                Ashton Classic
                              </h6>
                              <span className="contentHeadtextlight_">
                                Box of 25
                              </span>
                            </div>
                          </td>
                          <td className="text-start">
                            <input
                              type="text"
                              className="cateSelectd"
                              placeholder=""
                            />
                          </td>
                          <td className="text-start">
                            <p className="contentheadtext_">$253.95</p>
                          </td>
                          <td className="text-start">
                            <p className="itemsTxt">fgdg</p>
                          </td>
                          <td className="text-start">
                            <select className="cateSelectdFilter">
                              <option value="">Select One</option>
                            </select>
                          </td>
                          <td className="text-start">
                            <Link to="#" className="acceptBtn_ ">
                              Report this Item
                            </Link>
                            {/* <Link to='#' className='acceptBtn_ ' >Report this Item</Link> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="totalArea">
                    <div className="row">
                      <div className="col-md-5 text-start">
                        <div className="notes">
                          <textarea
                            className="h-auto"
                            placeholder="Notes"
                            id="floatingTextarea"
                            rows="10"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-4 text-start">
                        <div className="Customertotal">
                          <div className="totalDetails">
                            <h5 className="personalinfoHead">Sub Total</h5>
                            <h5 className="itemsTxt">${data?.actual_amount}</h5>
                          </div>
                          <div className="totalDetails">
                            <h5 className="personalinfoHead">Discount </h5>
                            <h5 className="itemsTxt Orangetext">
                              ${data?.discount}
                            </h5>
                          </div>
                          <div className="totalDetails">
                            <h5 className="personalinfoHead">
                              Shipping Charge
                            </h5>
                            <h5 className="itemsTxt">
                              ${data?.shipping_charge}
                            </h5>
                          </div>
                          <div className="totalDetails">
                            <h5 className="personalinfoHead">Tax</h5>
                            <h5 className="itemsTxt">${data?.tax}</h5>
                          </div>
                          <div className="totalDetails">
                            <h5 className="itemsText ">Total</h5>
                            <h5 className="itemsText">
                              ${data?.payable_amount}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="invoiceBottombtn mt-5">
                  <button className="declineBtn me-3">Discard</button>
                  <button className="acceptBtn_">
                    <span> Report Issues</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseReport;
