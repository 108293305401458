import React, { useEffect } from "react";
import moment from "moment-timezone";
import { calendar, profileImg1, suplier, OrderDown, trackNumber,shipImage } from "../../../../utilities/images";
import DatePicker from "react-datepicker";
import { useState } from "react";
import DataTable from "react-data-table-component";
import products from "../../../db/category.json";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  changeStatusApiAsync,
  getPurchaseListApiAsync,
} from "../../dashboard/dashboardSlice";
import CustomModal from "../../../components/shared/CustomModal";
import NewpurchaseInvoice from "./newpurchaseInvoice";
import CancelpurchaseInvoice from "./cancelpurchaseModal";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import CancelpurchaseModal from "./cancelpurchaseModal";
import ProcessingpurchaseModal from "./processingpurchaseModal";
import PurchasetransitModule from "./purchasetransitModule";
import PurchaseReport from "./purchaseReport";

const NewPurchase = (props) => {
  const { seller_id } = { ...props }
  const history = useHistory();
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const [singleOrderData, setSingleOrderData] = useState({});
  const [purchaseList, setPurchaseList] = useState([]);
  const dispatch = useDispatch();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [status, setStatus] = useState("all");
  const [paymentMethod, setPaymentMethod] = useState("all");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    invoiceNumber: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      invoiceNumber: "",
    });
    setKey(Math.random());
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };
  const columns1 = [
    {
      name: "Date",
      selector: (row) => {
        return (
          <>
            <div onClick={() => handleRowClick(row)} className="ms-2">
              <span className="contentSubtext_">
                {moment(row?.created_at).format("MM/DD/YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      selector: (row) => (row?.invoices?.id ? row?.invoices?.id : "-"),
      name: "Invoice Id",
      width: "120px",
    },
    {
      selector: (row) =>
        row?.invoice?.invoice_id ? row?.invoice?.invoice_id : "-",
      name: "Delivery Location",
      width: "120px",
    },
    {
      name: "Supplier",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                className="userInnerImg"
                src={
                  row?.sellerDetails?.user_profiles?.profile_photo
                    ? row?.sellerDetails?.user_profiles?.profile_photo
                    : profileImg1
                }
              />
              <div className="ms-2 purchaseMain">
                <span className="contentSubtext_ ">
                  {row?.sellerDetails?.user_profiles?.firstname}{" "}
                  {row?.sellerDetails?.user_profiles?.lastname}
                </span>
                <p className="mb-0 ">
                  <span className="tableHeadsmall">
                    {row?.sellerDetails?.user_profiles?.seller_type}
                  </span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "250px",
    },
    {
      selector: (row) =>
        row?.mode_of_payment === "jbr"
          ? "JBR Wallet"
          : row?.mode_of_payment === "cash"
            ? "Cash"
            : "Card",
      name: "Payment Method",
      width: "110px",
    },
    {
      selector: (row) => {
        return (
          <>
            <span>             
            {  !row?.shipmentImage ?  <img src={shipImage} alt="ship Image" className='me-2 shipImgTable' />:<></>}
            {row?.shipment ? row?.shipment : "221212"}
            </span>
          </>
        )
      },
      name: "Shipment",
      width: "110px",
    },
    {
      selector: (row) => {
        return (
          <>
            <span>
              {row?.tracking_number ? row?.tracking_number : "-"}
              <img src={trackNumber} alt="tracking Image" className='img-fluid ms-2' />
            </span>
          </>
        )
      },
      name: "Tracking",
      width: "110px",
    },
    {
      selector: (row) => {
        return (
          <div className="fw-bold">{row?.total_items}</div>)
      },
      name: "No. of Items",
      width: "120px",
    },
    {
      selector: (row) => {
        return(
          <div className="returnText">{row?.tax ? row?.tax : "-"}</div>
        )
      },
      name: "Tax",
      width: "110px",
    },
    {
      selector: (row) =>{
        return(
          <div className="fw-bold returnText">{row?.payable_amount ? row?.payable_amount : "-"}</div>
        )
      } ,
      name: "Total Amount",
      width: "140px",
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="ms-2 tableBtn__"
            >
              <Link
                to="#"
                className={`status_button_all_purchase`}
                style={{
                  background:
                    row.status == 0
                      ? "#3C444D"
                      : row?.status === 1 || row?.status === 2
                        ? "#1FB3FF"
                        : row?.status === 3 || row?.status === 4
                          ? "#005DB2"
                          : row?.status === 5 || row?.status === 6
                            ? "#275AFF"
                            : row?.status === 9
                              ? "#FB466C"
                              : "#D33300",
                }}
              >
                {row?.status === 0
                  ? "Pending"
                  : row?.status === 1 || row?.status === 2
                    ? "Processing"
                    : row?.status === 3 || row?.status === 4
                      ? "In-Transit"
                      : row?.status === 5 || row?.status === 6
                        ? "Delivered"
                        : row?.status === 9
                          ? "Returned"
                          : row?.status === 7 || row?.status === 8
                            ? "Cancelled"
                            : ""}
              </Link>
              <i className="fal fa-chevron-right ms-3"></i>
            </div>
          </>
        );
      },
    },
  ];

  const handleRowClick = (e) => {
    if (e.status === 9) {
      history.push("/purchaseReport", e);
      return;
    }
    setSingleOrderData(e);
    setModalDetail({
      show: true,
      flag: "CancelpurchaseModal",
      invoiceNumber: e?.invoices?.id ? e?.invoices?.id : "",
    });
    setKey(Math.random());
  };
  const changeStatusOfPurchaseHandle = (id, status) => {
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(changeStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            seller_id: seller_id ? seller_id : auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid,
            // seller_id: "8e29389f-7987-453b-9389-a1ad6bea25db",
            page: currentPage,
            limit: perPage,
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getPurchaseListApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setPurchaseList(obj?.payload?.data);
            setTotalRows(obj?.payload?.total);
            setLoading1(false);
            handleOnCloseModal();
          })
          .catch((obj) => {
            setLoading1(false);
          });
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };
  const purchaseListHandle = () => {
    let params = {
      postData: {
        seller_id: seller_id ? seller_id : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "8e29389f-7987-453b-9389-a1ad6bea25db",
        page: currentPage,
        limit: perPage,
        filter: "year",
      },
      tokenData: auth?.payload?.token,
    };
    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (status !== "all") {
      let newData = {
        ...params.postData,
        status: status,
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (paymentMethod !== "all") {
      let newData = {
        ...params.postData,
        mode_of_payment: paymentMethod,
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(getPurchaseListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setPurchaseList(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    purchaseListHandle();
  }, [currentPage, perPage, endDate, paymentMethod, status]);
  return (
    <div className="newPurchase">
      {seller_id ? <></> : <DashboardNavbar title="All Purchases" backShow={`/myPurchases`} />}
      <div className="tableSectionBackground ">
        <div className="row">
          <div className="col-md-12">
            <div className="campaignTopHead productMainCategory">
              <div className='categroyBox dateCategory'>
                <div className="calenderIcon campCalendar">
                  <img src={calendar} alt="calender" className='campcalendarImg' />
                  {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                  <DatePicker
                    className='cateDate'
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    placeholderText="Date"
                  />
                  <img src={OrderDown} alt="calender" className='calenderDownIcon' />
                </div>
              </div>
              <div className='categroyBox'>
                <select className="cateSelectdFilter" required>
                  <option value="" selected>
                    All Status
                  </option>
                  <option>Category1</option>
                  <option>Category2</option>
                </select>
              </div>
              <div className='categroyBox'>
                <select className="cateSelectdFilter" required>
                  <option value="" selected>
                    All Delivery Locations
                  </option>
                  <option>Category1</option>
                  <option>Category2</option>
                </select>
              </div>
              <div className='categroyBox'>
                <select className="cateSelectdFilter" required>
                  <option value="" selected>
                    All Payment
                  </option>
                  <option>Category1</option>
                  <option>Category2</option>
                </select>
              </div>
            </div>
          </div>

          {/* <div className="col-md-3">
            <select
              className="cateSelectdFilter"
              required
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value="all" selected>
                All Status
              </option>
              <option value="0">Reviewing</option>
              <option value="1">Accepted by seller</option>
              <option value="2">Preparing</option>
              <option value="3">Ready for Pickup</option>
              <option value="4">Pickup</option>
              <option value="5">Delivered</option>
              <option value="6">Pick up by Customer</option>
              <option value="7">Cancelled</option>
              <option value="8">Rejected by Seller </option>
              <option value="9">Returned</option>
            </select>
          </div>
          <div className="col-md-3">
            <select className="cateSelectdFilter" required>
              <option value="" selected>
                All Delivery Locations
              </option>
              <option>Category1</option>
              <option>Category2</option>
            </select>
          </div>
          <div className="col-md-3">
            <select
              className="cateSelectdFilter"
              required
              onChange={(e) => {
                setPaymentMethod(e.target.value);
              }}
            >
              <option value="all" selected>
                All Payments
              </option>
              <option value="jbr">JBR Wallet</option>
              <option value="cash">Cash</option>
              <option value="card">Card</option>
            </select>
          </div> */}
        </div>
      </div>
      <div className="table-responsive">
        <DataTable
          pagination
          columns={columns1}
          data={purchaseList}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
          customStyles={customStyles}
          progressPending={loading}
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className="commonWidth modalcommon_"
        ids={modalDetail.flag}
        child={
          <CancelpurchaseModal
            loadingState={loading1}
            statusHandle={(a, b) => changeStatusOfPurchaseHandle(a, b)}
            singleOrderData={singleOrderData}
            close={() => handleOnCloseModal()}
            updateList={() => purchaseListHandle()}
          ></CancelpurchaseModal>
        }
        header={
          <>
            <div className="modalHeader_">
              <div className="common_">
                <h3 className="modalHeading_">
                  Invoice {modalDetail.invoiceNumber}
                </h3>

                <span
                  className="crossIcon"
                  onClick={() => handleOnCloseModal()}
                >
                  <i className="las la-times" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default NewPurchase;
