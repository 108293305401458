import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import products from "../../db/category.json";
import {
  user,
  locationBlue,
  moneyIcon,
  manage,
  defaultUser,
  profileImg1,
} from "../../../utilities/images";
import { Link, useHistory } from "react-router-dom";
import CustomModal from "../../components/shared/CustomModal";
import SaleinvoiceModal from "./saleinvoiceModal";
import { unwrapResult } from "@reduxjs/toolkit";
import { getPosListingAsync } from "../../containers/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../containers/auth/authSlice";
import moment from "moment-timezone";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PosSales = () => {
  const history = useHistory();
  const location = useLocation();
  const [key, setKey] = useState(Math.random());
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [filter, setFilter] = useState(
    location?.state?.filter ? location?.state?.filter : false
  );
  const [needAllPickUp, setNeedAllPickup] = useState(
    location?.state?.isNeedAllPickup ? location?.state?.isNeedAllPickup : false
  );
  const [needReturn, setNeedReturn] = useState(
    location?.state?.isNeedReturnFalse
      ? location?.state?.isNeedReturnFalse
      : false
  );
  const [listData, setListData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const getPosListingData = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        page: currentPage,
        limit: perPage,
        // delivery_option: "2,3"
      },
      tokenData: auth?.payload?.token,
    };
    if (needAllPickUp && needAllPickUp === true) {
      params.postData = {
        ...params.postData,
        need_all_pickup: needAllPickUp,
      };
    }
    if (filter) {
      params.postData = {
        ...params.postData,
        filter_by: filter,
      };
    }
    if (!needReturn) {
      params.postData = {
        ...params.postData,
        need_walkin: true,
      };
    }
    if (needReturn) {
      params.postData = {
        ...params.postData,
        need_refunded: false,
        need_returned: false,
      };
    }
    setLoading(true);
    dispatch(getPosListingAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setListData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPosListingData();
  }, [perPage, currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "My-Pos";
  }, []);

  const columns1 = [
    {
      name: "#",
      width: "50px",
      selector: (row, i) => i + 1,
    },
    {
      name: "User",
      selector: (row) => {
        return (
          <>
            {row?.user_details ? (
              <div
                onClick={() => handleRowClick(row)}
                className="d-flex align-items-center"
              >
                <img
                  className="userInnerImg"
                  src={
                    row?.user_details?.profile_photo
                      ? row?.user_details?.profile_photo
                      : defaultUser
                  }
                  alt=""
                />
                <div className="ms-2">
                  <span className="subtext_">
                    {row.user_details?.firstname +
                      " " +
                      row.user_details?.lastname}
                  </span>
                  {row?.user_details?.current_address?.city ? (
                    <p className="mb-0">
                      <img src={locationBlue} className="img-fluid me-2" />
                      <span className="textInner">
                        {row?.user_details?.current_address?.city +
                          " " +
                          row?.user_details?.current_address?.country}{" "}
                      </span>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <div>Not Available</div>
            )}
          </>
        );
      },
      width: "300px",
    },
    {
      selector: (row) =>
        row?.invoices?.invoice_number
          ? row?.invoices?.invoice_number
          : row?.return_detail?.invoices?.invoice_number,
      name: "Invoice No.",
    },
    {
      name: "Date",
      selector: (row) => {
        return (
          <>
            <div onClick={() => handleRowClick(row)} className="ms-2">
              <span className="subtext_">
                {moment(row?.created_at).format("ll")}
              </span>
              <p className="textInner mt-2">
                {moment(row?.created_at).format("hh:mm A")}
              </p>
            </div>
          </>
        );
      },
      width: "200px",
    },

    {
      selector: (row) => row.total_items,
      name: "Quantity",
      width: "110px",
    },
    {
      name: "Payment Info",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="ms-2 tableBtn__"
            >
              <span className="contentSmallTxt">
                {row?.mode_of_payment?.toUpperCase()}
              </span>
            </div>
          </>
        );
      },
      width: "170px",
    },
    {
      name: "Delivery Info",
      selector: (row) => {
        return (
          <>
            {row?.delivery_details && row?.delivery_option == 1 ? (
              <div
                onClick={() => handleRowClick(row)}
                className="d-flex align-items-center"
              >
                <img
                  className="userInnerImg"
                  src={
                    row?.driver_details?.profile_photo
                      ? row?.driver_details?.profile_photo
                      : profileImg1
                  }
                  alt=""
                />
                <div className="ms-2 text-start">
                  <span className="contentSubtext_ ">
                    {row?.delivery_details?.title}
                  </span>
                  <p className="mb-0">
                    {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                    <span className="textInner">${row?.delivery_charge}</span>
                  </p>
                </div>
              </div>
            ) : row?.shipping_details && row?.delivery_option == 4 ? (
              <div
                onClick={() => handleRowClick(row)}
                className="d-flex align-items-center"
              >
                <img
                  className="userInnerImg"
                  src={
                    row?.shipping_details?.image
                      ? row?.shipping_details?.image
                      : defaultUser
                      
                  }
                  alt=""
                />
                <div className="ms-2 text-start">
                  <span className="contentSubtext_ ">
                    {row?.shipping_details?.title}
                  </span>
                  <p className="mb-0">
                    {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                    <span className="textInner">${row?.shipping_charge}</span>
                  </p>
                </div>
              </div>
            ) : (
              <>No Delivery Info</>
            )}
          </>
        );
      },
      width: "250px",
    },
    // {
    //     name: 'Tracker',
    //     selector: row => {
    //         return (
    //             <>
    //                 {
    //                     row?.tracking_number &&
    //                     <div onClick={() => handleRowClick(row)} className='ms-2'>
    //                         <span className='contentSubtext_'>{row.tracking_number}</span>
    //                         <img className='tarckIconn_' src={manage} />
    //                     </div>
    //                 }
    //             </>
    //         );
    //     },
    //     width: "150px",
    // },
    {
      name: "Amount",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="ms-2 tableBtn__"
            >
              <span className="contentSmallTxt">${row?.payable_amount}</span>
            </div>
          </>
        );
      },
      width: "100px",
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="ms-2 tableBtn__"
            >
              {row?.status == 0 ? (
                <Link to="#" className="commonbtndesign_ viewDetailBtn_ ">
                  {row?.order_type === "product"
                    ? "Order to Review"
                    : "Service to Review"}
                </Link>
              ) : row?.status == 1 ? (
                <Link to="#" className="commonbtndesign_ acceptBtn_ ">
                  Accepted
                </Link>
              ) : row?.status == 2 ? (
                <Link to="#" className="commonbtndesign_ viewDetailBtn_ ">
                  {row?.order_type === "product"
                    ? "Order Preparing"
                    : "In progress"}
                </Link>
              ) : row?.status == 3 && row?.delivery_option != 4 ? (
                <Link to="#" className="commonbtndesign_ greenBtn_ ">
                  {row?.order_type === "product"
                    ? "Ready for Pickup"
                    : "In progress"}
                </Link>
              ) : row?.status == 3 && row?.delivery_option == 4 ? (
                <Link to="#" className="commonbtndesign_ greenBtn_ ">
                  Printing Label
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 1 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  Assign to Driver
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 2 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  In progress
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 3 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  Picked-up
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 4 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  Shipped
                </Link>
              ) : row?.status == 5 ? (
                <Link to="#" className="commonbtndesign_ viewDetailBtn_">
                  {row?.order_type === "product" ? "Delivered" : "Completed"}
                </Link>
              ) : row?.status == 6 && row?.delivery_option == 3 ? (
                <Link to="#" className="commonbtndesign_ deliverBtn_ mb-0">
                  Pickup by Customer
                </Link>
              ) : row?.status == 7 && row?.is_returned_order == true ? (
                <Link to="#" className="commonbtndesign_ cancelbtn_">
                  Refunded
                </Link>
              ) : row?.status == 7 && row?.is_returned_order == false ? (
                <Link to="#" className="commonbtndesign_ cancelbtn_">
                  Cancelled
                </Link>
              ) : row?.status == 8 ? (
                <Link to="#" className="commonbtndesign_ cancelbtn_">
                  Rejected
                </Link>
              ) : row?.status == 9 ? (
                <Link to="#" className="commonbtndesign_ returnBtn_">
                  Returned
                </Link>
              ) : (
                ""
              )}
              {/* {row?.status === 0 ? "REVIEWING" :
                                row?.status === 1 ? "ACCEPTED BY SELLER" :
                                    row?.status === 2 ? "PREPAIRING" :
                                        row?.status === 3 ? "Ready for Pickup" :
                                            row?.status === 4 ? "PICKUP" :
                                                row?.status === 5 ? "DELIVERED" :
                                                    row?.status === 6 ? "PICKUP BY CUSTOMER" :
                                                        row?.status === 7 ? "CANCELED" :
                                                            row?.status === 8 ? "REJECTED BY SELLER" :
                                                                row?.status === 9 ? "RETURNED" :
                                                                    ""} */}
              <i
                style={{ cursor: "pointer" }}
                className="fal fa-chevron-right ms-3"
                aria-hidden="false"
              ></i>
            </div>
          </>
        );
      },
      width: "220px",
    },
  ];

  const handleRowClick = (e) => {
    setModalDetail({ show: true, flag: "SaleinvoiceModal", data: e });
    setKey(Math.random());
    // history.push({ pathname: '/analytics-product',  })
  };
  return (
    <>
      <DashboardNavbar title="Pos Sale" show={true} />
      <div className="posSales">
        {/* <div className='topHeader_'>
                    <div className="topStickyHeader_ mb-3">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <span>POS Sales</span>
                                </h2>
                            </div>

                        </div>
                    </div>
                </div> */}
        <section className="recentTransections mt-1 table-responsive">
          <DataTable
            pagination
            columns={columns1}
            data={listData}
            progressPending={loading}
            customStyles={customStyles}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </section>
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "SaleinvoiceModal"
              ? "commonWidth customContent"
              : ""
          }
          ids={
            modalDetail.flag === "SaleinvoiceModal" ? "saleinvoiceModal" : ""
          }
          child={
            modalDetail.flag === "SaleinvoiceModal" ? (
              <SaleinvoiceModal
                data={modalDetail.data}
                close={() => handleOnCloseModal()}
              />
            ) : (
              ""
            )
          }
          header={
            <>
              <div className="modalHeader_">
                <div className="common_">
                  {modalDetail.flag === "SaleinvoiceModal" ? (
                    <div className="anotherHeader">
                      <i
                        onClick={() => handleOnCloseModal()}
                        className="las la-angle-left"
                      ></i>
                      <h2 className="innerSubheadNormal">
                        Invoice#{modalDetail?.data?.invoices?.invoice_number ? modalDetail?.data?.invoices?.invoice_number : modalDetail?.data?.return_detail?.invoices?.invoice_number}
                      </h2>
                      <span className="bundleBtn">
                        {modalDetail?.data?.status === 0
                          ? "REVIEWING"
                          : modalDetail?.data?.status === 1
                          ? "ACCEPTED BY SELLER"
                          : modalDetail?.data?.status === 2
                          ? "PREPAIRING"
                          : modalDetail?.data?.status === 3
                          ? "Ready for Pickup"
                          : modalDetail?.data?.status === 4
                          ? "PICKUP"
                          : modalDetail?.data?.status === 5
                          ? "DELIVERED"
                          : modalDetail?.data?.status === 6
                          ? "PICKUP BY CUSTOMER"
                          : modalDetail?.data?.status === 7
                          ? "CANCELED"
                          : modalDetail?.data?.status === 8
                          ? "REJECTED BY SELLER"
                          : modalDetail?.data?.status === 9
                          ? "RETURNED"
                          : ""}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <button className="closeButton">
                    <i
                      className="las la-times"
                      onClick={() => handleOnCloseModal()}
                    ></i>
                  </button>
                </div>
              </div>
            </>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};

export default PosSales;
