import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EditServiceVarient = (props) => {
  const [supplyinfo, setSupplyinfo] = useState(props?.selectedServices?.supplies);
  const history = useHistory();

  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  console.log(props?.selectedServices,"dssssssssssssssssssssssssssss");
  return (
    <div className="col-lg-12 mt-4">
      <div className="productSelling">
        {supplyinfo?.map((v, i) => {
          return (
            <React.Fragment key={i}>
              <div className="table-responsive mt-3">
                <div className="d-flex align-items-center justify-content-space-between">
                  <button
                    className="backgroundFillbtn ms-auto"
                    onClick={() =>
                      history.push({
                        pathname: `/${props?.type == "service"
                            ? "editServiceSupply"
                            : "editProductSupply"
                          }`,
                        state: { ...v, product_id: props?.selectedServices?.id },
                      })
                    }
                  >
                    Edit Supply
                  </button>

                  {supplyinfo.length > 1 ? (
                    <button
                      className="backgroundFillbtn ms-2"
                      style={{ background: "#fe0000eb" }}
                    >
                      Delete Supply
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <table className="table  listItemsContainer">
                  <thead className="productHead_">
                    <th className="dateapoint">#</th>
                    {props?.selectedProduct?.type === "digital" ? (
                      <></>
                    ) : (
                      <th className="dateapoint text-center">Cost Price</th>
                    )}

                    <th className="dateapoint text-center">Supply Prices</th>
                  </thead>
                  <tbody>
                    <tr role="row">
                      <td className="text-start">{i + 1}</td>
                      {props?.selectedProduct?.type === "digital" ? (
                        <></>
                      ) : (
                        <td className="text-center">
                          <div className="name_content">
                            <h6 className="contentheadtext_">
                              {" "}
                              ${addThousandSeparator((v?.cost_price).toFixed(2))}



                            </h6>
                            {/* <span className="contentHeadtextlight_">Box of 25</span> */}
                          </div>
                        </td>
                      )}

                      <td className="text-center">
                        <p className="itemsTxt">
                          <span className="textParagh productindetail_">
                            {v?.supply_prices?.map((val, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <p className="textInnerHead px-3">
                                    App Name:{" "}
                                    <span className="mainlightHead">
                                      {val?.app_name}{" "}
                                    </span>
                                  </p>
                                  <p className="textInnerHead px-3">
                                    Selling Prices:{" "}
                                    <span className="mainlightHead">
                                      $
                                      {val?.selling_price
                                        ? addThousandSeparator((val?.selling_price).toFixed(2))
                                        : 0}{" "}
                                    </span>
                                  </p>
                                </React.Fragment>
                              );
                            })}
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {v?.supply_variants?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table  listItemsContainer">
                    <thead className="productHead">
                      <th className="dateapoint text-startr">#</th>
                      <th className="dateapoint text-center">Image/Name</th>
                      <th className="dateapoint text-center">Price</th>
                      <th className="dateapoint text-center">Stock</th>
                      <th className="dateapoint text-center">UPC</th>
                      <th className="dateapoint text-center">Weight</th>
                    </thead>
                    <tbody>
                      {v?.supply_variants?.map((val, ind) => {
                        return (
                          <tr key={ind} role="row">
                            <td className="text-start">{ind + 1}</td>
                            <td className="text-center">
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="imgFileBox me-2">
                                  <label className="" htmlFor="file-input">
                                    <span className="galleryCircle me-2">
                                      <img
                                        src={val?.image}
                                        alt=""
                                        className=""
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="variantItemsContent">
                                  <p className="subtextDark_">
                                    {JSON.parse(
                                      val?.attribute_variant?.variants
                                    )[0]?.attribute_name +
                                      "/" +
                                      JSON.parse(
                                        val?.attribute_variant?.variants
                                      )[0]?.attribute_value_name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              <p className="contentheadtext_">${val?.price}</p>
                            </td>
                            <td className="text-center">
                              <p className="contentheadtext_">{val?.stock}</p>
                            </td>
                            <td className="text-center">
                              <p className="contentheadtext_">{val?.upc}</p>
                            </td>
                            <td className="text-center">
                              <p className="contentheadtext_">{val?.weight}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default EditServiceVarient;
