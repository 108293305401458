import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectLoginAuth } from "../auth/authSlice";
import { imageUploadAsync } from "../message/messageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { calendar_light, rewardImg } from "../../../utilities/images";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import {
  createGiftCardApiAsync,
  updateGiftCardApiAsync,
} from "../dashboard/dashboardSlice";

const CreateGift = (props) => {
  const toastId = React.useRef(null);
  const [giftId] = useState(props?.selectedGift?.id);
  const [title, setTitle] = useState(
    props?.selectedGift?.title ? props?.selectedGift?.title : ""
  );
  console.log(props?.selectedGift, "dsssssssssssssssssssssssssssss");
  const date = moment.utc(props?.selectedGift?.start_date);
  const newDate = date.toDate();

  let dateFormatted = moment(props?.selectedGift?.end_date?.split('T')[0], "YYYY-MM-DD").toDate();
  const [startDate, setStartDate] = useState(
    props?.selectedGift?.end_date ? dateFormatted : ""
  );
  const [giftAmount, setGiftAmount] = useState(
    props?.selectedGift?.gift_amount ? props?.selectedGift?.gift_amount : ""
  );
  const [document, setDocument] = useState(
    props?.selectedGift?.image ? props?.selectedGift?.image : ""
  );
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  let imageFile;
  const handleChange = (e) => {
    e.preventDefault();
    imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append("file", imageFile);
    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    setDataLoading(true);
    dispatch(imageUploadAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setDataLoading(false);
        setDocument(obj?.payload[0]?.filePath);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
      })
      .catch((obj) => {
        setDataLoading(false);
      });
  };

  const submitHandle = (e) => {
    e.preventDefault();
    if (!document) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please upload image");
      }
      return;
    }
    if (!title) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter title");
      }
      return;
    }
    if (!giftAmount) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select gift amount");
      }
      return;
    }
    if (!startDate) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select expiry date");
      }
      return;
    }
    let params = {
      postData: {
        image: document,
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        end_date: moment(startDate).format("YYYY-MM-DD"),
        title: title,
        gift_amount: giftAmount,
      },
      tokenData: auth?.payload?.token,
    };

    if (props?.editMode) {
      let params = {
        postData: {
          id: giftId,
          image: document,
          start_date: moment(new Date()).format("YYYY-MM-DD"),
          end_date: moment(startDate).format("YYYY-MM-DD"),
          title: title,
          gift_amount: giftAmount,
        },
        tokenData: auth?.payload?.token,
      };
      setLoading(true);
      dispatch(updateGiftCardApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          toast.success(obj?.msg);
          props?.getGiftCardList();
          props.close();
        })
        .catch((obj) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      dispatch(createGiftCardApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          toast.success(obj?.msg);
          props?.getGiftCardList();
          props.close();
        })
        .catch((obj) => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="addEmployeeuser createuserprof_">
      <form
        autoComplete="nope"
        onSubmit={(e) => submitHandle(e)}
        className="emplyoeeDetails horizontalForms"
      >
        <div className="row">
          <div className="form-group col-lg-12 col-md-12 col-sm-12 mb-4 text-start">
            <div>
              <label className="deatilsHead fw-bold mb-3" htmlFor="amount">
                Upload Photo <span className="subtextSmall_"></span>
              </label>
            </div>

            <div className="edit_profile mb-4">
              {dataLoading ? (
                <span className="spinner-border inner-spin spinner-border-sm"></span>
              ) : (
                <div className="imgUser">
                  <img
                    id="profileImagePreview"
                    src={document ? document : rewardImg}
                    className="img-fluid profile_modal"
                  />

                  <label htmlFor="file-upload" className="custom-file-upload">
                    <i className="fas fa-camera"></i>
                  </label>

                  <input
                    id="file-upload"
                    type="file"
                    accept="image/jpeg, image/png"
                    name="profile_image"
                    className="d-none"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="form-group col-md-6  mb-4">
            <label className="labelTxt mb-3" htmlFor="amount">
              Title
            </label>
            <input
              type="text"
              name="text"
              className="customform-control"
              placeholder="Please enter title"
              autoComplete="off"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="form-group col-md-6 mb-4">
            <label className="labelTxt mb-3" htmlFor="amount">
              Gift Amount
            </label>
            <div className="inputGroup">
              <input
                type="number"
                name="gift_amount"
                className="customform-control"
                placeholder="Please enter the gift amount"
                autoComplete="off"
                onKeyDown={blockInvalidChar}
                value={giftAmount}
                onChange={(e) => setGiftAmount(e.target.value)}
              />
              <span className="dollrsign_">$</span>
            </div>
          </div>

          <div className="offerValidity">
            <h5 className="validText">Gift Card Validity </h5>
            <hr></hr>
            <div className="selectDate ">
              <label className="subtextDark_ mb-3 mx-3">Select Date</label>
              {/* <img className='calanderImgg' src={calendar_light} /> */}
              <DatePicker
                className="customform-control"
                selected={startDate}
                minDate={moment().toDate()}
                onChange={(date) => setStartDate(date)}
                placeholderText="Gift Card Validity Date"
              />
            </div>
          </div>

          <div className="footerSec mt-4">
            <button className="submitBtn" type="submit" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              &nbsp;&nbsp;
              <span>{props?.editMode ? "Update" : "Create"}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateGift;
