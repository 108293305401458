import React, { useEffect, useState } from 'react'
import { infoImg } from '../../../utilities/images'
import { CKEditor } from 'ckeditor4-react'
import { getBrandOfCategoryAsync, getCategoriesAsync, getServicesAsync } from '../dashboard/dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoginAuth } from '../auth/authSlice';
import Select from 'react-select';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const ProductDetails = (props) => {
    const location = useLocation();
    const selectedProductDtails = location?.state;
    const [selectedService, setSelectedService] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [productName, setProductName] = useState(selectedProductDtails?.name ? selectedProductDtails?.name : "")
    const [productDescription, setProductDescription] = useState(selectedProductDtails?.description ? selectedProductDtails?.description : "")
    const [loading, setLoading] = useState(false)
    const auth = useSelector(selectLoginAuth)
    const [services, setServices] = useState([]);
    const [servicesId, setServicesId] = useState(selectedProductDtails?.service_id ? selectedProductDtails?.service_id : "");
    const [category, setCategory] = useState([])
    const [categoryId, setCategoryId] = useState(selectedProductDtails?.category_id ? selectedProductDtails?.category_id : "")
    const [subCategory, setSubCategory] = useState([])
    const [subCategoryId, setSubcategoryId] = useState(selectedProductDtails?.sub_category_id ? selectedProductDtails?.sub_category_id : "")
    const [brand, setBrand] = useState([])
    const [brandId, setBrandId] = useState(selectedProductDtails?.brand_id ? selectedProductDtails?.brand_id : "")
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [subCategoryLoading, setSubCategoryLoading] = useState(false);
    const [brandLoading, setBrandLoading] = useState(false);
    const dispatch = useDispatch();
    let productResponse

    const handleServicesChange = (e) => {
        setCategoryLoading(true);
        setSelectedService(e)
        setServicesId(e.value);
        setSelectedSubCategory("")
        setSelectedBrand("")
        setSelectedCategory("")
        setCategory([]);
        setCategoryId("");
        setSubCategory([]);
        setSubcategoryId("");
        setBrand([]);
        setBrandId("");
        setSelectedSubCategory("")
        setSelectedBrand("")
        setSelectedCategory("")
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                service_id: e.value,
                main_category: true,
                check_product_existance: false
            }
        }
        setLoading(true)
        dispatch(getCategoriesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                let filterResult = obj?.payload?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: item?.name,
                        check_product_existance: false
                    };
                });
     
                setCategory(filterResult)
                setCategoryLoading(filterResult ? false : true);
                productResponse = {
                    "productName": productName,
                    "service_id": e.value,
                    "category_id": categoryId,
                    "sub_category_id": subCategoryId,
                    "brand_id": brandId
                }
                props.productDetails(productResponse, '')
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const handleCategoryChange = (e) => {
        setSubCategoryLoading(true)
        setSelectedCategory(e)
        setCategoryId(e.value);
        setSubCategory([]);
        setSubcategoryId("");
        setSelectedSubCategory("")
        setSelectedBrand("")
        setBrand([]);
        setBrandId("");
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                category_id: e.value,
                // main_category: true,
                need_subcategory: true,
                check_product_existance: false
            }
        }
        setLoading(true)
        dispatch(getCategoriesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                let filterResult = obj?.payload?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: item?.name
                    };
                });
                setSubCategory(filterResult);
                setSubCategoryLoading(filterResult ? false : true)
                productResponse = {
                    "productName": productName,
                    "service_id": servicesId,
                    "category_id": e.value,
                    "sub_category_id": subCategoryId,
                    "brand_id": brandId
                }
                props.productDetails(productResponse, '')
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const handleSubCategoryChange = (e) => {
        setBrandLoading(true)
        setSelectedSubCategory(e)
        setSubcategoryId(e.value);
        setBrand([]);
        setBrandId("");
        setSelectedBrand("")
        let brandData = {
            tokenData: auth?.payload?.token,
            postData: {
                sub_category_id: e.value,
                check_product_existance: false
            }
        }
        dispatch(getBrandOfCategoryAsync(brandData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                let filterResult = obj?.payload?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: item?.name
                    };
                });
                setBrand(filterResult)
                setBrandLoading(filterResult ? false :true)
                productResponse = {
                    "productName": productName,
                    "service_id": servicesId,
                    "category_id": categoryId,
                    "sub_category_id": e.value,
                    "brand_id": brandId
                }
                props.productDetails(productResponse, '')
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const handleBrandChange = (e) => {
        setSelectedBrand(e)
        productResponse = {
            "productName": productName,
            "service_id": servicesId,
            "category_id": categoryId,
            "sub_category_id": subCategoryId,
            "brand_id": e.value,
            check_product_existance: false
        }
        props.productDetails(productResponse, '')
        setBrandId(e.value)
    }

    const handleProductName = (e) => {
        setProductName(e.target.value)
        productResponse = {
            "productName": e.target.value,
            "service_id": servicesId,
            "category_id": categoryId,
            "sub_category_id": subCategoryId,
            "brand_id": brandId
        }
        props.productDetails(productResponse, '')
    }
    const handleProductDescription = (e) => {
        props.productDetails(e, "description")
        setProductDescription(e)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const newValue = event.target.value.trim();
            if (newValue !== '') {
                // Check if the entered value already exists
                const existingOption = brand.find(option => option.label.toLowerCase() === newValue.toLowerCase());
                if (!existingOption) {
                    const newOption = { value: newValue.toLowerCase(), label: newValue };
                    if(brand?.length > 0 ){
                        setBrand([...brand, newOption]);
                    }
                    else{
                        setBrand([newOption]);
                    }
                }
            }
        }
    };

    const handleKeyDownSubcategory = (event) => {
       
        if (event.key === 'Enter') {
            const newValue = event.target.value.trim();
            if (newValue !== '') {
                // Check if the entered value already exists
                const existingOption = subCategory?.find(option => option.label.toLowerCase() === newValue.toLowerCase());
                if (!existingOption) {
                    const newOption = { value: newValue.toLowerCase(), label: newValue };
                    if(subCategory?.length > 0 ){
                        setSubCategory([...subCategory, newOption]);
                    }
                    else{
                        setSubCategory([newOption]);
                    }
                }
            }
        }
    };

    const getServices = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                type: "product",
                check_product_existance: false
            }
        }
        setLoading(true)
        dispatch(getServicesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                let filterResult = obj?.payload?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: item?.name
                    };
                });
            
                setServices(filterResult)
                let selectedService = filterResult.find((item) => item.value == selectedProductDtails?.service_id);
                setSelectedService(selectedService ? selectedService : null);
                let data = {
                    tokenData: auth?.payload?.token,
                    postData: {
                        service_id: selectedService.value,
                        main_category: true,
                        check_product_existance: false
                    }
                }
                setLoading(true)
                dispatch(getCategoriesAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false);
                        let filterResult = obj?.payload?.data?.map((item) => {
                            return {
                                value: item?.id,
                                label: item?.name
                            };
                        });
                
                        setCategory(filterResult);
                        let selectedCategory = filterResult.find((item) => item.value == selectedProductDtails?.category_id);
                        setSelectedCategory(selectedCategory ? selectedCategory : null);
                        let data = {
                            tokenData: auth?.payload?.token,
                            postData: {
                                category_id: selectedCategory.value,
                                need_subcategory: true,
                                check_product_existance: false
                            }
                        }
                        setLoading(true)
                        dispatch(getCategoriesAsync(data))
                            .then(unwrapResult)
                            .then((obj) => {
                                setLoading(false);
                                let filterResult = obj?.payload?.data?.map((item) => {
                                    return {
                                        value: item?.id,
                                        label: item?.name
                                    };
                                });
                                setSubCategory(filterResult);
                                let selectedSubCategory = filterResult.find((item) => item.value == selectedProductDtails?.sub_category_id);
                                setSelectedSubCategory(selectedSubCategory ? selectedSubCategory : null);
                                let brandData = {
                                    tokenData: auth?.payload?.token,
                                    postData: {
                                        category_id: selectedSubCategory.value,
                                        check_product_existance: false
                                    }
                                }
                                dispatch(getBrandOfCategoryAsync(brandData))
                                    .then(unwrapResult)
                                    .then((obj) => {
                                        setLoading(false);
                                        let filterResult = obj?.payload?.data?.map((item) => {
                                            return {
                                                value: item?.id,
                                                label: item?.name
                                            };
                                        });
                                        setBrand(filterResult)
                                        let selectedBrand = filterResult.find((item) => item.value == selectedProductDtails?.brand_id);

                                    
                                        setSelectedBrand(selectedBrand ? selectedBrand : null);
                                    }
                                    )
                                    .catch((obj) => {
                                        setLoading(false)
                                    })
                            }
                            )
                            .catch((obj) => {
                                setLoading(false)
                            })
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getServices();
    }, [])

    return (
        <>
            <div className='productDetailsContainer'>
                <div className='ps-30 pe-30'>
                    <h2 className='headingBlue'>
                        Product Details
                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                    </h2>
                </div>
                {/* Start ProductName Area */}
                <div className='productDetailsSpc mt-4'>
                    <form className='productName'>
                        <div className='form-group'>
                            <label className="subheaderHeading mb-2">Product Name</label>
                            <div className='inputGroup'>
                                <input
                                    className="customform-control customInput"
                                    placeholder="Product name"
                                    name="text"
                                    type="text"
                                    value={productName}
                                    onChange={(e) => handleProductName(e)}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                {/* End ProductName Area */}
                {/* Start ProductCategory Area */}
                <div className='productCategoryArea mt-4 mb-4'> 
                    <form className='ProductCategory row'>
                        <div className="mb-5 form-group col-md-6">
                            <label className="deatilsHead mb-3 fw-normal">Select Master Category</label>
                            {/* <select className='customform-control select bg-white' defaultValue={"Select Services"} onChange={(e) => handleServicesChange(e)}>
                                <option value="Select Services" disabled>Select Services</option>
                                {
                                    (services && services.length > 0) ?
                                        <>
                                            {services?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                )
                                            })}
                                        </> : <></>
                                }
                            </select> */}
                            <Select
                                value={selectedService}
                                onChange={handleServicesChange}
                                options={services}
                                isSearchable={true}
                                placeholder="Select Services"
                            />
                        </div>
                        <div className="mb-5 form-group col-md-6">
                            <label className="deatilsHead mb-3 fw-normal">Select Category</label>
                            {/* <select className='customform-control select bg-white' defaultValue={"Select Category"} disabled={!servicesId} onChange={(e) => handleCategoryChange(e)}>
                                <option value="Select Category" >Select Category</option>
                                {
                                    (category) ?
                                        <>
                                            {category?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                )
                                            })}
                                        </> : <option disabled value="No category Found">No Category Found</option>
                                }
                            </select> */}
                            <Select
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                options={category}
                                isDisabled={!servicesId}
                                isSearchable={true}
                                placeholder={
                                    category && categoryLoading ? "Loading Categories..." : "Select Category"
                                  }
                            />
                        </div>

                        <div className="mt-2 form-group col-md-6">
                            <label className="deatilsHead mb-3 fw-normal">Select Sub Category</label>
                            {/* <select className='customform-control select bg-white' defaultValue={"Select Sub Category"} disabled={!categoryId} onChange={(e) => handleSubCategoryChange(e)}>
                                <option value="Select Sub Category" >Select Sub Category</option>
                                {
                                    (subCategory) ?
                                        <>
                                            {subCategory?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                )
                                            })}
                                        </> : <option disabled value="No Sub category Found">No Sub Category Found</option>
                                }
                            </select> */}
                            <Select
                                value={selectedSubCategory}
                                onChange={handleSubCategoryChange}
                                onKeyDown={handleKeyDownSubcategory}
                                options={subCategory}
                                isDisabled={!categoryId}
                                isSearchable={true}
                                placeholder={
                                    subCategory && subCategoryLoading
                                      ? "Loading Categories..."
                                      : "Select Sub Category"}
                                  
                            />
                        </div>
                        <div className="mt-2 form-group col-md-6">
                            <label className="deatilsHead mb-3 fw-normal">Select Brand</label>
                            {/* <select className='customform-control select bg-white' defaultValue={"Select Brand"} disabled={!subCategoryId} onChange={(e) => handleBrandChange(e)}>
                                <option value="Select Brand" >Select Brand</option>
                                {
                                    (brand) ?
                                        <>
                                            {brand?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                )
                                            })}
                                        </> : <option disabled value="No Brand Found">No Brand Found</option>
                                }
                            </select> */}
                            <Select
                                value={selectedBrand}
                                onChange={handleBrandChange}
                                onKeyDown={handleKeyDown}
                                options={brand}
                                isDisabled={!subCategoryId}
                                isSearchable={true}
                                placeholder={
                                    brand && brandLoading ? "Loading Brand..." : "Select Brand"
                                  }
                            />
                        </div>
                    </form>
                </div>
                {/* End ProductCategory Area */}
                {/* Start CK EditorArea */}
                <div className='productDetailsSpc'>
                    
                    <CKEditor
                        initData={productDescription}
                        config={{
                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", 'Table', "image"],
                            versionCheck: false
                        }}
                        name='editor'
                        readOnly={false}
                        onChange={(event, editor) => {
                            const data = event.editor.getData()
                            handleProductDescription(data);
                        }}
                        type="classic"
                    />
                </div>
                {/* End CK EditorArea */}
            </div>
        </>
    )
}

export default ProductDetails