import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { usa } from '../../../../utilities/images'
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { getCountriesAsync } from "../systemConfigSlice";

const CountryModal = (props) => {
  const history = useHistory()
  const [loading, setLoading] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [countryId, setCountryId] = useState();
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch()

  const getCountrydata = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        page: 1,
        limit: 10,
      }
    }
    setLoading(true)
    dispatch(getCountriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setCountryData(obj?.payload ? obj?.payload : [])

      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  const handleCountrySelect = (event) => {
    const selectedOption = event.target.value;
    setCountryId(event.target.id)
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    getCountrydata();
  }, [])

  return (
    <>
      <div className='countryModal'>
        <div className='container'>
          <div className='row bgTax'>
              <div className="mb-4 form-group col-md-12">
                <label className="form-label textInner_"> Name</label>
                <input className='customform-control' placeholder="Full Name or Business name" >

                </input>
              </div>
              <div className="mb-4 form-group col-md-12">
                <label className="form-label textInner_ mb-3">SSN or EIN</label>
                <input className='customform-control' placeholder="SSN or EIN" >
                </input>
              </div>
              <div className="mb-4 form-group col-md-12">
                <label className="form-label textInner_ mb-3">Street Address</label>
                <input className='customform-control' placeholder="Street Address" >
                </input>
              </div>
              <div className="mb-4 form-group col-md-12">
                <label className="form-label textInner_ mb-3">Apartment/Suite</label>
                <input className='customform-control' placeholder="Apartment/Suite" >
                </input>
              </div>
              <div className="mb-4 form-group col-md-6">
                <label className="form-label textInner_  mb-3">City</label>
                <input
                  className="customform-control"
                  placeholder="City"

                />

                {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
              </div>
              <div className="mb-4 form-group col-md-6">
                <label className="form-label textInner_  mb-3">Zip code</label>
                <input
                  type="number"
                  className="customform-control"
                  placeholder="Zip code"
                />
              </div>
              <div className="mb-2 form-group col-md-6">
                <label className="form-label textInner_ mb-3">Country</label>
                <input
                  className="customform-control "
                  placeholder="Country" />

              </div>
              <div className="mb-2 form-group col-md-6">
                <label className="form-label textInner_ mb-3">State</label>
                <input
                  className="customform-control "
                  placeholder="State"
                  type="text"/>
                {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
              </div>
           
            <div className='col-md-12'>
              {/* <p className='textInnerDark fw-normal mb-3'>Country</p> */}
              {/* {countryData?.data?.map((val, index) => {
          return (
            <div className='countryInner' key={val.id}>1
              <div className="customRadio_" >
                <input type="radio" id={val?.id} value={val?.name} checked={selectedOption === val?.name}
                name="radio-group"  onChange={handleCountrySelect} />
                <label for={val?.id}></label>
              </div>
              <div className='countryFlag'>
                <img src={usa} alt="" />
              </div>
              <h1 className='subtext_' >{val?.name}</h1>
            </div>
          )
        })}
         */}

            </div>
          </div>
        </div>
        <div className='footerSec addCateClick mb-4  mt-4'>
          {/* <button className='closebtnModal me-4'>Cancel</button>
          <button className='exchangeBtn lh-sm' onClick={() => { props.details("OperatingState"); props.setData({ country: selectedOption, id: countryId }) }}>Next</button> */}
          <button className='discardBtn me-3' type='button'>Disacard</button>
            <button className='saveBtn' type='submit'>Save</button>
        </div> 
      </div>
  
    </>
  )
}

export default CountryModal