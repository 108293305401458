import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  catfileicons,
  dotsinbox,
  docUpfile,
  filestore,
  openIcon,
  renameIcon,
  removeIcon,
  importIcon,
  wordIcon,
  viewIcon,
  pdfImage,
  imageImg1,
  pdfImg1,
} from "../../../../utilities/images";
import { FileUploader } from "react-drag-drop-files";
import products from "../../../db/catalog.json";
import DataTable from "react-data-table-component";
import CustomModal from "../../../components/shared/CustomModal";
import CreatefolderModal from "./createfolderModal";
import ChartArea from "../../dashboard/wallet/ChartArea";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAllFoldersAsync,
  deleteFolderApiAsync,
  createCatalogFolderApiAsync,
  updateFolderAsync,
  uploadFileInCatelogAsync,
  createCatalogApiAsync,
  getAllCatalogsApiAsync,
  deleteCatalogApiAsync,
  updateCatalogApiAsync,
  getStorageDataApiAsync,
  getRecievedCatalogApiAsync,
  deleteFolderInRecievedCatalogApiAsync,
} from "../../dashboard/dashboardSlice";
import { selectLoginAuth } from "../../auth/authSlice";
import moment from "moment-timezone";
import ViewdocumentModal from "./viewdocumentModal";
import DashboardNavbar from "../../dashboard/DashboardNavbar";

const fileTypes = ["JPG", "PNG", "GIF", "txt", "pdf"];

const Catalogs = () => {
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const history = useHistory();
  const [renameId, setRenameId] = useState("");
  const [renameCatalogId, setRenameCatalogId] = useState("");
  const [key, setKey] = useState(Math.random());
  const auth = useSelector(selectLoginAuth);
  const [pdfCaption, setPdfCaption] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [oldName, setOldName] = useState("");
  const [flag, setFlag] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const toastId = React.useRef(null);
  const [folderData, setFolderData] = useState([]);
  const [mimeType, setMimeType] = useState("");
  const [storageData, setStorageData] = useState([]);
  const [catalogData, setCatalogData] = useState([]);
  const [recievedCatalogData, setRecievedCatalogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [recentUploads, setRecentUploads] = useState(false);

  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const dispatch = useDispatch();
  let totalData = (
    (storageData?.documents + storageData?.images + storageData?.videos) /
    (1024 * 1024)
  ).toFixed(2);
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleChange = (file) => {
    uploadImage(file);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const handleRemoveCatalog = (id) => {
    const params = {
      postData: {
        id: id,
      },
      tokenData: auth?.payload?.token,
    };
    setRecentUploads(true);
    dispatch(deleteCatalogApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            filter_by: "my",
            page: currentPage,
            limit: perPage,
          },
          tokenData: auth?.payload?.token,
        };
        setRecentUploads(true);
        dispatch(getAllCatalogsApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setCatalogData(obj?.payload?.data);
            setTotalRows(obj?.payload?.total);
            dispatch(getStorageDataApiAsync(auth?.payload?.token))
              .then(unwrapResult)
              .then((obj) => {
                setStorageData(obj?.payload);
                setRecentUploads(false);
              })
              .catch((obj) => {
                setRecentUploads(false);
              });
          })
          .catch((obj) => {
            setRecentUploads(false);
          });
      })
      .catch((obj) => {
        setRecentUploads(false);
      });
  };

  const handleRenameCatalog = (folderName) => {
    const params = {
      postData: {
        id: renameCatalogId,
        caption: folderName,
      },
      tokenData: auth?.payload?.token,
    };
    setRecentUploads(true);
    dispatch(updateCatalogApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            filter_by: "my",
            page: currentPage,
            limit: perPage,
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getAllCatalogsApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setRecentUploads(false);
            setTotalRows(obj?.payload?.total);
            handleOnCloseModal();
            setCatalogData(obj?.payload?.data);
          })
          .catch((obj) => {
            setRecentUploads(false);
          });
      })
      .catch((obj) => {
        setRecentUploads(false);
      });
  };

  const handleViewClick = (e) => {
    let mimeType = e?.mime_type?.split("/")[0];
    setMimeType(mimeType);
    setPdfCaption(e?.caption);
    setPdfUrl(e?.url);
    setModalDetail({ show: true, flag: "ViewdocumentModal" });
    setKey(Math.random());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Catalogs";
  }, []);

  const uploadImage = (file) => {
    let formData = new FormData();
    Object.values(file).forEach((val, index) => {
      formData.append(`file`, val);
    });

    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    setImageUploadLoading(true);
    dispatch(uploadFileInCatelogAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        let data = {
          postData: {
            files: obj?.payload,
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(createCatalogApiAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            let params = {
              postData: {
                filter_by: "my",
                page: currentPage,
                limit: perPage,
              },
              tokenData: auth?.payload?.token,
            };
            dispatch(getAllCatalogsApiAsync(params))
              .then(unwrapResult)
              .then((obj) => {
                setCatalogData(obj?.payload?.data);
                setTotalRows(obj?.payload?.total);
                dispatch(getStorageDataApiAsync(auth?.payload?.token))
                  .then(unwrapResult)
                  .then((obj) => {
                    setStorageData(obj?.payload);
                    setImageUploadLoading(false);
                  })
                  .catch((obj) => {
                    setImageUploadLoading(false);
                  });
              })
              .catch((obj) => {
                setImageUploadLoading(false);
              });
          })
          .catch((obj) => {
            setImageUploadLoading(false);
          });
      })
      .catch((obj) => {
        setImageUploadLoading(false);
      });
  };

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    title: {
      display: true,
      text: "Custom Chart Title",
      position: "bottom",
    },
  };

  const doughnutData = {
    labels: ["Used", "Pending"],
    datasets: [
      {
        label: "Percentage",
        data: [totalData, 100 - totalData],
        backgroundColor: ["#275AFF", "#E5F0FF"],
        borderColor: ["#275AFF", "#E5F0FF"],
        borderWidth: 1,

        rotation: 85 * Math.PI,
        circumference: 60 * Math.PI,
      },
    ],
  };

  const columns1 = [
    {
      name: "File Name",
      selector: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <img
                style={{ height: "100%" }}
                className="userInnerImg"
                src={
                  row?.mime_type?.split("/")[0] === "image"
                    ? imageImg1
                    : row?.mime_type?.split("/")[1] === "pdf"
                    ? pdfImg1
                    : wordIcon
                }
              />
              <div className="ms-2">
                <span className="textParagh ">
                  {row?.caption?.length > 15
                    ? row?.caption?.substring(0, 15) + "..."
                    : row?.caption}
                </span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "Folder",
      selector: (row) => {
        return (
          <>
            <span className="textParagh ">
              {" "}
              <img className="doctabfile" src={filestore} />{" "}
              {row?.catalog_folder ? row?.catalog_folder?.name : "No Folder"}{" "}
            </span>
          </>
        );
      },
    },

    {
      name: "Last Modify",
      width: "300",
      selector: (row) => {
        return (
          <>
            <span className="textParagh ">
              {moment(row?.updated_at).format("DD MMM, YYYY | H : mm")}
            </span>
          </>
        );
      },
    },

    {
      name: "File Size",
      selector: (row) => (row?.size / (1024 * 1024)).toFixed(2) + "MB",
    },

    {
      name: "",
      selector: (row) => {
        return (
          <>
            <div className="dropdown typeBtn_">
              <Link
                to="#"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                className="more_icon"
                aria-expanded="false"
              >
                <img className="dotIconn_" src={dotsinbox} />
              </Link>
              <ul
                className="dropdown-menu chatDropDown"
                aria-labelledby="dropdownMenuButton2"
              >
                <li
                  onClick={() => {
                    handleViewClick(row);
                  }}
                >
                  <Link to="#" className="dropdown-item chatdropItem">
                    <img className="dropIcon" src={viewIcon} />
                    <span className="subtextSmall_">View</span>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setModalDetail({ show: true, flag: "CreatefolderModal" });
                    setKey(Math.random());
                    setFlag("renameCatalog");
                    setRenameCatalogId(row?.id);
                    setOldName(row?.caption);
                  }}
                >
                  <Link to="#" className="dropdown-item chatdropItem">
                    <img className="dropIcon" src={renameIcon} />
                    <span className="subtextSmall_">Rename</span>
                  </Link>
                </li>
                <li onClick={() => handleRemoveCatalog(row?.id)}>
                  <Link to="#" className="dropdown-item chatdropItem">
                    <img className="dropIcon" src={removeIcon} />
                    <span className="subtextSmall_">Remove</span>
                  </Link>
                </li>
                <li>
                  <a
                    className="dropdown-item chatdropItem"
                    href={row?.url}
                    download
                  >
                    <img className="dropIcon" src={importIcon} />
                    <span className="subtextSmall_">Download</span>
                  </a>
                </li>
              </ul>
            </div>
          </>
        );
      },
    },
  ];

  const handleRemoveFolder = (id) => {
    const params = {
      postData: {
        id: id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(deleteFolderApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            filter_by: "my",
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getAllFoldersAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setFolderData(obj?.payload);
            dispatch(getStorageDataApiAsync(auth?.payload?.token))
              .then(unwrapResult)
              .then((obj) => {
                setStorageData(obj?.payload);
                setLoading(false);
              })
              .catch((obj) => {
                setLoading(false);
              });
          })
          .catch((obj) => {
            setLoading(false);
          });
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleRemoveRecievedCatalogFolder = (id) => {
    let params = {
      postData: {
        sender_id: id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(deleteFolderInRecievedCatalogApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        dispatch(getRecievedCatalogApiAsync(auth?.payload?.token))
          .then(unwrapResult)
          .then((obj) => {
            setCatalogData(obj?.payload);
            setLoading(false);
          })
          .catch((obj) => {
            setLoading(false);
          });
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const createCatalogFolder = (folderName) => {
    let params = {
      postData: {
        name: folderName,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(createCatalogFolderApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            filter_by: "my",
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getAllFoldersAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setFolderData(obj?.payload);
            handleOnCloseModal();
            dispatch(getStorageDataApiAsync(auth?.payload?.token))
              .then(unwrapResult)
              .then((obj) => {
                setStorageData(obj?.payload);
                setLoading1(false);
              })
              .catch((obj) => {
                setLoading1(false);
              });
          })
          .catch((obj) => {
            setLoading1(false);
          });
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  const handleRenameFolder = (folderName) => {
    const params = {
      postData: {
        id: renameId,
        name: folderName,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(updateFolderAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            filter_by: "my",
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getAllFoldersAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setFolderData(obj?.payload);
            setLoading1(false);
            handleOnCloseModal();
          })
          .catch((obj) => {
            setLoading1(false);
          });
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
  };

  const getRecentCatalog = () => {
    let params = {
      postData: {
        filter_by: "my",
        page: currentPage,
        limit: perPage,
      },
      tokenData: auth?.payload?.token,
    };
    setRecentUploads(true);
    dispatch(getAllCatalogsApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setCatalogData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setRecentUploads(false);
      })
      .catch((obj) => {
        setRecentUploads(false);
      });
  };

  const getCatalogFolder = () => {
    let params = {
      postData: {
        filter_by: "my",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getAllFoldersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setFolderData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getStorageData = () => {
    setLoading(true);
    dispatch(getStorageDataApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setStorageData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getRecievedCatalogData = () => {
    setLoading(true);
    dispatch(getRecievedCatalogApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setRecievedCatalogData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCatalogFolder();
    getStorageData();
    getRecentCatalog();
    getRecievedCatalogData();
  }, [perPage, currentPage]);

  return (
    <>
      <DashboardNavbar title="My Catalogs " backShow={false} />
      <div className="catalogs_">
        {/* <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>Catalogs / <span className="innerSubtext">My Catalogs </span> </span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div> */}
        {loading === true ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            <div className="container-fluid">
              <div className="uploadFile_">
                <div className="row">
                  <div className="mainCatalog">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-xxl-9 col-xl-8 col-lg-8">
                          <h4 className="successMain Head">Upload File</h4>
                          <div className="uploadBroswe_">
                            <div id="dropzone">
                              <FileUploader
                                multiple={true}
                                handleChange={handleChange}
                                name="file"
                                types={fileTypes}
                                children={
                                  <div>
                                    {imageUploadLoading === true ? (
                                      <span className="spinner-border inner-spin spinner-border-sm"></span>
                                    ) : (
                                      <>
                                        <input
                                          type="file"
                                          id="file"
                                          accept="image/*"
                                          multiple={true}
                                          className="form-control d-none"
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                        />
                                        <label htmlFor="file">
                                          <span className="file-button">
                                            <div className="dropText ms-3">
                                              <span className="innerSubheadNormal">
                                                {" "}
                                                <img
                                                  src={catfileicons}
                                                  className="img-fluid editProfileImg"
                                                  alt="user img"
                                                />{" "}
                                                Drop your files here or
                                              </span>
                                              <Link
                                                to="#"
                                                className="linkTxt ms-1"
                                              >
                                                Browse
                                              </Link>
                                            </div>
                                            <h6 className="smallHeading">
                                              Supported file: PDF, JPG, PNG, etc
                                            </h6>
                                          </span>
                                        </label>
                                      </>
                                    )}
                                  </div>
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xxl-3 col-xl-4 col-lg-4">
                          <div className="chartsOuter ">
                            <div className="storageCtalog">
                              <div className="storageBox_">
                                <ChartArea
                                  classes="col-md-13 chartProduct"
                                  options={options1}
                                  data={doughnutData}
                                  chartType="Doughnut"
                                />
                                <h4 className="textInnerHead">
                                  {storageData ? totalData + "MB" : 0 + "MB"}
                                </h4>
                                <p className="mainlightPhragraph">
                                  {storageData
                                    ? ((totalData / 1024) * 100).toFixed(2)
                                    : 0}
                                  % storage has <br /> been used{" "}
                                </p>
                              </div>
                            </div>
                            <div className="filesDocument mt-4">
                              <h5 className="tablesubHead mt-2 mb-2">
                                {" "}
                                <img className="fileupImg" src={docUpfile} />
                                Documents{" "}
                                <span className="smallheadSub_">
                                  {storageData
                                    ? (
                                        storageData?.documents /
                                        (1024 * 1024)
                                      ).toFixed(2) + "MB"
                                    : 0 + "MB"}
                                </span>
                              </h5>
                              <h5 className="tablesubHead mt-2 mb-2">
                                {" "}
                                <img className="fileupImg" src={docUpfile} />
                                Images{" "}
                                <span className="smallheadSub_">
                                  {storageData
                                    ? (
                                        storageData?.images /
                                        (1024 * 1024)
                                      ).toFixed(2) + "MB"
                                    : 0 + "MB"}
                                </span>
                              </h5>
                              <h5 className="tablesubHead mt-2 mb-2">
                                {" "}
                                <img className="fileupImg" src={docUpfile} />
                                Videos{" "}
                                <span className="smallheadSub_">
                                  {storageData
                                    ? (
                                        storageData?.videos /
                                        (1024 * 1024)
                                      ).toFixed(2) + "MB"
                                    : 0 + "MB"}
                                </span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mainCatalog mt-4 mb-4">
              <div className="uploadFolder_ mt-4 mb-4">
                <div className="addFolder_ pb-2 mb-3">
                  <h4 className="successMain">Folders</h4>
                  <button
                    className="addBtns_"
                    onClick={() => {
                      setModalDetail({ show: true, flag: "CreatefolderModal" });
                      setKey(Math.random());
                      setFlag("create");
                    }}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>
                <div className="docfolderList">
                  <div className="row">
                    {folderData?.length > 0 ? (
                      folderData?.map((val, i) => {
                        return (
                          <div
                            onDoubleClick={() =>
                              history.push(`/folderOpen/${val?.id}`)
                            }
                            className="col-lg-3"
                            key={i}
                          >
                            <div className="documentFile_">
                              <div className="docTop_ mb-3">
                                <img className="me-2" src={filestore} />
                                <h5 className="textInnerDark">{val?.name}</h5>
                                <div className="dropdown typeBtn_">
                                  <Link
                                    to="#"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    className="more_icon"
                                    aria-expanded="false"
                                  >
                                    <img
                                      className="dotIconn_"
                                      src={dotsinbox}
                                    />
                                  </Link>
                                  <ul
                                    className="dropdown-menu chatDropDown"
                                    aria-labelledby="dropdownMenuButton2"
                                  >
                                    <li
                                      onClick={() =>
                                        history.push(`/folderOpen/${val?.id}`)
                                      }
                                    >
                                      <Link
                                        to="#"
                                        className="dropdown-item chatdropItem "
                                      >
                                        <img
                                          className="dropIcon"
                                          src={openIcon}
                                        />
                                        <span className="subtextSmall_">
                                          Open
                                        </span>
                                      </Link>
                                    </li>
                                    <li
                                      onClick={() => {
                                        setModalDetail({
                                          show: true,
                                          flag: "CreatefolderModal",
                                        });
                                        setKey(Math.random());
                                        setFlag("rename");
                                        setRenameId(val?.id);
                                        setOldName(val?.name);
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        className="dropdown-item chatdropItem"
                                      >
                                        <img
                                          className="dropIcon"
                                          src={renameIcon}
                                        />
                                        <span className="subtextSmall_">
                                          Rename
                                        </span>
                                      </Link>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleRemoveFolder(val?.id)
                                      }
                                    >
                                      <Link
                                        to="#"
                                        className="dropdown-item chatdropItem"
                                      >
                                        <img
                                          className="dropIcon"
                                          src={removeIcon}
                                        />
                                        <span className="subtextSmall_">
                                          Remove
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="#"
                                        className="dropdown-item chatdropItem"
                                      >
                                        <img
                                          className="dropIcon"
                                          src={importIcon}
                                        />
                                        <span className="subtextSmall_">
                                          Download
                                        </span>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <h6 className="greySubhead_ mb-1">
                                {val?.catalogCount} documents
                              </h6>
                              <h6 className="greySubhead_">
                                {val?.totalSize
                                  ? (val?.totalSize / (1024 * 1024)).toFixed(
                                      2
                                    ) + "MB"
                                  : "0MB"}
                              </h6>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>No Folders</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mainCatalog mt-4 mb-4">
              <div className="uploadFolder_ mt-4 mb-4">
                <div className="addFolder_ pb-2 mb-3">
                  <h4 className="successMain">Received Catalogs Folders</h4>
                </div>
                <div className="docfolderList">
                  <div className="row">
                    {recievedCatalogData?.length > 0 ? (
                      recievedCatalogData?.map((val, i) => {
                        return (
                          <div
                            onDoubleClick={() =>
                              history.push(`/receivedFolder/${val?.id}`)
                            }
                            className="col-lg-3"
                            key={i}
                          >
                            <div className="documentFile_">
                              <div className="docTop_ mb-3">
                                <img className="me-2" src={filestore} />
                                <h5 className="textInnerDark">
                                  {val?.folder_name}
                                </h5>
                                <div className="dropdown typeBtn_">
                                  <Link
                                    to="#"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    className="more_icon"
                                    aria-expanded="false"
                                  >
                                    <img
                                      className="dotIconn_"
                                      src={dotsinbox}
                                    />
                                  </Link>
                                  <ul
                                    className="dropdown-menu chatDropDown"
                                    aria-labelledby="dropdownMenuButton2"
                                  >
                                    <li
                                      onClick={() =>
                                        history.push(
                                          `/receivedFolder/${val?.id}`
                                        )
                                      }
                                    >
                                      <Link
                                        to="#"
                                        className="dropdown-item chatdropItem "
                                      >
                                        <img
                                          className="dropIcon"
                                          src={openIcon}
                                        />
                                        <span className="subtextSmall_">
                                          Open
                                        </span>
                                      </Link>
                                    </li>
                                    {/* <li onClick={() => { setModalDetail({ show: true, flag: "CreatefolderModal" }); setKey(Math.random()); setFlag("rename"); setRenameId(val?.id); setOldName(val?.name) }}><Link to="#" className="dropdown-item chatdropItem">
                                                                                <img className='dropIcon' src={renameIcon} />
                                                                                <span className='subtextSmall_'>Rename</span></Link>
                                                                            </li> */}
                                    <li
                                      onClick={() =>
                                        handleRemoveRecievedCatalogFolder(
                                          val?.id
                                        )
                                      }
                                    >
                                      <Link
                                        to="#"
                                        className="dropdown-item chatdropItem"
                                      >
                                        <img
                                          className="dropIcon"
                                          src={removeIcon}
                                        />
                                        <span className="subtextSmall_">
                                          Remove
                                        </span>
                                      </Link>
                                    </li>
                                    {/* <li><Link to="#" className="dropdown-item chatdropItem" >
                                                                                <img className='dropIcon' src={importIcon} />
                                                                                <span className='subtextSmall_'>Download</span></Link>
                                                                            </li> */}
                                  </ul>
                                </div>
                              </div>
                              <h6 className="greySubhead_ mb-1">
                                {val?.files?.length} documents
                              </h6>
                              <h6 className="greySubhead_">
                                {(
                                  val?.files?.reduce(
                                    (acc, file) => acc + file.size,
                                    0
                                  ) /
                                  (1024 * 1024)
                                )?.toFixed(2)}{" "}
                                MB
                              </h6>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>No Folders</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="mainCatalog recentUpload_ mt-4 mb-4">
          <h4 className="successMain">Recent Uploads</h4>
          <div className="commonTableheight table-responsive">
            <DataTable
              pagination
              columns={columns1}
              data={catalogData}
              defaultSortFieldId={1}
              onRowClicked={handleRowClick}
              progressPending={recentUploads}
              paginationServer
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationPerPage={perPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </div>
        </div>

        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "CreatefolderModal"
              ? "commonWidth customContent"
              : ""
          }
          ids={
            modalDetail.flag === "CreatefolderModal" ? "createfolderModal" : ""
          }
          child={
            modalDetail.flag === "CreatefolderModal" ? (
              <CreatefolderModal
                oldName={oldName}
                loadingState={loading1}
                flag={flag}
                renameCatalog={(e) => handleRenameCatalog(e)}
                renameFolder={(e) => handleRenameFolder(e)}
                folderCreate={(e) => createCatalogFolder(e)}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "ViewdocumentModal" ? (
              <ViewdocumentModal
                mimeType={mimeType}
                close={() => handleOnCloseModal()}
                pdfCaption={pdfCaption}
                pdfUrl={pdfUrl}
              />
            ) : (
              ""
            )
          }
          header={
            <>
              <div className="modalHeader_">
                <div className="common_">
                  {modalDetail.flag === "CreatefolderModal" ? (
                    <div className="anotherHeader">
                      <h2 className="innerSubheadNormal">
                        {flag === "renameCatalog"
                          ? "Rename Catalog"
                          : flag === "rename"
                          ? "Rename Folder"
                          : "Create New folder"}
                      </h2>
                    </div>
                  ) : modalDetail.flag === "ViewdocumentModal" ? (
                    <h2 className="modalHeading">{pdfCaption}</h2>
                  ) : (
                    ""
                  )}
                  <button className="closeButton">
                    <i
                      className="las la-times"
                      onClick={() => handleOnCloseModal()}
                    ></i>
                  </button>
                </div>
              </div>
            </>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};

export default Catalogs;
