import { unwrapResult } from '@reduxjs/toolkit';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import {  getDepartmentDataInSupportApiAsync, updateSupportAgentApiAsync } from '../../dashboard/dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const UpdateAgentModal = (props) => {
    const dispatch = useDispatch()
    const toastId = React.useRef(null);
    const auth = useSelector(selectLoginAuth);
    const [firstName, setFirstName] = useState(props?.selectedRow?.user_profiles?.firstname)
    const [lastName, setLastName] = useState(props?.selectedRow?.user_profiles?.lastname);
    const [securityPin, setSecurityPin] = useState(props?.selectedRow?.user_profiles?.security_pin);
    const [alreadySelectedDepartment, setAlreadySelectedDepartment] = useState([])
    const [departmentData, setDepartmentData] = useState([])
    const [departmentIds, setDepartmentIds] = useState([])
    const [loading, setLoading] = useState(false);


    const handleSelect = (e) => {
        let allIds = e.map((v) => {
            return v.id
        })
        setDepartmentIds(allIds)
    }

    const handleOptionValue = (e) => {
        return (
            <>
                {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCHkgJPx9vKxK-UV2Oqs_FaBstYEz0JqBb4OXGemg&s" alt="" height="50px" width="50px"/> */}
                {e}
            </>
        )
    }

    const submitHandle = (e) => {
        e.preventDefault();
        if (!firstName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your first name")
            }
            return
        }
        if (!lastName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your last name")
            }
            return
        }
        if (departmentIds?.length <= 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select atleast one department")
            }
            return
        }
        if (!securityPin) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter Security Pin")
            }
            return
        }
        let data = {
            "postData": {
                "id": props?.selectedRow?.id,
                "firstname": firstName,
                "lastname": lastName,
                "department_id": departmentIds,
                "role_id": 5,
                "email": props?.selectedRow?.email,
                "security_pin": securityPin,
                "phone_code": props?.selectedRow?.user_profiles?.phone_code,
                "phone_no": props?.selectedRow?.user_profiles?.phone_no
            },
            "tokenData": auth?.payload?.token
        }

        setLoading(true)
        dispatch(updateSupportAgentApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                props.handleAllAgents()
                props.close()
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })

    }

    const getAllDepartment = () => {
        dispatch(getDepartmentDataInSupportApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setDepartmentData(obj?.payload)
                let selectedDepartmentIds = props?.selectedRow?.user_departments?.map(v => v?.department_id)
                setDepartmentIds(selectedDepartmentIds)
                let departmentSelected = obj?.payload?.filter(value => selectedDepartmentIds?.includes(value?.id))
                setAlreadySelectedDepartment(departmentSelected)
            }
            )
            .catch((obj) => {
            })
    }
    useEffect(() => {
        getAllDepartment()
    }, [])
    return (
        <>
            <div className='addEmployeeuser createRoles_'>
                <form onSubmit={(e) => submitHandle(e)} className='emplyoeeDetails horizontalForms'>
                    <div className='row'>
                        <div className='form-group col-md-6  mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>First Name</label>
                            <input
                                type='text'
                                name="text"
                                className='customform-control'
                                placeholder="Please Enter First name"
                                autoComplete="off"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Last Name</label>
                            <input
                                type='text'
                                name="text"
                                className='customform-control'
                                placeholder='Enter the Last Name'
                                autoComplete="off"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>

                        <div className='form-group col-md-6 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Security Pin</label>
                            <input
                                type='password'
                                className='customform-control'
                                placeholder='Enter the Security Pin'
                                name="securityPin"
                                autoComplete="new-password"
                                maxLength={4}
                                value={securityPin}
                                onChange={(e) => setSecurityPin(e.target.value)}
                            />
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Email</label>
                            <input
                                type='email'
                                name="email"
                                className='customform-control'
                                placeholder="Please Enter the Email"
                                autoComplete="off"
                                disabled
                                value={props?.selectedRow?.email}
                            />
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'> Phone Number</label>
                            <PhoneInput
                                country="us"
                                value={props?.selectedRow?.user_profiles?.phone_code + "" + props?.selectedRow?.user_profiles?.phone_no}
                                enableSearch={true}
                                autoComplete="off"
                                disabled
                                placeholder="Enter the Number"
                            // onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                            />
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Select Departments</label>
                            <Multiselect
                                className='brandFilter customform-control'
                                displayValue="name"
                                options={departmentData}
                                loading={loading}
                                isObject={true}
                                placeholder='Select Privileges'
                                hidePlaceholder={false}
                                selectedValues={alreadySelectedDepartment}
                                // selectionLimit={1}
                                onSelect={handleSelect}
                                onRemove={handleSelect}
                                optionValueDecorator={(e) => handleOptionValue(e)}
                                selectedValueDecorator={(e) => handleOptionValue(e)}
                                avoidHighlightFirstOption={true}
                                showCheckbox
                            />
                        </div>
                        <div className='footerSec mt-4 mx-5'>
                            <button onClick={() => props.close()} className='closebtnModal me-4'>Cancel</button>
                            <button className='assignBtn_' type="submit" disabled={loading}>
                                {loading === true && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Update</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UpdateAgentModal
