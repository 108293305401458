import React, { useEffect, useState } from 'react'
import { viewEye_, calendar, totalOrder, volume, analysis, grossProfit } from '../../../utilities/images';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import products from '../../db/category.json'
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { totalAnalyticsOrderApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';

const TotalOrders = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const today = new Date();
    const auth = useSelector(selectLoginAuth);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [totalOrderAnalyticsData, setTotalOrderAnalyticsData] = useState("")
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    // const handleRowClick = (e) => {
    //     // console.log("rowclicked", e)
    //     // history.push({ pathname: `/salesReport/${e?.flag}`, state: JSON.stringify(e) })
    // }


    function addThousandSeparator(number) {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const columns1 = [
        {
            name: 'Date',
            selector: row => moment(row?.order_date).format('MM/DD/YYYY')
        },
        {
            selector: row => row?.total_orders,
            name: 'Total Order',
        },
        {
            selector: row => row?.new_consumer,
            name: 'Customer-New',
        },
        {
            selector: row => row?.consumer_returning,
            name: 'Customer-Returning',
        },
        {
            selector: row => `$${row?.amount ? addThousandSeparator((row?.amount)?.toFixed(2)) : "0"}`,
            name: 'Total Revenue',
        },
        {
            selector: row => {
                return (
                    <button onClick={() => history.push({ pathname: `/transections`, state: { "date":moment(`${row?.order_date}T00:00:00`)?.toDate(), "from": "analytics","deliveryOption":3 } })} className="secondaryOuterbtn_" type="button">Review</button>
                )
            },
            name: '',
        }
    ];

    const totalOrderAnalyticsHandle = () => {
        let params = {
            "postData": {
                filter: filterValue,
                channel: channelFilter,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(totalAnalyticsOrderApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setTotalOrderAnalyticsData(obj?.payload)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        totalOrderAnalyticsHandle()
    }, [filterValue, channelFilter, endDate])
    return (
        <>
            <DashboardNavbar title="Total Orders" backShow="/analytics" />
            <div className='analyticOrders'>
                <div className='row mt-4'>
                    <div className='col-lg-5'>
                        <div className='dateChannel'>
                            <div className="calenderIcon">
                                <img src={calendar} alt="calender" />
                                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                <DatePicker
                                    className='cateDate'
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={today}
                                    selectsRange
                                    placeholderText="Date"
                                />
                            </div>
                            <div className=" form-group ">
                                <div className=" form-group ">
                                    <select autoComplete="new-password" className='customform-control  selectControl form-select' value={channelFilter} onChange={(e) => setChannelFilter(e.target.value)}>
                                        <option value="all" >All Channels</option>
                                        <option value="b2c">B2C</option>
                                        <option value="b2b">B2B</option>
                                        <option value="pos">Pos</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 d-flex justify-content-end'>
                        <ul className='weekNav'>
                            <li onClick={() => setFilterValue("today")}><Link className={`weekMenu ${filterValue == "today" ? "active" : ""}`} to="#">Today</Link></li>
                            <li onClick={() => setFilterValue("week")}><Link className={`weekMenu ${filterValue == "week" ? "active" : ""}`} to="#">Weekly</Link></li>
                            <li onClick={() => setFilterValue("month")}><Link className={`weekMenu ${filterValue == "month" ? "active" : ""}`} to="#">Monthly</Link></li>
                            <li onClick={() => setFilterValue("year")}><Link className={`weekMenu ${filterValue == "year" ? "active" : ""}`} to="#">Yearly</Link></li>
                        </ul>
                    </div>
                </div>
                {loading ?
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                    : <div className='grossProfitData'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6  pe-2'>
                                <div className='grossTotalorder'>
                                    <img src={totalOrder} className='img-fluid grossImages' alt='totalOrder' />
                                    <p className='groSub mt-4 mb-0'>Total Orders</p>
                                    <h4 className='groMain'>{totalOrderAnalyticsData?.ordersOverView?.total_orders}</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                                <div className='grossTotalorder'>
                                    <img src={volume} className='img-fluid grossImages' alt='volume' />
                                    <p className='groSub mt-4 mb-0'>Total Volume</p>
                                    <h4 className='groMain'>{totalOrderAnalyticsData?.ordersOverView?.total_volume ? `$${addThousandSeparator((totalOrderAnalyticsData?.ordersOverView?.total_volume)?.toFixed(2))}` : "$0.00"}</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                                <div className='grossTotalorder'>
                                    <img src={analysis} className='img-fluid grossImages' alt='analysis' />
                                    <p className='groSub mt-4 mb-0'>Average Order Value</p>
                                    <h4 className='groMain'>{totalOrderAnalyticsData?.ordersOverView?.averageValue ? `$${addThousandSeparator((totalOrderAnalyticsData?.ordersOverView?.averageValue)?.toFixed(2))}` : "$0.00"}</h4>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 ps-2 '>
                                <div className='grossTotalorder'>
                                    <img src={grossProfit} className='img-fluid grossImages' alt='grossprofitImage' />
                                    <p className='groSub mt-4 mb-0'>Gross profit</p>
                                    <h4 className='groMain'>{totalOrderAnalyticsData?.ordersOverView?.total_profit ? `$${addThousandSeparator(totalOrderAnalyticsData?.ordersOverView?.total_profit?.toFixed(2))}` : "$0.00"}</h4>
                                </div>
                            </div>
                        </div>
                    </div>}
                <div className='table-responisve'>
                    <DataTable
                        columns={columns1}
                        data={totalOrderAnalyticsData?.order_listing}
                        defaultSortFieldId={1}
                        className='analytics_'
                        progressPending={loading}
                    />
                </div>
            </div>
        </>
    )
}

export default TotalOrders