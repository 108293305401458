import React from 'react'
import { templateProfile, templateLogo, pinLocate ,jobrPay ,debitPay ,applePayment } from '../../../utilities/images'


const PaymentTemplateModal = () => {
  return (
    <>
      <div className='newTemplatesModal paymentTemplateSections'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='templateLeft'>
              <form className='tempForm row'>
                <div className='templateSubForm'>
                  <div className='row mt-2'>
                    <div className="mb-4 form-group col-md-12">
                      <label className="deatilsHead mb-2">Duration</label>
                      <select autoComplete="new-password" className='customform-control select' >
                        <option value="operations" disabled>Feature Shop</option>
                        <option value="0">36 hrs</option>
                        <option value="1">Online</option>
                        <option value="2">Both</option>
                      </select>
                    </div>
                    <div className='col-md-12'>
                      <div className='campaignCost'>
                        <h4 className='campCostText'>Campaign Cost</h4>
                        <h3 className='campCostBold'>$9.00</h3>
                        <h6 className='dimensionPara'>Cost Per hour $0.25 </h6>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='campaignPayment'>
                        <h4 className='boldsubSubhead'>Payment Method</h4>
                        <div className='campPaymentMethod active'>
                          <img src={jobrPay} alt="payment Logo Image" className='img-fluid paymentMethodImg' />
                          <h6 className='payText'>JBR Coin</h6>
                        </div>
                        <div className='campPaymentMethod active'>
                          <img src={applePayment} alt="payment Logo Image" className='img-fluid paymentMethodImg' />
                          <h6 className='payText'>Apple pay</h6>
                        </div>
                        <div className='campPaymentMethod'>
                          <img src={debitPay} alt="payment Logo Image" className='img-fluid paymentMethodImg' />
                          <h6 className='payText'>Add Debit/Credit Card</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=' modalfooterBtn'>
                  <button className='me-4 w-100 drafBtn' >Save as Draft</button>
                  <button className='w-100 btnSave' type="submit" >
                    Pay & Publish
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='templateRight'>
              <h5 className='placementText'>Preview</h5>
              <div className='templatePreview'>
                <img src={templateProfile} alt="template Profile Image" className='img-fluid templateProfileImg' />
                <div className='templateInfo'>
                  <img src={templateLogo} alt="template Logo Image" className='img-fluid templateLogo' />
                  <div className='templateProfile'>
                    <h4 className='profileText'>ZARA</h4>
                    <div className='dFlex'>
                      <div className='tempLocate'>
                        <img src={pinLocate} alt="location image" className='img-fluid' />
                        <h6 className='locateSub m-0'>1.3 mile</h6>
                      </div>
                      <div className='tempLocate ms-3'>
                        <span className='dotCircle'></span>
                        <h6 className='locateSub m-0'>Miami, Florida</h6>
                      </div>
                    </div>
                    <div className='tempLable'>Ad</div>
                  </div>
                  <button className='storeBtn'>Store</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentTemplateModal