import React from 'react'
import moment from 'moment-timezone';

const OfferViewModal = (props) => {
    console.log(props?.selectedProduct   +"offerprops")
    return (
        <>
            <div className="scheduleModal">
                <div className="scheduleModalContent">
                    <div className="userProfileinfo_">
                        <img style={{ width: "125px" }} className='scheduleimg me-3' src={props?.selectedProduct?.product?.image} />
                        <div className="userProfileinforight_">
                            <h3 className='subHeading'>{props?.selectedProduct?.product?.name}</h3>
                            <p className='mainlightPhragraph mb-1'>
                                <b>Category: </b>{props?.selectedProduct?.categories?.name}
                            </p>
                            <p className='mainlightPhragraph mb-1'>
                                <b>Sub-category: </b>{props?.selectedProduct?.subcategories?.name}
                            </p>
                            {
                                props?.selectedProduct?.type === "product" ?
                                    <p className='mainlightPhragraph mb-1'>
                                        <b>Brand: </b>{props?.selectedProduct?.brands?.name}
                                    </p> : <></>
                            }
                        </div>
                    </div>
                    <div className="appointmentDetails_">
                        <h3>Offer Details</h3>
                        <div className="appointInner mb-4">
                            <div className="contentappont">
                                <p className='mainlightPhragraph mb-1'>
                                    <b>Cost Price: </b>${props?.selectedProduct?.actual_price}
                                </p>
                                <p className='mainlightPhragraph mb-1'>
                                    <b>Offer Price: </b>${props?.selectedProduct?.offer_price} {`(${props?.selectedProduct?.offer_flag == "per_box" ? "Per Box" : "Per Pack"})`}
                                </p>
                                {
                                    props?.selectedProduct?.offer_flag == "per_box" &&
                                    <p className='mainlightPhragraph mb-1'>
                                        <b>Quantity: </b>{props?.selectedProduct?.quantity}
                                    </p>
                                }

                                <p className='mainlightPhragraph mb-1'>
                                    <b>Offer Validity: </b>till {moment(props?.selectedProduct?.offer_validity).format("MMM DD, YYYY")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OfferViewModal