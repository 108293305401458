
import { combineReducers } from "redux";
import authReducer from "../../src/app/containers/auth/authSlice"
import dashboardReducer from "../../src/app/containers/dashboard/dashboardSlice"
import productReducer from "../../src/app/containers/myProducts/productSlice"
import returnReducer from "../../src/app/containers/myOrders/returnOrder/returnOrderSlice"

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  auth: authReducer,
  dashboard: dashboardReducer,
  products: productReducer,
  return: returnReducer
});

export default rootReducer;