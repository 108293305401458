import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, shield, user } from '../../../../utilities/images';

const NewChats = () => {
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const columns1 = [
        {
            name: '#',
            selector: row => row.id,
            width: "50px"
        },
        {
            name: "Name",
            selector: row => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <img className='userInnerImg' src={user} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ '>{row.name}</span>
                                <p className='mb-0'>
                                    <img src={shield} className='img-fluid me-2' />
                                    <span className='textInner'>260 101 480 0083 </span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "300px",
        },
        {
            name: 'Date',
            selector: row => {
                return (
                    <>
                        <div className='ms-2'>
                            <span className='contentSubtext_'>{row.date}</span>
                            <p className='textInner mt-2'>9:00:23a</p>
                        </div>
                    </>
                );
            },
            width: "220px",
        },
        {
            selector: row => row.value,
            name: 'Ticket id'
        },
        {
            selector: row => row.amount,
            name: 'User Type'
        },
        {
            selector: row => row.subject,
            name: 'Subject'
        },

        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div className='ms-2 mt-2 tableBtn__ text-center'>
                            <Link to='#' className='assignBtn_'>Assign Now</Link>
                            <h5 className='tableHeadsmall'>00:01:29</h5>
                        </div>
                    </>
                );
            },
            width: "170px"
        }
    ];

    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    }
    return (
        <div className='suportTicket'>
            <div className='table-responsive'>
            <DataTable
                pagination
                columns={columns1}
                data={products}
                defaultSortFieldId={1}
                onRowClicked={handleRowClick}
            />
            </div>
        </div>
    )
}

export default NewChats