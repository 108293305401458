import React from 'react'
import { dollor_sign, shareMap, DollorsignLarge, calendar, DollarSign, redMapimg, cashOutImg } from '../../../utilities/images';
import DatePicker from "react-datepicker";
import { useState } from 'react'
import moment from 'moment-timezone'
import products from "../../db/category.json"
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { getBankAccountApiAsync, getJbrUsdTransactionsApiAsync, getWalletBallanceApiAsync } from '../dashboard/dashboardSlice';
import { selectLoginAuth } from '../auth/authSlice';
import { useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CustomModal from '../../components/shared/CustomModal';
import AddbalanceModal from '../dashboard/wallet/addbalanceModal';

const JbrWallet = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [walletTransactions, setWalletTransactions] = useState('');
  const auth = useSelector(selectLoginAuth)
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const [bankDetails, setBankDetails] = useState([]);
  const [walletBalance, setWalletBalance] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const handleRowClick = (e) => {
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };
  const columns1 = [
    {
      name: '#',
      width: "50px",
      selector: (row, i) => i + 1
  },
    {
      name: "Date",
      selector: row => {
        return (
          <>
            <span className='textParagh '>{moment(row?.createdAt).format("MMM DD, YYYY")}</span>
            <span className='prop_span'>{moment(row?.createdAt).format("HH:mm")}</span>
          </>
        );
      },
      width: "100px",
    },
    {
      name: 'Transaction type',
      selector: row => {
        return (
          <>
            <span className='textParagh'>{row?.payment_type}</span>
          </>
        );
      },
      width: "180px",
    },
    {
      name: 'Transaction id',
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.transaction_id?.length > 20 ? row?.transaction_id?.substring(0, 20) + "..." : row?.transaction_id}</span>
          </>
        );
      },
      width: "185px",
    },
    {
      name: 'Amount',
      selector: row => {
        return (
          <>
            <span className='textParagh '>JBR {row?.amount} <img src={row?.payment_type === "issue_sila" ? DollarSign : cashOutImg} alt="DollarSign" /></span>
          </>
        );
      },
      width: "130px",
    },

    {
      selector: row => {
        return (
          <>
            <span className='textParagh'>Cash</span>
          </>
        );
      },
      name: 'Payment Mode'
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh'>{row?.payment_type}</span>
          </>
        );
      },
      name: 'Payment Method'
    },
    {
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center justify-content-center'>
              {/* <a className='arrow_icon' href="/#"> */}
              {/* <i className="fa fa-chevron-right"></i> */}
              {/* </a> */}
            </div>
          </>
        );
      },
      name: '',
      width: "50px"
    },
  ];

  const walletBalanceHandle = () => {
    let data = {
      tokenData: auth?.payload?.token
    }
    setLoading(true)
    dispatch(getWalletBallanceApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setWalletBalance(obj?.payload?.sila_balance)
        setLoading(false)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  const getBankAccountHandle = () => {
    setLoading(true)
    dispatch(getBankAccountApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setBankDetails(obj?.payload)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  const walletTransactionsList = () => {
    let params = {
      'tokenData': auth?.payload?.token,
      'postData': {
        page: currentPage,
        limit: perPage,
        type: 'jbr'
      }
    }
    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        "start_date": moment(startDate).format('YYYY-MM-DD'),
        "end_date": moment(endDate).format('YYYY-MM-DD'),
        "filter": 'custom'
      }
      params = {
        tokenData: auth?.payload?.token,
        postData: newData
      }
    }
    setLoading(true)
    dispatch(getJbrUsdTransactionsApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setWalletTransactions(obj?.payload)
        setTotalRows(obj?.payload?.jbr_transactions?.total)
        setLoading(false)
      })
      .then((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getBankAccountHandle()
    walletBalanceHandle()
    walletTransactionsList()
  }, [endDate, perPage, currentPage])

  return (
    <>
      <div className='walletMain'>
        <div className='topHeader_'>
          <div className="topStickyHeader_">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h2 className="mainHeading">
                  Wallet / <span className="innerSubtext">JBR Wallet</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="availableBlnc mb-3">
          <div className='row'>
            <div className='col-md-6'>
              <div className='leftAvailblnc'>
                <h5 className='validText mb-3'>Available Balance</h5>
                <h4 className='blacktextHead'>{walletTransactions?.available_balance}</h4>
                <Link className='withdraw_btn' onClick={() => {
                  setModalDetail({ show: true, flag: "AddbalanceModal" });
                  setKey(Math.random());
                }}>Withdraw <img src={DollorsignLarge} alt="DollorsignLarge" /></Link>
              </div>

            </div>
            <div className='col-md-6'>
              <div className='leftAvailblnc'>
                <div className='row'>
                  {
                    walletTransactions?.statistics?.length > 0 ?
                      walletTransactions?.statistics?.map((v, i) => {
                        return (
                          <div key={i} className='col-md-6 col-lg-6 customSpce mb-2'>
                            <div className='percentageContainer justify-content-between align-items-center'>
                              <div className='percentageValues'>
                                <h3 className='mainHeadingsub_ '>{v?.count}</h3>
                                <p className='textinner_'>{v?.name}</p>
                              </div>
                              <div className={`percentageRating ${v?.name === 'Total Usd Out' ? "redRating" : ""} mapbg`}>
                                <img src={v?.name === 'Total Usd Out' ? redMapimg : shareMap} className="" alt='ShareMap' />
                                <p className='percentageRate'>{v?.percentage?.toFixed(2)}%</p>
                              </div>
                            </div>
                          </div>
                        )
                      }) : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start TableFilter Area */}
        <div className='tableFilters mb-3'>
          <div className='row'>
            <div className='col-md-12'>
              <div className="calenderIcon">
                <img src={calendar} alt="calender" />
                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/> */}
                <DatePicker
                  className='cateDate'
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Date"
                />
              </div>
            </div>
            {/* <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        Country
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        State
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        ID type
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div> */}
          </div>
        </div>
        {/* End TableFilter Area */}
        {/* Start DataTable */}
        <DataTable
          pagination
          columns={columns1}
          data={walletTransactions?.jbr_transactions?.data}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
          className='transection_datatable'
          paginationServer
          progressPending={loading}
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
        {/* End DataTable */}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "AddbalanceModal" ? "addbalanceModal" : ""
        }
        size={
          "md"
        } child={
          modalDetail.flag === 'AddbalanceModal' ? <AddbalanceModal flag='withdraw' bankDetails={bankDetails} walletBalance={walletBalance} walletList={()=>walletTransactionsList()} close={() => handleOnCloseModal()} />
            : ""
        }
        header={
          <>
            {modalDetail.flag === 'AddbalanceModal'
              ?
              <>
                <div className="modalHeader_">
                  <div className="common_">
                    <h3 className='modalHeading'>Withdraw Wallet Balance</h3>

                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                      <i className="las la-times" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </> : <></>
            }
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default JbrWallet;