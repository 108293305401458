import React, { useState } from 'react'
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import {createTaxAsync,getTaxAsync} from "../../systemConfig/systemConfigSlice";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const CreateTaxModal = (props) => {
    const [value, setValue] = useState(false);
    const [check, setCheck] = useState(false)
    const history = useHistory()
    const [loading, setLoading] = useState([]);
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth);
    const [taxName, setTaxName] = useState()
    const [location, setLocation] = useState()
    const [rate, setRate] = useState()
    const [taxNameE, setTaxNameE] = useState()
    const [locationE, setLocationE] = useState()
    const [rateE, setRateE] = useState()
    const [amount, setAmount] = useState()
    const [createTax, setCreateTax] = useState()
    const handleCoinChange = (event) => {
        if(value === true){
            setValue(false);
        }else{
            setValue(true);
        }
    };
    const handleChange = (e)=>{
        if(check === true){
            setCheck(false)
        }else{
            setCheck(true)
        } 
    }
    const createTaxdata = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                seller_id: auth?.payload?.uniqe_id,
                parent_id: props?.parentId?.id.toString(),
                name: props?.parentId?.business_name ? props?.parentId?.business_name : "store",
                ssn: props?.parentId.ssn.toString(),
                tax_name: taxName,
                tax_rate: rate,
                location: location,
                is_include_tax: check,
                exemption_details: amount ?  [taxNameE,locationE,rateE,amount] : [],
                status: props?.parentId?.status
     }
        }
        setLoading(true)
        dispatch(createTaxAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setCreateTax(obj?.payload ? obj?.payload : [])
                let data = {
                    tokenData: auth?.payload?.token,
                    postData: {
                        seller_id: auth?.payload?.uniqe_id,
                        is_tax_details: true
                    }
                }
                setLoading(true)
                dispatch(getTaxAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false)
                        props.setTaxxData(obj?.payload ? obj?.payload : [])
                        
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
                props.close();
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
        }

  return (
    <>
    <div className='commonPadding_ '>
        <h1 className='userhaeding_'>Details</h1>
        <div className='form-Inventory_ mt-3'>
            <form className="form_horizontal">
                <div className='form-group mb-2'>
                    <div className='row'>
                        <div className='leftLabel col-md-4'>
                            <label className="subtextDark_ ">Tax name</label>
                        </div>
                        <div className='rightLabel col-md-8 ps-0'>
                            <input
                                type="text"
                                className="customform-control"
                                // name="text"
                                placeholder="Sales"
                                value={taxName || ''} 
                                onChange={(e) => setTaxName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-group mb-2'>
                    <div className='row'>
                        <div className='leftLabel col-md-4'>
                            <label className="subtextDark_ ">Tax rate</label>
                        </div>
                        <div className='rightLabel col-md-8 ps-0'>
                            <input
                                type="text"
                                className="customform-control"
                                // name="text"
                                placeholder="%"
                                value={rate || ''} 
                                onChange={(e) => setRate(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-group mb-2'>
                    <div className='row'>
                        <div className='leftLabel col-md-4'>
                            <label className="subtextDark_ ">Location</label>
                        </div>
                        <div className='rightLabel col-md-8 ps-0'>
                            <select className="customform-control" >
                            <option value="">Input box label </option>
                                {/* {options.map((option, index) => (
                                    <option key={index} value={option}> {option} </option>
                                ))} */}
                                <option selected value={location || ''} 
                                onChange={(e) => setLocation(e.target.value)}>
                                    Select one
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>

                        </div>
                    </div>
                </div>

            </form>
            <h1 className='userhaeding_ my-4'>Tax calculation</h1>
            <div className='taxInformation'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='d-flex align-items-center'>
                            <div className='form-group  d-flex'>
                                <input type="checkbox" id="" name="" value={check} onChange={(e) => handleChange(e)} />
                                <label htmlFor="register" className='me-4'></label><br />
                            </div>
                            <div className='operatinText' >
                                <h1 className='smalllightHead_ fw-500'>Include tax in item price</h1>
                                <p className='descriptionPara_ fw-normal'>If tax is included, the tax will appear in your reports, but your customers will not see the tax on their receipt.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='taxInformation businessDetails'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='d-flex'>
                            {/* <div className='opertaingFlag me-3'> */}
                                    <div className="form-check form-switch me-3 mt-2">
                                        <input type="checkbox" value={value} onChange={(e) => handleCoinChange(e)} />
                                    </div>
                                {/* </div> */}
                            <div className='w-100'>
                            <div className='operatinText' >
                                <h1 className='smalllightHead_ fw-500'>Add exemption rule</h1>
                                <p className='descriptionPara_ fw-normal'>Specify when this tax should not be applied.</p>
                                
                            </div>
                                <div className={value === true ? 'exemption me-5 mt-3' : 'exemption me-5 mt-3 d-none'} >
                                    <div className='form-group mb-2'>
                                        <div className='row'>
                                            <div className='leftLabel col-md-6'>
                                                <label className="subtextDark_ ">Tax exemption name</label>
                                            </div>
                                            <div className='rightLabel col-md-6 ps-0'>
                                                <input
                                                    type="text"
                                                    className="customform-control"
                                                    // name="text"
                                                    placeholder="Tax name"
                                                    value={taxNameE || ''} 
                                                    onChange={(e) => setTaxNameE(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group mb-2'>
                                        <div className='row'>
                                            <div className='leftLabel col-md-6'>
                                                <label className="subtextDark_ ">Locations</label>
                                            </div>
                                            <div className='rightLabel col-md-6 ps-0'>
                                                <select className="customform-control" >
                                                <option value="">Input box label </option>
                                                    {/* {options.map((option, index) => (
                                                        <option key={index} value={option}> {option} </option>
                                                    ))} */}
                                                    <option  selected value={locationE || ''} 
                                                    onChange={(e) => setLocationE(e.target.value)}>
                                                    Select location
                                                    </option>
                                                    <option>Category1</option>
                                                    <option>Category2</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group mb-2'>
                                        <div className='row'>
                                            <div className='leftLabel col-md-6'>
                                                <label className="subtextDark_ ">Exempt tax</label>
                                            </div>
                                            <div className='rightLabel col-md-6 ps-0'>
                                                <select className="customform-control" >
                                                <option value="">Input box label </option>
                                                    {/* {options.map((option, index) => (
                                                        <option key={index} value={option}> {option} </option>
                                                    ))} */}
                                                    <option  selected value={rateE || ''} 
                                                    onChange={(e) => setRateE(e.target.value)}>
                                                   Select Exempt tax option
                                                    </option>
                                                    <option>Category1</option>
                                                    <option>Category2</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group mb-2'>
                                        <div className='row'>
                                            <div className='leftLabel col-md-6'>
                                                <label className="subtextDark_ ">Amount </label>
                                            </div>
                                            <div className='rightLabel col-md-6 ps-0'>
                                                <input
                                                    type="text"
                                                    className="customform-control"
                                                    // name="text"
                                                    placeholder="$00.00"
                                                    value={amount || ''} 
                                                    onChange={(e) => setAmount(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-2 text-end'>
                        
                    </div> */}
                </div>
            </div>
            <button className='primary_btn mt-2' onClick={()=>createTaxdata()}>Save</button>
        </div>
    </div>
    </>
  )
}

export default CreateTaxModal