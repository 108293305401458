
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import products from "../../../db/category.json";
import AddUserModal from './addUserModal';
import { dots } from '../../../../utilities/images'
import CustomModal from '../../../components/shared/CustomModal';

const RolesTab = () => {
    const history = useHistory()
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    }

    const columns1 = [
        {
            name: '#',
            selector: row => row.id,
            width: "90px"
        },
        {
            name: 'Role name',
            selector: row => {
                return (
                    <>
                        <span className='textParagh'>Admin</span>
                    </>
                );
            },
            width: "500px",
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>3</span>
                    </>
                );
            },
            name: 'Users'
        },
        {
            name: '',
            selector: row => {
                return (
                    <>
                        <div className='dropdown tableDropdown'>
                            <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                                <i className="fal fa-ellipsis-v icon_"></i>
                                {/* <img src={dots} alt=""/> */}
                            </span>
                            <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                                <li className='dropdown-item' onClick={() => { history.push("/userViewList") }}>View List</li>
                                <li className='dropdown-item' onClick={() => {
                                    setModalDetail({ show: true, flag: "AddnewUser" });
                                    setKey(Math.random());
                                }}>Add new user</li>
                            </div>
                        </div>
                    </>
                );
            },
            width: "50px",
        },
    ];


    return (
        <>
            <div className='rolesUser'>
                <DataTable
                    pagination
                    columns={columns1}
                    data={products}
                    defaultSortFieldId={1}
                    onRowClicked={handleRowClick}
                    className="staffRole"
                />
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "AddnewUser" ? "AddnewuserModal" : ""}
                ids={modalDetail.flag === "AddnewUser" ? "AddnewuserModal" : ""}
                // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
                child={
                    modalDetail.flag === "AddnewUser" ? (
                        <AddUserModal close={() => handleOnCloseModal()} />
                    ) : (
                        ""
                    )
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            <h2 className="modalHeading">
                                Add New User
                            </h2>
                            <button className="closeButton">
                                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                            </button>
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />

        </>
    )
}

export default RolesTab