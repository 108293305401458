import React, { useEffect, useState } from 'react'
import { dots, threeDot } from '../../../utilities/images';
import CustomModal from '../../components/shared/CustomModal';
import MyProductDetials from '../myProducts/myProductDetails';
import { changeServiceAvailabilityStatusApiAsync, getProductServiceListApiAsync, selectProductData } from '../myProducts/productSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import DataTable from 'react-data-table-component';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteProductApiAsync } from '../dashboard/dashboardSlice';
import Swal from 'sweetalert2';

const ServiceListView = (props) => {
    const history = useHistory();
    const [key, setKey] = useState(Math.random());
    const data = Array.from(Array(8).keys())
    const [selectedProduct, setSelectedProduct] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("ServiceTable");
    const [serviceData, setServiceData] = useState([]);
    const productData = useSelector(selectProductData)
    let ProductsData = productData?.payload?.data;
    const auth = useSelector(selectLoginAuth);
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const deleteHandle = (row) => {
        Swal.fire({
            title: 'Do you want to delete the service?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    postData: {
                        product_ids: [row?.id]
                    },
                    tokenData: auth?.payload?.token
                }
                dispatch(deleteProductApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        toast.success("Service deleted successfully")
                        serviceListDataHandle()
                    })
                    .catch((obj) => {
                    })
            }
        })
    }

    const availabilityStatusHandle = (id, status) => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                type: 'digital',
                id: id,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
            },
            statusData: {
                availability_status: status === false ? 1 : 0
            }
        }
        // setLoading(true)
        dispatch(changeServiceAvailabilityStatusApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let data = {
                    tokenData: auth?.payload?.token,
                    postData: {
                        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                        type: "digital",
                        app_name: "merchant",
                        category_ids: props?.categoryId ? props?.categoryId : [],
                        sub_category_ids: props?.subCategoryId ? (props?.subCategoryId).toString() : [],
                        brand_id: props?.brandId ? props?.brandId : [],
                        lat: 32.5534,
                        long: 76.1258,
                        page: currentPage,
                        filter_deleted: true,
                        limit: perPage
                    }
                }
                toast.success("Availability Status Updated")
                // setLoading(true)
                dispatch(getProductServiceListApiAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        setServiceData(obj?.payload?.data)
                        setLoading(false)
                    })
                    .catch((err) => {
                        setLoading(false)
                    })
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const columns1 = [
        {
            selector: row => {
                console.log(row, "rowdata");
                return (
                    <>
                        <div className='productViewCard mb-4 listCardPosition'>
                            <img src={row.image} alt="image" className='img-fluid productImage_' />
                            <div className='productDetials'>
                                <div className='productViewHead'>
                                    <h1 className='productHeading'>{row?.name} </h1>

                                </div>
                                <ul className='productCategory'>
                                    <li>
                                        <p className='smallHeading'>Category</p>
                                        <p className='smallHeading'>{row?.category?.name}</p>
                                    </li>
                                    <li>
                                        <p className='smallHeading'>Sub-category</p>
                                        <p className='smallHeading'>{row?.sub_category?.name}</p>
                                    </li>
                                    {/* <li>
                                        <p className='smallHeading'>Brand</p>
                                        <p className='smallHeading'>{row?.brand?.name}</p>
                                    </li> */}
                                    <li>
                                        <p className='smallHeading'>Staff</p>
                                        <p className='smallHeading'>{row?.staff_count}</p>
                                    </li>
                                    {/* <li>
                                        <p className='smallHeading'>POS Price</p>
                                        <p className='smallHeading'>{row?.price ? `$${row?.price}` : ""}</p>
                                    </li>
                                    <li>
                                        <p className='smallHeading'>Online Price</p>
                                        <p className='smallHeading'>{row?.price ? `$${row?.price}` : ""}</p>
                                    </li> */}
                                    {/* <li>
                                        <p className='smallHeading'>Locations</p>
                                        <p className='smallHeading'>{row?.locations}</p>
                                    </li> */}
                                    <li className='mt-0 mb-0'>
                                        <p className='subtextSmall_'>Availability</p>
                                        <div className='d-flex align-items-center productToggleItems'>
                                            <div className="form-check form-switch me-2 mb-0">
                                                <input
                                                    type="checkbox"
                                                    id="availabilityStatus"
                                                    name="availabilityStatus"
                                                    value="availabilityStatus"
                                                    checked={row?.supplies[0]?.status === true}
                                                    onChange={() => availabilityStatusHandle(row?.id, row?.supplies[0]?.status)}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='cardDropImg'>
                                <div className='dropdown tableDropdown ms-3 '>
                                    <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                                        {/* <i className="fal fa-ellipsis-v icon_"></i> */}
                                        <img src={threeDot} alt="threeDotimage" className='img-fluid' />

                                    </span>
                                    <div className='dropdown-menu tableDropdownMenus ' aria-labelledby='barMenus'>
                                        <li className='dropdown-item' onClick={() => {
                                            setModalDetail({ show: true, flag: "MyProductDetials" });
                                            setKey(Math.random()); setSelectedProduct(row);
                                        }}
                                        >View</li>
                                        {/* <li className='dropdown-item'
                                        >Edit Varients</li> */}
                                        <li className='dropdown-item' onClick={() => history.push({ pathname: `/editService`, state: row })}>Edit</li>
                                        {/* <li className='dropdown-item'>Active</li> */}
                                        <li className='dropdown-item' onClick={() => { row = { ...row, from: "duplicateService" }; history.push({ pathname: `/add-service`, state: row }) }} >Duplicate Service</li>
                                        <li className='dropdown-item' onClick={() => deleteHandle(row)}>Delete</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='productViewCard available_box mb-4'>
                            <div className='productDetials'>
                                <ul className='productCategory'>
                                    <li className='mt-0 mb-0'>
                                        <p className='subtextSmall_'>Action</p>
                                        <div className='d-flex align-items-center productToggleItems'>
                                            <div className="form-check form-switch me-2 mb-0">
                                                <input
                                                    type="checkbox"
                                                    id="availabilityStatus"
                                                    name="availabilityStatus"
                                                    value="availabilityStatus"
                                                    checked={row?.supplies[0]?.status === true}
                                                    onChange={() => availabilityStatusHandle(row?.id, row?.supplies[0]?.status)}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </>
                );
            },
        },
    ];
    const handleRowClick = (e) => {
        setSelectedProduct(e);
        setModalDetail({ show: true, flag: "MyProductDetials" });
        setKey(Math.random());
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const serviceListDataHandle = () => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                type: "digital",
                app_name: "merchant",
                lat: 32.5534,
                long: 76.1258,
                search: props?.keyword || undefined,
                category_ids: props?.categoryId ? props?.categoryId : [],
                sub_category_ids: props?.subCategoryId ? (props?.subCategoryId).toString() : [],
                brand_id: props?.brandId ? (props?.brandId).toString() : [],
                page: currentPage,
                limit: perPage
            }
        }
        if (props?.startDate != null && props?.endDate != null) {
            let newData = {
                ...params.postData,
                "start_date": moment(props?.startDate).format('YYYY-MM-DD'),
                "end_date": moment(props?.endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        else {
            params = {
                tokenData: auth?.payload?.token,
                postData: {
                    seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                    type: "digital",
                    app_name: "merchant",
                    lat: 32.5534,
                    long: 76.1258,
                    category_ids: props?.categoryId ? props?.categoryId : [],
                    search: props?.keyword || undefined,
                    sub_category_ids: props?.subCategoryId ? (props?.subCategoryId).toString() : [],
                    brand_id: props?.brandId ? (props?.brandId).toString() : [],
                    page: currentPage,
                    filter_deleted: true,
                    limit: perPage
                }
            }
        }
        setLoading(true)
        dispatch(getProductServiceListApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setServiceData(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (props?.selectedValue) {
            serviceListDataHandle()
        }
    }, [perPage, currentPage, props?.subCategoryId, props?.brandId, props.endDate])

    useEffect(() => {
        if(props?.keyword == "" || props?.keyword?.length > 2){
            serviceListDataHandle();
        }
    }, [props?.keyword])

    return (
        <div className='brandListmain_'>
            <div className='serviceListView'>
                <section className='productListView  mt-3 viewGrid'>
                    <div className='row'>
                        <DataTable
                            className='columsGrid'
                            columns={columns1}
                            // data={props.ProductList}  
                            data={ProductsData}
                            // progressPending={loading}
                            // defaultSortFieldId={1}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={perPage}
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                        />
                    </div>
                    <CustomModal
                        key={key}
                        show={modalDetail.show}
                        // backdrop="static"
                        showCloseBtn={false}
                        isRightSideModal={false}
                        mediumWidth={false}
                        ids={modalDetail.flag === "MyProductDetials" ? "myProductDetails" : ""}
                        size={modalDetail.flag === "MyProductDetials" ? "xl" : "md"}
                        child={modalDetail.flag === 'MyProductDetials' ? <MyProductDetials close={() => handleOnCloseModal()} selectedProductId={selectedProduct?.id} />
                            : ""
                        }
                        onCloseModal={() => handleOnCloseModal()}
                    />
                </section>
            </div>
        </div>
    )
}

export default ServiceListView