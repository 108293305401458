import React, { useState } from 'react'
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import { calendar } from '../../../../utilities/images'
import { toast } from 'react-toastify';

const InventoryadjustModal = (props) => {
    const [enterprisesDate, setEnterprisesDate] = useState("");
    const [referenceNumber, setReferenceNumber] = useState('');
    const [quantityAdjusted, setQuantityAdjusted] = useState("");
    const [selectedOption, setSelectedOption] = useState('');
    const [selectReason, setSelectReason] = useState("");
    const optionsOfModeAdjustment = ['packet', 'box'];
    const optionsOfReason = ['returned', 'forgotton'];
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const toastId = React.useRef(null);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const handleSelectReason = (e) => {
        setSelectReason(e.target.value)
    }

    const clickHandle = (a, b, c, d, e, f) => {
        if (!selectedOption) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please Select Mode of Adjustment")
            }
            return
        }
        if (!referenceNumber) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please Enter reference number")
            }
            return
        }
        if (!enterprisesDate) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please Select Date")
            }
            return
        }
        if (!selectReason) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please Select any one reason")
            }
            return
        }
        if (!quantityAdjusted) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please Enter Quantity to Adjust")
            }
            return
        }
        props?.inventoryAdjustedData(a, b, c, d, e, f,"InventoryaddnewModal")
        // props?.details("InventoryaddnewModal")
    }


    return (
        <>
            <div className='newinventoryModal commonPadding_'>
                <div className='quantityTop_'>
                    <div className='selectLeft'>
                        <h5 className='lightgreyHeading_ mb-2'>Selected Product:</h5>
                        <h5 className='modalHeading'>{props?.data?.name?.length > 29 ? props?.data?.name?.substring(0, 29) + "..." : props?.data?.name}</h5>
                    </div>
                    <div className='selectRight'>
                        <h5 className='subheadBlue'>Quantity Available</h5>
                        <h4 className='modalHeadingGrey'>{props?.data?.supplies[0]?.rest_quantity}</h4>
                    </div>
                </div>
                <div className='form-Inventory_ mt-3'>
                    <form className="form_horizontal">
                        <div className='form-group mb-3'>
                            <div className='row'>
                                <div className='leftLabel col-md-4'>
                                    <label className="subtextDark_ ">Mode of adjustment</label>
                                </div>
                                <div className='rightLabel col-md-8'>
                                    <select className="customform-control" value={selectedOption} onChange={handleSelectChange} required>
                                        <option value="">Select Input box label</option>
                                        {optionsOfModeAdjustment?.map((option, index) => (
                                            <option key={index} value={option}> {option} </option>
                                        ))}
                                        {/* <option value="" selected>
                                        Input box label
                                    </option>
                                    <option>Category1</option>
                                    <option>Category2</option> */}
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className='form-group mb-3'>
                            <div className='row'>
                                <div className='leftLabel col-md-4'>
                                    <label className="subtextDark_ ">Reference Number</label>
                                </div>
                                <div className='rightLabel col-md-8'>
                                    <input
                                        type="number"
                                        className="customform-control"
                                        // name="text"
                                        placeholder="#"
                                        value={referenceNumber}
                                        onChange={(e) => setReferenceNumber(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-group mb-3'>
                            <div className='row'>
                                <div className='leftLabel col-md-4'>
                                    <label className="subtextDark_ ">Date</label>
                                </div>
                                <div className='rightLabel col-md-8'>
                                    <div className="calenderIcon">
                                        <img src={calendar} alt="calender" />
                                        <DatePicker className='customform-control' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                            dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="MM/dd/yyyy" />
                                        {/* <DatePicker
                                            className='cateDate'
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            placeholderText="Date"
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group mb-3'>
                            <div className='row'>
                                <div className='leftLabel col-md-4'>
                                    <label className="subtextDark_ ">Reason</label>
                                </div>
                                <div className='rightLabel col-md-8'>
                                    <select className="customform-control" value={selectReason} onChange={handleSelectReason} required>
                                        <option value="">Select Reason one</option>
                                        {optionsOfReason?.map((option, index) => (
                                            <option key={index} value={option}> {option} </option>
                                        ))}
                                        {/* <option value="" selected>
                                            Select one
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option> */}
                                    </select>

                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                <div className='quantity_add'>
                    <div className='row'>
                        <div className='col-md-6 ps-0'>
                            <div className='quantityBox_'>
                                <h5 className='buyHeading'>Quantity Adjusted</h5>
                                <input
                                    type="number"
                                    className="customform-control inventoryInput"
                                    name="text"
                                    placeholder=""
                                    value={quantityAdjusted}
                                    onChange={(e) => setQuantityAdjusted(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='quantityBox_'>
                                <h5 className='blacksemiHead_'>New Quantity On hand</h5>
                                <h4 className='blueheadbold_'>{(Number(props?.data?.supplies[0]?.rest_quantity)+ Number(quantityAdjusted))}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='inventButton mt-5'>
                    <button className='declineBtn me-3' onClick={() => props.close()}>Cancel</button>
                    <button className='acceptBtn_' onClick={() => clickHandle(props?.data?.id, quantityAdjusted, selectedOption, selectReason, enterprisesDate, referenceNumber)}>Adjust now</button>
                </div>
            </div>
        </>
    )
}

export default InventoryadjustModal