import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMerchantActiveSubscriptionAsync, getAllPlansAsync } from '../dashboard/dashboardSlice'
import { selectLoginAuth } from '../auth/authSlice'
import AnnualBilling from './annualBilling'
import MonthlyBilling from './monthlyBilling'
import { unwrapResult } from '@reduxjs/toolkit'

const PlansModal = (props) => {
  const [activeTab, setActiveTab] = useState("MonthlyBilling");
  const [monthlyPlan, setMonthlyPlan] = useState([])
  const [yearlyPlan, setYearlyPlan] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState("")
  const auth = useSelector(selectLoginAuth)
  const dispatch = useDispatch()

  const getMerchantActiveSubscription = () => { 
    setLoading(true)
    dispatch(getMerchantActiveSubscriptionAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setSelectedPlan(obj?.payload)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  // this callback function use for pass the monthlyPlan and yearlyPlan modal data in parent modal eg -open the card details modal
  const handleCardInfoflag = (data) => {
    props?.openModalflag(data)
  }

  useEffect(() => {
    // if (dataFetchedRef.current) return;
    // dataFetchedRef.current = true;
    setLoading(true)
    dispatch(getAllPlansAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then(async (obj) => {
        setLoading(false)
        setMonthlyPlan(obj?.payload?.data?.find(val => val?.name === "Monthly"))
        setYearlyPlan(obj?.payload?.data?.find(val => val?.name === "Annually"))
      }
      )
      .catch((obj) => {
        setLoading(false)
      })

      getMerchantActiveSubscription()
  }, [])
  return (
    <>
      {
        loading === true ?
          <div className="pageLoader d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          :
          <div className='plansModal'>
            <div className='plansAreas mt-3'>
              <div className='btn-group justify-content-center w-100 mb-4'>
                <button className={`plansBtns ${activeTab === 'MonthlyBilling' ? 'active' : ""}`}
                  onClick={() => setActiveTab('MonthlyBilling')}
                >Monthly Billing</button>
                <button className={`plansBtns ${activeTab === 'AnnuallyBilling' ? 'active' : ""}`}
                  onClick={() => setActiveTab('AnnuallyBilling')}>Annually Billing</button>
              </div>
              <div className='plansAreasContainer'>
                {activeTab == 'MonthlyBilling'
                  ?
                  <MonthlyBilling cardInfoflag={(data) => handleCardInfoflag(data)} handleChange={(data)=>props.handleSelectedPlan(data)} close={() => props?.close()} selectedPlan={selectedPlan} balance={props?.walletBalance} modalDetails={(e)=>props.details(e)} data={monthlyPlan} />
                  : activeTab == "AnnuallyBilling" ?
                    <AnnualBilling cardInfoflag={(data) => handleCardInfoflag(data)} handleChange={(data)=>props.handleSelectedPlan(data)} close={() => props?.close()} balance={props?.walletBalance} selectedPlan={selectedPlan} modalDetails={(e)=>props.details(e)} data={yearlyPlan} />
                    : ""
                }
              </div>
            </div>
          </div>
      }

    </>
  )
}

export default PlansModal