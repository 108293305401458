import React, { useEffect, useState } from 'react';
import { stepImgLg, rightArrowLg } from '../../../../utilities/images'
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getQrCodeAsync, systemConfigAsync, configureQrCodeApiAsync } from '../systemConfigSlice';
import { logDOM } from '@testing-library/react';
function QrCodeModal(props) {
    const [key, setKey] = useState(Math.random());
    const [activeData, setActiveData] = useState("");
    const [qrCodeData, setQrCodeData] = useState("");
    const [loading, setLoading] = useState([]);
    const [allData, setAllData] = useState([]);
    const [pin, setPin] = useState("");
    console.log(pin, "pincodeeeee");

    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch()

    const getQrData = () => {
        let data = {
            tokenData: auth?.payload?.token,
        }
        setLoading(true)
        dispatch(getQrCodeAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                console.log(obj, "qr code response")
                setQrCodeData(obj?.payload ? obj?.payload : [])
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const verifyQrData = () => {
        let data = {
            "tokenData": auth?.payload?.token,
            "postData": {
                "code": pin,
            }
        }
        setLoading(true)
        dispatch(configureQrCodeApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setQrCodeData(obj?.payload ? obj?.payload : [])
                let systemParams = {
                    tokenData: auth?.payload?.token,
                    postData: {
                        app_name: "pos",
                    }
                }
                dispatch(systemConfigAsync(systemParams))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false)
                        setAllData(obj?.payload ? obj?.payload : [])
                        props.setAllData(obj?.payload ? obj?.payload : [])
                    })
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    // const getSettingsData = () => {
    //     let data = {
    //         tokenData: auth?.payload?.token,
    //         postData: {
    //             app_name: "pos",
    //         }
    //     }
    //     setLoading(true)
    //     dispatch(systemConfigAsync(data))
    //         .then(unwrapResult)
    //         .then((obj) => {
    //             setLoading(false)
    //             setAllData(obj?.payload ? obj?.payload : [])
    //         }
    //         )
    //         .catch((obj) => {
    //             setLoading(false)
    //         })
    // }

    useEffect(() => {
        getQrData();
    }, [])

    return (
        <>
            <div className='twoStepModal'>
                <img  style={{ width: "200px" ,margin: "auto"}} src={qrCodeData?.qrCode} /><br />
                <div className="form-group">
                    <label for="">Pin</label>
                    <input
                        type={"password"}
                        className="form-control"
                        autoComplete="new-password"
                        // id="exampleInputPassword1"
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        placeholder="Pin"
                    />
                </div><br />
                <button className='btn btn-primary' onClick={() => { verifyQrData(); props.close() }}>verify</button>
            </div>

        </>
    );
}

export default QrCodeModal;