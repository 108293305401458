if (process.env.NODE_ENV === "production") {

  module.exports = {
    REACT_APP_PRODUCT_URL: "https://apiproductmgmt.jobr.com/",
    REACT_APP_WALLET_URL: "https://apiwallet.jobr.com/",
    REACT_APP_USER_SERVICES: "https://apiuserservice.jobr.com/",
    REACT_APP_ORDER_URL: "https://apiorder.jobr.com:8004/",
    REACT_APP_CHAT_URL: "https://apichat.jobr.com:8007/",
    REACT_APP_SUPPORT_URL: "https://apisupport.jobr.com/",
  };

} else {

  module.exports = {
    REACT_APP_PRODUCT_URL: "https://apiproductmgmt.jobr.com/",
    REACT_APP_WALLET_URL: "https://apiwallet.jobr.com/",
    REACT_APP_USER_SERVICES: "https://apiuserservice.jobr.com/",
    REACT_APP_ORDER_URL: "https://apiorder.jobr.com:8004/",
    REACT_APP_CHAT_URL: "https://apichat.jobr.com:8007/",
    REACT_APP_SUPPORT_URL: "https://apisupport.jobr.com/",
  };
}
