import React, { useEffect, useState } from "react";
import { infoImg, user } from "../../../utilities/images";
import { CKEditor } from "ckeditor4-react";
import {
  getBrandOfCategoryAsync,
  getCategoriesAsync,
  getServicesAsync,
} from "../dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Select from 'react-select';

const ProductDetails = (props) => {
  const location = useLocation();
  const userData = location.state;
  let productResponse;
  const [productName, setProductName] = useState(userData?.name ?? "");
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [productDescription, setProductDescription] = useState(userData?.description ?? "");
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [services, setServices] = useState([]);
  const [servicesId, setServicesId] = useState(userData?.service_id ?? "Select Services");
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(userData?.category_id ?? "Select Category");
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryId, setSubcategoryId] = useState(userData?.sub_category_id ?? "Select Sub-Category");
  const [brandId, setBrandId] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  const dispatch = useDispatch();

  console.log(userData, "userdtaa")

  const handleKeyDownSubcategory = (event) => {
    console.log("called");
    if (event.key === 'Enter') {
      const newValue = event.target.value.trim();
      if (newValue !== '') {
        // Check if the entered value already exists
        const existingOption = subCategory?.find(option => option.label.toLowerCase() === newValue.toLowerCase());
        if (!existingOption) {
          const newOption = { value: newValue.toLowerCase(), label: newValue };
          if (subCategory?.length > 0) {
            setSubCategory([...subCategory, newOption]);
          }
          else {
            setSubCategory([newOption]);
          }
        }
      }
    }
  };

  const handleServicesChange = (e) => {
    setCategoryLoading(true);
    console.log(e, "response of service change");
    setSelectedService(e)
    setServicesId(e.value);
    setSelectedCategory("")
    setSelectedSubCategory("")
    setCategory([]);
    setCategoryId("");
    setSubCategory([]);
    setSubcategoryId("");
    setBrandId("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        service_id: e.value,
        main_category: true,
        check_product_existance: false
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name
          };
        });
        setCategory(filterResult)
        setCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: e?.value,
          category_id: categoryId,
          sub_category_id: subCategoryId,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleCategoryChange = (e) => {
    setSubCategoryLoading(true)
    setCategoryId(e.value);
    setSelectedCategory(e)
    setSubCategory([]);
    setSubcategoryId("");
    setBrandId("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        need_subcategory: true,
        check_product_existance: false
        // main_category: true,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name
          };
        });
        setSubCategory(filterResult);
        setSubCategoryLoading(filterResult ? false : true)
        productResponse = {
          productName: productName,
          service_id: servicesId,
          category_id: e.value,
          sub_category_id: subCategoryId,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleSubCategoryChange = (e) => {
    setSubcategoryId(e.value);
    setBrandId("");
    setSelectedSubCategory(e)

    productResponse = {
      productName: productName,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: e.value,
      brand_id: brandId,
    };
    props.productDetails(productResponse, "");
  };

  const handleProductName = (e) => {
    setProductName(e.target.value);
    productResponse = {
      productName: e.target.value,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: brandId,
    };
    props.productDetails(productResponse, "");
  };
  const handleProductDescription = (e) => {
    props.productDetails(e, "description");
    setProductDescription(e);
  };

  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "service",
        check_product_existance: false
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name
          };
        });
        setServices(filterResult)
        // setServices(obj.payload.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const setInitialData = () => {
    if (userData?.from === "duplicateService") {
      setProductName(userData?.name ?? "");
      setServicesId(userData?.service_id ?? "");
      handleServicesChange({ target: { value: userData?.service_id ?? "" } });
      setCategoryId(userData?.category_id ?? "");
      handleCategoryChange({ target: { value: userData?.category_id ?? "" } });
      setSubcategoryId(userData?.sub_category_id ?? "");
    }
  };

  const getEditServices = () => {
    let data = {
        tokenData: auth?.payload?.token,
        postData: {
            type: "service",
            check_product_existance: false
        }
    }
    setLoading(true)
    dispatch(getServicesAsync(data))
        .then(unwrapResult)
        .then((obj) => {
            setLoading(false);
            let filterResult = obj?.payload?.data?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.name
                };
            });
            let selectedMastercategory = userData?.service_id.toString()
            setServicesId(selectedMastercategory ? selectedMastercategory : null);
            let selectedService = filterResult.find((item) => item.value == selectedMastercategory);
            setSelectedService(selectedService ? selectedService : null);
            setServices(filterResult)
            let data = {
                tokenData: auth?.payload?.token,
                postData: {
                    service_id: selectedMastercategory,
                    main_category: true,
                    check_product_existance: false
                }
            }
            setLoading(true)
            dispatch(getCategoriesAsync(data))
                .then(unwrapResult)
                .then((obj) => {
                    setLoading(false);


                    let filterResult = obj?.payload?.data?.map((item) => {
                        return {
                            value: item?.id,
                            label: item?.name
                        };
                    });
                    setCategory(filterResult)

                    let selectedCategory = userData?.category_id.toString();
                    setCategoryId(selectedCategory ? selectedCategory : null)
                    let selectedCatrgory = filterResult.find((item) => item.value == selectedCategory);
                    setSelectedCategory(selectedCatrgory ? selectedCatrgory : null);
                    let data = {
                        tokenData: auth?.payload?.token,
                        postData: {
                            category_id: selectedCategory,
                            need_subcategory: true,
                            check_product_existance: false
                        }
                    }
                    setLoading(true)
                    dispatch(getCategoriesAsync(data))
                        .then(unwrapResult)
                        .then((obj) => {
                            setLoading(false);
                            let filterResult = obj?.payload?.data?.map((item) => {
                                return {
                                    value: item?.id,
                                    label: item?.name
                                };
                            });
                            setSubCategory(filterResult);
                            let selectedSubCategory = userData?.sub_category_id.toString();
                            setSubcategoryId(selectedSubCategory ? selectedSubCategory : null)

                            let selectedsubCatrgory = filterResult.find((item) => item.value == selectedSubCategory);
                            setSelectedSubCategory(selectedsubCatrgory ? selectedsubCatrgory : null);
                        }
                        )
                        .catch((obj) => {
                            setLoading(false)
                        })
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        }
        )
        .catch((obj) => {
            setLoading(false)
        })
}
  useEffect(() => {
    getServices();
    setInitialData();
    getEditServices()
  }, []);
  return (
    <>
      <div className="productDetailsContainer">
        <div className="ps-30 pe-30">
          <h2 className="headingBlue">
            Service Details
            <img src={infoImg} alt="InfoImg" className="infoIcon ms-3" />
          </h2>
        </div>
        {/* Start ProductName Area */}
        <div className="productDetailsSpc mt-4">
          <form className="productName">
            <div className="form-group">
              <label className="subheaderHeading mb-2">Service Name</label>
              <div className="inputGroup">
                <input
                  className="customform-control customInput"
                  placeholder="Service name"
                  name="text"
                  type="text"
                  value={productName}
                  onChange={(e) => handleProductName(e)}
                />
              </div>
            </div>
          </form>
        </div>
        {/* End ProductName Area */}
        {/* Start ProductCategory Area */}
        <div className="productCategoryArea mt-4 mb-4">
          <form className="ProductCategory row">
            <div className="mb-5 form-group col-md-12">
              <label className="deatilsHead mb-3 fw-normal">
                Select Master Category
              </label>
              <Select
                value={selectedService}
                onChange={handleServicesChange}
                options={services}
                isSearchable={true}
                placeholder="Select Master Category"
              />
            </div>
            <div className="mb-5 form-group col-md-6">
              <label className="deatilsHead mb-3 fw-normal">
                Select Category
              </label>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                options={category}
                isDisabled={!servicesId}
                isSearchable={true}
                placeholder={
                  category && categoryLoading ? "Loading Categories..." : "Select Category"
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label className="deatilsHead mb-3 fw-normal">
                Select Sub Category
              </label>
              <Select
                value={selectedSubCategory}
                onChange={handleSubCategoryChange}
                onKeyDown={handleKeyDownSubcategory}
                options={subCategory}
                isDisabled={!categoryId}
                isSearchable={true}
                placeholder={
                  subCategory && subCategoryLoading
                    ? "Loading Categories..."
                    : "Select Sub Category"
                }
              />
            </div>
          </form>
        </div>
        {/* End ProductCategory Area */}
        {/* Start CK EditorArea */}
        <div className="productDetailsSpc">
          <CKEditor
            initData={productDescription}
            config={{
              versionCheck: false,
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
                "image",
              ],
            }}
            name="editor"
            readOnly={false}
            onChange={(event, editor) => {
              const data = event.editor.getData();
              handleProductDescription(data);
            }}
            type="classic"
          />
        </div>
        {/* End CK EditorArea */}
      </div>
    </>
  );
};

export default ProductDetails;
