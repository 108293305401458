import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { user, redMapimg, locationBlue, shareMap, profileImg1 } from '../../../utilities/images'
import products from "../../db/category.json"
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../auth/authSlice'
import { getOrderTrackingApiAsync } from '../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import moment from 'moment-timezone'

const OrderTrackinglist = (props) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [orderTracking, setOrderTracking] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const trackingRouteHandle = (deliveryOption, orderId, trackUrl) => {
        if (deliveryOption == 4) {
            window.open(trackUrl, '_blank');
        } else {
            history.push(`/trackingMap/${orderId}`)
        }
    }

    const columns1 = [
        {
            name: '#',
            width: "50px",
            // selector: row => row?.id
            selector: (row, i) => (i + 1 * (perPage * (currentPage - 1)) + 1)
        },
        {
            name: 'Date',
            selector: row => {
                return (
                    <>
                        <div className='ms-2'>
                            <span className='contentSubtext_'>{moment(row?.created_at).format("DD MMM, YYYY")}</span>
                            {/* <p className='textInner mt-2'>{moment(row?.created_at).format("H:mm")}</p> */}
                        </div>
                    </>
                );
            },
            width: "200px",
        },
        {
            selector: row => row?.invoices?.invoice_number,
            name: 'Invoice No.',
            // width: "160px",
        },
        {
            name: "Buyer",
            selector: row => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.seller_details?.user_profiles?.profile_photo ? row?.seller_details?.user_profiles?.profile_photo : profileImg1} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ '>{row?.seller_details?.user_profiles?.firstname + " " + row?.seller_details?.user_profiles?.lastname}</span>
                                {
                                    row?.seller_details?.user_profiles?.current_address?.city ?
                                        <p className='mb-0'>
                                            <img src={locationBlue} className='img-fluid me-2' />
                                            <span className='textInner'>{row?.seller_details?.user_profiles?.current_address?.city}</span>
                                        </p> : <></>}
                            </div>
                        </div>
                    </>
                );
            },
            width: "200px",
        },
        {
            selector: row => row?.total_items,
            name: 'No. of Items',
            width: "120px",// 
        },
        {
            name: "Delivery",
            selector: row => {
                return (
                    <>{
                        row?.delivery_details && row?.delivery_option == 1 ?
                            <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                                <img className='userInnerImg' src={row?.driver_details?.profile_photo ? row?.driver_details?.profile_photo : profileImg1} />
                                <div className='ms-2 text-start'>
                                    <span className='contentSubtext_ '>{row?.delivery_details?.title}</span>
                                    <p className='mb-0'>
                                        {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                                        <span className='textInner'>${row?.delivery_charge}</span>
                                    </p>
                                </div>
                            </div> :
                            row?.delivery_option == 4 ?
                                <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                                    <img className='userInnerImg' src={row?.shipping_details?.image ? row?.shipping_details?.image : profileImg1} />
                                    <div className='ms-2 text-start'>
                                        <span className='contentSubtext_ '>{row?.shipping_details?.title}</span>
                                        <p className='mb-0'>
                                            {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                                            <span className='textInner'>${row?.shipping_charge}</span>
                                        </p>
                                    </div>
                                </div>
                                : <>No Delivery Info</>}
                    </>
                );
            },
            width: "250px",
        },
        {
            selector: row => row?.tracking_number ? row?.tracking_number : "--",
            name: 'Tracker',
            width: "100px"
        },
        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div className='ms-2 tableBtn__'>
                            <button onClick={() => trackingRouteHandle(row?.delivery_option, row?.id, row?.trackurl)} className='secondryInner_'>Track Now <i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </>
                );
            },

            width: "170px"
        },
    ];
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const orderTrackingData = () => {
        let params = {
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                // seller_id: "b169ed4d-be27-44eb-9a08-74f997bc6a2j",
                page: currentPage,
                limit: perPage
            },
            tokenData: auth?.payload?.token
        }
        if (props?.startDate != null && props?.endDate != null) {
            let newData = {
                ...params.postData,
                "start_date": moment(props?.startDate).format('YYYY-MM-DD'),
                "end_date": moment(props?.endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getOrderTrackingApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                console.log(obj, "res of order tracking")
                setOrderTracking(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        orderTrackingData()
    }, [perPage, currentPage, props?.endDate])

    return (
        <section className='recentTransections commonTableheight table-responsive mt-1'>
            {/* <h3 className='textInnerHead mb-4'>Recent Orders</h3> */}
            <DataTable
                pagination
                columns={columns1}
                progressPending={loading}
                data={orderTracking}
                // defaultSortFieldId={1}
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                onRowClicked={handleRowClick}
                selectableRows
            />
        </section>
    )
}

export default OrderTrackinglist