import React from 'react'
import { Link } from 'react-router-dom';
import { outsale_, shield, Phoneimg, locationimg, } from '../../../../utilities/images'
import moment from 'moment-timezone';
import { selectLoginAuth } from '../../auth/authSlice';
import { useSelector } from 'react-redux';

const WalletrefundModal = (props) => {
    let row = props?.selectedValue
    const auth = useSelector(selectLoginAuth)

    return (
        <div className='walletPayment'>
            <div className="commonModal">
                <div className='commonBody p-0'>
                    <div className='subHeader'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="subheaderInnerwallet">
                                    <h5 className='subhaedHeading'>Sender</h5>
                                    <h6 className='subheaderHeading mb-2'>{row?.refund_from?.name}</h6>
                                    <p className='contentSubtextbold'>
                                        <img src={shield} />
                                        <span> {row?.refund_from?.unique_number}</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={Phoneimg} />
                                        <span>  {row?.refund_from?.phone_number}</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={locationimg} />
                                        <span>{row?.refund_from?.address}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="subheaderInnerwallet">
                                    <h5 className='subhaedHeading'>Receiver</h5>
                                    <h6 className='subheaderHeading mb-2'>{auth?.payload?.user?.user_profiles?.firstname+""+auth?.payload?.user?.user_profiles?.lastname}</h6>
                                    <p className='contentSubtextbold'>
                                        <img src={shield} /> <span>{auth?.payload?.id}</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={Phoneimg} /> <span>{auth?.payload?.user?.user_profiles?.full_phone_number}</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={locationimg} />
                                        <span>{auth?.payload?.user?.user_profiles?.current_address?.street_address}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modalContent">
                        <div className='amountDeatils_'>
                            <h3 className='mainbluekHead_'>JBR {row?.amount}</h3>
                            <h5 className='mainlightHead'>Refund Amount</h5>
                            <img className='walletadd' src={outsale_} />

                        </div>
                        <div className='bankInformation'>
                            <p className='infoHeading'><span className='bankheadInnr'>Transaction ID</span> <span className='haedingsemiBold_'>{row?.transaction_id}</span></p>
                            <p className='infoHeading'><span className='bankheadInnr'>Date & Time</span> <span className='haedingsemiBold_'>{moment(row?.updatedAt).format("MMM DD, YYYY hh:mm a")}</span></p>
                        </div>

                        <div className='invoiceLink'>
                            <p><span className='mainlightHead'>Invoice link:</span><small>{row?.invoice_link}</small></p>
                        </div>
                        <div className='transactionStatus'>
                            <p className='mainlightHead mb-3'>Transaction Status</p>
                            <Link to="/walletrefundInvoice" className='pendingSecondary'>{row?.status}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WalletrefundModal
