import React, { useState, useEffect } from 'react'
import { scn, search_light } from '../../../../utilities/images'
import CustomModal from '../../../components/shared/CustomModal';
import MakeofferModal2 from './makeofferModal2';
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { getBrandOfCategoryAsync, getCategoriesAsync, getProductAsync } from '../../dashboard/dashboardSlice'
import { toast } from 'react-toastify';

const OfferModal = (props) => {
    const [appName, setAppName] = useState("");
    const [deliveryOption, setDeliveryOption] = useState("");
    const auth = useSelector(selectLoginAuth);
    const [key, setKey] = useState(Math.random());
    const [masterCategory, setMasterCategory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [masterCategoryId, setMasterCategoryId] = useState("")
    const [brand, setBrand] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectProduct, setSelectProduct] =useState();
    const [subCategory, setSubCategory] = useState([])
    const [subCatedoryId, setSubCatedoryId] = useState("");
    const dispatch = useDispatch()
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleMasterCategoryChange = (e) => {
        setSubCategory([]);
        setBrand([]);
        setProduct([]);
       if(!appName){
        toast.error("please select app name");
        return ;
       }
       if(!deliveryOption){
        toast.error("please select deliveryOption");
        return ;
       }
        setMasterCategoryId(e.target.value)
        setSelectProduct();
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                // main_category: true,
                category_id: e.target.value,
                check_product_existance: false
            }
        }
        setLoading(true)
        dispatch(getCategoriesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setSubCategory(obj?.payload?.data ? obj?.payload?.data : [])
            }
            )
            .catch((obj) => {
                setLoading(false)
            })


        let brandData = {
            tokenData: auth?.payload?.token,
            postData: {
                category_id: e.target.value,
                check_product_existance: false
            }
        }
        dispatch(getBrandOfCategoryAsync(brandData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setBrand(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setLoading(false)
        })

        let productData = {
            tokenData: auth?.payload?.token,
            postData: {
                category_id: e.target.value,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                app_name: "b2b",
                // delivery_options: 1
                app_name: appName,
                delivery_options: deliveryOption
            }
        }
        dispatch(getProductAsync(productData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setProduct(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })    
    }

    const handleSubCategoryChange = (e) => {
        setBrand([]);
        setProduct([]);
        setSubCatedoryId(e.target.value);
        setSelectProduct();
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                // main_category: true,
                category_id: e.target.value,
                check_product_existance: false
            }
        }
        dispatch(getBrandOfCategoryAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setBrand(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
        let productData = {
            tokenData: auth?.payload?.token,
            postData: {
                category_id: masterCategoryId,
                sub_category_ids: e.target.value,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                app_name: appName,
                delivery_options: deliveryOption
                // app_name: "b2c",
                // delivery_options: 1
            }
        }
        dispatch(getProductAsync(productData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setProduct(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }


    const handleBrandChange = (e) => {
        setProduct([]);
        setSelectProduct();
        let productData = {
            tokenData: auth?.payload?.token,
            postData: {
                category_id: masterCategoryId,
                sub_category_ids: subCatedoryId,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                brand_id: e.target.value,
                app_name: appName,
                delivery_options: deliveryOption
                // app_name: "b2c",
                // delivery_options: 1
            }
        }
        dispatch(getProductAsync(productData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setProduct(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })

    }

    const handleProductChange = (e) => {
        let selectProduct = product.find(item  => item.id == e.target.value);
        setSelectProduct(selectProduct);
    }

    const getCategoriesList = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                main_category: true,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                check_product_existance: false
            }
        }
        setLoading(true)
        dispatch(getCategoriesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setMasterCategory(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const handleofferTwo =(offer, flag) =>{
        props.offer(offer, flag);
        props.close();
    }

    useEffect(() => {
        getCategoriesList();
    }, [])
    return (
        <>
            <div className='makeodderBody_'>
                <form className='makeofferDetail horizontalForms'>
                    <div className="input-group offersearch_group mb-4">
                        <img src={search_light} alt="" className="img-fluid searchIcon " />
                        <input type="text" className="customform-control" placeholder="Search product here"
                            aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <button className="scanbtn">
                            <img src={scn} className="img-fluid scanIcon" />
                        </button>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='titleTxt mb-2' htmlFor='amount'>Select App Name</label>
                            <select className='customform-control select ' defaultValue={"Select App Name"} onChange={(e) => {setAppName(e.target.value); setMasterCategory(); getCategoriesList(); setSubCategory([]); setBrand([]); setProduct([])}}>
                                <option value="" >Select App Name</option>
                                <option value="pos" >pos</option>
                                <option value="b2c" >b2c</option>
                                <option value="b2b" >b2b</option>
                            </select> 
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='titleTxt mb-2' htmlFor='amount'>Select Delivery Options</label>
                            <select className='customform-control select ' defaultValue={"Select Delivery Options"} onChange={(e) => {setDeliveryOption(e.target.value); setMasterCategory(); getCategoriesList(); setSubCategory([]); setBrand([]); setProduct([]);}}>
                                <option value="" >Select Delivery Options</option>
                                <option value="3" >Pickup</option>
                                <option value="1" >Delivery</option>
                                <option value="4" >Shipping</option>
                                
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='titleTxt mb-2' htmlFor='amount'>Select Category</label>
                            <select className='customform-control select ' defaultValue={"Select Category"} disabled={!appName || !deliveryOption} onChange={(e) => handleMasterCategoryChange(e)}>
                                <option value="Select Category" >Select Category</option>
                                {
                                    (masterCategory && masterCategory.length > 0) ?
                                        <>
                                            {masterCategory?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                )
                                            })}
                                        </> : <></>
                                }
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='titleTxt mb-2' htmlFor='amount'>Select Sub Category</label>
                            <select className='customform-control select ' defaultValue={"Select Sub Category"} disabled={!masterCategoryId} onChange={(e) => handleSubCategoryChange(e)}>
                                <option value="Select Sub Category" >Select Sub Category</option>
                                {
                                    (subCategory?.length > 0) ?
                                        <>
                                            {subCategory?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                )
                                            })}
                                        </> : <option disabled value="No Sub category Found">No Sub Category Found</option>
                                }
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='titleTxt mb-2' htmlFor='amount'>Select Brand</label>
                            <select className='customform-control select ' defaultValue={"Select Brand"} disabled={!masterCategoryId} onChange={(e) => handleBrandChange(e)}>
                                <option value="Select Brand" >Select Brand</option>
                                {
                                    (brand?.length > 0) ?
                                        <>
                                            {brand?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                )
                                            })}
                                        </> : <option disabled value="No Brand Found">No Brand Found</option>
                                }
                            </select>
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='titleTxt mb-2' htmlFor='amount'>Select Product</label>
                            <select className='customform-control select ' defaultValue={"Select Product"} disabled={!masterCategoryId} onChange={(e) => handleProductChange(e)} >
                                <option value="Select Product" >Select Product</option>
                                {
                                    (product?.length > 0) ?
                                        <>
                                            {product?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index} >{value?.name}</option>
                                                )
                                            })}
                                        </> : <option disabled value="No product Found">No product Found</option>
                                }
                            </select>
                        </div>
                        {selectProduct ? 
                        <div className='col-md-12 '>
                            <div className='selectedProduct_ mb-4'>
                                <h5 className='textInner mb-3'>Selected Product:</h5>
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <div className='productImage_'>
                                            <img className='productimg_' 
                                            // src={imgproduct} 
                                            src={selectProduct?.image}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='productContent'>
                                            <div className='productList'>
                                                <h5 className='textInner mb-1'>Product name:</h5>
                                                <h6 className='textinner_'>{selectProduct?.name}</h6>
                                            </div>
                                            <div className='productList'>
                                                <h5 className='textInner mb-1'>Brand:</h5>
                                                <h6 className='textinner_'>{selectProduct?.brand?.name}</h6>
                                            </div>
                                            <div className='productList'>
                                                <h5 className='textInner mb-1'>Sub-category:</h5>
                                                <h6 className='textinner_'>{selectProduct?.sub_category?.name}</h6>
                                            </div>
                                            <div className='productList'>
                                                <h5 className='textInner mb-1'>Category:</h5>
                                                <h6 className='textinner_'>{selectProduct?.category?.name}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : <></> }
                        <div className='col-md-12'>
                            <div className='nextbtn_'>
                                <button className='submitBtn' data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                                    setModalDetail({ show: true, flag: "MakeOffer2" });
                                    setKey(Math.random());
                                }} disabled={!selectProduct}>NEXT</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "MakeOffer2" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "MakeOffer2" ? "makeOffer2" : ""}
                // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
                child={
                    modalDetail.flag === "MakeOffer2" ? (
                        <MakeofferModal2 close={() => handleOnCloseModal()} selectedproduct={selectProduct} offerTwo={(data, flag)=>handleofferTwo(data, flag)}/>
                    ) :
                        (
                            ""
                        )
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            {modalDetail.flag === "MakeOffer2" ?
                                <h2 className="modalHeading">
                                    Make an Offer
                                </h2>
                                : ""
                            }
                            <button className="closeButton">
                                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                            </button>
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default OfferModal