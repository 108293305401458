import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../../auth/authSlice'
import { addFedexServiceApiAsync, changeShippingStatusApiAsync, getAllShippingServiceApiAsync } from '../../dashboardSlice'
import { toast } from 'react-toastify'
import { unwrapResult } from '@reduxjs/toolkit'

const SelectServiceModal = (props) => {
  const [loading, setLoading] = useState(false)
  const [selectService, setSelectService] = useState("jober")
  const toastId = React.useRef(null)
  const auth = useSelector(selectLoginAuth)
  const dispatch = useDispatch()
  const handleFedexAdd = () => {
    if (selectService == "fedex") {
      props.fedexModalHandle("jober");
    }
    else {
      let params = {
        postData: {
          "credential": {
            "api_key": "l7360e1b7104234a8386e8eed693acef66",
            "secret_key": "2045ec3e8bcd44269de3a8a7f3ddeb9d",
            "shipping_account": "510087020"
          },
          "shipping_service_id": props?.shippingService,
          "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
        },
        tokenData: auth?.payload?.token
      }
      setLoading(true)
      dispatch(addFedexServiceApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg)
          }
          let changeStatusData = {
            postData: {
              "is_connected": true,
              "shipping_service_id": props.shippingService,
              "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
            },
            tokenData: auth?.payload?.token
          }
          dispatch(changeShippingStatusApiAsync(changeStatusData))
            .then(unwrapResult)
            .then((obj) => {
              let data = {
                tokenData: auth?.payload?.token,
                postData: {
                  seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                }
              }
              dispatch(getAllShippingServiceApiAsync(data))
                .then(unwrapResult)
                .then((obj) => {
                  setLoading(false)
                  props.setShippingService(obj?.payload)
                  props.close()
                }
                )
                .catch((obj) => {
                  setLoading(false)
                })
            }
            )
            .catch((obj) => {
              setLoading(false)
            })
        }
        )
        .catch((obj) => {
          setLoading(false)
        })
    }
  }
  return (
    <div className='selectserviceModal_'>
      <h3 className='innerHeading mb-5 text-center'>Connect your Fedex account</h3>
      <div className='servicetabs_'>
        <div className={`accountBoxone_ ${selectService == "jober" ? 'active' : ""}`} onClick={() => setSelectService("jober")}>
          <h4 className='deatilsHead'>Jober</h4>
        </div>
        <div className={`accountBoxone_ ${selectService == "fedex" ? 'active' : ""}`} onClick={() => setSelectService("fedex")}>
          <h4 className='deatilsHead'>Fedex</h4>
        </div>
      </div>

      <div className='serviceFooter_'>
        <button class="staticDarkBtn " type="button" onClick={() => props.close()}>
          <span>Cancel</span></button>
        <button class="staticDarkBtn" type="button" onClick={() => handleFedexAdd()} disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>Connect</span></button>
      </div>
    </div>
  )
}

export default SelectServiceModal