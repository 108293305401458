import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { forgetpassimg } from "../../../utilities/images";
import CustomModal from '../../components/shared/CustomModal';
import ScheduleModal from './ScheduleModal';
import { forgetPasswordAsync, verifyOtpAsync, verifyPasswordAsync } from "./authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import PhoneInput from "react-phone-input-2";
import VerifyModal from "./verifyModal";

const ForgetPassword = () => {
  const [key, setKey] = useState(Math.random());
  const [phoneNo, setPhoneNo] = useState("")
  const [phoneCode, SetPhoneCode] = useState("");
  const [otp, setOtp] = useState("");
  const [userId, SetUserId] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [buttonActive, setButtonActive] = useState(false)
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
    if(phoneNumber?.length == 10){
      setButtonActive(true)
    }
    else{
      setButtonActive(false)
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!phoneCode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select your phone code");
      }
      return false
    }
    if (!phoneNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your phone number");
      }
      return false
    }
    if (!userId) {
      let data = {
        "type": "phone",
        "phone_code": `+${phoneCode}`,
        "phone_no": phoneNo
      }
      setLoading(true)
      await dispatch(forgetPasswordAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false)
          SetUserId(obj?.payload?.user_id)
          setButtonActive(false)
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg)
          }
        }
        )
        .catch((obj) => {
          setLoading(false)
        })
    } else {
      if (otp.length < 5) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Otp must be of 5 digits")
        }
        return
      }
      let data = {
        "user_id": userId,
        "reset_password_otp": otp
      }
      setLoading(true)
      dispatch(verifyPasswordAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false)
          history.push(`/resetPassword/${obj?.payload?.verification_id}`)
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg)
          }
          // props.details("Successful")
        }
        )
        .catch((obj) => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      <div className="commonLogin forgetcommon">
        <div className="container-fluid">
          <div className="row horizontalLogin">
            <div className="col-lg-6">
              <div className="loginForm">
                <div className="loginhead">
                  <h4 className="heading">Have you forgot password?</h4>
                </div>
                <form autoComplete="nope" className="form_horizontal w-100" onSubmit={(e) => { handleSubmit(e) }}>
                  <div className="mb-5 form-group">
                    {/* <label className="form-label">Phone Number</label> */}
                    {/* <input
                      type="text"
                      className="customform-control"
                      name="email"
                      placeholder="Phone Number"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                    /> */}
                    <div className="phone-numbpart">
                      <div className="country-plugin">
                        <div id="result">
                          <PhoneInput
                            country="us"
                            // value={phoneNo}
                            name={generateRandomName()}
                            autoComplete='new-password'
                            enableSearch={true}
                            placeholder="Phone no."
                            onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                          />
                          {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    userId ?
                      <VerifyModal phoneCode={phoneCode} phoneNo={phoneNo} buttonActive={(e) => setButtonActive(e)} SetUserId={SetUserId} otp={otp} setOtp={setOtp} /> : <></>
                  }

                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button className={`loginBtnSign w-100 ${buttonActive ? "buttonActive" :""}`} type="submit"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Next</span>
                    </button>
                    {/* <p className="mt-4 gobacklink">its not your phone number,  <Link to="#" onClick={() => { history.push("/") }}> change it. </Link> </p> */}
                    <p className="mt-4 gobacklink justify-content-center d-flex align-items-center"><i className="fa-solid fa-arrow-left-long" onClick={() => history.push('/')} style={{ cursor: "pointer" }}></i> Go back to <Link to="#" onClick={() => { history.push("/") }}> Login </Link> </p>
                  </div>
                </form>
                <div className="copyRight">
                  <p>All Rights Reserved by JOBR LLC | 2022</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="loginhorrizontal-form ">
                <div className="login_left">
                  <img
                    src={forgetpassimg}
                    className="d-none d-md-block img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "ScheduleModal" ? "scheduleModal" : ""}
        // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
        child={modalDetail.flag === 'ScheduleModal' ? <ScheduleModal close={() => handleOnCloseModal()} />
          : ""
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ForgetPassword;