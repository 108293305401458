import React, { useState } from 'react'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
// import { } from '../../../../utilities'
import CustomModal from '../../../components/shared/CustomModal';
import DeviceDetails from './deviceDetails';




const Device = () => {
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  return (

    <>
      <DashboardNavbar title="Devices" backShow={false} />
      <div className=''>
        <div className='tableSectionBackground'>
          <div className='tableFilters mt-3 mb-3'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='productMainCategory'>

                    <div className='categroyBox'>
                      <select className="cateSelectdFilter" required>
                        <option value="" selected>
                          All Status
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                      </select>
                    </div>

                    <div className='categroyBox'>
                      <select className="cateSelectdFilter" required>
                        <option value="" selected>
                          All Locations
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                      </select>
                    </div>
                    <div className='categroyBox'>
                      <select className="cateSelectdFilter" required>
                        <option value="" selected>
                          All Device Type
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                      </select>
                    </div>
                    <div className='categroyBox'>
                      <select className="cateSelectdFilter" required>
                        <option value="" selected>
                          All Applications
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className='saveBtn' onClick={() => {
            setModalDetail({ show: true, flag: "createTaxModal" });
            setKey(Math.random());
          }}>DeviceMOdal</button>

        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "deviceDetails" ? "deviceDetails" : modalDetail.flag === "deviceDetails" ? "deviceDetails" : ""}
        ids={modalDetail.flag === "deviceDetails" ? "deviceDetails" : modalDetail.flag === "deviceDetails" ? "deviceDetails" : ""}
        size="md"
        child={modalDetail.flag === 'deviceDetails' ? <DeviceDetails />
          :
          <></>
        }
        header={

          <div className="common_ modalHeader_ ">
            <button className="closeButton">
              <i className="las la-times  bgCross" onClick={() => handleOnCloseModal()}></i>
            </button>
          </div>
        }

      />















    </>

  )
}
export default Device