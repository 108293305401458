import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../auth/authSlice'
import { createAttributeApiAsync, getServicesAsync } from '../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import Select from 'react-select';
import CustomModal from '../../components/shared/CustomModal'
import AddAttributesModal from './addAttributesModal'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { add_plus, manage, modalCancel, plusImg } from '../../../utilities/images'
import { toast } from 'react-toastify'

const CreateOptionModal = (props) => {
    const [unitType, setUnitType] = useState("text");
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [ingredients, setIngredients] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(props?.selectedService ? props?.selectedService : null);
    const [servicesId, setServicesId] = useState(props?.selectedProductDtails?.service_id ? props?.selectedProductDtails?.service_id : "");
    const [loading, setLoading] = useState(false)
    const [key, setKey] = useState(Math.random());


    console.log(props?.selectedService, "SSSSSSSSSSSSSSSSSSSSS");
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const toastId = React.useRef(null)
    const handleUnitChange = (e) => {
        setUnitType(e.target.value)
    }
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const getServices = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                type: "product",
            }
        }
        dispatch(getServicesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                let filterResult = obj?.payload?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: item?.name
                    };
                });
                setServices(filterResult)
            }
            )
            .catch((obj) => {
            })
    }

    const handleAttributes = (e) => {
        setIngredients(e);
    }

    const handleServicesChange = (e) => {
        setSelectedService(e)
        setServicesId(e.value);
    }

    console.log(selectedService?.value, "dsssssssssssssssssss");

    const handleAdd = () => {
        let data = {
            "name": name,
            "description": description,
            "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
            "attribute_values": ingredients
        }
        console.log(selectedService,"sssssssssssssssssss");
        if (selectedService?.value || selectedService) {
            data = {
                ...data,
                "service_ids": [Number(selectedService?.value ? selectedService?.value : selectedService)],
            }
        }

        if (!name) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter name");
            }
            return;
        }
        // if (!servicesId) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Please select master category");
        //     }
        //     return;
        // }
        if (!selectedService) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select master category");
            }
            return;
        }
        if (ingredients?.length <= 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please add atleast one attribute");
            }
            return;
        }

        let params = {
            postData: data,
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(createAttributeApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                props?.handleModalChange("AddOptions")
            }
            )
            .catch((obj) => {
                setLoading(false)
            })

        console.log(data, servicesId, "data of atrribute")
    }

    useEffect(() => {
        getServices();
    }, [])

    useEffect(() => {
        if (unitType === "color") {
            setName("Color")
        } else {
            setName("")
        }
    }, [unitType])

    return (

        <>
            <div className='addAttributesModal modalBodyBg'>
                <div className='row'>
                    <div className='col-md-12 me-2'>
                        <label className='subheaderHeading mb-2' htmlFor='amount'>Attribute Type</label>
                        <select autoComplete="new-password" className='customform-control select mb-4' defaultValue={unitType} onChange={(e) => handleUnitChange(e)}>
                            <option value="text">Text</option>
                            <option value="color">Color</option>
                        </select>
                    </div>
                    <div className='col-md-12 me-2'>
                        <label className="deatilsHead mb-2 fw-normal">Attribute Name
                        </label>
                        <div className='form-group'>
                            <input
                                className="form-control inputLable"
                                placeholder="Name"
                                disabled={unitType === "color"}
                                aria-describedby="basic-addon2"
                                name="name"
                                type="text"
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-md-12 me-2 mt-4'>
                        <label className="deatilsHead mb-2 fw-normal">Attribute Description</label>
                        <textarea className="customform-control" id="" rows="4" value={description} onChange={e => setDescription(e.target.value)} placeholder='write your description here' />
                    </div>

                    {/* <div className="mt-4 form-group col-md-12">
                        <label className="deatilsHead mb-3 fw-normal">Select Master Category</label>
                        <Select
                            value={selectedService}
                            onChange={handleServicesChange}
                            options={services}
                            isSearchable={true}
                            placeholder="Select Master Category"
                        />
                    </div> */}

                    {/* Start Attributes Area */}
                    <div className='col-md-12 me-2 mt-5'>
                        <div className='d-flex justify-content-between'>
                            <h3 className='subheaderHeading'>Add Attributes</h3>
                            <Link to='#' className='linkTxt'
                                onClick={() => {
                                    setModalDetail({ show: true, flag: "AddAttributesModal" });
                                    setKey(Math.random());
                                }}
                            >
                                {/* Manage */}
                                {/* <i className="las la-plus"></i> */}
                                <img src={plusImg} className='ms-3' alt='Manage' />
                            </Link>
                        </div>
                        <ul className='attributesListMenus mt-3 '>
                            {ingredients.length > 0 ? <>
                                {ingredients?.map((item, index) => {
                                    return (
                                        <li className='attributesListItems' key={index}>
                                            <div className='col-6 d-flex'>
                                                {unitType == "color" &&
                                                    <div className='me-2' style={{ backgroundColor: `${item.name}`, width: "15px", height: "15px" }}></div>
                                                }
                                                <span className='subtextSmall_ me-5 col-md-6'>{item.name}</span>
                                            </div>
                                            <div className='col-6'>
                                                <span className='subtextDark_ col-md-6'>: {item.description}</span>
                                            </div>
                                        </li>
                                    )
                                })}
                            </> : ""}
                        </ul>
                    </div>
                    {/* End Attributes Area */}


                    <div className='row mt-3'>
                        <div className='col-md-6'>
                            <button className="discardBtn w-100 pt-3 pb-3" onClick={() => props.close()}>Discard</button>
                        </div>
                        <div className='col-md-6'>
                            <button className="saveBtn me-3 w-100 pt-3 pb-3" onClick={() => handleAdd()} disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Add</span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids=
                {
                    modalDetail.flag === "AddAttributesModal" ? "addAttributesModal" : modalDetail.flag === "saleTax" ? "saleModalTax" : ""
                }
                size={modalDetail.flag === "AddOptions" ? "xl" : "lg"}
                child=
                {
                    modalDetail.flag === 'AddAttributesModal' ? <AddAttributesModal name={unitType} close={() => handleOnCloseModal()} attributes={(e) => handleAttributes(e)} value={ingredients} />
                        : <></>
                }
                header={
                    <>
                        <h4 className='modalHeading_'>Add Attributes</h4>
                        <p style={{ cursor: "pointer" }} onClick={handleOnCloseModal} className='modal_cancel'>
                            <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                        </p>
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default CreateOptionModal