import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  search_light, defaultUser, userImage, dotsinbox, sendIcon, profileImg1,
  receiver, profileImg, deleteIcon, photoIcon, chatIconn, videoIcon, folderIcon, discountIcon,
  businessIcon, voiceIcon, translateIcon, shieldIcon, buildingIcon, chatImage_,
  videouserImg, calendarsh2, docupload, Viewimage, downloadimage, userImg_, building_, cardemail_, cardphone_
} from '../../../../utilities/images'
import UploadphotoModal from './uploadphotoModal'
import CustomModal from '../../../components/shared/CustomModal';
import VideocallModal from './videocallModal';
import VideoscheduleModal from './videoscheduleModal';
import OfferModal from './offerModal';
import FileuploadModal from './fileuploadModal';
import ChatComponent from './ChatComponent';
import { ChatNew } from '../..';
import BroadCastMessage from './broadCastMessage';
import BusinesscardModal from './businesscardModal';
import VoicerecordModal from './voicerecordModal';
import ShippingaddressModal from './shippingaddressModal';
import TranslatorModal from './translatorModal';
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../auth/authSlice';
import { sendMessageAsync, getMessageheadsAsync, getMessageListAsync, markAsReadChatApiAsync, deleteSingleMessageApiAsync } from '../messageSlice';
import { unwrapResult } from "@reduxjs/toolkit";
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
// import { Socket } from '../../../components/shared/socket';
import { initializeSocket, getSocket, closeSocket } from '../../../components/shared/socketHandle';
import { getScrollbarSize } from 'rsuite/esm/DOMHelper';


let chatHeadId = ""
const DirectMessage = () => {

  const [messageheadsList, setMessageheadsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [activeSendMessage, setactiveSendMesssage] = useState(false);
  const [activeData, setActiveData] = useState("chat")
  const [sendMessageUser, setSendMessageUser] = useState("");
  const [messageDetails, setMessageDetails] = useState("");
  const [message, setMessage] = useState('');
  const [messageData, setMessageData] = useState([]);
  const [chatHeadData, setChatHeadData] = useState('');
  // const [chatHeadId, setChatHeadId] = useState("")
  const [loading, setLoading] = useState(false);
  // console.log(loading,'loadingggggggggggggg');
  const [loadingChat, setLoadingChat] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const toastId = React.useRef(null)
  const [messageButton, setMessageButton] = useState(false);
  const [firstMessage, setFirstMessage] = useState(false);
  const [activeTab, setActiveTab] = useState("")
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch()
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  // var socket = Socket();
  //  const socket = io(`https://apichat.jobr.com:8007?userId=${auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid}`, { path: '/api/v1/connect'});

  // useEffect(() => {
  //   console.log("in useEffects")
  //   if (socket) {
  //     socket.on('connection', (response) => {
  //       console.log('Connected1', response);
  //     });

  //     // return () => {
  //     //   socket.disconnect();
  //     //   console.log("disconnect")
  //     // };
  //   }
  // }, [])

  useEffect(() => {
    const socket = getSocket();
    if (socket) {
      console.log(socket,'socketconnection');
      socket.on("get_messagehead", (data) => {
        console.log(data, "get_messagehead")
        setMessageheadsList(data?.data?.data);
        setActiveTab(`messageList${data?.data?.data[0]?.id}`)
        if (firstMessage) {
          setChatHeadData(data?.data?.data[0].chatPartner);
          setFirstMessage(false);
          setActiveData("chat");
          const newdata = data?.data?.data[0]
          if (newdata?.recipient_id === auth?.payload?.user?.unique_uuid || newdata?.recipient_id === auth?.payload?.uniqe_id) {
            setSendMessageUser(newdata?.sender_id)
          } else {
            setSendMessageUser(newdata?.recipient_id)
          }
          chatHeadId = newdata?.chats?.chathead_id
        }
      })
    }
  }, []);

  const handleSubmit = (e, flag) => {
    const socket = getSocket();
    // e.preventDefault();
    let params
    if (flag == "text") {
      if (!message) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please enter text message");
        }
        return;
      }
      else {
        params = {
          recipient_id: String(sendMessageUser),
          content: message,
          media_type: flag,
          chatHeadType: "directchat",
          sender_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
        }
      }
    }
    else if (flag == "image") {
      params = {
        recipient_id: String(sendMessageUser),
        media_url: e,
        media_type: flag,
        chatHeadType: "directchat",
        sender_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      }
    }
    else if (flag == "business_card") {
      params = {
        recipient_id: String(sendMessageUser),
        media_type: flag,
        business_card: e,
        chatHeadType: "directchat",
        sender_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      }
    }
    else if (flag == "doc") {
      params = {
        recipient_id: String(sendMessageUser),
        media_type: flag,
        media_url: e,
        chatHeadType: "directchat",
        sender_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      }
    }
    else if (flag == "audio") {
      params = {
        recipient_id: String(sendMessageUser),
        media_type: flag,
        media_url: e,
        chatHeadType: "directchat",
        sender_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      }
    }
    else if (flag == "shipping_address") {
      params = {
        recipient_id: String(sendMessageUser),
        media_type: flag,
        shipping_address: e,
        chatHeadType: "directchat",
        sender_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      }
    }
    else if (flag == "offer") {
      params = {
        recipient_id: String(sendMessageUser),
        media_type: flag,
        offer_id: String(e),
        chatHeadType: "directchat",
        sender_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      }
    }

    console.log("params", params)
    socket.emit("send_message", {
      ...params
    });
    socket.on("send_message", (data) => {
      console.log(data, "send_message")
      setMessage("");
      getMessageheadsList();
      if (firstMessage) {
        setFirstMessage(false);
        getMessageheadsList();
      }

    });
  }

useEffect(()=>{
  const socket = getSocket();
  socket.on("get_messagehead", (data) => {
    console.log(data, "get_messagehead")
    setMessageheadsList(data?.data?.data);
    setActiveTab(`messageList${data?.data?.data[0]?.id}`)
    if (firstMessage) {
      setChatHeadData(data?.data?.data[0].chatPartner);
      setFirstMessage(false);
      setActiveData("chat");
      const newdata = data?.data?.data[0]
      if (newdata?.recipient_id === auth?.payload?.user?.unique_uuid || newdata?.recipient_id === auth?.payload?.uniqe_id) {
        setSendMessageUser(newdata?.sender_id)
      } else {
        setSendMessageUser(newdata?.recipient_id)
      }
      chatHeadId = newdata?.chats?.chathead_id
    }
  })

  socket.on("get_messages", (data) => {
    console.log(data, "get_messages")
    setMessageData(data?.data?.data);
  })

  socket.on("join_room_user", (data) => {
    console.log(data, "join_room_user")
    // setMessageData(data?.data?.data);
  })

  socket.on("delete_messagehead", (data) => {
    console.log(data, "delete_messagehead")
    getMessageheadsList();
    setChatHeadData("")
    setMessageData([])
    setMessageButton(false)
  })
})


  const getMessageheadsList = (data) => {
    const socket = getSocket()
    // setLoading(true)
    socket.emit("get_messagehead", {
      headtype: "directchat",
      user_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
    })
  }

  const getMessageList = (id, flag) => {
    const socket = getSocket()

    if (flag == "removeMsg") {
      socket.emit("get_messages", {
        id: id,
        idtype: 'chathead',
      })
      return;

    }

    socket.emit("get_messages", {
      id: id,
      idtype: 'chathead',
    })

    socket.emit("join_room_user", {
      chathead_id: id,
    })

    socket.on(`JobrUser_${id}_room`, (data) => {
      setActiveData("chat");
      setMessage("");
      getMessageheadsList();
      // setMessageData((messageData) => [...messageData, data?.data])
      setMessageData((prevData) => {
        const isIdPresent = prevData.some(item => item.chathead_id === data?.data?.chathead_id);
        if (isIdPresent) {
          return [...prevData, data?.data];
        } else {
          return prevData; // Return the previous data without any changes
        }

      });
      console.log(data, "connected room response")
    })
  }

  const handleMessageList = (data) => {
    if (data?.recipient_id === auth?.payload?.user?.unique_uuid || data?.recipient_id === auth?.payload?.uniqe_id) {
      setSendMessageUser(data?.sender_id)
    } else {
      setSendMessageUser(data?.recipient_id)
    }
    // setChatHeadId(data?.chats?.chathead_id);
    chatHeadId = data?.chats?.chathead_id
    setMessageButton(true);
  }

  useEffect(() => {
    console.log(auth?.payload?.uniqe_id ?? auth?.payload?.user?.unique_uuid)
    const socket = initializeSocket(auth?.payload?.uniqe_id ?? auth?.payload?.user?.unique_uuid);
    getMessageheadsList();
  }, [])

  useEffect(() => {
    const socket = getSocket()
    if (chatHeadId) {
      if (socket) {
        getMessageList(chatHeadId);
      }
    }
  }, [chatHeadId])

  const deleteSingleMessageData = (messageID, chatId) => {
    const socket = getSocket()
    socket.emit("delete_message", {
      chat_id: messageID,
    })

    socket.on("delete_message", (data) => {
      console.log(data, "delete_message")
      getMessageheadsList();
      getMessageList(chatId, "removeMsg");
    })

  }

  const deleteChatHead = (chathead_id) => {
    const socket = getSocket()
    socket.emit("delete_messagehead", {
      chathead_id: chathead_id,
    })

  }


  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    // Filter the items based on the search term
    const filteredResults = messageheadsList.filter((item) =>
      item?.chatPartner?.organization_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleData = (data) => {
    const userId = data[0]?.user?.unique_uuid
    setSendMessageUser(userId);
    setMessageButton(true);
    setFirstMessage(true);
    setMessageData([]);
  }

  const handleImages = (images, flag) => {
    console.log(images, 'images in direct message');
    // let content = images?.map(item => item?.filePath);
    handleSubmit(images, flag);
  }

  const handleFile = (file, flag) => {
    // let content = file.map(item => item.filePath);
    handleSubmit(file, flag);
  }

  const handleBusinessCard = (business, flag) => {
    handleSubmit(business, flag);
  }

  const handleAudio = (audio, flag) => {
    let audioArray = [];
    audioArray.push(audio);
    handleSubmit(audioArray, flag);
  }

  const handleShippingAddress = (address, flag) => {
    handleSubmit(address, flag);
  }

  const handleOffer = (offer, flag) => {
    handleSubmit(offer, flag);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Messages";
  }, []);
  

  return (
    <>
      <div className='messagechatBox'>
        <div className='row'>
          <div className='col-md-3 pe-0'>
            <div className='chat_inbox'>
              <div className='dropdown newinboxbtn_ '>
                <Link to="#" className="dropdownBtn " id='inboxdropdown' data-bs-toggle='dropdown' aria-expanded='false'>
                  <span className='newdrop me-2'>New</span>
                  <span className='userProfile'>
                    <i className="fal fa-chevron-down"></i>
                  </span>
                </Link>
                <ul className='dropdown-menu chatDropDown' aria-labelledby="dropdownAdminButton">
                  <li className='dropdown-item' onClick={() => setActiveData("newchat")}>
                    <Link to='#' className='subtextSmall_'>New Chat</Link>
                  </li>
                  <li className='dropdown-item' onClick={() => setActiveData("broadcast")}>
                    <Link to='#' className='subtextSmall_'>Broadcast Message</Link>
                  </li>
                </ul>
              </div>

              <div className='searchBox_ mt-2 p-0'>
                <div className="input-group search_group">
                  <img src={search_light} alt="" className="img-fluid searchIcon" />
                  <input type="text" className="form-control" placeholder="Search here"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={searchTerm}
                    onChange={handleSearch} />
                </div>
              </div>

              {/* <div className='inboxshildBtn_ mt-3'>
                <Link to="#">
                  <img className='shildImg me-1' src={shieldIcon} />
                </Link>
                <Link to="#">
                  <img className='shildImg' src={buildingIcon} />
                </Link>
              </div> */}

              <div className='chatDetailbox_ '>
                {loading ?
                  <>
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                  </>
                  :
                  <>
                    {searchTerm && searchResults.length > 0 ?
                      <>
                        {/* <> */}
                        {searchResults?.map((item, index) => {
                          return (
                            <div className={`chatInnerinfo_ newMessage mt-3 ${activeTab === `messageList${item?._id}` ? 'active' : ''}`} key={index}
                              onClick={() => { handleMessageList(item); setActiveTab(`messageList${item?._id}`); setActiveData("chat"); setChatHeadData(item?.chatPartner) }}>
                              <div className='chatUser'>
                                <figure className={item?.chatsis_seen == 0 ? 'chatImg_user' : "mb-0"}>
                                  <img className='userProfile_' src={profileImg1} />
                                </figure>
                              </div>
                              {item?.recipient_id === auth ?
                                <>
                                  <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_">{item?.sender?.user_profiles?.firstname} <Link to="#" className="grpNmame ms-1"> {item?.sender?.user_profiles?.lastname} {item?.sender?.user_profiles?.organization_name}</Link>
                                      <span className='timezoneChat'>{moment(item?.chats?.updatedAt).format('hh:mm A')}</span></h5>
                                    <h5 className="subtextSmallblack_ mt-1">{item?.chats?.content?.length > 25 ? item?.chats?.content?.substring(0, 25) + "..." : item?.chats?.content}</h5>
                                  </div>
                                </>
                                : <>
                                  <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_">{item?.chatPartner?.firstname + " " + item?.chatPartner?.lastname} <Link to="#" className="grpNmame ms-1"> {item?.chatPartner?.organization_name?.length > 12 ? item?.chatPartner?.organization_name?.substring(0, 12) + "..." : item?.chatPartner?.organization_name}</Link>
                                      <span className='timezoneChat'>{moment(item?.chats?.updatedAt).format('hh:mm A')}</span></h5>
                                    <h5 className="subtextSmallblack_ mt-1">{item?.chats?.content?.length === 0 ? "Photo" : item?.chats?.content?.length > 35 ? item?.chats?.content?.substring(0, 35) + "....." : item?.chats?.content}</h5>
                                  </div>
                                </>
                              }
                            </div>
                          )
                        })}
                        {/* </>
                        : <>No Chats Found</> */}
                      </>
                      :
                      <>
                        {messageheadsList && messageheadsList?.length > 0 ?
                          <>
                            {messageheadsList?.map((item, index) => {
                              return (
                                <div className={`chatInnerinfo_ newMessage mt-3 ${activeTab === `messageList${item?._id}` ? 'active' : ''}`} key={index}
                                  onClick={() => { handleMessageList(item); setActiveTab(`messageList${item?._id}`); setActiveData("chat"); setChatHeadData(item?.chatPartner) }}>
                                  <div className='chatUser'>
                                    <figure className={item?.chatsis_seen == 0 ? 'chatImg_user' : "mb-0"}>
                                      <img className='userProfile_' src={item?.chatPartner?.banner_image ? item?.chatPartner?.banner_image : profileImg1} />
                                    </figure>
                                  </div>
                                  {item?.recipient_id === auth ?
                                    <>
                                      <div className='chatuserDetail_'>
                                        <h5 className="subtextSmall_">{item?.sender?.user_profiles?.firstname} <Link to="#" className="grpNmame ms-1"> {item?.sender?.user_profiles?.lastname} {item?.sender?.user_profiles?.organization_name}</Link>
                                          <span className='timezoneChat'>{moment(item?.chats?.updatedAt).format('hh:mm A')}</span></h5>
                                        <h5 className="subtextSmallblack_ mt-1">{item?.chats?.content?.length > 25 ? item?.chats?.content?.substring(0, 25) + "..." : item?.chats?.content}</h5>
                                      </div>
                                    </>
                                    : <>
                                      <div className='chatuserDetail_'>
                                        <h5 className="subtextSmall_">{item?.chatPartner?.firstname + " " + item?.chatPartner?.lastname} <Link to="#" className="grpNmame ms-1"> {item?.chatPartner?.organization_name?.length > 12 ? item?.chatPartner?.organization_name?.substring(0, 12) + "..." : item?.chatPartner?.organization_name}</Link></h5>
                                        <h5 className="subtextSmallblack_ mt-1">{item?.chats?.media_type === "doc" ? "File" : item?.chats?.media_type === "offer" ? "Offer" : item?.chats?.media_type === "image" ? "Photo" : item?.chats?.media_type === "business_card" ? "Business Card" : item?.chats?.content?.length > 35 ? item?.chats?.content?.substring(0, 35) + "....." : item?.chats?.content}</h5>
                                        <h5 className='timezoneChat mt-1  mb-0'>23/10/2023 <span className='ms-1 me-2'>|</span>  {moment(item?.chats?.updatedAt).format('hh:mm A')}</h5>
                                      </div>
                                    </>
                                  }
                                </div>
                              )
                            })}
                          </>
                          : <>No Chats Found</>
                        }
                      </>}
                  </>
                }

                {/* <div className='chatInnerinfo_ '>
                  <div className='chatUser'>
                    <img className='userProfile_' src={defaultUser} />
                  </div>
                  <div className='chatuserDetail_'>
                    <h5 className="subtextSmall_">Brain <Link to="#" className='detailLink'>Carrols Restaurant Group</Link> 12:59 am</h5>
                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className='col-md-9'>
            <div className='rightMessagebox_'>
              {
                activeData === "chat" ?
                  <ChatComponent deleteSingleMessage={(messageID, chatId) => deleteSingleMessageData(messageID, chatId)} deleteChatHead={(chathead_id) => deleteChatHead(chathead_id)} data={setMessageheadsList} chatHeadData={chatHeadData} loadingState={loadingChat} messageData={messageData} setMessageButton={setMessageButton} setMessageData={setMessageData} setChatHeadData={setChatHeadData} id={chatHeadId} />
                  : activeData === "newchat" ? <ChatNew userData={(data) => handleData(data)} />
                    : activeData === "broadcast" ?
                      <BroadCastMessage /> : <></>
              }

              {messageButton ?
                <div className='messageBottom_'>
                  <div className='typeMessgae'>
                    <div className='dropdown typeBtn_'>
                      <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                        <img className='dotIconn_' src={dotsinbox} />
                      </Link>
                      <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton2" >
                        <li><Link to="#" className="dropdown-item chatdropItem " data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                          setModalDetail({ show: true, flag: "UploadPhoto" });
                          setKey(Math.random());
                        }}>
                          <img className='dropIcon' src={photoIcon} />
                          <span className='subtextSmall_'>Photo</span></Link>
                        </li>
                        {/* <li><Link to="#" className="dropdown-item chatdropItem">
                          <img className='dropIcon' src={chatIconn} />
                          <span className='subtextSmall_'>Quick Messages</span></Link>
                        </li>
                        <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                          setModalDetail({ show: true, flag: "VideoCall" });
                          setKey(Math.random());
                        }}>
                          <img className='dropIcon' src={videoIcon} />
                          <span className='subtextSmall_'>Video Call</span></Link>
                        </li>
                        <li><Link to="#" className="dropdown-item chatdropItem" onClick={() => {
                          setModalDetail({ show: true, flag: "FileUpload" });
                          setKey(Math.random());
                        }}>
                          <img className='dropIcon' src={folderIcon} />
                          <span className='subtextSmall_'>File</span></Link>
                        </li>
                        <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                          setModalDetail({ show: true, flag: "MakeOffer" });
                          setKey(Math.random());
                        }}>
                          <img className='dropIcon' src={discountIcon} />
                          <span className='subtextSmall_'>Offer</span></Link>
                        </li>
                        <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                          setModalDetail({ show: true, flag: "BusinessCard" });
                          setKey(Math.random());
                        }}>
                          <img className='dropIcon' src={businessIcon} />
                          <span className='subtextSmall_'>Business Card</span></Link>
                        </li>
                        <li><Link to="#" className="dropdown-item chatdropItem">
                          <img className='dropIcon' src={priceIcon} />
                          <span className='subtextSmall_'>Latest Price</span></Link>
                        </li>
                        <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                          setModalDetail({ show: true, flag: "VoiceRecord" });
                          setKey(Math.random());
                        }}>
                          <img className='dropIcon' src={voiceIcon} />
                          <span className='subtextSmall_'>Voice Messages</span></Link>
                        </li>
                        <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                          setModalDetail({ show: true, flag: "translator" });
                          setKey(Math.random());
                        }}>
                          <img className='dropIcon' src={translateIcon} />
                          <span className='subtextSmall_'>Translator</span></Link>
                        </li>
                        <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                          setModalDetail({ show: true, flag: "ShippingAddress" });
                          setKey(Math.random());
                        }}>
                          <img className='dropIcon' src={shippingIcon} />
                          <span className='subtextSmall_'>Shipping address</span></Link>
                        </li> */}
                      </ul>
                    </div>
                    <textarea rows="2" cols="20"
                      id="messageBox"
                      placeholder="Type your message here"
                      value={message}
                      onChange={handleMessageChange}
                    ></textarea>
                    <button type='submit' className='submitBtn' onClick={(e) => handleSubmit(e, "text")} disabled={buttonLoading}>
                      {buttonLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Send <img className='sendFill' src={sendIcon} /></span>
                    </button>
                  </div>
                </div>
                : ""}
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "UploadPhoto" ? "commonWidth customContent" : "VideoCall" ? "commonWidth customContent" : "ScheduleCall" ? "commonWidth customContent" : "MakeOffer" ? "commonWidth customContent" : "FileUpload" ? "commonWidth customContent" : ""}
        ids={modalDetail.flag === "UploadPhoto" ? "uploadPhoto" : modalDetail.flag === "FileUpload" ? "fileUpload" : modalDetail.flag === "VideoCall" ? "videoCall" : modalDetail.flag === "ScheduleCall" ? "scheduleCall" : modalDetail.flag === "BusinessCard" ? "businessCard" : modalDetail.flag === "VoiceRecord" ? "voiceRecord" :
          modalDetail.flag === "ShippingAddress" ? "shippingAddress" : modalDetail.flag === "translator" ? "translator" : ""}

        child={
          modalDetail.flag === "UploadPhoto" ? (
            <UploadphotoModal close={() => handleOnCloseModal()} images={(data, flag) => handleImages(data, flag)} />
          ) :
            modalDetail.flag === "VideoCall" ? (
              <VideocallModal close={() => handleOnCloseModal()} />
            ) :
              modalDetail.flag === "ScheduleCall" ? (
                <VideoscheduleModal close={() => handleOnCloseModal()} />
              ) :
                modalDetail.flag === "MakeOffer" ? (
                  <OfferModal close={() => handleOnCloseModal()} offer={(data, flag) => handleOffer(data, flag)} />
                ) :
                  modalDetail.flag === "FileUpload" ? (
                    <FileuploadModal flag={"message"} close={() => handleOnCloseModal()} file={(data, flag) => handleFile(data, flag)} />
                  )
                    :
                    modalDetail.flag === "BusinessCard" ? (
                      <BusinesscardModal close={() => handleOnCloseModal()} businessCard={(data, flag) => handleBusinessCard(data, flag)} />
                    ) :
                      modalDetail.flag === "VoiceRecord" ? (
                        <VoicerecordModal close={() => handleOnCloseModal()} audio={(data, flag) => handleAudio(data, flag)} />
                      ) :
                        modalDetail.flag === "ShippingAddress" ? (
                          <ShippingaddressModal close={() => handleOnCloseModal()} shippingAddress={(data, flag) => handleShippingAddress(data, flag)} />
                        ) :
                          modalDetail.flag === "translator" ? (
                            <TranslatorModal close={() => handleOnCloseModal()} />
                          ) :
                            (
                              ""
                            )
        }
        header={
          <div className='modalHeader_'>
            <div className="common_">
              {modalDetail.flag === "UploadPhoto" ?
                <h2 className="modalHeading">
                  Image upload
                </h2>
                : modalDetail.flag === "VideoCall" ?
                  <h2 className="modalHeading">
                    Schedule for Video call
                  </h2>
                  : modalDetail.flag === "MakeOffer" ?
                    <h2 className="modalHeading">
                      Make an Offer
                    </h2>
                    : modalDetail.flag === "FileUpload" ?
                      <h2 className="modalHeading">
                        File Upload
                      </h2>
                      :
                      modalDetail.flag === "BusinessCard" ?
                        <h2 className="modalHeading">
                          Business Card
                        </h2>
                        : modalDetail.flag === "VoiceRecord" ?
                          <h2 className="modalHeading">
                            Record your Audio
                          </h2>
                          : modalDetail.flag === "ShippingAddress" ?
                            <h2 className="modalHeading">
                              Shipping Address
                            </h2>
                            : ""
              }
              <button className="closeButton">
                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
              </button>
            </div>
          </div>
        }

        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default DirectMessage