import React, { useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getProductListByIdAsync, updateInventoryApiAsync } from './productSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';

const EditProductModal = (props) => {
    const [editedData, setEditedData] = useState({ ...props?.selectedProduct });
    console.log(editedData, "datata")
    const [active, setActive] = useState(editedData?.status);
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData({
            ...editedData,
            [name]: Number(value),
        });
    };

    const handleSellingPriceChange = (index, name, value) => {
        const sellingPriceCopy = [...editedData.supply_prices];
        sellingPriceCopy[index][name] = value;
        setEditedData({
            ...editedData,
            supply_prices: sellingPriceCopy
        });
    };

    const getProductListById = (id) => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                id: props?.productId,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                app_name: "merchant",

            }
        }
        dispatch(getProductListByIdAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                props?.handleSave(obj?.payload?.product_detail)
                props?.close()
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    const submitHandle = (e) => {
        // setLoading(true)
        // dispatch(updateInventoryApiAsync(editedData))
        // .then(unwrapResult)
        // .then((obj) => {
        //     getProductListById()
        // })
        // .catch((obj) => {
        //   setLoading(false)
        // })
        props?.close()
    }

    return (
        <>
            <div className='productDetialsHead'>
                <div className='d-flex align-items-center'>
                    <h1 className='textInnerHead'>
                        <span><Link to="#" onClick={() => { props.close() }}> <i className="las la-angle-left me-3 backArrowImg_"></i> </Link></span>
                        Edit Inventory</h1>
                </div>
                {/* <button className='termseditBtn'><img src={penIcon} alt="image" className='me-1' /> Edit</button> */}
            </div>
            <div className='addEmployeeuser createuserprof_'>
                <form autoComplete="nope" className='emplyoeeDetails horizontalForms'>
                    <div className='row'>
                        <div className='form-group col-md-12  mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Sold Quantity</label>
                            <input
                                type='number'
                                name="sold_quantity"
                                className='customform-control'
                                placeholder="Please enter sold quantity"
                                autoComplete="off"
                                value={editedData.sold_quantity}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='form-group col-md-12 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Rest Quantity</label>
                            <input
                                type='number'
                                name="rest_quantity"
                                className='customform-control'
                                placeholder='Please enter rest quantity'
                                autoComplete="off"
                                value={editedData.rest_quantity}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='form-group col-md-12 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Total Quantity</label>
                            <input
                                type='number'
                                name="total_quantity"
                                className='customform-control' 
                                placeholder='Please enter Total Quantity'
                                autoComplete="off"
                                value={editedData.total_quantity}
                                onChange={handleChange}
                            />
                        </div>

                        <div className='form-group col-md-12 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Supply Prices:-</label>
                        </div>

                        {editedData?.supply_prices && editedData?.supply_prices?.map((sellingPriceItem, index) => (
                            <div key={index}>
                                <div className='form-group col-md-12 mb-4'>
                                    <label className='labelTxt mb-3' htmlFor='amount'>App Name</label>
                                    <input
                                        type="text"
                                        name="app_name"
                                        className='customform-control'
                                        placeholder='Please enter app name'
                                        value={sellingPriceItem?.app_name}
                                        disabled
                                    // onChange={(e) =>
                                    //     handleSellingPriceChange(index, 'app_name', e.target.value)
                                    // }
                                    />
                                </div>

                                <div className='form-group col-md-12 mb-4'>
                                    <label className='labelTxt mb-3' htmlFor='amount'>Selling Price</label>
                                    <label>Selling Price:</label>
                                    <input
                                        type="number"
                                        name="selling_price"
                                        className='customform-control'
                                        placeholder='Please enter selling Price'
                                        value={sellingPriceItem.selling_price}
                                        onChange={(e) =>
                                            handleSellingPriceChange(index, 'selling_price', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        ))}

                        <div className='form-group col-md-12 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Status</label>
                            <div className="form-check form-switch">
                                <input type="checkbox"
                                    checked={
                                        active
                                    }
                                    onChange={() => { setActive(prev => !prev) }} />
                            </div>
                        </div>

                        <div className='footerSec mt-4'>
                            {/* <button to='' className='secoundaryBtn me-4'>Cancel</button> */}
                            <button className='submitBtn' type="button" onClick={() => submitHandle()} disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Edit Inventory</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditProductModal
