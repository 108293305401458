import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPasswordAsync, verifyPasswordAsync } from "./authSlice";
import { resetrightimg ,modalCancel } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import SuccessfulCreated from "../auth/successfulModal/successfulCreated";


const ResetPassword = () => {
  const history = useHistory();
  const { id } = useParams()
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [loading, setLoading] = useState(false)
  const [verifyLoader, setVerifyLoader] = useState(false)
  const [password, setPassword] = useState("")
  const toastId = React.useRef(null)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [verificaionCode, setVerificationCode] = useState("")
  const dispatch = useDispatch()
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!password) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter new password");
      }
      return false
    }
    if (!(password.length >= 8 && (/[A-Z]/.test(password)) && (/[a-z]/.test(password)) && (/[0-9]/.test(password)) && (/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)))) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password should be strong");
      }
      return false
    }
    if (password !== confirmPassword) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password and confirm password should be same");
      }
      return false
    }
    let data = {
      "verification_id": id,
      "new_password": password,
      "confirm_password": confirmPassword
    }
    setLoading(true)
    await dispatch(resetPasswordAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.success(obj?.msg)
        // }
        // history.push('/')
        setModalDetail({
          show: true,
          flag: "successcreate"
        });
        setKey(Math.random());
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
      show: false,
      title: "",
      flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
      setModalDetail({
          show: false,
          title: "",
          flag: "",
      });
      setKey(Math.random());
  };
  return (
    <>
      <div className="commonLogin resetcommon">
        <div className="container-fluid">
          <div className="row horizontalLogin">
            <div className="col-lg-6">
              <div className="loginForm">
                <div className="loginhead">
                  <h4 className="heading">Create a New Password</h4>
                  <p className="smallText">Your new password must be different from previous used passwords.</p>
                </div>
                <form autoComplete="nope" className="form_horizontal w-100" onSubmit={(e) => { handleSubmit(e) }}>
                  <div className=" form-group">
                    <label className="form-label">New Password </label>
                    <input
                      className="form-control id_password"
                      placeholder="Password here"
                      name={generateRandomName()}
                      autoComplete="new-password"
                      type={passwordShown1 ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    // maxLength={4}
                    />
                    <span className="toggle_password_ info_icon" onClick={() => { setPasswordShown1(prev => !prev) }}>
                      <span className={passwordShown1 ? "show-icon togglePassword" : "hide-icon togglePassword"}></span>
                    </span>
                    {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                  </div>
                  <p className="textLine mb-4">Must be at least 8 characters</p>
                  <div className="mb-1 form-group">
                    <label className="form-label">Confirm New Password </label>
                    <input
                      className="form-control id_password"
                      placeholder="Password here"
                      name={generateRandomName()}
                      autoComplete="new-password"
                      type={passwordShown2 ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    // maxLength={4}
                    />
                    <span className="toggle_password_ info_icon" onClick={() => { setPasswordShown2(prev => !prev) }}>
                      <span className={passwordShown2 ? "show-icon togglePassword" : "hide-icon togglePassword"}></span>
                    </span>
                    {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                  </div>
                  {/* <div className="mb-1 form-group">
                    <label className="form-label">Verification Code </label>
                    <input
                      className="form-control id_password"
                      placeholder="verification code"
                      name={generateRandomName()}
                      autoComplete="new-password"
                      type="password"
                      value={verificaionCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      maxLength={5}
                    />
                    <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span>
                  </div> */}
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button className="loginBtn mt-4" type="submit" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Set New Password</span>
                    </button>
                  </div>
                </form>
                <div className="copyRight">
                  <p>All Rights Reserved by JOBR LLC | 2022</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="loginhorrizontal-form ">
                <div className="login_left">
                  <img
                    src={resetrightimg}
                    className="d-none d-md-block img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={modalDetail.flag === "successcreate" ? "commonWidth customContent" : ""}
        ids={modalDetail.flag === "successcreate" ? "successfulCreate" :  ""}
        child={
          modalDetail.flag === "successcreate" ? (
            <SuccessfulCreated
              close={() => handleOnCloseModal()}
            />
          ) :
              ""
        }
        header=

        {modalDetail.flag === "successcreate" ?
          <>
            <p onClick={handleOnCloseModal} className='modal_cancel'>
              <img src={modalCancel} className='img-fluid' alt="modalcancelImage" />
            </p>
          </>
          :
            ''
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ResetPassword;
