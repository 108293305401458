import React from 'react'

const RoleDetailsModal = (props) => {
    return (
        <>
            <div className='addEmployeeuser'>
                <form className='emplyoeeDetails horizontalForms'>
                    <div className='row'>
                        <div className='form-group col-md-12  mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'><b>Role Name:</b></label>
                            <p>{props?.selectedProduct?.name}</p>
                        </div>
                        <div className='form-group col-md-12 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'><b>Description:</b></label>
                            <p>{props?.selectedProduct?.description}</p>
                        </div>
                        <div className='form-group col-md-12 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'><b>Select Privileges:</b></label>
                            <span>{props?.selectedProduct?.role_privileges?.map((v, i) => {
                                return (
                                    <React.Fragment key={i}>
                                    {v?.privileges?.name + (i === props?.selectedProduct?.role_privileges?.length - 1 ? "" : ",")}
                                    </React.Fragment>
                                )
                            })}</span>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default RoleDetailsModal
