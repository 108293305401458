import React from 'react'
import { useState } from 'react';
import CustomModal from '../../components/shared/CustomModal';
import ConnectAccountModal from './connectAccountModal';
import DataTable, { createTheme } from 'react-data-table-component';
import { deleteicon, tobaco, viewIcon, OrderDown, calendar, contentImg, modalCancel } from '../../../utilities/images';
import products from "../../db/category.json"
import { useHistory } from 'react-router-dom';
import NewTemplatesModal from './newTemplatesModal';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import DatePicker from "react-datepicker";


const Templates = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const history = useHistory()
  const [key, setKey] = useState(Math.random());
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const data = Array.from(Array(5).keys())
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }
  const columns1 = [
    {
      name: 'Content',
      selector: row => {
        return (
          <>
            <div className='templateContent'>
              <img src={contentImg} alt="contentImage" className='img-fluid contentImg' />
              <div className='templateContentText'>
                <h4 className='notifyHead'>33% Off</h4>
                <h4 className='orderDimensionText'>iPhone 14 Pro Max</h4>
              </div>
            </div>
          </>
        );
      },
      width: "350px"
    },
    {
      name: "Template name",
      selector: row => {
        return (
          <>
            <span className='textParagh '>New Template 1</span>
          </>
        );
      },
      width: "250px"
    },
    {
      name: 'Type',
      selector: row => {
        return (
          <>
            <span className='textParagh '>Placement  Ad</span>
          </>
        );
      },
    },
    {
      name: 'Placement',
      selector: row => {
        return (
          <>
            <span className='textParagh '>Main Home Page</span>
          </>
        );
      },
    },
    {
      name: 'Last Update',
      selector: row => {
        return (
          <>
            <span className='textParagh '>17 Jun, 2020</span>
          </>
        );
      },
    },

    // {
    //   selector: row => {
    //     return (
    //       <>
    //         <button className='importBtn fw-normal pt-2 pb-2 ps-3 pe-3 d-inline-block'>Approved</button>
    //         <button className='pendingBtn_ fw-normal pt-2 pb-2 ps-3 pe-3 d-none'>Pending</button>
    //         <button className='draftsBtn_ fw-normal pt-2 pb-2 ps-3 pe-3 d-none'>Drafs</button>

    //       </>
    //     );
    //   },
    //   name: 'Status'
    // },
    {
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center justify-content-center'>
              {/* <img src={viewIcon} className='actionImg me-3' alt='View-Icon' />
              <img src={deleteicon} className='actionImg' alt='Delete-Icon' /> */}
              <button className='templateDelete me-2'>Delete</button>
              <button className='acceptBtnColor me-2'>Promote</button>
              <button className='templateEdit'>Edit</button>
            </div>
          </>
        );
      },
      name: 'Action',
      width: "300px"
    },

  ];
  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
  }
  return (
    <>
      <DashboardNavbar title="Templates" backShow={false} />
      <div className='templates'>
        <div className='tableSectionBackground '>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='campaignTopHead productMainCategory'>
                <div className='categroyBox dateCategory'>
                  <div className="calenderIcon campCalendar">
                    <img src={calendar} alt="calender" className='campcalendarImg' />
                    {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                    <DatePicker
                      className='cateDate'
                      selected={startDate}
                      onChange={handleDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      placeholderText="Date"
                    />
                    <img src={OrderDown} alt="calender" className='calenderDownIcon' />
                  </div>
                </div>
                <div className='categroyBox'>
                  <select className="cateSelectdFilter" required>
                    <option value="" selected>
                      All Types
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                  </select>
                </div>
                <div className='categroyBox'>
                  <select className="cateSelectdFilter" required>
                    <option value="" selected>
                      All Transactions
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                  </select>
                </div>
                <div className='categroyBox'>
                  <select className="cateSelectdFilter" required>
                    <option value="" selected>
                      All Payment
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='col-lg-12 table-responsive'>
              <DataTable
                pagination
                columns={columns1}
                data={products}
                defaultSortFieldId={1}
                onRowClicked={handleRowClick}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 text-end mt-3">
          <button className='inActiveBtn_ fw-normal pt-2 pb-2' onClick={() => {setModalDetail({ show: true, flag: 'NewTemplates' });setKey(Math.random());}}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
              <path d="M3.5 11C3.5 9.10025 3.50106 7.72573 3.64199 6.67754C3.78098 5.64373 4.04772 5.00253 4.52513 4.52513C5.00253 4.04772 5.64373 3.78098 6.67754 3.64199C7.72573 3.50106 9.10025 3.5 11 3.5H13C14.8998 3.5 16.2743 3.50106 17.3225 3.64199C18.3563 3.78098 18.9975 4.04772 19.4749 4.52513C19.9523 5.00253 20.219 5.64373 20.358 6.67754C20.4989 7.72573 20.5 9.10025 20.5 11V13C20.5 14.8998 20.4989 16.2743 20.358 17.3225C20.219 18.3563 19.9523 18.9975 19.4749 19.4749C18.9975 19.9523 18.3563 20.219 17.3225 20.358C16.2743 20.4989 14.8998 20.5 13 20.5H11C9.10025 20.5 7.72573 20.4989 6.67754 20.358C5.64373 20.219 5.00253 19.9523 4.52513 19.4749C4.04772 18.9975 3.78098 18.3563 3.64199 17.3225C3.50106 16.2743 3.5 14.8998 3.5 13V11Z" stroke="white" />
              <path d="M12 8L12 16" stroke="white" strokeLinejoin="round" />
              <path d="M16 12L8 12" stroke="white" strokeLinejoin="round" />
            </svg>
            New Template</button>
        </div>
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          ids={modalDetail.flag === "NewTemplates" ? "newTemplatesModal" : ""}
          size={modalDetail.flag === "NewTemplates" ? "xl" : "xl"}
          child={modalDetail.flag === 'NewTemplates' ? <NewTemplatesModal close={() => handleOnCloseModal()} />
            : ""
          }
          header=
          {modalDetail.flag === "NewTemplates" ?
            <>
              <h4 className='modalHeading_'>New Template</h4>
              <p onClick={handleOnCloseModal} className='modal_cancel'>
                <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
              </p>
            </>
            :
            ''
          }
          //{
          //   <div className='modalHeader_'>
          //     <div className="common_">
          //       {modalDetail.flag === "NewTemplates" ?
          //         <h2 className="subheaderHeading">
          //           Create New Promotion
          //         </h2>
          //         : ""
          //       }
          //       <button className="closeButton">
          //               <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
          //           </button>
          //     </div>
          //   </div>
          // }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  )
}

export default Templates