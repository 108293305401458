import React, { useState } from 'react'
import { userImg_, building_, cardemail_, cardphone_ } from '../../../../utilities/images';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux'

const BusinesscardModal = (props) => {
    const auth = useSelector(selectLoginAuth)
    const [businessCard, setBusinessCard] = useState({
        firstName: auth?.payload?.user?.user_profiles?.firstname,
        lastName: auth?.payload?.user?.user_profiles?.lastname,
        company_name: auth?.payload?.user?.user_profiles?.organization_name,
        email: auth?.payload?.email ? auth?.payload?.email : auth?.payload?.user?.email ? auth?.payload?.user?.email : auth?.payload?.emaill,
        mobile: auth?.payload?.user?.user_profiles?.full_phone_number
    });


    const sendBusinessCard = (businessCard) => {
        props.businessCard(businessCard, "business_card");
        props.close();
    }


    return (
        <div className='businesscard_ commonBody'>
            <form className='makeofferDetail horizontalForms'>
                <div className="userProfileinfo_">
                    <img className="marchantPro" src={userImg_} alt="profileimg" />
                    <div className="userProfileinforight_">
                        <h3 className='userhaeding_'>{businessCard?.firstName + " " + businessCard?.lastName}</h3>
                        <ul className='contentextLightMenus mt-2'>
                            <li className='contentextLightItems'>
                                <img className='cradInfo_' src={building_} />
                                <span className='ms-2'>{businessCard?.company_name}</span>
                            </li>
                            <li className='contentextLightItems'>
                                <img className='cradInfo_' src={cardemail_} />

                                <span className='ms-2'>{businessCard?.email}</span>
                            </li>
                            <li className='contentextLightItems'>
                                <img className='cradInfo_' src={cardphone_} />
                                <span className='ms-2'>{businessCard?.mobile}</span>
                            </li>
                        </ul>
                    </div>

                </div>
                <div className='sendBtn_ mt-3'>
                    <button className='submitBtn' onClick={() => sendBusinessCard(businessCard)}>Send</button>
                </div>
            </form>
        </div>
    )
}

export default BusinesscardModal