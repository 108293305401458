import React from 'react'
import { questionMark } from '../../../../utilities/images'

const DeleteaddressModal = () => {
  return (
    <div className='deleteaddressModal_'>
            <img className='markquestion_' src={questionMark}/>
            <h5 className='anotherblackText_ mt-5'>Sorry you can not delete Default Address</h5>
    </div>
  )
}

export default DeleteaddressModal