import moment from 'moment-timezone';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json";
import { userImgg } from '../../../../utilities/images'

const LeaveHistory = () => {
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const columns1 = [
        {
          name: '#',
          selector: row => row.id,
          width: "50px" 
        },
        {
          name: "Leave Starts",
          selector: row => {
          return (
                <>
                  <span className='textParagh '>Nov 5, 2022</span>
                </>
              );
          },
        },
        { name: 'Join Back',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Sep 17, 2022</span>
                </>
              );
          },
        },
        { name: 'Leave Type',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Casual</span>
                </>
              );
          },
        },
        { name: 'Reason',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Going to Family Function</span>
                </>
              );
          },
        },
        { name: 'Duration',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>1 Days</span>
                </>
              );
          },
        },
    ];
    
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <>
    <div className='systemEmplyInvoice'>
    <div className='chartsOuter'>
        <div className='row'>
            <div className='col-md-6'>
                <div className="userProfileinfo_">
                        <img className="marchantPro" src={userImgg} alt="profileimg" />
                        <div className="userProfileinforight_">
                            <h3 className='mainHeading'>Lillie A. </h3>
                            <button className='updatePriceBtn mt-2'>Active</button>
                            <ul className='contentextLightMenus mt-3'>
                                <li className='contentextLightItems'>
                                    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0404 9.37377L13.5702 10.9035C13.8076 11.1409 13.8076 11.5258 13.5702 11.7632C12.2867 13.0467 10.2548 13.1911 8.80266 12.102L8.06418 11.5481C6.69501 10.5213 5.47874 9.30499 4.45187 7.93582L3.898 7.19734C2.80894 5.74525 2.95334 3.71332 4.23682 2.42985C4.47422 2.19245 4.85912 2.19245 5.09651 2.42985L6.62623 3.95956C7.01675 4.35008 7.01675 4.98325 6.62623 5.37377L6.18116 5.81884C6.07297 5.92703 6.04615 6.09231 6.11458 6.22916C6.90568 7.81137 8.18863 9.09432 9.77084 9.88542C9.90769 9.95385 10.073 9.92703 10.1812 9.81884L10.6262 9.37377C11.0168 8.98325 11.6499 8.98325 12.0404 9.37377Z" stroke="#275AFF"/>
                                    </svg>
                                    <span className='ms-2'>4703-515-1193</span> 
                                </li>
                                <li className='contentextLightItems'>
                                    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2.66675" y="4" width="10.6667" height="8" rx="2" stroke="#275AFF"/>
                                    <path d="M2.66675 6L7.10565 8.21945C7.66871 8.50098 8.33145 8.50098 8.89451 8.21945L13.3334 6" stroke="#275AFF"/>
                                    </svg>
                                    <span className='ms-2'>vidal.webe2@gmail.com</span> 
                                </li>
                                <li className='contentextLightItems'>
                                    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 8C13 11.2093 9.63256 13.4165 8.42475 14.105C8.15889 14.2565 7.84111 14.2565 7.57526 14.105C6.36745 13.4165 3 11.2093 3 8C3 5 5.42267 3 8 3C10.6667 3 13 5 13 8Z" stroke="#275AFF"/>
                                    <circle cx="7.99992" cy="7.9987" r="2.16667" stroke="#275AFF"/>
                                    </svg>
                                    <span className='ms-2'>4318 Daffodil Lane, Savage, Virginia(VA), 20763</span> 
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
            <div className='col-md-6'>
                <ul className="customStatus_">
                    <li className='blueboldHead'>
                        <strong>Joined Date</strong><span className='lightsubHead'>Sep 20, 2022</span>
                    </li>
                    <li className='blueboldHead'>
                        <strong>Active Since</strong> <span className='lightsubHead'>265 days</span>
                    </li>
                    <li className='blueboldHead'>
                    <strong>Employment Type</strong><span className='lightsubHead'>Full-Time</span>
                    </li>
                    <li className='blueboldHead'>
                    <strong>Leave taken</strong><span className='lightsubHead'>3 days</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
   
    
</div>
    <div className='logHistory'>
         <div className='commonTables mt-3 mb-3'>
          <div className='tableFilters row'>
            <div className='col-md-3'>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    leave type
                    </option>
                    <option>leave type1</option>
                    <option>leave type2</option>
                </select>
            </div>
            <div className='col-md-3'>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    Year
                    </option>
                    <option>2000</option>
                    <option>2001</option>
                </select>
            </div>
          </div>
        </div>
        <div className='commonTableheight table-responsive'>
         <DataTable
          pagination
          columns={columns1}
          data={products}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
        </div>
    </div>
    </>

  )
}

export default LeaveHistory