import React, { useState } from 'react'
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import { calendar, productList1, productList2, productList3, OrderDown, productList4 } from '../../../../utilities/images'
import PurchaseList from './purchaseList';
import PurchaseView from './purchaseView';
import DashboardNavbar from '../../dashboard/DashboardNavbar';


const PurchaseTracking = () => {
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [activeTab, setActiveTab] = useState("PurchaseList");
    const [monthlyPlan, setMonthlyPlan] = useState([])
    const [yearlyPlan, setYearlyPlan] = useState([])
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    return (
        <>
            <DashboardNavbar title="Purchase Tracking" backShow={false} />
            <div className='orderTrack'>

                {/* <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>Purchase Tracking</span>
                            </h2>
                        </div>
                    </div>
                </div>
                 </div> */}
                <div className='tableSectionBackground'>
                    <div className='tableFilters '>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='productMainCategory tracking_'>
                                        <div className='categroyBox dateCategory'>
                                            <div className="calenderIcon campCalendar">
                                                <img src={calendar} alt="calender" className='campcalendarImg' />
                                                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                                <DatePicker
                                                    className='cateDate'
                                                    selected={startDate}
                                                    onChange={handleDateChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    placeholderText="Date"
                                                />
                                                <img src={OrderDown} alt="calender" className='calenderDownIcon' />
                                            </div>
                                        </div>


                                        {/* <div className='categroyBox'>
                                            <select className="cateSelectdFilter" required>
                                                <option value="" selected>
                                                    All Locations
                                                </option>
                                                <option>L1</option>
                                                <option>L2</option>
                                            </select>
                                        </div> */}
                                    </div>
                                </div>

                                {/* <div className='col-md-3'>
                                    <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                Country
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                                </div>
                                <div className='col-md-3'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                        State
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            Id type
                                            </option>
                                            <option>Category1</option>
                                            <option>Category2</option>
                                        </select>
                                    </div> */}
                                <div className='col-md-6'>
                                    <div className='productListTab text-end'>
                                        <button className={`plansBtns ${activeTab === 'PurchaseList' ? 'active' : ""}`}
                                            onClick={() => setActiveTab('PurchaseList')}
                                        >
                                            <img src={productList1} alt="image" className='showImage' />
                                            <img src={productList2} alt="image" className='hideImage' />
                                        </button>
                                        <button className={`plansBtns ${activeTab === 'PurchaseView' ? 'active' : ""}`}
                                            onClick={() => setActiveTab('PurchaseView')}>
                                            <img src={productList3} alt="image" className='hideImage' />
                                            <img src={productList4} alt="image" className='showImage' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className='col-lg-12'>
                        <div className='plansAreasContainer'>
                            {activeTab == 'PurchaseList'
                                ?
                                <PurchaseList data={monthlyPlan} endDate={endDate} startDate={startDate} />
                                : activeTab == "PurchaseView" ?
                                    <PurchaseView data={yearlyPlan} endDate={endDate} startDate={startDate} />
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PurchaseTracking
