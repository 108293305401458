import React, { useEffect, useState } from "react";
import {
  getLanguagesListApiAsync,
  updateUserSettingAsync,
} from "../systemConfigSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const AddlanguageModal = ({
  getAllLanguageList,
  close,
  getSelectedLanguages,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [getLanguagesList, setGetLanguagesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const onSelectLanguage = (data, id) => {
    if (
      data?.name === "English" &&
      data?.country === "United States" &&
      data?.lang_code === "en"
    ) {
      return;
    }
    setSelectedLanguages((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  // API for add specific Language from list...................................

  const addLanguage = () => {
    setButtonLoading(true);
    const getLatest = getLanguagesList
      ?.filter((item) => selectedLanguages?.includes(item?.id))
      ?.map((value) => {
        return { id: value?.id, status: 1 };
      })
      ?.filter((val) => val?.id);

    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        app_name: "pos",
        languages: getLatest,
      },
    };

    dispatch(updateUserSettingAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (obj.status_code === 200) {
          setButtonLoading(false);
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg);
          }
          getAllLanguageList();
          close();
        }
      })
      .catch((obj) => {});
  };

  // API for get all Language list...................................
  const handleGetLanguages = () => {
    let data = {
      postData: {
        app_name: "pos",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getLanguagesListApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        const allLanguageList = obj?.payload?.filter(
          (item) => item?.lang_code != null
        );
        setGetLanguagesList(allLanguageList);
        const selectedLanguageIds = getSelectedLanguages;
        const getActiveLanguages = obj?.payload?.filter((item) =>
          selectedLanguageIds?.map((item) => item?.id)?.includes(item?.id)
        );
        setSelectedLanguages(getActiveLanguages?.map((item) => item?.id));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetLanguages();
  }, []);

  return (
    <>
      <div className="addlanguageContent mt-3">
        <div className="addStoreForm">
          <div className="addlanguagebox_">
            {loading ? (
              <>
                <div className="loaderOuter">
                  <div className="spinner-grow loaderSpinner text-center my-5"></div>
                </div>
              </>
            ) : (
              getLanguagesList.map((data, index) => {
                return (
                  <div
                    key={index}
                    className={`countryLanguage_  my-3${
                      selectedLanguages?.includes(data?.id) ? " activelang" : ""
                    }`}
                    onClick={() => onSelectLanguage(data, data?.id)}
                  >
                    <img
                      src={data?.flag}
                      alt="langImg"
                      width={50}
                      height={50}
                      className="img-fluid rightImg me-3"
                    />
                    <span className="smalblueText_">{data?.name}</span>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <div className="addCustomerBtn mt-4">
        <button
          className="discardBtn w-100"
          type="button"
          onClick={() => {
            close();
          }}
        >
          Cancel
        </button>
        <button
          className="saveBtn w-100"
          type="button"
          onClick={addLanguage}
        >
          {buttonLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          Add
        </button>
      </div>
    </>
  );
};

export default AddlanguageModal;
