import moment from 'moment-timezone'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DatePicker from "react-datepicker";
import LogHistory from './logHistory';
import LeaveHistory from './leaveHistory';
const SystememployeHistory = () => {
  const [key, setKey] = useState(Math.random());
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('logHistory');
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  return (
    <>
      <div className='topHeader_'>
        <div className="topStickyHeader_ mb-3">
          <div className="row  align-items-center">
            <div className="col-md-6">
              <div className='anotherHeader'>
                <i className="las la-angle-left"></i>
                <h2 className="innerSubheadNormal">Teri J Henderson</h2>
                {/* <span className="pendingStatus">Pending</span> */}
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='logHistorybox'>
        <div className='row mb-4 mt-3'>
          <div className='col-md-12'>
            <Link to="#"
              className={`${activeTab == 'logHistory' ? 'valuesTimeswallet active' : ''} valuesTimeswallet`} onClick={() => { setActiveTab('logHistory') }}>
              Log History
            </Link>
            <Link to="#" className={`${activeTab == 'leaveHistory' ? 'valuesTimeswallet active' : ''} valuesTimeswallet`}
              onClick={() => { setActiveTab('leaveHistory') }}>
              Leave History
            </Link>
          </div>
        </div>
      </div>
      <div className='logTabsDetails'>
        {activeTab == 'logHistory'
          ?
          <LogHistory />
          :
          <LeaveHistory />
        }
      </div>
    </>
  )
}

export default SystememployeHistory