import React from 'react'

const WithdrawpreviewModal = (props) => {

    function addThousandSeparator(number) {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <>
            <div className='withdrawModal_'>
                <div className='currencyRight_'>
                    <h4 className='semiboldHeading_'>You will Receive</h4>
                    <h3 className='monsmianHead_'>${addThousandSeparator(props?.amount / 100)}</h3>
                </div>
                <div className='previewStatement_ mt-4'>
                    <div className='row'>
                        <div className='col-md-5 mb-2'>
                            <div className='valuewithdrawleft_'>
                                <h5 className='labelTxt'>Bank Name:</h5 >
                            </div>
                        </div>
                        <div className='col-md-7 mb-2'>
                            <div className='valuewithdraw_'>
                                <h5 className='labelTxt fw-bold'>{props?.accountName?.bank_name}</h5 >
                            </div>
                        </div>
                        <div className='col-md-5 mb-2'>
                            <div className='valuewithdrawleft_'>
                                <h5 className='labelTxt'>Account Number:</h5 >
                            </div>
                        </div>
                        <div className='col-md-7 mb-2'>
                            <div className='valuewithdraw_'>
                                <h5 className='labelTxt fw-bold'>{props?.accountName?.account_number}</h5 >
                            </div>
                        </div>
                        <div className='col-md-5 mb-2'>
                            <div className='valuewithdrawleft_'>
                                <h5 className='labelTxt'>Account Name:</h5 >
                            </div>
                        </div>
                        <div className='col-md-7 mb-2'>
                            <div className='valuewithdraw_'>
                                <h5 className='labelTxt fw-bold'>{props?.accountName?.account_owner_name}</h5 >
                            </div>
                        </div>
                        <div className='col-md-5 mb-2'>
                            <div className='valuewithdrawleft_'>
                                <h5 className='labelTxt'>Routing Number:</h5 >
                            </div>
                        </div>
                        <div className='col-md-7 mb-2'>
                            <div className='valuewithdraw_'>
                                <h5 className='labelTxt fw-bold'>{props?.accountName?.routing_number}</h5 >
                            </div>
                        </div>
                        {/* <div className='col-md-5 mb-2'>
                            <div className='valuewithdrawleft_'>
                                <h5 className='labelTxt'>Bank City/State:</h5 >
                            </div>
                        </div>
                        <div className='col-md-7 mb-2'>
                            <div className='valuewithdraw_'>
                                <h5 className='labelTxt fw-bold'>{props?.accountName?.bank_name}</h5 >
                            </div>
                        </div> */}
                        {/* <div className='col-md-5 mb-2'>
                            <div className='valuewithdrawleft_'>
                                <h5 className='labelTxt'>Address line :</h5 >
                            </div>
                        </div>
                        <div className='col-md-7 mb-2'>
                            <div className='valuewithdraw_'>
                                <h5 className='labelTxt fw-bold'>{props?.accountName?.bank_name}</h5 >
                            </div>
                        </div> */}
                    </div>
                </div>

            </div>
            <div className='modalbottonFooter_ widthdrawBtnns_ mt-5'>
                <button onClick={() => props?.close()} className='discardBtn' type="submit" >
                    <span>Discard</span>
                </button>
                <button className='saveBtn ' type="submit" onClick={(e) => props?.handleWithdraw(props.amount, props?.accountName?.account_name)} disabled={props.buttonLoading}>
                    {props.buttonLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Withdraw Funds</span>
                </button>
            </div>
        </>
    )
}

export default WithdrawpreviewModal