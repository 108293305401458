import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, profileImg1, shield, user } from '../../../../utilities/images';
import { unwrapResult } from '@reduxjs/toolkit';
import { getDepartmentDataInSupportApiAsync, getSupportTicketDataApiAsync } from '../../dashboard/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';

const ResolveTicket = (props) => {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch()
  const [departmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [supportData, setSupportData] = useState([]);
  const history = useHistory()
  const [enterprisesDate, setEnterprisesDate] = useState("")
  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };
  const columns1 = [
    {
      name: '#',
      selector: row => row?.id,
      width: "70px"
    },
    {
      name: "Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <img className='userInnerImg' src={row?.user_detail?.user_profiles?.profile_photo ? row?.user_detail?.user_profiles?.profile_photo : profileImg1} />
              <div className='ms-2'>
                <span className='contentSubtext_ '>{row?.user_detail?.user_profiles?.firstname}</span>
                <p className='mb-0'>
                  <img src={shield} className='img-fluid me-2' />
                  <span className='textInner'>260 101 480 0083 </span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "300px",
    },
    {
      name: 'Date',
      selector: row => {
        return (
          <>
            <div className='ms-2'>
              <span className='contentSubtext_'>{moment(row?.created_at).format("MMM DD, YYYY")}</span>
              <p className='textInner mt-2'>{moment(row?.created_at).format('hh:mm a')}</p>
            </div>
          </>
        );
      },
      width: "220px",
    },
    {
      selector: row => `#${row?.id}`,
      name: 'Ticket id',
      width: "120px",
    },
    {
      selector: row => row?.user_detail?.user_profiles?.seller_type,
      name: 'User Type',
      width: "120px",
    },
    {
      selector: row => departmentData?.find(v=>v?.id === row?.department_id)?.name,
      name: 'Department',
      width: "140px",
    },
    {
      selector: row => `${row?.subject?.name} Issue`,
      name: 'Subject',
      width: "120px",
    },
    {
      selector: row => row?.support_agent?.user_profiles?.firstname +" " +row?.support_agent?.user_profiles?.lastname,
      name: 'Responsible',
      width: "140px",
    },
    {
      name: 'Status',
      selector: row => {
        return (
          <>
            <div className='ms-2'>
              <span className='personalinfoHead mt-21234'>Resolved | {row?.support_agent?.user_profiles?.firstname +" " +row?.support_agent?.user_profiles?.lastname}</span>
              <p className='tablesubHead mt-2'>{moment(row?.status?.created_at).format("MMM DD, YYYY | hh:mm a")}</p>
            </div>
          </>
        );
      },
      width: "170px"
    },
    {
      name: '',
      selector: row => {
        return (
          <>
            <i className="fal fa-chevron-right" onClick={() => { history.push("/resolveticketDetail") }}></i>
          </>
        );
      },
      width: "50px",
    },
  ];


  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
  }
  const getSupportData = () => {
    let params = {
      "postData": {
        page: currentPage,
        limit: perPage,
        filter: 'resolved_tickets'
      },
      "tokenData": auth?.payload?.token
    }
    if (props?.startDate && props?.endDate) {
      let newData = {
          ...params.postData,
          "start_date": moment(props?.startDate).format('YYYY-MM-DD'),
          "end_date": moment(props?.endDate).format('YYYY-MM-DD')
      }
      params = {
          tokenData: auth?.payload?.token,
          postData: newData
      }
  }
    setLoading(true)
    dispatch(getSupportTicketDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setSupportData(obj?.payload?.data)
        setTotalRows(obj?.payload?.total)
        props.setResolvedTicketCount(obj?.payload?.data?.length)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  const getAllDepartment = () => {
    setLoading(true)
    dispatch(getDepartmentDataInSupportApiAsync(auth?.payload?.token))
        .then(unwrapResult)
        .then((obj) => {
            setDepartmentData(obj?.payload)
            setLoading(false)
        }
        )
        .catch((obj) => {
            setLoading(false)
        })
}

  useEffect(() => {
    getSupportData()
    getAllDepartment()
  }, [currentPage, perPage, props?.endDate])
  return (
    <div className='suportTicket'>
      <div className='table-responsive'>
        <DataTable
          pagination
          columns={columns1}
          data={supportData}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
          progressPending={loading}
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </div>
    </div>
  )
}

export default ResolveTicket