import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { user, shield, locationBlue, shareMap, profileImg1 } from '../../../../utilities/images'
import products from "../../../db/category.json"
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const ManufacturList = (props) => {
    const { flag } = useParams();
    const history = useHistory();
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };

    const handlePageChange = (page) => {
        props.setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        props.setPerPage(currentRowsPerPage);
        props.setCurrentPage(1);
    };
    const columns1 = [
        {
            name: '#',
            width: "70px",
            selector: row => row?.id


        },
        {
            name: "Manufacturer",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.user_profiles?.profile_photo ? row?.user_profiles?.profile_photo : profileImg1} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ '>{row?.user_profiles?.firstname + " " + row?.user_profiles?.lastname}</span>
                                <p className='mb-0'>
                                    <img src={shield} className='img-fluid me-2' />
                                    <span className='textInner'>260 101 480 0083 </span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "300px",
        },
        {
            selector: row => row?.user_profiles?.full_phone_number,
            name: 'Registered Phone',
            width: "175px",
        },
        {
            name: 'Location',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <h6 className='personalinfoHead mb-0'>{row?.user_profiles?.current_address?.city + "," + row?.user_profiles?.current_address?.state}</h6>
                            <p className='subtextSmall_ mt-0'>{row?.user_profiles?.current_address?.country}</p>
                        </div>
                    </>
                );
            },
            width: "200px",
        },
        {
            name: 'Email Address',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <p className='subtextSmall_ mt-0'>{row?.email}</p>
                        </div>
                    </>
                );
            },
            width: "200px",
        },
        // {
        //     name: 'App Tracker',
        //     selector: row => {
        //         return (
        //             <>
        //                 {
        //                     row?.app_tracker ?
        //                         <div className='ms-2 tableBtn__'>
        //                             <span className='personalinfoHead me-2'>{row?.app_tracker}</span>
        //                             <i className="fa-solid fa-arrow-up-right-from-square"></i>
        //                         </div> : ""
        //                 }
        //             </>
        //         );
        //     },

        //     width: "150px"
        // },
        {
            selector: row => row?.master_category?.name,
            name: 'Master Category',
            width: "175px"
        },
        {
            selector: row => row?.products,
            name: 'Products'
        },


        {
            name: 'Total Payment',
            selector: row => {
                return (
                    <>
                        <strong onClick={() => handleRowClick(row)}> ${row?.total_payment?.toFixed(2)} </strong>
                    </>
                )

            },
            width: "150px"
        },
        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div className='ms-2 tableBtn__'>
                            <Link to="#" className='completeStatus'>{row?.is_active === true ? 'Active' : "Inactive"}</Link>
                            {/* <i className="fal fa-chevron-right ms-3" aria-hidden="true"></i> */}
                        </div>
                    </>
                );
            },

            width: "120px"
        },



    ];
    const handleRowClick = (e) => {
        console.log("rowclicked", e)
        flag === 'consumer' ? void (0) : history.push(`/userDetails/${window.location.pathname.split("/")[2]}/${e?.unique_uuid}`)
    }
    return (
        <>
            <section className='recentTransections mt-1 table-responsive'>
                {/* <h3 className='textInnerHead mb-4'>Recent Orders</h3> */}
                <DataTable
                    pagination
                    columns={columns1}
                    data={props?.data}
                    customStyles={customStyles}
                    defaultSortFieldId={1}
                    onRowClicked={handleRowClick}
                    progressPending={props.loading}
                    paginationServer
                    paginationTotalRows={props.totalRows}
                    paginationPerPage={props.perPage}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    selectableRows
                />
            </section>
        </>
    )
}

export default ManufacturList