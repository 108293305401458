import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { unwrapResult } from '@reduxjs/toolkit'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import TimePicker from 'react-time-picker';
import moment from 'moment-timezone'
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { selectAddressList, selectLoginAuth } from '../../auth/authSlice'
import { updateSellerInfoApiAsync } from '../dashboardSlice'
import { dayList, options } from '../../../../utilities/helpers'

const SetUpAddressModal = (props) => {
   
    const location = useLocation();
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [pickupTime, setPickupTime] = useState("1 hours")
    const [pickupInstruction, setPickupInstruction] = useState("")

    const addresslist = useSelector(selectAddressList)
    const [businessHours, setBusinessHours] = useState([])
    const [startMondayTime, setStartMondayTime] = useState("");
    const [endMondayTime, setEndMondayTime] = useState('');
    const [startTuesddayTime, setStartTuesdayTime] = useState("");
    const [endTuesdayTime, setEndTuesdayTime] = useState('');
    const [startWednesdayTime, setStartWednesdayTime] = useState("");
    const [endWednesdayTime, setEndWednesdayTime] = useState('');
    const [startThursdayTime, setStartThursdayTime] = useState("");
    const [endThursdayTime, setEndThursdayTime] = useState('');
    const [startFridayTime, setStartFridayTime] = useState("");
    const [endFridayTime, setEndFridayTime] = useState('');
    const [startSaturdayTime, setStartSaturdayTime] = useState("");
    const [endSaturdayTime, setEndSaturdayTime] = useState('');
    const [startSundayTime, setStartSundayTime] = useState("");
    const [endSundayTime, setEndSundayTime] = useState('');
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const toastId = React.useRef(null)

    const [selectedOptions, setSelectedOptions] = useState([]);

    let selectedOptionNew = []
    const handleCheckboxChange1 = (option, flag) => {
        if (selectedOptions.find(val => val?.id == option?.id)) {
            selectedOptionNew = selectedOptions.filter((item) => item?.id !== option?.id)
            setSelectedOptions(selectedOptionNew);
        } else {
            selectedOptionNew = [...selectedOptions, option]
            setSelectedOptions([...selectedOptions, option]);
        }

        let startTime = (flag == 1) ? startMondayTime :
            (flag == 2) ? startTuesddayTime :
                (flag == 3) ? startWednesdayTime :
                    (flag == 4) ? startThursdayTime :
                        (flag == 5) ? startFridayTime :
                            (flag == 6) ? startSaturdayTime :
                                startSundayTime

        let endTime = (flag == 1) ? endMondayTime :
            (flag == 2) ? endTuesdayTime :
                (flag == 3) ? endWednesdayTime :
                    (flag == 4) ? endThursdayTime :
                        (flag == 5) ? endFridayTime :
                            (flag == 6) ? endSaturdayTime :
                                endSundayTime

        if (selectedOptionNew.find((day) => day.id == 1)) {
            setStartMondayTime(startTime);
            setEndMondayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 2)) {
            setStartTuesdayTime(startTime);
            setEndTuesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 3)) {
            setStartWednesdayTime(startTime);
            setEndWednesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 4)) {
            setStartThursdayTime(startTime);
            setEndThursdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 5)) {
            setStartFridayTime(startTime);
            setEndFridayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 6)) {
            setStartSaturdayTime(startTime);
            setEndSaturdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 7)) {
            setStartSundayTime(startTime);
            setEndSundayTime(endTime)
        }
    };

    function convertTo24HourFormat(time12Hour) {
        // Split the input time string into hours and minutes
        const [time, period] = time12Hour.split(' ');
        const [hours, minutes] = time.split(':');

        // Convert hours to an integer
        let hours24Hour = parseInt(hours, 10);

        // Adjust hours based on AM or PM
        if (period === 'PM' && hours24Hour !== 12) {
            hours24Hour += 12;
        } else if (period === 'AM' && hours24Hour === 12) {
            hours24Hour = 0;
        }

        // Format hours and minutes with leading zeros
        const formattedHours = hours24Hour.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');

        // Combine the hours and minutes into the 24-hour format
        const time24Hour = `${formattedHours}:${formattedMinutes}`;
        return time24Hour;
    }
    const filterData = () => {
        setPickupInstruction(props?.addressDataModal?.product_availability?.pickup_instructions ? props?.addressDataModal?.product_availability?.pickup_instructions: "")
        setPickupTime(props?.addressDataModal?.product_availability?.pickup_time ? props?.addressDataModal?.product_availability?.pickup_time : "1 hours")
    }


    const handleStartTimeChange = (newStartTime, flag) => {
        if (flag == "monday") {
            setStartMondayTime(newStartTime)
        }
        if (flag == "tuesday") {
            setStartTuesdayTime(newStartTime)
        }
        if (flag == "wednesday") {
            setStartWednesdayTime(newStartTime)
        }
        if (flag == "thursday") {
            setStartThursdayTime(newStartTime)
        }
        if (flag == "friday") {
            setStartFridayTime(newStartTime)
        }
        if (flag == "saturday") {
            setStartSaturdayTime(newStartTime)
        }
        if (flag == "sunday") {
            setStartSundayTime(newStartTime)
        }
    };

    const handleEndTimeChange = (newEndTime, flag) => {
        if (flag == "monday") {
            setEndMondayTime(newEndTime)
        }
        if (flag == "tuesday") {
            setEndTuesdayTime(newEndTime)
        }
        if (flag == "wednesday") {
            setEndWednesdayTime(newEndTime)
        }
        if (flag == "thursday") {
            setEndThursdayTime(newEndTime)
        }
        if (flag == "friday") {
            setEndFridayTime(newEndTime)
        }
        if (flag == "saturday") {
            setEndSaturdayTime(newEndTime)
        }
        if (flag == "sunday") {
            setEndSundayTime(newEndTime)
        }
    };

    const handleCopy = (flag) => {
        setSelectedOptions([])
    }

    const handleChange = (e) => {
        setPickupTime(e.target.value)
    }

    const handleCheckboxChange = (event) => {
        var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
        if (event.target.checked) {
            updatedList = businessHours?.length > 0 ? [...businessHours, event.target.value] : [event.target.value];
        } else {
            updatedList.splice(businessHours?.indexOf(event.target.value), 1);
        }
        setBusinessHours(updatedList);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        let addressData = {}
        if (pickupInstruction === "") {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter pickup instruction");
            }
            return false
        }
        // if (businessHours && businessHours?.length <= 0) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Please select business hours");
        //     }
        //     return false
        // }
        // if ((businessHours?.includes("monday") && !startMondayTime) ||
        //     (businessHours?.includes("tuesday") && !startTuesddayTime) ||
        //     (businessHours?.includes("wednesday") && !startWednesdayTime) ||
        //     (businessHours?.includes("thursday") && !startThursdayTime) ||
        //     (businessHours?.includes("friday") && !startFridayTime) ||
        //     (businessHours?.includes("saturday") && !startSaturdayTime) ||
        //     (businessHours?.includes("sunday") && !startSundayTime)
        // ) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Please select correct start time");
        //     }
        //     return false
        // }
        // if ((businessHours?.includes("monday") && !endMondayTime) ||
        //     (businessHours?.includes("tuesday") && !endTuesdayTime) ||
        //     (businessHours?.includes("wednesday") && !endWednesdayTime) ||
        //     (businessHours?.includes("thursday") && !endThursdayTime) ||
        //     (businessHours?.includes("friday") && !endFridayTime) ||
        //     (businessHours?.includes("saturday") && !endSaturdayTime) ||
        //     (businessHours?.includes("sunday") && !endSundayTime)
        // ) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Please select correct end time");
        //     }
        //     return false
        // }

        let postData = {
            "id": props.addressId,
            "product_availability": {
                "pickup_time": pickupTime,
                "pickup_instructions": pickupInstruction
            }
        }

        addressData = {
            postData: postData,
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(updateSellerInfoApiAsync(addressData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                props?.getAddressMerchant()
                props?.close()
                // history.push('/setup-address')
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        filterData()
    }, [])
    return (
        <div className='setModalSection'>
            <form className="form_horizontal locationForm_" >
                <div className='setupModalBg'>
                    <h6 className='dollarSign mb-3'>Information at checkout</h6>
                    <div className="mb-3">
                        <label className="expectedHeading mb-2">Expected Local Pickup time </label>
                        <select className='customform-control select customformModal' value={pickupTime ? pickupTime : "1 hours"} onChange={(e) => handleChange(e)}>
                            <option value="1 hours">Usually ready in 1 hours</option>
                            <option value="2 hours">Usually ready in 2 hours</option>
                            <option value="3 hours">Usually ready in 3 hours</option>
                        </select>
                        {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                    <div className='mb-3 orderPickUp'>
                        {/* <label className="dollarSign mb-3"> Order ready for pickup notification</label><br /> */}
                        <label className="expectedHeading mb-2"> Pickup instructions</label>
                        <textarea className="customform-control mb-0" id="" rows="4" value={pickupInstruction} onChange={e => setPickupInstruction(e.target.value)} placeholder='write your description here' />
                    </div>

                    {/* <div className="mb-4 orderPickUp">
                        <label className="deatilsHead mb-3"> Business Hours</label>

                        <div className='checkboxGroup businessChekbox d-block'>
                            {dayList.map((item, index) => {
                                return (
                                    <div className='checkboxInputs' key={index}>
                                        <input type="checkbox" id={item.day} className='checkBox' name={item.day} value={item.day}
                                            onChange={(e) => handleCheckboxChange(e)} checked={businessHours?.includes(item.day)} />
                                        <label htmlFor={item.day} className='checkBoxTxt'>{item.value}</label>
                                        {
                                            businessHours?.includes(item.day) &&
                                            <>
                                                <div style={{ display: "flex" }} className='timerBox_'>
                                                    <div style={{ display: "flex" }} className='innertimedate_'>
                                                        <p className='timehead_'>Start Time:</p>
                                                        <TimePicker
                                                            onChange={(e) => handleStartTimeChange(e, item.day)}
                                                            value={item.id == 1 ? startMondayTime : item.id == 2 ? startTuesddayTime : item.id == 3 ? startWednesdayTime : item.id == 4 ? startThursdayTime : item.id == 5 ? startFridayTime : item.id == 6 ? startSaturdayTime : startSundayTime}
                                                            format="h:mm a"
                                                            showLeadingZeros={false}
                                                            amPmAriaLabel="Select AM/PM"
                                                            className="custom-time-picker customPicker"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }} className='timerBox_'>
                                                        <p className='timehead_'>End Time:</p>
                                                        <TimePicker
                                                            onChange={(e) => handleEndTimeChange(e, item.day)}
                                                            value={item.id == 1 ? endMondayTime : item.id == 2 ? endTuesdayTime : item.id == 3 ? endWednesdayTime : item.id == 4 ? endThursdayTime : item.id == 5 ? endFridayTime : item.id == 6 ? endSaturdayTime : endSundayTime} // Use the 12-hour formatted time string directly
                                                            format="h:mm a" // Specify the format as 12-hour with AM/PM                                              
                                                            className="custom-time-picker customPicker"
                                                        />
                                                    </div>
                                                </div>
                                                <Dropdown onClick={() => { handleCopy(item.id) }}>
                                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                        <label className="iconcopy_ mb-0"> <i className="fa-solid fa-copy"></i> </label>
                                                    </Dropdown.Toggle>
                                                    <div className='timedatecoprBox_'>
                                                        <Dropdown.Menu>
                                                            <Form>
                                                                {options.map((option) => (
                                                                    <Form.Check
                                                                        key={option.id}
                                                                        type="checkbox"
                                                                        label={option.label}
                                                                        id={`option-${option.id}`}
                                                                        checked={selectedOptions.find(val => val?.id == option?.id)}
                                                                        onChange={() => handleCheckboxChange1(option, item.id)}
                                                                    />
                                                                ))}
                                                            </Form>
                                                        </Dropdown.Menu>
                                                    </div>
                                                </Dropdown>
                                            </>
                                        }
                                    </div>
                                )
                            })}
                        </div>

                    </div> */}
                </div>
                {/* <div className='col-md-3'>
                </div> */}

                <div className=' modalfooterBtn'>
                    <button className='me-4 w-100 btnDiscard' onClick={() => props.close()}>Discard</button>
                    <button className='w-100 btnSave' type="submit" onClick={(e) => { handleSubmit(e) }} disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Save</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SetUpAddressModal
