import React, { useEffect, useState } from 'react'
import {taxes} from '../../../../utilities/images'
import CustomModal from '../../../components/shared/CustomModal'
import CountryModal from './countryModal'
import TaxPayer from './taxPayer'
import StateModal from './stateModal';
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {getTaxAsync} from "../systemConfigSlice";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import DashboardNavbar from '../../dashboard/DashboardNavbar'



const Taxes = (props) => {
    const [key, setKey] = useState(Math.random());
    const [taxData, setTaxData] = useState()
    const [loading, setLoading] = useState([]);
    const auth = useSelector(selectLoginAuth);
    const [data, setData] = useState(null);
    const [stateData, setStateData] = useState(null);
    const dispatch = useDispatch()
    const history = useHistory()
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const handleDetails = (flag) => {
        if(flag == "OperatingState"){
        setModalDetail({ show: true, flag: "OperatingState" });
        setKey(Math.random());
        }
        if(flag == "TaxPayer"){
            setModalDetail({ show: true, flag: "TaxPayer" });
            setKey(Math.random());
        }
    }

  const getTaxdata = () => {
    let data = {
        tokenData: auth?.payload?.token,
        postData: {
            seller_id: auth?.payload?.uniqe_id,
            is_tax_details: false
        }
    }
    setLoading(true)
    dispatch(getTaxAsync(data))
        .then(unwrapResult)
        .then((obj) => {
            setLoading(false)
            setTaxData(obj?.payload ? obj?.payload : [])
            
        }
        )
        .catch((obj) => {
            setLoading(false)
        })
    }

    useEffect(()=>{
        getTaxdata();
    },[])

  return (
    <>
 <DashboardNavbar title="Taxes" backShow={false} />
    <div className='systemTaxes'>
        {/* <div className='topHeader_'>
            <div className="topStickyHeader_">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h2 className="mainHeading">
                            <span>System Config / <span className="innerSubtext">Taxes</span> </span>
                        </h2>
                    </div>
                </div>
            </div>
        </div> */}
        <div className='paymentContainer'>
            <div className='paymentArea'>
                <div className='row'>
                    <div className='col-md-10'>
                        <div className='d-flex align-items-start'>
                            <img src={taxes} className=' me-3' />
                            <div className='w-100'>
                                <h3 className='textInnerDark'>Taxes and duties</h3>
                                <p className='smalllightHead_ mt-3 mb-3'>If you haven’t already, create a shipping zone in the region(s) you’re liable in. Then, find the region in this list and select it to manage its tax settings. If you’re unsure about where you’re liable, check with a tax professional.</p>   
                                { taxData?.length > 0 ?  history.push('/taxInformation') :   
                                <button className='primary_btn w-auto mt-3'
                                    onClick={() => {
                                        setModalDetail({ show: true, flag: "OperatingCountry" });
                                        setKey(Math.random());
                                      }}
                                >Activate</button>
                                 }
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-2 text-end'>
                        <div className="form-check form-switch">
                            <input type="checkbox"
                                />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
    <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "OperatingCountry" ? "customContent":modalDetail.flag === "TaxPayer" ? "customContent": modalDetail.flag === "TaxPayer" ? "customContent": ""}
        ids={modalDetail.flag === "OperatingCountry" ? "operatingCountry" : modalDetail.flag === "TaxPayer" ? "AddnewuserModal" : modalDetail.flag === "TaxPayer" ? "AddnewuserModal" : ""}
        size="md"
        child={modalDetail.flag === 'OperatingCountry' ? <CountryModal setData={setData} close={() => handleOnCloseModal()} details={(e) => handleDetails(e)}/>
          : modalDetail.flag === 'OperatingState' ? <StateModal data={data} setStateData={setStateData} close={() => handleOnCloseModal()} details={(e) => handleDetails(e)} />
          : modalDetail.flag === 'TaxPayer' ? <TaxPayer  data={data} stateData={stateData} close={() => handleOnCloseModal()} details={(e) => handleDetails(e)}/>
          :<></>
        }
        header={
            <div className='modalHeader_'>
              <div className="common_">
                  <h2 className="modalHeading">
                  Tax Payer Information
                  </h2>
                  <button className="closeButton">
                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                  </button>
              </div>
            </div>
          }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default Taxes