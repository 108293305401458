import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import WithdrawpreviewModal from './withdrawpreviewModal'
import CustomModal from '../../../components/shared/CustomModal';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getBankAccountApiAsync, getWalletBallanceApiAsync, redeemBalanceToYourBankAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { toast } from 'react-toastify';

const InstantbankTransfer = () => {
    const history = useHistory();
    const toastId = React.useRef(null)
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [walletBalance, setWalletBalance] = useState("");
    const [bankDetails, setBankDetails] = useState([]);
    const [amount, setAmount] = useState("");
    const [accountName, setAccountName] = useState("");
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const handleModalOpen = () => {
        if (amount > walletBalance) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("you do not have sufficient funds to withdraw");
            }
            return false
        }
        if (!amount) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Enter amount to withdraw");
            }
            return false
        }
        if (!accountName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Select Bank Account");
            }
            return false
        }
        if (amount < 3000) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Minimum withdrawal amount is $30");
            }
            return false
        }
        if (amount > 1000000) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Maximum withdrawal amount is $10,000");
            }
            return false
        }
        setModalDetail({ show: true, flag: "withdrawpreviewModal" });
        setKey(Math.random());
    }

    const handleWithdraw = (withdrawAmount, name) => {
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
                amount: withdrawAmount,
                account_name: name
            },
        };
        setButtonLoading(true)
        dispatch(redeemBalanceToYourBankAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                handleOnCloseModal()
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg);
                }
                getBankAccountHandle()
                history.push(`/withdrawal`)
                setButtonLoading(false)
            }
            )
            .catch((obj) => {
                setButtonLoading(false)
            })
    }

    const getBankAccountHandle = () => {
        setLoading(true)
        dispatch(getBankAccountApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setBankDetails(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const walletBalanceHandle = () => {
        let data = {
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getWalletBallanceApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setWalletBalance(obj?.payload?.sila_balance)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    function addThousandSeparator(number) {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    useEffect(() => {
        walletBalanceHandle()
        getBankAccountHandle()
    }, [])

    return (
        <>
            <div className='instantbankTransfer'>
                <DashboardNavbar title="Instant Bank Transfer" backShow={`/withdrawal`} />
                {
                    loading ?
                        <span className="spinner-border inner-spin spinner-border-sm"></span> :
                        <div className='chartsOuter'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='witharwAmount_'>
                                        <h5 className='monsheading18 mb-4'>Amount to withdraw</h5>
                                        <label className='buyersubHead d-block'>Withdraw Amount :</label>
                                        <input onKeyDown={blockInvalidChar} value={amount} onChange={(e) => setAmount(e.target.value)} className='custom-control_' type="number" placeholder='JBR' />
                                        <p className='designation_'>Note: Min amount $30 USD. Max amount $10,000.00 USD</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='currencyRight_'>
                                        <h4 className='semiboldHeading_'>You will Receive</h4>
                                        <h3 className='monsmianHead_'>${addThousandSeparator(amount / 100)}</h3>
                                    </div>
                                </div>

                            </div>

                            <div className='bankSelected_ mt-5'>
                                <h5 className='monsheading18 mb-3'>Select Bank Account</h5>
                                {
                                    bankDetails?.length > 0 ?
                                        bankDetails?.map((v, i) => {
                                            return (
                                                <>
                                                    <div key={i} className="checkboxInputs custombtnradioo_ mb-2">
                                                        <input onChange={() => setAccountName(v)} type="radio" id={i + 1} className="radiobtncustom" name="select-bank1" />
                                                        <label for={i + 1} className="radiocustom">dfsdf
                                                        </label>
                                                        <div className='previewStatement_ mt-4'>
                                                            <div className='row'>
                                                                <div className='col-md-3 mb-2'>
                                                                    <div className='valuewithdrawleft_'>
                                                                        <h5 className='labelTxt'>Bank Name:</h5 >
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-9 mb-2'>
                                                                    <div className='valuewithdraw_'>
                                                                        <h5 className='labelTxt fw-bold'>{v?.bank_name}</h5 >
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3 mb-2'>
                                                                    <div className='valuewithdrawleft_'>
                                                                        <h5 className='labelTxt'>Account Number:</h5 >
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-9 mb-2'>
                                                                    <div className='valuewithdraw_'>
                                                                        <h5 className='labelTxt fw-bold'>{v?.account_number}</h5 >
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3 mb-2'>
                                                                    <div className='valuewithdrawleft_'>
                                                                        <h5 className='labelTxt'>Account Name:</h5 >
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-9 mb-2'>
                                                                    <div className='valuewithdraw_'>
                                                                        <h5 className='labelTxt fw-bold'>{v?.account_owner_name}</h5 >
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            )
                                        }) : "No Bank Account"
                                }

                                <p className='accdis_'>Add New Bank Account / Edit Bank Account from  <Link to="/bank-details">Settings {">"} Bank Account</Link></p>

                            </div>

                            <button className='btnwidthdraw_ mt-5' onClick={() => handleModalOpen()}>Withdraw Funds</button>
                        </div>}
            </div >
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "withdrawpreviewModal" ? "withdrawpreviewModal" : ""}
                ids={modalDetail.flag === "withdrawpreviewModal" ? "withdrawpreviewModal" : ""}
                size={
                    "lg"
                }
                header={
                    <div className="modalHeader_">
                        <div className="common_">
                            <h3 className='modalHeading_'>withdrawal Preview</h3>

                            <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                <i className="las la-times" aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>

                }
                child={
                    modalDetail.flag === "withdrawpreviewModal" ? (
                        <WithdrawpreviewModal buttonLoading={buttonLoading} handleWithdraw={(amount, name) => handleWithdraw(amount, name)} amount={amount} accountName={accountName} close={() => handleOnCloseModal()} />
                    ) : (
                        ""
                    )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default InstantbankTransfer