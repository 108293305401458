import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { firmImage, locate, starimg, timeimg, verifyImg, calendarsh } from '../../../../utilities/images'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const TranslatorModal = () => {


    // useEffect(() => {
    //     axios.get(`https://libretranslate.de/languages`)
    //         .then((response) => {
    //             console.log(response.data, "response.data")
    //         })
    // }, [])
   

    return (
        <div className='videocallModal commonBody'>
            <form className='schedulesDetail schedule horizontalForms'>
            <p className='calenderpara_ mb-4'> <b>Text Translator</b></p>

               
            </form>
        </div>
    )
}

export default TranslatorModal