import React from 'react'
import { facebook1, instagram1, linkedin1, tiktok, twitter, youtube } from '../../../utilities/images'

const ConnectAccountModal = () => {
const data = Array.from(Array(6).keys())
  return (
    <div className='connectAccountModal text-center mt-5'>
        <h3 className='subheading_ mb-3'>Connect Account</h3>
        <p className='textInnerDark fw-500'>Add the social media pages related to this brand. And don't worry, you can always add more later.</p>
        <div className='row mt-5'>
          {
            data.length > 0 ?
            <>
            {
              data.map((val, index) =>
              {
                return(
                  <div className='col-md-4 mb-4' key={index}>
                      <div className='socialConnectArea'>
                          <img src={index ===0? [facebook1]: index===1? [instagram1]: index===2? [twitter]: index===3? [linkedin1]: index===4? [youtube] : [tiktok]} alt='' className='mb-3 socialIcons'/>
                          <h4 className='mainlightHead'>
                          {index ===0? "Facebook": index===1? "Instagram": index===2? "Twitter": index===3? "LinkedIn": index===4? "YouTube" : "Tiktok"}
                          </h4>
                          <p className='commonsmallgeryHead_'>Page or Group</p>
                      </div>
                  </div>
                )
              })
            }
            </> :""
          }
        </div>
    </div>
  )
}

export default ConnectAccountModal