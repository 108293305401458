import React from 'react'
import { useState } from 'react';
import OtpInput from 'react-otp-input'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAddressApiAsync, selectLoginAuth } from '../../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import OtpTimer from "otp-timer";
import { updateSellerInfoApiAsync, verifyDriverApiAsync } from '../../dashboardSlice';

const AddDriverModal = (props) => {
    const [otp, setOtp] = useState("");
    const toastId = React.useRef(null)
    const [loading, setLoading] = useState(false)
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const onComplete = code => {
        setOtp(code);
    };

    const handleSubmit = (e) => {
        if (otp.length < 5) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Otp must be of 5 digits")
            }
            return
        }
        setLoading(true)
        let data = {
            postData: {
                "phone_no": props.phoneNumber,
                "otp": otp,
                "address_id": props?.addressId
            },
            tokenData: auth?.payload?.token
        }
        dispatch(verifyDriverApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let params = {
                    postData:{
                        "id": props?.addressId,
                        "specific_driver": true
                    },
                    tokenData: auth?.payload?.token
                }
                dispatch(updateSellerInfoApiAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }
                    let params = {
                        token: auth?.payload?.token,
                        sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                    }
                    dispatch(getAddressApiAsync(params))
                        .then(unwrapResult)
                        .then((obj) => {
                            setLoading(false)
                        }
                        )
                        .catch((obj) => {
                            setLoading(false)
                        })
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
                props.close()
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    // Send OTP to the Driver
    const sendOtp = () => {
        // setLoadingOtp(true)
        // let data = {
        //     "user_id": registerData?.payload?.user_id,
        // }
        // dispatch(resendOtpAsync(data))
        //     .then(unwrapResult)
        //     .then((obj) => {
        //         setLoadingOtp(true)
        //         if (!toast.isActive(toastId.current)) {
        //             toastId.current = toast.success(obj?.msg)
        //         }
        //     }
        //     )
        //     .catch((obj) => {
        //         setLoadingOtp(true)
        //     })
    }
    return (
        <div className='addDriverModal'>
            <div className='verifyModal'>
                <h4 className='innerHeading mb-2'>Check otp received on your phone</h4>
                <p className='mainlightHead'>Enter code here</p>
                <div className="mb-4 otp__ d-flex justify-content-center">
                    <OtpInput
                        numInputs={5}
                        className="input_digits_ "
                        value={otp}
                        data-cy="pin-field"
                        isInputNum={true}
                        isInputSecure={true}
                        onChange={onComplete}
                        autoComplete={false} // onComplete={(code) => onComplete(code)}
                    />
                </div>
                <div className='ps-4 pe-4'>
                    <button className='loginBtn w-100 '
                        onClick={() => handleSubmit()}
                        disabled={loading}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Almost Done!</span>

                    </button>
                </div>
                <p className='innerSubtext mt-5'>
                    <OtpTimer
                        //minutes={1}
                        seconds={60}
                        key={Math.random()}
                        //seconds={otpTimerPeriod}
                        //text="Resend OTP in:"
                        ButtonText="Resend"
                        resend={sendOtp}
                    />
                </p>
            </div>
        </div>
    )
}

export default AddDriverModal