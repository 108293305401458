import React, { useEffect, useState } from "react";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import { shield, editPen, profileImg1 } from "../../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import {
  getPosUsersAsync,
  selectLoginAuth,
  selectedPosUserData,
} from "../../auth/authSlice";
import moment from "moment-timezone";
import CustomModal from "../../../components/shared/CustomModal";
import UpdatePosUserModal from "../../myPos/UpdatePosUserModal";
import { unwrapResult } from "@reduxjs/toolkit";
import { getProductServiceListApiAsync } from "../../myProducts/productSlice";

const PersonalDetailsSystem = () => {
  const authData = useSelector(selectLoginAuth);
  const posUserData = useSelector(selectedPosUserData);
  const [loading, setLoading] = useState(false);
  const [posList, setPosList] = useState();
  const [allServiceData, setAllServiceData] = useState([]);
  const [assignedService, setAssignedService] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  console.log(posUserData, "posUserData");
  const userProfileDetails = authData?.payload?.user?.user_profiles;
  console.log(userProfileDetails,"userProfileDetailsnaresh");
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  // console.log(authData?.payload, "userProfileDetails");
  const fullName =
    userProfileDetails?.firstname + " " + userProfileDetails?.lastname;
  const [key, setKey] = useState(Math.random());
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const calculateActiveDays = (startDate) => {
    const currentDate = new Date();
    const startDateObject = new Date(startDate);
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - startDateObject;
    // Calculate the number of days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  // console.log(posList?.user?.user_roles, "pos list");

  const serviceHandle = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "digital",
        filter_deleted: true,
        app_name: "merchant",
      },
    };
    setLoading(true);
    dispatch(getProductServiceListApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setAllServiceData(obj?.payload?.data);
        let params = {
          postData: {
            seller_id: auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid,
          },
          tokenData: auth?.payload?.token,
        };
        setLoading(true);
        dispatch(getPosUsersAsync(params))
          .then(unwrapResult)
          .then((obj1) => {
            let filterResult = obj1?.payload?.pos_staff?.find(
              (val) =>
                val?.user?.unique_uuid ==
                (auth?.payload?.uniqe_id
                  ? auth?.payload?.uniqe_id
                  : auth?.payload?.user?.unique_uuid)
            );
            let selectedServicdIds = filterResult?.pos_staff_products?.map(
              (v) => v?.product_id
            );
            let serviceSelected = obj?.payload?.data
              ?.filter((value) => selectedServicdIds?.includes(value?.id))
              ?.map((v, i) => {
                return v?.name;
              });
            setAssignedService(serviceSelected);
            setPosList(filterResult);
            setSelectedId(filterResult?.user_id);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    serviceHandle();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Personal Details";
  }, []);

  return (
    <>
      <DashboardNavbar title="Personal Details" backShow={false} />
      <div className="personalDeatilSetion">
        <div className="personalSystemInfo">
          <div className="row">
            <div className="col-xxl-8 col-md-7">
              <div className="userProfileinfo_">
                <img
                  className="personalImg_ img-fluid"
                  src={
                    posList?.user?.user_profiles?.profile_photo
                      ? posList?.user?.user_profiles?.profile_photo
                      : profileImg1
                  }
                  alt="profileimg"
                />
                <div className="userProfileinforight_ ms-3">
                  <h3 className="modalHeading_">{fullName}</h3>

                  {userProfileDetails?.ssn_number && (
                    <div className="tempLocate">
                      <img
                        src={shield}
                        alt=""
                        className="img-fluid"
                      />
                      <span className="ms-2 retailText">
                        {userProfileDetails?.ssn_number
                          ? `*****${userProfileDetails?.ssn_number?.slice(
                              userProfileDetails?.ssn_number?.length - 4,
                              userProfileDetails?.ssn_number?.length
                            )}`
                          : null}
                      </span>
                    </div>
                  )}

                  <ul className="contentextLightMenus mt-3">
                    <li className="contentextLightItems">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0404 9.37377L13.5702 10.9035C13.8076 11.1409 13.8076 11.5258 13.5702 11.7632C12.2867 13.0467 10.2548 13.1911 8.80266 12.102L8.06418 11.5481C6.69501 10.5213 5.47874 9.30499 4.45187 7.93582L3.898 7.19734C2.80894 5.74525 2.95334 3.71332 4.23682 2.42985C4.47422 2.19245 4.85912 2.19245 5.09651 2.42985L6.62623 3.95956C7.01675 4.35008 7.01675 4.98325 6.62623 5.37377L6.18116 5.81884C6.07297 5.92703 6.04615 6.09231 6.11458 6.22916C6.90568 7.81137 8.18863 9.09432 9.77084 9.88542C9.90769 9.95385 10.073 9.92703 10.1812 9.81884L10.6262 9.37377C11.0168 8.98325 11.6499 8.98325 12.0404 9.37377Z"
                          stroke="#275AFF"
                        />
                      </svg>
                      <span className="ms-2">
                        {userProfileDetails?.full_phone_number}
                      </span>
                    </li>
                    <li className="contentextLightItems">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="2.66675"
                          y="4"
                          width="10.6667"
                          height="8"
                          rx="2"
                          stroke="#275AFF"
                        />
                        <path
                          d="M2.66675 6L7.10565 8.21945C7.66871 8.50098 8.33145 8.50098 8.89451 8.21945L13.3334 6"
                          stroke="#275AFF"
                        />
                      </svg>
                      <span className="ms-2">{authData?.payload?.email}</span>
                    </li>
                    <li className="contentextLightItems">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 8C13 11.2093 9.63256 13.4165 8.42475 14.105C8.15889 14.2565 7.84111 14.2565 7.57526 14.105C6.36745 13.4165 3 11.2093 3 8C3 5 5.42267 3 8 3C10.6667 3 13 5 13 8Z"
                          stroke="#275AFF"
                        />
                        <circle
                          cx="7.99992"
                          cy="7.9987"
                          r="2.16667"
                          stroke="#275AFF"
                        />
                      </svg>
                      <span className="ms-2">
                        {userProfileDetails?.current_address?.street_address},{" "}
                        {userProfileDetails?.current_address?.city},{" "}
                        {userProfileDetails?.current_address?.state}(
                        {userProfileDetails.current_address?.state_code}),
                        {userProfileDetails?.current_address?.country},{" "}
                        {userProfileDetails?.current_address?.zipcode}{" "}
                      </span>
                    </li>
                    <li className="contentextLightItems">
                      <button
                        className="profileEditBtn"
                        type="button"
                        onClick={() => {
                          setModalDetail({
                            show: true,
                            flag: "updatePosModal",
                          });
                          setKey(Math.random());
                        }}
                      >
                        <img
                          src={editPen}
                          alt=""
                          className="img-fluid "
                        />
                        Edit
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xxl-4  col-md-5 d-flex justify-content-end">
              <div className="joinDateInfo">
                <div className="employeeInfo">
                  <h4 className="retailText w-100">Joined Date</h4>
                  <h4 className="salePara m-0 w-100">
                    {moment(userProfileDetails?.created_at).format(
                      "MMM DD, YYYY"
                    )}
                  </h4>
                </div>
                <div className="employeeInfo mt-3">
                  <h4 className="retailText w-100">Active Since</h4>
                  <h4 className="salePara m-0 w-100">
                    {calculateActiveDays(userProfileDetails?.created_at)} days
                  </h4>
                </div>
                <div className="employeeInfo mt-3">
                  <h4 className="retailText w-100">Employment Type</h4>
                  <h4 className="salePara m-0 w-100">-</h4>
                </div>
                <div className="employeeInfo mt-3">
                  <h4 className="retailText w-100">Leave taken</h4>
                  <h4 className="salePara m-0 w-100">-</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="roleSection">
          <form className="personalInfoForm">
            <div className="roleFlex">
              <div className="productViewRight w-50">
                <div className="roleSub personalRole">
                  <h4 className="retailText m-0">Role</h4>
                </div>
                {/* <input
                  type="text"
                  class="personalControl"
                  placeholder="Admin"
                /> */}
                {posList?.user?.user_roles?.length > 0 ? (
                  posList?.user?.user_roles?.map((val, index) => {
                    return (
                      <span key={index}>
                        {val?.role?.name}{" "}
                        {posList?.user?.user_roles?.length - 1 > index
                          ? ", "
                          : ""}
                      </span>
                    );
                  })
                ) : (
                  <span>No Role Found</span>
                )}
              </div>
              <div className="productViewRight w-50">
                <div className="roleSub personalRole">
                  <h4 className="retailText m-0">Assign Services</h4>
                </div>
                {assignedService?.length > 0 ? (
                  assignedService?.map((val, index) => (
                    <span>
                      {val} {assignedService?.length - 1 > index ? ", " : ""}
                    </span>
                  ))
                ) : (
                  <span>N/A</span>
                )}
                {/* <input type="text" class="personalControl" placeholder="" /> */}
              </div>
            </div>
            <div className="roleFlex mt-2">
              <div className="identitySub">
                <h4 className="identityText m-0">Color to identify</h4>
                {posList?.color_code ? (
                  <div
                    className="blueBox"
                    style={{ background: posList?.color_code }}
                  ></div>
                ) : (
                  <div className="blueBox">N/A</div>
                )}
              </div>
              <div className="overTimeMain">
                <div className="form-check form-switch me-2 mb-0 ">
                  <input
                    type="checkbox"
                    id="skipPOS"
                    disabled
                    checked={posList?.is_over_time_allowed}
                  />
                </div>
                <h4 className="identityText m-0">Over Time Allowed</h4>
              </div>
              <div className="overTimeMain">
                <div className="form-check form-switch me-2 mb-0 ">
                  <input
                    type="checkbox"
                    id="skipPOS"
                    disabled
                    checked={posList?.is_tips_allowed}
                  />
                </div>
                <h4 className="identityText m-0">Tips Allowed</h4>
              </div>
            </div>
          </form>
        </div>
        <div className="timeRateSection">
          <div className="timeRateSub">
            <h4 className="semiboldHeading_ text-center">Hour rate</h4>
            <h4 className="textSmallHeading mt-3  text-center">
              {posList?.hourly_rate ? `JBR ${posList?.hourly_rate}/h` : "-"}
            </h4>
          </div>
          <div className="timeRateSub">
            <h4 className="semiboldHeading_ text-center">Over time rate</h4>
            <h4 className="textSmallHeading mt-3  text-center">
              {posList?.overtime_rate ? `JBR ${posList?.overtime_rate}/h` : "-"}
            </h4>
          </div>
          <div className="timeRateSub">
            <h4 className="semiboldHeading_ text-center">Payment Cycle</h4>
            <h4 className="textSmallHeading mt-3  text-center">
              {posList?.payment_cycle ? posList?.payment_cycle : "-"}
            </h4>
          </div>
          <div className="timeRateSub">
            <h4 className="semiboldHeading_ text-center">Billing</h4>
            <h4 className="textSmallHeading mt-3  text-center">
              {posList?.billing_type ? posList?.billing_type : "-"}
            </h4>
          </div>
        </div>
        <div className="billingSection">
          <div className="timeRateSub">
            <h4 className="semiboldHeading_ text-center">
              Current Billing Cycle
            </h4>
            <h4 className="textSmallHeading mt-3  text-center">
              {/* May 29, 2023 - Jun 4, 2023 */}-
            </h4>
          </div>
          <div className="timeRateSub">
            <h4 className="semiboldHeading_ text-center">Time Tracked</h4>
            <h4 className="textSmallHeading mt-3  text-center">
              {/* JBR 2500/h */}-
            </h4>
          </div>
          <div className="timeRateSub">
            <h4 className="semiboldHeading_ text-center">
              Weekly Tracking Limit
            </h4>
            <h4 className="textSmallHeading mt-3  text-center">
              {/* 1 h 30 m */}-
            </h4>
          </div>
        </div>
        <div className="tableSectionBackground personalInfoTable">
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="campaignTopHead productMainCategory">
                <div className="categroyBox">
                  <select className="cateSelectdFilter" required>
                    <option value="" selected>
                      All Roles
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                  </select>
                </div>
                <div className="categroyBox">
                  <select className="cateSelectdFilter" required>
                    <option value="" selected>
                      Employment Type
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                  </select>
                </div>
                <div className="categroyBox">
                  <select className="cateSelectdFilter" required>
                    <option value="" selected>
                      Payment Cycle
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                  </select>
                </div>
                <div className="categroyBox">
                  <select className="cateSelectdFilter" required>
                    <option value="" selected>
                      Status
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop={true}
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        size={modalDetail.flag === "updatePosModal" ? "lg" : ""}
        // className={modalDetail.flag === "posModal" ? "commonWidth customContent" : ""}
        ids={"UpdatePosModal"}
        child={
          modalDetail.flag === "updatePosModal" ? (
            <UpdatePosUserModal
              selectedRow={posList}
              setPosList={setPosList}
              id={selectedId}
              close={() => handleOnCloseModal()}
              personalDetail={true}
              assignedService={setAssignedService}
            />
          ) : (
            ""
          )
        }
        header={
          <>
            <div className="modalHeader_">
              <div className="common_">
                <div className="anotherHeader">
                  <h2 className="modalHeading">Update Pos User</h2>
                </div>
                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default PersonalDetailsSystem;
