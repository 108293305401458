import React from 'react'

const WalletDispute = () => {
    return (
        <div className='walletDispute'>
            <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>Wallet / <span className="innerSubtext">Dispute</span> </span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletDispute