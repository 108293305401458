import React, { useEffect, useRef } from "react";
import {
  calendar,
  cashOutImg,
  OrderDown,
  modalCancel,
} from "../../../utilities/images";
import DatePicker from "react-datepicker";
import { useState } from "react";
import moment from "moment-timezone";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import { getOrderDetailsAsync } from "../dashboard/dashboardSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import CustomModal from "../../components/shared/CustomModal";
import WalletPurhaseModal from "./walletPurhaseModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Transections = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("")
  const auth = useSelector(selectLoginAuth);
  const [startDate, setStartDate] = useState(
    location?.state?.from === "analytics" ? location?.state?.date : null
  );
  const [endDate, setEndDate] = useState(
    location?.state?.from === "analytics" ? location?.state?.date : null
  );
  const [deliveryOption, setDeliveryOption] = useState(
    location?.state?.deliveryOption ? location?.state?.deliveryOption : ""
  );
  const [status, setStatus] = useState(
    location?.state?.status ? location?.state?.status : ""
  );
  const [isWalkIn, setIsWalkIn] = useState(
    location?.state?.isWalkIn ? location?.state?.isWalkIn : false
  );
  const [filter, setFilter] = useState(
    location?.state?.filter ? location?.state?.filter : false
  );
  const [appName, setAppName] = useState(
    location?.state?.appName ? location?.state?.appName : false
  );
  const [needAllPickUp, setNeedAllPickup] = useState(
    location?.state?.isNeedAllPickup ? location?.state?.isNeedAllPickup : false
  );
  const [needReturn, setNeedReturn] = useState(
    location?.state?.isNeedReturnFalse
      ? location?.state?.isNeedReturnFalse
      : false
  );
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(Math.random());
  const [orderDetails, setOrderDetails] = useState([]);
  const [paymentOptionList, setPaymenysOptionList] = useState(false);
  const [transactionType, setTransactionType] = useState("all");
  const hasLoggedOnceRef = useRef(false);
  const [rowData, setRowData] = useState({});
  const handleTransactionType = (event) => {
    setTransactionType(event.target.value);
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleRowClick = (row) => {
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    setRowData(row);
    handleUserProfile("walletpurchase");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };
  const columns1 = [
    // {
    //   name: "#",
    //   width: "50px",
    //   selector: (row, i) => i + 1,
    // },
    {
      name: "Date",
      selector: (row) => {
        return (
          <>
            <span className="textParagh ">
              {moment(row?.created_at).format("MM/DD/YYYY | hh:mm a")}
            </span>
            {/* <span className="prop_span">
              {moment(row?.created_at).format("HH:mm")}
            </span> */}
          </>
        );
      },
      width: "180px",
    },

    // {
    //   name: "Transaction#",
    //   selector: (row) => {
    //     return (
    //       <>
    //         <span className="textParagh ">
    //           {row?.transaction_id
    //             ? row?.transaction_id?.substring(0, 20) + "..."
    //             : "-"}
    //         </span>
    //       </>
    //     );
    //   },
    //   width: "185px",
    // },
    {
      selector: row => row?.invoices?.invoice_number ? row?.invoices?.invoice_number : row?.return_detail?.invoices?.invoice_number,
      name: 'Invoice No.',
      width: "120px",
    },
    {
      name: "Transaction type",
      selector: (row) => {
        return (
          <>
            <span className="textParagh">
              {row?.app_name !== "b2c" ? "In-Store" : "Online"}
            </span>
          </>
        );
      },
      width: "180px",
    },
    {
      selector: (row) => {
        return (
          <>
            <span className="textParagh">
              {row?.mode_of_payment === "jbr"
                ? "JBR Wallet"
                : row?.mode_of_payment === "cash"
                  ? "Cash"
                  : "Card"}{" "}
            </span>
          </>
        );
      },
      name: "Payment Method",
    },
    {
      name: "Type",
      selector: (row) => {
        return (
          <>
            <span className="textParagh ">
              {row?.delivery_option == "1"
                ? "Delivery"
                : row?.delivery_option == "2"
                  ? "Reservation"
                  : row?.delivery_option == "3"
                    ? "Walkin"
                    : "Shipping"}
            </span>
          </>
        );
      },
      width: "130px",
    },
    {
      selector: (row) => {
        return (
          <>
            <span className="textParagh">
              {(row?.payable_amount >= 0 && row?.returns == null) ? `$${Number(row?.payable_amount).toFixed(2)}` : "-"}
            </span>
          </>
        );
      },
      name: "Cash In Amount",
    },
    {
      selector: (row) => {
        return (
          <>
            <span className="textParagh" style={{ color: "red" }}>
              {(row?.payable_amount < 0) ? `$${Number(row?.payable_amount)?.toFixed(2)}` : (row?.payable_amount > 0 && row?.returns != null) ? `$${Number(row?.returns?.refunded_amount)?.toFixed(2)}` : "-"}
            </span>
          </>
        );
      },
      name: "Cash Out Amount",
    },
    {
      selector: (row) => {
        return (
          <>
            <div className="d-flex align-items-center justify-content-center">
              {/* <a className='arrow_icon' href="/#"> */}
              <i className="fa fa-chevron-right"></i>
              {/* </a> */}
            </div>
          </>
        );
      },
      name: "",
      width: "50px",
    },
  ];

  const getOrderDetailsAsyncHandler = () => {
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        page: currentPage,
        limit: perPage,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        transaction_type: transactionType,
        search: keyword || undefined
      },
    };
    if (startDate != null && endDate != null) {
      params.postData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
    }
    if (deliveryOption == 3 && deliveryOption) {
      params.postData = {
        ...params.postData,
        need_walkin: true,
      };
    }
    if (deliveryOption != 3 && deliveryOption) {
      params.postData = {
        ...params.postData,
        delivery_option: deliveryOption,
      };
    }
    if (status) {
      params.postData = {
        ...params.postData,
        status: status,
      };
    }
    if (filter) {
      params.postData = {
        ...params.postData,
        filter_by: filter,
      };
    }
    if (isWalkIn) {
      params.postData = {
        ...params.postData,
        need_walkin: true,
      };
    }
    if (needAllPickUp && needAllPickUp === true) {
      params.postData = {
        ...params.postData,
        need_all_pickup: needAllPickUp,
      };
    }
    if (needAllPickUp === false) {
      params.postData = {
        ...params.postData,
        need_all_pickup: needAllPickUp,
      };
    }
    if (appName) {
      params.postData = {
        ...params.postData,
        app_name: appName,
      };
    }
    if (needReturn) {
      params.postData = {
        ...params.postData,
        need_refunded: false,
        need_returned: false,
      };
    }
    setLoading(true);
    dispatch(getOrderDetailsAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setOrderDetails(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const transType = params.get("transType");
    if (transType && !hasLoggedOnceRef.current) {
      setTransactionType(transType);
      hasLoggedOnceRef.current = true;
    }
    getOrderDetailsAsyncHandler();
  }, [endDate, perPage, currentPage, transactionType, deliveryOption]);

  useEffect(() => {
    if(keyword == "" || keyword?.length > 2){
      getOrderDetailsAsyncHandler();
    }
  }, [keyword]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Wallet";
  }, []);

  return (
    <>
      <DashboardNavbar title="Transactions" setKeyword={setKeyword} flag="goBack" backShow={true} />
      <div className="transections">
        {/* Start TopFixedHeader */}
        {/* <div className='topHeader_'>
        <div className="topStickyHeader_">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="mainHeading">Wallet / <span className="innerSubtext">Transactions</span></h2>
            </div>
          </div>
        </div>
      </div> */}
        {/* End TopFixedHeader */}
        {/* Start TableFilter Area */}

        <div className="tableSectionBackground">
          <div className="tableFilters mt-0 ">
            <div className="row ">
              <div className="col-md-12">
                <div className="productMainCategory">
                  <div className="categroyBox dateCategory">
                    <div className="calenderIcon campCalendar">
                      <img
                        className="iconcalenderImg_"
                        src={calendar}
                        alt="calender"
                      />
                      <DatePicker
                        className="cateDate"
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        placeholderText="Date"
                      />
                      <img
                        src={OrderDown}
                        alt="calender"
                        className="calenderDownIcon"
                      />
                    </div>
                  </div>
                  <div className="categroyBox">
                    <select
                      className="cateSelectdFilter"
                      value={transactionType}
                      onChange={handleTransactionType}
                      required
                    >
                      <option value="all" selected>
                        All Payments
                      </option>
                      {/* <option value="jbr">JBR Wallet</option> */}
                      <option value="cash">Cash</option>
                      <option value="card">Card</option>
                    </select>
                  </div>
                  {/* <div className="categroyBox">
                    <select className="cateSelectdFilter" required>
                      <option value="" selected>
                        State
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                    </select>
                  </div>
                  <div className="categroyBox">
                    <select className="cateSelectdFilter" required>
                      <option value="" selected>
                        ID type
                      </option>
                      <option>Category1</option>
                      <option>Category2</option>
                    </select>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <DataTable
              pagination
              columns={columns1}
              data={orderDetails}
              defaultSortFieldId={1}
              customStyles={customStyles}
              onRowClicked={handleRowClick}
              className="transection_datatable"
              // selectableRows
              paginationServer
              progressPending={loading}
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </div>
        </div>
        {/* } */}
        {/* End DataTable */}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="false"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "walletpurchase" ? "walletPurchaseModal" : ""}
        className={
          modalDetail.flag === "walletpurchase"
            ? "commonWidth customContent"
            : ""
        }
        child={
          modalDetail.flag === "walletpurchase" ? (
            <WalletPurhaseModal
              data={rowData}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "walletpurchase" ? (
            <>
              <p
                style={{ cursor: "pointer" }}
                onClick={handleOnCloseModal}
                className="modal_cancel"
              >
                <img
                  src={modalCancel}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Transections;
