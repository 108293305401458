import React from 'react'
import { Link } from 'react-router-dom'
import {
    search_light, defaultUser, company2, userImage, dotsinbox, pinlocate, tubeImage, refundproduct,
    receiver, profileImg, deleteIcon, paperclip_, buildingIcon, chatImage_,
    videouserImg, calendarsh2, docupload, Viewimage, downloadimage, userImg_, building_, cardemail_, cardphone_, invoicewhite
} from '../../../../utilities/images'
const Inquaries = () => {
    return (
        <div className='messagechatBox'>
            <div className='row'>
                <div className='col-md-3 pe-0'>
                    <div className='chat_inbox'>


                        <div className='searchBox_ p-0'>
                            <div className="input-group search_group">
                                <img src={search_light} alt="" className="img-fluid searchIcon" />
                                <input type="text" className="form-control" placeholder="Search here" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            </div>
                        </div>

                        {/* <div className='inboxshildBtn_ mt-3'>
                            <Link to="#">
                                <img className='shildImg me-1' src={shieldIcon} />
                            </Link>
                            <Link to="#">
                                <img className='shildImg' src={buildingIcon} />
                            </Link>
                        </div> */}

                        <div className='chatDetailbox_ '>
                            <div className='chatInnerinfo_ newMessage mt-3'>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                            <div className='chatInnerinfo_ '>
                                <div className='chatUser'>
                                    <img className='userProfile_' src={defaultUser} />
                                </div>
                                <div className='chatuserDetail_'>
                                    <h5 className="subtextSmall_ ">Brain <Link to="#" className='detailLink ms-2'>Carrols Restaurant Group</Link></h5>
                                    <h5 className="subtextSmallblack_">The obvious strategy of a ......</h5>
                                    <h5 className='timezoneChat mt-1  mb-0'> 23/10/2023 <span className='ms-1 me-2'>|</span>12:59 am</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-9'>
                    <div className='rightMessagebox_'>
                        <div className='messageTophead'>
                            <img className='senduserImage' src={company2} />
                            <div className='userInformation'>
                                <h5 className='upload-cnt mb-0'>Meyer Distribute</h5>
                                <h6 className='commonsmallgeryHead_ mb-0'>3101 Cook Hill Road Stamford, CT 06902</h6>
                            </div>
                            <div className='dropdown chatditBtn'>
                                <Link to="#" id="dropdownMenuButton1" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                    <img className='dotIcon_' src={dotsinbox} />
                                </Link>
                                <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                    <li><Link to="#" className="dropdown-item chatdropItem " >
                                        <img className='dropIcon' src={profileImg} />
                                        <span className='subtextSmall_'>Go to Profile</span></Link></li>
                                    <li><Link to="#" className="dropdown-item chatdropItem" >
                                        <img className='dropIcon' src={deleteIcon} />
                                        <span className='subtextSmall_'>Delete chat</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className='message_body'>
                            <div className='chatDatebox_'>
                                <h5 className='chatdeateHeading'>Today | April 20, 2022</h5>
                            </div>
                            <div className='leftMessage mt-3 mb-2'>
                                <div className="leftmessage-box">
                                    <img className='senduserImage' src={userImage} />
                                    <div className='leftBoxmsg_'>
                                        <p className='textinner_'>Dear Sir/Madam,</p>
                                        <p className='textinner_'>I am writing to inquire about the availability of 500 boxes from your company. We are a small business specializing in gift packaging and we are interested in purchasing the boxes to expand our product line.</p>
                                        <ul className='messagetextList_'>
                                            <p className='textinner_'>Could you please provide us with the following information:</p>
                                            <li className='textinner_'>The types and sizes of boxes available.</li>
                                            <li className='textinner_'>The minimum order quantity and pricing for each type of box.</li>
                                            <li className='textinner_'>The estimated delivery time and shipping cost to our location.</li>
                                        </ul>
                                        <p className='textinner_'>We are particularly interested in boxes made from eco-friendly materials and with unique designs. Please let us know if you have any options that fit these criteria.</p>
                                        <p className='textinner_'>If you could also provide us with samples of your products, we would greatly appreciate it.</p>
                                        <p className='textinner_'>Thank you for your time and consideration. We look forward to hearing back from you soon.</p>
                                        <div className="productinfoClassic_">
                                            <figure className='chatleftImage me-3 mb-0' >
                                                <img src={refundproduct} />
                                            </figure>
                                            <aside className='productrightDetail'>
                                                <h5 className='mainboldHead mb-0'>Ashton Classic</h5>
                                                <p className='descriptionPara_ mb-2'><em>20 Pack Box</em></p>
                                                <h6 className='descriptionPara_bold '>Inquiry:  500 boxes Price</h6>
                                            </aside>
                                        </div>
                                        <hr />
                                        <button className='sendInvoicebtn_ mt-2' type="submit"><img className='invoiceImg_ me-2' src={invoicewhite} />Send an Invoice</button>
                                    </div>
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>
                            <div className='leftMessage mt-3 mb-2'>
                                <div className="leftmessage-box">
                                    <img className='senduserImage' src={userImage} />
                                    <div className='leftBoxmsg_'>
                                        <p className='textinner_'>Our gateway is routed directly to Telstra, with Optus and Vodafone networks serviced through various Australian aggregators</p>
                                    </div>
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>
                            <div className='leftMessage mt-3 mb-2'>
                                <div className="leftmessage-box">
                                    <img className='senduserImage' src={userImage} />
                                    <div className='leftBoxmsg_'>
                                        <p className='textinner_'>Our gateway is routed directly to Telstra, with Optus and Vodafone networks serviced through various Australian aggregators</p>
                                    </div>
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>
                            <div className='rightMessage mt-3 mb-2'>
                                <div className="rightmessage-box">
                                    <div className='rightboxmsg_'>
                                        <p className='textinner_'>Our gateway is routed directly to Telstra, with Optus and Vodafone networks serviced through various Australian aggregators</p>
                                    </div>
                                    <img className='senduserImage' src={receiver} />
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>
                            <div className='rightMessage mt-3 mb-2'>
                                <div className="rightmessage-box">
                                    <div className='rightboxmsg_'>
                                        <img className='userimageSend_ ' src={chatImage_} />
                                    </div>
                                    <img className='senduserImage' src={receiver} />
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>
                            <div className='rightMessage mt-3 mb-2'>
                                <div className="rightmessage-box">
                                    <div className='rightboxmsg_'>
                                        <div className='videoCallwith'>
                                            <h5 className='semiboldHeading_'>Video call with</h5>
                                            <div className='videoUserdetail'>
                                                <img className='videoUser me-2' src={videouserImg} />
                                                <aside>
                                                    <h6 className='subtextDark_ mb-1'>Senia PanFeng</h6>
                                                    <p className='subtextSmall_ mb-1'>Date: Sep 5, 2022</p>
                                                    <p className='subtextSmall_'>Time: 3:00p-3:15p</p>
                                                </aside>
                                            </div>
                                            <button className='secondaryblackrbtn_ mt-3'>
                                                <img className='calendershdule me-1' src={calendarsh2} /> <span>Schedule</span>
                                            </button>
                                            <p className='schdulepara_'>You can reschedule this meeting only once.</p>

                                        </div>
                                    </div>
                                    <img className='senduserImage' src={receiver} />
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>

                            <div className='rightMessage mt-3 mb-2'>
                                <div className="rightmessage-box">
                                    <div className='rightboxmsg_'>
                                        <div className='uploadDocument mb-3'>
                                            <div className='uploadContent'>
                                                <div className='docFileupload'>
                                                    <img src={docupload} />
                                                    <span>talon_driving_licence </span>
                                                </div>
                                            </div>
                                            <div className='uploadContentbtn'>
                                                <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimage} /></Link>
                                                <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimage} /></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <img className='senduserImage' src={receiver} />
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>
                            <div className='rightMessage mt-3 mb-2'>
                                <div className="rightmessage-box">
                                    <div className='rightboxmsg_ rightanotherBg'>
                                        <h5 className='smallcardHead_'>Instant offer for you</h5>
                                        <div className='instantOfferBox'>
                                            <img className='tubeImage' src={tubeImage} />
                                            <div className='instantContent'>
                                                <h5 className='subtextDark_'>Marlboro Silver</h5>
                                                <p className='offerpara_ mt-2'>Comfortable Athletic Women Sneaker Shoes,New Style Fashion Sport Shoes</p>
                                                <h5 className='hightlightSmall mt-3 mb-0'>USD 5.60</h5>
                                                <p className='offersmall_'>200 Pairs (Min. Order)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <img className='senduserImage' src={receiver} />
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>
                            <div className='rightMessage mt-3 mb-2'>
                                <div className="rightmessage-box">
                                    <div className='rightboxmsg_ rightanotherBg'>
                                        <h5 className='smallcardHead_'>Business Card</h5>
                                        <div className="userProfileinfo_ anotherprofileBg">
                                            <img className="marchantPro" src={userImg_} alt="profileimg" />
                                            <div className="userProfileinforight_">
                                                <h3 className='userhaeding_'>Steven D. Daum</h3>
                                                <ul className='contentextLightMenus mt-2'>
                                                    <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={building_} />
                                                        <span className='ms-2'>Company name</span>
                                                    </li>
                                                    <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={cardemail_} />

                                                        <span className='ms-2'>emailaddress@email.com</span>
                                                    </li>
                                                    <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={cardphone_} />
                                                        <span className='ms-2'>805-627-5874</span>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                    <img className='senduserImage' src={receiver} />
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>
                            <div className='rightMessage mt-3 mb-2'>
                                <div className="rightmessage-box">
                                    <div className='rightboxmsg_ rightanotherBg'>
                                        <h5 className='smallcardHead_'>Shipping Address</h5>
                                        <div className="userProfileinfo_ anotherprofileBg">
                                            <div className="userProfileinforight_">
                                                <h3 className='userhaeding_'>Steven D. Daum</h3>
                                                <ul className='contentextLightMenus mt-2'>
                                                    <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={building_} />
                                                        <span className='ms-2'>Company name</span>
                                                    </li>
                                                    <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={pinlocate} />

                                                        <span className='ms-2'>1457 New Street Heppner, OR 97836</span>
                                                    </li>
                                                    <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={cardemail_} />

                                                        <span className='ms-2'>emailaddress@email.com</span>
                                                    </li>
                                                    <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={cardphone_} />
                                                        <span className='ms-2'>805-627-5874</span>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                    <img className='senduserImage' src={receiver} />
                                </div>
                                <span className='subtextSmallblack_'>12:30 pm</span>
                            </div>

                            {/* <!--Quick Message Content--> */}
                            <div className='quickMessage_'>
                                <button className="closeButton">
                                    <i className="las la-times"></i>
                                </button>
                                <div className='quickInnercontent'>
                                    <h5 className='labelTxt mt-3'>Hi, What’s the best price you can offer?</h5>
                                    <h5 className='labelTxt mt-3'>Hi, I’m interested on this product. I would like
                                        some more detilas.</h5>
                                    <h5 className='labelTxt mt-3'>Hi, Would you send me a product sample
                                        before I place an order?</h5>
                                    <h5 className='labelTxt mt-3'>What is your min. oder quantity?</h5>
                                </div>
                            </div>
                            {/* <!--Quick Message Content--> */}
                        </div>
                        
                        <div className='messageBottom_'>
                            <div className='typeMessgae'>
                                <div className='dropdown typeBtn_'>
                                    <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                    <img className='clippaper me-2' src={paperclip_} alt="paperclip"/>
                                    </Link>
                                    {/* <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton2" >
                    <li><Link to="#" className="dropdown-item chatdropItem " data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                      setModalDetail({ show: true, flag: "UploadPhoto" });
                      setKey(Math.random());
                    }}>
                      <img className='dropIcon' src={photoIcon} />
                      <span className='subtextSmall_'>Photo</span></Link>
                    </li>
                    <li><Link to="#" className="dropdown-item chatdropItem">
                      <img className='dropIcon' src={chatIconn} />
                      <span className='subtextSmall_'>Quick Messages</span></Link>
                    </li>
                    <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                      setModalDetail({ show: true, flag: "VideoCall" });
                      setKey(Math.random());
                    }}>
                      <img className='dropIcon' src={videoIcon} />
                      <span className='subtextSmall_'>Video Call</span></Link>
                    </li>
                    <li><Link to="#" className="dropdown-item chatdropItem" onClick={() => {
                      setModalDetail({ show: true, flag: "FileUpload" });
                      setKey(Math.random());
                    }}>
                      <img className='dropIcon' src={folderIcon} />
                      <span className='subtextSmall_'>File</span></Link>
                    </li>
                    <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                      setModalDetail({ show: true, flag: "MakeOffer" });
                      setKey(Math.random());
                    }}>
                      <img className='dropIcon' src={discountIcon} />
                      <span className='subtextSmall_'>Offer</span></Link>
                    </li>
                    <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                      setModalDetail({ show: true, flag: "BusinessCard" });
                      setKey(Math.random());
                    }}>
                      <img className='dropIcon' src={businessIcon} />
                      <span className='subtextSmall_'>Business Card</span></Link>
                    </li>
                    <li><Link to="#" className="dropdown-item chatdropItem">
                      <img className='dropIcon' src={priceIcon} />
                      <span className='subtextSmall_'>Latest Price</span></Link>
                    </li>
                    <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                      setModalDetail({ show: true, flag: "VoiceRecord" });
                      setKey(Math.random());
                    }}>
                      <img className='dropIcon' src={voiceIcon} />
                      <span className='subtextSmall_'>Voice Messages</span></Link>
                    </li>
                    <li><Link to="#" className="dropdown-item chatdropItem">
                      <img className='dropIcon' src={translateIcon} />
                      <span className='subtextSmall_'>Translator</span></Link>
                    </li>
                    <li><Link to="#" className="dropdown-item chatdropItem" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                      setModalDetail({ show: true, flag: "ShippingAddress" });
                      setKey(Math.random());
                    }}>
                      <img className='dropIcon' src={shippingIcon} />
                      <span className='subtextSmall_'>Shipping address</span></Link>
                    </li>
                  </ul> */}
                                </div>
                                <textarea className='me-3' rows="2" cols="9" id="messageBox" placeholder="Type your message here"></textarea>
                                <button type='submit' className='sendBtnbox'> Send <i class="fa-solid fa-paper-plane ms-2"></i></button>

                            </div>
                        </div>
                    </div >
                </div>
            </div>
        </div>
    )
}

export default Inquaries