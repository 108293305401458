import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  infoImg,
  profileImg1,
} from "../../../utilities/images";
import ProductDetails from "./ProductDetails";
import ProductImageAdd from "./productImageAdd";
import AddServiceHeader from "./AddServiceHeader";
import ProductPrice from "./productPrice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getPosUsersAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageUploadAsync } from "../message/messageSlice";
import { toast } from "react-toastify";
import { formValidator } from "./formValidator";
import { createProductApiAsync } from "../dashboard/dashboardSlice";
import Multiselect from "multiselect-react-dropdown";
import CustomModal from "../../components/shared/CustomModal";
import AddStaffModal from "./AddStaffModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AddService = (props) => {
  const location = useLocation();
  const userData = location?.state;
  console.log(userData, 'location state');

  const history = useHistory();
  const selectValuesMinMaxOptions = Array.from(
    { length: 20 },
    (_, index) => index + 1
  );
  const selectValuesHoursOptions = Array.from(
    { length: 12 },
    (_, index) => `${index} hr`
  );
  const selectValuesMinutesOptions = Array.from(
    { length: 60 },
    (_, index) => `${index} min`
  );
  const [maximumValue, setMaximumValue] = useState(userData?.supplies[0]?.max_capacity ? userData?.supplies[0]?.max_capacity : "Max");
  const [minimumValue, setMinimumValue] = useState(userData?.supplies[0]?.min_capacity ? userData?.supplies[0]?.min_capacity : "Min");
  const [hoursValue, setHoursValue] = useState("0 hr");
  const [minutesValue, setMinutesValue] = useState("0 min");
  const [hoursValue1, setHoursValue1] = useState("0 hr");
  const [minutesValue1, setMinutesValue1] = useState("0 min");
  const [productDetails, setProductDetails] = useState({
    "productName": userData?.name,
    "service_id": userData?.service_id,
    "category_id": userData?.category_id,
    "sub_category_id": userData?.sub_category_id,
  });
  const [approxTime, setApproxTime] = useState(
    userData?.supplies ? userData?.supplies[0]?.approx_service_time : ""
  );
  const [posList, setPosList] = useState([]);
  const [costPrice, setcostPrice] = useState(userData?.price ?? "");
  const [productDescription, setProductDescription] = useState("");
  const [imageDetails, setImageDetails] = useState({});
  const [prices, setPrices] = useState(userData?.supplies[0].supply_prices ?? []);
  const [activePos, setActivePos] = useState(userData?.supplies[0]?.supply_prices?.find(item => item.app_name === 'pos') ? true : false);
  const [activeOnlineStore, setActiveOnlineStore] = useState(userData?.supplies[0]?.supply_prices?.find(item => item.app_name === 'b2c' || item.app_name === 'b2b') ? true : false);
  const [activeBtoc, setActiveBtoc] = useState(userData?.supplies[0]?.supply_prices?.find(item => item.app_name === 'b2c') ? true : false);
  const [activeBtob, setActiveBtob] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState(["reservation"]);
  const [type, setType] = useState("digital");
  const [posUserId, setPosUserId] = useState([]);
  const [skipPosDetails, setSkipPosDetails] = useState(false);
  const [syncGoogleCalendar, setSyncGoogleCalendar] = useState(false);
  const [selectedPosId, setSelectedPosId] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  console.log(userData)
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const getIdsHandle = (data) => {
    setSelectedPosId(data);
    handleOnCloseModal();
  };

  const handleSkipPOSDetails = (e) => {
    setSkipPosDetails(e.target.checked);
  };
  const handleSyncGoogleCalendar = (e) => {
    setSyncGoogleCalendar(e.target.checked);
  };
  const handleChangeJobrPos = () => {
    setActiveOnlineStore(!activeOnlineStore);
    if (activeBtoc) {
      setActiveBtoc(false);
    }
    if (activeBtob) {
      setActiveBtob(false);
    }
  };

  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setProductDescription(e);
    } else {
      setProductDetails(e);
    }
  };
  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  const handleprices = (e) => {
    setPrices(e);
  };
  console.log(selectedPosId, "pos iddssss")

  const handleSave = () => {
    console.log(selectedPosId, "prices from state")
    let product_attribute = {
      name: productDetails?.productName,
      category_id: (productDetails?.category_id)?.toString(),
      sub_category_id: (productDetails?.sub_category_id)?.toString(),
      service_id: productDetails?.service_id,
      description: productDescription,
      type: type,
      // "barcode": barCode,
      // "sku": sku,
      image: imageDetails?.image,
      approx_service_time: Number(approxTime),
      pos_staff_ids: posUserId,
      images: imageDetails?.images,
      price: costPrice,
      delivery_options: deliveryOptions,
      prices: prices,
      sync_google_calendar: syncGoogleCalendar,
      skip_detail_screen_in_pos: skipPosDetails,
      min_capacity: minimumValue,
      max_capacity: maximumValue,
      buffer_time_before:
        parseInt(minutesValue.split(" ")[0]) +
        parseInt(hoursValue.split(" ")[0]) * 60,
      buffer_time_after:
        parseInt(minutesValue1.split(" ")[0]) +
        parseInt(hoursValue1.split(" ")[0]) * 60,
      sync_google_calendar: syncGoogleCalendar,
      skip_detail_screen_in_pos: skipPosDetails,
      // "need_pos_user":true
    };
    let result = formValidator(
      product_attribute,
      activePos,
      activeBtob,
      activeBtoc
    );
    if (result) {
      setSuccessLoader(true);
      let params = {
        postData: product_attribute,
        tokenData: auth?.payload?.token,
      };
      // if (productDetails?.brand_id) {
      //     params = {
      //         "postData": { ...product_attribute, "brand_id": productDetails?.brand_id },
      //         "tokenData": auth?.payload?.token
      //     }
      // }
      // if (approxTime) {
      //     params = {
      //         "postData": { ...product_attribute, "approx_service_time": Number(approxTime) },
      //         "tokenData": auth?.payload?.token
      //     }
      // }
      dispatch(createProductApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setSuccessLoader(false);
          toast.success("Service Created Successfully");
          history.push(`/service-list`);
        })
        .catch((obj) => {
          setSuccessLoader(false);
        });
    }
  };

  const handleOptionValue = (e) => {
    return <>{e}</>;
  };

  const handleSelect = (e) => {
    let allIds = e.map((v) => {
      return v.pos_staff_details_id ? v.pos_staff_details_id : v?.id;
    });
    setPosUserId(allIds);
  };
  const setInitialData = () => {
    setMaximumValue(userData?.supplies[0]?.max_capacity);
    setMinimumValue(userData?.supplies[0]?.min_capacity);
    setApproxTime(
      userData?.supplies ? userData?.supplies[0]?.approx_service_time : ""
    );
    setImageDetails({
      image: userData?.image ?? "",
      images: userData?.product_images?.map((item) => {
        return item?.url ?? " ";
      }),
    });
    setHoursValue(Math.floor(userData?.supplies[0]?.buffer_time_before / 60) + " " + "hr")
    setMinutesValue(userData?.supplies[0]?.buffer_time_before % 60 + " " + "min")
    setHoursValue1(Math.floor(userData?.supplies[0]?.buffer_time_after / 60) + " " + "hr")
    setMinutesValue1(userData?.supplies[0]?.buffer_time_after % 60 + " " + "min")
    setSkipPosDetails(userData?.supplies[0]?.skip_detail_screen_in_pos)
    setSyncGoogleCalendar(userData?.supplies[0]?.sync_google_calendar)
    setProductDescription(userData?.description);
    setPrices(userData?.supplies[0].supply_prices ?? [])
    setPosUserId(userData?.pos_staff?.map(item => item?.pos_staff_details?.id))
  };

  useEffect(() => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        need_staff_member: true,
      },
      tokenData: auth?.payload?.token,
    };


    setLoading(true);
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        // const modifiedData = obj.payload.pos_staff.map((item) => ({
        //   value: `${item.user.user_profiles.firstname} ${item.user.user_profiles.lastname}`,
        //   id: item.id,
        // }));
        if(userData){
          let staffIds = userData?.pos_staff?.map(item => item?.pos_staff_details_id)
          setSelectedPosId(obj?.payload?.pos_staff?.filter(value => staffIds?.includes(value?.id)))
        }
        setPosList(obj?.payload?.pos_staff);
        setLoading(false);

      })
      .catch((obj) => {
        setLoading(false);
      });
    if (userData?.from === "duplicateService") {
      setInitialData();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Add-Services";
  }, []);
  return (
    <div className="addProductManually addserviceManually">
      {/* Start FixedTopHeader */}
      <AddServiceHeader
        save={(e) => handleSave(e)}
        successLoader={successLoader}
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper">
        <div className="container-fluid pt-3 pb-3">
          <div className="row justify-content-center">
            <div className="col-md-10 ">
              {/* Start Uploaded ProductImg Area */}
              <ProductImageAdd imageData={(e) => handleImageDetails(e)} />
              {/* End Uploaded ProductImg Area */}

              {/* Start ProductDetails Area */}
              <div className="mt-4">
                <div className="row">
                  {/* Start ProductDetails Area */}
                  <div className="col-md-8">
                    <ProductDetails
                      productDetails={(e, flag) =>
                        handleProductDetails(e, flag)
                      }
                    />
                    {/* Start CostPrice Area */}
                    <div className="productDetailsContainer mt-3 ps-3 pe-3">
                      <form className="costPrice">
                        <div className="form-group">
                          <label className="subheaderHeading mb-2">
                            Cost Price
                            <img
                              src={infoImg}
                              alt="InfoImg"
                              className="infoIcon ms-3"
                            />
                          </label>
                          <div className="inputGroup">
                            <input
                              min="1"
                              onKeyDown={blockInvalidChar}
                              className="customform-control"
                              placeholder="Cost Price"
                              name="text"
                              type="number"
                              value={costPrice}
                              onChange={(e) => setcostPrice(e.target.value)}
                            />
                            <span className='dollrsign_'>$</span>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* End CostPrice Area */}

                    {/* Start JobrPos Area */}
                    <ProductPrice
                      activePos={activePos}
                      activeBtoc={activeBtoc}
                      activeBtob={activeBtob}
                      activeOnlineStore={activeOnlineStore}
                      costPrice={costPrice}
                      prices={(e) => handleprices(e)}
                    />

                    {/* End JobrB2B Area */}
                    {/*----- Start Options Area -----*/}

                    {/*----- End Options Area -----*/}
                  </div>

                  {/* End ProductDetails Area */}
                  <div className="col-md-4">
                    {/* Start ProductAvailability Area */}
                    <div className="outerArea mb-3">
                      <h3 className="subheaderHeading mb-4">
                        Service availability
                      </h3>
                      <ul className="productToggleMenus">
                        <li className="productToggleItems">
                          <div className="form-check form-switch me-2 mb-0">
                            <input
                              type="checkbox"
                              //checked={true}
                              checked={activePos}
                              onChange={() => setActivePos(!activePos)}
                            />
                          </div>
                          <h3 className="labelTxt">
                            <b>JOBR POS</b> <i>(Physical store)</i>
                          </h3>
                        </li>
                        <li className="productToggleItems">
                          <div className="form-check form-switch me-2 mb-0">
                            <input
                              type="checkbox"
                              checked={activeOnlineStore}
                              onChange={() => handleChangeJobrPos()}
                            />
                          </div>
                          <h3 className="labelTxt">
                            <b>JOBR Online Store</b>
                          </h3>
                        </li>
                        {activeOnlineStore ? (
                          <>
                            <li className="productToggleItems">
                              <div className="form-check form-switch me-2 mb-0">
                                <input
                                  type="checkbox"
                                  checked={activeBtoc}
                                  onChange={() => setActiveBtoc(!activeBtoc)}
                                />
                              </div>
                              <h3 className="labelTxt">
                                <b>JOBR B2C</b>
                              </h3>
                            </li>
                            {/* <li className="productToggleItems pl-2">
                              <div className="form-check form-switch  me-2 mb-0 ">
                                <input
                                  type="checkbox"
                                  checked={activeBtob}
                                  onChange={() => setActiveBtob(!activeBtob)}
                                />
                              </div>
                              <h3 className="labelTxt">
                                <b>JOBR B2B</b>
                              </h3>
                            </li> */}
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>

                    <div className="productDetailsContainer mt-3 ps-3 pe-3">
                      <form className="costPrice">
                        <div className="form-group">
                          <label className="subheaderHeading mb-2">
                            Approx Service Time
                          </label>
                          <div className="inputGroup">
                            <input
                              min="1"
                              onKeyDown={blockInvalidChar}
                              className="customform-control"
                              placeholder="Service time in min"
                              name="serviceTime"
                              type="number"
                              value={approxTime}
                              onChange={(e) => setApproxTime(e.target.value)}
                            />
                          </div>
                        </div>
                      </form>
                      <div className="row">
                        <label className="subheaderHeading mb-2 mt-4">
                          Capacity
                        </label>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-2">Minimum</label>
                            <div className="inputGroup">
                              <div className="inputGroup">
                                <select
                                  className="cateSelectdFilter"
                                  placeholder="maximum"
                                  defaultValue={minimumValue}
                                  onChange={(e) =>
                                    setMinimumValue(e.target.value)
                                  }
                                >
                                  <option value="Min">Min</option>
                                  {selectValuesMinMaxOptions.map((number) => (
                                    <option key={number} value={number}>
                                      {number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mb-2">Maximum</label>
                            <div className="inputGroup">
                              <select
                                className="cateSelectdFilter"
                                placeholder="minimum"
                                defaultValue={maximumValue}
                                onChange={(e) =>
                                  setMaximumValue(e.target.value)
                                }
                              >
                                <option value="Max">Max</option>
                                {selectValuesMinMaxOptions.map((number) => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <label className="subheaderHeading mb-2 mt-4">
                          Buffer Time Before
                        </label>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="inputGroup">
                              <div className="inputGroup">
                                <select
                                  className="cateSelectdFilter"
                                  placeholder="hours"
                                  value={hoursValue}
                                  onChange={(e) =>
                                    setHoursValue(e.target.value)
                                  }
                                >
                                  {selectValuesHoursOptions.map((number) => (
                                    <option key={number} value={number}>
                                      {number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="inputGroup">
                              <select
                                className="cateSelectdFilter"
                                placeholder="minutes"
                                value={minutesValue}
                                onChange={(e) =>
                                  setMinutesValue(e.target.value)
                                }
                              >
                                {selectValuesMinutesOptions.map((number) => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <label className="subheaderHeading mb-2 mt-4">
                          Buffer Time After
                        </label>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="inputGroup">
                              <div className="inputGroup">
                                <select
                                  className="cateSelectdFilter"
                                  placeholder="hours"
                                  value={hoursValue1}
                                  onChange={(e) =>
                                    setHoursValue1(e.target.value)
                                  }
                                >
                                  {selectValuesHoursOptions.map((number) => (
                                    <option key={number} value={number}>
                                      {number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="inputGroup">
                              <select
                                className="cateSelectdFilter"
                                placeholder="minutes"
                                value={minutesValue1}
                                onChange={(e) =>
                                  setMinutesValue1(e.target.value)
                                }
                              >
                                {selectValuesMinutesOptions.map((number) => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="outerArea mt-3">
                      <ul className="productToggleMenus">
                        <li className="productToggleItems">
                          <div className="form-check form-switch me-2 mb-0">
                            <input
                              type="checkbox"
                              id="skipPOS"
                              checked={syncGoogleCalendar}
                              onChange={(e) => {
                                handleSyncGoogleCalendar(e);
                              }}
                            />
                          </div>
                          <h3 className="labelTxt">
                            <b>Sync Google Calendar </b>
                          </h3>
                        </li>
                      </ul>
                      <p className="descriptionPara_ mt-4">
                        Enable this option if you want to include your event in
                        Google Calendar.
                      </p>
                    </div> */}

                    {/* <div className="outerArea mt-3">
                      <ul className="productToggleMenus">
                        <li className="productToggleItems">
                          <div className="form-check form-switch me-2 mb-0">
                            <input
                              type="checkbox"
                              id="skipPOS"
                              checked={skipPosDetails}
                              onChange={(e) => {
                                handleSkipPOSDetails(e);
                              }}
                            />
                          </div>
                          <h3 className="labelTxt">
                            <b>Skip Details Screen in POS</b>
                          </h3>
                        </li>
                      </ul>
                      <p className="descriptionPara_ mt-4">
                        Enable this option if the product needs to be physically
                        delivered to customers either via shipping or by
                        self-pickup. If this product is a service or a
                        downloadable item that doesn’t require delivery, keep
                        this option disabled.
                      </p>
                    </div> */}

                    {/* <div className='outerArea mb-3'>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="physical"
                                                            value="physical"
                                                            checked={type === "physical"}
                                                            onChange={(e) => { setType(e.target.value) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Physical Product</b></h3>
                                                </li>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="digital"
                                                            value="digital"
                                                            checked={type === "digital"}
                                                            // onChange={(e) => { setType(e.target.value) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Digital product or service</b></h3>
                                                </li>
                                            </ul>
                                            {type ?
                                                <form className='productDimensions mt-4'>
                                                    <div className='form-group mb-3'>
                                                        <label className="deatilsHead mb-2 fw-normal">Barcode(ISBN,UPC,GTIN,etc.)
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <input className="customform-control border" placeholder="888 1235647890" name="weight" type="text" value={barCode} onChange={(e) => { setBarCode(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <label className="deatilsHead mb-2 fw-normal">SKU (Stock Keeping Unit)
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <input className="customform-control border" placeholder="GO-123" name="weight" type="text" value={sku} onChange={(e) => { setSku(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                </form>
                                                : ""}
                                        </div> */}
                    {/* End ProductAvailability Area */}
                    {/* Start Inventory Area */}

                    {/* End Inventory Area */}
                    {/* Start Attributes Area */}

                    {/* End Attributes Area */}
                    {/* Start ShippingPickup Area */}

                    {/* End ShippingPickup Area */}
                  </div>
                </div>
              </div>
              {/* End ProductDetails Area */}
              <div className="row mt-3 mb-3">
                <div className="col-md-8">
                  <div className="outerArea ">
                    <label className="labelTxt mb-3" htmlFor="amount">
                      <b>Pos User</b>
                    </label>

                    {selectedPosId?.length > 0 ? (
                      <>
                        {selectedPosId?.map((val) => {
                          return (
                            <img
                              className="me-2"
                              src={val?.pos_staff_details?.user?.user_profiles?.profile_photo ? val?.pos_staff_details?.user?.user_profiles?.profile_photo : val?.user?.user_profiles?.profile_photo ? val?.user?.user_profiles?.profile_photo : profileImg1}
                              alt="pos_img"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50px",
                              }}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <Multiselect
                                            className='brandFilter customform-control'
                                            displayValue="value"
                                            options={posList}
                                            loading={loading}
                                            isObject={true}
                                            placeholder='Select Pos User'
                                            hidePlaceholder={false}
                                            // selectionLimit={1}
                                            onSelect={handleSelect}
                                            onRemove={handleSelect}
                                            optionValueDecorator={(e) => handleOptionValue(e)}
                                            selectedValueDecorator={(e) => handleOptionValue(e)}
                                            avoidHighlightFirstOption={true}
                                            showCheckbox
                                        /> */}
                    <span class="editIcon_">
                      <i
                        class="fa-solid fa-plus"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModalDetail({ show: true, flag: "addStaff" });
                          setKey(Math.random());
                        }}
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "ActivePos" ? "customContent" : ""}
        ids={
          modalDetail.flag === "ActivePos"
            ? "activePos"
            : modalDetail.flag === "addStaff"
              ? "addStaff"
              : ""
        }
        size={modalDetail.flag === "addStaff" ? "lg" : "md"}
        child={
          modalDetail.flag === "addStaff" ? (
            <AddStaffModal
              posIds={selectedPosId}
              close={() => handleOnCloseModal()}
              getIdsHandle={(e) => getIdsHandle(e)}
              handleSelect={e => handleSelect(e)}
            />
          ) : (
            <></>
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              <h2 className="modalHeading">Staffs</h2>
              <button className="closeButton">
                <i
                  className="las la-times"
                  onClick={() => handleOnCloseModal()}
                ></i>
              </button>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default AddService;