import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Steps } from "rsuite";
import "rsuite/dist/rsuite.css";

const TwoAuthentication = (props) => {

  return (
    <div className="walletauthModal">
      <div className="walletauthHead">
        {" "}
        <h4>Enable Two-Steps Security</h4>
        <button className="closeAction">
          <i className="las la-times" onClick={props.close}></i>
        </button>
      </div>
      <div className="walletauthContent">
        <div className="twofaContenthead">
          <Steps current={0}>
            <Steps.Item />
            <Steps.Item />
            <Steps.Item />
          </Steps>
        </div>
        <div className="twofamiddleBox">
          <h3 className="textHeading">Setup authenticator app </h3>
          <p className="textParagh">
            Two factor authenticarion addds an additional layer of security to
            your account by requiring more than just a password to sign in.
          </p>

          <h5>Scan the QR code</h5>
          <p className="textParagh">
            {" "}
            Two factor authenticarion addds an additional layer of security to
            your account by requiring more than just a password to sign in.
          </p>
          <div className="qrCode">
            <img src='' />
          </div>
          <div className="verifyCode">
            <h3>Verify the code from app</h3>
            <input type="text" className="form-control" />
          </div>
        </div>
      </div>
      <div className="walletauthBottom">
        <button type="button" className="BackBtn">
          Cancel
        </button>
        <button type="button" className="NextBtn">
          Continue
        </button>
      </div>
    </div>
  );
};

export default TwoAuthentication;
<Steps current={1}>
  <Steps.Item />
  <Steps.Item />
  <Steps.Item />
  <Steps.Item />
</Steps>;
