import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { getFaqCategoryDataApiAsync, updateFaqApiAsync } from '../../dashboard/dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';

const FaqeditModal = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [allCategories, setAllCategories] = useState([]);
  const [faq, setFaq] = useState(props?.selectedData?.title ? props?.selectedData?.title : "");
  const [faqText, setFaqText] = useState(props?.selectedData?.discription ? props?.selectedData?.discription : "");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [retailStatus, setRetailStatus] = useState(props?.selectedData?.retail_admin ? props?.selectedData?.retail_admin : false)
  const [manufactureStatus, setManufactureStatus] = useState(props?.selectedData?.manufacture_admin ? props?.selectedData?.manufacture_admin : false)
  const [distroStatus, setDistroStatus] = useState(props?.selectedData?.distro_admin ? props?.selectedData?.distro_admin : false)
  const [driverStatus, setDriverStatus] = useState(props?.selectedData?.driver_app ? props?.selectedData?.driver_app : false)
  const [deliveryStatus, setDeliveryStatus] = useState(props?.selectedData?.delivery_app ? props?.selectedData?.delivery_app : false)
  const [walletStatus, setWalletStatus] = useState(props?.selectedData?.wallet_app ? props?.selectedData?.wallet_app : false)



  const items = [
    { id: 1, label: 'Retail Admin', status: retailStatus },
    { id: 2, label: 'Manufacture Admin', status: manufactureStatus },
    { id: 3, label: 'Distro Admin', status: distroStatus },
    { id: 4, label: 'Driver App', status: driverStatus },
    { id: 5, label: 'Wallet App', status: walletStatus },
    { id: 6, label: 'Delivery App', status: deliveryStatus },
  ];


  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleCheckboxChange = (id, status) => {
    if (id === 1) {
      setRetailStatus(!status)
    }
    if (id === 2) {
      setManufactureStatus(!status)
    }
    if (id === 3) {
      setDistroStatus(!status)
    }
    if (id === 4) {
      setDriverStatus(!status)
    }
    if (id === 5) {
      setWalletStatus(!status)
    }
    if (id === 6) {
      setDeliveryStatus(!status)
    }
  };




  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      toast.error("please select category");
      return;
    }
    else if (!faq) {
      toast.error("Please select faq number");
      return;
    }
    else if (!faqText) {
      toast.error("Please select write faq text");
      return;
    }
    if (!retailStatus && !manufactureStatus && !distroStatus && !driverStatus && !walletStatus && !deliveryStatus) {
      toast.error("Please select atleast one platform for publish");
      return;
    }

    let params = {
      "postData": {
        'id': props?.selectedData?.id,
        "faq_category_id": parseInt(selectedCategory),
        "title": faq,
        "discription": faqText,
        "retail_admin": retailStatus,
        "manufacture_admin": manufactureStatus,
        "distro_admin": distroStatus,
        "driver_app": driverStatus,
        "wallet_app": walletStatus,
        "delivery_app": deliveryStatus,
      },
      "tokenData": auth?.payload?.token
    }
    dispatch(updateFaqApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        toast.success(obj?.msg);
        props.faqDataHandle();
        props.close();
      })
      .catch((obj) => {
      })
  }


  const faqCatagoryData = () => {
    let params = {
      "tokenData": auth?.payload?.token
    }
    dispatch(getFaqCategoryDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAllCategories(obj?.payload?.data)
        const allCategory = obj?.payload?.data;
        const selectedCategory = allCategory.find(item => item.id == props?.selectedData?.faq_category_id)
        setSelectedCategory(selectedCategory?.id.toString())
      })
      .catch((obj) => {
      })
  }

  useEffect(() => {
    faqCatagoryData()
  }, [])


  return (
    <div className='addnewfaqModal'>
      <form className='emplyoeeDetails horizontalForms' onSubmit={(e) => { handleSubmit(e) }}>
        <div className='row'>
          <div className='form-group mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>Select Category </label>
            <select className='customform-control select fw-normal' placeholder='Select Category' value={selectedCategory} onChange={handleSelectChange}>
              <option value="" >Select Category</option>
              {allCategories?.map((option, index) => (
                <option key={index} value={option?.id}>
                  {option?.category_name}
                </option>
              ))}
            </select>
          </div>
          <div className='form-group col-md-12  mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>FAQ </label>
            <input type='text' className='customform-control' placeholder='Routing Number'
              value={faq} onChange={e => setFaq(e.target.value)} />
          </div>
          <div className='form-group col-md-12  mb-4'>
            <textarea className='customform-control' name="w3review" rows="8" cols="50" placeholder=' Write here'
              value={faqText} onChange={e => setFaqText(e.target.value)} >
            </textarea>
          </div>
          <div className='faqcheck_'>
            <h4 className='subheadText fw-bold mb-3'>Select Platform for Publish</h4>
            <div className='row checkboxInner_'>
              {items.map((item, index) => (
                <div className=' col-md-6 mb-3' key={index}>
                  <input
                    className="me-2"
                    type="checkbox"
                    value={item.id}
                    checked={item?.status === true}
                    // checked={item.id == (selectedPlatform?.find(value => value == item.id))}
                    // onChange={(e) => handleCheckboxChange(e)}
                    onChange={() => handleCheckboxChange(item?.id, item?.status)}
                  />
                  <label className='contentheadtext_' >  {item.label}</label>
                </div>
              ))}
            </div>
          </div>
          
        </div>

      <div className='footerSec mt-4 '>
            {/* <button className='closebtnModal me-3' onClick={props.close}>Discard</button>
            <button className='saveBtn_ lh-sm me-3' type='button'>Save as Draft</button>
            <button className='publishBtn_ lh-sm' type="submit" >Publish</button> */}
            <button className='discardBtn me-3' onClick={props.close}>Cancel</button> 
            {/* <button className='saveBtn_ lh-sm me-3' type='button'>Save as Draft</button> */}
            <button className='saveBtn' type='submit'>Save</button>
          </div>
          </form>
    </div>
  )
}

export default FaqeditModal