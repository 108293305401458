import { toast } from 'react-toastify';

function editProductValidator(obj, dimensions) {
  
    if (!obj?.image) {
        toast.error("Please select product images")
        return false
    }
    if (!obj?.name) {
        toast.error("Please enter product name")
        return false
    }
    if (!obj?.service_id) {
        toast.error("Please select service")
        return false
    }
    if (!obj?.category_id) {
        toast.error("Please select catgeory")
        return false
    }
    if (!obj?.sub_category_id) {
        toast.error("Please select sub-category")
        return false
    }
    if (!obj?.brand_id) {
        toast.error("Please select brand")
        return false
    }
    if (!obj?.description) {
        toast.error("Please enter product description")
        return false
    }
    // if (!obj?.price) {
    //     toast.error("Please enter product price")
    //     return false
    // }
    if (!obj?.type) {
        toast.error("Please enter product type")
        return false
    }
    if (!obj?.barcode) {
        toast.error("Please enter barcode")
        return false
    }
    if ((obj?.sku && obj?.sku?.length < 11) || (obj?.sku && obj?.sku?.length > 14)) {
        toast.error("Please enter valid sku")
        return false
    }
    if ((obj?.barcode && obj?.barcode?.length < 11) || (obj?.barcode && obj?.barcode?.length > 14)) {
        toast.error("Please enter valid barcode")
        return false
    }
    // if (!dimensions?.weight) {
    //     toast.error("Please enter product weight")
    //     return false
    // }
    // if (!dimensions?.length) {
    //     toast.error("Please enter product length")
    //     return false
    // }
    // if (!dimensions?.breadth) {
    //     toast.error("Please enter product bredth")
    //     return false
    // }
    // if (!dimensions?.height) {
    //     toast.error("Please enter product height")
    //     return false
    // }
    return true
}
export { editProductValidator };