import React from 'react'
import DashboardNavbar from '../dashboard/DashboardNavbar'
import {search_light } from '../../../utilities/images'


const ProductUploadPreview = () => {
    return (
        <>
            <div className='productPreviewSection rightWrapper headerWrap'>
                <DashboardNavbar title="File Upload: Preview" backShow={false} />
                <div className='productViewSub'>
                    <div className='row'>
                        {/* <div className='col-lg-6 col-md-6 d-flex align-items-center'>
                            <div className='checkView me-3'>
                               <input type="checkbox" className='checkBx'/>
                               <label htmlFor="register" className=' mainlightPhragraph '>This is registered business. </label>
                            </div>
                        </div> */}
                        <div className='col-lg-6 col-md-6'>
                            <div className='productViewRight'>
                                <div className="input-group search_group">
                                    <img src={search_light} alt="" className="img-fluid searchIcon" />
                                    <input type="text" className="form-control" placeholder="Search here" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                </div>
                                <button  className='loginBtnSign'>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductUploadPreview