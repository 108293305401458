import React, { useEffect, useState } from 'react'
import { helpCenter, topics } from '../../../utilities/images';
import { getTrendingArticleApiAsync } from '../dashboard/dashboardSlice'
import { selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import DashboardNavbar from '../dashboard/DashboardNavbar'
import Accordion from 'react-bootstrap/Accordion';
import CustomModal from '../../components/shared/CustomModal';
import RefundoverviewModal from './refundoverviewModal';

const Support = () => {
  const [trendingArticle, setTrendingArticle] = useState([])
  const [articleTitle, setArticleTitle] = useState("")
  const [articleDescription, setArticleDescription] = useState("")
  const [keyword, setKeyword] = useState("")
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [activeTab, setActiveTab] = useState("createWallet")
  const [key, setKey] = useState(Math.random());

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const getTrendingArticle = (data) => {
    let params = {
      "postData": {
        "type": "article"
      },
      "tokenData": auth?.payload?.token
    }

    if(keyword) {
      params = {
        "postData": {
          "type": "article",
          "search": data
        },
        "tokenData": auth?.payload?.token
      }
    }
    setLoading(true)
    dispatch(getTrendingArticleApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setTrendingArticle(obj?.payload?.data)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if(keyword?.length > 3 || keyword.length == 0){
      getTrendingArticle(keyword)
    }
  }, [keyword])


  useEffect(()=>{
    window.scrollTo(0, 0);
    document.title = "Support";
},[])

  return (
    <div className='supportMain'>
      <DashboardNavbar title="Support" />
      <div className='supportHelp_'>
        <div className='chartsOuter mb-3'>
          <div className='helpSupport_'>
            <div className='row text-center justify-content-center'>
              <div className='col-md-6 col-sm-12'>
                <div className='howcanHelp_'>
                  <h4 className='priceHead_ mb-3'>Hi. How can we help?</h4>
                  <input type="text" class="form-control searchBoc_" placeholder="Search here" value={keyword} onChange={(e) => setKeyword(e.target.value)}/>
                </div>
              </div>
            </div>
          </div>
          <div className='trendingArticle_'>
            <h3 className='subheadsmall_ mb-4'>Trending Articles</h3>
            <div className='row'>

              {trendingArticle?.map((val, index) => {
                return (
                  <>
                    <div className='col-md-6 mb-3'>
                      <div className='aboutJobr_'>
                        <h3 className='subtextDark_'>{val?.title}</h3>
                        <i class="fa-solid fa-chevron-right" onClick={() => {
                          setModalDetail({ show: true, flag: "refundoverviewModal" });
                          setKey(Math.random());
                          setArticleTitle(val?.title)
                          setArticleDescription(val?.discription)
                        }}></i>
                      </div>
                    </div>
                  </>
                )
              })}

            </div>

          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='scalePlans'>
              <Accordion defaultActiveKey="1" className='mb-4' flush>
                <Accordion.Item eventKey="1" className='backContainer'>
                  <Accordion.Header>
                    <div className=''>
                      <h3 className='subheadsmall_'>Explore more support</h3>
                      <p className='agentHeading mb-0'>Check out these resources for answers to your questions, videos, and best practices.</p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className='mt-4'>
                    <div className='plansContainer moreExport'>
                      {/* Start InnerCollapse */}
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1" className={`plansItems ${activeTab == "HelpCenter" ? "active" : ""}`} onClick={() => setActiveTab("HelpCenter")}>
                          <Accordion.Header>
                            <img src={helpCenter} alt='' className='me-3' />
                            <div>
                              <h4 className='textInnerDark mb-1'>Help Center</h4>
                              <p className='smallText mb-0'>Before you open your store, first you need some products.</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className={`plansItems ${activeTab == "AskTopic" ? "active" : ""}`} onClick={() => setActiveTab("AskTopic")}>
                          <Accordion.Header>
                            <img src={topics} alt='' className='me-3' />
                            <div>
                              <h4 className='textInnerDark mb-1'>Ask us about a topic</h4>
                              <p className='smallText'>Before you open your store, first you need some products.</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      {/* End InnerCollapse */}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "RefundoverviewModal" ? "commonWidth modalcommon_" : ""}
        ids={modalDetail.flag === "refundoverviewModal" ? "refundoverviewModal" :
          modalDetail.flag === "refundoverviewModal" ? "purchasetransitModule" : ""}
        size={modalDetail.flag === "refundoverviewModal" ? "xl" : "md"}


        child={

          modalDetail.flag === "refundoverviewModal" ? (
            <RefundoverviewModal close={() => handleOnCloseModal()} data={articleDescription}/>
          ) :
            (
              ""
            )
        }
        header={
          <>
            {modalDetail.flag === 'refundoverviewModal'
              ?
              <>
                <div className="modalHeader_">
                  <div className="common_">
                    <h3 className='modalHeading_'>{articleTitle}</h3>

                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                      <i className="las la-times" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </> : ""



            }
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  )
}

export default Support