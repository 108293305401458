import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { user } from '../../../../../utilities/images'
import { Link } from 'react-router-dom'
import NewinventoryModal from './newinventoryModal'
import AddnewInventoryModal from './addnewInventoryModal'
import CustomModal from '../../../../components/shared/CustomModal';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartmentDataInSupportApiAsync, getSingleOrderPurchaseApiAsync } from '../../../dashboard/dashboardSlice'
import { selectLoginAuth } from '../../../auth/authSlice'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import moment from 'moment-timezone'

const RefundorderInvoice = () => {
    const history = useHistory()
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch()
    const [issueId, setIssueId] = useState("");
    const [issueData, setIssueData] = useState([]);
    const { id } = useParams();
    const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleDepartmetChange = (e) => {
        setIssueId(e.target.value)
    }

    const handleSingleOrderData = () => {
        let params = {
            "postData": {
                id: id
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getSingleOrderPurchaseApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    // const getAllIssueList = () => {
    //     setLoading(true)
    //     dispatch(getDepartmentDataInSupportApiAsync(auth?.payload?.token))
    //         .then(unwrapResult)
    //         .then((obj) => {
    //             setIssueData(obj?.payload)
    //             setLoading(false)
    //         }
    //         )
    //         .catch((obj) => {
    //             setLoading(false)
    //         })
    // }

    useEffect(() => {
        // getAllIssueList()
        handleSingleOrderData()
    }, [])

    return (
        <div className='refundoredrInvoice'>
            {/* Start FixedTopHeader */}
            <div className='topHeader_'>
                <div className="productTopStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="innerSubheadNormal">
                                <span onClick={() => history.push('/refundOrders')}>
                                    <i className="las la-angle-left me-3 directionArrow"></i>
                                </span>
                                <span>Refund/Return id#{id}</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* End FixedTopHeader */}
            <div className="refundorderWrapper">
                <div className='container-fluid pt-3 pb-3'>
                    {
                        loading === true ?
                            <span className="spinner-border inner-spin spinner-border-sm serviceList"></span>
                            :
                            <div className='row justify-content-center'>
                                <div className='col-md-10 '>
                                    <div className="toporderCommon">
                                        <div className="row">
                                            <div className='CustomerOnlinestatus_ '>
                                                <div className='invoiceHead mb-3'>
                                                    <h3 className='invoiceHeading'>Invoice</h3>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='invoiceForm'>
                                                            <h5 className='semiboldHeading_'>Invoice from:</h5>
                                                            <p className='smalllightHead_'>{data?.seller_details?.firstname + " " + data?.seller_details?.lastname}</p>
                                                            <p className='smalllightHead_'>{data?.seller_details?.phone_number}</p>
                                                            <p className='smalllightHead_'>{data?.seller_details?.current_address?.street_address}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='invoiceForm'>
                                                            <h5 className='semiboldHeading_'>Return/Refund:</h5>
                                                            <p className='smalllightHead_ fw-500'>Driver# <Link className='textBlue'>{data?.driver_details?.uid}</Link></p>
                                                            <p className='smalllightHead_ fw-500'>POS #36259</p>
                                                            <p className='smalllightHead_ fw-500'>Customer Unreachable </p>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-4">
                                                        <div className="invoiceForm">
                                                            <h5 className='semiboldHeading_'>Invoice details</h5>
                                                            <p className='invoiceHead'>
                                                                Invoice Number : <span>{data?.invoices?.invoice_number}</span>
                                                            </p>
                                                            <p className='invoiceHead'>
                                                                Created Date : <span>{moment(data?.invoices?.created_date).format("DD MMM YYYY")}</span>
                                                            </p>
                                                            <p className='invoiceHead'>
                                                                Canceled Date : <span>{moment(data?.invoices?.cancelled_date).format("DD MMM YYYY")}</span>
                                                            </p>
                                                            <p className='invoiceHead fw-500'>
                                                                <span>{moment(data?.invoices?.due_date).format("DD/MM/YYYY")}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="customerInvoicetable mt-4">
                                            <div className="table-responsive">
                                                <table className="table  listItemsContainer">
                                                    <thead className="tHead_">
                                                        <th className="text-start">#</th>
                                                        <th className="text-start"></th>
                                                        <th className="text-start">Descriptions</th>
                                                        <th className="text-start">No. of Items</th>
                                                        <th className="text-start">Rate</th>
                                                        <th>Amount</th>
                                                        <th className="text-start">Condition</th>
                                                        <th className="text-start">Action</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row">
                                                            {
                                                                data?.order_details?.length > 0 ?
                                                                    data?.order_details?.map((v, i) => {
                                                                        return (
                                                                            <React.Fragment key={i}>
                                                                                <td className="text-start">1</td>
                                                                                <td className="text-start">
                                                                                    <div className='rolecheckbox_'>
                                                                                        <input
                                                                                            className="me-2"
                                                                                            type="checkbox"
                                                                                            id="view1"
                                                                                            value=""
                                                                                        // checked={productIds?.includes(v?.id)}
                                                                                        // onChange={() => handleCheckBox(v?.id)}
                                                                                        />
                                                                                        <label className='contentheadtext_ mb-3' htmlFor="view1"> </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-start productonlineDetail">
                                                                                    <img src={user} alt="profileimg" className="tableProductimg" />
                                                                                    <div className="name_content">
                                                                                        <h6 className="contentheadtext_">{v?.product_name}</h6>
                                                                                        {/* <span className="contentHeadtextlight_">Box of 25</span> */}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <p>{v?.qty}</p>
                                                                                    {/* <input type="text" className='cateSelectd' placeholder='' value={v?.qty} /> */}
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <p className="contentheadtext_">${v?.price}</p>
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <p className="itemsTxt">${v?.price * v?.qty}</p>
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <select className="cateSelectdFilter" required onChange={(e) => handleDepartmetChange(e)}>
                                                                                        <option value="">Select One</option>
                                                                                        {
                                                                                            (issueData && issueData?.length > 0) ?
                                                                                                <>
                                                                                                    {issueData?.map((value, index) => {
                                                                                                        return (
                                                                                                            <option value={value?.id} key={index}>{value?.name}</option>
                                                                                                        )
                                                                                                    })}
                                                                                                </> : <></>
                                                                                        }
                                                                                    </select>
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <Link to='#' className='acceptBtn_ ' data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                                                                                        setModalDetail({ show: true, flag: "AddnewInventoryModal" });
                                                                                        setKey(Math.random());
                                                                                    }}>Add back to Inventory</Link>
                                                                                </td>
                                                                            </React.Fragment>)
                                                                    }) : <></>
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="totalArea">
                                                <div className='row'>
                                                    <div className="col-md-5 text-start">
                                                        <div className="notes">
                                                            <textarea
                                                                className="h-auto"
                                                                placeholder="Notes"
                                                                id="floatingTextarea"
                                                                rows="10"
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 text-start">
                                                        <div className="Customertotal">
                                                            <div className="totalDetails">
                                                                <h5 className="personalinfoHead">Sub Total</h5>
                                                                <h5 className="itemsTxt">${data?.total_sale_price}</h5>
                                                            </div>
                                                            <div className="totalDetails">
                                                                <h5 className="personalinfoHead">Discount {data?.coupon_code ? (data?.coupon_code) : ""}</h5>
                                                                <h5 className="itemsTxt Orangetext">${data?.discount}</h5>
                                                            </div>
                                                            <div className="totalDetails">
                                                                <h5 className="personalinfoHead">{data?.is_shipment_created === true ? 'Shipping' : 'Delivery'} Charge</h5>
                                                                <h5 className="itemsTxt">${data?.is_shipment_created === true ? data?.shipping_charge : data?.delivery_charge}</h5>
                                                            </div>
                                                            <div className="totalDetails">
                                                                <h5 className="personalinfoHead">Tax</h5>
                                                                <h5 className="personalinfoHead">${data?.tax ? data?.tax : "0"}</h5>
                                                            </div>
                                                            <div className="totalDetails">
                                                                <h5 className="personalinfoHead">Tips</h5>
                                                                <h5 className="personalinfoHead">${data?.tips ? data?.tips : "0"}</h5>
                                                            </div>
                                                            <div className="totalDetails">
                                                                <h5 className="itemsText ">Total</h5>
                                                                <h5 className="itemsTxt">${data?.payable_amount}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='invoiceBottombtn mt-5'>
                                            <button className='declineBtn me-3'>Contact Customer</button>
                                            <button className='acceptBtn_'>Resolved </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "NewinventoryModal" ? "commonWidth customContent" : "AddnewInventoryModal" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "NewinventoryModal" ? "newinventoryModal" : "AddnewInventoryModal" ? "addnewInventoryModal" : ""}
                child={
                    modalDetail.flag === "NewinventoryModal" ? (
                        <NewinventoryModal close={() => handleOnCloseModal()} />
                    ) :
                        modalDetail.flag === "AddnewInventoryModal" ? (
                            <AddnewInventoryModal close={() => handleOnCloseModal()} />
                        ) :
                            (
                                ""
                            )
                }
                header={
                    <>
                        <div className='modalHeader_'>
                            <div className="common_">
                                {modalDetail.flag === "NewinventoryModal" ?
                                    <h2 className="subheading2_">Add New Inventory</h2>
                                    :
                                    modalDetail.flag === "AddnewInventoryModal" ?
                                        <h2 className="subheading2_">Adding New Inventory</h2>
                                        : ""
                                }
                                <button className="closeButton">
                                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                </button>
                            </div>
                        </div>
                    </>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default RefundorderInvoice