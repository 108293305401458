import React, { useEffect, useState } from 'react'
import { viewEye_, calendar, totalOrder, volume, analysis, transactionVolume } from '../../../utilities/images';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import products from '../../db/category.json'
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import moment from 'moment-timezone';
import { getAnalyticStatDataApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const TotalRevenue = () => {
    const history = useHistory()
    const auth = useSelector(selectLoginAuth)
    const today = new Date();
    const [startDate, setStartDate] = useState(null);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [analyticsProfitData, setAnalyticsProfitsData] = useState("")
    const [endDate, setEndDate] = useState(null);
    const dispatch = useDispatch()
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    // const handleRowClick = (e) => {
    //     // console.log("rowclicked", e)
    //     history.push({ pathname: `/salesReport/${e?.flag}`, state: JSON.stringify(e) })
    // }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    function addThousandSeparator(number) {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const columns1 = [
        {
            name: 'Date',
            selector: row => moment(row?.order_date).format('MM/DD/YYYY')
        },
        {
            selector: row => row?.total_items,
            name: 'Total product',
        },
        {
            selector: row => `$${addThousandSeparator(row?.total_price?.toFixed(2))}`,
            name: 'Total Price',
        },
        {
            selector: row => `$${addThousandSeparator(row?.cost_sum?.toFixed(2))}`,
            name: 'Total Cost',
        },
        {
            selector: row => `${(row?.margin)?.toFixed(2)}%`,
            name: 'Margin',
        },
        {
            selector: row => {
                return (<b>${addThousandSeparator(row?.transaction?.toFixed(2))}</b>)
            },
            name: 'Total Volume',
        },''
    ];

    const newUserDataHandle = () => {
        let params = {
            "postData": {
                // is_admin: true,
                filter: filterValue,
                channel: channelFilter,
                page: currentPage,
                limit: perPage,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAnalyticStatDataApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAnalyticsProfitsData(obj?.payload)
                setLoading(false)
                setTotalRows(obj?.payload?.orderData?.data?.total)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        newUserDataHandle()
    }, [filterValue, channelFilter, endDate, currentPage])

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Analytics";
      }, []);

    return (
        <>
            <DashboardNavbar title="Total Revenue" backShow="/analytics" />
            <div className='analyticRevenue'>
                <div className='row mt-4'>
                    <div className='col-lg-5 col-md-12'>
                        <div className='dateChannel'>
                            <div className="calenderIcon">
                                <img src={calendar} alt="calender" />
                                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                <DatePicker
                                    className='cateDate'
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={today}
                                    selectsRange
                                    placeholderText="Date"
                                />
                            </div>
                            <div className=" form-group ">
                                <div className=" form-group ">
                                    <select autoComplete="new-password" className='customform-control  selectControl form-select' value={channelFilter} onChange={(e) => setChannelFilter(e.target.value)}>
                                        <option value="all" >All Channels</option>
                                        <option value="b2c">B2C</option>
                                        <option value="b2b">B2B</option>
                                        <option value="pos">Pos</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 d-flex justify-content-end'>
                        <ul className='weekNav'>
                            <li onClick={() => setFilterValue("today")}><Link className={`weekMenu ${filterValue == "today" ? "active" : ""}`} to="#">Today</Link></li>
                            <li onClick={() => setFilterValue("week")}><Link className={`weekMenu ${filterValue == "week" ? "active" : ""}`} to="#">Weekly</Link></li>
                            <li onClick={() => setFilterValue("month")}><Link className={`weekMenu ${filterValue == "month" ? "active" : ""}`} to="#">Monthly</Link></li>
                            <li onClick={() => setFilterValue("year")}><Link className={`weekMenu ${filterValue == "year" ? "active" : ""}`} to="#">Yearly</Link></li>
                        </ul>
                    </div>
                </div>
                {
                    loading ?
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                        :
                        <div className='grossProfitData'>
                            <div className='row'>
                                <div className='col-lg-3 col-md-6  pe-2'>
                                    <div className='grossTotalorder'>
                                        <img src={totalOrder} className='img-fluid grossImages' alt='grossProfit' />
                                        <p className='groSub mt-4 mb-0'>Total Orders</p>
                                        <h4 className='groMain'>{analyticsProfitData?.overView?.total_orders}</h4>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                                    <div className='grossTotalorder'>
                                        <img src={volume} className='img-fluid grossImages' alt='volume' />
                                        <p className='groSub mt-4 mb-0'>Total Revenue</p>
                                        <h4 className='groMain'>${addThousandSeparator(analyticsProfitData?.overView?.total_revenue ? analyticsProfitData?.overView?.total_revenue?.toFixed(2) : "0.00")}</h4>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                                    <div className='grossTotalorder'>
                                        <img src={analysis} className='img-fluid grossImages' alt='analysis' />
                                        <p className='groSub mt-4 mb-0'>Average Order Value</p>
                                        <h4 className='groMain'>${addThousandSeparator(analyticsProfitData?.overView?.average_value ? analyticsProfitData?.overView?.average_value?.toFixed(2) : "0.00")}</h4>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 ps-2 '>
                                    <div className='grossTotalorder'>
                                        <img src={transactionVolume} className='img-fluid grossImages' alt='transactionVolumeImage' />
                                        <p className='groSub mt-4 mb-0'>Total Volume</p>
                                        <h4 className='groMain'>${addThousandSeparator(analyticsProfitData?.overView?.transaction ? analyticsProfitData?.overView?.transaction?.toFixed(2) : "0.00")}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                <div className='table-responsive'>

                    <DataTable
                        columns={columns1}
                        data={analyticsProfitData?.orderData?.data}
                        defaultSortFieldId={1}
                        className='analytics_'
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                </div>
            </div>
        </>
    )
}

export default TotalRevenue