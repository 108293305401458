import React, { useEffect, useState } from 'react'
import CustomLoader from '../../../components/shared/CustomLoader';
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { createWalletProfileAsync, getMerchantStepStatusAsync } from '../dashboardSlice';
import { getUserByIdApiAsync, selectLoginAuth } from '../../auth/authSlice';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import DashboardNavbar from '../DashboardNavbar';

const Wallet = () => {
    document.title = "Create Wallet";

    const auth = useSelector(selectLoginAuth)
    const date = moment.utc(auth?.payload?.user?.user_profiles?.dob);
    const newDate = date.toDate()
    const [selectedType, setSelectedType] = useState("business")
    const [hide, setHide] = useState(false)
    const [displayValue, setDisplayValue] = useState()
    const history = useHistory();
    const [dob, setDob] = useState(auth?.payload?.user?.user_profiles?.dob ? newDate : "")
    const [ssn, setSsn] = useState("")
    const [gender, setGender] = useState(auth?.payload?.user?.user_profiles?.gender ? auth?.payload?.user?.user_profiles?.gender : "male")
    const [apt, setApt] = useState("")
    const [zipcode, setZipCode] = useState("")
    const [address, setAddress] = useState(auth?.payload?.user?.user_profiles?.current_address?.format_address ? auth?.payload?.user?.user_profiles?.current_address?.format_address : "")
    const dispatch = useDispatch()
    const toastId = React.useRef(null)
    const [piLoading, setPiLoading] = useState(false)
    const [countryCode, setCountryCode] = useState("")
    const [countryValue, setCountryValue] = useState("");
    const [stateCode, setStateCode] = useState("")
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [stateValue, setStateValue] = useState("")
    const [cityValue, setCityValue] = useState("")
    const [email, setEmail] = useState(auth?.payload?.user?.email ? auth?.payload?.user?.email : auth?.payload?.email)
    const generateRandomName = () => {
        return Math.random().toString(36).substr(2, 10);
    };
    const autoCompleteHandleChange = (address) => {
        if (address === "") {
            setCityValue("")
            setStateValue("")
            setCountryValue("")
            setZipCode("")
        }
        setAddress(address);
    };
    const autoCompleteHandleSelect = (address) => {

        geocodeByAddress(address)
            .then((results) => {
                fillAddressDetails(results);
                setDisplayValue(results[0]?.formatted_address?.split(',')[0])
                setHide(true)
                console.log(results[0]?.formatted_address?.split(',')[0], "updated valyue");
                setLatitude(results[0].geometry.location.lat())
                setLongitude(results[0].geometry.location.lng())
            })
            .catch((error) => { });
    };
    const fillAddressDetails = (results) => {
        setAddress(results[0].formatted_address);

        for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] == "postal_code") {
                setZipCode(results[0].address_components[j].short_name);
            } else if (results[0].address_components[j].types[0] == "locality") {
                setCityValue(results[0].address_components[j].long_name);
            } else if (
                results[0].address_components[j].types[0] ==
                "administrative_area_level_1" ||
                results[0].address_components[j].types[0] === 'administrative_area_level_3' ||
                results[0].address_components[j].types[0] === 'locality'
            ) {
                setStateValue(results[0].address_components[j].long_name);
                setStateCode(results[0].address_components[j]?.short_name)
            } else if (results[0].address_components[j].types[0] == "country") {
                setCountryValue(results[0].address_components[j].long_name);
                setCountryCode(results[0].address_components[j]?.short_name);
            }
            else if (results[0].address_components[j].types[0] == 'street_number') {
                setApt(results[0].address_components[j].long_name);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!dob) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your DOB");
            }
            return;
        }
        if (!ssn) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your ssn");
            }
            return;
        }
        const newPattern = /^[0-9\b]+$/;
        var result = newPattern.test(ssn?.toString())
        if (result === false) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter valid ssn number");
            }
            return;
        }
        if (ssn.length < 9) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("ssn number should be of 9 digits");
            }
            return;
        }
        if (!email) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your email");
            }
            return;
        }
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        var resultemail = pattern.test(email);

        if (resultemail === false) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter valid email");
            }
            return;
        }
        if (!address.length > 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your street address");
            }
            return;
        }
        if (!zipcode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your zipcode");
            }
            return;
        }
        if (!countryValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter country")
            }
            return
        }
        if (!stateValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter state")
            }
            return
        }
        if (!cityValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter City")
            }
            return
        }

        let walletData = {
            postData: {
                "type": selectedType,
                "first_name": auth?.payload?.user?.user_profiles?.firstname,
                "last_name": auth?.payload?.user?.user_profiles?.lastname,
                "address_type": "current",
                "address": address.slice(0, 40),
                "city": cityValue,
                "state": stateValue,
                "state_code": stateCode,
                // "gender": gender,
                "zip": zipcode,
                "country": countryValue,
                "country_code": countryCode,
                "phone_code": auth?.payload?.user?.user_profiles?.phone_code,
                "phone": auth?.payload?.user?.user_profiles?.phone_no,
                "email": auth?.payload?.user?.email ? auth?.payload?.user?.email : auth?.payload?.email,
                "dob": moment(dob).format('YYYY-MM-DD'),
                "ssn": ssn,
                "latitude": latitude,
                "longitude": longitude
            },
            tokenData: auth?.payload?.token
        }

        if (apt) {
            walletData = {
                postData: {
                    ...walletData.postData,
                    "apt": apt
                },
                tokenData: auth?.payload?.token
            }
        }
        setPiLoading(true)
        await dispatch(createWalletProfileAsync(walletData))
            .then(unwrapResult)
            .then((obj) => {
                let data = {
                    postData: {
                        // id: auth?.payload?.id,
                        id: auth?.payload?.id ? auth?.payload?.id : auth?.payload?.user?.id
                    },
                    tokenData: auth?.payload?.token
                }
                dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
                    .then(unwrapResult)
                    .then((obj) => {
                    })
                    .catch((obj) => {
                    })
                dispatch(getUserByIdApiAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        setPiLoading(false)
                        // if(selectedType == "business"){
                        //     history.push('/businessRegistration')
                        // }
                        // else{

                        history.push("/request-kyc")
                        // }
                    })
                    .catch((obj) => {
                        setPiLoading(false)
                    })
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
                setPiLoading(false)
            })
    }

    useEffect(() => {
        if (auth?.payload?.user?.user_profiles?.current_address?.format_address) {
            autoCompleteHandleSelect(auth?.payload?.user?.user_profiles?.current_address?.format_address)
            setApt(auth?.payload?.user?.user_profiles?.current_address?.apt)
        }
    }, [auth?.payload?.user?.user_profiles?.current_address?.format_address])

    return (
        <>
            <div className='personalInfo'>
                {/* Start TopFixedHeader */}

                {/* <div className='topHeader_'>
                    <div className="topStickyHeader_ mb-3">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <Link to="/plans"> <i className="las la-long-arrow-alt-left me-3"></i></Link>
                                    <span>Personal Information</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div> */}
                <DashboardNavbar title="Create JBR wallet" backShow="/plans" />
                {/* End TopFixedHeader */}
                <form className="form_horizontal" autoComplete="nope" onSubmit={(e) => { handleSubmit(e) }}>
                    {/* <label className='titleTxt mb-3'>Personal Information</label> */}
                    <div className='form_horizontalGroup'>
                        <div className='row'>
                            <div className="mb-4 form-group col-md-6">
                                <label className="deatilsHead mb-3">Email Address</label>
                                <input
                                    type="email"
                                    className="customform-control"
                                    name={generateRandomName}
                                    autoComplete='new-password'
                                    placeholder="Email"
                                    value={email} onChange={e => setEmail(e.target.value)}
                                    disabled
                                />
                            </div>
                            <div className="mb-4 form-group col-md-6">
                                <label className="deatilsHead mb-3">
                                    Date of birth (month / day / year) </label>
                                <div className='w-100 personDate'>
                                    <DatePicker
                                        selected={dob}
                                        name={generateRandomName}
                                        className="birth customform-control mb-0"
                                        autoComplete='new-password'
                                        onChange={(date) => setDob(date)}
                                        dateFormat="MM/dd/yyyy"
                                        maxDate={moment().subtract(18, 'years').subtract(1, 'day')._d}
                                        placeholderText="MM/DD/YYYY"
                                    />
                                </div>
                            </div>
                            <div className="mb-4 form-group col-md-6">
                                <label className="deatilsHead mb-3">Ssn </label>
                                <input
                                    className="customform-control id_password"
                                    placeholder="ssn"
                                    name={generateRandomName}
                                    autoComplete='new-password'
                                    type="password"
                                    value={ssn} onChange={e => setSsn(e.target.value)} maxLength={9}
                                />
                            </div>
                            {/* <div className='mb-4 form-group col-md-6'>
                                <label className="deatilsHead mb-3">Type </label>
                                <div className='checkboxGroup'>
                                    <div className='checkboxInputs'>
                                        <input autoComplete='new-password' type="radio" id="individual" className='checkBox' name='individual' checked={selectedType === 'individual'}
                                            onChange={() => setSelectedType('individual')} />
                                        <label htmlFor="individual" className='checkBoxTxt'>Individual</label>
                                    </div>
                                    <div className='checkboxInputs'>
                                        <input autoComplete='new-password' type="radio" id="business" className='checkBox' name='individual' checked={selectedType === 'business'}
                                            onChange={() => setSelectedType('business')} />
                                        <label htmlFor="business" className='checkBoxTxt'>Business</label>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className='mb-4 form-group col-md-6'>
                                <label className="deatilsHead mb-3">Gender</label>
                                <div className='checkboxGroup'>
                                    <div className='checkboxInputs'>
                                        <input type="radio" autoComplete='new-password' id="male" className='checkBox' name='gender' checked={gender === 'male'}
                                            onChange={() => setGender('male')} />
                                        <label htmlFor="male" className='checkBoxTxt'>Male</label>
                                    </div>
                                    <div className='checkboxInputs'>
                                        <input type="radio" autoComplete='new-password' id="female" className='checkBox' name='gender' checked={gender === 'female'}
                                            onChange={() => setGender('female')} />
                                        <label htmlFor="female" className='checkBoxTxt'>Female</label>
                                    </div>
                                </div>
                            </div> */}
                            <div className='mb-4 form-group col-md-6'>
                                <label className="deatilsHead mb-3">Current Address</label>
                                <div className='inputGroup'>
                                    {
                                        hide ?
                                            <span
                                                className="customform-control countrytags_ w-100"
                                                 onClick={() => setHide(!hide)}
                                            >{displayValue}</span>
                                            :
                                            <PlacesAutocomplete
                                                value={address}
                                                onChange={autoCompleteHandleChange}
                                                onSelect={autoCompleteHandleSelect}
                                                searchOptions={{
                                                    componentRestrictions: {
                                                        country: ['us'],
                                                    }
                                                }}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div>
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: 'Street Address',
                                                                className: 'location-search-input customform-control countrytags_',
                                                            })}
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion, index) => {
                                                                const className = suggestion.active
                                                                    ? 'suggestion-item--active'
                                                                    : 'suggestion-item';
                                                                // inline style for demonstration purpose
                                                                const style = suggestion.active
                                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                        key={index}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                    }

                                </div>
                            </div>
                            <div className='mb-4 form-group col-md-6'>
                                <label className="deatilsHead mb-3">Apt.</label>
                                <input
                                    type="text"
                                    className="customform-control"
                                    name={generateRandomName}
                                    placeholder="Apt."
                                    autoComplete='new-password'
                                    value={apt} onChange={e => setApt(e.target.value)}
                                />
                            </div>
                            <div className='mb-4 form-group col-md-6'>
                                <label className="deatilsHead mb-3">Country</label>
                                <div className='inputGroup'>
                                    <input
                                        className="customform-control countrytags_ id_password"
                                        placeholder="Country"
                                        name={generateRandomName}
                                        type="text"
                                        autoComplete='new-password'
                                        value={countryValue} onChange={e => setCountryValue(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='mb-4 form-group col-md-6'>
                                <label className="deatilsHead mb-3">City</label>
                                <input
                                    className="customform-control id_password"
                                    placeholder="City"
                                    name={generateRandomName}
                                    type="text"
                                    autoComplete='new-password'
                                    value={cityValue} onChange={e => setCityValue(e.target.value)}
                                />
                            </div>
                            <div className='mb-4 form-group col-md-6'>
                                <label className="deatilsHead mb-3">Zip Code</label>
                                <input
                                    type="number"
                                    className="customform-control"
                                    name={generateRandomName}
                                    placeholder="Zip code"
                                    autoComplete='new-password'
                                    value={zipcode} onChange={e => setZipCode(e.target.value)}
                                />
                            </div>
                            <div className='mb-4 form-group col-md-6'>
                                <label className="deatilsHead mb-3">State</label>
                                <input
                                    className="customform-control id_password"
                                    placeholder="State"
                                    name={generateRandomName}
                                    type="text"
                                    autoComplete='new-password'
                                    value={stateValue} onChange={e => setStateValue(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <button className='staticBtn mt-5 col-md-4' type="submit" disabled={piLoading}>
                                {piLoading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Next</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Wallet