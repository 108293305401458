import React, { useState } from "react";
import { selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  createPolicyApiAsync,
  updatePolicyApiAsync,
} from "../systemConfigSlice";
import { CKEditor } from "ckeditor4-react";

const CreatePolicyModal = (props) => {
  const flag = props.flag;
  const [flagType, setFlagType] = useState(
    props?.selectedPolicies?.flag ? props?.selectedPolicies?.flag : ""
  );
  const toastId = React.useRef(null);
  const [userName, setUserName] = useState(
    props?.selectedPolicies?.title ? props?.selectedPolicies?.title : ""
  );
  const [description, setDescription] = useState(
    props?.selectedPolicies?.content ? props?.selectedPolicies?.content : ""
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);

  const handleBusinessChange = (e) => {
    setFlagType(e.target.value);
  };
  const handleProductDescription = (e) => {
    setDescription(e);
  };

  const submitHandle = (e) => {
    e.preventDefault();
    // if (!userName && flag !== "legal") {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please Enter Title");
    //   }
    //   return;
    // }
    if (!description) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Enter Content");
      }
      return;
    }
    if (!flagType && flag !== "legal") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select Type");
      }
      return;
    }

    let data = {
        postData: {
          title: flagType,
          content: description,
          flag: flagType,
        },
        tokenData: auth?.payload?.token,
      };



    if (!props?.selectedPolicies) {
      if(flag === "legal"){
      data = {...data,postData:{...data.postData,flag: "legal_policy"}}
      }
      console.log(data)
      setLoading(true);
      dispatch(createPolicyApiAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          props.getpoliciesDataHandle();
          props.close();
          setLoading(false);
        })
        .catch((onj) => {
          setLoading(false);
        });
    } else {
      data = {...data,postData:{...data.postData,id: props?.selectedPolicies?.id}}
      setLoading(true);
      dispatch(updatePolicyApiAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          props.getpoliciesDataHandle();
          props.close();
          setLoading(false);
        })
        .catch((onj) => {
          setLoading(false);
        });
    }
  };

  

  return (
    <>
      <div className="addEmployeeuser createRoles_">
        <form
          onSubmit={(e) => submitHandle(e)}
          className="emplyoeeDetails horizontalForms"
        >
          <div className="row">
            {/* <div
              className={`form-group col-md-6  mb-4`}
            >
              <label className="labelTxt mb-3" htmlFor="amount">
                Title
              </label>
              <input
                type="text"
                name="text"
                className="customform-control"
                placeholder="Please Enter Title"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div> */}

            <div
              className={`form-group col-md-12  mb-4 ${
                flag == "legal" ? "d-none" : ""
              }`}
            >
              <label className="labelTxt mb-3" htmlFor="amount">
                Select Type
              </label>
              <select
                autoComplete="new-password"
                className="customform-control select"
                defaultValue={flagType ? flagType : "Type"}
                onChange={(e) => handleBusinessChange(e)}
              >
                <option value="Type" disabled>
                  Type
                </option>
                <option value="privacy_policy">Privacy Policy</option>
                <option value="terms_and_conditons">Terms & Conditons</option>
                <option value="discount_coupon_policy">
                  Discount Coupon Policy
                </option>
                <option value="return_policy">Return Policy</option>
                <option value="shipping_policy">Shipping Policy</option>
                <option value="cancellation_policy">Cancellation Policy</option>
              </select>
            </div>
            <div className="form-group col-md-12 mb-4">
              <label className="labelTxt mb-3" htmlFor="amount">
                Content
              </label>
              <CKEditor
                initData={description}
                config={{
                  versionCheck: false,
                  removePlugins: [
                    "EasyImage",
                    "ImageUpload",
                    "MediaEmbed",
                    "Table",
                    "image",
                  ],
                }}
                name="editor"
                readOnly={false}
                onChange={(event, editor) => {
                  const data = event.editor.getData();
                  handleProductDescription(data);
                }}
                type="classic"
              />
            </div>
            <div className="footerSec mt-4 text-end">
              {/* <button className="submitBtn" type="submit" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                <span>
                  {props?.selectedPolicies && flag==="legal" ? "Update Legal" : flag==="legal" ? "Create Legal":  props?.selectedPolicies? "Create Policy": "Update Policy"}
                </span>
              </button> */}

              <button className='closebtnModal me-3' onClick={props.close}>Cancel</button>
            {/* <button className='saveBtn_ lh-sm me-3' type='button'>Save as Draft</button> */}
            <button className='publishBtn_ lh-sm' type="submit">
              <span>Publish</span></button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreatePolicyModal;
