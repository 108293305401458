import React, { useState, useEffect } from 'react'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import { incoiceuser_, returnlogo, serviceuser_, verifyorder, cancleorder, map, pickuporder, OrderDown, fillradio_, profileImg1, unselectedOrder } from '../../../../utilities/images';
import CustomModal from '../../../components/shared/CustomModal';
import InventoryreturnModal from './inventoryreturnModal';
import { getOrderDetailByIdAsync } from '../../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../auth/authSlice'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment-timezone'
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
} from '@react-google-maps/api'
import { getAddressFromCoordinates } from '../../auth/formCordinates';

const DeliveryStatus = () => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const [latitude, setLatitude] = useState()
    const [productIds, setProductIds] = useState([]);
    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const [longitude, setLongitude] = useState()
    const auth = useSelector(selectLoginAuth);
    const [driverLoc, setDriverLoc] = useState("")
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [driverDistance, setDriverDistance] = useState("")
    const [driverDuration, setDriverDuration] = useState("")
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState("")
    const center = { lat: latitude, lng: longitude }
    const locationData = orderData;

    const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleSelectCheckBox = (id) => {
        const exists = productIds?.includes(id)
        if (exists === false) {
            setProductIds([...productIds, id])
        } else {
            const newData = productIds?.filter((c) => { return c !== id })
            setProductIds(newData)
        }
    }

    const geocodeHandler = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            getAddressFromCoordinates(position.coords.latitude, position.coords.longitude)
                .then(data => {
                    setDriverLoc(data?.formatted_address);
                })
                .catch(err => console.log('error getting address', err));
        });
    }
    async function calculateDriverRoute() {
        if (driverLoc === '' || locationData?.address_2 === '') {
            return
        }
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
            origin: driverLoc,
            destination: locationData?.address_2,
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
        })
        setDirectionsResponse(results)
        setDriverDistance(results.routes[0].legs[0].distance.text)
        setDriverDuration(results.routes[0].legs[0].duration.text)
    }
    const getOrderDataById = () => {
        let data = {
            id: id,
            token: auth?.payload?.token,
        }
        setLoading(true)
        dispatch(getOrderDetailByIdAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setOrderData(obj?.payload)
                setLongitude(obj?.payload?.coordinates[1])
                setLatitude(obj?.payload?.coordinates[0])
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        getOrderDataById()
        geocodeHandler()
        calculateDriverRoute()
    }, [])

    return (
        <>
            <div className='deliveryStatus_ orderReview_'>
                <DashboardNavbar title={`Orders#${loading ? "" : (orderData?.invoices?.invoice_number ? orderData?.invoices?.invoice_number : orderData?.return_detail?.invoices?.invoice_number)}`} backShow={`/allOrder`} show={true} />
                {loading ?
                    <span className="spinner-border inner-spin spinner-border-sm"></span> :
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='chartsOuter bglightgrey_'>
                                <div className="scheduleModal">
                                    <div className="scheduleModalContent">
                                        <div className='invoiceUser_'>
                                            {
                                                orderData?.user_details ?
                                                    <div className='reviewuserinfo_'>
                                                        <img className='invoveuser me-3' style={{ width: "50px" }} src={orderData?.user_details?.profile_photo ? orderData?.user_details?.profile_photo : profileImg1} />
                                                        <article className='ivoiceuserDetail_'>
                                                            <h4 className='monshead14'>{orderData?.user_details?.firstname + " " + orderData?.user_details?.lastname}</h4>
                                                            <p className='monshead12'>{orderData?.user_details?.current_address?.street_address}</p>
                                                        </article>
                                                    </div> :
                                                    <div>Not Available</div>
                                            }
                                            {
                                                orderData?.delivery_option == 1 ?
                                                    <div className='reviewuserinfo_'>
                                                        <img className='invoveuser me-3' alt='' src={orderData?.driver_details?.profile_photo ? orderData?.driver_details?.profile_photo : profileImg1} />
                                                        <article className='ivoiceuserDetail_'>
                                                            <h4 className='monshead14 blueText_'>{orderData?.delivery_details?.title}</h4>
                                                            {/* <p className='monshead12'>Immediately</p> */}
                                                        </article>
                                                    </div> :
                                                    orderData?.delivery_option == 4 ?
                                                        <div className='reviewuserinfo_'>
                                                            <img className='invoveuser me-3' alt='' src={orderData?.shipping_details?.image ? orderData?.shipping_details?.image : profileImg1} />
                                                            <article className='ivoiceuserDetail_'>
                                                                <h4 className='monshead14 blueText_'>{orderData?.shipping_details?.title}</h4>
                                                                {/* <p className='monshead12'>Immediately</p> */}
                                                            </article>
                                                        </div>
                                                        : <></>
                                            }
                                        </div>
                                        {
                                            orderData?.order_details?.length > 0 ?
                                                orderData?.order_details?.map((val, i) => {
                                                    return (
                                                        <div key={i} className='serviceDeatils_'>
                                                            <div className='serviceinfo_'>
                                                                <img style={{ width: "50px" }} className='img-fluid me-3' src={val?.product_image} />
                                                                <article className='duration_'>
                                                                    <h3 className='lightgreyHeading_'>{val?.product_name}</h3>
                                                                    {/* <p className='designation_'>White / s</p> */}

                                                                </article>
                                                            </div>
                                                            <div className='servicechanges_'>
                                                                <span className="serciceprice_">
                                                                    ${val?.offer_price && val?.offer_price != 0 ? val?.offer_price / val?.qty : val?.actual_price}
                                                                </span>
                                                                <span className="serciceprice_">{val?.qty}</span>
                                                                <span className="serciceprice_">
                                                                    ${val?.offer_price && val?.offer_price != 0 ? val?.offer_price : val?.qty * val?.actual_price}
                                                                </span>
                                                                {
                                                                    orderData?.delivery_option != 2 ?
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={productIds?.includes(val?.product_id)}
                                                                            onChange={() => handleSelectCheckBox(val?.product_id)}
                                                                        /> : <></>}
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                orderData?.return_detail?.return_details?.map((val, i) => {
                                                    return (
                                                        <>
                                                            <div key={i} className='serviceDeatils_'>
                                                                <div className='serviceinfo_'>
                                                                    <img style={{ width: "50px" }} className='img-fluid me-3' src={val?.order_details?.product_image} />
                                                                    <article className='duration_'>
                                                                        <h3 className='lightgreyHeading_'>{val?.order_details?.product_name}</h3>
                                                                        {/* <p className='designation_'>White / s</p> */}

                                                                    </article>
                                                                </div>
                                                                <div className='servicechanges_'>
                                                                    <span className='serciceprice_'>${Number(val?.order_details?.price)?.toFixed(2)}</span>
                                                                    <span className='serciceprice_'>{val?.order_details?.qty}</span>
                                                                    <span className='serciceprice_'>${Number((val?.order_details?.qty) * (val?.order_details?.price))?.toFixed(2)}</span>
                                                                    {
                                                                        orderData?.delivery_option != 2 ?
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={productIds?.includes(val?.order_details?.product_id)}
                                                                                onChange={() => handleSelectCheckBox(val?.order_details?.product_id)}
                                                                            /> : <></>}
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })
                                        }
                                    </div>
                                    <div className='grandTotal_ row'>
                                        <div className="col-md-5 text-start">
                                            <div className='orderinvoive'>
                                                <h5 className='lightgreyHeading_'>Total Item</h5>
                                                <h4 className='monshead22'>{orderData?.total_items}</h4>
                                                <h5 className='lightgreyHeading_'>Order Date</h5>
                                                <h4 className='monshead22'>{moment(orderData?.created_at).format("MM/DD/YYYY")}</h4>
                                                <h5 className='lightgreyHeading_'>Invoice No.</h5>
                                                <h4 className='monshead22'>{orderData?.invoices?.invoice_number ? orderData?.invoices?.invoice_number : orderData?.return_detail?.invoices?.invoice_number}</h4>

                                            </div>
                                        </div>

                                        <div className="col-md-7 text-start">
                                            <div className='orderBill_'>
                                                <div className="Customertotal">
                                                    <div className="totalDetails">
                                                        <h5 className="lightgreyHeading_ fw-semibold">Sub Total</h5>
                                                        <h5 className="lightgreyHeading_ fw-semibold">${Number(orderData?.actual_amount)?.toFixed(2)}</h5>
                                                    </div>
                                                    <div className="totalDetails">
                                                        <h5 className="textInnerHead2">Discount </h5>
                                                        <h5 className="personalinfoHead">-${orderData?.discount ? Number(orderData?.discount)?.toFixed(2) : 0}</h5>
                                                    </div>
                                                    {/* <div className="totalDetails">
                                                        <h5 className="textInnerHead2"> Other Fee</h5>
                                                        <h5 className="personalinfoHead">${orderData?.delivery_charge}</h5>
                                                    </div> */}
                                                    {
                                                        orderData?.delivery_option == 1 || orderData?.delivery_option == 4 ?
                                                            <>
                                                                <div className="totalDetails">
                                                                    <h5 className="textInnerHead2"> {orderData?.delivery_option == 4 ? 'Shipping' : 'Delivery'} Charge</h5>
                                                                    <h5 className="personalinfoHead">${orderData?.delivery_option == 4 ? Number(orderData?.shipping_charge)?.toFixed(2) : Number(orderData?.delivery_charge)?.toFixed(2)}</h5>
                                                                </div>
                                                            </> : <></>}
                                                    <div className="totalDetails">
                                                        <h5 className="textInnerHead2"> Tips</h5>
                                                        <h5 className="personalinfoHead">${Number(orderData?.tips)?.toFixed(2)}</h5>
                                                    </div>
                                                    <div className="totalDetails">
                                                        <h5 className="textInnerHead2">Tax</h5>
                                                        <h5 className="personalinfoHead">${Number(orderData?.tax)?.toFixed(2)}</h5>
                                                    </div>
                                                    <hr className='dashedrow'></hr>
                                                    <div className="totalDetails">
                                                        <h5 className="itemsText ">Total</h5>
                                                        <h5 className="itemsText">${Number(orderData?.payable_amount)?.toFixed(2)}</h5>
                                                    </div>
                                                    <div className='reviewbotBtn_ mt-2'>
                                                        {
                                                            productIds?.length > 0 ?
                                                                <button class="acceptBtn_ me-2" type="submit" disabled="" onClick={() => {
                                                                    setModalDetail({ show: true, flag: "inventoryreturnModal" });
                                                                    setKey(Math.random());
                                                                }}><span>Next</span></button> :
                                                                <button class="acceptBtn_ me-2" type="submit" disabled><span>Recieved Return</span></button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            orderData?.delivery_option == 1 || orderData?.delivery_option == 4 ?
                                <div className='col-md-6'>
                                    <div className='consumerMap'>
                                        <div className='consumerMap'>
                                            <div className="detailTrackingright_">
                                                <div className="MapOrders">
                                                    <div className="MapOrders_inner">
                                                        <GoogleMap
                                                            center={center}
                                                            zoom={15}
                                                            mapContainerStyle={{ width: '778px', height: '803px' }}
                                                            options={{
                                                                zoomControl: false,
                                                                streetViewControl: false,
                                                                mapTypeControl: false,
                                                                fullscreenControl: false,
                                                            }}
                                                            onLoad={map => setMap(map)}
                                                        >
                                                            {/* {directionsResponse && (
                            <DirectionsRenderer directions={directionsResponse} />
                          )} */}
                                                        </GoogleMap>
                                                    </div>
                                                    {" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="deliveryStatus">
                                        <div className="statusHead stausDown">
                                            <div className="statusLeft">
                                                <h3>Order status</h3>
                                                {/* <h6>Delivered</h6> */}
                                            </div>
                                            <figure>

                                                <img src={OrderDown} alt="downSelect" />
                                            </figure>
                                        </div>
                                        <div className='orderStatus '>

                                            {/* <div className="readyPickupstatus disableTrack">
                                                <div className='pickupBtnDetails'>
                                                    <figure>
                                                        <img src={fillradio_} alt="pickup" />
                                                    </figure>
                                                    <article>
                                                        <h3>Return CODE</h3>
                                                    </article>
                                                </div>
                                                <div className='pickupBtn'>
                                                    <button className='countbtn'>659X</button>
                                                </div>
                                            </div> */}
                                            <div className="readyPickupstatus">
                                                <figure>
                                                    <img src={orderData?.status === 9 ? pickuporder : unselectedOrder} alt="pickup" />
                                                </figure>
                                                <article>
                                                    <h3>Return to Shop</h3>
                                                    <div className='reviewuserinfo_'>
                                                        <img style={{ width: "50px" }} className='invoveuser me-3' src={orderData?.seller_details?.banner_image ? orderData?.seller_details?.banner_image : returnlogo} />
                                                        <article className='ivoiceuserDetail_'>
                                                            <h4 className='monshead14'>{orderData?.seller_details?.organization_name}</h4>
                                                            <p className='monshead12'>{orderData?.seller_details?.current_address?.street_address}</p>
                                                        </article>
                                                    </div>
                                                    {/* <div className='returnShop_'>
                                        <img className='returnlogo_' src={returnlogo}/>
                                    </div> */}
                                                </article>
                                            </div>
                                            <div className="readyPickupstatus">
                                                <figure>
                                                    <img src={orderData?.status === 9 ? cancleorder : unselectedOrder} alt="pickup" />
                                                </figure>
                                                <article>
                                                    <h3>Canceled</h3>
                                                    <h6>{moment(orderData?.status_desc?.status_8_updated_at).format("DD MMM, YYYY | hh:mm a")}</h6>
                                                </article>
                                            </div>
                                            <div className="readyPickupstatus pickupBtnDetails">
                                                <div className='pickupBtnDetails'>
                                                    <figure>
                                                        <img src={orderData?.status >= 4 ? verifyorder : unselectedOrder} alt="verifyorder" />
                                                    </figure>
                                                    <article>
                                                        <h3>Product Pickup</h3>
                                                        <h6>{moment(orderData?.status_desc?.status_4_updated_at).format("DD MMM, YYYY | hh:mm a")}</h6>
                                                    </article>
                                                </div>
                                                {
                                                    orderData?.order_delivery?.seller_otp ?
                                                        <div className='pickupBtn'>
                                                            <button className='countbtn'>{orderData?.order_delivery?.seller_otp}</button>
                                                        </div> : <></>
                                                }
                                            </div>
                                            {
                                                orderData?.delivery_option == 1 ?
                                                    <div className="readyPickupstatus">
                                                        <figure>
                                                            <img src={orderData?.driver_details ? pickuporder : unselectedOrder} alt="pickup" />
                                                        </figure>
                                                        <article>
                                                            <h3>Assign Driver</h3>
                                                            {/* <h6>{moment(orderData?.status_desc?.status_1_updated_at).format("DD MMM, YYYY | hh:mm a")}</h6> */}
                                                        </article>
                                                    </div> : <></>
                                            }
                                            <div className="readyPickupstatus">
                                                <figure>
                                                    <img src={orderData?.status >= 3 ? pickuporder : unselectedOrder} alt="pickup" />
                                                </figure>
                                                <article>
                                                    <h3>Ready to pickup</h3>
                                                    <h6>{moment(orderData?.status_desc?.status_3_updated_at).format("DD MMM, YYYY | hh:mm a")}</h6>
                                                </article>
                                            </div>
                                            <div className="readyPickupstatus">
                                                <figure>
                                                    <img src={orderData?.status >= 1 ? pickuporder : unselectedOrder} alt="pickup" />
                                                </figure>
                                                <article>
                                                    <h3>Order accepted</h3>
                                                    <h6>{moment(orderData?.status_desc?.status_1_updated_at).format("DD MMM, YYYY | hh:mm a")}</h6>
                                                </article>
                                            </div>

                                            {/* <div className="carrierbox">
                          <h5>Carrier:</h5>
                          <div className="shippingContact carriercontact">
                            <div className="shipcontactLeft">
                              <img className='me-2' src={singleOrderData?.driverDetails?.profile_photo ? singleOrderData?.driverDetails?.profile_photo : profileImg1} />
                              <div className="contctInfo">
                                <h4>{singleOrderData?.driverDetails?.user_profiles?.firstname + " " + singleOrderData?.driverDetails?.user_profiles?.lastname}</h4>
                              </div>
                            </div>
                            <button className="contactBtn">
                              <i className="las la-phone"></i>
                              <h3>Contact<br />{singleOrderData?.driverDetails?.user_profiles?.full_phone_number}</h3>
                            </button>
                          </div>
                        </div> : */}

                                        </div>
                                    </div>
                                </div> : <></>
                        }

                    </div>}
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "inventoryreturnModal" ? "inventoryreturnModal" : ""
                }
                size={
                    "md"
                } child={
                    modalDetail.flag === 'inventoryreturnModal' ? <InventoryreturnModal productIds={productIds} orderData={orderData} flag='withdraw' close={() => handleOnCloseModal()} />
                        : ""
                }
                header={
                    <>
                        {modalDetail.flag === 'inventoryreturnModal'
                            ?
                            <>
                                <div className="modalHeader_">
                                    <div className="common_">
                                        <h3 className='modalHeading_'>Return to Inventory</h3>

                                        <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                            <i className="las la-times" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </> : <></>
                        }
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default DeliveryStatus