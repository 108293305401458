import { unwrapResult } from '@reduxjs/toolkit';
import Multiselect from 'multiselect-react-dropdown'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { getAttributesApiAsync, getAttributesByIdApiAsync } from '../dashboard/dashboardSlice';
import { toast } from 'react-toastify';


const AddOptionsModal = (props) => {
    const toastId = React.useRef(null)
    const [activeTab, setActiveTab] = useState(props?.combinations?.length > 0 ? true : false);
    const [showData, setShowData] = useState(props?.combinations?.length > 0 ? true : false);
    const [attributesList, setAttributesList] = useState([]);
    const [attributeById, setAttributesById] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [selectedAttributesValue, setSelectedAttributesValue] = useState(props?.selectedAttributesValue?.length > 0 ? props?.selectedAttributesValue : []);
    const [combinations, setCombinations] = useState(props?.combinations?.length > 0 ? props?.combinations : []);
    const [options, setOptions] = useState([]);
    // console.log("attributes", attributes, options);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    // const handleSearch = (value) => {
    //     console.log(value,"value")
    //     // setLoading(true);
    //     const results = value ? attributes?.filter(w => w.name.toLowerCase().includes(value)) : []
    //     console.log("result", results)
    //     // setTimeout(r => { setOptions(results); setLoading(false) }, 400, results)
    // }

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...combinations];
        list[index][name] = value;
        setCombinations(list);

    };

    const handleSelect = (item) => {
        setSelectedAttributes(item)
        getAttributesById(item[0]?.id, item[0]?.name);
        setDropdownOpen(false)
    }

    const handleSelected = (item) => {

        let old_obj = selectedAttributes[0]
        old_obj.attribute_values = item

        let found;
        for (let i = 0; i < selectedAttributesValue.length; i++) {
            if (selectedAttributesValue[i]['name'] === old_obj.name) {
                let result = selectedAttributesValue
                result[i] = old_obj;
                setSelectedAttributesValue(result)
                found = true;
                break;
            }
        }
        if (!found) {
            let result = [...selectedAttributesValue, old_obj]
            setSelectedAttributesValue(result)
        }
    }


    const handleAddClick = () => {
        if (selectedAttributes?.length === 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select attributes");
            }
            return false
        }
        if (selectedAttributesValue?.length === 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select attributes values");
            }
            return false
        }
        setActiveTab(false);
        setShowData(true);
        generateCombinations(selectedAttributesValue)
    }

    function generateCombinations(objectsArray) {
        const combinations1 = [];

        function helper(currentCombination, index) {
            if (index === objectsArray.length) {
                combinations1.push(currentCombination);
                return;
            }
            const currentObject = objectsArray[index];
            const attributes = currentObject.attribute_values || [];

            for (let i = 0; i < attributes.length; i++) {
                const attribute = attributes[i];
                const attributeName = attribute.name;

                const newCombination = { ...currentCombination, [attributeName]: { ...attribute, "attribute_name": currentObject.name }, price: "", upc: "", weight: "", image: "", stock:"" };
                helper(newCombination, index + 1);
            }
        }
        helper({}, 0);
        setCombinations([...combinations, ...combinations1]);
        return combinations1;
    }

    const handleRemove = (index, item) => {
        const list = [...combinations];
        list.splice(index, 1);
        setCombinations(list);
    }


    const handleMultipalDelet = () => {
        const updatedData = combinations.filter((_, index) => !selectedItems.includes(index));
        setCombinations(updatedData);
        setSelectedItems([]);
    }


    const handleCheckBox = (index) => {
        if (selectedItems.includes(index)) {
            setSelectedItems(selectedItems.filter((itemIndex) => itemIndex !== index));
        } else {
            setSelectedItems([...selectedItems, index]);
        }
    }

    const handleApply = (item) => {
        const hasElementWithShortUpc = item?.some(item => item.upc.length < 11);
        const hasElementWithLongUpc = item?.some(item => item.upc.length > 14);
        if (hasElementWithShortUpc || hasElementWithLongUpc) {
            toast.error("Please enter valid upc")
            return false
        }
        const attributes = item.map(obj => {
            const attribute_values = [];
            Object.keys(obj).forEach(key => {
                const value = obj[key];

                if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    attribute_values.push({
                        attribute_id: value.attribute_id.toString(),
                        attribute_name: value.attribute_name,
                        attribute_value_id: value.id.toString(),
                        attribute_value_name: value.name
                    });
                }
            });
            return {
                attribute_values,
                price: obj.price,
                stock: obj.stock,
                upc: obj.upc,
                weight: obj?.weight,
                // barcode: obj.barcode,
                image: obj.image
            };
        });
        props.options(attributes, combinations, selectedAttributesValue);
        props.close();
    }

    const getAttributes = () => {
        let data = {
            postData: {
                service_id: props?.id,
                created_by_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            tokenData: auth?.payload?.token,
        }
        setLoading(true)
        dispatch(getAttributesApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                // console.log("objobj", obj)
                setAttributesList(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getAttributesById = (id, name) => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                attribute_id: id,
                created_by_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            }
        }
        setLoading(true)
        dispatch(getAttributesByIdApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                // console.log("objbyid", obj)
                const selectedData = obj?.payload?.data?.find((item => item.name == name));
                setAttributesById(selectedData.attribute_values);
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getAttributes()
    }, []);

    return ( 
        <div className='addOptionsModal addVarientModal'>
            <div className='row align-items-center'>
                <div className='col-md-6'>
                    <div className='btn-group variantBtnsGroup' role="group">
                        {showData ? <>
                            {selectedAttributesValue.length > 0 ? <>
                                {selectedAttributesValue?.map((item, index) => {
                                    return (
                                        <button className='variantBtns' key={index}>
                                            <span>{item.name}</span>
                                        </button>
                                    )
                                })}

                            </> : ""}
                        </> : ""}
                        {/* <button className='variantBtns border-0'>
                        <span>Color</span>
                    </button>  */}
                        <button className='addOptionsBtn' onClick={() => setActiveTab(true)} >
                            <i className="las la-plus"></i>
                        </button>
                    </div>
                    <button className='addOptionsBtn d-none'>
                        <i className="las la-plus"></i>
                    </button>
                    <p className='descriptionPara_ mt-2'>Click on + sign and add your variants</p>
                </div>
                <div className='col-md-6 text-end'>
                <button className='acceptBtn_ me-2' type="button" onClick={() => props?.handleModalChange("createVariant")}>Create</button>
                    {/* <button className='varientImgBtn me-2'>Edit Location</button> */}
                    {/*below button old classname plansBtns me-0 */}
                    {
                     selectedItems?.length > 0 &&
                     <button className={selectedItems?.length > 0 ? "varientImgBtn" : "varientImgBtn"} onClick={() => { handleMultipalDelet() }}>Delete</button>
                    }
                   
                </div>
            </div>
            {console.log(activeTab,"dddddddddddddddd")}
            {activeTab == true ?
                <div className='optionContainers mt-1'>
                    <div className='row align-items-center'>
                        <div className='col-md-5'>
                            <div className='optionSelectArea'>
                                <label htmlFor='optionName' className='subtextDark_'>Options </label>
                                <div className='multiselectArea'>
                                    <Multiselect
                                        className='brandFilter'
                                        displayValue="name"
                                        options={attributesList}
                                        // onSearch={handleSearch}
                                        closeOnSelect={true}
                                        loading={loading}
                                        // isObject={true}
                                        // placeholder='Search here'
                                        open={isDropdownOpen}
                                        hidePlaceholder={true}
                                        selectionLimit={1}
                                        onSelect={handleSelect}
                                        onRemove={handleSelect}
                                        onDropdownOpen={() => setDropdownOpen(true)}
                                        onDropdownClose={() => setDropdownOpen(false)}
                                        // optionValueDecorator={(e) => handleOptionValue(e)}
                                        // selectedValueDecorator={(e) => handleOptionValue(e)}
                                        // avoidHighlightFirstOption={true}
                                        showCheckbox
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='optionSelectArea'>
                                <label htmlFor='optionName' className='subtextDark_'>Options</label>
                                <div className='multiselectArea'>
                                    <Multiselect
                                        className='brandFilter'
                                        displayValue="name"
                                        options={attributeById}
                                        // onSearch={handleSearch}
                                        loading={loading}
                                        // isObject={true}
                                        // placeholder='Search here'
                                        hidePlaceholder={true}
                                        // selectionLimit={1}
                                        onSelect={handleSelected}
                                        onRemove={handleSelected}
                                        // optionValueDecorator={(e) => handleOptionValue(e)}
                                        // selectedValueDecorator={(e) => handleOptionValue(e)}
                                        // avoidHighlightFirstOption={true}
                                        showCheckbox
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 text-end'>
                            <button className='addBtn' onClick={() => handleAddClick(false)} >Add</button>
                            <span className="crossIcon text-white" onClick={() => { setActiveTab(false); setSelectedAttributes([]); setSelectedAttributesValue([]) }}><i className="las la-times" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>
                : ""}
            {/* Start CustomTable */}
            {showData ? <>
                {combinations?.length > 0 && combinations != undefined ? <>
                    <div className='table-responsive'>
                        <table className='customTable table'>
                            <thead className='border-0'>
                                <tr>
                                    <th className='customTableHead'>
                                        {/* <div className='rolecheckbox_'>
                                            <input className="me-2" type="checkbox" id="view1" value="" />
                                            <label className='contentheadtext_ mb-3' for="view1"></label>
                                        </div> */}
                                    </th>
                                    {/* <th className='customTableHead'>Min Qty</th>
                                    <th className='customTableHead'>Max Qty</th> */}
                                    <th className='customTableHead'>Price</th>
                                    <th className='customTableHead'>Stock</th>
                                    <th className='customTableHead'>UPC</th>
                                    <th className='customTableHead'>Weight </th>
                                </tr>
                            </thead>

                            <tbody className='customBody'>
                                {combinations?.map((item, index) => {
                                    //  console.log("item", item, "key", Object.keys(item))
                                    return (

                                        <tr key={index} className='customRows'>
                                            <td className='d-flex customTableData justify-content-between'>
                                                <div className='rolecheckbox_'>

                                                    <input className="me-2" type="checkbox"
                                                        checked={selectedItems.includes(index)}
                                                        onChange={() => handleCheckBox(index)}
                                                    />

                                                    <label className='contentheadtext_ mb-3' htmlFor="check1"></label>
                                                </div>

                                                {Object.keys(item).map((val, i) => (
                                                    <span key={i} className='subtextDark_ '>{(val == "attribute_name" || val == "name" || val == "price" || val == "upc" || val == "weight" || val == "stock"  || val == "image") ? <></> : val}

                                                    </span>
                                                ))}
                                                {/* <span className='subtextDark_ '>{Object.keys(item[1])}</span> */}
                                            </td>
                                            <td className='customTableData'>
                                                <div className='borderContainer'>
                                                    <input
                                                        className="storeHeading"
                                                        placeholder="Enter price"
                                                        aria-describedby="basic-addon2"
                                                        name="price"
                                                        type="number"
                                                        value={item.price}
                                                        onChange={e => handleInputChange(e, index)}
                                                    />
                                                    {/* <span className='storeHeading'>$15.00</span> */}
                                                </div>
                                            </td>
                                            <td className='customTableData'>
                                                <div className='borderContainer'>
                                                    <input
                                                        className="storeHeading"
                                                        placeholder="Enter stock"
                                                        aria-describedby="basic-addon2"
                                                        name="stock"
                                                        type="number"
                                                        value={item.stock}
                                                        onChange={e => handleInputChange(e, index)}
                                                    />
                                                   {/* <span className='storeHeading'>50</span>  */}
                                                </div>
                                            </td>
                                            <td className='customTableData'>
                                                <div className='borderContainer'>
                                                    <input
                                                        className="subtext_"
                                                        placeholder="Enter UPC"
                                                        aria-describedby="basic-addon2"
                                                        name="upc"
                                                        type="text"
                                                        value={item.upc}
                                                        onChange={e => handleInputChange(e, index)}
                                                    />
                                                    {/* <span className='subtext_'>FL402</span> */}
                                                </div>
                                            </td>
                                            <td className='customTableData'>
                                                <div className='borderContainer'>
                                                    <input
                                                        className="subtext_"
                                                        placeholder="weight"
                                                        aria-describedby="basic-addon2"
                                                        name="weight"
                                                        type="text"
                                                        value={item.weight}
                                                        onChange={e => handleInputChange(e, index)}
                                                    />
                                                    {/* <span className='subtext_'>2598133666</span> */}
                                                </div>
                                            </td>
                                            <td>
                                                <span className="crossIcon" onClick={() => handleRemove(index, item)}><i className="las la-times" aria-hidden="true"></i></span>
                                            </td>
                                        </tr>

                                    )
                                })

                                }

                            </tbody>

                        </table>
                    </div>
                </> : ""}
            </> : ""}

            {/* End CustomTable */}
            <div className='row mt-4'>
                <div className='col-md-3'>
                    <button className="discardBtn w-100 pt-3 pb-3" onClick={() => props.close()}>Discard</button>
                </div>
                <div className='col-md-3'>
                    <button className="saveBtn me-3 w-100 pt-3 pb-3" onClick={() => handleApply(combinations)}>Apply</button>
                </div>
            </div>
        </div>
    )
}

export default AddOptionsModal