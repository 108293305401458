import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  distributor,
  manufacture,
  retailer,
  retailerActive,
  shops,
  manufacture1,
  distributer1,
  oneStep,
} from "../../../../utilities/images";
import { getServicesApiAsync } from "../authSlice";
import Multiselect from "multiselect-react-dropdown";

const ChooseRoles = () => {
  const history = useHistory();
  const [organisationName, setOrganisationName] = useState("");
  const toastId = React.useRef(null);
  const [businessType, setBusinesType] = useState("");
  const [registerBusiness, setRegisterBusiness] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [operatingIndustry, setOperatingIndustry] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [operation, setOperations] = useState("operations");

  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const optionsOne = [
    { id: 1, value: "product", label: "Option 1" },
    { id: 2, value: "service", label: "Option 2" },
  ];
  const optionsTwo = [{ id: 1, value: "product", label: "Option 1" }];
  const handleSelect = (selectedList) => {
    let businessType = selectedList?.map((v) => v?.value);
    setSelectedOptions(businessType);
    if (businessType?.length > 0) {
      let data = {
        type: businessType?.toString(),
      };
      dispatch(getServicesApiAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setServiceList(obj?.payload?.data);
        })
        .catch((obj) => {});
    }
  };
  const handleBusinessChange = (e) => {
    setBusinesType(e.target.value);
    let filteredData = serviceList?.filter(
      (val) => val.type === e.target.value
    );
    setFilteredList(filteredData);
  };
  const handleOperatingIndustryChange = (e) => {
    let operatingIndustryIds = e?.map((v) => v?.id);
    setOperatingIndustry(operatingIndustryIds);
  };
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!activeTab) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select role in market");
      }
      return;
    }
    if (!organisationName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter organisation name");
      }
      return;
    }
    if (selectedOptions?.length === 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select business type");
      }
      return;
    }
    if (operatingIndustry?.length === 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select business industry");
      }
      return;
    }
    if (operation == "operations") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select operation");
      }
      return;
    }
    let data = {
      seller_type: activeTab,
      organization_name: organisationName,
      is_business_registered: registerBusiness,
      business_type: selectedOptions,
      service_ids: operatingIndustry?.toString(),
      operation: operation,
    };
    history.push({ pathname: "sign-up", state: data });
  };

  // useEffect(() => {
  //     let data = {
  //         type: "product"
  //     }
  //     dispatch(getServicesApiAsync(data))
  //         .then(unwrapResult)
  //         .then((obj) => {
  //             setServiceList(obj?.payload?.data)
  //         }
  //         )
  //         .catch((obj) => {
  //         })
  // }, [])
  return (
    <div className="chooseRoles">
      <div className="commonLogin">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-6 p-0 text-center">
              <div className="leftSect">
                <div className="login_left">
                  <img
                    src={shops}
                    className="d-none d-md-block img-fluid mx-auto mb-3"
                    alt="img"
                  />
                  <span className="heading_">
                    <b>
                      Create Your <br />
                      Organization
                    </b>
                  </span>
                </div>
                <div className="aboutBusiness">
                  <img src={oneStep} className="img-fluid mb-4" />
                  <h4 className="mb-2 innerSubtext">
                    Tell us about your Business
                  </h4>
                  <span className="">
                    Please let us know what you need help with and We will{" "}
                    <br />
                    do our best to assist you.
                  </span>
                  {/* <p className="mt-4 gobacklink justify-content-center d-flex align-items-center"><i className="fa-solid fa-arrow-left-long"></i> Go back to <Link to="#" onClick={() => { history.push("/") }}> Login </Link> </p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="loginhorrizontal-form ">
                <div className="row mb-3">
                  <h4 className="subheading_ col-md-12 mb-2">
                    Type of Business
                  </h4>
                  <div className="col-lg-4 p-13">
                    <div
                      className={`rolesContainer ${
                        activeTab == "manufacturer" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("manufacturer")}
                    >
                      <img
                        src={manufacture}
                        className="rolesImg mb-2"
                        alt="manufacture"
                      />
                      <img
                        src={manufacture1}
                        className="rolesImgHoverImg rolesHoverImg mb-2"
                        alt="RetailerActive"
                      />
                      <h5 className="mainlightHead mt-2">Manufacturer</h5>
                    </div>
                  </div>
                  <div className="col-lg-4 p-13">
                    <div
                      className={`rolesContainer ${
                        activeTab == "whole_seller" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("whole_seller")}
                    >
                      <img
                        src={distributor}
                        className="rolesImg mb-2"
                        alt="Distributor"
                      />
                      <img
                        src={distributer1}
                        className="rolesImgHoverImg rolesHoverImg mb-2"
                        alt="RetailerActive"
                      />
                      <h5 className="mainlightHead mt-2">Distributor</h5>
                    </div>
                  </div>
                  <div className="col-lg-4 p-13">
                    <div
                      className={`rolesContainer ${
                        activeTab == "retailer" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("retailer")}
                    >
                      <img
                        src={retailer}
                        className="rolesImg mb-2"
                        alt="Retailer"
                      />
                      <img
                        src={retailerActive}
                        className="rolesImgHoverImg rolesHoverImg mb-2"
                        alt="RetailerActive"
                      />
                      <h5 className="mainlightHead mt-2">Retailer</h5>
                    </div>
                  </div>
                </div>
                <form
                  autoComplete="nope"
                  className="form_horizontal row"
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-3">Business Name</label>
                    <input
                      type="text"
                      className="customform-control"
                      name="text"
                      autoComplete="new-password"
                      placeholder="Name of your Organization"
                      value={organisationName}
                      onChange={(e) => setOrganisationName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-3">Business Type </label>
                    <Multiselect
                      className="signupSelect customform-control"
                      displayValue="value"
                      options={
                        activeTab == "manufacturer" ||
                        activeTab == "whole_seller"
                          ? optionsTwo
                          : optionsOne
                      }
                      hidePlaceholder={false}
                      placeholder="Business Type"
                      onSelect={handleSelect}
                      onRemove={handleSelect}
                      disable={!activeTab}
                      showCheckbox
                    />
                  </div>
                  <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-3">
                      Business Industry
                    </label>
                    <Multiselect
                      className="signupSelect customform-control"
                      displayValue="name"
                      options={serviceList}
                      hidePlaceholder={true}
                      placeholder="Business Industry"
                      onSelect={handleOperatingIndustryChange}
                      onRemove={handleOperatingIndustryChange}
                      showCheckbox
                    />
                  </div>

                  <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-3">Operations</label>
                    <select
                      autoComplete="new-password"
                      className="customform-control select"
                      value={operation}
                      onChange={(e) => setOperations(e.target.value)}
                    >
                      <option value="operations" disabled>
                        Operations
                      </option>
                      <option value="0">Physical</option>
                      <option value="1">Online</option>
                      <option value="2">Both</option>
                    </select>
                  </div>

                  {/* <div className='mb-4 form-group col-md-12'>
                                        <input type="checkbox" id="register_business" name="register_business" value="register_business" checked={registerBusiness}
                                            onChange={() => setRegisterBusiness(!registerBusiness)} />
                                        <label htmlFor="register" className='ms-2 mainlightPhragraph'>This is registered business.</label><br />
                                    </div> */}
                  <div className="col-md-12 mt-5">
                    <button
                      className={`loginBtnSign w-100 ${
                        activeTab &&
                        organisationName &&
                        selectedOptions?.length > 0 &&
                        operatingIndustry &&
                        operation != "operations"
                          ? "buttonActive"
                          : ""
                      }`}
                      type="submit"
                      disabled={
                        !(
                          activeTab &&
                          organisationName &&
                          selectedOptions?.length > 0 &&
                          operatingIndustry &&
                          operation != "operations"
                        )
                      }
                    >
                      <span>Next</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseRoles;
