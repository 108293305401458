import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, shield, user } from '../../../../utilities/images';
const ResolveCall = () => {

 
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    
    const columns1 = [
        {
            name: "Name",
            selector: row => {
            return (
                  <>
                    <div className='d-flex align-items-center'>
                      <img className='userInnerImg' src={user} />
                      <div className='ms-2'>
                        <span className='contentSubtext_ '>{row.name}</span>
                        <p className='mb-0'>
                          <img src={shield} className='img-fluid me-2'/>
                          <span className='textInner'>260 101 480 0083 </span>
                        </p>
                      </div>
                    </div>
                  </>
                );
            },
            width: "300px", 
          },
        { name: 'Date',
        selector: row => {
          return (
                <>
                  <div className='ms-2'>
                      <span className='contentSubtext_'>{row.date}</span>
                      <p className='textInner mt-2'>9:00:23a</p>
                  </div>
                </>
              );
          },
          width: "150px",
        },
       
        {
          selector: row => row.value,
          name: 'Ticket id'
        },
        {
            selector: row => row.amount2,
            name: 'Subject'
          },
        
        {
          selector: row => row.usertype,
          name: 'User Type'
        },
        {
            selector: row => row.responsible,
            name: 'Responsible'
          },
          {
            selector: row => row.ringtime,
            name: 'Ringing Time'
          },
          {
            selector: row => row.ringtime,
            name: 'Talked Time'
          },
        {
          name: 'Status',
          selector: row => {
            return (
                  <>
                    <div className='ms-2'>
                      <span className='personalinfoHead mt-21234'>Resoved | agent name</span>
                      <p className='tablesubHead mt-2'>Sep 22, 2022 | 9:30 am</p>
                  </div>
                  </>
                );
            },
            width: "170px"
        },
        {
          name: '',
          selector: row => {
            return (
                  <>
                  <i className="fal fa-chevron-right " onClick={() => { history.push("/resolvecallDetail") }}></i> 
                  </>
                );
            },
            width: "50px",
        },
      ];
    
  
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='suportTicket'>
      <div className='table-responsive'>
        <DataTable
            pagination
            columns={columns1}
            data={products}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
          />
       </div>
    </div>
  )
}

export default ResolveCall