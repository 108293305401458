import React, { useState } from 'react'
import DeliveryRefund from './deliveryRefund/deliveryRefund'
import ShippingRefund from './shippingRefund/shippingRefund'

const RefundOrders = () => {

  const [activeTab, setActiveTab] = useState("DeliveryRefund");
  const [monthlyPlan, setMonthlyPlan] = useState([])
  const [yearlyPlan, setYearlyPlan] = useState([])


  return (
    <div className='refundOrders'>
      <div className='topHeader_'>
        <div className="topStickyHeader_">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="mainHeading">Refunded Orders</h2>
            </div>
          </div>
        </div>
      </div>
      <div className='btn-group  w-100 mb-4'>
        <button className={`refundBtn ${activeTab === 'DeliveryRefund' ? 'active' : ""}`}
          onClick={() => setActiveTab('DeliveryRefund')}
        >Delivery Returned</button>
        <button className={`refundBtn ${activeTab === 'ShippingRefund' ? 'active' : ""}`}
          onClick={() => setActiveTab('ShippingRefund')}>Shipping Returned</button>
      </div>
      <div className='plansAreasContainer table-responsive'>
        {activeTab == 'DeliveryRefund'
          ?
          <DeliveryRefund data={monthlyPlan} />
          : activeTab == "ShippingRefund" ?
            <ShippingRefund data={yearlyPlan} />
            : ""
        }
      </div>
    </div>
  )
}

export default RefundOrders