import React, { useEffect, useState } from "react";

const ReturnInventory = ({ selectedProducts, setNewQty }) => {
  const [selectedProductItems, setSelectedProductItems] =
    useState(selectedProducts);

  useEffect(() => {
    handleQuantity();
  }, []);

  function handleQuantity(action, index) {
    const selectedProduct = selectedProducts[index];
    const selectedProductQty =
      selectedProduct?.qty - selectedProduct?.returned_qty;

    const updateProductQuantity = selectedProductItems.map((item) => {
      const productQty = item?.qty - item?.returned_qty;

      // Check if the current item is the one being updated
      if (item?.product_id === selectedProduct?.product_id) {
        // Handle increase action
        if (action === "increase" && selectedProductQty > productQty) {
          return { ...item, qty: item.qty + 1 };
        }
        // Handle decrease action
        else if (action === "decrease" && productQty >= 1) {
          return { ...item, qty: item.qty - 1 };
        }
      }
      // Return the item unchanged if none of the above conditions match
      return item;
    });

    // Update the state outside the conditional logic to avoid repetition
    setSelectedProductItems(updateProductQuantity);
    setNewQty(updateProductQuantity);
  }

  return (
    <>
      <div className="productBoxDetails my-3">
        {selectedProductItems?.map((data, index) => {
          return (
            <div
              key={index}
              className="productcartBox mb-2 justify-content-between"
            >
              <div className="d-flex headdiscript_">
                <p className="priceHeading">
                  {selectedProducts[index]?.qty -
                    selectedProducts[index]?.returned_qty}
                  ×
                </p>
                <article className="returnProduct">
                  <h6 className="priceHeading"> {data?.product_name}</h6>
                </article>
              </div>
              <div className="form-group flexBox addCart ">
                <button
                  className="removeProductBtn"
                  onClick={() => handleQuantity("decrease", index)}
                >
                  <i className="fa-solid fa-minus plusMinus"></i>
                </button>
                <input
                  className="form-control customTextarea"
                  type="text"
                  placeholder=""
                  value={data?.qty - data?.returned_qty}
                  readOnly
                />
                <button
                  className="addProductBtn"
                  onClick={() => handleQuantity("increase", index)}
                >
                  <i className="fa-solid fa-plus plusMinus"></i>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ReturnInventory;
