import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  downlight,
  lossArrow,
  redMapimg,
  shareMap,
} from "../../../utilities/images";
import ChartArea from "../dashboard/wallet/ChartArea";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getPosCountAsync,
  getPosGraphApiAsync,
  getSalesPerPosApiAsync,
} from "../../containers/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../containers/auth/authSlice";
import { Bar } from "react-chartjs-2";
import DashboardNavbar from "../dashboard/DashboardNavbar";

const MyPos = () => {
  const history = useHistory();
  const [filterSalesValue, setFilterSalesValue] = useState("week");
  const [filterPosValue, setFilterPosValue] = useState("This week");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [countData, setCountData] = useState([]);
  const [salesPerPosData, setSalesPerPosData] = useState([]);
  const [graphData, setGraphData] = useState("");

  const handleFilterSalesValue = (value) => {
    setFilterSalesValue(value);
  };

  const handleFilterPosValue = (value) => {
    setFilterPosValue(value);
  };

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const lineData = {
    labels: graphData?.labels,
    datasets: [
      {
        label: "sales",
        data: graphData?.datasets ? graphData?.datasets[0]?.data : "",
        borderColor: "#1FB3FF",
        backgroundColor: "#1FB3FF",
      },
    ],
  };

  const data = {
    labels: salesPerPosData?.map(
      (v) => v?.pos_user_details?.user_profiles?.firstname
    ),
    // labels: ['pos1', 'pos2', 'pos3', 'pos4', 'pos5'],
    datasets: [
      {
        label: "Sales",
        data: salesPerPosData?.map((v) => v?.pos_user_sales_count),
        // data: [50, 45, 25, 48, 36],
        borderColor: "#275AFF",
        backgroundColor: "#275AFF",
      },
      {
        label: "Returned",
        data: salesPerPosData?.map((v) => v?.pos_user_returned_count),
        // data: [8, 5, 9, 8, 7],
        borderColor: "#FB466C",
        backgroundColor: "#FB466C",
      },
    ],
  };

  const differences = data.labels.map((label, index) => {
    const dataset1Value = data.datasets[0].data[index];
    const dataset2Value = data.datasets[1].data[index];
    return dataset1Value - dataset2Value;
  });
  // console.log("differences", differences)
  const options = {
    indexAxis: "y",
    scales: {
      x: {
        display: false,
        beginAtZero: true,
        stacked: true,
      },
      y: {
        stacked: true,
      },
      y2: {
        //  type: 'linear', // Specify the type of the y2 axis (linear for numeric values)
        position: "right", // Position the y2 axis on the right side
        ticks: {
          // callback: (value) => value.toString(),
          callback: (value, index, values) => {
            // Display the 'Difference' values instead of default labels
            // return '$' + differences[index].toString();
            return differences[index].toString();
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    borderRadius: 30,
  };

  const getPosOrderCount = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        filter_by:
          filterSalesValue === "This week"
            ? "week"
            : filterSalesValue?.toLowerCase(),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPosCountAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setCountData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getPosGraphData = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        filter_by:
          filterSalesValue === "This week"
            ? "week"
            : filterSalesValue?.toLowerCase(),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPosGraphApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setGraphData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const salesPerPosHandle = () => {
    let params = {
      postData: {
        // id: auth?.payload?.id ? auth?.payload?.id : auth?.payload?.user?.id,
        filter:
          filterSalesValue === "This week"
            ? "week"
            : filterSalesValue?.toLowerCase(),
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getSalesPerPosApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setSalesPerPosData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPosOrderCount();
    getPosGraphData();
    salesPerPosHandle();
  }, [filterSalesValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "My-Pos";
  }, []);

  return (
    <div className="myOrdersmain myPos">
      {loading ? (
        <span className="spinner-border inner-spin spinner-border-sm"></span>
      ) : (
        <>
          {" "}
          <DashboardNavbar title="Pos User" show={true} />
          <div className="row mb-3">
            <div className="col-md-12 text-end">
              <div className="dropdown weekDropdown">
                <button
                  className="dropdown-toggle cateSelectd"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {filterSalesValue} <img src={downlight} alt="img" />
                </button>
                <ul className="dropdown-menu weekDropdownMenus">
                  <li>
                    <a
                      href="#"
                      className="dropdown-item"
                      onClick={() => handleFilterSalesValue("today")}
                    >
                      Today
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="dropdown-item"
                      onClick={() => handleFilterSalesValue("week")}
                    >
                      This week
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="dropdown-item"
                      onClick={() => handleFilterSalesValue("month")}
                    >
                      {" "}
                      Month
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="dropdown-item"
                      onClick={() => handleFilterSalesValue("year")}
                    >
                      {" "}
                      Year
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="verificationTotal">
            <div className="row">
              {countData?.map((val, index) => {
                return (
                  <div
                    onClick={() =>
                      history.push({
                        pathname:
                          val?.name === "Total Sales"
                            ? "/posSales"
                            : "/transections",
                        state:
                          val?.name === "Total Sales"
                            ? {
                                isWalkIn: true,
                                filter: filterSalesValue,
                                isNeedAllPickup: true,
                                isNeedReturnFalse: true,
                              }
                            : val?.name === "Order Delivered"
                            ? {
                                status: "5",
                                isWalkIn: true,
                                filter: filterSalesValue,
                                isNeedAllPickup: false,
                                isNeedReturnFalse: true,
                              }
                            : val?.name === "Order Picked Up"
                            ? {
                                status: "6",
                                filter: filterSalesValue,
                                appName: "b2c",
                                isNeedReturnFalse: true,
                              }
                            : val?.name === "Delivery Returned"
                            ? { status: "9", filter: filterSalesValue }
                            : "",
                      })
                    }
                    className="col-sm-6 col-md-3 col-lg-3 customSpce"
                    key={index}
                  >
                    <div className="percentageContainer justify-content-between align-items-center">
                      <div className="percentageValues">
                        <h3 className="mainHeadingsub_ ">{val?.value}</h3>
                        <p className="textinner_">{val?.name}</p>
                      </div>
                      <div
                        className={`percentageRating ${
                          val?.analytics >= 0 ? "" : "percentageBg"
                        }`}
                      >
                        <img
                          style={{ width: "30px" }}
                          src={val?.analytics >= 0 ? shareMap : lossArrow}
                          className=""
                          alt="ShareMap"
                        />
                        <p
                          className={
                            val?.analytics >= 0
                              ? "percentageRate"
                              : "minusPercentageRate"
                          }
                        >
                          {val?.analytics?.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="tradesValue mt-4 mb-4">
            <div className="row">
              <div className="col-md-12">
                <div
                  onClick={() => history.push("/posSales")}
                  className="chartsOuter"
                >
                  <h3 className="textInnerHead mt-3">Sales</h3>
                  <div className="processStatus mt-3">
                    <p className="statusbox_">
                      <span className="complete_"></span> Sales
                    </p>
                  </div>
                  {/* <div className='row mt-4 mb-4 align-items-center'>

                                <div className='col-md-12'>
                                    <img src='' alt="Graph Image" className='img-fluid' />
                                </div>
                            </div> */}
                  <ChartArea
                    classes="productSales mt-2 mb-2"
                    header=""
                    options={options1}
                    data={lineData}
                    chartType="Line"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="tradesValue mt-4 mb-4">
            <div className="row">
              <div
                onClick={() => history.push("/posSales")}
                className="col-md-12"
              >
                <div className="chartsOuter">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <h3 className="textInnerHead mt-3">
                        Sales Per POS/Store
                      </h3>
                    </div>
                    <Bar
                      classes="productSales mt-2 mb-2"
                      header=""
                      options={options}
                      data={data}
                      chartType="Bar"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};
export default MyPos;
