import React from "react";
import * as Images from "../../utilities/images";

const ConnectedStatus = () => {
  return (
    <div className="verifySuccess">
      <div className="verifyBox">
        <figure className="successImg">
          <img src={Images.success} alt="tickImage" className="img-fluid" />
        </figure>
        <h1 className="verifyHeading">Success !</h1>
        <p className="congratulations-text">
          Congratulations! Your bank account has been successfully connected.
        </p>
      </div>
    </div>
  );
};

export default ConnectedStatus;
