import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { exportImg, employeProfile } from '../../../../utilities/images'

const AddEmployeeModal = () => {
    const history = useHistory()
  return (
    <div className='addEmployeeModal commonBody'>
        <form className='emplyoeeDetails horizontalForms'>
           <div className='row'>
                <div className='form-group col-md-12 mb-4 text-start'>
                    <div className='newEmployCon'>
                        <input type="file" id="file" accept="image/*" className="form-control d-none" />
                        <label htmlFor="file">
                            <span className="file-button">
                                <img src={employeProfile} className="img-fluid editProfileImg" alt="user img" />
                            </span>
                        </label>
                    </div>
                </div>
            <div className='form-group col-md-6  mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>First name</label>
                    <input type='text' className='customform-control' placeholder='First Name' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Last name</label>
                    <input type='text' className='customform-control' placeholder='Last Name' value='' />
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Email address</label>
                    <input type='email' className='customform-control' placeholder='Email address' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Employee ID</label>
                    <input type='text' className='customform-control' placeholder='Employee ID' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Join date</label>
                    <input type='text' className='customform-control' placeholder='dd / mm/ yyyy' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Phone number</label>
                    <input type='number' className='customform-control' placeholder='+1' value='' />
                </div>
                <div className='form-group col-md-6 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Password</label>
                    <input type='password' className='customform-control' placeholder='Password' value='dd / mm/ yyyy' />
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Department</label>
                    <select className='customform-control select fw-normal' placeholder='Department'>
                        <option>Department</option>
                        <option>Department2</option>
                        <option>Department3</option>
                    </select>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Dedignation</label>
                    <select className='customform-control select fw-normal'>
                        <option selected>Dedignation</option>
                        <option>Department2</option>
                        <option>Department3</option>
                    </select>
                </div>
                <div className='form-group col-md-12 mb-4'>
                    <label className='labelTxt mb-3' htmlFor='amount'>Description</label>
                    <textarea className='customform-control textAreaSize' placeholder='Description'></textarea>
                </div>
                <div className='footerSec mt-4'>
                    <button to='' className='secoundaryBtn me-4'>Cancel</button>
                    <button to='' className=''  onClick={() => history.push('/systemConfig/systemEmployeeInvoice')}>Add Employee</button>
                </div>
           </div>
        </form>
    </div>
  )
}

export default AddEmployeeModal