import React, { useState, useEffect } from 'react'
import OTPInput from "react-otp-input";
import { Link } from 'react-router-dom';
import CustomModal from '../../../components/shared/CustomModal';
import SuccessfulModal from '../successfulModal';
import Countdown from 'react-countdown';
import OtpTimer from "otp-timer";
import { selectRegisterAuth, verifyOtpAsync, resendOtpAsync, forgetPasswordAsync } from '../authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

const VerifyModal = (props) => {
    const [loading, setLoading] = useState(false)
    const [key, setKey] = useState(Math.random());
    const [timer, setTimer] = useState(false);
    const [showOtpTimer, setShowOtpTimer] = useState(false);
    const [loadingOtp, setLoadingOtp] = useState(false);
    const toastId = React.useRef(null)
    const registerData = useSelector(selectRegisterAuth)
    const dispatch = useDispatch()
    const onCompleted = (data) => {
        props.setOtp(data);
        if(data?.length == 5) {
            props?.buttonActive(true)
        }
        else{
            props?.buttonActive(false)
        }
    };
    const handleSubmit = (e) => {
        // if (otp.length < 5) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Otp must be of 5 digits")
        //     }
        //     return
        // }
        // setLoading(true)
        // let data = {
        //     "user_id": registerData?.payload?.user_id,
        //     "otp": otp
        // }
        // dispatch(verifyOtpAsync(data))
        //     .then(unwrapResult)
        //     .then((obj) => {
        //         setLoading(false)
        //         if (!toast.isActive(toastId.current)) {
        //             toastId.current = toast.success(obj?.msg)
        //         }
        //         props.details("Successful")
        //     }
        //     )
        //     .catch((obj) => {
        //         setLoading(false)
        //     })
    }

    // Send OTP to the customer
    const sendOtp = () => {
        let data = {
            "type": "phone",
            "phone_code": `+${props.phoneCode}`,
            "phone_no": props.phoneNo
          }
          setLoadingOtp(true)
           dispatch(forgetPasswordAsync(data))
            .then(unwrapResult)
            .then((obj) => {
              setLoadingOtp(false)
              props.SetUserId(obj?.payload?.user_id)
              if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(obj?.msg)
              }
              // setModalDetail({ show: true, flag: 'ScheduleModal' });
              // setKey(Math.random());
            }
            )
            .catch((obj) => {
              setLoadingOtp(false)
            })
    }

    return (
        <>
            <div className='verifyModal'>
                <h4 className='innerHeading'>Verify your phone number</h4>
                <p className='mainlightHead'>Enter OTP code here, we have sent on your phone number.</p>
                <div className="mb-4 mt-3 1234 otp__ d-flex justify-content-center ">
                    <OTPInput
                        numInputs={5}
                        className="input_digits_ "
                        value={props.otp}
                        data-cy="pin-field"
                        isInputNum={true}
                        onChange={(e)=>onCompleted(e)}
                        isInputSecure={true}
                        autoComplete="false"
                    // onComplete={(code) => onComplete(code)}
                    />
                </div>
                {/* <div className='ps-4 pe-4'>
                    <button className='loginBtn w-100 '
                        onClick={() => handleSubmit()}
                        disabled={loading}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Almost Done!</span>

                    </button>
                </div> */}
                <p className='innerSubtext mt-5 mb-4'>
                    <OtpTimer
                        //minutes={1}
                        seconds={60}
                        key={key}
                        //seconds={otpTimerPeriod}
                        //text="Resend OTP in:"
                        ButtonText="Resend"
                        resend={sendOtp}
                    />
                    {/* <a onClick={(e) => {return !showOtpTimer ? sendOtp() : e.preventDefault}} className={"send_otp " + (showOtpTimer ? "disabled" : "")} disabled>Resend</a> */}
                    {/* {timer ?
                        <Link to="#" className='linkTxt ms-2'><u>Resend</u></Link> : <></>} */}
                </p>

                {/* <p className='mainlightHead'>its not your phone number

                    <Link className='linkTxt' to="#" 
                    // onClick={() => { props.close()}}
                     >
                    <u >change it.</u></Link></p> */}
            </div>
        </>
    )
}

export default VerifyModal