import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../components/shared/CustomModal";
import { selectLoginAuth } from "../../auth/authSlice";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import { getMerchantActiveSubscriptionAsync } from "../../dashboard/dashboardSlice";
import AddCardModal from "../../scalePlan/addCardModal";
import PaymentDetail from "../../scalePlan/paymentDetails";
import PlansModal from "../../scalePlan/plansModal";
import ViewPlanModal from "./ViewPlanModal";

const PlanSubscriptions = () => {
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [planIncludesApp, setPlanIncludesApp] = useState("");
  const [planFeatures, setPlanFeatures] = useState("");

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const basicPlanIncludesApp = [
    {
      id: 1,
      name: "JOBR B2C",
    },
    // {
    //   id: 2,
    //   name: "JOBR Wallet",
    // },
  ];

  const premiumPlanIncludesApp = [
    {
      id: 1,
      name: "JOBR B2C",
    },
    // {
    //   id: 2,
    //   name: "JOBR Wallet",
    // },
    {
      id: 3,
      name: "JOBR POS",
    },
    {
      id: 4,
      name: "JOBR Driver",
    },
    {
      id: 5,
      name: "JOBR B2B",
    },
  ];

  const standardPlanIncludesApp = [
    {
      id: 1,
      name: "JOBR B2C",
    },
    // {
    //   id: 2,
    //   name: "JOBR Wallet",
    // },
    {
      id: 3,
      name: "JOBR POS",
    },
    {
      id: 4,
      name: "JOBR Driver",
    },
  ];

  const basicPlanFeatures = [
    {
      id: 1,
      name: "Online store",
    },
    {
      id: 2,
      name: "Shareable products",
    },
    {
      id: 3,
      name: "Unlimited products",
    },
  ];

  const premiumPlanFeatures = [
    {
      id: 1,
      name: "Online store",
    },
    {
      id: 2,
      name: "Shareable products",
    },
    {
      id: 3,
      name: "Unlimited products",
    },
    {
      id: 4,
      name: "24/7 support",
    },
    {
      id: 5,
      name: "Abandoned cart recovery",
    },
    {
      id: 6,
      name: "Advanced report builder",
    },
  ];

  const standardPlanFeatures = [
    {
      id: 1,
      name: "Online store",
    },
    {
      id: 2,
      name: "Shareable products",
    },
    {
      id: 3,
      name: "Unlimited products",
    },
    {
      id: 4,
      name: "24/7 support",
    },
  ];

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flags) => {
    setSelectedPlanId(flags?.id);
    const modalFlag = flags?.flag || flags;
    setModalDetail({
      show: true,
      title: "",
      flag: modalFlag,
    });
    setKey(Math.random());
  };

  const details = (data) => {
    setSelectedPlan(data);
  };

  const getMerchantActiveSubscription = () => {
    setLoading(true);
    dispatch(getMerchantActiveSubscriptionAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setSelectedPlan(obj?.payload);
        setPlanIncludesApp(
          obj?.payload[0]?.price_id?.metadata?.name === "Basic"
            ? basicPlanIncludesApp
            : obj?.payload[0]?.price_id?.metadata?.name === "Standard"
            ? standardPlanIncludesApp
            : premiumPlanIncludesApp
        );
        setPlanFeatures(
          obj?.payload[0]?.price_id?.metadata?.name === "Basic"
            ? basicPlanFeatures
            : obj?.payload[0]?.price_id?.metadata?.name === "Standard"
            ? standardPlanFeatures
            : premiumPlanFeatures
        );
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMerchantActiveSubscription();
  }, []);
  return (
    <>
      <DashboardNavbar title="Plans & Subscriptions" backShow={false} />
      <div className="plansDetails">
        <div className="container-fluid">
          <div className="row">
            {selectedPlan?.length > 0 ? (
              <div className="mainCatalog plans_ mb-3">
                <div className="col-md-12">
                  <h4 className="successMain">Business Plan</h4>
                  <div className="mainBussiness">
                    <div className="commonBussiness">
                      <div
                        className="planBasic"
                        onClick={() => {
                          setModalDetail({ show: true, flag: "viewPlan" });
                          setKey(Math.random());
                        }}
                      >
                        <h4 className="inActiveHead mt-4 mb-4">
                          {selectedPlan[0]?.price_id?.metadata?.name}
                        </h4>
                        <div className="bussinesDetails">
                          <h4 className="monshead14">
                            Your Plan includes app{" "}
                          </h4>
                          {planIncludesApp?.length > 0 ? (
                            planIncludesApp?.map((val, ind) => {
                              return (
                                <div key={ind}>
                                  <h4 className="subtextSmall_ text-start mt-1">
                                    {val?.name}
                                  </h4>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                          {/* <div key={ind} className="customRadio_ text-start">
                                                    <input type="radio" id="test1" checked />
                                                    <label for="test1">{val}</label>
                                                </div> */}
                          {/* <p className='subtextSmall_'>JOBR B2B</p>
                                                <p className='subtextSmall_'>JOBR B2c </p>
                                                <p className='subtextSmall_'>JOBR Admin</p>
                                                <p className='subtextSmall_'>JOBR POS</p> */}
                        </div>
                        <div className="bussinesDetails mt-3 mb-3">
                          <h4 className="monshead14 text-start">
                            Next billing date
                          </h4>
                          <p className="subtextSmall_">
                            {moment(selectedPlan[0]?.expiry_date).format(
                              "MMM DD, YYYY"
                            )}{" "}
                            for ${selectedPlan[0]?.amount}USD
                          </p>
                        </div>
                        <div className="bussinesDetails mt-3 mb-3">
                          <h4 className="monshead14 text-start">
                            Payment method
                          </h4>
                          <div className="visaBUssiness">
                            {/* <img src={visaImg} alt='visaImages' className='img-fluid visaIcons' /> */}
                            {/* <span className='monshead14'> Visa ending in 2275</span> */}
                            <span className="monshead14">
                              {/* JBR coin */}
                              Card
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="bussinesDetails  mt-3 mb-3">
                        <button
                          onClick={() => {
                            handleOpenModal("PickPlan");
                          }}
                          className="backgroundFillbtn"
                        >
                          Change Plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mainCatalog plans_ mb-3">
                <h4 className="text-center">No Active Plan Found</h4>
              </div>
            )}

            {/* <div className='mainCatalog plans_ mb-3'>
                            <div className='col-md-12'>
                                <h4 className='successMain'>Payroll Subscription</h4>
                                <div className='mainBussiness'>

                                    <div className='commonBussiness'>
                                        <h4 className='inActiveHead mt-4 mb-4'>Small Team</h4>

                                        <div className='bussinesDetails mt-3 mb-3'>
                                            <h4 className='monshead14 text-start'>Next billing date</h4>
                                            <p className='subtextSmall_'>March 2, 2023 for $1.00 USD</p>
                                        </div>
                                        <div className='bussinesDetails mt-3 mb-3'>
                                            <h4 className='monshead14 text-start'>Payment method</h4>
                                            <div className='visaBUssiness'>
                                                <img src={visaImg} alt='visaImages' className='img-fluid visaIcons' />
                                                <span className='monshead14'> Visa ending in 2275</span>
                                            </div>
                                        </div>
                                        <div className='bussinesDetails  mt-3 mb-3'>
                                            <button className='upgradeBtn'>Upgrade</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='mainCatalog plans_ mb-3'>
                            <div className='col-md-12'>
                                <h4 className='successMain'>Payroll Subscription</h4>
                                <div className='bussinessBox'>
                                    <button className='upgradeBtn'> Buy a payroll Plan</button>
                                    <h4 className='monshead14  mt-3 '>You have no active subscriptions</h4>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "PickPlan"
            ? "pickPlan"
            : modalDetail.flag === "viewPlan"
            ? "ViewPlan"
            : modalDetail.flag === "FindProduct"
            ? "findProduct"
            : modalDetail.flag === "paymentCardInfo"
            ? "viewPlan"
            : ""
        }
        size={
          modalDetail.flag === "PickPlan"
            ? "xl"
            : modalDetail.flag === "FindProduct"
            ? "md"
            : modalDetail.flag === "paymentCardInfo"
            ? "xl"
            : modalDetail.flag === "AddCard"
            ? "lg"
            : "lg"
        }
        child={
          modalDetail.flag === "viewPlan" ? (
            <ViewPlanModal
              flag={handleOpenModal}
              close={() => handleOnCloseModal()}
              selectedPlan={selectedPlan}
              planIncludesApp={planIncludesApp}
              planFeatures={planFeatures}
              loading={loading}
            />
          ) : modalDetail.flag === "paymentCardInfo" ? (
            <AddCardModal
              details={(e) => details(e)}
              close={() => handleOnCloseModal()}
              openModalflag={(flag) => handleOpenModal(flag)}
              selectedPlanId={selectedPlanId}
            />
          ) : modalDetail.flag === "AddCard" ? (
            <PaymentDetail
            selectedPlanId={selectedPlanId}
            setSelectedPlanId={setSelectedPlanId}
              close={() => handleOnCloseModal()}
              openModalflag={(flag) => handleOpenModal(flag)}
            />
          ) : modalDetail.flag === "PickPlan" ? (
            <PlansModal
              openModalflag={(flag) => handleOpenModal(flag)}
              handleSelectedPlan={(data) => details(data)}
              close={() => handleOnCloseModal()}
              selectedPlan={selectedPlan}
            />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "PickPlan" ? (
              <>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3 className="heading_ mb-2">Plan that fits your scale</h3>
                    <p className="mb-0">
                      Simple, Transparent pricing that grows with you
                    </p>
                    <span
                      className="crossIcon"
                      onClick={() => handleOnCloseModal()}
                    >
                      <i className="las la-times " aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </>
            ) : modalDetail.flag === "paymentCardInfo" ? (
              <>
                <h3 className="subheading_ mb-2">Card Details</h3>
                <span
                  className="crossIcon"
                  onClick={() => handleOnCloseModal()}
                >
                  <i className="las la-times" aria-hidden="true"></i>
                </span>
              </>
            ) : modalDetail.flag === "AddCard" ? (
              <>
                <h3 className="subheading_ mb-2">Add card</h3>
                <span
                  className="crossIcon"
                  onClick={() => handleOnCloseModal()}
                >
                  <i className="las la-times" aria-hidden="true"></i>
                </span>
              </>
            ) : (
              <>
                {/* <h3 className='subheading_ mb-2'>Based on your Store Profile setup</h3> */}
                <span
                  className="crossIcon"
                  onClick={() => handleOnCloseModal()}
                >
                  <i className="las la-times crossBg " aria-hidden="true"></i>
                </span>
              </>
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default PlanSubscriptions;
