import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectLoginAuth } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import DataTable from 'react-data-table-component';
import { deleteOfferApiAsync, getProductOfferListApiAsync } from '../dashboard/dashboardSlice';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import { calendar } from "../../../utilities/images";
import ProductOfferModal from "./ProductOfferModal";
import CustomModal from "../../components/shared/CustomModal";
import OfferViewModal from "./offerViewModal";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import OfferEditModal from "./offerEditModal";

const ProductOffer = () => {
  const pathname = window.location.pathname;

  console.log(pathname, "pathname");
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [offerList, setOfferList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(Math.random());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteHandle = (id) => {
    console.log(id, 'idddddddddddddd');
    Swal.fire({
      title: "Do you want to delete this offer?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          postData: {
           id: id?.id,
          },
          tokenData: auth?.payload?.token,
        }
        dispatch(deleteOfferApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            getOfferList();
            toast.success(obj?.msg);
          })
          .catch((obj) => {});
      }
    });
  };

  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  let columns1 = [
    {
      name: "#",
      // selector: row => row?.id,
      selector: (row, i) => i + 1 * (perPage * (currentPage - 1)) + 1,
      width: "40px",
    },
    {
      name: `${pathname === "/serviceOffer" ? "Service" : "Product"} Name`,
      selector: (row) => {
        return (
          <>
            <div
              className="d-flex align-items-center"
              onClick={() => handleRowClick(row)}
            >
              <img className="userInnerImg" src={row?.product?.image} />
              <div className="ms-2">
                <span className="textParagh ">
                  {row?.product?.name?.length > 15
                    ? row?.product?.name?.substring(0, 15) + "..."
                    : row?.product?.name}
                </span>
              </div>
            </div>
          </>
        );
      },
      width: "200px",
    },
    {
      name: "Category",
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {row?.categories?.name?.length > 15
                ? row?.categories?.name?.substring(0, 15) + "..."
                : row?.categories?.name}
            </span>
          </>
        );
      },
    },
    {
      name: "Sub-category",
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {row?.subcategories?.name?.length > 15
                ? row?.subcategories?.name?.substring(0, 15) + "..."
                : row?.subcategories?.name}
            </span>
          </>
        );
      },
      width: "180px",
    },
    {
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {row?.actual_price ? "$" : ""}
              {row?.actual_price}
            </span>
          </>
        );
      },
      name: "Cost Price",
    },

    {
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              ${row?.offer_price}
            </span>
          </>
        );
      },
      name: "Offer Price",
      width: "180px",
    },
    {
      selector: (row) => {
        return (
          <>
            <span className="textParagh " onClick={() => handleRowClick(row)}>
              {moment?.utc(row?.offer_validity)?.format("MMM DD, YYYY")}
            </span>
            {/* <span className='textParagh ' onClick={() => handleRowClick(row)}>{moment(row?.offer_validity).subtract(1, 'days')?.format("MMM DD, YYYY")}</span> */}
          </>
        );
      },
      name: "Offer validity date",
      width: "170px",
    },
    {
      name: "",
      selector: (row) => {
        return (
          <>
            <div className="d-flex justify-space-between align-items-center pe-3 ps-3">
              <div className="dropdown tableDropdown ms-3 ">
                <span
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="barMenus"
                >
                  <i className="fal fa-ellipsis-v icon_"></i>
                </span>
                <div
                  className="dropdown-menu tableDropdownMenus categoryDrop"
                  aria-labelledby="barMenus"
                >
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedProduct(row);
                      setModalDetail({ show: true, flag: "viewOffer" });
                      setKey(Math.random());
                    }}
                  >
                    View
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedProduct(row);
                      setModalDetail({ show: true, flag: "editOffer" });
                      setKey(Math.random());
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => deleteHandle(row)}
                  >
                    Delete
                  </li>
                </div>
              </div>
            </div>
          </>
        );
      },
      width: "100px",
    },
  ];

  if (pathname === "/productOffer") {
    columns1 = [
      ...columns1?.slice(0, columns1?.length - 3),
      {
        selector: (row) => {
          return (
            <>
              <span className="textParagh " onClick={() => handleRowClick(row)}>
                {row?.brands?.name?.length > 15
                  ? row?.brands?.name?.substring(0, 15) + "..."
                  : row?.brands?.name}
              </span>
            </>
          );
        },
        name: "Brand",
      },
      ...columns1.slice(columns1?.length - 3),
    ];
  }

  const handleRowClick = (e) => {
    setSelectedProduct(e);
    setModalDetail({ show: true, flag: "viewOffer" });
    setKey(Math.random());
  };

  const getOfferList = (page) => {
    setLoading(true);
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        page: page ? page : currentPage,
        limit: perPage,
        type: pathname === "/serviceOffer" ? "service" : "product",
      },
    };
    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    dispatch(getProductOfferListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        console.log(obj?.payload, "offers");
        setOfferList(obj?.payload?.data);
        console.log(offerList+"offerlistmy")
        setTotalRows(obj?.payload?.total);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOfferList();
  }, [currentPage, perPage, endDate, pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Offers";
  }, []);

  return (
    <>
      <DashboardNavbar
        title={`${
          pathname === "/serviceOffer" ? "Services/ offer " : "Products/ offer "
        }`}
        backShow={false}
      />
      <div className="tableSectionBackground productOfferSection">
        {/* <div className='topHeader_'>
          <div className="topStickyHeader_ position-static w-100">
            <div className="row align-items-center">
              <div className="col-md-9">
                <h2 className="mainHeading">Product /<span className="innerSubtext">Products List</span> </h2>
              </div>
              <div className='col-md-3 text-end'>
                <button className='backgroundFillbtn' type="button" onClick={() => {
                  setModalDetail({ show: true, flag: "MakeOffer" });
                  setKey(Math.random());
                }}>Create offer</button>
              </div>
            </div>
          </div>
        </div> */}
        <div className="filteOffer">
          <div className="row">
            <div className="col-lg-6">
              <div className="calenderIcon">
                <img src={calendar} alt="calender" />
                <DatePicker
                  className="cateDate"
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Date"
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <button
                className="backgroundFillbtn"
                type="button"
                onClick={() => {
                  setModalDetail({ show: true, flag: "MakeOffer" });
                  setKey(Math.random());
                }}
              >
                Create offer
              </button>
            </div>
          </div>
        </div>
        <div className='plansAreasContainer'>
          <section className='recentTransections mt-1 commonTableheight table-responsive'>
            <DataTable
              columns={columns1}
              data={offerList}
              progressPending={loading}
              defaultSortFieldId={1}
              customStyles={customStyles}
              onRowClicked={(e) => handleRowClick(e)}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationPerPage={perPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </section>
        </div>

        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop={true}
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          ids={
            modalDetail.flag === "viewOffer"
              ? "ViewOffer"
              : modalDetail.flag === "MakeOffer"
              ? "createOffer"
              : modalDetail.flag === "editOffer"
              ? "editOffer"
              : ""
          }
          size={
            modalDetail.flag === "viewOffer" || modalDetail.flag === "MakeOffer"
              ? "xl"
              : "md" ||
                modalDetail.flag === "editOffer" ||
                modalDetail.flag === "MakeOffer"
              ? "xl"
              : "md"
          }
          child={
            modalDetail.flag === "MakeOffer" ? (
              <ProductOfferModal
                type={pathname === "/serviceOffer" ? "digital" : "physical"}
                close={() => handleOnCloseModal()}
                setTotalRows={setTotalRows}
                setOfferList={setOfferList}
              />
            ) : modalDetail.flag === "viewOffer" ? (
              <OfferViewModal
                selectedProduct={selectedProduct}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "editOffer" ? (
              <OfferEditModal
                selectedProduct={selectedProduct}
                close={() => handleOnCloseModal()}
                setTotalRows={setTotalRows}
                setOfferList={setOfferList}
              />
            ) : (
              ""
            )
          }
          header={
            <div className="modalHeader_">
              <div className="common_">
                <h2 className="modalHeading_">
                  {modalDetail.flag === "MakeOffer"
                    ? "Create"
                    : "" || modalDetail.flag === "editOffer"
                    ? "Edit"
                    : "" || modalDetail.flag === "viewOffer"
                    ? "View"
                    : ""}{" "}
                  an Offer
                </h2>

                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};

export default ProductOffer;
