import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import CustomModal from '../../../components/shared/CustomModal';
import TwoStepModal from './twoStepModal'
import QrCodeModal from './qrCodeModal';
import { googleAuth, security } from '../../../../utilities/images'
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getQrCodeAsync, systemConfigAsync, updateUserSettingAsync } from '../systemConfigSlice';
import DashboardNavbar from '../../dashboard/DashboardNavbar';


const SecuritySetting = (props) => {
    const [key, setKey] = useState(Math.random());
    const [loading, setLoading] = useState([]);
    const [allData, setAllData] = useState([]);
    const [qrCodeData, setQrCodeData] = useState("");
    const [authState, setAuthState] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch()

    const handleDetails = (flag) => {
        setModalDetail({ show: true, flag: flag });
        setKey(Math.random());
    }

    const getSettingsData = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                app_name: "pos",
            }
        }
        setLoading(true)
        dispatch(systemConfigAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setAllData(obj?.payload ? obj?.payload : [])
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    const handleChange = (e) => {
        // console.log(e.target.checked,"ffffffffff");
        setAuthState(e.target.checked)
        if (!allData?.google_authenticator_status) {
            setModalDetail({ show: true, flag: "TwoStepModal" });
            setKey(Math.random());
        }
        else {
            let params = {
                tokenData: auth?.payload?.token,
                postData: {
                    app_name: 'pos',
                    google_authenticator_status: false,
                },
            }
            setLoading(true)
            dispatch(updateUserSettingAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setLoading(false)
                    toast.success(obj?.msg);
                    getSettingsData()
                }
                )
                .catch((obj) => {
                    setLoading(false)
                    //
                })
        }
    }
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    useEffect(() => {
        getSettingsData();
    }, [])

    return (
        <>
            <div className='securitySetting'>
                <DashboardNavbar title="Security" show={true} />
                <div className='paymentContainer'>
                    <div className='paymentArea mb-2 mt-2'>
                        <div className='row'>
                            <div className='col-md-10'>
                                <div className='d-flex align-items-start'>
                                    <img src={security} className='paymentTypeIcon me-3' />
                                    <div className='w-100'>
                                        <h3 className='textInnerDark'>2-step verification</h3>
                                        <p className='subtext_ fw-normal mt-3 mb-3'>An extra layer to boost your team members account security. A verification code will be required in addition to password each time you sign in.</p>
                                        <div className='businessDetails'>
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <Link
                                                        to="#">
                                                        <div className='d-flex '>
                                                            <span className='IconPin me-3'>
                                                                <img src={googleAuth} alt='LocationPin' />
                                                            </span>
                                                            <div className=''
                                                            >
                                                                <h4 className='itemsText'>2-step verification for team members</h4>
                                                                <div className=''>
                                                                    <p className='descriptionPara_'>Team members must enable their own verification methods.</p>
                                                                </div>
                                                                {/* <div className='d-flex align-items-center mt-2'>
                                                            <img src={startDriver} alt='Star' className='me-1' />
                                                            <h6 className='subtextDark_'>Specify JOBR delivery Drivers </h6>
                                                        </div> */}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className='col-md-2 text-end'>
                                                    <div className="form-check form-switch">
                                                        <input type="checkbox" checked={allData?.google_authenticator_status} onChange={(e) => handleChange(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "TwoStepModal" ? "customContent" : modalDetail.flag === "QrCodeModal" ? "customContent" : ""}
                ids={modalDetail.flag === "TwoStepModal" ? "twoStepModal" : modalDetail.flag === "QrCodeModal" ? "twoStepModal" : ""}
                size={
                    modalDetail.flag === "TwoStepModal" ? "lg" : modalDetail.flag === "QrCodeModal" ? "lg" : ""
                }
                child={
                    modalDetail.flag === 'TwoStepModal' ? <TwoStepModal details={(e) => handleDetails(e)} close={() => handleOnCloseModal()} />
                        : modalDetail.flag === "QrCodeModal" ? <QrCodeModal status={authState} setAllData={setAllData} close={() => handleOnCloseModal()} />
                            : <></>
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">

                            <h2 className="modalHeading">
                                Enable Two-Steps Security
                            </h2>

                            <button className="closeButton">
                                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                            </button>
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default SecuritySetting