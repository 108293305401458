import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import DataTable, { createTheme } from 'react-data-table-component';
import { renameIcon, viewIcon, calendar, deleteIcon, deleteicon } from '../../../../utilities/images';
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import CustomModal from '../../../components/shared/CustomModal'
import products from "../../../db/catalog.json"
import FaqeditModal from './faqeditModal';
import { unwrapResult } from '@reduxjs/toolkit';
import { getFaqDataApiAsync, deleteFaqApiAsync, updateFaqApiAsync, getFaqCategoryDataApiAsync } from '../../dashboard/dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import ViewFaqModal from './viewFaqModal';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const ConfigFaq = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const [categories, setCategories] = useState("");
  const [myValue, setMyValue] = useState('');
  const [faqData, setFaqData] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [selectedData, setSelectedData] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const auth = useSelector(selectLoginAuth);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };


  const handleFaqActive = (status, id) => {
    let params = {
      "postData": {
        'id': id,
        'is_active': !status
      },
      "tokenData": auth?.payload?.token
    }
    dispatch(updateFaqApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        toast.success(obj?.msg)
        faqDataHandle();
      })
      .catch((obj) => {
      })
  }



  const columns1 = [
    {
      name: '#',
      selector: (row,i) => i+1,
      width: "70px"
    },
    {
      name: "Category",
      selector: row => {
        const selectedCategory = categories?.length > 0 ? categories?.find(item => item.id == row.faq_category_id) : [];
        return (
          <>
            <span className='textParagh '>{selectedCategory?.category_name}</span>
          </>
        );
      },
    },
    {
      name: 'FAQ',
      width: "350px",
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.title}</span>
            <p className='smallHeading'><div dangerouslySetInnerHTML={{ __html: row?.discription?.substring(0, 40) + "..." }} /></p>
          </>
        );
      },
    },
    {
      name: 'Create',
      selector: row => {
        return (
          <>
            <span className='textParagh '>{moment(row?.created_at).format("DD MMM, YYYY")}</span>
          </>
        );
      },
    },
    {
      name: 'Update ',
      selector: row => {
        return (
          <>
            <span className='textParagh '>{moment(row?.updated_at).format("DD MMM, YYYY")}</span>
          </>
        );
      },
    },
    {
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center justify-content-center configswitch_'>
              <div className="form-check form-switch">
                <input type="checkbox"
                  checked={row?.is_active}
                  onChange={() => handleFaqActive(row?.is_active, row?.id)}
                />
              </div>
              <img src={renameIcon} className='actionImg ms-4 me-4' alt='Delete-Icon' onClick={() => {
                setSelectedData(row)
                setModalDetail({ show: true, flag: 'FaqeditModal' });
                setKey(Math.random());
              }} />
              <img src={viewIcon} className='actionImg me-3' alt='View-Icon' onClick={() => {
                setSelectedData(row)
                setModalDetail({ show: true, flag: 'FaqViewModal' });
                setKey(Math.random());
              }}
              />
              <img src={deleteicon} className='actionImg me-3' alt='View-Icon' onClick={() => handleDeleteCategory(row?.id)} />
            </div>
          </>
        );
      },
      name: ''
    },

  ];
  const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
  }



  const handleDeleteCategory = (id) => {
    Swal.fire({
      title: 'Do you want to delete the FAQ?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let params = {
          "postData": {
            id: id,
          },
          "tokenData": auth?.payload?.token
        }
        setDeleteLoading(true)
        dispatch(deleteFaqApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setDeleteLoading(false);
            toast.success(obj?.msg);
            faqDataHandle();
          })
          .catch((obj) => {
            setDeleteLoading(false)
          })
      }
    })
  }

  useImperativeHandle(ref, () => ({
    getProductListParent() {
      let params = {
        "postData": {
          page: currentPage,
          limit: perPage,
          // type: "my_account",
          // is_admin: false
        },
        "tokenData": auth?.payload?.token
      }
      setLoading(true)
      dispatch(getFaqDataApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setFaqData(obj?.payload?.data)
          setTotalRows(obj?.payload?.total)
          setLoading(false)
        })
        .catch((obj) => {
          setLoading(false)
        })
    }
  }));

  const faqDataHandle = () => {
    let params = {
      "postData": {
        page: currentPage,
        limit: perPage,
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
        // type: "my_account",
        // is_admin: false
      },
      "tokenData": auth?.payload?.token
    }
    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        "start_date": moment(startDate).format('YYYY-MM-DD'),
        "end_date": moment(endDate).format('YYYY-MM-DD')
      }
      params = {
        tokenData: auth?.payload?.token,
        postData: newData
      }
    }
    setLoading(true)
    dispatch(getFaqDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setFaqData(obj?.payload?.data)
        setTotalRows(obj?.payload?.total)
        setLoading(false)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  const faqCatagoryData = () => {
    let params = {
      "tokenData": auth?.payload?.token
    }
    dispatch(getFaqCategoryDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setCategories(obj?.payload?.data)
      })
      .catch((obj) => {
      })
  }

  useEffect(() => {
    faqCatagoryData()
  }, [])

  useEffect(() => {
    faqDataHandle()
  }, [endDate, perPage, currentPage])

  return (
    <>
      <div className='faqHeading_ mt-5 mb-4'>
        <h1 className='subheading_ fw-500'>Frequently Asked Questions</h1>
        <p className='subtext_ fw-normal mt-2'>Copy
First, a disclaimer – the entire process of writing a blog post often takes more.</p>
      </div>

      <div className='tableSectionBackground'>
        <div className='tableFilters mt-3 mb-3'>
          <div className='col-md-12'>
          <div className='row'>
            {/* <div className='col-md-6 '>
            <div className="calenderIcon ">
              <img src={calendar} alt="calender" />
          <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                  dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> 
              <DatePicker
                className='cateDate'
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Date"
              />
            </div>
          </div> */}
            {/* <div className='col-md-3'>
              <select className="cateSelectdFilter" value={myValue} onChange={(e) => setMyValue(e.target.value)} required>
                <option  >Country </option>
                <option  >Category1</option>
                <option  >Category2</option>
              </select>
            </div>
            <div className='col-md-3'>
              <select className="cateSelectdFilter" value={myValue} onChange={(e) => setMyValue(e.target.value)} required>
                <option  >State </option>
                <option  >Category1</option>
                <option  >Category2</option>
              </select>
            </div> */}
            {/* <div className='col-md-3'>
              <select className="cateSelectdFilter" value={myValue} onChange={(e) => setMyValue(e.target.value)} required>
                <option  >Id type </option>
                <option  >Category1</option>
                <option  >Category2</option>
              </select>
            </div> */}
            <div className='col-lg-6'>
              <div className='productMainCategory'>
                {/* <div className='categroyBox'>
                  <select className="cateSelectdFilter" required>
                    <option value="" selected>
                      All Status
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                  </select>
                </div> */}
                {/* <div className='categroyBox'>
                  <select className="cateSelectdFilter" required>
                    <option value="" selected>
                      All Categories
                    </option>
                    <option>Category1</option>
                    <option>Category2</option>
                  </select>
                </div> */}
              </div>
            </div>
          </div>
          </div>
        </div>
    
      <div className='configFaq'>
        <div className='commonTableheight table-responsive'>
          <DataTable
            pagination
            columns={columns1}
            data={faqData}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
            progressPending={loading}
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </div>
        <CustomModal
          key={key}
          show={modalDetail.show}
          // backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          ids={modalDetail.flag === "FaqeditModal" ? "faqeditModal" : modalDetail.flag === 'FaqViewModal' ? "faqViewModal" : ""}
          size={modalDetail.flag === "FaqeditModal" || modalDetail.flag === 'FaqViewModal' ? "md" : "lg"}
          child={modalDetail.flag === 'FaqeditModal' ? <FaqeditModal selectedData={selectedData} close={() => handleOnCloseModal()} faqDataHandle={() => { faqDataHandle() }} /> :
            modalDetail.flag === 'FaqViewModal' ? <ViewFaqModal selectedData={selectedData} close={() => handleOnCloseModal()} />
              : ""
          }
          header={
            <div className='modalHeader_'>
              <div className="common_">
                <h2 className="modalHeading">
                  {/* {modalDetail.flag === 'FaqeditModal' ? 'Edit' : 'View'} FAQ */} Edit view Faq
                </h2>
                <button className="closeButton">
                  <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                </button>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
      </div>
    </>

  )
})

export default ConfigFaq
