import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import CustomModal from "../../../components/shared/CustomModal";
import { Link, useHistory } from "react-router-dom";
import {
  calendar,
  user,
  locationBlue,
  subtractImg,
  profileImg1,
  search_light,
  manage,
  addPlusBtn,
  modalCancel,
} from "../../../../utilities/images";
import PendingInvoiceModal from "./pendingInvoiceModal";
import {
  changeStatusApiAsync,
  getAllOrdersApiAsync,
  getCategoriesAsync,
} from "../../dashboard/dashboardSlice";
// import { deleteProductApiAsync, get, getCategoriesAsync } from '../dashboard/dashboardSlice'
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../auth/authSlice";
import CustomLoader from "../../../components/shared/CustomLoader";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { OrderDown } from "../../../../utilities/images";
import PrintLabelModal from "./PrintLabelModal";

var isOpen = false;
const AllOrder = (props) => {
  const { seller_id } = { ...props };
  const [printingUrl, setPrintingUrl] = useState("");
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const [keyword, setKeyword] = useState("");
  const [key, setKey] = useState(Math.random());
  const containerRef = useRef(null);
  const [newSelectedId, setNewSelectedId] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [selectedValue, setSelectedValue] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [newMessagekey, setNewMessagekey] = useState(Math.random());
  const auth = useSelector(selectLoginAuth);
  const [orderData, setOrderData] = useState({});
  const [singleOrderData, setSingleOrderData] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orderFilter, setOrderFilter] = useState("");
  const [filter, setFilter] = useState(
    location?.state?.filter ? location?.state?.filter : ""
  );
  const [statusFilter, setStatusFilter] = useState(
    location?.state?.status ? location?.state?.status : ""
  );
  const [paymentFilter, setPaymentFilter] = useState("");
  const [columnStatus, setColumnStatus] = useState({
    invoiceNo: false,
    locations: false,
    user: false,
    paymentMethod: false,
    delivery: false,
    tracking: false,
    noOfItems: false,
  });
  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let params = {
    postData: {
      seller_id: seller_id
        ? seller_id
        : auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      page: currentPage,
      limit: perPage,
      // order_type: "product",
      // transaction_type: "all"
      search: keyword || undefined,
    },
    tokenData: auth?.payload?.token,
  };

  const handleOrderFilter = (e) => {
    setOrderFilter(e?.target.value);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e?.target.value);
  };

  const handlePaymentFilter = (e) => {
    setPaymentFilter(e?.target.value);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const paginationOptions = {
    // rowsPerPageText: 'Rows per page:',
    // rangeSeparatorText: 'of',
    // selectAllRowsItem: true,
    // selectAllRowsItemText: 'All',
  };
  const handleSubcategoryChange = (subCategoryId) => {
    let filterData = [...subCategoryIds];
    if (filterData?.includes(subCategoryId)) {
      filterData = filterData?.filter((val) => val != subCategoryId);
      setSubCategoryIds(filterData);
    } else {
      filterData = [...subCategoryIds, subCategoryId];
      setSubCategoryIds(filterData);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const trackingRouteHandle = (status, deliveryOption, orderId, trackUrl) => {
    if (deliveryOption == 4) {
      window.open(trackUrl, "_blank");
    } else {
      status === 9
        ? history.push(`/deliveryStatus/${orderId}`)
        : history.push(`/orderTrack/${orderId}`);
    }
  };

  const handleToggle = () => {
    setDropdownOpen(true);
    setTimeout(() => {
      isOpen = true;
      setNewMessagekey(Math.random());
    }, 100);
    // setIsOpen(true);
  };
  const fetchAllCategories = (searchKeyword) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        main_category: true,
        with_subcategories: true,
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
    };
    if (searchKeyword) {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          ...data.postData,
          search: searchKeyword,
          check_product_existance: false,
        },
      };
    }
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setCategoryData(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getAllOrdersHandle = (modalClose = null) => {
    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (orderFilter && orderFilter != 0) {
      let newData = {
        ...params.postData,
        delivery_option: orderFilter,
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (orderFilter && orderFilter == 0) {
      let newData = {
        ...params.postData,
        status: orderFilter,
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (statusFilter) {
      let newData = {
        ...params.postData,
        status: statusFilter,
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (filter) {
      let newData = {
        ...params.postData,
        filter_by: filter,
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (statusFilter && statusFilter === "printing") {
      let newData = {
        ...params.postData,
        status: 3,
        all_orders: "shipping",
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (paymentFilter) {
      let newData = {
        ...params.postData,
        transaction_type: paymentFilter,
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(getAllOrdersApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setOrderData(obj);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const changeStatusOfOrderHandle = (id, status) => {
    setNewSelectedId(id);
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: auth?.payload?.token,
    };

    status === 8 ? setLoading1(true) : setLoading2(true);
    dispatch(changeStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        getAllOrdersHandle(true);
        handleOnCloseModal();
        setLoading1(false);
        setLoading2(false);
      })
      .catch((obj) => {
        setLoading1(false);
        setLoading2(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Orders";
  }, []);

  let columns1 = [
    {
      name: "Date",
      selector: (row) => {
        return (
          <>
            <div onClick={() => handleRowClick(row)} className="ms-2">
              <span className="contentSubtext_">
                {moment(row?.order_details[0]?.created_at).format("MM/DD/YYYY")}
              </span>
              {/* <p className='textInner mt-2'>{moment(row?.order_details[0]?.created_at).format("HH:mm")}</p> */}
            </div>
          </>
        );
      },
      width: "120px",
    },
    {
      selector: (row) =>
        row?.invoices?.invoice_number
          ? row?.invoices?.invoice_number
          : row?.return_detail?.invoices?.invoice_number,
      name: "Invoice No.",
      width: "120px",
    },
    {
      selector: (row) => row?.order_type,
      name: "Type",
      width: "110px",
    },
    {
      name: "Staff",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="d-flex align-items-center"
            >
              <img
                className="userInnerImg"
                src={
                  row?.seller_details?.profile_photo
                    ? row?.seller_details?.profile_photo
                    : profileImg1
                }
              />
              <div className="ms-2 text-start">
                <span className="contentSubtext_ ">
                  {row?.seller_details?.firstname +
                    " " +
                    row?.seller_details?.lastname}
                </span>
                <p className="mb-0">
                  <span className="textInner">POS Staff</span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "250px",
    },
    {
      selector: (row) =>
        "$" + (row?.tips ? Number(row?.tips)?.toFixed(2) : "0.00"),
      name: "Tips",
      width: "150px",
    },
    {
      selector: (row) =>
        "$" + (row?.tax ? Number(row?.tax)?.toFixed(2) : "0.00"),
      name: "Tax",
      width: "150px",
    },
    {
      name: "Total Amount",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="ms-2 tableBtn__"
            >
              <span className="contentSmallTxt">
                ${addThousandSeparator(Number(row?.payable_amount)?.toFixed(2))}
              </span>
            </div>
          </>
        );
      },
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="ms-2 tableBtn__"
            >
              {row?.is_returned_order ? (
                <Link to="#" className="commonbtndesign_ returnBtn_">
                  Returned
                </Link>
              ) : row?.status == 0 ? (
                <Link to="#" className="commonbtndesign_ viewDetailBtn_ ">
                  {row?.order_type === "product"
                    ? "Order to Review"
                    : "Service to Review"}
                </Link>
              ) : row?.status == 1 ? (
                <Link to="#" className="commonbtndesign_ acceptBtn_ ">
                  Accepted
                </Link>
              ) : row?.status == 2 ? (
                <Link to="#" className="commonbtndesign_ viewDetailBtn_ ">
                  {row?.order_type === "product"
                    ? "Order Preparing"
                    : "In progress"}
                </Link>
              ) : row?.status == 3 && row?.delivery_option != 4 ? (
                <Link to="#" className="commonbtndesign_ greenBtn_ ">
                  {row?.order_type === "product"
                    ? "Ready for Pickup"
                    : "In progress"}
                </Link>
              ) : row?.status == 3 && row?.delivery_option == 4 ? (
                <Link to="#" className="commonbtndesign_ greenBtn_ ">
                  Printing Label
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 1 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  Assign to Driver
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 2 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  In progress
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 3 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  Picked-up
                </Link>
              ) : row?.status == 4 && row?.delivery_option == 4 ? (
                <Link to="#" className=" commonbtndesign_ bundleBtn">
                  Shipped
                </Link>
              ) : row?.status == 5 ? (
                <Link to="#" className="commonbtndesign_ viewDetailBtn_">
                  {row?.order_type === "product" ? "Completed" : "Completed"}
                </Link>
              ) : row?.status == 6 && row?.delivery_option == 3 ? (
                <Link to="#" className="commonbtndesign_ deliverBtn_ mb-0">
                  Pickup by Customer
                </Link>
              ) : row?.status == 7 && row?.is_returned_order == true ? (
                <Link to="#" className="commonbtndesign_ cancelbtn_">
                  Refunded
                </Link>
              ) : row?.status == 7 && row?.is_returned_order == false ? (
                <Link to="#" className="commonbtndesign_ cancelbtn_">
                  Cancelled
                </Link>
              ) : row?.status == 8 ? (
                <Link to="#" className="commonbtndesign_ cancelbtn_">
                  Rejected
                </Link>
              ) : row?.status == 9 ? (
                <Link to="#" className="commonbtndesign_ returnBtn_">
                  Returned
                </Link>
              ) : (
                ""
              )}
            </div>
          </>
        );
      },

      width: "170px",
    },
    {
      name: "",
      selector: (row) => {
        return (
          <>
            <div className="d-flex justify-space-between align-items-center pe-3 ps-3">
              <div className="dropdown tableDropdown ms-3 ">
                <span
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="barMenus"
                >
                  <i className="fal fa-ellipsis-v icon_"></i>
                </span>
                <div
                  className="dropdown-menu tableDropdownMenus categoryDrop"
                  aria-labelledby="barMenus"
                >
                  <li
                    onClick={() => history.push(`/orderReview/${row?.id}`)}
                    className="dropdown-item"
                  >
                    View
                  </li>
                  {row?.label_url &&
                  !(row?.status == 3 && row?.delivery_option == 4) ? (
                    <li
                      onClick={() => {
                        setPrintingUrl(row?.label_url);
                        setModalDetail({ show: true, flag: "printLabel" });
                        setKey(Math.random());
                      }}
                      className="dropdown-item"
                    >
                      Print Label
                    </li>
                  ) : (
                    <></>
                  )}
                  {row?.status === 0 ? (
                    <>
                      <li
                        onClick={() => changeStatusOfOrderHandle(row?.id, 8)}
                        className="dropdown-item"
                      >
                        Decline Order
                      </li>
                      <li
                        onClick={
                          row?.delivery_option == 4
                            ? () => changeStatusOfOrderHandle(row?.id, 3)
                            : () => changeStatusOfOrderHandle(row?.id, 1)
                        }
                        className="dropdown-item"
                      >
                        Accept Order
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {row?.status == 3 && row?.delivery_option == 4 ? (
                    <li
                      onClick={() => {
                        setPrintingUrl(row?.label_url);
                        setModalDetail({ show: true, flag: "printLabel" });
                        setKey(Math.random());
                        changeStatusOfOrderHandle(orderData?.id, 4);
                      }}
                      className="dropdown-item"
                    >
                      Print Label
                    </li>
                  ) : (
                    <></>
                  )}
                  {row?.status !== 0 ? (
                    <li
                      onClick={() =>
                        trackingRouteHandle(
                          row?.status,
                          row?.delivery_option,
                          row?.id,
                          row?.tracking_info?.url
                        )
                      }
                      className="dropdown-item"
                    >
                      Track
                    </li>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      },
      width: "100px",
    },
    // {
    //     name: 'Action',
    //     selector: row => {
    //         return (
    //             <>
    //                 <div className='ms-2 tableBtn__'>
    //                     <button onClick={() => changeStatusOfOrderHandle(row?.id, 8)} className='declineBtn me-3' type="submit" disabled={loading1}>
    //                         {loading1 && newSelectedId == row?.id && (
    //                             <span className="spinner-border spinner-border-sm"></span>
    //                         )}
    //                         &nbsp;&nbsp;
    //                         <span>Decline</span>
    //                     </button>
    //                     <button onClick={() => changeStatusOfOrderHandle(row?.id, 1)} className='acceptBtn_ me-2' type="submit" disabled={loading2}>
    //                         {loading2 && newSelectedId == row?.id && (
    //                             <span className="spinner-border spinner-border-sm"></span>
    //                         )}
    //                         &nbsp;&nbsp;
    //                         <span>Accept</span>
    //                     </button>
    //                 </div>
    //             </>
    //         );
    //     },
    //     width: "150px"
    // },
  ];
  if (columnStatus?.invoiceNo) {
    columns1 = [
      ...columns1?.slice(0, columns1?.length - 5),
      {
        selector: (row) =>
          row?.invoices?.invoice_number
            ? row?.invoices?.invoice_number
            : row?.return_detail?.invoices?.invoice_number,
        name: "Invoice Number",
        width: "160px",
      },
      ...columns1.slice(columns1?.length - 5),
    ];
  }
  if (columnStatus?.user) {
    columns1 = [
      {
        name: "User",
        selector: (row) => {
          return (
            <>
              {row?.user_details ? (
                <div
                  onClick={() => handleRowClick(row)}
                  className="d-flex align-items-center"
                >
                  <img
                    className="userInnerImg"
                    src={
                      row?.user_details?.profile_photo
                        ? row?.user_details?.profile_photo
                        : profileImg1
                    }
                  />
                  <div className="ms-2 text-start">
                    <span className="contentSubtext_ ">
                      {row?.user_details?.firstname +
                        " " +
                        row?.user_details?.lastname}
                    </span>
                    <p className="mb-0">
                      <img src={locationBlue} className="img-fluid me-2" />
                      <span className="textInner">
                        {row?.user_details?.current_address?.city?.length > 50
                          ? row?.user_details?.current_address?.city?.substring(
                              0,
                              50
                            ) + "..."
                          : row?.user_details?.current_address?.city}
                        ,
                        {row?.user_details?.current_address?.country?.length >
                        10
                          ? row?.user_details?.current_address?.country?.substring(
                              0,
                              10
                            ) + "..."
                          : row?.user_details?.current_address?.country}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div>Not Available</div>
              )}
            </>
          );
        },
        width: "300px",
      },
      ...columns1,
    ];
  }
  if (columnStatus?.locations) {
    columns1 = [
      ...columns1?.slice(0, columns1?.length - 5),
      {
        selector: (row) => row?.seller_details?.current_address?.city,
        name: "Location",
      },
      ...columns1.slice(columns1?.length - 5),
    ];
  }
  if (columnStatus?.paymentMethod) {
    columns1 = [
      ...columns1?.slice(0, columns1?.length - 5),
      {
        selector: (row) =>
          row?.mode_of_payment === "card"
            ? "Card"
            : row?.mode_of_payment === "cash"
            ? "Cash"
            : row?.mode_of_payment === "jbr"
            ? "JBR Wallet"
            : "---",
        name: "Payment Method",
        width: "160px",
      },
      ...columns1.slice(columns1?.length - 5),
    ];
  }
  if (columnStatus?.delivery) {
    columns1 = [
      ...columns1?.slice(0, columns1?.length - 5),
      {
        name: "Delivery",
        selector: (row) => {
          return (
            <>
              {row?.delivery_details && row?.delivery_option == 1 ? (
                <div
                  onClick={() => handleRowClick(row)}
                  className="d-flex align-items-center"
                >
                  <img
                    className="userInnerImg"
                    src={
                      row?.driver_details?.profile_photo
                        ? row?.driver_details?.profile_photo
                        : profileImg1
                    }
                  />
                  <div className="ms-2 text-start">
                    <span className="contentSubtext_ ">
                      {row?.delivery_details?.title}
                    </span>
                    <p className="mb-0">
                      {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                      <span className="textInner">${row?.delivery_charge}</span>
                    </p>
                  </div>
                </div>
              ) : row?.shipping_details && row?.delivery_option == 4 ? (
                <div
                  onClick={() => handleRowClick(row)}
                  className="d-flex align-items-center"
                >
                  <img
                    className="userInnerImg"
                    src={
                      row?.shipping_details?.image
                        ? row?.shipping_details?.image
                        : profileImg1
                    }
                  />
                  <div className="ms-2 text-start">
                    <span className="contentSubtext_ ">
                      {row?.shipping_details?.title}
                    </span>
                    <p className="mb-0">
                      {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                      <span className="textInner">${row?.shipping_charge}</span>
                    </p>
                  </div>
                </div>
              ) : (
                <>No Delivery Info</>
              )}
            </>
          );
        },
        width: "200px",
      },
      ...columns1.slice(columns1?.length - 5),
    ];
  }
  if (columnStatus?.tracking) {
    columns1 = [
      ...columns1?.slice(0, columns1?.length - 5),
      {
        name: "Tracking",
        selector: (row) => {
          return (
            <>
              {row.tracking_number ? (
                <div onClick={() => handleRowClick(row)} className="ms-2">
                  <span className="contentSubtext_">
                    {row?.tracking_number}
                  </span>
                  <img className="tarckIconn_" src={manage} />
                </div>
              ) : (
                ""
              )}
            </>
          );
        },
        width: "200px",
      },
      ...columns1.slice(columns1?.length - 5),
    ];
  }
  if (columnStatus?.noOfItems) {
    columns1 = [
      ...columns1?.slice(0, columns1?.length - 5),
      {
        selector: (row) => row?.total_items,
        name: "No. of Items",
        width: "120px",
      },
      ...columns1.slice(columns1?.length - 5),
    ];
  }
  const handleRowClick = (e) => {
    history.push(`/orderReview/${e?.id}`);
  };

  const handleFilterValue = (e, value) => {
    if (value === "invoiceNo") {
      setColumnStatus({ ...columnStatus, invoiceNo: e.target.checked });
    }
    if (value === "locations") {
      setColumnStatus({ ...columnStatus, locations: e.target.checked });
    }
    if (value === "user") {
      setColumnStatus({ ...columnStatus, user: e.target.checked });
    }
    if (value === "paymentMethod") {
      setColumnStatus({ ...columnStatus, paymentMethod: e.target.checked });
    }
    if (value === "delivery") {
      setColumnStatus({ ...columnStatus, delivery: e.target.checked });
    }
    if (value === "tracking") {
      setColumnStatus({ ...columnStatus, tracking: e.target.checked });
    }
    if (value === "noOfItems") {
      setColumnStatus({ ...columnStatus, noOfItems: e.target.checked });
    }
  };

  const ExpandedComponent =
    (suppData) =>
    ({ data }) => {
      setSingleOrderData(data);
      return (
        <>
          <pre className="preaddBox">
            <div className="stockaAvailabilit">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="stoclLeft">
                    <span>Items Stock availability:</span>
                    <span className="allText">
                      All <img className="subtractImg" src={subtractImg} />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 text-end">
                  <div className="stoclRight">
                    <button
                      onClick={() => changeStatusOfOrderHandle(data?.id, 8)}
                      className="declineBtn me-3"
                      type="submit"
                      disabled={loading1}
                    >
                      {loading1 && newSelectedId && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Decline Order</span>
                    </button>
                    <button
                      onClick={() => changeStatusOfOrderHandle(data?.id, 1)}
                      className="acceptBtn_ me-2"
                      type="submit"
                      disabled={loading2}
                    >
                      {loading2 && newSelectedId && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Accept Order</span>
                    </button>
                    <button
                      className="viewDetailBtn_"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "PendingInvoiceModal",
                        });
                        setKey(Math.random());
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </pre>
        </>
      );
    };

  useEffect(() => {
    getAllOrdersHandle();
  }, [endDate, perPage, currentPage, orderFilter, statusFilter, paymentFilter]);

  useEffect(() => {
    if (keyword == "" || keyword?.length > 2) {
      getAllOrdersHandle();
    }
  }, [keyword]);

  return (
    <>
      <section className="newOrder">
        {seller_id ? (
          <></>
        ) : (
          <DashboardNavbar
            title="All Orders"
            setKeyword={setKeyword}
            backShow={`/myOrders`}
          />
        )}
        <div className=" productList productModuleList">
          <div className="tableSectionBackground">
            <div className="tableFihandleBrandDataChangelters tableFilters">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-11">
                    <div className="productMainCategory">
                      <div className="categroyBox dateCategory">
                        <div className="calenderIcon campCalendar">
                          <img
                            src={calendar}
                            alt="calender"
                            className="campcalendarImg"
                          />
                          {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                          <DatePicker
                            className="cateDate"
                            selected={startDate}
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            placeholderText="Date"
                          />
                          <img
                            src={OrderDown}
                            alt="calender"
                            className="calenderDownIcon"
                          />
                        </div>
                      </div>
                      <div className="categroyBox BoxMediaCategory">
                        <select
                          className="cateSelectdFilter "
                          value={orderFilter}
                          required
                          onChange={(e) => handleOrderFilter(e)}
                        >
                          <option value="" selected>
                            All Orders
                          </option>
                          <option value="1">Delivery</option>
                          <option value="3">In-Store</option>
                          <option value="4">Shipping</option>
                          <option value="0">Pending Orders</option>
                        </select>
                      </div>
                      <div className="categroyBox BoxMediaCategory">
                        <select
                          className="cateSelectdFilter"
                          value={statusFilter}
                          required
                          onChange={(e) => handleStatusFilter(e)}
                        >
                          <option value="" selected>
                            All Status
                          </option>
                          <option value="0"> Orders to Review</option>
                          <option value="1"> Accepted</option>
                          <option value="2"> Order Prepairing</option>
                          <option value="printing"> Printing Label</option>
                          <option value="4"> Assign to Driver</option>
                          <option value="5,6"> Delivered</option>
                          <option value="7"> Cancelled</option>
                          <option value="8"> Rejected</option>
                          <option value="9"> Returned</option>
                        </select>
                      </div>
                      {/* <div className='categroyBox'>
                                                <select className="cateSelectdFilter" required >
                                                    <option value="" selected>
                                                        All Locations
                                                    </option>
                                                    <option>FL 1</option>
                                                    <option>FL 2</option>
                                                </select>
                                            </div> */}
                      <div className="categroyBox BoxMediaCategory">
                        <select
                          className="cateSelectdFilter"
                          required
                          onChange={(e) => handlePaymentFilter(e)}
                        >
                          <option value="" selected>
                            All Payment
                          </option>
                          {/* <option value="jbr">JOBR Wallet</option> */}
                          <option value="cash">Cash</option>
                          <option value="card">Cards</option>
                          <option value="card">Gift Cards</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <div className="dropdown weekDropdown orderDrop">
                      <img
                        style={{ cursor: "pointer" }}
                        src={addPlusBtn}
                        alt=""
                        className="img-fluid plusDropImg ms-2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      />
                      {/* <button className="downloadbtn_ ms-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"><span className='plusIcon_'><i className="fa-solid fa-plus"></i> </span></button> */}
                      <ul className="dropdown-menu categoryDrop">
                        <li className="categoryFlex tablesubHead ">
                          <input
                            className="me-2"
                            type="checkbox"
                            id="columnStatus"
                            checked={columnStatus?.upc}
                            onChange={(e) => handleFilterValue(e, "invoiceNo")}
                          />
                          <label className="tablesubHead " for="view1">
                            Invoice No.
                          </label>
                        </li>
                        <li className="categoryFlex ">
                          <input
                            className="me-2"
                            type="checkbox"
                            id="columnStatus"
                            checked={columnStatus?.price}
                            onChange={(e) => handleFilterValue(e, "locations")}
                          />
                          <label className="tablesubHead " for="view1">
                            Locations
                          </label>
                        </li>
                        <li className="categoryFlex ">
                          <input
                            className="me-2"
                            type="checkbox"
                            id="columnStatus"
                            checked={columnStatus?.category}
                            onChange={(e) => handleFilterValue(e, "user")}
                          />
                          <label className="tablesubHead " for="view1">
                            User
                          </label>
                        </li>
                        <li className="categoryFlex ">
                          <input
                            className="me-2"
                            type="checkbox"
                            id="columnStatus"
                            checked={columnStatus?.subCategory}
                            onChange={(e) =>
                              handleFilterValue(e, "paymentMethod")
                            }
                          />
                          <label className="tablesubHead " for="view1">
                            Payment Method
                          </label>
                        </li>
                        <li className="categoryFlex ">
                          <input
                            className="me-2"
                            type="checkbox"
                            id="columnStatus"
                            checked={columnStatus?.brand}
                            onChange={(e) => handleFilterValue(e, "delivery")}
                          />
                          <label className="tablesubHead " for="view1">
                            Delivery
                          </label>
                        </li>
                        <li className="categoryFlex ">
                          <input
                            className="me-2"
                            type="checkbox"
                            id="columnStatus"
                            checked={columnStatus?.stock}
                            onChange={(e) => handleFilterValue(e, "tracking")}
                          />
                          <label className="tablesubHead " for="view1">
                            Tracking
                          </label>
                        </li>
                        <li className="categoryFlex ">
                          <input
                            className="me-2"
                            type="checkbox"
                            id="columnStatus"
                            checked={columnStatus?.availableToSell}
                            onChange={(e) => handleFilterValue(e, "noOfItems")}
                          />
                          <label className="tablesubHead " for="view1">
                            No. of Items
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    Country
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    State
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    Id type
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* {
                    loading === true ?
                        // <CustomLoader />
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                        : */}
          <section className="table-responsive commonTableheight ">
            <DataTable
              pagination
              columns={columns1}
              data={orderData?.payload?.data}
              // expandableRows
              customStyles={customStyles}
              defaultSortFieldId={1}
              onRowClicked={handleRowClick}
              // expandableRowsComponent={ExpandedComponent("")}
              className="newOrderTable"
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              progressPending={loading}
              paginationServer
              // selectableRows
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationComponentOptions={paginationOptions}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </section>
        </div>
        {/* } */}
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "PendingInvoiceModal"
              ? "commonWidth customContent"
              : ""
          }
          ids={
            modalDetail.flag === "verifyOtp"
              ? "VerifyOtp"
              : modalDetail.flag === "PendingInvoiceModal"
              ? "pendinginvoiceModal"
              : ""
          }
          size={modalDetail.flag === "printLabel" ? "md" : "xl"}
          child={
            modalDetail.flag === "printLabel" ? (
              <PrintLabelModal
                printingUrl={printingUrl}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "PendingInvoiceModal" ? (
              <PendingInvoiceModal
                cancelLoading={loading1}
                acceptLoaDing={loading2}
                statusHandle={(a, b) => changeStatusOfOrderHandle(a, b)}
                setOrderData={setOrderData}
                singleOrderData={singleOrderData}
                close={() => handleOnCloseModal()}
              />
            ) : (
              ""
            )
          }
          header={
            modalDetail.flag === "printLabel" ? (
              <>
                <div className="headerLeft">
                  <h4 className="modalHeading_ me-3">Print Label</h4>
                </div>

                <p
                  style={{ cursor: "pointer" }}
                  onClick={handleOnCloseModal}
                  className="modal_cancel"
                >
                  <img
                    src={modalCancel}
                    className="ModalCancel"
                    alt="modalcancelImg"
                  />
                </p>
              </>
            ) : (
              <>
                <div className="modalHeader_">
                  <div className="common_">
                    {modalDetail.flag === "PendingInvoiceModal" ? (
                      <div className="anotherHeader">
                        <i
                          className="las la-angle-left"
                          onClick={() => handleOnCloseModal()}
                        ></i>
                        <h2 className="innerSubheadNormal">
                          Invoice#{singleOrderData?.invoice?.invoice_id}
                        </h2>
                        <span className="pendingStatus">
                          {singleOrderData?.status === 0
                            ? "Pending"
                            : singleOrderData?.status === 1
                            ? "Processing"
                            : singleOrderData?.status === 2
                            ? "Processing"
                            : singleOrderData?.status === 3
                            ? "Ready for Pickup"
                            : singleOrderData?.status === 4
                            ? "On the way"
                            : ""}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <button className="closeButton">
                      <i
                        className="las la-times"
                        onClick={() => handleOnCloseModal()}
                      ></i>
                    </button>
                  </div>
                </div>
              </>
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </section>
    </>
  );
};

export default AllOrder;
