import React, { useEffect, useState } from 'react'
import { getBankAccountApiAsync, getWalletBallanceApiAsync, issueSilaBalanceToYourWalletAsync } from '../dashboard/dashboardSlice'
import { selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const AddBalanceModal = (props) => {
    const [bankDetails, setBankDetails] = useState([]);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [checkedState, setCheckedState] = useState(false);
    const [checkedValue, setCheckedValue] = useState("")
    const [amount, setAmount] = useState("")
    const [buttonLoading, setButtonLoading] = useState(false);
    const auth = useSelector(selectLoginAuth)

    const handleChange = (accountName) => {
        setCheckedState(prev => !prev)
        if (checkedState === false) {
            setCheckedValue(accountName)
        } else {
            setCheckedValue("")
        }
    }

    const handleSubmit = () => {
        if (!checkedValue) {
            toast.error("Please Select Bank Account")
            return false
        }
        if (!amount) {
            toast.error("Please Add Amount to Add to Your Wallet")
            return false
        }
        let params = {
            postData: {
                "amount": amount,
                "account_name": checkedValue
            },
            tokenData: auth?.payload?.token
        }
        setButtonLoading(true)
        dispatch(issueSilaBalanceToYourWalletAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let data = {
                    tokenData: auth?.payload?.token
                }
                dispatch(getWalletBallanceApiAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        props.details("PickPlan")
                        // setWalletBalance(obj?.payload?.sila_balance)
                        setButtonLoading(false)
                    })
                    .catch((obj) => {
                        setButtonLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setButtonLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        dispatch(getBankAccountApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setBankDetails(obj?.payload)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }, [])

    return (
        <>
            {
                loading === true ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className='addDriverModal'>
                        <div className='verifyModal'>
                            {/* <h4 className='innerHeading mb-2'>Add balance to your Wallet</h4> */}
                            <div className="mb-4 otp__ d-flex justify-content-center">
                                {bankDetails?.map((val, index) => {
                                    return (
                                        <div className='accountDetails' key={index}>
                                            <div className="form-group" >
                                                <input
                                                    type="checkbox"
                                                    id={`custom-checkbox1-${index}`}
                                                    name="group"
                                                    value={val?.id}
                                                    onChange={() => handleChange(val?.account_name)}
                                                />
                                                <label htmlFor={`custom-checkbox1-${index}`}></label>
                                            </div>
                                            <h4 className="textInnerDark mb-1">A/c No. - <span className='textinner_'>{val?.account_number}</span></h4>
                                            <h4 className="textInnerDark ">A/c Holder Name: <span className='textinner_'>{val?.account_owner_name}</span></h4>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="mb-4 otp__ d-flex justify-content-center">
                                <input type="number" value={amount} onChange={(e) => setAmount(e?.target?.value)} />
                            </div>
                            <div className='ps-4 pe-4'>
                                <button className='loginBtn w-100 '
                                    onClick={() => handleSubmit()}
                                    disabled={buttonLoading}
                                >
                                    {buttonLoading === true && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Pay now</span>
                                </button>
                                {/* <button className='loginBtn w-100 '>
                                    Pay Now
                                </button> */}
                            </div>
                            <p className='innerSubtext mt-5'>
                                {/* <OtpTimer
                        //minutes={1}
                        seconds={60}
                        key={Math.random()}
                        //seconds={otpTimerPeriod}
                        //text="Resend OTP in:"
                        ButtonText="Resend"
                        resend={sendOtp}
                    /> */}
                            </p>
                        </div>
                    </div>}
        </>
    )
}

export default AddBalanceModal
