import React, { useState } from 'react'
import { Calendardate, calendertime } from '../../../../utilities/images'
import moment from 'moment-timezone'

const AppointmentRequest = (props) => {
    const [appointmentId, setAppointmentId] = useState("");
    return (
        <div className="calenderRight">
            {props.data?.length > 0 ?
                props?.data?.map((v, i) => {
                    return (
                        <div key={i} className="calenderappointment">
                            <h4>Request for <strong>{v?.product_name}</strong>
                            </h4>
                            <div className='calenderDetail'>
                                <div className="calenderTime">
                                    <img src={calendertime} alt="" />
                                    <span>Time : <strong>{v?.start_time + "-" + v?.end_time}</strong>
                                    </span>
                                </div>
                                <div className="calenderTime">
                                    <img src={Calendardate} alt="" />
                                    <span>Date : <strong>{moment(v?.date).format("MMMM DD, YYYY")}</strong>
                                    </span>
                                </div>
                            </div>
                            <div className="calenderbtn">
                                <button onClick={() => {props.statusChange(v?.id, 1, "newRequest");setAppointmentId(v?.id)}} className='completeStatus me-3' type="submit" disabled={props.stateLoading2}>
                                    {v?.id === appointmentId && props.stateLoading2 && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    &nbsp;&nbsp;
                                    <span>Accept</span>
                                </button>
                                <button onClick={() => {props.statusChange(v?.id, 3, 'newRequest');setAppointmentId(v?.id)}} className='viewDetailBtn_' type="submit" disabled={props.stateLoading1}>
                                    {v?.id === appointmentId && props.stateLoading1 && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    &nbsp;&nbsp;
                                    <span>No</span>
                                </button>
                            </div>
                        </div>
                    )
                }) : <>No new appointments</>
            }
        </div>
    )
}

export default AppointmentRequest