import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import {
  calendar,
  user,
  locationBlue,
  manage,
  moneyIcon,
  profileImg1,
} from "../../../../utilities/images";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import CustomModal from "../../../components/shared/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAllOrdersApiAsync } from "../../dashboard/dashboardSlice";
import CustomLoader from "../../../components/shared/CustomLoader";
import PendingInvoiceModal from "../orders/pendingInvoiceModal";

const CompleteOrders = () => {
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const [key, setKey] = useState(Math.random());
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [orderData, setOrderData] = useState({});
  const [singleOrderData, setSingleOrderData] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };
  const handleRowClick = (e) => {
    setSingleOrderData(e);
    setModalDetail({ show: true, flag: "CompleteorderModal" });
    setKey(Math.random());
  };
  const columns1 = [
    {
      name: "#",
      width: "70px",
      // selector: row => row?.id
      selector: (row, i) => i + 1 * (perPage * (currentPage - 1)) + 1,
    },
    {
      name: "User",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="d-flex align-items-center"
            >
              <img
                className="userInnerImg"
                src={
                  row?.user_details?.profile_photo
                    ? row?.user_details?.profile_photo
                    : profileImg1
                }
              />
              <div className="ms-2 text-start">
                <span className="contentSubtext_ ">
                  {row?.user_details?.firstname +
                    " " +
                    row?.user_details?.lastname}
                </span>
                <p className="mb-0">
                  <img src={locationBlue} className="img-fluid me-2" />
                  <span className="textInner">
                    {row?.user_details?.current_address?.city?.length > 50
                      ? row?.user_details?.current_address?.city?.substring(
                          0,
                          50
                        ) + "..."
                      : row?.user_details?.current_address?.city}
                    ,
                    {row?.user_details?.current_address?.country?.length > 10
                      ? row?.user_details?.current_address?.country?.substring(
                          0,
                          10
                        ) + "..."
                      : row?.user_details?.current_address?.country}
                  </span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "300px",
    },
    {
      selector: (row) => row?.invoices?.invoice_number,
      name: "Invoice Number",
      width: "160px",
    },
    {
      name: "Date",
      selector: (row) => {
        return (
          <>
            <div onClick={() => handleRowClick(row)} className="ms-2">
              <span className="contentSubtext_">
                {moment(row?.order_details[0]?.created_at).format(
                  "MMM DD, YYYY"
                )}
              </span>
              <p className="textInner mt-2">
                {moment(row?.order_details[0]?.created_at).format("HH:mm")}
              </p>
            </div>
          </>
        );
      },
      width: "120px",
    },
    {
      selector: (row) => row?.order_details[0]?.qty,
      name: "Quantity",
    },
    {
      name: "Payment Info",
      selector: (row) =>
        row?.mode_of_payment === "card"
          ? "Card"
          : row?.mode_of_payment === "cash"
          ? "Cash"
          : row?.mode_of_payment === "jbr"
          ? "JBR Wallet"
          : "---",
      width: "200px",
    },
    {
      name: "Delivery Info",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="d-flex align-items-center"
            >
              {/* <img className='userInnerImg' src={row?.driver_details?.profile_photo ? row?.driver_details?.profile_photo : profileImg1} /> */}
              <div className="ms-2 text-start">
                <span className="contentSubtext_ ">
                  {row?.delivery_details?.title}
                </span>
                <p className="mb-0">
                  {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                  <span className="textInner">${row?.delivery_charge}</span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "200px",
    },
    {
      name: "Tracker",
      selector: (row) => {
        return (
          <>
            {row?.tracking_number ? (
              <div onClick={() => handleRowClick(row)} className="ms-2">
                <span className="contentSubtext_">{row?.tracking_number}</span>
                <img className="tarckIconn_" src={manage} />
              </div>
            ) : (
              ""
            )}
          </>
        );
      },
      width: "200px",
    },
    {
      name: "Amount",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="ms-2 tableBtn__"
            >
              <span className="contentSmallTxt">${row?.payable_amount}</span>
            </div>
          </>
        );
      },
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="ms-2 tableBtn__"
            >
              <Link
                to="#"
                className="completeStatus"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Delivered{" "}
              </Link>
              {/* <i style={{cursor:"pointer"}} className="fal fa-chevron-right ms-3" aria-hidden="true" onClick={() => {
                                setSingleOrderData(row)
                                setModalDetail({ show: true, flag: "CompleteorderModal" });
                                setKey(Math.random());
                            }} ></i> */}
            </div>
          </>
        );
      },
      width: "170px",
    },
  ];

  const getAllOrdersHandle = () => {
    let params = {
      postData: {
        status: "5,6",
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        page: currentPage,
        limit: perPage,
        order_type: "product",
      },
      tokenData: auth?.payload?.token,
    };

    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(getAllOrdersApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setOrderData(obj);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllOrdersHandle();
  }, [endDate, perPage, currentPage]);

  return (
    <div className="completeOrders_">
      <div className="topHeader_">
        <div className="topStickyHeader_">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="mainHeading">Completed Orders</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="tableFilters">
        <div className="row">
          <div className="col-md-12">
            <div className="calenderIcon">
              <img src={calendar} alt="calender" />
              {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                            dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
              <DatePicker
                className="cateDate"
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Date"
              />
            </div>
          </div>
          {/* <div className='col-md-3'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            Country
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            State
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            Id type
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div> */}
        </div>
      </div>
      {/* {
                loading === true ?
                    // <CustomLoader />
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                    : */}
      <section className="table-responsive">
        <DataTable
          pagination
          columns={columns1}
          data={orderData?.payload?.data}
          defaultSortFieldId={1}
          customStyles={customStyles}
          onRowClicked={handleRowClick}
          className="newOrderTable"
          progressPending={loading}
          paginationServer
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </section>
      {/* } */}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "CompleteorderModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "CompleteorderModal" ? "completeorderModal" : ""
        }
        child={
          modalDetail.flag === "CompleteorderModal" ? (
            <PendingInvoiceModal
              singleOrderData={singleOrderData}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          <>
            <div className="modalHeader_">
              <div className="common_">
                {modalDetail.flag === "CompleteorderModal" ? (
                  <div className="anotherHeader">
                    <i
                      className="las la-angle-left"
                      onClick={() => handleOnCloseModal()}
                    ></i>
                    <h2 className="innerSubheadNormal">
                      Invoice#{singleOrderData?.invoices?.invoice_number}
                    </h2>
                    <span className="completeStatus">Delivered</span>
                  </div>
                ) : (
                  ""
                )}
                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default CompleteOrders;
