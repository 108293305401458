import React from 'react'
import { user, shield, redMapimg, shareMap, calendar } from '../../../../utilities/images'
import DatePicker from "react-datepicker";
import moment from 'moment-timezone'
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import products from "../../../db/category.json"
import { Link, useHistory } from 'react-router-dom';
import CustomModal from '../../../components/shared/CustomModal';
import WalletpaymentModal from './walletpaymentModal';


const WalletPayment = () => {
    const [key, setKey] = useState(Math.random());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const [modalDetail, setModalDetail] = useState({
      show: false,
      title: "",
      flag: "",
    });
    const handleOnCloseModal = () => {
      setModalDetail({
        show: false,
        title: "",
        flag: "",
      });
      setKey(Math.random());
    };
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const handleRowClick = (e) => {
        // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    }
    const columns1 = [
        {
            name: '#',
            width: "50px",
            selector: (row, i) => i + 1
        },
        {
            name: "Date",
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>Jun 21,2022</span>
                        <span className='prop_span'>13:21</span>
                    </>
                );
            },
            width: "250px",
        },
        {
            name: 'Payment To',
            selector: row => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <img className='userInnerImg' src={user} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ '>{row.name}</span>
                                <p className='mb-0'>
                                    <img src={shield} className='shild_img img-fluid ' />
                                    <span className='tablesubHead'>260 101 480 0083 </span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "250px",
        },
        {
            name: 'Amount',
            selector: row => {
                return (
                    <>
                        <span className='textParagh me-2'>JBR 126.08</span>
                    </>
                );
            },
        },
        {
            name: 'Transaction type',
            selector: row => {
                return (
                    <>
                        <span className='textParagh'>Bank Fees</span>
                    </>
                );
            },
            width: "200px",
        },
        {
            name: 'Transaction id',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>12466998</span>
                    </>
                );
            },
            width: "250px",
        },


        {
            selector: row => {
                return (
                    <>
                        <div className='ms-2 tableBtn__'>
                            <Link to="#" className='innerBtn_'>Completed</Link>
                            <button className='nextAction' type="text" onClick={() => {
                    setModalDetail({ show: true, flag: "WalletpaymentModal" });
                    setKey(Math.random());
                  }}><i className="fal fa-chevron-right ms-3" ></i></button>
                        </div>
                    </>
                );
            },
            name: '',
            width: "150px"
        },

    ];
    return (
        <div className='walletPayment'>
            <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>Wallet / <span className="innerSubtext">Payments</span> </span>
                            </h2>
                        </div>

                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-12 text-end'>
                    <div className="dropdown weekDropdown">
                        <button className="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            This week <i className="fa-solid fa-angle-down"></i>
                        </button>
                        <ul className="dropdown-menu weekDropdownMenus">
                            <li><a className="dropdown-item">This week</a></li>
                            <li><a className="dropdown-item">Last Week</a></li>
                            <li><a className="dropdown-item">This Month</a></li>
                            <li><a className="dropdown-item">This Quarter </a></li>
                            <li><a className="dropdown-item">This Year</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="availableBlnc">
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='leftAvailblnc'>
                            <h5 className='validText mb-3'>Available Balance</h5>
                            <h4 className='blacktextHead'>$4,500.26</h4>
                        </div>

                    </div>
                    <div className='col-md-6'>
                        <div className='leftAvailblnc'>
                            <div className='row'>
                                <div className='col-md-6 col-lg-6 customSpce mb-2'>
                                    <div className='percentageContainer justify-content-between align-items-center'>
                                        <div className='percentageValues'>
                                            <h3 className='mainHeadingsub_ '>10,029</h3>
                                            <p className='textinner_'>Total JBR In</p>
                                        </div>
                                        <div className='percentageRating mapbg'>
                                            <img src={shareMap} className="" alt='ShareMap' />
                                            <p className='percentageRate'>1.2%</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6 customSpce'>
                                    <div className='percentageContainer justify-content-between align-items-center'>
                                        <div className='percentageValues'>
                                            <h3 className='mainHeadingsub_ '>10,029</h3>
                                            <p className='textinner_'>Total JBR Out</p>
                                        </div>
                                        <div className='percentageRating mapbg'>
                                            <img src={shareMap} className="" alt='ShareMap' />
                                            <p className='percentageRate'>1.2%</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6 customSpce mb-2'>
                                    <div className='percentageContainer justify-content-between align-items-center'>
                                        <div className='percentageValues'>
                                            <h3 className='mainHeadingsub_ '>10,029</h3>
                                            <p className='textinner_'>Total USD In</p>
                                        </div>
                                        <div className='percentageRating mapbg'>
                                            <img src={shareMap} className="" alt='ShareMap' />
                                            <p className='percentageRate'>1.2%</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6 customSpce'>
                                    <div className='percentageContainer justify-content-between align-items-center'>
                                        <div className='percentageValues'>
                                            <h3 className='mainHeadingsub_ '>10,029</h3>
                                            <p className='textinner_'>Total USD Out</p>
                                        </div>
                                        <div className='percentageRating redRating mapbg'>
                                            <img src={redMapimg} className="" alt='ShareMap' />
                                            <p className='percentageRate'>1.2%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Start TableFilter Area */}
            <div className='tableFilters mb-3 mt-4'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="calenderIcon">
                            <img src={calendar} alt="calender" />
                            {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                            <DatePicker
                                className='cateDate'
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText="Date"
                            />
                        </div>
                    </div>
                    {/* <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Country
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                State
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                ID type
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div> */}
                </div>
            </div>
            {/* End TableFilter Area */}
            {/* Start DataTable */}
            <div className='commonTableheight table-responsive'>
            <DataTable
                pagination
                columns={columns1}
                data={products}
                defaultSortFieldId={1}
                onRowClicked={handleRowClick}
                className='transection_datatable'
            />
            </div>
            {/* End DataTable */}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "WalletpaymentModal" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "WalletpaymentModal" ? "walletpaymentModal" : ""}
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            <h2 className="modalHeading">
                            Payment
                            </h2>
                            <button className="closeButton">
                                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                            </button>
                        </div>
                    </div>
                }
                child={
                    modalDetail.flag === "WalletpaymentModal" ? (
                        <WalletpaymentModal close={() => handleOnCloseModal()} />
                    ) : (
                        ""
                    )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default WalletPayment