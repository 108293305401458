import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendar_light } from '../../../../utilities/images';
import { selectLoginAuth, } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sendOfferAsync } from '../messageSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

const MakeofferModal2 = (props) => {

    const selectedproduct = props?.selectedproduct;
    const [startDate, setStartDate] = useState(new Date());
    const [offerOneCarton, setOfferOneCarton] = useState();
    const [offerOnePack, setOfferOnePack] = useState();
    const [minimumOrderOneCarton, setMinimumOrderOneCarton] = useState();
    const [minimumOrderOnePack, setMinimumOrderOnePack] = useState();
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch()



    const sendOffer = (e) => {
        e.preventDefault();
        if (!offerOneCarton) {
            toast.error("please create offer one carton")
            return false;
        }
        else if (!minimumOrderOneCarton) {
            toast.error("please create minimum order of one carton")
            return false;
        }
        // else if (!offerOnePack) {
        //     toast.error("please create offer one pack")
        //     return false;
        // }
        // else if (!minimumOrderOnePack) {
        //     toast.error("please create minimum order of one pack")
        //     return false;
        // }
        else {
            let params = {
                tokenData: auth?.payload?.token,
                postData: {
                    category_id: String(selectedproduct?.category_id),
                    sub_category_id: selectedproduct?.sub_category_id ? String(selectedproduct?.sub_category_id) : "0",
                    brand_id: String(selectedproduct?.brand_id),
                    product_id: String(selectedproduct?.id),
                    actual_price_per_carton: String(selectedproduct?.price),
                    actual_price_per_pack: "10",
                    offer_price_per_carton: offerOneCarton,
                    offer_price_per_pack: "0",
                    min_order_per_carton: minimumOrderOneCarton,
                    min_order_per_pack: "0",
                    offer_validity: moment(startDate).format('YYYY-MM-DD')
                }

            }

            setLoading(true)
            dispatch(sendOfferAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    let offerId = obj.payload.id
                    setLoading(false)
                    props.offerTwo(offerId, "offer");
                    props.close();
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        }
    }

    return (
        <div className='makeodderBody_'>
            <form className='makeofferDetail horizontalForms' onSubmit={(e) => { sendOffer(e) }}>
                <div className='selectedProduct_ mb-4'>
                    <h5 className='textInner mb-3'>Selected Product:</h5>
                    <div className='row'>
                        <div className='col-md-7'>
                            <div className='productImage_'>
                                <img className='productimg_' src={selectedproduct?.image} />
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='productContent'>
                                <div className='productList'>
                                    <h5 className='textInner mb-1'>Product name:</h5>
                                    <h6 className='textinner_'>{selectedproduct?.name}</h6>
                                </div>
                                <div className='productList'>
                                    <h5 className='textInner mb-1'>Brand:</h5>
                                    <h6 className='textinner_'>{selectedproduct?.brand?.name}</h6>
                                </div>
                                <div className='productList'>
                                    <h5 className='textInner mb-1'>Sub-category:</h5>
                                    <h6 className='textinner_'>{selectedproduct?.sub_category?.name}</h6>
                                </div>
                                <div className='productList'>
                                    <h5 className='textInner mb-1'>Category:</h5>
                                    <h6 className='textinner_'>{selectedproduct?.category?.name}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='offerpriceBox_ mb-4'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='currentPrice_'>
                                <h5 className='textInner mb-3'>Current Price</h5>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='currentPrice_'>
                                <h5 className='textInner mb-3'>Offering Price</h5>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='currentPrice_ mb-3'>
                                <h5 className='textInner mb-3'>Minimum order</h5>
                            </div>
                        </div>
                    </div>
                    {selectedproduct?.supplies[0]?.supply_prices.map((item, index) => {
                        return (
                            <div className='row' key={index}>
                                <div className='col-md-4'>
                                    <div className='currentPrice_'>
                                        <div className='piceDetail mb-3'>
                                            <h5 className='priceHead_'>$ {item?.selling_price} </h5>
                                            <p className='textinner_'>{item?.price_type === "fixed" ? "1 carton" : `1 pack of ${item?.min_qty}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='currentPrice_'>
                                        <div className='inputPrice mb-4'>
                                            <span className='dollarSign'>$</span>
                                            <input type="text" className='priceInput' value={offerOneCarton} onChange={(e) => { setOfferOneCarton(e.target.value) }} />
                                            <span className='pricegrey'>{item?.price_type === "fixed" ? "1 carton" : "1 Pack"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='currentPrice_ mb-3'>
                                        <div className='inputPrice mb-4'>
                                            <input type="text" className='priceInput' value={minimumOrderOneCarton} onChange={(e) => { setMinimumOrderOneCarton(e.target.value) }} />
                                            <span className='pricegrey'>{item?.price_type === "fixed" ? "1 carton" : "1 Pack"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='offerValidity'>
                    <h5 className='validText'>Offrer Validity </h5>
                    <hr></hr>
                    <div className='selectDate'>
                        <label className='subtextDark_ mb-3 me-3'>Select Date</label>
                        <img className='calanderImgg' src={calendar_light} />
                        <DatePicker
                            className="customform-control"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            minDate={new Date()} />
                    </div>
                </div>
                <div className='col-md-12 mt-4'>
                    <div className='offerbutton'>
                        <button className='sendOfferBtn' onClick={props.close}>Back</button>
                        <button className='staticBtn' type='submit' >Send Offer</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default MakeofferModal2