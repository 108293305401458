import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  calendar,
  shareMap,
  productList1,
  productList2,
  productList3,
  productList4,
  downlight,
  service,
} from "../../../../utilities/images";
import DatePicker from "react-datepicker";
import ConsumerList from "./consumerList";
import ConsumerView from "./consumerView";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressApiAsync, selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomersApiAsync,
  getConsumerDataApiAsync,
  getServicesAsync,
} from "../../dashboard/dashboardSlice";
import DashboardLayout from "../../../components/shared/DashboardLayout";
import DashboardNavbar from "../../dashboard/DashboardNavbar";

const Consumer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [keyword, setKeyword] = useState("")
  const [endDate, setEndDate] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [addressId, setAddressId] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [services, setServices] = useState([]);
  const [servicesId, setServicesId] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("This week");
  const auth = useSelector(selectLoginAuth);
  const [CountData, setCountData] = useState([]);
  const [consumerData, setConsumerData] = useState([]);
  const [invitedData, setInvitedData] = useState([]);
  const [customerType, setCustomerType] = useState(
    location?.state?.customerType ?? ""
  );
  const [invitedType, setInvitedType] = useState(location?.state ?? "");
  const handleDateChange = (dates) => {
    setFilterValue("");
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleFilterValue = (value) => {
    setStartDate(null);
    setEndDate(null);
    setFilterValue(value);
  };

  const getConsumerData = () => {
    let params;
    if (filterValue) {
      params = {
        postData: {
          page: currentPage,
          limit: perPage,
          search: keyword || undefined,
          filter: "year",
          // filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
          seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          type: customerType,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (servicesId) {
      params = {
        postData: {
          ...params.postData,
          service_id: servicesId,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (addressId) {
      params = {
        postData: {
          ...params.postData,
          seller_address_id: addressId,
        },
        tokenData: auth?.payload?.token,
      };
    }
    setLoading(true);
    dispatch(getAllCustomersApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setConsumerData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAllAddress(obj?.payload);
      })
      .catch((obj) => {});
  };
  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        // type: "product",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getServices();
    getAllAddress();
  }, []);

  useEffect(() => {
    if (customerType) {
      getConsumerData();
    }
  }, [
    currentPage,
    perPage,
    filterValue,
    endDate,
    customerType,
    addressId,
    servicesId,
  ]);

  useEffect(() => {
    if (keyword?.length > 2 || keyword === "") {
      getConsumerData();
    }
  }, [keyword]);

  return (
    <>
      <DashboardNavbar title="Customer" backShow={"/userList"} setKeyword={setKeyword} />
      <div className="consumerList_">
        {/* <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>Users / <span className="innerSubtext">Consumer</span> </span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div> */}
        {/* <div className="row mb-3">
          <div className="col-md-12 text-end">
            <div className="dropdown weekDropdown">
              <button
                className="dropdown-toggle cateSelectd"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {filterValue ? filterValue : "Select Filter"}{" "}
                <img src={downlight} alt="img" />
              </button>
              <ul className="dropdown-menu weekDropdownMenus">
                <li>
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleFilterValue("Today")}
                  >
                    Today
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleFilterValue("This week")}
                  >
                    This week
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleFilterValue("Month")}
                  >
                    {" "}
                    Month
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleFilterValue("Year")}
                  >
                    {" "}
                    Year
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}

        {/* <div className='verificationTotal_'>
                <div className='row'>
                    {
                        CountData?.length > 0 ?
                            CountData?.map((v, i) => {
                                return (
                                    <div key={i} className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                                        <div className='percentageContainer justify-content-between align-items-center'>
                                            <div className='percentageValues'>
                                                <h3 className='mainHeadingsub_ '>{v?.type === 'Total Payment' ? `$${v?.total}` : v?.total}</h3>
                                                <p className='textinner_'>{v?.type}</p>
                                            </div>
                                            <div className='percentageRating mapbg'>
                                                <img src={shareMap} className="" alt='ShareMap' />
                                                <p className='percentageRate'>{v?.analytics?.toFixed(2)}%</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <></>
                    }
                </div>
            </div> */}

        {/* 
              <div className='chartsOuter mt-4 mb-4'>
                    <div className='consumerTabs_'>
                        <span className={activeData == "newUsers" ? "userTabs_ active" : "userTabs_"} style={{ cursor: "pointer" }} onClick={() => setActiveData("newUsers")}>New User</span>{" "}
                        <span className={activeData == "activeUsers" ? "userTabs_ active" : "userTabs_"} style={{ cursor: "pointer" }} onClick={() => setActiveData("activeUsers")}>Active User</span>{" "}
                        <span className={activeData == "eventCount" ? "userTabs_ active" : "userTabs_"} style={{ cursor: "pointer" }} onClick={() => setActiveData("eventCount")} >Event Count</span>
                    </div>
                    {
                       
                            <div className='conversionLocate'>
                                <ChartArea classes="productSales mt-2 mb-2" header="Services Sales" options={options1} data={serviceSalesDataNewUser} chartType="Line" />
                            </div>
                            : activeData == "activeUsers" ?
                                <div className='conversionLocate'>
                                    <ChartArea classes="productSales mt-2 mb-2" header="Services Sales" options={options1} data={serviceSalesDataActiveUser} chartType="Line" />
                                </div>
                                : <div className='conversionLocate'>
                                    <ChartArea classes="productSales mt-2 mb-2" header="Services Sales" options={options1} data={serviceSalesDataEvent} chartType="Line" />
                                </div>
                    }
                </div>  */}

        <div className="verificationTotal">
          <div className="row mt-4">
            <div className="col-lg-12 d-flex justify-content-start">
              <ul className="weekNav">
                <li
                  onClick={() => {
                    setCustomerType("all_customers");
                    setInvitedType("");
                  }}
                >
                  <Link
                    className={`weekMenu ${
                      customerType === "all_customers" ? "active" : ""
                    }`}
                  >
                    All Customers
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setCustomerType("new_customers");
                    setInvitedType("");
                  }}
                >
                  <Link
                    className={`weekMenu ${
                      customerType === "new_customers" ? "active" : ""
                    }`}
                  >
                    New Customers
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setCustomerType("returning_customers");
                    setInvitedType("");
                  }}
                >
                  <Link
                    className={`weekMenu ${
                      customerType === "returning_customers" ? "active" : ""
                    }`}
                  >
                    Returning Customers
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setCustomerType("online_customers");
                    setInvitedType("");
                  }}
                >
                  <Link
                    className={`weekMenu ${
                      customerType === "online_customers" ? "active" : ""
                    }`}
                  >
                    Online Customers
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setCustomerType("walkin_customers");
                    setInvitedType("");
                  }}
                >
                  <Link
                    className={`weekMenu ${
                      customerType === "walkin_customers" ? "active" : ""
                    }`}
                  >
                    Walking Customers
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setCustomerType("invited_customers");
                    setInvitedType("");
                  }}
                >
                  <Link
                    className={`weekMenu ${
                      customerType === "invited_customers" ? "active" : ""
                    }`}
                  >
                    Invited Customers
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="tableSectionBackground">
          <div className="tableFihandleBrandDataChangelters tableFilters mt-4 mb-3">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="productMainCategory">
                    {/* <div className="categroyBox">
                      <select
                        className="cateSelectdFilter"
                        value={servicesId}
                        onChange={(e) => setServicesId(e.target.value)}
                      >
                        <option value="" selected>
                          All Category
                        </option>
                        {services?.map((val, index) => {
                          return (
                            <option value={val?.value} key={index}>
                              {val?.label}
                            </option>
                          );
                        })}
                      </select>
                    </div> */}
                    <div className="categroyBox">
                      {/* <select className="cateSelectdFilter" required>
                        <option value="" selected>
                          All location
                        </option>
                        <option>Location1</option>
                        <option>Location2</option>
                      </select> */}
                      <select
                        autoComplete="new-password"
                        className="customform-control  selectControl form-select"
                        value={addressId}
                        onChange={(e) => setAddressId(e.target.value)}
                      >
                        <option value="" selected>
                          All location
                        </option>
                        {allAddress?.map((val, index) => {
                          return (
                            <option value={val?.id} key={index}>
                              {val?.format_address}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-12">
                  <div className="productListTab  productListBtn">
                    <button
                      className={`plansBtns ${
                        activeTab === "MonthlyBilling" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("MonthlyBilling")}
                    >
                      <img
                        src={productList1}
                        alt="image"
                        className="showImage"
                      />
                      <img
                        src={productList2}
                        alt="image"
                        className="hideImage"
                      />
                    </button>
                    <button
                      className={`plansBtns ${
                        activeTab === "AnnuallyBilling" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("AnnuallyBilling")}
                    >
                      <img
                        src={productList3}
                        alt="image"
                        className="hideImage"
                      />
                      <img
                        src={productList4}
                        alt="image"
                        className="showImage"
                      />
                    </button>
                  </div>
                </div> */}

                {/* <div className='col-md-12'>
                                    <div className="calenderIcon">
                                        <img src={calendar} alt="calender" />
                                         <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> 
                                        <DatePicker
                                            className='cateDate'
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            placeholderText="Date"
                                        />
                                    </div>
                                </div> */}
                {/* <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Country
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                State
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Id type
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div> */}
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="plansAreasContainer">
              <ConsumerList
                loading={loading}
                data={consumerData}
                totalRows={totalRows}
                perPage={perPage}
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Consumer;
