import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CustomModal from "../../../components/shared/CustomModal";
import { usa, language } from "../../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserSettingsApiAsync,
  updateUserSettingAsync,
} from "../systemConfigSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../auth/authSlice";
import { toast } from "react-toastify";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import LanguagesChangeStatusModal from "./languagesChangeStatusModal";
import AddlanguageModal from "./addlanguageModal";

const Languages = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [index, setIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [languagesData, setLanguagesData] = useState([]);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [key, setKey] = useState(Math.random());
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  const handleCheckboxChange = (index, status) => {
    languagesData[index]["status"] = status;
    console.log(languagesData, "language data");
    const newData = languagesData?.map((item) => {
      const { lang_code, ...rest } = item;
      return rest;
    });
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        app_name: "pos",
        languages: newData,
      },
    };
    setButtonLoading(true);
    dispatch(updateUserSettingAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setButtonLoading(false);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        handleOnCloseModal();
        getLanguagesData();
      })
      .catch((obj) => {
        setButtonLoading(false);
      });
  };

  const getLanguagesData = () => {
    let data = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        app_name: "pos",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserSettingsApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLanguagesData(obj?.payload?.languages);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLanguagesData();
  }, []);

  return (
    <>
      <div className="languages">
        <DashboardNavbar title="Languages" show={true} />
        {loading ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <div className="paymentContainer">
            <div className="paymentArea">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex align-items-start">
                    <img src={language} className="paymentTypeIcon me-3" />
                    <div className="w-100">
                      <h3 className="textInnerDark">Published languages</h3>
                      <p className="subtext_ fw-normal mt-3 mb-3">
                        Active in the markets they've been added to and visible
                        to customers.
                      </p>

                      {languagesData?.length > 0 ? (
                        <>
                          {languagesData?.map((item, index) => {
                            return (
                              <div className="businessDetails languageCheck" key={index}>
                                <div className="row">
                                  <div className="col-md-10">
                                    <div className="d-flex align-items-center">
                                      <span className="IconPin me-3">
                                        <img
                                          src={item?.flag}
                                          alt="LocationPin"
                                        />
                                      </span>
                                      <div className="">
                                        <h4 className="itemsText">
                                          {item?.language}{" "}
                                        </h4>
                                        <div className="">
                                          {item?.language === "English" ? (
                                            <p className="descriptionPara_ mt-1">
                                              Default{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 text-end">
                                    <div className="form-check form-switch ">
                                      <input
                                        type="checkbox"
                                        checked={item?.status === 1}
                                        onChange={() => {
                                          if (
                                            item?.language === "English" &&
                                            item?.country === "United States" &&
                                            item?.lang_code === "en" &&
                                            item?.status === 1
                                          ) {
                                            return;
                                          }
                                          setIndex(index);
                                          setModalDetail({
                                            show: true,
                                            flag: "languagesChangeStatus",
                                          });
                                          setKey(Math.random());
                                          setSelectedLanguage(item);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="ms-5">No Languages Found</div>
                      )}

                      <div className="addlanguage_ mt-4 pt-2">
                        <button
                          className="addlangueBtn_"
                          onClick={() => {
                            handleUserProfile("addlanguageModal");
                          }}
                        >
                          {" "}
                          <span>Add language</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "languagesChangeStatus" ? "customContent" : ""
        }
        ids={
          modalDetail.flag === "languagesChangeStatus" ? "operatingCountry" : ""
        }
        size={modalDetail.flag === "languagesChangeStatus" ? "md" : "lg"}
        child={
          modalDetail.flag === "languagesChangeStatus" ? (
            <LanguagesChangeStatusModal
              buttonLoading={buttonLoading}
              index={index}
              close={() => handleOnCloseModal()}
              selectedLanguage={selectedLanguage}
              handleChange={(index, status) =>
                handleCheckboxChange(index, status)
              }
            />
          ) : modalDetail.flag === "addlanguageModal" ? (
            <AddlanguageModal
              close={() => handleOnCloseModal()}
              getAllLanguageList={getLanguagesData}
              getSelectedLanguages={languagesData}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "addlanguageModal" ? (
            <>
              <div className="d-block"> 
                <h4 className="appointMain m-0">Add a language </h4>
                <p className="activateText mt-1">
                  Select one or more languages to add
                </p>
              </div>
            </>
          ) : (
            <div className="modalHeader_">
              <div className="common_">
                <h2 className="modalHeading">
                  {selectedLanguage?.status === 0 ? "Add" : "Remove"} language
                </h2>
                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Languages;
