import React, { useEffect, useState } from 'react'
import { addIcon } from '../../../utilities/images'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const CustomPrice = (props) => {
    const costPrices = props?.costPrice ? props?.costPrice : "";
    const location = useLocation()
    const userData = location.state;
    const customPriceList = []
    const b2bcustom = userData?.supplies[0]?.supply_prices.forEach((item)=>{if(item.app_name ==='b2b' && item.price_type !=='fixed'){
        customPriceList.push({min_qty: item?.min_qty, max_qty: item?.max_qty, selling_price: item.selling_price, margin_percentage: item.margin_percentage })
    }})
    
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const [inputList, setInputList] = useState(customPriceList.length>0? customPriceList: [{ min_qty: "", max_qty: "", selling_price: "", margin_percentage: "" }]);
    //   console.log("inputList",inputList);


    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        if (name === 'selling_price') {
            const sellingPrice = parseFloat(value);
            const costPrice = parseFloat(costPrices); // Assuming costPrices is a numeric value
            const marginPercentage = ((sellingPrice - costPrice) / costPrice) * 100;
            list[index]['margin_percentage'] = isNaN(marginPercentage) ? '' : marginPercentage.toFixed(2);
        }
        if (name === 'margin_percentage') {
            const marginPercentage = parseFloat(value);
            const costPrice = parseFloat(costPrices); // Assuming costPrices is a numeric value
            const sellingPriceValue = (costPrice + (costPrice * (marginPercentage) / 100));
            list[index]['selling_price'] = isNaN(sellingPriceValue) ? '' : sellingPriceValue.toFixed(2);
        }
        setInputList(list);
        props.customPriceBtob(list);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { min_qty: "", max_qty: "", selling_price: "", margin_percentage: "" }]);
    };

    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        props.customPriceBtob(list);
    };

    useEffect(() => {
        const list = inputList.map(item => {
          const sellingPrice = parseFloat(item.selling_price);
          const costPrice = parseFloat(costPrices);
          const marginPercentage = ((sellingPrice - costPrice) / costPrice) * 100;
          return {
            ...item,
            margin_percentage: isNaN(marginPercentage) ? '' : marginPercentage.toFixed(2),
          };
        });
        setInputList(list);
      }, [costPrices]);


    return (
        <div className='customPrice'>
            <form>
                {inputList.map((item, i) => {
                    return (
                        <div className='outerArea mb-4' key={i}>
                            {i ?
                                <span className='removeSelect' onClick={() => handleRemoveClick(i)} ><i className="fas fa-times"></i></span>
                                : ""}
                            <div className='row mb-4'>
                                <label className="deatilsHead mb-2 fw-normal">Selling Quantity
                                </label>
                                <div className='form-group col-md-6'>
                                    <div className='inputGroup'>
                                        <input
                                            min="0"
                                            onKeyDown={blockInvalidChar}
                                            className="customform-control"
                                            placeholder="Min QTY"
                                            name="min_qty"
                                            type="number"
                                            value={item.min_qty}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group col-md-6'>
                                    <div className='inputGroup'>
                                        <input
                                            min="0"
                                            onKeyDown={blockInvalidChar}
                                            className="customform-control"
                                            placeholder="Max QTY"
                                            name="max_qty"
                                            type="number"
                                            value={item.max_qty}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="deatilsHead mb-2 fw-normal">Selling Price
                                </label>
                                <div className='form-group col-md-6'>
                                    <div className='inputGroup'>
                                        <input
                                            min="1"
                                            onKeyDown={blockInvalidChar}
                                            className="customform-control"
                                            placeholder="Selling Price"
                                            name="selling_price"
                                            type="number"
                                            value={item.selling_price}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group col-md-6'>
                                    <div className='inputGroup'>
                                        <input
                                        // disabled
                                            min="1"
                                            onKeyDown={blockInvalidChar}
                                            className="customform-control"
                                            placeholder="margin_percentage"
                                            name="margin_percentage"
                                            type="number"
                                            value={item.margin_percentage}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                        <span className='percentagesign_'>%</span>
                                    </div>
                                </div>
                            </div>
                        </div>  

                    )
                })}

                {/* <div className='outerArea'>
                <div className='row mb-4'>
                    <label className="deatilsHead mb-2 fw-normal">Selling Quantity
                    </label>
                    <div className='form-group col-md-6'>
                        <div className='inputGroup'>
                            <input
                                className="customform-control"
                                placeholder="Min QTY"
                                name="text"
                                type="text"
                                value=""
                            />
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <div className='inputGroup'>
                            <input
                                className="customform-control"
                                placeholder="Max QTY"
                                name="text"
                                type="text"
                                value=""
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <label className="deatilsHead mb-2 fw-normal">Selling Price
                    </label>
                    <div className='form-group col-md-6'>
                        <div className='inputGroup'>
                            <input
                                className="customform-control"
                                placeholder="Selling Price"
                                name="text"
                                type="text"
                                value=""
                            />
                        </div>
                    </div>
                    <div className='form-group col-md-6'>
                        <div className='inputGroup'>
                            <input
                                className="customform-control"
                                placeholder="margin_percentage"
                                name="text"
                                type="text"
                                value=""
                            />
                        </div>
                    </div>
                </div>    
            </div> */}
                <div className='row mt-5'>
                    <div className='col-md-12' onClick={handleAddClick}>
                        <Link>
                            <img src={addIcon} className='me-2' />
                            <span className='textinner_ fw-500'>Add another</span>
                        </Link>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CustomPrice