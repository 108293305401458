import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { redMapimg, shareMap } from "../../../utilities/images";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getAnalyticStatDataApiAsync } from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import ChartArea from "../dashboard/wallet/ChartArea";

const SalesReport = () => {
  const { flag } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [analyticData, setAnalyticData] = useState("");
  const [overviewData, setOverviewData] = useState("");
  const [loading, setLoading] = useState(false);

  const barData = {
    labels: analyticData?.graph_data?.labels,
    datasets: [
      {
        label: "sales",
        data: analyticData?.graph_data?.datasets
          ? analyticData?.graph_data?.datasets[0]?.data
          : "",
        borderColor: "#1FB3FF",
        backgroundColor: "#1FB3FF",
      },
    ],
  };
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const statDataHandle = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        filter: flag === "grossPayment" ? "year" : "week",
        is_admin: true,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getAnalyticStatDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        let newData =
          flag === "sales"
            ? obj?.payload?.revenue
            : flag === "goodsCost"
            ? obj?.payload?.cost
            : flag === "grossPayment"
            ? obj?.payload?.sale
            : flag === "grossProfit"
            ? obj?.payload?.profit
            : flag === "totalSales"
            ? obj?.payload?.revenue
            : "";
        setAnalyticData(newData);
        setOverviewData(obj?.payload?.overView);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    statDataHandle();
  }, []);

  return (
    <div className="salesReport">
      <div className="topHeader_">
        <div className="productTopStickyHeader_ mb-3">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="innerSubheadNormal">
                <span onClick={() => history.push("/stats")}>
                  <i className="las la-angle-left me-3 directionArrow"></i>
                </span>
                <span>
                  {flag === "sales"
                    ? "Sales Report"
                    : flag === "goodsCost"
                    ? "Cost of goods sold"
                    : flag === "grossPayment"
                    ? "Gross payment by month"
                    : flag === "grossProfit"
                    ? "Gross Profit"
                    : flag === "totalSales"
                    ? "Total Sales"
                    : ""}
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="salesReportbox_ mt-4">
        <div className="container-fluid p-0">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="verificationTotal mt-5">
                {loading ? (
                  <span className="spinner-border inner-spin spinner-border-sm"></span>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-sm-6 col-md-3 col-lg-3 customSpce">
                        <div className="percentageContainer justify-content-between align-items-center">
                          <div className="percentageValues">
                            <h3 className="mainHeadingsub_ ">
                              $
                              {overviewData?.transaction
                                ? overviewData?.transaction?.toFixed(2)
                                : 0}
                            </h3>
                            <p className="textinner_">Gross Sales</p>
                          </div>
                          <div className="percentageRating mapbg">
                            <img src={shareMap} className="" alt="ShareMap" />
                            <p className="percentageRate">1.2%</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3 col-lg-3 customSpce">
                        <div className="percentageContainer justify-content-between align-items-center">
                          <div className="percentageValues">
                            <h3 className="mainHeadingsub_ ">
                              $
                              {overviewData?.returned
                                ? overviewData?.returned?.toFixed(2)
                                : 0}
                            </h3>
                            <p className="textinner_">Refund</p>
                          </div>
                          <div className="percentageRating mapbg">
                            <img src={shareMap} className="" alt="ShareMap" />
                            <p className="percentageRate">1.2%</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3 col-lg-3 customSpce">
                        <div className="percentageContainer justify-content-between align-items-center">
                          <div className="percentageValues">
                            <h3 className="mainHeadingsub_ ">
                              $
                              {overviewData?.sale
                                ? overviewData?.sale?.toFixed(2)
                                : 0}
                            </h3>
                            <p className="textinner_">Net Sales</p>
                          </div>
                          <div className="percentageRating mapbg">
                            <img src={shareMap} className="" alt="ShareMap" />
                            <p className="percentageRate">1.2%</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3 col-lg-3 customSpce">
                        <div className="percentageContainer justify-content-between align-items-center">
                          <div className="percentageValues">
                            <h3 className="mainHeadingsub_ ">
                              $
                              {overviewData?.profit_sum
                                ? overviewData?.profit_sum?.toFixed(2)
                                : 0}
                            </h3>
                            <p className="textinner_">Gross Profit</p>
                          </div>
                          <div className="percentageRating mapbg">
                            <img src={shareMap} className="" alt="ShareMap" />
                            <p className="percentageRate">1.2%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tradesValue mt-4 mb-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="chartsOuter">
                            {/* <h3 className="textInnerHead mt-3">Sales</h3> */}
                            {/* <div className='processStatus mt-3'>
                                                                <p className='statusbox_'><span className='complete_'></span> Sales</p>
                                                            </div> */}
                            <ChartArea
                              classes="productSales mt-2 mb-2"
                              header=""
                              options={options1}
                              data={barData}
                              chartType="Bar"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReport;
