import { toast } from 'react-toastify';

function formValidator(obj, activePos, activeBtob, activeBtoc) {
    let hasEmptyValues = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            const obj = arr[i];
            for (let key in obj) {
                if (obj.hasOwnProperty(key) && obj[key] === '') {
                    return true; // Empty value found
                }
            }
        }
        return false; // No empty values found
    }

    let filterB2B = obj?.prices?.filter((val) => (val?.price_type == "quantity_base"))
    let resultB2B = filterB2B?.length > 0 ? hasEmptyValues(filterB2B[0]?.bundle_price) : ""

    let priceResult = obj?.prices?.length > 0 ? hasEmptyValues(obj?.prices) : ""


    console.log(obj, "obj responseeeee")


    if (!obj?.image) {
        toast.error("Please select service images")
        return false
    }
    if (!obj?.name) {
        toast.error("Please enter service name")
        return false
    }
    if (obj?.service_id == "Select Services") {
        toast.error("Please select service")
        return false
    }
    if (obj?.category_id == "Select Category") {
        toast.error("Please select catgeory")
        return false
    }
    if (obj?.sub_category_id == "Select Sub-Category") {
        toast.error("Please select sub-category")
        return false
    }
    // if (!obj?.brand_id) {
    //     toast.error("Please select brand")
    //     return false
    // }
    if (!obj?.description) {
        toast.error("Please enter service description")
        return false
    }
    if (!obj?.price) {
        toast.error("Please enter service price")
        return false
    }
    if (!obj?.approx_service_time) {
        toast.error("Please enter approx service time")
        return false
    }
    if (!obj?.type) {
        toast.error("Please enter service type")
        return false
    }
    // if(!obj?.barcode){
    //     toast.error("Please enter barcode")
    //     return false
    // }
    // if(!obj?.sku){
    //     toast.error("Please enter sku")
    //     return false
    // }
    if (!activePos && !activeBtob && !activeBtoc) {
        toast.error("Please select product availability")
        return false
    }
    if (obj?.prices?.length == 0 || priceResult) {
        toast.error("Please add selling price and profit margin")
        return false
    }
    if (filterB2B?.length > 0 && resultB2B) {
        toast.error("Please add selling quantity and selling price")
        return false
    }
    if (obj?.pos_staff_ids?.length <= 0) {
        toast.error("Please select an pos user")
        return false
    }
    if(obj?.min_capacity == "Min") {
        toast.error("Please select minimum capacity")
        return false
    }
    if(obj?.max_capacity == "Max") {
        toast.error("Please select maximum capacity")
        return false
    }
    if(Number(obj?.min_capacity) > Number(obj?.max_capacity)) {
        toast.error("Minimum capacity cannot be greater than maximum capacity")
        return false
    }
    if(obj?.pos_staff_ids?.length <=0){
        toast.error("Please select an Pos user")
        return false
    }
    return true
}
export { formValidator };