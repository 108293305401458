import React, { useEffect, useState } from 'react'
import { tobacorec, dots, tobaco } from '../../../utilities/images'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../auth/authSlice'
import DataTable from 'react-data-table-component'
import { getMyProductStatisticDataAsync } from './productSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const CategoryTableview = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { flag, serviceType } = useParams();
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(Math.random());

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }
  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
  };
  const columns1 = [
    {
      selector: row => {
        return (
          <>

            <div className='productViewCard mb-4' onClick={() => {
              let id = ""
              flag === "category" ? id = row?.category_id : flag === "sub_category" ? id = row?.sub_category_id : id = row?.brand_id
              history.push(`/categoryDetail/${flag}/${serviceType}/${id}`)
            }} >
              <img src={row.image} alt="image" className='img-fluid productImage_' />
              <div className='productDetials'>
                <div className='productViewHead'>
                  <h1 className='productHeading'>{row.category_name} </h1>
                  <img src={dots} alt="icon" />
                </div>
                <ul className='productCategory'>
                  <li>
                    <p className='subtextSmall_'>Sub-Category Listed</p>
                    <p className='subtextSmall_'>{row?.sub_category_listed}</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Brand Listed</p>
                    <p className='subtextSmall_'>{row?.brand}</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Product Listed</p>
                    <p className='subtextSmall_'>{row?.product_listed}</p>
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const columns2 = [
    {
      selector: row => {
        return (
          <>
            <div className='productViewCard mb-4' onClick={() => {
              let id = ""
              flag === "category" ? id = row?.category_id : flag === "sub_category" ? id = row?.sub_category_id : id = row?.brand_id
              history.push(`/categoryDetail/${flag}/${serviceType}/${id}`)
            }} >
              <img src={row.image} alt="image" className='img-fluid productImage_' />
              <div className='productDetials'>
                <div className='productViewHead'>
                  <h1 className='productHeading'>{row.sub_category_name} </h1>
                  <img src={dots} alt="icon" />
                </div>
                <ul className='productCategory'>
                  <li>
                    <p className='subtextSmall_'>Category</p>
                    <p className='subtextSmall_'>{row?.category_name}</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Brand Listed</p>
                    <p className='subtextSmall_'>{row?.brand}</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Product Listed</p>
                    <p className='subtextSmall_'>{row?.product_listed}</p>
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const columns3 = [
    {
      selector: row => {
        return (
          <>
            <div className='productViewCard mb-4' onClick={() => {
              let id = ""
              flag === "category" ? id = row?.category_id : flag === "sub_category" ? id = row?.sub_category_id : id = row?.id
              history.push(`/categoryDetail/${flag}/${serviceType}/${id}`)
            }} >
              <img src={row?.image} alt="image" className='img-fluid productImage_' />
              <div className='productDetials'>
                <div className='productViewHead'>
                  <h1 className='productHeading'>{row?.name} </h1>
                  <img src={dots} alt="icon" />
                </div>
                <ul className='productCategory'>
                  <li>
                    <p className='subtextSmall_'>Category</p>
                    <p className='subtextSmall_'>{row?.category_name}</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Sub Category</p>
                    <p className='subtextSmall_'>{row?.subcategory_name}</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Product Listed</p>
                    <p className='subtextSmall_'>{row?.product_count}</p>
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const handleRowClick = (e) => {
    let id = ""
    flag === "category" ? id = e?.category_id : flag === "sub_category" ? id = e?.sub_category_id : id = e?.brand_id
    history.push(`/categoryDetail/${flag}/${serviceType}/${id}`)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const getCategoryList = (page) => {
    setLoading(true)
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        type: flag,
        // app_name: "merchant",
        page: page ? page : currentPage,
        limit: perPage,
        filter: "year",
        service_type: serviceType
      }
    }
    dispatch(getMyProductStatisticDataAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setCategoryList(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getCategoryList();
  }, [currentPage, perPage, flag, serviceType]);

  return (
    <div className='brandListmain_'>
      <section className='productListView mt-3'>
        <div className='row'>
          <DataTable
            className='columsGrid'
            columns={flag === "category" ? columns1 : flag === "sub_category" ? columns2 : columns3}
            data={categoryList}
            progressPending={loading}
            // defaultSortFieldId={1}
            customStyles={customStyles}
            onRowClicked={(e) => handleRowClick(e)}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationPerPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
          {/* <div className='col-md-3'>
            <div className='productViewCard ' onClick={() => { history.push("/categoryDetail") }} >
              <img src={tobacorec} alt="image" className='img-fluid' />
              <div className='productDetials'>
                <div className='productViewHead'>
                  <h1 className='productHeading'> Tobacco</h1>
                  <img src={dots} alt="icon" />
                </div>
                <ul className='productCategory'>
                  <li>
                    <p className='subtextSmall_'>Sub-Category Listed</p>
                    <p className='subtextSmall_'>4</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Brand listed</p>
                    <p className='subtextSmall_'>15</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Product listed</p>
                    <p className='subtextSmall_'>19</p>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='productViewCard'>
              <img src={tobacorec} alt="image" className='img-fluid' />
              <div className='productDetials'>
                <div className='productViewHead'>
                  <h1 className='productHeading'>Tobacco </h1>
                  <img src={dots} alt="icon" />
                </div>
                <ul className='productCategory'>
                  <li>
                    <p className='subtextSmall_'>Sub-Category Listed</p>
                    <p className='subtextSmall_'>4</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Brand listed</p>
                    <p className='subtextSmall_'>15</p>
                  </li>
                  <li>
                    <p className='subtextSmall_'>Product listed</p>
                    <p className='subtextSmall_'>19</p>
                  </li>

                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </div>

  )
}

export default CategoryTableview