import React from "react";

const AuthLayout = (props) => {
  return (
    <>
      <main className="main-wrapper">{props?.children}</main>
    </>
  );
};

export default AuthLayout;
