import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { selectLoginAuth } from '../../auth/authSlice'
import { checkKycAsync, requestKycAsync } from '../dashboardSlice'
import { kycWatch } from "../../../../utilities/images"

const RequestKYC = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [kycRequest, setKycRequest] = useState(false)
    const toastId = React.useRef(null)
    const auth = useSelector(selectLoginAuth)

    const handleCheckKyc = async () => {
        await dispatch(checkKycAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success("Kyc Done Successfully")
                }
                history.push('/IDVerification')
                setKycRequest(obj.payload)
            }
            )
            .catch((obj) => {
            })
    }

    const handleRequestKyc = async () => {
        await dispatch(requestKycAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setTimeout(handleCheckKyc, 1000)
            }
            )
            .catch((obj) => {
                setTimeout(handleCheckKyc, 1000)
            })
    }

    useEffect(() => {
        handleRequestKyc();
    }, [])
    return (
        <div className='requestKYC'>
            {/* Start FixedTopHeader */}
            <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>Request KYC</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* End FixedTopHeader */}
            <div className='backContainer'>
                <div className='row justify-content-center'>
                    <div className='col-lg-5'>
                        <div className='verifyKyc_'>
                            <div className='verifyTop_'>
                                <h3 className="subheading_ mb-3">Verification in progress</h3>
                                {/* <p className='text'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p> */}
                            </div>
                            <div className='middleVerify_'>
                            <img className='kycWtachh_' src={kycWatch} />
                                {/* <p className='text'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p> */}
                            </div>
                            <p className='blueTextt_'>Sit Back! We'll notify you</p>
                        </div>
                    </div>
                </div>
                {/* <p className='text'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </p>
                <button className='secoundaryBtn d-flex ms-auto' onClick={handleRequestKyc}>Request KYC</button>
                <p className='text'>
                    Verification may take a few minutest, so make sure to refreh and check your status
                </p>
                <div className='d-flex mt-5 justify-content-between align-items-center'>
                    <h3 className='innerSubtext'>KYC Review Status</h3>
                    <Link to='#' className='linkTxt' onClick={handleCheckKyc}>
                        <i className="fas fa-sync-alt me-2"></i>
                        <b>Refresh Status</b>
                    </Link>

                </div>
                <p className='mainlightHead mt-3 d-flex justify-content-end'>Status passed</p>
                <div className='row'>
                    <div className='col-md-4'>
                        <button className='primary_btn' disabled={!kycRequest} onClick={() => history.push('/IDVerification')}>Continue</button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default RequestKYC