import React from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  user,
  shield,
  locationBlue,
  shareMap,
  profileImg1,
} from "../../../../utilities/images";
import products from "../../../db/category.json";

const ConsumerList = (props) => {
  const history = useHistory();
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handlePageChange = (page) => {
    props.setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    props.setPerPage(currentRowsPerPage);
    props.setCurrentPage(1);
  };
  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const columns1 = [
    {
      name: "#",
      width: "70px",
      selector: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => {
        return (
          <>
            {row?.user_details ? (
              <div
                onClick={() => handleRowClick(row)}
                className="d-flex align-items-center"
              >
                <img
                  className="userInnerImg"
                  src={row?.user_details?.profile_photo ?? profileImg1}
                  alt=""
                />
                <div className="ms-2">
                  <span className="contentSubtext_  fw-500">
                    {row?.user_details?.firstname +
                      " " +
                      row?.user_details?.lastname}
                  </span>
                  {row?.user_details?.current_address?.city ? (
                    <p className="mb-0">
                      <span className="textInner fw-500">
                        {" "}
                        {row?.user_details?.current_address?.city ?? " "},{" "}
                        {row?.user_details?.zipcode ?? " "}{" "}
                      </span>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : row?.invitation_details?.firstname && row?.invitation_id ? (
              <div
                onClick={() => handleRowClick(row)}
                className="d-flex align-items-center"
              >
                <img
                  className="userInnerImg"
                  src={row?.invitation_details?.profile_photo ?? profileImg1}
                  alt=""
                />
                <div className="ms-2">
                  <span className="contentSubtext_  fw-500">
                    {row?.invitation_details?.firstname +
                      " " +
                      row?.invitation_details?.lastname}
                  </span>
                  {row?.invitation_details?.current_address?.city ? (
                    <p className="mb-0">
                      <span className="textInner fw-500">
                        {" "}
                        {row?.invitation_details?.current_address?.city ??
                          " "}, {row?.invitation_details?.zipcode ?? " "}{" "}
                      </span>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">Not Available</div>
            )}
          </>
        );
      },
      width: "300px",
    },
    {
      selector: (row) =>
        row?.user_details?.phone_number
          ? row?.user_details?.phone_number
          : row?.invitation_details?.full_phone_number
          ? row?.invitation_details?.full_phone_number
          : "--",
      name: "Registered Phone",
    },
    {
      name: "Total Orders",
      selector: (row) => row?.total_orders,
    },
    {
      name: "Total Products",
      selector: (row) => row?.total_products,
    },
    {
      name: "Lifetime Spent",
      selector: (row) =>
        "$" + addThousandSeparator(row?.life_time_spent?.toFixed(2)),
    },
  ];
  const handleRowClick = (e) => {
    history.push(`/consumerProfile`, e);
  };
  return (
    <section className="recentTransections mt-1 table-responsive">
      {/* <h3 className='textInnerHead mb-4'>Recent Orders</h3> */}
      <DataTable
        pagination
        columns={columns1}
        data={props?.data}
        customStyles={customStyles}
        defaultSortFieldId={1}
        onRowClicked={handleRowClick}
        progressPending={props.loading}
        paginationServer
        paginationTotalRows={props.totalRows}
        paginationPerPage={props.perPage}
        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </section>
  );
};

export default ConsumerList;
