import React, { useEffect, useState } from 'react'
import { locationPin } from '../../../../../utilities/images'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectAddressList, selectLoginAuth } from '../../../auth/authSlice'
import { updateSellerInfoApiAsync } from '../../dashboardSlice'
import { toast } from 'react-toastify'
import { unwrapResult } from '@reduxjs/toolkit'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import TimePicker from 'react-time-picker';
import moment from 'moment-timezone'
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { dayList, options } from '../../../../../utilities/helpers'

const LocalPickup = () => {
    const location = useLocation();
    const history = useHistory()
    const { addressId } = useParams()
    const [isActive, setIsActive] = useState(false)
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    const [pickupTime, setPickupTime] = useState("1 hours")
    const [pickupInstruction, setPickupInstruction] = useState("")
    const addresslist = useSelector(selectAddressList)
    const [businessHours, setBusinessHours] = useState([])
    const [startMondayTime, setStartMondayTime] = useState("");
    const [endMondayTime, setEndMondayTime] = useState('');
    const [startTuesddayTime, setStartTuesdayTime] = useState("");
    const [endTuesdayTime, setEndTuesdayTime] = useState('');
    const [startWednesdayTime, setStartWednesdayTime] = useState("");
    const [endWednesdayTime, setEndWednesdayTime] = useState('');
    const [startThursdayTime, setStartThursdayTime] = useState("");
    const [endThursdayTime, setEndThursdayTime] = useState('');
    const [startFridayTime, setStartFridayTime] = useState("");
    const [endFridayTime, setEndFridayTime] = useState('');
    const [startSaturdayTime, setStartSaturdayTime] = useState("");
    const [endSaturdayTime, setEndSaturdayTime] = useState('');
    const [startSundayTime, setStartSundayTime] = useState("");
    const [endSundayTime, setEndSundayTime] = useState('');
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const toastId = React.useRef(null)

    const [selectedOptions, setSelectedOptions] = useState([]);

    let selectedOptionNew = []

    const handleCheckboxChange1 = (option, flag) => {
        if (selectedOptions.find(val => val?.id == option?.id)) {
            selectedOptionNew = selectedOptions.filter((item) => item?.id !== option?.id)
            setSelectedOptions(selectedOptionNew);
        } else {
            selectedOptionNew = [...selectedOptions, option]
            setSelectedOptions([...selectedOptions, option]);
        }

        let startTime = (flag == 1) ? startMondayTime :
            (flag == 2) ? startTuesddayTime :
                (flag == 3) ? startWednesdayTime :
                    (flag == 4) ? startThursdayTime :
                        (flag == 5) ? startFridayTime :
                            (flag == 6) ? startSaturdayTime :
                                startSundayTime

        let endTime = (flag == 1) ? endMondayTime :
            (flag == 2) ? endTuesdayTime :
                (flag == 3) ? endWednesdayTime :
                    (flag == 4) ? endThursdayTime :
                        (flag == 5) ? endFridayTime :
                            (flag == 6) ? endSaturdayTime :
                                endSundayTime

        if (selectedOptionNew.find((day) => day.id == 1)) {
            setStartMondayTime(startTime);
            setEndMondayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 2)) {
            setStartTuesdayTime(startTime);
            setEndTuesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 3)) {
            setStartWednesdayTime(startTime);
            setEndWednesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 4)) {
            setStartThursdayTime(startTime);
            setEndThursdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 5)) {
            setStartFridayTime(startTime);
            setEndFridayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 6)) {
            setStartSaturdayTime(startTime);
            setEndSaturdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 7)) {
            setStartSundayTime(startTime);
            setEndSundayTime(endTime)
        }
    };

    function convertTo24HourFormat(time12Hour) {
        // Split the input time string into hours and minutes
        const [time, period] = time12Hour.split(' ');
        const [hours, minutes] = time.split(':');

        // Convert hours to an integer
        let hours24Hour = parseInt(hours, 10);

        // Adjust hours based on AM or PM
        if (period === 'PM' && hours24Hour !== 12) {
            hours24Hour += 12;
        } else if (period === 'AM' && hours24Hour === 12) {
            hours24Hour = 0;
        }

        // Format hours and minutes with leading zeros
        const formattedHours = hours24Hour.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');

        // Combine the hours and minutes into the 24-hour format
        const time24Hour = `${formattedHours}:${formattedMinutes}`;
        return time24Hour;
    }
    const filterData = () => {
        let filterResult = addresslist?.payload?.local_pickup?.find(val => val?.id == addressId)
        setAddress(filterResult)
        setPickupInstruction(filterResult?.pickup_instructions)
        setPickupTime(filterResult?.pickup_time ? filterResult?.pickup_time : "1 hours")
        setStartMondayTime(filterResult?.seller_availability?.availability[0]?.opening_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[0]?.opening_time) : filterResult?.seller_availability?.availability[0]?.opening_time)
        setEndMondayTime(filterResult?.seller_availability?.availability[0]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[0]?.closing_time) : filterResult?.seller_availability?.availability[0]?.closing_time)
        setStartTuesdayTime(filterResult?.seller_availability?.availability[1]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[1]?.opening_time) : filterResult?.seller_availability?.availability[1]?.closing_time)
        setEndTuesdayTime(filterResult?.seller_availability?.availability[1]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[1]?.closing_time) : filterResult?.seller_availability?.availability[1]?.closing_time)
        setStartWednesdayTime(filterResult?.seller_availability?.availability[2]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[2]?.opening_time) : filterResult?.seller_availability?.availability[2]?.closing_time)
        setEndWednesdayTime(filterResult?.seller_availability?.availability[2]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[2]?.closing_time) : filterResult?.seller_availability?.availability[2]?.closing_time)
        setStartThursdayTime(filterResult?.seller_availability?.availability[3]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[3]?.opening_time) : filterResult?.seller_availability?.availability[3]?.closing_time)
        setEndThursdayTime(filterResult?.seller_availability?.availability[4]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[3]?.closing_time) : filterResult?.seller_availability?.availability[3]?.closing_time)
        setStartFridayTime(filterResult?.seller_availability?.availability[4]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[4]?.opening_time) : filterResult?.seller_availability?.availability[4]?.closing_time)
        setEndFridayTime(filterResult?.seller_availability?.availability[4]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[4]?.closing_time) : filterResult?.seller_availability?.availability[4]?.closing_time)
        setStartSaturdayTime(filterResult?.seller_availability?.availability[5]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[5]?.opening_time) : filterResult?.seller_availability?.availability[5]?.closing_time)
        setEndSaturdayTime(filterResult?.seller_availability?.availability[5]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[5]?.closing_time) : filterResult?.seller_availability?.availability[5]?.closing_time)
        setStartSundayTime(filterResult?.seller_availability?.availability[6]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[6]?.opening_time) : filterResult?.seller_availability?.availability[6]?.closing_time)
        setEndSundayTime(filterResult?.seller_availability?.availability[6]?.closing_time ? convertTo24HourFormat(filterResult?.seller_availability?.availability[6]?.closing_time) : filterResult?.seller_availability?.availability[6]?.closing_time)
        setBusinessHours(filterResult?.business_working_days)
    }

    const handleStartTimeChange = (newStartTime, flag) => {
        if (flag == "monday") {
            setStartMondayTime(newStartTime)
        }
        if (flag == "tuesday") {
            setStartTuesdayTime(newStartTime)
        }
        if (flag == "wednesday") {
            setStartWednesdayTime(newStartTime)
        }
        if (flag == "thursday") {
            setStartThursdayTime(newStartTime)
        }
        if (flag == "friday") {
            setStartFridayTime(newStartTime)
        }
        if (flag == "saturday") {
            setStartSaturdayTime(newStartTime)
        }
        if (flag == "sunday") {
            setStartSundayTime(newStartTime)
        }
    };

    const handleEndTimeChange = (newEndTime, flag) => {
        if (flag == "monday") {
            setEndMondayTime(newEndTime)
        }
        if (flag == "tuesday") {
            setEndTuesdayTime(newEndTime)
        }
        if (flag == "wednesday") {
            setEndWednesdayTime(newEndTime)
        }
        if (flag == "thursday") {
            setEndThursdayTime(newEndTime)
        }
        if (flag == "friday") {
            setEndFridayTime(newEndTime)
        }
        if (flag == "saturday") {
            setEndSaturdayTime(newEndTime)
        }
        if (flag == "sunday") {
            setEndSundayTime(newEndTime)
        }
    };

    const handleCopy = (flag) => {
        setSelectedOptions([])
    }

    const handleChange = (e) => {
        setPickupTime(e.target.value)
    }

    const handleCheckboxChange = (event) => {
        var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
        if (event.target.checked) {
            updatedList = businessHours?.length > 0 ? [...businessHours, event.target.value] : [event.target.value];
        } else {
            updatedList.splice(businessHours?.indexOf(event.target.value), 1);
        }
        setBusinessHours(updatedList);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (pickupInstruction === "") {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter pickup instruction");
            }
            return false
        }
        if (businessHours && businessHours?.length <= 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select business hours");
            }
            return false
        }
        if ((businessHours?.includes("monday") && !startMondayTime) ||
            (businessHours?.includes("tuesday") && !startTuesddayTime) ||
            (businessHours?.includes("wednesday") && !startWednesdayTime) ||
            (businessHours?.includes("thursday") && !startThursdayTime) ||
            (businessHours?.includes("friday") && !startFridayTime) ||
            (businessHours?.includes("saturday") && !startSaturdayTime) ||
            (businessHours?.includes("sunday") && !startSundayTime)
        ) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select correct start time");
            }
            return false
        }
        if ((businessHours?.includes("monday") && !endMondayTime) ||
            (businessHours?.includes("tuesday") && !endTuesdayTime) ||
            (businessHours?.includes("wednesday") && !endWednesdayTime) ||
            (businessHours?.includes("thursday") && !endThursdayTime) ||
            (businessHours?.includes("friday") && !endFridayTime) ||
            (businessHours?.includes("saturday") && !endSaturdayTime) ||
            (businessHours?.includes("sunday") && !endSundayTime)
        ) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select correct end time");
            }
            return false
        }
        setLoading(true)
        let postData = {
            "id": addressId,
            "pickup_time": pickupTime,
            "business_working_days": businessHours,
            "availability": [
                {
                    "day": "1",
                    "opening_time": businessHours?.includes("monday") ? moment(startMondayTime, 'h:mm a').format('h:mm a') : "",
                    "closing_time": businessHours?.includes("monday") ? moment(endMondayTime, 'h:mm a').format('h:mm a') : ""
                },
                {
                    "day": "2",
                    "opening_time": businessHours?.includes("tuesday") ? moment(startTuesddayTime, 'h:mm a').format('h:mm a') : "",
                    "closing_time": businessHours?.includes("tuesday") ? moment(endTuesdayTime, 'h:mm a').format('h:mm a') : ""
                },
                {
                    "day": "3",
                    "opening_time": businessHours?.includes("wednesday") ? moment(startWednesdayTime, 'h:mm a').format('h:mm a') : "",
                    "closing_time": businessHours?.includes("wednesday") ? moment(endWednesdayTime, 'h:mm a').format('h:mm a') : ""
                },
                {
                    "day": "4",
                    "opening_time": businessHours?.includes("thursday") ? moment(startThursdayTime, 'h:mm a').format('h:mm a') : "",
                    "closing_time": businessHours?.includes("thursday") ? moment(endThursdayTime, 'h:mm a').format('h:mm a') : ""
                },
                {
                    "day": "5",
                    "opening_time": businessHours?.includes("friday") ? moment(startFridayTime, 'h:mm a').format('h:mm a') : "",
                    "closing_time": businessHours?.includes("friday") ? moment(endFridayTime, 'h:mm a').format('h:mm a') : ""
                },
                {
                    "day": "6",
                    "opening_time": businessHours?.includes("saturday") ? moment(startSaturdayTime, 'h:mm a').format('h:mm a') : "",
                    "closing_time": businessHours?.includes("saturday") ? moment(endSaturdayTime, 'h:mm a').format('h:mm a') : ""
                },
                {
                    "day": "7",
                    "opening_time": businessHours?.includes("sunday") ? moment(startSundayTime, 'h:mm a').format('h:mm a') : "",
                    "closing_time": businessHours?.includes("sunday") ? moment(endSundayTime, 'h:mm a').format('h:mm a') : ""
                }
            ]
        }

        if (pickupInstruction) {
            postData = { ...postData, "pickup_instructions": pickupInstruction }
        }
        let addressData = {
            postData: postData,
            tokenData: auth?.payload?.token
        }

        dispatch(updateSellerInfoApiAsync(addressData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/setup-address')
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        filterData()
    }, [])

    return (
        <div className='pickupAddress'>
            {/* Start FixedTopHeader */}
            <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="innerSubheadNormal">
                                <span onClick={() => history.push(location?.state)}>
                                    <i className="las la-angle-left me-3 directionArrow"></i>
                                </span>
                                <span>Local Pickup for 106 E 2nd St</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* End FixedTopHeader */}
            <div className='backContainer'>
                <div className=''>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className='subheading_ mt-4 mb-4'>Location:
                                <span className='subheading2_'>Local Pickup</span>
                            </h2>
                        </div>
                        <div className='col-md-12'>
                            {
                                address ?
                                    <div className='businessDetails'>
                                        <Link to="#">
                                            <div className='d-flex align-items-center'>
                                                <span className='IconPin me-3'>
                                                    <img src={locationPin} alt='LocationPin' />
                                                </span>
                                                <div className=''>
                                                    <p className='descriptionPara_ mb-1'>{address?.street_address}</p>
                                                    <h4 className='itemsText'>{auth?.payload?.user?.user_profiles?.organization_name}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    : <></>
                            }
                        </div>
                        <div className='mb-4 form-group col-md-12 mt-4 mb-4'>
                            <input type="checkbox" id="register_business" name="register_business" checked={isActive} onChange={() => setIsActive(!isActive)} />
                            <label htmlFor="register" className='ms-2'>This location offers JOBR delivery</label><br />
                        </div>
                    </div>
                </div>
                {
                    isActive ?
                        <form className="form_horizontal row locationForm_" onSubmit={(e) => { handleSubmit(e) }}>
                            <div className='col-md-12'>
                                <h6 className='deatilsHead'>Information at checkout</h6>
                            </div>
                            <div className="mb-4 form-group col-md-12">
                                <label className="deatilsHead mb-2">Expected Local Pickup time </label>
                                <select className='customform-control select' defaultValue={pickupInstruction ? pickupInstruction : "1 hours"} onChange={(e) => handleChange(e)}>
                                    <option value="1 hours">Usually ready in 1 hours</option>
                                    <option value="2 hours">Usually ready in 2 hours</option>
                                    <option value="3 hours">Usually ready in 3 hours</option>
                                </select>
                                {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                            </div>
                            <div className='mb-4 form-group col-md-12'>
                                <label className="deatilsHead mb-3"> Order ready for pickup notification</label><br />
                                <label className="deatilsHead mb-3"> Pickup instructions</label>
                                <textarea className="customform-control" id="" rows="4" value={pickupInstruction} onChange={e => setPickupInstruction(e.target.value)} placeholder='write your description here' />
                            </div>

                            <div className="mb-4 form-group col-xxl-10 col-xl-10 col-lg-8 col-md-12 ">
                                <label className="deatilsHead mb-3"> Business Hours</label>

                                <div className='checkboxGroup businessChekbox d-block'>
                                    {dayList.map((item, index) => {
                                        return (
                                            <div className='checkboxInputs' key={index}>
                                                <input type="checkbox" id={item.day} className='checkBox' name={item.day} value={item.day}
                                                    onChange={(e) => handleCheckboxChange(e)} checked={businessHours?.includes(item.day)} />
                                                <label htmlFor={item.day} className='checkBoxTxt'>{item.value}</label>
                                                {
                                                    businessHours?.includes(item.day) &&
                                                    <>
                                                        <div style={{ display: "flex" }} className='timerBox_'>
                                                            <div style={{ display: "flex" }} className='innertimedate_'>
                                                                <p className='timehead_'>Start Time:</p>
                                                                <TimePicker
                                                                    onChange={(e) => handleStartTimeChange(e, item.day)}
                                                                    value={item.id == 1 ? startMondayTime : item.id == 2 ? startTuesddayTime : item.id == 3 ? startWednesdayTime : item.id == 4 ? startThursdayTime : item.id == 5 ? startFridayTime : item.id == 6 ? startSaturdayTime : startSundayTime}
                                                                    format="h:mm a"
                                                                    showLeadingZeros={false}
                                                                    amPmAriaLabel="Select AM/PM"
                                                                    className="custom-time-picker customPicker"
                                                                />
                                                            </div>
                                                            <div style={{ display: "flex" }} className='timerBox_'>
                                                                <p className='timehead_'>End Time:</p>
                                                                <TimePicker
                                                                    onChange={(e) => handleEndTimeChange(e, item.day)}
                                                                    value={item.id == 1 ? endMondayTime : item.id == 2 ? endTuesdayTime : item.id == 3 ? endWednesdayTime : item.id == 4 ? endThursdayTime : item.id == 5 ? endFridayTime : item.id == 6 ? endSaturdayTime : endSundayTime} // Use the 12-hour formatted time string directly
                                                                    format="h:mm a" // Specify the format as 12-hour with AM/PM                                              
                                                                    className="custom-time-picker customPicker"
                                                                />
                                                            </div>
                                                        </div>
                                                        <Dropdown onClick={() => { handleCopy(item.id) }}>
                                                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                                <label className="iconcopy_ mb-0"> <i className="fa-solid fa-copy"></i> </label>
                                                            </Dropdown.Toggle>
                                                            <div className='timedatecoprBox_'>
                                                                <Dropdown.Menu>
                                                                    <Form>
                                                                        {options.map((option) => (
                                                                            <Form.Check
                                                                                key={option.id}
                                                                                type="checkbox"
                                                                                label={option.label}
                                                                                id={`option-${option.id}`}
                                                                                checked={selectedOptions.find(val => val?.id == option?.id)}
                                                                                onChange={() => handleCheckboxChange1(option, item.id)}
                                                                            />
                                                                        ))}
                                                                    </Form>
                                                                </Dropdown.Menu>
                                                            </div>
                                                        </Dropdown>
                                                    </>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>

                            <div className='col-md-3'>
                            </div>

                            <div className='col-md-12 text-start'>
                                <button className='staticDarkBtn' type="submit" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    &nbsp;&nbsp;
                                    <span>Save</span>
                                </button>
                            </div>
                        </form>
                        : <></>
                }
            </div>
        </div>
    )
}

export default LocalPickup