import React from 'react'
import { calendar, dotsinbox, filestore, importUser, wordIcon } from '../../../utilities/images'
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import { useState } from 'react'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import products from "../../db/catalog.json"

const NewPromotionModal = () => {
const [enterprisesDate, setEnterprisesDate] = useState("")
const history = useHistory()
const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
const columns1 = [
      
    {
        name: "",
        selector: row => {
            return (
                <>
                    <div className='rolecheckbox_'>
                        <input className="me-2" type="checkbox" id="check1" value="" />
                        <label className='contentheadtext_ mb-3' htmlFor="check1"></label>
                    </div>
                </>
            );
        },
        width: "40px",
    },
    {
        name: 'Name',
        selector: row => {
            return (
                <>
                    <span className='textinner_ '>Kelly G. Wingo</span>
                </>
            );
        },
        width: "100px",
    },
   
    {
        name: 'Phone Number',
        selector: row => {
            return (
                <>
                    <span className='textinner_ '>909-520-6181</span>
                </>
            );
        },
        width: "220px",
    },

   
    {
        name: 'State',
        selector:row =>
        {
            return (
                <>
                    <span className='textinner_ '>Ontario</span>
                </>
            );
        },
        width: "180px",
      
    },
    
    {
        name: 'City',
        selector: row => {
            return (
                <>
                    <span className='textinner_ '>California</span>
                </>
            );
        },
        width: "100px",
    },
];

const handleRowClick = (e) => {
    // console.log("rowclicked", e)
    // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
}
  return (
    <div className='newPromotionModal'>
        <div className='row'>
            <div className='col-md-8'>
                <div className=''>
                    <h3 className='labelTxt fw-semibold'>Template Selection</h3>
                    <p className='textinner_ mt-3 mb-4 fw-500'>Add a title or choose which type of media you'll use for this header.</p>
                    <form className='tempForm row'>
                        <div className='form-group col-md-6'>
                            <label className='deatilsHead mb-2 fw-normal'>Select Category</label>
                            <select className="customform-control select">
                                <option value="0" selected>Category ...</option>
                                <option value="1">Tobacco</option>
                                <option value="2">Dab</option>
                                <option value="10">Vegetables and fruit</option>
                                <option value="11">Juice and plant drinks</option>
                            </select>
                        </div>
                        <div className='form-group col-md-6'>
                            <label className='deatilsHead mb-2 fw-normal'>Select Template</label>
                            <select className="customform-control select">
                                <option value="0" selected>Language ..</option>
                                <option value="1">Tobacco</option>
                                <option value="2">Dab</option>
                                <option value="10">Vegetables and fruit</option>
                                <option value="11">Juice and plant drinks</option>
                            </select>
                        </div>
                    </form>
                </div>
                <hr />
                <div className='contacts mt-4'>
                    <h3 className='labelTxt fw-semibold'>Contacts </h3>
                    <p className='textinner_ mt-3 mb-4 fw-500'>Select contacts from list or import contacts</p>
                    <button className='importBtn mb-3'>
                        <img src={importUser}  alt='' className='img-fluid me-2'/>
                        <span>Import Contacts</span>
                    </button>
                    {/* Start TableFilter Area */}
                    <div className='tableFilters'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="calenderIcon">
                                    <img src={calendar} alt="calender" />
                                    {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                    dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/> */}
                                    <DatePicker
                                            className='cateDate'
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            placeholderText="Date"
                                        />
                                </div>
                            </div>
                            {/* <div className='col-md-4'>
                                <select className="cateSelectdFilter" required>
                                    <option value="" selected>
                                    Country
                                    </option>
                                    <option>Category1</option>
                                    <option>Category2</option>
                                </select>
                            </div>
                            <div className='col-md-4'>
                                <select className="cateSelectdFilter" required>
                                    <option value="" selected>
                                    State
                                    </option>
                                    <option>Category1</option>
                                    <option>Category2</option>
                                </select>
                            </div> */}
                        </div>
                    </div>
                    {/* End TableFilter Area */}
                    <DataTable
                        pagination
                        columns={columns1}
                        data={products}
                        defaultSortFieldId={1}
                        onRowClicked={handleRowClick}
                        className='contactTable'
                    />
                    <hr />
                    <button className='primary_btn d-inline-block w-auto mt-4'>Create New Promotion</button>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='rightPreviewContainer'>
                    <h5 className='subtextSmall_'>Preview</h5>
                    <div className='msgArea mt-2'>
                        Hi Friend 👋🏻 <br />
                        Welcome to WATI Demo!
                        Click below button to send your first message package
                    </div>
                    <button className='plainBtn mt-2'>Button Text</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewPromotionModal