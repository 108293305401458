import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import { calendar, suplier, dots, fedeximg, profileImg1 } from '../../../../utilities/images'
import DatePicker from "react-datepicker";
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import products from "../../../db/category.json";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { getPurchaseListApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomModal from '../../../components/shared/CustomModal';
import NewpurchaseInvoice from '../newPurchase/newpurchaseInvoice';


const CancelPurchase = () => {
    const history = useHistory()
    const [singleOrderData, setSingleOrderData] = useState({});
    const [purchaseList, setPurchaseList] = useState([]);
    const dispatch = useDispatch();
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };
    const columns1 = [
        {
            name: '#',
            selector: row => row?.id,
            width: "70px",
        },
        {
            name: 'Date',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <span className='contentSubtext_'>{moment(row?.created_at).format("MMM DD, YYYY")}</span>
                            <p className='tableHeadsmall'>{moment(row?.created_at).format("HH : mm")}</p>
                        </div>
                    </>
                );
            },
        },
        {
            name: 'Cancelation Date',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <span className='contentSubtext_'>{moment(row?.cancelled_at).format("MMM DD, YYYY")}</span>
                            <p className='tableHeadsmall'>{moment(row?.cancelled_at).format("HH : mm")}</p>
                        </div>
                    </>
                );
            },
            width: "150px",
        },
        {
            selector: row => row?.invoice?.invoice_id,
            name: 'Invoice Id',
            width: "120px"
        },
        {
            name: "Supplier",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.sellerDetails?.user_profiles?.banner_image ? row?.sellerDetails?.user_profiles?.banner_image : profileImg1} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ '>{row?.sellerDetails?.user_profiles?.organization_name}</span>
                                <p className='mb-0 '>
                                    <span className='tableHeadsmall'>{row?.sellerDetails?.user_profiles?.current_address?.city + "," + row?.sellerDetails?.user_profiles?.current_address?.country}</span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "250px",
        },

        {
            selector: row => row?.total_items,
            name: 'Qunatity',
            width: "110px"
        },

        {
            name: 'Amount',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2 tableBtn__'>
                            <span className='contentSmallTxt'>${row?.payable_amount}</span>

                        </div>
                    </>
                );
            },
            width: "100px"

        },
        {
            name: 'Remark',
            selector: row => {
                return (
                    <>
                        <div className='ms-2'>
                            <span className='contentSubtext_'>Stock out</span>
                        </div>
                    </>
                );
            },
            width: "200px",
        },
        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2 tableBtn__'>
                            <Link to='#' className='deniedBtn  me-3'>Canceled</Link>
                            {/* <img className='me-3' src={dots} /> */}
                        </div>
                    </>
                );
            },

            width: "150px"
        },

    ];

    const handleRowClick = (e) => {
        setSingleOrderData(e)
        setModalDetail({ show: true, flag: "PendingInvoiceModal" });
        setKey(Math.random());
    }
    const purchaseListHandle = () => {
        let params = {
            "postData": {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                status: 7,
                page: currentPage,
                limit: perPage
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate != null && endDate != null) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getPurchaseListApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setPurchaseList(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        purchaseListHandle()
    }, [currentPage, perPage, endDate])
    return (
        <div className='newPurchase'>
            <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>My Purchases / <span className="innerSubtext">Cancelled</span></span>
                            </h2>
                        </div>

                    </div>
                </div>
            </div>
            <div className='tableFilters'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="calenderIcon">
                            <img src={calendar} alt="calender" />
                            {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                            <DatePicker
                                className='cateDate'
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText="Date"
                            />
                        </div>
                    </div>
                    {/* <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Country
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                State
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Id type
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div> */}
                </div>
            </div>
            <div className=' commonTableheight table-responsive mt-1'>
                <DataTable
                    pagination
                    columns={columns1}
                    data={purchaseList}
                    defaultSortFieldId={1}
                    onRowClicked={handleRowClick}
                    customStyles={customStyles}
                    progressPending={loading}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    paginationPerPage={perPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                />
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "PendingInvoiceModal" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "PendingInvoiceModal" ? "pendinginvoiceModal" : ""}

                child={

                    modalDetail.flag === "PendingInvoiceModal" ? (
                        <NewpurchaseInvoice singleOrderData={singleOrderData} close={() => handleOnCloseModal()} />
                    ) :

                        (
                            ""
                        )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>

    )
}

export default CancelPurchase