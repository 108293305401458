import React, { useState } from 'react'
import OTPInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { verifyQrCodeAsync } from './authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from './authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const TwoFactorAuthentication = () => {
    const history = useHistory()
    const [pin, setPin] = useState("")
    const [loading, setLoading] = useState(false)
    const auth = useSelector(selectLoginAuth)
    const toastId = React.useRef(null)
    const dispatch = useDispatch()
    const onComplete = (code) => {
        setPin(code);
    }

    const handleVerify = () => {
        if (pin.length < 6) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Pin should be of 6 digits");
            }
            return;
        }
        let params = {
            postData: {
                code: pin
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(verifyQrCodeAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/plans')
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    return (
        <>
            <div className='commonPadding_'>
                <div className="mb-4 form-group col-md-10">
                    <label className="deatilsHead mb-3">Enter Pin</label>
                    <div className="pin-box d-flex justify-content-center" >
                        <OTPInput numInputs={6}
                            className='input_digits_'
                            value={pin}
                            data-cy="pin-field"
                            name="pin-field"
                            autoComplete="new-password"
                            isInputNum={true}
                            isInputSecure={true}
                            onChange={onComplete}
                        // onComplete={(code) => onComplete(code)}
                        />
                    </div>
                </div>

                <div className="d-grid gap-2 col-12 mx-auto">
                    <button className="loginBtn" type="button" disabled={loading} onClick={() => handleVerify()}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Verify</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default TwoFactorAuthentication