import React, { useEffect, useState } from "react";
import {
  businesslocation,
  defaultUser,
  editPen,
  store,
  userImg_,
} from "../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { getServicesApiAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getUserByIdApiAsync } from "../auth/authSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import CustomModal from "../../components/shared/CustomModal";
import EditEmailPhoneModal from "../../components/EditEmailPhoneModal";
import VerifyUserModal from "../myPos/verifyUserModal";

const MyProfile = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [operatingIndustry, setOperatingIndustry] = useState([]);
  const [serviceIds, setServiceIds] = useState([]);
  const [userData, setUserData] = useState("");
  const [sellerType, setSellerType] = useState("");
  const [key, setKey] = useState(Math.random());
  console.log(sellerType, "sellerType");
  const dispatch = useDispatch();

  console.log(userData?.user_profiles,"dssssssssssssssss");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    type: "",
    value: "",
    phone_code: "",
  });
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    let data = {
      postData: {
        id: auth?.payload?.user?.user_profiles?.user_id
          ? auth?.payload?.user?.user_profiles?.user_id
          : auth?.payload?.user_profiles?.user_id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserByIdApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setUserData(obj?.payload);
        setSellerType(
          obj?.payload?.user_profiles?.seller_type?.replace(/_/g, " ")
        );
        dispatch(getServicesApiAsync())
          .then(unwrapResult)
          .then((obj1) => {
            let serviceIds =
              obj?.payload?.user_profiles?.service_ids?.split(",");
            setServiceIds(serviceIds);
            let filterData = obj1?.payload?.data?.filter((item) =>
              serviceIds?.includes(item.id.toString())
            );
            setOperatingIndustry(filterData);
            setLoading(false);
          })
          .catch((obj) => {
            setLoading(false);
          });
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  return (
    <>
      <DashboardNavbar title="My Profile" show={true} />
      {loading ? (
        <span className="spinner-border inner-spin spinner-border-sm"></span>
      ) : (
        <>
          <div className="row">
            <div className="col-md-12 text-end">
              <button
                className="btn btn-primary "
                onClick={() => history.push(`/editProfile`)}
              >
                {" "}
                Edit Profile
              </button>
            </div>
          </div>
          <div className="myProfile">
            {/* <div className='topHeader_ '>
                <div className="topStickyHeader_ mb-3">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h2 className="mainHeading">
                        <span>My Profile</span>
                      </h2>
                    </div>
                    <div className="col-md-6 text-end">
                      <button className='btn btn-primary' onClick={() => history.push(`/editProfile`)}> Edit Profile</button>
                    </div>
                  </div>
                </div>
              </div> */}

            <div className="chartsOuter mt-3">
              <h5 className="mb-3 subheading_">User Details</h5>
              <div className="myproContent">
                <div className="myProfile">
                  <img
                    src={
                      userData?.user_profiles?.profile_photo
                        ? userData?.user_profiles?.profile_photo
                        : userImg_
                    }
                    alt="img"
                    className="profileBig"
                  />
                  <div className="myProfileInner">
                    {/* <button className="updateBtn" >
              <i className="fas fa-pen"></i> Edit Profile
              </button> */}
                    <h5 className="mb-2 fw-400 ">{`${userData?.user_profiles?.firstname} ${userData?.user_profiles?.lastname}`}</h5>
                    <table className="profileTable">
                      <tr className="details">
                        <td>Email</td>
                        <td>Phone</td>
                        <td>Organisation Name</td>
                        <td>Seller Type</td>
                      </tr>
                      <tr className="data">
                        <td>
                          {userData?.email}
                          <img
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "ScheduleModal",
                                type: "Email",
                                value: userData?.email,
                              });
                              setKey(Math.random());
                            }}
                            src={editPen}
                            alt="img"
                            className="editIcon_New"
                          />
                        </td>

                        <td>
                          {userData?.user_profiles?.phone_code}{" "}
                          {userData?.user_profiles?.phone_no}
                          <img
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "ScheduleModal",
                                type: "Phone",
                                value: userData?.user_profiles?.phone_no,
                                phone_code: userData?.user_profiles?.phone_code,
                              });
                              setKey(Math.random());
                            }}
                            src={editPen}
                            alt="img"
                            className="editIcon_New"
                          />
                        </td>
                        <td>
                          {userData?.user_profiles?.organization_name
                            ?.charAt(0)
                            ?.toUpperCase() +
                            userData?.user_profiles?.organization_name?.slice(
                              1
                            )}
                        </td>
                        <td>
                          {sellerType?.charAt(0)?.toUpperCase() +
                            sellerType?.slice(1)}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="chartsOuter mt-5">
            <h5 className="mb-3 subheading_">Business Details</h5>
            <div className="myproContent">
              <div className="myProfile ">
                <img
                  src={
                    userData?.user_profiles?.business_logo
                      ? userData?.user_profiles?.business_logo
                      : store
                  }
                  alt="img"
                  className="profileBig"
                />
                <div className="myProfileInner">
                  {/* <button className="updateBtn" >
              <i className="fas fa-pen"></i> Edit Profile
              </button> */}
                  <h5 className="mb-2 fw-400 ">
                    {userData?.user_profiles?.organization_name
                      ?.charAt(0)
                      ?.toUpperCase() +
                      userData?.user_profiles?.organization_name?.slice(1)}
                  </h5>
                  <table className="profileTable">
                    <tr className="details">
                      <td>Business Type</td>
                      <td>Seller Type</td>
                      <td>Operating Industry</td>
                    </tr>
                    <tr className="data">
                      <td>
                        {userData?.user_profiles?.business_type?.map((v, i) => {
                          return (
                            <React.Fragment key={i}>
                              {v?.charAt(0)?.toUpperCase() +
                                v?.slice(1) +
                                `${
                                  i + 1 ===
                                  userData?.user_profiles?.business_type?.length
                                    ? ""
                                    : ", "
                                }`}
                            </React.Fragment>
                          );
                        })}
                      </td>
                      <td>
                        {sellerType?.charAt(0)?.toUpperCase() +
                          sellerType?.slice(1)}
                      </td>
                      {operatingIndustry?.map((v, i) => {
                        return (
                          <span key={i}>
                            {v?.name}
                            {i + 1 === operatingIndustry?.length ? "" : ", "}
                          </span>
                        );
                      })}
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="chartsOuter mt-5">
            <h5 className="mb-3 subheading_">Address Details</h5>
            <div className="myproContent">
              <div className="myProfile">
                {/* <img src={userData?.user_profiles?.business_logo ? userData?.user_profiles?.business_logo : userImg_} alt="img" className="profileBig" /> */}
                <div className="myProfileInner">
                  <h5 className="mb-2 fw-400 ">
                    {userData?.user_profiles?.current_address?.street_address}
                  </h5>
                  <table className="profileTable">
                    <tr className="details">
                      <td>City</td>
                      <td>State</td>
                      <td>Country</td>
                      <td>Zip code</td>
                      <td>Apt.</td>
                    </tr>
                    <tr className="data">
                      <td>{userData?.user_profiles?.current_address?.city}</td>
                      <td>{userData?.user_profiles?.current_address?.state}</td>
                      <td>
                        {userData?.user_profiles?.current_address?.country}
                      </td>
                      <td>
                        {userData?.user_profiles?.current_address?.zipcode}
                      </td>
                      <td>
                        {userData?.user_profiles?.current_address?.apt
                          ? userData?.user_profiles?.current_address?.apt
                          : "--"}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {userData?.user_profiles?.social_links?.length > 0 ? (
            <div className="chartsOuter mt-5">
              <h5 className="mb-3 subheading_">Social Links</h5>
              <div className="myproContent">
                <div className="myProfile">
                  <div className="myProfileInner">
                    {userData?.user_profiles?.social_links?.map((v, i) => {
                      return (
                        <p className="my-2" key={i}>
                          <b>{v?.title}</b> :{" "}
                          <a target="_blank" href={v?.url}>
                            {v?.url}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "ScheduleModal" ? "scheduleModal" : ""}
        // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
        child={
          modalDetail.flag === "ScheduleModal" ? (
            <EditEmailPhoneModal
              modalDetail={modalDetail}
              close={() => handleOnCloseModal()}
              getUserData={() => getUserData()}
            />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default MyProfile;
