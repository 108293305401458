import React, { useState } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createRoleApiAsync, getPrivilegesApiAsync, getPosUsersAsync, selectLoginAuth, getRoleApiAsync, deleteRoleApiAsync } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomModal from '../../components/shared/CustomModal';
import CreateRoleModal from "./createRoleModal";
import { useEffect } from 'react';
import DataTable from 'react-data-table-component'
import RoleDetailsModal from './RoleDetailsModal';
import DeleteModal from './deleteModal';
import DashboardNavbar from '../dashboard/DashboardNavbar';




const CreateRole = (props) => {
    const toastId = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState('')
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [posList, setPosList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [roleUsers, setRoleUsers] = useState([])
    const history = useHistory()
    const [key, setKey] = useState(Math.random());

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const columns1 = [
        {
            name: '#',

            selector: row => row.id,
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.name}</span>
                    </>
                );
            },
            name: 'Role Name',
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.description?.length > 20 ? row?.description?.substring(0, 20) + "..." : row?.description}</span>
                    </>
                );
            },
            name: 'Description'
        },
        {
            selector: row => {
                let data = row?.role_privileges?.map((v, i) => {
                    return v?.privileges?.name + (i === row?.role_privileges?.length - 1 ? "" : ", ")
                })
                return (
                    <>

                        <span className='textParagh '>{data}</span>
                    </>
                );
            },
            name: 'Privilages',
        },
        {
            selector: row => {
                return (
                    <>
                      
                        <div className='d-flex justify-space-between align-items-center'>
                            {/* <span className='textParagh '>${row?.price}</span> */}
                            <div className='dropdown tableDropdown ms-3 '>
                                <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                                    <i className="fal fa-ellipsis-v icon_"></i>
                                    {/* <img src={dots} alt=""/> */}
                                </span>
                                <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                                    <li className='dropdown-item' onClick={() => {
                                        setSelectedProduct(row)
                                        setModalDetail({ show: true, flag: "RoleDetailsModal" });
                                        setKey(Math.random());
                                    }} >View Details</li>
                                    <li className='dropdown-item' onClick={() => {
                                        setSelectedProduct(row)
                                        setModalDetail({ show: true, flag: "CreateRoleModal" });
                                        setKey(Math.random());
                                    }}>Edit</li>
                                    <li className='dropdown-item' onClick={() => {
                                        setSelectedProduct(row)
                                        setModalDetail({ show: true, flag: "deleteRoleModal" });
                                        setKey(Math.random());
                                    }}>Delete</li>
                                </div>
                            </div>
                        </div>
                    </>
                );
            },

        }
    ]

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const deleteHandle = (id) => {
        let params = {
            "postData": {
                "id": id
            },
            "tokenData": auth?.payload?.token
        }
        setLoading1(true)
        dispatch(deleteRoleApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let data = {
                    user_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                    page: currentPage,
                    limit: perPage
                }
                dispatch(getRoleApiAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        setRoleUsers(obj?.payload?.roles)
                        setLoading1(false)
                        handleOnCloseModal()
                    }
                    )
                    .catch((obj) => {
                        setLoading1(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading1(false)
            })
    }

    const handleOpenModal = () => {
        setSelectedProduct('')
        setModalDetail({ show: true, flag: "CreateRoleModal" });
        setKey(Math.random());
    }

    useEffect(() => {
        let data = {
            user_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
            page: currentPage,
            limit: perPage
        }
        setLoading(true)
        dispatch(getRoleApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setRoleUsers(obj?.payload?.roles)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }, [perPage, currentPage])
    
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "My-Pos";
      }, []);
    return (
        <>
          <DashboardNavbar title="Create Role" show={true} />
          <div className='row justify-content-end mb-3'>
          <div className="col-md-2 text-end">
          <button className='btn btn-primary' onClick={() => handleOpenModal()}> Create Role</button>
                        </div>
                        </div>
       
            {/* <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-10">
                            <h2 className="mainHeading">
                                <span>Create Role</span>
                            </h2>
                        </div>
                        
                    </div>
                </div> 
            </div> */}

            {/* <div className='addEmployeeuser'>
                <form onSubmit={(e) => submitHandle(e)} className='emplyoeeDetails horizontalForms'>
                    <div className='row'>
                        <div className='form-group col-md-6  mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>First Name</label>
                            <input
                                type='text'
                                name="text"
                                className='customform-control'
                                placeholder="Please Enter First name"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Description</label>
                            <textarea row="2" className='customform-control textAreaSize' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description'></textarea>
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Select Privileges</label>
                            <Multiselect
                                className='brandFilter'
                                displayValue="value"
                                options={privileges}
                                loading={loading}
                                isObject={true}
                                placeholder='Select Privileges'
                                hidePlaceholder={false}
                                // selectionLimit={1}
                                onSelect={handleSelect}
                                onRemove={handleSelect}
                                optionValueDecorator={(e) => handleOptionValue(e)}
                                selectedValueDecorator={(e) => handleOptionValue(e)}
                                avoidHighlightFirstOption={true}
                                showCheckbox
                            />
                        </div>

                        <div className='footerSec mt-4'>
                            <button className='submitBtn' type="submit" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Create Role</span>
                            </button>
                        </div>

                    </div>
                </form>
            </div> */}
            <div className='createRole_ tableSectionBackground'>
                <div className='table-responsive'>
                    <DataTable
                        columns={columns1}
                        data={roleUsers}
                        progressPending={loading}
                        //   onRowClicked={handleRowClick}
                        //   customStyles={customStyles}   
                        defaultSortFieldId={1}
                        pagination
                        paginationServer
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "CreateRoleModal" || modalDetail.flag === "RoleDetailsModal" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "RoleDetailsModal" ? "RoleDetailsModal" : modalDetail.flag === "CreateRoleModal" ? "createRoleModal" : modalDetail.flag === "deleteRoleModal" ? "DeleteRoleModal" : ""}
                child={
                    modalDetail.flag === "deleteRoleModal" ? (
                        <DeleteModal flag="deleteRole" loadingState={loading1} selectedProduct={selectedProduct} deleteHandle={(e) => deleteHandle(e)} close={() => handleOnCloseModal()} />
                    ) :
                        modalDetail.flag === "RoleDetailsModal" ? (
                            <RoleDetailsModal selectedProduct={selectedProduct} close={() => handleOnCloseModal()} />
                        ) :
                            modalDetail.flag === "CreateRoleModal" ? (
                                <CreateRoleModal selectedProduct={selectedProduct} setRoleUsers={setRoleUsers} close={() => handleOnCloseModal()} />
                            ) :
                                (
                                    ""
                                )
                }
                header={
                    <>
                        <div className='modalHeader_'>
                            <div className="common_">
                                {
                                    modalDetail.flag === "deleteRoleModal" ?
                                        <h2 className="subheading2_">Delete role</h2>
                                        :
                                        modalDetail.flag === "CreateRoleModal" ?
                                            <div className='anotherHeader'>
                                                <h2 className="modalHeading">Create Role</h2>
                                            </div>
                                            :
                                            <div className='anotherHeader'>
                                                <h2 className="modalHeading">Role Details</h2>
                                            </div>
                                }
                                <button className="closeButton">
                                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                </button>

                            </div>
                        </div>
                    </>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default CreateRole