import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component'
import products from "../../../../db/category.json"
import { calendar, user, locationBlue, moneyIcon, profileImg1 } from '../../../../../utilities/images'
import { Link } from 'react-router-dom';
import { selectLoginAuth } from '../../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrdersApiAsync } from '../../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const ShippingRefund = () => {
    const dispatch = useDispatch()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const auth = useSelector(selectLoginAuth);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };
    const handleRowClick = (e) => {
        // setSingleOrderData(e)
        // setModalDetail({ show: true, flag: "PendingInvoiceModal" });
        // setKey(Math.random());
    }
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };
    const columns1 = [
        {
            name: '#',
            width: "70px",
            // selector: row => row?.id
            selector: (row, i) => (i + 1  *(perPage * (currentPage - 1)) + 1)
        },
        {
            name: "User",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.user_details?.profile_photo ? row?.user_details?.profile_photo : profileImg1} />
                            <div className='ms-2 text-start'>
                                <span className='contentSubtext_ '>{row?.user_details?.firstname + " " + row?.user_details?.lastname}</span>
                                <p className='mb-0'>
                                    <img src={locationBlue} className='img-fluid me-2' />
                                    <span className='textInner'>{row?.user_details?.current_address?.city?.length > 50 ? row?.user_details?.current_address?.city?.substring(0, 50) + "..." : row?.user_details?.current_address?.city},{row?.user_details?.current_address?.country?.length > 10 ? row?.user_details?.current_address?.country?.substring(0, 10) + "..." : row?.user_details?.current_address?.country}</span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "250px",
        },
        {
            selector: row => row?.invoices?.invoice_number,
            name: 'Invoice Number',
            width: "160px",
        },
        {
            name: 'Date',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <span className='contentSubtext_'>{moment(row?.order_details[0]?.created_at).format("MMM DD, YYYY")}</span>
                            <p className='textInner mt-2'>{moment(row?.order_details[0]?.created_at).format("HH:mm")}</p>
                        </div>
                    </>
                );
            },
            width: "120px",
        },

        {
            selector: row => row?.order_details[0]?.qty,
            name: 'Quantity',
        },
        {
            name: "Delivery Info",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.driver_details?.profile_photo ? row?.driver_details?.profile_photo : profileImg1} />
                            <div className='ms-2 text-start'>
                                <span className='contentSubtext_ '>{row?.driver_details?.username}</span>
                                <p className='mb-0'>
                                    {/* <img src={moneyIcon} className='img-fluid me-2' /> */}
                                    <span className='textInner'>${row?.driver_payout_amount / 100}</span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "200px",
        },
        {
            name: 'Remark',
            selector: row => {
                return (
                    <>
                        <div className='ms-2'>
                            <span className='contentSubtext_'>Client unreachable </span>
                        </div>
                    </>
                );
            },
            width: "200px",
        },
        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div className='ms-2 tableBtn__'>
                            <Link to='/refundorderInvoice' className='pendingStatus' >Refund Progressing</Link>
                            <Link to='#' className='bundleBtn d-none' >Refund </Link>

                        </div>
                    </>
                );
            },

            width: "250px"
        },
        {
            name: 'Settlement Amount ',
            selector: row => {
                return (
                    <>
                        <div className='ms-2'>
                            <span className='contentSubtext_'>$-</span>
                        </div>
                    </>
                );
            },
            width: "50px",
        },
        {
            name: '',
            selector: row => {
                return (
                    <>
                        <div className='ms-2'>
                            <i className="fal fa-chevron-right ms-3" aria-hidden="true"></i>
                        </div>
                    </>
                );
            },
            width: "50px",
        },

    ];

    const getAllOrdersHandle = (modalClose = null) => {
        let params = {
            "postData": {
                delivery_option: 4,
                need_refunded: true,
                page: currentPage,
                limit: perPage,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                order_type: "product"
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate != null && endDate != null) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAllOrdersApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setOrderData(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getAllOrdersHandle()
    }, [perPage, currentPage, endDate])

    return (
        <div className='deliveryRefund'>
            <div className='tableFilters'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="calenderIcon">
                            <img src={calendar} alt="calender" />
                            {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                            <DatePicker
                                className='cateDate'
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText="Date"
                            />
                        </div>
                    </div>
                    {/* <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Country
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                State
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Id type
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div> */}
                </div>
            </div>
            <div className='commonTableheight table-responsive'>
                <DataTable
                    pagination
                    columns={columns1}
                    data={orderData}
                    customStyles={customStyles}
                    defaultSortFieldId={1}
                    className="refundOrders"
                    onRowClicked={handleRowClick}
                    progressPending={loading}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    paginationPerPage={perPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                />
            </div>
        </div>
    )
}

export default ShippingRefund