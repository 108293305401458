import React from "react";
import * as Images from '../../../../utilities/images';
import moment from "moment-timezone";
import { formattedPrice } from "../../../../utilities/globalMethods";

const OriginalInvoice = ({
    merchentDetails,
    originalOrderArray,
    orderDetails,
    posData,
    deliveryShippingCharges,
}) => {
    return (
        <>
            <div className="col-lg-6 col-md-6">
                <div className="commanOuter me-0 ms-0 commonSubOuter confirmRight p-0 returnInvoive_">
                    <div className="confirmRightSub confirmAddress">
                        <h2 className="mapleHeading text-center">
                            {merchentDetails?.organization_name}.
                        </h2>
                        <h4 className="mapleAddress text-center">
                            {" "}
                            {merchentDetails?.current_address?.street_address},
                            {merchentDetails?.current_address?.city},
                            {merchentDetails?.current_address?.state},
                            {merchentDetails?.current_address?.country},
                            {merchentDetails?.current_address?.zipcode}
                        </h4>
                        <h4 className="mapleAddress text-center p-0">
                            {" "}
                            {merchentDetails?.full_phone_number}
                        </h4>
                    </div>
                    <div className="mapleProductDetails confirmRightSub">
                        {originalOrderArray?.map((data, idx) => {
                            return (
                                <div key={idx} className="flexBox mapleProductDetailsBox">
                                    <div className="flexbase">
                                        <p className="mapleProductcount">× {data?.qty}</p>
                                        <article className="ms-3">
                                            <p className="mapleProductHeading">
                                                {data?.product_name}
                                            </p>
                                        </article>
                                    </div>
                                    <article>
                                        <p className="mapleProductPrice">
                                            {formattedPrice(data?.actual_price * data?.qty)}
                                        </p>
                                    </article>
                                </div>
                            );
                        })}
                    </div>
                    <div className="flexBox mapleInvoiceBox confirmRightSub">
                        <article>
                            <p className="mapleProductPrice">Payment Option</p>
                            <p className="mapleProductHeading">
                                {orderDetails?.mode_of_payment}
                            </p>
                            <p className="mapleProductPrice">Invoice</p>
                            <p className="mapleProductHeading">
                                # {orderDetails?.invoices?.invoice_number}
                            </p>
                        </article>
                        <article>
                            <p className="mapleProductPrice">Date</p>
                            <p className="mapleProductHeading">
                                {" "}
                                {moment.utc(orderDetails?.updated_at).format("ddd, DD/MM/YYYY")}
                            </p>
                            <p className="mapleProductPrice">POS No.</p>
                            <p className="mapleProductHeading">{posData?.pos_number}</p>
                        </article>
                        <article>
                            <p className="mapleProductPrice">Mode</p>
                            <p className="mapleProductHeading">Walk-In</p>
                            <p className="mapleProductPrice">User UD</p>
                            <p className="mapleProductHeading">{posData?.id}</p>
                        </article>
                    </div>
                    <div className="flexBox maplePriceBox">
                        <article>
                            <p className="productName">Subtotal</p>
                            {(orderDetails?.delivery_charge != "0" ||
                                orderDetails?.shipping_charge != "0") && (
                                    <p className="productName">{deliveryShippingCharges?.title}</p>
                                )}
                            <p className="productName">Tips</p>
                            <p className="productName">Tax</p>
                            <p className="productName fw-bold">Total</p>
                        </article>
                        <article>
                            <p className="productName">
                                {formattedPrice(orderDetails?.total_sale_price)}
                            </p>
                            {(orderDetails?.delivery_charge != "0" ||
                                orderDetails?.shipping_charge != "0") && (
                                    <p className="productName">
                                        {formattedPrice(deliveryShippingCharges?.deliveryCharges)}
                                    </p>
                                )}

                            <p className="productName">
                                {formattedPrice(orderDetails?.tips)}
                            </p>

                            <p className="productName">{formattedPrice(orderDetails?.tax)}</p>

                            <p className="totalBtn">
                                {formattedPrice(orderDetails?.payable_amount)}
                            </p>
                        </article>
                    </div>
                    <div className="confirmFooter">
                        <img
                            src={Images.Logo}
                            alt="logo"
                            className="img-fluid logo mb-3"
                        /><br />
                        <img
                            src={Images.barCodeScanImg}
                            alt="barCodeScanImg"
                            className="img-fluid barCodeScanImg"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default OriginalInvoice;
