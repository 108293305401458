import React, { useState } from 'react'
import DeliveryAddress from './pickupAddress/deliveryAddress'
import DropOffAddress from './pickupAddress/dropOffAddress'
import { delivery, deliveryActive, dropOff, dropOffActive } from '../../../../utilities/images'

const DeliveryAddressModal = (props) => {
    const [activeTab, setActiveTab] = useState('jobrDelivery')
    const [loading, setLoading] = useState(false)


    return (
        <div className='addAddress deliveryModalSection'>
            <div className='addresstabsbg_'>
            <div className='row mb-4'>
                <div className='col-md-6'>
                    <div className={`deliveryTypeContain ${activeTab === 'jobrDelivery' ? 'active' : ''}`}
                        onClick={() => setActiveTab('jobrDelivery')}>
                        <img src={delivery} alt='delivery' className='paymentTypeIcon me-3' />
                        <img src={deliveryActive} alt='deliveryActive' className='paymentTypeIcon activeImg me-3' />
                        <div className=''>
                            <h3 className='boldsubSubhead'>JOBR Delivery</h3>
                            <p className='subtext_ mt-2'>Choose specific areas that you deliver to.</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className={`deliveryTypeContain ${activeTab === 'dropoff' ? 'active' : ''}`}
                        onClick={() => setActiveTab('dropoff')}>
                        <img src={dropOff} alt='dropOffActive' className='paymentTypeIcon me-3' />
                        <img src={dropOffActive} alt='dropOffActive' className='paymentTypeIcon activeImg me-3' />
                        <div className='mb-3'>
                            <h3 className='boldsubSubhead'>Use Your Driver </h3>
                            <p className='subtext_ mt-2'>Set a distance  that you deliver to.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* End LocationOffer Buttons */}
            {/* Start LocationOffers Details */}
            <div className='LocationOffersDetails'>
                {activeTab === 'jobrDelivery'
                    ?
                    <DeliveryAddress addressId={props.addressId} close={() => props.close()} addressDataModal={props?.addressDataModal} getAddressMerchant={props?.getAddressMerchant} />
                    : activeTab === 'dropoff'
                        ?
                        <DropOffAddress addressId={props.addressId} close={() => props.close()} addressDataModal={props?.addressDataModal} getAddressMerchant={props?.getAddressMerchant} />
                        : <></>
                }
            </div>
            </div>
           
        </div>
    )
}

export default DeliveryAddressModal;
