import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartmentDataInSupportApiAsync, getSupportAgentsApiAsync } from '../../dashboard/dashboardSlice'
import { selectLoginAuth } from '../../auth/authSlice'
import { toast } from 'react-toastify'

const SuportticketModal = (props) => {
    console.log(props,'pppppppp');
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const [departmentData, setDepartmentData] = useState([]);
    const [supportAgents, setSupportAgents] = useState([]);
    const [departmentID, setDepartmentID] = useState(props?.selectedProduct?.support_agent?.department_id ? props?.selectedProduct?.support_agent?.department_id : "");
    const [supportAgentID, setSupportAgentID] = useState(props?.selectedProduct?.support_agent_id ? props?.selectedProduct?.support_agent_id : "");
    const toastId = React.useRef(null)

    const handleDepartmetChange = (e) => {
        setDepartmentID(e.target.value)
        let params = {
            postData: {
                id: e.target.value
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getSupportAgentsApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setSupportAgents(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const handleSupportAgentChange = (e) => {
        setSupportAgentID(e.target.value)
    }

    const handleSupportAssignAgent = (e, departmentId, agentId) => {
        e.preventDefault()
        if (!departmentId) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select department");
            }
            return false
        }
        if (!agentId) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select any support agent");
            }
            return false
        }
        props?.handleAssignAgent(agentId)
    }

    const getAllDepartment = () => {
        setLoading(true)
        dispatch(getDepartmentDataInSupportApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setDepartmentData(obj?.payload)
                if (props?.selectedProduct?.support_agent?.department_id) {
                    let params = {
                        postData: {
                            id: props?.selectedProduct?.support_agent?.department_id
                        },
                        tokenData: auth?.payload?.token
                    }
                    setLoading(true)
                    dispatch(getSupportAgentsApiAsync(params))
                        .then(unwrapResult)
                        .then((obj) => {
                            setSupportAgents(obj?.payload)
                            setLoading(false)
                        }
                        )
                        .catch((obj) => {
                            setLoading(false)
                        })
                }
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        getAllDepartment()
    }, [])
    return (
        <div className='addEmployeeModal '>
            <form className='commonbody_ horizontalForms'>
                <div className='row'>
                    <div className='form-group col-md-12 mb-4'>
                        <label className='labelTxt mb-3' htmlFor='amount'>Select Department</label>
                        <select className='w-100 select customform-control' value={departmentID} onChange={(e) => handleDepartmetChange(e)}>
                            <option value='Select Department' >Select Department</option>
                            {
                                (departmentData && departmentData?.length > 0) ?
                                    <>
                                        {departmentData?.map((value, index) => {
                                            return (
                                                <option value={value?.id} key={index}>{value?.name}</option>
                                            )
                                        })}
                                    </> : <></>
                            }
                        </select>
                    </div>
                    <div className='form-group col-md-12 mb-4'>
                        <label className='labelTxt mb-3' htmlFor='amount'>Select Agent</label>
                        <select disabled={!departmentID} value={supportAgentID} className='w-100 select customform-control' onChange={(e) => handleSupportAgentChange(e)}>
                            <option value='Select Agent' >Select Agent</option>
                            {
                                (supportAgents && supportAgents?.length > 0) ?
                                    <>
                                        {supportAgents?.map((value, index) => {
                                            return (
                                                <option value={value?.user?.unique_uuid} key={index}>{value?.user?.user_profiles?.firstname+" "+value?.user?.user_profiles?.lastname}</option>
                                            )
                                        })}
                                    </> : <></>
                            }
                        </select>
                    </div>
                    <div className='footerSec mt-4'>
                        <button onClick={() => props.close()} className='closebtnModal me-4'>Cancel</button>
                        <button className='assignBtn_' type="submit" onClick={(e) => { handleSupportAssignAgent(e, departmentID, supportAgentID) }} disabled={props.loading}>
                            {props.loading === true && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Assign Now</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SuportticketModal