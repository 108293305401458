import React, { useState } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image';
import { toast } from 'react-toastify';
import { viewIcon } from '../../../utilities/images'
import { SketchPicker } from 'react-color';

const AddAttributesModal = (props) => {
    const [ingredients, setIngredients] = useState(props.value.length > 0 ? props.value : [{ name: "", description: "" }]);
    const [error, setError] = useState("");
    
    const handleColorChange = (e,i) => {
        const list = [...ingredients];
        list[i].name = e.hex;
        setIngredients(list);
    };
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...ingredients];
        list[index][name] = value;
        setIngredients(list);
        // props.customPriceBtob(list);
    };

    console.log(ingredients, "ingridiendts")

    const handleAddClick = () => {
        if (ingredients.length <= 4) {
            setIngredients([...ingredients, { name: "", description: "" }]);
        }
        else {
            setError("You can add maximum 5 custom attributes")
        }

    };

    const handleRemoveClick = index => {
        setError("")
        const list = [...ingredients];
        list.splice(index, 1);
        setIngredients(list);
        // props.customPriceBtob(list);
    };
    function hasEmptyValues(arr) {
        for (let i = 0; i < arr.length; i++) {
            const obj = arr[i];
            for (let key in obj) {
                if (obj.hasOwnProperty(key) && obj[key] === '') {
                    return true; // Empty value found
                }
            }
        }
        return false; // No empty values found
    }

    const handleUpdate = () => {
        if (hasEmptyValues(ingredients)) {
            toast.error("name and description could not be empty")
        } else {
            props.attributes(ingredients);
            props.close();
        }
    }

    return (
        <>
            <div className='addAttributesModal modalBodyBg'>
                <div className='row'>
                    <div className='col-md-12'>
                        {ingredients.map((item, i) => {
                            return (
                                <div className='btn-group w-100 mb-3' key={i}>
                                    {/* <button className='labelBtn col-md-5'>
                            <span className='subtextDark_'>
                                Tobacco
                            </span>
                        </button> */}
                                    {i ?
                                        <span className='removeSelect' onClick={() => handleRemoveClick(i)} ><i className="fas fa-times"></i></span>
                                        : ""}
                                    <div className='col-md-6 me-2'>
                                        {
                                            props?.name?.toLowerCase() === "color" ?
                                                <div className='form-group'>
                                                    <SketchPicker name="name" color={item.name} onChange={e => handleColorChange(e, i)} />
                                                </div> :
                                                <div className='form-group'>
                                                    <input
                                                        className="form-control inputLable"
                                                        placeholder="Name "
                                                        aria-describedby="basic-addon2"
                                                        name="name"
                                                        type="text"
                                                        value={item.name}
                                                        onChange={e => handleInputChange(e, i)}
                                                    />
                                                </div>}
                                    </div>
                                    <div className='col-md-6 '>
                                        <div className='form-group'>
                                            <input
                                                className="form-control inputLable"
                                                placeholder=" Description "
                                                aria-describedby="basic-addon2"
                                                name="description"
                                                type="text"
                                                value={item.description}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </div>
                                    </div>
                                </div>

                            )
                        })}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-5'>
                        <button className='primaryBtnOuter bg-light' onClick={handleAddClick}>
                            <i className="las la-plus me-2"></i>
                            Add more attributes
                        </button>
                    </div>
                </div>
                <p className='storeText  mt-4'>
                    {error ?
                        <i>{error}</i>
                        : ""}
                </p>
                {/* <div className='row mt-auto justify-content-end'>
                    <div className='col-md-3'>
                        <button className='primary_btn pt-3 pb-3' onClick={() => handleUpdate()}>
                            {props.value.length > 0 ? <> Updated </> : <> save</>}</button>
                    </div>
                </div> */}
            </div>
            <div className=' modalfooterBtn'>
                <button className='me-4 w-100 btnDiscard'>Discard</button>
                <button className='w-100 btnSave' onClick={() => handleUpdate()} type="submit">
                    &nbsp;&nbsp;
                    <span>Save</span>
                </button>
            </div>
        </>
    )
}

export default AddAttributesModal