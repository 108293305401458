import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component'
import { calendar, user, locationBlue, moneyIcon, profileImg1 } from '../../../utilities/images'
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { getPosUsersAsync, selectLoginAuth } from '../auth/authSlice';
import { getAppointemntApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomLoader from '../../components/shared/CustomLoader';
import CustomModal from '../../components/shared/CustomModal';
import AppointSchedule from '../myAppointments/todayAppointments/appointSchedule';
import DashboardNavbar from '../dashboard/DashboardNavbar';

const CompletedBooking = () => {
    const location = useLocation();
    const { flag, status } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const [posList, setPosList] = useState([]);
    const [appointmentData, setAppointmentData] = useState([]);
    const [singleOrderData, setSingleOrderData] = useState({});
    const [key, setKey] = useState(Math.random());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [paymentFilter, setPaymentFilter] = useState("")
    const [posUserName, setPosUserName] = useState("")
    const [statusFilter, setStatusFilter] = useState(location?.state ? location?.state : "")
    const [orderFilter, setOrderFilter] = useState("")

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    function addThousandSeparator(number) {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePaymentFilter = (e) => {
        setPaymentFilter(e?.target.value)
    }

    const handlePosUserFilter = (e) => {
        setPosUserName(e?.target.value)
    }

    const handleStatusFilter = (e) => {
        setStatusFilter(e?.target.value)
    }

    const handleOrderFilter = (e) => {
        setOrderFilter(e?.target.value)
    }

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const columns1 = [
        {
            name: 'Date/Time',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className=' text-left'>
                            <span className='textParagh'>{moment(row?.date).format("MM/DD/YYYY | ")}<span className="">{row?.start_time + "-" + row?.end_time}</span></span>
                        </div>
                    </>
                );
            },
            width: "270px",
        },
        {
            name: "Customer",
            selector: row => {
                return (
                    <>
                    {
                        row?.user_details ?
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.user_details?.profile_photo ? row?.user_details?.profile_photo : profileImg1} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ fw-semibold'>{row?.user_details?.firstname + " " + row?.user_details?.lastname}</span>
                                <p className='mb-0'>
                                    <img src={locationBlue} className='img-fluid me-2 custImg' />
                                    <span className='contentSmallTxt fw-semibold'>{row?.user_details?.current_address?.city},{row?.user_details?.current_address?.country}</span>
                                </p>
                            </div>
                        </div>:<div className='d-flex align-items-center'>Not Available</div>}
                    </>
                );
            },
            width: "300px",
        },
        {
            name: "Staff",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.pos_user_details?.user?.user_profiles?.profile_photo ? row?.pos_user_details?.user?.user_profiles?.profile_photo : profileImg1} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ fw-semibold'>{row?.pos_user_details?.user?.user_profiles?.firstname + " " + row?.pos_user_details?.user?.user_profiles?.lastname}</span>
                                <p className='mb-0 text-left' >
                                    {/* <img src={locationBlue} className='img-fluid me-2 custImg' /> */}
                                    <span className='contentSmallTxt fw-semibold'>{row?.pos_user_details?.is_staff_member === true ? "Staff" : "Non-staff"}</span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "200px",
        },
        {
            selector: row => row?.user_details?.current_address?.city,
            name: 'Location',
            width: "150px",
        },
        {
            selector: row => row?.invoices?.invoice_number,
            name: 'Invoice No.',
            width: "150px",
        },
        {
            selector: row => row?.app_name === "pos" ? "In-Store" : "Online",
            name: 'Order Type',
            width: "150px",
        },
        {
            selector: row => row?.mode_of_payment?.toUpperCase(),
            name: 'Payment Method',
            width: "170px",
        },
        {
            selector: row => row?.product_name,
            name: 'Services',
            width: "150px",
        },
        {
            selector: row => row?.approx_service_time + " min",
            name: 'Duration',
            width: "150px",
        },
        // {
        //     selector: row => row?.status === 3 ? '$' + (row?.tips ? row?.tips : 0) : "",
        //     name: 'Tips',
        //     width: "150px",
        // },
        // {
        //     selector: row => row?.status === 3 ? '$' + (row?.tax ? row?.tax : 0) : "",
        //     name: 'Tax',
        //     width: "150px",
        // },
        {
            selector: row => '$' + addThousandSeparator(row?.price),
            name: 'Total Amount',
            width: "150px",
        },
        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div className='ms-2 tableBtn__'>
                            <div className='col-lg-6 text-end'>
                                {
                                    row?.status === 3 ?
                                        <div className='stoclRight'>
                                            <button className='acceptBtn_ me-2' type="submit" disabled>
                                                <span>Completed</span>
                                            </button>
                                        </div> :
                                        row?.status === 4 ?
                                            <div className='stoclRight'>
                                                <button className='btn btn-danger' type="submit" disabled>
                                                    <span>Rejected</span>
                                                </button>
                                            </div>:
                                            row?.status === 5 ?
                                            <div className='stoclRight'>
                                                <button className='btn btn-danger' type="submit" disabled>
                                                    <span>Cancelled</span>
                                                </button>
                                            </div>
                                            : <></>
                                }
                            </div>

                        </div >
                    </>
                );
            },
            width: "150px"
        }
    ];

    const handleRowClick = (e) => {
        setSingleOrderData(e)
        if (singleOrderData) {
            setModalDetail({ show: true, flag: "PendingInvoiceModal" });
            setKey(Math.random())
        }
    }

    const handleAppointmentData = () => {
        let params = {
            "postData": {
                page: currentPage,
                limit: perPage,
                status: "3,4,5",
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate != null && endDate != null) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        if (paymentFilter) {
            let newData = {
                ...params.postData,
                "transaction_type": paymentFilter
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        if (statusFilter) {
            let newData = {
                ...params.postData,
                "status": statusFilter
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        if (posUserName) {
            let newData = {
                ...params.postData,
                "pos_user_id": posUserName
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        if (orderFilter) {
            let newData = {
                ...params.postData,
                "app_name": orderFilter
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAppointemntApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAppointmentData(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        let params = {
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                need_staff_member: true
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getPosUsersAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                const modifiedData = obj.payload.pos_staff.map((item) => ({
                    value: `${item?.user?.user_profiles?.firstname ? item?.user?.user_profiles?.firstname : ""} ${item?.user?.user_profiles?.lastname ? item?.user?.user_profiles?.lastname : ""}`,
                    id: item?.user?.unique_uuid
                }));
                setPosList(modifiedData)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        handleAppointmentData()
    }, [endDate, perPage, currentPage, paymentFilter, statusFilter, posUserName, orderFilter])

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Bookings";
      }, []);

    return (
        <>
            {/* {loading === true ?
                // <CustomLoader /> 
                <span className="spinner-border inner-spin spinner-border-sm"></span>
                : */}
            <>
                <DashboardNavbar title="Appointments" show={true} />
                <div className='tableSectionBackground appointsection_'>

                    <div className='fulfilledAppointment_ '>
                        {/* <div className='topHeader_'>
                        <div className="topStickyHeader_">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <h2 className="mainHeading">Appointments</h2>
                                </div>

                            </div>
                        </div>
                    </div> */}


                        <div className='tableFilters mt-0'>
                            <div className='productMainCategory'>
                                <div className='categroyBox'>
                                    <div className="calenderIcon">
                                        <img src={calendar} alt="calender" />
                                        {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                            dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                        <DatePicker
                                            className='cateDate'
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            placeholderText="Date"
                                        />
                                    </div>
                                </div>
                                <div className='categroyBox'>
                                    <select className="cateSelectdFilter" value={orderFilter} required onChange={(e) => handleOrderFilter(e)}>
                                        <option value="" selected>
                                            All Orders
                                        </option>
                                        <option value="pos">In-Store</option>
                                        <option value="b2c">Online</option>
                                    </select>
                                </div>
                                <div className='categroyBox'>
                                    <select className="cateSelectdFilter" value={statusFilter} required onChange={(e) => handleStatusFilter(e)}>
                                        <option value="" selected>
                                            All Status
                                        </option>
                                        <option value="3">Completed</option>
                                        <option value="4,5">Cancelled</option>
                                    </select>
                                </div>
                                {/* <div className='categroyBox'>
                                <select className="cateSelectdFilter" required>
                                    <option value="" selected>

                                        All Locations
                                    </option>
                                    <option>L1</option>
                                    <option>L2</option>
                                </select>
                            </div> */}
                                <div className='categroyBox'>
                                    <select className="cateSelectdFilter" value={paymentFilter} required onChange={(e) => handlePaymentFilter(e)}>
                                        <option value="" selected>
                                            All Payment
                                        </option>
                                        {/* <option value='jbr'>JOBR Wallet</option> */}
                                        <option value='cash'>Cash</option>
                                        <option value='card'>Cards</option>
                                        <option value='card'>Gift Cards</option>
                                    </select>
                                </div>
                                <div className='categroyBox'>
                                    <select className="cateSelectdFilter" required value={posUserName} onChange={(e) => handlePosUserFilter(e)}>
                                        <option value="" selected>
                                            All Staffs
                                        </option>
                                        {
                                            posList?.map((v, i) => {
                                                return (
                                                    <option value={v?.id}>{v?.value}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='recentTransections mt-5 table-responsive'>
                        <DataTable
                            pagination
                            columns={columns1}
                            data={appointmentData}
                            defaultSortFieldId={1}
                            customStyles={customStyles}
                            className="fullApointment"
                            onRowClicked={handleRowClick}
                            progressPending={loading}
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={perPage}
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                        />
                    </section>
                </div>
            </>
            {/* } */}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={modalDetail.flag === "PendingInvoiceModal" ? "pendingModal" : ""}
                className={modalDetail.flag === "PendingInvoiceModal" ? "commonWidth" : ""}
                child={
                    modalDetail.flag === "PendingInvoiceModal" ? (
                        <AppointSchedule data={singleOrderData} close={() => handleOnCloseModal()} />
                    ) :

                        (
                            ""
                        )
                }
                header={
                    <>
                        {modalDetail.flag === 'PendingInvoiceModal'
                            ?
                            <>
                                <div className="modalHeader_">
                                    <div className="common_">
                                        <h3 className='modalHeading_'>Invoice#{singleOrderData?.invoices?.invoice_number}</h3>

                                        <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                            <i className="las la-times" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>




                            </> : <></>
                        }
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default CompletedBooking;