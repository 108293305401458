import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DataTable from 'react-data-table-component'

import { user, redMapimg, locationBlue, shareMap, downlight, profileImg1, subtractImg } from '../../../utilities/images'

import products from "../../db/category.json"
import ChartArea from '../dashboard/wallet/ChartArea'
import { changeAppointmentStatusApiAsync, getAppointemntApiAsync, getAppointmentCountApiAsync, getAppointmentGraphApiAsync } from '../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import CustomLoader from '../../components/shared/CustomLoader'
import AppointSchedule from './todayAppointments/appointSchedule'
import CustomModal from '../../components/shared/CustomModal'


const MyAppointments = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [appointmentData, setAppointmentData] = useState([]);
    const [appointmentCountData, setAppointmentCountData] = useState([]);
    const [appointmentGraphData, setAppointmentGraphData] = useState([]);
    const [filterValue, setFilterValue] = useState("This week");
    const [singleOrderData, setSingleOrderData] = useState('');
    const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleFilterValue = (value) => {
        setFilterValue(value);
    }

    const options1 = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    }


    const lineData = {
        labels: appointmentGraphData?.labels,
        datasets: [
            {
                label: 'Appointments',
                data: appointmentGraphData?.datasets ? appointmentGraphData?.datasets[0]?.data : "",
                borderColor: '#1CD3FF',
                backgroundColor: '#1CD3FF',
            },
            {
                label: 'Completed',
                data: appointmentGraphData?.datasets ? appointmentGraphData?.datasets[1]?.data : "",
                borderColor: '#275AFF',
                backgroundColor: '#275AFF',
            },
        ],
    };
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };

    const columns1 = [
        {
            name: '#',
            selector: row => row?.id,
            width: "70px"
        },
        {
            name: "User",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.user_details?.profile_photo ? row?.user_details?.profile_photo : profileImg1} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ '>{row?.user_details?.firstname + " " + row?.user_details?.lastname}</span>
                                <p className='mb-0'>
                                    <img src={locationBlue} className='img-fluid me-2 custImg' />
                                    <span className='contentSmallTxt'>{row?.user_details?.current_address?.city},{row?.user_details?.current_address?.country}</span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "300px",
        },
        {
            selector: row => row?.product_name,
            name: 'Service Name',
            width: "150px",
        },
        {
            name: 'Date',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2 text-center'>
                            <span className='textParagh'>{moment(row?.date).format("dddd MMMM DD, YYYY")}<span className="hightlightSmall">{row?.start_time + "-" + row?.end_time}</span></span>
                        </div>
                    </>
                );
            },
            width: "370px",
        },
        // {
        //     name: 'Status',
        //     selector: row => {
        //         return (
        //             <>
        //                 <div className='ms-2 tableBtn__ text-end'>
        //                     <Link to="#" className='innerBtn_'>Next</Link>
        //                 </div>
        //             </>
        //         );
        //     },
        //     width: "100px"
        // },
        {
            name: 'Action',
            selector: row => {
                return (
                    <>
                        <div className='ms-2 tableBtn__'>
                            <div className='col-lg-6 text-end'>
                                <div className='stoclRight'>
                                    <button onClick={() => changeStatusOfAppointmentHandle(row?.id, 3)} className='declineBtn me-3' type="submit" disabled={loading1}>
                                        {loading1 && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        <span>Decline</span>
                                    </button>
                                    <button onClick={() => changeStatusOfAppointmentHandle(row?.id, 1)} className='acceptBtn_ me-2' type="submit" disabled={loading2}>
                                        {loading2 && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        <span>Accept</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </>
                );
            },
            width: "170px"
        },
        // {
        //     name: '',
        //     selector: row => {
        //         return (
        //             <>
        //                 <div className='ms-2 tableBtn__'>
        //                     {/* <i className="fal fa-chevron-right ms-3"> */}
        //                     {/* <span onClick={() => ExpandedComponent("")}></span> */}
        //                     {/* </i> */}

        //                 </div>
        //             </>
        //         );
        //     },
        //     width: "100px"
        // }
    ];

    const changeStatusOfAppointmentHandle = (id, status) => {
        let orderStatus = {
            "postData": {
                id: id,
                status: status
            },
            "tokenData": auth?.payload?.token
        }

        status === 3 ? setLoading1(true) : setLoading2(true)
        dispatch(changeAppointmentStatusApiAsync(orderStatus))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    "postData": {
                        // page: 1,
                        // limit: 10,
                        status: 0,
                        need_upcoming: true,
                        filter_by: filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
                        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getAppointemntApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setAppointmentData(obj?.payload?.data)
                        setLoading1(false)
                        setLoading2(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading1(false)
                        setLoading2(false)
                    })

            }
            )
            .catch((obj) => {
                setLoading1(false)
                setLoading2(false)
            })
    }

    const ExpandedComponent = (suppData) => ({ data }) => {
        setSingleOrderData(data)
        return (
            <>
                <pre className='preaddBox'>
                    <div className='stockaAvailabilit'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div className='stoclLeft'>
                                    <span>Items Stock availability:</span>
                                    <span className='allText'>All <img className='subtractImg' src={subtractImg} /></span>
                                </div>
                            </div>
                            <div className='col-lg-6 text-end'>
                                <div className='stoclRight'>
                                    <button onClick={() => changeStatusOfAppointmentHandle(data?.id, 3)} className='declineBtn me-3' type="submit" disabled={loading1}>
                                        {loading1 && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        <span>Decline</span>
                                    </button>
                                    <button onClick={() => changeStatusOfAppointmentHandle(data?.id, 1)} className='acceptBtn_ me-2' type="submit" disabled={loading2}>
                                        {loading2 && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        <span>Accept</span>
                                    </button>
                                    <button className='viewDetailBtn_' data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                                        setModalDetail({ show: true, flag: "PendingInvoiceModal" });
                                        setKey(Math.random());
                                    }}>View Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </pre>
            </>
        );
    };

    const handleRowClick = (e) => {
        setSingleOrderData(e)
        setModalDetail({ show: true, flag: "PendingInvoiceModal" });
        setKey(Math.random());
    }

    const appointmentCountHandle = () => {
        let params = {
            "postData": {
                filter_by: filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getAppointmentCountApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAppointmentCountData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const appointmentGraphHandle = () => {
        let params = {
            "postData": {
                filter_by: filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token,

        }
        setLoading(true)
        dispatch(getAppointmentGraphApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAppointmentGraphData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const handleAppointmentData = () => {
        let params = {
            "postData": {
                status: 0,
                // need_upcoming: true,
                // filter_by: filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getAppointemntApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAppointmentData(obj?.payload?.data)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        handleAppointmentData()
        appointmentCountHandle()
        appointmentGraphHandle()
    }, [filterValue])

    return (
        <>
            {loading === true ?
                // <CustomLoader /> 
                <span className="spinner-border inner-spin spinner-border-sm"></span>
                :
                <div className='myOrdersmain'>
                    <div className='topHeader_'>
                        <div className="topStickyHeader_ mb-3">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <h2 className="mainHeading">
                                        <span>Appointments</span>
                                    </h2>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-12 text-end'>
                            <div className="dropdown weekDropdown">
                                <button className="dropdown-toggle cateSelectd" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {filterValue} <img src={downlight} alt="img" />
                                </button>
                                <ul className="dropdown-menu weekDropdownMenus">
                                    <li><a className="dropdown-item" onClick={() => handleFilterValue("Today")} >Today</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleFilterValue("This week")} >This week</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleFilterValue("Month")}> Month</a></li>
                                    <li><a className="dropdown-item" onClick={() => handleFilterValue("Year")}> Year</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='verificationTotal'>
                        <div className='row'>
                            {appointmentCountData?.length > 0 ?
                                appointmentCountData?.map((v, i) => {
                                    return (
                                        <div key={i} className='col-sm-6 col-md-3 col-lg-3 customSpce'>
                                            <div className='percentageContainer justify-content-between align-items-center'>
                                                <div className='percentageValues'>
                                                    <h3 className='mainHeadingsub_ '>{v?.value}</h3>
                                                    <p className='textinner_'>{v?.name}</p>
                                                </div>
                                                <div className={v?.name === "Cancelled" ? 'percentageRating redRating mapbg' : 'percentageRating mapbg'}>
                                                    <img src={v?.name === "Cancelled" ? redMapimg : shareMap} className="" alt='ShareMap' />
                                                    <p className='percentageRate'>{v?.percentageChange?.toFixed(2)}%</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <></>
                            }
                        </div>
                    </div>
                    <div className='tradesValue mt-4 mb-4'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='chartsOuter'>
                                    <h3 className="textInnerHead mt-3">New Users</h3>
                                    <div className='processStatus mt-3'>
                                        <p className='statusbox_'><span className='appointment_'></span> Appointments</p>
                                        <p className='statusbox_'> <span className='apointComplete'></span> Completed </p>
                                    </div>
                                    <div className='row mt-4 mb-4 align-items-center'>

                                        <div className='col-md-12'>
                                            <ChartArea classes="productSales mt-2 mb-2" header="" options={options1} data={lineData} chartType="Line" />
                                            {/* <img src='' alt="Graph Image" className='img-fluid' /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className='recentTransections mt-5 table-responsive'>
                        <h3 className='textInnerHead mb-4'>Recent Appointments</h3>
                        <DataTable
                            pagination
                            columns={columns1}
                            data={appointmentData}
                            defaultSortFieldId={1}
                            customStyles={customStyles}
                            // expandableRows
                            onRowClicked={handleRowClick}
                            // expandableRowsComponent={ExpandedComponent("")}
                            className="newOrderTable"
                        />
                    </section>
                </div>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "PendingInvoiceModal" ? "commonWidth customContent" : ""}
                child={
                    modalDetail.flag === "PendingInvoiceModal" ? (
                        <AppointSchedule data={singleOrderData} close={() => handleOnCloseModal()} />
                    ) :

                        (
                            ""
                        )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )

}

export default MyAppointments