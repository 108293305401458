import React, { useEffect, useState } from "react";
import * as Images from '../../../../utilities/images';
// import Image from "next/image";
import { unwrapResult } from '@reduxjs/toolkit';
// import Pagination from "../../components/commanComonets/pagination";
import { useDispatch, useSelector } from "react-redux";
import { searchByInvoiceIdApiAsync, selectReturnData, searchBySkuApiAsync, setInvoiceData, returnEmpty } from '../../myOrders/returnOrder/returnOrderSlice';
import {
  // searchBySKU,
  // searchInvoiceByInvoiceId,
  // selectReturnData,
  // setInvoiceData,
  // setSearchInvoiceByInvoiceId,
} from '../../dashboard/dashboardSlice';
// import { selectLoginAuth } from "../../redux/slices/auth";
import { selectLoginAuth } from '../../auth/authSlice';
import moment from "moment-timezone";
// import { useRouter } from "next/router";
import { toast } from "react-toastify";
import CustomModal from '../../../components/shared/CustomModal';
import Manualinvoice from "./manual-entry(search)";
import {
  amountFormat,
  formattedReturnPrice
} from "../../../../utilities/globalMethods";
import useDebounce from "../../../hooks/useDebounce";

import ReturnInvoice from "./ReturnInvoice";
import NoInvoiceSelected from "./NoInvoiceSelected";
import OriginalInvoice from "./OriginalInvoice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DashboardNavbar from "../../dashboard/DashboardNavbar";

const OrderReturn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const router = useRouter();
  // const showInvoiceData = router?.query?.["showInvoiceData"];
  const showInvoiceData = ""

  const authData = useSelector(selectLoginAuth);
  const posData = authData?.payload;
  const merchentDetails = authData?.payload?.user?.user_profiles;
  console.log(authData,"sssssssssssssssssssssssssss");
  const sellerId = authData?.payload?.uniqe_id;
  const [searchInvoiceViaBarcode, setSearchInvoiceViaBarcode] = useState("");
  const debouncedSearchSKU = useDebounce(searchInvoiceViaBarcode, 1000);
  const invoiceData = useSelector(selectReturnData);
  const SearchInvoiceRespones = invoiceData?.invoiceByInvoiceId?.payload;
  // const isReturnOrder =
  //   SearchInvoiceRespones?.order_id == null && !!SearchInvoiceRespones?.return;
  const isReturnOrder =   SearchInvoiceRespones?.order_id == null && !!SearchInvoiceRespones?.return;

  const returnData = SearchInvoiceRespones?.return;
  const returnProductArray = returnData?.return_details;
  const orderDetails = SearchInvoiceRespones?.order;
  const TAX_PERCENTAGE = 0.08;
  const originalOrderArray = orderDetails?.order_details;
  const [searchKeyword, setSearchKeyword] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [checkeddata, setCheckedData] = useState("");
  const [productDetails, setProductDetails] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [calculateDiscountPrice, setCalculateDiscountPrice] = useState(0);
  const [getDiscountedPrice, setGetDiscountedPrice] = useState(0);

  const hasAllProductsReturned =
    SearchInvoiceRespones?.order?.order_details?.every(
      (obj) => obj?.qty - obj?.returned_qty === 0
    );

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  useEffect(() => {
    if (SearchInvoiceRespones?.order?.order_details) {
      setProductDetails(
        SearchInvoiceRespones?.order?.order_details?.map((item) => ({
          ...item,
          isChecked: false,
          actual_price: `${item?.offer_price != "0"
            ? item?.offer_price / item?.qty
            : item?.actual_price
            }`,
        }))
      );
    }
  }, [SearchInvoiceRespones?.order?.order_details, checkeddata]);

  const handleSearchInvoice = (invoiceNumber) => {

    let params = {
      "postData": {
        invoiceId: invoiceNumber,
        seller_id: sellerId,

      },
      "tokenData": authData?.payload?.token
    }
    if (
      invoiceNumber.includes("Invoice_") ||
      invoiceNumber.includes("invoice_") ||
      invoiceNumber.includes("rtrn_invce_")
    ) {
      params.postData.isScanned = true;
    } else {
      params.postData.isScanned = false;
    }
    dispatch(searchByInvoiceIdApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {

      }
      )
      .catch((obj) => {

      })

  };
  useEffect(() => {
    if (searchKeyword && typeof searchKeyword != "undefined") {
      setIsSearching(true);
      const search = setTimeout(() => {
        //Your search query and it will run the function after 3secs from user stops typing
        var keyword = searchKeyword.toLowerCase();
        handleSearchInvoice(keyword);
      }, 2000);
      return () => clearTimeout(search);
    } else {
    }
  }, [searchKeyword]);

  // Calculate percentage
  const calculateDiscountPercentage =
    (orderDetails?.discount / orderDetails?.total_sale_price) * 100;

  const handleGoToNext = () => {
    const selectedProductItems = productDetails?.filter(
      (item) => item?.isChecked
    );
    if (selectedProductItems?.length > 0) {
      history.push("/productrefunds");

      const shareData = {
        selectedItems: JSON.stringify(selectedProductItems),
        calculatedDiscountPercentagePerItem: calculateDiscountPercentage,
        calculatedDiscountPrice: calculateDiscountPrice,
      };
      dispatch(setInvoiceData(shareData));
    } else {
      toast.error("Please select products to refund!");
    }
  };

  const deliveryShippingChargesForNormalSearch = () => {
    let deliveryCharges;
    let title;
    if (orderDetails?.delivery_charge !== "0") {
      deliveryCharges = orderDetails?.delivery_charge;
      title = "Delivery Charges";
    } else if (orderDetails?.shipping_charge !== "0") {
      deliveryCharges = orderDetails?.shipping_charge;
      title = "Shipping Charges";
    } else {
      title = "";
      deliveryCharges = "0";
    }
    return { title, deliveryCharges };
  };

  // Calculate subtotal
  const calculateSubtotal = productDetails?.reduce((accu, item) => {
    return (
      accu + parseFloat(item?.actual_price * (item.qty - item.returned_qty))
    );
  }, 0);

  useEffect(() => {
    // Calculate discount price
    const newCalculateDiscountPrice =
      (calculateSubtotal * calculateDiscountPercentage) / 100;
    setCalculateDiscountPrice(newCalculateDiscountPrice);

    // Calculate discounted price
    const newGetDiscountedPrice = calculateSubtotal - newCalculateDiscountPrice;
    setGetDiscountedPrice(newGetDiscountedPrice);
  }, [productDetails, orderDetails]);

  // Calculate total tax
  const totalTax = getDiscountedPrice * TAX_PERCENTAGE;

  // Calculate other charges
  const otherCharges =
    parseFloat(orderDetails?.tips || 0) +
    parseFloat(deliveryShippingChargesForNormalSearch().deliveryCharges || 0);

  // Calculate total amount
  const calculateTotalAmount = getDiscountedPrice + totalTax + otherCharges;

  // Object containing calculated amounts
  const getCalculatedAmount = {
    subTotal: productDetails ? calculateSubtotal : 0,
    totalTax: totalTax || 0,
    totalAmount: calculateTotalAmount || 0,
  };

  const updateQuantity = (operation, index) => {
    const orderData = orderDetails?.order_details[index];
    const currentQty =
      productDetails[index]?.qty - productDetails[index]?.returned_qty;
    const maxAllowedQty = orderData?.qty - orderData?.returned_qty;
    if (operation == "-" && currentQty > 1) {
      setProductDetails((products) => {
        // Clone the product at the specified index
        let updatedProducts = [...products];

        // Clone the product object at the specified index
        let productToUpdate = { ...updatedProducts[index] };

        // Update the quantity
        productToUpdate.qty -= 1;

        // Update the product at the specified index
        updatedProducts[index] = productToUpdate;
        return updatedProducts;
      });
    }

    if (operation === "+" && currentQty < maxAllowedQty) {
      setProductDetails((products) => {
        // Clone the product at the specified index
        let updatedProducts = [...products];

        // Clone the product object at the specified index
        let productToUpdate = { ...updatedProducts[index] };

        // Update the quantity
        productToUpdate.qty += 1;

        // Update the product at the specified index
        updatedProducts[index] = productToUpdate;
        return updatedProducts;
      });
    }
  };

  const handleCheckboxChange = (data) => {
    const updatedProductDetails = productDetails?.map((item) =>
      data?.product_id === item?.product_id
        ? { ...item, isChecked: item?.isChecked ? false : true }
        : item
    );
    setProductDetails(updatedProductDetails);
  };

  const handleGoToManualEntry = () => {
    setModalDetail({ show: true, flag: "manualEntry" });
    setKey(Math.random());
  };

  useEffect(() => {
    if (debouncedSearchSKU) {
      let params = {
        "postData": {
          app_name: "pos",
          search: searchInvoiceViaBarcode,
          seller_id: sellerId,
        },
        "tokenData": authData?.payload?.token
      }
      dispatch(searchBySkuApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setCheckedData(obj?.payload?.product_detail);
        }
        )
        .catch((obj) => {
        })
    }
  }, [debouncedSearchSKU]);

  const handleSacnBarcode = (e) => {
    const enteredValue = e.target.value;
    if (enteredValue.length <= 15) {
      setSearchInvoiceViaBarcode(enteredValue);
    }
  };

  const deliveryShippingCharges = () => {
    let deliveryCharges;
    let title;
    if (returnData?.delivery_charge !== "0") {
      deliveryCharges = returnData?.delivery_charge;
      title = "Delivery Charges";
    } else if (returnData?.shipping_charge !== "0") {
      deliveryCharges = returnData?.shipping_charge;
      title = "Shipping Charges";
    } else {
      title = "";
      deliveryCharges = "0";
    }
    return { title, deliveryCharges };
  };

  useEffect(() => {
    if (checkeddata) {
      const updatedProductDetails = productDetails?.map((item) =>
        checkeddata?.id === item?.product_id
          ? { ...item, isChecked: true }
          : item
      );
      setProductDetails(updatedProductDetails);
    }
  }, [checkeddata]);

  useEffect(() => {
    if (
      !(showInvoiceData && showInvoiceData == "true") &&
      SearchInvoiceRespones
    ) {
      dispatch(returnEmpty());
    } else if (SearchInvoiceRespones?.invoice_number) {
      setSearchKeyword(SearchInvoiceRespones.invoice_number);
    }
  }, []);

  const { sumQtyPrice } = returnProductArray
    ? returnProductArray.reduce(
      (acc, item) => {
        const qty = Number(item?.order_details?.qty) || 0;
        const price = Number(item?.order_details?.price) || 0;

        acc.sumQtyPrice += qty * price;

        return acc;
      },
      { sumQtyPrice: 0 }
    )
    : { sumQtyPrice: 0 };

  const sumTax = returnProductArray?.reduce((acc, item) => {
    const price = Number(item?.order_details?.price) || 0;
    const tax = 0.08 * price; // 8% tax
    return acc + tax;
  }, 0);

  const refundAmountSubTotal = returnData?.return_details?.reduce(
    (acc, item) => {
      const totalAmount = acc + item.refunded_amount * item.returned_qty;
      return totalAmount;
    },
    0
  );
  return (
    <>
      <DashboardNavbar title="Product Refund" backShow={false} />
      <div className="productInvoice">
        <div className="row">
          <div className="col-lg-6">
            <div className="commanOuter">
              <div class="searchBar">
                <input
                  type="text"
                  class="ps-5 form-control searchControl"
                  placeholder="Search here the # of invoice"
                  value={searchKeyword}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                />
                <figure className="scanBox">
                  <img
                    src={Images.scanImg}
                    alt="ScanIcon"
                    className="img-fluid "
                  />
                </figure>
                <img
                  src={Images.search_light}
                  alt="SearchImageIcon"
                  className="img-fluid searchImg"
                />
              </div>
              <div className="invoiceHeader">
                <p className="innerHeading">
                  Invoices
                  <span className="productCount">
                    (#{SearchInvoiceRespones?.invoice_number})
                  </span>
                </p>
              </div>
              <div className="commanscrollBar InvoiceTableBox">
                <div className="table-responsive">
                  <table id="invoiceProduct" className="product_table">
                    <thead className="invoiceHeadingBox">
                      <tr>
                        <th className="invoiceHeading"># Invoice</th>
                        <th className="invoiceHeading">Customer</th>
                        <th className="invoiceHeading">Sale</th>
                        <th className="invoiceHeading">Items</th>
                        <th className="invoiceHeading">Price</th>
                        <th className="invoiceHeading"></th>
                      </tr>
                    </thead>

                    {SearchInvoiceRespones ? (
                      <tbody>
                        <tr className="product_invoice active">
                          <td className="invoice_subhead">
                            #{SearchInvoiceRespones?.invoice_number}
                          </td>
                          <td className="invoice_subhead">
                            <div className="">
                              <img
                                src={Images.jokerImg}
                                alt="tableImg"
                                className="costumerImg"
                              />
                              <span>
                                {SearchInvoiceRespones?.order?.user_details
                                  ?.user_profiles?.firstname
                                  ? SearchInvoiceRespones?.order?.user_details
                                    ?.user_profiles?.firstname
                                  : "NA"}
                              </span>
                            </div>
                          </td>
                          <td className="invoice_subhead">
                            <div className="inStoreBtn">
                              <img
                                src={Images.storeImg}
                                alt="store"
                                className="storeimg"
                              />
                              <span>
                                {SearchInvoiceRespones?.order
                                  ?.delivery_option === "1"
                                  ? "Delivery"
                                  : SearchInvoiceRespones?.order
                                    ?.delivery_option === "2"
                                    ? "Reservation"
                                    : SearchInvoiceRespones?.order
                                      ?.delivery_option === "3"
                                      ? "Instore"
                                      : SearchInvoiceRespones?.order
                                        ?.delivery_option === "4"
                                        ? "Shipping"
                                        : "Instore"}
                              </span>
                            </div>
                          </td>
                          <td className="invoice_subhead">
                            {isReturnOrder
                              ? SearchInvoiceRespones?.return?.return_details
                                ?.length
                              : 
                              // SearchInvoiceRespones?.order?.total_items
                              productDetails?.filter(data => data?.qty - data?.returned_qty != 0)?.length}
                          </td>

                          <td className="invoice_subhead">
                            <div className="priceBtn">
                              <img
                                src={Images.moneyImg}
                                alt="money"
                                className="moneyImg"
                              />
                              <span>
                                {console.log(SearchInvoiceRespones,  amountFormat(getCalculatedAmount.totalAmount),"ddddddddddddddddddddddddddd")}

                                {}
                                {amountFormat(
                                  isReturnOrder
                                    ? SearchInvoiceRespones?.return
                                      ?.refunded_amount
                                    : 
                                    // SearchInvoiceRespones?.order?.payable_amount
                                    getCalculatedAmount.totalAmount
                                )}

                              </span>
                            </div>
                          </td>

                          <td className="invoice_subhead">
                            <img
                              src={Images.arrowIcon}
                              alt="arrows"
                              className="arrowRight_"
                            />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={6}>
                            <p className="noInvoiceText my-3">
                              No Invoices Found
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              {/* <Pagination /> */}
            </div>
          </div>
          {/* <ReturnInvoice
            {...{ merchentDetails, returnProductArray, returnData }}
          /> */}

{console.log(SearchInvoiceRespones, hasAllProductsReturned, isReturnOrder, "invoice responssssssssssseeeeeee")}
          {hasAllProductsReturned ? (
            <OriginalInvoice
              {...{
                merchentDetails,
                originalOrderArray,
                orderDetails,
                posData,
                deliveryShippingCharges: deliveryShippingCharges(),
              }}
            />
          ) : isReturnOrder ? (
            <ReturnInvoice
              {...{
                merchentDetails,
                returnProductArray,
                returnData,
                posData,
                refundAmountSubTotal,
                deliveryShippingCharges:
                  deliveryShippingChargesForNormalSearch(),
              }}
            />
          ) : SearchInvoiceRespones ? (
            <div className="col-lg-6">
              <div className="commanOuter">
                <div className="d-flex justify-content-between mb-3">
                  <figure className="costumerImgout">
                    <img
                      src={Images.jokerImg}
                      alt="tableImg"
                      className="costumerImg"
                    />
                    <span className="innerHeading ps-2">Customer</span>
                  </figure>
                  <figure className="">
                    <img
                      src={Images.storeImg}
                      alt="store"
                      className="storeimg"
                    />
                    <span className="innerHeading px-2">
                      {" "}
                      {SearchInvoiceRespones?.order?.delivery_option == "1"
                        ? "Delivery"
                        : SearchInvoiceRespones?.order?.delivery_option === "2"
                          ? "Reservation"
                          : SearchInvoiceRespones?.order?.delivery_option === "3"
                            ? "In-store"
                            : SearchInvoiceRespones?.order?.delivery_option === "4"
                              ? "Shipping"
                              : "In-store"}
                    </span>
                    <button className="inStoreBtn">
                      {" "}
                      {moment.utc(orderDetails?.date).format("DD/MM/YYYY")}
                    </button>
                  </figure>
                </div>
                <div className="d-flex justify-content-between  invoiceSearchBox pb-3">
                  <div className="SearchinvoiceBox">
                    <div className="ProductsearchBar">
                      <input
                        type="text"
                        className="form-control searchControl"
                        placeholder="Scan Barcode of each Item"
                        value={searchInvoiceViaBarcode}
                        onChange={(e) => handleSacnBarcode(e)}
                      />
                      <img
                        src={Images.scanImg}
                        alt="SearchImageIcon"
                        className="img-fluid scanImg"
                      />
                    </div>
                  </div>
                  <div className="invoiceButtonBox">
                    <button
                      type="button"
                      className="boderdManualButton"
                      onClick={(e) => handleGoToManualEntry(e)}
                    >
                      Manual Entry
                      <img
                        src={Images.plusRound}
                        alt="SearchImageIcon"
                        className="img-fluid ms-2"
                      />
                    </button>
                  </div>
                </div>
                <div className="detailScrollDelivery  mt-3">
                  {productDetails?.length > 0 ? (
                    productDetails?.map((data, idx) => (
                      <>
                        {data?.qty - data?.returned_qty != 0 && (
                          <div className="selectedProductDetails" key={idx}>
                            <div className="d-flex productDataInfo">
                              <figure>
                                <img
                                  src={data?.product_image}
                                  alt="tableImg"
                                  className="costumerImg"
                                  height={100}
                                  width={100}
                                />
                              </figure>
                              <div className="ps-1">
                                <p className="aboutProduct invoiceDataText">
                                  {data?.product_name}
                                </p>
                                <div className="d-flex">
                                  {/* <article className="productColor">
                                <span className="Yellow"></span>
                                <span className="Red"></span>
                                <span className="Pink"></span>
                                <span className="Blue"></span>
                                <span className="Black"></span>
                                <span className="White"></span>
                              </article> */}
                                  {/* <span className="productSize">Colors / Size</span> */}
                                </div>
                              </div>
                            </div>
                            <p className="productPriceinvoice">
                              {amountFormat(data?.actual_price)}
                            </p>
                            <div className="incrementBtn ">
                              <i
                                onClick={() => updateQuantity("-", idx)}
                                className="fa-solid fa-minus plusMinus"
                              ></i>
                              <input
                                className="form-control addBtnControl"
                                type="number"
                                placeholder="1"
                                value={data?.qty - data?.returned_qty}
                              />

                              <i
                                onClick={() => updateQuantity("+", idx)}
                                className="fa-solid fa-plus plusMinus"
                              ></i>
                            </div>
                            <p className="productPriceinvoice">{data?.qty}</p>
                            <p className="productPriceinvoice">
                              {amountFormat(
                                data?.actual_price *
                                (data?.qty - data?.returned_qty)
                              )}
                            </p>
                            <article>
                              <label className="custom-checkbox">
                                <input
                                  type="checkbox"
                                  checked={data?.isChecked}
                                  onChange={() => handleCheckboxChange(data)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </article>
                          </div>
                        )}
                      </>
                    ))
                  ) : (
                    <>
                      {productDetails?.length === 0 ? (
                        <h3 className="mt-3 mb-3">No Data Found!</h3>
                      ) : (
                        <div className="loaderOuter">
                          <div className="spinner-grow loaderSpinner text-center my-5"></div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="OrderBox">
                      <div className="OrderCheckoutBox">
                        <p className="orderHeading">Total Items</p>
                        <p className="orderSubHeading">
                          { productDetails?.filter(data => data?.qty - data?.returned_qty != 0)?.length}
                        </p>
                      </div>
                      <div className="OrderCheckoutBox">
                        <p className="orderHeading">Order Date</p>
                        <p className="orderSubHeading">
                          {moment.utc(orderDetails?.date).format("DD/MM/YYYY")}
                        </p>
                      </div>
                      <div className="OrderCheckoutBox">
                        <p className="orderHeading">Order ID#</p>
                        <p className="orderSubHeading">
                          {SearchInvoiceRespones?.invoice_number}
                        </p>
                      </div>
                      <div className="OrderCheckoutBox">
                        <p className="orderHeading">Payment Method</p>
                        <figure className="priceBtn">
                          <img
                            src={Images.moneyImg}
                            alt="money"
                            className="moneyImg me-2"
                          />
                          <span>{orderDetails?.mode_of_payment}</span>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="productBilling">
                      <div className="OrderDiscountBox">
                        <div className="flexBox ">
                          <p className="orderHeading">Sub Total</p>
                          <p className="orderSubHeading">
                            {amountFormat(getCalculatedAmount.subTotal)}
                          </p>
                        </div>
                        {deliveryShippingChargesForNormalSearch().title !=
                          "" && (
                            <div className="flexBox">
                              <p className="orderHeading">
                                {deliveryShippingChargesForNormalSearch().title}
                              </p>
                              <p className="orderSubHeading">
                                {amountFormat(
                                  deliveryShippingChargesForNormalSearch()
                                    .deliveryCharges ?? "0"
                                )}
                              </p>
                            </div>
                          )}

                        <div className="flexBox">
                          <p className="orderHeading">{"Discount"}</p>
                          <p className="orderSubHeading">
                            -{amountFormat(calculateDiscountPrice)}
                          </p>
                        </div>
                        <div className="flexBox">
                          <p className="orderHeading">Other Fees (Tips)</p>
                          <p className="orderSubHeading">
                            {amountFormat(
                              orderDetails?.tips ? orderDetails?.tips : "0.00"
                            )}
                          </p>
                        </div>
                        <div className="flexBox">
                          <p className="orderHeading">Tax</p>
                          <p className="orderSubHeading">
                            {amountFormat(getCalculatedAmount.totalTax)}
                          </p>
                        </div>
                      </div>
                      <div className="OrderTotal">
                        <div className="flexBox">
                          <p className="priceHeading">Total</p>
                          <p className="priceHeading">
                            {amountFormat(getCalculatedAmount.totalAmount)}
                            {/* ${orderDetails?.payable_amount} */}
                          </p>
                        </div>
                        <button
                          type="button"
                          className="BlueBtn w-100"
                          onClick={(e) => {
                            handleGoToNext(e);
                          }}
                        >
                          Next
                          <img
                            src={Images.ArrowRight}
                            alt="ArrowRight"
                            className="img-fluid ArrowRight"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NoInvoiceSelected />
          )}
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "manualEntry" ? "manualEntry" : "ReturnInventory"
        }
        child={
          modalDetail.flag === "manualEntry" ? (
            <Manualinvoice
              closeManulModal={() => handleOnCloseModal()}
              productDetails={productDetails}
              setCheckedData={setCheckedData}
            />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default OrderReturn;
