import React, { useState } from 'react'
import { dhl } from '../../../../../utilities/images'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../../auth/authSlice';
import { addFedexServiceApiAsync, changeShippingStatusApiAsync, getAllShippingServiceApiAsync } from '../../dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const DhlAccountModal = (props) => {
    const [apiKey, setApiKey] = useState("");
    const [loading, setLoading] = useState(false)
    const [clientSecret, setClientSecret] = useState("")
    const [shippingAccount, setShippingAccount] = useState("")
    const auth = useSelector(selectLoginAuth)
    const toastId = React.useRef(null)
    const dispatch = useDispatch()

    const handleFedexAdd = (e) => {
        e.preventDefault()
        if (!apiKey) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your api key");
            }
            return false
        }
        if (!clientSecret) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your secret key");
            }
            return false
        }
        if (!shippingAccount) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your shipping account");
            }
            return false
        }
        let params = {
            postData: {
                "credential": {
                    "api_key": apiKey,
                    "secret_key": clientSecret,
                    "shipping_account": shippingAccount
                },
                "shipping_service_id": props?.shippingService,
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(addFedexServiceApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                let changeStatusData = {
                    postData: {
                        "is_connected": true,
                        "shipping_service_id": props.shippingService,
                        "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                    },
                    tokenData: auth?.payload?.token
                }
                dispatch(changeShippingStatusApiAsync(changeStatusData))
                    .then(unwrapResult)
                    .then((obj) => {
                        let data = {
                            tokenData: auth?.payload?.token,
                            postData: {
                                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                            }
                        }
                        dispatch(getAllShippingServiceApiAsync(data))
                            .then(unwrapResult)
                            .then((obj) => {
                                setLoading(false)
                                props.setShippingService(obj?.payload)
                                props.close()
                            }
                            )
                            .catch((obj) => {
                                setLoading(false)
                            })
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    return (
        <div className='dhlAccountModal'>
            <h4 className="innerHeading mb-5 text-center">Connect your Fedex account</h4>
            {/* <img src={dhl} className='brandLogos mx-auto d-block' alt='DHL'/> */}

            <form className='form_horizontal row mt-4' onSubmit={(e) => { handleFedexAdd(e) }}>
                <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-3">Api Key</label>
                    <input
                        type="text"
                        className="customform-control"
                        name="api_key"
                        placeholder="api key"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                    />
                </div>
                <div className="mb-1 form-group col-md-12">
                    <label className="deatilsHead mb-3">Client Secret </label>
                    <input
                        className="customform-control id_password"
                        placeholder="client secret"
                        name="client_secret"
                        type="text"
                        value={clientSecret}
                        onChange={(e) => setClientSecret(e.target.value)}
                    />
                </div>
                <div className="mb-1 form-group col-md-12">
                    <label className="deatilsHead mb-3">Shipping Account </label>
                    <input
                        className="customform-control id_password"
                        placeholder="client secret"
                        name="client_secret"
                        type="text"
                        value={shippingAccount}
                        onChange={(e) => setShippingAccount(e.target.value)}
                    />
                </div>
                <div className='form-group col-md-12 text-end'>
                    <a href="https://developer.fedex.com/" target="_blank"><span className='forgot'>Forget Password?</span></a>
                </div>
                <button type="submit" className='btWarning mt-4' disabled={loading}>
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    Connect Fedex
                </button>
                <div className='form-group col-md-12 text-center mt-2'>
                    <p className='subtext_'>
                        Not Registered? <a href="https://developer.fedex.com/" target="_blank" className='subtext_'>Register Now</a>
                    </p>
                </div>
            </form>
        </div>
    )
}

export default DhlAccountModal