import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { emailBlue, phoneBlue, profileImg1 } from '../../../utilities/images';
import { selectLoginAuth } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getProductServiceListApiAsync } from '../myProducts/productSlice';
import { addThousandSeparator } from '../../../utilities/thousandCommaSeparated';

const PosDetailsModal = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const posData = props?.selectedRow;

    const [allServiceData, setAllServiceData] = useState([])

    const serviceHandle = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                type: "digital",
                filter_deleted: true,
                app_name: "merchant"
            }
        }
        setLoading(true)
        dispatch(getProductServiceListApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                let selectedServicdIds = props?.selectedRow?.pos_staff_products?.map(v => v?.product_id)
                let serviceSelected = obj?.payload?.data?.filter(value => selectedServicdIds?.includes(value?.id))
                setAllServiceData(serviceSelected)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        serviceHandle()
    }, [])
    return (
        <>
            {
                loading === true ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className="scheduleModal">

                        <div className="scheduleModalContent">
                            <div className="userProfileinfo_">
                                <img className='scheduleimg me-3' src={posData?.user?.user_profiles?.profile_photo ? posData?.user?.user_profiles?.profile_photo : profileImg1} />
                                <div className="userProfileinforight_">
                                    <h3 className='subHeading'>
                                        {(posData?.user?.user_profiles?.firstname ? posData?.user?.user_profiles?.firstname : "") + " " + (posData?.user?.user_profiles?.lastname ? posData?.user?.user_profiles?.lastname : "")}
                                    </h3>
                                    <p className='mainlightPhragraph mb-1'>
                                        <img className='me-2' src={phoneBlue} alt="" />
                                        {posData?.user?.user_profiles?.full_phone_number}
                                    </p>
                                    <p className='mainlightPhragraph mb-1'>
                                        <img className='me-2' src={emailBlue} alt="" />
                                        {posData?.user?.email}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className="appointmentDetails_">
                                        <h3>Role Details</h3>
                                        <div className="appointInner mb-4">
                                            <div className="contentappont">
                                                <h5>Roles</h5>
                                                <span>{posData?.user?.user_roles ? posData?.user?.user_roles?.map((v, i) => {
                                                    return (<React.Fragment key={i}>
                                                        {v?.role?.name + (i === posData?.user?.user_roles?.length - 1 ? "" : ", ")}
                                                    </React.Fragment>
                                                    )
                                                }) : "No Role Selected"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="appointmentDetails_">
                                        <h3>Service Details</h3>
                                        <div className="appointInner mb-4">
                                            <div className="contentappont">
                                                <h5>Services</h5>
                                                <span>{allServiceData?.length > 0 ? allServiceData?.map((v, i) => {
                                                    return (<React.Fragment key={i}>
                                                        {v?.name + (i === allServiceData?.length - 1 ? "" : ", ")}
                                                    </React.Fragment>)
                                                }) : "No Service Selected"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="appointmentDetails_">
                                        <h3>Selected Color</h3>
                                        <div className="appointInner mb-4">
                                            <h5>Color Code</h5>
                                            <p>{props?.selectedRow?.color_code}</p>
                                            <div style={{ backgroundColor: props?.selectedRow?.color_code, width: "50px", height: "50px", border: "1px solid black" }}></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className="appointmentDetails_">
                                        <h3>Hourly Rate</h3>
                                        <div className="appointInner mb-4">
                                            <div className="contentappont">
                                                <span>{`JBR ${addThousandSeparator(props?.selectedRow?.hourly_rate ? props?.selectedRow?.hourly_rate : 0)}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className="appointmentDetails_">
                                        <h3>Overtime Rate</h3>
                                        <div className="appointInner mb-4">
                                            <div className="contentappont">
                                                <span>{`JBR ${addThousandSeparator(props?.selectedRow?.overtime_rate ? props?.selectedRow?.overtime_rate : 0)}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className="appointmentDetails_">
                                        <h3>Payment Cycle</h3>
                                        <div className="appointInner mb-4">
                                            <div className="contentappont">
                                                <span>{props?.selectedRow?.payment_cycle}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className="appointmentDetails_">
                                        <h3>Billing Type</h3>
                                        <div className="appointInner mb-4">
                                            <div className="contentappont">
                                                <span>{props?.selectedRow?.billing_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className="appointmentDetails_">
                                        <h3>Employee Type</h3>
                                        <div className="appointInner mb-4">
                                            <div className="contentappont">
                                                <span>{props?.selectedRow?.employment_type == "full_time" ? "Full time" : "Part time"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-12'>
                                    <div className="appointmentDetails_">
                                        <h3 className='mt-3 mb-3'>Availability Details</h3>
                                        <div className="appointInner mb-4">
                                            <div className="contentappont">
                                                <span>{props?.selectedRow?.pos_user_availability?.length > 0 ? props?.selectedRow?.pos_user_availability?.map((v, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            {/* <p key={i}>{v?.day}: Start Time: {v?.opening_time ? v?.opening_time : '__'} - End Time: {v?.closing_time ? v?.closing_time : '__'}</p> */}
                                                            <div className='scheduleFlex'>
                                                                <p className='ScheduleDay'>{v?.day == 1 ? "Monday" : v?.day == 2 ? "Tuesday" : v?.day == 3 ? "Wednesday" : v?.day == 4 ? "Thursday" : v?.day == 5 ? "Friday" : v?.day == 6 ? "Saturday" : v?.day == 7 ? "Sunday" : ""}: </p>
                                                                <p className='scheduleTime'>
                                                                    Start Time: {v?.opening_time ? v?.opening_time : '__'} - End Time: {v?.closing_time ? v?.closing_time : '__'}
                                                                </p>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }) : "No Availability Details"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
        </>
    )
}

export default PosDetailsModal