import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
const NewsupportModal = () => {
    return (
        <div className='supportnewadd_'>
            <div className='newsupportModal_'>
                <div className="form-group  mb-4">
                    <label className="dollarSign mb-3" for="amount">Select Issue</label>
                    <select className="select customform-control" name="billing_cycle" id="billing_cycle">
                        <option value="Select Billing Cycle" disabled="" selected="">Select Billing Cycle</option>
                        <option value="automatic">Automatic</option><option value="manual">Manual</option>
                    </select>
                </div>
                <div className="form-group  mb-4">
                    <textarea row="9" column="50" class="customform-control " placeholder="Description"></textarea>
                </div>
                <div className='col-md-12 form-group '>
                    <h4 className='placementText '>Upload Image</h4>
                    <div className='mainCatalog ps-0 pe-0'>
                        <div id='dropzone'>
                            <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" />
                            <label htmlFor="file">
                                <span className="file-button uploadImages_">
                                    {/* <img src={imgGallery1} className="img-fluid editProfileImg" alt="user img" /> */}
                                    <div className='dropText ms-3'>
                                        <span className='textinner_'>Drop your files here or</span>
                                        <Link to='#' className='linkTxt ms-1'>Browse</Link>
                                    </div>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
               
            </div>
            <div className='col-lg-12 mt-5'>
                    <div className='suppportbottombtn_'>
                        <button className='discardgeryBtn me-3' type="submit">Discard</button>
                        <button className='secoundaryBtn' type="submit">Submit</button>

                    </div>
                </div>
        </div>
    )
}

export default NewsupportModal