import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../auth/authSlice'
import { returnToInventoryInOrderAsync } from '../../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const InventoryreturnModal = (props) => {
    let data = props?.orderData
    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const [returnedOrders, setReturnedOrders] = useState([])
    const [paramData, setParamData] = useState([])

    const [returnQty, setReturnQty] = useState(0)


    const quantityHandle = (status, index, id, qty, writeoffqty, addtoinventoryqty, productName) => {

        if (status === "plus") {
            let newParamValue = {
                "id": id,
                "qty": qty,
                "write_off_qty": writeoffqty - 1,
                "add_to_inventory_qty": addtoinventoryqty + 1,
                "productName": productName
            }
            if (index !== -1) {
                const newArray = [
                    ...paramData.slice(0, index),
                    newParamValue,
                    ...paramData.slice(index + 1)
                ];
                setParamData(newArray);
            }
        }
        if (status === "minus") {
            let newValue = {
                "id": id,
                "qty": qty,
                "write_off_qty": writeoffqty + 1,
                "add_to_inventory_qty": addtoinventoryqty - 1,
                "productName": productName
            }
            if (index !== -1) {
                const newArray = [
                    ...paramData.slice(0, index),
                    newValue,
                    ...paramData.slice(index + 1)
                ];
                setParamData(newArray);
            }
        }
    }

    const returnToInventoryHandle = () => {
        paramData?.forEach(obj => {
            delete obj?.productName;
        });
        let params = {
            postData: {
                "order_id": data?.id,
                "drawer_id": data?.drawer_id,
                "products": paramData
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(returnToInventoryInOrderAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                props.close()
                history.push(`/allOrder`)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        let selectedOredrs = data?.order_details?.filter(value => props?.productIds?.includes(value?.product_id))
        setReturnedOrders(selectedOredrs)
        let newParams = data?.order_details?.filter(value => props?.productIds?.includes(value?.product_id))?.map(item => {
            return ({
                "id": item?.product_id,
                "qty": item?.qty,
                "write_off_qty": 0,
                "add_to_inventory_qty": item?.returned_qty,
                "productName": item?.product_name
            })
        })
        setParamData(newParams)
    }, [])

    return (
        <div className='inventoryModal_'>
            <div className='inventoryInnercontent_'>
                <h5 className='appointHead mb-4'>All Returned item will be adjusted with inventory</h5>
                <div className='walletPurchaseTable'>
                    {
                        paramData?.length > 0 ?
                            paramData?.map((v, i) => {
                                return (
                                    <div className='purchaseTableSub' key={i}>
                                        <div className='walletFlex'>
                                            <h4 className='profileboldHead'>{v?.qty}</h4>
                                            <h4 className='profileboldHead'>x</h4>
                                            <div className='orderDimension'>
                                                <h4 className='orderDimensionText mb-1'>{v?.productName}</h4>
                                                <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4>
                                            </div>
                                        </div>
                                        <div className='quantBox_'>
                                            <span style={{ cursor: "pointer" }} className='quantText_' onClick={() => quantityHandle("minus", i, v?.id, v?.qty, v?.write_off_qty, v?.add_to_inventory_qty, v?.productName)}><i class="fas fa-minus"></i></span>
                                            <span className='quantText_'>{v?.add_to_inventory_qty}</span>
                                            <span style={{ cursor: "pointer" }} className='quantText_' onClick={() => quantityHandle("plus", i, v?.id, v?.qty, v?.write_off_qty, v?.add_to_inventory_qty, v?.productName)}><i class="fas fa-plus"></i></span>
                                        </div>
                                    </div>
                                )
                            }) : <></>
                    }
                </div>
            </div>

            <button class="modalsaveBtn_ mt-5" type="submit" onClick={() => returnToInventoryHandle()} disabled={loading}>
                {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Return to Inventory </span></button>

        </div>
    )
}

export default InventoryreturnModal