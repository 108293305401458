import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../dashboard/DashboardNavbar'
import { getMerchantSettingsAsync, settingUpdateApiAsync } from '../dashboard/dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoginAuth } from '../auth/authSlice'
import { CKEditor } from 'ckeditor4-react'
import { toast } from 'react-toastify'

const AppSetting = () => {
    const [loading, setLoading] = useState(false)
    const [settingsData, setSettingsData] = useState("")
    const [fuelPrice, setFuelPrice] = useState("")
    const [b2cPrivacyPolicy, setB2cPrivacyPolicy] = useState("")
    const [driverPrivacyPolicy, setDriverPrivacyPolicy] = useState("")
    const [WalletPrivacyPolicy, setWalletPrivacyPolicy] = useState("")
    const [b2cTermsCondition, setB2cTermsCondition] = useState("")
    const [WalletTermsCondition, setWalletTermsCondition] = useState("")
    const [driverTermsCondition, setDriverTermsCondition] = useState("")
    const [buttonLoading, setButtonLoading] = useState(false)
    const dispatch = useDispatch()
    const toastId = React.useRef(null);
    const blockInvalidChar = (e) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
    const auth = useSelector(selectLoginAuth)
    const getAppSettings = () => {
        setLoading(true)
        dispatch(getMerchantSettingsAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setSettingsData(obj?.payload)
                setFuelPrice(obj?.payload?.fuel_price)
                setB2cPrivacyPolicy(obj?.payload?.b2c_privacy_policy)
                setDriverPrivacyPolicy(obj?.payload?.driver_privacy_policy)
                setWalletPrivacyPolicy(obj?.payload?.wallet_privacy_policy)
                setB2cTermsCondition(obj?.payload?.b2c_terms_and_conditons)
                setWalletTermsCondition(obj?.payload?.wallet_terms_and_conditons)
                setDriverTermsCondition(obj?.payload?.driver_terms_and_conditons)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const submitHandle = (e) => {
        e.preventDefault()
        if (!fuelPrice) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter fuel privacy");
            }
            return;
        }
        if (!b2cPrivacyPolicy) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter B2C privacy policy");
            }
            return;
        }
        if (!WalletPrivacyPolicy) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter Wallet privacy policy");
            }
            return;
        }
        if (!driverPrivacyPolicy) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter Driver privacy policy");
            }
            return;
        }
        if (!b2cTermsCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter B2C Terms & Condition");
            }
            return;
        }
        if (!WalletTermsCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter Wallet Terms & Condition");
            }
            return;
        }
        if (!driverTermsCondition) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter Driver Terms & Condition");
            }
            return;
        }
        let params = {
            postData: {
                "fuel_price": fuelPrice,
                "b2c_privacy_policy": b2cPrivacyPolicy,
                "driver_privacy_policy": driverPrivacyPolicy,
                "wallet_privacy_policy": WalletPrivacyPolicy,
                "b2c_terms_and_conditons": b2cTermsCondition,
                "driver_terms_and_conditons": driverTermsCondition,
                "wallet_terms_and_conditons": WalletTermsCondition
            },
            tokenData: auth?.payload?.token
        }
        setButtonLoading(true);
        dispatch(settingUpdateApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setButtonLoading(false);
                toast.success(obj?.msg)
            })
            .catch((obj) => {
                setButtonLoading(false);
            });
    }

    useEffect(() => {
        getAppSettings()
    }, [])


    return (
        <>
            <DashboardNavbar title="App Setting" show={false} />
            {
                loading ? (
                    <div className="col-md-12">
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                    </div>
                )
                    :
                    <>
                        <form
                            onSubmit={(e) => submitHandle(e)}
                            className="emplyoeeDetails horizontalForms"
                        >
                            <div className="row">
                                <div className="mb-4 form-group col-md-12 inputGroup">
                                    <label className="labelTxt mb-3" htmlFor="amount">
                                        Fuel Price
                                    </label>
                                    <input
                                        type="number"
                                        className="customform-control"
                                        onKeyDown={blockInvalidChar}
                                        name="fuel_price"
                                        autoComplete="new-password"
                                        placeholder="20.00"
                                        value={fuelPrice}
                                        onChange={(e) => setFuelPrice(e.target.value)}
                                    />
                                    <span className='dollrsign1_'>$</span>
                                </div>
                                <div className="form-group col-md-12 mb-4">
                                    <label className="labelTxt mb-3" htmlFor="amount">
                                        B2C Privacy Policy
                                    </label>
                                    <CKEditor
                                        initData={b2cPrivacyPolicy}
                                        config={{
                                            removePlugins: [
                                                "EasyImage",
                                                "ImageUpload",
                                                "MediaEmbed",
                                                "Table",
                                                "image",
                                            ],
                                            versionCheck: false
                                        }}
                                        name="editor"
                                        readOnly={false}
                                        onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            setB2cPrivacyPolicy(data);
                                        }}
                                        type="classic"
                                    />
                                </div>

                                <div className="form-group col-md-12 mb-4">
                                    <label className="labelTxt mb-3" htmlFor="amount">
                                        Driver Privacy Policy
                                    </label>
                                    <CKEditor
                                        initData={driverPrivacyPolicy}
                                        config={{
                                            removePlugins: [
                                                "EasyImage",
                                                "ImageUpload",
                                                "MediaEmbed",
                                                "Table",
                                                "image",
                                            ],
                                            versionCheck: false
                                        }}
                                        name="editor"
                                        readOnly={false}
                                        onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            setDriverPrivacyPolicy(data);
                                        }}
                                        type="classic"
                                    />
                                </div>

                                <div className="form-group col-md-12 mb-4">
                                    <label className="labelTxt mb-3" htmlFor="amount">
                                        Wallet Privacy Policy
                                    </label>
                                    <CKEditor
                                        initData={WalletPrivacyPolicy}
                                        config={{
                                            removePlugins: [
                                                "EasyImage",
                                                "ImageUpload",
                                                "MediaEmbed",
                                                "Table",
                                                "image",
                                            ],
                                            versionCheck: false
                                        }}
                                        name="editor"
                                        readOnly={false}
                                        onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            setWalletPrivacyPolicy(data);
                                        }}
                                        type="classic"
                                    />
                                </div>

                                <div className="form-group col-md-12 mb-4">
                                    <label className="labelTxt mb-3" htmlFor="amount">
                                        B2C Terms & Condition
                                    </label>
                                    <CKEditor
                                        initData={b2cTermsCondition}
                                        config={{
                                            removePlugins: [
                                                "EasyImage",
                                                "ImageUpload",
                                                "MediaEmbed",
                                                "Table",
                                                "image",
                                            ],
                                            versionCheck: false
                                        }}
                                        name="editor"
                                        readOnly={false}
                                        onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            setB2cTermsCondition(data);
                                        }}
                                        type="classic"
                                    />
                                </div>

                                <div className="form-group col-md-12 mb-4">
                                    <label className="labelTxt mb-3" htmlFor="amount">
                                        Wallet Terms & Condition
                                    </label>
                                    <CKEditor
                                        initData={WalletTermsCondition}
                                        config={{
                                            removePlugins: [
                                                "EasyImage",
                                                "ImageUpload",
                                                "MediaEmbed",
                                                "Table",
                                                "image",
                                            ],
                                            versionCheck: false
                                        }}
                                        name="editor"
                                        readOnly={false}
                                        onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            setWalletTermsCondition(data);
                                        }}
                                        type="classic"
                                    />
                                </div>
                                <div className="form-group col-md-12 mb-4">
                                    <label className="labelTxt mb-3" htmlFor="amount">
                                        Driver Terms & Condition
                                    </label>
                                    <CKEditor
                                        initData={driverTermsCondition}
                                        config={{
                                            removePlugins: [
                                                "EasyImage",
                                                "ImageUpload",
                                                "MediaEmbed",
                                                "Table",
                                                "image",
                                            ],
                                            versionCheck: false
                                        }}
                                        name="editor"
                                        readOnly={false}
                                        onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            setDriverTermsCondition(data);
                                        }}
                                        type="classic"
                                    />
                                </div>
                                <div className="footerSec mt-4 text-center">
                                    <button className='publishBtn_ lh-sm' type="submit" disabled={buttonLoading}>
                                        {buttonLoading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </form>

                    </>
            }
        </>
    )
}

export default AppSetting