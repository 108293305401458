import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DriverProfile from "./driverProfile";
import DriverDeliveries from './driverDeliveries';
import DriverTransaction from './driverTransaction';
import { useDispatch, useSelector } from 'react-redux';
import { getDriverProfileApiAsync } from '../../dashboard/dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { unwrapResult } from '@reduxjs/toolkit';


const DriverTracking = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("driverProfile");
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState("")
  const [deliveryData, setDeliveryData] = useState([])
  const [transactionsData, setTransactionsData] = useState([])
  const auth = useSelector(selectLoginAuth);

  const driverDataHandle = () => {
    let params = {
      "postData": {
        driver_id: id,
        // page:1,
        // limit:10
      },
      "tokenData": auth?.payload?.token
    }
    setLoading(true)
    dispatch(getDriverProfileApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setProfileData(obj?.payload?.profile_details)
        setTransactionsData(obj?.payload?.transaction_details?.data)
        setDeliveryData(obj?.payload?.delivery_details?.data)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  useEffect(() => {
    driverDataHandle()
  }, [])
  return (
    <>
      <div className='marchatUser'>
        <div className='topHeader_'>
          <div className="topStickyHeader_ mb-3">
            <div className="row  align-items-center">
              <div className="col-md-12">
                <div className='anotherHeader'>
                  <i className="las la-angle-left" onClick={() => { history.push("/userList") }} ></i>
                  <h2 className="innerSubheadNormal">Back</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button className={`valuesTimeswallet ${activeTab === 'driverProfile' ? 'active' : ""}`}
          onClick={() => setActiveTab('driverProfile')}
        > Profile
        </button>
        <button className={`valuesTimeswallet ${activeTab === 'driverDeliveries' ? 'active' : ""}`}
          onClick={() => setActiveTab('driverDeliveries')}>
          Deliveries
        </button>
        <button className={`valuesTimeswallet ${activeTab === 'driverTransaction' ? 'active' : ""}`}
          onClick={() => setActiveTab('driverTransaction')}>
          Transactions
        </button>
      </div>
      <div className='plansAreasContainer'>

        {activeTab == 'driverProfile'
          ?
          <DriverProfile loading={loading} data={profileData} />
          :
          activeTab == 'driverDeliveries'
            ?
            <DriverDeliveries loading={loading} data={deliveryData}/>
            :
            activeTab == 'driverTransaction'
              ?
              <DriverTransaction loading={loading} data={transactionsData}/>

              : ""
        }
      </div>

    </>
  )
}

export default DriverTracking