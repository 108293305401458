import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { getFaqCategoryTagsApiAsync, updateFaqCategoryApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const FaqCategoryEditModal = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState(props?.selectedData?.category_detail ? props?.selectedData?.category_detail : "");
  const [selectedCategory, setSelectedCategory] = useState('Department 1');
  const [categoryName, setCategoryName]= useState(props?.selectedData?.category_name ? props?.selectedData?.category_name : "")
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionData, setOptionData] = useState([]);

  
  const handleSelect = (selectedList) => {
    setSelectedOptions(selectedList);
  };


  const handleSubmit = (e) => {
    let filteredSelectedOptions = selectedOptions?.map(v => v?.value)
    e.preventDefault();
    if (!categoryName) {
      toast.error("please select category");
      return;
    }
    else if (!categoryDetails) {
      toast.error("Please write category details");
      return;
    }
    else if (selectedOptions.length <= 2) {
      toast.error("Please select at least 3 category");
      return;
    }
    let params = {
      "postData": {
        'id': props?.selectedData?.id,
        "category_name": categoryName,
        "category_detail": categoryDetails,
        "category_tags": filteredSelectedOptions
      },
      "tokenData": auth?.payload?.token
    }
    setLoadingButton(true)
    dispatch(updateFaqCategoryApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoadingButton(false)
        toast.success(obj?.msg)
        props?.faqCategoryDataHandle();
        props?.close()
      })
      .catch((obj) => {
        setLoadingButton(false)
      })
  }

  const categoryTagsData = () => {
    setLoading(true)
    dispatch(getFaqCategoryTagsApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        let modifiedData = obj?.payload?.map((item, i) => ({
          id: i + 1,
          value: item
        }));
        setOptionData(modifiedData)
        setLoading(false)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
     categoryTagsData();
     if(props?.selectedData?.category_tags){
      let modifiedData = props?.selectedData?.category_tags?.map((item, i) => ({
        id: i + 1,
        value: item
      }));
      setSelectedOptions(modifiedData)
     }
  }, [])


  return (
    <div className='addnewfaqModal'>
      <form className='emplyoeeDetails horizontalForms' onSubmit={(e) => { handleSubmit(e) }}>
        <div className='row'>
          <div className='form-group mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>Category Name</label>
            <input type='text' className='customform-control' placeholder='Details here'
              value={categoryName} onChange={e => setCategoryName(e.target.value)} />
          </div>
          <div className='form-group col-md-12  mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>Category details </label>
            <input type='text' className='customform-control' placeholder='Details here'
              value={categoryDetails} onChange={e => setCategoryDetails(e.target.value)} />
            <p className='tableHeadsmall text-end'>160 letters maximum</p>
          </div>
          <div className='form-group col-md-12  mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>Category tags</label>
            {/* <input type='text' className='customform-control' placeholder='Tags' /> */}
            <Multiselect
              className='brandFilter customform-control'
              displayValue="value"
              options={optionData}
              selectedValues={selectedOptions}
              // value={selectedOptions}
              hidePlaceholder={true}
              onSelect={handleSelect}
              onRemove={handleSelect}
              showCheckbox
            />
            <p className='tableHeadsmall text-end'>At least 3 tags</p>
          </div>


          <div className='footerSec mt-4 text-end'>
            <button className='closebtnModal me-3' onClick={props.close}>Cancel</button>
            <button className='publishBtn_ lh-sm' type='submit' disabled={loadingButton}>
            {loadingButton && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Save</span></button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default FaqCategoryEditModal