import React, { useEffect, useState } from 'react'
import {taxes,Verfidsky,usa,florida,editIcon,plusIcon,calendar} from '../../../../utilities/images'
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import CustomModal from '../../../components/shared/CustomModal'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import products from "../../../db/category.json"
import StateTaxModal from './stateTaxModal'
import CreateTaxModal from './createTaxModal'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {getTaxAsync} from "../systemConfigSlice";

const TaxInformation = (props) => {
    const [key, setKey] = useState(Math.random());
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState([]);
    const [taxData, setTaxData] = useState()
    const [taxxData, setTaxxData] = useState()
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

const handleDetails = (flag) => {
    if(flag == "OperatingState"){
      setModalDetail({ show: true, flag: "OperatingState" });
      setKey(Math.random());
    }
    if(flag == "TaxPayer"){
        setModalDetail({ show: true, flag: "TaxPayer" });
        setKey(Math.random());
      }
  }

  const getTaxdata = () => {
    let data = {
        tokenData: auth?.payload?.token,
        postData: {
            seller_id: auth?.payload?.uniqe_id,
            is_tax_details: false
        }
    }
    setLoading(true)
    dispatch(getTaxAsync(data))
        .then(unwrapResult)
        .then((obj) => {
            setLoading(false)
            setTaxData(obj?.payload ? obj?.payload : [])
            
        }
        )
        .catch((obj) => {
            setLoading(false)
        })
    }
    const getTaxxdata = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                seller_id: auth?.payload?.uniqe_id,
                is_tax_details: true
            }
        }
        setLoading(true)
        dispatch(getTaxAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setTaxxData(obj?.payload ? obj?.payload : [])
                
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
        }


    useEffect(()=>{
        getTaxdata();
        getTaxxdata();
    },[])


  
  const columns1 = [
    {
      name: '#',
      selector: row => row.id,
      width: "50px" 
    },
    
    { name: 'Tax Name',
    selector: row => {
      return (
            <>
              <span className='textParagh '>{row?.tax_name}</span>
            </>
          );
      },
      width: "220px",
    },
   
    {
      name: 'Locations',
      selector: row => {
        return (
              <>
                <span className='textParagh '>{row?.location}</span>
              </>
            );
        },
        width: "130px",
    },
    
    {
      selector: row => {
        return (
              <>
                <span className='textParagh '>{row?.tax_rate}</span>
              </>
            );
        },
      name: 'Tax rate'
    },
    
    {
      name: 'Status',
      selector: row => {
        return (
              <>
              <div className='d-flex align-items-center'>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked readOnly/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                </div>
              </div>
              </>
            );
        },
        width: "120px",
    },
    {
      name: '',
      selector: row => {
        return (
              <>
              <div className='logsign_' >
                <i className="fal fa-chevron-right cursor" ></i> 
              </div>

              </>
            );
        },
        width: "50px",
    },
  ];
  return (
    <>
    <div className='systemTaxes'>
        <div className='topHeader_'>
            <div className="topStickyHeader_">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h2 className="mainHeading">
                            <span>System Config / <span className="innerSubtext">Taxes</span> </span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <div className='paymentContainer'>
            <div className='paymentArea'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='d-flex align-items-start'>
                            <img src={taxes} className=' me-3' />
                            <div className='w-100'>
                                <h3 className='textInnerDark mb-3'>Taxes and duties</h3>
                                <div className='taxInformation'>
                                    <p className='smalllightHead_ fw-500'>Name: Charlie Saari</p>
                                    <p className='smalllightHead_ fw-500'>SSN: 136042056</p>
                                    <p className='smalllightHead_ fw-500'>3755 Williams Mine Road, Newark, NJ 07102</p>
                                    <button className='verifiedBtn mt-3'><img src={Verfidsky} alt=""/><span className='smalllightHead_ fw-500'> Verified</span></button>
                                </div>
                                <div className='taxInformation businessDetails'>
                                    <div className='row'>
                                        <div className='col-md-10'>
                                            <div className='d-flex align-items-center'>
                                                <div className='opertaingFlag me-3'>
                                                    <img src={usa} alt=""/>
                                                </div>
                                                <div className='operatinText' onClick={() => {
                                                        setModalDetail({ show: true, flag: "StateTaxModal" });
                                                        setKey(Math.random());
                                                    }}>
                                                    <h1 className='smalllightHead_ fw-500'>Operating Country</h1>
                                                    <p className='descriptionPara_ fw-normal'>United States of America</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-2 text-end'>
                                            <div className="form-check form-switch">
                                                <input type="checkbox" />
                                            </div>
                                        </div>
                                    </div>
                                   <div className='row justify-content-center mt-3'>
                                    <div className='col-md-11'>
                                        <div className='row'>
                                            <div className='col-md-10'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='opertaingFlag me-3'>
                                                        <img src={florida} alt=""/>
                                                    </div>
                                                    <div className='operatinText' >
                                                        <h1 className='smalllightHead_ fw-500'>Operating Country</h1>
                                                        <p className='descriptionPara_ fw-normal'>United States of America</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-2 text-end'>
                                                <div className="form-check form-switch">
                                                    <input type="checkbox" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   </div>
                                   <div className='row'>
                                            <div className='col-12'>
                                                <div className='text-end'>
                                                <button className='primaryBtnOuter w-auto mt-3 py-2'
                                                ><strong className='d-flex'> Add State <img src={plusIcon} alt="" className='ms-2'/></strong></button>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                            
                                <button className='primaryBtnOuter w-auto mt-3 py-2'
                                    onClick={() => {
                                        setModalDetail({ show: true, flag: "CreateTaxModal" });
                                        setKey(Math.random());
                                    }}
                                ><strong><img src={editIcon} alt=""/> Create tax</strong></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='tableFilters mt-4 mb-3'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="calenderIcon">
                        <img src={calendar} alt="calender" />
                        {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                            dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                            <DatePicker
                                className='cateDate'
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText="Date"
                            />
                    </div>
                </div>
                {/* <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" >
                            Country
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" >
                            State
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" >
                            Id type
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div> */}
            </div>
        </div>
    <DataTable
          pagination
          columns={columns1}
          data={taxxData?.data}
          defaultSortFieldId={1}
          className="taxTable"
          onRowClicked={handleRowClick
        
        }
        />
    <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "StateTaxModal" ? "customContent" : modalDetail.flag === "CreateTaxModal" ? "customContent" : ""}
        ids={modalDetail.flag === "StateTaxModal" ? "operatingCountry" : ""}
        size={
            modalDetail.flag === "StateTaxModal" ? "md" : 
            modalDetail.flag === "CreateTaxModal" ? "lg" : "md"
            }
        child={
            modalDetail.flag === 'StateTaxModal' ? (<StateTaxModal close={() => handleOnCloseModal()} details={(e) => handleDetails(e)}/>
            ) :
        modalDetail.flag === "CreateTaxModal" ? (
            <CreateTaxModal setTaxxData={setTaxxData} parentId={taxData?.data[0]} close={() => handleOnCloseModal()} details={e => handleDetails(e)}/>
            ) : <></>            
        }
        header={
            <div className='modalHeader_'>
              <div className="common_">
              {modalDetail.flag === "StateTaxModal" ?
                  <h2 className="modalHeading">
                  Activate your State Tax
                  </h2>
                  :
                  modalDetail.flag === "CreateTaxModal" ?
                      <h2 className="modalHeading fw-semibold">Create tax</h2>
                      :
                      ""
              }
                  <button className="closeButton">
                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                  </button>
              </div>
            </div>
          }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
    
  )
}

export default TaxInformation