import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getProductListApi,
  getProductServiceListApi,
  getProductListByIdApi,
  getProductSalesGraphApi,
  getTopSellingApi,
  getCountApi,
  getProductStatisticData,
  getSingleProductStatisticDataById,
  getServiceCountDataApi,
  getServiceSalesBarGraphApi,
  changeServiceAvailabilityStatusApi,
  getProductTrendingGraphApi,
  getLowStockInventoryApi,
  updateInventoryApi,
  adjustInventoryApi,
  createCoupon
} from "./productApi";


const initialState = {
  status: "idle",
  productData: null,
};

export const getProductListAsync = createAsyncThunk("product/getProductListApi", async (data) => {
  const response = await getProductListApi(data.postData, data.tokenData);
  return response.data;
});
export const getProductServiceListApiAsync = createAsyncThunk("product/getProductServiceLisApi", async (data) => {
  const response = await getProductServiceListApi(data.postData, data.tokenData);
  return response.data;
});
export const getProductListByIdAsync = createAsyncThunk("product/getProductListByIdApi", async (data) => {
  const response = await getProductListByIdApi(data.postData, data.tokenData);
  return response.data;
});
export const getProductSalesGraphApiAsync = createAsyncThunk("product/getProductSalesGraphApi", async (data) => {
  const response = await getProductSalesGraphApi(data.postData, data.tokenData);
  return response.data;
});
export const getTopSellingApiAsync = createAsyncThunk("product/getTopSellingApi", async (data) => {
  const response = await getTopSellingApi(data.postData, data.tokenData);
  return response.data;
});
export const getCountApiAsync = createAsyncThunk("product/getCountApi", async (data) => {
  const response = await getCountApi(data.postData, data.tokenData);
  return response.data;
});
export const getMyProductStatisticDataAsync = createAsyncThunk("product/getProductStatisticData", async (data) => {
  const response = await getProductStatisticData(data.postData, data.tokenData);
  return response.data;
});
export const getSingleProductStatisticDataByIdAsync = createAsyncThunk("product/getSingleProductStatisticDataById", async (data) => {
  const response = await getSingleProductStatisticDataById(data.postData, data.tokenData);
  return response.data;
});
export const getServiceCountDataApiAsync = createAsyncThunk("product/getServiceCountDataApi", async (data) => {
  const response = await getServiceCountDataApi(data.postData, data.tokenData);
  return response.data;
});
export const getServiceSalesBarGraphApiAsync = createAsyncThunk("product/getServiceSalesBarGraphApi", async (data) => {
  const response = await getServiceSalesBarGraphApi(data.postData, data.tokenData);
  return response.data;
});
export const changeServiceAvailabilityStatusApiAsync = createAsyncThunk("product/changeServiceAvailabilityStatusApi", async (data) => {
  const response = await changeServiceAvailabilityStatusApi(data.postData, data.tokenData, data.statusData);
  return response.data;
});

export const getProductTrendingGraphAsync = createAsyncThunk("product/getProductTrendingGraphApi", async (data) => {
  const response = await getProductTrendingGraphApi(data.postData, data.tokenData, data.statusData);
  return response.data;
});

export const getLowStockInventoryAsync = createAsyncThunk("product/getLowStockInventoryApi", async (data) => {
  const response = await getLowStockInventoryApi(data.postData, data.tokenData, data.statusData);
  return response.data;
});

export const updateInventoryApiAsync = createAsyncThunk("product/updateInventoryApi", async (data) => {
  const response = await updateInventoryApi(data.postData, data.bodyData, data.tokenData);
  return response.data;
});
export const adjustInventoryApiAsync = createAsyncThunk("product/adjustInventoryApi", async (data) => {
  const response = await adjustInventoryApi(data.postData, data.tokenData);
  return response.data;
});

// new
export const createCouponAsync = createAsyncThunk("product/createCoupon", async (data) => {
  const response = await createCoupon(data.postData, data.tokenData)
  return response.data;
});

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    productEmpty: (state) => {
      state.productData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductServiceListApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductServiceListApiAsync.fulfilled, (state, { payload }) => {
        state.productData = payload
        state.status = "idle";
      })
      .addCase(getProductServiceListApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.productData = null
      })
  },
});
export const { productEmpty } = productSlice.actions;
export const selectProductData = (state) => state?.products?.productData;

export default productSlice.reducer;

