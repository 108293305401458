import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { brandLogo, shops, stepHalf, } from '../../../../utilities/images'
import OTPInput from 'react-otp-input';
import { resendOtpApiAsync, verifyPhoneOtpApiAsync } from '../authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import OtpTimer from "otp-timer";

const VerifyOtp = () => {
    const history = useHistory()
    const pathName = window.location.pathname;
    const [key] = useState(Math.random());
    const phoneData = localStorage.getItem("phone_details") ? JSON.parse(localStorage.getItem("phone_details")) : ""
    const [otp, setOtp] = useState("");
    const toastId = React.useRef(null)
    const dispatch = useDispatch()
    const [otpLoading, setOtpLoading] = useState(false)
    const [buttonActive, setButtonActive] = useState(false)
    const generateRandomName = () => {
        return Math.random().toString(36).substr(2, 10);
    };
    const onComplete = (code) => {
        setOtp(code);
        if(code.length == 5){
            setButtonActive(true)
        }
        else{
            setButtonActive(false)
        }
    }

    const handleVerifyOtp = (e) => {
        // e.preventDefault()
        if (!otp || otp.length < 5) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your otp");
            }
            return false
        }
        setOtpLoading(true)
        let params
        if (phoneData?.user_id) {
            params = {
                "type": "phone",
                "verification_id": `${phoneData?.verification_id}`,
                "otp": otp,
                "user_id": `${phoneData?.user_id}`
            }
        }
        else {
            params = {
                "type": "phone",
                "verification_id": `${phoneData?.verification_id}`,
                "otp": otp
            }
        }

        dispatch(verifyPhoneOtpApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/verifyEmail')
                localStorage.setItem("existing_user", JSON.stringify(obj?.payload))
                setOtpLoading(false)
            }
            )
            .catch((obj) => {
                setOtpLoading(false)
            })
    }

    const resendOtp = () => {
        let params = {
            "type": "phone",
            "verification_id": `${phoneData?.verification_id}`
        }
        dispatch(resendOtpApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
            })
    }
console.log(otp,'otppppppppppppppppppp');
    useEffect(()=>{
        if(otp && otp?.length == 5){
            handleVerifyOtp()
        }
    },[otp])

    return (
        <div className='chooseRoles'>
            <div className="commonLogin">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-lg-6 p-0 text-center">
                            <div className='leftSect'>
                                <div className="login_left">
                                    <img src={shops} className="d-none d-md-block img-fluid mx-auto mb-3" alt="img" />
                                    <span className='heading_'><b>Create Your <br />Organization</b></span>
                                </div>
                                <div className='aboutBusiness'>
                                    {/* <img src={stepHalf} className='img-fluid mb-4' /> */}
                                    <h4 className='mb-2 innerSubtext'>Tell us about your Business</h4>
                                    <span className=''>Please let us know what you need help with and We will  <br />do our best to assist you.</span>
                                    {/* <p className="mt-4 gobacklink justify-content-center d-flex align-items-center"><i className="fa-solid fa-arrow-left-long"></i> Go back to <Link to="/login" > Login </Link> </p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 protectionRight verifyPhone__">
                            <div className='loginhorrizontal-form  '>
                                <form className="form_horizontal row" onSubmit={(e) => handleVerifyOtp(e)}>
                                    <div className="logo-part">
                                        <Link to="#">
                                            <img className='mb-4' src={brandLogo} alt="" />
                                        </Link>
                                        <h2>Verify your <strong>Phone Number</strong></h2>
                                        <p>Enter OTP code here, we have sent on your phone number.</p>
                                    </div>


                                    <div className="verify-part">
                                        <div className="verify-box text-center">
                                            <div className="pin-box d-flex justify-content-center" >
                                                <OTPInput numInputs={5}
                                                    className='input_digits_'
                                                    value={otp}
                                                    data-cy="pin-field"
                                                    name={generateRandomName()}
                                                    autoComplete="new-password"
                                                    isInputNum={true}
                                                    isInputSecure={true}
                                                    onChange={onComplete}
                                                // onComplete={(code) => onComplete(code)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-5 text-center protectionBtn">

                                        <p className='innerSubtext mt-5 mb-3'>
                                            <OtpTimer
                                                //minutes={1}
                                                seconds={60}
                                                key={key}
                                                //seconds={otpTimerPeriod}
                                                //text="Resend OTP in:"
                                                ButtonText="Resend"
                                                resend={resendOtp}
                                            />
                                        </p>
                                        {/* <p>Didn’t receive Code? <Link to="#" onClick={() => resendOtp()}>Resend</Link></p> */}
                                        {/* <button className={`loginBtnSign w-100 ${buttonActive ? 'buttonActive': ''}`} disabled={otpLoading || !buttonActive} type="submit">
                                            {otpLoading === true && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Verified</span>
                                        </button> */}
                                        <p className='mt-4'>Not the correct number? <Link to="/verify-Phone">Change it</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyOtp