import React, { useState } from 'react'
import { dots, viewIcon, stop, shareMap, facebook, instaIcon, calendar, OrderDown } from '../../../utilities/images'
import CustomModal from '../../components/shared/CustomModal';
import ScheduleModal from '../auth/ScheduleModal';
import NewPromotionModal from './newPromotionModal';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import DatePicker from "react-datepicker";


const PromotionalActivities = () => {
    const data = Array.from(Array(4).keys())
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    return (
        <>
            <DashboardNavbar title="Campaign Overview" backShow={false} />
            <div className='promotionalActivities'>
                <div className='tableSectionBackground '>  
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='campaignTopHead productMainCategory'>
                                <div className='categroyBox dateCategory'>
                                    <div className="calenderIcon campCalendar">
                                        <img src={calendar} alt="calender" className='campcalendarImg' />
                                        {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                        <DatePicker
                                            className='cateDate'
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            placeholderText="Date"
                                        />
                                        <img src={OrderDown} alt="calender" className='calenderDownIcon' />
                                    </div>
                                </div>
                                <div className='categroyBox'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            All Types
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>
                                <div className='categroyBox'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            All Transactions
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>
                                <div className='categroyBox'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            All Payment
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    backdrop="static"
                    showCloseBtn={true}
                    isRightSideModal={false}
                    mediumWidth={false}
                    ids={modalDetail.flag === "NewPromotionModal" ? "newPromotionModal" : ""}
                    size={modalDetail.flag === "NewPromotionModal" ? "xl" : "xl"}
                    header={
                        <div className='modalHeader_'>
                            <div className="common_">
                                {modalDetail.flag === "NewPromotionModal" ?
                                    <h2 className="subheaderHeading">
                                        Create New Promotion
                                    </h2>
                                    : ""
                                }
                                {/* <button className="closeButton">
                        <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                    </button> */}
                            </div>
                        </div>
                    }
                    child={modalDetail.flag === 'NewPromotionModal' ? <NewPromotionModal close={() => handleOnCloseModal()} />
                        : ""
                    }
                    onCloseModal={() => handleOnCloseModal()}
                />
            </div>
        </>
    )
}

export default PromotionalActivities