import React, { useEffect, useState } from 'react'
import products from '../../db/category.json'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { viewEye_, calendar, totalOrder, volume, analysis, grossProfit } from '../../../utilities/images';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { getAnalyticStatDataApiAsync } from '../dashboard/dashboardSlice';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';


const GrossProfits = () => {
    const dispatch = useDispatch();
    const today = new Date();
    const auth = useSelector(selectLoginAuth);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [analyticsProfitData, setAnalyticsProfitsData] = useState("")
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Analytics";
      }, []);
    // const handleRowClick = (e) => {
    //     // console.log("rowclicked", e)
    //     // history.push({ pathname: `/salesReport/${e?.flag}`, state: JSON.stringify(e) })
    // }


    function addThousandSeparator(number) {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const columns1 = [
        {
            name: 'Date',
            selector: row => moment(row?.order_date).format('MM/DD/YYYY')
        },
        {
            selector: row => row?.total_orders,
            name: 'Total Order',
        },
        {
            selector: row => addThousandSeparator(row?.transaction?.toFixed(2)),
            name: 'Transaction Volume',
        },
        {
            selector: row => `$${addThousandSeparator(row?.average_value?.toFixed(2))}`,
            name: 'Average order Value',
        },
        {
            selector: row => `$${addThousandSeparator(row?.total_tax?.toFixed(2))}`,
            name: 'Tax',
        },
        {
            selector: row => `$${addThousandSeparator(row?.cost_sum?.toFixed(2))}`,
            name: 'Total Cost',
        },
        {
            selector: row => `${(row?.margin)?.toFixed(2)}%`,
            name: 'Margin',
        },
        {
            selector: row => {
                return (
                    <b>${addThousandSeparator(row?.profit_sum?.toFixed(2))}</b>
                )
            },
            name: 'Gross Profit',
        },
    ];

    const newUserDataHandle = () => {
        let params = {
            "postData": {
                // is_admin: true,
                filter: filterValue,
                channel: channelFilter,
                page: currentPage,
                limit: perPage,
                // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852"
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid

            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAnalyticStatDataApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAnalyticsProfitsData(obj?.payload)
                setLoading(false)
                setTotalRows(obj?.payload?.orderData?.total)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        newUserDataHandle()
    }, [filterValue, channelFilter, endDate, currentPage])

    return (
        <>
            <DashboardNavbar title="Gross Profits" backShow="/analytics" />
            <div className='analyticGross'>
                {/* <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">Analytics / <span className="innerSubtext">Stats</span> </h2>
                        </div>
                    </div>
                </div>
            </div> */}
                <div className='row mt-4'>
                    <div className='col-lg-5'>
                        <div className='dateChannel'>
                            <div className="calenderIcon">
                                <img src={calendar} alt="calender" />
                                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                <DatePicker
                                    className='cateDate'
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={today}
                                    selectsRange
                                    placeholderText="Date"
                                />
                            </div>
                            <div className=" form-group ">
                                <div className=" form-group ">
                                    <select autoComplete="new-password" className='customform-control  selectControl form-select' value={channelFilter} onChange={(e) => setChannelFilter(e.target.value)}>
                                        <option value="all" >All Channels</option>
                                        <option value="b2c">B2C</option>
                                        <option value="b2b">B2B</option>
                                        <option value="pos">Pos</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 d-flex justify-content-end'>
                        <ul className='weekNav'>
                            <li onClick={() => setFilterValue("today")}><Link className={`weekMenu ${filterValue == "today" ? "active" : ""}`} to="#">Today</Link></li>
                            <li onClick={() => setFilterValue("week")}><Link className={`weekMenu ${filterValue == "week" ? "active" : ""}`} to="#">Weekly</Link></li>
                            <li onClick={() => setFilterValue("month")}><Link className={`weekMenu ${filterValue == "month" ? "active" : ""}`} to="#">Monthly</Link></li>
                            <li onClick={() => setFilterValue("year")}><Link className={`weekMenu ${filterValue == "year" ? "active" : ""}`} to="#">Yearly</Link></li>
                        </ul>
                    </div>
                </div>
                {
                    loading ?
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                        :
                        <div className='grossProfitData'>
                            <div className='row'>
                                <div className='col-lg-3 col-md-6  pe-2'>
                                    <div className='grossTotalorder'>
                                        <img src={totalOrder} className='img-fluid grossImages' alt='totalorderImage' />
                                        <p className='groSub mt-4 mb-0'>Total Orders</p>
                                        <h4 className='groMain'>{analyticsProfitData?.overView?.total_orders}</h4>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6  ps-2 pe-2'>
                                    <div className='grossTotalorder'>
                                        <img src={volume} className='img-fluid grossImages' alt='volumeImage' />
                                        <p className='groSub mt-4 mb-0'>Total Volume</p>
                                        <h4 className='groMain'>${addThousandSeparator(analyticsProfitData?.overView?.transaction ? analyticsProfitData?.overView?.transaction?.toFixed(2) : "0.00")}</h4>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                                    <div className='grossTotalorder'>
                                        <img src={analysis} className='img-fluid grossImages' alt='analysisImage' />
                                        <p className='groSub mt-4 mb-0'>Average Order Value</p>
                                        <h4 className='groMain'>{analyticsProfitData?.overView?.average_value ? `$${addThousandSeparator((analyticsProfitData?.overView?.average_value).toFixed(2))}` : "$0.00"}</h4>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-6 ps-2 '>
                                    <div className='grossTotalorder'>
                                        <img src={grossProfit} className='img-fluid grossImages' alt='grossprofitimage' />
                                        <p className='groSub mt-4 mb-0'>Gross profit</p>
                                        <h4 className='groMain'>{analyticsProfitData?.overView?.profit_sum ? `$${addThousandSeparator((analyticsProfitData?.overView?.profit_sum).toFixed(2))}`: "$0.00"}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                <div className='table-responsive'>

                <DataTable
                    columns={columns1}
                    data={analyticsProfitData?.orderData?.data}
                    defaultSortFieldId={1}
                    className='analytics_'
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                />
                </div>
            </div>
        </>
    )
}

export default GrossProfits