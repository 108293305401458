import React from 'react'
const LanguagesChangeStatusModal = (props) => {


    return (
        <>
            <div className='languageModal'>
                {/* <p className='textInnerDark fw-normal mb-3'>Language</p> */}
                <div className='' >
                    <div className='countryFlag me-0 mb-5'>
                        <img className="flagcountImage_" src={props?.selectedLanguage?.flag} alt="flag" />
                    </div>
                    <h1 className='subtext_ mb-3'>{props?.selectedLanguage?.language}</h1>
                    <p className='contPara_'>{`You are ${props?.selectedLanguage?.status === 0 ? "adding a new language to" : "removing a language from"} your system`}</p>
                </div>
                <div className='footerSec mt-5'>
                    <button type="button" className='closebtnModal me-4' onClick={() => props?.close()}>Discard</button>
                    <button className='exchangeBtn lh-sm' type="button" disabled={props.buttonLoading} onClick={() => props?.handleChange(props?.index, props?.selectedLanguage?.status === 0 ? 1 : 0)}>
                        {props.buttonLoading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>{props?.selectedLanguage?.status === 0 ? 'Add' : "Remove"}</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default LanguagesChangeStatusModal