import React, { useState } from 'react'
import CustomModal from '../../components/shared/CustomModal'
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import GetTokenModal from './GetTokenModal';
import { quickbookGetUriApiAsync, quickbookSyncDataApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const Quickbook = () => {
    const [loading, setLoading] = useState(false)
    const [key, setKey] = useState(Math.random());
    const auth = useSelector(selectLoginAuth);
    const toastId = React.useRef(null)
    const dispatch = useDispatch()
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleSubmit = () => {
        setLoading(true)
        dispatch(quickbookSyncDataApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => { 
                setLoading(false)
                console.log(obj, "error response")
                dispatch(quickbookGetUriApiAsync(auth?.payload?.token))
                    .then(unwrapResult)
                    .then((obj) => {
                        window.open(obj?.payload, '_blank')
                    }
                    )
                    .catch((obj) => {

                    })
                setModalDetail({ show: true, title: "getTokenModal", flag: "getTokenModal" });
                setKey(Math.random());
            })
    }

    return (
        <>
            <div className='legal'>
                <div className='topHeader_'>
                    <div className="topStickyHeader_">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <span>System Config / <span className="innerSubtext">Quickbook</span> </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <button className='btn btn-primary ms-2' type="button" onClick={() => handleSubmit()}>
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    <span>Sync Order</span>
                </button>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className="customContent"
                ids="legalModal"
                size="lg"
                child={<GetTokenModal close={() => handleOnCloseModal()}/>}
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            <h2 className="modalHeading">
                                Enter url for sync order
                            </h2>
                            <button className="closeButton">
                                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                            </button>
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default Quickbook