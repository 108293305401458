import React, { useState } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createRoleApiAsync, getPrivilegesApiAsync, getRoleApiAsync, selectLoginAuth, updateRoleApiAsync } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';

const CreateRoleModal = (props) => {
    const toastId = React.useRef(null);
    const [userName, setUserName] = useState(props?.selectedProduct ? props?.selectedProduct?.name : "")
    const [description, setDescription] = useState(props?.selectedProduct ? props?.selectedProduct?.description : "")
    const [loading, setLoading] = useState(false);
    const [privilegesIds, setPrivilegesIds] = useState(props?.selectedProduct ? props?.selectedProduct?.role_privileges?.map(v=>v?.privilege_id) : [])
    const dispatch = useDispatch()
    let selectedData = props?.selectedProduct?.role_privileges?.map((item) => ({
        value: item?.privileges?.name,
        id: item?.privilege_id
    }))
    const auth = useSelector(selectLoginAuth)
    const [privileges, setPriviliges] = useState([])
    const history = useHistory()

    const handleOptionValue = (e) => {
        return (
            <>
                {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCHkgJPx9vKxK-UV2Oqs_FaBstYEz0JqBb4OXGemg&s" alt="" height="50px" width="50px"/> */}
                {e}
            </>
        )
    }

    const handleSelect = (e) => {
        let allIds = e.map((v) => {
            return v.id
        })
        setPrivilegesIds(allIds)
    }

    const submitHandle = (e) => {
        e.preventDefault()
        if (!userName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter username")
            }
            return
        }
        if (!description) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter description")
            }
            return
        }
        if (privilegesIds.length <= 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select atleast one privileges")
            }
            return
        }
        if (!props?.selectedProduct) {
            let data = {
                postData: {
                    "name": userName,
                    "description": description,
                    "privilege_ids": privilegesIds
                },
                tokenData: auth?.payload?.token
            }
            setLoading(true)
            dispatch(createRoleApiAsync(data))
                .then(unwrapResult)
                .then((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }
                    let data = {
                        user_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                        // page: currentPage,
                        // limit: perPage
                    }
                    dispatch(getRoleApiAsync(data))
                        .then(unwrapResult)
                        .then((obj) => {
                            props?.setRoleUsers(obj?.payload?.roles)
                            setUserName("")
                            setDescription("")
                            setPrivilegesIds([])
                            setLoading(false)
                            props?.close();
                        }
                        )
                        .catch((obj) => {
                            setLoading(false)
                        })

                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        } else {
            let params = {
                postData: {
                    "id": props?.selectedProduct?.id,
                    "name": userName,
                    "description": description,
                    "privilege_ids": privilegesIds
                },
                tokenData: auth?.payload?.token
            }
            setLoading(true)
            dispatch(updateRoleApiAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }
                    let data = {
                        user_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                        // page: currentPage,
                        // limit: perPage
                    }
                    dispatch(getRoleApiAsync(data))
                        .then(unwrapResult)
                        .then((obj) => {
                            props?.setRoleUsers(obj?.payload?.roles)
                            setUserName("")
                            setDescription("")
                            setPrivilegesIds([])
                            setLoading(false)
                            props?.close();
                        }
                        )
                        .catch((obj) => {
                            setLoading(false)
                        })
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        dispatch(getPrivilegesApiAsync())
            .then(unwrapResult)
            .then((obj) => {
                const modifiedData = obj?.payload?.data?.map((item) => ({
                    value: item?.name,
                    id: item?.id
                }));
                setPriviliges(modifiedData)
            }
            )
            .catch((obj) => {
            })
    }, [])
    return (
        <>
            <div className='addEmployeeuser createRoles_'>
                <form onSubmit={(e) => submitHandle(e)} className='emplyoeeDetails horizontalForms'>
                    <div className='row'>
                        <div className='form-group col-md-6  mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Role Name</label>
                            <input
                                type='text'
                                name="text"
                                className='customform-control'
                                placeholder="Please Enter Role Name"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </div>
                       
                        <div className='form-group col-md-6 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Select Privileges</label>
                            <Multiselect
                                className='brandFilter customform-control'
                                displayValue="value"
                                options={privileges}
                                loading={loading}
                                isObject={true}
                                placeholder='Select Privileges'
                                hidePlaceholder={false}
                                selectedValues={selectedData?.length > 0 ? selectedData: ""}
                                // selectionLimit={1}
                                onSelect={handleSelect}
                                onRemove={handleSelect}
                                optionValueDecorator={(e) => handleOptionValue(e)}
                                selectedValueDecorator={(e) => handleOptionValue(e)}
                                avoidHighlightFirstOption={true}
                                showCheckbox
                            />
                        </div>
                        <div className='form-group col-md-12 mb-4'>
                            <label className='labelTxt mb-3' htmlFor='amount'>Description</label>
                            <textarea row="8" column="30" className='customform-control ' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description'></textarea>
                        </div>
                        <div className='footerSec mt-4'>
                            <button className='submitBtn' type="submit" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>{props?.selectedProduct ? 'Update Role' : "Create Role"}</span>
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </>
    )
}

export default CreateRoleModal