import React, { useState } from 'react'
import { locationBlue, appointClock, profileImg1 } from '../../../../utilities/images'
import moment from 'moment-timezone';

const AppointmentToday = (props) => {

  return (
    <>
      <div className='appointToday_'>
        {props.loading === true ?
          <span className="spinner-border inner-spin spinner-border-sm"></span>
          :
          <>
            <div>
              <h3 className='subheadText fw-bold mb-4'>Morning Session ({props?.morningData ? props?.morningData?.length : 0})</h3>
              <div className='row'>
                {
                  props?.morningData?.length > 0 ?
                    props?.morningData?.map((v, i) => {
                      return (
                        <div key={i} className='col-md-3 col-sm-3 mb-3'>
                          <div className='appointDetail_' onClick={() => props.details('AppointSchedule', v)} >
                            <div className='serviceDetail_'>
                              <img style={{ width: "70px" }} className='serviceProduct_ me-3' src={v?.product_image} />
                              <div className='serviceContent'>
                                <h5 className='callDate_ mb-1'>Service Name:</h5>
                                <h4 className='semiboldHeading_'>{v?.product_name}</h4>
                              </div>
                            </div>
                            <div className="subheaderInner mt-2">
                              <img style={{ width: "50px" }} className=' appointimg me-2' src={v?.user_details?.profile_photo ? v?.user_details?.profile_photo : profileImg1} />
                              <div className='bottomAppoint'>
                                <h6 className='appointHead'>{v?.user_details?.firstname + " " + v?.user_details?.lastname}</h6>
                                <p className='contentextLight'>
                                  <img src={locationBlue} />
                                  <span className='locBox_'>{v?.user_details?.current_address?.city},{v?.user_details?.current_address?.country}</span>
                                </p>
                              </div>
                            </div>
                            <div className='apointBottom mt-3' >
                              <div className='appointTime'>
                                <img className='apointwatch' src={appointClock} />
                                <span className='subapoint_'>{moment(v?.date).format("dddd MMMM DD, YYYY")}</span>
                              </div>
                              <div className='appointDate'>
                                <span className='subapoint_'>{v?.start_time + "-" + v?.end_time}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }) : <div >No Morning Appointment</div>
                }
              </div>
            </div>
            <div className='mt-4'>
              <h3 className='subheadText fw-bold mb-4'>After Noon Session ({props?.noonData ? props?.noonData?.length : 0})</h3>
              <div className='row'>
                {
                  props?.noonData?.length > 0 ?
                    props?.noonData?.map((v, i) => {
                      return (
                        <div key={i} className='col-md-3 col-sm-3 mb-3'>
                          <div className='appointDetail_' onClick={() => props.details('AppointSchedule', v)} >
                            <div className='serviceDetail_'>
                              <img style={{ width: "70px" }} className='serviceProduct_ me-3' src={v?.product_image} />
                              <div className='serviceContent'>
                                <h5 className='callDate_ mb-1'>Service Name:</h5>
                                <h4 className='semiboldHeading_'>{v?.product_name}</h4>
                              </div>
                            </div>
                            <div className="subheaderInner mt-2">
                              <img style={{ width: "50px" }} className=' appointimg me-2' src={v?.user_details?.profile_photo ? v?.user_details?.profile_photo : profileImg1} />
                              <div className='bottomAppoint'>
                                <h6 className='appointHead'>{v?.user_details?.firstname + " " + v?.user_details?.lastname}</h6>
                                <p className='contentextLight'>
                                  <img src={locationBlue} />
                                  <span className='locBox_'>{v?.user_details?.current_address?.city},{v?.user_details?.current_address?.country}</span>
                                </p>
                              </div>
                            </div>
                            <div className='apointBottom mt-3' >
                              <div className='appointTime'>
                                <img className='apointwatch' src={appointClock} />
                                <span className='subapoint_'>{moment(v?.date).format("dddd MMMM DD, YYYY")}</span>
                              </div>
                              <div className='appointDate'>
                                <span className='subapoint_'>{v?.start_time + "-" + v?.end_time}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }) : <div>No After Noon Appointment</div>
                }
              </div>
            </div>
          </>}
      </div>
    </>
  )
}

export default AppointmentToday