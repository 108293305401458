import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { detailuser, phoneBlue, emailBlue, locationBlue, Sendfill, profileImg1 } from '../../../../utilities/images'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../auth/authSlice'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { unwrapResult } from '@reduxjs/toolkit'
import { getSingleSupportDataApiAsync, getSupportTicketCountApiAsync } from '../../dashboard/dashboardSlice'
import moment from 'moment-timezone';
import { Socket } from '../../../components/shared/socket'
import { initializeSocket, getSocket, closeSocket } from '../../../components/shared/socketHandle'

import { toast } from 'react-toastify'
import DashboardNavbar from '../../dashboard/DashboardNavbar'


const SupportticketDetail = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const toastId = React.useRef(null)
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const [supportData, setSupportData] = useState('');
    const [countData, setCountData] = useState('');
    const [message, setMessage] = useState('');
    const [sendMessageUser, setSendMessageUser] = useState("");
    const [receiverMessage, setReceiverMessage] = useState("");
    const [sendMessage, setSendMessage] = useState("")
    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ block: "end", inline: "nearest" })
    }
    const [messageData, setMessageData] = useState([]);
    // var socket = Socket();

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = (e, flag) => {
        const socket = getSocket(); 
        e.preventDefault();
        let params
        if (flag == "text") {
            if (!message) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error("Please enter text message");
                }
                return;
            }
            else {
                params = {
                    recipient_id: String(receiverMessage),
                    content: message,
                    media_type: flag,
                    chatHeadType: "inquiry",
                    sender_id: String(sendMessageUser),
                    ticket_id: (supportData?.id).toString()
                }
            }
        }

        socket.emit("send_message", {
            ...params
        });

        socket.on("send_message", (data) => {
            setSendMessage(data)
            setMessage("")
        })
        socket.emit("get_messages", {
            id: id,
            idtype: 'ticket_id',
        })
        if (socket) {
            socket.on("get_messages", (data) => {
                console.log(data,"get_messages")
                setMessageData(data?.data?.data)
                const newdata = data?.data?.data[0];
                setSendMessageUser(newdata?.recipient_id);
                setReceiverMessage(newdata?.sender_id)
            })
        }
    }

    const supportDataHandle = () => {
        const socket = getSocket(); 
        let params = {
            "postData": {
                id: id,
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getSingleSupportDataApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setSupportData(obj?.payload);
                socket.emit("get_messages", {
                    id: id,
                    idtype: 'ticket_id',
                })
                let paramData = {
                    "postData": {
                        user_id: obj?.payload?.user_id,
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getSupportTicketCountApiAsync(paramData))
                    .then((obj) => {
                        setCountData(obj?.payload?.payload)
                        setLoading(false)
                    })
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        var socket = initializeSocket(auth?.payload?.uniqe_id ?? auth?.payload?.user?.unique_uuid);
        scrollToBottom()
        supportDataHandle()
    }, [])

    console.log(supportData, "support data")

    useEffect(() => {
        const socket = getSocket(); 
        if (socket) {
            socket.on("get_messages", (data) => {
                console.log(data,"get_messages_useEfffect")
                setMessageData(data?.data?.data)
                const newdata = data?.data?.data[0];
                setSendMessageUser(newdata?.recipient_id);
                setReceiverMessage(newdata?.sender_id)
            })
        }
    }, [ supportData, sendMessage]);

    useEffect(() => {
        scrollToBottom()
    }, [messageData]);

    return (
        <>
            {
                loading ?
                    <span className="spinner-border inner-spin spinner-border-sm serviceList"></span>
                    :
                    <>
                        <div className='suportticketDetails'>
                            <DashboardNavbar title="Ticket id #25649895" backShow={`/supportTicket`}/>

                        </div>
                        <div className='suportticketDetails_'>
                            <div className='row'>
                                <div className='col-lg-9'>
                                    <div className='informationcontentInner'>
                                        <div className='detailleftCommon'>
                                            {/* <div className='detailsTicket_'>
                                                <div className='detailsTicketinfo_'>
                                                    <img src={supportData?.user_detail?.user_profiles?.profile_photo ? supportData?.user_detail?.user_profiles?.profile_photo : profileImg1} alt='' />
                                                    <div className='nameDetail'>
                                                        <h5 className='contentSubtextbold'>{supportData?.user_detail?.user_profiles?.firstname}</h5>
                                                        <p className='tableHeadsmall'>{supportData?.user_detail?.user_profiles?.seller_type}</p>
                                                    </div>
                                                </div>
                                                <p className='descriptionPara_ mt-2'>{supportData?.notes}</p>
                                                <span className='headsubHeading__ d-flex justify-content-end'>{moment(supportData?.created_at).format("DD MMM, YYYY | hh:mm a")}</span>
                                            </div>
                                            <div className='detailsTicket_'>
                                                <div className='detailsTicketinfo_'>
                                                    <img src={detailuser} />
                                                    <div className='nameDetail'>
                                                        <h5 className='contentSubtextbold'>Florecilla Jonny</h5>
                                                        <p className='tableHeadsmall'>Manufacturer</p>
                                                    </div>
                                                </div>
                                                <p className='descriptionPara_ mt-2'>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ultrices suscipit vehicula. Phasellus feugiat, velit nec volutpat pretium, massa metus blandit sem, et auctor est nisi at neque. Phasellus eget velit efficitur, posuere erat sit amet, mollis ante. Nunc eleifend nisl commodo facilisis dictum. Sed sodales iaculis euismod. Etiam auctor lectus sed tortor pulvinar aliquam. Etiam in ipsum odio. Duis ut orci gravida mauris semper suscipit.
                                                </p>
                                                <span>----------</span>
                                                <p className='descriptionPara_ mb-3'>
                                                    Regards,
                                                </p>
                                                <p className='descriptionPara_ m-0'>
                                                    Satomi D.
                                                </p>
                                                <p className='descriptionPara_  m-0'>
                                                    Jobr.com Support Team
                                                </p>
                                                <span className='headsubHeading__ d-flex justify-content-end'>20 May, 2022   | 08:09am</span>
                                            </div> */}

                                            {
                                                messageData?.map((val, index) => {
                                                    return (
                                                        <div className='detailsTicket_' ref={messagesEndRef} key={index}>
                                                            <div className='detailsTicketinfo_'>
                                                                <img src={(val?.sender_id == supportData?.user_id && supportData?.user_detail?.user_profiles?.profile_photo) ? supportData?.user_detail?.user_profiles?.profile_photo : profileImg1} alt='' />
                                                                <div className='nameDetail'>
                                                                    <h5 className='contentSubtextbold'>{val?.sender_id == supportData?.user_id ? supportData?.user_detail?.user_profiles?.firstname : "Agent"}</h5>
                                                                    {/* <p className='tableHeadsmall'>{supportData?.user_detail?.user_profiles?.seller_type}</p> */}
                                                                </div>
                                                            </div>
                                                            <p className='descriptionPara_ mt-2'>{val?.content}</p>
                                                            <span className='headsubHeading__ d-flex justify-content-end'>{moment(val?.created_at).format("DD MMM, YYYY | hh:mm a")}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='chatInput_'>
                                            <i className="fal fa-ellipsis-v icon_" aria-hidden="true"></i>
                                            <input type="text" className=' chatbox' placeholder='Hi, There'
                                                value={message}
                                                onChange={handleMessageChange}
                                            />
                                            <button type="submit" className='sendBtnbox' onClick={(e) => handleSubmit(e, "text")} > <span className='me-2'>Send</span> <i class="fa-solid fa-paper-plane"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='supportRight_'>
                                        <div className='detailsTicketRight_'>
                                            <div className='detailsTicketinfo_ mb-2'>
                                                <img src={supportData?.user_detail?.user_profiles?.profile_photo ? supportData?.user_detail?.user_profiles?.profile_photo : profileImg1} />
                                                <div className='nameDetail'>
                                                    <h5 className='contentSubtextbold'>{supportData?.user_detail?.user_profiles?.firstname}</h5>
                                                    <p className='tableHeadsmall'>{supportData?.user_detail?.user_profiles?.seller_type}</p>
                                                </div>
                                            </div>
                                            <div className="detailsTicketinfoInner_">
                                                <p className='contentextLight'>
                                                    <img src={phoneBlue} />
                                                    <span>{supportData?.user_detail?.user_profiles?.full_phone_number}</span>
                                                </p>
                                                <p className='contentextLight'>
                                                    <img src={emailBlue} />
                                                    <span> {supportData?.user_detail?.email?.length > 25 ? supportData?.user_detail?.email?.substring(0, 25) + "..." : supportData?.user_detail?.email}</span>
                                                </p>
                                                <p className='contentextLight'>
                                                    <img src={locationBlue} />
                                                    <span> {supportData?.user_detail?.user_profiles?.current_address?.street_address?.length > 25 ? supportData?.user_detail?.user_profiles?.current_address?.street_address?.substring(0, 25) + "..." : supportData?.user_detail?.user_profiles?.current_address?.street_address}</span>
                                                </p>
                                            </div>
                                            <div className='totalTickets_ mt-3'>
                                                <h5 className='subInnersubhead'>Total Tickets: {countData?.total_tickets}</h5>
                                                <div className='totalticketBottom'>
                                                    <p className='smallHeading'>Solved: <strong>{countData?.solved_tickets}</strong></p>
                                                    <p className='smallHeading'>Cancelled: <strong>{countData?.cancel_tickets}</strong></p>
                                                </div>
                                                <div className='totalticketBottom'>
                                                    <p className='smallHeading'>Open: <strong>{countData?.open_tickets}</strong></p>
                                                    <p className='smallHeading'>Unrespond: <strong>{countData?.unrespond_tickets}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='asignAgent_ mt-3'>
                                            <h5 className='agentHeading'>Assigned agent:</h5>
                                            <div className='detailsTicketinfo_ mb-2'>
                                                <img src={supportData?.support_agent?.user_profiles?.profile_photo ? supportData?.support_agent?.user_profiles?.profile_photo : profileImg1} />
                                                <div className='nameDetail'>
                                                    <h5 className='contentSubtextbold'>{`${supportData?.support_agent?.user_profiles?.firstname ?? " "} ${supportData?.support_agent?.user_profiles?.lastname ?? " "}`}</h5>
                                                    <p className='tableHeadsmall'>{moment(supportData?.support_agent?.updated_at).format("DD MMM, YYYY | hh:mm a")}</p>
                                                </div>
                                            </div>
                                            {/* <h5 className='agentHeading mt-4' >Also on this ticket:</h5>
                                        <div className='detailsTicketinfo_ mb-2'>
                                            <img src={detailuser} />
                                            <div className='nameDetail'>
                                                <h5 className='contentSubtextbold'>Satomi D.</h5>
                                            </div>
                                        </div>
                                        <div className='detailsTicketinfo_ mb-2'>
                                            <img src={detailuser} />
                                            <div className='nameDetail'>
                                                <h5 className='contentSubtextbold'>Satomi D.</h5>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
        </>
    )
}

export default SupportticketDetail