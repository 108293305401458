import { unwrapResult } from '@reduxjs/toolkit'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../auth/authSlice'
import { updateSellerInfoApiAsync } from '../../dashboard/dashboardSlice'
const DefaultaddressModal = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth)
    const {addressDataModal,addressId,close,getAddressMerchant} = {...props}
    const [loading, setLoading] = useState(false)
    const address = addressDataModal;
    const [editAdress, setEditAddress] = useState({
        allow_local_pickup: address?.allow_local_pickup,
        allow_jobr_delivery: address?.allow_jobr_delivery,
        allow_local_drop_off: address?.allow_local_drop_off,
        allow_shipping: address?.allow_shipping
    })
    const handleChange = (e)=>{
        console.log(e.target.checked, e.target.name)
        setEditAddress((prevEditAddress) => ({
            ...prevEditAddress,
            [e.target.name]: !prevEditAddress[e.target.name],
        }))
    }
    const handleSave = () => {
        for(let i in editAdress){
            console.log(i)
            if (editAdress[i] === true){
                editAdress[i]="1"
            }
            else{
                editAdress[i]="0"
            }
        }
        let params = {
            "postData": {
                "id": address.id,
                ...editAdress
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(updateSellerInfoApiAsync(params))
            .then(unwrapResult)
            .then(()=>{
                setLoading(false);
                getAddressMerchant()
                close();
            })
    }

    return (
        <>
            <div className='commonmodal-header mb-4'>
                <div className='addheadLeft_'>
                    <h4 className='modalHeading_'>Default Address </h4>

                </div>
                <div className='addheadRight'>
                    <button className='addressdiscardBtn me-2' type="submit" onClick={()=>{close()}}>Discard</button>
                    <button className='addresssaveBtn bluebgcolor_' onClick={handleSave} type="submit" >Save</button>
                </div>
            </div>
            <div className='addaddressModal_ defaultaddressModal'>
            <Form className="addressForm_">
                    <div class="form-group">
                        <label class="contentheadtext_ fw-semibold">Identify As</label>
                        <h5 className='smalltextblack_'>{address?.address_type}</h5>
                    </div>
                    <div class="form-group">
                        <label class="contentheadtext_ fw-semibold">Street Address</label>
                        <h5 className='smalltextblack_'>{address?.street_address}</h5>
                    </div>
                    <div class="form-group">
                        <label class="contentheadtext_ fw-semibold">State</label>
                        <h5 className='smalltextblack_'>{address?.state}</h5>
                    </div>
                    <div class="form-group">
                        <label class="contentheadtext_ fw-semibold">City</label>
                        <h5 className='smalltextblack_'>{address?.city}</h5>
                    </div>
                    <div class="form-group">
                        <label class="contentheadtext_ fw-semibold">Zip Code</label>
                        <h5 className='smalltextblack_'>{address?.zipcode}</h5>
                    </div>
                    <div class="form-group">
                        <label class="contentheadtext_ fw-semibold">Country</label>
                        <h5 className='smalltextblack_'>{address?.country}</h5>
                    </div>
                   
                    <div class="mb-4 mt-4 form-group">
                        <div className='shippingavail_'>
                            <h5 className='testshiphead'>Shipping & Pickup Availability <span className='optionalhead_'>Optional</span></h5>
                            <div className='shippCheck_ mt-3'>
                                <div className='inputCheck_ mb-2'>
                                    <input
                                        type="checkbox"
                                        id='custom-checkbox-selectAll2'
                                        className='checkBox '
                                        name='allow_local_pickup'
                                        checked= {editAdress?.allow_local_pickup}
                                        onChange={handleChange}

                                    />
                                    <label htmlFor='custom-checkbox-selectAll2' className='checkBoxTxt '>Local Pickup</label>
                                </div>
                                <div className='inputCheck_ mb-2'>
                                    <input
                                        type="checkbox"
                                        id='custom-checkbox-selectAll3'
                                        className='checkBox '
                                        name='allow_jobr_delivery'
                                        checked= {editAdress?.allow_jobr_delivery}
                                        onChange={handleChange}

                                    />
                                    <label htmlFor='custom-checkbox-selectAll3' className='checkBoxTxt '>JOBR Delivery</label>
                                </div>
                                <div className='inputCheck_ mb-2'>
                                    <input
                                        type="checkbox"
                                        id='custom-checkbox-selectAll4'
                                        className='checkBox '
                                        name='allow_local_drop_off'
                                        checked= {editAdress?.allow_local_drop_off}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor='custom-checkbox-selectAll4' className='checkBoxTxt '>Local Drop-off</label>
                                </div>
                                <div className='inputCheck_ mb-2'>
                                    <input
                                        type="checkbox"
                                        id='custom-checkbox-selectAll5'
                                        checked= {editAdress?.allow_shipping}
                                        className='checkBox '
                                        name='allow_shipping'
                                        onChange={handleChange}

                                    />
                                    <label htmlFor='custom-checkbox-selectAll5' className='checkBoxTxt '>Shipping</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default DefaultaddressModal