import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ManufactureProfile from './manufactureProfile';
import ManufactureProduct from './manufactureProduct';
import ManufactureOrder from './manufactureOrder';
import ManufacturePurchased from './manufacturePurchased';
import { getManufactureDetailsByIdApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DashboardNavbar from '../../dashboard/DashboardNavbar';
import ProductList from '../../myProducts/productList';
import AllOrder from '../../myOrders/orders/allOrder';
import NewPurchase from '../../myPurchases/newPurchase';


const ManufactureDetails = () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState("manufactureProfile");
  const dispatch = useDispatch();
  const { flag, id } = useParams();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState("")
  const [purchasedData, setPurchasedData] = useState("")
  const [productList, setProductList] = useState("")
  const [orderData, setOrderData] = useState([])
  const [productsData, setProductsData] = useState([])
  const auth = useSelector(selectLoginAuth);

  const maufactureDataHandle = () => {
    let params = {
      "postData": {
        seller_id: id,
        type: flag,
        // page:1,
        // limit:10
      },
      "tokenData": auth?.payload?.token
    }
    setLoading(true)
    dispatch(getManufactureDetailsByIdApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setProfileData(obj?.payload?.profile)
        setProductsData(obj?.payload?.products?.data)
        setOrderData(obj?.payload?.orders?.data)
        setPurchasedData(obj?.payload?.purchase)
        let listOfProducts = obj?.payload?.products?.data?.map((v, i) => {
          return v?.name + (i === obj?.payload?.products?.data?.length - 1 ? "" : "/")
        })
        setProductList(listOfProducts)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  useEffect(() => {
    maufactureDataHandle()
  }, [activeTab])

  return (
    <>

      <DashboardNavbar title="Manufacturers/Ag" backShow={false} />


      <div className='marchatUser mb-3'>
        {/* <div className='topHeader_'>
          <div className="topStickyHeader_ mb-3">
            <div className="row  align-items-center">
              <div className="col-md-12">
                <div className='anotherHeader'>
                  <i className="las la-angle-left" onClick={() => { history.push(`/user-list/${flag}`) }} ></i>
                  <h2 className="innerSubheadNormal">Back</h2>
                </div>
              </div> 
            </div>
          </div>
        </div> */}

        <button className={`valuesTimeswallet ${activeTab === 'manufactureProfile' ? 'active' : ""}`}
          onClick={() => setActiveTab('manufactureProfile')}
        > Profile
        </button>
        <button className={`valuesTimeswallet ${activeTab === 'manufactureProducts' ? 'active' : ""}`}
          onClick={() => setActiveTab('manufactureProducts')}>
          Products
        </button>
        <button className={`valuesTimeswallet ${activeTab === 'manufactureOrders' ? 'active' : ""}`}
          onClick={() => setActiveTab('manufactureOrders')}>
          Orders
        </button>
        <button className={`valuesTimeswallet ${activeTab === 'manufacturePurchased' ? 'active' : ""}`}
          onClick={() => setActiveTab('manufacturePurchased')}>
          Purchased
        </button>
      </div>
      {
        loading ?
          <span className="spinner-border inner-spin spinner-border-sm"></span>
          :
          <div className='plansAreasContainer'>

            {activeTab == 'manufactureProfile'
              ?
              <ManufactureProfile data={profileData} loading={loading} productList={productList} />
              :
              activeTab == 'manufactureProducts'
                ?
                <ProductList seller_id={id} />
                :
                activeTab == 'manufactureOrders'
                  ?
                  <AllOrder seller_id={id}  />

                  :
                  activeTab == 'manufacturePurchased'
                    ?
                    <NewPurchase seller_id={id}  />

                    : ""
            }
          </div>}

    </>
  )
}

export default ManufactureDetails