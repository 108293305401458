import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationListAsync } from "./dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import { profileImg1 } from "../../../utilities/images";
import DashboardNavbar from "./DashboardNavbar";

const NotificationList = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [notifications, setNotifications] = useState([]);
  console.log(notifications, "notifications");

  const showNotifications = () => {
    let params = {
      tokenData: auth?.payload?.token,
    };

    dispatch(notificationListAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setNotifications(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    showNotifications();
  }, []);

  return (
    <>
      <DashboardNavbar title="Notification" backShow={false} />
      <div className="notifySection transectionCommon">
        {/* <div className="flexTable mb-4">
          <h4 className="appointMain text-start m-0">Notification</h4>
        </div> */}

        {loading ? (
          <td className="text-center" colSpan={12}>
            Loading...
          </td>
        ) : (
          <>
            {notifications?.map((data, idx) => {
              return (
                <div className="notificationArea" key={idx}>
                  <div className="NotificationSubArea">
                    <div className="taxCountryMain">
                      <div className="operatingCountry">
                        {/* <img
                          src={
                            data?.notification?.product_images
                              ? data?.notification?.product_images
                              : profileImg1
                          }
                          className="countryImg"
                          alt="countryImage"
                        /> */}
                        <div className="countryText">
                          <h4 className="cancelOrderText">
                            {" "}
                            {data?.notification?.title}
                          </h4>
                          <p className="settingText mt-1">
                            {" "}
                            {data?.notification?.description.length > 70
                              ? `${data?.notification?.description?.slice(
                                0,
                                70
                              )}...`
                              : data?.notification?.description}
                          </p>
                        </div>
                      </div>
                      <button className="notifyBtn" type="button">
                        {" "}
                        {moment(data?.notification?.created_at).fromNow()}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};
export default NotificationList;
