import React from 'react'

const SaleTaxModal = () => {
    return (
        <>
            <div className='saleTaxSection modalBodyBg'>
                <div className='nonTaxSection posCheckBx'>
                    <div className="form-check form-switch">
                        <input type="checkbox"
                        />
                    </div>
                    <h4 className='customTableHead'>Non-Taxable Product</h4>
                </div>
                <div className='salePercent'>
                    <div className='checkView'>
                        <input type="checkbox" className='checkBx' />
                    </div>
                    <div className='salePercentText'>
                        <h4 className='customTableHead '>Sales Tax: USA 10%</h4>
                        <p className='totalcharts_ '>Applicable for all Items & Services</p>
                    </div>
                </div>
                <p className='salePara'>Sales Taxes configured to apply to all items or categories are automatically applied to new items. Edit Sales Tax application rules in <span className='linkText '>Settings &gt; Sales Tax .</span></p>
            </div>
        </>
    )
}

export default SaleTaxModal