import React, { useEffect } from 'react'
import moment from 'moment-timezone';
import { openIcon, removeIcon, renameIcon, importIcon, export_light, calendar, wordIcon, filestore, dotsinbox, editWhite, viewIcon, imageImg1, pdfImg1 } from '../../../../utilities/images';
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import FileuploadModal from '../../message/directMessage/fileuploadModal';
import CustomModal from '../../../components/shared/CustomModal';
import ViewdocumentModal from './viewdocumentModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { createCatalogApiAsync, deleteCatalogApiAsync, getSingleFolderDataApiAsync, getStorageDataApiAsync, updateCatalogApiAsync, updateFolderAsync, uploadFileInCatelogAsync } from '../../dashboard/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CreatefolderModal from './createfolderModal';
import DashboardNavbar from '../../dashboard/DashboardNavbar';

const FolderOpen = () => {
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const history = useHistory()
    const dispatch = useDispatch()
    const [flag, setFlag] = useState("")
    const [pdfCaption, setPdfCaption] = useState("")
    const [pdfUrl, setPdfUrl] = useState("")
    const auth = useSelector(selectLoginAuth)
    const [oldName, setOldName] = useState("")
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { id } = useParams();
    const [folderData, setFolderData] = useState('');
    const [renameCatalogId, setRenameCatalogId] = useState("")
    const [key, setKey] = useState(Math.random());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [mimeType, setMimeType] = useState("");
    

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const handleRemoveCatalog = (removeId) => {
        const params = {
            "postData": {
                id: removeId
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(deleteCatalogApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    "postData": {
                        id: id,
                        page: currentPage,
                        limit: perPage
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getSingleFolderDataApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setFolderData(obj?.payload)
                        setTotalRows(obj?.payload?.total)
                        dispatch(getStorageDataApiAsync(auth?.payload?.token))
                            .then(unwrapResult)
                            .then((obj) => {
                                setLoading(false)
                            }
                            )
                            .catch((obj) => {
                                setLoading(false)
                            })
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const handleRenameCatalogInFolder = (folderName) => {
        const params = {
            "postData": {
                id: renameCatalogId,
                caption: folderName
            },
            "tokenData": auth?.payload?.token
        }
        setLoading1(true)
        dispatch(updateCatalogApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    "postData": {
                        id: id,
                        page: currentPage,
                        limit: perPage
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getSingleFolderDataApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setFolderData(obj?.payload)
                        setTotalRows(obj?.payload?.total)
                        handleOnCloseModal()
                        setLoading1(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading1(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading1(false)
            })
    }

    const handleRenameFolderInFolder = (folderName) => {
        const params = {
            "postData": {
                id: id,
                name: folderName
            },
            "tokenData": auth?.payload?.token
        }
        setLoading1(true)
        dispatch(updateFolderAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    "postData": {
                        id: id,
                        page: currentPage,
                        limit: perPage
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getSingleFolderDataApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setFolderData(obj?.payload)
                        setTotalRows(obj?.payload?.total)
                        handleOnCloseModal()
                        setLoading1(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading1(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading1(false)
            })
    }

    const handleViewClick = (e) => {
        setMimeType(e?.mime_type?.split('/')[0])
        setPdfCaption(e?.caption)
        setPdfUrl(e?.url)
        if (pdfCaption && pdfUrl) {
            setModalDetail({ show: true, flag: "ViewdocumentModal" });
            setKey(Math.random());
        }
    }

    const columns1 = [

        {
            name: "File Name",
            selector: row => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.mime_type?.split('/')[0] === "image" ? imageImg1 : row?.mime_type?.split('/')[1] === "pdf" ? pdfImg1 : wordIcon} />
                            <div className='ms-2'>
                                <span className='textParagh '>{row?.caption?.length > 15 ? row?.caption?.substring(0, 15) + "..." : row?.caption}</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            name: 'Folder',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '> <img className='doctabfile' src={filestore} />{folderData?.name?.length > 20 ? folderData?.name?.substring(0, 15) + "..." : folderData?.name}</span>
                    </>
                );
            },
        },

        {
            name: 'Last Modify',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{moment(row?.updated_at).format("DD MMM, YYYY | H : mm")}</span>
                    </>
                );
            },
        },


        {
            name: 'File Size',
            selector: row => ((row?.size) / (1024 * 1024)).toFixed(2) + "MB",

        },

        {
            name: '',
            selector: row => {
                return (
                    <>

                        <div className='dropdown typeBtn_'>
                            <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                <img className='dotIconn_' src={dotsinbox} />
                            </Link>
                            <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton2" >
                                <li onClick={() => {
                                    handleViewClick(row)
                                }}><Link to="#" className="dropdown-item chatdropItem">
                                        <img className='dropIcon' src={viewIcon} />
                                        <span className='subtextSmall_'>View</span></Link>
                                </li>
                                <li onClick={() => { setModalDetail({ show: true, flag: "CreatefolderModal" }); setKey(Math.random()); setFlag("renameCatalogInFolder"); setRenameCatalogId(row?.id); setOldName(row?.caption) }}><Link to="#" className="dropdown-item chatdropItem">
                                    <img className='dropIcon' src={renameIcon} />
                                    <span className='subtextSmall_'>Rename</span></Link>
                                </li>
                                <li onClick={() => handleRemoveCatalog(row?.id)}><Link to="#" className="dropdown-item chatdropItem" >
                                    <img className='dropIcon' src={removeIcon} />
                                    <span className='subtextSmall_'>Remove</span></Link>
                                </li>
                                <li> <a className="dropdown-item chatdropItem" href={row?.url} download>
                                    <img className='dropIcon' src={importIcon} />
                                    <span className='subtextSmall_'>Download</span>
                                </a>
                                </li>
                            </ul>
                        </div>
                    </>
                );
            },
        },
    ];

    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    }

    const uploadFileHandle = (file) => {
        let data = {
            "postData": {
                files: file,
                folder_id: id
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(createCatalogApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    "postData": {
                        id: id,
                        page: currentPage,
                        limit: perPage
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getSingleFolderDataApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setFolderData(obj?.payload)
                        setTotalRows(obj?.payload?.total)
                        dispatch(getStorageDataApiAsync(auth?.payload?.token))
                            .then(unwrapResult)
                            .then((obj) => {
                                setLoading(false)
                            }
                            )
                            .catch((obj) => {
                                setLoading(false)
                            })
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const singleFolderData = () => {
        let params = {
            "postData": {
                id: id,
                page: currentPage,
                limit: perPage
            },
            "tokenData": auth?.payload?.token
        }

        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getSingleFolderDataApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setFolderData(obj?.payload)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        singleFolderData()
    }, [perPage, currentPage, endDate])

    return (
        <>
            <DashboardNavbar title="My Catalogs " backShow={true} />
            <div className='folderOpen_'>

                <div className='topHeader_'>
                    <div className="topStickyHeader_">
                        <div className="row align-items-center">
                            <div className="col-md-6 d-flex align-items-center">
                                <h2 className="mainHeading me-2">
                                    <span><i onClick={() => history.push(`/catalogs`)} className="las la-angle-left directionArrow"></i></span>  <span>Folder / <span className="innerSubtext">{folderData?.name} </span> </span>
                                </h2>
                                <button style={{cursor:"pointer"}} onClick={() => { setModalDetail({ show: true, flag: "CreatefolderModal" }); setKey(Math.random()); setFlag("renameFolderInFolder"); setOldName(folderData?.name) }} className='innergrey_btn'>Edit <img className='editImg' src={editWhite} /></button>
                            </div>
                            <div className="col-md-6 text-end">
                                <button className='uploadBtn_' onClick={() => {
                                    setModalDetail({ show: true, flag: "FileUpload" });
                                    setKey(Math.random());
                                }}> <img className='export_' src={export_light} /> Upload File</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tableSectionBackground'>
                    <div className='tableFilters'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="calenderIcon">
                                    {/* <img src={calendar} alt="calender" /> */}
                                    {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                    dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                    {/* <DatePicker
                                    className='cateDate'
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    placeholderText="Date"
                                /> */}
                                </div>
                            </div>
                            {/* <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    Country
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div> */}
                            {/* <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    State
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    Id type
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div> */}

                            <div className='productMainCategory'>
                                <div className='categroyBox'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            All Categories
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>
                                <div className='categroyBox'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            All Locations
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <section className='table-responsive'>
                    <DataTable
                        pagination
                        columns={columns1}
                        data={folderData?.catalog}
                        defaultSortFieldId={1}
                        onRowClicked={handleRowClick}
                        progressPending={loading}
                        paginationServer
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                    /></section>
                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    backdrop="static"
                    showCloseBtn={false}
                    isRightSideModal={false}
                    mediumWidth={false}
                    className={modalDetail.flag === "CreatefolderModal" ? "commonWidth customContent" : modalDetail.flag === "FileUpload" ? "commonWidth customContent" : modalDetail.flag === "ViewdocumentModal" ? "viewdocumentModal" : ""}
                    ids={modalDetail.flag === "CreatefolderModal" ? "createfolderModal" : modalDetail.flag === "ViewdocumentModal" ? "viewdocumentModal" : modalDetail.flag === "FileUpload" ? "fileUpload" : ""
                    }

                    child={
                        modalDetail.flag === "CreatefolderModal" ? (
                            <CreatefolderModal oldName={oldName} loadingState={loading1} flag={flag} renameCatalogInFolder={(e) => handleRenameCatalogInFolder(e)} renameFolderInFolder={(e) => handleRenameFolderInFolder(e)} close={() => handleOnCloseModal()} />
                        ) :
                            modalDetail.flag === "FileUpload" ? (
                                <FileuploadModal uploadHandleInCatelog={(e) => uploadFileHandle(e)} flag={"catalog"} close={() => handleOnCloseModal()} />
                            )
                                :
                                modalDetail.flag === "ViewdocumentModal" ? (
                                    <ViewdocumentModal close={() => handleOnCloseModal()} pdfCaption={pdfCaption} pdfUrl={pdfUrl} mimeType={mimeType}/>
                                )
                                    :
                                    (
                                        ""
                                    )
                    }
                    header={
                        <div className='modalHeader_'>
                            <div className="common_">
                                {modalDetail.flag === "CreatefolderModal" ?
                                    <div className='anotherHeader'>
                                        <h2 className="innerSubheadNormal">{flag === 'renameFolderInFolder' ? 'Rename Folder' : flag === 'renameCatalogInFolder' ? 'Rename Catalog' : ''}</h2>
                                    </div>
                                    :
                                    modalDetail.flag === "FileUpload" ?
                                        <h2 className="modalHeading_ ">
                                            Image Upload
                                        </h2>
                                        :
                                        modalDetail.flag === "ViewdocumentModal" ?
                                            <h2 className="modalHeading">
                                                {pdfCaption}
                                            </h2>
                                            :
                                            ""
                                }
                                <button className="closeButton">
                                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                </button>
                            </div>
                        </div>
                    }

                    onCloseModal={() => handleOnCloseModal()}
                />
            </div>
        </>

    )
}

export default FolderOpen