import React, { useState } from 'react'
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component'
import { calendar, user, locationBlue, moneyIcon } from '../../../utilities/images'
import products from "../../db/category.json"
import { Link } from 'react-router-dom';

const CancelledAppointment = () => {
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
const columns1 = [
    {
        name: '#',
        width: "50px",
        selector: row => row.id

    },
    {
        name: 'Visited Time',
        selector: row => {
            return (
                <>
                    <div className='ms-2'>
                        <span className='contentSubtext_'>{row.date}</span>
                        <p className='textInner mt-2'>13: 21</p>
                    </div>
                </>
            );
        },
    },
    
    {
        name: "User",
        selector: row => {
            return (
                <>
                    <div className='d-flex align-items-center'>
                        <img className='userInnerImg' src={user} />
                        <div className='ms-2 text-start'>
                            <span className='contentSubtext_ '>{row.name}</span>
                            <p className='mb-0'>
                                <img src={locationBlue} className='img-fluid me-2' />
                                <span className='textInner'>Florida, USA </span>
                            </p>
                        </div>
                    </div>
                </>
            );
        },
    },
    {
        name: "Service Name",
        selector: row => row.service
    },
    {
        name: 'Appointment Id',
        selector: row => {
            return (
                <>
                    <div className='ms-2'>
                        <span className='contentSubtext_'>#1265484664</span>
                    </div>
                </>
            );
        },
    },
   

    {
        selector: row => row.amount,
        name: 'Amount'
    },
  
 
    {
        name: 'Status',
        selector: row => {
            return (
                <>
                    <div className='ms-2 tableBtn__'>
                        <Link to='#' className='deniedBtn' > Cancelled  </Link>
                       
                    </div>
                </>
            );
        },

    },
   
    {
        name: '',
        selector: row => {
            return (
                <>
                    <div className='ms-2'>
                     <i className="fal fa-chevron-right ms-3" aria-hidden="true"></i>
                    </div>
                </>
            );
        },
    },
]
    return (
        <>
        <div className='fulfilledAppointment_'>
            <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">Appointments / <span className="innerSubtext">Cancelled Appointments</span> </h2>
                        </div>

                    </div>
                </div>
            </div>
            <div className='tableFilters'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="calenderIcon">
                                <img src={calendar} alt="calender" />
                                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                    dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                <DatePicker
                                    className='cateDate'
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    placeholderText="Date"
                                />
                            </div>
                        </div>
                        {/* <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    Country
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    State
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    Id type
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div> */}
                    </div>
                </div>
        </div>
         <DataTable
         pagination
         columns={columns1}
         data={products}
         defaultSortFieldId={1}
         className="fullApointment"
     />
     </>
    )
}

export default CancelledAppointment