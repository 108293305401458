import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { dotsinbox, calendar, shield, user } from '../../../../utilities/images';
const PendingChats = () => {

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const columns1 = [
      {
        name: '#',
        selector: row => row.id,
        width: "50px" 
      },
      {
        name: "Name",
        selector: row => {
        return (
              <>
                <div className='d-flex align-items-center'>
                  <img className='userInnerImg' src={user} />
                  <div className='ms-2'>
                    <span className='contentSubtext_ '>{row.name}</span>
                    <p className='mb-0'>
                      <img src={shield} className='img-fluid me-2'/>
                      <span className='textInner'>260 101 480 0083 </span>
                    </p>
                  </div>
                </div>
              </>
            );
        },
        width: "300px", 
      },
      { name: 'Date',
      selector: row => {
        return (
              <>
                <div className='ms-2'>
                    <span className='contentSubtext_'>{row.date}</span>
                    <p className='textInner mt-2'>9:00:23a</p>
                </div>
              </>
            );
        },
        width: "220px",
      },
      {
        selector: row => row.value,
        name: 'Ticket id'
      },
      {
        selector: row => row.subject,
        name: 'Subject'
      },
      {
        selector: row => row.amount,
        name: 'User Type'
      },
      {
        selector: row => row.responsible,
        name: 'Responsible'
      },
      {
        name: 'Status',
        selector: row => {
          return (
                <>
                  <div className='ms-2 mt-2 tableBtn__ text-center'>
                      <Link to='/pendingchatDetail' className='secondryInner_'>Pending</Link>
                     
                  </div>
                </>
              );
          },
          width: "170px"
      },
      {
        name: '',
        selector: row => {
          return (
                <>
               <div className='dropdown chatditBtn'>
                            <Link to="#" id="dropdownMenuButton1" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                <img className='dotIcon_' src={dotsinbox} />
                            </Link>
                            <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                <li><Link to="#" className="dropdown-item chatdropItem " >
                                    <span className='subtextSmall_'>View Details</span></Link></li>
                                <li><Link to="/pendingTicketsassign" className="dropdown-item chatdropItem" >
                                    <span className='subtextSmall_'>Assign new Agent</span></Link>
                                </li>
                                <li><Link to="#" className="dropdown-item chatdropItem" >
                                    <span className='subtextSmall_'>Delete</span></Link>
                                </li>
                            </ul>
                        </div>
                </>
              );
          },
          width: "50px",
      },
    ];
  
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
  return (
    <div className='suportTicket'>
      <div className='commonTableheight table-responsive'>
        <DataTable
          pagination
            columns={columns1}
            data={products}
            defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
        </div>
    </div>
  )
}

export default PendingChats