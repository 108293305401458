import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import { getBusinessTypesAsync, getNaicsCategoriesAsync, businessRegistrationApiAsync } from '../dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from "@reduxjs/toolkit";
import Select from 'react-select';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import DashboardNavbar from '../DashboardNavbar';

const BusinessRegistration = () => {
    document.title = "BusinessRegistration";
    const [hide, setHide] = useState(false)
    const [displayValue, setDisplayValue] = useState()
    const toastId = React.useRef(null)
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(auth?.payload?.user?.email ? auth?.payload?.user?.email : auth?.payload?.email);
    const [dob, setDob] = useState("");
    const [employerIdentification, setEmployerIdentification] = useState("");
    const [businessName, setBusinessName] = useState(auth?.payload?.user?.user_profiles?.organization_name)
    const [businessWebsite, setBusinessWebsite] = useState(auth?.payload?.user?.user_profiles?.business_website ? auth?.payload?.user?.user_profiles?.business_website : "");
    const [designation, setDesignation] = useState("")
    const [address, setAddress] = useState(auth?.payload?.user?.user_profiles?.current_address?.format_address ? auth?.payload?.user?.user_profiles?.current_address?.format_address : "");
    const [apt, setApt] = useState("")
    const [zipCode, setZipCode] = useState("");
    const [countryValue, setCountryValue] = useState("");
    const [countryCode, setCountryCode] = useState("")
    const [cityValue, setCityValue] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [stateCode, setStateCode] = useState("")
    const [uuid, setUuid] = useState("");
    const [businessType, setBusinessType] = useState("")
    const [businessTypeList, setBusinessTypeList] = useState([]);
    // const [naicsCode, setNaicsCode] = useState("")
    // const [naicsCategoryList, setNaicsCategoryList] = useState([]);
    const [selectedNaics, setSelectedNaics] = useState(null);
    const [selectedNaicsId, setSelectedNaicsId] = useState("");
    const [NaicsList, setNaicsList] = useState([]);
    const generateRandomName = () => {
        return Math.random().toString(36).substr(2, 10);
    };


    const autoCompleteHandleChange = (address) => {
        if (address === "") {
            setCityValue("")
            setStateValue("")
            setCountryValue("")
            setZipCode("")
            setApt("")
        }
        setAddress(address);
    };

    const handleNAICSChange = (e) => {
        setSelectedNaics(e)
        setSelectedNaicsId(e.value);
    }

    const autoCompleteHandleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                fillAddressDetails(results);
                setDisplayValue(results[0]?.formatted_address?.split(',')[0])
                setHide(true)
            })
            .catch((error) => { });
    };

    const fillAddressDetails = (results) => {
        setAddress(results[0].formatted_address);

        for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] == "postal_code") {
                setZipCode(results[0].address_components[j].short_name);
            } else if (results[0].address_components[j].types[0] == "locality") {
                setCityValue(results[0].address_components[j].long_name);
            } else if (
                results[0].address_components[j].types[0] ==
                "administrative_area_level_1" ||
                results[0].address_components[j].types[0] === 'administrative_area_level_3' ||
                results[0].address_components[j].types[0] === 'locality'
            ) {
                setStateValue(results[0].address_components[j].long_name);
                setStateCode(results[0].address_components[j]?.short_name)
            } else if (results[0].address_components[j].types[0] == "country") {
                setCountryValue(results[0].address_components[j].long_name);
                setCountryCode(results[0].address_components[j]?.short_name);
            }
            else if (results[0].address_components[j].types[0] == 'street_number') {
                setApt(results[0].address_components[j].long_name);
            }
        }
    };

    const handleBusinessRegistration = async (e) => {
        e.preventDefault();
        if (!dob) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your DOB");
            }
            return;
        }
        if (!employerIdentification) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter employer identification number");
            }
            return;
        }
        if (!email) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your email");
            }
            return;
        }
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let resultemail = pattern.test(email);
        if (resultemail === false) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter valid email");
            }
            return;
        }
        if (!businessName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your business name");
            }
            return;
        }
        if (!businessWebsite) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your business webiste");
            }
            return;
        }
        let urlPattern = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
        let resultUrl = urlPattern.test(businessWebsite?.split(":")[0] === "https" ? businessWebsite : `https://${businessWebsite}`);
        if (resultUrl === false) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter valid webiste url");
            }
            return;
        }
        if (!businessType) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select your business type");
            }
            return;
        }
        if (!selectedNaicsId) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please Select Your NAICS Code");
            }
            return;
        }
        if (!address.length > 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your street address");
            }
            return;
        }
        if (!cityValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter City")
            }
            return
        }
        if (!stateValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter state")
            }
            return
        }
        if (!zipCode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your zipcode");
            }
            return;
        }
        if (!countryValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter country")
            }
            return
        }

        let businessData = {
            postData: {
                "address_type": "current",
                "address": address.slice(0, 40),
                "city": cityValue,
                "state": stateValue,
                "state_code": stateCode,
                "zip": zipCode,
                "country": countryValue,
                "country_code": countryCode,
                "phone_code": auth?.payload?.user?.user_profiles?.phone_code,
                "phone": auth?.payload?.user?.user_profiles?.phone_no,
                "email": email,
                "dob": moment(dob).format('YYYY-MM-DD'),
                "entity_name": businessName,
                "business_type": businessType,
                "business_type_uuid": uuid,
                "business_website": businessWebsite?.split(":")[0] === "https" ? businessWebsite : `https://${businessWebsite}`,
                "naics_code": selectedNaicsId,
                "business_registration_state": stateCode,
                "doing_business_as": designation,
                "employer_identification_number": employerIdentification
            },
            tokenData: auth?.payload?.token
        }

        if (apt) {
            businessData = {
                postData: {
                    ...businessData.postData,
                    "apt": apt
                },
                tokenData: auth?.payload?.token
            }
        }
        setLoading(true)
        await dispatch(businessRegistrationApiAsync(businessData))
            .then(unwrapResult)
            .then(async (obj) => {
                setLoading(false);
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                history.push('/requestKYB')
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    };

    const handleBusinessType = (e) => {
        let uuid = businessTypeList?.filter((val) => val.name === e.target.value)[0]?.uuid
        setUuid(uuid)
        setBusinessType(e.target.value)
    }
    // const handleNaicsCode = (e) => {
    //     setNaicsCode(e.target.value)
    // }

    const getBusinessTypes = () => {
        dispatch(getBusinessTypesAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setBusinessTypeList(obj?.payload)
            }
            )
            .catch((obj) => {
            })
    }

    const getNaicsCategories = () => {
        dispatch(getNaicsCategoriesAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                let filterResult = obj?.payload?.map((item) => {
                    return {
                        value: item?.code,
                        label: item?.subcategory
                    };
                });
                setNaicsList(filterResult)
                // setNaicsCategoryList(obj?.payload)
            }
            )
            .catch((obj) => {
            })
    }

    useEffect(() => {
        getBusinessTypes();
        getNaicsCategories();

    }, [])

    useEffect(() => {
        if (auth?.payload?.user?.user_profiles?.current_address?.format_address) {
            autoCompleteHandleSelect(auth?.payload?.user?.user_profiles?.current_address?.format_address)
            setApt(auth?.payload?.user?.user_profiles?.current_address?.apt)
        }
    }, [auth?.payload?.user?.user_profiles?.current_address?.format_address])

    return (
        <div className='businessRegistration'>
            {/* <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <i className="las la-long-arrow-alt-left me-3"></i>
                                <span>Business Registration</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div> */}
            <DashboardNavbar title="Business Registration" backShow="/plans" />
            <form className="form_horizontal" autoComplete="nope" onSubmit={(e) => handleBusinessRegistration(e)}>
                {/* <label className='titleTxt mb-3'>Personal Information</label> */}
                <div className='form_horizontalGroup'>
                    <div className='row'>
                        <div className="mb-4 form-group col-md-6">
                            <label className="deatilsHead mb-3">Email Address</label>
                            <input
                                type="email"
                                className="customform-control"
                                name={generateRandomName}
                                autoComplete="new-password"
                                placeholder="Email"
                                value={email}
                                disabled
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-4 form-group col-md-6">
                            <label className="deatilsHead mb-3">
                                Date of Incorporation (month / day  / year) </label>
                            <DatePicker
                                selected={dob}
                                name={generateRandomName}
                                autoComplete="new-password"
                                className="birth customform-control mb-0"
                                onChange={(date) => setDob(date)}
                                dateFormat="MM/dd/yyyy"
                                maxDate={moment().subtract(18, 'years').subtract(1, 'day')._d}
                                placeholderText="MM/DD/YYYY"
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Employer Identification Number</label>
                            <input
                                type="password"
                                className="customform-control"
                                placeholder="Employer Identification Number"
                                value={employerIdentification}
                                name={generateRandomName}
                                autoComplete="new-password"
                                maxLength={9}
                                onChange={e => setEmployerIdentification(e.target.value)}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Business Name</label>
                            <input
                                type="text"
                                className="customform-control"
                                placeholder="Business Name"
                                name={generateRandomName}
                                autoComplete="new-password"
                                value={businessName}
                                onChange={e => setBusinessName(e.target.value)}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Business Website</label>
                            <input
                                type="text"
                                className="customform-control"
                                placeholder="e.g. jobr.com"
                                value={businessWebsite}
                                name={generateRandomName}
                                autoComplete="new-password"
                                onChange={e => setBusinessWebsite(e.target.value)}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Doing Business as</label>
                            <input
                                type="text"
                                className="customform-control"
                                placeholder="Doing Business as"
                                value={designation}
                                name={generateRandomName}
                                autoComplete="new-password"
                                onChange={e => setDesignation(e.target.value)}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className='deatilsHead mb-2'>Select Business Type</label>
                            <select className='select customform-control' defaultValue="Select Business Type" autoComplete="new-password" onChange={(e) => handleBusinessType(e)}>
                                <option value="Select Business Type" disabled >Select Business Type</option>
                                {(businessTypeList && businessTypeList.length > 0) && businessTypeList.map((value, index) => {
                                    return (
                                        <option value={value?.name} uuid={value?.uuid} key={index}>{value?.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className='deatilsHead mb-2'>Select NAICS Code</label>
                            {/* <select className='select customform-control' defaultValue="Select Naics Code" autoComplete="new-password" onChange={(e) => handleNaicsCode(e)}>
                                <option value="Select Naics Code" disabled>Select NAICS Code</option>
                                {naicsCategoryList?.map((value, index) => {
                                    return (
                                        <option value={value?.code} key={index}>{value?.subcategory}</option>
                                    )
                                })}
                            </select> */}

                            <Select
                                value={selectedNaics}
                                onChange={handleNAICSChange}
                                options={NaicsList}
                                isSearchable={true}
                                placeholder="Select NAICS"
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Current Address</label>
                            <div className="inputGroup">
                                {
                                    hide ?
                                        <span
                                            className="customform-control countrytags_ w-100"
                                            onClick={() => setHide(!hide)}
                                        >{displayValue}</span>
                                        :
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={autoCompleteHandleChange}
                                            onSelect={autoCompleteHandleSelect}
                                            searchOptions={{
                                                componentRestrictions: {
                                                    country: ['us', 'ind'],
                                                }
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Street Address',
                                                            className: 'location-search-input customform-control',
                                                        })}
                                                    />
                                                    <div className="aautocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                }
                            </div>
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Apt.</label>
                            <input
                                type="text"
                                className="customform-control"
                                placeholder="Apt."
                                name={generateRandomName}
                                autoComplete="new-password"
                                value={apt} onChange={e => setApt(e.target.value)}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Country</label>
                            <input
                                className="customform-control id_password"
                                placeholder="Country"
                                name={generateRandomName}
                                autoComplete="new-password"
                                type="text"
                                value={countryValue}
                                onChange={(e) => setCountryValue(e.target.value)}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">City</label>
                            <input
                                className="customform-control id_password"
                                placeholder="City"
                                name={generateRandomName}
                                autoComplete="new-password"
                                type="text"
                                value={cityValue}
                                onChange={(e) => setCityValue(e.target.value)}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">State</label>
                            <input
                                className="customform-control id_password"
                                placeholder="State"
                                type="text"
                                value={stateValue}
                                name={generateRandomName}
                                autoComplete="new-password"
                                onChange={(e) => setStateValue(e.target.value)}
                            />
                        </div>
                        <div className='mb-4 form-group col-md-6'>
                            <label className="deatilsHead mb-3">Zip Code</label>
                            <input
                                type="text"
                                className="customform-control"
                                placeholder="Zip code"
                                value={zipCode}
                                maxLength={8}
                                name={generateRandomName}
                                autoComplete="new-password"
                                onChange={(e) => setZipCode(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='row justify-content-center'>

                        <button type="submit" className='staticBtn mt-5 col-md-4' disabled={loading}>
                            {loading === true && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            Next Step
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default BusinessRegistration