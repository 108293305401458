import React, { useState } from 'react'
import {
    transactionIcon, moneyBag, walletCircle, walletCard, walletGraph, featureChart,
    campChart,
} from '../../../utilities/images'
import ChartArea from '../dashboard/wallet/ChartArea'
import { geoCentroid } from "d3-geo";
import { ComposableMap, Geographies, Geography, Marker, Annotation } from "react-simple-maps";
import allStates from "./data/allstates.json";
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';



const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
};

const Campaign = () => {
    const [filterValue, setFilterValue] = useState("week");
    const [tooltipContent, setTooltipContent] = useState('');

    const handleMouseEnter = (event, geography, name) => {
        setTooltipContent(name);
    };
    const handleMouseLeave = () => {
        setTooltipContent('');
    };

    const handleFilterValue = (value) => {
        setFilterValue(value);
    }

    const options1 = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    }

    const barData = {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [
            {
                label: 'Completed',
                data: [12, 56, 70, 10, 21, 11, 10],
                backgroundColor: '#FCBA30',
            },
            {
                label: 'Cancelled',
                data: [10, 40, 60, 10, 21, 20, 30],
                backgroundColor: '#275AFF',
            },
        ],
    };

    const ReferralLink = {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [
            {
                label: 'Completed',
                data: [12, 56, 70, 10, 21, 11, 10],
                backgroundColor: '#6C63F0',
            },
        ],
    };

    const doughnutData = {
        labels: ['Active', 'In-active'],
        datasets: [
            {
                label: 'Percentage',
                data: [90, 10],
                backgroundColor: [
                    '#275AFF',
                    '#FB466C'
                ],
                borderColor: [
                    '#275AFF',
                    '#FB466C'
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <>
            <DashboardNavbar title="Campaign Overview" backShow={false} />
            <div className='campaignMain'>
                <div className='row mt-4'>
                    <div className='col-lg-12 d-flex justify-content-end'>
                        <ul className='weekNav'>
                            <li onClick={() => setFilterValue("today")}><Link className={`weekMenu ${filterValue == "today" ? "active" : ""}`} to="#">Today</Link></li>
                            <li onClick={() => setFilterValue("week")}><Link className={`weekMenu ${filterValue == "week" ? "active" : ""}`} to="#">Weekly</Link></li>
                            <li onClick={() => setFilterValue("month")}><Link className={`weekMenu ${filterValue == "month" ? "active" : ""}`} to="#">Monthly</Link></li>
                            <li onClick={() => setFilterValue("year")}><Link className={`weekMenu ${filterValue == "year" ? "active" : ""}`} to="#">Yearly</Link></li>
                        </ul>
                    </div>
                </div>
                <div className='walletOverviewData '>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-6 col-md-6  pe-2 mt-3'>
                            <div className='grossTotalorder'>
                                <img src={transactionIcon} className='img-fluid' alt='transactionIcon' />
                                <p className='groSub mt-4 mb-0'>Total Clicks</p>
                                <h4 className='groMain'>$8,426,590</h4>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6  ps-2 pe-2 mt-3'>
                            <div className='grossTotalorder'>
                                <img src={moneyBag} className='img-fluid' alt='moneyBagImage' />
                                <p className='groSub mt-4 mb-0'>Average Click </p>
                                <h4 className='groMain'>$345,690</h4>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 ps-2 pe-2 mt-3'>
                            <div className='grossTotalorder'>
                                <img src={walletCircle} className='img-fluid' alt='walletCircleImage' />
                                <p className='groSub mt-4 mb-0'>Conversion Cost</p>
                                <h4 className='groMain'>$230,004</h4>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 ps-2 mt-3'>
                            <div className='grossTotalorder'>
                                <img src={walletCard} className='img-fluid' alt='walletcardImg' />
                                <p className='groSub mt-4 mb-0'>Conversion Sales</p>
                                <h4 className='groMain'>$568,900</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tradesValue mt-4 mb-4'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='chartsOuter'>
                                <h3 className="placementText mt-3">Conversion Sales</h3>
                                {/* <div className='processStatus mt-3'>
                        <p className='statusbox_'><span className='complete_'></span> Cash In</p>
                        <p className='statusbox_'> <span className='cancel_'></span> Cash Out</p>
                      </div> */}
                                <div className='graphChecked_ mt-3 mb-2 '>
                                    <div className='graphBox_ me-3'>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll2'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll2' className='checkBoxTxt masterChecked'>Online Customers</label>
                                    </div>
                                    <div className='checkYellow me-3'>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll3'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll3' className='checkBoxTxt masterChecked'>New Customers</label>
                                    </div>
                                </div>
                                <div className='row mt-4 mb-4 align-items-center'>
                                    <div className='col-md-12'>
                                        <img src={walletGraph} alt="Graph Image" className='img-fluid' />
                                        {/* <ChartArea classes="productSales mt-2 mb-2" header="Services Sales" options={options1} data={serviceSalesData} chartType="Line" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xxl-7  col-xl-7  col-lg-12 '>
                        <div className='campaignPlacement'>
                            <h4 className='placementText'>Sales Comparison by Placement Ads</h4>
                            <div className='campaignPlaceSub justify-content-between '>
                                <div className='chartCheckBox graphChecked_  '>
                                    <div className='graphBox_ mt-3 '>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll6'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll6' className='checkBoxTxt masterChecked'>Main Home Page</label>
                                    </div>
                                    <div className='graphCheck_ mt-3'>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll8'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll8' className='checkBoxTxt masterChecked'>Category Page</label>
                                    </div>
                                    <div className='pinkCheck_ mt-3'>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll9'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll9' className='checkBoxTxt masterChecked'>Shop Page</label>
                                    </div>
                                    <div className='greenCheck_ mt-3'>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll11'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll11' className='checkBoxTxt masterChecked'>Top Search </label>
                                    </div>
                                    <div className='checkYellow mt-3'>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll7'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll7' className='checkBoxTxt masterChecked'>Top New Release</label>
                                    </div>
                                </div>
                                <figure className='campChartImg'>
                                    <img src={campChart} alt="Graph Image" className='img-fluid ' />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className=' col-xxl-5  col-xl-5  col-lg-12 '>
                        <div className='campaignPlacement'>
                            <h4 className='placementText'>Sales Comparison by Feature Shop</h4>
                            <div className='campaignFeatureSub justify-content-center'>
                                <div className='graphChecked_ '>
                                    <div className='graphBox_  mt-3'>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll4'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll4' className='checkBoxTxt masterChecked'>Main Home Page</label>
                                    </div>
                                    <div className='graphCheck_ mt-3'>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll' className='checkBoxTxt masterChecked'>Category Page</label>
                                    </div>
                                    <div className='checkYellow mt-3'>
                                        <input
                                            type="checkbox"
                                            id='custom-checkbox-selectAll5'
                                            className='checkBox '
                                            name='custom-checkbox-selectAll'
                                            value='custom-checkbox-selectAll'
                                        />
                                        <label htmlFor='custom-checkbox-selectAll5' className='checkBoxTxt masterChecked'>Top 10 Shops Preview</label>
                                    </div>
                                </div>
                                <img src={featureChart} alt="Graph Image" className='img-fluid  mt-4' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Campaign