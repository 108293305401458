import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { quickbookGetTokenApiAsync, quickbookSyncDataApiAsync } from '../dashboard/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const GetTokenModal = (props) => {
  const [url, setUrl] = useState("")
  const [loading, setLoading] = useState(false)
  const toastId = React.useRef(null)
  const auth = useSelector(selectLoginAuth)
  const dispatch = useDispatch()
  const handleSubmit = () => {
    if (!url) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter url!");
      }
      return;
    }
    let params = {
      postData: {
        "url": url
      },
      tokenData: auth?.payload?.token
    }
    setLoading(true)
    dispatch(quickbookGetTokenApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        dispatch(quickbookSyncDataApiAsync(auth?.payload?.token))
          .then(unwrapResult)
          .then((obj) => {
            setLoading(false)
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.success(obj?.msg)
            }
            props?.close()
          }
          )
          .catch((obj) => {
            setLoading(false)
          })
      }
      )
      .catch((obj) => {
        setLoading(false)
      })

  }
  return (
    <div className='commonPadding_'>
      <p className='subHeading'>Enter the authorization url to sync order</p>
      <form>
        <div className='row'>
          <div className="mb-4 form-group col-md-10">
            <label className="deatilsHead mb-3">Url</label>
            <input
              type="text"
              className="customform-control"
              name="text"
              placeholder="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div className=" form-group col-md-2">
            <label className="deatilsHead mt-3"></label>
            <button className='sendBtn' type="button" onClick={() => handleSubmit()}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              &nbsp;&nbsp;
              <span>Sync</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default GetTokenModal