import React from "react";
import moment from "moment-timezone";

const CouponViewModal = (props) => {
  console.log(props.selectedProduct, "viewprop");
  return (
    <>
      <div className="scheduleModal">
        <div className="scheduleModalContent">
          <div className="userProfileinfo_">
            <img
              style={{ width: "300px", height: "150px" }}
              className="scheduleimg me-3"
              src={props?.selectedProduct?.image}
            />
          </div>
          <div className="appointmentDetails_">
            <h3>Coupon Details</h3>
            <div className="appointInner mb-4">
              <div className="contentappont">
                <p className="mainlightPhragraph mb-1">
                  <b>Code:</b>&nbsp;{props?.selectedProduct?.code}
                </p>

                {props?.selectedProduct?.type === "percentage" ? (
                  <>
                    {/* set maximum discount */}

                    <p className="mainlightPhragraph mb-1">
                      <b>Maximum Discount:</b>&nbsp;
                      ${props?.selectedProduct?.max_discount
                        ? props?.selectedProduct?.max_discount.toLocaleString()
                        : "0.00"}
                    </p>
                    {/* minimum discount */}
                    <p className="mainlightPhragraph mb-1">
                      <b>Minimum Discount:</b>&nbsp;
                      ${props?.selectedProduct?.min_discount
                        ? props?.selectedProduct?.min_discount.toLocaleString()
                        : "0.00"}

                    </p>

                    {/* discount percentage */}
                    <p className="mainlightPhragraph mb-1">
                      <b>Discount Percentage: </b>&nbsp;
                      {props?.selectedProduct?.discount_percentage}%
                    </p>
                  </>
                ) : (
                  <p className="mainlightPhragraph mb-1">
                    <b>Discount Amount :</b>&nbsp; $
                    {props?.selectedProduct?.discount_amount}
                  </p>
                )}

                {/* <p className="mainlightPhragraph mb-1">
                  <b>Maximum total Usage</b>&nbsp;$
                  {props?.selectedProduct?.max_total_usage}
                </p>

                <p className="mainlightPhragraph mb-1">
                  <b>Minimum order Amount</b>&nbsp;$
                  {props?.selectedProduct?.minimum_order_amount}
                </p> */}

                <p className="mainlightPhragraph mb-1">
                  <b>Maximum Total Usage :</b>&nbsp;
                  {props?.selectedProduct?.max_total_usage
                    ? `${props?.selectedProduct?.max_total_usage.toLocaleString()}`
                    : ""}
                </p>

                <p className="mainlightPhragraph mb-1">
                  <b>Minimum Order Amount:</b>&nbsp;$
                  {props?.selectedProduct?.minimum_order_amount
                    ? props?.selectedProduct?.minimum_order_amount.toLocaleString()
                    : "0.00"}
                </p>

                <p className="mainlightPhragraph mb-1">
                  <b>Type:</b>&nbsp;
                  {props?.selectedProduct?.type === "percentage"
                    ? "Percentage"
                    : "Flat"}
                </p>

                <p className="mainlightPhragraph mb-1">
                  <b>Description:</b>&nbsp;{props?.selectedProduct?.description}
                </p>

                <p className="mainlightPhragraph mb-1">
                  <b>Start Date: </b> {moment?.utc(props?.selectedProduct?.start_time)?.format("MMM DD, YYYY")}
                </p>
                <p className="mainlightPhragraph mb-1">
                  <b>Coupon Validity: </b> {moment?.utc(props?.selectedProduct?.end_time)?.format("MMM DD, YYYY")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponViewModal;
