import React, { useState } from 'react'
import { resetmodalimg } from "../../../utilities/images";

const ScheduleModal = (props) => {
  return (
    <div className='noHeaderModal p-2 forgotModal'>

      <button className="closeAction"><i className="las la-times" onClick={props.close}></i></button>

      <div className='noHeaderModalcontent'>
        <img src={resetmodalimg} />
        <h4>A verification code sent to your phone number</h4>
        <p>Please use this code to set your new password</p>

      </div>
    </div>
  )
}

export default ScheduleModal