import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { createFaqApiAsync, getFaqCategoryDataApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';

const AddnewfaqModal = (props) => {
  const dispatch = useDispatch()
  const [faq, setFaq] = useState("");
  const auth = useSelector(selectLoginAuth);
  const [buttonLoading, setButoonLoading] = useState(false);
  const [faqText, setFaqText] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [retailStatus, setRetailStatus] = useState(false)
  const [manufactureStatus, setManufactureStatus] = useState(false)
  const [distroStatus, setDistroStatus] = useState(false)
  const [driverStatus, setDriverStatus] = useState(false)
  const [deliveryStatus, setDeliveryStatus] = useState(false)
  const [walletStatus, setWalletStatus] = useState(false)
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const items = [
    { id: 1, label: 'Retail Admin', status: retailStatus },
    { id: 2, label: 'Manufacture Admin', status: manufactureStatus },
    { id: 3, label: 'Distro Admin', status: distroStatus },
    { id: 4, label: 'Driver App', status: driverStatus },
    { id: 5, label: 'Wallet App', status: walletStatus },
    { id: 6, label: 'Delivery App', status: deliveryStatus },
  ];


  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleCheckboxChange = (id, status) => {
    if (id === 1) {
      setRetailStatus(!status)
    }
    if (id === 2) {
      setManufactureStatus(!status)
    }
    if (id === 3) {
      setDistroStatus(!status)
    }
    if (id === 4) {
      setDriverStatus(!status)
    }
    if (id === 5) {
      setWalletStatus(!status)
    }
    if (id === 6) {
      setDeliveryStatus(!status)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      toast.error("please select category");
      return;
    }
    if (!faq) {
      toast.error("Please write faq");
      return;
    }
    if (!faqText) {
      toast.error("Please write faq answer");
      return;
    }
    if (!retailStatus && !manufactureStatus && !distroStatus && !driverStatus && !walletStatus && !deliveryStatus) {
      toast.error("Please select atleast one platform for publish");
      return;
    }
    let data = {
      "postData": {
        "faq_category_id": selectedCategory,
        "title": faq,
        "discription": faqText,
        "retail_admin": retailStatus,
        "manufacture_admin": manufactureStatus,
        "distro_admin": distroStatus,
        "driver_app": driverStatus,
        "wallet_app": walletStatus,
        "delivery_app": deliveryStatus
      },
      "tokenData": auth?.payload?.token
    }
    setButoonLoading(true)
    dispatch(createFaqApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        props?.faqRef?.current?.getProductListParent()
        props?.close()
        setButoonLoading(false)
      })
      .catch((obj) => {
        setButoonLoading(false)
      })
  }

  const faqCatagoryData = () => {
    let params = {
      // "postData": {
      //   page: 1,
      //   limit: 10
      // },
      "tokenData": auth?.payload?.token
    }
    dispatch(getFaqCategoryDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAllCategories(obj?.payload?.data)
      })
      .catch((obj) => {
      })
  }

  useEffect(() => {
    faqCatagoryData()
  }, [])

  return (
    <div className='addnewfaqModal'>
      <form className='emplyoeeDetails horizontalForms' onSubmit={(e) => { handleSubmit(e) }} >
        
        <div className='row'>
          <div className='form-group mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>Select Category </label>
            <select className='customform-control select fw-normal' placeholder='Select Category' value={selectedCategory} onChange={handleSelectChange}>
              <option value="" >Select Category</option>
              {allCategories?.map((option, index) => (
                <option key={index} value={option?.id}>
                  {option?.category_name}
                </option>
              ))}
            </select>
          </div>
          <div className='form-group col-md-12  mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>FAQ </label>
            <input type='text' className='customform-control' placeholder='FAQ'
              value={faq} onChange={e => setFaq(e.target.value)} />
          </div>
          <div className='form-group col-md-12  mb-4'>
            <textarea className='customform-control' name="w3review" rows="8" cols="50" placeholder=' Write FAQ answer here'
              value={faqText} onChange={e => setFaqText(e.target.value)} >

            </textarea>
          </div>
          <div className='faqcheck_'>
            <h4 className='subheadText fw-bold mb-3'>Select Platform for Publish</h4>
            <div className='row'>
              {items?.map((item, i) => (
                <div className=' col-md-6 mb-3' key={i}>
                  <input
                    className="me-2"
                    type="checkbox"
                    value={item}
                    checked={item?.status === true}
                    onChange={() => handleCheckboxChange(item?.id, item?.status)}
                  />
                  <label className='contentheadtext_' >  {item?.label}</label>
                </div>
              ))}
              {/* 
              <div className='col-md-6 mb-3'>
               {/* <input className="me-2" type="checkbox" id="view1" value="" />
                <label className='contentheadtext_' for="view1"> Retail Admin</label> 
              </div>
              <div className='col-md-6 mb-3'>
                <input className="me-2" type="checkbox" id="view2" value="" />
                <label className='contentheadtext_' for="view2"> Driver App</label>
              </div>
              <div className=' col-md-6 mb-3'>
                <input className="me-2" type="checkbox" id="view3" value="" />
                <label className='contentheadtext_' for="view3"> Manufacture Admin</label>
              </div>
              <div className='col-md-6 mb-3'>
                <input className="me-2" type="checkbox" id="view4" value="" />
                <label className='contentheadtext_' for="view4"> Wallet App</label>
              </div>
              <div className=' col-md-6 mb-3'>
                <input className="me-2" type="checkbox" id="view5" value="" />
                <label className='contentheadtext_' for="view5"> Distro Admin</label>
              </div>
              <div className='col-md-6 mb-3'>
                <input className="me-2" type="checkbox" id="view6" value="" />
                <label className='contentheadtext_' for="view6"> Delivery App</label>
              </div> */}
            </div>
        
         
        </div>
        </div>
        

      <div className='footerSec mt-4 '>
            <button className='discardBtn me-3' onClick={props.close}>Cancel</button>
            {/* <button className='saveBtn_ lh-sm me-3' type='button'>Save as Draft</button> */}
            {/* <button className='publishBtn_ lh-sm' type="submit" disabled={buttonLoading}>
              {buttonLoading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Publish</span>
            </button> */}
            <button className='saveBtn saveactive' type='submit'>
            {buttonLoading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Save</span>
              </button>
          </div>
          </form>
    </div>
  )
}

export default AddnewfaqModal