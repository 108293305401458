import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { updateUserSettingAsync } from '../systemConfig/systemConfigSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const BookingsettingModal = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [view, setView] = useState(props?.userSettingData?.calender_view);
  const [format, setFormat] = useState(props?.userSettingData?.time_format);
  const [acceptRequest, setAcceptRequest] = useState(props?.userSettingData?.accept_appointment_request);
  const [color, setColor] = useState(props?.userSettingData?.employee_color_set);

  const handleUpdateSettings = () => {
    let data = {
      postData: {
        calender_view: view,
        time_format: format,
        accept_appointment_request: acceptRequest,
        employee_color_set: color
      },
      tokenData: auth?.payload?.token
    }
    setLoading(true)
    dispatch(updateUserSettingAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        props?.handleAllAppointmentData()
        props.close()
        setLoading(false)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  return (
    <div className='bookingsettingModal_'>
      <h2 className='modalHeading_'>Preference</h2>
      <hr />
      <div className='booksettingContent_'>
        <div className='calenderView_'>
          <h4 className='monsHeading14'>Default Calendar view</h4>
          <ul>
            <li>
              <input value={view} checked={view === 'day'} onChange={(e) => setView("day")} type="radio" id="f-option" name="view" />
              <label for="f-option">Day View</label>
              <div class="check"></div>
            </li>

            <li>
              <input value={view} checked={view === 'week'} onChange={(e) => setView("week")} type="radio" id="s-option" name="view" />
              <label for="s-option">Week View</label>

              <div class="check"><div class="inside"></div></div>
            </li>

            <li>
              <input value={view} checked={view === 'month'} onChange={(e) => setView("month")} type="radio" id="t-option" name="view" />
              <label for="t-option">Month View</label>

              <div class="check"><div class="inside"></div></div>
            </li>
          </ul>
        </div>
        <div className='calenderView_'>
          <h4 className='monsHeading14'>Time Format</h4>
          <ul>
            <li>
              <input value={format} checked={format === '12'} onChange={(e) => setFormat("12")} type="radio" id="time1" name="format" />
              <label for="time1">12 Hours (AM/PM)</label>
              <div class="check"></div>
            </li>

            <li>
              <input value={format} checked={format === '24'} onChange={(e) => setFormat("24")} type="radio" id="time2" name="format" />
              <label for="time2">24 Hours</label>

              <div class="check"><div class="inside"></div></div>
            </li>
          </ul>
        </div>
        <div className='calenderView_'>
          <h4 className='monsHeading14'>Appointment Request </h4>
          <ul>
            <li>
              <input value={acceptRequest} checked={acceptRequest === 'manually'} onChange={(e) => setAcceptRequest("manually")} type="radio" id="acceptmanu" name="request" />
              <label for="acceptmanu">Accept Manually</label>
              <div class="check"></div>
            </li>

            <li>
              <input value={acceptRequest} checked={acceptRequest === 'automatically'} onChange={(e) => setAcceptRequest("automatically")} type="radio" id="acceptauto" name="request" />
              <label for="acceptauto">Accept Automatically</label>

              <div class="check"><div class="inside"></div></div>
            </li>
          </ul>
        </div>
        <div className='calenderView_'>
          <h4 className='monsHeading14'>Employee’s Color set</h4>
          <ul>
            <li>
              <input value={color} checked={color === 'default'} onChange={(e) => setColor("default")} type="radio" id="default1" name="color" />
              <label for="default1">Default</label>
              <div class="check"></div>
            </li>

            <li>
              <input value={color} checked={color === 'manual'} onChange={(e) => setColor("manual")} type="radio" id="manual1" name="color" />
              <label for="manual1">Manual</label>
              <div class="check"><div class="inside"></div></div>
            </li>
          </ul>
        </div>
      </div>
      <div className='modalbottonFooter_'>
        <button onClick={() => props.close()} className='modalcloseBtn_' type="submit">Close</button>
        <button onClick={() => handleUpdateSettings()} className='modalsaveBtn_' type="submit" disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>Save</span>
        </button>
      </div>

    </div>
  )
}

export default BookingsettingModal