import React, { useState } from 'react'
import { firmImage, locate, starimg, timeimg, verifyImg, calendarsh } from '../../../../utilities/images'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const VideoscheduleModal = () => {
    const [value, onChange] = useState(new Date());

    return (
        <div className='videocallModal commonBody'>
            <form className='schedulesDetail schedule horizontalForms'>
            <p className='calenderpara_ mb-4'>It would be 15 minutes call with Yiwu Leqi E-Commerce Firm reprentative <b>Senia PanFeng</b></p>

                <div className='row'>
                    <div className='col-md-7'>
                        <div className='calanderBox_'>
                        <Calendar onChange={onChange} value={value} />
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='calanderrightBox_'>
                            <ul className='calanderTimelist text-end'>
                                <li className='listTimebox mb-2'>
                                    <span>9:00a-10:00a</span>
                                </li>
                                <li className='listTimebox mb-2'>
                                    <span>10:00a-11:00a</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>11:00a-12:00a</span>
                                </li>
                                <li className='listTimebox active mb-2'>
                                    <span>12:00a-1:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>1:00p-2:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>2:00p-3:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>3:00p-4:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>4:00p-5:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>5:00p-6:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>6:00p-7:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>7:00p-8:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>8:00p-9:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>9:00p-10:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>10:00p-11:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>11:00p-12:00p</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>12:00p-1:00a</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>1:00a-2:00a</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>2:00a-3:00a</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>3:00a-4:00a</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>4:00a-5:00a</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>5:00a-6:00a</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>6:00a-7:00a</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>7:00a-8:00a</span>
                                </li>
                                <li className='listTimebox  mb-2'>
                                    <span>8:00a-9:00a</span>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <button className='blacksubBtn_ mt-3'>Set Time</button>
            </form>
        </div>
    )
}

export default VideoscheduleModal