import React, { useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { selectLoginAuth, updateAccountApiAsync } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const UpdateBankDetails = ({ userConnectDetails }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    accountHolderName: "",
    routingNumber: "",
    currency: "",
    accountNumber: "",
  });

  useEffect(() => {
    setBankDetails({
      bankName: userConnectDetails?.[0]?.bank_name || "",
      accountHolderName: userConnectDetails?.[0]?.account_holder_name || "",
      routingNumber: userConnectDetails?.[0]?.routing_number || "",
      currency: userConnectDetails?.[0]?.currency || "",
      accountNumber: userConnectDetails?.[0]?.last4 || "",
    });
  }, []);

  // update bank details
  const handleUpdatePayoutDetails = () => {
    let params = {
      tokenData: auth?.payload?.token,
    };
    setIsLoading(true);
    dispatch(updateAccountApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setIsLoading(false);
        if (obj?.payload?.url) {
          window.open(obj.payload?.url, '_blank');
          // window.location.href = obj.payload?.url;
        }
      })
      .catch((obj) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="protectionRight">
      <div
        className={
          pathname === "/payout-details"
            ? "update-bank-outer"
            : "loginhorrizontal-form"
        }
      >
        <h4 className="subheading_">Update Bank Account</h4>
        <div
          className={pathname === "/connect-bank-account" && "no-bank-outer"}
        >
          <div className="mt-5">
            <form className="form_horizontal row">
              <div className="mb-4 form-group col-md-6">
                <label className="deatilsHead mb-3">Bank Name</label>
                <input
                  readOnly
                  type="text"
                  value={bankDetails?.bankName}
                  className="customform-control"
                  placeholder="Bank name"
                />
              </div>
              <div className="mb-4 form-group col-md-6">
                <label className="deatilsHead mb-3">Ac Holder Name</label>
                <input
                  readOnly
                  type="text"
                  value={bankDetails?.accountHolderName || "----"}
                  className="customform-control"
                  placeholder="Ac holder name"
                />
              </div>
              <div className="mb-4 form-group col-md-6">
                <label className="deatilsHead mb-3">Routing Number</label>
                <input
                  readOnly
                  className="customform-control id_password"
                  placeholder="Routing number"
                  type="text"
                  value={bankDetails?.routingNumber}
                />
              </div>
              <div className="mb-4 form-group col-md-6">
                <label className="deatilsHead mb-3">Currency</label>
                <input
                  readOnly
                  className="customform-control id_password"
                  placeholder="Ac number"
                  type="text"
                  value={bankDetails?.currency}
                />
              </div>
              <div className="mb-4 form-group col-md-12">
                <label className="deatilsHead mb-3">Ac Number</label>
                <div>
                  <input
                    readOnly
                    type="text"
                    className="customform-control"
                    placeholder="Ac number"
                    value={
                      bankDetails?.accountNumber
                        ? `*************${bankDetails?.accountNumber}`
                        : ""
                    }
                  />
                </div>
              </div>
            </form>
            <div
              className={
                pathname === "/payout-details"
                  ? "col-md-12 payout-btn-outer"
                  : "col-md-12"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  pathname === "/payout-details"
                    ? handleUpdatePayoutDetails()
                    : history.push("/chooseProtection");
                }}
              >
                <button
                  className={
                    pathname === "/payout-details"
                      ? "payout-btn-inner"
                      : "loginBtnSign w-100 buttonActive mt-2"
                  }
                  disabled={isLoading}
                >
                  {`${
                    pathname === "/payout-details"
                      ? "Go to Stripe"
                      : "Go Forward"
                  }
            `}
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm ms-1"></span>
                  )}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateBankDetails;
