import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const PlanSelected = () => {
    return (
        <>
            <div className='addBankAccount'>
                <div className='topHeader_'>
                    <div className="topStickyHeader_ mb-3">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <Link to="/plans"> <i className="las la-long-arrow-alt-left me-3"></i></Link>
                                    <span>Plan Details</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='backContainer'>
                    <h1>You have successfully purchased a plan</h1>
                </div>
            </div>
        </>
    )
}

export default PlanSelected
