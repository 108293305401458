import React, { useEffect, useState } from "react";
import DashboardNavbar from "../DashboardNavbar";
import {
  getconnectAccountApiAsync,
  selectLoginAuth,
  selectSignUpAuth,
  updateAccountApiAsync,
} from "../../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import AddBankDetails from "../../auth/signup/AddBankDetails";
import UpdateBankDetails from "../../auth/signup/UpdateBankDetails";

const PayoutDetails = () => {
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [userConnectDetails, setUserConnectDetails] = useState([]);

  // get bank details
  useEffect(() => {
    let params = {
      tokenData: auth?.payload?.token,
    };
    dispatch(getconnectAccountApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        setLoading(false);
        setUserConnectDetails(res?.payload?.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div className="full-loader">
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        </div>
      ) : (
        <div className="addBankAccount">
          <DashboardNavbar title="Payout Details" show={true} />
          {userConnectDetails?.length > 0 ? (
            <UpdateBankDetails userConnectDetails={userConnectDetails} />
          ) : (
            <AddBankDetails />
          )}
        </div>
      )}
    </>
  );
};

export default PayoutDetails;
