import React, { useEffect, useState } from 'react'
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { systemConfigAsync, updateUserSettingAsync } from '../systemConfigSlice';
import DashboardNavbar from '../../dashboard/DashboardNavbar';

const Notification = () => {
  const [coinData, setCoinData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [loading, setLoading] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch()

  const handleAppChange = (event, flag) => {
    updateApphandle(event.target.checked, flag);
  };


  const coinDatahandle = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
      app_name: "pos",
      }
    }
    setLoading(true)
    dispatch(systemConfigAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setCoinData(obj?.payload ? obj?.payload : [])
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  const updateApphandle = (checked, flag) => {
    let data = {}
    if (flag === "app") {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          app_name: "pos",
          notification_status: checked,
        }
      }
    } else if (flag === "sms") {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          app_name: "pos",
          chat_notification_status: checked,
        }
      }
    } else {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          app_name: "pos",
          email_notification_status: checked,
        }
      }
    }
    setLoading(true)
    dispatch(updateUserSettingAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setUpdateData(obj?.payload ? obj?.payload : [])
        let data = {
          tokenData: auth?.payload?.token,
          postData: {
            app_name: "pos",
          }
        }
        setLoading(true)
        dispatch(systemConfigAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            setLoading(false)
            setCoinData(obj?.payload ? obj?.payload : [])
          }
          )
          .catch((obj) => {
            setLoading(false)
          })
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }


  useEffect(() => {
    coinDatahandle();
  }, [])

  return (
    <>
      <div className='systemSetting'>
      <DashboardNavbar title="Notifications" show={true} />

        {/* <div className='topHeader_'>
          <div className="topStickyHeader_ mb-3">
            <div className="row  align-items-center">
              <div className="col-md-6">
                <h2 className="mainHeading">System Config / <span className='innerSubtext'>System Settings</span> </h2>
              </div>
            </div>
          </div>
        </div> */}
        <div className='notificationContent_'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='notificationLeft_'>
                <ul>
                  <li className='notificationList active'><span className='semiboldHeading__'><span className='dots'></span>Notification</span> <i className="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'><span className='dots'></span> Notification</span> <i className="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'><span className='dots'></span> Notification</span> <i className="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'><span className='dots'></span> Notification</span> <i className="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'><span className='dots'></span> Notification</span> <i className="fal fa-chevron-right" aria-hidden="true"></i></li>
                  <li className='notificationList'><span className='tableHeadsmall'><span className='dots'></span> Notification</span> <i className="fal fa-chevron-right" aria-hidden="true"></i></li>
                </ul>
              </div>
            </div>
            <div className='col-md-9'>
              <div className='notificationRight_ businessDetails'>
                <h5 className='deatilsHead'>Notification</h5>
                <div className='notificationInfo_'>
                  <h4 className='notifyHead'>App Notification</h4>
                  <ul className='notifictionContent'>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span>
                      <div className="form-check form-switch">
                        {coinData?.notification_status == true ?
                          <input type="checkbox" checked={true}
                            onChange={(e) => handleAppChange(e, "app")} />
                          :
                          <input type="checkbox" checked={false}
                            onChange={(e) => handleAppChange(e, "app")} />
                        }
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='notificationInfo_'>
                  <h4 className='notifyHead'>SMS Notification</h4>
                  <ul className='notifictionContent'>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span>
                      <div className="form-check form-switch">
                        {coinData?.chat_notification_status === true ?
                          <input type="checkbox" checked={true}
                            onChange={(e) => handleAppChange(e, "sms")} />
                          :
                          <input type="checkbox" checked={false}
                            onChange={(e) => handleAppChange(e, "sms")} />
                        }
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='notificationInfo_'>
                  <h4 className='notifyHead'>Email Notification</h4>
                  <ul className='notifictionContent'>
                    <li className='notificationList'>
                      <span className='personalinfoHead'>News and Updates</span>
                      <div className="form-check form-switch">
                        {coinData?.email_notification_status === true ?
                          <input type="checkbox" checked={true}
                            onChange={(e) => handleAppChange(e, "email")} />
                          :
                          <input type="checkbox" checked={false}
                            onChange={(e) => handleAppChange(e, "email")} />
                        }
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notification