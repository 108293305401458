import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getBrandOfCategoryAsync, getCategoriesAsync, getServicesAsync } from '../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import UploadproductModal from './ uploadproductModal'
import CustomModal from '../../components/shared/CustomModal';
import { mannualtap, plugins, uploadFile, database, } from '../../../utilities/images'
import file from '../../public/file/Product_Details_mini.xlsx'

const FindProductModal = (props) => {
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }
  const history = useHistory()
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [businessType, setBusinesType] = useState("")
  const [masterCategoryId, setMasterCategoryId] = useState("")
  const [masterCategoryName, setMasterCategoryName] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [subCategoryId, setSubCategoryId] = useState("")
  const [filteredList, setFilteredList] = useState([])
  const [masterCategoryData, setMasterCategoryData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [brand, setBrand] = useState([])
  const [brandId, setBrandId] = useState("")
  const [serviceList, setServiceList] = useState([])
  const handleMasterCategoryChange = (e) => {
    setMasterCategoryId(e.target.value)
    let name = masterCategoryData?.find(v => v?.id == e.target.value)?.name
    setMasterCategoryName(name)
    let filteredData = serviceList?.filter((val) => val?.type === e.target.value)
    setFilteredList(filteredData)
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        // main_category: true,
        service_id: e.target.value,
        check_product_existance: false
      }
    }
    setLoading(true)
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setCategoryData(obj?.payload?.data)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  const handleBusinessChange = (e) => {
    setBusinesType(e.target.value)
    setCategoryId(e.target.value)
    let filteredData = serviceList?.filter((val) => val?.type === e.target.value)
    setFilteredList(filteredData)
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        // main_category: true,
        category_id: e.target.value,
        check_product_existance: false
      }
    }
    setLoading(true)
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setSubCategory(obj?.payload?.data ? obj?.payload?.data : [])
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  const handleSubcategoryDataChange = (e) => {
    setSubCategoryId(e.target.value)
    let brandData = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.target.value,
        check_product_existance: false
      }
    }
    dispatch(getBrandOfCategoryAsync(brandData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setBrand(obj?.payload?.data)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  const handleBrandChange = (e) => {
    setBrandId(e.target.value)
  }

  const handleFindProducts = () => {
    // if (!categoryId) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please Select Category")
    //   }
    // }
    // else if (!subCategoryId) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please Select Sub-Category")
    //   }
    // }
    // else if (!brandId) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please Select Brand")
    //   }
    // }
    // else {
    // }
    history.push(`products`)
  }

  const getMasterCategoriesList = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: 'product',
        check_product_existance: false
      }
    }
    setLoading(true)
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setMasterCategoryData(obj?.payload?.data ? obj?.payload?.data : [])
      }
      )
      .catch((obj) => {
        setLoading(false)
      })

  }

  useEffect(() => {
    getMasterCategoriesList()
    localStorage.setItem("backButtonRoute", props?.flag)
  }, [])

  return (
    <>
      <div className='addProductModalSection'>
        <div className='row'>
          <div className='col-lg-6 col-md-6' >
            <div className='productsubSections'>
              <figure className='bigTickSection'>
                <img src={database} alt='databaseImage' className='img-fluid' />
              </figure>
              <h4 className='subheaderHeading'>JOBR Database </h4>
              <p className='storeText'>JOBR Already added 50,000+ Products with all resources you can use easily.</p>
              <button className='addProductBtn w-100' type='button' onClick={() => history.push(`/products`)}>Find Your Products</button>
            </div>
          </div>
          <div className='col-lg-6 col-md-6'>
            <div className='productsubSections productSecondSub'>
              <figure className='bigTickSection'>
                <img src={uploadFile} alt='uploadfileImage' className='img-fluid' />
              </figure>
              <h4 className='subheaderHeading'>Upload File here </h4>
              <p className='storeText'>Already have the file (file format: .xlsx or CVS ), please upload file for less work</p>
              <button className='addProductBtn w-100' type='button' onClick={() => history.push(`/productUploadFile`)}
                // setModalDetail({ show: , flag: "UploadproductModal" });
                // setKey(Math.random());true
              >Upload file</button>
              <div className='bigTickSection'><a href={file} download className="storeText mt-4" >Check <span className='linkText'>demo</span> file formation</a></div>
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-lg-6 col-md-6' style={{ cursor: "pointer" }} onClick={() => history.push(`/productPlugin`)}>
            <div className='productsubSections'>
              <figure className='bigTickSection'>
                <img src={plugins} alt='databaseImage' className='img-fluid' />
              </figure>
              <h4 className='subheaderHeading'>Plugins</h4>
              <p className='storeText'>JOBR build Plugins to import data from your existing shop</p>
            </div>
          </div>
          <div className='col-lg-6 col-md-6' style={{ cursor: "pointer" }} onClick={() => history.push(`/add-product/myProducts`)}>
            <div className='productsubSections'>
              <figure className='bigTickSection'>
                <img src={mannualtap} alt='mannualtap image' className='img-fluid' />
              </figure>
              <h4 className='subheaderHeading'>Add Manually </h4>
            </div>
          </div>
        </div>
      </div>


      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="false"
        showCloseBtn={true}
        isRightSideModal={false}
        mediumWidth={false}
        ids=
        {
          modalDetail.flag === "UploadproductModal" ? "uploadproductModal" :
            ""
        }
        size={modalDetail.flag === "UploadproductModal" ? "lg" : ""}
        child=
        {
          modalDetail.flag === 'UploadproductModal' ?
            <UploadproductModal previousModalClose={() => props.close()} close={() => handleOnCloseModal()} />

            : ""
        }

      />
    </>
  )
}

export default FindProductModal