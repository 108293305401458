import React from 'react'
import { buyerImg, tableImg } from '../../../../utilities/images'
const WalletrefundInvoice = () => {
  return (
    <>
      <div className='tokenpaymentInvoice'>
        <div className='topHeader_'>
          <div className="topStickyHeader_ mb-3">
            <div className="row  align-items-center">
              <div className="col-md-12">
                <div className='anotherHeader'>
                  <i className="las la-angle-left"></i>
                  <h2 className="innerSubheadNormal">Transaction# 25659165659165</h2>
                  <span className="pendingStatus">Pending</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="toporderCommon_">
          <div className="row">
            <div className="col-md-8">
              <div className='CustomerOnlinestatus afterBorder'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className="buyerDeatils">
                      <h4 className='buyHeading'>Buyer</h4>
                      <div className="userProfileinfo_">
                        <img className='me-3' src={buyerImg} alt="profileimg" />
                        <div className="userProfileinforight_">
                          <h3 className='mainlightHead'>Richman Brothers</h3>
                          <span className="buyerStatus">Menucaturer</span>
                          <p className='mainlightPhragraph'>
                            4156 College View Belleville, IL 62220 Phone: 890-941-8897
                            44780
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className="buyerDeatils">
                      <h4 className='buyHeading'>Marchent</h4>
                      <div className="userProfileinfo_">
                        <img className='me-3' src={buyerImg} alt="profileimg" />
                        <div className="userProfileinforight_">
                          <h3 className='mainlightHead'>Richman Brothers</h3>
                          <span className="buyerStatus">Wholesaler</span>
                          <p className='mainlightPhragraph'>
                            4156 College View Belleville, IL 62220 Phone: 890-941-8897
                            44780
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="CustomerOnlinestatus">
                <h5 className='buyHeading'>Invoice details</h5>
                <p className='invoiceHead'>
                  Invoice Id : <span>025659165659165</span>
                </p>
                <p className='invoiceHead'>
                  Created Date : <span>05-04-2022</span>
                </p>
                <p className='invoiceHead'>
                  Due Date : <span>05-04-2022</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="customerInvoicetable mt-4">
          <div className="table-responsive">
            <table className="table  listItemsContainer">
              <thead className="tHead_">
                <th className="text-start">#</th>
                <th className="text-start">Descriptions</th>
                <th className="text-start">No. of Items</th>
                <th className="text-start">Rate</th>
                <th>Amount</th>
              </thead>
              <tbody>
                <tr role="row">
                  <td className="text-start">1</td>
                  <td className="text-start productonlineDetail">
                    <img src={tableImg} alt="profileimg" className="tableProductimg" />
                    <div className="name_content">
                      <h6 className="contentheadtext_"> Ashton Classic</h6>
                      <span className="contentHeadtextlight_">Box of 25</span>
                    </div>
                  </td>
                  <td className="text-start">
                    <p className="contentheadtext_">16 Box</p>
                  </td>
                  <td className="text-start">
                    <p className="contentheadtext_">$250.00</p>
                  </td>
                  <td className="text-start">
                    <p className="itemsTxt">$4,063.20</p>
                  </td>
                </tr>
                <tr role="row">
                  <td className="text-start">1</td>
                  <td className="text-start productonlineDetail">
                    <img src={tableImg} alt="profileimg" className="tableProductimg" />
                    <div className="name_content">
                      <h6 className="contentheadtext_"> Ashton Classic</h6>
                      <span className="contentHeadtextlight_">Box of 25</span>
                    </div>
                  </td>
                  <td className="text-start">
                    <p className="contentheadtext_">16 Box</p>
                  </td>
                  <td className="text-start">
                    <p className="contentheadtext_">$250.00</p>
                  </td>
                  <td className="text-start">
                    <p className="itemsTxt">$4,063.20</p>
                  </td>
                </tr>
                <tr role="row">
                  <td className="text-start">1</td>
                  <td className="text-start productonlineDetail">
                    <img src={tableImg} alt="profileimg" className="tableProductimg" />
                    <div className="name_content">
                      <h6 className="contentheadtext_"> Ashton Classic</h6>
                      <span className="contentHeadtextlight_">Box of 25</span>
                    </div>
                  </td>
                  <td className="text-start">
                    <p className="contentheadtext_">16 Box</p>
                  </td>
                  <td className="text-start">
                    <p className="contentheadtext_">$250.00</p>
                  </td>
                  <td className="text-start">
                    <p className="itemsTxt">$4,063.20</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="totalArea ">
            <div className='row'>
              <div className="col-md-6 text-start">
                <div className="notes">
                  <textarea
                    className=""
                    placeholder="Refund Reason:"
                    id="floatingTextarea"
                    rows="9"
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6 text-start">
                <div className="Customertotal">
                  <div className="totalDetails">
                    <h5 className="personalinfoHead">Sub Total</h5>
                    <h5 className="itemsText">$7,363.20</h5>
                  </div>
                  <div className="totalDetails">
                    <h5 className="personalinfoHead">Service Charge (5%)</h5>
                    <h5 className="itemsText">$83.20</h5>
                  </div>
                  <div className="totalDetails">
                    <h5 className="personalinfoHead">Discount ( MIDApril100)</h5>
                    <h5 className="itemsText Orangetext">$100.00</h5>
                  </div>
                  <div className="totalDetails">
                    <h5 className="personalinfoHead">Shipping Charrg</h5>
                    <h5 className="itemsText">$12.00</h5>
                  </div>
                  <div className="totalDetails">
                    <h5 className="itemsText ">Total</h5>
                    <h5 className="itemsText">$7001.20</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WalletrefundInvoice