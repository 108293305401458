import React, { useEffect, useState } from 'react'
import { getFaqCategoryDataApiAsync } from '../../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';


const ViewFaqModal = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [categories, setCategories] = useState("");

    const faqCatagoryData = () => {
        let params = {
            "tokenData": auth?.payload?.token
        }
        dispatch(getFaqCategoryDataApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                const allCategory = obj?.payload?.data;
                const selectedCategory = allCategory.find(item => item.id == props?.selectedData?.faq_category_id)
                setCategories(selectedCategory?.category_name)
            })
            .catch((obj) => {
            })
    }

    useEffect(() => {
        faqCatagoryData()
    }, [])

    return (
        <div className='addnewfaqModal'>
            <form className='emplyoeeDetails horizontalForms'>
                <div className='row'>
                    <div className='form-group mb-4'>
                        <label className='labelTxt mb-3' htmlFor='amount'>FAQ's Title</label>
                        <p>{props?.selectedData?.title}</p>
                    </div>
                    <div className='form-group col-md-12  mb-4'>
                        <label className='labelTxt mb-3' htmlFor='amount'>FAQ's Discription: </label>
                        <p>{props?.selectedData?.discription}</p>
                        <div dangerouslySetInnerHTML={{ __html: props?.selectedData?.answer }} />
                    </div>
                    <div className='form-group mb-4'>
                        <label className='labelTxt mb-3' htmlFor='amount'>Category Name:</label>
                        <p>{categories}</p>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ViewFaqModal