import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  category,
  category2,
  category3,
  category4,
  defaultUser,
} from "../../../utilities/images";
import ChartArea from "../dashboard/wallet/ChartArea";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import CustomLoader from "../../components/shared/CustomLoader";
import {
  getCountApiAsync,
  getProductSalesGraphApiAsync,
  getTopSellingApiAsync,
  getProductTrendingGraphAsync,
} from "./productSlice";
import CustomModal from "../../components/shared/CustomModal";
import FindProductModal from "../scalePlan/findProductModal";
import { getInventoryDetailsApiAsync } from "../dashboard/dashboardSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { addThousandSeparator } from "../../../utilities/thousandCommaSeparated";

const MyProduct = () => {
  const history = useHistory();
  const pathname = window.location.pathname;
  const [loading, setLoading] = useState(false);
  const [salesGraphData, setSalesGraphData] = useState("");
  const [countData, setCountData] = useState({});
  const [topSellingProduct, setTopSellingProduct] = useState([]);
  const [pieChartData, setPieChartData] = useState("");
  console.log(pieChartData, "piee pieeeee");
  const [topSellingCategory, setTopSellingCategory] = useState([]);
  const [filterValue, setFilterValue] = useState("This week");
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleFilterValue = (value) => {
    setFilterValue(value);
    let params = {
      filterValue: value === "This week" ? "week" : value?.toLowerCase(),
    };
    getProductSalesGraphData(params);
    countDataHandle(params);
  };
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  let otherOption = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const lineData = {
    labels: salesGraphData?.labels,
    datasets: [
      {
        label: "sales",
        data: salesGraphData ? salesGraphData?.datasets[0]?.data : [],
        borderColor: "#1FB3FF",
        backgroundColor: "#1FB3FF",
      },
    ],
  };

  const doughnutDataProduct = {
    labels: ["Total Products", "Low Stock Items", "Stock Out"],
    datasets: [
      {
        label: "",
        data: [
          pieChartData?.total_products,
          pieChartData?.low_stock,
          pieChartData?.stock_out,
        ],
        backgroundColor: ["#3C444D", "#FB466C", "#D33300"],
        borderColor: ["#3C444D", "#FB466C", "#D33300"],
        borderWidth: 1,
      },
    ],
  };

  const getPieChartData = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        filter: "need_products",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getInventoryDetailsApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setPieChartData(obj?.payload[0]);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getProductSalesGraphData = (data) => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // "filter": data?.filterValue ? data?.filterValue : "week",
        type: "product",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getProductSalesGraphApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setSalesGraphData(obj?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getTopSellingProduct = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "product",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getTopSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setTopSellingProduct(
          obj?.payload?.filter((item) => item?.product_details?.name)
        );
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getTopSellingCategory = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        service_type: "product",
        type: "category",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getTopSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setTopSellingCategory(
          obj?.payload?.filter((item) => item?.product_details?.name)
        );
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const countDataHandle = (data) => {
    let countData = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // 'filter': data?.filterValue ? data?.filterValue : "week",
        service_type: "product",
      },
    };
    setLoading(true);
    dispatch(getCountApiAsync(countData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setCountData(obj?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPieChartData();
    getProductSalesGraphData(null);
    getTopSellingProduct();
    getTopSellingCategory();
    countDataHandle(null);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Products";
  }, []);

  return (
    <>
      <DashboardNavbar title="Products Overview" backShow={false} />
      <div className="myProductmain">
        {loading ? (
          <>
            <span className="spinner-border inner-spin spinner-border-sm"></span>
          </>
        ) : (
          <>
            <div className="grossProfitData productProfitData">
              <div className="row">
                {countData?.result?.length > 0
                  ? countData?.result?.map((val, i) => {
                      return (
                        <div
                          key={i}
                          className="col-lg-3 col-md-6  pe-2"
                          onClick={() => history.push("/productList")}
                        >
                          <div className="grossTotalorder">
                            <img
                              src={
                                val?.title === "Category"
                                  ? category
                                  : val?.title === "Sub Category"
                                  ? category2
                                  : val?.title === "Brand"
                                  ? category3
                                  : val?.title === "Product"
                                  ? category4
                                  : ""
                              }
                              className=""
                              alt="ShareMap"
                            />
                            <p className="groMain ms-2">{val?.count}</p>
                            <h4 className="groSub mt-4 mb-0">{val?.title}</h4>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="chartsInfo">
              <div className="row">
                <div className="col-md-4 ">
                  <div className="chartInfoSub ">
                    <h6 className="chartActive">Active Items</h6>
                    <div className="row justify-content-center">
                      <ChartArea
                        classes="col-md-12 col-xl-10 col-xxl-10 col-lg-10 chartProduct"
                        push={() => history.push("/Total-Inventory")}
                        header="Product Sales Trends"
                        options={otherOption}
                        data={doughnutDataProduct}
                        chartType="Doughnut"
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-12 outerBorder">
                        <div className="productDetailInfo">
                          <div className="dFlex">
                            <h6 className="totalcharts_">Total Products: </h6>
                            <h6 className="chartActive">
                              {pieChartData?.total_products}
                            </h6>
                          </div>
                          <div className="dFlex stockItem_">
                            <h6 className="totalcharts_">Low Stock Items:</h6>
                            <h6 className="chartActive">
                              {" "}
                              {pieChartData?.low_stock}
                            </h6>
                          </div>
                          <div className="dFlex stockItemOut">
                            <h6 className="totalcharts_">Stock Out:</h6>
                            <h6 className="chartActive">
                              {" "}
                              {pieChartData?.stock_out}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="chartInfoSub ">
                    <h6 className="chartActive text-start">Product Sales </h6>
                    <ChartArea
                      classes="productSales"
                      push={() => history.push("/Total-Product-Sold")}
                      header="Product Sales"
                      options={options1}
                      data={lineData}
                      chartType="Line"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="topProducts_">
              <div className="row">
                <div className="col-xl-6 col-lg-12">
                  <div className="chartsOuter">
                    <h5 className="dollarSign mb-3">Top Items</h5>
                    <div className="topItemsection dFlex">
                      <div className="itemSale_">
                        <h4 className="itemsText">Items</h4>
                      </div>
                      <div className="unitSale">
                        <h4 className="itemsText ">Unit Sold</h4>
                        <h4 className="itemsText ">Gross Sale</h4>
                      </div>
                    </div>
                    <div className="countriesDetails mt-3">
                      {topSellingProduct?.length > 0 ? (
                        <>
                          {topSellingProduct?.map((val, index) => {
                            return (
                              <div className="countryInnerderail topItemsection dFlex">
                                <div className="itemSale_">
                                  <span className="serialNo">{index + 1}</span>
                                  <div className="countryCount dFlex">
                                    <img
                                      className="brandImg"
                                      style={{ width: "50px", height: "40px" }}
                                      src={
                                        val?.product_details?.image
                                          ? val?.product_details?.image
                                          : defaultUser
                                      }
                                    />
                                    <div className="countryContent ms-2">
                                      <h4 className="mainlightHead">
                                        {val?.product_details?.name?.length > 15
                                          ? val?.product_details?.name?.slice(
                                              0,
                                              15
                                            ) + "..."
                                          : val?.product_details?.name}
                                      </h4>
                                      {/* <h5 className='tableHeadsmall'>Belicoso 6 × 54</h5> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="unitSale">
                                  <h5 className="itemsText ms-3">
                                    {val?.qty_sum}
                                  </h5>
                                  <h5 className="itemsText">
                                    $
                                    {addThousandSeparator(
                                      (val?.price_sum).toFixed(2)
                                    )}
                                  </h5>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        "No Products Found"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12">
                  <div className="chartsOuter">
                    <h5 className="dollarSign mb-3">Top Categories</h5>
                    <div className="topItemsection dFlex">
                      <div className="itemSale_">
                        <h4 className="itemsText">Category</h4>
                      </div>
                      <div className="unitSale">
                        <h4 className="itemsText">Items Sold</h4>
                        <h4 className="itemsText ">Gross Sale</h4>
                      </div>
                    </div>
                    <div className="countriesDetails mt-3">
                      {topSellingCategory?.length > 0 ? (
                        <>
                          {topSellingCategory?.map((val, index) => {
                            return (
                              <div className="countryInnerderail topItemsection dFlex">
                                <div className="itemSale_">
                                  <span className="serialNo">{index + 1}</span>
                                  <div className="countryCount  dFlex">
                                    <img
                                      className="brandImg"
                                      style={{ width: "50px", height: "40px" }}
                                      src={
                                        val?.product_details?.image
                                          ? val?.product_details?.image
                                          : defaultUser
                                      }
                                    />
                                    <div className="countryContent ms-2">
                                      <h4 className="mainlightHead">{`${
                                        val?.product_details?.name?.length > 15
                                          ? val?.product_details?.name?.slice(
                                              0,
                                              15
                                            ) + "..."
                                          : val?.product_details?.name
                                      }`}</h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="unitSale">
                                  <h5 className="itemsText ms-3">
                                    {val?.qty_sum} box
                                  </h5>
                                  <h5 className="itemsText">
                                    $
                                    {addThousandSeparator(
                                      (val?.price_sum).toFixed(2)
                                    )}
                                  </h5>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        "No Categories Found"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomModal
              key={key}
              show={modalDetail.show}
              backdrop={true}
              showCloseBtn={false}
              isRightSideModal={false}
              mediumWidth={false}
              ids={modalDetail.flag === "FindProduct" ? "findProduct" : ""}
              size={modalDetail.flag === "FindProduct" ? "lg" : "md"}
              child={
                modalDetail.flag === "FindProduct" ? (
                  <FindProductModal
                    flag={pathname}
                    close={() => handleOnCloseModal()}
                  />
                ) : (
                  ""
                )
              }
              onCloseModal={() => handleOnCloseModal()}
            />
          </>
        )}
      </div>
    </>
  );
};

export default MyProduct;
