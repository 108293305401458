import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, shield, user } from '../../../../utilities/images';
import NewChats from './newChats';
import PendingChats from './pendingChats';
import PendingchatAssign from './pendingchatAssign';
import ResolveChats from './resolveChats';

const SupportChat = () => {
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [activeTab, setActiveTab] = useState('newChats');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    }
    return (
        <div className='suportTicket'>
            <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row  align-items-center">
                        <div className="col-md-12">
                            <h2 className="mainHeading">Support /<span className='innerSubtext'> Chats</span></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className={`${activeTab == 'newChats' ? 'valuesTimeswallet active' : ''} valuesTimeswallet`} onClick={() => { setActiveTab('newChats') }}>New Chats (05)</button>
                        <button className={`${activeTab == 'pendingChats' ? 'valuesTimeswallet active' : ''} valuesTimeswallet`} onClick={() => { setActiveTab('pendingChats') }}>Pending Chats (13)</button>
                        <button className={`${activeTab == 'pendingchatAssign' ? 'valuesTimeswallet active' : ''} valuesTimeswallet`} onClick={() => { setActiveTab('pendingchatAssign') }}>Assigned Chats (13)</button>
                        <button className={`${activeTab == 'resolveChats' ? 'valuesTimeswallet active' : ''} valuesTimeswallet`} onClick={() => { setActiveTab('resolveChats') }}>Resolved Chats</button>
                    </div>
                </div>
            </div>
            <div className='commonTables mt-3 mb-3'>
                <div className='tableFilters'>
                    <div className="calenderIcon">
                        <img src={calendar} alt="calender" />
                        {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                            dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                        <DatePicker
                                className='cateDate'
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText="Date"
                            />
                    </div>
                    {/* <select className="cateSelectdFilter" required>
                        <option value="" selected>
                            Country
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                            State
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                            Id type
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select> */}
                </div>
            </div>
            <div className='logTabsDetails'>
                {activeTab == 'newChats'
                    ?
                    <NewChats />
                    :
                    activeTab == 'pendingChats'
                        ?
                        <PendingChats />
                        :
                        activeTab == 'pendingchatAssign'
                            ?
                            <PendingchatAssign />

                            : 
                            activeTab == 'resolveChats'
                            ?
                            <ResolveChats />

                            :""
                }
            </div>
         

        </div>
    )
}

export default SupportChat