import React from 'react'
import { lossArrow, shareMap } from '../../../../utilities/images'



const HeaderStatistics = (props) => {
    return (
        <div className='col-sm-6 col-md-3 col-lg-3' onClick={props?.push}>
            <div className='percentageContainer'>
                <div className='percentageValues'>
                    <h3 className='heading_ '>{props?.value}</h3>
                    <p className='textInner'>{props?.heading}</p>
                </div>
                <div className={`percentageRating ${props?.positive?'' : 'percentageBg'}`}>
                    <img src={props?.positive? shareMap: lossArrow} className="sharMapImg" alt='ShareMap' />
                    <p className={props?.positive?'percentageRate':'minusPercentageRate'}>{Number(props?.percentage)?.toFixed(2)}%</p>
                </div>
            </div>
        </div>
    )
}

export default HeaderStatistics