import React, { useEffect, useState } from 'react'
import { userCercle } from '../../../utilities/images'
import { geoCentroid } from "d3-geo";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";
import allStates from "../campaign/data/allstates.json";
import axios from 'axios';
import { gapi } from 'gapi-script';
import ChartArea from '../dashboard/wallet/ChartArea';
import moment from 'moment-timezone';
import DashboardNavbar from '../dashboard/DashboardNavbar';

const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
};

const Analyticsss = () => {
    const [data, setData] = useState([]);
    const [accessToken, setAccessToken] = useState('');
    const [activeData, setActiveData] = useState("newUsers")
    const [deviceCategory, setDeviceCategory] = useState([])
    const [tooltipContent, setTooltipContent] = useState('');
    const [usersGraphData, setUsersGraphData] = useState([])

    const handleMouseEnter = (event, geography, name1) => {
        let name = name1 == "United States of America" ? "United States" : name1
        let dataResult = data?.filter(val => ((val?.dimensionValues[0]?.value == name)))
        let result = dataResult?.length > 0 ? dataResult[0]?.metricValues[0]?.value : "0"
        setTooltipContent(`${name} : ${result}`);
    };
    const handleMouseLeave = () => {
        setTooltipContent('');
    };


    const options1 = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    }

    const dimensionValues = usersGraphData.map(val => val?.dimensionValues[0]?.value);
    const dates = dimensionValues.map((dimensionValues) => moment(dimensionValues, "YYYY MM DD").toDate());
    const formattedDates = dates.map((date) => moment(date).format("DD MMM, YYYY"));

    const serviceSalesDataNewUser = {
        labels: formattedDates,
        datasets: [
            {
                fill: true,
                label: 'New User',
                data: usersGraphData.map(val => val?.metricValues[0]?.value),
                borderColor: '#275AFF',
                backgroundColor: '#1675e063',
            },
        ],
    };
    const serviceSalesDataActiveUser = {
        labels: formattedDates,
        datasets: [
            {
                fill: true,
                label: 'Active User',
                data: usersGraphData.map(val => val?.metricValues[1]?.value),
                borderColor: '#275AFF',
                backgroundColor: '#1675e063',
            },
        ],
    };
    const serviceSalesDataEvent = {
        labels: formattedDates,
        datasets: [
            {
                fill: true,
                label: 'Event Count',
                data: usersGraphData.map(val => val?.metricValues[2]?.value),
                borderColor: '#275AFF',
                backgroundColor: '#1675e063',
            },
        ],
    };
    const doughnutData = {
        labels: ['Desktop', 'Mobile'],
        datasets: [
            {
                label: 'User',
                data: [deviceCategory[0]?.metricValues[0]?.value, deviceCategory[1]?.metricValues[0]?.value],
                backgroundColor: [
                    '#275AFF',
                    '#FB466C'
                ],
                borderColor: [
                    '#275AFF',
                    '#FB466C'
                ],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js';
        script.onload = initializeGoogleAuth;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const initializeGoogleAuth = () => {
        gapi.load('auth2', () => {
            gapi.auth2.init({
                client_id: "318453714270-jjrpft9tqi7q7bu4h9e6u4r8uo8gvlf2.apps.googleusercontent.com",
                scope: "https://www.googleapis.com/auth/analytics.readonly"
            }).then(() => {
                const auth2 = gapi.auth2.getAuthInstance();
                auth2.signIn().then(responseGoogle);
            });
        });
    };

    const responseGoogle = (response) => {
        if (response.xc.access_token) {
            setAccessToken(response.xc.access_token);
        }
    };

    const getAnalyticsData = async () => {
        try {
            const response = await axios.post(
                `https://analyticsdata.googleapis.com/v1beta/properties/402339820:runReport`,
                {
                    "property": "properties/402339820",
                    "dateRanges": [
                        {
                            "startDate": "30daysAgo",
                            "endDate": "yesterday"
                        }
                    ],
                    "metrics": [
                        { "name": "activeUsers" },
                    ],
                    "dimensions": [{ "name": "country" }]
                },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                }
            );
            setData(response.data.rows);
        } catch (error) {
            // console.error('Error fetching Google Analytics data:', error);
        }
    }

    const getDeviceCategory = async () => {
        try {
            const response = await axios.post(
                `https://analyticsdata.googleapis.com/v1beta/properties/402339820:runReport`,
                {
                    "property": "properties/402339820",
                    // "dateRanges": [
                    //     {
                    //         "startDate": "2023-07-01",
                    //         "endDate": "2023-08-20"
                    //     }
                    // ],
                    "dateRanges": [
                        { "startDate": "30daysAgo", "endDate": "yesterday" }
                    ],
                    "metrics": [
                        { "name": "activeUsers" },
                    ],
                    "dimensions": [{
                        "name": "deviceCategory"
                    }]
                },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                }
            );
            setDeviceCategory(response.data.rows);
        } catch (error) {
            // console.error('Error fetching Google Analytics data:', error);
        }
    }

    const getUsersGraphData = async () => {
        try {
            const response = await axios.post(
                `https://analyticsdata.googleapis.com/v1beta/properties/402339820:runReport`,
                {
                    "property": "properties/402339820",
                    "dateRanges": [
                        {
                            "startDate": "30daysAgo",
                            "endDate": "today"
                        }
                    ],
                    "metrics": [
                        { "name": "newUsers" },
                        {
                            "name": "activeUsers"
                        },
                        {
                            "name": "eventCount"
                        }
                    ],
                    "dimensions": [{ "name": "date" }]
                },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                }
            );
            setUsersGraphData([...response.data.rows].sort((a, b) => a.dimensionValues[0].value - b.dimensionValues[0].value));
        } catch (error) {
            // console.error('Error fetching Google Analytics data:', error);
        }
    }

    useEffect(() => {
        if (accessToken) {
            getAnalyticsData();
            getDeviceCategory();
            getUsersGraphData();
        }
    }, [accessToken])

    return (
        <>
        <DashboardNavbar title="Analytics" backShow={false}/>
        <div className='campaignMain'>
            <div className='chartsOuter mt-4 mb-4'>
                <div className='analyticsTabs_'>
                    <span className={activeData == "newUsers" ? "userTabs_ active" : "userTabs_"} style={{ cursor: "pointer" }} onClick={() => setActiveData("newUsers")}>New User</span>{" "}
                    <span className={activeData == "activeUsers" ? "userTabs_ active" : "userTabs_"} style={{ cursor: "pointer" }} onClick={() => setActiveData("activeUsers")}>Active User</span>{" "}
                    <span className={activeData == "eventCount" ? "userTabs_ active" : "userTabs_"} style={{ cursor: "pointer" }} onClick={() => setActiveData("eventCount")} >Event Count</span>
                </div>
                {
                    activeData == "newUsers" ?
                        <div className='conversionLocate'>
                            <ChartArea classes="productSales mt-2 mb-2" header="Services Sales" options={options1} data={serviceSalesDataNewUser} chartType="Line" />
                        </div>
                        : activeData == "activeUsers" ?
                            <div className='conversionLocate'>
                                <ChartArea classes="productSales mt-2 mb-2" header="Services Sales" options={options1} data={serviceSalesDataActiveUser} chartType="Line" />
                            </div>
                            : <div className='conversionLocate'>
                                <ChartArea classes="productSales mt-2 mb-2" header="Services Sales" options={options1} data={serviceSalesDataEvent} chartType="Line" />
                            </div>
                }
            </div>

            <div className='conversionLocate'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='chartsOuter'>
                            <h3 className='textInnerHead mb-3'>Users by Country</h3>
                            <div className='row'>
                                <div className='col-md-5 col-lg-5'>
                                    <div className='campStates'>
                                        <h3 className='subheadBlue'>Your Top Countries</h3>
                                        {
                                            data?.map((val, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className='countryInnerderail mt-3' key={index}>
                                                            <div className='countryContent'>
                                                                <h4 className='contentheadtext_'>{val?.dimensionValues[0]?.value}</h4>
                                                            </div>
                                                            <div className="countryCount">
                                                                <h5 className='itemsTxt'> <img className='userCercle' src={userCercle} />{val?.metricValues[0]?.value}</h5>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-md-7 col-lg-7 text-center'>
                                    <div className='staesMap_' style={{ position: 'relative' }}>
                                        {tooltipContent && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    left: "55%",
                                                    top: "0",
                                                    transform: "translate(-50%, -50%)",
                                                    background: "white",
                                                    padding: "8px",
                                                    borderRadius: "4px",
                                                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                                                }}
                                            >
                                                {tooltipContent}
                                            </div>
                                        )}
                                        {/* <img src={statesMap} alt="satesMap" /> */}
                                        <ComposableMap >
                                            <Geographies geography={geoUrl} >
                                                {({ geographies }) => (
                                                    <>
                                                        {
                                                            geographies.map((geo, index) => {
                                                                const isSelected = data?.filter(val => ((val?.dimensionValues[0]?.value == geo.properties.name) || ((val?.dimensionValues[0]?.value == "United States" ? "United States of America" : "") == geo.properties.name)))
                                                                return (
                                                                    <>
                                                                        <Geography
                                                                            key={geo.rsmKey}
                                                                            stroke="#FFF"
                                                                            geography={geo}
                                                                            // fill={isSelected?.length > 0 ? 'red' : 'orange'}
                                                                            onMouseEnter={(event) =>
                                                                                handleMouseEnter(event, geo, geo.properties.name)
                                                                            }
                                                                            onMouseLeave={handleMouseLeave}
                                                                            style={{
                                                                                default: {
                                                                                    fill: `${isSelected?.length > 0 ? '#275AFF' : '#D6D6DA'}`,
                                                                                    stroke: '#FFFFFF',
                                                                                    strokeWidth: 0.5,
                                                                                    outline: 'none',
                                                                                },
                                                                                hover: {
                                                                                    fill: '#F53',
                                                                                    stroke: '#FFFFFF',
                                                                                    strokeWidth: 0.5,
                                                                                    outline: 'none',
                                                                                },
                                                                            }}
                                                                            data-tip
                                                                            data-for="registerTip"
                                                                        />
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                        {geographies.map(geo => {
                                                            const centroid = geoCentroid(geo);
                                                            const cur = allStates.find(s => s.val === geo.id);
                                                            return (
                                                                <g key={geo.rsmKey + "-name"} >
                                                                    {cur &&
                                                                        centroid[0] > -160 &&
                                                                        centroid[0] < -67 &&
                                                                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                                                            <Marker coordinates={centroid} >
                                                                                <text y="2" fontSize={14} textAnchor="middle">
                                                                                    {cur.id}
                                                                                </text>
                                                                            </Marker>
                                                                        ) : (
                                                                            <Annotation
                                                                                subject={centroid}
                                                                                dx={offsets[cur.id][0]}
                                                                                dy={offsets[cur.id][1]}
                                                                            >
                                                                                <text x={4} fontSize={14} alignmentBaseline="middle">
                                                                                    {cur.id}
                                                                                </text>
                                                                            </Annotation>
                                                                        ))}
                                                                </g>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </Geographies>
                                        </ComposableMap>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='chartsOuter conversionGraph_'>
                            <h3 className='textInnerHead mb-3'>Conversions By Platform</h3>
                            <div className='col-lg-8'>
                                <ChartArea classes=" chartProduct" options={options1} data={doughnutData} chartType="Doughnut" />
                            </div>
                            <div className='graphValue_'>
                                <div>
                                    <span>Desktop: </span><span>{deviceCategory[0]?.metricValues[0]?.value}</span>
                                </div>
                                <div>
                                    <span>Mobile: </span><span>{deviceCategory[1]?.metricValues[0]?.value}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
        </>
    )
}

export default Analyticsss