import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import { createFaqCategoryApiAsync, getFaqCategoryDataApiAsync, getFaqCategoryTagsApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';

const AddnewcategoryModal = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState("");
  const [name, setName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [optionData, setOptionData] = useState([]);

  const handleSelect = (selectedList) => {
    let filteredData = selectedList?.map(v => v?.value)
    setSelectedCategory(filteredData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Please Enter Category Name");
      return;
    }
    if (!categoryDetails) {
      toast.error("Please write category details");
      return;
    }
    if (categoryDetails?.length > 160) {
      toast.error("Category Details maximum limit is 160 only");
      return;
    }
    if (selectedCategory?.length <= 2) {
      toast.error("Please select at least 3 category tags");
      return;
    }
    let data = {
      "postData": {
        "category_name": name,
        "category_detail": categoryDetails,
        "category_tags": selectedCategory
      },
      "tokenData": auth?.payload?.token
    }
    setLoadingButton(true)
    dispatch(createFaqCategoryApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoadingButton(false)
        props?.faqCategoryRef?.current?.getProductParent()
        props?.close()
      })
      .catch((obj) => {
        setLoadingButton(false)
      })
  }

  const categoryTagsData = () => {
    setLoading(true)
    dispatch(getFaqCategoryTagsApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        let modifiedData = obj?.payload?.map((item, i) => ({
          id: i + 1,
          value: item
        }));
        setOptionData(modifiedData)
        setLoading(false)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    categoryTagsData()
  }, [])

  return (
    <div className='addnewfaqModal'>
      <form className='emplyoeeDetails horizontalForms' onSubmit={(e) => { handleSubmit(e) }}>
        <div className='row'>
          <div className='form-group mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>Category Name</label>
            <input type="text" value={name} className='customform-control' placeholder='Category Name here' onChange={(e) => setName(e.target.value)} />
          </div>
          <div className='form-group col-md-12  mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>Category details </label>
            <textarea type='text' className='customform-control' placeholder='Category Details here'
              value={categoryDetails} onChange={e => setCategoryDetails(e.target.value)} />
            <p className='tableHeadsmall text-end'>160 letters maximum</p>
          </div>
          <div className='form-group col-md-12  mb-4'>
            <label className='labelTxt mb-3' htmlFor='amount'>Category tags</label>
            {/* <input type='text' className='customform-control' placeholder='Tags' /> */}
            <Multiselect
              className='brandFilter customform-control'
              displayValue="value"
              options={optionData}
              hidePlaceholder={true}
              onSelect={handleSelect}
              onRemove={handleSelect}
              showCheckbox
            />
            <p className='tableHeadsmall text-end'>At least 3 tags</p>
          </div>
          
        </div>

      <div className='footerSec addCateClick mt-4'>
            {/* <button className='closebtnModal me-3' onClick={props.close}>Cancel</button>
            <button className='publishBtn_ lh-sm' type='submit' disabled={loadingButton}>
              {loadingButton && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Save</span>
            </button> */}
            <button className='discardBtn me-3' onClick={props.close}>Cancel</button>
            <button className='saveBtn' type='submit'>Save</button>
          </div>
          </form>
    </div>
  )
}

export default AddnewcategoryModal