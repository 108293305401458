import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  pickuporder, ticketImg, locationBlue, products, prorightarrow, videouserImg, profileImg1, unselectedOrder
} from "../../../utilities/images";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { getSingleOrderTrackingApiAsync } from "../dashboard/dashboardSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
import { getAddressFromCoordinates } from "./AddressMethod";

function TrakingMap(props) {
  const dispatch = useDispatch()
  const [latitude, setLatitude] = useState()
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [langitude, setLangitude] = useState()
  const auth = useSelector(selectLoginAuth)
  const { id } = useParams();
  const history = useHistory()
  const [driverLoc, setDriverLoc] = useState("")
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [driverDistance, setDriverDistance] = useState("")
  const [driverDuration, setDriverDuration] = useState("")
  const [orderStatus, setorderStatus] = useState(true);
  const [orderTrackStatus, setorderTrackStatus] = useState('');
  const [singleOrderData, setSingleOrderData] = useState("")
  const locationData = singleOrderData;

  const center = { lat: latitude, lng: langitude }
  const geocodeHandler = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      getAddressFromCoordinates(position.coords.latitude, position.coords.longitude)
        .then(data => {
          setDriverLoc(data?.formatted_address);
        })
        .catch(err => console.log('error getting address', err));
    });
  }
  async function calculateDriverRoute() {
    if (driverLoc === '' || locationData?.address_2 === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: driverLoc,
      destination: locationData?.address_2,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
    setDriverDistance(results.routes[0].legs[0].distance.text)
    setDriverDuration(results.routes[0].legs[0].duration.text)
  }
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setorderStatus((current) => !current);
  };


  const singleTrackOrder = () => {
    let params = {
      postData: {
        orderId: id,
      },
      tokenData: auth?.payload?.token
    }
    dispatch(getSingleOrderTrackingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setSingleOrderData(obj?.payload)
        setorderTrackStatus(obj?.payload?.status)
        setLangitude(obj?.payload?.order_pickup_longitude)
        setLatitude(obj?.payload?.order_pickup_latitude)
      })
      .catch((obj) => {

      })
  }

  useEffect(() => {
    singleTrackOrder()
    geocodeHandler()
    calculateDriverRoute()
  }, [])

  return (
    <>
       <DashboardNavbar title="# EV938507560CN" backShow={false} /> 
      <div className="trackingMapp_">
        {/* <div className='topHeader_'>
          <div className="topStickyHeader_ mb-3">
            <div className="row  align-items-center">
              <div className="col-md-12">
                <div className='anotherHeader'>
                  <i className="las la-angle-left" onClick={() => history.goBack()} ></i>
                  <h2 className="innerSubheadNormal">Track#{id}</h2>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="toporderCommon">
          <div className="detailsTracking_ trackingModule">
            <div className="row">
              <div className="col-md-6">
                <div className="detailTrackingleft_">
                  <div className="userProfileinfo_ trackDetailsprofile_">
                    <img className="me-4" src={singleOrderData?.userDetail?.user_profiles?.profile_photo ? singleOrderData?.userDetail?.user_profiles?.profile_photo : profileImg1} alt="profileimg " />
                    <div className="userProfileinforight_">
                      <h3>{`${singleOrderData?.userDetail?.user_profiles?.firstname} ${singleOrderData?.userDetail?.user_profiles?.lastname}`}</h3>
                      <p>
                        <img className="trackitemimgg_" src={locationBlue} alt="" />
                        {singleOrderData?.order_delivery_address}
                      </p>
                      <div className="profilebottom_">
                        <div className="profilesubContent">
                          <img className="trackitemimgg_1" src={ticketImg} alt="" />
                          <span>${singleOrderData?.order?.payable_amount}</span>
                        </div>
                        <div className="profilesubContent">
                          <img className="trackitemimgg_1" src={products} alt="" />
                          <span>{singleOrderData?.order?.total_items} Items</span>
                        </div>
                        <div className="profilesubContent">
                          <span className="blueText">Details</span>
                          <img className="rightproarrow" src={prorightarrow} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="deliveryStatus">
                    <div className="statusHead">
                      <div className="statusLeft mb-3">
                        <h3 className="mt-2">Tracking History</h3>
                        {/* <h6>Assigning driver</h6> */}
                      </div>
                    </div>
                    <div
                      className={
                        orderStatus ? "statusBody addClass" : "statusBody"
                      }
                    >
                      <div className="readyPickupstatus">
                        <figure>
                          <img src={orderTrackStatus >= 6 ? pickuporder : unselectedOrder} alt="pickup" />
                        </figure>
                        <article>
                          <h3>verifycode</h3>
                          <h6>........</h6>
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure>
                          <img src={orderTrackStatus >= 5 ? pickuporder : unselectedOrder} alt="verifycode" />
                        </figure>
                        <article>
                          <h3>delivery</h3>
                          {/* <h6>waiting time 10 mins</h6> */}
                        </article>
                      </div>
                      {/* <div className="readyPickupstatus">
                        <figure>
                          <img src={pickuporder} alt="pickup" />
                        </figure>
                        <article>
                          <h3>Next to your block</h3>
                          <h6>with in 10 minutes</h6>
                        </article>
                      </div> */}
                      <div className="readyPickupstatus">
                        <figure>
                          <img src={orderTrackStatus >= 4 ? pickuporder : unselectedOrder} alt="verifyorder" />
                        </figure>
                        <article>
                          <h3>Product Pickup</h3>
                          {/* <h6>with in 10 minutes</h6> */}
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure>
                          <img src={singleOrderData?.driver_details ? pickuporder : unselectedOrder} alt="pickup" />
                        </figure>
                        <article>
                          <h3>Assign Driver</h3>
                          {/* <h6>with in 10 minutes</h6> */}
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure>
                          <img src={orderTrackStatus >= 3 ? pickuporder : unselectedOrder} alt="pickup" />
                        </figure>
                        <article>
                          <h3>Ready to pickup</h3>
                          
                          {/* <h6>with in 10 minutes</h6> */}
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure>
                          <img src={orderTrackStatus >= 1 ? pickuporder : unselectedOrder} alt="pickup" />
                        </figure>
                        <article>
                          <h3>Order accepted</h3>
                          {/* <h6>with in 10 minutes</h6> */}
                        </article>
                      </div>
                      {singleOrderData?.driverDetails ?
                        <div className="carrierbox">
                          <h5>Carrier:</h5>
                          <div className="shippingContact carriercontact">
                            <div className="shipcontactLeft">
                              <img style={{ width: "40px" }} className='me-2' src={singleOrderData?.driverDetails?.profile_photo ? singleOrderData?.driverDetails?.profile_photo : profileImg1} />
                              <div className="contctInfo">
                                <h4>{singleOrderData?.driverDetails?.user_profiles?.firstname + " " + singleOrderData?.driverDetails?.user_profiles?.lastname}</h4>
                              </div>
                            </div>
                            <button className="contactBtn">
                              <i className="las la-phone"></i>
                              <h3>Contact</h3>
                            </button>
                          </div>
                        </div> :
                        <></>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6"> 
                {/* {singleOrderData?.order_details?.length > 0 ? */}
                  <div className="detailTrackingright_">
                    <div className="MapOrders">
                      <div className="MapOrders_inner">
                        <GoogleMap
                          center={center}
                          zoom={15}
                          mapContainerStyle={{ width: '100%', height: '817px' }}
                          options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                          }}
                          onLoad={map => setMap(map)}
                        >
                          {/* {directionsResponse && (
                            <DirectionsRenderer directions={directionsResponse} />
                          )} */}
                        </GoogleMap>
                      </div>
                      {" "}
                    </div>
                  </div>
                  {/* : "No graph Data"} */}
              </div>
              {/* <div className="col-md-6">
                <div className="detailTrackingright_">
                  <div className="MapOrders">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96485.45894957401!2d-72.83509575903798!3d40.912005312792395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85e2c0949dc13%3A0x1f8329c3de1c24ee!2sCalverton%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1667997278940!5m2!1sen!2sin"
                      width="573"
                      height="700"
                      frameBorder="0"
                      border-radius="10px"
                      style={{ border: 0, borderRadius: "10px" }}
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                    />{" "}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default TrakingMap;