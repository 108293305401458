import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, shield, user } from '../../../../utilities/images';
import NewTicket from './newTicket';
import PendingTicket from './pendingTicket';
import ResolveTicket from './resolveTicket';
import { useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import DashboardNavbar from '../../dashboard/DashboardNavbar';
import NewsupportModal from './newsupportModal';
import CustomModal from '../../../components/shared/CustomModal';


const SuportTicket = () => {
  const auth = useSelector(selectLoginAuth);
  const [activeTab, setActiveTab] = useState('newTicket');
  const [startDate, setStartDate] = useState(null);
  const [newTicketCount, setNewTicketCount] = useState("");
  const [pendingTicketCount, setPendingTicketCount] = useState("");
  const [resolvedTicketCount, setResolvedTicketCount] = useState("");
  const [endDate, setEndDate] = useState(null);

  const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Support";
  }, []);


  return (
    <div className='suportTicket'>
            <DashboardNavbar title="Support" backShow={`/support`}/>
            <div className='row'>
              <div className='col-md-12'>
                <div className='tableHead_'>
                  <h3 className='dollarSign'>My Tickets</h3>
                  <div className='righttabContent_'>
                    <button className='bluebtnBox_ me-3' type="submit" onClick={() => {
                setModalDetail({ show: true, flag: "newsupportModal" });
                setKey(Math.random());
            }}>New Support Ticket</button>
                    <button className='viewbtnBox_' type="submit">View All</button>

                  </div>

                  </div>
              </div>
            </div>

      <div className=''>
        <div className='row'>
          <div className='col-md-12'>
            <button className={`${activeTab == 'newTicket' ? 'valuesTimeswallet active' : ''} valuesTimeswallet`} onClick={() => { setActiveTab('newTicket') }}>New Tickets {newTicketCount ? `(${newTicketCount})` : ""}</button>
            <button className={`${activeTab == 'pendingTicket' ? 'valuesTimeswallet active' : ''} valuesTimeswallet`} onClick={() => { setActiveTab('pendingTicket') }}>Pending Tickets {pendingTicketCount ? `(${pendingTicketCount})` : ""}</button>
            <button className={`${activeTab == 'resolveTicket' ? 'valuesTimeswallet active' : ''} valuesTimeswallet`} onClick={() => { setActiveTab('resolveTicket') }}>Resolved Tickets {resolvedTicketCount ? `(${resolvedTicketCount})` : ""}</button>
          </div>
        </div>
      </div>
      <div className='commonTables mt-3 mb-3'>
        <div className='tableFilters'>
          <div className="calenderIcon">
            <img src={calendar} alt="calender" />
            {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
              dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
            <DatePicker
              className='cateDate'
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              placeholderText="Date"
            />
          </div>
          {/* <select className="cateSelectdFilter" required>
            <option value="" selected>
              Country
            </option>
            <option>Category1</option>
            <option>Category2</option>
          </select>
          <select className="cateSelectdFilter" required>
            <option value="" selected>
              State
            </option>
            <option>Category1</option>
            <option>Category2</option>
          </select>
          <select className="cateSelectdFilter" required>
            <option value="" selected>
              Id type
            </option>
            <option>Category1</option>
            <option>Category2</option>
          </select> */}
        </div>
      </div>
      <div className='logTabsDetails'>
        {activeTab == 'newTicket'
          ?
          <NewTicket setNewTicketCount={setNewTicketCount} startDate={startDate} endDate={endDate}/>
          :
          activeTab == 'pendingTicket'
            ?
            <PendingTicket setPendingTicketCount={setPendingTicketCount} startDate={startDate} endDate={endDate} />
            :
            activeTab == 'resolveTicket'
              ?
              <ResolveTicket setResolvedTicketCount={setResolvedTicketCount} startDate={startDate} endDate={endDate} />

              : ""

        }
      </div>
      <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "newsupportModal" ? "commonWidth modalcommon_" :""}
                ids={modalDetail.flag === "newsupportModal" ? "newsupportModal" :
                modalDetail.flag === "newsupportModal" ? "newsupportModal" :""}
                size={modalDetail.flag === "newsupportModal" ? "xl" : "md"}


                child={

                    modalDetail.flag === "newsupportModal" ? (
                        <NewsupportModal close={() => handleOnCloseModal()} />
                    ) :
                        (
                            ""
                        )
                }
                header={
                    <>
                        {modalDetail.flag === 'newsupportModal'
                            ?
                            <>
                                <div className="modalHeader_">
                                    <div className="common_">
                                        <h3 className='modalHeading_'>New Support Ticket</h3>

                                        <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                            <i className="las la-times" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </> : ""
                            
                          
                           
                        }
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
    </div>
  )
}

export default SuportTicket