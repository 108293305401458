import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { clickImg, rightArrow } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";

const AnnualBilling = (props) => {
  const handlePlanData = (id) => {
    const data = {
      flag: "paymentCardInfo",
      id: id,
    };
    props?.flag(data);
  };

  return (
    <div className="annualBilling">
      <div className="row">
        {props?.data?.prices
          ?.sort((a, b) => a.unit_amount - b.unit_amount)
          .map((val, index) => {
            return (
              <div className="col-md-4" key={index}>
                <div
                  className={`${
                    props?.selectedPlan?.length > 0 &&
                    props?.selectedPlan[0].price_id?.id === val?.id
                      ? "active"
                      : ""
                  } ${
                    val?.metadata?.name === "Basic"
                      ? "plansDetailsContainer basicPlan"
                      : val?.metadata?.name === "Standard"
                      ? "plansDetailsContainer standardPlan"
                      : "plansDetailsContainer premiumPlan"
                  }`}
                >
                  <h4 className="innerHeadingSky">{val?.metadata?.name}</h4>
                  <p className="smallText">{val?.nickname}</p>
                  <div className="mb-4 mt-4">
                    <h4 className="planAmount">${val?.unit_amount / 100}</h4>
                    <span className="smallText">Per Year</span>
                  </div>
                  <div className={`appIncludedArea`}>
                    <h6 className="subheadText mb-2">
                      <b>Apps Included:</b>
                    </h6>
                    <div className="appIncludedValues">
                      <div className="appIncludedInputs">
                        <input
                          type="radio"
                          id={`${val?.id}JOBRB2C`}
                          name={`${val?.id}JOBRB2C`}
                          value="JOBR B2C"
                          className="customRadio"
                          checked={true}
                          disabled
                        />
                        <label htmlFor={`${val?.id}JOBRB2C`} className="">
                          JOBR B2C
                        </label>
                      </div>
                      {/* <div className="appIncludedInputs">
                        <input
                          type="radio"
                          id={`${val?.id}JOBRWallet`}
                          name={`${val?.id}JOBRWallet`}
                          value="JOBR Wallet"
                          className="customRadio"
                          checked={true}
                          disabled
                        />
                        <label htmlFor={`${val?.id}JOBRWallet`}>
                          JOBR Wallet
                        </label>
                      </div> */}
                      <div className="appIncludedInputs">
                        <input
                          type="radio"
                          id={`${val?.id}JOBRPOS`}
                          name={`${val?.id}JOBRPOS`}
                          value="JOBR POS"
                          className="customRadio"
                          checked={
                            val?.metadata?.name != "Basic" ? true : false
                          }
                          disabled
                        />
                        <label htmlFor={`${val?.id}JOBRPOS`}>JOBR POS</label>
                      </div>
                      <div className="appIncludedInputs">
                        <input
                          type="radio"
                          id={`${val?.id}JOBRDriver`}
                          name={`${val?.id}JOBRDriver`}
                          value="JOBRDriver"
                          className="customRadio"
                          checked={
                            val?.metadata?.name != "Basic" ? true : false
                          }
                          disabled
                        />
                        <label htmlFor={`${val?.id}JOBRDriver`}>
                          JOBR Driver
                        </label>
                      </div>
                      <div className="appIncludedInputs">
                        <input
                          type="radio"
                          id={`${val?.id}JOBRB2B`}
                          name={`${val?.id}JOBRB2B`}
                          value="JOBRB2B"
                          className="customRadio"
                          checked={
                            val?.metadata?.name === "Premium" ? true : false
                          }
                          disabled
                        />
                        <label htmlFor={`${val?.id}JOBRB2B`}>JOBR B2B</label>
                        <br />
                      </div>
                      <button
                        onClick={() => {
                          props?.selectedPlan?.length > 0 &&
                          props?.selectedPlan[0].price_id?.id === val?.id
                            ? void 0
                            : handlePlanData(val?.id);
                        }}
                        className="getStarted mt-4 mb-4"
                        // disabled={loading}
                      >
                        {/* {loading && val?.id == newSelectedId && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )} */}
                        &nbsp;&nbsp;
                        {props?.selectedPlan?.length > 0 &&
                        props?.selectedPlan[0].price_id?.id === val?.id
                          ? "Active Plan"
                          : "Get Started"}
                        {props?.selectedPlan?.length > 0 &&
                        props?.selectedPlan[0].price_id?.id === val?.id ? (
                          <></>
                        ) : (
                          <img src={rightArrow} alt="" className="ms-3" />
                        )}
                      </button>
                    </div>
                    <ul className="otherMainus">
                      <ul className="otherMainus">
                        <li className="otherItems" key={index}>
                          <img src={clickImg} className="" alt="" />
                          <span className="">Online store</span>
                        </li>
                        <li className="otherItems" key={index}>
                          <img src={clickImg} className="" alt="" />
                          <span className="">Shareable products</span>
                        </li>
                        <li className="otherItems" key={index}>
                          <img src={clickImg} className="" alt="" />
                          <span className="">Unlimited products</span>
                        </li>
                        {(val?.metadata?.name === "Standard" ||
                          val?.metadata?.name === "Premium") && (
                          <li className="otherItems" key={index}>
                            <img src={clickImg} className="" alt="" />
                            <span className="">24/7 support</span>
                          </li>
                        )}
                        {val?.metadata?.name === "Premium" && (
                          <>
                            <li className="otherItems" key={index}>
                              <img src={clickImg} className="" alt="" />
                              <span className="">Abandoned cart recovery</span>
                            </li>
                            <li className="otherItems" key={index}>
                              <img src={clickImg} className="" alt="" />
                              <span className="">Advanced report builder</span>
                            </li>
                          </>
                        )}
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AnnualBilling;
