
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import products from "../../../db/category.json";

const ViewlogHistory = () => {
    const [key, setKey] = useState(Math.random());
    const history = useHistory()
    const [activeTab, setActiveTab] = useState('logHistory');
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
      });
      const handleOnCloseModal = () => {
        setModalDetail({
          show: false,
          title: "",
          flag: "",
        });
        setKey(Math.random());
      };
      const columns1 = [
        {
          name: '#',
          selector: row => row.id,
          width: "50px" 
        },
        {
          name: "Log in date",
          selector: row => {
          return (
                <>
                  <span className='textParagh '>05 May, 2022</span>
                </>
              );
          },
          width: "130px", 
        },
        { name: 'Log in time',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>10:05:32 pm</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'logout date',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>05 May, 2022</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'logout time',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>05:12:32 pm</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'Duration',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>08h 07min 00sec</span>
                </>
              );
          },
          width: "220px",
        },
        { name: 'Media',
        selector: row => {
          return (
                <>
                  <span className='textParagh '>Chrome</span>
                </>
              );
          },
          width: "220px",
        },
        // {
        //   selector: row => row.types,
        //   name: 'Type'
        // },
        // {
        //   selector: row => row.fees,
        //   name: 'Fees'
        // },
    ];
    
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({pathname:'/analytics-product',state:JSON.stringify(e)})
    }
    return (
        <div className='systemEmplyInvoice'>
            <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row  align-items-center">
                        <div className="col-md-6">
                            <div className='anotherHeader'>
                            <i className="las la-angle-left" onClick={() => history.push ('/systemConfig/userviewList')}></i>
                            <h2 className="innerSubheadNormal">Role: Wallet Admin</h2>
                            {/* <span className="pendingStatus">Pending</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='commonTables mt-3 mb-3'>
          <div className='tableFilters row'>
            <div className='col-md-3'>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    Role
                    </option>
                    <option>Admin</option>
                    <option>leave type2</option>
                </select>
            </div>
            <div className='col-md-3'>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    Status
                    </option>
                    <option>2000</option>
                    <option>2001</option>
                </select>
            </div>
            <div className='col-md-3'>
                <select className="cateSelectdFilter" required>
                    <option value="" selected>
                    Gender
                    </option>
                    <option>Male</option>
                    <option>Female</option>
                </select>
            </div>
          </div>
        </div>
        <DataTable
          pagination
          columns={columns1}
          data={products}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
        />
           
            
        </div>
    )
}

export default ViewlogHistory