import React, { useState, useEffect } from "react";
import { downlight } from "../../../../utilities/images";
import { Link } from "react-router-dom";
import HeaderStatistics from "./HeaderStatistics";
import ChartArea from "./ChartArea";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import {
  getMerchantStatisticsAsync,
  getMerchantOrderGraphAsync,
  getMerchantTotalCustomerGraphAsync,
  getMerchantTotalOrderGraphAsync,
} from "../dashboardSlice";
import CustomLoader from "../../../components/shared/CustomLoader";
import DashboardNavbar from "../DashboardNavbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WalletStats = () => {
  const history = useHistory();
  const [merchantStatistics, setMerchantStatistics] = useState({});
  const [merchantOrderGraph, setMerchantOrderGraph] = useState({});
  const [merchantCustomerGrpah, setMerchantCustomerGrpah] = useState("");
  const [merchantOrderGraphAnalysic, setMerchantOrderGraphAnalysis] =
    useState("");
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({
    Walkin: true,
    Online: true,
    New: true,
  });
  const [checked1, setChecked1] = useState({
    Pos: true,
    Delivery: true,
    Shipping: true,
  });
  let [dataSets, setDataSets] = useState([]);
  let [dataSets1, setDataSets1] = useState([]);

  const [filterValue, setFilterValue] = useState("week");
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        align: "start",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          pointStyle: "circle",
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const barData = {
    labels: merchantOrderGraph?.labels,
    datasets: [
      {
        fill: true,
        label: "Walking Customer",
        data:
          merchantOrderGraph?.datasets?.length > 0
            ? merchantOrderGraph?.datasets[0]?.data
            : [],
        borderColor: "#275AFF",
        backgroundColor: "#FFFFFF00",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handelDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: merchantCustomerGrpah?.graphData?.datasets
            ? merchantCustomerGrpah.graphData.datasets[num]?.data
            : "",
          borderColor: color,
          backgroundColor: color,
        },
      ]);
    } else {
      setDataSets(dataSets.filter((item) => item.label !== value));
    }
  };
  const handelOrderDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets1([
        ...dataSets1,
        {
          fill: true,
          label: value,
          data: merchantOrderGraphAnalysic?.graphData?.datasets
            ? merchantOrderGraphAnalysic.graphData.datasets[num]?.data
            : "",
          borderColor: color,
          backgroundColor: color,
        },
      ]);
    } else {
      setDataSets1(dataSets1.filter((item) => item.label !== value));
    }
  };
  const getMerchantStatistics = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
        filter: value,
        need_sales: true,
      },
    };
    setLoading(true);
    dispatch(getMerchantStatisticsAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setMerchantStatistics(obj.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getMerchantOrderGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
        filter: value,
        need_sales: true,
      },
    };
    setLoading(true);
    dispatch(getMerchantOrderGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setMerchantOrderGraph(obj.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getMerchantTotalCustomerGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
        filter: value,
      },
    };
    setLoading(true);
    dispatch(getMerchantTotalCustomerGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);

        setMerchantCustomerGrpah(obj.payload);
        setDataSets([
          {
            fill: true,
            label: "Walkin Customer",
            data: obj?.payload?.graphData?.datasets[0]?.data,
            borderColor: "#1FB3FF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Online Customer",
            data: obj?.payload?.graphData?.datasets[1]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "New Customer",
            data: obj?.payload?.graphData?.datasets[2]?.data,
            borderColor: "#FCBA30",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getMerchantTotalOrderGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
        filter: value,
      },
    };
    setLoading(true);
    dispatch(getMerchantTotalOrderGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setMerchantOrderGraphAnalysis(obj.payload);
        setDataSets1([
          {
            fill: true,
            label: "POS Orders",
            data: obj.payload?.graphData?.datasets[0]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Delivery Orders",
            data: obj.payload?.graphData?.datasets[1]?.data,
            borderColor: "#1FB3FF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Shipping Orders",
            data: obj.payload?.graphData?.datasets[2]?.data,
            borderColor: "#102773",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMerchantStatistics(filterValue);
    getMerchantOrderGraph(filterValue);
    getMerchantTotalCustomerGraph(filterValue);
    getMerchantTotalOrderGraph(filterValue);
  }, [filterValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Dashboard";
  }, []);
  

  return (
    <div className="walletStats">
      <div className="row mb-3">
        <div className="col-lg-5"></div>
        <div className="col-lg-7 d-flex justify-content-end">
          <ul className="weekNav">
            <li onClick={() => setFilterValue("today")}>
              <Link
                className={`weekMenu ${filterValue == "today" ? "active" : ""}`}
                to="#"
              >
                Today
              </Link>
            </li>
            <li onClick={() => setFilterValue("week")}>
              <Link
                className={`weekMenu ${filterValue == "week" ? "active" : ""}`}
                to="#"
              >
                Weekly
              </Link>
            </li>
            <li onClick={() => setFilterValue("month")}>
              <Link
                className={`weekMenu ${filterValue == "month" ? "active" : ""}`}
                to="#"
              >
                Monthly
              </Link>
            </li>
            <li onClick={() => setFilterValue("year")}>
              <Link
                className={`weekMenu ${filterValue == "year" ? "active" : ""}`}
                to="#"
              >
                Yearly
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* Start PercentageContainer Section */}
      <div className="verificationTotal">
        <DashboardNavbar title="Home" show={false} />

        {loading ? (
          <div className="col-md-12">
            <span className="spinner-border inner-spin spinner-border-sm"></span>
          </div>
        ) : Object.keys(merchantStatistics).length > 0 ? (
          <>
            <div className="col-md-12">
              <div className="row">
                <HeaderStatistics
                  value={`$${addThousandSeparator(
                    (merchantStatistics?.net_sales
                      ? merchantStatistics?.net_sales
                      : 0
                    )?.toFixed(2)
                  )}`}
                  heading="Net Sales"
                  percentage={`${(merchantStatistics?.net_sales_stat
                    ? merchantStatistics?.net_sales_stat
                    : 0
                  )?.toFixed(2)}`}
                  push={() => history.push("/Gross-Profits")}
                  positive={
                    merchantStatistics?.net_sales_stat < 0 ? false : true
                  }
                />
                <HeaderStatistics
                  value={`$${addThousandSeparator(
                    (merchantStatistics?.gross_sales
                      ? merchantStatistics?.gross_sales
                      : 0
                    )?.toFixed(2)
                  )}`}
                  heading="Gross Sales"
                  percentage={`${(merchantStatistics?.gross_sales_stats
                    ? merchantStatistics?.gross_sales_stats
                    : 0
                  )?.toFixed(2)}`}
                  push={() => history.push("/Total-Orders")}
                  positive={
                    merchantStatistics?.gross_sales_stats < 0 ? false : true
                  }
                />
                <HeaderStatistics
                  value={`$${addThousandSeparator(
                    (merchantStatistics?.total_transactions
                      ? merchantStatistics?.total_transactions
                      : 0
                    )?.toFixed(2)
                  )}`}
                  heading="Transections"
                  percentage={`${(merchantStatistics?.total_transactions_stats
                    ? merchantStatistics?.total_transactions_stats
                    : 0
                  )?.toFixed(2)}`}
                  push={() => history.push("/transections")}
                  positive={
                    merchantStatistics?.total_transactions_stats < 0
                      ? false
                      : true
                  }
                />
                <HeaderStatistics
                  value={`$${addThousandSeparator(
                    (merchantStatistics?.avg_net_sales
                      ? merchantStatistics?.avg_net_sales
                      : 0
                    )?.toFixed(2)
                  )}`}
                  heading="Avg Net Sales"
                  percentage={`${(merchantStatistics?.avg_sales_stats
                    ? merchantStatistics?.avg_sales_stats
                    : 0
                  )?.toFixed(2)}`}
                  push={() => history.push("/Total-Revenue")}
                  positive={
                    merchantStatistics?.avg_sales_stats < 0 ? false : true
                  }
                />
              </div>
              <div className="chartsOuter mt-4 mb-3">
                <h3 className="textInnerHead ms-4">Sales Overview </h3>
                <ChartArea
                  classes="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3"
                  push={() => history.push("/analytics")}
                  header="Sales Overview"
                  options={options}
                  data={barData}
                  chartType="Line"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="chartsOuter mt-4 mb-3">
                  <h3
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/userList")}
                    className="textInnerHead ms-4"
                  >
                    Total Customer
                  </h3>
                  <h6 className="textInnerHead ms-4  mt-2">
                    {merchantCustomerGrpah?.total_customers?.totalCustomer}
                  </h6>
                  <div className="graphChecked_  mb-2  mt-3">
                    <div className="graphCheck_ me-3 mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll2"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelDataSetChange(
                            e,
                            "Walkin Customer",
                            0,
                            "#1FB3FF"
                          );
                          setChecked({
                            ...checked,
                            Walkin: checked.Walkin === true ? false : true,
                          });
                        }}
                        checked={checked.Walkin}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll2"
                        className="checkBoxTxt masterChecked"
                      >
                        Walkin Customer
                      </label>
                    </div>
                    <div className="graphBox_ me-3 mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll3"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={checked.Online}
                        onChange={(e) => {
                          handelDataSetChange(
                            e,
                            "Online Customer",
                            1,
                            "#275AFF"
                          );
                          setChecked({
                            ...checked,
                            Online: checked.Online === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll3"
                        className="checkBoxTxt masterChecked"
                      >
                        Online Customer
                      </label>
                    </div>
                    <div className="checkYellow me-3 mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll4"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={checked.New}
                        onChange={(e) => {
                          handelDataSetChange(e, "New Customer", 2, "#FCBA30");
                          setChecked({
                            ...checked,
                            New: checked.New === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll4"
                        className="checkBoxTxt masterChecked"
                      >
                        New Customer
                      </label>
                    </div>
                  </div>
                  <ChartArea
                    classes="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3"
                    header="Sales Overview"
                    options={options1}
                    data={{
                      labels: merchantCustomerGrpah?.graphData?.labels,
                      datasets: dataSets,
                    }}
                    chartType="Line"
                  />
                </div>
              </div>
              {/* <div className='col-md-4'>
                                        <div className='chartsOuter mt-4 mb-3'>
                                            <h3 className='textInnerHead ms-4'>Payment Type </h3>
                                            <ChartArea classes="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3" header="Sales Overview" options={options1} data={barData} chartType="Bar" />
                                        </div>
                                    </div> */}
              <div className="col-md-4">
                <div className="chartsOuter mt-4 mb-3">
                  <h3
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/Total-Orders")}
                    className="textInnerHead ms-4"
                  >
                    Total Orders
                  </h3>
                  <h6 className="textInnerHead ms-4 mt-2">
                    {merchantOrderGraphAnalysic?.totalAmount
                      ? `$${addThousandSeparator(
                          merchantOrderGraphAnalysic?.totalAmount?.toFixed(2)
                        )}`
                      : "$0.00"}
                  </h6>
                  <div className="graphChecked_  mb-2  mt-3">
                    <div className="graphBox_ me-3 mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAllOrder2"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelOrderDataSetChange(
                            e,
                            "POS Orders",
                            0,
                            "#275AFF"
                          );
                          setChecked1({
                            ...checked1,
                            Pos: checked1.Pos === true ? false : true,
                          });
                        }}
                        checked={checked1.Pos}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAllOrder2"
                        className="checkBoxTxt masterChecked"
                      >
                        POS Orders
                      </label>
                    </div>
                    <div className="graphCheck_ me-3 mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAllOrder3"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={checked1.Delivery}
                        onChange={(e) => {
                          handelOrderDataSetChange(
                            e,
                            "Delivery Orders",
                            1,
                            "#1FB3FF"
                          );
                          setChecked1({
                            ...checked1,
                            Delivery: checked1.Delivery === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAllOrder3"
                        className="checkBoxTxt masterChecked"
                      >
                        Delivery Orders
                      </label>
                    </div>
                    <div className="darkBlueCheck me-3 mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAllOrder4"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={checked1.Shipping}
                        onChange={(e) => {
                          handelOrderDataSetChange(
                            e,
                            "Shipping Orders",
                            2,
                            "#102773"
                          );
                          setChecked1({
                            ...checked1,
                            Shipping: checked1.Shipping === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAllOrder4"
                        className="checkBoxTxt masterChecked"
                      >
                        Shipping Orders
                      </label>
                    </div>
                  </div>
                  <ChartArea
                    classes="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3"
                    header="Sales Overview"
                    options={options1}
                    data={{
                      labels: merchantOrderGraphAnalysic?.graphData?.labels,
                      datasets: dataSets1,
                    }}
                    chartType="Line"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="col-md-12">
            {" "}
            <h2>No record found</h2>
          </div>
        )}
        {/* <div className='col-md-4'>
                        <div className='notificationBoard'>
                            <h3 className='mainboldHead'><b>Notification board</b></h3>
                            <ul className='notificationDetails'>
                                <li className='notificationItems'>
                                    <Link to='#' className='notificationTxt notShow'>
                                        <span className='hightlightSmall'>JOBR POS/</span>
                                        3 items sales @$56.00
                                    </Link>
                                </li>
                                <li className='notificationItems'>
                                    <Link to='#' className='notificationTxt notShow'>
                                        <span className='hightlightSmall'>JOBR POS/</span>
                                        3 items sales @$56.00
                                    </Link>
                                </li>
                                <li className='notificationItems'>
                                    <Link to='#' className='notificationTxt showed'>
                                        <span className='hightlightSmall'>JOBR POS/</span>
                                        3 items sales @$56.00
                                    </Link>
                                </li>
                                <li className='notificationItems'>
                                    <Link to="#" className='notificationTxt showed'>
                                        <span className='hightlightSmall'>JOBR POS/</span>
                                        3 items sales @$56.00
                                    </Link>
                                </li>
                            </ul>
                            <button className='viewAll'>View All</button>
                        </div>
                    </div> */}
      </div>
      {/* End PercentageContainer Section */}
    </div>
  );
};

export default WalletStats;
