import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component'
import { calendar, user, locationBlue, moneyIcon, profileImg1 } from '../../../utilities/images'
import products from "../../db/category.json"
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { getAppointemntApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomLoader from '../../components/shared/CustomLoader';
import CustomModal from '../../components/shared/CustomModal';
import AppointSchedule from './todayAppointments/appointSchedule';

const FulfilledAppointment = () => {
    const { flag, status } = useParams();
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const [appointmentData, setAppointmentData] = useState([]);
    const [singleOrderData, setSingleOrderData] = useState({});
    const [key, setKey] = useState(Math.random());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };

    const columns1 = [
        {
            name: '#',
            width: "70px",
            selector: row => row.id

        },
        {
            name: 'Visited Time',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <span className='contentSubtext_'>{moment(row?.date).format("MMMM DD, YYYY")}</span>
                            <p className='textInner mt-2'>{row?.start_time + "-" + row?.end_time}</p>
                        </div>
                    </>
                );
            },
            width: "200px",
        },

        {
            name: "User",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.user_details?.profile_photo ? row?.user_details?.profile_photo : profileImg1} />
                            <div className='ms-2 text-start'>
                                <span className='contentSubtext_ '>{row?.user_details?.firstname + " " + row?.user_details?.lastname}</span>
                                <p className='mb-0'>
                                    <img src={locationBlue} className='img-fluid me-2' />
                                    <span className='textInner'>{row?.user_details?.current_address?.city},{row?.user_details?.current_address?.country?.substring(0, 10) + "..."} </span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "300px",
        },
        {
            name: "Service Name",
            selector: row => row?.product_name,
        },
        {
            name: 'Appointment Id',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <span className='contentSubtext_'>#{row?.id}</span>
                        </div>
                    </>
                );
            },
            width: "200px",
        },


        {
            name: 'Amount',
            selector: row => {
                return (
                    <>
                        <div className='ms-2'>
                            <span className='contentSubtext_'>${row?.payable_amount}</span>
                        </div>
                    </>
                );
            },
        },


        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2 tableBtn__'>
                            <Link to='#' className='completeStatus' > Fulfilled </Link>

                        </div>
                    </>
                );
            },

            width: "100px"
        },

        // {
        //     name: '',
        //     selector: row => {
        //         return (
        //             <>
        //                 <div className='ms-2' onClick={() => {
        //                     setModalDetail({ show: true, flag: "PendingInvoiceModal" });
        //                     setKey(Math.random());
        //                 }}>
        //                     <i className="fal fa-chevron-right ms-3" aria-hidden="true"></i>
        //                 </div>
        //             </>
        //         );
        //     },
        //     width: "100px",
        // },
    ]

    const columns2 = [
        {
            name: '#',
            width: "50px",
            selector: row => row?.id

        },
        {
            name: 'Visited Time',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <span className='contentSubtext_'>{moment(row?.date).format("MMMM DD, YYYY")}</span>
                            <p className='textInner mt-2'>{row?.start_time + "-" + row?.end_time}</p>
                        </div>
                    </>
                );
            },
            width: "200px",
        },

        {
            name: "User",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.user_details?.profile_photo ? row?.user_details?.profile_photo : profileImg1} />
                            <div className='ms-2 text-start'>
                                <span className='contentSubtext_ '>{row?.user_details?.firstname + " " + row?.user_details?.lastname}</span>
                                <p className='mb-0'>
                                    <img src={locationBlue} className='img-fluid me-2' />
                                    <span className='textInner'>{row?.user_details?.current_address?.city},{row?.user_details?.current_address?.country?.substring(0, 10) + "..."} </span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "300px",
        },
        {
            name: "Service Name",
            selector: row => row?.product_name,
        },
        {
            name: 'Appointment Id',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <span className='contentSubtext_'>#{row?.id}</span>
                        </div>
                    </>
                );
            },
            width: "200px",
        },

        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2 tableBtn__'>
                            <Link to='#' className='deniedBtn' > Cancelled  </Link>

                        </div>
                    </>
                );
            },

            width: "130px"
        },

        // {
        //     name: '',
        //     selector: row => {
        //         return (
        //             <>
        //                 <div className='ms-2' >
        //                     <i className="fal fa-chevron-right ms-3" aria-hidden="true"></i>
        //                 </div>
        //             </>
        //         );
        //     },
        //     width: "100px",
        // },
    ]

    const handleRowClick = (e) => {
        setSingleOrderData(e)
        if (singleOrderData) {
            setModalDetail({ show: true, flag: "PendingInvoiceModal" });
            setKey(Math.random())
        }
    }

    const handleAppointmentData = () => {
        let params = {
            "postData": {
                // page: 1,
                // limit: 10,
                status: status,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate != null && endDate != null) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAppointemntApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAppointmentData(obj?.payload?.data)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        handleAppointmentData()
    }, [flag, status, endDate])

    return (
        <>
            {loading === true ?
                // <CustomLoader /> 
                <span className="spinner-border inner-spin spinner-border-sm"></span>
                :
                <>
                    <div className='fulfilledAppointment_'>
                        <div className='topHeader_'>
                            <div className="topStickyHeader_">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <h2 className="mainHeading">Appointments / <span className="innerSubtext">{flag === 'fulfilled' ? 'Fulfilled Appointments' : 'Cancelled Appointments'}</span> </h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='tableFilters'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="calenderIcon">
                                        <img src={calendar} alt="calender" />
                                        {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                            dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                        <DatePicker
                                            className='cateDate'
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            placeholderText="Date"
                                        />
                                    </div>
                                </div>
                                {/* <div className='col-md-3'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            Country
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            State
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div>
                                <div className='col-md-3'>
                                    <select className="cateSelectdFilter" required>
                                        <option value="" selected>
                                            Id type
                                        </option>
                                        <option>Category1</option>
                                        <option>Category2</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <section className='recentTransections mt-5 table-responsive'>
                        <DataTable
                            pagination
                            columns={flag === "fulfilled" ? columns1 : columns2}
                            data={appointmentData}
                            defaultSortFieldId={1}
                            customStyles={customStyles}
                            className="fullApointment"
                            onRowClicked={handleRowClick}
                        />
                    </section>
                </>}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "PendingInvoiceModal" ? "commonWidth customContent" : ""}
                child={
                    modalDetail.flag === "PendingInvoiceModal" ? (
                        <AppointSchedule data={singleOrderData} close={() => handleOnCloseModal()} />
                    ) :

                        (
                            ""
                        )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default FulfilledAppointment