import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import { darkInfo } from '../../../../utilities/images'
import { unwrapResult } from '@reduxjs/toolkit'
import { getUserSettingsApiAsync, updateUserSettingAsync } from '../systemConfigSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getAddressApiAsync, selectLoginAuth } from '../../auth/authSlice'
import { dayList, options } from '../../../../utilities/helpers'
import TimePicker from 'react-time-picker'
import { Dropdown, Form } from 'react-bootstrap'
import moment from 'moment-timezone'
import { updateSellerInfoApiAsync } from '../../dashboard/dashboardSlice'
import { toast } from 'react-toastify'
import LargeOrderQuantity from './LargeOrderQuantity'
import CustomModal from '../../../components/shared/CustomModal'

const RetailOrder = () => {
    let selectedOptionNew = []
    const dispatch = useDispatch();
    const toastId = React.useRef(null);
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [userSettingData, setUserSettingData] = useState("");
    const [productAvailability, setProductAvailability] = useState('');
    const [startMondayTime, setStartMondayTime] = useState("");
    console.log(startMondayTime, 'start monday timeee');
    const [endMondayTime, setEndMondayTime] = useState('');
    console.log(endMondayTime, 'endMondayTime monday timeee');
    const [startTuesddayTime, setStartTuesdayTime] = useState("");
    const [endTuesdayTime, setEndTuesdayTime] = useState('');
    const [startWednesdayTime, setStartWednesdayTime] = useState("");
    const [endWednesdayTime, setEndWednesdayTime] = useState('');
    const [startThursdayTime, setStartThursdayTime] = useState("");
    const [endThursdayTime, setEndThursdayTime] = useState('');
    const [startFridayTime, setStartFridayTime] = useState("");
    const [endFridayTime, setEndFridayTime] = useState('');
    const [startSaturdayTime, setStartSaturdayTime] = useState("");
    const [endSaturdayTime, setEndSaturdayTime] = useState('');
    const [startSundayTime, setStartSundayTime] = useState("");
    const [endSundayTime, setEndSundayTime] = useState('');
    const [businessHours, setBusinessHours] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [qty, setQty] = useState("")
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };


    function convertTo24HourFormat(time12Hour) {
        console.log(time12Hour, 'input time');
        // Split the input time string into hours and minutes
        const [time, period] = time12Hour?.split(' ');
        const [hours, minutes] = time?.split(':');

        // Convert hours to an integer
        let hours24Hour = parseInt(hours, 10);
        // Adjust hours based on AM or PM
        if ((period == 'pm' || period == 'PM') && hours24Hour != 12) {
            hours24Hour = hours24Hour + 12;
        } else if ((period == 'am' || period == 'AM') && hours24Hour == 12) {
            hours24Hour = 0;
        }

        // Format hours and minutes with leading zeros
        const formattedHours = hours24Hour.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');

        // Combine the hours and minutes into the 24-hour format
        const time24Hour = `${formattedHours}:${formattedMinutes}`;
        return time24Hour;
    }
    const handleDefaultOnlineOrder = (e) => {
        let data = {
            "default_online_order_status": e.target.value,
            'app_name': "pos"
        }
        updateUserSettingshandle(data)
    }
    const handleCheckBox = (e, flag, isToggle) => {
        let data = {}
        if (flag === "largeOrder") {
            if (isToggle == "called" || isToggle == "toggle") {
                data = {
                    "large_order_setting": e,
                    'app_name': "pos",
                    'large_order_quantity_limit': qty
                }
                setQty("")
            }
            else {
                data = {
                    "large_order_setting": e,
                    'app_name': "pos"
                }
            }

            if (isToggle == "called") {
                setModalDetail({ show: true, flag: "LargeOrderQuantity" });
                setKey(Math.random());
            }
            console.log(e, "large orderrrrrrrrrrrrrrrr")
        }
        if (flag === "preOrder") {
            data = {
                "enable_pre_order": e,
                'app_name': "pos"
            }
        }
        if (flag === "24/7Order") {
            data = {
                "enable_24_7_online_orders": e,
                'app_name': "pos"
            }
        }
        if (flag === "usaHolidays") {
            data = {
                "usa_holidays_as_days_off": e,
                'app_name': "pos"
            }
        }
        if (isToggle != "called" || isToggle == "toggle" || !isToggle) {
            updateUserSettingshandle(data)
        }

    }

    const handleStartTimeChange = (newStartTime, flag) => {
        if (flag == "monday") {
            setStartMondayTime(newStartTime)
        }
        if (flag == "tuesday") {
            setStartTuesdayTime(newStartTime)
        }
        if (flag == "wednesday") {
            setStartWednesdayTime(newStartTime)
        }
        if (flag == "thursday") {
            setStartThursdayTime(newStartTime)
        }
        if (flag == "friday") {
            setStartFridayTime(newStartTime)
        }
        if (flag == "saturday") {
            setStartSaturdayTime(newStartTime)
        }
        if (flag == "sunday") {
            setStartSundayTime(newStartTime)
        }
    };

    const handleEndTimeChange = (newEndTime, flag) => {
        if (flag == "monday") {
            setEndMondayTime(newEndTime)
        }
        if (flag == "tuesday") {
            setEndTuesdayTime(newEndTime)
        }
        if (flag == "wednesday") {
            setEndWednesdayTime(newEndTime)
        }
        if (flag == "thursday") {
            setEndThursdayTime(newEndTime)
        }
        if (flag == "friday") {
            setEndFridayTime(newEndTime)
        }
        if (flag == "saturday") {
            setEndSaturdayTime(newEndTime)
        }
        if (flag == "sunday") {
            setEndSundayTime(newEndTime)
        }
    };

    const handleCheckboxChange2 = (option, flag) => {
        if (selectedOptions.find(val => val?.id == option?.id)) {
            selectedOptionNew = selectedOptions.filter((item) => item?.id !== option?.id)
            setSelectedOptions(selectedOptionNew);
        } else {
            selectedOptionNew = [...selectedOptions, option]
            setSelectedOptions([...selectedOptions, option]);
        }

        let startTime = (flag == 1) ? startMondayTime :
            (flag == 2) ? startTuesddayTime :
                (flag == 3) ? startWednesdayTime :
                    (flag == 4) ? startThursdayTime :
                        (flag == 5) ? startFridayTime :
                            (flag == 6) ? startSaturdayTime :
                                startSundayTime

        let endTime = (flag == 1) ? endMondayTime :
            (flag == 2) ? endTuesdayTime :
                (flag == 3) ? endWednesdayTime :
                    (flag == 4) ? endThursdayTime :
                        (flag == 5) ? endFridayTime :
                            (flag == 6) ? endSaturdayTime :
                                endSundayTime

        if (selectedOptionNew.find((day) => day.id == 1)) {
            setStartMondayTime(startTime);
            setEndMondayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 2)) {
            setStartTuesdayTime(startTime);
            setEndTuesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 3)) {
            setStartWednesdayTime(startTime);
            setEndWednesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 4)) {
            setStartThursdayTime(startTime);
            setEndThursdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 5)) {
            setStartFridayTime(startTime);
            setEndFridayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 6)) {
            setStartSaturdayTime(startTime);
            setEndSaturdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 7)) {
            setStartSundayTime(startTime);
            setEndSundayTime(endTime)
        }
    };
    const handleCopy = (flag) => {
        setSelectedOptions([])
    }
    const handleCheckboxChange1 = (event) => {
        var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
        if (event.target.checked) {
            updatedList = businessHours?.length > 0 ? [...businessHours, event.target.value] : [event.target.value];
        } else {
            updatedList.splice(businessHours?.indexOf(event.target.value), 1);
        }
        setBusinessHours(updatedList);
    }

    const handleUpdate = () => {
        if ((businessHours?.includes("monday") && !startMondayTime) ||
            (businessHours?.includes("tuesday") && !startTuesddayTime) ||
            (businessHours?.includes("wednesday") && !startWednesdayTime) ||
            (businessHours?.includes("thursday") && !startThursdayTime) ||
            (businessHours?.includes("friday") && !startFridayTime) ||
            (businessHours?.includes("saturday") && !startSaturdayTime) ||
            (businessHours?.includes("sunday") && !startSundayTime)
        ) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select correct start time");
            }
            return false
        }
        if ((businessHours?.includes("monday") && !endMondayTime) ||
            (businessHours?.includes("tuesday") && !endTuesdayTime) ||
            (businessHours?.includes("wednesday") && !endWednesdayTime) ||
            (businessHours?.includes("thursday") && !endThursdayTime) ||
            (businessHours?.includes("friday") && !endFridayTime) ||
            (businessHours?.includes("saturday") && !endSaturdayTime) ||
            (businessHours?.includes("sunday") && !endSundayTime)
        ) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select correct end time");
            }
            return false
        }
        const paramsData = {
            "postData": {
                "id": productAvailability?.id,
                "product_availability": {
                    "availability": [
                        {
                            "day": "1",
                            "opening_time": businessHours?.includes("monday") ? moment(startMondayTime, 'h:mm a').format('h:mm a') : "",
                            "closing_time": businessHours?.includes("monday") ? moment(endMondayTime, 'h:mm a').format('h:mm a') : ""
                        },
                        {
                            "day": "2",
                            "opening_time": businessHours?.includes("tuesday") ? moment(startTuesddayTime, 'h:mm a').format('h:mm a') : "",
                            "closing_time": businessHours?.includes("tuesday") ? moment(endTuesdayTime, 'h:mm a').format('h:mm a') : ""
                        },
                        {
                            "day": "3",
                            "opening_time": businessHours?.includes("wednesday") ? moment(startWednesdayTime, 'h:mm a').format('h:mm a') : "",
                            "closing_time": businessHours?.includes("wednesday") ? moment(endWednesdayTime, 'h:mm a').format('h:mm a') : ""
                        },
                        {
                            "day": "4",
                            "opening_time": businessHours?.includes("thursday") ? moment(startThursdayTime, 'h:mm a').format('h:mm a') : "",
                            "closing_time": businessHours?.includes("thursday") ? moment(endThursdayTime, 'h:mm a').format('h:mm a') : ""
                        },
                        {
                            "day": "5",
                            "opening_time": businessHours?.includes("friday") ? moment(startFridayTime, 'h:mm a').format('h:mm a') : "",
                            "closing_time": businessHours?.includes("friday") ? moment(endFridayTime, 'h:mm a').format('h:mm a') : ""
                        },
                        {
                            "day": "6",
                            "opening_time": businessHours?.includes("saturday") ? moment(startSaturdayTime, 'h:mm a').format('h:mm a') : "",
                            "closing_time": businessHours?.includes("saturday") ? moment(endSaturdayTime, 'h:mm a').format('h:mm a') : ""
                        },
                        {
                            "day": "7",
                            "opening_time": businessHours?.includes("sunday") ? moment(startSundayTime, 'h:mm a').format('h:mm a') : "",
                            "closing_time": businessHours?.includes("sunday") ? moment(endSundayTime, 'h:mm a').format('h:mm a') : ""
                        }
                    ]
                }
            },
            "tokenData": auth?.payload?.token
        }
        setButtonLoading(true)
        dispatch(updateSellerInfoApiAsync(paramsData))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    token: auth?.payload?.token,
                    sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                }
                dispatch(getAddressApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        toast.success("Product availabilty updated successfully")
                        setUserSettingData(obj?.payload)
                        setButtonLoading(false)
                    })
                    .catch((obj) => {
                        setButtonLoading(false)
                    })
            })
            .catch((obj) => {
                setButtonLoading(false)
            })
    }


    const updateUserSettingshandle = (data) => {
        let paramData = {
            tokenData: auth?.payload?.token,
            postData: data
        }
        data?.default_online_order_status ? setLoading(true) : void (0)
        dispatch(updateUserSettingAsync(paramData))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    postData: {
                        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                        app_name: 'pos'
                    },
                    tokenData: auth?.payload?.token
                }
                dispatch(getUserSettingsApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setUserSettingData(obj?.payload)
                        setLoading(false)
                    })
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getUserSettingHandle = () => {
        let data = {
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                app_name: 'pos'
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getUserSettingsApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setUserSettingData(obj?.payload)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    const productAvailabilityData = () => {
        let params = {
            token: auth?.payload?.token,
            sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
        }
        setLoading(true)
        dispatch(getAddressApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setProductAvailability(obj?.payload ? obj?.payload[0] : [])
                if (obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 1)) {
                    let startTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 1)?.opening_time
                    let endTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 1)?.closing_time
                    setStartMondayTime(convertTo24HourFormat(startTime));
                    setEndMondayTime(convertTo24HourFormat(endTime))
                    if (startTime != "") {
                        businessHours[0] = "monday"
                    }
                }
                if (obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 2)) {
                    let startTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 2)?.opening_time
                    let endTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 2)?.closing_time
                    setStartTuesdayTime(convertTo24HourFormat(startTime));
                    setEndTuesdayTime(convertTo24HourFormat(endTime))
                    if (startTime != "") {
                        businessHours[1] = "tuesday"
                    }
                }
                if (obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 3)) {
                    let startTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 3)?.opening_time
                    let endTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 3)?.closing_time
                    setStartWednesdayTime(convertTo24HourFormat(startTime));
                    setEndWednesdayTime(convertTo24HourFormat(endTime))
                    if (startTime != "") {
                        businessHours[2] = "wednesday"
                    }
                }
                if (obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 4)) {
                    let startTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 4)?.opening_time
                    let endTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 4)?.closing_time
                    setStartThursdayTime(convertTo24HourFormat(startTime));
                    setEndThursdayTime(convertTo24HourFormat(endTime))
                    if (startTime != "") {
                        businessHours[3] = "thursday"
                    }
                }
                if (obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 5)) {
                    let startTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 5)?.opening_time
                    let endTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 5)?.closing_time
                    setStartFridayTime(convertTo24HourFormat(startTime));
                    setEndFridayTime(convertTo24HourFormat(endTime))
                    if (startTime != "") {
                        businessHours[4] = "friday"
                    }
                }
                if (obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 6)) {
                    let startTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 6)?.opening_time
                    let endTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 6)?.closing_time
                    setStartSaturdayTime(convertTo24HourFormat(startTime));
                    setEndSaturdayTime(convertTo24HourFormat(endTime))
                    if (startTime != "") {
                        businessHours[5] = "saturday"
                    }
                }
                if (obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 7)) {
                    let startTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 7)?.opening_time
                    let endTime = obj?.payload[0]?.product_availability?.availability?.find((day) => day.day == 7)?.closing_time
                    setStartSundayTime(convertTo24HourFormat(startTime));
                    setEndSundayTime(convertTo24HourFormat(endTime))
                    if (startTime != "") {
                        businessHours[6] = "sunday"
                    }
                }
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getUserSettingHandle()
        productAvailabilityData()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Retail Order";
    }, []);

    return (
        <>
            <DashboardNavbar title="Retail Orders" backShow={false} />
            {loading ? <span className="spinner-border inner-spin spinner-border-sm"></span> :
                <div className='retailOrderSection'>
                    <div className='retailGeneral'>
                        <h4 className='blacksemiHead_ m-0 fw-bold'>General</h4>
                        <p className='salePara'>Use these settings to define plugin general settings and default settings for your online orders.</p>
                        <div className='retailOrderStatus'>
                            <div className='defaultOrder w-100'>
                                <h4 className='retailText m-0'>Default Online Order Status</h4>
                                <img src={darkInfo} alt='InfoImg' className='img-fluid' />
                            </div>
                            <select autoComplete="new-password" className='retailSelect select w-100' value={userSettingData?.default_online_order_status} onChange={(e) => handleDefaultOnlineOrder(e)} >
                                {/* <option value="operations" disabled>Feature Shop</option> */}
                                <option value="1">Automatic</option>
                                <option value="0">Manual</option>
                                {/* <option value="2">Both</option> */}
                            </select>
                        </div>

                    </div>
                    <div className='retailGeneral'>
                        <h4 className='blacksemiHead_ m-0 fw-bold'>Advance Settings*</h4>
                        <p className='salePara'>Use these settings to define plugin general settings and default settings for your online orders.</p>
                        <div className='retailSlots'>
                            <div className='advanceSetting'>
                                <h4 className='semiboldHeading_  m-0'> Large Order Settings</h4>
                                <p className='callDate_ mt-1 '>Set a quantity limit to prevent unexpected large pickup or delivery orders.Include a custom message explaining how to place orders that are over the quantity limit.</p>
                            </div>
                            <div className="form-check form-switch ms-3">
                                <input
                                    checked={userSettingData?.large_order_setting}
                                    onChange={(e) => handleCheckBox(e.target.checked, "largeOrder", userSettingData?.large_order_setting ? "nocalled" : "called")}
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <div className='retailSlots'>
                            <div className='advanceSetting'>
                                <h4 className='semiboldHeading_  m-0'> Enable Pre-Order</h4>
                                <p className='callDate_ mt-1 '>Clients can Pre-Order for a desire Product.</p>
                            </div>
                            <div className="form-check form-switch ms-3">
                                <input
                                    checked={userSettingData?.enable_pre_order}
                                    onChange={(e) => handleCheckBox(e.target.checked, "preOrder", true)}
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        {/* <div className='retailSlots'>
                            <div className='advanceSetting'>
                                <h4 className='semiboldHeading_  m-0'> Enable 24/7 Online Orders</h4>
                                <p className='callDate_ mt-1 '>Clients can place orders 24/7</p>
                            </div>
                            <div className="form-check form-switch ms-3">
                                <input
                                    checked={userSettingData?.enable_24_7_online_orders}
                                    onChange={(e) => handleCheckBox(e, "24/7Order")}
                                    type="checkbox"
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className='retailGeneral'>
                        <div className="row justify-content-space-around align-items-center">
                            <div className='col md-6'>
                                <h4 className='blacksemiHead_ m-0 fw-bold'>Working Hours & Days Off Settings</h4>
                            </div>
                            <div className='col md-6 text-end'>
                                <button type='submit' className='submitBtn' onClick={() => handleUpdate()} disabled={buttonLoading}>
                                    {buttonLoading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Update</span>
                                </button>
                            </div>
                        </div>
                        {/* <div className='retailCustomTable table-responsive'> */}
                        {/* <table class="table table-bordered table-striped roundedTable m-0">
                                <tr>
                                    <th>
                                        <div className='dateapoint '>
                                            Day
                                        </div>
                                    </th>
                                    <th>
                                        <div className='dateapoint'>
                                            Open Time
                                        </div>
                                    </th>
                                    <th>
                                        <div className='dateapoint'>
                                            Close Time
                                        </div>
                                    </th>
                                    <th>
                                        <div className='varientImgBtn '>Apply Same Time </div>
                                    </th>

                                </tr>
                                <tr>
                                    <td>
                                        <div className='checkView me-3'>
                                            <input type="checkbox" className='checkBx' />
                                            <label htmlFor="register" className=' mainlightPhragraph '>Monday </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start'>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start'>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='checkView text-center'>
                                            <input type="checkbox" className='checkBx' />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='checkView me-3'>
                                            <input type="checkbox" className='checkBx' />
                                            <label htmlFor="register" className=' mainlightPhragraph '>Tuesday</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='checkView text-center'>
                                            <input type="checkbox" className='checkBx' />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='checkView me-3'>
                                            <input type="checkbox" className='checkBx' />
                                            <label htmlFor="register" className=' mainlightPhragraph '>Wednesday</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='checkView text-center'>
                                            <input type="checkbox" className='checkBx' />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='checkView me-3'>
                                            <input type="checkbox" className='checkBx' />
                                            <label htmlFor="register" className=' mainlightPhragraph '>Thursday</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='checkView text-center'>
                                            <input type="checkbox" className='checkBx' />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='checkView me-3'>
                                            <input type="checkbox" className='checkBx' />
                                            <label htmlFor="register" className=' mainlightPhragraph '>Friday</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='checkView text-center'>
                                            <input type="checkbox" className='checkBx' />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='checkView me-3'>
                                            <input type="checkbox" className='checkBx' />
                                            <label htmlFor="register" className=' mainlightPhragraph '>Saturday</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start'>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='checkView text-center'>
                                            <input type="checkbox" className='checkBx' />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='checkView me-3'>
                                            <input type="checkbox" className='checkBx' />
                                            <label htmlFor="register" className=' mainlightPhragraph '>Sunday</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='openTime'>
                                            <h4 className='salePara text-start '>00:00</h4>
                                            <select autoComplete="new-password" className='timeSelect select' >
                                                <option value="0">AM</option>
                                                <option value="1">PM</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='checkView text-center'>
                                            <input type="checkbox" className='checkBx' />
                                        </div>
                                    </td>
                                </tr>
                            </table> */}
                        <div className='checkboxGroup businessChekbox mt-4 d-block'>
                            {dayList?.map((item, index) => {
                                return (
                                    <div className='checkboxInputs' key={index}>
                                        <input type="checkbox" id={item.day} className='checkBox' name={item.day} value={item.day}
                                            onChange={(e) => handleCheckboxChange1(e)} checked={businessHours?.includes(item.day)} />
                                        <label htmlFor={item.day} className='checkBoxTxt'>{item.value}</label>
                                        {
                                            businessHours?.includes(item.day) &&
                                            <>
                                                <div style={{ display: "flex" }} className='timerBox_'>
                                                    <div style={{ display: "flex" }} className='innertimedate_'>
                                                        <p className='timehead_ me-2'>Start Time : </p>
                                                        <TimePicker
                                                            onChange={(e) => handleStartTimeChange(e, item.day)}
                                                            value={item.id == 1 ? startMondayTime : item.id == 2 ? startTuesddayTime : item.id == 3 ? startWednesdayTime : item.id == 4 ? startThursdayTime : item.id == 5 ? startFridayTime : item.id == 6 ? startSaturdayTime : startSundayTime}
                                                            format="h:mm a"
                                                            showLeadingZeros={false}
                                                            amPmAriaLabel="Select AM/PM"
                                                            className="custom-time-picker customPicker"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }} className='timerBox_'>
                                                        <p className='timehead_ me-2'>End Time : </p>
                                                        <TimePicker
                                                            onChange={(e) => handleEndTimeChange(e, item.day)}
                                                            value={item.id == 1 ? endMondayTime : item.id == 2 ? endTuesdayTime : item.id == 3 ? endWednesdayTime : item.id == 4 ? endThursdayTime : item.id == 5 ? endFridayTime : item.id == 6 ? endSaturdayTime : endSundayTime} // Use the 12-hour formatted time string directly
                                                            format="h:mm a" // Specify the format as 12-hour with AM/PM                                              
                                                            className="custom-time-picker customPicker"
                                                        />
                                                    </div>
                                                </div>
                                                <Dropdown onClick={() => { handleCopy(item.id) }}>
                                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                        <label className="iconcopy_ mb-0"> <i className="fa-solid fa-copy"></i> </label>
                                                    </Dropdown.Toggle>
                                                    <div className='timedatecoprBox_'>
                                                        <Dropdown.Menu>
                                                            <Form>
                                                                {options.map((option) => (
                                                                    <Form.Check
                                                                        key={option.id}
                                                                        type="checkbox"
                                                                        label={option.label}
                                                                        id={`option-${option.id}`}
                                                                        checked={selectedOptions.find(val => val?.id == option?.id)}
                                                                        onChange={() => handleCheckboxChange2(option, item.id)}
                                                                    />
                                                                ))}
                                                            </Form>
                                                        </Dropdown.Menu>
                                                    </div>
                                                </Dropdown>
                                            </>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        {/* </div> */}
                        <div className='retailSlots'>
                            <div className='retailSlotSub'>
                                <h4 className='semiboldHeading_  m-0'>All USA Holidays are include as Days off</h4>
                                <img src={darkInfo} alt='InfoImg' className='img-fluid' />
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    checked={userSettingData?.usa_holidays_as_days_off}
                                    onChange={(e) => handleCheckBox(e.target.checked, "usaHolidays", true)}
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    </div>
                </div>}



            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop={true}
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "viewGift"
                        ? "ViewGift"
                        : modalDetail.flag === "MakeGift"
                            ? "createOffer"
                            : modalDetail.flag === "editGift"
                                ? "editGift"
                                : ""
                }
                size={
                    "md"
                }
                child={
                    modalDetail.flag === "LargeOrderQuantity" ? (
                        <LargeOrderQuantity
                            close={() => handleOnCloseModal()}
                            setQty={setQty}
                            qty={qty}
                            handleCheckBox={(e, name, isToggle) => handleCheckBox(e, name, isToggle)}
                        />
                    ) : (
                        ""
                    )
                }
                header={
                    <div className="modalHeader_">
                        <div className="common_">
                            <h2 className="modalHeading_">
                                Large Order Quantity Limit{" "}
                            </h2>

                            <button className="closeButton">
                                <i
                                    className="las la-times"
                                    onClick={() => handleOnCloseModal()}
                                ></i>
                            </button>
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />

        </>
    )
}

export default RetailOrder