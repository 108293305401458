import React from 'react'
// import { barcode, brandLogo, map, singleLine, OrderDown, unselectedOrder } from '../../../../utilities/images'
import DashboardNavbar from '../dashboard/DashboardNavbar'
import {barcode, brandLogo, map, OrderDown, unselectedOrder} from '../../../utilities/images'

const TrackingOrder = () => {
    return (
        <>
            <div className='trackingOrder rightWrapper headerWrap'>
                <DashboardNavbar title="Orders#890000 " backShow={false} />
                <div className='consumerOrder'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6'>
                                <div className='walletPurchaseSection orderDetails_'>
                                    <div className='walletAddress'>
                                        <h4 className='expectedHeading'>Primark</h4>
                                        <p className='walletHeading mt-3'>63 Ivy Road, Hawkville, GA, USA 31036</p>
                                        <h4 className='walletHeading mt-2'>+123-456-7890</h4>
                                    </div>
                                    <div className='walletPurchaseTable'>
                                        <div className='purchaseTableSub'>
                                            <div className='walletFlex'>
                                                <h4 className='profileboldHead'>1</h4>
                                                <div className='orderDimension'>
                                                    <h4 className='orderDimensionText mb-1'>Marlboro Red Gold</h4>
                                                    <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4>
                                                </div>
                                            </div>
                                            <h4 className='profileboldHead'>$6.56</h4>
                                        </div>
                                        <div className='purchaseTableSub'>
                                            <div className='walletFlex'>
                                                <h4 className='profileboldHead'>1</h4>
                                                <div className='orderDimension'>
                                                    <h4 className='orderDimensionText mb-1'>Marlboro Red Gold</h4>
                                                    <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4>
                                                </div>
                                            </div>
                                            <h4 className='profileboldHead'>$6.56</h4>
                                        </div>
                                        <div className='purchaseTableSub'>
                                            <div className='walletFlex'>
                                                <h4 className='profileboldHead'>5</h4>
                                                <div className='orderDimension'>
                                                    <h4 className='orderDimensionText mb-1'>Marlboro Red Gold</h4>
                                                    <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4>
                                                </div>
                                            </div>
                                            <h4 className='profileboldHead'>$6.56</h4>
                                        </div>
                                        <div className='purchaseTableSub'>
                                            <div className='walletFlex'>
                                                <h4 className='profileboldHead'>1</h4>
                                                <div className='orderDimension'>
                                                    <h4 className='orderDimensionText mb-1'>Marlboro Red Gold</h4>
                                                    <h4 className='dimensionPara m-0'>Color: Grey  <span className='sizeDimension ms-2'>Size: Grey</span></h4>
                                                </div>
                                            </div>
                                            <h4 className='profileboldHead'>$6.56</h4>
                                        </div>
                                    </div>
                                    <div className='totalPurchaseOrder'>
                                        <div className='totalOrderSub'>
                                            <h4 className='totalOrderText mb-0'>Sub-Total</h4>
                                            <h4 className='walletHeading mb-0'>$7,363.20</h4>
                                        </div>
                                        <div className='totalOrderSub'>
                                            <h4 className='totalOrderText mb-0'>Discount ( MIDApril100)</h4>
                                            <h4 className='walletHeading mb-0'>$7,363.20</h4>
                                        </div>
                                        <div className='totalOrderSub'>
                                            <h4 className='totalOrderText mb-0'>Shipping Charrg</h4>
                                            <h4 className='walletHeading mb-0'>$7,363.20</h4>
                                        </div>
                                        <div className='totalOrderSub totalOrderMain '>
                                            <h4 className='totalOrderText mb-0'>Total</h4>
                                            <h4 className='walletHeading mb-0'>$7001.20</h4>
                                        </div>
                                    </div>
                                    <div className='paymentoptionSection'>
                                        <h4 className='profileboldHead'>Payment option:<span className='headingjobr ms-2'>Card</span></h4>
                                        <h4 className='subtextSmallblack_ '>Wed 26 Apr , 2023<span className='subtextSmallblack_  ms-2'>6:27 AM</span></h4>
                                        <h4 className='subtextSmallblack_ '>Walk-In</h4>
                                        <h4 className='subtextSmallblack_ '>Invoice No.. # 3467589</h4>
                                        <h4 className='subtextSmallblack_ '>POS No. #Front-CC013</h4>
                                        <h4 className='subtextSmallblack_ '>User ID : ****128</h4>
                                    </div>
                                    <div className='walletfooterModal'>
                                        <h2 className='modalFooterText'>Thank You</h2>
                                        <img src={barcode} className='img-fluid' alt='barcodeImage' />
                                        <img src={brandLogo} className='img-fluid brandImg_' alt='barcodeImage' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-6 pe-0'>
                                <div className='consumerMap'>
                                    <img src={map} className='comsumermapImg' alt='map' />
                                </div>
                                <div className="deliveryStatus">
                                    <div className="statusHead stausDown">
                                        <div className="statusLeft">
                                            <h3>Order status</h3>
                                            <h6>Assigning driver</h6>
                                        </div>
                                        <figure>

                                            <img src={OrderDown} alt="downSelect" />
                                        </figure>
                                    </div>
                                    <div className='orderStatus '>
                                        <div className="readyPickupstatus">
                                            <figure>
                                                <img src={unselectedOrder} alt="pickup" />
                                            </figure>
                                            <article>
                                                <h3>verifycode</h3>
                                                <h6>........</h6>
                                            </article>
                                        </div>
                                        <div className="readyPickupstatus">
                                            <figure>
                                                <img src={unselectedOrder} alt="verifycode" />
                                            </figure>
                                            <article>
                                                <h3>delivery</h3>
                                                {/* <h6>waiting time 10 mins</h6> */}
                                            </article>
                                        </div>
                                        {/* <div className="readyPickupstatus">
                        <figure>
                          <img src={pickuporder} alt="pickup" />
                        </figure>
                        <article>
                          <h3>Next to your block</h3>
                          <h6>with in 10 minutes</h6>
                        </article>
                      </div> */}
                                        <div className="readyPickupstatus pickupBtnDetails">
                                            <div className='pickupBtnDetails'>
                                                <figure>
                                                    <img src={unselectedOrder} alt="verifyorder" />
                                                </figure>
                                                <article>
                                                    <h3>Product Pickup</h3>
                                                    {/* <h6>with in 10 minutes</h6> */}
                                                </article>
                                            </div>
                                            <div className='pickupBtn'>
                                                <button className='countbtn'>659X</button>
                                            </div>
                                        </div>
                                        <div className="readyPickupstatus">
                                            <figure>
                                                <img src={unselectedOrder} alt="pickup" />
                                            </figure>
                                            <article>
                                                <h3>Assign Driver</h3>
                                                {/* <h6>with in 10 minutes</h6> */}
                                            </article>
                                        </div>
                                        <div className="readyPickupstatus">
                                            <figure>
                                                <img src={unselectedOrder} alt="pickup" />
                                            </figure>
                                            <article>
                                                <h3>Ready to pickup</h3>
                                                {/* <h6>with in 10 minutes</h6> */}
                                            </article>
                                        </div>
                                        <div className="readyPickupstatus">
                                            <figure>
                                                <img src={unselectedOrder} alt="pickup" />
                                            </figure>
                                            <article>
                                                <h3>Order accepted</h3>
                                                {/* <h6>with in 10 minutes</h6> */}
                                            </article>
                                        </div>

                                        {/* <div className="carrierbox">
                          <h5>Carrier:</h5>
                          <div className="shippingContact carriercontact">
                            <div className="shipcontactLeft">
                              <img className='me-2' src={singleOrderData?.driverDetails?.profile_photo ? singleOrderData?.driverDetails?.profile_photo : profileImg1} />
                              <div className="contctInfo">
                                <h4>{singleOrderData?.driverDetails?.user_profiles?.firstname + " " + singleOrderData?.driverDetails?.user_profiles?.lastname}</h4>
                              </div>
                            </div>
                            <button className="contactBtn">
                              <i className="las la-phone"></i>
                              <h3>Contact<br />{singleOrderData?.driverDetails?.user_profiles?.full_phone_number}</h3>
                            </button>
                          </div>
                        </div> : */}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default TrackingOrder