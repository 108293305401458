import React, { useEffect, useState } from "react";
import { infoImg } from "../../../utilities/images";
import { CKEditor } from "ckeditor4-react";
import {
  getCategoriesAsync,
  getServicesAsync,
} from "../dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
const ServiceDetails = (props) => {
  let productResponse;
  const location = useLocation();
  // const selectedProductDtails = location?.state;
  const selectedProductDtails = props?.data;

  const [serviceName, setServiceName] = useState(
    selectedProductDtails?.name ? selectedProductDtails?.name : ""
  );

  const [serviceDescription, setServiceDescription] = useState(
    selectedProductDtails?.description ? selectedProductDtails?.description : ""
  );

  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterCategoryId, setMasterCategoryId] = useState("");
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [subCategoryId, setSubcategoryId] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  const dispatch = useDispatch();

  const handleProductName = (e) => {
    setServiceName(e.target.value);
    productResponse = {
      serviceName: e.target.value,
      masterCategoryId: masterCategoryId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
    };
    props.servicesDetails(productResponse, "");
  };

  const handleKeyDownSubcategory = (event) => {
    if (event.key === "Enter") {
      const newValue = event.target.value.trim();
      if (newValue !== "") {
        // Check if the entered value already exists
        const existingOption = subCategory?.find(
          (option) => option.label.toLowerCase() === newValue.toLowerCase()
        );
        if (!existingOption) {
          const newOption = { value: newValue.toLowerCase(), label: newValue };
          if (subCategory?.length > 0) {
            setSubCategory([...subCategory, newOption]);
          } else {
            setSubCategory([newOption]);
          }
        }
      }
    }
  };

  const handleServicesChange = (e) => {
    setCategoryLoading(true);
    setMasterCategoryId(e.value);
    setSelectedService(e);
    setSelectedCategory("");
    setSelectedSubCategory("");
    setCategory([]);
    setCategoryId("");
    setSubCategory([]);
    setSubcategoryId("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        service_id: e.value,
        main_category: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);

        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setCategory(filterResult);
        setCategoryLoading(filterResult ? false : true);
        productResponse = {
          serviceName: serviceName,
          masterCategoryId: e?.value,
          category_id: categoryId,
          sub_category_id: subCategoryId,
        };
        props.servicesDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleCategoryChange = (e) => {
    setSubCategoryLoading(true)
    setCategoryId(e.value);
    setSubCategory([]);
    setSubcategoryId("");
    setSelectedCategory(e);
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        need_subcategory: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setSubCategory(filterResult);
        setSubCategoryLoading(filterResult ? false : true)
        productResponse = {
          serviceName: serviceName,
          masterCategoryId: masterCategoryId,
          category_id: e.value,
          sub_category_id: subCategoryId,
        };
        props.servicesDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleSubCategoryChange = (e) => {
    setSubcategoryId(e.value);
    setSelectedSubCategory(e);
    productResponse = {
      serviceName: serviceName,
      masterCategoryId: masterCategoryId,
      category_id: categoryId,
      sub_category_id: e.value,
    };
    props.servicesDetails(productResponse, "");
  };

  const handleProductDescription = (e) => {
    props.servicesDetails(e, "description");
    setServiceDescription(e);
  };

  const getEditServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "service",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        let selectedMastercategory =
          selectedProductDtails?.service_id.toString();
        setMasterCategoryId(
          selectedMastercategory ? selectedMastercategory : null
        );
        let selectedService = filterResult.find(
          (item) => item.value == selectedMastercategory
        );
        setSelectedService(selectedService ? selectedService : null);
        setMasterCategory(filterResult);
        let data = {
          tokenData: auth?.payload?.token,
          postData: {
            service_id: selectedMastercategory,
            main_category: true,
            check_product_existance: false,
          },
        };
        setLoading(true);
        dispatch(getCategoriesAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            setLoading(false);

            let filterResult = obj?.payload?.data?.map((item) => {
              return {
                value: item?.id,
                label: item?.name,
              };
            });
            setCategory(filterResult);

            let selectedCategory =
              selectedProductDtails?.category_id.toString();
            setCategoryId(selectedCategory ? selectedCategory : null);
            let selectedCatrgory = filterResult.find(
              (item) => item.value == selectedCategory
            );
            setSelectedCategory(selectedCatrgory ? selectedCatrgory : null);
            let data = {
              tokenData: auth?.payload?.token,
              postData: {
                category_id: selectedCategory,
                need_subcategory: true,
                check_product_existance: false,
              },
            };
            setLoading(true);
            dispatch(getCategoriesAsync(data))
              .then(unwrapResult)
              .then((obj) => {
                setLoading(false);
                let filterResult = obj?.payload?.data?.map((item) => {
                  return {
                    value: item?.id,
                    label: item?.name,
                  };
                });
                setSubCategory(filterResult);
                let selectedSubCategory =
                  selectedProductDtails?.sub_category_id.toString();
                setSubcategoryId(
                  selectedSubCategory ? selectedSubCategory : null
                );

                let selectedsubCatrgory = filterResult.find(
                  (item) => item.value == selectedSubCategory
                );
                setSelectedSubCategory(
                  selectedsubCatrgory ? selectedsubCatrgory : null
                );
              })
              .catch((obj) => {
                setLoading(false);
              });
          })
          .catch((obj) => {
            setLoading(false);
          });
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getEditServices();
  }, []);

  return (
    <>
      <div className="productDetailsContainer">
        <div className="ps-30 pe-30">
          <h2 className="headingBlue">
            Service Details
            <img src={infoImg} alt="InfoImg" className="infoIcon ms-3" />
          </h2>
        </div>
        {/* Start ProductName Area */}
        <div className="productDetailsSpc mt-4">
          <form className="productName">
            <div className="form-group">
              <label className="subheaderHeading mb-2">Service Name</label>
              <div className="inputGroup">
                <input
                  className="customform-control customInput"
                  placeholder="Service name"
                  name="text"
                  type="text"
                  value={serviceName}
                  onChange={(e) => handleProductName(e)}
                />
              </div>
            </div>
          </form>
        </div>
        {/* End ProductName Area */}
        {/* Start ProductCategory Area */}
        <div className="productCategoryArea mt-4 mb-4">
          <form className="ProductCategory row">
            <div className="mb-5 form-group col-md-12">
              <label className="deatilsHead mb-3 fw-normal">
                Select Master Category
              </label>
              {/* <select className='customform-control select bg-white' value={masterCategoryId} defaultValue={"Select master category"} onChange={(e) => handleServicesChange(e)}>
                                <option value="" disabled>Select Master Category</option>
                                {
                                    (masterCategory && masterCategory.length > 0) ?
                                        <>
                                            {masterCategory?.map((value, index) => {
                                                return (
                                                    <option value={value?.id} key={index}>{value?.name}</option>
                                                )
                                            })}
                                        </> : <></>
                                }
                            </select> */}

              <Select
                value={selectedService}
                onChange={handleServicesChange}
                options={masterCategory}
                isSearchable={true}
                placeholder="Select Services"
              />
            </div>
            <div className="mb-5 form-group col-md-6">
              <label className="deatilsHead mb-3 fw-normal">
                Select Category
              </label>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                options={category}
                isDisabled={!masterCategoryId}
                isSearchable={true}
                placeholder={
                  category && categoryLoading ? "Loading Categories..." : "Select Category"
                }
              />
            </div>
            <div className=" form-group col-md-6">
              <label className="deatilsHead mb-3 fw-normal">
                Select Sub Category
              </label>

              <Select
                value={selectedSubCategory}
                onChange={handleSubCategoryChange}
                onKeyDown={handleKeyDownSubcategory}
                options={subCategory}
                isDisabled={!categoryId}
                isSearchable={true}
                placeholder={
                  subCategory && subCategoryLoading
                    ? "Loading Categories..."
                    : "Select Sub Category"
                }
              />
            </div>
          </form>
        </div>
        {/* End ProductCategory Area */}
        {/* Start CK EditorArea */}
        <div className="productDetailsSpc">
          <CKEditor
            initData={serviceDescription}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
                "image",
              ],
              versionCheck: false
            }}
            name="editor"
            readOnly={false}
            onChange={(event, editor) => {
              const data = event.editor.getData();
              handleProductDescription(data);
            }}
            type="classic"
          />
        </div>
        {/* End CK EditorArea */}
      </div>
    </>
  );
};

export default ServiceDetails;
