import React, { useEffect } from 'react'
import { shareMap, calendar } from '../../../../utilities/images';
import DatePicker from "react-datepicker";
import { useState } from 'react'
import moment from 'moment-timezone'
import products from "../../../db/category.json"
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import CustomModal from '../../../components/shared/CustomModal';
import InstantwithdrawModal from '../instantwithdrawModal';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { getWalletBallanceApiAsync, getWithdrawalDataApiAsync } from '../../dashboard/dashboardSlice';
import DashboardNavbar from '../../dashboard/DashboardNavbar';

const WithDrawal = () => {
  const history = useHistory()
  const [enterprisesDate, setEnterprisesDate] = useState("")
  const [key, setKey] = useState(Math.random());
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const [withdrwalData, setWithdrwalData] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const auth = useSelector(selectLoginAuth)
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleRowClick = (e) => {
    setSelectedValue(e)
    setModalDetail({ show: true, flag: "InstantWithdraw" });
    setKey(Math.random());
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };
  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
  };
  const columns1 = [
    // {
    //   name: '#',
    //   width: "50px",
    //   selector: (row, i) => i + 1
    // },
    {
      name: "Date",
      selector: row => {
        return (
          <>
            <span onClick={() => handleRowClick(row)} className='textParagh '>{moment(row?.createdAt).format("MM/DD/YYYY | hh:mm a")}</span>
            {/* <span onClick={() => handleRowClick(row)} className='prop_span'>{moment(row?.createdAt).format("HH:mm")}</span> */}
          </>
        );
      },
      width: "200px",
    },
    {
      selector: row => {
        return (
          <>
            <span onClick={() => handleRowClick(row)} className='textParagh'>{row?.bank_details?.bank_name}</span>
          </>
        );
      },
      name: 'Requested at',
    },
    // {
    //   name: 'Transaction id',
    //   selector: row => {
    //     return (
    //       <>
    //         <span onClick={() => handleRowClick(row)} className='textParagh '>{row?.transaction_id?.length > 20 ? row?.transaction_id?.substring(0, 20) + "..." : row?.transaction_id}</span>
    //       </>
    //     );
    //   },
    // },
    {
      selector: row => {
        return (
          <>
            <span onClick={() => handleRowClick(row)} className='textParagh'>{row?.type}</span>
          </>
        );
      },
      name: 'Details',
    },
    {
      name: 'Amount',
      selector: row => {
        return (
          <>
            <span onClick={() => handleRowClick(row)} className='textParagh '>JBR {row?.amount}</span>
          </>
        );
      },
    },

    // {
    //   selector: row => {
    //     return (
    //       <>
    //         <span onClick={() => handleRowClick(row)} className='textParagh'>{row?.amount / 100}</span>
    //       </>
    //     );
    //   },
    //   name: 'Converted Amount',
    //   width: "180px",
    // },

    // {
    //   selector: row => {
    //     return (
    //       <>
    //         <span onClick={() => handleRowClick(row)} className='textParagh'>{row?.fee}</span>
    //       </>
    //     );
    //   },
    //   name: 'Fees',
    //   width: "100px",
    // },
    // {
    //   selector: row => {
    //     return (
    //       <>
    //         <span onClick={() => handleRowClick(row)} className='textParagh'>{row?.amount_received?.toFixed(2)}</span>
    //       </>
    //     );
    //   },
    //   name: 'Amount Received',
    //   width: "180px",

    // },
    {
      selector: row => {
        return (
          <>
            <span onClick={() => handleRowClick(row)} className='textParagh '>{moment(row?.updatedAt).format("MM/DD/YYYY")}</span>
          </>
        );
      },
      name: 'Processing date',

    },

    {
      selector: row => {
        return (
          <>
            <div onClick={() => handleRowClick(row)} className='ms-2 tableBtn__'>
              <Link to="#" className='innerBtn_'>{row?.status === 'success' ? 'Completed' : "Pending"}</Link>
              <button className='nextAction' type="text"></button>
            </div>
          </>
        );
      },
      name: 'Status',
    },
    {
      selector: row => {
        return (
          <>
            <div className='d-flex justify-space-between align-items-center pe-3 ps-3'>
              <div className='dropdown tableDropdown ms-3 '>
                <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                  <i className="fal fa-ellipsis-v icon_"></i>
                </span>
                {
                  row?.status === 'success' ? <></> :
                    <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                      <li className='dropdown-item' onClick={() => {
                        // setSelectedId(row?.user_id)
                        // setModalDetail({ show: true, flag: "deleteRoleModal" });
                        // setKey(Math.random());
                      }}>Cancel Withdrawal</li>
                    </div>}
              </div>
            </div>
          </>
        );
      },
      name: '',
      width: '100px'
    },
  ];

  const withDrawalDataHandle = () => {
    let params = {
      'tokenData': auth?.payload?.token,
      'postData': {
        page: currentPage,
        limit: perPage
      }
    }
    if (startDate != null && endDate != null) {
      let newData = {
        ...params.postData,
        "start_date": moment(startDate).format('YYYY-MM-DD'),
        "end_date": moment(endDate).format('YYYY-MM-DD'),
        "filter": 'custom'
      }
      params = {
        tokenData: auth?.payload?.token,
        postData: newData
      }
    }
    setLoading(true)
    dispatch(getWithdrawalDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setWithdrwalData(obj?.payload)
        setTotalRows(obj?.payload?.user_withdrawal_transactions?.total)
        setLoading(false)
      })
      .then((obj) => {
        setLoading(false)
      })
  }

  const walletBalanceHandle = () => {
    let data = {
      tokenData: auth?.payload?.token
    }
    setLoading(true)
    dispatch(getWalletBallanceApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setWalletBalance(obj?.payload?.sila_balance)
        setLoading(false)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    withDrawalDataHandle()
    walletBalanceHandle()
  }, [endDate, perPage, currentPage])

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Wallet";
  }, []);

  return (
    <div className='walletMain'>
      <DashboardNavbar title="Withdrawals" backShow={`/WalletMain`} />
      <div className='currency_information'>
        <div className='row'>
          <div className='col-xxl-8 col-lg-9'>
            <div className='withdrawInfo_'>
              <div className='table-responsive'>
                <table className='withdrawtable_'>
                  <thead>
                    <th><h5>Method</h5></th>
                    <th><h5>Descriptions </h5></th>
                    <th><h5>Fees </h5></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td><h5>JBR Transfer</h5></td>
                      <td><h5>The fastest method to withdraw funds, directly to your local bank account! Available in selected countries only.</h5></td>
                      <td><div className='feecontent_'>
                        <h5>No Fee</h5>
                        <button onClick={() => history.push(`/instantbankTransfer`)} className='primary_btnborder' type="submit">Withdraw</button>
                      </div></td>
                    </tr>
                    <tr>
                      <td><h5>Instant Bank Transfer</h5></td>
                      <td><h5>The fastest method to withdraw funds, directly to your local bank account! Available in selected countries only.</h5></td>
                      <td><div className='feecontent_'>
                        <h5>2.5%</h5>
                        <button onClick={() => history.push(`/instantbankTransfer`)} className='primary_btnborder' type="submit">Withdraw</button>
                      </div></td>
                    </tr>
                    <tr>
                      <td><h5>Normal Bank Transfer</h5></td>
                      <td><h5>The fastest method to withdraw funds, directly to your local bank account! Available in selected countries only.</h5></td>
                      <td><div className='feecontent_'>
                        <h5>No Fee</h5>
                        <button onClick={() => history.push(`/instantbankTransfer`)} className='primary_btnborder' type="submit">Withdraw</button>
                      </div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='col-xxl-4 col-lg-3'>
            <div className='currencyRight_'>
              <h4 className='semiboldHeading_'>Current Balance</h4>
              <h3 className='monsmianHead_'>JBR {walletBalance}</h3>
            </div>
          </div>
        </div>

      </div>
      <div className='chartsOuter withdrawtable_ mt-3'>
        <h5 className='subhaedHeading mb-3'>Pending Withdrawals</h5>

        <div className='table-responsive '>
          <DataTable
            pagination
            columns={columns1}
            data={withdrwalData?.user_withdrawal_transactions?.data}
            defaultSortFieldId={1}
            onRowClicked={handleRowClick}
            className='transection_datatable'
            customStyles={customStyles}
            paginationServer
            progressPending={loading}
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </div>
      </div>


      <div className='earnFunding_ mt-5'>
        <h5 className='subhaedHeading mb-3'>Withdrawing earned funds</h5>
        <h6 className='contentheadtext_ mb-3'>To file a withdrawal request:</h6>
        <ul>
          <li className='contentheadtext_'>1.  Make sure to complete the account verifications. This includes verifying your email, phone number, and KYC.</li>
          <li className='contentheadtext_'>2.  Click on your profile picture thumbnail from the main menu bar and click "Withdraw funds".</li>
          <li className='contentheadtext_'>3. Select your preferred withdrawal method to receive your earnings.</li>
          <li className='contentheadtext_'>4. Enter the amount and currency you want to withdraw, as well as the details of your bank account (if necessary).</li>
          <li className='contentheadtext_'>5. Review the withdrawal details and click the "Withdraw Funds" button.</li>

        </ul>
      </div>
      <div className='earnFunding_ mt-4'>
        <h5 className='subhaedHeading mb-3'>Things to Note:</h5>
        <ul className='thingtodo_'>
          <li className='contentheadtext_'> The Daily Withdrawal Requests feature allows a withdrawal request to be processed within one (1) business day after it has been verified. It does not refer to allowing users to file withdrawals daily or allowing withdrawals to be processed daily.</li>
          <li className='contentheadtext_'>All withdrawals are processed in our Sydney office, where processing is done only during business hours/days. They are not processed 24 hours daily.</li>
          <li className='contentheadtext_'>Requests lodged past 5PM EDT (New York) will be processed the next business day.</li>
          <li className='contentheadtext_'>For first withdrawals, it takes 15 days to clear your account before your withdrawal request is processed.</li>

        </ul>
      </div>











      {/* 
      Start TableFilter Area
      <div className='tableFilters mb-3'>
        <div className='row'>
          <div className='col-md-12'>
            <div className="calenderIcon">
              <img src={calendar} alt="calender" />
              <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date"/>
              <DatePicker
                className='cateDate'
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                placeholderText="Date"
              />
            </div>
          </div>
          <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        Country
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        State
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        ID type
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
        </div>
      </div>
      End TableFilter Area
      Start DataTable
      <div className='table-responsive'>
        <DataTable
          pagination
          columns={columns1}
          data={withdrwalData?.user_withdrawal_transactions?.data}
          defaultSortFieldId={1}
          onRowClicked={handleRowClick}
          className='transection_datatable'
          customStyles={customStyles}
          paginationServer
          progressPending={loading}
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </div>
      End DataTable
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "InstantWithdraw" ? "InstantwithdrawModal" : ""}
        ids={modalDetail.flag === "InstantWithdraw" ? "InstantwithdrawModal" : ""}
        header={
          <div className='modalHeader_'>
            <div className="common_">
              <h2 className="modalHeading">
                {selectedValue?.type}
              </h2>
              <button className="closeButton">
                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
              </button>
            </div>
          </div>
        }
        child={
          modalDetail.flag === "InstantWithdraw" ? (
            <InstantwithdrawModal selectedValue={selectedValue} close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      /> */}
    </div>
  )
}

export default WithDrawal;