import React, { useEffect, useState } from 'react'
import { emailBlue, phoneBlue } from '../../../../utilities/images'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../auth/authSlice'
import { getDepartmentDataInSupportApiAsync } from '../../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'

const AgentDeatailsModal = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const [departmentData, setDepartmentData] = useState([])

    useEffect(() => {
        dispatch(getDepartmentDataInSupportApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                let selectedDepartmentIds = props?.selectedRow?.user_departments?.map(v => v?.department_id)
                let departmentSelected = obj?.payload?.filter(value => selectedDepartmentIds?.includes(value?.id))?.map((v, i) => {
                    return v?.name
                })
                setDepartmentData(departmentSelected)
            }
            )
            .catch((obj) => {
            })
    }, [])
    return (
        <>
            <div className="scheduleModal">
                <div className="scheduleModalContent">
                    <div className="userProfileinfo_">
                        {/* <img className='scheduleimg me-3' src={props?.user?.user_profiles?.profile_photo ? props?.user?.user_profiles?.profile_photo : profileImg1} /> */}
                        <div className="userProfileinforight_">
                            <h3 className='subHeading'>{props?.selectedRow?.user_profiles?.firstname + " " + props?.selectedRow?.user_profiles?.lastname}</h3>
                            <p className='mainlightPhragraph mb-1'>
                                <img className='me-2' src={phoneBlue} alt="" />
                                {props?.selectedRow?.user_profiles?.full_phone_number}
                            </p>
                            <p className='mainlightPhragraph mb-1'>
                                <img className='me-2' src={emailBlue} alt="" />
                                {props?.selectedRow?.email}
                            </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className="appointmentDetails_">
                                {/* <h3>Department Details</h3> */}
                                <div className="appointInner mb-4">
                                    <div className="contentappont">
                                        <h5>Departments</h5>
                                        <span>{departmentData?.length > 0 ? departmentData?.map((v, i) => {
                                            return (<React.Fragment key={i}>
                                                {v + (i === departmentData?.length - 1 ? "" : ", ")}
                                            </React.Fragment>)
                                        }) : "No Service Selected"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgentDeatailsModal
