import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { getMyProductStatisticDataAsync } from './productSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const CategoryListtable = () => {
  const history = useHistory()
  const { flag, serviceType } = useParams();
  const [selectedProduct, setSelectedProduct] = useState({});
  const dispatch = useDispatch()
  const auth = useSelector(selectLoginAuth); 
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(Math.random());

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }
  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
  };
  const columns1 = [
    {
      name: '#',
      // selector: row => row?.category_id,
      selector: (row, i) => (i + 1  *(perPage * (currentPage - 1)) + 1),
      width: "100px"
    },
    {
      name: "Category Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center' onClick={()=>handleRowClick(row)}>
              <img className='userInnerImg' src={row?.image} />
              <div className='ms-2'>
                <span className='textParagh '>{row?.category_name?.length > 20 ? row?.category_name?.substring(0, 20) + "..." : row?.category_name}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: 'Sub-category',
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={()=>handleRowClick(row)}>{row?.sub_category_listed}</span>
          </>
        );
      },
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={()=>handleRowClick(row)}>{row?.brand}</span>
          </>
        );
      },
      name: 'Brand',
    },

    {
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={()=>handleRowClick(row)}>{row?.product_listed}</span>
          </>
        );
      },
      name: 'Product',
    },

    

  ];
  const columns2 = [
    {
      name: '#',
      // selector: row => row?.sub_category_id,
      selector: (row, i) => (i + 1  *(perPage * (currentPage - 1)) + 1),
      width: "100px"
    },
    {
      name: "Sub-Categories Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center' onClick={()=>handleRowClick(row)}>
              <img className='userInnerImg' src={row?.image} />
              <div className='ms-2'>
                <span className='textParagh '>{row?.sub_category_name?.length > 20 ? row?.sub_category_name?.substring(0, 20) + "..." : row?.sub_category_name}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: 'Category',
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={()=>handleRowClick(row)}>{row?.category_name?.length > 20 ? row?.category_name?.substring(0, 20) + "..." : row?.category_name}</span>
          </>
        );
      },
    },

    {
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={()=>handleRowClick(row)}>{row?.brand}</span>
          </>
        );
      },
      name: 'Brand Listed'
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={()=>handleRowClick(row)}>{row?.product_listed}</span>
          </>
        );
      },
      name: 'Product Listed'
    }

  ];
  const columns3 = [
    {
      name: '#',
      selector: row => row?.id,
      width: "100px"
    },
    {
      name: "Brand Name",
      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center' onClick={()=>handleRowClick(row)}>
              <img className='userInnerImg' src={row?.image} />
              <div className='ms-2'>
                <span className='textParagh '>{row?.name?.length > 20 ? row?.name?.substring(0, 20) + "..." : row?.name}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: 'Category',
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={()=>handleRowClick(row)}>{row?.category_name?.length > 20 ? row?.category_name?.substring(0, 20) + "..." : row?.category_name}</span>
          </>
        );
      },
    },


    {
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={()=>handleRowClick(row)}>{row?.subcategory_name?.length > 20 ? row?.subcategory_name?.substring(0, 20) + "..." : row?.subcategory_name}</span>
          </>
        );
      },
      name: 'Sub Category'
    },

    {
      selector: row => {
        return (
          <>
            <span className='textParagh ' onClick={()=>handleRowClick(row)}>{row?.product_count}</span>
          </>
        );
      },
      name: 'Product Listed'
    },

  ];

  const handleRowClick = (e) => {
    let id = ""
    flag === "category" ? id = e?.category_id : flag === "sub_category" ? id = e?.sub_category_id : id = e?.id
    history.push(`/categoryDetail/${flag}/${serviceType}/${id}`)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  }

  const getCategoryList = (page) => {
    setLoading(true)
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        type: flag,
        // app_name: "merchant",
        page: page ? page : currentPage,
        limit: perPage,
        filter: "year",
        service_type: serviceType
      }
    }
    dispatch(getMyProductStatisticDataAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setCategoryList(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getCategoryList();
  }, [currentPage, perPage, flag, serviceType]);
  return (
    <section className='recentTransections mt-1'>
      {/* <h3 className='textInnerHead mb-4'>Recent Orders</h3> */}
      <DataTable
        columns={flag === "category" ? columns1 : flag === "sub_category" ? columns2 : columns3}
        data={categoryList}
        progressPending={loading}
        defaultSortFieldId={1}
        customStyles={customStyles}
        onRowClicked={(e) => handleRowClick(e)}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </section>
  )
}

export default CategoryListtable