import React from 'react'

const FaqViewModal = (props) => {

    console.log(props?.selectedData,"dssssssssssssssssss");
    return (
        <div className='addnewfaqModal'>
            <form className='emplyoeeDetails horizontalForms'>
                <div className='row'>
                    <div className='form-group mb-4'>
                        <label className='labelTxt mb-3' htmlFor='amount'>Category Name:</label>
                        <p>{props?.selectedData?.category_name}</p>
                    </div>
                    <div className='form-group col-md-12  mb-4'>
                        <label className='labelTxt mb-3' htmlFor='amount'>Category details: </label>
                        <p>{props?.selectedData?.category_detail}</p>
                    </div>
                    <div className='form-group col-md-12  mb-4'>
                        <label className='labelTxt mb-3' htmlFor='amount'>Category tags:</label>
                        {
                            props?.selectedData?.category_tags?.map((val, index) => {
                                return(
                                    <span key={index}>{val}{index <= props?.selectedData?.category_tags?.length ? ", " :<></>} </span>
                                )
                            })
                        }
                        {/* <p>{props?.selectedData?.category_tags}</p> */}
                    </div>
                    <div className='form-group col-md-12  mb-4'>
                        <label className='labelTxt mb-3' htmlFor='amount'>Faq Count:</label>
                        <p>{props?.selectedData?.faq_count}</p>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FaqViewModal
