import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import { emailBlue, locationBlue, phoneBlue, profileImg1 } from '../../../utilities/images';
import { getPosUsersAsync, selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingTimeSlotsApiAsync, resheduleBookingApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import DatePicker from "react-horizontal-datepicker";

const BookingSchedule = (props) => {
    let data = props?.data;
    console.log(data, 'propssssssssssss');
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [slotData, setSlotData] = useState([]);
    const [posList, setPosList] = useState([]);
    const [posUserId, setPosUserId] = useState(data?.pos_user_details?.user?.unique_uuid);
    const [posUserName, setPosUserName] = useState(data?.pos_user_details?.user?.user_profiles?.firstname + " " + data?.pos_user_details?.user?.user_profiles?.lastname);
    const [date, setDate] = useState(moment(data?.created_at).format('YYYY-MM-DD'));
    const [startTime, setStartTime] = useState(data?.start_time);
    const [endTime, setEndTime] = useState(data?.end_time);
    const [loadingNew, setLoadingNew] = useState(false);

    const statusChange = (id, status) => {
        props.changeStatusOfAppointment(id, status)
        props.close()
    }
    const selectedDay = val => {
        setDate(moment(val).format('YYYY-MM-DD'))
        let slotData = {
            "postData": {
                "date": moment(val).format('YYYY-MM-DD'),
                "product_id":props?.data?.product_id,
                "seller_id": posUserId
            },
            "tokenData": auth?.payload?.token
        }
        setLoading1(true)
        dispatch(getBookingTimeSlotsApiAsync(slotData))
            .then(unwrapResult)
            .then((obj) => {
                setSlotData(obj?.payload?.slots)
                setLoading1(false)
            })
            .catch((obj) => {
                setLoading1(false)
            })
    };
    const handleClick = (val) => {
        setStartTime(val?.start_time)
        setEndTime(val?.end_time)
    }
    const resheduleBookingHandle = (id) => {
        let data = {
            "postData": {
                'id': id,
                "date": date,
                "start_time": startTime,
                "end_time": endTime,
                "pos_user_id": posUserId
            },
            "tokenData": auth?.payload?.token
        }
        setLoadingNew(true)
        dispatch(resheduleBookingApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                props.handleAllAppointmentData()
                props.close()
                setLoadingNew(false)
            })
            .catch((obj) => {
                setLoadingNew(false)
            })
    }

    const getPosUser = () => {
        let params = {
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                need_staff_member: true
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getPosUsersAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setPosList(obj?.payload?.pos_staff)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    const getTimeSlots = () => {
        let slotData = {
            "postData": {
                "date": date,
                "product_id":props?.data?.product_id,
                "seller_id": auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
            },
            "tokenData": auth?.payload?.token
        }
        setLoading1(true)
        dispatch(getBookingTimeSlotsApiAsync(slotData))
            .then(unwrapResult)
            .then((obj) => {
                setSlotData(obj?.payload?.slots)
                setLoading1(false)
            })
            .catch((obj) => {
                setLoading1(false)
            })
    }

    useEffect(() => {
        getPosUser()
    }, [])

    useEffect(() => {
        getTimeSlots()
    }, [posUserId])

    return (
        <div className="scheduleModal">
            {/* <div className='scheduleHead text-white'> <h4>Booking #{data?.id}</h4>
                <button className="closeAction text-white"><i className="las la-times" onClick={props.close}></i></button>
            </div> */}
            {loading === true ?
                <div className="pageLoader d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <div className="bookModalsection">
                    <div className="bookModalDetails">
                        <div className="Innerbooking">
                            <div className="customerBookProfile mb-4">
                                <h5 className='smallHeading'>Customer:</h5>
                                {
                                    data?.user_details ?
                                        <div className='d-flex align-items-center'>
                                            <img className='userInnerImg' src={data?.user_details?.profile_photo ? data?.user_details?.profile_photo : profileImg1} />
                                            <div className='ms-2'>
                                                <span className='haedingsemiBold_'>{data?.user_details?.firstname + " " + data?.user_details?.lastname}</span>
                                                {
                                                    data?.user_details?.current_address &&
                                                    <p className='mb-0'>
                                                        <img src={locationBlue} className='img-fluid me-1 custImg' />
                                                        <span className='contentSmallTxt'>{data?.user_details?.current_address?.city},{data?.user_details?.current_address?.country}</span>
                                                    </p>}
                                            </div>
                                        </div> : <>Not Available</>}
                            </div>
                        </div>
                        <div className="contentappont mb-4">
                            <div className='dFlex '>
                                <h5 className='bookingTask'>{data?.product_name}</h5>
                                <span className='bookingTask'>${data?.price}</span>
                            </div>
                            <span className='innerSubheadNormal'>Est: {data?.approx_service_time + " min"}</span>
                        </div>
                        <div className="appointBook mb-4">
                            <div className='serviceProvide'><h5 className='serviceText'>SERVICE PROVIDER</h5></div>
                            <div className="contentappont">
                                <h5 className='semiboldHeading_ '>Selected:<span>{posUserName}</span></h5>
                                <div className='bookingFigure'>
                                    {
                                        posList?.length > 0 ?
                                            posList?.map((v, i) => {
                                                return (
                                                    <img key={i} className={posUserId !== v?.user?.unique_uuid ? 'serviceImg' : 'serviceImg active'} src={v?.user?.user_profiles?.profile_photo ? v?.user?.user_profiles?.profile_photo : profileImg1} onClick={() => { setPosUserId(v?.user?.unique_uuid); setPosUserName(v?.user?.user_profiles?.firstname + " " + v?.user?.user_profiles?.lastname) }} />
                                                )
                                            }) : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="appointBook mb-4 ">
                            <div className='serviceProvide'><span className='serviceText'>AVAILABLE SLOT</span></div>
                            <div className="time-mdlprt mb-2">
                                <h5 className='semiboldHeading_ mt-3 mb-4'>Time:<span>{moment.utc(date).format('dddd') + " @ " + startTime}</span></h5>
                                <DatePicker
                                    getSelectedDay={selectedDay}
                                    labelFormat={false}
                                    // color={"#374e8c"}
                                    // onChange={(e)=>handleDateChange(e)}
                                    numberOfVisibleDates={5}
                                />
                                {loading1 === true ?
                                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                                    :
                                    <div className="delivery-time">
                                        {
                                            slotData?.map((val, index) => {
                                                return (
                                                    <button type='button' key={index} disabled={val?.is_available === false} className={val?.is_available === false ? "time_button disabletext_" : (startTime == val?.start_time) ? 'time_button active' : 'time_button'} onClick={() => handleClick(val)}><span className='texttime_'>{val?.start_time}-{val?.end_time}</span></button>
                                                )
                                            })
                                        }
                                    </div>}
                            </div>
                        </div>

                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <button onClick={() => statusChange(data?.id, 4)} className='cancelBookBtn me-3' type="submit" disabled={props.stateLoading1}>
                            {props.stateLoading1 && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Cancel Booking</span>
                        </button>
                        <button onClick={() => resheduleBookingHandle(data?.id)} className='saveModifyBtn me-2' type="submit" disabled={loadingNew}>
                            {loadingNew && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Save Modifications</span>
                        </button>
                    </div>
                </div>}
        </div>
    )
}

export default BookingSchedule;