import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  redMapimg,
  shareMap,
  downlight,
  lossArrow,
} from "../../../utilities/images";
import {
  getAnalyticOrdersApiAsync,
  getMerchantTotalOrderGraphAsync,
  getPosCountAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import ChartArea from "../dashboard/wallet/ChartArea";

const MyOrders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading1, setLoading1] = useState(false);
  const [analyticsOrderData, setAnalyticsOrderData] = useState("");
  const [countData, setCountData] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const [filterValue, setFilterValue] = useState("week");
  const [merchantOrderGraphAnalysic, setMerchantOrderGraphAnalysis] =
    useState("");
  const handleFilterValue = (value) => {
    setFilterValue(value);
  };
  const [checked, setChecked] = useState({
    pos: true,
    delivery: true,
    shipping: true,
  });
  let [dataSets, setDataSets] = useState([]);
  const [POSdataSets, setPOSDataSets] = useState([]);
  const [POSchecked, setPOSChecked] = useState({
    Delivered: true,
    Returned: true,
    Cancelled: true,
  });
  const [deliveryDatasets, setDeliveryDatasets] = useState([]);
  const [DeliveryChecked, setDeliveryChecked] = useState({
    Delivered: true,
    Returned: true,
    Cancelled: true,
  });
  const [shippingChecked, setShippingChecked] = useState({
    Delivered: true,
    Returned: true,
    Cancelled: true,
  });
  const [shippingDatasets, setShippingDatasets] = useState([]);
  const handelPOSDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setPOSDataSets([
        ...POSdataSets,
        {
          fill: true,
          label: value,
          data: analyticsOrderData?.pos_graph?.graph_data?.datasets[num]?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setPOSDataSets(POSdataSets.filter((item) => item.label !== value));
    }
  };

  const handelDeliveryDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDeliveryDatasets([
        ...deliveryDatasets,
        {
          fill: true,
          label: value,
          data: analyticsOrderData?.delivery_graph?.graph_data?.datasets[num]
            ?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setDeliveryDatasets(
        deliveryDatasets.filter((item) => item.label !== value)
      );
    }
  };
  const handelShippingDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setShippingDatasets([
        ...shippingDatasets,
        {
          fill: true,
          label: value,
          data: analyticsOrderData?.shipping_graph?.graph_data?.datasets[num]
            ?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setShippingDatasets(
        shippingDatasets.filter((item) => item.label !== value)
      );
    }
  };
  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        align: "start",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          pointStyle: "circle",
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const PosOrderAnalytics = {
    labels: analyticsOrderData?.pos_graph?.graph_data?.labels,
    datasets: POSdataSets,
  };

  const DeliveryOrderAnalytics = {
    labels: analyticsOrderData?.delivery_graph?.graph_data?.labels,
    datasets: deliveryDatasets,
  };

  const ShippingOrderAnalytics = {
    labels: analyticsOrderData?.shipping_graph?.graph_data?.labels,
    datasets: shippingDatasets,
  };

  const TotalOrderAnalytics = {
    labels: merchantOrderGraphAnalysic?.graphData?.labels,
    datasets: dataSets,
  };
  const handelDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: merchantOrderGraphAnalysic?.graphData?.datasets
            ? merchantOrderGraphAnalysic.graphData.datasets[num]?.data
            : "",
          borderColor: color,
          backgroundColor: color,
        },
      ]);
    } else {
      setDataSets(dataSets.filter((item) => item.label !== value));
    }
  };
  const getMerchantTotalOrderGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        filter: value,
      },
    };
    setLoading1(true);
    dispatch(getMerchantTotalOrderGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        setMerchantOrderGraphAnalysis(obj.payload);
        setDataSets([
          {
            fill: true,
            label: "POS Orders",
            data: obj.payload?.graphData?.datasets[0]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Delivery Orders",
            data: obj.payload?.graphData?.datasets[1]?.data,
            borderColor: "#FCBA30",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Shipping Orders",
            data: obj.payload?.graphData?.datasets[2]?.data,
            borderColor: "#FB466C",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  const orderAnalyticsHandle = (value) => {
    let params = {
      postData: {
        filter: value,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAnalyticOrdersApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAnalyticsOrderData(obj?.payload);
        setPOSDataSets([
          {
            fill: true,
            label: "Delivered",
            data: obj?.payload?.pos_graph?.graph_data?.datasets[0]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Returned",
            data: obj?.payload?.pos_graph?.graph_data?.datasets[1]?.data,
            borderColor: "#FCBA30",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Cancelled",
            data: obj?.payload?.pos_graph?.graph_data?.datasets[2]?.data,
            borderColor: "#FB466C",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
        setDeliveryDatasets([
          {
            fill: true,
            label: "Delivered",
            data: obj?.payload?.delivery_graph?.graph_data?.datasets[0]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Returned",
            data: obj?.payload?.delivery_graph?.graph_data?.datasets[1]?.data,
            borderColor: "#FCBA30",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Cancelled",
            data: obj?.payload?.delivery_graph?.graph_data?.datasets[2]?.data,
            borderColor: "#FB466C",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
        setShippingDatasets([
          {
            fill: true,
            label: "Delivered",
            data: obj?.payload?.shipping_graph?.graph_data?.datasets[0]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Returned",
            data: obj?.payload?.shipping_graph?.graph_data?.datasets[1]?.data,
            borderColor: "#FCBA30",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Cancelled",
            data: obj?.payload?.shipping_graph?.graph_data?.datasets[2]?.data,
            borderColor: "#FB466C",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
        setLoading1(false);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  const getPosOrderCount = (value) => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        filter_by: value === "week" ? "week" : filterValue?.toLowerCase(),
        need_my_orders: true,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getPosCountAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setCountData(obj?.payload);
        setLoading1(false);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  useEffect(() => {
    getPosOrderCount(filterValue);
    getMerchantTotalOrderGraph(filterValue);
    orderAnalyticsHandle(filterValue);
  }, [filterValue]);


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Orders";
  }, []);

  return (
    <>
      <DashboardNavbar title="Orders Overview" backShow={false} />
      <div className="myOrdersmain myOrders_">
        {/* <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>My Orders </span>
                            </h2>
                        </div>

                    </div>
                </div>
            </div> */}

        <div className="row mb-3">
          <div className="col-md-12 text-end">
            <div className="dropdown weekDropdown">
              <button
                className="dropdown-toggle cateSelectd"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {filterValue} <img src={downlight} alt="img" />
              </button>
              <ul className="dropdown-menu weekDropdownMenus">
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => handleFilterValue("today")}
                  >
                    Today
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => handleFilterValue("week")}
                  >
                    This week
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => handleFilterValue("month")}
                  >
                    {" "}
                    Month
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => handleFilterValue("year")}
                  >
                    {" "}
                    Year
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="verificationTotal">
          <div className="row">
            {countData?.length > 0 ? (
              countData?.map((v, i) => {
                return (
                  <div
                    onClick={() =>
                      history.push({
                        pathname: "/allOrder",
                        state:
                          v?.name === "Total Orders"
                            ? { filter: filterValue }
                            : v?.name === "Orders Completed"
                            ? { status: "5,6", filter: filterValue }
                            : v?.name === "Orders Pending"
                            ? { status: "0,1,2,3,4", filter: filterValue }
                            : v?.name === "Orders Canceled"
                            ? { status: "7,8,9", filter: filterValue }
                            : "",
                      })
                    }
                    key={i}
                    className="col-sm-6 col-md-3 col-lg-3 customSpce"
                  >
                    <div className="percentageContainer justify-content-between align-items-center">
                      <div className="percentageValues">
                        <h3 className="mainHeadingsub_ ">{v?.value}</h3>
                        <p className="textinner_">{v?.name}</p>
                      </div>
                      <div
                        className={`percentageRating ${
                          v?.analytics >= 0 ? "" : "percentageBg"
                        }`}
                      >
                        <img
                          style={{ width: "30px" }}
                          src={v?.analytics >= 0 ? shareMap : lossArrow}
                          className=""
                          alt="ShareMap"
                        />
                        <p
                          className={
                            v?.analytics >= 0
                              ? "percentageRate"
                              : "minusPercentageRate"
                          }
                        >
                          {v?.analytics?.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
        {loading1 ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            <div className="tradesValue mt-4 mb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="chartsOuter mt-4 mb-3">
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/Total-Orders")}
                      className="textInnerHead mt-3"
                    >
                      Total Orders
                    </h3>
                    <h3 className="Innerhead mt-3">
                      {merchantOrderGraphAnalysic?.totalAmount
                        ? `$${addThousandSeparator(
                            merchantOrderGraphAnalysic?.totalAmount?.toFixed(2)
                          )}`
                        : "$0.00"}
                    </h3>
                    <div className="graphChecked_ mt-3 mb-2 ">
                      <div className="graphBox_ me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll2"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          onChange={(e) => {
                            handelDataSetChange(e, "POS Orders", 0, "#275AFF");
                            setChecked({
                              ...checked,
                              pos: checked.pos === true ? false : true,
                            });
                          }}
                          checked={checked.pos}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll2"
                          className="checkBoxTxt masterChecked"
                        >
                          POS Orders
                        </label>
                      </div>
                      <div className="checkYellow me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll3"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.delivery}
                          onChange={(e) => {
                            handelDataSetChange(
                              e,
                              "Delivery Orders",
                              1,
                              "#FCBA30"
                            );
                            setChecked({
                              ...checked,
                              delivery:
                                checked.delivery === true ? false : true,
                            });
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll3"
                          className="checkBoxTxt masterChecked"
                        >
                          Delivery Orders
                        </label>
                      </div>
                      <div className="checkRed me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll4"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.shipping}
                          onChange={(e) => {
                            handelDataSetChange(
                              e,
                              "Shipping Orders",
                              2,
                              "#FB466C"
                            );
                            setChecked({
                              ...checked,
                              shipping:
                                checked.shipping === true ? false : true,
                            });
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll4"
                          className="checkBoxTxt masterChecked"
                        >
                          Shipping Orders
                        </label>
                      </div>
                    </div>
                    <div className="row mt-4 mb-4 align-items-end">
                      <ChartArea
                        classes="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3"
                        header="Sales Overview"
                        options={options1}
                        data={TotalOrderAnalytics}
                        chartType="Line"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mt-4">
                <div className="chartsOuter">
                  <h4
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/Total-POS-Orders")}
                    className="expectedHeading "
                  >
                    Total POS Orders
                  </h4>
                  <h4 className="successMain text-start">
                    {" "}
                    {addThousandSeparator(
                      analyticsOrderData?.pos_graph?.total_count
                    )}
                  </h4>
                  <div className="graphChecked_  mb-2 ">
                    <div className="graphBox_ me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll2POS"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelPOSDataSetChange(e, "Delivered", 0, "#275AFF");
                          setPOSChecked({
                            ...POSchecked,
                            Delivered:
                              POSchecked.Delivered === true ? false : true,
                          });
                        }}
                        checked={POSchecked.Delivered}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll2POS"
                        className="checkBoxTxt "
                      >
                        Delivered
                      </label>
                    </div>
                    <div className="checkYellow me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll3POS"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={POSchecked.Returned}
                        onChange={(e) => {
                          handelPOSDataSetChange(e, "Returned", 1, "#FCBA30");
                          setPOSChecked({
                            ...POSchecked,
                            Returned:
                              POSchecked.Returned === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll3POS"
                        className="checkBoxTxt "
                      >
                        Returned
                      </label>
                    </div>
                    <div className="checkRed me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll4POS"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={POSchecked.Cancelled}
                        onChange={(e) => {
                          handelPOSDataSetChange(e, "Cancelled", 2, "#FB466C");
                          setPOSChecked({
                            ...POSchecked,
                            Cancelled:
                              POSchecked.Cancelled === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll4POS"
                        className="checkBoxTxt "
                      >
                        Cancelled
                      </label>
                    </div>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={PosOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4">
                <div className="chartsOuter">
                  <h4
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/Total-Delivery-Orders")}
                    className="expectedHeading "
                  >
                    Total Delivery Orders
                  </h4>
                  <h4 className="successMain text-start">
                    {" "}
                    {addThousandSeparator(
                      analyticsOrderData?.delivery_graph?.total_count
                    )}
                  </h4>
                  <div className="graphChecked_  mb-2 ">
                    <div className="graphBox_ me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll23Delivery"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelDeliveryDataSetChange(
                            e,
                            "Delivered",
                            0,
                            "#275AFF"
                          );
                          setDeliveryChecked({
                            ...DeliveryChecked,
                            Delivered:
                              DeliveryChecked.Delivered === true ? false : true,
                          });
                        }}
                        checked={DeliveryChecked.Delivered}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll23Delivery"
                        className="checkBoxTxt "
                      >
                        Delivered
                      </label>
                    </div>
                    <div className="checkYellow me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll34Delivery"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={DeliveryChecked.Returned}
                        onChange={(e) => {
                          handelDeliveryDataSetChange(
                            e,
                            "Returned",
                            1,
                            "#FCBA30"
                          );
                          setDeliveryChecked({
                            ...DeliveryChecked,
                            Returned:
                              DeliveryChecked.Returned === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll34Delivery"
                        className="checkBoxTxt "
                      >
                        {/* Total DElivery oRDERS */}
                        Returned
                      </label>
                    </div>
                    <div className="checkRed me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll43Delivery"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={DeliveryChecked.Cancelled}
                        onChange={(e) => {
                          handelDeliveryDataSetChange(
                            e,
                            "Cancelled",
                            2,
                            "#FB466C"
                          );
                          setDeliveryChecked({
                            ...DeliveryChecked,
                            Cancelled:
                              DeliveryChecked.Cancelled === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll43Delivery"
                        className="checkBoxTxt "
                      >
                        Cancelled
                      </label>
                    </div>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={DeliveryOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4">
                <div className="chartsOuter">
                  <h4
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/Total-Shipping-Orders")}
                    className="expectedHeading "
                  >
                    Total Shipping Orders
                  </h4>
                  <h4 className="successMain text-start">
                    {" "}
                    {addThousandSeparator(
                      analyticsOrderData?.shipping_graph?.total_count
                    )}
                  </h4>
                  <div className="graphChecked_  mb-2 ">
                    <div className="graphBox_ me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll231Shipping"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelShippingDataSetChange(
                            e,
                            "Delivered",
                            0,
                            "#275AFF"
                          );
                          setShippingChecked({
                            ...shippingChecked,
                            Delivered:
                              shippingChecked.Delivered === true ? false : true,
                          });
                        }}
                        checked={shippingChecked.Delivered}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll231Shipping"
                        className="checkBoxTxt "
                      >
                        Delivered
                      </label>
                    </div>
                    <div className="checkYellow me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll341Shipping"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={shippingChecked.Returned}
                        onChange={(e) => {
                          handelShippingDataSetChange(
                            e,
                            "Returned",
                            1,
                            "#FCBA30"
                          );
                          setShippingChecked({
                            ...shippingChecked,
                            Returned:
                              shippingChecked.Returned === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll341Shipping"
                        className="checkBoxTxt "
                      >
                        {/* Total DElivery oRDERS */}
                        Returned
                      </label>
                    </div>
                    <div className="checkRed me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll431Shipping"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={shippingChecked.Cancelled}
                        onChange={(e) => {
                          handelShippingDataSetChange(
                            e,
                            "Cancelled",
                            2,
                            "#FB466C"
                          );
                          setShippingChecked({
                            ...shippingChecked,
                            Cancelled:
                              shippingChecked.Cancelled === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll431Shipping"
                        className="checkBoxTxt "
                      >
                        Cancelled
                      </label>
                    </div>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={ShippingOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyOrders;
