import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deletePosUserApiAsync, getPosUsersAsync, selectLoginAuth } from '../auth/authSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import DataTable from 'react-data-table-component'
import { profileImg1 } from '../../../utilities/images'
import CustomModal from '../../components/shared/CustomModal'
import PosDetailsModal from './posDetailsModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import UpdatePosUserModal from './UpdatePosUserModal'
import DeleteModal from './deleteModal'
import { toast } from 'react-toastify'
import { getProductServiceListApiAsync } from '../myProducts/productSlice'
import DashboardNavbar from '../dashboard/DashboardNavbar'

const PosUser = () => {
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [posList, setPosList] = useState([])
  const [allServiceData, setAllServiceData] = useState([])
  const history = useHistory();
  const toastId = React.useRef(null);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const dispatch = useDispatch()
  const auth = useSelector(selectLoginAuth)
  const [key, setKey] = useState(Math.random());

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const columns1 = [
    {
      name: '#',
      selector: row => row.id,
      width: "70px"
    },
    {
      name: "Image",
      width: "80px",

      selector: row => {
        return (
          <>
            <div className='d-flex align-items-center'>
              <img className='userInnerImg' src={row?.user?.user_profiles?.profile_photo ? row?.user?.user_profiles?.profile_photo : profileImg1} />
            </div>
          </>
        );
      },
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{(row?.user?.user_profiles?.firstname ? row?.user?.user_profiles?.firstname : "") + " " + (row?.user?.user_profiles?.lastname ? row?.user?.user_profiles?.lastname : "")}</span>
          </>
        );
      },
      name: 'Name',
    },
    {
      name: "Role",
      selector: row => {
        let roleData = row?.user?.user_roles?.map((v, i) => {
          return v?.role?.name
        })
        return (
          <>
            <div className='d-flex align-items-center'>
              <span className='textParagh '>{roleData?.toString()?.length > 25 ? roleData?.toString()?.substring(0, 25) + "..." : roleData?.toString()}</span>
            </div>
          </>
        );
      },
      name: 'Role',
    },
    {
      name: "Services",
      selector: row => {
        let selectedServicdIds = row?.pos_staff_products?.map(v => v?.product_id)
        let serviceSelected = allServiceData?.filter(value => selectedServicdIds?.includes(value?.id))?.map((v, i) => {
          return v?.name
        })
        return (
          <>
            <div className='d-flex align-items-center'>
              <span className='textParagh '>{serviceSelected?.toString()?.length > 50 ? serviceSelected?.toString()?.substring(0, 50) + "..." : serviceSelected?.toString()}</span>
            </div>
          </>
        );
      },
    },
    {
      selector: row => {
        return (
          <>
            <span className='textParagh '>{row?.user?.email?.length > 20 ? row?.user?.email?.substring(0, 20) + "..." : row?.user?.email}</span>
          </>
        );
      },
      name: 'Email',
      width: '200px'
    },
    {
      selector: row => {
        return (
          <>
            <div className='mobposUser'>
              <span className='textParagh '>{row?.user?.user_profiles?.full_phone_number}</span>

            </div>
          </>
        );
      },
      name: 'Mobile No.',
    },
    {
      selector: row => {
        return (
          <>
            <div className='d-flex justify-space-between align-items-center pe-3 ps-3'>
              <div className='dropdown tableDropdown ms-3 '>
                <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                  <i className="fal fa-ellipsis-v icon_"></i>
                </span>
                <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                  <li className='dropdown-item' onClick={() => {
                    setSelectedId(row?.user_id)
                    setSelectedRow(row)
                    setModalDetail({ show: true, flag: "posModal" });
                    setKey(Math.random());
                  }}>View Details</li>
                  {/* <li className='dropdown-item'>Active</li> */}
                  <li className='dropdown-item' onClick={() => {
                    setSelectedId(row?.user_id)
                    setSelectedRow(row)
                    setModalDetail({ show: true, flag: "updatePosModal" });
                    setKey(Math.random());
                  }}>Edit</li>
                  <li className='dropdown-item' onClick={() => {
                    setSelectedId(row?.user_id)
                    setModalDetail({ show: true, flag: "deleteRoleModal" });
                    setKey(Math.random());
                  }}>Delete</li>
                </div>
              </div>
            </div>
          </>
        );
      },
      name: '',
      width: '80px'
    },
  ]

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const deletePosUserHandle = (id) => {
    let params = {
      "postData": {
        "id": id
      },
      "tokenData": auth?.payload?.token
    }
    setLoading1(true)
    dispatch(deletePosUserApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        let data = {
          postData: {
            seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
            page: currentPage,
            limit: perPage
          },
          tokenData: auth?.payload?.token
        }
        dispatch(getPosUsersAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            setPosList(obj?.payload?.pos_staff)
            setTotalRows(obj?.payload?.total)
            setLoading1(false)
            handleOnCloseModal()
          })
          .catch((obj) => {
            setLoading1(false)
          })
      }
      )
      .catch((obj) => {
        setLoading1(false)
      })
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "My-Pos";
  }, []);

  const serviceHandle = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        type: "digital",
        filter_deleted: true,
        app_name: "merchant"
      }
    }
    setLoading(true)
    dispatch(getProductServiceListApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setAllServiceData(obj?.payload?.data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    serviceHandle()
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        page: currentPage,
        limit: perPage
      },
      tokenData: auth?.payload?.token
    }
    setLoading(true)
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setPosList(obj?.payload?.pos_staff)
        setTotalRows(obj?.payload?.total)
        setLoading(false)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }, [perPage, currentPage])
  return (
    <>
      <div className='posUser_'>
        <DashboardNavbar title="Pos User" backShow={false} />

        {/* <div className='topHeader_'>
          <div className="topStickyHeader_ mb-3">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h2 className="mainHeading">
                  <span>Pos Users</span>
                </h2>
              </div>
              <div className="col-md-6 text-end">
                <button className='btn btn-primary' onClick={() => history.push(`/createUser`)}>Create Pos User</button>
              </div>
            </div>
          </div>
        </div> */}


        {/* <section className='recentTransections postransaction table-responsive commonTableheight mt-1'> */}
        <section className='recentTransections table-responsive commonTableheight mt-1'>
          <DataTable
            columns={columns1}
            data={posList}
            //   onRowClicked={handleRowClick}
            //   customStyles={customStyles}        
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </section>
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop={true}
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          size={modalDetail.flag === "updatePosModal" ? "lg" : ""}
          // className={modalDetail.flag === "posModal" ? "commonWidth customContent" : ""}
          ids={modalDetail.flag === "deleteRoleModal" ? "DeleteRoleModal" : modalDetail.flag === "updatePosModal" ? "UpdatePosModal" : modalDetail.flag === "posModal" ? "PosModal" : ""}
          child={
            modalDetail.flag === "deleteRoleModal" ? (
              <DeleteModal loadingState={loading1} flag="deletePosUser" id={selectedId} deletePosUserHandle={(e) => deletePosUserHandle(e)} close={() => handleOnCloseModal()} />
            ) :
              modalDetail.flag === "updatePosModal" ? (
                <UpdatePosUserModal selectedRow={selectedRow} setPosList={setPosList} id={selectedId} close={() => handleOnCloseModal()} />
              ) :
                modalDetail.flag === "posModal" ? (
                  <PosDetailsModal selectedRow={selectedRow} id={selectedId} close={() => handleOnCloseModal()} />
                ) :
                  (
                    ""
                  )
          }
          header={
            <> {modalDetail.flag === "deleteRoleModal" ?
              <h2 className="subheading2_">Delete Pos User</h2>
              :
              modalDetail.flag === "updatePosModal" ?
                <div className='modalHeader_'>
                  <div className="common_">
                    <div className='anotherHeader'>
                      <h2 className="modalHeading">Update Pos User</h2>
                    </div>
                    <button className="closeButton">
                      <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                    </button>
                  </div>
                </div>
                :
                modalDetail.flag === "posModal" ?
                  <div className='modalHeader_'>
                    <div className="common_">
                      <div className='anotherHeader'>
                        <h2 className="modalHeading">POS details</h2>
                      </div>
                      <button className="closeButton">
                        <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                      </button>
                    </div>
                  </div>
                  :
                  ""
            }
            </>
          }
          onCloseModal={() => handleOnCloseModal()}
        />

      </div>
    </>

  )
}

export default PosUser