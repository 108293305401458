import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { galleryImg, galleryUpload, infoImg, manage, imgGallery1, modalCancel } from '../../../utilities/images'
import ProductDetails from './ProductDetails'
import ProductImageAdd from './productImageAdd'
import AddProductHeader from './AddProductHeader'
import FixedPrice from './fixedPrice'
import CustomPrice from './customPrice'
import AddOptionsModal from './addOptionsModal'
import CustomModal from '../../components/shared/CustomModal'
import AddAttributesModal from './addAttributesModal';
import ProductPrice from './productPrice'
import { unwrapResult } from '@reduxjs/toolkit'
import { getAddressApiAsync, selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { imageUploadAsync } from '../message/messageSlice';
import { toast } from 'react-toastify';
import { formValidator } from './formValidator'
import { createProductApiAsync } from '../dashboard/dashboardSlice'
import SaleTaxModal from './saleTaxModal'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Multiselect from 'multiselect-react-dropdown'
import CreateOptionModal from './createOptionModal'

const AddProduct = (props) => {
    const history = useHistory()
    const backRoute = localStorage.getItem("backButtonRoute")
    const [productDetails, setProductDetails] = useState({})
    const [serviceId, setServiceId] = useState("")
    const [selectedService, setSelectedService] = useState(null);
    const [costPrice, setcostPrice] = useState("")
    const { flag } = useParams()
    const [productDescription, setProductDescription] = useState("")
    const [skipPosDetails, setSkipPosDetails] = useState(false)
    const [imageDetails, setImageDetails] = useState({})
    const [prices, setPrices] = useState();
    const [activePos, setActivePos] = useState(false);
    const [activeOnlineStore, setActiveOnlineStore] = useState(false);
    const [activeBtoc, setActiveBtoc] = useState(false);
    const [activeBtob, setActiveBtob] = useState(false);
    const [inventoryOpening, setInventoryOpening] = useState("");
    const [inventoryReorderPoint, setInventoryReorderPoint] = useState("");
    const [allAddress, setAllAddress] = useState([]);
    const [allAddressIds, setAllAddressIds] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [weightUnit, setWeightUnit] = useState("kg");
    const [dimensions, setDimensions] = useState({
        weight: "",
        length: "",
        breadth: "",
        height: ""
    });
    console.log(dimensions, 'dimensionsssss');
    const [type] = useState("physical");
    const [barCode, setBarCode] = useState("");
    const [unitType, setUnitType] = useState("per_unit");
    const [sku, setSku] = useState("");
    const [optionsList, setOptionsList] = useState([]);
    const [combinations, setCombinations] = useState([]);
    const [selectedAttributesValue, setSelectedAttributesValue] = useState([]);
    const auth = useSelector(selectLoginAuth);
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [successLoader, setSuccessLoader] = useState(false)
    const [singleImage, setSingleImage] = useState();
    console.log(singleImage,'singleImage');
    const [checkedOptionList, setCheckedOptionList] = useState([]);
    const [checkedValue, setCheckedValue] = useState([]);
    const [allSelectChecked, setAllSelectChecked] = useState(false);
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleWeightUnit = (e) => {
        setWeightUnit(e.target.value)
    }

    const handleUnitChange = (e) => {
        setUnitType(e.target.value)
    }

    const handleChangeJobrPos = () => {
        setActiveOnlineStore(!activeOnlineStore);
        if (activeBtoc) {
            setActiveBtoc(false);
        }
        if (activeBtob) {
            setActiveBtob(false);
        }
    }

    const handleProductDetails = (e, flag) => {
        if (flag == "description") {
            setProductDescription(e)
        } else {
            setProductDetails(e)
        }
    }
    const handleImageDetails = (e) => {
        setImageDetails(e)
    }

    const handleChangeCostPrice = (e) => {
        setcostPrice(e.target.value);
    }

    const handleprices = (e) => {
        setPrices(e);
    }

    const handleAttributes = (e) => {
        setIngredients(e);
    }

    const handleSelect = (data) => {
        let allIds = data?.map(v => v?.id)
        setAllAddressIds(allIds);
    };
    const handleBarcodeChange = (event) => {
        // Check if the input length is within the allowed limit (12 characters)
        if (event.target.value.length <= 14) {
            setBarCode(event.target.value);
        }
      };
      const handleSkuChange = (event) => {
        // Check if the input length is within the allowed limit (12 characters)
        if (event.target.value.length <= 14) {
            setSku(event.target.value);
        }
      };

    const handleOptions = (attributes, data, value) => {
        console.log(attributes, data, value, 'handle options data');
        setOptionsList(attributes);
        setCombinations(data);
        setCheckedOptionList(
            new Array(attributes?.length).fill(false)
        )
        setSelectedAttributesValue(value)
    }

    const handlechangeShippingOrPickup = (optionValue) => {
        if (deliveryOptions.includes(optionValue)) {
            setDeliveryOptions(deliveryOptions.filter(option => option !== optionValue));
        } else {
            setDeliveryOptions([...deliveryOptions, optionValue]);
        }
    }

    const handleSkipPOSDetails = (e) => {
        setSkipPosDetails(e.target.checked)
    }

    const handledimensions = (e) => {
        const { name, value } = e.target;
        let data = { ...dimensions, [name]: value ? value:0  }
        setDimensions(data);
    }


    const uploadImage = (e) => {
        if (checkedValue.length <= 0) {
            toast.error("Please select checkbox")
        }
        else {
            const imageFile = e.target.files[0];
            let formData = new FormData();
            formData.append(`file`, imageFile);

            let params = {
                file: formData,
                token: auth?.payload?.token
            }
            setLoading(true)
            dispatch(imageUploadAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setSingleImage(obj?.payload[0]?.filePath);
                    const newImage = obj?.payload[0]?.filePath;
                    replaceImage(optionsList, checkedValue, newImage)
                    setLoading(false)
                }
                )
                .catch((obj) => {
                    setLoading(false)
                });
        }
    }

    const handleCheckbox = (position) => {
        const updatedCheckedState = checkedOptionList.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedOptionList(updatedCheckedState)
        let data = updatedCheckedState.map((val, index) => {
            return (
                val === true ? index : false
            )
        })
        let result = data?.filter(val => val !== false);
        setCheckedValue(result);
        if (result.length == checkedOptionList.length) {
            setAllSelectChecked(true);
        } else {
            setAllSelectChecked(false);
        }
    }

    const handleSelectAllChange = (e) => {
        if (e.target.checked == false) {
            setAllSelectChecked(false);
            setCheckedOptionList(
                new Array(checkedOptionList?.length).fill(false)
            )
            setCheckedValue([]);
        } else {
            setAllSelectChecked(true);
            setCheckedOptionList(
                new Array(checkedOptionList?.length).fill(true)
            )
            const updatedCheckedState = checkedOptionList.map((item, index) =>
                index
            );
            setCheckedValue(updatedCheckedState);
        }
    }

    function replaceImage(arr, indexes, url) {
        indexes.forEach(index => {
            if (arr[index]) {
                arr[index].image = url;
            }
        });

        setOptionsList(arr);
        return arr;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Add-Products";
      }, []);
    

    const handleSave = () => {
        let product_attribute = {
            "name": productDetails?.productName,
            "category_id": (productDetails?.category_id)?.toString(),
            "sub_category_id": (productDetails?.sub_category_id)?.toString(),
            "brand_id": (productDetails?.brand_id).toString(),
            "service_id": productDetails?.service_id,
            "description": productDescription,
            "type": "physical",
            "barcode": barCode,
            "image": imageDetails?.image,
            "images": imageDetails?.images,
            "price": costPrice,
            "quantity_unit": unitType,
            "skip_detail_screen_in_pos": skipPosDetails,
            "weight_unit": "lb",
            "delivery_options": deliveryOptions,
            "quantity": inventoryOpening,
            "prices": prices,
        }
        console.log(product_attribute, "product_attributeRRRR");
        if (sku) {
            product_attribute = { ...product_attribute, "sku": sku }
        }
        // if(deliveryOptions.length > 0 && deliveryOptions?.includes("shipping")){
        if(deliveryOptions.length > 0 && deliveryOptions?.includes("shipping")){
            product_attribute = { ...product_attribute,"dimensions": dimensions }    
        }
        if (inventoryReorderPoint) {
            product_attribute = { ...product_attribute, "quantity_reorder_point": inventoryReorderPoint }
        }
        if (ingredients?.length > 0) {
            product_attribute = { ...product_attribute, "ingredients": ingredients, }
        }
        if (optionsList?.length > 0) {
            product_attribute = { ...product_attribute, "attributes": optionsList, }
        }
        if (allAddressIds?.length > 0) {
            product_attribute = { ...product_attribute, "seller_address_ids": allAddressIds, }
        }

        let result = formValidator(product_attribute, activePos, activeBtob, activeBtoc)
        if (result) {
            setSuccessLoader(true)
            let params = {
                "postData": product_attribute,
                "tokenData": auth?.payload?.token
            }
            dispatch(createProductApiAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setSuccessLoader(false)
                    toast.success(obj?.msg)
                    history.push(`${backRoute}`)
                }
                )
                .catch((obj) => {
                    setSuccessLoader(false)
                });
        }
    }

    const handleModalChange = (flag) => {
        setModalDetail({ show: true, flag: flag }); 
        setKey(Math.random());
    }

    const getAllAddress = () => {
        const params = {
            sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
            token: auth?.payload?.token
        }
        setLoading1(true)
        dispatch(getAddressApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading1(false)
                // const uniqueAddresses = [...new Set(obj?.payload?.map(entry => entry.format_address))];
                // console.log(uniqueAddresses,'uniqueAddresses');
                // const filteredData = obj?.payload?.filter(entry =>
                //     obj?.payload?.filter(item => item.format_address === entry.format_address).length > 1
                // );
                const addresses = [...new Set(obj?.payload?.map(entry => entry?.format_address))];

                // const addressCounts = obj?.payload?.reduce((acc, obj) => {
                //     acc[obj.format_address] = (acc[obj.format_address] || 0) + 1;
                //     return acc;
                // }, {});

                // const filtered = obj?.payload?.filter(obj => addressCounts[obj.format_address] > 1);
                // console.log(addressCounts,'addressCounts');
                // console.log(filtered,'filtered');
                setAllAddress(obj?.payload)
            })
            .catch((obj) => {
                setLoading1(false)
            })
    }
    useEffect(() => {
        getAllAddress()
    }, [])

    useEffect(() => {
        // Attach the event listener to the document
        document.addEventListener('keydown', handleKeyDown);
        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className='addProductManually'>
            {/* Start FixedTopHeader */}
            <AddProductHeader flag={backRoute} save={(e) => handleSave(e)} successLoader={successLoader} />
            {/* End FixedTopHeader */}
            <div className="productRightWrapper">
                <div className='container-fluid pt-3 pb-3'>
                    <div className='row justify-content-center'>
                        <div className='col-md-10 '>
                            {/* Start Uploaded ProductImg Area */}
                            <ProductImageAdd imageData={(e) => handleImageDetails(e)} />
                            {/* End Uploaded ProductImg Area */}

                            {/* Start ProductDetails Area */}
                            <div className='mt-4'>
                                <div className='row'>
                                    {/* Start ProductDetails Area */}
                                    <div className='col-md-7'>
                                        <ProductDetails setSelectedService={setSelectedService} setServiceIdforAttributes={setServiceId} productDetails={(e, flag) => handleProductDetails(e, flag)} />
                                        {/* Start CostPrice Area */}
                                        <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                            <form className='row costPrice'>
                                                <div className='form-group col-md-6 mb-4'>
                                                    <label className="subheaderHeading mb-2">Cost Price
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>
                                                    <div className='inputGroup'>
                                                        <input
                                                            min="1"
                                                            onKeyDown={blockInvalidChar}
                                                            className="customform-control"
                                                            placeholder="Cost Price"
                                                            name="text"
                                                            type="number"
                                                            value={costPrice}
                                                            onChange={(e) => handleChangeCostPrice(e)}
                                                        // onChange={(e) => setcostPrice(e.target.value)}
                                                        />
                                                        <span className='dollrsign1_'>$</span>
                                                    </div>
                                                </div>
                                                <div className='form-group col-md-6 mb-4'>
                                                    <label className='subheaderHeading mb-2' htmlFor='amount'>Unit</label>
                                                    <select autoComplete="new-password" className='customform-control select' defaultValue={unitType} onChange={(e) => handleUnitChange(e)}>
                                                        <option value="per_unit">per unit</option>
                                                        <option value="per_box">per box</option>
                                                        <option value="per_bag">per bag</option>
                                                        <option value="per_pound">per pound</option>
                                                        <option value="per_feet">per feet</option>
                                                        <option value="per_gallon">per gallon</option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div>
                                        {/* <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                            <form className='row costPrice'>
                                                <div className='form-group w-100 mb-4'>
                                                    <label className="subheaderHeading mb-2">Tax
                                                        <img src={infoImg} alt='InfoImg' className='img-fluid ms-3 cursorPoint' onClick={() => { setModalDetail({ show: true, flag: "saleTax" }); setKey(Math.random()); }} />
                                                    </label>
                                                    <select autoComplete="new-password" className='customform-control select' defaultValue={unitType} onChange={(e) => handleUnitChange(e)}>
                                                        <option value="per_unit">per unit</option>
                                                        <option value="per_box">per box</option>
                                                        <option value="per_bag">per bag</option>
                                                        <option value="per_pound">per pound</option>
                                                        <option value="per_feet">per feet</option>
                                                        <option value="per_gallon">per gallon</option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div> */}
                                        <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                            <form className='row costPrice'>
                                                <div className='form-group w-100 mb-4'>
                                                    <label className="subheaderHeading mb-2">Address
                                                    </label>
                                                    <Multiselect
                                                        className='brandFilter customform-control'
                                                        displayValue="format_address"
                                                        options={allAddress}
                                                        hidePlaceholder={false}
                                                        placeholder='Select Address'
                                                        onSelect={handleSelect}
                                                        onRemove={handleSelect}
                                                        showCheckbox
                                                    // onKeyDown={handleKeyDown} 
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        {/* End CostPrice Area */}
                                        {/* Start JobrPos Area */}
                                        <ProductPrice activePos={activePos} activeBtoc={activeBtoc} activeBtob={activeBtob} activeOnlineStore={activeOnlineStore} costPrice={costPrice} prices={(e) => handleprices(e)} />

                                        {/* End JobrB2B Area */}
                                        {/*----- Start Options Area -----*/}
                                        <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                            <h2 className='headingBlue mb-4'>
                                                Options
                                                <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                            </h2>
                                            <button className='addOptions' onClick={() => { setModalDetail({ show: true, flag: "AddOptions" }); setKey(Math.random()); }}>
                                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" className='me-2' xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.25021 0C9.43145 7.6429e-06 9.60655 0.0656426 9.74314 0.184767C9.87973 0.303891 9.96856 0.468446 9.99321 0.648L10.0002 0.75L10.0012 8H17.2542C17.4442 8.00006 17.6272 8.07224 17.766 8.20197C17.9049 8.3317 17.9893 8.5093 18.0022 8.69888C18.0152 8.88846 17.9557 9.07589 17.8358 9.2233C17.7159 9.37071 17.5445 9.4671 17.3562 9.493L17.2542 9.5H10.0012L10.0032 16.75C10.0029 16.9399 9.93054 17.1227 9.80073 17.2613C9.67092 17.4 9.49334 17.4842 9.30383 17.4971C9.11433 17.5099 8.92702 17.4503 8.77972 17.3304C8.63242 17.2105 8.5361 17.0392 8.51021 16.851L8.50321 16.749L8.50121 9.5H1.25221C1.06219 9.49994 0.879272 9.42776 0.740421 9.29803C0.60157 9.1683 0.517139 8.9907 0.504187 8.80112C0.491235 8.61154 0.550728 8.42411 0.670645 8.2767C0.790563 8.12929 0.961963 8.0329 1.15021 8.007L1.25221 8H8.50221L8.50021 0.75C8.50021 0.551088 8.57923 0.360322 8.71988 0.21967C8.86054 0.0790175 9.0513 0 9.25021 0Z" fill="#275AFF" />
                                                </svg>
                                                Add Options
                                            </button>
                                            
                                            {optionsList.length > 0 ? <>
                                                <div className='row mt-4'>
                                                    <div className='col-md-12'>
                                                        <h4 className='deatilsHead mb-2 fw-normal'>Varients</h4>
                                                    </div>
                                                </div>
                                                <div className='row align-items-center'>
                                                    <div className='col-md-6'>
                                                        <input
                                                            type="checkbox"
                                                            className='checkBox'
                                                            id="view1"
                                                            checked={allSelectChecked}
                                                            onChange={(e) => { handleSelectAllChange(e) }}
                                                        />
                                                        <label htmlFor="view1" className='storeText'>{optionsList?.length} variations</label>
                                                    </div>
                                                    <div className='col-md-6 text-end'>
                                                        <div className='justify-content-end variantBtn'>
                                                            <div className='imgFileBox me-3'>
                                                                <input
                                                                    type="file"
                                                                    id="file"
                                                                    accept="image/*"
                                                                    className="file-input__input"
                                                                    onChange={(e) => { uploadImage(e) }}
                                                                    required=""
                                                                />
                                                                <label className="varientImgBtn" htmlFor="file-input">
                                                                    <img src={imgGallery1} alt="" className='me-2 img-fluid imgGalleryBtn'  />
                                                                    Image Upload
                                                                </label>
                                                            </div>
                                                            <button className='varientImgBtn' onClick={() => { setModalDetail({ show: true, flag: "AddOptions" }); setKey(Math.random()); }}>Manage</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='table-responsive'>
                                                    <table className='table variantTables mt-3'>
                                                        <tbody>
                                                            {optionsList?.map((item, index) => {
                                                                return (
                                                                    <tr className='variantTable' key={index}>
                                                                        <td className='variantItems'>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className="rolecheckbox_">
                                                                                    <input
                                                                                        className="me-2"
                                                                                        type="checkbox"
                                                                                        id={`view1${index}`}
                                                                                        name={item?.id}
                                                                                        value={item?.id}
                                                                                        checked={checkedOptionList[index]}
                                                                                        onChange={() => handleCheckbox(index)}
                                                                                    />
                                                                                    <label className="contentheadtext_" htmlFor="view1"></label>
                                                                                </div>
                                                                                <div className='d-flex'>
                                                                                    <div className='imgFileBox me-3'>
                                                                                        <input
                                                                                            type="file"
                                                                                            name="file"
                                                                                            id="file"
                                                                                            accept="image/*"
                                                                                            className="file-input__input"
                                                                                            onChange={(e) => { uploadImage(e) }}
                                                                                        />


                                                                                        <label className="" htmlFor="file-input">
                                                                                            <span className='galleryCircle me-2'>
                                                                                                {loading && checkedValue?.includes(index) ?
                                                                                                    <span className="spinner-border spinner-border-sm"></span>
                                                                                                    :
                                                                                                    <img src={item.image ? item.image : galleryImg} alt='' className='' />
                                                                                                }
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {item.attribute_values.map((ele, index) => {
                                                                                        return (
                                                                                            <div className='variantItemsContent' key={index}>

                                                                                                <p className='subtextDark_' >{ele?.attribute_value_name}</p>
                                                                                                
                                                                                            </div>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className='variantItems'>
                                                                            <p className='textinner_'>{item?.price ? item?.price : "$0.00"}</p>
                                                                        </td>
                                                                      
                                                                        <td className='variantItems'>
                                                                            <p className='textinner_'> {item?.upc ? item?.upc : "0"}</p>
                                                                        </td>
                                                                        <td className='variantItems'>
                                                                            <div className=' d-flex align-items-center justify-content-between'>
                                                                                <p className='textinner_'>{item?.weight ? item?.weight : "0"}</p>
                                                                             
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                           
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                                : <></>}
                                            
                                        </div>
                                        {/*----- End Options Area -----*/}
                                    </div>
                                    {/* End ProductDetails Area */}
                                    <div className='col-md-5'>
                                        {/* Start ProductAvailability Area */}
                                        <div className='outerArea mb-3'>
                                            <h3 className='subheaderHeading mb-4'>Product availability</h3>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            //checked={true}
                                                            onChange={() => setActivePos(!activePos)}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>JOBR POS</b> <i>(Physical store)</i></h3>
                                                </li>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input type="checkbox"
                                                            onChange={() => handleChangeJobrPos()} />
                                                    </div>
                                                    <h3 className='labelTxt'><b>JOBR Online Store</b></h3>
                                                </li>
                                                {activeOnlineStore ?
                                                    <>
                                                        <li className='productToggleItems ms-4'>
                                                            <div className="form-check form-switch me-2 mb-0">
                                                                <input type="checkbox"

                                                                    value={false}
                                                                    onChange={() => setActiveBtoc(!activeBtoc)}
                                                                />
                                                            </div>
                                                            <h4 className='storeText'>JOBR B2C</h4>
                                                        </li>
                                                        <li className='productToggleItems pl-2 ms-4'>
                                                            <div className="form-check form-switch  me-2 mb-0 ">
                                                                <input type="checkbox"
                                                                    value={false}
                                                                    onChange={() => setActiveBtob(!activeBtob)}
                                                                />
                                                            </div>
                                                            <h4 className='storeText'>JOBR B2B</h4>
                                                        </li>
                                                    </>
                                                    :
                                                    ""}
                                            </ul>
                                        </div>
                                        <div className='outerArea mb-3'>
                                            <ul className='productToggleMenus'>
                                                {/* <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="physical"
                                                            value="physical"
                                                            defaultChecked={true}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Physical Product</b></h3>
                                                </li> */}
                                                {/* <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="digital"
                                                            value="digital"
                                                            checked={type === "digital"}
                                                            onChange={(e) => { setType(e.target.value) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Digital product or service</b></h3>
                                                </li> */}
                                            </ul>
                                            {type ?
                                                <form className='productDimensions productinput mt-4'>
                                                    <div className='form-group mb-3'>
                                                        <label className="deatilsHead mb-2 fw-normal">Barcode(ISBN,UPC,GTIN,etc.)
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <input className="customform-control border" onKeyDown={blockInvalidChar} maxLength={14} placeholder="888 1235647890" name="weight" type="number" value={barCode} onChange={handleBarcodeChange} />
                                                        </div>
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <label className="deatilsHead mb-2 fw-normal">SKU (Stock Keeping Unit)
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <input className="customform-control border" placeholder="GO-123" maxLength={14} name="weight" type="text" value={sku} onChange={handleSkuChange} />
                                                        </div>
                                                    </div>
                                                </form>
                                                : ""}
                                        </div>
                                        {/* End ProductAvailability Area */}
                                        {/* Start Inventory Area */}
                                        <div className='outerArea mb-3'>
                                            <h3 className='subheaderHeading mb-4'>Inventory</h3>
                                            <form className='inventory'>
                                                <div className='form-group mb-3'>
                                                    <label className="deatilsHead mb-2 fw-normal">Inventory-Opening
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>
                                                    <div className='inputGroup'>
                                                        <input
                                                            min="1"
                                                            onKeyDown={blockInvalidChar}
                                                            className="customform-control border"
                                                            placeholder=" Quantity "
                                                            type="number"
                                                            value={inventoryOpening}
                                                            onChange={(e) => setInventoryOpening(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <label className="deatilsHead mb-2 fw-normal">Inventory-Reorder Point
                                                        <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                    </label>
                                                    <div className='inputGroup'>
                                                        <input
                                                            min="1"
                                                            onKeyDown={blockInvalidChar}
                                                            className="customform-control border"
                                                            placeholder=" Quantity reorder point"
                                                            type="number"
                                                            value={inventoryReorderPoint}
                                                            onChange={(e) => setInventoryReorderPoint(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        {/* End Inventory Area */}
                                        {/* Start Attributes Area */}
                                        {/* <div className='outerArea mb-3'>
                                            <div className='d-flex justify-content-between'>
                                                <h3 className='subheaderHeading'>Attributes</h3>
                                                <Link to='#' className='linkTxt'
                                                    onClick={() => {
                                                        setModalDetail({ show: true, flag: "AddAttributesModal" });
                                                        setKey(Math.random());
                                                    }}
                                                >
                                                    Manage
                                                    <img src={manage} className='ms-3' alt='Manage' />
                                                </Link>
                                            </div>
                                            <ul className='attributesListMenus mt-3 '>
                                                {ingredients.length > 0 ? <>
                                                    {ingredients?.map((item, index) => {
                                                        return (
                                                            <li className='attributesListItems' key={index}>
                                                                <div className='col-6'>
                                                                    <span className='subtextSmall_ me-5 col-md-6'>{item.name}</span>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <span className='subtextDark_ col-md-6'>: {item.description}</span>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </> : ""}
                                            </ul>
                                        </div> */}
                                        {/* End Attributes Area */}
                                        {/* Start ShippingPickup Area */}
                                        <div className='outerArea'>
                                            <h3 className='subheaderHeading mb-4'>Shipping or Pickup</h3>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input type="checkbox"
                                                            id="pickup"
                                                            value="pickup"
                                                            onChange={() => { handlechangeShippingOrPickup("pickup") }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Pickup</b></h3>
                                                </li>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input type="checkbox"
                                                            id="delivery"
                                                            value="delivery"
                                                            onChange={() => { handlechangeShippingOrPickup("delivery") }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Delivery</b></h3>
                                                </li>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input type="checkbox"
                                                            id="shipping"
                                                            value="shipping"
                                                            onChange={() => { handlechangeShippingOrPickup("shipping") }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Shipping</b></h3>
                                                </li>
                                            </ul>
                                            <p className='descriptionPara_ mt-4'>
                                                Enable this option if the product needs to be physically delivered to customers either via shipping or by self-pickup. If this product is a service or a downloadable item that doesn’t require delivery, keep this option disabled.
                                            </p>
                                            {/* {(deliveryOptions.length > 0 && deliveryOptions?.includes("shipping")) ? */}
                                            {(deliveryOptions.length > 0 ) ?
                                                <form className=' mt-4'>
                                                    <div className='form-group mb-3 '>
                                                        <label className="deatilsHead mb-2 fw-normal">Product weight
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='productWeight_'>
                                                            <div className='col-lg-12'>
                                                                <div className='row align-items-center '>
                                                                    <div className='col-xl-12 col-lg-12 col-md-12 pe-0'>
                                                                        <div className='inputGroup'>
                                                                            <input className="customform-control weightPlace" placeholder="lb" name="weight" type="number" value={dimensions?.weight} onChange={(e) => { handledimensions(e) }} />
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='productDimensions col-xl-3 col-lg-2 col-md-3 p-0'>
                                                                        <span className="">lb</span>
                                                                        <select className=" customform-control" onChange={(e) => handleWeightUnit(e)}>
                                                                            <option value="kg" selected="">kg</option>
                                                                            <option value="gm">gm</option>
                                                                            <option value="litre">litre</option>
                                                                            <option value="ounce">ounce</option>
                                                                        </select>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <label className="deatilsHead mb-2 fw-normal">Product Dimensions
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <ul className="dimensionsGroup mb-3">
                                                            <li className='dimensionsGroupItems'>
                                                                <label className='textinner_ mb-1'><i>Length</i></label>
                                                                <input type="number" className="form-control" placeholder="length" name="length" aria-describedby="button-addon1" value={dimensions?.length} onChange={(e) => { handledimensions(e) }} />
                                                            </li>
                                                            <li className='dimensionsGroupItems'>
                                                                <label className='textinner_ mb-1'><i>Width</i></label>
                                                                <input type="number" className="form-control" placeholder="width" name="breadth" aria-describedby="button-addon1" value={dimensions?.breadth} onChange={(e) => { handledimensions(e) }} />
                                                            </li>
                                                            <li className='dimensionsGroupItems'>
                                                                <label className='textinner_ mb-1'><i>Height</i></label>
                                                                <input type="number" className="form-control" placeholder="height" name="height" aria-describedby="button-addon1" value={dimensions?.height} onChange={(e) => { handledimensions(e) }} />
                                                            </li>
                                                            <li className='dimensionsGroupItems'>
                                                                <span className='linkText'>in</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </form>
                                                : ""}
                                        </div>
                                        {/* End ShippingPickup Area */}
                                        {/* <div className='outerArea mt-3'>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="skipPOS"
                                                            value={skipPosDetails}
                                                            onChange={(e) => { handleSkipPOSDetails(e) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Skip Details Screen in POS</b></h3>
                                                </li>
                                            </ul>
                                            <p className='descriptionPara_ mt-4'>
                                                Enable this option if the product needs to be physically delivered to customers either via shipping or by self-pickup. If this product is a service or a downloadable item that doesn’t require delivery, keep this option disabled.
                                            </p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End ProductDetails Area */}
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids=
                {
                    modalDetail.flag === "AddOptions" ? "addOptions" :
                        modalDetail.flag === "AddAttributesModal" ? "addAttributesModal" : modalDetail.flag === "saleTax" ? "saleModalTax" : ""
                }
                size={modalDetail.flag === "AddOptions" ? "xl" : "lg"}
                child=
                {
                    modalDetail.flag === 'AddOptions' ? <AddOptionsModal id={serviceId} close={() => handleOnCloseModal()} options={(attributes, data, value) => handleOptions(attributes, data, value)} combinations={combinations} selectedAttributesValue={selectedAttributesValue} handleModalChange={(e) => handleModalChange(e)}/>
                        : modalDetail.flag === 'AddAttributesModal' ? <AddAttributesModal close={() => handleOnCloseModal()} attributes={(e) => handleAttributes(e)} value={ingredients} />
                            : modalDetail.flag === 'saleTax' ? <SaleTaxModal close={() => handleOnCloseModal()} attributes={(e) => handleAttributes(e)} value={ingredients} />
                                : modalDetail.flag === 'createVariant' ? <CreateOptionModal selectedService={selectedService} serviceId={serviceId} close={() => handleOnCloseModal()} handleModalChange={(e) => handleModalChange(e)}/>
                                    : <></>
                }
                header={
                    <>
                        {modalDetail.flag === 'AddOptions'
                            ?
                            <>
                                <h3 className='subheading_ mb-2'>Variants manage</h3>
                                <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                    <i className="las la-times" aria-hidden="true"></i>
                                </span>
                            </>
                            : modalDetail.flag === 'saleTax' ? <>
                                <h4 className='modalHeading_'>Sales tax</h4>
                                <p onClick={handleOnCloseModal} className='modal_cancel'>
                                    <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                                </p>
                            </>
                                :
                                <>
                                    <h4 className='modalHeading_'>Add custom attribute</h4>
                                    <p style={{cursor:"pointer"}} onClick={handleOnCloseModal} className='modal_cancel'>
                                        <img src={modalCancel} className='ModalCancel' alt='modalcancelImg' />
                                    </p>
                                </>
                        }

                    </>

                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default AddProduct