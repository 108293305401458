import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import moment from 'moment-timezone'
import { Link  } from 'react-router-dom'
import { calendar, user } from '../../../../utilities/images'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import products from "../../../db/category.json"
import CustomModal from '../../../components/shared/CustomModal';
import AddUserModal from './addUserModal';
import AssignedUserTab from './assignedUserTab';
import RolesTab from './rolesTab';
import AddEmployeeModal from './addEmployeeModal.js';
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllUserAsync } from '../systemConfigSlice';

const StaffRole = () => {
  const [userData, setUserData] = useState("")
  const [activeTab, setActiveTab] = useState('roles');
  const [key, setKey] = useState(Math.random());
  const [loading, setLoading] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch()
  const history = useHistory()
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
      setModalDetail({
      show: false,
      title: "",
      flag: "",
      });
      setKey(Math.random());
  };

  const allUsersData = () => {
    let data = {
        tokenData: auth?.payload?.token,
    }
    setLoading(true)
    dispatch(getAllUserAsync(data))
        .then(unwrapResult)
        .then((obj) => {
            setLoading(false)
            setUserData(obj?.payload ? obj?.payload : [])
        }
        )
        .catch((obj) => {
            setLoading(false)
        })
  }
  useEffect(()=>{
    allUsersData();
  },[])

  return (
    <div className='systemConfig'>
        <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>System Config / <span className="innerSubtext">Staff Roles</span> </span>
                            </h2>
                        </div>
                        <div className="col-md-6 text-end">
                            <button className='backgroundFillbtn' onClick={() => {
                    setModalDetail({ show: true, flag: "AddNewEmplyoee" });
                    setKey(Math.random());
                  }}> <span className='plusIcon_'><i className="fa-solid fa-plus"></i> </span> Add Employee</button>
                        </div>
                    </div>
                </div>
        </div>
        <div className='row mb-4'>
          <div className='col-md-12'>
            <Link to="#" className={`${activeTab=='roles'? 'valuesTimeswallet active': ''} valuesTimeswallet`} onClick={()=>{setActiveTab('roles')}}>Roles</Link>
            <Link to="#" className={`${activeTab=='assignUser'? 'valuesTimeswallet active': ''} valuesTimeswallet`}
                    onClick={()=>{setActiveTab('assignUser')}}>Assigned Users</Link>
          </div>
        </div>
        <div className='logTabsDetails'>
            {activeTab == 'roles' 
            ?
                <RolesTab/>
            :
                <AssignedUserTab />
            }
        </div>
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "AddNewEmployeeDetails" ? "customContent commonWidth" :modalDetail.flag === "AddNewEmplyoee" ? "commonWidth customContent" : ""}
          ids={modalDetail.flag === "AddNewEmployeeDetails" ? "addNewUser" :modalDetail.flag === "AddNewEmplyoee" ? "addNewEmplyoee" : ""}
          size={modalDetail.flag === "AddNewEmployeeDetails" ? "md" : "xl"}
          child={
            modalDetail.flag === "addNewEmplyoee" ? (
              <AddUserModal close={() => handleOnCloseModal()} />
            ) :
            modalDetail.flag === "AddNewEmplyoee" ? (
              <AddEmployeeModal close={() => handleOnCloseModal()} />
            ) : (
              ""
            )
          }
          header={
            <div className='modalHeader_'>
              <div className="common_">
                  <h2 className="modalHeading">
                    Add New Employee Details
                  </h2>
                  <button className="closeButton">
                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                  </button>
              </div>
            </div>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
    </div>
  )
}

export default StaffRole