import React from 'react'
import { shield, manage, chatIcon, profileImg1 } from '../../../../utilities/images'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone';

const DriverProfile = (props) => {
  let data = props?.data;
  return (
    <>
      {
        props?.loading ?
          <span className="spinner-border inner-spin spinner-border-sm"></span>
          :
          <div className='merchantactive'>
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xl-5 col-xxl-4">
                <div className='chartsOuter mt-4'>
                  <div className='driverprofile_box'>
                    <img className='driveinfo_img mb-3' src={data?.user_profiles?.profile_photo ? data?.user_profiles?.profile_photo : profileImg1} />
                    <h4 className='boldsubSubhead'>{data?.user_profiles?.firstname + " " + data?.user_profiles?.lastname}</h4>
                    <p className='contentSubtextbold mb-2'>
                      <img src={shield} /> <span>{data?.user_profiles?.driving_licence_no}</span>
                    </p>
                    <p className='bluesubHead__ mb-3'>{data?.user_profiles?.current_address?.state + "," + data?.user_profiles?.current_address?.country}</p>
                  </div>
                  <hr></hr>
                  <div className='drivecontentInfo_'>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Registered</strong>
                      <p className='mt-2 mb-0'>{moment(data?.created_at).format("DD MMM YYYY")}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Trip Complete</strong>
                      <p className='mt-2 mb-0'>{data?.tripsCompleted}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Success Rate</strong>
                      <p className='mt-2 mb-0'>{data?.successRate ? data?.successRate : 0}%</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Total Earnings</strong>
                      <p className='mt-2 mb-0'>JBR {data?.totalEarnings}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Status</strong>
                      <p className='completeStatus ms-0 mt-2 mb-0'>{data?.is_active === true ? "Active" : "Inactive"}</p>
                    </h4>
                    <div className='bottombtn__ mt-4'>
                      <button className='chatbtnn_' type="button"><img className='chaticonn__' src={chatIcon} /> Send Message</button>
                      <button className='trackApp_' type="button"><img className='trachimg_ me-2' src={manage} /> App Track </button>

                    </div>
                  </div>

                </div>
                <div className='chartsOuter mt-5'>
                  <div className='driverPayment_'>
                    <h3 className='modalHeading mb-3'>Payment Details</h3>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Bank Account</strong>
                      <p className='connectted_ mt-2 mb-0'>Connected <i className="fas fa-check-circle"></i></p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Credit/Debit</strong>
                      <p className='mt-2 mb-0'>Not Connected  </p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Apple pay</strong>
                      <p className='mt-2 mb-0'>Not Connected  </p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Paypal</strong>
                      <p className='mt-2 mb-0'>Not Connected  </p>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-8 col-xl-7 col-xxl-8">
                <div className="chartsOuter mt-4">
                  <div className='persnlInfo_'>
                    <h3 className='modalHeading mb-3'>Personal Information</h3>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Registered Mobile</strong>
                      <p className=' mt-2 mb-0'>{data?.user_profiles?.full_phone_number}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Email </strong>
                      <p className='mt-2 mb-0'>{data?.email}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Locations</strong>
                      <p className='mt-2 mb-0'>{data?.user_profiles?.current_address?.street_address}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>SSN</strong>
                      <p className='mt-2 mb-0'>*****{data?.user_profiles?.ssn_number?.substring(5, 9)}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Gender</strong>
                      <p className='mt-2 mb-0'>{data?.user_profiles?.gender}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Age</strong>
                      <p className='mt-2 mb-0'>{data?.user_profiles?.dob ? new Date().getFullYear() - new Date(data?.user_profiles?.dob).getFullYear() : null}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Date of Birth</strong>
                      <p className='mt-2 mb-0'>{moment(data?.user_profiles?.dob).format('MMMM DD,YYYY')}</p>
                    </h4>
                  </div>
                </div>
                <div className='chartsOuter mt-3'>
                  <div className='driverLicence'>
                    <div className='verifydetailHead mb-3'>
                      <h5 className='deatilsHead'>Driver's License</h5>
                    </div>
                    <div className='licenceImg'>
                      <img className="me-4" style={{ width: "300px" }} src={data?.user_profiles?.driving_license_front_photo} />
                      <div className='drivepersonal'>
                        <h5>Talon Smith</h5>
                        <p><strong>DLN:</strong> {data?.user_profiles?.driving_licence_no}</p>
                        <h6>{data?.user_profiles?.current_address?.street_address}</h6>
                        <div className='drivepersonalInfo mt-3'>
                          <div className='row'>
                            <div className='personalinfoHead col-md-6'><strong>DOB:</strong> <span>{moment(data?.user_profiles?.dob).format('MM/DD/YYYY')}</span></div>
                            <div className='personalinfoHead col-md-6'><strong>className:</strong> <span>A</span></div>
                            <div className='personalinfoHead col-md-6'><strong>SEX:</strong> <span>{data?.user_profiles?.gender === "male" ? "M" : "F"}</span></div>
                            <div className='personalinfoHead col-md-6'><strong>ISS:</strong> <span>{moment(data?.user_profiles?.dob).format('MMMM DD,YYYY')}</span></div>
                            <div className='personalinfoHead col-md-6'><strong>HGT:</strong> <span>6'0"</span></div>
                            <div className='personalinfoHead col-md-6'><strong>EXP:</strong> <span>03/02/2022</span></div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='chartsOuter mt-3'>
                  <div className='vehicle_details'>
                    <h3 className='modalHeading mb-3'>Vehicle Details</h3>

                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Make:</strong>
                      <p className='mt-2 mb-0'>{data?.user_profiles?.vehical_make}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Model:</strong>
                      <p className='mt-2 mb-0'>{data?.user_profiles?.vehical_model}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Year:</strong>
                      <p className='mt-2 mb-0'>{data?.user_profiles?.vehical_year}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Color:</strong>
                      <p className='mt-2 mb-0'>{data?.user_profiles?.vehical_make}</p>
                    </h4>
                    <h4 className='deviceHead mb-2'>
                      <strong className='subtextDark_'>Reg No#</strong>
                      <p className=' ms-0 mt-2 mb-0'>NY 20659898</p>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>}
    </>
  )
}

export default DriverProfile