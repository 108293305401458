import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import CustomModal from '../../../components/shared/CustomModal';
import CreateNewModal from '../invoice/createNewModal';
import {
    taxes, chatIconConfig,
    msgIcon,
    printIcon
} from '../../../../utilities/images';
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { systemConfigAsync, updateUserSettingAsync } from '../systemConfigSlice';
import DashboardNavbar from '../../dashboard/DashboardNavbar';

const Invoice = () => {
    const [key, setKey] = useState(Math.random());
    const [coinData, setCoinData] = useState([]);
    const [updateData, setUpdateData] = useState([]);
    const [loading, setLoading] = useState([]);
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch()
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });


    const handleChange = (event, flag) => {
        updateStatushandle(event.target.checked, flag);
    };

    const coinDatahandle = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                app_name: "pos",
            }
        }
        setLoading(true)
        dispatch(systemConfigAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setCoinData(obj?.payload ? obj?.payload : [])
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const updateStatushandle = (checked, flag) => {
        let data = {}
        if (flag === "sms") {
            data = {
                tokenData: auth?.payload?.token,
                postData: {
                    order_invoice_sms: checked,
                },
                queryData: {
                    seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                }
            }
        } else if (flag === "email") {
            data = {
                tokenData: auth?.payload?.token,
                postData: {
                    order_invoice_email: checked,
                },
                queryData: {
                    seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                }
            }
        } else {
            data = {
                tokenData: auth?.payload?.token,
                postData: {
                    order_invoice_print: checked,
                },
                queryData: {
                    seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                }
            }
        }
        setLoading(true)
        dispatch(updateUserSettingAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setUpdateData(obj?.payload ? obj?.payload : [])
                let data = {
                    tokenData: auth?.payload?.token,
                    postData: {
                        app_name: "pos",
                    }
                }
                setLoading(true)
                dispatch(systemConfigAsync(data))
                    .then(unwrapResult)
                    .then((obj) => {
                        setLoading(false)
                        setCoinData(obj?.payload ? obj?.payload : [])
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }


    useEffect(() => {
        coinDatahandle();
    }, [])

    return (
        <>

            <DashboardNavbar title="Invoices" backShow={false} />
            <div className='configInvoice'>
                {/* <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>System Config / <span className="innerSubtext">Invoice</span> </span>
                            </h2>
                        </div>
                        <div className="col-md-6 text-end">
                            <button className='backgroundFillbtn'
                                onClick={() => {
                                    setModalDetail({ show: true, flag: "CreateNew" });
                                    setKey(Math.random());
                                }}
                            > <span className='plusIcon_'><i className="fa-solid fa-plus"></i> </span>Create New</button>
                        </div>
                    </div>
                </div>
            </div> */}
                <div className='paymentContainer'>
                    <div className='paymentArea'>
                        <div className='row'>
                            <div className='col-md-10'>
                                <div className='d-flex align-items-start'>
                                    <img src={taxes} className='paymentTypeIcon me-3' />
                                    <div className='w-100'>
                                        <h3 className='textInnerDark'>Invoice Setting</h3>
                                        <p className='subtext_ fw-normal mt-3 mb-3'>Add a curbside, drive-through, or in-store pickup. Specify your business hours, the pickup location, and add a brief instruction on how the pickup works for customers.</p>
                                        <div className='businessDetails'>
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <Link
                                                        to="">
                                                        <div className='d-flex '>
                                                            <span className='IconPin me-3'>
                                                                <img src={chatIconConfig} alt='LocationPin' />
                                                            </span>
                                                            <div className=''>
                                                                <h4 className='itemsText'>Sms to phone number </h4>
                                                                <div className=''>
                                                                    <p className='descriptionPara_'>It may apply charge </p>
                                                                </div>
                                                                {/* <div className='d-flex align-items-center mt-2'>
                                                            <img src={startDriver} alt='Star' className='me-1' />
                                                            <h6 className='subtextDark_'>Specify JOBR delivery Drivers </h6>
                                                        </div> */}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className='col-md-2 text-end'>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={coinData?.order_invoice_sms}
                                                            onChange={(e) => handleChange(e, "sms")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='businessDetails mt-3'>
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <Link
                                                        to="">
                                                        <div className='d-flex '>
                                                            <span className='IconPin me-3'>
                                                                <img src={msgIcon} alt='LocationPin' />
                                                            </span>
                                                            <div className=''>
                                                                <h4 className='itemsText'>Email </h4>
                                                                <div className=''>
                                                                    <p className='descriptionPara_'>Email will Send to buyers email address</p>
                                                                </div>
                                                                {/* <div className='d-flex align-items-center mt-2'>
                                                            <img src={startDriver} alt='Star' className='me-1' />
                                                            <h6 className='subtextDark_'>Specify JOBR delivery Drivers </h6>
                                                        </div> */}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className='col-md-2 text-end'>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={coinData?.order_invoice_email}
                                                            onChange={(e) => handleChange(e, "email")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='businessDetails mt-3'>
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <Link
                                                        to="">
                                                        <div className='d-flex '>
                                                            <span className='IconPin me-3'>
                                                                <img src={printIcon} alt='LocationPin' />
                                                            </span>
                                                            <div className=''>
                                                                <h4 className='itemsText'>Print invoice </h4>
                                                                <div className=''>
                                                                    <p className='descriptionPara_'>Needs to connect printer for print invoice</p>
                                                                </div>
                                                                {/* <div className='d-flex align-items-center mt-2'>
                                                            <img src={startDriver} alt='Star' className='me-1' />
                                                            <h6 className='subtextDark_'>Specify JOBR delivery Drivers </h6>
                                                        </div> */}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className='col-md-2 text-end'>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={coinData?.order_invoice_print}
                                                            onChange={(e) => handleChange(e, "print")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { /*    <div className='paymentContainer'>
     <div className='paymentArea'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='d-flex align-items-start'>
                            //  <img src={taxes} className='paymentTypeIcon me-3' /> 
                            <div className='w-100'>
                                <h3 className='textInnerDark'>Invoice Template</h3>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='invoiceCon mt-3'>
                                            <div className='dFlex w-100'>
                                                <div className='editLegalTxt'>
                                                    <h1 className='descriptionPara_ fw-normal'>Last update date:</h1>
                                                    <p><span className='commonsmallgeryHead_ me-3'>Sep 27, 2023</span> <span className='commonsmallgeryHead_'>10: 00 AM</span></p>
                                                </div>
                                                <button className='editBtn_'><img src={penIcon} alt=""/>Edit</button>
                                            </div>
                                            <div className='invoicefoot mt-4'>
                                                <img src={invoiceImg} alt="" className='invoiceImg'/>
                                                <div className='invoiceFootTxt mt-2'>
                                                    <span className='tablesubHead me-3'>Active :</span>
                                                    <ul className='invoicSocial mb-0'>
                                                        <li>
                                                            <div className='socialIcon'>
                                                                <img src={emailIconSm} alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='socialIcon'>
                                                                <img src='' alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='socialIcon'>
                                                                <img src={printIconSm} alt=""/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                            
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='invoiceCon mt-3'>
                                            <div className='dFlex w-100'>
                                                <div className='editLegalTxt'>
                                                    <h1 className='descriptionPara_ fw-normal'>Last update date:</h1>
                                                    <p><span className='commonsmallgeryHead_ me-3'>Sep 27, 2023</span> <span className='commonsmallgeryHead_'>10: 00 AM</span></p>
                                                </div>
                                                <button className='editBtn_'><img src={penIcon} alt=""/>Edit</button>
                                            </div>
                                            <div className='invoicefoot mt-4'>
                                                <img src={invoiceImg} alt="" className='invoiceImg'/>
                                                <div className='invoiceFootTxt mt-2'>
                                                    <span className='tablesubHead me-3'>Active :</span>
                                                    <ul className='invoicSocial mb-0'>
                                                        <li>
                                                            <div className='socialIcon'>
                                                                <img src={emailIconSm} alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='socialIcon'>
                                                                <img src='' alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='socialIcon'>
                                                                <img src={printIconSm} alt=""/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
         </div>    */}
                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    backdrop={true}
                    showCloseBtn={false}
                    isRightSideModal={false}
                    mediumWidth={false}
                    className={modalDetail.flag === "CreateNew" ? "commonWidth customContent" : ""}
                    ids={modalDetail.flag === "CreateNew" ? "createNewModal" : ""}

                    child={

                        modalDetail.flag === "CreateNew" ? (
                            <CreateNewModal close={() => handleOnCloseModal()} />
                        ) :

                            (
                                ""
                            )
                    }
                    // header={
                    //     <>
                    //         <div className='modalHeader_'>
                    //             <div className="common_">
                    //                 {modalDetail.flag === "CreateNew" ?
                    //                     <div className='anotherHeader'>
                    //                         <i className="las la-angle-left"></i>
                    //                         <h2 className="innerSubheadNormal">Invoice#</h2>
                    //                         <span className="completeStatus">Delivered</span>
                    //                     </div>
                    //                     : ""
                    //                 }
                    //                 <button className="closeButton">
                    //                     <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                    //                 </button>
                    //             </div>
                    //         </div>
                    //     </>
                    // }

                    onCloseModal={() => handleOnCloseModal()}
                />
            </div>
        </>
    )
}

export default Invoice