import React from 'react'
import { bigTick } from "../../../../utilities/images";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SuccessfulCreated = () => {
 const history = useHistory();
  return (
  <>
    <div className='successFullCreate'>
     <div className='bigTickSection'>
        <img src={bigTick} className='img-fluid bigTickImg'/>
     </div>
     <div className='sucessHeading_'>
     <h4 className='successMain'>Successfully Created</h4>
     <p className='successSub'>Please Login Back </p>
     </div>
     <button onClick={()=>history.push("/")} className='loginBtnSign w-100'>Back to login</button>
    </div>
  </>
  )
}

export default SuccessfulCreated