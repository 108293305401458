import React from "react";
import * as Images from '../../../../utilities/images';
// import Image from "next/image";
// import { useRouter } from "next/router";
// import { selectReturnData } from "../../redux/slices/productReturn";
import { useSelector } from "react-redux";
import { selectReturnData } from "./returnOrderSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DashboardNavbar from "../../dashboard/DashboardNavbar";

const ConfirmationSuccess = () => {
    const history = useHistory()
  const invoiceData = useSelector(selectReturnData);
  const invoiceNumber = invoiceData?.invoiceByInvoiceId?.payload?.invoice_number;

  console.log(invoiceData,invoiceData?.invoiceByInvoiceId?.payload?.invoice_number,"ddddddddddddddddddd");
  return (
    <>
         <DashboardNavbar title="Product Refund" backShow={false} />
      <div className="ConfirmationSuccess">
        <div className="commanOuter">
          <div className="flexBox returninvoiceBtn_">
            {/* <button
              type="button"
              className="backButton"
              onClick={() => {
                router.back();
              }}
            >
              <Image
                src={Images.ArrowLeft}
                alt="backBtnIcon"
                className="img-fluid backBtnIcon"
              />
              Back
            </button> */}
            <button
              type="button"
              className="backButton ms-4"
              onClick={() => history.push("/productRefund")}
            >
              {/* <Image
                src={Images.InvoiceIcon}
                alt="InvoiceIcon"
                className="img-fluid InvoiceIcon me-1"
              /> */}
              Return to Invoices
            </button>
          </div>
          <div className="ReturnConfirmed">
            <div className="ReturnConfirmedBox">
              <img
                src={Images.Verfidsign}
                className="img-fluid SuccessTick"
                alt="SuccessTick"
              />
              <h5 className="returnConfirmedHeading">Return Confirmed</h5>
              <p className="userPosition">
                Invoice No. # {invoiceNumber} return
                successfully Completed!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationSuccess;
