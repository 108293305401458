import React, { useEffect, useState } from 'react'
import { trackuser, ticketImg, products, trackMap_, user1, profileImg1 } from '../../../../utilities/images'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import DataTable from 'react-data-table-component'
import { getOrderTrackingApiAsync } from '../../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoginAuth } from '../../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'

const PurchaseView = (props) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [purchaseTracking, setPurchaseTracking] = useState("")
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const auth = useSelector(selectLoginAuth)
  const dispatch = useDispatch()
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const trackingRouteHandle = (deliveryOption, orderId, trackUrl) => {
    if (deliveryOption == 4) {
      window.open(trackUrl, '_blank');
    } else {
      history.push(`/trackingMap/${orderId}`)
    }
  }

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const columns1 = [
    {
      selector: row => {
        return (
          <>
          <div className='row'>
            <div className='col-md-4'>
              <div className='trackContent_' onClick={() => trackingRouteHandle(row?.delivery_option, row?.id, row?.trackurl)}>
                <div className='orderdeliveryTrack_'>
                  <div className='ordertrackTop  orderTrackUser'>
                    <h3 className='modalHeading '><img className='trackingUser me-3' style={{width:"50px"}} src={row?.user_details?.user_profiles?.profile_photo ? row?.user_details?.user_profiles?.profile_photo : profileImg1} />
                     <span className='boldsubSubhead'>{`${row?.user_details?.user_profiles?.firstname} ${row?.user_details?.user_profiles?.lastname}`}</span> 
                    </h3>
                    <hr />
                    <div className='trackItems_'>
                      <h5 className='mainlightPhragraph'>
                        <img className='tick1 me-2' src={ticketImg} />
                        <span className=''><span className='blueitem_'>$</span> {row?.payable_amount}</span>
                      </h5>
                      <h5 className='mainlightPhragraph'>
                        <img className='products_ me-2' src={products} />
                        <span className=''><span className='blueitem_'>{row?.total_items}</span  > Items</span>
                      </h5>
                    
                    </div>
                    <div className='trackBottom_ mt-3'>
                      <img className='mapImges_ img-fluid' src={trackMap_} />
                      <div className='mapdeatils_'>
                        {
                          row?.tracking_number ? 
                          <h5 className='tablesubHead mb-2'>#{row?.tracking_number}</h5>:""
                        }
                        <h4 className='dliverBox_'><span className='pendingStatus'>Delivery: {row?.invoice?.delivery_date}</span> <img className='userImmg_' src={(row?.delivery_option == 1 && row?.driver_details?.profile_photo) ? row?.driver_details?.profile_photo : (row?.delivery_option == 4 && row?.shipping_details?.image) ? row?.shipping_details?.image : profileImg1} /></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let data = {
      postData: {
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        // seller_id: "98c7398d-a270-4a66-a925-5c75d7554e7c",
        page: currentPage,
        limit: perPage,
        need_purchases: true
      },
      tokenData: auth?.payload?.token
    }

    if (props?.startDate != null && props?.endDate != null) {
      let newData = {
        ...data.postData,
        "start_date": moment(props?.startDate).format('YYYY-MM-DD'),
        "end_date": moment(props?.endDate).format('YYYY-MM-DD')
      }
      data = {
        tokenData: auth?.payload?.token,
        postData: newData
      }
    }
    else {
      data = {
        "postData": {
          seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
          // seller_id: "98c7398d-a270-4a66-a925-5c75d7554e7c",
          page: currentPage,
          limit: perPage,
          need_purchases: true
        },
        "tokenData": auth?.payload?.token
      }
    }
    setLoading(true)
    dispatch(getOrderTrackingApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setPurchaseTracking(obj?.payload?.data)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }, [perPage, currentPage, props?.endDate])

  return (
    <div className='ordertrackingview_ '>
      <div className='row'>
        <DataTable
        //  className='columsGrid'
          pagination
          columns={columns1}
          progressPending={loading}
          data={purchaseTracking}
          // defaultSortFieldId={1}
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        // onRowClicked={handleRowClick}
        />
      </div>
    </div>
  )
}

export default PurchaseView