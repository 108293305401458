import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { bellimgg } from '../../../../utilities/images'
import AppointmentToday from './appointmentToday'
import TomorrowAppointment from './tomorrowAppointment'
import CustomModal from '../../../components/shared/CustomModal';
import AppointmentRequest from './appointmentRequest'
import { changeAppointmentStatusApiAsync, getAppointemntApiAsync } from '../../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { selectLoginAuth } from '../../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import AppointSchedule from './appointSchedule'
import { Badge } from 'react-bootstrap'
// import Badge from "@material-ui/core/Badge"

const TodayAppontments = () => {
    const [activeTab, setActiveTab] = useState('today');
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [appointmentData, setAppointmentData] = useState([]);
    const [appointmentMorningData, setAppointmentMorningData] = useState([]);
    const [appointmentNoonData, setAppointmentNoonData] = useState([]);
    const [newAppointmentRequests, setNewAppointmentRequests] = useState([]);
    const [singleAppointment, setSingleAppointment] = useState("");
    const dispatch = useDispatch();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleOpenModal = (flag, data) => {
        setSingleAppointment(data)
        setModalDetail({ show: true, flag: flag });
        setKey(Math.random());
    }

    const changeStatusOfAppointmentHandle = (id, status, modalIs) => {
        let orderStatus = {
            "postData": {
                id: id,
                status: status
            },
            "tokenData": auth?.payload?.token
        }
        status === 3 ? setLoading1(true) : setLoading2(true)
        dispatch(changeAppointmentStatusApiAsync(orderStatus))
            .then(unwrapResult)
            .then((obj) => {
                let params = {}
                if (modalIs === 'newRequest') {
                    params = {
                        "postData": {
                            status: 0,
                            // need_upcoming: true,
                            // filter_by: 'year',
                            seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                        },
                        "tokenData": auth?.payload?.token
                    }
                } else {
                    params = {
                        "postData": {
                            status: 1,
                            need_upcoming: true,
                            filter_by: activeTab,
                            seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                        },
                        "tokenData": auth?.payload?.token
                    }
                }
                dispatch(getAppointemntApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        if (modalIs === 'newRequest') {
                            setNewAppointmentRequests(obj?.payload?.data)
                            handleOnCloseModal()
                            let paramData = {
                                "postData": {
                                    status: 1,
                                    need_upcoming: true,
                                    filter_by: activeTab,
                                    seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
                                },
                                "tokenData": auth?.payload?.token
                            }
                            dispatch(getAppointemntApiAsync(paramData))
                                .then(unwrapResult)
                                .then((obj) => {
                                    let morningData = obj?.payload?.data?.filter(items => items?.start_time?.split(" ")[1] === 'AM')
                                    let afterNoonData = obj?.payload?.data?.filter(items => items?.start_time?.split(" ")[1] === 'PM')
                                    setAppointmentData(obj?.payload?.data)
                                    setAppointmentMorningData(morningData)
                                    setAppointmentNoonData(afterNoonData)
                                    setLoading(false)
                                }
                                )
                                .catch((obj) => {
                                    setLoading(false)
                                })
                        } else {
                            let morningData = obj?.payload?.data?.filter(items => items?.start_time?.split(" ")[1] === 'AM')
                            let afterNoonData = obj?.payload?.data?.filter(items => items?.start_time?.split(" ")[1] === 'PM')
                            setAppointmentData(obj?.payload?.data)
                            setAppointmentMorningData(morningData)
                            setAppointmentNoonData(afterNoonData)
                        }
                        setLoading1(false)
                        setLoading2(false)
                        // handleOnCloseModal()
                        setModalDetail({ show: false, flag: "" });
                        setKey(Math.random());
                    }
                    )
                    .catch((obj) => {
                        setLoading1(false)
                        setLoading2(false)
                    })

            }
            )
            .catch((obj) => {
                setLoading1(false)
                setLoading2(false)
            })
    }

    const newAppointmentDataHandle = () => {
        let params = {
            "postData": {
                status: 0,
                need_upcoming: true,
                // filter_by: activeTab,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getAppointemntApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setNewAppointmentRequests(obj?.payload?.data)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const appointmentDataHandle = () => {
        let params = {
            "postData": {
                status: 1,
                need_upcoming: true,
                filter_by: activeTab,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(getAppointemntApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                let morningData = obj?.payload?.data?.filter(items => items?.start_time?.split(" ")[1] === 'AM')
                let afterNoonData = obj?.payload?.data?.filter(items => items?.start_time?.split(" ")[1] === 'PM')
                setAppointmentData(obj?.payload?.data)
                setAppointmentMorningData(morningData)
                setAppointmentNoonData(afterNoonData)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        appointmentDataHandle()
    }, [activeTab])

    useEffect(() => {
        newAppointmentDataHandle()
    }, [])
    return (
        <>
            <div className='todayAppoint_'>
                <div className='topHeader_'>
                    <div className="topStickyHeader_ mb-3">
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <h2 className="mainHeading">Appointments /<span className='innerSubtext'> Today’s Appointments</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='midHeader_ mb-5'>
                    <div className="topStickyHeader_ mt-5 mb-3">
                        <div className="row  align-items-center">
                            <div className="col-md-6">
                                <Link to="#" className={`${activeTab == 'today' ? 'contentheadtext_ active' : ''} contentheadtext_ me-3`}
                                    onClick={() => { setActiveTab('today') }}>Today’s Appointments {activeTab === "today" && appointmentData ? `(${appointmentData?.length})` : ""}</Link>
                                <Link to="#" className={`${activeTab == 'tomorrow' ? 'contentheadtext_ active' : ''} contentheadtext_`}
                                    onClick={() => { setActiveTab('tomorrow') }}>Tomorrow’s Appointments {activeTab === "tomorrow" && appointmentData ? `(${appointmentData?.length})` : ""}</Link>
                            </div>
                            <div className="col-md-6 text-end">
                                <button className='appointBtn appointCustomBtn' onClick={() => {
                                    setModalDetail({ show: true, flag: "AppointmentRequest" });
                                    setKey(Math.random());
                                }}><div className='notiCount'>{newAppointmentRequests ? newAppointmentRequests?.length : 0}</div>New Requests <img className='bellImg' src={bellimgg} /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {activeTab == 'today'
                    ?
                    <AppointmentToday details={(e, val) => handleOpenModal(e, val)} morningData={appointmentMorningData} noonData={appointmentNoonData} data={appointmentData} loading={loading} />
                    :
                    <TomorrowAppointment details={(e, val) => handleOpenModal(e, val)} morningData={appointmentMorningData} noonData={appointmentNoonData} data={appointmentData} loading={loading} />
                }
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "AppointmentRequest" ? "appointmentRequest" :
                    modalDetail.flag === "AppointSchedule" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "AppointmentRequest" ? "AppointmentRequest" :
                    modalDetail.flag === "AppointSchedule" ? "AppointSchedule" : ""}
                child={
                    modalDetail.flag === "AppointmentRequest" ? (
                        <AppointmentRequest stateLoading1={loading1} stateLoading2={loading2} statusChange={(a, b, c) => changeStatusOfAppointmentHandle(a, b, c)} data={newAppointmentRequests} close={() => handleOnCloseModal()} />
                    ) : modalDetail.flag === "AppointSchedule" ? (
                        <AppointSchedule stateLoading1={loading1} stateLoading2={loading2} statusChange={(a, b, c) => changeStatusOfAppointmentHandle(a, b, c)} data={singleAppointment} close={() => handleOnCloseModal()} />
                    ) : (
                        ""
                    )
                }
            />
        </>
    )
}

export default TodayAppontments