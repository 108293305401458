import React, { useState } from 'react'
import { incoiceuser_, profileImg1, serviceuser_ } from '../../../../utilities/images';
import moment from 'moment-timezone';

const AppointSchedule = (props) => {
    let data = props?.data;
    return (
        <div className="scheduleModal">
            <div className="scheduleModalContent">
                {
                    data?.user_details ?
                        <div className='invoiceUser_'>
                            <img style={{ width: "50px" }} className='invoveuser me-3' src={data?.user_details?.profile_photo ? data?.user_details?.profile_photo : profileImg1} />
                            <article className='ivoiceuserDetail_'>
                                <h4 className='monshead14'>{data?.user_details?.firstname + " " + data?.user_details?.lastname}</h4>
                                {data?.user_details?.current_address && <p className='monshead12'>{data?.user_details?.current_address?.street_address}</p>}
                            </article>
                        </div> : <>Not Available</>}
                <div className='serviceDeatils_'>
                    <div className='serviceinfo_'>
                        <img style={{ width: "50px" }} className='img-fluid me-3' src={data?.pos_user_details?.user?.user_profiles?.profile_photo ? data?.pos_user_details?.user?.user_profiles?.profile_photo : profileImg1} />
                        <article className='duration_'>
                            <h3 className='lightgreyHeading_'>{data?.product_name}</h3>
                            <h4 className='subtextSmall_'><em>{moment.utc(data?.date).format("MMMM DD, YYYY")} @ {data?.start_time + "-" + data?.end_time} </em></h4>
                            <span className='designation_'>Duration: {data?.approx_service_time + " min"}</span>
                            <p className='designation_'>Staff: <span className='lightgreyHeading_'>{data?.pos_user_details?.user?.user_profiles?.firstname + " " + data?.pos_user_details?.user?.user_profiles?.lastname}</span></p>
                        </article>
                    </div>
                    <div className='servicechanges_'>
                        <span className='serciceprice_'>${data?.actual_price}</span>
                        <span className='serciceprice_'>{data?.qty}</span>
                        <span className='serciceprice_'>${data?.price}</span>
                    </div>
                </div>
            </div>
            <div className='grandTotal_ row'>
                <div className="col-md-5 text-start">
                    <div className='orderinvoive'>
                        <h5 className='lightgreyHeading_'>Total Item</h5>
                        <h4 className='monshead22'>{data?.qty}</h4>
                        <h5 className='lightgreyHeading_'>Order Date</h5>
                        <h4 className='monshead22'>{moment.utc(data?.date).format("MM/DD/YYYY")}</h4>
                        <h5 className='lightgreyHeading_'>Invoice No.</h5>
                        <h4 className='monshead22'>{data?.invoices?.invoice_number}</h4>
                    </div>
                </div>

                <div className="col-md-7 text-start">
                    <div className='orderBill_'>
                        <div className="Customertotal">
                            <div className="totalDetails">
                                <h5 className="lightgreyHeading_ fw-semibold">Sub Total</h5>
                                <h5 className="lightgreyHeading_ fw-semibold">${data?.actual_price}</h5>
                            </div>
                            {/* <div className="totalDetails">
                                <h5 className="textInnerHead2">Discount </h5>
                                <h5 className="personalinfoHead">-${data?.discount}</h5>
                            </div> */}
                            {/* <div className="totalDetails">
                                <h5 className="textInnerHead2"> Other Fee</h5>
                                <h5 className="personalinfoHead">$4.00</h5>
                            </div> */}
                            {/* <div className="totalDetails">
                                <h5 className="textInnerHead2">Tax</h5>
                                <h5 className="personalinfoHead">${data?.tax ? data?.tax : 0}</h5>
                            </div> */}
                            {/* <div className="totalDetails">
                                <h5 className="textInnerHead2">Tips</h5>
                                <h5 className="personalinfoHead">${data?.tips ? data?.tips : 0}</h5>
                            </div> */}
                            <hr className='dashedrow'></hr>
                            <div className="totalDetails">
                                <h5 className="itemsText ">Total</h5>
                                <h5 className="itemsText">${data?.price}</h5>
                            </div>
                            <button className={data?.status === 3 ? "acceptBtn_ me-2" : "cancelledBtn_"} type="submit" disabled><span>{data?.status === 3 ? "Completed" : data?.status === 4 ? "Rejected" : "Cancelled"}</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointSchedule