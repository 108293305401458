import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import DatePicker from "react-datepicker";
import { calendar, productList1, productList2, productList3, OrderDown, productList4 } from '../../../utilities/images'
import OrderTrackinglist from './orderTrackinglist';
import { SortOrder } from 'react-data-table-component';
import OrderTrackingview from './orderTrackingview';
import DashboardNavbar from '../dashboard/DashboardNavbar';

const Tracking = () => {
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [activeTab, setActiveTab] = useState("MonthlyBilling");
    const [monthlyPlan, setMonthlyPlan] = useState([])
    const [yearlyPlan, setYearlyPlan] = useState([])
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Trackings";
      }, []);
    return (
        <>

            <DashboardNavbar title="Order Tracking" backShow={false} />
            <div className='orderTrack'>
                {/* <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="mainHeading">
                                <span>Order Tracking</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div> */}
                <div className='tableSectionBackground'>
                    <div className='tableFilters '>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                <div className='productMainCategory tracking_'>
                                        <div className='categroyBox dateCategory'>
                                            <div className="calenderIcon campCalendar">
                                                <img src={calendar} alt="calender" className='campcalendarImg' />
                                                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                                        dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                                <DatePicker
                                                    className='cateDate'
                                                    selected={startDate}
                                                    onChange={handleDateChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    placeholderText="Date"
                                                />
                                                <img src={OrderDown} alt="calender" className='calenderDownIcon' />
                                            </div>
                                        </div>


                                        {/* <div className='categroyBox'>
                                            <select className="cateSelectdFilter" required>
                                                <option value="" selected>
                                                    All Locations
                                                </option>
                                                <option>L1</option>
                                                <option>L2</option>
                                            </select>
                                        </div> */}
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='productListTab'>
                                        <button className={`plansBtns ${activeTab === 'MonthlyBilling' ? 'active' : ""}`}
                                            onClick={() => setActiveTab('MonthlyBilling')}
                                        >
                                            <img src={productList1} alt="image" className='showImage' />
                                            <img src={productList2} alt="image" className='hideImage' />
                                        </button>
                                        <button className={`plansBtns ${activeTab === 'AnnuallyBilling' ? 'active' : ""}`}
                                            onClick={() => setActiveTab('AnnuallyBilling')}>
                                            <img src={productList3} alt="image" className='hideImage' />
                                            <img src={productList4} alt="image" className='showImage' />
                                        </button>
                                    </div>
                                </div>
                                {/* <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        Country
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        State
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <select className="cateSelectdFilter" required>
                        <option value="" selected>
                        Id type
                        </option>
                        <option>Category1</option>
                        <option>Category2</option>
                    </select>
                </div> */}
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-12'>
                        <div className='plansAreasContainer'>
                            {activeTab == 'MonthlyBilling'
                                ?
                                <OrderTrackinglist startDate={startDate} endDate={endDate} data={monthlyPlan} />
                                : activeTab == "AnnuallyBilling" ?
                                    <OrderTrackingview startDate={startDate} endDate={endDate} data={yearlyPlan} />
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tracking
