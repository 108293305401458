import React, { useState } from 'react'
import moment from 'moment-timezone';
import { calendar } from '../../../../utilities/images';
import CustomModal from '../../../components/shared/CustomModal';
import InventoryaddnewModal from './inventoryaddnewModal';
import { toast } from "react-toastify";

const UpdateinventoryModal = (props) => {
  let data = props?.data;
  const toastId = React.useRef(null);
  const [newInventory, setNewInventory] = useState();
  const [selectedOption, setSelectedOption] = useState('');
  const options = ['packet', 'box'];

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const clickHandle = (a, b, c) => {
    if (!newInventory) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter Quantity")
      }
      return
    }
    if (!selectedOption) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select One Option")
      }
      return
    }
    props.inventoryUpdateData(a, b, c, "InventoryaddnewModal")
  }

  return (
    <>
      <div className='updateinventModal'>
        <div className='newinventoryModal commonPadding_'>
          <div className='selectedProduct_ mb-4'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='productContent'>
                  <div className='productList mb-3'>
                    <h5 className='textInner mb-1'>Product name:</h5>
                    <h6 className='textinner_'>{data?.name}</h6>
                  </div>
                  <div className='productList mb-3'>
                    <h5 className='textInner mb-1'>Brand:</h5>
                    <h6 className='textinner_'>{data?.brand?.name}</h6>
                  </div>
                  <div className='productList mb-3'>
                    <h5 className='textInner mb-1'>Sub-category:</h5>
                    <h6 className='textinner_'>{data?.sub_category?.name}</h6>
                  </div>
                  <div className='productList mb-3'>
                    <h5 className='textInner mb-1'>Category:</h5>
                    <h6 className='textinner_'>{data?.category?.name}</h6>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='addingNewiventory_'>
                  <p className='modalHeading'>Stock in Hand</p>
                  <h2 className='mainBoldHeading'>{data?.supplies ? data?.supplies[0]?.rest_quantity : ""}<span className='blacksemiHead_'>Box</span></h2>
                  <p className='mainlightHead'>You are going to add Inventory</p>

                </div>
              </div>
            </div>
          </div>
          <div className='trackquantity_add'>
            <div className='row'>
              <div className='col-md-12'>
                <h5 className='buyHeading mb-5'>Track Inventory for this item </h5>
                <div className='inputInvenmtory'>
                  <div className='inventLeft'>
                    <label className="infoHeading ">Add New Inventory</label>
                    <input
                      type="text"
                      className="customform-control inventoryInput"
                      name="text"
                      placeholder=""
                      value={newInventory}
                      onChange={(e) => setNewInventory(e.target.value)}
                    />
                  </div>
                  <div className='inventRight'>
                    <label className="infoHeading ">Reason</label>
                    <div className='rightLabel '>
                      <select className="customform-control" value={selectedOption} onChange={handleSelectChange} required>
                        <option value="">Select an option</option>
                        {options.map((option, index) => (
                          <option key={index} value={option}> {option} </option>
                        ))}
                        {/* <option value="" selected>Select </option>
                        <option onClick={() => handleFilterValue("Category1")}>Category1</option>
                        <option onClick={() => handleFilterValue("Category2")}>Category2</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <button className='acceptBtn_ mt-3' onClick={() => clickHandle(data?.id, newInventory, selectedOption)}>Add Inventory</button>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default UpdateinventoryModal
