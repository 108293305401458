import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllPlans,
  getAllSaveCard,
  getAddCard,
  subscriptionBuy,
  createWalletApi,
  requestKyc,
  checkKyc,
  getDocumentType,
  uploadKycDocument,
  getIdCardPhoto,
  getBusinessTypes,
  getNaicsCategories,
  businessRegistrationApi,
  generatePlaidTokenApi,
  getBankAccountApi,
  getConnectBankAccountInfoApi,
  linkBankAccount,
  businessKycApi,
  uploadbusinessDocument,
  setupAddressApi,
  updateSellerInfoApi,
  addYourDriverApi,
  verifyDriverApi,
  getWalletInventoryGraphApi,
  getWalletStatisticsApi,
  getAttributeApi,
  getBrandOfCategory,
  getCategories,
  getProductes,
  getMerchantStatistics,
  getMerchantOrderGraph,
  getAttributesApi,
  getAttributesByIdApi,
  getAllOrdersApi,
  searchByInvoiceIdApi,
  searchBySkuApi,

  getSevicesApi,
  createProductApi,
  changeStatusApi,
  getWalletTransectionsApi,
  getAppointemntApi,
  getAllShippingServiceApi,
  addFedexServiceApi,
  getAddedToInventoryProducts,
  addProductsToInventory,
  changeShippingStatusApi,
  updateShippingAddressStatusApi,
  importFileApi,
  changeAppointmentStatusApi,
  getAppointmentCountApi,
  getAppointmentGraphApi,
  getWalletBallanceApi,
  issueSilaBalanceToYourWallet,
  getPosCountApi,
  getPosListingApi,
  getInventoryCountApi,
  getInventoryDetailsApi,
  getDriverCountApi,
  getInventorySalePurchaseStatisticsApi,
  getInventoryDetailsByIdApi,
  createCatalogFolderApi,
  getAllFoldersApi,
  deleteFolderApi,
  updateFolderApi,
  getSingleFolderDataApi,
  uploadFileInCatelog,
  createCatalogApi,
  getAllCatalogsApi,
  deleteCatalogApi,
  updateCatalogApi,
  getStorageDataApi,
  getPosGraphApi,
  getSalesPerPosApi,
  getSubscriberApi,
  getDriverProfileApi,
  getManufactureDetailsByIdApi,
  getPurchaseGraphApi,
  getManufactureRetailerWholeSellerApi,
  changeSellerAddressStatus,
  getConsumerDataApi,
  getPurchaseListApi,
  getSupportDataApi,
  getSupportTicketDataApi,
  getDepartmentDataInSupportApi,
  getSupportAgentsApi,
  assignSupportAgentApi,
  getSingleOrderPurchaseApi,
  reportAnIssueApi,
  deleteSupportApi,
  getSingleSupportDataApi,
  getSupportTicketCountApi,
  getCashFlowStatisticsApi,
  getTransactionStatisticsApi,
  getAnalyticStatDataApi,
  getOrderTrackingApi,
  buySubscriptionApi,
  getMerchantStepStatus,
  getMerchantActiveSubscription,
  getJbrUsdTransactionsApi,
  deleteBankAccountApi,
  checkbankBalanceApi,
  createProductOfferApi,
  getProductOfferListApi,
  getWithdrawalDataApi,
  redeemBalanceToYourBank,
  updateInventoryApi,
  deleteProductApi,
  getWalletRefundDataApi,
  getFaqDataApi,
  getFaqCategoryDataApi,
  updateFaqCategoryApi,
  updateFaqApi,
  getFaqCategoryTagsApi,
  createFaqCategoryApi,
  createFaqApi,
  deleteFaqCategoryApi,
  deleteFaqyApi,
  getSingleOrderTrackingApi,
  getRecievedCatalogApi,
  getSingleRecievedCatalogApi,
  staffPaymentApi,
  deleteFolderInRecievedCatalogApi,
  quickbookGetUriApi,
  quickbookSyncDataApi,
  quickbookGetTokenApi,
  deleteFileInRecievedCatalogApi,
  createSupportAgentApi,
  getAllSupportAgentsApi,
  deleteSupportAgentApi,
  updateSupportAgentApi,
  staffPayApi,
  getNewUserStatisticsApi,
  getAnalyticOrdersApi,
  totalAnalyticsProductSoldApi,
  totalAnalyticsInventoryApi,
  totalAnalyticsOrderApi,
  resheduleBookingApi,
  getBookingTimeSlotsApi,
  getMerchantTotalCustomerGraph,
  getMerchantTotalOrderGraph,
  editProductApi,
  getPluginsList,
  getOrderWalletTransactions,
  getOrderDetails,
  editProductSupplyApi,
  getAllCustomers,
  getOrderDetailById,
  returnToInventoryInOrder,
  deleteProductSupply,
  createProductSupplyApi,
  createAttributeApi,
  verifyOtpInOrderApi,
  deleteOfferApi,
  updateProductOfferApi,
  getTrendingArticleApi,
  createCustomerApi,
  getAllInvitedCustomerApi,
  getMarketStatusApi,
  updateMarketStatusApi,
  getNotificationList,
  getProductCouponListApi,
  deleteCouponApi,
  updateProductCouponApi,
  getAllGiftCard,
  createGiftCardApi,
  updateGiftCardApi,
  deleteGiftCardApi,
  getMerchantSettings,
  settingUpdateApi,
  cardDeleteApi
} from "./dashboardApi";

const initialState = {
  status: "idle",
  walletProfile: null,
  merchantStatus: null
};

export const getAllPlansAsync = createAsyncThunk("auth/getAllPlans", async (data) => {
  const response = await getAllPlans(data);
  return response.data;
});
export const getAllSaveCardAsync = createAsyncThunk("auth/getAllSaveCard", async (data) => {
  const response = await getAllSaveCard(data);
  return response.data;
});
export const getAddCardAsync = createAsyncThunk("auth/getAddCard", async (data) => {
  const response = await getAddCard(data.postData, data.tokenData);
  return response.data;
});
export const subscriptionBuyAsync = createAsyncThunk("auth/subscriptionBuy", async (data) => {
  const response = await subscriptionBuy(data.postData, data.tokenData);
  return response.data;
});
export const createWalletProfileAsync = createAsyncThunk("auth/createWalletApi", async (data) => {
  const response = await createWalletApi(data.postData, data.tokenData);
  return response.data;
});
export const requestKycAsync = createAsyncThunk("auth/requestKyc", async (data) => {
  const response = await requestKyc(data);
  return response.data;
});
export const checkKycAsync = createAsyncThunk("auth/checkKyc", async (data) => {
  const response = await checkKyc(data);
  return response.data;
});
export const getDocumentTypeAsync = createAsyncThunk("auth/getDocumentType", async (data) => {
  const response = await getDocumentType(data);
  return response.data;
});
export const uploadKycDocumentAsync = createAsyncThunk("auth/uploadKycDocument", async (data) => {
  const response = await uploadKycDocument(data.postData, data.tokenData);
  return response.data;
});
export const uploadIdCardAsync = createAsyncThunk("idCard/photo", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});
export const uploadIdCardAsync1 = createAsyncThunk("idCard/photo1", async (data) => {
  const response = await getIdCardPhoto(data);
  return response.data;
});

export const getBusinessTypesAsync = createAsyncThunk("auth/getBusinessTypes", async (data) => {
  const response = await getBusinessTypes(data);
  return response.data;
});

export const getNaicsCategoriesAsync = createAsyncThunk("auth/getNaicsCategories", async (data) => {
  const response = await getNaicsCategories(data);
  return response.data;
});

export const businessRegistrationApiAsync = createAsyncThunk("auth/businessRegistrationApi", async (data) => {
  const response = await businessRegistrationApi(data.postData, data.tokenData);
  return response.data;
});

export const businessKycApiAsync = createAsyncThunk("auth/businessKycApi", async (data) => {
  const response = await businessKycApi(data);
  return response.data;
});

export const uploadbusinessDocumentAsync = createAsyncThunk("auth/uploadbusinessDocument", async (data) => {
  const response = await uploadbusinessDocument(data.postData, data.tokenData);
  return response.data;
});
export const generatePlaidTokenApiAsync = createAsyncThunk("auth/generatePlaidTokenApi", async (data) => {
  const response = await generatePlaidTokenApi(data);
  return response.data;
});
export const linkBankAccountAsync = createAsyncThunk("auth/linkBankAccount", async (data) => {
  const response = await linkBankAccount(data.postData, data.tokenData);
  return response.data;
});
export const getBankAccountApiAsync = createAsyncThunk("auth/getBankAccountApi", async (data) => {
  const response = await getBankAccountApi(data);
  return response.data;
});
export const getConnectBankAccountInfoApiAsync = createAsyncThunk("auth/getConnectBankAccountInfoApi", async (data) => {
  const response = await getConnectBankAccountInfoApi(data);
  return response.data;
});
export const setupAddressApiAsync = createAsyncThunk("auth/setupAddressApi", async (data) => {
  const response = await setupAddressApi(data.postData, data.tokenData);
  return response.data;
});
export const updateSellerInfoApiAsync = createAsyncThunk("auth/updateSellerInfoApi", async (data) => {
  const response = await updateSellerInfoApi(data.postData, data.tokenData);
  return response.data;
});
export const changeSellerAddressStatusAsync = createAsyncThunk("auth/changeSellerAddressStatus", async (data) => {
  const response = await changeSellerAddressStatus(data.postData, data.tokenData);
  return response.data;
});
export const addYourDriverApiAsync = createAsyncThunk("auth/addYourDriverApi", async (data) => {
  const response = await addYourDriverApi(data.postData, data.tokenData);
  return response.data;
});
export const verifyDriverApiAsync = createAsyncThunk("auth/verifyDriverApi", async (data) => {
  const response = await verifyDriverApi(data.postData, data.tokenData);
  return response.data;
});

export const getWalletInventoryGraphApiAsync = createAsyncThunk("auth/getWalletInventoryGraphApi", async (data) => {
  const response = await getWalletInventoryGraphApi(data.postData, data.tokenData);
  return response.data;
});
export const getWalletStatisticsApiAsync = createAsyncThunk("auth/getWalletStatisticsApi", async (data) => {
  const response = await getWalletStatisticsApi(data.postData, data.tokenData);
  return response.data;
});
export const getAttributeApiAsync = createAsyncThunk("auth/getAttributeApi", async (data) => {
  const response = await getAttributeApi(data.postData, data.tokenData);
  return response.data;
});
export const getBrandOfCategoryAsync = createAsyncThunk("auth/getBrandOfCategory", async (data) => {
  const response = await getBrandOfCategory(data.postData, data.tokenData);
  return response.data;
});
export const getCategoriesAsync = createAsyncThunk("auth/getCategories", async (data) => {
  const response = await getCategories(data.postData, data.tokenData);
  return response.data;
});

export const getProductAsync = createAsyncThunk("auth/getProductes", async (data) => {
  const response = await getProductes(data.postData, data.tokenData);
  return response.data;
});


export const getMerchantStatisticsAsync = createAsyncThunk("merchant/getMerchantStatistics", async (data) => {
  const response = await getMerchantStatistics(data.postData, data.tokenData);
  return response.data;
});

export const getMerchantOrderGraphAsync = createAsyncThunk("merchant/getMerchantOrderGraph", async (data) => {
  const response = await getMerchantOrderGraph(data.postData, data.tokenData);
  return response.data;
});

export const getMerchantTotalCustomerGraphAsync = createAsyncThunk("merchant/getMerchantTotalCustomerGraph", async (data) => {
  const response = await getMerchantTotalCustomerGraph(data.postData, data.tokenData);
  return response.data;
});

export const getMerchantTotalOrderGraphAsync = createAsyncThunk("merchant/getMerchantTotalOrderGraph", async (data) => {
  const response = await getMerchantTotalOrderGraph(data.postData, data.tokenData);
  return response.data;
});

export const getAttributesApiAsync = createAsyncThunk("auth/getAttributesApi", async (data) => {
  const response = await getAttributesApi(data.postData, data.tokenData);
  return response.data;
});

export const getAttributesByIdApiAsync = createAsyncThunk("auth/getAttributesByIdApi", async (data) => {
  const response = await getAttributesByIdApi(data.postData, data.tokenData);
  return response.data;
});

export const getAllOrdersApiAsync = createAsyncThunk("auth/getAllOrdersApi", async (data) => {
  const response = await getAllOrdersApi(data.postData, data.tokenData);
  return response.data;
});

export const getServicesAsync = createAsyncThunk("auth/getSevicesApi", async (data) => {
  const response = await getSevicesApi(data.postData, data.tokenData);
  return response.data;
});
export const changeStatusApiAsync = createAsyncThunk("auth/changeStatusApi", async (data) => {
  const response = await changeStatusApi(data.postData, data.tokenData);
  return response.data;
});
export const createProductApiAsync = createAsyncThunk("auth/createProductApi", async (data) => {
  const response = await createProductApi(data.postData, data.tokenData);
  return response.data;
})
export const getWalletTransectionsApiAsync = createAsyncThunk("auth/getWalletTransectionsApi", async (data) => {
  const response = await getWalletTransectionsApi(data.postData, data.tokenData);
  return response.data;
});
export const getAppointemntApiAsync = createAsyncThunk("auth/getAppointemntApi", async (data) => {
  const response = await getAppointemntApi(data.postData, data.tokenData);
  return response.data;
});
export const getAllShippingServiceApiAsync = createAsyncThunk("auth/getAllShippingServiceApi", async (data) => {
  const response = await getAllShippingServiceApi(data.postData, data.tokenData);
  return response.data;
});
export const addFedexServiceApiAsync = createAsyncThunk("auth/addFedexServiceApi", async (data) => {
  const response = await addFedexServiceApi(data.postData, data.tokenData);
  return response.data;
});
export const createAttributeApiAsync = createAsyncThunk("auth/createAttributeApi", async (data) => {
  const response = await createAttributeApi(data.postData, data.tokenData);
  return response.data;
});
export const getAddedToInventoryProductsAsync = createAsyncThunk("auth/getAddedToInventoryProducts", async (data) => {
  const response = await getAddedToInventoryProducts(data.postData, data.tokenData);
  return response.data;
});
export const addProductsToInventoryAsync = createAsyncThunk("auth/addProductsToInventory", async (data) => {
  const response = await addProductsToInventory(data.postData, data.tokenData);
  return response.data;
});
export const changeShippingStatusApiAsync = createAsyncThunk("auth/changeShippingStatusApi", async (data) => {
  const response = await changeShippingStatusApi(data.postData, data.tokenData);
  return response.data;
});
export const updateShippingAddressStatusApiAsync = createAsyncThunk("auth/updateShippingAddressStatusApi", async (data) => {
  const response = await updateShippingAddressStatusApi(data.postData, data.tokenData);
  return response.data;
});
export const importFileApiAsync = createAsyncThunk("auth/importFileApi", async (data) => {
  const response = await importFileApi(data.postData, data?.file, data.tokenData);
  return response.data;
});
export const changeAppointmentStatusApiAsync = createAsyncThunk("auth/changeAppointmentStatusApi", async (data) => {
  const response = await changeAppointmentStatusApi(data.postData, data.tokenData);
  return response.data;
});
export const getAppointmentCountApiAsync = createAsyncThunk("auth/getAppointmentCountApi", async (data) => {
  const response = await getAppointmentCountApi(data.postData, data.tokenData);
  return response.data;
});
export const getAppointmentGraphApiAsync = createAsyncThunk("auth/getAppointmentGraphApi", async (data) => {
  const response = await getAppointmentGraphApi(data.postData, data.tokenData);
  return response.data;
});
export const getWalletBallanceApiAsync = createAsyncThunk("auth/getWalletBallanceApi", async (data) => {
  const response = await getWalletBallanceApi(data.tokenData);
  return response.data;
});
export const issueSilaBalanceToYourWalletAsync = createAsyncThunk("auth/issueSilaBalanceToYourWallet", async (data) => {
  const response = await issueSilaBalanceToYourWallet(data.postData, data.tokenData);
  return response.data;
});
export const redeemBalanceToYourBankAsync = createAsyncThunk("auth/redeemBalanceToYourBank", async (data) => {
  const response = await redeemBalanceToYourBank(data.postData, data.tokenData);
  return response.data;
});

export const getPosCountAsync = createAsyncThunk("merchant/getPosCountApi", async (data) => {
  const response = await getPosCountApi(data.postData, data.tokenData);
  return response.data;
});
export const getPosGraphApiAsync = createAsyncThunk("merchant/getPosGraphApi", async (data) => {
  const response = await getPosGraphApi(data.postData, data.tokenData);
  return response.data;
});
export const getSalesPerPosApiAsync = createAsyncThunk("merchant/getSalesPerPosApi", async (data) => {
  const response = await getSalesPerPosApi(data.postData, data.tokenData);
  return response.data;
});

export const getPosListingAsync = createAsyncThunk("merchant/getPosListingApi", async (data) => {
  const response = await getPosListingApi(data.postData, data.tokenData);
  return response.data;
});
export const getInventoryCountApiAsync = createAsyncThunk("merchant/getInventoryCountApi", async (data) => {
  const response = await getInventoryCountApi(data.postData, data.tokenData);
  return response.data;
});
export const getInventoryDetailsApiAsync = createAsyncThunk("merchant/getInventoryDetailsApi", async (data) => {
  const response = await getInventoryDetailsApi(data.postData, data.tokenData);
  return response.data;
});

export const getDriverCountAsync = createAsyncThunk("merchant/getDriverCountApi", async (data) => {
  const response = await getDriverCountApi(data.postData, data.tokenData);
  return response.data;
});
export const getInventorySalePurchaseStatisticsApiAsync = createAsyncThunk("merchant/getInventorySalePurchaseStatisticsApi", async (data) => {
  const response = await getInventorySalePurchaseStatisticsApi(data.postData, data.tokenData);
  return response.data;
});
export const getInventoryDetailsByIdApiAsync = createAsyncThunk("merchant/getInventoryDetailsByIdApi", async (data) => {
  const response = await getInventoryDetailsByIdApi(data.postData, data.tokenData);
  return response.data;
});

export const createCatalogFolderApiAsync = createAsyncThunk("merchant/createCatalogFolderApi", async (data) => {
  const response = await createCatalogFolderApi(data.postData, data.tokenData);
  return response.data;
});

export const getAllFoldersAsync = createAsyncThunk("merchant/getAllFoldersApi", async (data) => {
  const response = await getAllFoldersApi(data.postData, data.tokenData);
  return response.data;
});

export const deleteFolderApiAsync = createAsyncThunk("merchant/deleteFolderApi", async (data) => {
  const response = await deleteFolderApi(data.postData, data.tokenData);
  return response.data;
});

export const updateFolderAsync = createAsyncThunk("merchant/updateFolderApi", async (data) => {
  const response = await updateFolderApi(data.postData, data.tokenData);
  return response.data;
});

export const getOrderTrackingApiAsync = createAsyncThunk("merchant/getOrderTrackingApi", async (data) => {
  const response = await getOrderTrackingApi(data.postData, data.tokenData);
  return response.data;
});
export const buySubscriptionApiAsync = createAsyncThunk("merchant/buySubscriptionApi", async (data) => {
  const response = await buySubscriptionApi(data.postData, data.tokenData);
  return response.data;
});
export const getMerchantStepStatusAsync = createAsyncThunk("merchant/getMerchantStepStatus", async (data) => {
  const response = await getMerchantStepStatus(data);
  return response.data;
});
export const getMerchantActiveSubscriptionAsync = createAsyncThunk("merchant/getMerchantActiveSubscription", async (data) => {
  const response = await getMerchantActiveSubscription(data);
  return response.data;
});
export const getSingleFolderDataApiAsync = createAsyncThunk("merchant/getSingleFolderDataApi", async (data) => {
  const response = await getSingleFolderDataApi(data.postData, data.tokenData);
  return response.data;
});
export const uploadFileInCatelogAsync = createAsyncThunk("merchant/uploadFileInCatelog", async (data) => {
  const response = await uploadFileInCatelog(data.file, data.token);
  return response.data;
});
export const createCatalogApiAsync = createAsyncThunk("merchant/createCatalogApi", async (data) => {
  const response = await createCatalogApi(data.postData, data.tokenData);
  return response.data;
});
export const getAllCatalogsApiAsync = createAsyncThunk("merchant/getAllCatalogsApi", async (data) => {
  const response = await getAllCatalogsApi(data.postData, data.tokenData);
  return response.data;
});
export const deleteCatalogApiAsync = createAsyncThunk("merchant/deleteCatalogApi", async (data) => {
  const response = await deleteCatalogApi(data.postData, data.tokenData);
  return response.data;
});
export const updateCatalogApiAsync = createAsyncThunk("merchant/updateCatalogApi", async (data) => {
  const response = await updateCatalogApi(data.postData, data.tokenData);
  return response.data;
});
export const getStorageDataApiAsync = createAsyncThunk("merchant/getStorageDataApi", async (data) => {
  const response = await getStorageDataApi(data);
  return response.data;
});
export const getSubscriberApiAsync = createAsyncThunk("merchant/getSubscriberApi", async (data) => {
  const response = await getSubscriberApi(data);
  return response.data;
});
export const getDriverProfileApiAsync = createAsyncThunk("merchant/getDriverProfileApi", async (data) => {
  const response = await getDriverProfileApi(data.postData, data.tokenData);
  return response.data;
});
export const getManufactureRetailerWholeSellerApiAsync = createAsyncThunk("merchant/getManufactureRetailerWholeSellerApi", async (data) => {
  const response = await getManufactureRetailerWholeSellerApi(data.postData, data.tokenData);
  return response.data;
});
export const getManufactureDetailsByIdApiAsync = createAsyncThunk("merchant/getManufactureDetailsByIdApi", async (data) => {
  const response = await getManufactureDetailsByIdApi(data.postData, data.tokenData);
  return response.data;
});
export const getPurchaseGraphApiAsync = createAsyncThunk("merchant/getPurchaseGraphApi", async (data) => {
  const response = await getPurchaseGraphApi(data.postData, data.tokenData);
  return response.data;
});
export const getConsumerDataApiAsync = createAsyncThunk("merchant/getConsumerDataApi", async (data) => {
  const response = await getConsumerDataApi(data.postData, data.tokenData);
  return response.data;
});
export const getPurchaseListApiAsync = createAsyncThunk("merchant/getPurchaseListApi", async (data) => {
  const response = await getPurchaseListApi(data.postData, data.tokenData);
  return response.data;
});
export const getSupportDataApiAsync = createAsyncThunk("merchant/getSupportDataApi", async (data) => {
  const response = await getSupportDataApi(data.postData, data.tokenData);
  return response.data;
});
export const getSupportTicketDataApiAsync = createAsyncThunk("merchant/getSupportTicketDataApi", async (data) => {
  const response = await getSupportTicketDataApi(data.postData, data.tokenData);
  return response.data;
});
export const getDepartmentDataInSupportApiAsync = createAsyncThunk("merchant/getDepartmentDataInSupportApi", async (data) => {
  const response = await getDepartmentDataInSupportApi(data);
  return response.data;
});
export const getSupportAgentsApiAsync = createAsyncThunk("merchant/getSupportAgentsApi", async (data) => {
  const response = await getSupportAgentsApi(data.postData, data.tokenData);
  return response.data;
});
export const assignSupportAgentApiAsync = createAsyncThunk("merchant/assignSupportAgentApi", async (data) => {
  const response = await assignSupportAgentApi(data.postData, data.tokenData);
  return response.data;
});
export const getSingleOrderPurchaseApiAsync = createAsyncThunk("merchant/getSingleOrderPurchaseApi", async (data) => {
  const response = await getSingleOrderPurchaseApi(data.postData, data.tokenData);
  return response.data;
});
export const reportAnIssueApiAsync = createAsyncThunk("merchant/reportAnIssueApi", async (data) => {
  const response = await reportAnIssueApi(data.postData, data.tokenData);
  return response.data;
});
export const deleteSupportApiAsync = createAsyncThunk("merchant/deleteSupportApi", async (data) => {
  const response = await deleteSupportApi(data.postData, data.tokenData);
  return response.data;
});
export const getSingleSupportDataApiAsync = createAsyncThunk("merchant/getSingleSupportDataApi", async (data) => {
  const response = await getSingleSupportDataApi(data.postData, data.tokenData);
  return response.data;
});
export const getSupportTicketCountApiAsync = createAsyncThunk("merchant/getSupportTicketCountApi", async (data) => {
  const response = await getSupportTicketCountApi(data.postData, data.tokenData);
  return response.data;
});
export const getCashFlowStatisticsApiAsync = createAsyncThunk("merchant/getCashFlowStatisticsApi", async (data) => {
  const response = await getCashFlowStatisticsApi(data.postData, data.tokenData);
  return response.data;
});
export const getTransactionStatisticsApiAsync = createAsyncThunk("merchant/getTransactionStatisticsApi", async (data) => {
  const response = await getTransactionStatisticsApi(data.postData, data.tokenData);
  return response.data;
});
export const getAnalyticStatDataApiAsync = createAsyncThunk("merchant/getAnalyticStatDataApi", async (data) => {
  const response = await getAnalyticStatDataApi(data.postData, data.tokenData);
  return response.data;
});
export const getAnalyticOrdersApiAsync = createAsyncThunk("merchant/getAnalyticOrdersApi", async (data) => {
  const response = await getAnalyticOrdersApi(data.postData, data.tokenData);
  return response.data;
});
export const totalAnalyticsOrderApiAsync = createAsyncThunk("merchant/totalAnalyticsOrderApi", async (data) => {
  const response = await totalAnalyticsOrderApi(data.postData, data.tokenData);
  return response.data;
});
export const totalAnalyticsInventoryApiAsync = createAsyncThunk("merchant/totalAnalyticsInventoryApi", async (data) => {
  const response = await totalAnalyticsInventoryApi(data.postData, data.tokenData);
  return response.data;
});
export const totalAnalyticsProductSoldApiAsync = createAsyncThunk("merchant/totalAnalyticsProductSoldApi", async (data) => {
  const response = await totalAnalyticsProductSoldApi(data.postData, data.tokenData);
  return response.data;
});
export const getJbrUsdTransactionsApiAsync = createAsyncThunk("merchant/getJbrUsdTransactionsApi", async (data) => {
  const response = await getJbrUsdTransactionsApi(data.postData, data.tokenData);
  return response.data;
});
export const deleteBankAccountApiAsync = createAsyncThunk("merchant/deleteBankAccountApi", async (data) => {
  const response = await deleteBankAccountApi(data.postData, data.tokenData);
  return response.data;
});
export const checkbankBalanceApiAsync = createAsyncThunk("merchant/checkbankBalanceApi", async (data) => {
  const response = await checkbankBalanceApi(data.postData, data.tokenData);
  return response.data;
});
export const createProductOfferApiAsync = createAsyncThunk("merchant/createProductOfferApi", async (data) => {
  const response = await createProductOfferApi(data.postData, data.tokenData);
  return response.data;
});
export const updateProductOfferApiAsync = createAsyncThunk("merchant/updateProductOfferApi", async (data) => {
  const response = await updateProductOfferApi(data.postData, data.tokenData);
  return response.data;
});
export const getProductOfferListApiAsync = createAsyncThunk("merchant/getProductOfferListApi", async (data) => {
  const response = await getProductOfferListApi(data.postData, data.tokenData);
  return response.data;
});
export const getWithdrawalDataApiAsync = createAsyncThunk("merchant/getWithdrawalDataApi", async (data) => {
  const response = await getWithdrawalDataApi(data.postData, data.tokenData);
  return response.data;
});
export const updateInventoryApiAsync = createAsyncThunk("merchant/updateInventoryApi", async (data) => {
  const response = await updateInventoryApi(data.postData, data.tokenData);
  return response.data;
});
export const deleteProductApiAsync = createAsyncThunk("merchant/deleteProductApi", async (data) => {
  const response = await deleteProductApi(data.postData, data.tokenData);
  return response.data;
});
export const getWalletRefundDataApiAsync = createAsyncThunk("merchant/getWalletRefundDataApi", async (data) => {
  const response = await getWalletRefundDataApi(data.postData, data.tokenData);
  return response.data;
});
export const getFaqDataApiAsync = createAsyncThunk("merchant/getFaqDataApi", async (data) => {
  const response = await getFaqDataApi(data.postData, data.tokenData);
  return response.data;
});
export const getFaqCategoryDataApiAsync = createAsyncThunk("merchant/getFaqCategoryDataApi", async (data) => {
  const response = await getFaqCategoryDataApi(data.postData, data.tokenData);
  return response.data;
});
export const updateFaqCategoryApiAsync = createAsyncThunk("merchant/updateFaqCategoryApi", async (data) => {
  const response = await updateFaqCategoryApi(data.postData, data.tokenData);
  return response.data;
});

export const updateFaqApiAsync = createAsyncThunk("merchant/updateFaqApi", async (data) => {
  const response = await updateFaqApi(data.postData, data.tokenData);
  return response.data;
});


export const getFaqCategoryTagsApiAsync = createAsyncThunk("merchant/getFaqCategoryTagsApi", async (data) => {
  const response = await getFaqCategoryTagsApi(data);
  return response.data;
});
export const createFaqCategoryApiAsync = createAsyncThunk("merchant/createFaqCategoryApi", async (data) => {
  const response = await createFaqCategoryApi(data.postData, data.tokenData);
  return response.data;
});
export const createFaqApiAsync = createAsyncThunk("merchant/createFaqApi", async (data) => {
  const response = await createFaqApi(data.postData, data.tokenData);
  return response.data;
});

export const deleteCategoryApiAsync = createAsyncThunk("merchant/deleteFaqCategoryApi", async (data) => {
  const response = await deleteFaqCategoryApi(data.postData, data.tokenData);
  return response.data;
});

export const deleteFaqApiAsync = createAsyncThunk("merchant/deleteFaqyApi", async (data) => {
  const response = await deleteFaqyApi(data.postData, data.tokenData);
  return response.data;
});
export const getSingleOrderTrackingApiAsync = createAsyncThunk("merchant/getSingleOrderTrackingApi", async (data) => {
  const response = await getSingleOrderTrackingApi(data.postData, data.tokenData);
  return response.data;
});
export const getRecievedCatalogApiAsync = createAsyncThunk("merchant/getRecievedCatalogApi", async (data) => {
  const response = await getRecievedCatalogApi(data);
  return response.data;
});
export const getSingleRecievedCatalogApiAsync = createAsyncThunk("merchant/getSingleRecievedCatalogApi", async (data) => {
  const response = await getSingleRecievedCatalogApi(data.id, data.tokenData);
  return response.data;
});
export const staffPaymentApiAsync = createAsyncThunk("merchant/staffPaymentApi", async (data) => {
  const response = await staffPaymentApi(data.id, data.tokenData);
  return response.data;
});
export const staffPayApiAsync = createAsyncThunk("merchant/staffPayApi", async (data) => {
  const response = await staffPayApi(data.postData, data.tokenData);
  return response.data;
});

export const deleteFolderInRecievedCatalogApiAsync = createAsyncThunk("merchant/deleteFolderInRecievedCatalogApi", async (data) => {
  const response = await deleteFolderInRecievedCatalogApi(data.postData, data.tokenData);
  return response.data;
});
export const deleteFileInRecievedCatalogApiAsync = createAsyncThunk("merchant/deleteFileInRecievedCatalogApi", async (data) => {
  const response = await deleteFileInRecievedCatalogApi(data.postData, data.tokenData);
  return response.data;
});


export const quickbookGetUriApiAsync = createAsyncThunk("merchant/quickbookGetUriApi", async (data) => {
  const response = await quickbookGetUriApi(data);
  return response.data;
});
export const quickbookSyncDataApiAsync = createAsyncThunk("merchant/quickbookSyncDataApi", async (data) => {
  const response = await quickbookSyncDataApi(data);
  return response.data;
});
export const quickbookGetTokenApiAsync = createAsyncThunk("merchant/quickbookGetTokenApi", async (data) => {
  const response = await quickbookGetTokenApi(data.postData, data.tokenData);
  return response.data;
});
export const createSupportAgentApiAsync = createAsyncThunk("merchant/createSupportAgentApi", async (data) => {
  const response = await createSupportAgentApi(data.postData, data.tokenData);
  return response.data;
});
export const getAllSupportAgentsApiAsync = createAsyncThunk("merchant/getAllSupportAgentsApi", async (data) => {
  const response = await getAllSupportAgentsApi(data.postData, data.tokenData);
  return response.data;
});
export const deleteSupportAgentApiAsync = createAsyncThunk("merchant/deleteSupportAgentApi", async (data) => {
  const response = await deleteSupportAgentApi(data.postData, data.tokenData);
  return response.data;
});
export const updateSupportAgentApiAsync = createAsyncThunk("merchant/updateSupportAgentApi", async (data) => {
  const response = await updateSupportAgentApi(data.postData, data.tokenData);
  return response.data;
});
export const getNewUserStatisticsApiAsync = createAsyncThunk("merchant/getNewUserStatisticsApi", async (data) => {
  const response = await getNewUserStatisticsApi(data.postData, data.tokenData);
  return response.data;
});
export const resheduleBookingApiAsync = createAsyncThunk("merchant/resheduleBookingApi", async (data) => {
  const response = await resheduleBookingApi(data.postData, data.tokenData);
  return response.data;
});
export const getBookingTimeSlotsApiAsync = createAsyncThunk("merchant/getBookingTimeSlotsApi", async (data) => {
  const response = await getBookingTimeSlotsApi(data.postData, data.tokenData);
  return response.data;
});
export const editProductApiAsync = createAsyncThunk("merchant/editProductApi", async (data) => {
  const response = await editProductApi(data.postData, data.tokenData);
  return response.data;
});
export const getPluginsListAsync = createAsyncThunk("merchant/getPluginsList", async () => {
  const response = await getPluginsList();
  return response.data;
});
export const editProductSupplyApiAsync = createAsyncThunk("merchant/editProductSupplyApi", async (data) => {
  const response = await editProductSupplyApi(data.postData, data.tokenData);
  return response.data;
});

export const getOrderWalletTransactionsAsync = createAsyncThunk("merchant/getOrderWalletTransactions", async (data) => {
  const response = await getOrderWalletTransactions(data.postData, data.tokenData);
  return response.data;
});

export const getOrderDetailsAsync = createAsyncThunk("merchant/getOrderDetails", async (data) => {
  const response = await getOrderDetails(data.postData, data.tokenData);
  return response.data;
});
export const getOrderDetailByIdAsync = createAsyncThunk("merchant/getOrderDetailById", async (data) => {
  const response = await getOrderDetailById(data.id, data.token);
  return response.data;
});

export const getAllCustomersApiAsync = createAsyncThunk("merchant/getCustomerData", async (data) => {
  const response = await getAllCustomers(data.postData, data.tokenData);
  return response.data;
});
export const returnToInventoryInOrderAsync = createAsyncThunk("merchant/returnToInventoryInOrder", async (data) => {
  const response = await returnToInventoryInOrder(data.postData, data.tokenData);
  return response.data;
});
export const createProductSupplyApiAsync = createAsyncThunk("merchant/createProductSupplyApi", async (data) => {
  const response = await createProductSupplyApi(data.postData, data.tokenData);
  return response.data;
});

export const deleteProductSupplyApiAsync = createAsyncThunk("merchant/deleteProductSupplyApi", async (data) => {
  const response = await deleteProductSupply(data.postData, data.tokenData);
  return response.data;
});
export const verifyOtpInOrderApiAsync = createAsyncThunk("merchant/verifyOtpInOrderApi", async (data) => {
  const response = await verifyOtpInOrderApi(data.postData, data.tokenData);
  return response.data;
});
export const deleteOfferApiAsync = createAsyncThunk("merchant/deleteOfferApi", async (data) => {
  const response = await deleteOfferApi(data.postData, data.tokenData);
  return response.data;
});
export const getTrendingArticleApiAsync = createAsyncThunk("merchant/getTrendingArticleApi", async (data) => {
  const response = await getTrendingArticleApi(data.postData, data.tokenData);
  return response.data;
});
export const createCustomerApiAsync = createAsyncThunk("merchant/createCustomerApi", async (data) => {
  const response = await createCustomerApi(data.postData, data.tokenData);
  return response.data;
});
export const getMarketStatusApiAsync = createAsyncThunk("merchant/getMarketStatusApi", async (data) => {
  const response = await getMarketStatusApi(data.postData, data.tokenData);
  return response.data;
});
export const updateMarketStatusApiAsync = createAsyncThunk("merchant/updateMarketStatusApi", async (data) => {
  const response = await updateMarketStatusApi(data.postData, data.tokenData);
  return response.data;
});

export const notificationListAsync = createAsyncThunk("merchant/getNotificationList", async (data) => {
  const response = await getNotificationList(data.tokenData);
  return response.data;
});


// new
export const getProductCouponListApiAsync = createAsyncThunk("merchant/getProductCouponListApi", async (data) => {
  const response = await getProductCouponListApi(data.postData, data.tokenData);
  return response.data;
});


// new

export const deleteCouponApiAsync = createAsyncThunk("merchant/deleteCouponApi", async (data) => {
  const response = await deleteCouponApi(data.postData, data.tokenData);
  return response.data;
});

// new

export const updateProductCouponApiAsync = createAsyncThunk("merchant/updateProductCouponApi", async (data) => {
  const response = await updateProductCouponApi(data.postData, data.tokenData);
  return response.data;
});


export const getAllGiftCardAsync = createAsyncThunk("merchant/getAllGiftCard", async (data) => {
  const response = await getAllGiftCard(data.postData, data.tokenData);
  return response.data;
});
export const createGiftCardApiAsync = createAsyncThunk("merchant/createGiftCardApi", async (data) => {
  const response = await createGiftCardApi(data.postData, data.tokenData);
  return response.data;
});
export const updateGiftCardApiAsync = createAsyncThunk("merchant/updateGiftCardApi", async (data) => {
  const response = await updateGiftCardApi(data.postData, data.tokenData);
  return response.data;
});
export const deleteGiftCardApiAsync = createAsyncThunk("merchant/deleteGiftCardApi", async (data) => {
  const response = await deleteGiftCardApi(data.postData, data.tokenData);
  return response.data;
});
export const getMerchantSettingsAsync = createAsyncThunk("merchant/getMerchantSettings", async (data) => {
  const response = await getMerchantSettings(data);
  return response.data;
});
export const settingUpdateApiAsync = createAsyncThunk("merchant/settingUpdateApi", async (data) => {
  const response = await settingUpdateApi(data.postData, data.tokenData);
  return response.data;
});
export const cardDeleteApiAsync = createAsyncThunk("merchant/cardDeleteApi", async (data) => {
  const response = await cardDeleteApi(data.postData, data.tokenData);
  return response.data;
});



export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardEmpty: (state) => {
      state.walletProfile = null;
      state.merchantStatus = null;
      state.searchBySkuStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createWalletProfileAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createWalletProfileAsync.fulfilled, (state, { payload }) => {
        state.walletProfile = payload
        state.status = "idle";
      })
      .addCase(createWalletProfileAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.walletProfile = null
      })
      .addCase(getMerchantStepStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMerchantStepStatusAsync.fulfilled, (state, { payload }) => {
        state.merchantStatus = payload
        state.status = "idle";
      })
      .addCase(getMerchantStepStatusAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.merchantStatus = null
      })
  }
});
export const { dashboardEmpty } = dashboardSlice.actions;
export const selectWalletProfile = (state) => state.dashboard.walletProfile;
export const selectMerchantStatus = (state) => state.dashboard.merchantStatus;
// export const selectReturnData = (state) => state.dashboard.searchBySkuStatus;
export default dashboardSlice.reducer;