import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
// import { calendar, shareMap, productList1, productList2, productList3, productList4, downlight, userlistImg, userImg, consumerImg } from '../../../utilities/images'
import DatePicker from "react-datepicker";
import DriverUserlist from "./drivers/deiverUserlist";
import DriverUserview from "./drivers/driverUserView";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import {
  getDriverCountAsync,
  getMerchantTotalCustomerGraphAsync,
} from "../../containers/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../containers/auth/authSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import {
  userGraphlist,
  HalfCrossImg,
  customer,
  userCercle,
  calendar,
  shareMap,
  productList1,
  productList2,
  productList3,
  productList4,
  downlight,
  userlistImg,
  userImg,
  userGraph,
} from "../../../utilities/images";
import ChartArea from "../dashboard/wallet/ChartArea";
import { useHistory } from "react-router-dom";

const UserList = () => {
  
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const [activeTab, setActiveTab] = useState("MonthlyBilling");
  const [monthlyPlan, setMonthlyPlan] = useState([]);
  const [yearlyPlan, setYearlyPlan] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [filterValue, setFilterValue] = useState("This week");
  const [driverCountData, setDriverCountData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [analyticsData, setAnalyticsData] = useState();
  const [dataSets, setDataSets]= useState([])
  const  [checked, setChecked] = useState({
    walkingCustomers: true,
    onlineCustomers: true,
    newCustomers: true

  });
  const handelDataSetChange = (e, value, num, color) => {
    console.log(value,e.target.checked);
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: analyticsData.graphData?.datasets[num]?.data
            ?? [],
          borderColor: color,
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
      ]);
    } else {
      setDataSets(
        dataSets.filter((item) => item.label !== value))
    }
  };
  const history = useHistory();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const serviceSalesData = {
    labels: analyticsData?.graphData?.labels,
    datasets: dataSets
  };

  const getMerchantTotalCustomerGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
        filter: filterValue === "This week" ? "week" : filterValue,
      },
    };
    setLoading(true);
    dispatch(getMerchantTotalCustomerGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        console.log(obj?.payload, "customer graph response");
        setAnalyticsData(obj.payload);
        setDataSets([
          {
            // fill: true,
            label: "Walking Customers",
            data: obj.payload?.graphData?.datasets
              ? obj.payload?.graphData?.datasets[0]?.data
              : "",
            borderColor: "#1FB3FF",
            // backgroundColor: '#1675e063',
            backgroundColor: 'rgba(0, 0, 0, 0)'
          },
          {
            // fill: true,
            label: "Online Customers",
            data: obj.payload?.graphData?.datasets
              ? obj.payload?.graphData?.datasets[1]?.data
              : "",
            borderColor: "#275AFF",
            // backgroundColor: '#e09fffab',
            backgroundColor: 'rgba(0, 0, 0, 0)'
          },
          {
            // fill: true,
            label: "New Customers",
            data: obj.payload?.graphData?.datasets
              ? obj.payload?.graphData?.datasets[2]?.data
              : "",
            borderColor: "#FCBA30",
            // backgroundColor: '#e09fffab',
            backgroundColor: 'rgba(0, 0, 0, 0)'
          },
        ],)
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleDateChange = (dates) => {
    setFilterValue("");
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleFilterValue = (value) => {
    setStartDate(null);
    setEndDate(null);
    setFilterValue(value);
  };

  const getDriverCount = () => {
    let params;
    if (filterValue) {
      params = {
        postData: {
          seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          page: currentPage,
          limit: perPage,
          filter:
            filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (startDate != null && endDate != null) {
      params = {
        postData: {
          seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          page: currentPage,
          limit: perPage,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        },
        tokenData: auth?.payload?.token,
      };
    }
    setLoading(true);
    dispatch(getDriverCountAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setDriverData(obj?.payload?.driver_list?.data);
        setDriverCountData(obj?.payload?.data);
        setTotalRows(obj?.payload?.driver_list?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // getDriverCount();
    getMerchantTotalCustomerGraph();
  }, [currentPage, perPage, filterValue, endDate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "userList";
  }, []);
  
  return (
    <>
      <DashboardNavbar title="Customer" backShow={false} />

      {loading === true ? (
        // <CustomLoader />
        <span className="spinner-border inner-spin spinner-border-sm"></span>
      ) : (
        <div className="userList_">
          <div className="bookingsOverview_">
            <div className="verificationTotal">
              <div className="row mt-4">
                <div className="col-lg-12 d-flex justify-content-end">
                  <ul className="weekNav">
                    <li
                      onClick={() => {
                        setFilterValue("today");
                      }}
                    >
                      <Link
                        className={`weekMenu ${
                          filterValue === "today" ? "active" : ""
                        }`}
                      >
                        Today
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        setFilterValue("week");
                      }}
                    >
                      <Link
                        className={`weekMenu ${
                          filterValue === "week" || filterValue === "This week"
                            ? "active"
                            : ""
                        }`}
                      >
                        Weekly
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        setFilterValue("month");
                      }}
                    >
                      <Link
                        className={`weekMenu ${
                          filterValue === "month" ? "active" : ""
                        }`}
                      >
                        Monthly
                      </Link>
                    </li>
                    {/* <li><Link className={`weekMenu ${filterValue==="today" ? "active": ""}`}>Quaterly</Link></li> */}
                    <li
                      onClick={() => {
                        setFilterValue("year");
                      }}
                    >
                      <Link
                        className={`weekMenu ${
                          filterValue === "year" ? "active" : ""
                        }`}
                      >
                        Yearly
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="verificationTotal">
            <div className="row mt-4">
              <div className="col-sm-6 col-md-3 col-lg-3 customSpce mb-2 " onClick={()=>{history.push('/customer',{customerType:'new_customers'})}}>
                <div className="percentageContainer">
                  <div className="percentageRating">
                    <img src={customer} className="user" alt="users" />
                  </div>
                  <div className="percentageValues">
                    <h3 className="subheading_">
                      {analyticsData?.total_customers?.newCustomer ?? 0}
                    </h3>
                    <p className="textinner_">New Customers</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 customSpce mb-2 " onClick={()=>{history.push('/customer',{customerType:'returning_customers'})}} >
                <div className="percentageContainer">
                  <div className="percentageRating">
                    <img src={HalfCrossImg} className="user" alt="users" />
                  </div>
                  <div className="percentageValues">
                    <h3 className="subheading_ ">
                      {analyticsData?.total_customers?.returningCustomer ?? 0}
                    </h3>
                    <p className="textinner_">Returning Customers</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 customSpce mb-2 " onClick={()=>{history.push('/customer',{customerType:'online_customers'})}}>
                <div className="percentageContainer">
                  <div className="percentageRating">
                    <img src={userCercle} className="user" alt="users" />
                  </div>
                  <div className="percentageValues">
                    <h3 className="subheading_">
                      {analyticsData?.total_customers?.onlineCustomers ?? 0}
                    </h3>
                    <p className="textinner_">Online Customers</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 customSpce mb-2 " onClick={()=>{ history.push('/customer',{customerType:'walkin_customers'}) }}>
                <div className="percentageContainer">
                  <div className="percentageRating">
                    <img src={userCercle} className="user" alt="users" />
                  </div>
                  <div className="percentageValues">
                    <h3 className="subheading_">
                      {analyticsData?.total_customers?.walkingCustomers ?? 0}
                    </h3>
                    <p className="textinner_">Walk-in Customers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="totalCustomer">
            <div className="row">
              <div className="col-md-12">
                <div className="totalHead mt-4 mb-4">
                  <h4 className="innerSixteen">Total Customers</h4>
                  <div className="totalCount">
                    <h3 className="mainbluekHead_">
                      {analyticsData?.total_customers?.totalCustomer ?? 0}
                    </h3>
                    <button
                      className="viewBtn"
                      onClick={()=>{ history.push('/customer',{customerType:'all_customers'}) }}
                    >
                      View All
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tradesrowue mt-4 mb-4">
            <div className="row">
              <div className="col-md-12">
                <div className="chartsOuter">
                  <h3 className="textSmallHeading">Total Orders</h3>
                  {/* <h5 className="testTweentyFour">$20,590</h5> */}
                  <div className="row mt-4 mb-4 align-items-center">
                    {/* <div className='col-md-3'>
                                    <div className='tradesContainer'>
                                        <div className='tradesMain'>
                                            <h4 className='innerHeadingSky'>${analyticsData?.currentPurchasesAmount ? analyticsData?.currentPurchasesAmount?.toFixed(2) : 0}</h4>
                                            <p className='textinner_'>Current rowues</p>
                                        </div>
                                        <div className='tradesMain'>
                                            <h4 className='innerHeadingSky_'>${analyticsData?.previousPurchasesAmount ? analyticsData?.previousPurchasesAmount?.toFixed(2) : 0}</h4>
                                            <p className='textinner_'>Previous rowues</p>
                                        </div>
                                    </div>
                                </div> */}
                    <div className="graphChecked_ mt-3 mb-2 ">
                      <div className="graphCheck1_ me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll2"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          onChange={(e) => {
                            handelDataSetChange(e, "Walking Customers", 0, "#1FB3FF");
                            setChecked({...checked,walkingCustomers: checked.walkingCustomers===true ? false: true})
                          }}
                          checked={checked.walkingCustomers}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll2"
                          className="checkBoxTxt masterChecked"
                        >
                          Walking Customers
                        </label>
                      </div>
                      <div className="graphBox_ me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll3"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.onlineCustomers}
                          onChange={(e) => {
                            handelDataSetChange(e, "Online Customers", 1, "#275AFF");
                            setChecked({...checked,onlineCustomers: checked.onlineCustomers===true ? false: true})
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll3"
                          className="checkBoxTxt masterChecked"
                        >
                          Online Customers
                        </label>
                      </div>
                      <div className="checkYellow me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll4"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.newCustomers}
                          onChange={(e) => {
                            handelDataSetChange(e, "New Customers", 2, "#FCBA30");
                            setChecked({...checked,newCustomers: checked.newCustomers===true ? false: true})
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll4"
                          className="checkBoxTxt masterChecked"
                        >
                          New Customers
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {/* <img src={orderGraph} alt="Graph Image" className='img-fluid' /> */}
                      <ChartArea
                        classes="productSales mt-2 mb-2"
                        header="Services Sales"
                        options={options}
                        data={serviceSalesData}
                        chartType="Line"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='verificationTotal_'>
                        <div className='row'>
                            {driverCountData ?
                                driverCountData?.map((val, index) => {
                                    return (
                                        <div className='col-sm-6 col-md-3 col-lg-3 customSpce' key={index}>
                                            <div className='percentageContainer justify-content-between align-items-center'>
                                                <div className='percentageValues'>
                                                    <h3 className='mainHeadingsub_ '>{val?.type === 'Total Payment' ? `$${val?.total}` : val?.total}</h3>
                                                    <p className='textinner_'>{val?.type}</p>
                                                </div>
                                                <div className='percentageRating mapbg'>
                                                    <img src={shareMap} className="" alt='ShareMap' />
                                                    <p className='percentageRate'>{val?.analytics}%</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <></>}

                        </div>
                    </div> */}

          {/* <div className='tableFilters mt-4 mb-3'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="calenderIcon">
                                    <img src={calendar} alt="calender" />
                                 <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" />
                                    <DatePicker
                                        className='cateDate'
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        placeholderText="Date"
                                    />
                                </div>
                            </div>
                           <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Country
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                State
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Id type
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div> 
                        </div>
                    </div>
                    <div className='productListTab'>
                        <button className={`plansBtns ${activeTab === 'MonthlyBilling' ? 'active' : ""}`}
                            onClick={() => setActiveTab('MonthlyBilling')}
                        >
                            <img src={productList1} alt="image" className='showImage' />
                            <img src={productList2} alt="image" className='hideImage' />
                        </button>
                        <button className={`plansBtns ${activeTab === 'AnnuallyBilling' ? 'active' : ""}`}
                            onClick={() => setActiveTab('AnnuallyBilling')}>
                            <img src={productList3} alt="image" className='hideImage' />
                            <img src={productList4} alt="image" className='showImage' />

                        </button>
                    </div>
                    <div className='plansAreasContainer'>
                        {activeTab == 'MonthlyBilling'
                            ?
                            <DriverUserlist loading={loading} data={driverData} totalRows={totalRows} perPage={perPage} setCurrentPage={setCurrentPage} setPerPage={setPerPage} />
                            : activeTab == "AnnuallyBilling" ?
                                <DriverUserview loading={loading} data={driverData} totalRows={totalRows} perPage={perPage} setCurrentPage={setCurrentPage} setPerPage={setPerPage} />
                                : ""
                        }
                    </div>  */}
        </div>
      )}
    </>
  );
};

export default UserList;
