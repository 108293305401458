import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { incoiceuser_, serviceuser_ } from "../../../../utilities/images";
import { selectLoginAuth } from "../../auth/authSlice";
import { changeStatusApiAsync } from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const CancelpurchaseModal = (props) => {
  const { singleOrderData, close, updateList } = { ...props };
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [loading2, setLoading2] = useState(false);

  const changeStatusOfOrderHandle = (id) => {
    // setNewSelectedId(id);
    let orderStatus = {
      postData: {
        id: id,
        status: 8,
      },
      tokenData: auth?.payload?.token,
    };

    setLoading2(true);
    dispatch(changeStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        updateList();
        close();
        setLoading2(false);
      })
      .catch((obj) => {
        setLoading2(false);
      });
  };

  return (
    <div className="cancelpurchaseModal_">
      <div className="scheduleModal">
        <div className="scheduleModalContent">
          <div className="invoiceUser_">
            <div className="reviewuserinfo_">
              <img className="invoveuser me-3" style={{width: "20px"}} src={singleOrderData?.sellerDetails?.user_profiles?.banner_image ?? incoiceuser_} />
              <article className="ivoiceuserDetail_">
                <h4 className="monshead14">{singleOrderData?.sellerDetails?.user_profiles?.firstname} {singleOrderData?.sellerDetails?.user_profiles?.lastname }</h4>
                <p className="monshead12">
                  1480 Bassel Street, New Orleans, LA 70113
                </p>
              </article>
            </div>
            <div className="reviewuserinfo_">
              <img className="invoveuser me-3" src={incoiceuser_} />
              <article className="ivoiceuserDetail_">
                <h4 className="monshead14 blueText_">Express Delivery</h4>
                <p className="monshead12">Immediately</p>
              </article>
            </div>
          </div>
          <div className="statusInvoice_">
            <span>Status : </span>
            <button
              className={`status_button_all_purchase`}
              style={{
                background:
                  singleOrderData.status == 0
                    ? "#3C444D"
                    : singleOrderData?.status === 1 ||
                      singleOrderData?.status === 2
                    ? "#1FB3FF"
                    : singleOrderData?.status === 3 ||
                      singleOrderData?.status === 4
                    ? "#005DB2"
                    : singleOrderData?.status === 5 ||
                      singleOrderData?.status === 6
                    ? "#275AFF"
                    : singleOrderData?.status === 9
                    ? "#FB466C"
                    : "#D33300",
              }}
            >
              {" "}
              {singleOrderData?.status === 0
                ? "Pending"
                : singleOrderData?.status === 1 || singleOrderData?.status === 2
                ? "Processing"
                : singleOrderData?.status === 3 || singleOrderData?.status === 4
                ? "In-Transit"
                : singleOrderData?.status === 5 || singleOrderData?.status === 6
                ? "Delivered"
                : singleOrderData?.status === 9
                ? "Returned"
                : singleOrderData?.status === 7 || singleOrderData?.status === 8
                ? "Cancelled"
                : ""}
            </button>
          </div>
          {singleOrderData.order_details.map((item) => (
            <React.Fragment key={item.id}>
              <div className="serviceDeatils_">
                <div className="serviceinfo_">
                  <img
                    className="img-fluid me-3"
                    style={{ width: "30px" }}
                    src={
                      item?.product_image ? item?.product_image : serviceuser_
                    }
                  />
                  <article className="duration_">
                    <h3 className="lightgreyHeading_">
                      {item?.product_name ? item.product_name : ""}
                    </h3>
                    <p className="designation_"></p>
                  </article>
                </div>
                <div className="servicechanges_">
                  <span className="serciceprice_">${item?.price}</span>
                  <span className="serciceprice_">{item?.qty}</span>
                  <span className="serciceprice_">
                    ${item?.price * item?.qty}
                  </span>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="grandTotal_ singleOrderData">
          <div className="row">
          <div className="col-md-5 text-start">
            <div className="orderinvoive">
              <h5 className="lightgreyHeading_">Total Item</h5>
              <h4 className="monshead22">{singleOrderData?.total_items}</h4>
              <h5 className="lightgreyHeading_">Order Date</h5>
              <h4 className="monshead22">
                {moment(singleOrderData.created_at).format("MM/DD/YYYY")}
              </h4>
              <h5 className="lightgreyHeading_">Invoice No.</h5>
              <h4 className="monshead22">{singleOrderData?.invoices?.id}</h4>
            </div>
          </div>
          

          <div className="col-md-7 text-start">
            <div className="orderBill_">
              <div className="Customertotal">
                <div className="totalDetails">
                  <h5 className="lightgreyHeading_ fw-semibold">Sub Total</h5>
                  <h5 className="lightgreyHeading_ fw-semibold">
                    ${singleOrderData?.actual_amount}
                  </h5>
                </div>
                <div className="totalDetails">
                  <h5 className="textInnerHead2">Shipping Charge</h5>
                  <h5 className="personalinfoHead">
                    ${singleOrderData?.shipping_charge}
                  </h5>
                </div>
                <div className="totalDetails">
                  <h5 className="textInnerHead2">Discount </h5>
                  <h5 className="personalinfoHead">
                    ${singleOrderData?.discount ? singleOrderData.discount : 0}
                  </h5>
                </div>
                {/* <div className="totalDetails">
                  <h5 className="textInnerHead2"> Other Fee</h5>
                  <h5 className="personalinfoHead">$4.00</h5>
                </div> */}

                <div className="totalDetails">
                  <h5 className="textInnerHead2">Tax</h5>
                  <h5 className="personalinfoHead">${singleOrderData?.tax}</h5>
                </div>
                <hr className="dashedsingleOrderData"></hr>
                <div className="totalDetails">
                  <h5 className="itemsText ">Total</h5>
                  <h5 className="itemsText">
                    ${singleOrderData?.payable_amount}
                  </h5>
                </div>
                <div className="reviewbotBtn_ mt-2">
                  {singleOrderData?.status == 0 ? (
                    <>
                      {loading2 ? (
                        <button
                          className="nextverifyBtn w-100"
                          type="button"
                          disabled
                        >
                          <span className="spinner-border spinner-border-sm"></span>
                        </button>
                      ) : (
                        <button
                          className="cancelledBtn_"
                          type="submit"
                          disabled=""
                          onClick={() => {
                            changeStatusOfOrderHandle(singleOrderData.id);
                          }}
                        >
                          <span>Cancel Purchase Order</span>
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <button
                        className="pendingSecondary"
                        type="submit"
                        disabled=""
                      >
                        <span>Track</span>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelpurchaseModal;
