import React, { useEffect, useState } from 'react'
import { delivery, deliveryActive, dropOff, dropOffActive, locationPin } from '../../../../../utilities/images'
import { Link, useHistory, useParams } from 'react-router-dom'
import DeliveryAddress from './deliveryAddress'
import DropOffAddress from './dropOffAddress'
import { useDispatch, useSelector } from 'react-redux'
import { selectAddressList, selectLoginAuth } from '../../../auth/authSlice'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const PickupAddress = () => {
    const location = useLocation();
    const history = useHistory()
    const { addressId } = useParams()
    const [isActive, setIsActive] = useState(false)
    const [activeTab, setActiveTab] = useState('jobrDelivery')
    const [address, setAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    const addresslist = useSelector(selectAddressList)
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const toastId = React.useRef(null)
    const filterData = () => {
        let filterResult = addresslist?.payload?.jobr_delivery?.find(val => val?.id == addressId)
        setAddress(filterResult)
    }

    useEffect(() => {
        filterData()
    }, [])
    return (
        <div className='pickupAddress'>
            {/* Start FixedTopHeader */}
            <div className='topHeader_'>
                <div className="topStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="innerSubheadNormal">
                                <span onClick={() => history.push(location?.state)}>
                                    <i className="las la-angle-left me-3 directionArrow"></i>
                                </span>
                                <span>Local delivery for 106 E 2nd St</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* End FixedTopHeader */}
            <div className='backContainer'>
                <div className=''>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className='subheading_ mt-4 mb-4'>Location:
                                <span className='subheading2_'>JOBR delivery</span>
                            </h2>
                        </div>
                        <div className='col-md-12'>
                            {
                                address ?
                                    <div className='businessDetails'>
                                        <Link to="#">
                                            <div className='d-flex align-items-center'>
                                                <span className='IconPin me-3'>
                                                    <img src={locationPin} alt='LocationPin' />
                                                </span>
                                                <div className=''>
                                                    <p className='descriptionPara_ mb-1'>{address?.street_address}</p>
                                                    <h4 className='itemsText'>{auth?.payload?.user?.user_profiles?.organization_name}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    : <></>
                            }
                        </div>
                        <div className='mb-4 form-group col-md-12 current_addressmt-4 mb-4'>
                            <input type="checkbox" id="register_business" name="register_business" value="register_business" checked={isActive} onChange={() => setIsActive(!isActive)} />
                            <label htmlFor="register" className='ms-2'>This location offers JOBR delivery</label><br />
                        </div>
                    </div>
                </div>
                {
                    isActive ?
                        <>
                            {/* Start LocationOffer Buttons */}
                            <div className='row mb-4'>
                                <div className='col-md-6'>
                                    <div className={`deliveryTypeContain ${activeTab === 'jobrDelivery' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('jobrDelivery')}>
                                        <img src={delivery} alt='delivery' className='paymentTypeIcon me-3' />
                                        <img src={deliveryActive} alt='deliveryActive' className='paymentTypeIcon activeImg me-3' />
                                        <div className=''>
                                            <h3 className='boldsubSubhead'>JOBR Delivery</h3>
                                            <p className='subtext_ mt-2'>Choose specific areas that you deliver to.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={`deliveryTypeContain ${activeTab === 'dropoff' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('dropoff')}>
                                        <img src={dropOff} alt='dropOffActive' className='paymentTypeIcon me-3' />
                                        <img src={dropOffActive} alt='dropOffActive' className='paymentTypeIcon activeImg me-3' />
                                        <div className=''>
                                            <h3 className='boldsubSubhead'>Specify JOBR delivery Drivers </h3>
                                            <p className='subtext_ mt-2'>Set a distance around your location that you deliver to.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End LocationOffer Buttons */}
                            {/* Start LocationOffers Details */}
                            <div className='LocationOffersDetails'>
                                {activeTab === 'jobrDelivery'
                                    ?
                                    <DeliveryAddress data={address}/>
                                    : activeTab === 'dropoff'
                                        ?
                                        <DropOffAddress data={address}/>
                                        : <></>
                                }
                            </div>
                            {/* End LocationOffers Details */}
                        </>
                        : <></>
                }
            </div>
        </div>
    )
}

export default PickupAddress