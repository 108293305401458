import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  checkbankBalanceApiAsync,
  deleteBankAccountApiAsync,
  generatePlaidTokenApiAsync,
  getBankAccountApiAsync,
  getWalletBallanceApiAsync,
  issueSilaBalanceToYourWalletAsync,
  linkBankAccountAsync,
} from "../dashboardSlice";
import { selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { usePlaidLink } from "react-plaid-link";
import { toast } from "react-toastify";
import CustomModal from "../../../components/shared/CustomModal";
import PlansModal from "../../scalePlan/plansModal";
import AddbalanceModal from "./addbalanceModal";
import Swal from "sweetalert2";
import DashboardNavbar from "../DashboardNavbar";
import { dotsinbox } from "../../../../utilities/images";
import BankdetailviewModal from "./bankdetailviewModal";
import { addThousandSeparator } from "../../../../utilities/thousandCommaSeparated";

const BankDetails = () => {
  const dispatch = useDispatch();
  const [linkToken, setLinkToken] = useState("");
  const [walletBalance, setWalletBalance] = useState("");
  const [bankBalance, setBankBalance] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankDetails, setBankDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      // send public_token to server
      let params = {
        postData: {
          plaid_public_token: public_token,
        },
        tokenData: auth?.payload?.token,
      };
      setLoading(true);
      dispatch(linkBankAccountAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          dispatch(getBankAccountApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
              setBankDetails(obj?.payload);
              setLoading(false);
            })
            .catch((obj) => {
              setLoading(false);
            });
        })
        .catch((obj) => {
          setLoading(false);
        });
    },
  });

  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleSingleBankBalanceCheck = (accountName) => {
    setAccountName(accountName);
    let data = {
      postData: {
        account_name: accountName,
        // "app-name": 'merchant'
      },
      tokenData: auth?.payload?.token,
    };
    setLoading2(true);
    dispatch(checkbankBalanceApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading2(false);
        setBankBalance(obj?.payload?.available_balance);
      })
      .catch((obj) => {
        setLoading2(false);
      });
  };

  const handleRemoveBankAccount = (accountName) => {
    Swal.fire({
      title: "Do you want to delete this account?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setAccountName(accountName);
        let data = {
          postData: {
            account_name: accountName,
            // "app-name": 'merchant'
          },
          tokenData: auth?.payload?.token,
        };
        setLoading1(true);
        dispatch(deleteBankAccountApiAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            dispatch(getBankAccountApiAsync(auth?.payload?.token))
              .then(unwrapResult)
              .then((obj) => {
                setBankDetails(obj?.payload);
                setLoading1(false);
              })
              .catch((obj) => {
                setLoading1(false);
              });
          })
          .catch((obj) => {
            setLoading1(false);
          });
      }
    });
  };

  const walletBalanceHandle = () => {
    let data = {
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getWalletBallanceApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setWalletBalance(obj?.payload?.sila_balance);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const getBankAccountHandle = () => {
    setLoading(true);
    dispatch(getBankAccountApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setBankDetails(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBankAccountHandle();
    walletBalanceHandle();
    dispatch(generatePlaidTokenApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setLinkToken(obj?.payload?.link_token);
      })
      .catch((obj) => {});
  }, []);

  return (
    <>
      {loading === true ? (
        <span className="spinner-border inner-spin spinner-border-sm"></span>
      ) : (
        <div className="addBankAccount">
          <DashboardNavbar title="Bank Accounts" show={true} />

          {/* <div className='topHeader_'>
                        <div className="topStickyHeader_ mb-3">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <h2 className="mainHeading">
                                        <Link to="/plans"> <i className="las la-long-arrow-alt-left me-3"></i></Link>
                                        <span>Add Wallet Balance</span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <div className="backContainer">
            <div className="availBalnce mb-5">
              <h3 className="subheading_">
                Available Balance:{" "}
                <span className="blueText_">
                  {" "}
                  {addThousandSeparator(
                    Number(walletBalance)?.toFixed(2)
                  )} JBR{" "}
                </span>
              </h3>
              <button
                className="primary_btn "
                onClick={() => {
                  setModalDetail({ show: true, flag: "AddbalanceModal" });
                  setKey(Math.random());
                }}
              >
                {" "}
                {!ready && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp; Add Wallet Balance
              </button>
            </div>
            <div className="row">
              {bankDetails?.length > 0 ? (
                <>
                  {bankDetails?.map((val, index) => {
                    return (
                      <div key={index} className="col-xxl-4 col-xl-6 col-lg-6">
                        <div className="accountDetails  mb-3">
                          <div className="addaccountBox_">
                            <figure className="bankIcon_">
                              <i className="fas fa-university"></i>
                            </figure>
                            <div className="acountDetails_">
                              <h4 className="textInnerDark mb-2">
                                A/c No. -{" "}
                                <span className="textinner_">
                                  {val?.account_number}
                                </span>
                              </h4>
                              <h4 className="textInnerDark mb-2">
                                A/c Holder Name:{" "}
                                <span className="textinner_">
                                  {val?.account_owner_name}
                                </span>
                              </h4>
                              <h4 className="textInnerDark mb-2">
                                Bank Name:{" "}
                                <span className="textinner_">
                                  {val?.bank_name}
                                </span>
                              </h4>
                              {!loading2 &&
                              val?.account_name === accountName ? (
                                <h4 className="textInnerDark ">
                                  Bank Balance:{" "}
                                  <span className="textinner_">
                                    ${bankBalance}
                                  </span>
                                </h4>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="dropdown businesstypeBtn_ dropdown-menu-end">
                              <Link
                                to="#"
                                id="dropdownMenuButton2"
                                data-bs-toggle="dropdown"
                                className="more_icon"
                                aria-expanded="false"
                              >
                                <img className="" src={dotsinbox} alt="" />
                              </Link>
                              <ul
                                className="dropdown-menu chatDropDown"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <li>
                                  <Link
                                    to="#"
                                    className="dropdown-item chatdropItem "
                                    onClick={() => {
                                      setModalDetail({
                                        show: true,
                                        flag: "BankdetailviewModal",
                                      });
                                      setKey(Math.random());
                                    }}
                                  >
                                    <span className="subtextSmall_">View</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    className="dropdown-item chatdropItem"
                                  >
                                    <span className="subtextSmall_">
                                      Delete
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="accbtnDetail_">
                            <button
                              className="primary_btnborder "
                              onClick={() =>
                                handleRemoveBankAccount(val?.account_name)
                              }
                              disabled={loading1}
                            >
                              {loading1 === true &&
                                val?.account_name === accountName && (
                                  <span className="spinner-border spinner-border-sm"></span>
                                )}
                              <span>Remove Account</span>
                            </button>
                            <button
                              className="primary_btnborder  "
                              onClick={() =>
                                handleSingleBankBalanceCheck(val?.account_name)
                              }
                              disabled={loading2}
                            >
                              {loading2 === true &&
                                val?.account_name === accountName && (
                                  <span className="spinner-border spinner-border-sm"></span>
                                )}
                              <span>Check Balance</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div>No Bank Account Added</div>
              )}
            </div>
            <div className="row mt-auto justify-content-start" l>
              <div className="col-md-3">
                <button
                  className="primary_btn mb-2 pt-3 pb-3"
                  onClick={() => open()}
                  disabled={!ready}
                >
                  {" "}
                  {!ready && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  &nbsp;&nbsp; Add Bank account
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "PickPlan"
            ? "pickPlan"
            : modalDetail.flag === "AddbalanceModal"
            ? "addbalanceModal"
            : modalDetail.flag === "BankdetailviewModal"
            ? "bankdetailviewModal"
            : ""
        }
        size={"md"}
        child={
          modalDetail.flag === "PickPlan" ? (
            <PlansModal
              walletBalance={walletBalance}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "AddbalanceModal" ? (
            <AddbalanceModal
              flag="add"
              bankDetails={bankDetails}
              walletBalance={walletBalance}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "BankdetailviewModal" ? (
            <BankdetailviewModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          <>
            {modalDetail.flag === "PickPlan" ? (
              <>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h3 className="heading_ mb-2">Plan that fits your scale</h3>
                    <p>Simple, Transparent pricing that grows with you</p>
                    <span
                      className="crossIcon"
                      onClick={() => handleOnCloseModal()}
                    >
                      <i className="las la-times" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {modalDetail.flag === "AddbalanceModal" ? (
              <>
                <div className="modalHeader_">
                  <div className="common_">
                    <h3 className="modalHeading">Add Wallet Balance</h3>

                    <span
                      className="crossIcon"
                      onClick={() => handleOnCloseModal()}
                    >
                      <i className="las la-times" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </>
            ) : modalDetail.flag === "BankdetailviewModal" ? (
              <>
                <div className="modalHeader_">
                  <div className="common_">
                    <span
                      className="crossIcon"
                      onClick={() => handleOnCloseModal()}
                    >
                      <i className="las la-times" aria-hidden="true"></i>
                    </span>
                    <h3 className="modalHeading">Bank of America</h3>

                    <button className="deletebtn__" type="submit">
                      Delete
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default BankDetails;
