import React, { useEffect, useRef } from 'react'
import { calendar, productList1, productList2, productList3, productList4, search_light, addPlusBtn } from '../../../utilities/images'
import DatePicker from "react-datepicker";
import { useState } from 'react';
import moment from 'moment-timezone';
import ServiceListTable from './serviceListTable';
import ServiceListView from './serviceListView';
import { changeServiceAvailabilityStatusApiAsync, getProductServiceListApiAsync, getServiceListApiAsync } from '../myProducts/productSlice';
import { deleteProductApiAsync, getBrandOfCategoryAsync, getCategoriesAsync } from '../dashboard/dashboardSlice'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import CustomLoader from '../../components/shared/CustomLoader';
import { useHistory } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import Swal from 'sweetalert2';

var isOpen = false;
var isBrandOpen = false;
const ServiceList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const childRef = useRef(null);
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [activeTab, setActiveTab] = useState("ServiceTable");
    const [keyword, setKeyword] = useState("")
    const auth = useSelector(selectLoginAuth);
    const [serviceData, setServiceData] = useState([]);
    const containerRef = useRef(null);
    const brandRef = useRef(null);
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [categoryData, setCategoryData] = useState([])
    const [subCategoryIds, setSubCategoryIds] = useState([])
    const [loading, setLoading] = useState(false);
    const [brand, setBrand] = useState([]);
    const [searchValue, setSearchValue] = useState()
    const [searchBrandValue, setSearchBrandValue] = useState()
    const [brandIds, setBrandIds] = useState([])
    const [newMessagekey, setNewMessagekey] = useState(Math.random())
    const [selectedValue, setSelectedValue] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const toastId = React.useRef(null)

    const [columnStatus, setColumnStatus] = useState({
        category: true,
        // locations: true,
        sub_category: true,
        staff: true,
        price: true
    })
    console.log(columnStatus, "Status-data");
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setSelectedValue(true)
    }

    const fetchAllCategories = (searchKeyword) => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                main_category: true,
                with_subcategories: true,
                service_type: "service",
                seller_id: auth?.payload?.uniqe_id,
                check_product_existance: false

            }
        }
        if (searchKeyword) {
            data = {
                tokenData: auth?.payload?.token,
                postData: {
                    ...data.postData,
                    search: searchKeyword,
                    check_product_existance: false
                }
            }
        }
        setLoading(true)
        dispatch(getCategoriesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setCategoryData(obj?.payload?.data)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const fetchAllBrand = (searchKeyword = null) => {
        // let brandData = {
        //     tokenData: auth?.payload?.token,
        // }
        // if (searchKeyword) {
        //     brandData = {
        //         tokenData: auth?.payload?.token,
        //         postData: {
        //             search: searchKeyword
        //         }
        //     }
        // }
        // dispatch(getBrandOfCategoryAsync(brandData))
        //     .then(unwrapResult)
        //     .then((obj) => {
        //         setLoading(false)
        //         // let filterResult = obj?.payload?.data?.map((item) => {
        //         //     return {
        //         //         value: item?.id,
        //         //         label: item?.name
        //         //     };
        //         // });
        //         setBrand(obj?.payload?.data)
        //     }
        //     )
        //     .catch((obj) => {
        //         setLoading(false)
        //     })
    }
    const handleSubcategoryChange = (subCategoryId) => {
        let filterData = [...subCategoryIds]
        if (filterData?.includes(subCategoryId)) {
            filterData = filterData?.filter(val => val != subCategoryId)
            setSubCategoryIds(filterData)
        }
        else {
            filterData = [...subCategoryIds, subCategoryId]
            setSubCategoryIds(filterData)
        }
    }

    // const handleBrandChange = (brandId) => {
    //     let filterData = [...brandIds]
    //     if (filterData?.includes(brandId)) {
    //         filterData = filterData?.filter(val => val != brandId)
    //         setBrandIds(filterData)
    //     }
    //     else {
    //         filterData = [...brandIds, brandId]
    //         setBrandIds(filterData)
    //     }
    // }

    useEffect(() => {
        fetchAllCategories()
        fetchAllBrand()
    }, [])

    const handleToggle = () => {
        setTimeout(() => {
            isOpen = true;
            setNewMessagekey(Math.random());
        }, 100);
        // setIsOpen(true);
    };

    // const handleBrandToggle = () => {
    //     setTimeout(() => {
    //         isBrandOpen = true;
    //         setNewMessagekey(Math.random());
    //     }, 100);
    //     // setIsOpen(true);
    // };

    const handleClickOutside = async (event) => {
        if (containerRef?.current && !containerRef.current.contains(event.target) && isOpen) {
            isOpen = false;
            setNewMessagekey(Math.random());
        }
        setSelectedValue(false)
    };
    const handleClickOutsideBrand = async (event) => {
        if (brandRef?.current && !brandRef.current.contains(event.target) && isBrandOpen) {
            isBrandOpen = false;
            setNewMessagekey(Math.random());
        }
        setSelectedValue(false)
    };

    //  const pageData = (data, value) =>{
    //     setCurrentPage(data)
    //     setPerPage(value)
    //  }

    

    const handleDeleteProduct = () => {
        if (selectedRows?.length > 0) {
            const productIds = selectedRows.map(item => item.id);
            Swal.fire({
                title: 'Do you want to delete the product?',
                showCancelButton: true,
                confirmButtonText: 'Delete',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    let params = {
                        postData: {
                            product_ids: productIds
                        },
                        tokenData: auth?.payload?.token
                    }
                    setDeleteLoading(true)
                    dispatch(deleteProductApiAsync(params))
                        .then(unwrapResult)
                        .then((obj) => {
                            setDeleteLoading(false)
                            childRef.current.getProductListParent();
                            toast.success(obj?.msg)
                        })
                        .catch((obj) => {
                            setDeleteLoading(false)
                        })
                }
            })
        }
        else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select product to delete")
            }
        }
    }

    const handleDownloadCSV = () => {
        if (selectedRows?.length > 0) {
            // Convert your data into CSV format using PapaParse
            const csvData = Papa.unparse(selectedRows);
            // Create a Blob containing the CSV data
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
            // Save the Blob as a file with a .csv extension
            saveAs(blob, 'product_list.csv');
        }
        else {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select product to import")
            }
        }
    };

    const handleFilterValue = (e, value) => {
        console.log(value, "value");
        if (value === "Category") {
            setColumnStatus({ ...columnStatus, category: e.target.checked });
        }
        if (value === "Location") {
            setColumnStatus({ ...columnStatus, locations: e.target.checked });
        }
        if (value === "subCategory") {
            setColumnStatus({ ...columnStatus, sub_category: e.target.checked });
        }
        if (value === "Staffs") {
            setColumnStatus({ ...columnStatus, staff: e.target.checked });
        }
        if (value === "Price") {
            setColumnStatus({ ...columnStatus, price: e.target.checked });
        }

    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('click', handleClickOutsideBrand);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('click', handleClickOutsideBrand);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Services-List";
      }, []);
    

    useEffect(() => {
        if (searchValue) {
            const search = setTimeout(() => {
                //Your search query and it will run the function after 3secs from user stops typing
                var keyword = searchValue.toLowerCase();
                fetchAllCategories(keyword)
            }, 1000);
            return () => clearTimeout(search)
        }
        else {
            fetchAllCategories()
        }
    }, [searchValue])

    useEffect(() => {
        if (searchBrandValue) {
            const search = setTimeout(() => {
                //Your search query and it will run the function after 3secs from user stops typing
                var keyword = searchBrandValue.toLowerCase();
                fetchAllBrand(keyword)
            }, 1000);
            return () => clearTimeout(search)
        }
        else {
            fetchAllBrand()
        }
    }, [searchBrandValue])

    return (
        <div className='serviceList' key={newMessagekey}>
            <DashboardNavbar title="Services" setKeyword={setKeyword}/>
            {/* Start TopFixedHeader */}
            {/* <div className='topHeader_'>
                <div className="topStickyHeader_ position-static w-100 mb-0">
                    <div className="row align-items-center">
                        <div className="col-md-9">
                        </div>
                        <div className='col-md-3'>
                            <div className='actionbtns_'>
                                <div className="dropdown weekDropdown me-2 dropdown-menu-end">
                                    <img src={addPlusBtn} alt="" className="img-fluid plusDropImg ms-2" data-bs-toggle="dropdown" aria-expanded="false" />
                                    <button className="productHeadBtn ms-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"><span className='plusIcon_ m-0'><i className="fa-solid fa-plus"></i> </span></button>
                                    <ul className="dropdown-menu categoryDrop">
                                        <li className='categoryFlex tablesubHead '>
                                            <input
                                                className="me-2"
                                                type="checkbox"
                                                id="columnStatus"
                                                value={columnStatus?.category}
                                                onChange={(e) => handleFilterValue(e, 'Category')}
                                            />
                                            <label className='tablesubHead ' for="view1">Category</label>
                                        </li>
                                        <li className='categoryFlex '>
                                            <input
                                                className="me-2"
                                                type="checkbox"
                                                id="columnStatus"
                                                value={columnStatus?.locations}
                                                onChange={(e) => handleFilterValue(e, 'locations')}
                                            />
                                            <label className='tablesubHead mb-3' for="view1">Locations</label>
                                        </li>
                                        <li className='categoryFlex '>
                                            <input
                                                className="me-2"
                                                type="checkbox"
                                                id="columnStatus"
                                                value={columnStatus?.locations}
                                                onChange={(e) => handleFilterValue(e, 'Location')}
                                            />
                                            <label className='tablesubHead ' for="view1">Locations</label>
                                        </li>
                                        <li className='categoryFlex '>
                                            <input
                                                className="me-2"
                                                type="checkbox"
                                                id="columnStatus"
                                                value={columnStatus?.sub_category}
                                                onChange={(e) => handleFilterValue(e, 'subCategory')}
                                            />
                                            <label className='tablesubHead ' for="view1">Sub-Category</label>
                                        </li>
                                        <li className='categoryFlex '>
                                            <input
                                                className="me-2"
                                                type="checkbox"
                                                id="columnStatus"
                                                value={columnStatus?.staff}
                                                onChange={(e) => handleFilterValue(e, 'Staffs')}
                                            />
                                            <label className='tablesubHead ' for="view1">Staffs</label>
                                        </li>
                                        <li className='categoryFlex '>
                                            <input
                                                className="me-2"
                                                type="checkbox"
                                                id="columnStatus"
                                                value={columnStatus?.price}
                                                onChange={(e) => handleFilterValue(e, 'Price')}
                                            />
                                            <label className='tablesubHead ' for="view1">Price</label>
                                        </li>
                                    </ul>
                                </div>
                                {
                                    selectedRows?.length > 0 && activeTab == 'ServiceTable' ?
                                        <button className='deletebtn_' disabled={loading} type="button" onClick={() => handleDeleteProduct()}> <i className="fas fa-trash-alt"></i> </button>
                                        : <></>
                                }
                                <button className='productHeadBtn me-2' type="button" onClick={() => handleDownloadCSV()}> <i className="fas fa-arrow-down"></i> </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* End TopFixedHeader */}
            {/* Start TableFilter Area */}
            <div className='tableSectionBackground'>
                <div className='tableFihandleBrandDataChangelters tableFilters'>
                    <div className='col-lg-12 mt-2'>
                        <div className='row'>
                            {/* <div className='col-md-3'>
                            <div className="calenderIcon">
                                <img src={calendar} alt="calender" />                               
                                <DatePicker
                                    className='cateDate'
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    placeholderText="Date"
                                />
                            </div>
                        </div> */}
                            <div className='col-md-6'>
                                <div className='productMainCategory'>
                                    <div className='productMainbox_'>
                                        <button className="filterTab_" onClick={() => handleToggle()}
                                        >
                                            Filter By Category <span ><i className="fal fa-chevron-down" aria-hidden="true"></i></span>
                                        </button>
                                        {
                                            isOpen ?
                                                <div className="filterDropdown_" ref={containerRef}>
                                                    <div className="input-group search_group">
                                                        <img src={search_light} alt="" className="img-fluid searchIcon" />
                                                        <input type="text" className="form-control" placeholder="Search here" value={searchValue} onChange={(e) => { setSearchValue(e.target.value); setSelectedValue(false) }} />
                                                    </div>
                                                    <div className='listdropfilterList_'>
                                                        {
                                                            loading ?
                                                                <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                                                                :
                                                                <>
                                                                    {
                                                                        categoryData?.length > 0 ?
                                                                            categoryData?.map((val, index) => {
                                                                                return (
                                                                                    <div className='listProductbody_ mt-3' key={index}>
                                                                                        <h3 className='mainboldHead'>{val?.name}</h3>
                                                                                        <ul className='listItemsprodct_'>
                                                                                            {
                                                                                                val?.sub_catgories?.map((data, index) => {
                                                                                                    return (
                                                                                                        <li className={`textParagh ${subCategoryIds?.includes(data?.id) ? "selectProduct" : ""} mt-2 mb-2`} key={index} onClick={() => { handleSubcategoryChange(data?.id); setSelectedValue(true) }}>{(data?.name)?.slice(0, 30)}{data?.name?.length >= 30 ? "..." : ""}</li>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>

                                                                                    </div>
                                                                                )
                                                                            })
                                                                            : <div>No Categories Found</div>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='productListTab productListBtn'>
                                    <div className='actionbtns_'>
                                        <div className="dropdown weekDropdown me-2 dropdown-menu-end">
                                            {/* <img src={addPlusBtn} alt="" className="img-fluid plusDropImg ms-2" data-bs-toggle="dropdown" aria-expanded="false" /> */}
                                            <button className="productHeadBtn ms-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"><span className='plusIcon_ m-0'><i className="fa-solid fa-plus"></i> </span></button>
                                            <ul className="dropdown-menu categoryDrop">
                                                <li className='categoryFlex tablesubHead '>
                                                    <input
                                                        className="me-2"
                                                        type="checkbox"
                                                        id="columnStatus"
                                                        checked={columnStatus?.category}
                                                        onChange={(e) => handleFilterValue(e, 'Category')}
                                                    />
                                                    <label className='tablesubHead ' for="view1">Category</label>
                                                </li>
                                                {/* <li className='categoryFlex '>
                                                    <input
                                                        className="me-2"
                                                        type="checkbox"
                                                        id="columnStatus"
                                                        checked={columnStatus?.locations}
                                                        onChange={(e) => handleFilterValue(e, 'Location')}
                                                    />
                                                    <label className='tablesubHead ' for="view1">Locations</label>
                                                </li> */}
                                                <li className='categoryFlex '>
                                                    <input
                                                        className="me-2"
                                                        type="checkbox"
                                                        id="columnStatus"
                                                        checked={columnStatus?.sub_category}
                                                        onChange={(e) => handleFilterValue(e, 'subCategory')}
                                                    />
                                                    <label className='tablesubHead ' for="view1">Sub-Category</label>
                                                </li>
                                                <li className='categoryFlex '>
                                                    <input
                                                        className="me-2"
                                                        type="checkbox"
                                                        id="columnStatus"
                                                        checked={columnStatus?.staff}
                                                        onChange={(e) => handleFilterValue(e, 'Staffs')}
                                                    />
                                                    <label className='tablesubHead ' for="view1">Staffs</label>
                                                </li>
                                                <li className='categoryFlex '>
                                                    <input
                                                        className="me-2"
                                                        type="checkbox"
                                                        id="columnStatus"
                                                        checked={columnStatus?.price}
                                                        onChange={(e) => handleFilterValue(e, 'Price')}
                                                    />
                                                    <label className='tablesubHead ' for="view1">Price</label>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            selectedRows?.length > 0 && activeTab == 'ServiceTable' ?
                                                <button className='deletebtn_' disabled={loading} type="button" onClick={() => handleDeleteProduct()}> <i className="fas fa-trash-alt"></i> </button>
                                                : <></>
                                        }
                                        <button className='productHeadBtn me-2' type="button" onClick={() => handleDownloadCSV()}> <i className="fas fa-arrow-down"></i> </button>

                                    </div>
                                    <button className={`plansBtns ${activeTab === 'ServiceTable' ? 'active' : ""}`}
                                        onClick={() => setActiveTab('ServiceTable')}
                                    >
                                        <img src={productList1} alt="image" className='showImage' />
                                        <img src={productList2} alt="image" className='hideImage' />
                                    </button>
                                    <button className={`plansBtns ${activeTab === 'ServiceTableView' ? 'active' : ""}`}
                                        onClick={() => setActiveTab('ServiceTableView')}>
                                        <img src={productList3} alt="image" className='hideImage' />
                                        <img src={productList4} alt="image" className='showImage' />
                                    </button>
                                </div>
                            </div>

                            {/* <div className='col-md-3'>
                    <div className='productMainbox_'>
                        <button className="filterTab_" onClick={() => handleBrandToggle()}
                        >
                            Filer By Brand <span ><i className="fal fa-chevron-down" aria-hidden="true"></i></span>
                        </button>
                        {
                            isBrandOpen ?
                                <div className="filterDropdown_" ref={brandRef}>
                                    <div className="input-group search_group">
                                        <img src={search_light} alt="" className="img-fluid searchIcon" />
                                        <input type="text" className="form-control" placeholder="Search here" value={searchBrandValue} onChange={(e) => {setSearchBrandValue(e.target.value);setSelectedValue(false)}} />
                                    </div>
                                    <div className='listdropfilterList_'>
                                        {
                                            loading ?
                                                <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                                                :
                                                <>
                                                    {
                                                        brand?.length > 0 ?
                                                                    <div className='listProductbody_ mt-3'>
                                                                        <ul className='listItemsprodct_'>
                                                                            {
                                                                                brand?.map((data, index) => {
                                                                                    return (
                                                                                        <li className={`textParagh ${brandIds?.includes(data?.id) ? "selectProduct" : ""} mt-2 mb-2`} key={index} onClick={() => { handleBrandChange(data?.id);setSelectedValue(true) }}>{(data?.name)?.slice(0, 30)}{data?.name?.length >= 30 ? "..." : ""}</li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                          
                                                            : <div>No Brand Found</div>
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                                : <></>
                        }
                    </div>
                </div> */}
                        </div>
                    </div>
                </div>

                {/* End TableFilter Area */}

                {loading === true ?
                    <span className="spinner-border inner-spin spinner-border-sm serviceList"></span>
                    :
                    <div className='listAreaContainer m-0'>
                        {activeTab == 'ServiceTable'
                            ?
                            <ServiceListTable ref={childRef} keyword={keyword} selectedRows={selectedRows} setSelectedRows={setSelectedRows} columnStatus={columnStatus} startDate={startDate} endDate={endDate} selectedValue={selectedValue} subCategoryId={subCategoryIds} brandId={brandIds}
                                serviceData={serviceData} /> : activeTab == "ServiceTableView" ?
                                <ServiceListView keyword={keyword} columnStatus={columnStatus} startDate={startDate} endDate={endDate} selectedValue={selectedValue} subCategoryId={subCategoryIds} brandId={brandIds} serviceData={serviceData} />
                                : ""
                        }
                    </div>}
            </div>
        </div>
    )
}

export default ServiceList