import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { getWalletBallanceApiAsync, issueSilaBalanceToYourWalletAsync, redeemBalanceToYourBankAsync } from '../dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';

const AddbalanceModal = (props) => {
  const [amount, setAmount] = useState("")
  const [selectedBank, setSelectedBank] = useState("")
  const history = useHistory();
  const dispatch = useDispatch();
  const toastId = React.useRef(null)
  const [buttonLoading, setButtonLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  const handleSubmit = () => {
    if (!selectedBank) {
      toast.error("Please Select Bank Account")
      return false
    }
    if (!amount) {
      toast.error(`Please Enter Amount to Add to Your Wallet`)
      return false
    }
    let params = {
      postData: {
        "amount": amount * 100,
        "account_name": selectedBank
      },
      tokenData: auth?.payload?.token
    }
    setButtonLoading(true)
    dispatch(issueSilaBalanceToYourWalletAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        let data = {
          tokenData: auth?.payload?.token
        }
        dispatch(getWalletBallanceApiAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            props.close()
            setSelectedBank("")
            setAmount("")
            setButtonLoading(false)
          })
          .catch((obj) => {
            setButtonLoading(false)
          })
      }
      )
      .catch((obj) => {
        setButtonLoading(false)
      })
  }

  const handleWithdrawalSubmit = () => {
    if (!selectedBank) {
      toast.error("Please Select Bank Account")
      return false
    }
    if (!amount) {
      toast.error(`Please Enter Amount to Withdraw from Your Wallet`)
      return false
    }
    if (amount > props.walletBalance) {
      toast.error("You don't have sufficient funds to withdraw")
      return false
    }
    let params = {
      postData: {
        "amount": amount,
        "account_name": selectedBank
      },
      tokenData: auth?.payload?.token
    }
    setButtonLoading(true)
    dispatch(redeemBalanceToYourBankAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        let data = {
          tokenData: auth?.payload?.token
        }
        props.walletList()
        dispatch(getWalletBallanceApiAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            props.close()
            setSelectedBank("")
            setAmount("")
            setButtonLoading(false)
          })
          .catch((obj) => {
            setButtonLoading(false)
          })
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
      }
      )
      .catch((obj) => {
        setButtonLoading(false)
      })
  }

  return (
    <div className='addbalnceModal_'>
      <div className="mb-4 form-group">
        <label className="deatilsHead mb-3">Enter Amount to {props.flag === 'withdraw' ? "Withdraw" : 'Add'}</label>
        <input
          type="number"
          min="0"
          onKeyDown={blockInvalidChar}
          className="customform-control"
          name="email"
          placeholder={"Enter amount to " + props.flag}
          value={amount} onChange={(e) => setAmount(e?.target?.value)}
        />
      </div>

      {props.bankDetails?.length > 0 ?
        <>
          {
            props.bankDetails?.map((val, index) => {
              return (
                <div className={selectedBank == val?.account_name ? 'accountDetails addaccountBox_ active mb-3' : 'accountDetails addaccountBox_ mb-3'} style={{ cursor: "pointer" }} key={index} onClick={() => setSelectedBank(val?.account_name)}>
                  <figure className='bankIcon_'><i className="fas fa-university"></i></figure>
                  <div className='acountDetails_'>
                    <h4 className="textInnerDark mb-2">A/c No. - <span className='textinner_'>{val?.account_number}</span></h4>
                    <h4 className="textInnerDark mb-2">A/c Holder Name: <span className='textinner_'>{val?.account_owner_name}</span></h4>
                    <h4 className="textInnerDark ">Bank Name: <span className='textinner_'>{val?.bank_name}</span></h4>
                  </div>
                </div>
              )
            })
          }
        </>
        :
        <div>No Bank Account Added</div>
      }
      <button className='primary_btn mt-3 mb-2 pt-3 pb-3' onClick={props.flag === 'withdraw' ? () => handleWithdrawalSubmit() : () => handleSubmit()} disabled={buttonLoading === true}> {buttonLoading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
        &nbsp;&nbsp;
        {props.flag === 'withdraw' ? "Withdraw" : 'Add Balance'}</button>
    </div>
  )
}

export default AddbalanceModal