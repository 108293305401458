import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clickImg } from "../../../../utilities/images";
import { selectLoginAuth } from "../../auth/authSlice";
import { getMerchantStepStatusAsync } from "../../dashboard/dashboardSlice";

const ViewPlanModal = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(props?.loading);
  const [selectedPlan, setSelectedPlan] = useState(props?.selectedPlan);
  const auth = useSelector(selectLoginAuth);

  const handleOpenModal = (flags) => {
    props?.flag(flags);
  };

  const getMerchantStepStatus = () => {
    setLoading(true);
    dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // getMerchantActiveSubscription()
    getMerchantStepStatus();
  }, []);
  return (
    <>
      <div className="viewPlanSub">
        {loading === true ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            {selectedPlan?.length > 0 ? (
              <div className="configPlanCard">
                <h1 className="validText">Your Plan</h1>
                <div className="dFlex">
                  <p className="innerHeadingSky mb-2">
                    {selectedPlan[0]?.price_id?.metadata?.name}
                  </p>
                  <button
                    className="saveBtn"
                    onClick={() => {
                      handleOpenModal("PickPlan");
                    }}
                  >
                    Change Plan
                  </button>
                </div>
                <p className="descriptionPara_ fw-normal">
                  {selectedPlan[0]?.price_id?.nickname}
                </p>
                <div className="yourPlan">
                  <h1 className="subtextDark_ fw-bold mb-2">
                    Your Plan includes app
                  </h1>
                  <form action="#">
                    {props?.planIncludesApp.length > 0 ? (
                      props?.planIncludesApp?.map((val, ind) => {
                        console.log(val, "valbaaaa");
                        return (
                          <div key={ind} className="customRadio_">
                            <input type="radio" id="test1" checked />
                            <label for="test1">{val?.name}</label>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
                <div className="yourPlan">
                  <h1 className="subtextDark_ fw-bold mb-2">Plan Features</h1>
                  <ul className="otherMainus">
                    {props?.planFeatures?.length > 0 ? (
                      props?.planFeatures?.map((val, ind) => {
                        return (
                          <li key={ind} className="otherItems">
                            <img src={clickImg} className="" alt="" />
                            <span className="">{val?.name}</span>
                          </li>
                        );
                      })
                    ) : (
                      <div>No Plan Feature Found</div>
                    )}
                  </ul>
                </div>
                <div className="nextBilling">
                  <h1 className="subtextDark_ fw-bold mb-2">
                    Next billing date
                  </h1>
                  {/* <p className='subtext_ fw-normal'> for$USD</p> */}
                  <p className="subtext_ fw-normal">
                    {moment(selectedPlan[0]?.expiry_date).format(
                      "MMM DD, YYYY"
                    )}{" "}
                    for ${selectedPlan[0]?.amount}USD
                  </p>
                </div>
                <div className="nextBilling">
                  <h1 className="subtextDark_ fw-bold mb-2">Payment method</h1>
                  <p className="subtext_ fw-normal">
                    {/* <img src={visa} className="visaImg me-2" /> */}
                    Card
                    {/* JBR Coin */}
                  </p>
                </div>
              </div>
            ) : (
              <div>No Active Plan Found</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ViewPlanModal;
