import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { retailer, searchUser } from '../../../../utilities/images'
import Multiselect from 'multiselect-react-dropdown';
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../auth/authSlice';
import { getChatUserListAsync } from '../messageSlice';
import { unwrapResult } from "@reduxjs/toolkit";
import { propTypes } from 'react-bootstrap/esm/Image';


const ChatNew = (props) => {
    const [options_, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()

    // const data = [{ cat: 1, value: "cerulean" },
    // { cat: 2, value: "fuchsia rose" },
    // { cat: 3, value: "true red" },
    // { cat: 4, value: "aqua sky" },
    // { cat: 5, value: "tigerlily" },
    // { cat: 6, value: "blue turquoise" }];
    const handleSearch = (value) => {
        //setLoading(true);
        //const results = value ? data.filter(w => w.value.toLowerCase().includes(value)) : []
        //setTimeout(r => { setOptions(results); setLoading(false) }, 400, results)

        let params = {
            token: auth?.payload?.token,
            search: value,
        }

        dispatch(getChatUserListAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                
                const result = obj?.payload?.merchants?.data
                if(result.length > 0 ){
                    setLoading(true);
                    const resultOne = value ? result.filter(w => w.organization_name.toLowerCase().includes(value)) : []
                    setTimeout(r => { setOptions(resultOne); setLoading(false) }, 400, resultOne)
                }
            }
            )
            .catch((obj) => {
                
            })
    }

    
    const handleSelect = (item) => {
        props.userData(item);
    }

    const handleOptionValue = (e,a) => {
        return (
            <>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCHkgJPx9vKxK-UV2Oqs_FaBstYEz0JqBb4OXGemg&s" alt="" height="50px" width="50px" />
               {/* {e} */}
               {a.organization_name} {a.seller_type}
              
            </>
        )
    }

    useEffect(() => {
        //getChatUserList()
    }, [])

    return (
        <>
            <div className='rightMessagebox_'>
                <div className='messageTophead p-0'>
                    <div className='topcahtSearch'>
                        <h5 className='boldsubHead_'>To:</h5>
                        {/* <input type="search" className="form-control" id="gsearch" name="gsearch"></input> */}
                        <div className='serchtopChat_'>
                        <Multiselect
                            displayValue="organization_name"
                            options={options_}
                            onSearch={handleSearch}
                            loading={loading}
                            placeholder='Search here'
                            hidePlaceholder={true}
                            selectionLimit={1}
                            onSelect={handleSelect}
                            onRemove={handleSelect}
                            optionValueDecorator={(e,a) => handleOptionValue(e,a)}
                            selectedValueDecorator={(e,a) => handleOptionValue(e,a)}
                        />
                        </div>
                        <img className='searchUser_' src={searchUser} />
                    </div>

                </div>
                <div className='broadCastmsg'>
                    <input type="text" className="form-control" placeholder="Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                </div>
                <div className='message_body'>
                    <div className='chatDatebox_'>
                        {/* <h5 className='chatdeateHeading'>Today | April 20, 2022</h5> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatNew