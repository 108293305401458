import React, { useEffect } from 'react'
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import { calendar, imageStock2 } from '../../../../utilities/images';
import { useState } from 'react';
import DataTable from 'react-data-table-component'
import { useHistory } from 'react-router-dom';
import products from '../../../db/category.json';
import { getInventoryDetailsByIdApiAsync } from '../../dashboard/dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { unwrapResult } from '@reduxjs/toolkit';

const InventoryviewDetail = () => {
    const [key, setKey] = useState(Math.random());
    const history = useHistory()
    const dispatch = useDispatch()
    const [inventoryData, setInventoryData] = useState([])
    const { id, flag } = useParams();
    const auth = useSelector(selectLoginAuth)
    const [enterprisesDate, setEnterprisesDate] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false)

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const columns1 = [
        {
            name: '#',
            selector: row => row.id,
        },
        {
            name: 'Date',
            selector: row => {
                return (
                    <>
                        <div className='ms-2'>
                            <p className='personalinfoHead mt-2'>
                                <span className='contentSubtext_ me-3 ms-3'>{moment(row?.created_at).format("MMM DD,YYYY | H:mm")}</span>
                            </p>
                        </div>
                    </>
                );
            },
        },
        {
            name: 'Stock in Hand',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.rest_quantity}</span>
                    </>
                );
            },
        },
        {
            name: 'Sold',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.sold_quantity}</span>
                    </>
                );
            },
        },
        {
            name: 'Adjusted',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.adjusted_quantity}</span>
                    </>
                );
            },
        },
        {
            name: 'New Added',
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.new_added_quantity}</span>
                    </>
                );
            },
        },
        {
            selector: row => {
                return (
                    <>
                        <span className='textParagh '>{row?.responsible}</span>
                    </>
                );
            },
            name: 'Responsible'
        },
    ];
    const handleRowClick = (e) => {
        setKey(Math.random());
    }

    const inventoryDetailsHandle = () => {
        let params = {
            'postData': {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                id: id
            },
            'tokenData': auth?.payload?.token
        }
        setLoading(true)
        dispatch(getInventoryDetailsByIdApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setInventoryData(obj?.payload)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        inventoryDetailsHandle()
    }, [])

    return (
        <div className='inventoryviewDetail'>
            <div className='topHeader_'>
                <div className="topStickyHeader_">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="anotherHead"> <i onClick={() => history.push(`/${flag}`)} className="fal fa-chevron-left" aria-hidden="true"></i>{inventoryData ? inventoryData[0]?.products?.name : ""}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tableFilters'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="calenderIcon">
                            <img src={calendar} alt="calender" />
                            {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                            <DatePicker
                                className='cateDate'
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText="Date"
                            />
                        </div>
                    </div>
                    {/* <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option  >Country </option>
                            <option  >Category1</option>
                            <option  >Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option  >State </option>
                            <option  >Category1</option>
                            <option  >Category2</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option  >Id type </option>
                            <option  >Category1</option>
                            <option  >Category2</option>
                        </select>
                    </div> */}
                </div>
            </div>
            {
                loading === true ?
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                    :
                    <DataTable
                        pagination
                        columns={columns1}
                        defaultSortFieldId={1}
                        data={inventoryData}
                        onRowClicked={(e) => handleRowClick(e)}
                    />
            }
        </div>
    )
}

export default InventoryviewDetail