import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import {taxes,Verfidsky,usa,florida,manage} from '../../../../utilities/images';

const StateTaxModal = (props) => {

  return (
   <>
   <div className='countryModal'>
   <h1 className='linkTxt fw-semibold mb-4'>This will show in your financial reports.</h1>
        <div className='taxInformation businessDetails'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='d-flex align-items-center'>
                        <div className='opertaingFlag me-3'>
                            <img src={usa} alt=""/>
                        </div>
                        <div className='operatinText'>
                            <h1 className='smalllightHead_ fw-500'>Operating Country</h1>
                            <p className='descriptionPara_ fw-normal'>United States of America</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-11'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='d-flex align-items-center'>
                                <div className='opertaingFlag me-3'>
                                    <img src={florida} alt=""/>
                                </div>
                                <div className='operatinText align-items-start' >
                                    <h1 className='smalllightHead_ fw-500'>Florida</h1>
                                    <p className='descriptionPara_ fw-normal mb-2'>Florida's general state sales tax rate is 6%</p>
                                    <Link to="#"><img src={manage} alt="" className='me-2'/> <span className='subtextSmall_'>Checkout for more</span></Link>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div className='footerSec mt-4'>
            <button to='' className='closebtnModal me-4'>Cancel</button>
            <button to='' className='exchangeBtn lh-sm'>Active</button>
        </div>
    </div>
   </>
  )
}

export default StateTaxModal