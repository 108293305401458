import React, { useEffect } from 'react'
import { calendar, user, shield, invoice, profileImg1 } from '../../../../utilities/images'
import DatePicker from "react-datepicker";
import moment from 'moment-timezone'
import { useState } from 'react';
import DataTable from 'react-data-table-component'
import products from "../../../db/category.json"
import { Link, useHistory } from 'react-router-dom';
import CustomModal from '../../../components/shared/CustomModal';
import WalletrefundModal from './walletrefundModal';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { getWalletRefundDataApiAsync } from '../../dashboard/dashboardSlice';

const WalletRefund = () => {
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const [key, setKey] = useState(Math.random());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [walletRefundData, setWalletRefundData] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const auth = useSelector(selectLoginAuth)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleRowClick = (e) => {
        setSelectedValue(e)
        setModalDetail({ show: true, flag: "WalletrefundModal" });
        setKey(Math.random());
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };
    const columns1 = [
        {
            name: '#',
            width: "50px",
            selector: (row, i) => i + 1
        },
        {
            name: "Refund from",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.refund_from?.image ? row?.refund_from?.image : profileImg1} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ '>{row?.refund_from?.name}</span>
                                <p className='mb-0'>
                                    <img src={shield} className='img-fluid me-2' />
                                    <span className='tablesubHead'>{row?.refund_from?.unique_number}</span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "250px",
        },
        {
            name: 'Transaction id',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <span className='textParagh '>{row?.transaction_id?.length > 20 ? row?.transaction_id?.substring(0, 20) + "..." : row?.transaction_id}</span>
                        </div>
                    </>
                );
            },
        },
        {
            name: 'Date',
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2'>
                            <span className='textParagh '>{moment(row?.updatedAt).format("MMM DD, YYYY")}</span>
                            <span className='prop_span'>{moment(row?.updatedAt).format("hh:mm a")}</span>
                        </div>
                    </>
                );
            },
        },
        {
            name: 'Refund Amount',
            selector: row => {
                return (
                    <>
                        <strong onClick={() => handleRowClick(row)} className='contentSubtext_'>JBR {row?.amount}</strong>
                    </>
                );
            },
        },

        {
            name: 'Reason',
            selector: row => {
                return (
                    <>
                        <span onClick={() => handleRowClick(row)} className='contentSubtext_'>{row?.reason}</span>
                    </>
                );
            },
        },
        {
            name: 'Status',
            width: "250px",
            selector: row => {
                return (
                    <>
                        <div onClick={() => handleRowClick(row)} className='ms-2 tableBtn__'>
                            <Link to='#' className='pendingStatus'>{row?.status}</Link>
                            <img className='refundInvoice me-2 ms-4' src={invoice} />
                            <i className="fal fa-chevron-right ms-3"></i>
                        </div>
                    </>
                );
            },
        }
    ];

    const walletRefundDataHandle = () => {
        let params = {
            'tokenData': auth?.payload?.token,
            'postData': {
                page: currentPage,
                limit: perPage
            }
        }
        if (startDate != null && endDate != null) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getWalletRefundDataApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                console.log(obj,'llllllllllllllllllllllllllll');
                setWalletRefundData(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                setLoading(false)
            })
            .then((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        walletRefundDataHandle()
    }, [endDate, perPage, currentPage])

    return (
        <>
            <div className='walletRefund_'>
                <div className='topHeader_'>
                    <div className="topStickyHeader_">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <span>Wallet / <span className="innerSubtext">Refunded</span> </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start TableFilter Area */}
                <div className='tableFilters mb-3 mt-4'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="calenderIcon">
                                <img src={calendar} alt="calender" />
                                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                    dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                                <DatePicker
                                    className='cateDate'
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    placeholderText="Date"
                                />
                            </div>
                        </div>
                        {/* <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    Country
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    State
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <select className="cateSelectdFilter" required>
                                <option value="" selected>
                                    ID type
                                </option>
                                <option>Category1</option>
                                <option>Category2</option>
                            </select>
                        </div> */}
                    </div>
                </div>
                {/* End TableFilter Area */}
            </div>
            <div className='commonTableheight table-responsive'>
                <DataTable
                    pagination
                    columns={columns1}
                    data={walletRefundData}
                    defaultSortFieldId={1}
                    onRowClicked={handleRowClick}
                    customStyles={customStyles}
                    paginationServer
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                />
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "WalletrefundModal" ? "commonWidth customContent" : ""}
                ids={modalDetail.flag === "WalletrefundModal" ? "walletrefundModal" : ""}
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            <h2 className="modalHeading">
                                Refund
                            </h2>
                            <button className="closeButton">
                                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                            </button>
                        </div>
                    </div>
                }
                child={
                    modalDetail.flag === "WalletrefundModal" ? (
                        <WalletrefundModal selectedValue={selectedValue} close={() => handleOnCloseModal()} />
                    ) : (
                        ""
                    )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default WalletRefund