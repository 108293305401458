import React from 'react';
import { Route } from 'react-router-dom';
import Authenticator from './authenticator';

// import DashboardLayout from '../components/shared/DashboardLayout';
import ProductLayout from '../components/shared/ProductLayout';

const ProductPrivateRoute = ({ component: Component, allowed, ...rest }) => (
  <Route {...rest} render={props => (
    <Authenticator isAuthenticationRequired={true} {...props}>
        <Component {...props} />
    </Authenticator>
  )} />
)

export default ProductPrivateRoute;
