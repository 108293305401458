import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { user } from '../../../../utilities/images'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import CustomModal from '../../../components/shared/CustomModal';
import ReportModal from './reportModal'
import { getDepartmentDataInSupportApiAsync, getSingleOrderPurchaseApiAsync, reportAnIssueApiAsync } from '../../dashboard/dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../auth/authSlice'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { unwrapResult } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

const ReceivedreportInvoice = () => {
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch()
    const { id } = useParams();
    const toastId = React.useRef(null)
    const [notes, setNotes] = useState("");
    const [data, setData] = useState("");
    const [issueData, setIssueData] = useState([]);
    const [productIds, setProductIds] = useState([]);
    const [issueId, setIssueId] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const history = useHistory()
    const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleDepartmetChange = (e) => {
        setIssueId(e.target.value)
    }

    const handleModalDetails = () => {
        setModalDetail({ show: false, flag: "" })
        setKey(Math.random())
        history.push(`/receivedPurchase`)
    }

    const handleCheckBox = (id) => {
        const exists = productIds?.includes(id)
        if (exists === false) {
            setProductIds([...productIds, id])
        } else {
            const newData = productIds?.filter((c) => { return c !== id })
            setProductIds(newData)
        }
    }

    const handleReportIssue = () => {
        if (!issueId) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select any one Issue");
            }
            return false
        }
        if (!notes) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please write something about the issue");
            }
            return false
        }
        if (productIds?.length === 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please Select Products");
            }
            return false
        }
        let paramData = {
            "postData": {
                subject_id: issueId,
                email: data?.user_details?.email,
                name: data?.user_details?.firstname,
                notes: notes,
                type: "support",
                app_name: "merchant",
                order_id: id,
                product_ids: productIds
            },
            "tokenData": auth?.payload?.token
        }
        console.log(paramData, 'dataaaaaaaaaaaaaaaaaaaa');
        setLoadingButton(true)
        dispatch(reportAnIssueApiAsync(paramData))
            .then(unwrapResult)
            .then((obj) => {
                setLoadingButton(false)
                setModalDetail({ show: true, flag: "ReportModal" });
                setKey(Math.random());
            }
            )
            .catch((obj) => {
                setLoadingButton(false)
            })
    }

    const handleSinglePurchaseData = () => {
        let params = {
            "postData": {
                id: id
            },
            "tokenData": auth?.payload?.token
        }
        dispatch(getSingleOrderPurchaseApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    const getAllDepartment = () => {
        setLoading(true)
        dispatch(getDepartmentDataInSupportApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setIssueData(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getAllDepartment()
        handleSinglePurchaseData()
    }, [])

    return (
        <div className='reportInvoice'>
            {/* Start FixedTopHeader */}
            <div className='topHeader_'>
                <div className="productTopStickyHeader_ mb-3">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="innerSubheadNormal">
                                <span onClick={() => history.push('/receivedPurchase')}>
                                    <i className="las la-angle-left me-3 directionArrow"></i>
                                </span>
                                <span>Report an Issue</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* End FixedTopHeader */}
            <div className="refundorderWrapper">
                {
                    loading === true ?
                        <span className="spinner-border inner-spin spinner-border-sm serviceList"></span>
                        :
                        <div className='container-fluid pt-3 pb-3'>
                            <div className='row justify-content-center'>
                                <div className='col-md-10 '>
                                    <div className="toporderCommon">
                                        <div className="row">
                                            <div className='CustomerOnlinestatus_ '>
                                                <div className='invoiceHead mb-3'>
                                                    <h3 className='invoiceHeading'>Invoice</h3>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className='invoiceForm'>
                                                            <h5 className='semiboldHeading_'>Invoice from:</h5>
                                                            <p className='smalllightHead_'>{data?.seller_details?.organization_name}</p>
                                                            <p className='smalllightHead_'>{data?.seller_details?.phone_number}</p>
                                                            <p className='smalllightHead_'>{data?.seller_details?.current_address?.street_address}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <div className='invoiceForm'>
                                                            <h5 className='semiboldHeading_'>Return/Refund:</h5>
                                                            <p className='smalllightHead_ fw-500'>Driver# <Link to='#' className='textBlue'>{data?.driver_details?.uid}</Link></p>
                                                            <p className='smalllightHead_ fw-500'>POS #36259</p>
                                                            <p className='smalllightHead_ fw-500'>Customer Unreachable </p>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-4">
                                                        <div className="invoiceForm">
                                                            <h5 className='semiboldHeading_'>Invoice details</h5>
                                                            <p className='invoiceHead'>
                                                                Invoice Id : <span>{data?.invoice?.invoice_id}</span>
                                                            </p>
                                                            <p className='invoiceHead'>
                                                                Created Date : <span>{data?.invoice?.created_date}</span>
                                                            </p>
                                                            <p className='invoiceHead'>
                                                                Recieved Date : <span>{data?.invoice?.delivery_date}</span>
                                                            </p>
                                                            <p className='invoiceHead fw-500'>
                                                                <span>{data?.invoice?.due_date}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="customerInvoicetable mt-4">
                                            <div className="table-responsive">
                                                <table className="table  listItemsContainer">
                                                    <thead className="tHead_">
                                                        <th className="text-start">#</th>
                                                        <th className="text-start"></th>
                                                        <th className="text-start">Descriptions</th>
                                                        <th className="text-start">No. of Items</th>
                                                        <th className="text-start">Rate</th>
                                                        <th>Amount</th>
                                                        <th className="text-start">Condition</th>
                                                        <th className="text-start">Action</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr role="row">
                                                            {
                                                                data?.order_details?.length > 0 ?
                                                                    data?.order_details?.map((v, i) => {
                                                                        return (
                                                                            <React.Fragment key={i}>
                                                                                <td className="text-start">1</td>
                                                                                <td className="text-start">
                                                                                    <div className='rolecheckbox_'>
                                                                                        <input
                                                                                            className="me-2"
                                                                                            type="checkbox"
                                                                                            id="view1"
                                                                                            value=""
                                                                                            checked={productIds?.includes(v?.id)}
                                                                                            onChange={() => handleCheckBox(v?.id)}
                                                                                        />
                                                                                        <label className='contentheadtext_ mb-3' htmlFor="view1"> </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-start productonlineDetail">
                                                                                    <img src={user} alt="profileimg" className="tableProductimg" />
                                                                                    <div className="name_content">
                                                                                        <h6 className="contentheadtext_">{v?.product_name}</h6>
                                                                                        <span className="contentHeadtextlight_">Box of 25</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <input type="text" className='cateSelectd' placeholder='' value={v?.qty} />
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <p className="contentheadtext_">${v?.price}</p>
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <p className="itemsTxt">${v?.price * v?.qty}</p>
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <select className="cateSelectdFilter" required onChange={(e) => handleDepartmetChange(e)}>
                                                                                        <option value="">Select One</option>
                                                                                        {
                                                                                            (issueData && issueData?.length > 0) ?
                                                                                                <>
                                                                                                    {issueData?.map((value, index) => {
                                                                                                        return (
                                                                                                            <option value={value?.id} key={index}>{value?.name}</option>
                                                                                                        )
                                                                                                    })}
                                                                                                </> : <></>
                                                                                        }
                                                                                    </select>
                                                                                </td>
                                                                                <td className="text-start">
                                                                                    <Link to='#' className='acceptBtn_ ' >Report</Link>
                                                                                    {/* <Link to='#' className='acceptBtn_ ' >Report this Item</Link> */}
                                                                                </td>
                                                                            </React.Fragment>)
                                                                    }) : <></>
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="totalArea">
                                                <div className='row'>
                                                    <div className="col-md-5 text-start">
                                                        <div className="notes">
                                                            <textarea
                                                                className="h-auto"
                                                                placeholder="Notes"
                                                                id="floatingTextarea"
                                                                rows="10"
                                                                value={notes}
                                                                onChange={(e) => setNotes(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 text-start">
                                                        <div className="Customertotal">
                                                            <div className="totalDetails">
                                                                <h5 className="personalinfoHead">Sub Total</h5>
                                                                <h5 className="itemsTxt">${data?.total_sale_price}</h5>
                                                            </div>
                                                            <div className="totalDetails">
                                                                <h5 className="personalinfoHead">Discount ({data?.coupon_code ? data?.coupon_code : "No Coupon Applied"})</h5>
                                                                <h5 className="itemsTxt Orangetext">${data?.discount}</h5>
                                                            </div>
                                                            <div className="totalDetails">
                                                                <h5 className="personalinfoHead">Shipping Charge</h5>
                                                                <h5 className="itemsTxt">${data?.shipping_charge}</h5>
                                                            </div>
                                                            <div className="totalDetails">
                                                                <h5 className="itemsText ">Total</h5>
                                                                <h5 className="itemsTxt">${data?.payable_amount}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='invoiceBottombtn mt-5'>
                                            <button className='declineBtn me-3' onClick={() => history.push('/receivedPurchase')}>Discard</button>
                                            <button className='acceptBtn_' data-bs-toggle="dropdown" aria-expanded="false" onClick={() => handleReportIssue()}>
                                                {loadingButton && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                &nbsp;&nbsp;
                                                <span> Report Issues</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                <CustomModal
                    key={key}
                    show={modalDetail.show}
                    // backdrop="static"
                    showCloseBtn={false}
                    isRightSideModal={false}
                    mediumWidth={false}
                    className={modalDetail.flag === "ReportModal" ? "commonWidth customContent" : ""}
                    ids={modalDetail.flag === "ReportModal" ? "reportModal" : ""}

                    child={

                        modalDetail.flag === "ReportModal" ? (
                            <ReportModal close={() => handleOnCloseModal()} details={() => handleModalDetails()} />
                        ) :

                            (
                                ""
                            )
                    }

                />
            </div>
        </div>
    )
}

export default ReceivedreportInvoice