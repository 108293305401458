import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
// import { exportImg, employeProfile } from '../../../../utilities/images'
const AddUserModal = () => {
const history = useHistory()
  return (
    <div className='addEmployeeModal'>
    <form className='emplyoeeDetails horizontalForms'>
       <div className='row'>
          <div className='form-group col-md-12 mb-4'>
              <label className='labelTxt mb-3' htmlFor='amount'>Roll</label>
              <select className='customform-control select fw-normal' placeholder='Wallet Admin'>
                  <option>Wallet Admin</option>
                  <option>Department2</option>
                  <option>Department3</option>
              </select>
          </div>
          <div className='form-group col-md-12 mb-4'>
              <label className='labelTxt mb-3' htmlFor='amount'>Employee  id</label>
              <select className='customform-control select fw-normal' placeholder='Type id'>
                  <option>Wallet Admin</option>
                  <option>Department2</option>
                  <option>Department3</option>
              </select>
          </div>
          <div className='form-group col-md-12  mb-4'>
                <label className='labelTxt mb-3' htmlFor='amount'>User name</label>
                <input type='text' className='customform-control' placeholder='User name' value='' />
            </div>
           
            <div className='form-group col-md-12 mb-4'>
                <label className='labelTxt mb-3' htmlFor='amount'>Email address</label>
                <input type='email' className='customform-control' placeholder='Email address' value='' />
            </div>
            <div className='form-group col-md-6 mb-4'>
                <label className='labelTxt mb-3' htmlFor='amount'>Phone number</label>
                <input type='number' className='customform-control' placeholder='+1' value='' />
            </div>
            <div className='form-group col-md-6 mb-4'>
                <label className='labelTxt mb-3' htmlFor='amount'>Password</label>
                <input type='password' className='customform-control' placeholder='Password' value='dd / mm/ yyyy' />
            </div>
            <div className='footerSec mt-4'>
                <button to='' className='secoundaryBtn me-4'>Cancel</button>
                <button to='' className='submitBtn'  onClick={() => history.push('/systemConfig/systemEmployeeInvoice')}>Invite user</button>
            </div>
       </div>
    </form>
    </div>
  )
}

export default AddUserModal