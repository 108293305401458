import React, { useState, useEffect, useRef } from 'react';
import { calendarsh2, chatImage_, deleteIcon, dotsinbox, profileImg, receiver, userImage, profileImg1, videouserImg, userImg_, building_, cardemail_, cardphone_, Viewimage, downloadimage, docupload, pinlocate } from '../../../../utilities/images'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../auth/authSlice';
import { getMessageListAsync, deleteChatAsync, getMessageheadsAsync, deleteSingleMessageApiAsync } from '../messageSlice';
import { unwrapResult } from "@reduxjs/toolkit";
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment-timezone';


const ChatComponent = (props) => {
  const [messageList, setMessageList] = useState([])
  const [userLocation, setUserLocation] = useState();
  const [userProfile, setUserProfile] = useState();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth)
  const [userData, setUserData] = useState(false);
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ block: "end", inline: "nearest" })
  }
  const groupedChatData = props?.messageData?.reduce((result, message) => {
    // console.log(message, 'xxxxxxxxxxxxxxxxxxxxx', result);
    const date = message?.createdAt?.split('T')[0]; // Extract date part from timestamp
    if (!result[date]) {
      result[date] = [];
    }
    result[date].push(message);
    return result;
  }, {})
  // console.log(groupedChatData, 'groupedChatData');


  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  let formattedYesterday = moment(yesterday).format('YYYY-MM-DD')


  // const deleteChatData = () => {
  // }

  // const deleteChatDatasss = () => {
  //   let params = {
  //     id: props?.id,
  //     tokenData: auth?.payload?.token
  //   }
  //   setLoading(true)
  //   dispatch(deleteChatAsync(params))
  //     .then(unwrapResult)
  //     .then(async (obj) => {
  //       setLoading(false)
  //       setMessageList(obj?.payload?.messages);
  //       let params = {
  //         postData: {
  //           page: 1,
  //           limit: 10,
  //           headtype: "directchat"
  //         },
  //         tokenData: auth?.payload?.token
  //       }
  //       dispatch(getMessageheadsAsync(params))
  //         .then(unwrapResult)
  //         .then(async (obj) => {
  //           setLoading(false);
  //           setUserData(false)
  //           props.data(obj?.payload?.data);
  //           props.setChatHeadData("")
  //           props.setMessageData([])
  //           props.setMessageButton(false)
  //         }
  //         )
  //         .catch((obj) => {
  //           setLoading(false)
  //         })
  //     }
  //     )
  //     .catch((obj) => {
  //       setLoading(false)
  //     })
  // }

  const onButtonClick = (item) => {
    // console.log(item, "itemmmm");
    fetch(item.filePath).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = item.originalname;
        alink.click();
      })
    })
  }

  useEffect(() => {
    scrollToBottom()
  }, [props?.messageData])

  return (
    <div className='rightMessagebox_'>
      {props?.chatHeadData ?
        <div className='messageTophead'>
          <img className='senduserImage' src={props?.chatHeadData ? props?.chatHeadData?.banner_image : profileImg1} />
          <div className='userInformation'>
            <h5 className='upload-cnt mb-0'>{props?.chatHeadData?.organization_name}</h5>
            <h6 className='commonsmallgeryHead_ mb-0'>{props?.chatHeadData?.address ? props?.chatHeadData?.address[0]?.custom_address : ""}</h6>
          </div>
          <div className='dropdown '>
            <Link to="#" id="dropdownMenuButton1" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
              <img className='dotIcon_' src={dotsinbox} />
            </Link>
            <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
              <li><Link to="#" className="dropdown-item chatdropItem " >
                <img className='dropIcon' src={profileImg} />
                <span className='subtextSmall_'>Go to Profile</span></Link></li>
              <li><Link to="#" className="dropdown-item chatdropItem" >
                <img className='dropIcon' src={deleteIcon} />
                <span className='subtextSmall_' onClick={() => props.deleteChatHead(props?.id)}>Delete chat</span></Link>
              </li>
            </ul>
          </div>
        </div>
        : ""}
      {props?.loadingState || loading === true ?
        <>
          {/* <span className="spinner-border inner-spin spinner-border-sm"></span> */}
          <div className="pageLoader d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
        :
        <>
          <div className='message_body' >
            {Object.keys(groupedChatData)?.length > 0 ? <>
              {Object.keys(groupedChatData)?.map((date, index) => (
                <div key={index}>
                  <div className='chatDatebox_'>
                    <h5 className='chatdeateHeading'>{date === moment(today).format('YYYY-MM-DD') ? "Today | " + moment(date).format('MMMM DD, YYYY') : date === moment(formattedYesterday).format('YYYY-MM-DD') ? "Yesterday | " + moment(date).format('MMMM DD, YYYY') : moment(date).format('dddd | MMMM DD, YYYY')}</h5>
                  </div>
                  {groupedChatData[date]?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {
                          item?.sender_id === auth?.payload?.uniqe_id || item?.sender_id === auth?.payload?.unique_uuid ?
                            <>
                              {item.media_type == "text" ?
                                <div className='rightMessage mt-3 mb-2 mrr' ref={messagesEndRef}  >
                                  <div className="rightmessage-box" >
                                    <div className='rightboxmsg_'>
                                      <p className='textinner_'>{item?.content}</p>
                                    </div>
                                    <div className='dropdown '>
                                      <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                        <img className='senduserImage' src={auth?.payload?.user?.user_profiles?.profile_photo ? auth?.payload?.user?.user_profiles?.profile_photo : profileImg1} />
                                      </Link>
                                      <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                        <li><Link to="#" className="dropdown-item chatdropItem" >
                                          <img className='dropIcon' src={deleteIcon} />
                                          <span className='subtextSmall_' onClick={() => props.deleteSingleMessage(item?._id, props?.id)}>Remove</span></Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                </div>
                                :
                                item?.media_type == "image" ?
                                  <div className='rightMessage mt-3 mb-2' ref={messagesEndRef}  >
                                    <div className="rightmessage-box" >
                                      <div className='rightboxmsg_'>
                                        {item?.media_url.map((image, index) => {
                                          return (
                                            <img className='userimageSend_ ' key={index} src={image?.filePath} />
                                          )
                                        })}
                                      </div>
                                      <div className='dropdown '>
                                        <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                          <img className='senduserImage' src={auth?.payload?.user?.user_profiles?.profile_photo ? auth?.payload?.user?.user_profiles?.profile_photo : profileImg1} />
                                        </Link>
                                        <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                          <li><Link to="#" className="dropdown-item chatdropItem" >
                                            <img className='dropIcon' src={deleteIcon} />
                                            <span className='subtextSmall_' onClick={() => props.deleteSingleMessage(item?._id, props?.id)}>Remove</span></Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                  </div>
                                  :
                                  item?.media_type == "business_card" ?
                                    <div className='rightMessage mt-3 mb-2' ref={messagesEndRef}  >
                                      <div className="rightmessage-box" >
                                        <div className='rightboxmsg_ rightanotherBg'>
                                          <h5 className='smallcardHead_'>Business Card</h5>
                                          <div className="userProfileinfo_ anotherprofileBg">
                                            <img className="marchantPro" src={userImg_} alt="profileimg" />
                                            <div className="userProfileinforight_">
                                              <h3 className='userhaeding_'>{item?.business_card?.firstName + " " + item?.business_card?.lastName}</h3>
                                              <ul className='contentextLightMenus mt-2'>
                                                <li className='contentextLightItems'>
                                                  <img className='cradInfo_' src={building_} />
                                                  <span className='ms-2'>{item?.business_card?.company_name}</span>
                                                </li>
                                                <li className='contentextLightItems'>
                                                  <img className='cradInfo_' src={cardemail_} />

                                                  <span className='ms-2'>{item?.business_card?.email}</span>
                                                </li>
                                                <li className='contentextLightItems'>
                                                  <img className='cradInfo_' src={cardphone_} />
                                                  <span className='ms-2'>{item?.business_card?.mobile}</span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='dropdown '>
                                          <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                            <img className='senduserImage' src={auth?.payload?.user?.user_profiles?.profile_photo ? auth?.payload?.user?.user_profiles?.profile_photo : profileImg1} />
                                          </Link>
                                          <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                            <li><Link to="#" className="dropdown-item chatdropItem" >
                                              <img className='dropIcon' src={deleteIcon} />
                                              <span className='subtextSmall_' onClick={() => props.deleteSingleMessage(item?._id, props?.id)}>Remove</span></Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                    </div>
                                    :
                                    item?.media_type == "doc" ?
                                      <div className='rightMessage mt-3 mb-2' ref={messagesEndRef}  >
                                        <div className="rightmessage-box" >
                                          <div className='rightboxmsg_'>
                                            {item?.media_url?.map((file, index) => {
                                              return (
                                                <div className='uploadDocument mb-3' key={index}>
                                                  <div className='uploadContent'>
                                                    <div className='docFileupload'>
                                                      <img src={file?.filePath?.split('.')[3] === "pdf" ? docupload : file?.split('.')[3] == "text/plain" ? "https://cdn-icons-png.flaticon.com/512/3979/3979306.png" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqSwpiH1Qm_cPwkq3YZaqzosujuIVodIrirMcMY1fSQg&usqp=CAU&ec=48665701"} />
                                                      <span>{file?.originalname} </span>
                                                    </div>
                                                  </div>
                                                  <div className='uploadContentbtn'>
                                                    <a href={file?.filePath} target="_blank" className='viewBtn'><img className="actionsbtn" src={Viewimage} /></a>
                                                    <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimage} onClick={() => onButtonClick(file)} /></Link>
                                                  </div>
                                                </div>
                                              )
                                            })}
                                          </div>
                                          <div className='dropdown '>
                                            <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                              <img className='senduserImage' src={auth?.payload?.user?.user_profiles?.profile_photo ? auth?.payload?.user?.user_profiles?.profile_photo : profileImg1} />
                                            </Link>
                                            <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                              <li><Link to="#" className="dropdown-item chatdropItem" >
                                                <img className='dropIcon' src={deleteIcon} />
                                                <span className='subtextSmall_' onClick={() => props.deleteSingleMessage(item?._id, props?.id)}>Remove</span></Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                      </div>
                                      :
                                      item?.media_type == "audio" ?
                                        <div className='rightMessage mt-3 mb-2' ref={messagesEndRef}  >
                                          <div className="rightmessage-box" >
                                            {item?.media_url?.map((audio, index) => {
                                              return (
                                                <div key={index}>
                                                  <ReactAudioPlayer
                                                    src={audio?.filePath}
                                                    autoPlay={false}
                                                    controls={true}
                                                  />
                                                </div>
                                              )
                                            })}
                                            <div className='dropdown '>
                                              <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                                <img className='senduserImage' src={auth?.payload?.user?.user_profiles?.profile_photo ? auth?.payload?.user?.user_profiles?.profile_photo : profileImg1} />
                                              </Link>
                                              <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                                <li><Link to="#" className="dropdown-item chatdropItem" >
                                                  <img className='dropIcon' src={deleteIcon} />
                                                  <span className='subtextSmall_' onClick={() => props.deleteSingleMessage(item?._id, props?.id)}>Remove</span></Link>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                        </div>
                                        :
                                        item?.media_type == "shipping_address" ?
                                          <div className='rightMessage mt-3 mb-2' ref={messagesEndRef}  >
                                            <div className="rightmessage-box" >
                                              <div className='rightboxmsg_ rightanotherBg'>
                                                <h5 className='smallcardHead_'>Shipping Address</h5>
                                                <div className="userProfileinfo_ anotherprofileBg">
                                                  <img className="marchantPro" src={userImg_} alt="profileimg" />
                                                  <div className="userProfileinforight_">
                                                    <h3 className='userhaeding_'>{item?.shipping_address?.firstName + " " + item?.shipping_address?.lastName}</h3>
                                                    <ul className='contentextLightMenus mt-2'>
                                                      <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={building_} />
                                                        <span className='ms-2'>{item?.shipping_address?.company_name}</span>
                                                      </li>
                                                      <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={pinlocate} />
                                                        <span className='ms-2'>{item?.shipping_address?.full_address}</span>
                                                      </li>
                                                      <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={cardemail_} />
                                                        <span className='ms-2'>{item?.shipping_address?.email}</span>
                                                      </li>
                                                      <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={cardphone_} />
                                                        <span className='ms-2'>{item?.shipping_address?.mobile}</span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='dropdown '>
                                                <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                                  <img className='senduserImage' src={auth?.payload?.user?.user_profiles?.profile_photo ? auth?.payload?.user?.user_profiles?.profile_photo : profileImg1} />
                                                </Link>
                                                <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                                  <li><Link to="#" className="dropdown-item chatdropItem" >
                                                    <img className='dropIcon' src={deleteIcon} />
                                                    <span className='subtextSmall_' onClick={() => props.deleteSingleMessage(item?._id, props?.id)}>Remove</span></Link>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                            <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                          </div>
                                          : item?.media_type == "offer" ?
                                            <div className='rightMessage mt-3 mb-2' ref={messagesEndRef} >
                                              <div className="rightmessage-box">
                                                <div className='rightboxmsg_ rightanotherBg'>
                                                  <h5 className='smallcardHead_'>Instant offer for you</h5>
                                                  <div className='instantOfferBox'>
                                                    <img className='tubeImage' src={item?.offerData?.products?.image} />
                                                    <div className='instantContent'>
                                                      <h5 className='subtextDark_'>{item?.offerData?.products?.name}</h5>
                                                      <p className='offerpara_ mt-2'>{item?.offerData?.products?.description}</p>
                                                      <h5 className='hightlightSmall mt-3 mb-0'>USD {item?.offerData?.offer_price_per_carton}</h5>
                                                      <p className='offersmall_'>{item?.offerData?.min_order_per_carton} Pairs (Min. Order)</p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className='dropdown chatditBtn'>
                                                  <Link to="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                                    <img className='senduserImage' src={auth?.payload?.user?.user_profiles?.profile_photo ? auth?.payload?.user?.user_profiles?.profile_photo : profileImg1} />
                                                  </Link>
                                                  <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                                    <li><Link to="#" className="dropdown-item chatdropItem" >
                                                      <img className='dropIcon' src={deleteIcon} />
                                                      <span className='subtextSmall_' onClick={() => props.deleteSingleMessage(item?._id, props?.id)}>Remove</span></Link>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                            </div>
                                            :
                                            <></>}
                            </>
                            :
                            <>
                              {item?.media_type == "text" ?
                                <div className='leftMessage mt-3 mb-2' ref={messagesEndRef}  >
                                  <div className="leftmessage-box" >
                                    <img className='senduserImage' src={props?.chatHeadData?.banner_image ? props?.chatHeadData?.banner_image : profileImg1} />
                                    <p className='textinner_'>{item.content}</p>
                                  </div>
                                  <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                </div>
                                :
                                item?.media_type == "image" ?
                                  <div className='leftMessage mt-3 mb-2' ref={messagesEndRef}  >
                                    <div className="leftmessage-box" >
                                      <img className='senduserImage' src={props?.chatHeadData?.banner_image ? props?.chatHeadData?.banner_image : profileImg1} />
                                      <div className='rightboxmsg_' >
                                        {item?.media_url.map((image, index) => {
                                          return (
                                            <img className='userimageSend_ ' key={index} src={image?.filePath} />
                                          )
                                        })}
                                      </div>
                                    </div>
                                    <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                  </div>
                                  :
                                  item?.media_type == "business_card" ?
                                    <div className='leftMessage mt-3 mb-2' ref={messagesEndRef}  >
                                      <div className="leftmessage-box" >
                                        <img className='senduserImage' src={props?.chatHeadData?.banner_image ? props?.chatHeadData?.banner_image : profileImg1} />
                                        <div className='rightboxmsg_ rightanotherBg'>
                                          <h5 className='smallcardHead_'>Business Card</h5>
                                          <div className="userProfileinfo_ anotherprofileBg">
                                            <img className="marchantPro" src={userImg_} alt="profileimg" />
                                            <div className="userProfileinforight_">
                                              <h3 className='userhaeding_'>{item?.business_card?.firstName + " " + item?.business_card?.lastName}</h3>
                                              <ul className='contentextLightMenus mt-2'>
                                                <li className='contentextLightItems'>
                                                  <img className='cradInfo_' src={building_} />
                                                  <span className='ms-2'>{item?.business_card?.company_name}</span>
                                                </li>
                                                <li className='contentextLightItems'>
                                                  <img className='cradInfo_' src={cardemail_} />
                                                  <span className='ms-2'>{item?.business_card?.email}</span>
                                                </li>
                                                <li className='contentextLightItems'>
                                                  <img className='cradInfo_' src={cardphone_} />
                                                  <span className='ms-2'>{item?.business_card?.mobile}</span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                    </div>
                                    :
                                    item?.media_type == "doc" ?
                                      <div className='leftMessage mt-3 mb-2' ref={messagesEndRef}  >
                                        <div className="leftmessage-box" >
                                          <img className='senduserImage' src={props?.chatHeadData?.banner_image ? props?.chatHeadData?.banner_image : profileImg1} />
                                          <div className='rightboxmsg_'>
                                            {item?.media_url?.map((file, index) => {
                                              return (
                                                <div className='uploadDocument mb-3' key={index}>
                                                  <div className='uploadContent'>
                                                    <div className='docFileupload'>
                                                      <img src={file?.filePath?.split('.')[3] === "pdf" ? docupload : file?.split('.')[3] == "text/plain" ? "https://cdn-icons-png.flaticon.com/512/3979/3979306.png" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqSwpiH1Qm_cPwkq3YZaqzosujuIVodIrirMcMY1fSQg&usqp=CAU&ec=48665701"} />
                                                      <span> {file?.originalname}</span>
                                                    </div>
                                                  </div>
                                                  <div className='uploadContentbtn'>
                                                    <a href={file?.filePath} target="_blank" className='viewBtn'><img className="actionsbtn" src={Viewimage} /></a>
                                                    <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimage} onClick={() => onButtonClick(file)} /></Link>
                                                  </div>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>
                                        <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                      </div>
                                      :
                                      item?.media_type == "audio" ?
                                        <div className='leftMessage mt-3 mb-2' ref={messagesEndRef}  >
                                          <div className="leftmessage-box" >
                                            <img className='senduserImage' src={props?.chatHeadData?.banner_image ? props?.chatHeadData?.banner_image : profileImg1} />
                                            {item?.media_url?.map((audio, index) => {
                                              return (
                                                <div key={index}>
                                                  <ReactAudioPlayer
                                                    src={audio?.filePath}
                                                    autoPlay={false}
                                                    controls
                                                  />
                                                </div>
                                              )
                                            })}
                                          </div>
                                          <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                        </div>
                                        :
                                        item?.media_type == "shipping_address" ?
                                          <div className='leftMessage mt-3 mb-2' ref={messagesEndRef}>
                                            <div className="leftmessage-box" >
                                              <img className='senduserImage' src={props?.chatHeadData?.banner_image ? props?.chatHeadData?.banner_image : profileImg1} />
                                              <div className='rightboxmsg_ rightanotherBg'>
                                                <h5 className='smallcardHead_'>Shipping Address</h5>
                                                <div className="userProfileinfo_ anotherprofileBg">
                                                  <img className="marchantPro" src={userImg_} alt="profileimg" />
                                                  <div className="userProfileinforight_">
                                                    <h3 className='userhaeding_'>{item?.shipping_address?.firstName + " " + item?.shipping_address?.lastName}</h3>
                                                    <ul className='contentextLightMenus mt-2'>
                                                      <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={building_} />
                                                        <span className='ms-2'>{item?.shipping_address?.company_name}</span>
                                                      </li>
                                                      <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={pinlocate} />
                                                        <span className='ms-2'>{item?.shipping_address?.full_address}</span>
                                                      </li>
                                                      <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={cardemail_} />
                                                        <span className='ms-2'>{item?.shipping_address?.email}</span>
                                                      </li>
                                                      <li className='contentextLightItems'>
                                                        <img className='cradInfo_' src={cardphone_} />
                                                        <span className='ms-2'>{item?.shipping_address?.mobile}</span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                          </div>
                                          :
                                          item?.media_type == "offer" ?
                                            <div className='leftMessage mt-3 mb-2' ref={messagesEndRef}  >
                                              <div className="leftmessage-box" >
                                                <img className='senduserImage' src={props?.chatHeadData?.banner_image ? props?.chatHeadData?.banner_image : profileImg1} />
                                                <div className='rightboxmsg_ rightanotherBg'>
                                                  <h5 className='smallcardHead_'>Instant offer for you</h5>
                                                  <div className='instantOfferBox'>

                                                    <img className='tubeImage' src={item?.offerData?.products?.image} />
                                                    <div className='instantContent'>
                                                      <h5 className='subtextDark_'>{item?.offerData?.products?.name}</h5>
                                                      <p className='offerpara_ mt-2'>{item?.offerData?.products?.description}</p>
                                                      <h5 className='hightlightSmall mt-3 mb-0'>USD {item?.offerData?.offer_price_per_carton}</h5>
                                                      <p className='offersmall_'>{item?.offerData?.min_order_per_carton} Pairs (Min. Order)</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <span className='subtextSmallblack_'>{moment(item?.createdAt).format('hh:mm A')}</span>
                                            </div>
                                            :
                                            <></>
                              }
                            </>
                        }
                      </React.Fragment>
                    )
                  })}
                </div>
              ))}
            </>
              : ""}

            {/* <div className='rightMessage mt-3 mb-2'>
      <div className="rightmessage-box">
        <img className='userimageSend_ me-3' src={chatImage_} />
        <img className='senduserImage' src={auth?.payload?.user?.user_profiles?.profile_photo ? auth?.payload?.user?.user_profiles?.profile_photo : profileImg1} />
      </div>
      <span className='subtextSmallblack_'>12:30 pm</span>
    </div>
    <div className='rightMessage mt-3 mb-2'>
      <div className="rightmessage-box">
        <div className='videoCallwith'>
          <h5 className='semiboldHeading_'>Video call with</h5>
          <div className='videoUserdetail'>
            <img className='videoUser me-2' src={videouserImg} />
            <aside>
              <h6 className='subtextDark_ mb-1'>Senia PanFeng</h6>
              <p className='subtextSmall_ mb-1'>Date: Sep 5, 2022</p>
              <p className='subtextSmall_'>Time: 3:00p-3:15p</p>
            </aside>
          </div>
          <button className='secondaryblackrbtn_ mt-3'>
            <img className='calendershdule me-1' src={calendarsh2} /> <span>Schedule</span>
          </button>
          <p className='schdulepara_'>You can reschedule this meeting only once.</p>

        </div>
        <div className='leftMessage mt-3 mb-2'>
          <div className="leftmessage-box">
            <img className='senduserImage' src={userImage} />
            <div className='leftBoxmsg_'>
              <p className='textinner_'>Our gateway is routed directly to Telstra, with Optus and Vodafone networks serviced through various Australian aggregators</p>
            </div>
          </div>
          <span className='subtextSmallblack_'>12:30 pm</span>
        </div>
        <div className='leftMessage mt-3 mb-2'>
          <div className="leftmessage-box">
            <img className='senduserImage' src={userImage} />
            <div className='leftBoxmsg_'>
              <p className='textinner_'>Our gateway is routed directly to Telstra, with Optus and Vodafone networks serviced through various Australian aggregators</p>
            </div>
          </div>
          <span className='subtextSmallblack_'>12:30 pm</span>
        </div>
        <div className='rightMessage mt-3 mb-2'>
          <div className="rightmessage-box">
            <div className='rightboxmsg_'>
              <p className='textinner_'>Our gateway is routed directly to Telstra, with Optus and Vodafone networks serviced through various Australian aggregators</p>
            </div>
            <img className='senduserImage' src={receiver} />
          </div>
          <span className='subtextSmallblack_'>12:30 pm</span>
        </div>
        <div className='rightMessage mt-3 mb-2'>
          <div className="rightmessage-box">
            <div className='rightboxmsg_'>
              <img className='userimageSend_ ' src={chatImage_} />
            </div>
            <img className='senduserImage' src={receiver} />
          </div>
          <span className='subtextSmallblack_'>12:30 pm</span>
        </div>
        <div className='rightMessage mt-3 mb-2'>
          <div className="rightmessage-box">
            <div className='rightboxmsg_'>
              <div className='videoCallwith'>
                <h5 className='semiboldHeading_'>Video call with</h5>
                <div className='videoUserdetail'>
                  <img className='videoUser me-2' src={videouserImg} />
                  <aside>
                    <h6 className='subtextDark_ mb-1'>Senia PanFeng</h6>
                    <p className='subtextSmall_ mb-1'>Date: Sep 5, 2022</p>
                    <p className='subtextSmall_'>Time: 3:00p-3:15p</p>
                  </aside>
                </div>
                <button className='secondaryblackrbtn_ mt-3'>
                  <img className='calendershdule me-1' src={calendarsh2} /> <span>Schedule</span>
                </button>
                <p className='schdulepara_'>You can reschedule this meeting only once.</p>

              </div>
            </div>
            <img className='senduserImage' src={receiver} />
          </div>
          <span className='subtextSmallblack_'>12:30 pm</span>
        </div>

        <div className='rightMessage mt-3 mb-2'>
          <div className="rightmessage-box">
            <div className='rightboxmsg_'>
              <div className='uploadDocument mb-3'>
                <div className='uploadContent'>
                  <div className='docFileupload'>
                    <img src={docupload} />
                    <span>talon_driving_licence </span>
                  </div>
                </div>
                <div className='uploadContentbtn'>
                  <Link to="#" className='viewBtn'><img className="actionsbtn" src={Viewimage} /></Link>
                  <Link to="#" className='downloadBtn'><img className="actionsbtn" src={downloadimage} /></Link>
                </div>
              </div>
            </div>
            <img className='senduserImage' src={receiver} />
          </div>
          <span className='subtextSmallblack_'>12:30 pm</span>
        </div>
        <div className='rightMessage mt-3 mb-2'>
          <div className="rightmessage-box">
            <div className='rightboxmsg_ rightanotherBg'>
              <h5 className='smallcardHead_'>Instant offer for you</h5>
              <div className='instantOfferBox'>
                <img className='tubeImage' src={tubeImage} />
                <div className='instantContent'>
                  <h5 className='subtextDark_'>Marlboro Silver</h5>
                  <p className='offerpara_ mt-2'>Comfortable Athletic Women Sneaker Shoes,New Style Fashion Sport Shoes</p>
                  <h5 className='hightlightSmall mt-3 mb-0'>USD 5.60</h5>
                  <p className='offersmall_'>200 Pairs (Min. Order)</p>
                </div>
              </div>
            </div>
            <img className='senduserImage' src={receiver} />
          </div>
          <span className='subtextSmallblack_'>12:30 pm</span>
        </div>
        <div className='rightMessage mt-3 mb-2'>
          <div className="rightmessage-box">
            <div className='rightboxmsg_ rightanotherBg'>
              <h5 className='smallcardHead_'>Business Card</h5>
              <div className="userProfileinfo_ anotherprofileBg">
                <img className="marchantPro" src={userImg_} alt="profileimg" />
                <div className="userProfileinforight_">
                  <h3 className='userhaeding_'>Steven D. Daum</h3>
                  <ul className='contentextLightMenus mt-2'>
                    <li className='contentextLightItems'>
                      <img className='cradInfo_' src={building_} />
                      <span className='ms-2'>Company name</span>
                    </li>
                    <li className='contentextLightItems'>
                      <img className='cradInfo_' src={cardemail_} />

                      <span className='ms-2'>emailaddress@email.com</span>
                    </li>
                    <li className='contentextLightItems'>
                      <img className='cradInfo_' src={cardphone_} />
                      <span className='ms-2'>805-627-5874</span>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <img className='senduserImage' src={receiver} />
          </div>
          <span className='subtextSmallblack_'>12:30 pm</span>
        </div>
        <div className='rightMessage mt-3 mb-2'>
          <div className="rightmessage-box">
            <div className='rightboxmsg_ rightanotherBg'>
              <h5 className='smallcardHead_'>Shipping Address</h5>
              <div className="userProfileinfo_ anotherprofileBg">
                <div className="userProfileinforight_">
                  <h3 className='userhaeding_'>Steven D. Daum</h3>
                  <ul className='contentextLightMenus mt-2'>
                    <li className='contentextLightItems'>
                      <img className='cradInfo_' src={building_} />
                      <span className='ms-2'>Company name</span>
                    </li>
                    <li className='contentextLightItems'>
                      <img className='cradInfo_' src={pinlocate} />

                      <span className='ms-2'>1457 New Street Heppner, OR 97836</span>
                    </li>
                    <li className='contentextLightItems'>
                      <img className='cradInfo_' src={cardemail_} />

                      <span className='ms-2'>emailaddress@email.com</span>
                    </li>
                    <li className='contentextLightItems'>
                      <img className='cradInfo_' src={cardphone_} />
                      <span className='ms-2'>805-627-5874</span>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <img className='senduserImage' src={receiver} />
          </div>
          <span className='subtextSmallblack_'>12:30 pm</span>
        </div>
        {/* <!--Quick Message Content--> */}
            <div className='quickMessage_'>
              <button className="closeButton">
                <i className="las la-times"></i>
              </button>
              <div className='quickInnercontent'>
                <h5 className='labelTxt mt-3'>Hi, What’s the best price you can offer?</h5>
                <h5 className='labelTxt mt-3'>Hi, I’m interested on this product. I would like
                  some more detilas.</h5>
                <h5 className='labelTxt mt-3'>Hi, Would you send me a product sample
                  before I place an order?</h5>
                <h5 className='labelTxt mt-3'>What is your min. oder quantity?</h5>
              </div>
            </div>
            {/* <!--Quick Message Content--> */}
          </div>
          {/* <span className='subtextSmallblack_'>12:30 pm</span> */}
        </>}
    </div>
  )
}

export default ChatComponent