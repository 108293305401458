import React, { useEffect, useState } from 'react'
import { updateSellerInfoApiAsync } from '../../dashboardSlice'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../../../auth/authSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import TimePicker from 'react-time-picker'
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { dayList, options } from '../../../../../utilities/helpers'
import { Range, getTrackBackground } from "react-range";
import { rangeLocate } from '../../../../../utilities/images'


const DeliveryAddress = (props) => {
    let deliveryTypeData = props?.data?.delivery_type_detail;
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [expressTime, setExpressTime] = useState("10 min")
    const [oneHourTime, setOneHourTime] = useState("10 min")
    const [twoHourTime, setTwoHourTime] = useState("10 min")
    const [businessHours, setBusinessHours] = useState([])
    const [startMondayTime, setStartMondayTime] = useState("");
    const [endMondayTime, setEndMondayTime] = useState('');
    const [startTuesddayTime, setStartTuesdayTime] = useState("");
    const [endTuesdayTime, setEndTuesdayTime] = useState('');
    const [startWednesdayTime, setStartWednesdayTime] = useState("");
    const [endWednesdayTime, setEndWednesdayTime] = useState('');
    const [startThursdayTime, setStartThursdayTime] = useState("");
    const [endThursdayTime, setEndThursdayTime] = useState('');
    const [startFridayTime, setStartFridayTime] = useState("");
    const [endFridayTime, setEndFridayTime] = useState('');
    const [startSaturdayTime, setStartSaturdayTime] = useState("");
    const [endSaturdayTime, setEndSaturdayTime] = useState('');
    const [startSundayTime, setStartSundayTime] = useState("");
    const [endSundayTime, setEndSundayTime] = useState('');
    const toastId = React.useRef(null)
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const [pickupInstruction, setPickupInstruction] = useState("")
    const [selectedOptions, setSelectedOptions] = useState([]);
    let selectedOptionNew = []
    const STEP = 2;
    const MIN = 1;
    const MAX = 15;
    const [values, setValues] = useState([5]);


    const createScale = () => {
        let scale = [];

        for (let i = MIN; i <= MAX; i = i + STEP) {
            scale.push(
                <span key={i} className="scale_item">
                    {i}
                </span>
            );
        }

        return scale;
    };

    const handleCheckboxChange1 = (option, flag) => {
        if (selectedOptions.find(val => val?.id == option?.id)) {
            selectedOptionNew = selectedOptions.filter((item) => item?.id !== option?.id)
            setSelectedOptions(selectedOptionNew);
        } else {
            selectedOptionNew = [...selectedOptions, option]
            setSelectedOptions([...selectedOptions, option]);
        }

        let startTime = (flag == 1) ? startMondayTime :
            (flag == 2) ? startTuesddayTime :
                (flag == 3) ? startWednesdayTime :
                    (flag == 4) ? startThursdayTime :
                        (flag == 5) ? startFridayTime :
                            (flag == 6) ? startSaturdayTime :
                                startSundayTime

        let endTime = (flag == 1) ? endMondayTime :
            (flag == 2) ? endTuesdayTime :
                (flag == 3) ? endWednesdayTime :
                    (flag == 4) ? endThursdayTime :
                        (flag == 5) ? endFridayTime :
                            (flag == 6) ? endSaturdayTime :
                                endSundayTime

        if (selectedOptionNew.find((day) => day.id == 1)) {
            setStartMondayTime(startTime);
            setEndMondayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 2)) {
            setStartTuesdayTime(startTime);
            setEndTuesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 3)) {
            setStartWednesdayTime(startTime);
            setEndWednesdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 4)) {
            setStartThursdayTime(startTime);
            setEndThursdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 5)) {
            setStartFridayTime(startTime);
            setEndFridayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 6)) {
            setStartSaturdayTime(startTime);
            setEndSaturdayTime(endTime)
        }
        if (selectedOptionNew.find((day) => day.id == 7)) {
            setStartSundayTime(startTime);
            setEndSundayTime(endTime)
        }
    };
    const handleCopy = (flag) => {
        setSelectedOptions([])
    }

    function convertTo24HourFormat(time12Hour) {
        // Split the input time string into hours and minutes
        const [time, period] = time12Hour.split(' ');
        const [hours, minutes] = time.split(':');

        // Convert hours to an integer
        let hours24Hour = parseInt(hours, 10);

        // Adjust hours based on AM or PM
        if (period === 'PM' && hours24Hour !== 12) {
            hours24Hour += 12;
        } else if (period === 'AM' && hours24Hour === 12) {
            hours24Hour = 0;
        }

        // Format hours and minutes with leading zeros
        const formattedHours = hours24Hour.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');

        // Combine the hours and minutes into the 24-hour format
        const time24Hour = `${formattedHours}:${formattedMinutes}`;

        return time24Hour;
    }

    const handleStartTimeChange = (newStartTime, flag) => {
        if (flag == "monday") {
            setStartMondayTime(newStartTime)
        }
        if (flag == "tuesday") {
            setStartTuesdayTime(newStartTime)
        }
        if (flag == "wednesday") {
            setStartWednesdayTime(newStartTime)
        }
        if (flag == "thursday") {
            setStartThursdayTime(newStartTime)
        }
        if (flag == "friday") {
            setStartFridayTime(newStartTime)
        }
        if (flag == "saturday") {
            setStartSaturdayTime(newStartTime)
        }
        if (flag == "sunday") {
            setStartSundayTime(newStartTime)
        }
    };

    const handleEndTimeChange = (newEndTime, flag) => {
        if (flag == "monday") {
            setEndMondayTime(newEndTime)
        }
        if (flag == "tuesday") {
            setEndTuesdayTime(newEndTime)
        }
        if (flag == "wednesday") {
            setEndWednesdayTime(newEndTime)
        }
        if (flag == "thursday") {
            setEndThursdayTime(newEndTime)
        }
        if (flag == "friday") {
            setEndFridayTime(newEndTime)
        }
        if (flag == "saturday") {
            setEndSaturdayTime(newEndTime)
        }
        if (flag == "sunday") {
            setEndSundayTime(newEndTime)
        }
    };

    const handleCheckboxChange = (event) => {
        var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
        if (event.target.checked) {
            updatedList = businessHours?.length > 0 ? [...businessHours, event.target.value] : [event.target.value];
        } else {
            updatedList.splice(businessHours?.indexOf(event.target.value), 1);
        }
        setBusinessHours(updatedList);
    }

    const handleChange = (e, flag) => {
        if (flag === "express") {
            setExpressTime(e.target.value)
        }
        else if (flag === "1hr") {
            setOneHourTime(e.target.value)
        }
        else {
            setTwoHourTime(e.target.value)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (pickupInstruction === "") {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter pickup instruction");
            }
            return false
        }
        // if (businessHours && businessHours?.length <= 0) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Please select business hours");
        //     }
        //     return false
        // }
        // if ((businessHours?.includes("monday") && !startMondayTime) ||
        //     (businessHours?.includes("tuesday") && !startTuesddayTime) ||
        //     (businessHours?.includes("wednesday") && !startWednesdayTime) ||
        //     (businessHours?.includes("thursday") && !startThursdayTime) ||
        //     (businessHours?.includes("friday") && !startFridayTime) ||
        //     (businessHours?.includes("saturday") && !startSaturdayTime) ||
        //     (businessHours?.includes("sunday") && !startSundayTime)
        // ) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Please select correct start time");
        //     }
        //     return false
        // }
        // if ((businessHours?.includes("monday") && !endMondayTime) ||
        //     (businessHours?.includes("tuesday") && !endTuesdayTime) ||
        //     (businessHours?.includes("wednesday") && !endWednesdayTime) ||
        //     (businessHours?.includes("thursday") && !endThursdayTime) ||
        //     (businessHours?.includes("friday") && !endFridayTime) ||
        //     (businessHours?.includes("saturday") && !endSaturdayTime) ||
        //     (businessHours?.includes("sunday") && !endSundayTime)
        // ) {
        //     if (!toast.isActive(toastId.current)) {
        //         toastId.current = toast.error("Please select correct end time");
        //     }
        //     return false
        // }
        setLoading(true)
        let postData = {
            "id": props.addressId,
            "miles": values[0],
            "product_availability": {
                // "availability": [
                //     {
                //         "day": "1",
                //         "opening_time": businessHours?.includes("monday") ? moment(startMondayTime, 'h:mm a').format('h:mm a') : "",
                //         "closing_time": businessHours?.includes("monday") ? moment(endMondayTime, 'h:mm a').format('h:mm a') : ""
                //     },
                //     {
                //         "day": "2",
                //         "opening_time": businessHours?.includes("tuesday") ? moment(startTuesddayTime, 'h:mm a').format('h:mm a') : "",
                //         "closing_time": businessHours?.includes("tuesday") ? moment(endTuesdayTime, 'h:mm a').format('h:mm a') : ""
                //     },
                //     {
                //         "day": "3",
                //         "opening_time": businessHours?.includes("wednesday") ? moment(startWednesdayTime, 'h:mm a').format('h:mm a') : "",
                //         "closing_time": businessHours?.includes("wednesday") ? moment(endWednesdayTime, 'h:mm a').format('h:mm a') : ""
                //     },
                //     {
                //         "day": "4",
                //         "opening_time": businessHours?.includes("thursday") ? moment(startThursdayTime, 'h:mm a').format('h:mm a') : "",
                //         "closing_time": businessHours?.includes("thursday") ? moment(endThursdayTime, 'h:mm a').format('h:mm a') : ""
                //     },
                //     {
                //         "day": "5",
                //         "opening_time": businessHours?.includes("friday") ? moment(startFridayTime, 'h:mm a').format('h:mm a') : "",
                //         "closing_time": businessHours?.includes("friday") ? moment(endFridayTime, 'h:mm a').format('h:mm a') : ""
                //     },
                //     {
                //         "day": "6",
                //         "opening_time": businessHours?.includes("saturday") ? moment(startSaturdayTime, 'h:mm a').format('h:mm a') : "",
                //         "closing_time": businessHours?.includes("saturday") ? moment(endSaturdayTime, 'h:mm a').format('h:mm a') : ""
                //     },
                //     {
                //         "day": "7",
                //         "opening_time": businessHours?.includes("sunday") ? moment(startSundayTime, 'h:mm a').format('h:mm a') : "",
                //         "closing_time": businessHours?.includes("sunday") ? moment(endSundayTime, 'h:mm a').format('h:mm a') : ""
                //     }
                // ],
                "express_delivery": expressTime,
                "one_hour_window": oneHourTime,
                "two_hour_window": twoHourTime
            },
        }

        if (pickupInstruction) {
            postData = {
                ...postData,
                "product_availability": {
                    ...postData.product_availability,
                    "delivery_instructions": pickupInstruction
                }
            }
        }
        // if (dropOffInstruction) {
        //     addressData = {
        //         postData: {
        //             ...addressData.postData,
        //             "product_availability": {
        //                 ...addressData.postData.product_availability,
        //                 "drop_off_instructions": dropOffInstruction
        //             }
        //         },
        //         tokenData: auth?.payload?.token
        //     }
        // }
        let addressData = {
            postData: postData,
            tokenData: auth?.payload?.token
        }
        dispatch(updateSellerInfoApiAsync(addressData))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                props?.close()
                props?.getAddressMerchant()
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const filterData = () => {
        if (props?.addressDataModal?.jobr_delivery_availability?.availability?.length > 0) {
            const businessDays = [
                // props?.addressDataModal?.jobr_delivery_availability?.availability[0]?.closing_time != "" && 'monday',
                // props?.addressDataModal?.jobr_delivery_availability?.availability[1]?.closing_time != "" && 'tuesday',
                // props?.addressDataModal?.jobr_delivery_availability?.availability[2]?.closing_time != "" && 'wednesday',
                // props?.addressDataModal?.jobr_delivery_availability?.availability[3]?.closing_time != "" && 'thursday',
                // props?.addressDataModal?.jobr_delivery_availability?.availability[4]?.closing_time != "" && 'friday',
                // props?.addressDataModal?.jobr_delivery_availability?.availability[5]?.closing_time != "" && 'saturday',
                // props?.addressDataModal?.jobr_delivery_availability?.availability[6]?.closing_time != "" && 'sunday'
            ];
            // setStartMondayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[0]?.opening_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[0]?.opening_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[0]?.opening_time)
            // setEndMondayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[0]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[0]?.closing_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[0]?.closing_time)
            // setStartTuesdayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[1]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[1]?.opening_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[1]?.closing_time)
            // setEndTuesdayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[1]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[1]?.closing_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[1]?.closing_time)
            // setStartWednesdayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[2]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[2]?.opening_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[2]?.closing_time)
            // setEndWednesdayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[2]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[2]?.closing_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[2]?.closing_time)
            // setStartThursdayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[3]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[3]?.opening_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[3]?.closing_time)
            // setEndThursdayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[4]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[3]?.closing_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[3]?.closing_time)
            // setStartFridayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[4]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[4]?.opening_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[4]?.closing_time)
            // setEndFridayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[4]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[4]?.closing_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[4]?.closing_time)
            // setStartSaturdayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[5]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[5]?.opening_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[5]?.closing_time)
            // setEndSaturdayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[5]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[5]?.closing_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[5]?.closing_time)
            // setStartSundayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[6]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[6]?.opening_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[6]?.closing_time)
            // setEndSundayTime(props?.addressDataModal?.jobr_delivery_availability?.availability[6]?.closing_time ? convertTo24HourFormat(props?.addressDataModal?.jobr_delivery_availability?.availability[6]?.closing_time) : props?.addressDataModal?.jobr_delivery_availability?.availability[6]?.closing_time)
            // setBusinessHours(businessDays)
        }
        setExpressTime(props?.addressDataModal?.product_availability?.express_delivery ? props?.addressDataModal?.product_availability?.express_delivery : "10min")
        setOneHourTime(props?.addressDataModal?.product_availability?.one_hour_window ? props?.addressDataModal?.product_availability?.one_hour_window : "10min")
        setTwoHourTime(props?.addressDataModal?.product_availability?.two_hour_window ? props?.addressDataModal?.product_availability?.two_hour_window : "10min")
        setPickupInstruction(props?.addressDataModal?.product_availability?.delivery_instructions ? props?.addressDataModal?.product_availability?.delivery_instructions : "")
        setValues(props?.addressDataModal?.miles ? [props?.addressDataModal?.miles] : [5])
    }

    useEffect(() => {
        filterData()
    }, [])
    return (
        <>
            <div className='deliveryAddress'>
                <form className="row locationForm_" onSubmit={(e) => handleSubmit(e)}>
                    <div className='formdelivery_'>
                    <div className='col-md-12'>
                        <div className='rangeouter_  py-2 px-3 mb-4'>
                            <div className='leftlocateicon_'>
                                <img className='locateiconn_' src={rangeLocate} />
                            </div>
                            <div className='rangeRight_'>
                                <h3 className="title p-0 m-0"><span className='commonsmallgeryHead_'>In your</span> <strong style={{ color: "#275AFF" }}>5 miles</strong></h3>
                                <Range
                                    values={values}
                                    step={STEP}
                                    min={MIN}
                                    max={MAX}
                                    onChange={(newValues) => setValues(newValues)}
                                    renderTrack={({ props, children }) => {
                                        return (
                                            <div
                                                onMouseDown={props.onMouseDown}
                                                onTouchStart={props.onTouchStart}
                                                style={{
                                                    ...props.style,
                                                    height: "36px",
                                                    display: "flex",
                                                    width: "100%"
                                                }}
                                            >
                                                <div
                                                    onMouseDown={props.onMouseDown}
                                                    onTouchStart={props.onTouchStart}
                                                    style={{
                                                        ...props.style,
                                                        height: "36px",
                                                        display: "flex",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <div
                                                        ref={props.ref}
                                                        style={{
                                                            height: "4px",
                                                            width: "100%",
                                                            borderRadius: "5px",
                                                            background: getTrackBackground({
                                                                values: values,
                                                                colors: ["#275AFF", "#CACACA"],
                                                                min: MIN,
                                                                max: MAX
                                                            }),
                                                            alignSelf: "center",
                                                            // boxShadow: "inset -1px -3px 0px 0px rgb(102, 99, 106)"
                                                        }}
                                                    >
                                                        {children}
                                                        <div className="scale">{createScale()}</div>
                                                    </div>
                                                </div>
                                                </div>
                                            );
                                        }}
                                        renderThumb={({ props, isDragged }) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: "20px",
                                                    width: "20px",
                                                    borderRadius: "15px",
                                                    backgroundColor: "#275AFF",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    // border: "3px solid rgb(230, 192, 179)"
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <h6 className='deatilsHead'>Information at checkout</h6>
                        </div>
                        <div className="mb-4 form-group col-md-12">
                            <label className="deatilsHead mb-2">Express delivery
                                <span className='subtextSmall_'>(1 hour delivery window)</span>
                            </label>
                            <select className='customform-control select' value={expressTime} onChange={(e) => handleChange(e, "express")}>
                                <option value="10 min">Usually ready in 10 minutes</option>
                                <option value="20 min">Usually ready in 20 minutes</option>
                                <option value="30 min">Usually ready in 30 minutes</option>
                                <option value="40 min">Usually ready in 40 minutes</option>
                                <option value="50 min">Usually ready in 50 minutes</option>
                                <option value="60 min">Usually ready in 60 minutes</option>
                            </select>
                            {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                        </div>
                        <div className="mb-4 form-group col-md-12">
                            <label className="deatilsHead mb-2">1 hour delivery window </label>
                            <select className='customform-control select' value={oneHourTime} onChange={(e) => handleChange(e, "1hr")}>
                                <option value="10 min">Usually ready in 10 minutes</option>
                                <option value="20 min">Usually ready in 20 minutes</option>
                                <option value="30 min">Usually ready in 30 minutes</option>
                                <option value="40 min">Usually ready in 40 minutes</option>
                                <option value="50 min">Usually ready in 50 minutes</option>
                                <option value="60 min">Usually ready in 60 minutes</option>
                            </select>
                            {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                        </div>
                        <div className="mb-4 form-group col-md-12">
                            <label className="deatilsHead mb-2">2 hour delivery window </label>
                            <select className='customform-control select' value={twoHourTime} onChange={(e) => handleChange(e, "2hr")}>
                                <option value="10 min">Usually ready in 10 minutes</option>
                                <option value="20 min">Usually ready in 20 minutes</option>
                                <option value="30 min">Usually ready in 30 minutes</option>
                                <option value="40 min">Usually ready in 40 minutes</option>
                                <option value="50 min">Usually ready in 50 minutes</option>
                                <option value="60 min">Usually ready in 60 minutes</option>
                            </select>
                            {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                        </div>
                        <div className='mb-4 form-group col-md-12'>
                            <div className='orderPickup__'>
                                <label className="deatilsHead mb-3"> Pickup instructions for JOBR Driver</label>
                                <textarea className="customform-control" id="" rows="4" value={pickupInstruction} onChange={e => setPickupInstruction(e.target.value)} placeholder='Bring your confirmation email when you come to collect your order.' />
                            </div>
                        </div>

                        {/* <div className="mb-4 form-group col-xxl-12 col-lg-12 col-md-12 ">
                        <label className="deatilsHead mb-3"> Business Hours</label>
                        <div className='checkboxGroup businessChekbox d-block'>
                            {dayList.map((item, index) => {
                                return (
                                    <div className='checkboxInputs' key={index}>
                                        <input type="checkbox" id={item.day} className='checkBox' name={item.day} value={item.day}
                                            onChange={(e) => handleCheckboxChange(e)} checked={businessHours?.includes(item.day)} />
                                        <label htmlFor={item.day} className='checkBoxTxt'>{item.value}</label>
                                        {
                                            businessHours?.includes(item.day) &&
                                            <>
                                                <div style={{ display: "flex" }} className='timerBox_'>
                                                    <div style={{ display: "flex" }} className='innertimedate_'>
                                                        <p className='timehead_'>Start Time:</p>
                                                        <TimePicker
                                                            onChange={(e) => handleStartTimeChange(e, item.day)}
                                                            value={item.id == 1 ? startMondayTime : item.id == 2 ? startTuesddayTime : item.id == 3 ? startWednesdayTime : item.id == 4 ? startThursdayTime : item.id == 5 ? startFridayTime : item.id == 6 ? startSaturdayTime : startSundayTime}
                                                            format="h:mm a"
                                                            showLeadingZeros={false}
                                                            amPmAriaLabel="Select AM/PM"
                                                            className="custom-time-picker customPicker"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }} className='timerBox_'>
                                                        <p className='timehead_'>End Time:</p>
                                                        <TimePicker
                                                            onChange={(e) => handleEndTimeChange(e, item.day)}
                                                            value={item.id == 1 ? endMondayTime : item.id == 2 ? endTuesdayTime : item.id == 3 ? endWednesdayTime : item.id == 4 ? endThursdayTime : item.id == 5 ? endFridayTime : item.id == 6 ? endSaturdayTime : endSundayTime} // Use the 12-hour formatted time string directly
                                                            format="h:mm a" // Specify the format as 12-hour with AM/PM                                              
                                                            className="custom-time-picker customPicker"
                                                        />
                                                    </div>
                                                </div>
                                                <Dropdown onClick={() => { handleCopy(item.id) }}>
                                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                        <label className="iconcopy_ mb-0"> <i className="fa-solid fa-copy"></i> </label>
                                                    </Dropdown.Toggle>
                                                    <div className='timedatecoprBox_'>
                                                        <Dropdown.Menu>
                                                            <Form>
                                                                {options.map((option) => (
                                                                    <Form.Check
                                                                        key={option.id}
                                                                        type="checkbox"
                                                                        label={option.label}
                                                                        id={`option-${option.id}`}
                                                                        checked={selectedOptions.find(val => val?.id == option?.id)}
                                                                        onChange={() => handleCheckboxChange1(option, item.id)}
                                                                    />
                                                                ))}
                                                            </Form>
                                                        </Dropdown.Menu>
                                                    </div>
                                                </Dropdown>
                                            </>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}
                    </div>
                    
                    <div className='col-md-12 text-start  modalfooterBtn mt-5'>
                        <button className='me-4 w-100 btnDiscard' onClick={() => props.close()}>Cancel</button>
                        <button className='w-100 btnSave' type="submit" disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Save</span>
                        </button>
                    </div>

                </form>
            </div>

        </>
    )
}

export default DeliveryAddress