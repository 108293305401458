import React from "react";
import moment from "moment-timezone";
import * as Images from '../../../../utilities/images';
import {
    amountFormat,
    formattedReturnPrice,
} from "../../../../utilities/globalMethods";

const ReturnInvoice = ({
    merchentDetails,
    returnProductArray,
    returnData,
    posData,
    refundAmountSubTotal,
    deliveryShippingCharges,
}) => {
    return (
        <>
            <div className="col-lg-6 col-md-6">

                {console.log(merchentDetails, "meeeeeeeeeeeeeeee")}
                <div className="commanOuter me-0 ms-0 commonSubOuter confirmRight p-0 returnInvoive_">
                    <div className="confirmRightSub confirmAddress">
                        <h2 className="mapleHeading text-center">
                            {merchentDetails?.organization_name}.
                        </h2>
                        <h4 className="mapleAddress text-center">
                            {" "}
                            {merchentDetails?.current_address?.street_address},
                            {merchentDetails?.current_address?.city},
                            {merchentDetails?.current_address?.state},
                            {merchentDetails?.current_address?.country},
                            {merchentDetails?.current_address?.zipcode}
                        </h4>
                        <h4 className="mapleAddress text-center p-0">
                            {" "}
                            {merchentDetails?.full_phone_number}
                        </h4>
                    </div>
                    <div className="mapleProductDetails confirmRightSub">
                        {returnProductArray?.map((data, idx) => {
                            return (
                                <div key={idx} className="flexBox mapleProductDetailsBox">
                                    <div className="flexbase listinvoice_">
                                        <p className="mapleProductcount">× {data?.returned_qty}</p>
                                        <article className="ms-3">
                                            <p className="mapleProductHeading">
                                                {data?.order_details?.product_name}
                                            </p>
                                            {/* <span className="mapleProductcount">Yellow / M</span> */}
                                        </article>
                                    </div>
                                    <article>
                                        <p className="mapleProductPrice">
                                            {formattedReturnPrice(
                                                data?.refunded_amount * data?.returned_qty
                                            )}
                                        </p>
                                    </article>
                                </div>
                            );
                        })}
                    </div>
                    <div className="flexBox mapleInvoiceBox confirmRightSub">
                        <article>
                            <p className="mapleProductPrice">Status</p>
                            <p className="mapleProductHeading">{returnData?.type}</p>
                            <p className="mapleProductPrice">Invoice</p>
                            <p className="mapleProductHeading">
                                # {returnData?.invoices?.invoice_number}
                            </p>
                        </article>
                        <article>
                            <p className="mapleProductPrice">Date</p>
                            <p className="mapleProductHeading">
                                {" "}
                                {moment.utc(returnData?.updated_at).format("ddd, DD/MM/YYYY")}
                            </p>
                            <p className="mapleProductPrice">POS No.</p>
                            <p className="mapleProductHeading">{posData?.pos_number}</p>
                        </article>
                        <article>
                            <p className="mapleProductPrice">Mode</p>
                            <p className="mapleProductHeading">Walk-In</p>
                            <p className="mapleProductPrice">User UD</p>
                            <p className="mapleProductHeading">{posData?.id}</p>
                        </article>
                    </div>
                    <div className="flexBox maplePriceBox">
                        <article>
                            <p className="productName">Subtotal</p>
                            {(returnData?.delivery_charge != "0" ||
                                returnData?.shipping_charge != "0") && (
                                    <p className="productName">{deliveryShippingCharges?.title}</p>
                                )}

                            <p className="productName">Tax</p>
                            <p className="productName">Discount</p>
                            <p className="productName fw-bold">Total</p>
                        </article>
                        <article>
                            <p className="productName">
                                {formattedReturnPrice(refundAmountSubTotal)}
                            </p>
                            {(returnData?.delivery_charge != "0" ||
                                returnData?.shipping_charge != "0") && (
                                    <p className="productName">
                                        {formattedReturnPrice(
                                            deliveryShippingCharges?.deliveryCharges
                                        )}
                                    </p>
                                )}

                            <p className="productName">
                                {formattedReturnPrice(returnData?.tax)}
                            </p>
                            <p className="productName">
                                {amountFormat(returnData?.discount)}
                            </p>

                            <p className="totalBtn">
                                {formattedReturnPrice(returnData?.refunded_amount)}
                            </p>
                        </article>
                    </div>
                    <div className="confirmFooter">
                        <img
                            src={Images.Logo}
                            alt="logo"
                            className="img-fluid logo mb-3"
                        /><br />
                        <img
                            src={Images.barCodeScanImg}
                            alt="barCodeScanImg"
                            className="img-fluid barCodeScanImg"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReturnInvoice;
