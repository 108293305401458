import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {usa} from '../../../../utilities/images'
import {taxes} from '../../../../utilities/images'
import CustomModal from '../../../components/shared/CustomModal'
import TaxPayer from './taxPayer';
import { selectLoginAuth, } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import { getStateAsync } from "../systemConfigSlice";

const StateModal = (props) => {
const history = useHistory()
const [loading, setLoading] = useState([]);
const [key, setKey] = useState(Math.random());
const [stateData, setStateData] = useState([]);
const [selectedOption, setSelectedOption] = useState();
const [stateId, setStateId] = useState();
const auth = useSelector(selectLoginAuth);
const dispatch = useDispatch()
const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
});
const handleOnCloseModal = () => {
    setModalDetail({
        show: false,
        title: "",
        flag: ""
    });
    setKey(Math.random());
}

const getStatedata = () => {
  let data = {
    tokenData: auth?.payload?.token,
    postData: {
      country_id: props.data.id,
      page: 1,
      limit: 10,
    }
  }
  setLoading(true)
  dispatch(getStateAsync(data))
    .then(unwrapResult)
    .then((obj) => {
      setLoading(false)
      setStateData(obj?.payload ? obj?.payload : [])

    }
    )
    .catch((obj) => {
      setLoading(false)
    })
}

const handleStateSelect = (event) => {
  const selectedOption = event.target.value;
  setStateId(event.target.id)
  setSelectedOption(selectedOption);
};

  useEffect(()=>{
    getStatedata();
  },[])

  return (
    <>
    <div className='countryModal'>
        <div className='stateCon'>
            <div className='d-flex align-items-center'>
              <img src={usa} alt="" className='me-3'/>
              <h1 className='subtext_'>{props.data.country}</h1>
            </div>
            <h1 className='subheadText fw-semibold mt-3'>Select States</h1>
            {stateData?.data?.map((val, index) => {
              return (
            <div className='my-4 form-group col-md-12 d-flex' key={index}>
                <input type="checkbox" name="" id={val?.id} value={val?.name} checked={selectedOption === val?.name}
                onChange={handleStateSelect}/>
                <label htmlFor="register" className='ms-2'>{val?.name}</label><br />
            </div>
            )
          })}
      
          </div>
        <div className='footerSec mt-4'>
                <button to='' className='closebtnModal me-4'>Cancel</button>
                <button to='' className='exchangeBtn lh-sm' onClick={() => {props.details("TaxPayer"); props.setStateData({state:selectedOption,stateId:stateId})}} >Next</button>
            </div>
    </div>
  
    </>
  )
}

export default StateModal