import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import CustomModal from '../../../../components/shared/CustomModal';
import OriginalinvoiceModal from './originalinvoiceModal';
import {
    dotsinbox, sendIcon, buyerImg, locationBlue, products, ticketImg, dhlimg, refundproduct, returnbox, moneyIcon
} from '../../../../../utilities/images'
const RefundProcessing = () => {
    const history = useHistory()
    const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    return (
        <div className="wrapper">
            <div className='refundProcessing_'>
                {/* Start FixedTopHeader */}
                <div className='topHeader_'>
                    <div className="productTopStickyHeader_ mb-3">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="innerSubheadNormal">
                                    <span onClick={() => history.push('/plans')}>
                                        <i className="las la-angle-left me-3 directionArrow"></i>
                                    </span>
                                    <span>Refund/Return id#12365</span>
                                    <span className='pendingStatus ms-3'>Refund Processing</span>
                                </h2>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End FixedTopHeader */}
                <div className="content_areas">
                    <div className="messagechatBox">
                        <div className="row">
                            <div className="col-md-4 pe-0">
                                <div className="chat_inbox">
                                    <div className='buyerDetails_ '>
                                        <h5 className='textInnerHead'>Buyer</h5>
                                        <div className='buyerInfomation_ mt-3'>
                                            <figure>
                                                <img className='buyrImg' src={buyerImg} />
                                            </figure>
                                            <aside className='buyerRightbox'>
                                                <h5 className='productDetails fw-500'>American Medical Associ..</h5>
                                                <p className='accordionInnerLinks mt-1'><img src={locationBlue} /> 1189 Main Street, Seattle, WA 98161</p>
                                                <hr />
                                                <div className='buyrpaymentDetail'>
                                                    <h4 className='buyersubHead me-3'><img src={ticketImg} /> <span>$516.30</span></h4>
                                                    <h4 className='buyersubHead me-3'><img src={products} /> <span>$516.30 </span></h4>
                                                    <h5 className='bluesubHead_'>Details</h5>
                                                    {/* <Link to="#"></> */}
                                                </div>
                                            </aside>
                                        </div>
                                        <hr />
                                        <div className='reportIssue_'>
                                            <h6 className='commonsmallBold__'>Report Issue:</h6>
                                            <p className='agentHeading mb-2'>Wrong Products</p>
                                            <h6 className='commonsmallBold__'>Report Issue Date</h6>
                                            <p className='agentHeading mb-2'>9/03/2023    12:52 PM</p>
                                        </div>
                                    </div>
                                    <hr className='hrborder' />

                                    <div className='buyerDetails_ '>
                                        <h5 className='textInnerHead'>Shipping</h5>
                                        <div className='buyerInfomation_ mt-3'>
                                            <figure>
                                                <img className='buyrImg' src={dhlimg} />
                                            </figure>
                                            <aside className='buyerRightbox'>
                                                <h5 className='productDetails fw-500'>American Medical Associ..</h5>
                                                <p className='accordionInnerLinks mt-1'><img src={locationBlue} /> 1189 Main Street, Seattle, WA 98161</p>
                                                <hr />
                                                <div className='buyrpaymentDetail'>
                                                    <h4 className='buyersubHead me-3'><img src={ticketImg} /> <span>$516.30</span></h4>
                                                    <h5 className='bluesubHead_'>Details</h5>
                                                    {/* <Link to="#"></> */}
                                                </div>
                                            </aside>
                                        </div>
                                        <hr />
                                        <div className='reportIssue_'>
                                            <h6 className='commonsmallBold__'>Shipment ID:</h6>
                                            <p className='agentHeading mb-2'>EB033979713IT</p>
                                            <h6 className='commonsmallBold__'>Shipment Date</h6>
                                            <p className='agentHeading mb-2'>9/03/2023    12:52 PM</p>
                                            <h6 className='commonsmallBold__'>Delivery Date</h6>
                                            <p className='agentHeading'>9/03/2023    12:52 PM</p>
                                        </div>
                                    </div>
                                    <hr className='hrborder' />
                                    <div className='lastUpdateBox_ mt-4'>
                                        <h5 className='textInnerHead mb-3'>Last Update</h5>
                                        <div className='reportIssue_'>
                                            <h6 className='commonsmallBold__'>Request for Return all Products</h6>
                                            <p className='agentHeading mb-2'>9/03/2023    12:52 PM</p>
                                            <h6 className='commonsmallBold__'>Request for Return all Products</h6>
                                            <p className='agentHeading'>9/03/2023    12:52 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 p-0">
                                <div className="rightMessagebox_">
                                    <div className="message_body">
                                        <div className="chatDatebox_in">
                                            <h5 className="chatdeateHeading">Today | April 20, 2022</h5>
                                        </div>
                                        <div className="leftMessage mt-3 mb-2">
                                            <div className="leftmessage-box">
                                                <img className='senduserImage ' src={dhlimg} />
                                                <div className="leftBoxmsg_">
                                                    <div className="textinner_">
                                                        <figure className='chatleftImage me-3 mb-0' >
                                                            <img src={refundproduct} />
                                                        </figure>
                                                        <aside className='productrightDetail'>
                                                            <h5 className='mainboldHead mb-0'>Ashton Classic</h5>
                                                            <p className='descriptionPara_ mb-2'><em>20 Pack Box</em></p>
                                                            <h6 className='descriptionPara_bold '>Issue: Wrong Product</h6>
                                                            <p className='descriptionPara_small fw-400'>3 Box</p>
                                                        </aside>
                                                        <hr />
                                                    </div>
                                                    <hr />

                                                    <div className="textinner_">
                                                        <figure className='chatleftImage me-3 mb-0' >
                                                            <img src={refundproduct} />
                                                        </figure>
                                                        <aside className='productrightDetail'>
                                                            <h5 className='mainboldHead mb-0'>Ashton Classic</h5>
                                                            <p className='descriptionPara_ mb-2'><em>20 Pack Box</em></p>
                                                            <h6 className='descriptionPara_bold '>Issue: Wrong Product</h6>
                                                            <p className='descriptionPara_small fw-400'>3 Box</p>
                                                        </aside>

                                                    </div>
                                                    <hr />
                                                    <div className="textinner_">
                                                        <figure className='chatleftImage me-3 mb-0' >
                                                            <img src={refundproduct} />
                                                        </figure>
                                                        <aside className='productrightDetail'>
                                                            <h5 className='mainboldHead mb-0'>Ashton Classic</h5>
                                                            <p className='descriptionPara_ mb-2'><em>20 Pack Box</em></p>
                                                            <h6 className='descriptionPara_bold '>Issue: Wrong Product</h6>
                                                            <p className='descriptionPara_small fw-400'>3 Box</p>
                                                        </aside>

                                                    </div>
                                                    <hr />
                                                    <div className='showdetails_'>
                                                        <Link to="#" className='showless_ me-5'>See Less <i className="fal fa-chevron-up ms-2" aria-hidden="true"></i></Link>
                                                        <Link to="#" className='showless_' data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                                                            setModalDetail({ show: true, flag: "SaleinvoiceModal" });
                                                            setKey(Math.random());
                                                        }}>See the Original Invoice <i className="fas fa-external-link-alt shareinfo"></i></Link>
                                                    </div>
                                                    <p className='tableHeadsmall mt-2'>I received the wrong product. The product you delivered is not in acco.....</p>
                                                    <div className='amountBoxx_ mt-3'>
                                                        <p className='agentHeading mb-2'>Return Amount </p>
                                                        <h4 className='pinkHead'>$705.00</h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <span className="subtextSmallblack_">12:30 pm</span>
                                        </div>
                                        <div className="rightMessage mt-3 mb-2">
                                            <div className="rightmessage-box">
                                                <div className="rightboxmsg_">
                                                    <div className="textinner_">
                                                        <p className='mb-0'>We are very sorry for this inconvenience.</p>
                                                        <p>Please <Link to="#">Return all products </Link> those are reported as wrong item ect.</p>

                                                        <p>  Shipping cost of return back to Company and shipping to you will bear by us.</p>

                                                        <p> Thank you
                                                        </p>
                                                    </div>
                                                </div>
                                                <img className='senduserImage ' src={dhlimg} />
                                            </div>
                                            <span className="subtextSmallblack_">12:30 pm</span>
                                        </div>
                                        <div className='requestBox_ mt-5'>
                                            <ul>
                                                <li><Link to="#" className='requestBtn_ me-3'> <img className='proImage' src={returnbox}/> Ask for Return Products</Link></li>
                                                <li><Link to="#" className='requestBtn_ me-3'> <img className='proImage' src={returnbox}/> Ask for Images</Link></li>
                                                <li><Link to="#" className='requestBtn_ me-3'> <img className='proImage' src={returnbox}/> Ask for Videos</Link></li>
                                                <li><Link to="#" className='paymentamount_'> <img className='proImage' src={moneyIcon}/> Pay full  Amount</Link></li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className='messageBottom_'>
                                        <div className='typeMessgae'>
                                            <div className='dropdown typeBtn_'>
                                                <Link to="#" id="dropdownMenuButton2">
                                                    <img className='dotIconn_' src={dotsinbox} />
                                                </Link>

                                            </div>
                                            <textarea rows="2" cols="30" id="messageBox" placeholder="Type your message here"></textarea>
                                            <button type='submit' className='submitBtn'>Send <img className='sendFill' src={sendIcon} /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "OriginalinvoiceModal" ? "commonWidth customContent" :  ""}
                ids={modalDetail.flag === "OriginalinvoiceModal" ? "originalinvoiceModal" : ""}

                child={

                    modalDetail.flag === "OriginalinvoiceModal" ? (
                        <OriginalinvoiceModal close={() => handleOnCloseModal()} />
                    ) :
                            (
                                ""
                            )
                }
                header={
                    <>
                        <div className='modalHeader_'>
                            <div className="common_">
                                {modalDetail.flag === "OriginalinvoiceModal" ?
                                    <h2 className="subheading2_">Invoice</h2>
                                    :
                                    ""
                                }
                                <button className="closeButton">
                                    <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                </button>
                            </div>
                        </div>
                    </>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </div>

    )
}

export default RefundProcessing
