import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import products from "../../../db/category.json"
import { calendar, shield, user, dotsinbox, profileImg1, } from '../../../../utilities/images';
import { assignSupportAgentApiAsync, deleteSupportApiAsync, getSupportTicketDataApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import CustomModal from '../../../components/shared/CustomModal';
import DeleteModal from '../../myPos/deleteModal';
import SuportticketModal from './supportticketModal';

const PendingTicket = (props) => {
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch()
    const [selectedProduct, setSelectedProduct] = useState('')
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [supportID, setSupportID] = useState('');
    const auth = useSelector(selectLoginAuth);
    const [supportData, setSupportData] = useState([]);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const history = useHistory()
    const [enterprisesDate, setEnterprisesDate] = useState("")
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const handleAssignAgent = (supportAgentIid) => {
        let paramData = {
            postData: {
                "support_id": supportID,
                "support_agent_id": supportAgentIid
            },
            tokenData: auth?.payload?.token
        }
        setLoading1(true)
        dispatch(assignSupportAgentApiAsync(paramData))
            .then(unwrapResult)
            .then((obj) => {
                getSupportData()
                setLoading1(false)
                handleOnCloseModal()
            }
            )
            .catch((obj) => {
                setLoading1(false)
            })
    }

    const handleDeleteSupport = (id) => {
        let paramdata = {
            "postData": {
                id: id,
            },
            "tokenData": auth?.payload?.token
        }
        setLoading1(true)
        dispatch(deleteSupportApiAsync(paramdata))
            .then(unwrapResult)
            .then((obj) => {
                let params = {
                    "postData": {
                        page: currentPage,
                        limit: perPage,
                        filter: 'pending_tickets'
                    },
                    "tokenData": auth?.payload?.token
                }
                dispatch(getSupportTicketDataApiAsync(params))
                    .then(unwrapResult)
                    .then((obj) => {
                        setSupportData(obj?.payload?.data)
                        setTotalRows(obj?.payload?.total)
                        props.setPendingTicketCount(obj?.payload?.data?.length)
                        setLoading1(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading1(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading1(false)
            })
    }

    const columns1 = [
        {
            name: '#',
            selector: row => row?.id,
            width: "70px"
        },
        {
            name: "Name",
            selector: row => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <img className='userInnerImg' src={row?.user_detail?.user_profiles?.profile_photo ? row?.user_detail?.user_profiles?.profile_photo : profileImg1} />
                            <div className='ms-2'>
                                <span className='contentSubtext_ '>{row?.user_detail?.user_profiles?.firstname}</span>
                                <p className='mb-0'>
                                    <img src={shield} className='img-fluid me-2' />
                                    <span className='textInner'>260 101 480 0083 </span>
                                </p>
                            </div>
                        </div>
                    </>
                );
            },
            width: "300px",
        },
        {
            name: 'Date',
            selector: row => {
                return (
                    <>
                        <div className='ms-2'>
                            <span className='contentSubtext_'>{moment(row?.created_at).format("MMM DD, YYYY")}</span>
                            <p className='textInner mt-2'>{moment(row?.created_at).format('hh:mm a')}</p>
                        </div>
                    </>
                );
            },
      
        },
        {
            selector: row => `#${row?.id}`,
            name: 'Ticket id',
        },
        {
            selector: row => `${row?.subject_id == 1 ? "Payment" : row?.subject_id == 2 ? "Delivery" : row?.subject_id == 3 ? "Transfer" : row?.subject_id == 4 ? "Order" : "Others"} Issue`,
            name: 'Subject',
        },
        {
            selector: row => row?.user_detail?.user_profiles?.seller_type,
            name: 'User Type',
        },
        {
            selector: row => row?.support_agent?.user_profiles?.firstname+" "+row?.support_agent?.user_profiles?.lastname,
            name: 'Responsible',
        },
        {
            name: 'Status',
            selector: row => {
                return (
                    <>
                        <div className='ms-2 mt-2 tableBtn__ text-center'>
                            <div className={row?.status_id === 5 ? "bundleBtn" : row?.status_id === 1 ? "completeStatus" : "pendingStatus"}>{row?.status_id === 5 ? "Assigned" : row?.status_id === 1 ? "Resolved" : "Pending"}</div>

                        </div>
                    </>
                );
            },
            width: "170px",
        },
        {
            name: '',
            selector: row => {
                return (
                    <>
                        <div className='dropdown chatditBtn'>
                            <Link to="#" id="dropdownMenuButton1" data-bs-toggle="dropdown" className="more_icon" aria-expanded="false">
                                <img className='dotIcon_' src={dotsinbox} />
                            </Link>
                            <ul className="dropdown-menu chatDropDown" aria-labelledby="dropdownMenuButton1" >
                                <li onClick={() => history.push(`/supportticketDetail/${row?.id}`)}><Link to="#" className="dropdown-item chatdropItem " >
                                    <span className='subtextSmall_'>View Details</span></Link></li>
                                <li onClick={() => {
                                    setModalDetail({ show: true, flag: "SuportticketModal" });
                                    setKey(Math.random());
                                    setSupportID(row?.id)
                                    setSelectedProduct(row)
                                }}><Link to="#" className="dropdown-item chatdropItem" >
                                        <span className='subtextSmall_'>Assign new Agent</span></Link>
                                </li>
                                <li onClick={() => {
                                    setSelectedProduct(row)
                                    setModalDetail({ show: true, flag: "deleteSupport" });
                                    setKey(Math.random());
                                }}><Link to="#" className="dropdown-item chatdropItem" >
                                        <span className='subtextSmall_'>Delete</span></Link>
                                </li>
                            </ul>
                        </div>
                    </>
                );
            },
        },
    ];

    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        // history.push({ pathname: '/analytics-product', state: JSON.stringify(e) })
    }
    const getSupportData = () => {
        let params = {
            "postData": {
                page: currentPage,
                limit: perPage,
                filter: 'pending_tickets'
            },
            "tokenData": auth?.payload?.token
        }

        if (props?.startDate && props?.endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(props?.startDate).format('YYYY-MM-DD'),
                "end_date": moment(props?.endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getSupportTicketDataApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setSupportData(obj?.payload?.data)
                setTotalRows(obj?.payload?.total)
                props.setPendingTicketCount(obj?.payload?.data?.length)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getSupportData()
    }, [currentPage, perPage, props?.endDate])

    return (
        <div className='suportTicket'>
            <div className='commonTableheight table-responsive'>
                <DataTable
                    pagination
                    columns={columns1}
                    data={supportData}
                    defaultSortFieldId={1}
                    onRowClicked={handleRowClick}
                    progressPending={loading}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                />
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "SuportticketModal" ? "suportticketModal" : ""}
                ids={modalDetail.flag === "deleteSupport" ? "DeleteSupport" : modalDetail.flag === "SuportticketModal" ? "suportticketModal" : ""}
                child={
                    modalDetail.flag === "deleteSupport" ? (
                        <DeleteModal flag="deleteSupport" loadingState={loading1} selectedProduct={selectedProduct} deleteSupport={(e) => handleDeleteSupport(e)} close={() => handleOnCloseModal()} />
                    ) :
                        modalDetail.flag === "SuportticketModal" ? (
                            <SuportticketModal selectedProduct={selectedProduct} loading={loading1} handleAssignAgent={(supportAgentIid) => handleAssignAgent(supportAgentIid)} close={() => handleOnCloseModal()} />
                        ) :
                            (
                                ""
                            )
                }
                header={
                    <>
                        {modalDetail.flag === "SuportticketModal" ?
                            <div className='modalHeader_'>
                                <div className="common_">
                                    <h2 className="mainHeading">
                                        Assign Agent
                                    </h2>
                                    <button className="closeButton">
                                        <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                    </button>
                                </div>
                            </div>
                            :
                            <div className='modalHeader_'>
                                <div className="common_">
                                    {
                                        modalDetail.flag === "deleteSupport" ?
                                            <h2 className="subheading2_">Delete Support</h2>
                                            :
                                            <></>
                                    }
                                    <button className="closeButton">
                                        <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                                    </button>

                                </div>
                            </div>}
                    </>
                }

                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default PendingTicket