import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { deleteIcon, docupload, fileicons, sendIcon } from '../../../../utilities/images'
import { imageUploadAsync } from '../messageSlice';
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { selectLoginAuth } from '../../auth/authSlice';
import { FileUploader } from "react-drag-drop-files";
import { uploadFileInCatelogAsync } from '../../dashboard/dashboardSlice';
const fileTypes = ["JPG", "PNG", "GIF", "txt", "pdf"];

const FileuploadModal = (props) => {
    const history = useHistory()
    const [file, setFile] = useState([]);
    const dispatch = useDispatch()
    const toastId = React.useRef(null);
    const auth = useSelector(selectLoginAuth)
    const [loading, setLoading] = useState(false);

    const handleChange = (file) => {
        uploadImage(file)
    };

    const uploadImage = (file) => {
        let formData = new FormData();
        Object.values(file).forEach((val, index) => {
            formData.append(`file`, val);
        });

        let params = {
            file: formData,
            token: auth?.payload?.token
        }
        setLoading(true)
        if (props.flag === "catalog") {
            dispatch(uploadFileInCatelogAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setLoading(false)
                    setFile(obj?.payload);
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        } else {
            dispatch(imageUploadAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    setLoading(false)
                    setFile(obj?.payload);
                    // setFile([...file, {
                    //     fileURl: obj.payload.profile_photo,
                    //     name: documentFile.name, size: documentFile.size
                    // }])
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg)
                    }

                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        }
    }

    const deleteFile = (item) => {
        const filterFile = file.filter(file => file.filePath != item);
        setFile(filterFile)
    }


    const sendFile = (file) => {
        if (props.flag === "catalog") {
            props.uploadHandleInCatelog(file)
            props.close();
        } else {
            props.file(file, "doc");
            props.close();
        }
    }


    return (
        <>
            <div className='uploadphotoModal commonBody'>
                <form className='emplyoeeDetails horizontalForms'>
                    <div className='row'>

                        <div className='form-group col-md-12 mb-4 text-start'>
                            <div className='mainCatalog'>

                                <div id='dropzone'>
                                    <FileUploader
                                        multiple={true}
                                        handleChange={handleChange}
                                        name="file"
                                        types={fileTypes}
                                        children={
                                            <div >
                                                {loading === true ?
                                                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                                                    :
                                                    <>
                                                        <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" onChange={(e) => { handleChange(e) }} />
                                                        <label htmlFor="file">
                                                            <span className="file-button uploadImages_">
                                                                <img src={fileicons} className="img-fluid editProfileImg" alt="user img" />
                                                                <div className='dropText ms-3'>
                                                                    <span className='textinner_'>Drop your files here or</span>
                                                                    <Link to='#' className='linkTxt ms-1'>Browse</Link>
                                                                </div>
                                                            </span>
                                                        </label>
                                                    </>}
                                            </div>
                                        }
                                    />
                                    {/* <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" onChange={(e) => { handleChange(e) }} /> */}

                                    {/* <input type="file" id="file" accept="image/*" className="form-control d-none" onChange={(e) => { handleChange(e) }} /> 
                        <label for="file">
                            <span className="file-button">
                                <img src={imgGallery1} className="img-fluid editProfileImg" alt="user img" />
                                <div className='dropText ms-3'>
                                    <span className='textinner_'>Drop your files here or</span>
                                    <Link to="#" className='linkTxt ms-1'>Browse</Link>
                                </div>
                            </span>
                        </label>  */}
                                </div>
                                {/* <label className='uploadContent mt-2 mb-3' htmlFor='amount'>
                                For bulk file upload: <span className='subtextSmall_'><i>Name your images like</i><Link to="#" className='linkText ms-1'>main file</Link></span>
                            </label> */}

                                {file && file?.length > 0 ?
                                    <>
                                        {file?.map((item, index) => {
                                            return (
                                                <ul className='uploadProfile_' key={index}>
                                                    <img className="w3-round" alt='' src={item?.mimeType == "application/pdf" ? docupload : item?.mimeType == "text/plain" ? "https://cdn-icons-png.flaticon.com/512/3979/3979306.png" : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqSwpiH1Qm_cPwkq3YZaqzosujuIVodIrirMcMY1fSQg&usqp=CAU&ec=48665701"} ></img>
                                                    <div className='profileDetail_'>
                                                        <h5 className='profileboldHead'>{item.originalname}</h5>
                                                        <p className='profileboldgraph'> {(item.size / (1024 * 1024)).toFixed(2)} MB </p>
                                                    </div>
                                                    <img className='deleteIconn' alt ='' src={deleteIcon} onClick={(e) => deleteFile(item.filePath)} />
                                                </ul>
                                            )
                                        })}
                                        <button type='button' className='submitBtn' onClick={() => sendFile(file)}>{props.flag === "catalog" ? 'Upload' : 'Send'} <img className='sendFill' src={sendIcon} /></button>
                                    </>
                                    :
                                    <></>
                                }

                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </>

    )
}

export default FileuploadModal